import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';

import AdminPage from '@client/components/AdminPage';
import LoadingSection from '@client/components/generic/LoadingSection';
import theme from '@client/css-modules/AdminPage.css';
import {
  fetchAdminPortalDashboardUrl,
  selectEmbeddingUrl,
} from '@client/store/slices/admin-portal.slice';

// documentation to add Quicksight imports
// https://github.com/awslabs/amazon-quicksight-embedding-sdk

// this is needed so that we don't call embedDashboard() with the same url, which causes an error
// https://github.com/awslabs/amazon-quicksight-embedding-sdk/issues/107
let previousUrl: string | null = null;

const Analytics = () => {
  const dispatch = useDispatch();
  const url = useSelector(selectEmbeddingUrl);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErrorState, setIsErrorState] = useState(false);

  useEffect(() => {
    dispatch(fetchAdminPortalDashboardUrl());
  }, []);

  useEffect(() => {
    if (url && url !== previousUrl) {
      const dashboardInstance = embedDashboard({
        url,
        container: document.getElementById(theme['analytics-container']),
      });
      dashboardInstance.on('error', () => {
        console.log('error loading dashboard');
        setIsErrorState(true);
      });
      dashboardInstance.on('load', () => {
        console.log('dashboard loaded');
        setIsLoaded(true);
      });
      previousUrl = url;
    }
  }, [url]);

  return (
    <AdminPage title="Analytics">
      {!isErrorState && (
        <main
          id={theme['analytics-container']}
          style={{ display: isLoaded ? 'block' : 'none' }}
        />
      )}
      {!isLoaded && !isErrorState && (
        <div className={theme.LoadingState}>
          <div>
            <div>
              <LoadingSection isLoading={!isLoaded} />
            </div>
            <div>This may take a few minutes.</div>
          </div>
        </div>
      )}
      {isErrorState && (
        <div className={theme.ErrorState} style={{ fontWeight: 'bold' }}>
          There was an error loading the dashboard. Please reload and try again.
        </div>
      )}
    </AdminPage>
  );
};

export default Analytics;
