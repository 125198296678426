export const SHARE_PROPERTY_SUBMIT = 'SHARE_PROPERTY_SUBMIT';
export const SHARE_PROPERTY_SUBMIT_SUCCESS = 'SHARE_PROPERTY_SUBMIT_SUCCESS';
export const SHARE_PROPERTY_SUBMIT_FAILURE = 'SHARE_PROPERTY_SUBMIT_FAILURE';
export const CLEAR_SHARE_PROPERTY = 'CLEAR_SHARE_PROPERTY';

export type SharePropertySubmitAction = {
  type: typeof SHARE_PROPERTY_SUBMIT;
  payload: {
    street: string;
    city: string;
    state: string;
    zip: string;
    unit: string | null;
    address_id: number | null | undefined;
    addressSlug: string;
    emails: string[];
    message: string;
    usersEmail: string;
    usersName: string;
    loId: string | null;
  };
  meta: {
    analytics: {
      event: 'share_email';
    };
    props: {
      emails: string[];
      message: string;
      usersEmail: string;
      usersName: string;
      ch_property_id: string;
    };
  };
};

export type SharePropertySubmitArgs = {
  street: string;
  city: string;
  state: string;
  zip: string;
  unit: string | null;
  address_id: number | null | undefined;
  addressSlug: string;
  emails: string[];
  message: string;
  usersEmail: string;
  usersName: string;
  loId: string | null;
};

export const sharePropertySubmit = ({
  street,
  city,
  state,
  zip,
  unit,
  address_id,
  addressSlug,
  emails,
  message,
  usersEmail,
  usersName,
  loId,
}: SharePropertySubmitArgs): SharePropertySubmitAction => ({
  type: SHARE_PROPERTY_SUBMIT,
  payload: {
    street,
    city,
    state,
    zip,
    unit,
    address_id,
    addressSlug,
    emails,
    message,
    usersEmail,
    usersName,
    loId,
  },
  meta: {
    analytics: {
      event: 'share_email',
    },
    props: {
      emails,
      message,
      usersEmail,
      usersName,
      ch_property_id: addressSlug,
    },
  },
});

export type SharePropertySubmitSuccessAction = {
  type: typeof SHARE_PROPERTY_SUBMIT_SUCCESS;
  payload: {
    slug: string | null;
    street: string;
    city: string;
    state: string;
    zip: string;
    unit: string | null;
    address_id: number | null | undefined;
  };
};

export const sharePropertySubmitSuccess = (
  data: SharePropertySubmitSuccessAction['payload']
): SharePropertySubmitSuccessAction => ({
  type: SHARE_PROPERTY_SUBMIT_SUCCESS,
  payload: {
    slug: data.slug,
    street: data.street,
    city: data.city,
    state: data.state,
    zip: data.zip,
    unit: data.unit,
    address_id: data.address_id,
  },
});

export type SharePropertySubmitFailureAction = {
  type: typeof SHARE_PROPERTY_SUBMIT_FAILURE;
  payload: {
    error: string | null;
  };
};

export const sharePropertySubmitFailure = (
  error: string
): SharePropertySubmitFailureAction => ({
  type: SHARE_PROPERTY_SUBMIT_FAILURE,
  payload: {
    error,
  },
});

export type ClearSharePropertyAction = {
  type: typeof CLEAR_SHARE_PROPERTY;
};

export const clearShareProperty = (): ClearSharePropertyAction => ({
  type: CLEAR_SHARE_PROPERTY,
});

export type SharePropertyAction =
  | SharePropertySubmitSuccessAction
  | SharePropertySubmitFailureAction
  | ClearSharePropertyAction;
