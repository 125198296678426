import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import React from 'react';
import classNames from 'classnames';
import defaultTheme from '@client/css-modules/DesktopNavItem.css';

import { View } from '@client/routes/constants';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';

type Props = {
  dataHcName?: string;
  ariaLabel?: string;
  view: View | View[] | null;
  currentView: View | null;
  theme: Theme;
  onClick: () => void;
  activeColor: string;
  underlineWhenSelected?: boolean;
  hasActiveColorWhenHovered?: boolean;
  dataTooltipAnchorId?: string;
  dataEventName?: string;
  dataParentEventName?: string;
  dataEventDataJson?: Object;
  children?: React.ReactNode;
};

/**
 * A nav item used in the desktop header
 */
const DesktopNavItem = ({
  ariaLabel,
  dataHcName,
  view,
  currentView,
  theme,
  onClick,
  activeColor,
  children,
  underlineWhenSelected,
  hasActiveColorWhenHovered,
  dataTooltipAnchorId,
  dataEventName,
  dataParentEventName,
  dataEventDataJson,
}: Props) => {
  const isSelected = Array.isArray(view)
    ? !!(currentView && view.indexOf(currentView) > -1)
    : currentView === view;

  return (
    <button
      data-hc-name={dataHcName}
      data-event-name={dataEventName}
      data-parent-event-name={dataParentEventName}
      data-event-data-json={JSON.stringify(dataEventDataJson)}
      data-tooltip-anchor-id={dataTooltipAnchorId}
      role="link"
      aria-label={ariaLabel}
      onKeyDown={onEnterOrSpaceKey(onClick)}
      onClick={onClick}
      className={classNames(theme.DesktopNavItem, {
        [theme.DesktopNavItemSelected || '']: isSelected,
      })}
      style={{ ...(isSelected ? { color: activeColor } : {}) }}
    >
      {children}
      {underlineWhenSelected && isSelected && (
        <div
          className={theme.DesktopNavItemUnderline}
          style={{ background: activeColor }}
        />
      )}
      {hasActiveColorWhenHovered && (
        <style
          dangerouslySetInnerHTML={{
            __html: `
            .${theme.DesktopNavItem}:hover {
              color: ${activeColor} !important;
            }
          `,
          }}
        />
      )}
    </button>
  );
};

const ThemedDesktopNavItem = themr(
  'DesktopNavItem',
  defaultTheme
)(DesktopNavItem);
export default ThemedDesktopNavItem;
