import React from 'react';
import { useSelector } from 'react-redux';
import { themr } from '@friendsofreactjs/react-css-themr';

import PhoneCallIcon from '@client/inline-svgs/phonecall-icon';
import { formatPhoneNumber } from '@client/utils/string.utils';
import { isPhoneNumberStandard } from '@client/utils/validations.forms';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import { GenericLOInfoProps } from '@client/store/types/cobranded-components/loan-officer-components';
import defaultTheme from '@client/css-modules/GenericLOInfo.css';
import { getCustomizationData } from '@client/store/selectors/cobranding.selectors';
import { useLoInfoProps } from '@client/hooks/lo-info-provider.hooks';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { EVENTS, PARENT_EVENTS } from '@client/store/analytics-constants';

const GenericLOInfo: React.FC<GenericLOInfoProps> = ({
  theme,
  hidePhoneAndEmail,
  shouldUseDotSeparator,
  shouldHidePhoneCallButtonOnMobile,
  shouldDisplayNmlsIdOnSecondLine,
  shouldDisplaySubTitleSameLineWithTitle,
}) => {
  const isMobile = useSelector(getIsSmallSize);
  const { lo_generic_info_subtitle } = useSelector(getCustomizationData);
  const { genericLoData } = useLoInfoProps();
  const { agentContactPhoneIconColor } = useCobrandStyles();

  if (!genericLoData) {
    return null;
  } else {
    const { name, email, phone, nmlsId } = genericLoData;
    const shouldUsePhoneCallButton =
      isMobile &&
      isPhoneNumberStandard(phone) &&
      !shouldHidePhoneCallButtonOnMobile;

    return (
      <section className={theme.GenericLOInfo}>
        <div className={theme.ContactContent}>
          <div className={theme.TopSection}>
            <div className={theme.AgentName}>{name}</div>
            {lo_generic_info_subtitle && (
              <div className={theme.AgentSubtitle}>
                {shouldDisplaySubTitleSameLineWithTitle && <span>&nbsp;</span>}
                {lo_generic_info_subtitle}
              </div>
            )}
            {shouldUseDotSeparator && !shouldDisplayNmlsIdOnSecondLine && (
              <div className={theme.DotSeparator} aria-hidden="true">
                {' '}
                •{' '}
              </div>
            )}
            {nmlsId && !shouldDisplayNmlsIdOnSecondLine && (
              <div className={theme.mlsId}>{nmlsId}</div>
            )}
          </div>
          <div className={theme.BottomSection}>
            {nmlsId && shouldDisplayNmlsIdOnSecondLine && (
              <div className={theme.mlsId}>
                {nmlsId}{' '}
                {shouldUseDotSeparator && (
                  <span className={theme.DotSeparator} aria-hidden="true">
                    {' '}
                    •{' '}
                  </span>
                )}
              </div>
            )}

            <div className={theme.PhoneAndEmail}>
              {!shouldUsePhoneCallButton && phone && !hidePhoneAndEmail && (
                <a
                  data-event-name={EVENTS.CLICK_LO_CONTACT_CALL}
                  data-parent-event-name={PARENT_EVENTS.CLICK_LO_CONTACT}
                  href={`tel:${phone}`}
                  title={`Call ${name}`}
                  target="_blank"
                >
                  {formatPhoneNumber(phone)}
                </a>
              )}
              {!shouldUsePhoneCallButton &&
                phone &&
                email &&
                !hidePhoneAndEmail && (
                  <span className={theme.DotSeparator} aria-hidden="true">
                    {' '}
                    •{' '}
                  </span>
                )}
              {email && !hidePhoneAndEmail && (
                <a
                  data-event-name={EVENTS.CLICK_LO_CONTACT_EMAIL}
                  data-parent-event-name={PARENT_EVENTS.CLICK_LO_CONTACT}
                  href={`mailto:${email}`}
                  title={`Send an email to ${name}`}
                  target="_blank"
                >
                  {email}
                </a>
              )}
            </div>
          </div>
        </div>
        {shouldUsePhoneCallButton && phone && (
          <div>
            <a
              style={{
                color: agentContactPhoneIconColor,
              }}
              data-event-name={EVENTS.CLICK_LO_CONTACT_CALL}
              data-parent-event-name={PARENT_EVENTS.CLICK_LO_CONTACT}
              className={theme.PhoneCallLink}
              href={`tel:${phone}`}
              title={`Call ${name}`}
              target="_blank"
            >
              <PhoneCallIcon className={theme.PhoneCallIcon} />
            </a>
          </div>
        )}
      </section>
    );
  }
};

const ThemedGenericLOInfo = themr('GenericLOInfo', defaultTheme)(GenericLOInfo);
export default ThemedGenericLOInfo;
