import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import { NWMLS_ID } from '@client/store/constants';
import { getMLSIdFromPropertyDetails } from '@client/utils/property.utils';
import { CommonPropertyDetails } from '@client/store/types/property';
import { View } from '@client/routes/constants';

type Props = {
  propertyDetails: CommonPropertyDetails;
  className?: string;
};

const MLSAttribution: React.FC<Props> = ({ propertyDetails, className }) => {
  const mlsId = getMLSIdFromPropertyDetails(propertyDetails);
  const listingOfficeName =
    get(propertyDetails, 'listingOfficeName') ||
    get(propertyDetails, ['latestListing', 'listingOfficeName']);
  const isOnPDPView = useSelector(getCurrentView) === View.PROPERTY_DETAILS;

  /* the NWMLS exception to show brokerage info on pdp page */
  const verbiage =
    mlsId === NWMLS_ID && !isOnPDPView
      ? 'Listing provided by NWMLS'
      : listingOfficeName
      ? `Courtesy of ${listingOfficeName}`
      : null;

  return verbiage ? (
    className ? (
      <div className={className}>{verbiage}</div>
    ) : (
      <span title={verbiage}>{verbiage}</span>
    )
  ) : null;
};

export default MLSAttribution;
