import { PARENT_EVENTS } from '@client/store/analytics-constants';

export const REPORT_VISIT = 'REPORT_VISIT';

export type ReportVisitAction = {
  type: typeof REPORT_VISIT;
  meta: {
    analytics: {
      event: string;
      parentEvent: string;
    };
  };
};

export const reportVisit = (): ReportVisitAction => ({
  type: REPORT_VISIT,
  meta: {
    analytics: {
      event: 'visit',
      parentEvent: PARENT_EVENTS.EVENT_STREAM,
    },
  },
});

export type TrackingAction = ReportVisitAction;
