import { RegressionType } from '@client/store/constants';
import MapAvmDeepDiveLocationContainer from '@client/containers/map-avm-deep-dive-location.container';
import PieChart from '@client/components/generic/PieChart';
import {
  PropertyValuesWithRegressionTypes,
  RegressionData,
} from '@client/store/types/regression-data';
import { Theme } from '@friendsofreactjs/react-css-themr';
import loadable, { LoadableClassComponent } from '@loadable/component';
import React from 'react';

const RegressionLineChart = loadable(
  () =>
    import(/* webpackPrefetch: true */ '@client/components/RegressionLineChart')
);

const REGRESSION_CONTAINERS_BY_TYPE: Record<
  RegressionType,
  | typeof MapAvmDeepDiveLocationContainer
  | LoadableClassComponent<any>
  | typeof PieChart
> = {
  [RegressionType.LOCATION]: MapAvmDeepDiveLocationContainer,
  [RegressionType.LIVINGAREA]: RegressionLineChart,
  [RegressionType.LOTSIZE]: RegressionLineChart,
  [RegressionType.AGE]: RegressionLineChart,
  [RegressionType.BEDROOMS]: RegressionLineChart,
  [RegressionType.BATHROOMS]: RegressionLineChart,
  [RegressionType.POOL]: PieChart,
  [RegressionType.BASEMENT]: PieChart,
};

const REGRESSION_ARIA_LABEL_BY_TYPE: Record<RegressionType, string> = {
  [RegressionType.LOCATION]: 'Pricing trend map',
  [RegressionType.LIVINGAREA]: 'Living area comparison',
  [RegressionType.LOTSIZE]: 'Lot size comparison',
  [RegressionType.AGE]: 'Age of property comparison',
  [RegressionType.BEDROOMS]: 'Number of bedrooms comparison',
  [RegressionType.BATHROOMS]: 'Number of bathrooms comparison',
  [RegressionType.POOL]: 'Pool comparison',
  [RegressionType.BASEMENT]: 'Basement comparison',
};

type Props = {
  count: number | undefined;
  factor: null | RegressionData;
  showDataSummaryForSmallScreens?: boolean;
  propertyValues: PropertyValuesWithRegressionTypes;
  theme: Theme;
};

function AvmDeepDiveComponent({
  count,
  factor,
  showDataSummaryForSmallScreens,
  propertyValues,
  theme,
}: Props) {
  if (!factor) {
    return null;
  }
  const factorType = factor.type.toUpperCase() as RegressionType;
  const DeepDiveComponent = REGRESSION_CONTAINERS_BY_TYPE[factorType];
  return (
    <DeepDiveComponent
      count={count}
      data={factor}
      showDataSummaryForSmallScreens={showDataSummaryForSmallScreens}
      propertyValues={propertyValues}
      theme={theme}
      ariaLabel={REGRESSION_ARIA_LABEL_BY_TYPE[factorType]}
    />
  );
}

export default AvmDeepDiveComponent;
