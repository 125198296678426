import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import React, { useState } from 'react';

import EyeHiddenIcon from '@client/assets/images/eye_hidden.svg';
import EyeVisibleIcon from '@client/assets/images/eye_visible.svg';
import CobrandedStyles from '@client/components/CobrandedStyles';
import defaultTheme from '@client/css-modules/TextInput.css';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import { ActiveAddressSearchLabelPosition } from '@client/store/types/cobranding';

export type TextInputPropsInternal = {
  activeAddressSearchLabelPosition?: ActiveAddressSearchLabelPosition;
  labelStyle?: React.CSSProperties;
  disabled?: boolean;
  label: string | JSX.Element;
  required?: boolean;
  type?: 'text' | 'email' | 'password' | 'tel' | 'search';
  theme: Theme;
  value?: string | number | string[] | undefined;
  className?: string;
  role?: string;
  ariaLabel?: string;
  ariaDescribedby?: string;
  name?: string;
  autoComplete?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent) => void;
  placeholder?: string;
  tabIndex?: number;
  error?: string | JSX.Element | null;
  offScreenLabel?: boolean;
  maxLength?: number;
  readOnly?: boolean;
  errorAsValue?: string;
  style?: React.CSSProperties;
  accessibilityFocusColorOverride?: string;
  // wrappedUID is used in place of the "uid" passed down from the local AccessibleElementUniqueId
  // when TextInput is wrapped within another AccessibleElementUniqueId. Basically we want to use the "uid"
  // from the top level AccessibleElementUniqueId
  wrappedUID?: string;
  onMouseDown?: () => void;
  hasVisibleLabel?: boolean;
  forwardedRef?: React.RefObject<HTMLInputElement>;
  dataHcName?: string;
  formatter?: (string) => string;
};

/* For the TextInput component, `theme` will ALWAYS be passed since it's wrapped by `themr`. However,
 * when importing the type for use in other components, we want to label `theme` as optional, as it only
 * needs to be passed if we want to override the default theme */
export interface TextInputProps
  extends Omit<TextInputPropsInternal, 'theme'>,
    Partial<Pick<TextInputPropsInternal, 'theme'>> {
  theme?: Theme;
}

const TextInput = (props: TextInputPropsInternal) => {
  const [showAccessibilityBorder, setShowAccessibilityBorder] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    setShowAccessibilityBorder(false);
    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
    const isFocusAllowed =
      document.body.className.indexOf('no-focus-outline') < 0;
    setShowAccessibilityBorder(isFocusAllowed);
    if (props.onFocus) {
      props.onFocus(e);
    }
  };

  const {
    dataHcName,
    forwardedRef,
    onFocus,
    onBlur,
    activeAddressSearchLabelPosition,
    error,
    value,
    theme,
    label,
    errorAsValue,
    offScreenLabel,
    className,
    maxLength,
    style,
    accessibilityFocusColorOverride,
    ariaLabel,
    ariaDescribedby,
    wrappedUID,
    hasVisibleLabel,
    type,
    labelStyle,
    ...rest
  } = props;

  return (
    <CobrandedStyles>
      {({ accessibilityFocusColor }) => (
        <AccessibleElementUniqueId>
          {({ uid }: { uid: string }) => (
            <div
              className={classNames(theme.InputWrapper, className)}
              data-hc-name={dataHcName}
            >
              <div className={theme.InputElementContainer}>
                <input
                  data-hc-name={dataHcName ? `${dataHcName}-input` : undefined}
                  style={{
                    ...style,
                    ...(offScreenLabel ? { marginTop: 0 } : {}),
                    ...(showAccessibilityBorder
                      ? {
                          borderWidth: '2px',
                          borderColor: accessibilityFocusColorOverride
                            ? accessibilityFocusColorOverride
                            : accessibilityFocusColor,
                        }
                      : {}),
                  }}
                  id={wrappedUID || uid}
                  className={classNames(theme.InputElement, {
                    [theme.InputWithError]: errorAsValue,
                    [theme.InputWithVisibleLabel]: hasVisibleLabel,
                  })}
                  value={errorAsValue || value}
                  aria-label={ariaLabel}
                  aria-describedby={ariaDescribedby}
                  ref={forwardedRef}
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                  maxLength={maxLength}
                  type={
                    type === 'password' && isPasswordVisible ? 'text' : type
                  }
                  {...rest}
                />
                <label
                  htmlFor={wrappedUID || uid}
                  id={`${wrappedUID || uid}-label`}
                  className={classNames(theme.Label, {
                    [theme.OffScreenLabel]: offScreenLabel,
                    [theme.HasVisibleLabel]: hasVisibleLabel,
                  })}
                  style={{
                    ...activeAddressSearchLabelPosition,
                    ...labelStyle,
                  }}
                >
                  {label}
                </label>
                {type === 'password' && (
                  <button
                    className={theme.TogglePasswordVisibilityButton}
                    type="button"
                    data-hc-name="toggle-password-visibility-button"
                    aria-controls={wrappedUID || uid}
                    aria-pressed={isPasswordVisible}
                    aria-label={`Click to ${
                      isPasswordVisible ? 'hide' : 'show'
                    } password`}
                    onClick={() => {
                      setIsPasswordVisible(!isPasswordVisible);
                    }}
                  >
                    <img
                      src={isPasswordVisible ? EyeHiddenIcon : EyeVisibleIcon}
                      alt=""
                    />
                  </button>
                )}
              </div>
              {!errorAsValue && error && (
                <div
                  className={theme.Error}
                  data-hc-name={'error-message'}
                  data-testid={'error-message'}
                >
                  {error}
                </div>
              )}
            </div>
          )}
        </AccessibleElementUniqueId>
      )}
    </CobrandedStyles>
  );
};

const ThemedTextInput = themr('TextInput', defaultTheme)(TextInput);
export default ThemedTextInput;
