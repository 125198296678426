import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import React, { useState } from 'react';

import { stopEvent, key } from '@client/utils/component.utils';
import defaultTheme from '@client/css-modules/SimpleTooltip.css';
import InfoIconCobranded from '@client/components/InfoIcon/InfoIconCobranded';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import { useAriaAnnouncer } from '@client/context/aria-announcer';

type Props = {
  /* Text content or a component to be displayed within the tooltip */
  content: JSX.Element | string;
  /* Text content or a component that when hovered or clicked, will cause the tooltip
   * to display */
  trigger?: JSX.Element | string;
  className?: string;
  dataHcName?: string;
  triggerAriaLabel?: string;
  triggerAriaDescribedBy?: string;
  width?: number;
  theme: Theme;
  backgroundColor?: string;
};

/* A simple tooltip that renders inline (not in a portal) above the trigger element when hovering/clicking/
 * focusing.  Using this over Tooltip.tsx is preferable when needing nested tooltips or when needing to
 * extensively customize the style of the tooltip.  Note that this tooltip is NOT setup for dynamically sizing
 * itself to fit inside the viewport, auto scrolling to fit inside the viewport, etc.  Use Tooltip.tsx for that.
 */
const SimpleTooltip: React.FC<Props> = ({
  content,
  trigger,
  triggerAriaLabel,
  triggerAriaDescribedBy,
  width,
  theme,
  backgroundColor,
}) => {
  const [isShowingTooltip, setIsShowingTooltip] = useState(false);

  const announcer = useAriaAnnouncer();

  const handleShowTooltip = () => {
    setIsShowingTooltip(true);
    if (announcer) {
      announcer(content);
    }
    

  };
  const handleHideTooltip = () => {
    setIsShowingTooltip(false);
    if (announcer) {
      announcer('');
    }
    
  };
  const handleToggleTooltip = () => {
    if (isShowingTooltip) {
      handleHideTooltip();
    } else {
      handleShowTooltip();
    }
  };
  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (key.isReturn(e.key) || key.isSpace(e.key)) {
      stopEvent(e);
      handleToggleTooltip();
    } else if (key.isEscape(e.key)) {
      stopEvent(e);
      handleHideTooltip();
    }
  };

 

  const effectiveWidth = width || 200;

  return (
    <div className={theme.Tooltip}>
      <AccessibleElementUniqueId>
        {({ uid }) => (
          <>
            <button
              id={`trigger-${uid}`}
              type="button"
              className={theme.Trigger}
              onKeyDown={handleOnKeyDown}
              onClick={handleToggleTooltip}
              aria-label={
                triggerAriaLabel
                  ? triggerAriaLabel
                  : trigger
                  ? undefined
                  : 'More Info'
              }
              aria-expanded={isShowingTooltip}
              aria-describedby={triggerAriaDescribedBy || undefined}
              aria-controls={`tooltip-${uid}`}
              onMouseEnter={handleShowTooltip}
              onMouseLeave={handleHideTooltip}
              onBlur={handleHideTooltip}
            >
              {trigger || <InfoIconCobranded className={theme.InfoIcon} />}
            </button>
            <div
              role="tooltip"
              id={`tooltip-${uid}`}
              aria-labelledby={`trigger-${uid}`}
            >
              {isShowingTooltip && (
                <div
                  className={theme.TooltipContentWrapper}
                  style={{
                    marginLeft: `${-(effectiveWidth / 2)}px`,
                    width: effectiveWidth,
                  }}
                >
                  <div
                    className={theme.TooltipContent}
                    style={{ backgroundColor }}
                  >
                    {content}
                    <div className={theme.TooltipArrow}>
                      <div
                        aria-hidden="true"
                        className={theme.ArrowText}
                        style={
                          backgroundColor
                            ? {
                                color: backgroundColor,
                                textShadow: `0 -2px 0 ${backgroundColor}`,
                              }
                            : {}
                        }
                      >
                        ▼
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </AccessibleElementUniqueId>
    </div>
  );
};

const ThemedSimpleTooltip = themr(
  'ThemedSimpleTooltip',
  defaultTheme
)(SimpleTooltip);
export default ThemedSimpleTooltip;
