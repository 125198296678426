import classNames from 'classnames';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import { useSelector } from 'react-redux';

import TextButton from '@client/components/generic/TextButton';
import defaultTheme from '@client/css-modules/BrokerageAttribution.css';
import ClickableLogo from '@client/components/ClickableLogo';
import { getIsInsideNativeApp } from '@client/store/selectors/cobranding.selectors';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import PoweredByLogoCobranded from '@client/components/PoweredByLogo/PoweredByLogoCobranded';

export type BrokerageAttributionProps = {
  theme: Theme;
  openAttributionModal: () => void;
  /* Instead of using media queries in the CSS we are passing this from the container
   * based on the size of the viewport. This is because Homebuyer uses a hybrid of
   * mobile and desktop views for Tablet sizing, so we can't just use media queries across
   * the whole site */
  isVerticalAlignment: boolean;
  hideBrokerageSection: boolean;
  /* Removes logo and aligns right */
  isMinimalStyling?: boolean;
  redirectToBrokerageInfo?: () => void;
  /* For analytics reporting */
  slug?: string | null;
};

function BrokerageAttribution({
  theme,
  openAttributionModal,
  isVerticalAlignment,
  isMinimalStyling,
  redirectToBrokerageInfo,
  hideBrokerageSection,
  slug,
}: BrokerageAttributionProps) {
  function handleClickContactLink() {
    if (redirectToBrokerageInfo) {
      redirectToBrokerageInfo();
    } else {
      openAttributionModal();
    }
  }

  const isInsideNativeApp = useSelector(getIsInsideNativeApp);
  const { propertyBrokerageLinkColor } = useCobrandStyles();

  return (
    <>
      <div
        className={classNames(theme.BrokerageAttribution, {
          [theme.VerticalAlignment]: isVerticalAlignment && !isMinimalStyling,
          [theme.MinimalStyling]: isMinimalStyling,
        })}
      >
        {!isMinimalStyling && (
          <div className={theme.LogoSection}>
            <ClickableLogo
              className={theme.ComeHomeLogo}
              dataHcName={'comehome-logo'}
              Logo={PoweredByLogoCobranded}
              theme={theme}
              alwaysUseRouteChange={isInsideNativeApp}
            />
          </div>
        )}
        {!hideBrokerageSection && (
          <div className={theme.BrokerageSection}>
            <TextButton
              style={{
                color: propertyBrokerageLinkColor ?? '#0B6EFD',
              }}
              theme={theme}
              className={theme.Link}
              onClick={handleClickContactLink}
            >
              Contact
            </TextButton>{' '}
            HouseCanary, Inc. Brokerage
          </div>
        )}
      </div>
    </>
  );
}

export default themr(
  'ThemedBrokerageAttribution',
  defaultTheme
)(BrokerageAttribution);
