import classNames from 'classnames';
import React, { Component } from 'react';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';

import { parseCSSAmount } from '@client/utils/string.utils';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import defaultTheme from '@client/css-modules/HorizontalToggle.css';

type Props = {
  ariaLabel?: string;
  ariaLabelledBy?: string;
  dataHcName?: string;
  offState: boolean;
  /** Options for the toggle. Format: { label: <string>, value: <boolean> }  */
  options: { label: string; name?: string; value: boolean }[];
  /** Callback executed on select, with the selected option's value as the argument */
  handleSelect: (value: boolean) => void;
  /** The value that should be shown as selected */
  selectedValue: boolean;
  /** A multiplier that determines the width of each selection */
  widthMultiplier: number;
  /** Offset position left for selectedMask, to achieve specific styling for settings page */
  selectedMaskOffset: number;
  id?: string;
  toggleButtonStyle: {};
  theme: Theme;
};

const checkIfValidProps = (props: Props): Error | undefined => {
  const options = props.options;
  if (
    !options ||
    options.constructor !== Array ||
    !options.map((item) => item.value).join('')
  ) {
    return new Error(
      `${options} must be of type Array, contain at least one item, and items must contain a 'value' prop`
    );
  } else {
    return;
  }
};

/**
 * NOTE: Pulled from the Component Library and slightly tweaked so that it would
 * look like the mocks for our App. TODO: make this more flexible and push up to
 * Component Lib so that we can easily style and share on apps that don't have the exact styles.
 *
 * Displays a simple horizontal toggle control.  The consuming app is responsible for
 * implementing a `handleSelect` callback and passing the selected value back as
 * `selectedValue`, which will cause the selected value in this component to change.
 */
class HorizontalToggle extends Component<Props> {
  maskRef: React.RefObject<HTMLDivElement> = React.createRef();

  componentDidMount() {
    checkIfValidProps(this.props);
  }

  render() {
    const {
      dataHcName,
      selectedValue = this.props.options[0].value,
      offState,
      options,
      handleSelect,
      selectedMaskOffset,
      theme,
      widthMultiplier,
      ariaLabel,
      ariaLabelledBy,
      id,
      toggleButtonStyle,
      ...rest
    } = this.props;

    /* Get from CSS exported variable...
    TODO: this needs to be dynamically pulled from a DOM node, or passed as a
    prop - it stops the toggle from being able to be themed. */

    const OPTION_TOTAL_PADDING = parseCSSAmount(theme.optionPadding) * 2;

    let selectedIdx = 0;
    const width =
      Math.max(
        ...options.map((o, i) => {
          if (selectedValue === o.value) {
            selectedIdx = i;
          }
          return o.label.length;
        })
      ) * widthMultiplier;
    const selectedMaskLeftOffset = selectedIdx
      ? -selectedMaskOffset
      : selectedMaskOffset;

    return (
      <button
        id={id ? id : 'horizontal-toggle'}
        role="switch"
        aria-label={`${ariaLabel} Toggle`}
        aria-labelledby={ariaLabelledBy}
        aria-checked={selectedValue}
        data-hc-name={dataHcName ? dataHcName : 'horizontal-toggle'}
        className={classNames(theme.HorizontalToggle, undefined, {
          [theme.OffState]: offState,
        })}
        onKeyDown={(e) =>
          onEnterOrSpaceKey(() => handleSelect(!selectedValue))(e)
        }
        onClick={() => handleSelect(!selectedValue)}
        style={toggleButtonStyle}
        {...rest}
      >
        {options.map((option) => (
          <div
            key={`toggle-${option.value}`}
            data-hc-name="horizontal-toggle-option"
            style={{ width: `${width}px` }}
            className={classNames(theme.Option, {
              [theme.Selected]: selectedValue === option.value,
            })}
          >
            {option.label || option.value}
          </div>
        ))}
        <div
          data-hc-name="horizontal-toggle-selected-mask"
          className={theme.SelectedMask}
          ref={this.maskRef}
          style={{
            width: `${width}px`,
            left: `${
              width * selectedIdx +
              OPTION_TOTAL_PADDING * selectedIdx +
              selectedMaskLeftOffset
            }px`,
          }}
        />
      </button>
    );
  }
}

export default themr('ThemedHorizontalToggle', defaultTheme)(HorizontalToggle);
