import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import selectorDividerTheme from '@client/css-modules/SelectorDivider.css';

type Props = {
  theme: Theme;
};

export const SelectorDivider: React.FC<Props> = ({ theme }) => {
  return <span className={theme.SelectorDivider} />;
};

export const ThemedSelectorDivider = themr(
  'SelectorDivider',
  selectorDividerTheme
)(SelectorDivider);

export default ThemedSelectorDivider;
