import classNames from 'classnames';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';

import { BasePill } from '@client/components/generic/BasePill';
import { dollarsFormatter } from '@client/utils/formatter.utils';
import { PROPERTY_STATUSES } from '@client/store/constants';
import defaultTheme from '@client/css-modules/ListingStatusPillMultiUnit.css';
import { ListingStatus } from '@client/store/sagas/queries/types';

type PillProps = {
  theme: Theme;
  className: string;
  price?: number | null;
  children?: React.ReactNode;
};
const ActivePill = ({ theme, className, price }: PillProps) => (
  <BasePill
    theme={theme}
    className={classNames(theme.ListingStatusPillMultiUnit, className)}
  >
    <div className={theme.Status}>For Sale</div>
    {!!price && <div className={theme.Price}>{dollarsFormatter(price)}</div>}
  </BasePill>
);

const SoldPill = ({ theme, className, price }: PillProps) => (
  <BasePill
    theme={theme}
    className={classNames(theme.ListingStatusPillMultiUnit, className)}
  >
    <div className={theme.Status}>Recently Sold</div>
    {!!price && <div className={theme.Price}>{dollarsFormatter(price)}</div>}
  </BasePill>
);

const GenericPill = ({ theme, className, children }: PillProps) => (
  <BasePill
    theme={theme}
    className={classNames(theme.ListingStatusPillMultiUnit, className)}
  >
    <div className={theme.Status}>{children}</div>
  </BasePill>
);

type ListingStatusPillMultiUnitProps = {
  listingStatus: ListingStatus | null;
  theme: Theme;
  price: number | null;
};
const ListingStatusPillMultiUnit = ({
  listingStatus,
  price,
  theme,
}: ListingStatusPillMultiUnitProps) => {
  switch (listingStatus) {
    case PROPERTY_STATUSES.ACTIVE:
      return (
        <ActivePill theme={theme} className={theme.Active} price={price} />
      );
    case PROPERTY_STATUSES.PENDING:
      return (
        <GenericPill theme={theme} className={theme.Pending}>
          Pending
        </GenericPill>
      );
    case PROPERTY_STATUSES.CONTINGENT:
      return (
        <GenericPill theme={theme} className={theme.Contingent}>
          Under Contract
        </GenericPill>
      );
    case PROPERTY_STATUSES.WITHDRAWN:
      return (
        <GenericPill theme={theme} className={theme.Withdrawn}>
          Withdrawn
        </GenericPill>
      );
    case PROPERTY_STATUSES.SOLD:
      return <SoldPill theme={theme} className={theme.Sold} price={price} />;
    default:
      /* Hiding Off Market label for now */
      /* return <GenericPill theme={theme} className={theme.OffMarket}>Off Market</GenericPill>; */
      return <div />;
  }
};

const ThemedListingStatusPillMultiUnit = themr(
  'ListingStatusPillMultiUnit',
  defaultTheme
)(ListingStatusPillMultiUnit);
export default ThemedListingStatusPillMultiUnit;
