import { connect } from 'react-redux';

import {
  getIsConfirmEmailBannerOpen,
  getIsUpdateEmailBannerOpen,
} from '@client/store/selectors/app-banner.selectors';
import renderOnMountUntyped from '@client/hocs/render-on-mount-untyped';
import AppBanner from '@client/components/AppBanner';

const mapStateToProps = (state) => {
  return {
    isShowingUpdateEmailBanner: getIsUpdateEmailBannerOpen(state),
    isShowingConfirmEmailBanner: !!getIsConfirmEmailBannerOpen(state),
  };
};

export default renderOnMountUntyped(connect(mapStateToProps, {})(AppBanner));
