import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import AvmBreakdownAvmDefinition from './AvmBreakdownAvmDefinition';
import AvmBreakdownAvmDefinitionChase from './AvmBreakdownAvmDefinitionChase';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import { AvmBreakdownAvmDefinitionProps } from '@client/store/types/cobranded-components/avm-breakdown-avm-definition';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return AvmBreakdownAvmDefinitionChase;
    default:
      return AvmBreakdownAvmDefinition;
  }
};

const AvmBreakdownAvmDefinitionCobranded: React.FC<
  AvmBreakdownAvmDefinitionProps
> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default AvmBreakdownAvmDefinitionCobranded;
