import {
  APIWatchListItem,
  WatchListItem,
  FullWatchListItem,
  StubWatchListItem,
  WatchListSortField,
  WatchListSortOrder,
} from '@client/store/types/watchlist';
import {
  FETCH_WATCHLIST,
  FETCH_WATCHLIST_SUCCESS,
  REMOVE_FROM_WATCHLIST,
  FETCH_WATCHLIST_ITEM_ERROR,
  FETCH_WATCHLIST_ITEM_SUCCESS,
  ADD_TO_WATCHLIST,
  WATCHLIST_APPLY_SORT,
} from '@client/store/actions/watchlist.actions';
import { Action } from '@client/store/actions';
import { STATUSES } from '@client/store/constants';
import { normalizePropertyData } from '@client/utils/property.utils';
import { Status } from '@client/store/types/statuses';

export type WatchListState = {
  items: WatchListItem[];
  status: Status;
  errorMessage?: string | null;
  propertySort: {
    sortField: WatchListSortField | null;
    sortOrder: WatchListSortOrder | null;
  };
};

const INITIAL_STATE: WatchListState = {
  items: [],
  status: STATUSES.INIT,
  errorMessage: null,
  propertySort: {
    sortField: null,
    sortOrder: null,
  },
};

const getIsStubWatchlistItem = (
  watchlistItem: WatchListItem
): watchlistItem is StubWatchListItem => {
  return !!(watchlistItem as StubWatchListItem).isStubItem;
};

const getIsFullWatchListItem = (
  watchlistItem: WatchListItem
): watchlistItem is FullWatchListItem => {
  return !!(
    (watchlistItem as FullWatchListItem).addressId &&
    !(watchlistItem as StubWatchListItem).isStubItem
  );
};

const makeStateItemFromResponseItem = (
  item: APIWatchListItem
): FullWatchListItem => ({
  bookmark: {
    ...item.bookmark,
  },
  href: item.href,
  slug: item.slug,
  addressId: item.address_id,
  status: STATUSES.LOADING,
});

export default function watchlistReducer(
  state = INITIAL_STATE,
  action: Action
): WatchListState {
  /* eslint-disable indent */
  switch (action.type) {
    case ADD_TO_WATCHLIST:
      return {
        ...state,
        items: [
          ...state.items,
          { isStubItem: true, slug: action.payload.slug },
        ],
      };
    case FETCH_WATCHLIST:
      return {
        ...state,
        status: STATUSES.LOADING,
      };
    /* Add newly retrieved items to state but keep stub items without matching response items in state to account for race condition when adding new/fetching existing simultaneously */
    case FETCH_WATCHLIST_SUCCESS:
      const responseItems = action.payload.data;
      let fullItems: FullWatchListItem[] = [];
      let stubItemsNotInResponse: StubWatchListItem[] = [];

      for (let i = 0; i < state.items.length; i++) {
        const stateItem = state.items[i];
        /* Keep all current full state items untouched */
        if (getIsFullWatchListItem(stateItem)) {
          fullItems.push(stateItem);
        }
        /* Replace all stub state items with full items from the new response if they're in the response */
        if (getIsStubWatchlistItem(stateItem)) {
          const matchingResponseItem = responseItems.find(
            (responseItem) => responseItem.slug === stateItem.slug
          );

          if (matchingResponseItem) {
            fullItems.push(makeStateItemFromResponseItem(matchingResponseItem));
          } else {
            stubItemsNotInResponse.push(stateItem);
          }
        }
      }

      for (let i = 0; i < responseItems.length; i++) {
        const responseItem = responseItems[i];
        /* If a response item is not in the current state items list, add it */
        if (
          !state.items.find((stateItem) => stateItem.slug === responseItem.slug)
        ) {
          fullItems.push(makeStateItemFromResponseItem(responseItem));
        }
      }

      return {
        ...state,
        /* Most recently updated items to be displayed first */
        items: [
          ...fullItems.sort((a, b) => {
            return (
              new Date(b.bookmark.updated).getTime() -
              new Date(a.bookmark.updated).getTime()
            );
          }),
          ...stubItemsNotInResponse,
        ],
        status: STATUSES.SUCCESS,
      };
    case REMOVE_FROM_WATCHLIST:
      return {
        ...state,
        items: state.items.filter((item) => item.slug !== action.payload.slug),
      };
    case FETCH_WATCHLIST_ITEM_SUCCESS:
      /* Not using strict equality to find addressId match,
        As it is a number from bookmarks api and property
        graph requires it to be a string
      */
      return {
        ...state,
        items: state.items.map((item) => {
          /* eslint-disable */
          if (
            getIsFullWatchListItem(item) &&
            item.addressId == action.payload.addressId
          ) {
            return {
              ...item,
              status: STATUSES.SUCCESS,
              propertyDetails: normalizePropertyData(action.payload.data),
            };
          } else {
            return item;
          }
        }),
      };
    case FETCH_WATCHLIST_ITEM_ERROR:
      return {
        ...state,
        items: state.items.map((item) => {
          if (
            getIsFullWatchListItem(item) &&
            item.addressId === action.payload.addressId
          ) {
            return {
              ...item,
              status: STATUSES.ERROR,
              errorMessage: action.payload.errorMessage,
            };
          } else {
            return item;
          }
        }),
      };
    case WATCHLIST_APPLY_SORT:
      return {
        ...state,
        propertySort: {
          sortField: action.payload.sortField,
          sortOrder: action.payload.sortOrder,
        },
      };
    default:
      return state;
  }
}
