import theme from '@client/css-modules/AvmBreakdownToolTipTextChase.css';

const AvmBreakdownToolTipTextChase: React.FC<{}> = () => {
  return (
    <div className={theme.AvmBreakdownToolTipTextChase}>
      <div>
        <div className={theme.SubHeading}>Chase Notice</div>
        <div className={theme.ToolTipText}>
          The home value estimate listed is for informational purposes only.
          This home value estimate is not determined by JPMorgan Chase and is
          the sole product of HouseCanary. This home value estimate is not an
          appraisal and will not be used by JPMorgan Chase to make credit
          decisions, including but not limited to, underwriting and loan
          origination.
        </div>
      </div>
      <br />
      <div>
        <div className={theme.SubHeading}>ComeHome Value</div>
        <div className={theme.ToolTipText}>
          This is ComeHome's estimated market value for this home. It is not a
          formal appraisal. This estimate is based on our market knowledge, and
          it should be used as a starting point to determine a home's value.
          <br />
          <br />
          To determine how a home measures up against other typical homes in the
          same area, we first calculate medians for each attribute: the homes
          that are right in the middle of the range of properties in that area.
          After calculating the median values for lot size, square footage, age,
          and bedroom and bathroom count, we then compare the home you're
          evaluating to the medians and share that comparison with you. This is
          how we are able to determine what's typical in an area and what's not,
          and how the home you're viewing measures up against the typical
          features in the area.
        </div>
      </div>
    </div>
  );
};

export default AvmBreakdownToolTipTextChase;
