import React from 'react';
import classNames from 'classnames';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import LinkIcon from '@client/inline-svgs/link-icon';
import TextInput from '@client/components/generic/TextInput';
import FlatButton from '@client/components/generic/FlatButton';
import LoadingSection from '@client/components/generic/LoadingSection';
import defaultTheme from '@client/css-modules/CopyableField.css';
import { PARENT_EVENTS, EVENTS } from '@client/store/analytics-constants';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type CopyableFieldProps = {
  /** The value to display in the text field, string or number */
  value?: string | undefined;
  // Whether the loading spinner should be shown in place of the "copy" button
  // to indicate that the field content is loading
  isLoading: boolean;
  /** Callback method executed after the user copies the link, for reporting purposes */
  afterCopy?: (value: string) => void;
  className?: string;
  theme: Theme;
  'data-hc-name'?: string;
  'data-event-name'?: string;
  'data-parent-event-name'?: string;
};

/**
 * Displays a text field along with a "copy" button, enabling the user to copy the
 * text field content.  Generally, the field value should be loaded externally and
 * supplied to the component for the user to copy without editing.
 */
const CopyableField: React.FC<CopyableFieldProps> = ({
  value = '',
  isLoading = false,
  afterCopy,
  className,
  theme,
  ['data-hc-name']: dataHcName,
  ['data-event-name']: dataEventName,
  ['data-parent-event-name']: dataParentEventName,
}) => {
  const { linkColor } = useCobrandStyles();
  const copyUrlToClipboard = () => {
    if (!value) {
      return;
    }
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(value);
    } else if (document?.execCommand) {
      document.execCommand('copy');
    }

    afterCopy?.(value);
  };

  return (
    <div className={classNames(className, theme.CopyableField)}>
      <TextInput
        theme={theme}
        value={value || ''}
        label="Copy link"
        ariaLabel="Read only copy link"
        offScreenLabel
        readOnly
        data-hc-name={`${dataHcName}-input`}
        data-event-name={`${dataEventName}-input`}
        data-parent-event-name={dataParentEventName}
      />
      <LoadingSection isLoading={isLoading} className={theme.CopyControl}>
        <FlatButton
          className={theme.CopyControlButton}
          key="button"
          label="Copy link"
          aria-label="Copy Link"
          icon={<LinkIcon className={theme.CopyIcon} />}
          onClick={copyUrlToClipboard}
          theme={theme}
          data-hc-name={`${dataHcName}-button`}
          data-event-name={
            dataEventName
              ? `${dataEventName}-button`
              : EVENTS.CLICK_SHARE_COPY_LINK
          }
          data-parent-event-name={
            dataParentEventName
              ? dataParentEventName
              : PARENT_EVENTS.CLICK_SHARE
          }
          labelStyle={{ color: linkColor }}
        />
      </LoadingSection>
    </div>
  );
};

export default themr('ThemedCopyableField', defaultTheme)(CopyableField);
