import { connect } from 'react-redux';
import { ReduxState } from '@client/store/types/redux-state';
import { getPropertyLocation } from '@client/store/selectors/property-details.selectors';
import StreetView from '@client/components/StreetView';

type OwnProps = {
  onFailCallback: () => void;
};

const mapStateToProps = (state: ReduxState, { onFailCallback }: OwnProps) => {
  return {
    propertyLocation: getPropertyLocation(state),
    onFailCallback,
  };
};

export default connect(mapStateToProps)(StreetView);
