import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import CobrandedComponent, {
  CobrandedComponentArgs,
} from '@client/components/CobrandedComponent';
import MobileSearchSave from '@client/inline-svgs/mobile-search-save';
import MobileSearchSuccess from '@client/inline-svgs/mobile-search-success';
import LoadingSpinner from '@client/inline-svgs/loading-spinner';
import theme from '@client/css-modules/SaveSearchButton.css';
import PillButton from '@client/components/generic/PillButton';
import { getSearchViewport } from '@client/store/selectors/search.selectors';
import { getSaveSearchButtonConfig } from '@client/store/selectors/cobranding.selectors';

export type SaveSearchButtonProps = {
  hasSavedCurrentFilter: boolean;
  isSavingCurrentFilter: boolean;
  handleSaveSearch: () => void;
};

const SaveSearchButton = (props: SaveSearchButtonProps) => {
  const mapIsLoading = !useSelector(getSearchViewport);
  const { hasSavedCurrentFilter, isSavingCurrentFilter, handleSaveSearch } =
    props;
  const handleSave = () => {
    if (!mapIsLoading && !hasSavedCurrentFilter) {
      handleSaveSearch();
    }
  };
  const { saveSearchButtonIcon, saveSearchButtonLabel } = useSelector(getSaveSearchButtonConfig)
  const saveSearchText = saveSearchButtonLabel ? `Save ${saveSearchButtonLabel}` : 'Save Search';
  const searchSavedText = `${saveSearchButtonLabel ? saveSearchButtonLabel : 'Search'} saved`;

  return (
    <CobrandedComponent>
      {({
        utils: { getFormattedCTAText },
        styles: {
          saveSearchDesktopButtonColor,
          saveSearchDesktopButtonFontWeight,
        },
      }: CobrandedComponentArgs) => (
        <>
          <PillButton
            className={theme.SaveFilterButton}
            dataHcName={'save-filter-button'}
            ariaLive="polite"
            onClick={handleSave}
            style={{
              background: saveSearchDesktopButtonColor,
              fontWeight: saveSearchDesktopButtonFontWeight,
            }}
          >
            {isSavingCurrentFilter ? (
              <LoadingSpinner
                className={classNames(theme.SaveButtonIcon, theme.Spinner)}
              />
            ) : hasSavedCurrentFilter ? (
              <MobileSearchSuccess className={theme.SaveButtonIcon} />
            ) : (
            <>
              {saveSearchButtonIcon ? (<img src={saveSearchButtonIcon} alt="" className={theme.SaveButtonIcon} />) : ( <MobileSearchSave className={theme.SaveButtonIcon} />)}
            </>
            )}
            {isSavingCurrentFilter ? (
              <div>Saving...</div>
            ) : !hasSavedCurrentFilter ? (
              <div>{getFormattedCTAText(saveSearchText)}</div>
            ) : (
              <div>{searchSavedText}</div>
            )}
          </PillButton>
        </>
      )}
    </CobrandedComponent>
  );
};

export default SaveSearchButton;
