import { call, put, select } from 'redux-saga/effects';
import { routeChange } from '@src/redux-saga-router-plus/actions';

import { PlaceLookupResultWithPlace } from '@client/store/types/place-search';
import { PlaceSearchQuery } from '@client/store/sagas/queries/types';
import { ParamsType, QueryType } from '@src/redux-saga-router-plus/types';
import { View } from '@client/routes/constants';
import { getIsInitialRoute } from '@client/store/selectors/router.selectors';
import { handlePlaceLookupSuccess } from '@client/store/sagas/place-search.saga';
import { graphQLApiClient } from '@client/services/graphql-api-client';

/* Here we're rendering the maps-search page, set to the location of a specific market
 * indicated by the URL params */
export default function* getLatLngCoordsForPlace({
  view,
  params,
  query,
}: {
  view: View;
  params: ParamsType;
  query: QueryType;
}) {
  const { zipcode } = params;
  const isInitialRoute = (yield select(getIsInitialRoute)) as ReturnType<
    typeof getIsInitialRoute
  >;
  const zipArea = (yield call(
    [graphQLApiClient, graphQLApiClient.getPlaceSearchDetails],
    { term: zipcode }
  )) as PlaceSearchQuery;
  const zipPlace = zipArea?.placeSearch?.places?.[0];
  const placeId = zipPlace?.placeId;
  let data: PlaceLookupResultWithPlace | null = null;

  if (placeId) {
    data = (yield call(
      [graphQLApiClient, graphQLApiClient.getPlaceLookupDetails],
      { placeId }
    )) as PlaceLookupResultWithPlace;
  }

  if (!data?.placeLookup?.place?.placeId) {
    yield put(routeChange({ view: View.PAGE_404 }));
  } else {
    const { place } = data.placeLookup;
    const { mlscoverage, placeType, name, locality, region } = place;
    yield call(handlePlaceLookupSuccess, {
      data,
      constrainedToPlace: {
        placeId: place.placeId,
        mlsCoverage: mlscoverage || null,
        city: locality || null,
        state: region || null,
        /* The `name` field contains the zipcode only if `placeType` === 'postalcode' */
        zipcode: placeType === 'postalcode' ? name : null,
      },
      /* If we're loading this route on init, the property list has been set on the server-side,
       * and doesn't need to be reloaded once the map renders */
      shouldResetPropertyList: !isInitialRoute,
    });
  }
}
