import React from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import CloseIcon from '@client/inline-svgs/close';
import theme from '@client/css-modules/DismissibleBanner.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type DismissibleBannerProps = {
  active: boolean;
  theme: Theme;
  //  Function called when the CloseButton is clicked
  onClose: () => void;
  // String passed for QA automation testing
  dataHcName?: string;
  ariaLabelledby: string;
  children?: React.ReactNode;
};

/**
 * A statically positioned bar appearing above the app header that can be dismissed by clicking the 'X' icon
 */
const DismissibleBanner = ({
  active,
  children,
  theme,
  onClose,
  dataHcName,
  ariaLabelledby,
  ...rest
}: DismissibleBannerProps) => {
  const {
    dismissableBannerBackground,
    dismissableBannerTextColor,
    dismissableBannerLinkColor,
  } = useCobrandStyles();

  return active ? (
    <div
      role="dialog"
      key="dissmissible-banner"
      className={theme.DismissibleBanner}
      style={{
        color: dismissableBannerTextColor,
        background: dismissableBannerBackground,
      }}
      data-hc-name={dataHcName}
      aria-labelledby={ariaLabelledby}
      {...rest}
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `
                  .${theme.CloseButton} .element-to-fill {
                    fill: ${dismissableBannerLinkColor};
                  }
                `,
        }}
      />
      <div className={theme.Window}>
        <div className={theme.Content}>
          {children}
          <button
            aria-label="Close banner"
            className={theme.CloseButton}
            onClick={onClose}
          >
            <CloseIcon className={theme.CloseIcon} />
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

const ThemedDismissibleBanner = themr(
  'DismissibleBanner',
  theme
)(DismissibleBanner);
export default ThemedDismissibleBanner;
