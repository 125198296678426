import { PropertyListProperty } from '@client/store/types/property';
import {
  LenderCTAAdCard,
  LoanOfficerAd,
  TopAgentsRankedAd,
  FinanceAd,
  AdCardForCobrand,
} from '@client/store/types/search-ads';

type Card =
  | LenderCTAAdCard
  | LoanOfficerAd
  | FinanceAd
  | TopAgentsRankedAd
  | PropertyListProperty
  | AdCardForCobrand;
/**
 * Used on search page mobile & desktop to differentiate when to render appropriate
 * agent cards instead of propertyCard
 */
export const getIsPropertyCardItem = (
  propertyListItem: Card
): propertyListItem is PropertyListProperty => {
  return (
    !(propertyListItem as LenderCTAAdCard).isLenderCTACard &&
    !(propertyListItem as LoanOfficerAd).isLoanOfficerAd &&
    !(propertyListItem as TopAgentsRankedAd).isTopAgentsRankedAd &&
    !(propertyListItem as FinanceAd).isFinanceAd &&
    !(propertyListItem as AdCardForCobrand).isAdCardForCobrand
  );
};

export const getIsLenderCTACard = (
  propertyListItem: Card
): propertyListItem is LenderCTAAdCard => {
  return (propertyListItem as LenderCTAAdCard).isLenderCTACard;
};

export const getIsTopAgentsRankedAd = (
  propertyListItem: Card
): propertyListItem is TopAgentsRankedAd => {
  return (propertyListItem as TopAgentsRankedAd).isTopAgentsRankedAd;
};

export const getIsLoanOfficerAd = (
  propertyListItem: Card
): propertyListItem is LoanOfficerAd => {
  return (propertyListItem as LoanOfficerAd).isLoanOfficerAd;
};

export const getIsFinanceAd = (
  propertyListItem: Card
): propertyListItem is FinanceAd => {
  return (propertyListItem as FinanceAd).isFinanceAd;
};

export const getIsAdCardForCobrand = (
  propertyListItem: Card
): propertyListItem is AdCardForCobrand => {
  return (propertyListItem as AdCardForCobrand).isAdCardForCobrand;
};
