import React from 'react';
import classNames from 'classnames';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import loadable from '@loadable/component';

import linkTheme from '@client/css-modules/ExternalLink.css';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
const ExternalLinkIcon = loadable(
  () => import('@client/inline-svgs/external-link-icon')
);

type Props = {
  theme: Theme;
  href: string;
  ariaLabel?: string;
  className?: string;
  dataHcName?: string;
  onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;
  style?: React.CSSProperties;
  onMouseEnter?: (e: React.MouseEvent) => void;
  onFocus?: (e: React.FocusEvent) => void;
  onBlur?: (e: React.FocusEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;
  tabIndex?: number;
  target?: string;
  dataEventName?: string;
  dataParentEventName?: string;
  showIcon?: boolean;
  children?: React.ReactNode;
};

/* Set as default on function based props */
const blankFunction = () => void 0;

const ExternalLink = ({
  theme,
  href,
  children,
  onClick = blankFunction,
  ariaLabel,
  className,
  dataHcName,
  style,
  onMouseEnter = blankFunction,
  onMouseLeave = blankFunction,
  onBlur = blankFunction,
  onFocus = blankFunction,
  tabIndex,
  target,
  dataEventName,
  dataParentEventName,
  showIcon,
}: Props) => (
  <a
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onBlur={onBlur}
    onFocus={onFocus}
    style={style}
    aria-label={ariaLabel}
    href={href}
    onClick={onClick}
    onKeyDown={onEnterOrSpaceKey(onClick, true)}
    rel="noopener noreferrer nofollow"
    data-hc-name={dataHcName}
    className={classNames(theme.ExternalLink, {
      [className || '']: !!className,
    })}
    target={target || '_blank'}
    data-event-name={dataEventName}
    data-parent-event-name={dataParentEventName}
  >
    {children || null}
    {showIcon && <ExternalLinkIcon className={theme.ExternalLinkIcon} />}
  </a>
);

export const ThemedExternalLink = themr(
  'ExternalLink',
  linkTheme
)(ExternalLink);
export default ThemedExternalLink;
