import TextInput, {
  TextInputProps,
} from '@client/components/generic/TextInput';
import ValidatorInput, {
  ValidatorProps,
} from '@client/components/generic/ValidatorInput';
import {
  emailValidator,
  getIsRequiredValidator,
} from '@client/services/validator';

const EmailInput = (props: TextInputProps) => (
  <TextInput type="email" {...props} />
);

const ValidatedEmailInput = ValidatorInput<string>(EmailInput);

const EmailField = (
  props: TextInputProps & Omit<ValidatorProps<string>, 'validate'>
) => (
  <ValidatedEmailInput
    dataHcName={'email-control'}
    validate={
      props?.required
        ? [getIsRequiredValidator(emailValidator)]
        : [emailValidator]
    }
    {...props}
  />
);

export default EmailField;
