import loadable from '@loadable/component';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GlobalStyle from '@client/app/GlobalStyle';
import {
  CUSTOM_FONT,
  CUSTOM_FONT_FALLBACK,
} from '@client/cobrand-settings/cobrand-styles';
import LoadingSection from '@client/components/generic/LoadingSection';
import MatchMedia from '@client/components/MatchMedia';
import PageLayoutWrapper from '@client/components/PageLayoutWrapper/PageLayoutWrapper';
import Router from '@client/components/Router';
import theme from '@client/css-modules/AppContainer.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { useNativeAppAuthentication } from '@client/hooks/native-app-integration.hooks';
import { useDispatchActionsAfterSSO } from '@client/hooks/sso-redirect.hooks';
import { View } from '@client/routes/constants';
import {
  eventType,
  ParentEventType,
  reportEvent,
  reportPageView,
} from '@client/store/actions/analytics.actions';
import { authModalShow } from '@client/store/actions/auth.actions';
import { openModal } from '@client/store/actions/modals.actions';
import { fetchUserLocation } from '@client/store/actions/search.actions';
import { applicationDidMount } from '@client/store/actions/server-rendering-info.actions';
import { reportVisit } from '@client/store/actions/tracking.actions';
import { SEARCH_ACTIVE_VIEW_URL_PARAM_KEY } from '@client/store/constants';
import { getIsLoggedIn } from '@client/store/selectors/auth.selectors';
import { getShouldAuthenticateViaNativeAppOnMount } from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { getIsAppContainerFixed } from '@client/store/selectors/global-ui.selectors';
import {
  getShowInviteModalForBuyer,
  getShowInviteModalForSeller,
} from '@client/store/selectors/invite.selectors';
import { getIsFourHundredZoom } from '@client/store/selectors/match-media.selectors';
import { getActiveHomeownerOrPDPSlug } from '@client/store/selectors/router.selectors';
import {
  getIsApplicationMounted,
  getShouldServerSideRender,
} from '@client/store/selectors/server-rendering-info.selectors';
import { getIDSelectorByView } from '@client/utils/analytics.utils';
import { key } from '@client/utils/component.utils';
import { getDataAttrEventNameForDOMEventTarget } from '@client/utils/dom.utils';
import {
  getCurrentQuery,
  getCurrentView,
  getPreviousQuery,
  getPreviousView,
} from '@src/redux-saga-router-plus/selectors';

const Modals = loadable(() => import('@client/components/Modals'));

const useReportPageView = () => {
  const dispatch = useDispatch();
  const currentSlug = useSelector(getActiveHomeownerOrPDPSlug);
  const view = useSelector(getCurrentView);
  const previousView = useSelector(getPreviousView);
  const query = useSelector(getCurrentQuery);
  const previousQuery = useSelector(getPreviousQuery);
  const eventProps = currentSlug
    ? { props: { ch_property_id: currentSlug } }
    : undefined;

  /* Report pageview on mount */
  useEffect(() => {
    dispatch(reportPageView(true, eventProps));
  }, []);

  /* Report pageview on view change */
  useEffect(() => {
    /* Send the pageview event if the view is changing.  This will not be executed on initial page render
     * after app init. */
    if (previousView && previousView !== view) {
      dispatch(reportPageView(false, eventProps));
    }

    /* Special case: search page mobile "map" and "list" views should both be counted as pages for analytics */
    if (
      previousView === View.SEARCH &&
      view === View.SEARCH &&
      previousQuery[SEARCH_ACTIVE_VIEW_URL_PARAM_KEY] !==
        query[SEARCH_ACTIVE_VIEW_URL_PARAM_KEY]
    ) {
      dispatch(reportPageView(false, eventProps));
    }
  }, [dispatch, eventProps, view, previousView, query, previousQuery]);
};

const AppContainer: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const source = useSelector(getCurrentView);
  const sourceId = useSelector(getIDSelectorByView(source || null));
  const showInviteModalForBuyer = useSelector(getShowInviteModalForBuyer);
  const showInviteModalForSeller = useSelector(getShowInviteModalForSeller);
  const isApplicationMounted = useSelector(getIsApplicationMounted);
  const isAppContainerFixed = useSelector(getIsAppContainerFixed);
  const shouldServerSideRender = useSelector(getShouldServerSideRender);
  const authenticateViaNativeAppOnMount = useSelector(
    getShouldAuthenticateViaNativeAppOnMount
  );
  const isUsingSPSamlLogin = useSelector(
    getIsFeatureEnabled('use_sp_saml_login')
  );
  const isChaseNonSPEnabled = useSelector(
    getIsFeatureEnabled('chase_non_sp_enabled')
  );
  const isUserLoggedIn = useSelector(getIsLoggedIn);
  const currentView = useSelector(getCurrentView);
  const isFourHundredPercentZoom = useSelector(getIsFourHundredZoom);
  const fourHundredZoomStyles =
    isFourHundredPercentZoom && currentView === View.SEARCH;
  const dispatchActionsAfterSSO = useDispatchActionsAfterSSO();

  useReportPageView();
  const authenticate = useNativeAppAuthentication();
  const cobrandStyles = useCobrandStyles();

  /**
   * Report any click event where a `data-event-name` is defined on a DOM node or parent DOM node.
   * Separate handlers are defined within pages to capture page-specific event context
   */
  const handleDataAttrEventReporting = (e: MouseEvent | KeyboardEvent) => {
    const { eventName, parentEventName, eventData } =
      getDataAttrEventNameForDOMEventTarget(e);
    const topLevelItems =
      source && sourceId ? { source, source_id: sourceId } : undefined;
    if (eventName) {
      dispatch(
        reportEvent(
          eventName as eventType,
          parentEventName as ParentEventType, // this is a bad solution, but there is no way to do type checking on data attributes of an html element
          eventData || null,
          topLevelItems
        )
      );
    }
  };

  useEffect(() => {
    dispatch(applicationDidMount());
    dispatch(fetchUserLocation());
    dispatch(reportVisit());

    if ((isUsingSPSamlLogin || isChaseNonSPEnabled) && isUserLoggedIn) {
      dispatchActionsAfterSSO();
    }

    /* Authenticate via native app on mount when applicable. Add a slight delay as a fix for Android
     * integrations where the JSBridge is injected into the web context slightly after the app mounts */
    let nativeAppAuthenticateTimeout: ReturnType<typeof setTimeout> | null =
      null;
    if (authenticateViaNativeAppOnMount) {
      nativeAppAuthenticateTimeout = setTimeout(() => {
        authenticate();
      }, 500);
    }

    /** Show invite modals if applicable */
    if (showInviteModalForBuyer) {
      dispatch(openModal('invite-search-agreement-modal'));
    } else if (showInviteModalForSeller) {
      dispatch(authModalShow());
    }

    /**
     * Having an outline for focused elements are required for accessibility.
     * Outlines however can be annoying if someone is not navigating the app using their keyboard.
     * On initial load, 'no-focus-outline' class is applied on the #react-root div which disables all outlines
     * for specific focused elements like button, input, etc. When a user presses the
     * Tab key, the 'no-focus-outline'class is removed and outline will be visible for all focused elements.
     */
    document.body.addEventListener('keydown', (e) => {
      if (key.isTab(e.key)) {
        document.body.classList.remove('no-focus-outline');
      }
    });
    document.body.addEventListener('mousedown', (e) => {
      if (!document.body.classList.contains('no-focus-outline')) {
        document.body.classList.add('no-focus-outline');
      }
    });

    return () => {
      if (nativeAppAuthenticateTimeout) {
        clearTimeout(nativeAppAuthenticateTimeout);
      }
    };
  }, []);

  useEffect(() => {
    window.addEventListener('click', handleDataAttrEventReporting);
    window.addEventListener('keydown', handleDataAttrEventReporting);

    return () => {
      window.removeEventListener('click', handleDataAttrEventReporting);
      window.removeEventListener('keydown', handleDataAttrEventReporting);
    };
    /* ensures that most recent source and sourceId is used for analytics */
  }, [source, sourceId]);

  return (
    <>
      <GlobalStyle
        accessibilityFocusOutlineColor={
          cobrandStyles.accessibilityFocusOutlineColor
        }
        accessibilityFocusOutlineStyle={
          cobrandStyles.accessibilityFocusOutlineStyle
        }
        accessibilityFocusOutlineWidth={
          cobrandStyles.accessibilityFocusOutlineWidth
        }
      />
      <div
        id="app-container"
        style={{
          color: cobrandStyles.defaultTextColor,
          fontFamily: `${CUSTOM_FONT}, ${CUSTOM_FONT_FALLBACK}, sans-serif`,
          height: fourHundredZoomStyles ? 'auto' : '100%',
          position: fourHundredZoomStyles ? 'static' : undefined,
        }}
        className={classNames(theme.AppContainer, {
          [theme.AppContainerFixed]:
            isApplicationMounted && isAppContainerFixed,
        })}
      >
        {!isApplicationMounted && !shouldServerSideRender ? (
          <LoadingSection isLoading />
        ) : (
          <PageLayoutWrapper>
            <Router />
          </PageLayoutWrapper>
        )}
        <MatchMedia />
        <Modals />
      </div>
    </>
  );
};

export default AppContainer;
