import { CustomizationData } from '@client/store/types/cobranding';
import { useCobrandData } from '@client/hooks/cobrand-data.hooks';

export type CobrandedDataComponentArgs = {
  customizationData: CustomizationData;
};

type CobrandedDataChildren = {
  children: (args: CobrandedDataComponentArgs) => JSX.Element | null;
};

/**
 * Component that provides branding Data via a `children` render prop to allow child components
 * to use that info in their declaration
 *
 * Example usage:
 *
 *  <CobrandedDataComponent>
 *    { ({ show_some_button }) => show_some_button && <button /> }
 *  </CobrandedDataComponent>
 *
 */
export default function CobrandedDataComponent({
  children,
}: CobrandedDataChildren) {
  return children({
    customizationData: useCobrandData(),
  });
}
