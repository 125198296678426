/**
 * Keep top-level keys in alpha order please
 */
export default ({ slug }) => ({
  query: /* GraphQL */ `query PropertySchools($slug: String) {
    propertyLookup (id: { slug: $slug}) {
      schools {
        id
      }
    }
  }`,
  variables: {
    slug,
  },
});
