import { Component } from 'react';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';

import ScrollPicker from '@client/components/generic/ScrollPicker';
import {
  FilterKey,
  LABEL_DEFAULTS,
  CONTROL_CUSTOMIZATIONS,
} from '@client/store/filter-constants';
import { getFilterControlCustomization } from '@client/utils/filters.utils';
import defaultTheme from '@client/css-modules/MaxOnlyPicker.css';
import { FilterPickerValue } from '@client/store/types/filters';

export type MaxOnlyPickerProps = {
  theme: Theme;
  filterKey: FilterKey;
  values: FilterPickerValue[];
  setValueForStateFormatter: (value: FilterPickerValue) => FilterPickerValue;
  labelFormatter: (val: FilterPickerValue) => string;
  onChange: (value: [null, FilterPickerValue]) => void;
  handleReportValueSelection: (value: FilterPickerValue) => void;
  currentValues: [null, FilterPickerValue | null];
  height?: number;
  itemHeight?: number;
  getValueForControlFormatter: (currentVal: FilterPickerValue | null) => string;
  ariaLabelledBy: string;
};

type State = {
  max: FilterPickerValue;
};

class MaxOnlyPicker extends Component<MaxOnlyPickerProps, State> {
  defaultText: string = '';
  constructor(props: MaxOnlyPickerProps) {
    super(props);
    const { currentValues, getValueForControlFormatter } = props;
    this.defaultText =
      getFilterControlCustomization(props.filterKey)?.[
        CONTROL_CUSTOMIZATIONS.CUSTOM_NO_MAX_TEXT
      ] ?? LABEL_DEFAULTS.NO_MAX;
    this.state = {
      max: getValueForControlFormatter(currentValues[1]) || this.defaultText,
    };
  }

  handleValueChange = (type: string, value: FilterPickerValue): void => {
    const { onChange, setValueForStateFormatter, handleReportValueSelection } =
      this.props;
    const formattedVal = value && setValueForStateFormatter(value);
    onChange([null, formattedVal]);
    /* Updating control value via local state for performance */
    this.setState({ max: value });
    handleReportValueSelection(value);
  };

  generateLabels = (v: FilterPickerValue): string => {
    if (v === this.defaultText) {
      return v;
    } else {
      return this.props.labelFormatter(v);
    }
  };

  render() {
    const { theme, values, height, itemHeight, ariaLabelledBy } = this.props;
    const { max } = this.state;

    return (
      <div className={theme.MaxOnlyPicker}>
        <ScrollPicker
          dataHcName="max-only-picker"
          ariaLabelledBy={ariaLabelledBy}
          height={height}
          itemHeight={itemHeight}
          labelFormatter={this.generateLabels}
          valueGroups={{
            max: max,
          }}
          optionGroups={{
            max: [this.defaultText, ...values],
          }}
          onChange={this.handleValueChange}
        />
      </div>
    );
  }
}

export default themr('MaxOnlyPicker', defaultTheme)(MaxOnlyPicker);
