import React, { PureComponent } from 'react';
import SVGInline from 'react-svg-inline';
import SVGUniquerContext from '@client/context/svg-uniquer';
import { SVGImageAltProps, SVGIconProps } from '@client/inline-svgs/types';

const ID_REGEX_PATTERN = /id="(.*?)"/g;
const URL_REGEX_PATTERN = /"url\((.*?)\)"/g;
const XLINK_HREF_REGEX_PATTERN = /xlink:href="#(.*?)"/g;

type SVGUniqerProps = {
  incrementSVGUId: () => number;
  svgData: string;
  imageAltProps?: SVGImageAltProps;
  ariaHidden?: boolean;
};

/**
 * Modifies every 'id' attribute value for a svg element at runtime by appending a
 * unique path to prevent every rendered instance of the svg from having the same id.
 * 
 * Also adds accessible label support for applying alt text to SVGs.
 */
function makeSVGDataUnique(svgData: string, uId: number, imageAlt?: SVGImageAltProps, ariaHidden?: boolean): string {
  const uniqueSvg = svgData
    .replace(ID_REGEX_PATTERN, `id="$1__${uId}"`)
    .replace(URL_REGEX_PATTERN, `"url($1__${uId})"`)
    .replace(XLINK_HREF_REGEX_PATTERN, `xlink:href="#$1__${uId}"`);

  if (typeof window !== 'undefined') {
    const parser = new window.DOMParser();
    const svgDoc = parser.parseFromString(uniqueSvg, "image/svg+xml");

    if (imageAlt?.ariaLabelledBy) {
      svgDoc.documentElement.setAttribute("aria-labelledby", imageAlt.ariaLabelledBy + `-${uId}`);
      svgDoc.documentElement.setAttribute("role", "img");
    };
    
    if (ariaHidden) {
      svgDoc.documentElement.setAttribute("aria-hidden", "true");
    };
  
    if (imageAlt?.ariaLabelledBy && imageAlt?.title) {
      const titleElement = svgDoc.createElementNS("http://www.w3.org/2000/svg", "title");
      titleElement.textContent = imageAlt.title;
      svgDoc.documentElement.prepend(titleElement);
      titleElement.id = imageAlt.ariaLabelledBy + `-${uId}`;
    };
  
    return new XMLSerializer().serializeToString(svgDoc);
  };

  return uniqueSvg;
}

class SVGUniqer extends PureComponent<SVGUniqerProps> {
  svgData: string;

  constructor(props: any) {
    super(props);
    this.svgData = makeSVGDataUnique(props.svgData, props.incrementSVGUId(), props?.imageAltProps, props?.ariaHidden);
  }

  render() {
    const { svgData, incrementSVGUId, imageAltProps, ariaHidden, ...rest } = this.props;
    return <SVGInline svg={this.svgData} {...rest} />;
  }
}

export default function makeUnique(
  svgData: string
): React.FunctionComponent<SVGIconProps> {
  const SVGComponent: React.FunctionComponent<SVGIconProps> = (props) => (
    <SVGUniquerContext.Consumer>
      {({ incrementSVGUId }) => {
        return (
          <SVGUniqer
            incrementSVGUId={incrementSVGUId}
            svgData={svgData}
            {...props}
          />
        );
      }}
    </SVGUniquerContext.Consumer>
  );

  return SVGComponent;
}
