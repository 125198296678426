import { INITIAL_LEGAL_CONTENT_STATE } from '@client/store/reducers/legal-content.reducer';
export const FETCH_LEGAL_CONTENT = 'FETCH_LEGAL_CONTENT';
export const FETCH_LEGAL_CONTENT_SUCCESS = 'FETCH_LEGAL_CONTENT_SUCCESS';

type LegalContentStateKey = keyof typeof INITIAL_LEGAL_CONTENT_STATE;

type FetchLegalContent = {
  type: typeof FETCH_LEGAL_CONTENT;
  payload: {
    key: LegalContentStateKey;
    url: string;
  };
};

export const fetchLegalContent = (
  key: LegalContentStateKey,
  url: string
): FetchLegalContent => ({
  type: FETCH_LEGAL_CONTENT,
  payload: {
    key,
    url,
  },
});

type FetchLegalContentSuccess = {
  type: typeof FETCH_LEGAL_CONTENT_SUCCESS;
  payload: {
    key: keyof typeof INITIAL_LEGAL_CONTENT_STATE;
    content: string;
  };
};

export const fetchLegalContentSuccess = (
  key: LegalContentStateKey,
  content: string
): FetchLegalContentSuccess => ({
  type: FETCH_LEGAL_CONTENT_SUCCESS,
  payload: {
    key,
    content,
  },
});

export type LegalContentAction = FetchLegalContent | FetchLegalContentSuccess;
