import { MLSCoverageLayerFeatureProperties } from '@client/store/types/maps';

import { watchEvery } from '@client/utils/saga.utils';
import { call, put, select } from 'redux-saga/effects';
import {
  REGISTER_EMAIL,
  registerEmailFailure,
} from '@client/store/actions/mls-notify.actions';
import { SearchShowMLSRegistrationConfirm } from '@client/store/actions/search.actions';
import { get } from 'lodash';
import {
  getSearchMLSCoverageLayerFeatures,
  getPlaceId,
} from '@client/store/selectors/search.selectors';
import { consumerApiClient } from '@client/services/consumer-api-client';
import { getUserId } from '@client/store/selectors/auth.selectors';

export function* submitRegisterEmail(action) {
  try {
    const userId = yield select(getUserId);
    const placeId = yield select(getPlaceId);
    const mlsCoverageLayerFeatures = (yield select(
      getSearchMLSCoverageLayerFeatures
    )) as (null | MLSCoverageLayerFeatureProperties)[];
    const fips = mlsCoverageLayerFeatures
      .filter(
        (featureProperties) =>
          featureProperties && featureProperties.mls_coverage < 30
      )
      .map(
        (featureProperties) => featureProperties && featureProperties.county_id
      );
    const payload = {
      email: action.payload.email,
      user_id: userId,
      ...(placeId ? { place_ids: [placeId] } : { fips }),
    };

    yield call(
      [consumerApiClient, consumerApiClient.registerEmailForMLSNotifications],
      payload
    );
    yield put(SearchShowMLSRegistrationConfirm());
  } catch (e: any) {
    const errorMessage = get(
      e,
      ['responseJSON', 'message'],
      get(e, ['responseJSON', 'error'], 'Error sending emails')
    );
    yield put(registerEmailFailure(errorMessage));
  }
}

export default function registerMLSNotifySaga(sageMiddleware) {
  watchEvery(sageMiddleware, {
    [REGISTER_EMAIL]: submitRegisterEmail,
  });
}
