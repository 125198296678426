import {
  LO_ID_TEMPLATE_KEY,
  replaceTemplateKeyWithLoId,
} from '@client/utils/loan-officer.utils';
import { createSelector } from 'reselect';

import { LoanOfficerState } from '@client/store/reducers/loan-officer.reducer';
import {
  getCobrandDisplayName,
  getCobrandId,
  getLoCardConfig,
} from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { ReduxState } from '@client/store/types/redux-state';

export function getLoanOfficerState(state: ReduxState): LoanOfficerState {
  return state.loanOfficer;
}

export const getLoanOfficerData = createSelector(
  getLoanOfficerState,
  (loanOfficerState) => loanOfficerState.data
);

export const getLoanOfficerStatus = createSelector(
  getLoanOfficerState,
  (loanOfficerState) => loanOfficerState.status
);

function isStateThatShowsBranchAddress(state = '') {
  const statesToShow = ['MO', 'NJ', 'NV'];
  return statesToShow.includes(state);
}

export const getLoanOfficerInfo = createSelector(
  getLoanOfficerData,
  getCobrandDisplayName,
  getCobrandId,
  (loanOfficerData, partnerName, cobrandId) => {
    if (
      loanOfficerData?.lo_id &&
      (loanOfficerData?.first_name || loanOfficerData?.full_name)
    ) {
      const {
        first_name: firstName,
        last_name: lastName,
        full_name: fullName,
        phone,
        email,
        headshot: photoUrl,
        nmls_id: id,
        oh_mlo: ohMlo,
        branch_nmls: branchNMLS,
        address_line1: addressLine1,
        address_line2: addressLine2,
        city,
        state,
        zipcode,
        website,
        company,
      } = loanOfficerData || {};
      return {
        branchAddress:
          state &&
          isStateThatShowsBranchAddress(state) &&
          addressLine1 &&
          city &&
          state
            ? {
                street: [addressLine1, addressLine2].reduce((a, b) =>
                  a && b ? a + ' ' + b : a
                ),
                city,
                state,
                zipcode,
              }
            : undefined,
        branchNMLS: branchNMLS ? `Branch NMLS #${branchNMLS}` : undefined,
        company: cobrandId === 'www' ? company : partnerName,
        email,
        fullName,
        firstName,
        /* TODO remove this property since it's not named specifically enough */
        id: id ? `NMLS #${id}` : undefined,
        nmlsIdDisplay: id ? `NMLS #${id}` : undefined,
        lastName,
        phone,
        photoUrl,
        ohMlo: ohMlo ? `OH MLO #${ohMlo}` : undefined,
        website,
        zipcode,
        loId: loanOfficerData?.lo_id,
        customCtaButtonText: loanOfficerData?.custom_cta_button_text,
        customCtaUrl: loanOfficerData?.custom_cta_url,
      };
    } else {
      return null;
    }
  }
);

export const getLoanOfficerId = createSelector(
  getLoanOfficerData,
  (loanOfficerData) => loanOfficerData?.lo_id || null
);

export const getLoanOfficerIsLODirectUser = createSelector(
  getLoanOfficerData,
  (loanOfficerData) => loanOfficerData?.special_type === 'louser'
);

export const getLoanOfficerDatabaseId = createSelector(
  getLoanOfficerData,
  (loanOfficerData) =>
    loanOfficerData?.lo_id && loanOfficerData?.id
      ? loanOfficerData.id
      : undefined
);

export const getLoanOfficerRefiSavingsPerMonth = createSelector(
  getLoanOfficerState,
  (loanOfficerState) => loanOfficerState.refiSavingsPerMonth
);

export const getLoanOfficerLastMessageDate = createSelector(
  getLoanOfficerState,
  (loanOfficerState) => loanOfficerState.lastMessageDate
);

export const getContactLoanOfficerError = createSelector(
  getLoanOfficerState,
  (loanOfficerState) => loanOfficerState.contactLoanOfficerError
);

/**
 * Returns the URL for the LO CTA, which may require template substitution, depending
 * on available data.
 */
export const getLoCardUrl = createSelector(
  getLoCardConfig,
  getLoanOfficerId,
  (config, loId) => {
    const url = config?.hbPdp?.url;
    const urlTemplate = config?.hbPdp?.urlTemplate ?? '';

    if (loId !== null && urlTemplate?.includes(LO_ID_TEMPLATE_KEY)) {
      return replaceTemplateKeyWithLoId(urlTemplate, loId);
    } else {
      return url;
    }
  }
);

export const getIsShowingLenderCTACards = createSelector(
  getCobrandId,
  getLoanOfficerIsLODirectUser,
  (state) => getIsFeatureEnabled('lo_direct')(state),
  (state) => getIsFeatureEnabled('cta_cleanup')(state),
  (
    cobrandId,
    isLoanOfficerLODirectUser,
    isLODirectEnabled,
    isCTACleanupEnabled
  ) =>
    /* Special rules for ComeHome cobrand: only want show the Lender CTAs if the user is an LO Direct user's client
     * (since the CTAs open Your Team in that case), otherwise hide them since ComeHome is not a lender */
    isLODirectEnabled && cobrandId === 'www'
      ? isCTACleanupEnabled && isLoanOfficerLODirectUser
      : isCTACleanupEnabled
);

export const getIsShowingHeaderLenderCTA = createSelector(
  getCobrandId,
  getLoanOfficerIsLODirectUser,
  (state) => getIsFeatureEnabled('lo_direct')(state),
  (state) => getIsFeatureEnabled('header_lender_cta')(state),
  (
    cobrandId,
    isLoanOfficerLODirectUser,
    isLODirectEnabled,
    isHeaderLenderCTAEnabled
  ) =>
    /* Special rules for ComeHome cobrand: only want show the header lender CTA if the user is an LO Direct user's client
     * (since the CTAs open Your Team in that case), otherwise hide it since ComeHome is not a lender */
    isLODirectEnabled && cobrandId === 'www'
      ? isHeaderLenderCTAEnabled && isLoanOfficerLODirectUser
      : isHeaderLenderCTAEnabled
);
