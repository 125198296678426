import MLSCopyrightText from '@client/components/MLSCopyrightText';
import { MlsRegulations } from '@client/store/sagas/queries/types';
import { Theme } from '@friendsofreactjs/react-css-themr';
import React from 'react';

type MLSCopyrightSectionProps = {
  theme: Theme;
  globalMLSRegulations: Partial<MlsRegulations>[] | null;
  propertyMLSRegulations: Partial<MlsRegulations> | null;
  refreshedDateTime: string;
};

const MLSCopyrightSection: React.FC<MLSCopyrightSectionProps> = ({
  globalMLSRegulations,
  propertyMLSRegulations,
  refreshedDateTime,
  theme,
}) => {
  if (propertyMLSRegulations) {
    return (
      <MLSCopyrightText
        theme={theme}
        logoUrl={propertyMLSRegulations?.logo}
        copyrightText={propertyMLSRegulations?.copyrightStatement}
        disclaimerText={propertyMLSRegulations?.disclaimer}
        refreshedDateTime={refreshedDateTime}
      />
    );
  }
  /* fallback to mls info fetched for SRP if not on pdp page */
  return (
    <>
      {globalMLSRegulations?.map((regulation, index) => (
        <MLSCopyrightText
          key={`regulation-${index}`}
          theme={theme}
          logoUrl={regulation?.logo}
          copyrightText={regulation?.copyrightStatement}
          disclaimerText={regulation?.disclaimer}
          refreshedDateTime={refreshedDateTime}
        />
      ))}
    </>
  );
};

export default MLSCopyrightSection;
