import React from 'react';
import classNames from 'classnames';

import BreadcrumbContainer from '@client/containers/breadcrumbs.container';
import HomeownerHomeBuyerToggleContainer from '@client/containers/homeowner-home-buyer-toggle.container';
import { PropertyPageTopBarProps } from '@client/store/types/cobranded-components/property-page-top-bar';
import theme from '@client/css-modules/PropertyPageTopBarComeHome.css';

const PropertyPageTopBarComeHome: React.FC<PropertyPageTopBarProps> = ({
  shouldDisplayToggleForUserAndProperty,
}) => {
  return (
    <div className={theme.PropertyPageTopBar}>
      <div
        className={classNames(theme.PropertyPageTopBarInner, {
          [theme.WithToggleHidden]: !shouldDisplayToggleForUserAndProperty,
        })}
      >
        <BreadcrumbContainer />
        {shouldDisplayToggleForUserAndProperty && (
          <HomeownerHomeBuyerToggleContainer />
        )}
      </div>
    </div>
  );
};

export default PropertyPageTopBarComeHome;
