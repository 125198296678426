import classNames from 'classnames';
import React from 'react';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';

import defaultTheme from '@client/css-modules/VerticalSeparator.css';

type Props = {
  className?: string;
  theme: Theme;
};

const VerticalSeparator: React.FC<Props> = ({ className, theme }) => (
  <div className={classNames(theme.VerticalSeparator, className)} />
);

export const ThemedVerticalSeparator = themr(
  'VerticalSeparator',
  defaultTheme
)(VerticalSeparator);
export default ThemedVerticalSeparator;
