import getIsLocal from '@server/middleware/utility-functions/get-is-local';

/**
 * util to return domain for cookie settings
 * @param scopeToCobrand: string - Pass in valid cobrand to
 * limit the scope of cookie being set, pass empty string otherwise
 */
export default function (scopeToCobrand?: string): string {
  let subdomain = '';
  if (scopeToCobrand) {
    subdomain = getIsLocal()
      ? `.${scopeToCobrand}.local`
      : `.${scopeToCobrand}`;
  }
  return process.env.LOCAL_API_ENV === 'prod'
    ? `${subdomain}.comehome.com`
    : `${subdomain}.${process.env.LOCAL_API_ENV}.ch.housecanary.net`;
}
