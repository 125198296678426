import React, { Component } from 'react';

import SearchPageMapContainer from '@client/containers/search-page-map.container';
import SearchPageListContainer from '@client/containers/search-page-list.container';
import MobileFiltersContainer from '@client/containers/mobile-filters.container';
import theme from '@client/css-modules/SearchPage.css';
import { BoundsObject, CurrentMapViewport } from '@client/store/types/maps';
import AccessiblePageTitleContainer from '@client/containers/accessible-page-title.container';

type Props = {
  shouldShowAppDLError: boolean;
  isShowingSearchList: boolean;
  isShowingMobileFiltersForQueryParam: boolean;
  clearSavedSearchState: () => void;
  reportDLErr: () => void;
  handleCloseMobileFilters: (
    shouldGoBackWhenClosingMobileFilters: boolean
  ) => void;
  handleSaveSearch: (location: 'list' | 'map' | 'filters') => void;
  initialPropertyListLocationParams: CurrentMapViewport;
  reportInitialFilterState: () => void;
  searchFetchPropertyList: (params: BoundsObject) => void;
  shouldGoBackWhenClosingMobileFilters: boolean;
  isFourHundredPercentZoom?: boolean;
};

export default class SearchPage extends Component<Props> {
  componentWillUnmount() {
    this.props.clearSavedSearchState();
  }

  async componentDidMount() {
    this.props.shouldShowAppDLError && this.props.reportDLErr();
    const { searchFetchPropertyList, initialPropertyListLocationParams } =
      this.props;

    if (initialPropertyListLocationParams) {
      searchFetchPropertyList(initialPropertyListLocationParams);
    }

    // Report initial filter state when search page mounts
    this.props.reportInitialFilterState();
  }

  render() {
    const {
      isShowingSearchList,
      isShowingMobileFiltersForQueryParam,
      handleCloseMobileFilters,
      handleSaveSearch,
      shouldGoBackWhenClosingMobileFilters,
      isFourHundredPercentZoom,
    } = this.props;

    const showFilters = isShowingMobileFiltersForQueryParam;

    return (
      <div 
        className={theme.SearchPage}
        style={isFourHundredPercentZoom ? { position: "static" } : {}}
      >
        <SearchPageMapContainer handleSaveSearch={handleSaveSearch} />
        {isShowingSearchList && (
          <SearchPageListContainer handleSaveSearch={handleSaveSearch} />
        )}
        {showFilters && (
          <MobileFiltersContainer
            handleClose={() =>
              handleCloseMobileFilters(shouldGoBackWhenClosingMobileFilters)
            }
            handleSaveSearch={handleSaveSearch}
          />
        )}
        <AccessiblePageTitleContainer />
      </div>
    );
  }
}
