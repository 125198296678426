import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import NoSavedSearchesIcon from '@client/inline-svgs/cobrand/lendco/lendco-no-saved-searches-icon';
import NoSavedSearchesIconChase from '@client/inline-svgs/cobrand/chase/chase-no-saved-searches-icon';
import NoSavedSearchesIconComeHome from '@client/inline-svgs/no-saved-searches-icon';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return NoSavedSearchesIconChase;
    case COBRAND_IDS.WWW:
      return NoSavedSearchesIconComeHome;
    default:
      return NoSavedSearchesIcon;
  }
};

const SavedSearchesPageDefaultIconCobranded: React.FC<{ className: string }> = (
  props
) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default SavedSearchesPageDefaultIconCobranded;
