import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { themr } from '@friendsofreactjs/react-css-themr';

import DisclosureAsterisk from '@client/components/generic/DisclosureAsterisk';
import { DisclosureProps } from '@client/store/types/cobranded-components/disclosure';
import { getDisclosureText } from '@client/store/selectors/cobranding.selectors';
import defaultTheme from '@client/css-modules/Disclosure.css';

const Disclosure: FC<DisclosureProps> = ({
  theme,
  showEstimatedPaymentDisclosure,
  showMortgageCalcDisclosure,
  showHomeOwnerRefiCalcDisclosure,
}) => {
  const { estimatedPayment, mortgageCalculator, refiCalculator } =
    useSelector(getDisclosureText);
  /* do not display disclosure if no option passed */
  const isValidOption =
    showEstimatedPaymentDisclosure ||
    showMortgageCalcDisclosure ||
    showHomeOwnerRefiCalcDisclosure;
  const disclosureText =
    (showEstimatedPaymentDisclosure && estimatedPayment) ||
    (showMortgageCalcDisclosure && mortgageCalculator) ||
    (showHomeOwnerRefiCalcDisclosure && refiCalculator);

  return isValidOption && disclosureText ? (
    <div className={theme.DisclosureContainer}>
      {showMortgageCalcDisclosure && <div className={theme.Divider} />}
      <div className={theme.Disclosure}>
        {
          <DisclosureAsterisk
            shouldShowLighterColor={showMortgageCalcDisclosure}
            theme={theme}
          />
        }
        <span dangerouslySetInnerHTML={{ __html: disclosureText }} />
      </div>
    </div>
  ) : null;
};

const ThemedDisclosure = themr('Disclosure', defaultTheme)(Disclosure);
export default ThemedDisclosure;
