import { connect } from 'react-redux';
import PrivacyPolicyPage from '@client/pages/PrivacyPolicyPage';
import { getLegalContentPrivacyPolicy } from '@client/store/selectors/legal-content.selectors';
import { fetchLegalContent } from '@client/store/actions/legal-content.actions';
import HC_CONSTANTS from '@client/app.config';

const mapStateToProps = (state) => {
  return {
    content: getLegalContentPrivacyPolicy(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleFetchContent: () =>
    dispatch(
      fetchLegalContent('privacyPolicy', HC_CONSTANTS.PRIVACY_POLICY_PROXY_URL)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyPage);
