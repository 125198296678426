export default (addressId) => ({
  query: /* GraphQL */ `
    query PropertyDetailsForWatchlistItem($addressId: NumericID) {
      propertyLookup(id: { addressId: $addressId }) {
        address {
          slug
          fullAddress
          hcAddressId
          streetAddress
          unit
          city
          state
          zipcode
          zipcodePlus4
        }
        county {
          name
        }
        structure {
          yearBuilt
          stories
        }
        avm(qualityMethod: CONSUMER) {
          priceMean
        }
        bestPhotos(orderBy: MLS) {
          id
          storageUrl
          prediction
          confidence
          representation {
            httpUrl
            height
            width
          }
        }
        geoLocation {
          latitude
          longitude
        }
        latestListing {
          listingOfficeName
          status
          price
        }
        listDate
        listPrice
        livingSpace {
          livingArea
          bedrooms {
            count
          }
          bathrooms {
            summaryCount
          }
        }
        mlsState
        propertyType
        paymentEstimate {
          total
        }
        mls {
          mlsID
          regulations {
            logoOverlay
            photosLogoOverlay
          }
        }
      }
    }
  `,
  variables: {
    addressId,
  },
});
