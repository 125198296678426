import React, { useEffect, useState } from 'react';

/**
 * Given a component, render it after this HOC component mounts
 */
const renderOnMount =
  <P extends object>(WrappedComponent: React.ComponentType<P>): React.FC<P> =>
  (props: P) => {
    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
      setHasMounted(true);
    }, []);

    return hasMounted ? <WrappedComponent {...props} /> : null;
  };

export default renderOnMount;
