import { connect } from 'react-redux';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';

import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { View } from '@client/routes/constants';
import WatchListHeaderCobranded from '@client/components/WatchListHeader/WatchListHeaderCobranded';
import { WATCHLIST_HEADER_SECTION_KEY } from '@client/store/constants';
import { debounce } from 'lodash';

// Method to refocus keyboard focus to the selected tab element.
// Because changing tabs causes a page navigation, tab focus is lost
// Only perform the tab refocus, if the activeEle was the tab excuting 
// actions.
const refocusTab = debounce((activeEle: Element | null, tabId: string) => {
  if (activeEle?.id === tabId) {
  document?.getElementById(tabId)?.focus?.();
  }
}, 100)

const mapStateToProps = (state) => {
  return {
    isSmallScreenSize: getIsSmallSize(state),
    isWatchListRoute: getCurrentView(state) === View.WATCHLIST,
    isSavedSearchesRoute: getCurrentView(state) === View.SAVED_SEARCHES,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToWatchlist: () => {
      const activeEle = document?.activeElement;
      dispatch(reportEvent('click_watchlist_tab'));
      dispatch(routeChange({ view: View.WATCHLIST }));
      refocusTab(activeEle, `${WATCHLIST_HEADER_SECTION_KEY.SAVED_HOMES}_TAB`);
    },
    goToSavedSearches: () => {
      const activeEle = document?.activeElement;
      dispatch(reportEvent('click_saved_searches'));
      dispatch(routeChange({ view: View.SAVED_SEARCHES }));
      refocusTab(activeEle, `${WATCHLIST_HEADER_SECTION_KEY.SAVED_SEARCHES}_TAB`);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WatchListHeaderCobranded);
