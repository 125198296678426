import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import PoweredByComeHomeLogo from './PoweredByComeHomeLogo';
import PoweredByHouseCanaryLogo from './PoweredByHouseCanaryLogo';

interface PoweredByLogoCobrandedProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  useWhiteLogo?: boolean;
}

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.WWW:
      return PoweredByHouseCanaryLogo;
    default:
      return PoweredByComeHomeLogo;
  }
};

const PoweredByLogoCobranded: React.FC<PoweredByLogoCobrandedProps> = (
  props
) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default PoweredByLogoCobranded;
