import classNames from 'classnames';

import React, { Component } from 'react';
import { motion } from 'framer-motion';

import HeaderMobileBottomSection from '@client/components/HeaderMobileBottomSection';
import theme from '@client/css-modules/PhotoListModal.css';
import TransformedImageIOSFixContainer from '@client/containers/transformed-image-ios-fix.container';
import LazilyRenderedList from '@client/components/LazilyRenderedList';
import SlideInModalContainer from '@client/containers/slide-in-modal.container';
import { createModalPortal } from '@client/hocs/create-modal-portal';
import { NormalizedProperty } from '@client/store/types/property';
import WatchListActionButtonContainer from '@client/containers/watchlist-action-button.container';
import { getPropertyValueAndValueLabel } from '@client/utils/property.utils';
import MLSAttribution from '@client/components/MLSAttribution';
import CobrandedDataComponent, {
  CobrandedDataComponentArgs,
} from '@client/components/CobrandedDataComponent';
import PropertyCardValueCobranded from '@client/components/PropertyCardValue/PropertyCardValueCobranded';

const SCROLLABLE_CONTAINER_CLASSNAME = 'PhotoListModal__ScrollableContainer';

type Props = {
  isActive: boolean;
  isAddedToWatchList: boolean;
  photos: string[];
  propertyDetails: NormalizedProperty | null;
  handleClose: () => void;
  handlePropertyPhotosWatchClick: (slug: string) => void;
  handlePropertyPhotosUnwatchClick: (slug: string) => void;
  handlePropertyPhotosUnwatchConfirmClick: (slug: string) => void;
  selectedPhotoUrl: string | null;
  shouldHideSavePropertyButton: boolean;
};

type State = {
  hasListReachedBottom: boolean;
};

/**
 * A modal to display all property photos
 */
class PhotoListModal extends Component<Props, State> {
  state: State = {
    hasListReachedBottom: false,
  };

  handleListNotReachedBottom = () => {
    this.setState({ hasListReachedBottom: false });
  };

  handleListReachedBottom = () => {
    this.setState({ hasListReachedBottom: true });
  };

  render() {
    const {
      isActive,
      photos,
      handleClose,
      propertyDetails,
      isAddedToWatchList,
      handlePropertyPhotosWatchClick,
      handlePropertyPhotosUnwatchClick,
      handlePropertyPhotosUnwatchConfirmClick,
      selectedPhotoUrl,
      shouldHideSavePropertyButton,
    } = this.props;

    /* When hiding the mobile property card, the removal of the property card slug in the URL triggers the
     * animation that removes the property card, hence this component is rendered without data for a brief period */
    if (!propertyDetails) {
      return null;
    }

    const { hasListReachedBottom } = this.state;
    const { slug, fullAddress } = propertyDetails;
    const watchlistAddress = {
      street: propertyDetails.streetAddress,
      city: propertyDetails.city,
      state: propertyDetails.state,
      zip: propertyDetails.zipcode,
      unit: propertyDetails.unit,
      address_id: propertyDetails.hcAddressId,
      slug: propertyDetails.slug,
    };

    return (
      <CobrandedDataComponent>
        {({
          customizationData: { is_showing_header_in_photos_list_modal },
        }: CobrandedDataComponentArgs) => (
          <SlideInModalContainer
            className={theme.PhotoListModal}
            theme={{
              ...theme,
              ScrollableContainer: SCROLLABLE_CONTAINER_CLASSNAME,
              Modal: classNames(theme.Modal, {
                /* When not showing the header at the top, add padding so that the close button
                 * can be seen */
                [theme.ModalWithTopPadding]:
                  is_showing_header_in_photos_list_modal,
              }),
            }}
            isActive={isActive}
            modalAriaLabel={'Photo list'}
            hideCloseIcon={is_showing_header_in_photos_list_modal}
            handleClose={handleClose}
          >
            {is_showing_header_in_photos_list_modal && (
              <HeaderMobileBottomSection theme={theme} />
            )}
            <LazilyRenderedList
              dataHcName="photo-list-photos"
              keyToScroll={
                selectedPhotoUrl &&
                photos &&
                photos.indexOf(selectedPhotoUrl) > 0
                  ? selectedPhotoUrl
                  : undefined
              }
              preloadBuffer={2000}
              scrollableAncestorClassName={SCROLLABLE_CONTAINER_CLASSNAME}
              theme={theme}
              itemDimensionsStyle={{
                width: '100vw',
                height: '70vw',
              }}
              llKeyField="data-src"
              onReachedBottom={this.handleListReachedBottom}
              onNotReachedBottom={this.handleListNotReachedBottom}
            >
              {photos &&
                photos.map((url) => (
                  <TransformedImageIOSFixContainer
                    className={theme.Photo}
                    alt={`Photo of ${fullAddress}`}
                    src={url}
                    key={url}
                    data-src={url}
                  />
                ))}
            </LazilyRenderedList>
            <motion.div
              animate={
                hasListReachedBottom
                  ? { y: 150 }
                  : { y: 0, transition: { damping: 0 } }
              }
              className={theme.PropertyInfo}
              onClick={handleClose}
            >
              <div className={theme.PropertyInfoInner}>
                <div
                  className={theme.PropertyAddress}
                  data-hc-name="photo-list-property-address"
                >
                  {propertyDetails.fullStreetAddress}, {propertyDetails.city}
                </div>
                <PropertyCardValueCobranded
                  /* Provides `value` and `valueLabel` props */
                  {...getPropertyValueAndValueLabel(propertyDetails)}
                  monthlyPaymentEstimate={
                    propertyDetails.monthlyPaymentEstimate
                  }
                  theme={theme}
                  bottomLabel={
                    <MLSAttribution propertyDetails={propertyDetails} />
                  }
                />
              </div>
              {!shouldHideSavePropertyButton && (
                <WatchListActionButtonContainer
                  address={watchlistAddress}
                  fullAddress={fullAddress}
                  isAddedToWatchList={isAddedToWatchList}
                  shouldHandleCheckingForWatchListStatus={false}
                  addressSlug={slug}
                  theme={theme}
                  isInModal
                  handleReportWatchClick={handlePropertyPhotosWatchClick}
                  handleReportUnwatchClick={handlePropertyPhotosUnwatchClick}
                  handleReportUnwatchConfirmClick={
                    handlePropertyPhotosUnwatchConfirmClick
                  }
                />
              )}
            </motion.div>
          </SlideInModalContainer>
        )}
      </CobrandedDataComponent>
    );
  }
}

export default createModalPortal(PhotoListModal, 'photo-list');
