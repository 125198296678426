import registerAdminPortalSaga from '@client/store/sagas/admin.saga';
import registerAlertsSaga from '@client/store/sagas/alerts.saga';
import registerAuthSaga from '@client/store/sagas/auth.saga';
import registerInviteSaga from '@client/store/sagas/invite.saga';
import registerFeedbackSaga from '@client/store/sagas/feedback.saga';
import registerFindAgentSaga from '@client/store/sagas/find-agent.saga';
import registerHomeownerSaga from '@client/store/sagas/homeowner.saga';
import registerJSBridgeSaga from '@client/store/sagas/chase-integration.saga';
import registerLastSearchSaga from '@client/store/sagas/last-search.saga';
import registerLegalContentSaga from '@client/store/sagas/legal-content.saga';
import registerLocalActivitiesSaga from '@client/store/sagas/local-activities.saga';
import registerLoDirectSaga from '@client/store/sagas/lo-direct.saga';
import registerMapsSharedSaga from '@client/store/sagas/map-legend-breaks.saga';
import registerModalsSharedSaga from '@client/store/sagas/modals.saga';
import registerPlaceSearchSaga from '@client/store/sagas/place-search.saga';
import registerPropertyPhotosSaga from '@client/store/sagas/property-photos.saga';
import registerPropertyDetailsSaga from '@client/store/sagas/property-details.saga';
import registerSavedSearchSaga from '@client/store/sagas/saved-search.saga';
import registerSearchSaga from '@client/store/sagas/search.saga';
import registerSharePropertySaga from '@client/store/sagas/share-property.saga';
import registerTestPropertiesSaga from '@client/store/sagas/test-properties.saga';
import registerWatchlistSaga from '@client/store/sagas/watchlist.saga';
import registerUnreadAlertsResetSaga from '@client/store/sagas/unread-alerts-reset.saga';
import registerMLSNotifySaga from '@client/store/sagas/mls-notify.saga';
import registerLoanOfficerSaga from '@client/store/sagas/loan-officer.saga';
import registerPageviewSaga from '@client/store/sagas/pageview.saga';
import registerHomeownerEquitySaga from '@client/store/sagas/homeowner-equity.saga';
import registerHomeownerMortgageSaga from '@client/store/sagas/homeowner-mortgage.saga';
import registerYourTeamSaga from '@client/store/sagas/your-team.saga';
import registerHomeownerVerificationSaga from '@client/store/sagas/homeowner-verification.saga';
import registerRequestATourSaga from '@client/store/sagas/request-a-tour.saga';
import registerRecentUserActivitySaga from '@client/store/sagas/recent-user-activity.saga';
import registerPartnerAPICredentialSaga from '@client/store/sagas/partner-api-credential.saga';
import registerCensusTractSaga from '@client/store/sagas/census-tract.saga';

export default function registerSagas(sagaMiddleware) {
  registerAdminPortalSaga(sagaMiddleware);
  registerAlertsSaga(sagaMiddleware);
  registerAuthSaga(sagaMiddleware);
  registerInviteSaga(sagaMiddleware);
  registerFeedbackSaga(sagaMiddleware);
  registerFindAgentSaga(sagaMiddleware);
  registerHomeownerSaga(sagaMiddleware);
  registerJSBridgeSaga(sagaMiddleware);
  registerLastSearchSaga(sagaMiddleware);
  registerLegalContentSaga(sagaMiddleware);
  registerLocalActivitiesSaga(sagaMiddleware);
  registerLoDirectSaga(sagaMiddleware);
  registerMapsSharedSaga(sagaMiddleware);
  registerModalsSharedSaga(sagaMiddleware);
  registerPlaceSearchSaga(sagaMiddleware);
  registerPropertyPhotosSaga(sagaMiddleware);
  registerPropertyDetailsSaga(sagaMiddleware);
  registerRecentUserActivitySaga(sagaMiddleware);
  registerRequestATourSaga(sagaMiddleware);
  registerSavedSearchSaga(sagaMiddleware);
  registerSearchSaga(sagaMiddleware);
  registerSharePropertySaga(sagaMiddleware);
  registerTestPropertiesSaga(sagaMiddleware);
  registerWatchlistSaga(sagaMiddleware);
  registerUnreadAlertsResetSaga(sagaMiddleware);
  registerMLSNotifySaga(sagaMiddleware);
  registerLoanOfficerSaga(sagaMiddleware);
  registerPageviewSaga(sagaMiddleware);
  registerHomeownerEquitySaga(sagaMiddleware);
  registerHomeownerMortgageSaga(sagaMiddleware);
  registerYourTeamSaga(sagaMiddleware);
  registerHomeownerVerificationSaga(sagaMiddleware);
  registerPartnerAPICredentialSaga(sagaMiddleware);
  registerCensusTractSaga(sagaMiddleware);
}
