import { createSelector } from 'reselect';

import { ReduxState } from '@client/store/types/redux-state';
import { WatchListState } from '@client/store/reducers/watchlist.reducer';
import { STATUSES } from '../constants';
import { FullWatchListItem } from '@client/store/types/watchlist';

export const getWatchListState = (state: ReduxState): WatchListState =>
  state.watchlist;

export const getWatchListItems = createSelector(
  getWatchListState,
  (watchListState) => watchListState.items
);

export const getIsPropertyInWatchList = (slug: string) =>
  createSelector(getWatchListState, (watchListState) =>
    watchListState.items.some((item) => item.slug === slug)
  );

export const getWatchListStatus = createSelector(
  getWatchListState,
  (watchListState) => watchListState.status
);

export const getIsDoneGettingPropertyDetailsForAllWatchlist = createSelector(
  getWatchListItems,
  (watchListItems) => {
    /**
     * Only return watchlist items gotten from API, not stub watchlist items added
     * to state as a result of adding a property to watchlist from search page.
     */
    let watchListData = (watchListItems as FullWatchListItem[]).filter(
      (item) => !!item.bookmark
    );
    const watchlistWithData = watchListData.filter(
      (item) => item?.status !== STATUSES.LOADING
    );
    return watchlistWithData.length === watchListData.length;
  }
);

export const getPropertySort = createSelector(
  getWatchListState,
  (watchListState) => watchListState.propertySort
);

export const getWatchListSortOrder = createSelector(
  getPropertySort,
  (propertySort) => propertySort.sortOrder
);

export const getWatchListSortField = createSelector(
  getPropertySort,
  (propertySort) => propertySort.sortField
);
