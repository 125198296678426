import { ErrorObj, User } from '@client/store/types/auth';

export const SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN =
  'SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN';
export const SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN_SUCCESS =
  'SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN_SUCCESS';
export const SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN_ERROR =
  'SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN_ERROR';
export const CLEAR_USER_EMAIL_UPDATE_FROM_OPT_IN_ERROR =
  'CLEAR_USER_EMAIL_UPDATE_FROM_OPT_IN_ERROR';
export const OPEN_EMAIL_OPT_IN_MODAL = 'OPEN_EMAIL_OPT_IN_MODAL';
export const CLOSE_EMAIL_OPT_IN_MODAL = 'CLOSE_EMAIL_OPT_IN_MODAL';
export const SET_MODAL_DISPLAYED_ON_SAVE_PROPERTY_TO_TRUE =
  'SET_MODAL_DISPLAYED_ON_SAVE_PROPERTY_TO_TRUE';
export const SET_MODAL_DISPLAYED_ON_SAVE_SEARCH_TO_TRUE =
  'SET_MODAL_DISPLAYED_ON_SAVE_SEARCH_TO_TRUE';
export const SET_MODAL_DISPLAYED_ON_CLAIM_HOME_TO_TRUE =
  'SET_MODAL_DISPLAYED_ON_CLAIM_HOME_TO_TRUE';

type OpenEmailOptInModalAction = {
  type: typeof OPEN_EMAIL_OPT_IN_MODAL;
};

export const openEmailOptInModal = (): OpenEmailOptInModalAction => ({
  type: OPEN_EMAIL_OPT_IN_MODAL,
});

type CloseEmailOptInModalAction = {
  type: typeof CLOSE_EMAIL_OPT_IN_MODAL;
};

export const closeEmailOptInModal = (): CloseEmailOptInModalAction => ({
  type: CLOSE_EMAIL_OPT_IN_MODAL,
});

export type SubmitUserEmailUpdateFromOptInAction = {
  type: typeof SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN;
  payload: { userInfo: Partial<User> };
};

export const submitUserEmailUpdateFromOptIn = (
  userInfo: Partial<User>
): SubmitUserEmailUpdateFromOptInAction => ({
  type: SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN,
  payload: { userInfo },
});

type SubmitUserEmailUpdateFromOptInSuccess = {
  type: typeof SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN_SUCCESS;
};

export const submitUserEmailUpdateFromOptInSuccess =
  (): SubmitUserEmailUpdateFromOptInSuccess => ({
    type: SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN_SUCCESS,
  });

type SubmitUserEmailUpdateFromOptInError = {
  type: typeof SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN_ERROR;
  payload: { error: ErrorObj | string };
};

export const submitUserEmailUpdateFromOptInError = (
  error: ErrorObj | string
): SubmitUserEmailUpdateFromOptInError => ({
  type: SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN_ERROR,
  payload: { error },
});

export type ClearUserEmailUpdateFromOptInError = {
  type: typeof CLEAR_USER_EMAIL_UPDATE_FROM_OPT_IN_ERROR;
};

export const clearUserEmailUpdateFromOptInError =
  (): ClearUserEmailUpdateFromOptInError => ({
    type: CLEAR_USER_EMAIL_UPDATE_FROM_OPT_IN_ERROR,
  });

type SetModalDisplayedOnSavePropertyToTrue = {
  type: typeof SET_MODAL_DISPLAYED_ON_SAVE_PROPERTY_TO_TRUE;
};

export const setModalDisplayedOnSavePropertyToTrue =
  (): SetModalDisplayedOnSavePropertyToTrue => ({
    type: SET_MODAL_DISPLAYED_ON_SAVE_PROPERTY_TO_TRUE,
  });

type SetModalDisplayedOnSaveSearchToTrue = {
  type: typeof SET_MODAL_DISPLAYED_ON_SAVE_SEARCH_TO_TRUE;
};

export const setModalDisplayedOnSaveSearchToTrue =
  (): SetModalDisplayedOnSaveSearchToTrue => ({
    type: SET_MODAL_DISPLAYED_ON_SAVE_SEARCH_TO_TRUE,
  });

type SetModalDisplayedOnClaimHomeToTrue = {
  type: typeof SET_MODAL_DISPLAYED_ON_CLAIM_HOME_TO_TRUE;
};

export const setModalDisplayedOnClaimHomeToTrue =
  (): SetModalDisplayedOnClaimHomeToTrue => ({
    type: SET_MODAL_DISPLAYED_ON_CLAIM_HOME_TO_TRUE,
  });

export type EmailOptInAction =
  | OpenEmailOptInModalAction
  | CloseEmailOptInModalAction
  | SetModalDisplayedOnSavePropertyToTrue
  | SetModalDisplayedOnSaveSearchToTrue
  | SetModalDisplayedOnClaimHomeToTrue
  | SubmitUserEmailUpdateFromOptInAction
  | SubmitUserEmailUpdateFromOptInError
  | ClearUserEmailUpdateFromOptInError
  | SubmitUserEmailUpdateFromOptInSuccess;
