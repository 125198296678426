import { Action } from '@client/store/actions';
import { ImageCTAsResponse } from '@client/store/types/consumer-api';

export type ImageCTAsState = {
  [Property in keyof ImageCTAsResponse]: ImageCTAsResponse[Property];
};

export const INITIAL_IMAGE_CTAS_STATE: ImageCTAsState = {
  hic: {
    desktop: undefined,
    tablet: undefined,
    mobile: undefined,
  },
  ho: {
    desktop: undefined,
    tablet: undefined,
    mobile: undefined,
  },
  pdp: {
    desktop: undefined,
    tablet: undefined,
    mobile: undefined,
  },
  srp: {
    desktop: undefined,
    tablet: undefined,
    mobile: undefined,
  },
};

export default function imageCTAs(
  state = INITIAL_IMAGE_CTAS_STATE,
  action: Action
): ImageCTAsState {
  return state;
}
