import { ActionType, TrackersType, StoreType } from './types';

import { removeInvalidTrackers, checkActionHasAnalytics } from './utils';

export default function analyticsMiddleware(
  trackers: TrackersType = [],
  onCompleteCallback?: (
    action: ActionType,
    store: StoreType,
    error?: {}
  ) => void
) {
  /*
  HouseCanary Analytics middleware.
  Currently supports Kinesis (Beacon) Events

  Args:
    - trackers: list of tracker objects returned from tracker factories

  Action Meta Keys (object body is the same for all):
    - kinesis: Sends a kinesis event

  Sample Action Obj:
    {
      type: ACTION,
      payload: {},
      meta: {
        analytics: {
          event: 'event_name_str',
          props: {
            some_info: 'Some data to collect'
          }
        }
        kinesis: {}
      }
    }
  */

  // Remove invalid trackers to avoid having to re-check them when each event is fired
  const validTrackers: TrackersType = removeInvalidTrackers(trackers);
  // Abort middleware if no valid trackers are provided
  if (!validTrackers.length) {
    console.warn('No valid analytics trackers have been provided.');
    return (store: StoreType) =>
      (next: (action: ActionType) => void) =>
      (action: ActionType) =>
        next(action);
  }

  // Check actions for analytics metadata
  return (store: StoreType) => {
    return (next: (action: ActionType) => void) => (action: ActionType) => {
      // Check that the action includes analytics metadata for the defined trackers
      if (checkActionHasAnalytics(action, validTrackers)) {
        try {
          validTrackers.forEach((trk) => trk.fn(action, store));
          if (onCompleteCallback) {
            onCompleteCallback(action, store);
          }
        } catch (e: any) {
          console.error('Analytics error', e);
          if (onCompleteCallback) {
            onCompleteCallback(action, store, e);
          }
        }
      }
      return next(action);
    };
  };
}
