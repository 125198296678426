import InputRange from '@client/components/generic/InputRange';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import React, { Component } from 'react';

import defaultTheme from '@client/css-modules/RangeSlider.css';

function precisionRound(number, precision) {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
}

type Value =
  /* When seeking a single handle */
  | number
  /* When seeking two handles denoting a range */
  | {
      min: number;
      max: number;
    };

type State = {
  value: Value;
};

type Props = {
  dataHcName?: string;
  className?: string;
  /* Minimum possible value to achieve by sliding the handle */
  minValue: number;
  /* Maximum possible value to achieve by sliding the handle */
  maxValue: number;
  /* Value of the handle between the minimum and maximum possible values */
  value: Value;
  labelFormatter: (value: Value) => string;
  step: number;
  /* This should not typically be used, since it's fired continuously during the slide action */
  onChange?: (value: Value) => void;
  onChangeStart?: (value: Value) => void;
  /* This should be the handler used to react to the user sliding the handle */
  onChangeComplete?: (value: Value) => void;
  handleReportValueSelection: (value: null) => void;
  /* A label to display to the right of the slider, denoting the maximum value in some cases */
  rightSideLabel?: string;
  ariaLabelledBy?: string;
  theme: Theme;
  disabled?: boolean;
  /* For accessibility there are instances where we may need to handle the data in one way, but
   * explain it to a screen reader differently. For example, the slider may need a percentage
   * value as 0.15, but the screen reader would want 15%. */
  ariaLabelFormatter?: (value: Value) => number;
};

/* Wrapper for InputRange, a control that allows adjusting either one or two
 * handles along a horizontal plane */
class RangeSlider extends Component<Props, State> {
  sliderRef: React.RefObject<HTMLDivElement> = React.createRef();
  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  handleChange = (value: Value): void => {
    const { step } = this.props;
    const { current: Slider } = this.sliderRef;

    this.setState({ value });

    /* This isn't pretty, but it moves the labels apart slightly to maintain legibility when the two slider
     * handles are very close to one another.  This is our best fix, since we can't change the positioning
     * of the HTML elements in the slider library */
    if (typeof value === 'object' && Slider) {
      const labels = Slider.querySelectorAll(
        '.input-range__slider-container .input-range__label--value'
      ) as NodeListOf<HTMLElement>;

      if (precisionRound(Math.abs(value.max - value.min), 4) <= 1 * step) {
        labels[0].style.transform = 'translateX(-10px)';
        labels[1].style.transform = 'translateX(10px)';
      } else if (
        precisionRound(Math.abs(value.max - value.min), 4) <=
        2 * step
      ) {
        labels[0].style.transform = 'translateX(-5px)';
        labels[1].style.transform = 'translateX(5px)';
      } else {
        labels[0].style.transform = 'translateX(0)';
        labels[1].style.transform = 'translateX(0)';
      }
    }

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.value, this.props.value)) {
      this.setState({ value: this.props.value });
    }
  }

  handleChangeComplete = (value: Value) => {
    const { onChangeComplete, handleReportValueSelection } = this.props;

    if (onChangeComplete) {
      onChangeComplete(value);
    }

    /* We don't know whether the user changed the right or left slider handle, so reporting `null` for value */
    handleReportValueSelection(null);
  };

  render() {
    const {
      theme,
      minValue,
      maxValue,
      labelFormatter,
      step,
      disabled,
      onChangeStart,
      rightSideLabel,
      className,
      dataHcName,
      ariaLabelledBy,
      ariaLabelFormatter,
    } = this.props;

    return (
      <div
        className={classNames(theme.RangeSlider, className)}
        data-hc-name={dataHcName}
        ref={this.sliderRef}
      >
        <InputRange
          minValue={minValue}
          maxValue={maxValue}
          value={this.state.value}
          formatLabel={labelFormatter}
          step={step}
          disabled={disabled}
          onChange={this.handleChange}
          onChangeStart={onChangeStart}
          onChangeComplete={this.handleChangeComplete}
          ariaLabelledby={ariaLabelledBy}
          formatAriaValues={ariaLabelFormatter}
        />
        {rightSideLabel && (
          <span className={theme.RightSideLabel}>{rightSideLabel}</span>
        )}
      </div>
    );
  }
}

const ThemedRangeSlider = themr('RangeSlider', defaultTheme)(RangeSlider);
export default ThemedRangeSlider;
