import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import defaultTheme from '@client/css-modules/FooterSubsection.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import {
  getCobrandDisplayName,
  getFooterSubsectionDetails,
} from '@client/store/selectors/cobranding.selectors';

type FooterSubsectionProps = { theme: Theme };

const FooterSubsection: FC<FooterSubsectionProps> = ({ theme }) => {
  const cobrandDisplayName = useSelector(getCobrandDisplayName);
  const { logoUrl, text } = useSelector(getFooterSubsectionDetails);
  const { mobileLogoUrl } = useCobrandStyles();

  return (
    <section
      className={theme.FooterSubsection}
      data-hc-name={`${cobrandDisplayName}-footer`}
    >
      {logoUrl ? (
        <div className={theme.FooterLogo}>
          <img src={logoUrl} alt={cobrandDisplayName} />
        </div>
      ) : (
        mobileLogoUrl && (
          <div className={theme.FooterLogo}>
            <img src={mobileLogoUrl} alt={cobrandDisplayName} />
          </div>
        )
      )}
      {!!text && (
        <div className={theme.FooterText}>
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      )}
    </section>
  );
};

export const ThemedFooterSubsection = themr(
  'FooterSubsection',
  defaultTheme
)(FooterSubsection);
export default ThemedFooterSubsection;
