import HC_CONSTANTS from '@client/app.config';
import { getAccessToken } from '@client/store/selectors/auth.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { getOutboundUrl } from '@client/utils/url-formatting.utils';
import { useSelector } from 'react-redux';

export const useOutboundCtaUrl = (
  originalCtaUrl: string | null
): string | null => {
  const isOutboundCtaEnabled = useSelector(
    getIsFeatureEnabled('outbound_cta_links_have_tokens')
  );
  const accessToken = useSelector(getAccessToken);

  if (isOutboundCtaEnabled && accessToken && HC_CONSTANTS.OUTBOUND_CTA_URL) {
    return originalCtaUrl && getOutboundUrl({ originalCtaUrl, accessToken });
  } else {
    return originalCtaUrl;
  }
};
