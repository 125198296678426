import { connect } from 'react-redux';

import { reportEvent } from '@client/store/actions/analytics.actions';
import LoanQualificationLetterChase from '@client/components/cobrand/chase/LoanQualificationLetterChase';
import { getUserBuyingPower } from '@client/store/selectors/auth.selectors';
import {
  getPropertyYearBuilt,
  getPropertyDetailsNormalized,
} from '@client/store/selectors/property-details.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { chaseCTAClick } from '@client/store/actions/cobranding.actions';
import { ChaseCTAEventParams } from '@client/store/types/chase-integration-data';
import { getJSBridgePropertyTypeForPropertyType } from '@client/store/sagas/chase-integration.saga';

const mapStateToProps = (state) => {
  return {
    propertyDetails: getPropertyDetailsNormalized(state),
    userBuyingPower: getUserBuyingPower(state),
    yearBuilt: getPropertyYearBuilt(state),
    isFeatureEnabledForWebApp: getIsFeatureEnabled(
      'chase_offer_letter_cta_in_web_app'
    )(state),
    getJSBridgePropertyTypeForPropertyType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleClick: (eventParams: ChaseCTAEventParams) => {
    dispatch(chaseCTAClick(eventParams));
  },
  reportSeeLetterClick: () => dispatch(reportEvent('click_see_letter')),
  reportOffMarketSeeLetterClick: () =>
    dispatch(reportEvent('click_pdp_off_market_see_letter')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoanQualificationLetterChase);
