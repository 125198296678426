/**
 * Whether Google Analytics 4 type events should be sent, given a GA tracker ID
 * GA4 account ids always start with G-XXX when older UA account ids start with UA-XXX
 */
import { Action } from '@client/store/actions';
import { ReduxState } from '@client/store/types/redux-state';
import { SearchWidgetReduxState } from '@src/widgets/search-widget/store/types';
import { View } from '@client/routes/constants';
import {
  selectContextualSearchId,
  selectGenericViewId,
  selectHICEstimateId,
  selectPDPViewId,
} from '@client/store/slices/analytics-data.slice';
import { v4 as uuidv4 } from 'uuid';

type ActionWithAnalytics = {
  type: Action['type'];
  payload: any;
  meta: {
    analytics: {
      event: string;
    };
  };
};

export const isMainAppReduxState = (
  state: ReduxState | SearchWidgetReduxState
): state is ReduxState => {
  return !!(state as ReduxState).search;
};

export const isGenericView = (view) => {
  const viewsWithSpecificIDs = [
    View.SEARCH,
    View.PROPERTY_DETAILS,
    View.HOME_IMPROVEMENT,
  ];
  return !viewsWithSpecificIDs.includes(view);
};

export const getIDSelectorByView = (view: View | null) =>
  view === View.SEARCH
    ? selectContextualSearchId
    : view === View.PROPERTY_DETAILS
    ? selectPDPViewId
    : view === View.HOME_IMPROVEMENT
    ? selectHICEstimateId
    : selectGenericViewId;

export const generateUUID = () => uuidv4();

export const getActionHasAnalytics = (
  action
): action is ActionWithAnalytics => {
  return !!(action as ActionWithAnalytics)?.meta?.analytics?.event;
};
