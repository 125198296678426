import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import React, { Component } from 'react';
import { useSelector } from 'react-redux';

import HC_CONSTANTS from '@client/app.config';
import ChevronIconCobranded from '@client/components/ChevronIcon/ChevronIconCobranded';
import ClickableLogo from '@client/components/ClickableLogo';
import CobrandedDataComponent, {
  CobrandedDataComponentArgs,
} from '@client/components/CobrandedDataComponent';
import CobrandedStyles from '@client/components/CobrandedStyles';
import Copyright from '@client/components/Copyright';
import FooterLogoCobranded from '@client/components/FooterLogo/FooterLogoCobranded';
import FooterSubsection from '@client/components/FooterSubsection';
import ExternalLink from '@client/components/generic/ExternalLink';
import VerticalSeparator from '@client/components/generic/VerticalSeparator';
import HUDLogo from '@client/components/HUDLogo';
import RouterLink from '@client/components/RouterLink';
import YourTeamTriggerButton from '@client/components/YourTeam/YourTeamTriggerButtonCobranded';
import MLSCopyrightSectionContainer from '@client/containers/mls-copyright-section.container';

import defaultTheme from '@client/css-modules/Footer.css';
import { View } from '@client/routes/constants';
import { CITY_LINKS_DATA_FOR_NEW_FOOTER } from '@client/store/constants';
import { UtmState } from '@client/store/reducers/utm.reducer';
import { getIsFourHundredZoom } from '@client/store/selectors/match-media.selectors';
import { getIsShowingAlternateYourTeamTriggerButton } from '@client/store/selectors/modals.selectors';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';

const CITY_COUNT_TO_DISPLAY_ON_SMALL_SCREENS = 3;

const generateUtmParams = (utmState: UtmState) => {
  const utmArr = Object.keys(utmState).map((key) => {
    return `${key}=${utmState[key]}`;
  });
  const utmString = utmArr.join('&');
  return utmString ? `?${utmString}` : '';
};

const DefaultFooterWrapper: React.FunctionComponent<{
  theme: Theme;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}> = ({ children, theme, style }) => {
  const isAlternateYourTeamTriggerButtonShowing = useSelector(
    getIsShowingAlternateYourTeamTriggerButton
  );
  const isFourHundredPercentZoom = useSelector(getIsFourHundredZoom);
  return (
    <footer
      role="contentinfo"
      className={theme.Footer}
      data-hc-name="footer-section"
      style={style}
    >
      {
        /* If we're not showing the "alternate" YT button on the mobile PDP and we are at 400% zoom then show this global button */
        !isAlternateYourTeamTriggerButtonShowing &&
          isFourHundredPercentZoom && (
            <div className={theme.YourTeamTriggerButtonWrapper}>
              <YourTeamTriggerButton />
            </div>
          )
      }
      {children}
    </footer>
  );
};

const SectionFooterWrapper: React.FunctionComponent<{
  theme: Theme;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}> = ({ children, theme, style }) => {
  const isAlternateYourTeamTriggerButtonShowing = useSelector(
    getIsShowingAlternateYourTeamTriggerButton
  );
  const currentView = useSelector(getCurrentView);
  const isFourHundredPercentZoom = useSelector(getIsFourHundredZoom);
  const fourHundredZoomStyles =
    isFourHundredPercentZoom && currentView === View.SEARCH;
  return (
    <section
      className={theme.Footer}
      data-hc-name="footer-section"
      style={fourHundredZoomStyles ? { ...style, paddingBottom: 0 } : style}
    >
      {
        /* If we're not showing the "alternate" YT button on the mobile PDP and we are at 400% zoom then show this global button */
        !isAlternateYourTeamTriggerButtonShowing &&
          isFourHundredPercentZoom && (
            <div className={theme.YourTeamTriggerButtonWrapper}>
              <YourTeamTriggerButton />
            </div>
          )
      }
      {children}
    </section>
  );
};

type Props = {
  theme: Theme;
  shouldUseSectionElement?: boolean;
  isShowingHUDLogo: boolean;
  isShowingMLSInfo: boolean;
  shouldShowCondensedList: boolean;
  reportStoreIosClick?: () => void;
  reportStoreAndroidClick?: () => void;
  isInsideNativeApp: boolean;
  supportUrl?: string | null;
  style?: React.CSSProperties;
  isShowingBrokerLink: boolean;
  utmState: UtmState;
  isLODirectEnabled?: boolean;
};

type State = {
  isShowingAllCities: boolean;
};

export class Footer extends Component<Props, State> {
  state: State = {
    isShowingAllCities: false,
  };

  getCityLinks = (theme: Theme): JSX.Element[] => {
    return CITY_LINKS_DATA_FOR_NEW_FOOTER.sort((a, b) => {
      if (a.NAME < b.NAME) {
        return -1;
      } else {
        return 1;
      }
    }).map((city) => {
      /*
        NOTE: The city list items have a vertical separator added via the .CityLi
        class so that we are not adding list items for non-content "|" characters.
        This is to fulfill accessibility requirements.
      */
      return (
        <li key={city.PLACESLUG} className={theme.CityLi}>
          <RouterLink
            view={View.CITIES}
            params={{ placeSlug: city.PLACESLUG }}
            className={theme.Link}
          >
            {city.NAME}
          </RouterLink>
        </li>
      );
    });
  };

  reduceNodes = (theme: Theme, elements): JSX.Element => {
    const renderedProps = elements.reduce((accu, elem) => {
      return accu === null ? [elem] : [...accu, elem];
    }, null);

    return <React.Fragment>{renderedProps}</React.Fragment>;
  };

  renderCityLinks = (theme: Theme): JSX.Element => {
    let cityLinks = this.getCityLinks(theme);
    if (this.props.shouldShowCondensedList && !this.state.isShowingAllCities) {
      cityLinks = cityLinks.slice(0, CITY_COUNT_TO_DISPLAY_ON_SMALL_SCREENS);
    }
    return this.reduceNodes(theme, cityLinks);
  };

  toggleCityLinksCount = () => {
    this.setState((state) => ({
      isShowingAllCities: !state.isShowingAllCities,
    }));
  };

  render() {
    const { isShowingAllCities } = this.state;
    const {
      theme,
      shouldShowCondensedList,
      shouldUseSectionElement,
      isShowingHUDLogo,
      isShowingMLSInfo,
      supportUrl,
      style,
      isShowingBrokerLink,
      utmState,
      isLODirectEnabled,
    } = this.props;

    const utmParams = generateUtmParams(utmState);
    const FooterWrapperComponent = !shouldUseSectionElement
      ? DefaultFooterWrapper
      : SectionFooterWrapper;
    const cityLinksCount = this.getCityLinks(theme).length;
    const isInternalSupportUrl =
      supportUrl && (Object.values(View) as string[]).includes(supportUrl);

    return (
      <CobrandedDataComponent>
        {({
          customizationData: {
            should_show_privacy_policy_links,
            should_show_city_links,
          },
        }: CobrandedDataComponentArgs) => (
          <CobrandedStyles>
            {({ linkColor }) => {
              const showContactUsLink = !!supportUrl;
              return (
                <FooterWrapperComponent theme={theme} style={style}>
                  <div className={theme.PreFooterContent}>
                    <ClickableLogo
                      dataHcName={'comehome-logo'}
                      ariaLabel="ComeHome by HouseCanary"
                      className={theme.ComeHomeFooterLogo}
                      theme={theme}
                      Logo={FooterLogoCobranded}
                    />
                    {should_show_city_links && (
                      <div
                        className={theme.CityLinksContainer}
                        data-hc-name={'city-links'}
                      >
                        {cityLinksCount > 0 && (
                          <ul className={theme.CitiesUl}>
                            {this.renderCityLinks(theme)}
                          </ul>
                        )}
                        {cityLinksCount > 1 && shouldShowCondensedList && (
                          <div key="ContentAnimation">
                            <button
                              type="button"
                              aria-label={
                                isShowingAllCities
                                  ? 'Show Less Cities'
                                  : 'Show More Cities'
                              }
                              aria-expanded={isShowingAllCities}
                              className={classNames(theme.MoreCityLinks, {
                                [theme.active]: isShowingAllCities,
                              })}
                              onKeyPress={onEnterOrSpaceKey(
                                this.toggleCityLinksCount
                              )}
                              onClick={this.toggleCityLinksCount}
                            >
                              {!isShowingAllCities && (
                                <VerticalSeparator theme={this.props.theme} />
                              )}
                              <span
                                className={theme.CityTextLink}
                                style={{ color: linkColor }}
                              >
                                {isShowingAllCities ? (
                                  <span className={theme.ShowLessText}>
                                    Less
                                  </span>
                                ) : (
                                  'More'
                                )}
                              </span>
                              <ChevronIconCobranded
                                className={theme.ChevronIcon}
                              />
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <section
                    className={theme.FooterElement}
                    data-hc-name={'site-links-section'}
                  >
                    <ul className={theme.SiteLinks} data-hc-name={'site-links'}>
                      {/*
                              NOTE: The site link list items have a vertical separator added via the .SiteLinkItem
                              class so that we are not adding list items for non-content "|" characters.
                              This is to fulfill accessibility requirements.
                            */}
                      <li className={theme.SiteLinkItem}>
                        <ClickableLogo
                          dataHcName="comehome-footer-link"
                          aria-label="ComeHome"
                          Logo={(props) => (
                            <div
                              {...props}
                              className={classNames(
                                theme.ComehomeFooterLink,
                                theme.Link
                              )}
                            >
                              ComeHome
                            </div>
                          )}
                        />
                      </li>
                      {isLODirectEnabled && (
                        <li className={theme.SiteLinkItem}>
                          <RouterLink
                            key="loan-officer-login"
                            data-hc-name="chd-footer-link"
                            view={View.LO_DIRECT_CLIENTS}
                            className={theme.Link}
                            target="_blank"
                          >
                            Loan Officer Login
                          </RouterLink>
                        </li>
                      )}
                      {showContactUsLink && (
                        <li className={theme.SiteLinkItem}>
                          {showContactUsLink &&
                            (isInternalSupportUrl ? (
                              <RouterLink
                                key="contact-us"
                                ariaLabel="contact us"
                                view={supportUrl}
                                className={theme.Link}
                              >
                                Contact Us
                              </RouterLink>
                            ) : (
                              <ExternalLink
                                className={theme.Link}
                                href={supportUrl as string}
                              >
                                Contact Us
                              </ExternalLink>
                            ))}
                        </li>
                      )}
                      {should_show_privacy_policy_links && (
                        <>
                          <li className={theme.SiteLinkItem}>
                            <RouterLink
                              key="footer-privacy-policy"
                              ariaLabel="privacy policy"
                              view={View.PRIVACY_POLICY}
                              className={theme.Link}
                            >
                              Privacy Policy
                            </RouterLink>
                          </li>
                        </>
                      )}
                      <li className={theme.SiteLinkItem}>
                        <RouterLink
                          key="footer-terms-of-use"
                          ariaLabel="terms of use"
                          view={View.TERMS_OF_USE}
                          className={theme.Link}
                        >
                          Terms of Use
                        </RouterLink>
                      </li>
                    </ul>
                    <div className={theme.Text} data-hc-name={'info-links'}>
                      <div>
                        <Copyright theme={theme} />
                        <span data-hc-name={'copyright-desc'}>
                          , Inc. All Rights Reserved
                        </span>
                      </div>
                      <div
                        className={theme.DRENotice}
                        data-hc-name={'dre-notice'}
                      >
                        {HC_CONSTANTS.DRE_NOTICE}
                      </div>
                      <div data-hc-name={'notice-links'}>
                        <ExternalLink
                          className={theme.ExternalLink}
                          style={{ color: linkColor }}
                          href="https://uploads-ssl.webflow.com/659c81c0f2b2def2180e9b9f/661841246c4747e58caeaeb5_TX-Information-about-Brokerage-Services.pdf"
                        >
                          TREC Information About Brokerage Services
                        </ExternalLink>
                      </div>
                      <div>
                        <ExternalLink
                          className={theme.ExternalLink}
                          style={{ color: linkColor }}
                          href="https://www.trec.texas.gov/forms/consumer-protection-notice"
                        >
                          TREC Consumer Protection Notice
                        </ExternalLink>
                      </div>
                      <div>
                        <ExternalLink
                          className={theme.ExternalLink}
                          style={{ color: linkColor }}
                          href="https://uploads-ssl.webflow.com/659c81c0f2b2def2180e9b9f/65cd53995c0ab0688cb07362_LS-NY-SOP.pdf"
                        >
                          NY Standard Operating Procedures
                        </ExternalLink>
                      </div>
                      {isShowingBrokerLink && (
                        <div>
                          <ExternalLink
                            className={theme.ExternalLink}
                            style={{ color: linkColor }}
                            href={`https://www.housecanary.com/brokerage${utmParams}`}
                          >
                            Contact
                          </ExternalLink>
                          <span className={theme.BrokerName}>
                            {' '}
                            Thomas Karras | Principal Broker
                          </span>
                        </div>
                      )}
                    </div>
                  </section>
                  <FooterSubsection />
                  <section data-hc-name={'mls-section'}>
                    {isShowingMLSInfo && (
                      <MLSCopyrightSectionContainer theme={theme} />
                    )}
                    {isShowingHUDLogo && <HUDLogo theme={theme} />}
                  </section>
                </FooterWrapperComponent>
              );
            }}
          </CobrandedStyles>
        )}
      </CobrandedDataComponent>
    );
  }
}

export const ThemedFooter = themr('Footer', defaultTheme)(Footer);

export default ThemedFooter;
