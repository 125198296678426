import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import theme from '@client/css-modules/LoadMoreResultsButton.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type Props = {
  handleLoadMoreClick: () => void;
};

const LoadMoreResultsButton: React.FC<Props> = ({ handleLoadMoreClick }) => {
  const { defaultTextColor } = useCobrandStyles();
  return (
    <div className={theme.LoadMoreResultsContainer}>
      <button
        className={theme.LoadMoreResults}
        style={{ color: defaultTextColor }}
        type="button"
        onKeyDown={onEnterOrSpaceKey(handleLoadMoreClick)}
        onClick={handleLoadMoreClick}
      >
        Load More Results
      </button>
    </div>
  );
};

export default LoadMoreResultsButton;
