import { connect } from 'react-redux';
import HomeownerHomeBuyerToggle from '@client/components/HomeownerHomeBuyerToggle';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import { View } from '@client/routes/constants';
import { getBuyerOrOwnerPropertySlug } from '@client/store/selectors/homeowner.selectors';
import { reportEvent } from '@client/store/actions/analytics.actions';

const mapStateToProps = (state) => ({
  isOnHomeowner: getCurrentView(state) === View.HOMEOWNER_PROPERTY_DETAILS,
  slug: getBuyerOrOwnerPropertySlug(state),
});

const mapDispatchToProps = (dispatch) => ({
  reportNavigation: (isOnHomeowner: boolean) => {
    const eventName = isOnHomeowner
      ? 'click_owner_public_view'
      : 'click_property_details_owner_view';
    dispatch(reportEvent(eventName));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeownerHomeBuyerToggle);
