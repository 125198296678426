import { Theme } from '@friendsofreactjs/react-css-themr';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { getCobrandStyles } from '@client/cobrand-settings/cobrand-styles';
import MobileFilterControl from '@client/components/MobileFilterControl';
import {
  reportEvent,
  reportSearchFilterValueSelection,
} from '@client/store/actions/analytics.actions';
import { clearCurrentSavedSearch } from '@client/store/actions/saved-search.actions';
import {
  searchCancelPendingQueries,
  searchClearSelectedFilterInitialValue,
  searchSetSelectedFilterInitialValue,
  searchUpdateFilter,
} from '@client/store/actions/search.actions';
import { FilterKey } from '@client/store/filter-constants';
import { getUserBuyingPower } from '@client/store/selectors/auth.selectors';
import {
  getCobrandDisplayName,
  getCobrandId,
  getDomainTheme,
} from '@client/store/selectors/cobranding.selectors';
import {
  getIsFeatureEnabled,
  getSearchListMaxResultCount,
} from '@client/store/selectors/enabled-features.selectors';
import { getIsXXSmallSize } from '@client/store/selectors/match-media.selectors';
import {
  getActiveFilters,
  getSearchFilters,
  getSearchPropertyTotalCount,
  getSelectedFilterInitialValue,
} from '@client/store/selectors/search.selectors';
import { FilterPickerValue } from '@client/store/types/filters';

type OwnProps = {
  filterKey: FilterKey;
  handleClose: () => void;
  handleCancel?: () => void;
  handleApply?: () => void;
  needsMobileHeightAdjustment: boolean;
  theme: Theme;
};

const mapStateToProps = (state, ownProps: OwnProps) => {
  const filterKey = ownProps.filterKey;
  const filterValuesByKey = getSearchFilters(state);
  const cobrandId = getCobrandId(state);
  const domainTheme = getDomainTheme(state);

  return {
    filterKey,
    filterValuesByKey,
    propertyCount: getSearchPropertyTotalCount(state),
    isXXSmallScreenSize: getIsXXSmallSize(state),
    isDisplayMultiFamilySearchFiltersEnabled: getIsFeatureEnabled(
      'temp_display_multi_family_search_filters'
    )(state),
    activeFilterValuesByKey: getActiveFilters(state),
    initialValue: getSelectedFilterInitialValue(state),
    userBuyingPower: getUserBuyingPower(state),
    cobrandDisplayName: getCobrandDisplayName(state),
    activeBuyingPowerColor: getCobrandStyles(cobrandId, domainTheme)
      .activeBuyingPowerColor,
    maxResultCount: getSearchListMaxResultCount(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleValueChange: (key, value) => {
    dispatch(searchCancelPendingQueries());
    dispatch(searchUpdateFilter(key, value));
    dispatch(clearCurrentSavedSearch());
  },
  setInitialFilterValue: (key, value) =>
    dispatch(searchSetSelectedFilterInitialValue(key, value)),
  clearInitialFilterValue: () =>
    dispatch(searchClearSelectedFilterInitialValue()),
  reportQuickFilterMobileApplyClick: (filterKey) =>
    dispatch(reportEvent('click_mobile_filter_apply', '', { filterKey })),
  reportQuickFilterMobileCancelClick: (filterKey) =>
    dispatch(reportEvent('click_mobile_filter_cancel', '', { filterKey })),
  reportBuyingPowerOkClick: () =>
    dispatch(reportEvent('click_buying_power_ok')),
  reportBuyingPowerCancelClick: () =>
    dispatch(reportEvent('click_buyer_power_cancel')),
  reportValueSelection: (
    filterKey: FilterKey,
    descriptor: string | null,
    value: FilterPickerValue | null
  ) => {
    dispatch(reportSearchFilterValueSelection(filterKey, descriptor, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileFilterControl);
