import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import React from 'react';

type LLKey = string | number;

type Props = {
  llKey: LLKey;
  removeComponentFromCache: (llKey: LLKey) => void;
  shouldRenderChild: boolean;
  style: {
    width: string;
    height?: string;
    minHeight?: string;
  };
  theme: Theme;
  childHTMLEl: keyof JSX.IntrinsicElements;
  children?: React.ReactNode;
};

const checkIfValidProps = (props) => {
  const childArr = React.Children.toArray(props.children);
  if (childArr.length !== 1) {
    throw new Error(
      'Each LazilyRenderedComponent must contain exactly one child'
    );
  }
  if (!props.llKey) {
    throw new Error(
      `Each LazilyRenderedComponent be passed a llKey prop.  You passed: ${props.llKey}`
    );
  }
};

/* A component intended for use with LazyRenderedList, to only render items currently
 * within the viewport */
class LazilyRenderedComponent extends React.PureComponent<Props> {
  componentDidMount() {
    checkIfValidProps(this.props);
  }

  componentWillUnmount() {
    this.props.removeComponentFromCache(this.props.llKey);
  }
  componentDidCatch() {
    console.log('error in lazy component');
  }

  render() {
    const { theme, childHTMLEl = 'li' } = this.props;
    const Component = childHTMLEl;

    return (
      <Component
        data-scroll-to-key={this.props.llKey}
        className={theme.LazilyRenderedComponent}
        style={this.props.style}
      >
        {this.props.shouldRenderChild && this.props.children}
      </Component>
    );
  }
}

const ThemedLazilyRenderedComponent = themr(
  'LazilyRenderedComponent',
  {}
)(LazilyRenderedComponent);
export default ThemedLazilyRenderedComponent;
