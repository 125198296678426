import PillButton from '@client/components/generic/PillButton';
import MarketingModuleHouseImg from '@client/components/HomeownerAdImg/MarketingModuleHouseImg';
import NewIcon from '@client/components/NewIcon';
import defaultTheme from '@client/css-modules/HomeownerUpsellAd.css';
import { LockedComponent } from '@client/hocs/locked-component';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { getPropertyDetailPageConfig } from '@client/store/selectors/cobranding.selectors';
import {
  SectionId,
  SpecialUserType,
} from '@client/store/types/locked-components';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

type Props = {
  claimAndRedirectToHomeowner: (slug: string) => void;
  slug: string | null;
  theme: Theme;
};

function HomeownerUpsellAd({
  claimAndRedirectToHomeowner,
  slug,
  theme,
}: Props) {
  const { homeownerAdBtnRadius, homeownerAdBg, homeownerAdTextColor } =
    useCobrandStyles();
  const propertyPageSettings = useSelector(getPropertyDetailPageConfig);
  return (
    <div
      data-hc-name="upsell-section"
      className={theme.HomeownerUpsellAd}
      style={{ backgroundColor: homeownerAdBg }}
    >
      <div>
        <NewIcon />
        <div className={theme.Title} style={{ color: homeownerAdTextColor }}>
          {propertyPageSettings?.claimHomeHeader || 'Do you own this home?'}
        </div>
        <div
          className={theme.Description}
          style={{ color: homeownerAdTextColor }}
        >
          {propertyPageSettings?.claimHomeSubHeader ||
            'Access your homeowner dashboard'}
        </div>
        <div>
          <LockedComponent
            sectionId={SectionId.PdpHomeownerUpsellAd}
            lockedFor={[SpecialUserType.Restricted]}
            className={theme.LockedComponentContainer}
            theme={theme}
          >
            {({ isLocked }) => (
              <PillButton
                disabled={isLocked}
                deemphasized={isLocked}
                data-event-name={'click_property_details_owner_promo'}
                data-parent-event-name={
                  PARENT_EVENTS.CLICK_PROPERTY_DETAILS_OWNER
                }
                style={{
                  ...(homeownerAdBtnRadius && {
                    borderRadius: homeownerAdBtnRadius,
                  }),
                }}
                className={classNames(theme.CtaButton, {
                  [theme.disabled]: isLocked,
                })}
                onClick={() => {
                  slug && claimAndRedirectToHomeowner(slug);
                }}
              >
                {propertyPageSettings?.claimHomeCTALabel || 'Claim home'}
              </PillButton>
            )}
          </LockedComponent>
        </div>
      </div>
      {
        <div className={theme.HomeownerAdImgContainer}>
          <MarketingModuleHouseImg className={theme.HomeownerAdImg} />
        </div>
      }
    </div>
  );
}

const ThemedHomeownerUpsellAd = themr(
  'HomeownerUpsellAd',
  defaultTheme
)(HomeownerUpsellAd);
export default ThemedHomeownerUpsellAd;
