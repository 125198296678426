import { loadableReady } from '@loadable/component';
import React from 'react';
import ReactDOM from 'react-dom';
import { hydrateRoot } from 'react-dom/client';

import ClientAppWrapper from '@client/app/ClientAppWrapper';
import { localStorageUtil } from '@client/utils/local-storage.utils';

/**
 * Used to render App client-side
 */
export function init() {
  loadableReady(() => {
    /* SECURITY: This is used only to enable functionality for convenient debugging, nothing additional is exposed */
    /* eslint-disable-next-line custom/explain-localstorage */
    if (localStorageUtil.getItem('chaseJSBridgeDebug')) {
      (window as any).ThirdPartyWVToNativeChaseJSBridgeHandler = {
        goToNativePage: (params) => {
          console.log(
            'ThirdPartyWVToNativeChaseJSBridgeHandler.goToNativePage:',
            params
          );
        },
        externalBrowser: (params) => {
          console.log(
            'ThirdPartyWVToNativeChaseJSBridgeHandler.externalBrowser:',
            params
          );
        },
      };
    }

    if (
      process.env.NODE_ENV === 'development' &&
      /* eslint-disable-next-line custom/explain-localstorage */
      !localStorageUtil.getItem('disableReactAxe')
    ) {
      const reactAxe = require('@axe-core/react');
      /* NOTE: With this positioning in our app, reactAxe first runs prior to the client finishing rendering, and therefore
       * can't check the correct DOM layout. It's wrapped in a setTimeout to make the initial function call come after rendering. */
      window.setTimeout(() => {
        /*
         * need to pass in empty object for third arg to prevent page from breaking
         * https://github.com/dequelabs/axe-core-npm/issues/176
         */
        reactAxe(React, ReactDOM, 4000, {});
      }, 4000);
    }
    const root = document.getElementById('react-root');
    if (root) {
      hydrateRoot(root, <ClientAppWrapper />);
    }
  });
}

/* NOTE: this only fires if your localStorage has the key `reduxDebug`. If you don't, `window._store` is undefined. */
export function throwError() {
  (window as any)._store.dispatch({
    type: 'THROW_TEST_ERROR',
  });
}
