import { ChaseCTAEventParams } from '@client/store/types/chase-integration-data';

export const CHASE_CTA_CLICK = 'CHASE_CTA_CLICK';
export const CHASE_LENDER_CTA_CLICK = 'CHASE_LENDER_CTA_CLICK';
export const LOG_TOU_ACCEPTANCE = 'LOG_TOU_ACCEPTANCE';
export const CHASE_OPEN_IN_EXTERNAL_BROWSER = 'CHASE_OPEN_IN_EXTERNAL_BROWSER';
export const CHASE_WEB_REDIRECT = 'CHASE_WEB_REDIRECT';

export type ChaseCTAClickAction = {
  type: typeof CHASE_CTA_CLICK;
  payload: {
    params: ChaseCTAEventParams;
  };
};

/**
 * Dispatched when clicking a hardcoded Chase CTA (i.e. See Loan Options)
 */
export const chaseCTAClick = (
  params: ChaseCTAEventParams
): ChaseCTAClickAction => ({
  type: CHASE_CTA_CLICK,
  payload: {
    params,
  },
});

export type ChaseLenderCTAClickAction = {
  type: typeof CHASE_LENDER_CTA_CLICK;
  payload: {
    webRedirectUrl: string;
    nativeAppNavKey: string;
  };
};

/**
 * Dispatched when clicking a Parcon-defined Lender CTA (CTA copy, url, colors defined in Parcon)
 */
export const chaseLenderCTAClick = (
  webRedirectUrl: string,
  nativeAppNavKey: string
): ChaseLenderCTAClickAction => ({
  type: CHASE_LENDER_CTA_CLICK,
  payload: {
    webRedirectUrl,
    nativeAppNavKey,
  },
});

type LogTOUAcceptanceAction = {
  type: typeof LOG_TOU_ACCEPTANCE;
  meta: {
    analytics: {
      event: string;
      props: { slug: string } | { placeId: string };
    };
  };
};

export const logTOUAcceptance = (
  props: { slug: string } | { placeId: string }
): LogTOUAcceptanceAction => ({
  type: LOG_TOU_ACCEPTANCE,
  meta: {
    analytics: {
      event: 'click_cobrand_transition_page_tou_acceptance',
      props,
    },
  },
});

export interface ChaseOpenInExternalBrowser {
  type: typeof CHASE_OPEN_IN_EXTERNAL_BROWSER;
  payload: {
    url: string;
  };
}

export const chaseOpenInExternalBrowser = (
  url: string
): ChaseOpenInExternalBrowser => ({
  type: CHASE_OPEN_IN_EXTERNAL_BROWSER,
  payload: { url },
});
