import { consumerApiClient } from '@client/services/consumer-api-client';
import { watchEvery } from '@client/utils/saga.utils';
import { call } from 'redux-saga/effects';
import {
  OPT_IN_BUYER_EMAILS,
  USER_INVITE_AGREE_TO_TERMS,
} from '@client/store/actions/invite.actions';

export function* optInBuyerEmailsSaga(action) {
  yield call([consumerApiClient, consumerApiClient.optInBuyerEmails]);
}

export function* userInviteAgreeToTermsSaga(action) {
  yield call([consumerApiClient, consumerApiClient.agreeToTerms]);
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [OPT_IN_BUYER_EMAILS]: optInBuyerEmailsSaga,
    [USER_INVITE_AGREE_TO_TERMS]: userInviteAgreeToTermsSaga,
  });
};
