import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import PropertyCardValue, {
  PropertyCardValueProps,
} from '@client/components/PropertyCardValue/PropertyCardValue';
import PropertyCardValueInline, {
  PropertyCardValueInlineProps,
} from '@client/components/PropertyCardValue/PropertyCardValueInline';
import PropertyCardValueStacked, {
  PropertyCardValueStackedProps,
} from '@client/components/PropertyCardValue/PropertyCardValueStacked';
import { COBRAND_IDS } from '@client/store/types/cobranding';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return PropertyCardValueInline;
    case COBRAND_IDS.WWW:
      return PropertyCardValueStacked;
    default:
      return PropertyCardValue;
  }
};

const PropertyCardValueCobranded: React.FC<
  | PropertyCardValueInlineProps
  | PropertyCardValueProps
  | PropertyCardValueStackedProps
> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default PropertyCardValueCobranded;
