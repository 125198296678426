import React from 'react';
import pluralize from 'pluralize';

import VerticalSeparator from '@client/components/generic/VerticalSeparator';
import defaultTheme from '@client/css-modules/AvmDeepDiveTypicalHomeTooltip.css';

type Props = {
  areaMedian?: number;
  chartType?: string;
  label?: string;
  labelFormatter?: (areaMedian, chartType) => string;
  numberOfHomes?: number | null;
};

const AvmDeepDiveTypicalHomeTooltip = ({
  areaMedian,
  chartType,
  label,
  labelFormatter,
  numberOfHomes,
}: Props) => {
  return (
    <React.Fragment>
      <div className={defaultTheme.HeadingSection}>
        <div className={defaultTheme.TypicalHomeFilledCircle} />
        <div className={defaultTheme.Heading}>Typical home in the area</div>
      </div>
      <div className={defaultTheme.PropertyDataContainer}>
        <div className={defaultTheme.PropertyData}>
          {
            /**
             * labelFormatter is used for Regression line chart
             * areaMedian, chartType must be passed along with labelFormatter
             * label is used for Pie Chart
             */
            labelFormatter ? (
              <div>{labelFormatter(areaMedian, chartType)}</div>
            ) : (
              <div>{label}</div>
            )
          }
          {
            /**
             * numberOfHomes is only applicable for PieChart tooltips
             */
            numberOfHomes && (
              <React.Fragment>
                <VerticalSeparator theme={defaultTheme} />
                <div>
                  {numberOfHomes} {pluralize('home', numberOfHomes)}
                </div>
              </React.Fragment>
            )
          }
        </div>
      </div>
    </React.Fragment>
  );
};

export default AvmDeepDiveTypicalHomeTooltip;
