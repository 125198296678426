import { put, select } from 'redux-saga/effects';
import { routeChange } from '@src/redux-saga-router-plus/actions';

import { View } from '@client/routes/constants';
import { getPreviousParams } from '@src/redux-saga-router-plus/selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';

export default function* loadHomeownerPDPPage({ view, params, query }) {
  const isHomeownerEnabled = yield select(getIsFeatureEnabled('homeowner'));

  /*
   * If homeowner feature is disabled, redirect to search
   */
  if (!isHomeownerEnabled) {
    yield put(routeChange({ view: View.SEARCH }));
  }

  const { slug } = params;
  const previousParams = yield select(getPreviousParams);
  const { slug: previousSlug } = previousParams;

  /* Ensure we're at page top when navigating to new Homeowner page */
  if (slug !== previousSlug) {
    window.scrollTo(0, 0);
  }
}
