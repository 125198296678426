import React, { Component } from 'react';

import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { ConfirmModalProps } from '@client/store/types/cobranded-components/confirm-modal';
import SmallModal from '@client/components/generic/SmallModal';
import { createModalPortal } from '@client/hocs/create-modal-portal';
import theme from '@client/css-modules/ConfirmModalChase.css';
import CobrandedStyles from '@client/components/CobrandedStyles';
import removeWarningIconUrl from '@client/assets/images/cobrand/chase/chase-remove-warning.png';
import PillButton from '@client/components/generic/PillButton';

/**
 * A flexible confirmation modal
 */
class ConfirmModalChase extends Component<ConfirmModalProps, {}> {
  cancelButtonRef: React.RefObject<HTMLButtonElement> = React.createRef();

  componentDidUpdate(prevProps: ConfirmModalProps) {
    if (!prevProps.isActive && this.props.isActive) {
      /* Focus the cancel button */
      const { current } = this.cancelButtonRef;
      current && current.focus && current.focus();
    }
  }

  render() {
    const {
      isActive,
      title,
      body,
      confirmText,
      cancelText,
      handleConfirm,
      handleClose,
      handleReturnFocus,
      handleReportEvent,
    } = this.props;

    return (
      <CobrandedStyles>
        {({ pillButtonPrimaryBackgroundColor }) => (
          <SmallModal
            className={theme.ConfirmModal}
            isActive={isActive}
            handleClose={(e) => {
              if (isActive && handleReportEvent) {
                handleReportEvent('click_confirm_modal_back_button');
                handleClose(e);
              }
            }}
            handleReturnFocus={handleReturnFocus}
            theme={theme}
          >
            <div className={theme.ConfirmModalContent} aria-live="assertive">
              <div
                className={theme.ConfirmModalTitle}
                role="heading"
                aria-level={2}
              >
                <div
                  className={theme.RemoveWarningIconContainer}
                  style={{ backgroundImage: `url(${removeWarningIconUrl})` }}
                />
                {title}
              </div>
              <div className={theme.ConfirmModalBody}>{body}</div>
              <div className={theme.ConfirmModalFooter}>
                <PillButton
                  ariaLabel="confirm"
                  theme={theme}
                  onClick={handleConfirm}
                  onKeyDown={onEnterOrSpaceKey(handleConfirm)}
                >
                  {confirmText || 'Confirm'}
                </PillButton>
                <PillButton
                  data-event-name="click_confirm_modal_cancel"
                  forwardedRef={this.cancelButtonRef}
                  ariaLabel="cancel"
                  theme={theme}
                  style={{
                    color: pillButtonPrimaryBackgroundColor,
                    borderColor: pillButtonPrimaryBackgroundColor,
                  }}
                  onClick={handleClose}
                  onKeyDown={onEnterOrSpaceKey(handleClose)}
                  deemphasized
                >
                  {cancelText || 'Cancel'}
                </PillButton>
              </div>
            </div>
          </SmallModal>
        )}
      </CobrandedStyles>
    );
  }
}

export default createModalPortal(ConfirmModalChase, 'confirm-modal');
