import React from 'react';
import { reportToSentry } from '@client/utils/error.utils';

/**
 * Given a rendered DOM node, create a new React component with its attributes
 */
export const createComponentFromDOMNode = (
  node: Element
): React.ReactElement => {
  const nodeName = node.nodeName.toLowerCase();
  const prepareAttributes = (node: Element) => {
    /* Convert from NamedNodeMap to Array */
    const nodeAttributes = Array.prototype.slice.call(
      node.attributes
    ) as Attr[];
    const attributes = nodeAttributes.reduce((acc, curr) => {
      if (curr.name === 'class') {
        acc.className = curr.value;
      } else {
        acc[curr.name] = curr.value;
      }
      return acc;
    }, {} as { [key: string]: any });
    /* For canvas elements, copy over the drawn image inside after the canvas mounts */
    if (nodeName === 'canvas') {
      attributes.ref = (ele) => {
        if (ele) {
          let context = ele.getContext('2d');
          // Getting context has been shown to sometimes fail. In this case, we'll have a blank canvas,
          // but at least the rest of the CarouselRow images will load and display
          // https://sentry.io/housecanarycom/consumer-web-qa/issues/699635394
          if (context) {
            context.drawImage(node, 0, 0);
          } else {
            reportToSentry(`Unable to get context of image: ${node}`);
          }
        }
      };
    }
    return attributes;
  };

  return React.createElement(nodeName, prepareAttributes(node));
};

/**
 * Stop event bubbling and prevent default action
 */
export const stopEvent = (
  e:
    | React.MouseEvent
    | React.KeyboardEvent
    | React.ChangeEvent
    | MouseEvent
    | KeyboardEvent
): void => {
  e.stopPropagation();
  e.preventDefault();
};

export const key = {
  isArrowUp: (key: string) => ['ArrowUp', 'Up'].includes(key),
  isArrowDown: (key: string) => ['ArrowDown', 'Down'].includes(key),
  isArrowLeft: (key: string) => ['ArrowLeft', 'Left'].includes(key),
  isArrowRight: (key: string) => ['ArrowRight', 'Right'].includes(key),
  isEscape: (key: string) => ['Esc', 'Escape'].includes(key),
  isSpace: (key: string) => key === ' ',
  isTab: (key: string) => key === 'Tab',
  isBackTab: (e: KeyboardEvent) => e.shiftKey && e.key === 'Tab',
  isReturn: (key: string) => key === 'Enter',
  isBackspace: (key: string) => key === 'Backspace',
};

let globalGenericUidCounter = 0;
const GENERIC_UID_PREFIX = 'generic-uid-';

/* Generates a unique ID for a variety of situations.  Prefer using accessible-element-unique-id HOC
 * due to it not utilizing counter during SSR, which prevents client/server mismatch warnings */
export const generateGenericUid = (): string =>{
 return  process.env.NODE_ENV === 'test'
    ? `${GENERIC_UID_PREFIX}${typeof window['globalGenericCounter'] === 'number' ? window['globalGenericCounter']++ : 'test-value'}`
    : `${GENERIC_UID_PREFIX}${globalGenericUidCounter++}`;}
