import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import { SVGIconProps } from '@client/inline-svgs/types';
import LendcoChevron from '@client/inline-svgs/cobrand/lendco/lendco-chevron';
import ChevronIconChase from '@client/inline-svgs/chevron-chase';
import ChevronIconStandardGray from '@client/inline-svgs/chevron-standard-gray';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return ChevronIconChase;
    case COBRAND_IDS.LENDCO:
      return LendcoChevron;
    default:
      return ChevronIconStandardGray;
  }
};

const ChevronIconCobranded: React.FC<SVGIconProps> = (props: SVGIconProps) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default ChevronIconCobranded;
