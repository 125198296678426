import classNames from 'classnames';
import { findDOMNode } from 'react-dom';
import { debounce } from 'lodash';
import React, { Component } from 'react';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';

import ChevronIconStandardGray from '@client/inline-svgs/chevron-standard-gray';
import { onEnterKey } from '@client/utils/accessibility.utils';
import defaultTheme from '@client/css-modules/ResizableContainer.css';

/* TODO make this a prop and support right or left position */
const POSITION_OF_HANDLE = 'left';
const CLICK_MOVE_TOLERANCE = 5;
const STEP_DIRECTIONS = {
  BIGGER: '+',
  SMALLER: '-',
};

type Props = {
  /* Whether to show the resize handle, allowing resizing */
  allowResize: boolean;
  /* Callback executed on resize, with the container width as an argument */
  onResize?: (width: number) => void;
  /* If provided, increment the width of the container by this amount when the
   * resize handle is clicked */
  stepWidth: number;
  /* The padding inside of this container to account for when incrementing steps */
  totalPadding: number;
  /* The maximum width that the user is allowed to drag */
  maxWidth?: number;
  /* Whether to snap the width to a multiple of `stepWidth` after a drag resize */
  snapToIncrementAfterResize: boolean;
  /* Hook to the reporting action */
  handleReporting?: (amountHandleMoved: number) => void;
  theme: Theme;
  children?: React.ReactNode;
};

type State = {
  stepDirection: string;
};

/**
 * A component that is able to be resized by clicking or dragging a handle placed on the left side
 *
 * Clicking the drag handle will change the size to be a single column. Clicking again will
 * change back to the initial size.
 *
 * TODO the above doesn't work properly when deving locally since the CSS hasn't been applied at the
 * time of the component mounting (when `initialWidth` is recorded).  This is a problem in other areas
 * of the site as well, and should be addressed soon.
 */
class ResizableContainer extends Component<Props, State> {
  static defaultProps = {
    allowResize: true,
    onResize: () => {},
    handleReporting: () => {},
  };

  state = {
    stepDirection: STEP_DIRECTIONS.SMALLER,
  };

  componentNode: Element | null = null;
  debouncedResizeHandler: any = null;
  initialWidth: number = 0;
  width: number = 0;
  amountHandleMoved: number = 0;
  mouseDownHandlePosition: number = 0;

  initResize = (e: React.MouseEvent) => {
    window.addEventListener('mouseup', this.stopResize);
    window.addEventListener('mousemove', this.resize);
    this.mouseDownHandlePosition = e.clientX;
  };

  handleKeyDownResize = () => {
    this.incrementSize();
  };

  resize = (e) => {
    this.width = window.innerWidth - e.clientX;
    if (!this.componentNode) {
      throw new Error(
        'ResizableContainer: componentNode is not defined when attempting to resize'
      );
    }
    (this.componentNode as HTMLElement).style.width = `${this.width}px`;
    this.amountHandleMoved = this.mouseDownHandlePosition - e.clientX;
    this.debouncedResizeHandler();
  };

  handleResize = () => {
    const { onResize } = this.props;
    onResize && onResize(this.width);
  };

  stopResize = () => {
    const { handleReporting, snapToIncrementAfterResize } = this.props;
    window.removeEventListener('mouseup', this.stopResize);
    window.removeEventListener('mousemove', this.resize);
    handleReporting && handleReporting(this.amountHandleMoved);

    /* If clicking or moving the drag handle so little that we're counting as a click */
    if (Math.abs(this.amountHandleMoved) < CLICK_MOVE_TOLERANCE) {
      /* Increment the size either to one column or back to the original number of columns */
      this.incrementSize();
    } else if (snapToIncrementAfterResize) {
      this.snapToIncrement();
    }
    this.amountHandleMoved = 0;
    this.mouseDownHandlePosition = 0;
  };

  /* Increment the size either to one column or back to the original number of columns */
  incrementSize = () => {
    const { stepWidth, totalPadding, maxWidth } = this.props;
    const { stepDirection } = this.state;
    const newWidth =
      stepDirection === STEP_DIRECTIONS.BIGGER
        ? /* the initial width of the component, set by CSS */
          maxWidth && this.initialWidth > maxWidth
          ? maxWidth
          : this.initialWidth
        : /* a single column wide */
          stepWidth + totalPadding;
    if (!this.componentNode) {
      throw new Error(
        'ResizableContainer: componentNode is not defined when attempting to resize'
      );
    }
    (this.componentNode as HTMLElement).style.width = `${newWidth}px`;
    this.width = newWidth;
    this.handleResize();
    /* Toggle step direction */
    this.setIncrementDirection(
      stepDirection === STEP_DIRECTIONS.BIGGER
        ? STEP_DIRECTIONS.SMALLER
        : STEP_DIRECTIONS.BIGGER
    );
  };

  /* Snap to a multiple of the increment size */
  snapToIncrement = () => {
    const { stepWidth, totalPadding, maxWidth } = this.props;
    if (!this.componentNode) {
      throw new Error(
        'ResizableContainer: componentNode is not defined when attempting to resize'
      );
    }
    const componentWidth = this.componentNode.clientWidth;

    if (stepWidth) {
      /* Allow dragging to greater than maxWidth, but snap back if maxWidth exceeded */
      const effectiveComponentWidth =
        maxWidth && componentWidth > maxWidth ? maxWidth : componentWidth;
      const percentToNextStep =
        ((effectiveComponentWidth - totalPadding) % stepWidth) / stepWidth;
      const mathOperator = percentToNextStep > 0.5 ? 'ceil' : 'floor';
      /* Snap to the step nearest to the drop point */
      const steps = Math[mathOperator](
        (effectiveComponentWidth - totalPadding) / stepWidth
      );
      const newWidth = steps * stepWidth + totalPadding;

      if (!this.componentNode) {
        throw new Error(
          'ResizableContainer: componentNode is not defined when attempting to resize'
        );
      }
      (this.componentNode as HTMLElement).style.width = `${newWidth}px`;
      this.width = newWidth;

      this.handleResize();
      this.setIncrementDirection();
    }
  };

  setIncrementDirection = (forceBigger?: string) => {
    const { stepWidth, totalPadding } = this.props;
    const { stepDirection } = this.state;
    const maxWidth =
      this.props.maxWidth && this.props.maxWidth > window.innerWidth
        ? window.innerWidth
        : this.props.maxWidth;

    /* Whether to reverse step direction */
    if (
      maxWidth &&
      this.width + stepWidth + totalPadding > maxWidth &&
      stepDirection === STEP_DIRECTIONS.BIGGER
    ) {
      this.setState({ stepDirection: STEP_DIRECTIONS.SMALLER });
    } else if (
      forceBigger ||
      (this.width - stepWidth < stepWidth &&
        stepDirection === STEP_DIRECTIONS.SMALLER)
    ) {
      this.setState({ stepDirection: STEP_DIRECTIONS.BIGGER });
    }
  };

  componentDidMount() {
    /* We know that this component's rendered element can't be of type Text */
    this.componentNode = findDOMNode(this) as Element | null;
    this.initialWidth = this.componentNode!.clientWidth;
    this.debouncedResizeHandler = debounce(this.handleResize, 300);
  }

  componentWillUnmount() {
    this.debouncedResizeHandler.cancel();
  }

  render() {
    const {
      children,
      onResize,
      stepWidth,
      totalPadding,
      maxWidth,
      snapToIncrementAfterResize,
      allowResize,
      handleReporting,
      theme,
      ...rest
    } = this.props;
    const { stepDirection } = this.state;

    return (
      <div className={theme.ResizableContainer} {...rest}>
        {allowResize && (
          <button
            className={classNames(
              theme.Resizer,
              theme[`positioned-${POSITION_OF_HANDLE}`]
            )}
            type="button"
            onKeyDown={onEnterKey(this.handleKeyDownResize)}
            onMouseDown={this.initResize}
            aria-label={
              // This button resizes the search list container, not the map. So when this container shrinks the map grows.
              stepDirection === STEP_DIRECTIONS.BIGGER
                ? 'Reduce the size of the map'
                : 'Enlarge the map'
            }
            style={{ [POSITION_OF_HANDLE]: 0 }}
          >
            <ChevronIconStandardGray
              className={classNames(theme.ResizerIcon, {
                [theme.ResizeIconRight]:
                  stepDirection === STEP_DIRECTIONS.SMALLER,
              })}
            />
          </button>
        )}
        {children}
      </div>
    );
  }
}

const ThemedResizableContainer = themr(
  'ResizableContainer',
  defaultTheme
)(ResizableContainer);
export default ThemedResizableContainer;
