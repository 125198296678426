import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { useDispatch, useSelector } from 'react-redux';

import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { reportEvent } from '@client/store/actions/analytics.actions';
import {
  selectIsSRPGrantFilterOn,
  toggleSRPGrantFilter,
} from '@client/store/slices/grant-program.slice';

import CloseIcon from '@client/inline-svgs/close';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';

const ACTIVE_FILTER_ROW_DATA_ATTR = 'data-active-filter-row-key';

type Props = {
  theme: Theme;
  ariaLabelledBy?: string;
  onClick: () => void;
  onClose: () => void;
  onRemove?: () => void;
};

const GrantProgramMobileFilterListing = ({
  theme,
  ariaLabelledBy,
  onClick,
  onRemove,
}: Props) => {
  const dispatch = useDispatch();
  const isFilterOn = useSelector(selectIsSRPGrantFilterOn);
  const { activeFilterPillColor } = useCobrandStyles();
  const handleRemoveFilter = () => {
    dispatch(reportEvent('click_grant_eligible_search', 'searches_performed'));
    dispatch(toggleSRPGrantFilter());
    onRemove?.();
  };

  return (
    <div className={theme.FilterRowWrapper} role="group" aria-labelledby={ariaLabelledBy}>
      <button
        className={theme.FilterRow}
        /* Needed to set focus on this element after returning from filter control for a11y */
        {...{ [ACTIVE_FILTER_ROW_DATA_ATTR]: 'grant-program' }}
        type="button"
        aria-describedby={'grant-pill'}
        onClick={onClick}
        onKeyDown={onEnterOrSpaceKey(onClick)}
      >
        <span
          id={'grant-program'}
          onKeyDown={onEnterOrSpaceKey(onClick)}
          className={theme.FilterTitle}
        >
          Grant Eligible
        </span>
        <span
          className={theme.ActiveFilterPill}
          style={{ background: activeFilterPillColor }}
          id={'grant-pill'}
        >
          {isFilterOn ? 'On' : 'Off'}
        </span>
      </button>
      <div className={theme.FilterOptionCloseWrapper}>
        <button
          type="button"
          aria-label={`Remove Grant Eligible filter`}
          className={theme.FilterOptionCloseButton}
          onClick={handleRemoveFilter}
          onKeyDown={onEnterOrSpaceKey(handleRemoveFilter)}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

const ThemedGrantProgramMobileFilterListing = themr(
  'GrantProgramMobileFilterListing'
)(GrantProgramMobileFilterListing);
export default ThemedGrantProgramMobileFilterListing;
