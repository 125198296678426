/**
 * Keep top-level keys in alpha order please
 */
export default ({ slug, zipHpiStart, zipHpiEnd }) => ({
  query: `query HomeownerPropertyDetailsQuery(
      $slug: String,
      $zipHpiStart: Date,
      $zipHpiEnd: Date
    ) {
    propertyLookup (id: { slug: $slug}) {
      address {
        slug
        hcAddressId
        fullAddress
        streetAddress
        unit
        city
        state
        zipcode
        zipcodePlus4
      }
      avm (qualityMethod: CONSUMER) {
        priceMean
        priceUpper
      }
      bestPhotos (limit: 1, orderBy: MLS) {
        representation {
          httpUrl
          height
          width
        }
      }
      propertyType
      geoLocation {
        latitude
        longitude
      }
      latestListing {
        status
      }
      listDate
      livingSpace {
        bedrooms {
          count
        }
        bathrooms {
          summaryCount
        }
        livingArea
        numberOfRooms
      }
      ltvDetails {
        ltvMean
        origination {
          ltv
        }
      }
      marketStats {
        date
        daysOnMarketMedian
        estlistings
        priceMedian
        estHomesSold
        medianPricePerSqft
        medianSalePrice
      }
      mlsState
      saleDate
      salePrice
      site {
        area (units: SQFT)
        areaDescription
        zoning {
          code
        }
      }
      transfers(order: DESCENDING) {
        transferDate
        transferPrice
        eventType
      }
      structure {
        yearBuilt
      }
      zip {
        hpi (start: $zipHpiStart, end: $zipHpiEnd) {
          adjustedValue
          month
        }
      }
      condition {
        con0
        con1
        con2
        con3
        con4
        con5
        rating
      }
      propensityToSell {
        threeMonth
      }
    }
  }`,
  variables: {
    slug,
    zipHpiStart,
    zipHpiEnd,
  },
});
