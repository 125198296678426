/**
 * Keep top-level keys in alpha order please
 */
export default ({ params }) => ({
  query: `query HomeownerPlaceSearchQuery(
    $zipCode: String
  ) {
    placeSearch (id: {
      term: $zipCode,
      placeType: 'REGIONS',
      addressLookupType: 'LOOKUP'
    }) {
      places {
        placeId
      }
    }
  }`,
  variables: {
    zipCode: params.zipCode,
  },
});
