import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ScrollToTop from '@client/components/ScrollToTop';
import YourTeamTriggerButton from '@client/components/YourTeam/YourTeamTriggerButtonCobranded';
import LenderCTAButtonPDP from '@client/components/LenderCTAButtonPDP/LenderCTAButtonPDP';
import theme from '@client/css-modules/MobileStickyFooter.css';
import ScrollTrackedComponent from '@client/hocs/track-scroll-position';
import classNames from 'classnames';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { setIsShowingAlternateYourTeamTriggerButton } from '@client/store/actions/modals.actions';

export default function MobileStickyFooter({
  rightRailCTAPosition,
}: {
  rightRailCTAPosition: number;
}) {
  const dispatch = useDispatch();
  const [isHidingGradient, setIsHidingGradient] = useState(false);
  const isYourTeamEnabled = useSelector(getIsFeatureEnabled('your_team'));
  const isHidingLenderCTAndScrollToTop = rightRailCTAPosition >= 0;

  /* If not called on scroll change, page can change sizing based on what's been rendered,
   * or change in screen size, which will cause issues with hiding the gradient */
  const getIsAtBottomOfPage = () => {
    const pageHeight =
      (typeof document !== 'undefined' && document?.body?.scrollHeight) || 0;
    const screenHeight =
      (typeof window !== 'undefined' && window?.screen?.availHeight) || 0;
    const buffer = 80;
    const footerPosition = pageHeight - screenHeight - buffer;
    return footerPosition;
  };

  const setPositionInformation = (y: number) => {
    const currentHeight = getIsAtBottomOfPage();
    setIsHidingGradient(y > currentHeight);
  };

  useEffect(() => {
    dispatch(setIsShowingAlternateYourTeamTriggerButton(true));
    return () => {
      dispatch(setIsShowingAlternateYourTeamTriggerButton(false));
    };
  }, []);

  return (
    <ScrollTrackedComponent
      onPositionChange={({ y }) => {
        setPositionInformation(y);
      }}
      rateLimitStrategy="throttle"
      rateLimitAmount={300}
    >
      {({ y }) => (
        <div
          className={classNames(theme.MobileStickyFooter, {
            [theme.isHidingGradient]:
              isHidingGradient ||
              (isHidingLenderCTAndScrollToTop && !isYourTeamEnabled),
          })}
        >
          <div>
            <ScrollToTop
              theme={theme}
              className={
                isHidingLenderCTAndScrollToTop ? theme.hidden : undefined
              }
              overrideHidden
            />
          </div>
          <div className={theme.RightCol}>
            <LenderCTAButtonPDP
              theme={theme}
              className={
                isHidingLenderCTAndScrollToTop ? theme.hidden : undefined
              }
              dataEventName="click_primary_cta_mobile_footer"
              dataParentEventName="lender_ctas"
            />
            {isYourTeamEnabled && (
              <YourTeamTriggerButton
                theme={theme}
                dataEventName="click_your_team_mobile_footer"
                dataParentEventName="lender_ctas"
              />
            )}
          </div>
        </div>
      )}
    </ScrollTrackedComponent>
  );
}
