import PoweredByComeHomeLogoWhiteSvg from '@client/assets/images/powered-by-comehome-white.svg';
import PoweredByComeHomeLogoSvg from '@client/assets/images/powered-by-comehome.svg';
import React from 'react';

interface PoweredByComeHomeProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  useWhiteLogo?: boolean;
}

const PoweredByComeHomeLogo: React.FunctionComponent<PoweredByComeHomeProps> = (
  props
) => {
  const { useWhiteLogo, ...rest } = props;
  return (
    <img
      src={
        useWhiteLogo ? PoweredByComeHomeLogoWhiteSvg : PoweredByComeHomeLogoSvg
      }
      {...rest}
    />
  );
};

export default PoweredByComeHomeLogo;
