import { routeChange } from '@src/redux-saga-router-plus/actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import SearchMapPropertyCard from '@client/components/SearchMapPropertyCard';
import { View } from '@client/routes/constants';
import {
  reportPropertyDetailsUnwatchClick,
  reportPropertyDetailsUnwatchConfirmClick,
  reportPropertyDetailsWatchClick,
} from '@client/store/actions/analytics.actions';
import {
  fetchInitPropertyDetails,
  setLocalStoragePropertySeen,
} from '@client/store/actions/property-details.actions';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import {
  getActivePDPPropertyHasData,
  getPropertyDetailsNormalized,
  getPropertyIsInWatchList,
} from '@client/store/selectors/property-details.selectors';
import { getActivePDPSlug } from '@client/store/selectors/router.selectors';
import { getSearchIsMobileMapPropertyCardActive } from '@client/store/selectors/search.selectors';
import { searchRouteChangeToHidePDPModal } from '@client/store/actions/search.actions';

type OwnProps = {
  onEntranceAnimationComplete?: () => void;
};

const mapStateToProps = (state, ownProps: OwnProps) => {
  return {
    isActive:
      getIsSmallSize(state) && getSearchIsMobileMapPropertyCardActive(state),
    activePropertyHasData: getActivePDPPropertyHasData(state),
    isAddedToWatchList: getPropertyIsInWatchList(state),
    normalizedPropertyData: getPropertyDetailsNormalized(state),
    addressSlug: getActivePDPSlug(state),
    photoSize: getIsSmallSize(state)
      ? ('LARGE' as 'LARGE')
      : ('MEDIUM' as 'MEDIUM'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleGoToPDP: (slug: string) =>
    dispatch(routeChange({ view: View.PROPERTY_DETAILS, params: { slug } })),
  fetchPropertyCardData: (slug: string) =>
    dispatch(fetchInitPropertyDetails(slug)),
  setLocalStoragePropertySeen: (slug: string) =>
    dispatch(setLocalStoragePropertySeen(slug)),
  handlePropertyDetailsWatchClick: (slug) =>
    dispatch(reportPropertyDetailsWatchClick(slug)),
  handlePropertyDetailsUnwatchClick: (slug) =>
    dispatch(reportPropertyDetailsUnwatchClick(slug)),
  handlePropertyDetailsUnwatchConfirmClick: (slug) =>
    dispatch(reportPropertyDetailsUnwatchConfirmClick(slug)),
  handleHidePDPModal: (): void => {
    dispatch(searchRouteChangeToHidePDPModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchMapPropertyCard);
