import { LoanOfficerAPIResponse } from '@client/store/types/consumer-api';

export const FETCH_LOAN_OFFICER_INFO_SUCCESS =
  'FETCH_LOAN_OFFICER_INFO_SUCCESS';
export const FETCH_LOAN_OFFICER_INFO_ERROR = 'FETCH_LOAN_OFFICER_INFO_ERROR';
export const CONTACT_LOAN_OFFICER = 'CONTACT_LOAN_OFFICER';
export const CONTACT_LOAN_OFFICER_SUCCESS = 'CONTACT_LOAN_OFFICER_SUCCESS';
export const CONTACT_LOAN_OFFICER_ERROR = 'CONTACT_LOAN_OFFICER_ERROR';

export type FormValuesTypes = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message?: string;
};

export type FetchLoanOfficerInfoSuccessAction = {
  type: typeof FETCH_LOAN_OFFICER_INFO_SUCCESS;
  payload: {
    loInfo: LoanOfficerAPIResponse | null;
  };
};

export const fetchLoanOfficerInfoSuccess = (
  payload
): FetchLoanOfficerInfoSuccessAction => ({
  type: FETCH_LOAN_OFFICER_INFO_SUCCESS,
  payload: {
    loInfo: payload.loInfo,
  },
});

export type FetchLoanOfficerInfoErrorAction = {
  type: typeof FETCH_LOAN_OFFICER_INFO_ERROR;
};

export const fetchLoanOfficerInfoError =
  (): FetchLoanOfficerInfoErrorAction => ({
    type: FETCH_LOAN_OFFICER_INFO_ERROR,
  });

export type ContactLoanOfficerAction = {
  type: typeof CONTACT_LOAN_OFFICER;
  payload: {
    formValues: FormValuesTypes;
  };
};

export const contactLoanOfficer = (
  formValues: FormValuesTypes
): ContactLoanOfficerAction => ({
  type: CONTACT_LOAN_OFFICER,
  payload: {
    formValues,
  },
});

export type ContactLoanOfficerSuccessAction = {
  type: typeof CONTACT_LOAN_OFFICER_SUCCESS;
};

export const contactLoanOfficerSuccess =
  (): ContactLoanOfficerSuccessAction => ({
    type: CONTACT_LOAN_OFFICER_SUCCESS,
  });

export type ContactLoanOfficerErrorAction = {
  type: typeof CONTACT_LOAN_OFFICER_ERROR;
  payload: {
    error: string | null;
  };
};

export const contactLoanOfficerError = (
  error: string
): ContactLoanOfficerErrorAction => ({
  type: CONTACT_LOAN_OFFICER_ERROR,
  payload: {
    error,
  },
});

export type LoanOfficerAction =
  | FetchLoanOfficerInfoSuccessAction
  | FetchLoanOfficerInfoErrorAction
  | ContactLoanOfficerAction
  | ContactLoanOfficerSuccessAction
  | ContactLoanOfficerErrorAction;
