import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import HeartIconInactive from '@client/inline-svgs/heart-inactive';
import HeartIconInactiveChase from '@client/inline-svgs/heart-inactive-chase';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return HeartIconInactiveChase;
    default:
      return HeartIconInactive;
  }
};

const HeartIconInactiveCobranded: React.FC<{ className?: string }> = (
  props
) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default HeartIconInactiveCobranded;
