/**
 * Ported from https://github.com/willmcpo/body-scroll-lock/blob/master/src/bodyScrollLock.js
 * and modified to fix the scroll lock behavior not functioning correctly on iOS 13 Safari
 *
 * Modifications include:
 * - Adding `getIosVersion` method
 * - Renaming `isIosDevice` to `getShouldPreventBodyTouchEventsForIOSVersion` and adding `getIosVersion` check
 *
 * TODO: revert back to external lib once iOS 13 bug has been fixed
 */
let hasPassiveEvents = false;
if (typeof window !== 'undefined') {
  const passiveTestOptions = {
    get passive() {
      hasPassiveEvents = true;
      return undefined;
    },
  };
  window.addEventListener('testPassive', null, passiveTestOptions);
  window.removeEventListener('testPassive', null, passiveTestOptions);
}

const getIosVersion = () => {
  if (/iP(hone|od|ad)/.test(window.navigator.platform)) {
    /* eslint-disable-next-line security/detect-unsafe-regex */
    let v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (!v || !v.length) {
      // In the ComeHome app, `navigator.appVersion` will return null or empty (and, in general, appVersion
      // is not a reliable API: https://developer.mozilla.org/en-US/docs/Web/API/NavigatorID/appVersion)
      return null;
    }

    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
  }
};

const getShouldPreventBodyTouchEventsForIOSVersion = () => {
  const iOSVersion = getIosVersion();
  return (
    typeof window !== 'undefined' &&
    window.navigator &&
    window.navigator.platform &&
    /iP(ad|hone|od)/.test(window.navigator.platform) &&
    /* On iOS 13.0 - 13.2 preventing body touch events caused problems.  Appears to have been
     * fixed in more recent versions */
    iOSVersion &&
    (iOSVersion[0] < 13 ||
      (iOSVersion[0] === 13 && iOSVersion[1] >= 2) ||
      iOSVersion[0] > 13)
  );
};

let locks = [];
let documentListenerAdded = false;
let initialClientY = -1;
let previousBodyOverflowSetting;
let previousBodyPaddingRight;

// returns true if `el` should be allowed to receive touchmove events.
const allowTouchMove = (el) =>
  locks.some((lock) => {
    if (lock.options.allowTouchMove && lock.options.allowTouchMove(el)) {
      return true;
    }

    return false;
  });

const preventDefault = (rawEvent) => {
  const e = rawEvent || window.event;

  // For the case whereby consumers adds a touchmove event listener to document.
  // Recall that we do document.addEventListener('touchmove', preventDefault, { passive: false })
  // in disableBodyScroll - so if we provide this opportunity to allowTouchMove, then
  // the touchmove event on document will break.
  if (allowTouchMove(e.target)) {
    return true;
  }

  // Do not prevent if the event has more than one touch (usually meaning this is a multi touch gesture like pinch to zoom).
  if (e.touches.length > 1) return true;

  if (e.preventDefault) e.preventDefault();

  return false;
};

const setOverflowHidden = () => {
  // Setting overflow on body/documentElement synchronously in Desktop Safari slows down
  // the responsiveness for some reason. Setting within a setTimeout fixes this.
  window.setTimeout(() => {
    // If previousBodyPaddingRight is already set, don't set it again.
    if (previousBodyPaddingRight === undefined) {
      const scrollBarGap =
        window.innerWidth - document.documentElement.clientWidth;

      if (scrollBarGap > 0) {
        // https://github.com/rick-liruixin/body-scroll-lock-upgrade/blob/develop/src/body-scroll-lock.ts#L94-L100
        const computedBodyPaddingRight = parseInt(
          window
            .getComputedStyle(document.body)
            .getPropertyValue('padding-right'),
          10
        );
        previousBodyPaddingRight = document.body.style.paddingRight;
        document.body.style.paddingRight = `${
          computedBodyPaddingRight + scrollBarGap
        }px`;
      }
    }

    // If previousBodyOverflowSetting is already set, don't set it again.
    if (previousBodyOverflowSetting === undefined) {
      previousBodyOverflowSetting = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
    }
  });
};

const restoreOverflowSetting = () => {
  // Setting overflow on body/documentElement synchronously in Desktop Safari slows down
  // the responsiveness for some reason. Setting within a setTimeout fixes this.
  window.setTimeout(() => {
    if (previousBodyPaddingRight !== undefined) {
      document.body.style.paddingRight = previousBodyPaddingRight;

      // Restore previousBodyPaddingRight to undefined so setOverflowHidden knows it
      // can be set again.
      previousBodyPaddingRight = undefined;
    }

    if (previousBodyOverflowSetting !== undefined) {
      document.body.style.overflow = previousBodyOverflowSetting;

      // Restore previousBodyOverflowSetting to undefined
      // so setOverflowHidden knows it can be set again.
      previousBodyOverflowSetting = undefined;
    }
  });
};

// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
const isElementTotallyScrolled = (element) =>
  element && element.scrollHeight - element.scrollTop <= element.clientHeight;
const isElementAtTopOfScroll = (element) => element && element.scrollTop === 0;

const handleScroll = (event, targetElement) => {
  const clientY = event.targetTouches[0].clientY - initialClientY;

  if (allowTouchMove(event.target)) {
    return false;
  }

  if (isElementAtTopOfScroll(targetElement) && clientY > 0) {
    // element is at the top of its scroll.
    return preventDefault(event);
  }

  if (isElementTotallyScrolled(targetElement) && clientY < 0) {
    // element is at the top of its scroll.
    return preventDefault(event);
  }

  event.stopPropagation();
  return true;
};

/* Filter-out the supplied targetElement from the list of locks */
const removeLocks = (targetElement, options) => {
  locks = locks.filter((lock) => {
    return options.uId
      ? /* If supplying a uId to remove, remove only locks that have that uId attached. This is helpful
         * to remove locks when the targetElement might have changed after adding the lock. */
        !lock.options.uId || lock.options.uId !== options.uId
      : /* Otherwise, match the locks to remove by reference equality on the targetElement */
        lock.targetElement !== targetElement;
  });
};

export const disableBodyScroll = (targetElement, options = {}) => {
  if (getShouldPreventBodyTouchEventsForIOSVersion()) {
    // targetElement must be provided, and disableBodyScroll must not have been
    // called on this targetElement before.
    if (!targetElement) {
      console.error(
        'disableBodyScroll unsuccessful - targetElement must be provided when calling disableBodyScroll on IOS devices.'
      );
      return;
    }

    if (
      targetElement &&
      !locks.some((lock) => lock.targetElement === targetElement)
    ) {
      const lock = {
        targetElement,
        options,
      };

      locks = [...locks, lock];

      targetElement.ontouchstart = (event) => {
        if (event.targetTouches.length === 1) {
          // detect single touch.
          initialClientY = event.targetTouches[0].clientY;
        }
      };
      targetElement.ontouchmove = (event) => {
        if (event.targetTouches.length === 1) {
          // detect single touch.
          handleScroll(event, targetElement);
        }
      };

      if (!documentListenerAdded) {
        document.addEventListener(
          'touchmove',
          preventDefault,
          hasPassiveEvents ? { passive: false } : undefined
        );
        documentListenerAdded = true;
      }
    }
  } else {
    setOverflowHidden(options);
    const lock = {
      targetElement,
      options,
    };

    locks = [...locks, lock];
  }
};

export const clearAllBodyScrollLocks = () => {
  if (getShouldPreventBodyTouchEventsForIOSVersion()) {
    // Clear all locks ontouchstart/ontouchmove handlers, and the references.
    locks.forEach((lock) => {
      lock.targetElement.ontouchstart = null;
      lock.targetElement.ontouchmove = null;
    });

    if (documentListenerAdded) {
      document.removeEventListener(
        'touchmove',
        preventDefault,
        hasPassiveEvents ? { passive: false } : undefined
      );
      documentListenerAdded = false;
    }

    locks = [];

    // Reset initial clientY.
    initialClientY = -1;
  } else {
    restoreOverflowSetting();
    locks = [];
  }
};

export const enableBodyScroll = (targetElement, options = {}) => {
  if (getShouldPreventBodyTouchEventsForIOSVersion()) {
    if (!targetElement) {
      console.error(
        'enableBodyScroll unsuccessful - targetElement must be provided when calling enableBodyScroll on IOS devices.'
      );
      return;
    }

    targetElement.ontouchstart = null;
    targetElement.ontouchmove = null;

    removeLocks(targetElement, options);
    if (documentListenerAdded && locks.length === 0) {
      document.removeEventListener(
        'touchmove',
        preventDefault,
        hasPassiveEvents ? { passive: false } : undefined
      );

      documentListenerAdded = false;
    }
  } else {
    removeLocks(targetElement, options);
    if (!locks.length) {
      restoreOverflowSetting();
    }
  }
};
