import React, { Component } from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import { MotionStyle, motion } from 'framer-motion';

import { ALL_SEARCH_VIEWS, View } from '@client/routes/constants';
import { SEARCH_ACTIVE_VIEW_URL_PARAM_KEY } from '@client/store/constants';
import NavIconAlerts from '@client/inline-svgs/nav-icon-alerts';
import NavIconMyHome from '@client/inline-svgs/nav-icon-my-home';
import NavIconWatchlist from '@client/inline-svgs/nav-icon-watchlist';
import NavIconMap from '@client/inline-svgs/nav-icon-map';
import NavIconList from '@client/inline-svgs/nav-icon-list';
import AccountIcon from '@client/inline-svgs/account-icon';
import SearchIcon from '@client/inline-svgs/search';
import MenuNotificationCount from '@client/components/MenuNotificationCount';
import CobrandedDataComponent, {
  CobrandedDataComponentArgs,
} from '@client/components/CobrandedDataComponent';
import defaultTheme from '@client/css-modules/MobileBottomNav.css';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';

export type MobileBottomNavItemKey =
  | 'map'
  | 'list'
  | 'saved'
  | 'homeowner'
  | 'alerts'
  | 'settings'
  | 'search';

type Props = {
  isShown: boolean;
  activeView: View | null;
  unreadAlertsCount: number;
  isSearchPageListOpen: boolean;
  activeItemColor: string;
  inactiveItemColor: string;
  activeItemBorderBottomStyle: string;
  handleRouteChange: (view: View, query?: {}) => void;
  handleHidePDPModalViaRouteChange: () => void;
  handleReportNavButtonClick: (viewId: MobileBottomNavItemKey) => void;
  handleSearchRouteChange: (isShowingSearchPageList: boolean) => void;
  isShowingMyHomeMobileBottomNavItem: boolean;
  theme: Theme;
  navLinkLabels: {
    navLinkSearchLabel?: string | null,
    navLinkHomeownerLabel?: string | null,
    navLinkWatchlistLabel?: string | null,
    navLinkAlertsLabel?: string | null,
  };
  isFourHundredPercentZoom?: boolean;
};

type NavButtonProps = {
  onClick: () => void;
  Icon: React.ReactElement;
  label: string;
  isActive: boolean;
  unreadAlertsCount?: number;
  activeItemColor: string;
  activeBorderBottomStyle: string;
  navKey: keyof typeof NAV_ITEM_KEYS;
  className?: string;
  theme: Theme;
  style?: React.CSSProperties;
};

const { navHeight } = defaultTheme;

const NAV_ITEM_KEYS = {
  MAP: 'MAP' as 'MAP',
  LIST: 'LIST' as 'LIST',
  WATCHLIST: 'WATCHLIST' as 'WATCHLIST',
  ALERTS: 'ALERTS' as 'ALERTS',
  SETTINGS: 'SETTINGS' as 'SETTINGS',
  HOMEOWNER: 'HOMEOWNER' as 'HOMEOWNER',
  SEARCH: 'SEARCH' as 'SEARCH',
};

/* For each nav item key, the views for which the nav item should be shown as active */
const CHILD_VIEWS_FOR_NAV_ITEM_KEY = {
  /* Search page nav items are treated separately */
  [NAV_ITEM_KEYS.WATCHLIST]: [View.WATCHLIST, View.SAVED_SEARCHES] as View[],
  [NAV_ITEM_KEYS.ALERTS]: [View.ALERTS] as View[],
  [NAV_ITEM_KEYS.SEARCH]: [View.SEARCH] as View[],
  [NAV_ITEM_KEYS.HOMEOWNER]: [
    View.HOMEOWNER,
    View.HOMEOWNER_CLAIMED_HOMES,
    View.HOMEOWNER_PROPERTY_DETAILS,
  ] as View[],
  [NAV_ITEM_KEYS.SETTINGS]: [
    View.SETTINGS,
    View.FEEDBACK,
    View.LEGAL,
    View.NOTIFICATIONS,
    View.PROFILE,
  ] as View[],
};

const NavButton = ({
  onClick,
  Icon,
  label,
  isActive,
  unreadAlertsCount,
  activeItemColor,
  activeBorderBottomStyle,
  navKey,
  theme,
  className,
  style,
}: NavButtonProps) => (
  <button
    type="button"
    aria-label={label}
    className={classNames(
      theme.NavButton,
      className ? { [className]: true } : {}
    )}
    onClick={onClick}
    onKeyDown={onEnterOrSpaceKey(onClick)}
    data-nav-key={navKey}
    style={style}
  >
    <span className={theme.NavButtonIconContainer}>
      {Icon}
      {!!unreadAlertsCount && unreadAlertsCount > 0 && (
        <MenuNotificationCount theme={theme} count={unreadAlertsCount} />
      )}
    </span>
    <span
      className={theme.NavButtonLabel}
      style={isActive ? { color: activeItemColor } : {}}
    >
      <label
        style={isActive ? { borderBottom: activeBorderBottomStyle } : {}}
        className={theme.NavButtonLabelText}
      >
        {label}
      </label>
    </span>
  </button>
);

/**
 * A bottom navigation bar for mobile devices
 */
class MobileBottomNav extends Component<Props, {}> {
  componentDidMount() {
    const { activeItemColor, inactiveItemColor } = this.props;

    this.styleNavElements(activeItemColor, inactiveItemColor);
  }

  componentDidUpdate(prevProps) {
    const {
      activeView,
      isSearchPageListOpen,
      activeItemColor,
      inactiveItemColor,
      isShown,
    } = this.props;

    if (
      prevProps.activeView !== activeView ||
      (!prevProps.isShown && isShown) ||
      isSearchPageListOpen !== prevProps.isSearchPageListOpen
    ) {
      this.styleNavElements(activeItemColor, inactiveItemColor);
    }
  }

  /**
   * Given a nav item key, return whether the nav item should be shown as active
   */
  getIsNavItemActive = (
    navItemKey: keyof typeof NAV_ITEM_KEYS | null
  ): boolean => {
    const { activeView, isSearchPageListOpen } = this.props;

    if (navItemKey === NAV_ITEM_KEYS.MAP || navItemKey === NAV_ITEM_KEYS.LIST) {
      return (
        activeView === View.SEARCH &&
        ((isSearchPageListOpen && navItemKey === NAV_ITEM_KEYS.LIST) ||
          (!isSearchPageListOpen && navItemKey === NAV_ITEM_KEYS.MAP))
      );
    } else if (
      navItemKey === NAV_ITEM_KEYS.WATCHLIST ||
      navItemKey === NAV_ITEM_KEYS.ALERTS ||
      navItemKey === NAV_ITEM_KEYS.SETTINGS ||
      navItemKey === NAV_ITEM_KEYS.HOMEOWNER ||
      navItemKey === NAV_ITEM_KEYS.SEARCH
    ) {
      const childViews: View[] = CHILD_VIEWS_FOR_NAV_ITEM_KEY[navItemKey];
      return !!(activeView && childViews.indexOf(activeView) > -1);
    } else {
      return false;
    }
  };

  handleNavigateToSearchSection = (
    shouldShowListView: boolean,
    theme: Theme
  ): void => {
    const { handleSearchRouteChange, handleRouteChange, activeView } =
      this.props;

    if (activeView && ALL_SEARCH_VIEWS.includes(activeView)) {
      handleSearchRouteChange(shouldShowListView);
    } else {
      handleRouteChange(View.SEARCH, {
        [SEARCH_ACTIVE_VIEW_URL_PARAM_KEY]: shouldShowListView ? 'list' : 'map',
      });
    }
  };

  handleNavigateToNormalRoute = (view: View, theme: Theme): void => {
    const { handleRouteChange } = this.props;

    handleRouteChange(view);
  };

  /**
   * Since we're dealing with SVGs, we need to apply fill and stroke styles manually within
   * each SVG component.  Certainly not ideal, but better than adding 10s of icons to the app
   * for each state and for each cobrand in each state.
   */
  styleNavElements = (
    activeItemColor: string,
    inactiveItemColor: string
  ): void => {
    /* Elements requiring stroke defs */
    [
      ...document.querySelectorAll(
        `.${defaultTheme.MobileBottomNav} .${defaultTheme.NavButton}`
      ),
    ].forEach((node) => {
      const isActive = this.getIsNavItemActive(
        node.getAttribute('data-nav-key') as keyof typeof NAV_ITEM_KEYS
      );
      const elementsToStyle = [...node.querySelectorAll('.element-to-stroke')];
      const strokeColor = isActive ? activeItemColor : inactiveItemColor;
      elementsToStyle.forEach((nodeToFill) =>
        nodeToFill.setAttribute('style', `stroke: ${strokeColor}`)
      );
    });

    /* Elements requiring fill defs */
    [
      ...document.querySelectorAll(
        `.${defaultTheme.MobileBottomNav} .${defaultTheme.NavButton}`
      ),
    ].forEach((node) => {
      const isActive = this.getIsNavItemActive(
        node.getAttribute('data-nav-key') as keyof typeof NAV_ITEM_KEYS
      );
      const elementsToStyle = [...node.querySelectorAll('.element-to-fill')];
      const fillColor = isActive ? activeItemColor : inactiveItemColor;
      elementsToStyle.forEach((nodeToFill) =>
        nodeToFill.setAttribute('style', `fill: ${fillColor}`)
      );
    });
  };

  render() {
    const {
      isShown,
      unreadAlertsCount,
      theme,
      handleReportNavButtonClick,
      activeItemColor,
      activeItemBorderBottomStyle,
      isShowingMyHomeMobileBottomNavItem,
      isFourHundredPercentZoom,
      activeView,
      navLinkLabels,
    } = this.props;

    const fourHundredZoomStyles = isFourHundredPercentZoom && activeView === View.SEARCH;

    return (
      <CobrandedDataComponent>
        {({
          customizationData: { should_show_settings_mobile_bottom_nav_item },
        }: CobrandedDataComponentArgs) =>
          isShown ? (
            <motion.nav
              key="nav"
              initial={{ y: navHeight }}
              animate={{
                y: 0,
                transition: {
                  damping: 0,
                  delay: 0.1,
                  duration: 0.15,
                  easing: 'easeIn',
                },
              }}
              className={theme.MobileBottomNav}
              style={fourHundredZoomStyles ? { display: "flex" } as MotionStyle : {}}
            >
              <NavButton
                label="Map"
                className={theme.NavButtonMap}
                Icon={<NavIconMap className={theme.NavButtonIcon} />}
                isActive={this.getIsNavItemActive(NAV_ITEM_KEYS.MAP)}
                onClick={() => {
                  this.handleNavigateToSearchSection(false, theme);
                  handleReportNavButtonClick('map');
                }}
                activeItemColor={activeItemColor}
                activeBorderBottomStyle={activeItemBorderBottomStyle}
                navKey={NAV_ITEM_KEYS.MAP}
                theme={theme}
                style={fourHundredZoomStyles ? { display: "none" } : {}}
              />
              <NavButton
                label="List"
                className={theme.NavButtonList}
                Icon={<NavIconList className={theme.NavButtonIcon} />}
                isActive={this.getIsNavItemActive(NAV_ITEM_KEYS.LIST)}
                onClick={() => {
                  this.handleNavigateToSearchSection(true, theme);
                  handleReportNavButtonClick('list');
                }}
                activeItemColor={activeItemColor}
                activeBorderBottomStyle={activeItemBorderBottomStyle}
                navKey={NAV_ITEM_KEYS.LIST}
                theme={theme}
              />
              {/* On tablet, show a single nav item since map & list are combined */}
              <NavButton
                label={navLinkLabels.navLinkSearchLabel || "Search" }
                className={theme.NavButtonSearch}
                Icon={<SearchIcon className={theme.NavButtonIcon} />}
                isActive={this.getIsNavItemActive(NAV_ITEM_KEYS.SEARCH)}
                onClick={() => {
                  this.handleNavigateToSearchSection(false, theme);
                  handleReportNavButtonClick('search');
                }}
                activeItemColor={activeItemColor}
                activeBorderBottomStyle={activeItemBorderBottomStyle}
                navKey={NAV_ITEM_KEYS.MAP}
                theme={theme}
              />

              <NavButton
                label={navLinkLabels.navLinkWatchlistLabel || "Saved" }
                Icon={<NavIconWatchlist className={theme.NavButtonIcon} />}
                isActive={this.getIsNavItemActive(NAV_ITEM_KEYS.WATCHLIST)}
                onClick={() => {
                  this.handleNavigateToNormalRoute(View.WATCHLIST, theme);
                  handleReportNavButtonClick('saved');
                }}
                activeItemColor={activeItemColor}
                activeBorderBottomStyle={activeItemBorderBottomStyle}
                navKey={NAV_ITEM_KEYS.WATCHLIST}
                theme={theme}
              />
      
              {isShowingMyHomeMobileBottomNavItem && (
                <NavButton
                  label={navLinkLabels.navLinkHomeownerLabel || "My Home" }
                  Icon={<NavIconMyHome className={theme.NavButtonIcon} />}
                  isActive={this.getIsNavItemActive(NAV_ITEM_KEYS.HOMEOWNER)}
                  onClick={() => {
                    this.handleNavigateToNormalRoute(View.HOMEOWNER, theme);
                    handleReportNavButtonClick('homeowner');
                  }}
                  activeItemColor={activeItemColor}
                  activeBorderBottomStyle={activeItemBorderBottomStyle}
                  navKey={NAV_ITEM_KEYS.HOMEOWNER}
                  theme={theme}
                />
              )}
              <NavButton
                label={navLinkLabels.navLinkAlertsLabel || "Alerts" }
                Icon={<NavIconAlerts className={theme.NavButtonIcon} />}
                isActive={this.getIsNavItemActive(NAV_ITEM_KEYS.ALERTS)}
                onClick={() => {
                  this.handleNavigateToNormalRoute(View.ALERTS, theme);
                  handleReportNavButtonClick('alerts');
                }}
                activeItemColor={activeItemColor}
                activeBorderBottomStyle={activeItemBorderBottomStyle}
                unreadAlertsCount={unreadAlertsCount}
                navKey={NAV_ITEM_KEYS.ALERTS}
                theme={theme}
              />
              {should_show_settings_mobile_bottom_nav_item && (
                <NavButton
                  label="Settings"
                  className={theme.NavButtonSettings}
                  Icon={<AccountIcon className={theme.NavButtonIcon} />}
                  isActive={this.getIsNavItemActive(NAV_ITEM_KEYS.SETTINGS)}
                  onClick={() => {
                    this.handleNavigateToNormalRoute(View.SETTINGS, theme);
                    handleReportNavButtonClick('settings');
                  }}
                  activeItemColor={activeItemColor}
                  activeBorderBottomStyle={activeItemBorderBottomStyle}
                  navKey={NAV_ITEM_KEYS.SETTINGS}
                  theme={theme}
                />
              )}
            </motion.nav>
          ) : null
        }
      </CobrandedDataComponent>
    );
  }
}

const ThemedMobileBottomNav = themr(
  'MobileBottomNav',
  defaultTheme
)(MobileBottomNav);
export default ThemedMobileBottomNav;
