import { validateEmail } from '@client/services/validator';
import accounting from 'accounting';

export const moneyFormatter = (
  value?: string | number,
  ...options: any[]
): string => accounting.formatMoney(value as string | number, ...options);

export const dollarsFormatter = (
  value: string | number,
  precision: number = 0
): string =>
  moneyFormatter(value, {
    symbol: '$',
    precision: precision,
    format: {
      pos: '%s%v',
      neg: '-%s%v',
      zero: '%s%v',
    },
  });

export const numberFormatter = (
  value: string | number,
  ...options: any[]
): string => accounting.formatNumber(value as number, ...options);

type Additions = {
  [key: string]: string;
};

export const titleFormatter = (str: string, additions: Additions = {}) => {
  // fail gracefully when input not string
  if (new String(str).toString() !== str) {
    return '';
  }

  // Replace underscores with spaces:
  str = str.replace(/(_\w)/g, (m) => {
    return ' ' + m[1].toUpperCase();
  });

  // Make the first letter of a line capitalized:
  str = str.charAt(0).toUpperCase() + str.slice(1);

  // Account for a handful of specific words:
  const wordMap = {
    'No ': 'Number ',
    ' Of ': ' of ',
    'Sq Ft': 'Sq. Ft.',
    Hoa: 'HOA',
    ...additions, // allows user to send additional items or override items that may be specific to that use case
  };

  for (let key in wordMap) {
    str = str.replace(key, wordMap[key]);
  }

  return str;
};

export const streetAddressFormatter = (
  street: string,
  unit: string | number | null,
  unitDesignator?: string | number
) => {
  const unitDisplay = unit
    ? unitDesignator
      ? ` ${unitDesignator} ${unit}`
      : ` ${unit}`
    : '';
  return `${street || ''}${unitDisplay}`;
};

/**
 * Splits a comma delimited list into an array of values
 * ex: 'My,Stuff,In,An,Array' => ['My', 'Stuff', 'In', 'An', 'Array']
 * @param {String} string - The string containing the comma delimited list
 * @returns {Array} - the string, broken into an array
 */
export const splitAtCommas = (str: string) => {
  // fail gracefully when input not string
  if (new String(str).toString() !== str) {
    return [];
  }

  return str ? str.split(',') : [];
};

/**
 * Removes spaces from a string.
 * ex: 'My string with spaces' => 'Mystringwithspaces'
 * @param {String} string - the string to be processed
 * @returns {String} - the string with spaces removed
 */
export const removeSpaces = (str: string) => {
  // fail gracefully when input not string
  if (new String(str).toString() !== str) {
    return '';
  }

  return str.replace(/\s/g, '');
};

export const MULTI_EMAIL_ERROR_MSG =
  'Please enter valid, comma-separated emails.';
  
/**
 * Validates a string of comma-separated emails (ex: 'hello@cool.com,email@address.com,jon@snow.com')
 * @param  {string} comma delineated list of emails to validate
 * @return {string | null} it returns an error message if invalid, or null if valid
 */
export const multiEmailValidator = (emails: string): string | null => {
  // fail gracefully when input not string
  if (new String(emails).toString() !== emails) {
    return MULTI_EMAIL_ERROR_MSG;
  }

  const emailArr = splitAtCommas(removeSpaces(emails));

  return emailArr.reduce((acc?: any, email?: string | null) => {
    if (email && !validateEmail(email.trim())) {
      return MULTI_EMAIL_ERROR_MSG;
    } else {
      return null;
    }
  }, null);
};

export const combineAddress = (
  line1: string,
  line2: string,
  city: string,
  state: string,
  zip
): string => {
  let finalString = line1;
  if (line2) {
    finalString = finalString + ' ' + line2;
  }
  if (city) {
    finalString = finalString + ', ' + city;
  }
  if (state) {
    finalString = finalString + ' ' + state;
  }
  if (zip) {
    finalString = finalString + ' ' + zip;
  }
  return finalString;
};

// Format input value to number
export const valueToNumberFormatter = (value?: string | number) => {
  if (typeof value === 'string') {
    return parseInt(value.replace(/\D/g, ''), 10);
  }
  return value;
};
