import { connect } from 'react-redux';

import ListingInfo from '@client/components/ListingInfo';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { getUserContactInfo } from '@client/store/selectors/auth.selectors';
import {
  getMlsIdOfPropertyMls,
  getPropertyAddressSlug,
  getPropertyAgentInfo,
  getPropertyIdInMLS,
  getPropertyIsActiveStatusType,
  getPropertyListingNotes,
  getPropertyMlsAbbrevName,
  getPropertyMlsLogoUrl,
  getShouldDisplayAgentInfo,
} from '@client/store/selectors/property-details.selectors';

let userInfo = {};
const mapStateToProps = (state) => {
  userInfo = getUserContactInfo(state);
  return {
    agentInfo: getPropertyAgentInfo(state),
    shouldDisplayAgentInfo: getShouldDisplayAgentInfo(state),
    listingNotes: getPropertyListingNotes(state),
    shouldDisplayListingNotes: getPropertyIsActiveStatusType(state),
    propertyIdInMLS: getPropertyIdInMLS(state),
    mlsNameAbbreviated: getPropertyMlsAbbrevName(state),
    mlsLogoUrl: getPropertyMlsLogoUrl(state),
    mlsIdOfMls: getMlsIdOfPropertyMls(state),
    propertySlug: getPropertyAddressSlug(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handlePropertyDetailsContactAgentClick: (ch_property_id: string | null) =>
      dispatch(
        reportEvent(
          'click_property_details_contact_agent',
          PARENT_EVENTS.CLICK_PDP_MODULES,
          {
            ...userInfo,
            ch_property_id,
          }
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingInfo);
