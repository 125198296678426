import React from 'react';

import theme from '@client/css-modules/SaveSearchFloatingButton.css';
import MobileSearchSave from '@client/inline-svgs/mobile-search-save';
import MobileSearchSuccess from '@client/inline-svgs/mobile-search-success';
import LoadingSpinner from '@client/inline-svgs/loading-spinner';
import classNames from 'classnames';
import PillButton from '@client/components/generic/PillButton';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { getSaveSearchButtonConfig } from '@client/store/selectors/cobranding.selectors';

export type SaveSearchFloatingButtonProps = {
  hasSavedCurrentSearch: boolean;
  isSavingCurrentSearch;
  handleSaveSearch: () => void;
};

const SaveSearchFloatingButton = (props: SaveSearchFloatingButtonProps) => {
  const { hasSavedCurrentSearch, isSavingCurrentSearch, handleSaveSearch } =
    props;
  const BTN_ID = 'save-search-button';
  const handleClick = () => {
    if (!hasSavedCurrentSearch) {
      handleSaveSearch();
    }
  };
  const {
    saveSearchFloatingButtonColor,
    saveSearchFloatingButtonSuccessColor,
  } = useCobrandStyles();
  const { saveSearchButtonLabel, saveSearchButtonIcon } = useSelector(getSaveSearchButtonConfig)
  const saveSearchText = saveSearchButtonLabel ? `Save ${saveSearchButtonLabel}` : 'Save search'
  const searchSavedText = saveSearchButtonLabel ? `${saveSearchButtonLabel} saved` : 'Search saved'

  const addSaveSearchButtonAsFirstChild = (element: JSX.Element) => {
    const mapElement = document.querySelector('[data-hc-name="map-section"]') as Element;
    const buttonElement = document.getElementById(BTN_ID);
    // Check if the element is already in the mapElement
    if (mapElement && buttonElement && buttonElement !== mapElement.firstChild) {
      mapElement.insertBefore(buttonElement, mapElement.firstChild)
    }
    // Wait until DOM / map has loaded because createPortal's second param needs to be existing DOM element.
    return mapElement && createPortal(element, mapElement);
  };

  return (
    <>
      {
        addSaveSearchButtonAsFirstChild(
          <PillButton
            id={BTN_ID}
            className={theme.SaveSearchFloatingButton}
            style={
              hasSavedCurrentSearch
                ? { backgroundColor: saveSearchFloatingButtonSuccessColor }
                : { backgroundColor: saveSearchFloatingButtonColor }
            }
            onClick={handleClick}
            ariaLabel={
              isSavingCurrentSearch
                ? saveSearchText
                : hasSavedCurrentSearch
                  ? searchSavedText
                  : saveSearchText
            }
            ariaLive="polite"
          >
            {isSavingCurrentSearch ? (
              <LoadingSpinner
                className={classNames(theme.MobileButtonIcon, theme.Spinner)}
              />
            ) : hasSavedCurrentSearch ? (
              <MobileSearchSuccess className={theme.MobileButtonIcon} />
            ) : (
                <>
                  {saveSearchButtonIcon ? <img src={saveSearchButtonIcon} alt="" className={theme.MobileButtonIcon} /> :
                    <MobileSearchSave className={theme.MobileButtonIcon} />
                  }
                </>
            )}
            <div>
              {isSavingCurrentSearch
                ? 'Saving...'
                : hasSavedCurrentSearch
                  ? searchSavedText
                  : saveSearchText}
            </div>
          </PillButton>
        )
      }
    </>
  );
};

export default SaveSearchFloatingButton;
