import { ReactNode } from 'react';

const ConditionalWrapper = ({
  condition,
  trueConditionWrapper,
  falseConditionWrapper,
  children,
}: {
  condition: boolean;
  trueConditionWrapper: (children: ReactNode) => ReactNode;
  falseConditionWrapper: (children: ReactNode) => ReactNode;
  children: ReactNode;
}) =>
  condition ? trueConditionWrapper(children) : falseConditionWrapper(children);

export default ConditionalWrapper;
