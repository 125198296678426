import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import ChaseCarat from '@client/inline-svgs/chase-carat';
import LendcoCarat from '@client/inline-svgs/cobrand/lendco/lendco-carat';

type Props = {
  className?: string;
};

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return ChaseCarat;
    default:
      return LendcoCarat;
  }
};

const CaratIcon: React.FC<Props> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default CaratIcon;
