import { LayerGroupLabel, LegendInterval } from '@client/store/types/maps';
import schoolIcon0 from 'assets/images/school-pin-0.png';
import schoolIcon1 from 'assets/images/school-pin-1.png';
import schoolIcon2 from 'assets/images/school-pin-2.png';
import schoolIcon3 from 'assets/images/school-pin-3.png';
import schoolIcon4 from 'assets/images/school-pin-4.png';
import schoolIconUnranked from 'assets/images/school-pin-unranked.png';
import barPinIcon from 'assets/images/bar-pin.png';
import cafePinIcon from 'assets/images/cafe-pin.png';
import entertainmentPinIcon from 'assets/images/entertainment-pin.png';
import groceryPinIcon from 'assets/images/grocery-pin.png';
import parkPinIcon from 'assets/images/park-pin.png';
import restaurantPinIcon from 'assets/images/restaurant-pin.png';
import { ACTIVITY_IDS } from '@client/store/constants';
import halftone1 from '@client/assets/images/halftone-shade-1.svg';
import halftone2 from '@client/assets/images/halftone-shade-2.svg';
import halftone3 from '@client/assets/images/halftone-shade-3.svg';
import halftone4 from '@client/assets/images/halftone-shade-4.svg';
import halftone5 from '@client/assets/images/halftone-shade-5.svg';
import halftoneEmpty from '@client/assets/images/halftone-shade-empty.svg';
import { dollarsFormatter } from '@client/utils/formatter.utils';

/* Internally used identifiers for layer groups, each containing one or more layers */
export const LAYER_GROUP_KEYS = {
  FORECAST: 'forecast' as 'forecast',
  SCHOOLS: 'schools' as 'schools',
  CRIME: 'crime' as 'crime',
  PRICE: 'price' as 'price',
};

export type LayerGroup =
  (typeof LAYER_GROUP_KEYS)[keyof typeof LAYER_GROUP_KEYS];

/* The API metric associated with each layer.  Also used by this app as a unique key
 * for each layer. These keys are used by GAIA to define legend breaks and features
 * for each layer. */
export const LAYER_METRICS = {
  SCHOOLS_ELEMENTARY: 'schools_elementary' as 'schools_elementary',
  SCHOOLS_MIDDLE: 'schools_middle' as 'schools_middle',
  SCHOOLS_HIGH: 'schools_high' as 'schools_high',
  CRIME_ALL: 'all_crime_county_percentile' as 'all_crime_county_percentile',
  CRIME_VIOLENT:
    'violent_crime_county_percentile' as 'violent_crime_county_percentile',
  CRIME_PROPERTY:
    'property_crime_county_percentile' as 'property_crime_county_percentile',
  PRICE_SQFT: 'avg_price_per_sqft' as 'avg_price_per_sqft',
  AVG_PRICE: 'avg_price' as 'avg_price',
  FORECAST: 'price_pctchg_1_yr_fc' as 'price_pctchg_1_yr_fc',
};

/* Used by Mapbox to keep track of layers, used by us to specify the stacking order of
 * a newly added layer */
export const MAPBOX_LAYER_IDS = {
  MARKERS: 'markers-symbol-layer' as 'markers-symbol-layer',
  SCHOOL_SYMBOLS: 'schools-symbol-layer' as 'schools-symbol-layer',
  SCHOOL_DISTRICT_BOUNDARY:
    'school-district-boundary-layer' as 'school-district-boundary-layer',
};

export type MapboxLayerId =
  (typeof MAPBOX_LAYER_IDS)[keyof typeof MAPBOX_LAYER_IDS];

/* Defines the layer group control buttons in MapLayersControl */
export const LAYER_CONTROL_GROUPS = [
  LAYER_GROUP_KEYS.CRIME,
  LAYER_GROUP_KEYS.SCHOOLS,
  LAYER_GROUP_KEYS.PRICE,
  LAYER_GROUP_KEYS.FORECAST,
];

export type LayerMetric = (typeof LAYER_METRICS)[keyof typeof LAYER_METRICS];

/* Defines the layers to be displayed within each group in MapLayersControl */
export const LAYER_GROUP_LAYER_METRICS = {
  [LAYER_GROUP_KEYS.FORECAST]: [LAYER_METRICS.FORECAST] as LayerMetric[],
  [LAYER_GROUP_KEYS.SCHOOLS]: [
    LAYER_METRICS.SCHOOLS_ELEMENTARY,
    LAYER_METRICS.SCHOOLS_MIDDLE,
    LAYER_METRICS.SCHOOLS_HIGH,
  ] as LayerMetric[],
  [LAYER_GROUP_KEYS.CRIME]: [
    LAYER_METRICS.CRIME_ALL,
    LAYER_METRICS.CRIME_VIOLENT,
    LAYER_METRICS.CRIME_PROPERTY,
  ] as LayerMetric[],
  [LAYER_GROUP_KEYS.PRICE]: [
    LAYER_METRICS.PRICE_SQFT,
    LAYER_METRICS.AVG_PRICE,
  ] as LayerMetric[],
};

/* Preset identifiers used to render a certain preset for map feature styling in
 * MapLayersControl */
export const LAYER_PRESET_TYPES = {
  SCHOOLS: 'schools',
  BLOCKS: 'blocks',
};

/* Defines a preset type for each layer metric */
export const LAYER_PRESET_TYPES_FOR_METRIC = {
  [LAYER_METRICS.SCHOOLS_ELEMENTARY]: LAYER_PRESET_TYPES.SCHOOLS,
  [LAYER_METRICS.SCHOOLS_MIDDLE]: LAYER_PRESET_TYPES.SCHOOLS,
  [LAYER_METRICS.SCHOOLS_HIGH]: LAYER_PRESET_TYPES.SCHOOLS,
  [LAYER_METRICS.CRIME_ALL]: LAYER_PRESET_TYPES.BLOCKS,
  [LAYER_METRICS.CRIME_VIOLENT]: LAYER_PRESET_TYPES.BLOCKS,
  [LAYER_METRICS.CRIME_PROPERTY]: LAYER_PRESET_TYPES.BLOCKS,
  [LAYER_METRICS.PRICE_SQFT]: LAYER_PRESET_TYPES.BLOCKS,
  [LAYER_METRICS.AVG_PRICE]: LAYER_PRESET_TYPES.BLOCKS,
  [LAYER_METRICS.FORECAST]: LAYER_PRESET_TYPES.BLOCKS,
};

/* A mapping of the zoom levels at which each layer source should be displayed
 * on the map for each layer.  Used both to get legend breaks for the layer while
 * it's activated on map move and to display messaging once a layer is hidden after
 * zooming out */
export const LAYER_SOURCE_ZOOM_MAPPING = {
  [LAYER_METRICS.CRIME_ALL]: {
    consumer_blocks: [13],
  },
  [LAYER_METRICS.CRIME_VIOLENT]: {
    consumer_blocks: [13],
  },
  [LAYER_METRICS.CRIME_PROPERTY]: {
    consumer_blocks: [13],
  },
  [LAYER_METRICS.FORECAST]: {
    consumer_blocks: [13],
    consumer_block_groups: [9, 13],
  },
  [LAYER_METRICS.PRICE_SQFT]: {
    consumer_blocks: [13],
    consumer_block_groups: [9, 13],
  },
  [LAYER_METRICS.AVG_PRICE]: {
    consumer_blocks: [13],
    consumer_block_groups: [9, 13],
  },
  /* Despite schools layers using hardcoded legend data, we still want the map messaging
   * to display when zoomed out past level 9, and we still want the blocks legend data
   * for the area ready if switching back to block layers */
  [LAYER_METRICS.SCHOOLS_ELEMENTARY]: {
    consumer_blocks: [13],
    consumer_block_groups: [9, 13],
  },
  [LAYER_METRICS.SCHOOLS_MIDDLE]: {
    consumer_blocks: [13],
    consumer_block_groups: [9, 13],
  },
  [LAYER_METRICS.SCHOOLS_HIGH]: {
    consumer_blocks: [13],
    consumer_block_groups: [9, 13],
  },
};

/* Used to facilitate getting legend breaks when no layer has yet been activated in order
 * for the breaks to be ready once a layer is activated */
export const DEFAULT_STATS_ZOOM_MAPPING = {
  consumer_blocks: [13],
  consumer_block_groups: [9, 13],
};

/* UI labels for each layer group */
export const LAYER_GROUP_LABELS: { [K in LayerGroup]: LayerGroupLabel } = {
  [LAYER_GROUP_KEYS.CRIME]: {
    label: 'Crime',
    longLabel: 'Crime County Rate',
    tooltipText:
      'Crime reporting guidelines vary by county, so similar incidents could be reported as a crime in one county and not reported in another.',
    tooltipLegendHighestExplanation: 'Most Crime Reports',
    tooltipLegendLowestExplanation: 'Fewest Crime Reports',
    valueFormatter: (value) => `${value}%`,
  },
  [LAYER_GROUP_KEYS.SCHOOLS]: {
    label: 'Schools',
    longLabel: 'School Rankings Within State',
    tooltipLegendHighestExplanation: 'Top Ranked in State',
    tooltipLegendLowestExplanation: 'Lowest Ranked in State',
    valueFormatter: (value) => `${value}`,
  },
  [LAYER_GROUP_KEYS.PRICE]: {
    label: 'Price',
    longLabel: null,
    valueFormatter: (value) => dollarsFormatter(value),
  },
  [LAYER_GROUP_KEYS.FORECAST]: {
    label: 'Forecast',
    longLabel: 'Forecast % Change',
    valueFormatter: (value) => `${Math.floor(value * 1000) / 10}%`,
  },
};

/* UI labels for each layer */
export const LAYER_LABELS = {
  [LAYER_METRICS.SCHOOLS_ELEMENTARY]: 'Elementary',
  [LAYER_METRICS.SCHOOLS_MIDDLE]: 'Middle',
  [LAYER_METRICS.SCHOOLS_HIGH]: 'High',
  [LAYER_METRICS.CRIME_ALL]: 'All',
  [LAYER_METRICS.CRIME_VIOLENT]: 'Violent',
  [LAYER_METRICS.CRIME_PROPERTY]: 'Property',
  [LAYER_METRICS.PRICE_SQFT]: 'Per Square Foot',
  [LAYER_METRICS.AVG_PRICE]: 'Average',
  [LAYER_METRICS.FORECAST]: 'One Year',
};

/* The sought key at feature.properties.level for each school layer */
export const SCHOOLS_LAYER_FILTER_DEF = {
  [LAYER_METRICS.SCHOOLS_ELEMENTARY]: 'P',
  [LAYER_METRICS.SCHOOLS_MIDDLE]: 'M',
  [LAYER_METRICS.SCHOOLS_HIGH]: 'H',
};

export const SCHOOL_MARKER_IMAGE_IDS = {
  SCHOOL_MARKER_IMAGE_0: 'school-marker-image-0',
  SCHOOL_MARKER_IMAGE_1: 'school-marker-image-1',
  SCHOOL_MARKER_IMAGE_2: 'school-marker-image-2',
  SCHOOL_MARKER_IMAGE_3: 'school-marker-image-3',
  SCHOOL_MARKER_IMAGE_4: 'school-marker-image-4',
  SCHOOL_MARKER_IMAGE_UNRANKED: 'school-marker-image-unranked',
};

export const LAYER_GROUP_FEATURE_COLORS = {
  [LAYER_GROUP_KEYS.FORECAST]: [
    '#ccfb9c',
    '#b6f175',
    '#7be27c',
    '#00ceb8',
    '#0092a5',
  ],
  [LAYER_GROUP_KEYS.PRICE]: [
    '#ccfb9c',
    '#b6f175',
    '#7be27c',
    '#00ceb8',
    '#0092a5',
  ],
  [LAYER_GROUP_KEYS.CRIME]: [
    '#00df9d',
    '#7be27c',
    '#ebdb00',
    '#ffb600',
    '#ff8153',
  ],
  [LAYER_GROUP_KEYS.SCHOOLS]: [
    '#fd8a5f',
    '#feb913',
    '#e9db39',
    '#93df87',
    '#36dba2',
  ],
};

export const LAYERS_TO_USE_GRADIENTS = [
  LAYER_METRICS.CRIME_ALL,
  LAYER_METRICS.CRIME_VIOLENT,
  LAYER_METRICS.CRIME_PROPERTY,
  LAYER_METRICS.PRICE_SQFT,
  LAYER_METRICS.AVG_PRICE,
  LAYER_METRICS.FORECAST,
];

export const SCHOOL_DEFAULT_COLOR = '#DCDCDC';

/* For each interval: [lowEndOfRange, highEndOfRange, color, rangeDisplay, mapboxImageID] */
export const SCHOOLS_LEGEND_INTERVALS: LegendInterval[] = [
  [
    -1,
    20,
    LAYER_GROUP_FEATURE_COLORS[LAYER_GROUP_KEYS.SCHOOLS][0],
    ['1%', '20%'],
    SCHOOL_MARKER_IMAGE_IDS.SCHOOL_MARKER_IMAGE_0,
  ],
  [
    20,
    40,
    LAYER_GROUP_FEATURE_COLORS[LAYER_GROUP_KEYS.SCHOOLS][1],
    ['21%', '40%'],
    SCHOOL_MARKER_IMAGE_IDS.SCHOOL_MARKER_IMAGE_1,
  ],
  [
    40,
    60,
    LAYER_GROUP_FEATURE_COLORS[LAYER_GROUP_KEYS.SCHOOLS][2],
    ['41%', '60%'],
    SCHOOL_MARKER_IMAGE_IDS.SCHOOL_MARKER_IMAGE_2,
  ],
  [
    60,
    80,
    LAYER_GROUP_FEATURE_COLORS[LAYER_GROUP_KEYS.SCHOOLS][3],
    ['61%', '80%'],
    SCHOOL_MARKER_IMAGE_IDS.SCHOOL_MARKER_IMAGE_3,
  ],
  [
    80,
    100,
    LAYER_GROUP_FEATURE_COLORS[LAYER_GROUP_KEYS.SCHOOLS][4],
    ['81%', '100%'],
    SCHOOL_MARKER_IMAGE_IDS.SCHOOL_MARKER_IMAGE_4,
  ],
];

export const LAYER_GROUP_LEGEND_CONFIG = {
  [LAYER_GROUP_KEYS.FORECAST]: {
    valueSuffix: '',
    isCurrencyBased: false,
    isPercentBased: true,
  },
  [LAYER_GROUP_KEYS.SCHOOLS]: {
    valueSuffix: '',
    isCurrencyBased: false,
    isPercentBased: false,
  },
  [LAYER_GROUP_KEYS.CRIME]: {
    valueSuffix: '%',
    isCurrencyBased: false,
    isPercentBased: false,
  },
  [LAYER_GROUP_KEYS.PRICE]: {
    valueSuffix: '',
    isCurrencyBased: true,
    isPercentBased: false,
  },
};

export const BLOCK_FEATURES_FILTER = [
  'any',
  ['>', ['number', ['get', 'aland'], Infinity], 0],
  ['==', ['number', ['get', 'awater'], Infinity], 0],
];

export const TOO_FAR_ZOOMED_OUT_ERROR_MESSAGE_FOR_LAYER_GROUP = {
  [LAYER_GROUP_KEYS.FORECAST]: `Please zoom in to see the ${LAYER_GROUP_LABELS[
    LAYER_GROUP_KEYS.FORECAST
  ].label.toLowerCase()} heatmap.`,
  [LAYER_GROUP_KEYS.SCHOOLS]: 'Please zoom in to see schools on the map.',
  [LAYER_GROUP_KEYS.CRIME]: `Please zoom in to see the ${LAYER_GROUP_LABELS[
    LAYER_GROUP_KEYS.CRIME
  ].label.toLowerCase()} heatmap.`,
  [LAYER_GROUP_KEYS.PRICE]: `Please zoom in to see the ${LAYER_GROUP_LABELS[
    LAYER_GROUP_KEYS.PRICE
  ].label.toLowerCase()} heatmap.`,
};

export const NO_DATA_FOR_LAYER_MESSAGE =
  "We're sorry, we don't have heatmap data for this area";

export const MARKER_IMAGE_IDS = {
  CLUSTER: 'cluster' as 'cluster',
  OFF_MARKET: 'offMarket' as 'offMarket',
  ON_MARKET: 'onMarket' as 'onMarket',
  ON_MARKET_VISITED: 'onMarketViewed' as 'onMarketViewed',
  SUBJECT: 'subject' as 'subject',
  SUBJECT_OFF_MARKET: 'subjectOffMarket' as 'subjectOffMarket',
  // Shared with Activity IDs so that we can easily match them up:
  BARS: ACTIVITY_IDS.BARS,
  CAFES: ACTIVITY_IDS.CAFES,
  ENTERTAINMENT: ACTIVITY_IDS.ENTERTAINMENT,
  GROCERY: ACTIVITY_IDS.GROCERY,
  PARKS: ACTIVITY_IDS.PARKS,
  RESTAURANTS: ACTIVITY_IDS.RESTAURANTS,
};

export const SCHOOL_MARKER_IMAGE_URL_BY_ID: { [key: string]: string } = {
  [SCHOOL_MARKER_IMAGE_IDS.SCHOOL_MARKER_IMAGE_0]: schoolIcon0,
  [SCHOOL_MARKER_IMAGE_IDS.SCHOOL_MARKER_IMAGE_1]: schoolIcon1,
  [SCHOOL_MARKER_IMAGE_IDS.SCHOOL_MARKER_IMAGE_2]: schoolIcon2,
  [SCHOOL_MARKER_IMAGE_IDS.SCHOOL_MARKER_IMAGE_3]: schoolIcon3,
  [SCHOOL_MARKER_IMAGE_IDS.SCHOOL_MARKER_IMAGE_4]: schoolIcon4,
  [SCHOOL_MARKER_IMAGE_IDS.SCHOOL_MARKER_IMAGE_UNRANKED]: schoolIconUnranked,
};

export const ACTIVITY_MARKER_IMAGE_URL_BY_ID: { [key: string]: string } = {
  [MARKER_IMAGE_IDS.BARS]: barPinIcon,
  [MARKER_IMAGE_IDS.CAFES]: cafePinIcon,
  [MARKER_IMAGE_IDS.ENTERTAINMENT]: entertainmentPinIcon,
  [MARKER_IMAGE_IDS.GROCERY]: groceryPinIcon,
  [MARKER_IMAGE_IDS.PARKS]: parkPinIcon,
  [MARKER_IMAGE_IDS.RESTAURANTS]: restaurantPinIcon,
};

/* Account for the icon's height */
export const MARKER_POPUP_Y_OFFSET = -53;
export const FIT_POPUP_PADDING_WITH_MOBILE_CONTROLS = { x: 60, y: 20 };
export const FIT_POPUP_PADDING_WITH_DESKTOP_CONTROLS = { x: 20, y: 100 };

/* Constants used in the Mapbox symbol layer for API-generated and Mapbox generated clusters */
export const CLUSTER_MARKER_DISPLAY_CONSTANTS = {
  TEXT_COLOR: '#FFF',
  TEXT_OFFSET: [0, 0.1],
  TEXT_SIZE: 14,
  TEXT_MAX_WIDTH: 2,
  TEXT_LINE_HEIGHT: 1.0,
  MAPBOX_CLUSTER_ICON_SIZE_STEP_DEF: [
    0.28, 3, 0.3, 5, 0.35, 10, 0.45, 15, 0.5, 500, 0.55,
  ],
};

/* Fonts available for use in Mapbox layer style definitions */
export const MAPBOX_FONTS = {
  AVENIR_MEDIUM: 'Avenir LT W01 Medium',
  AVENIR_HEAVY: 'Avenir LT W01 Heavy',
  AVENIR_BOOK: 'Avenir LT W01 Book',
  AVENIR_BLACK: 'Avenir LT W01 Black',
};

export const MAP_MARKER_OFF_MARKET_TEXT_COLOR = '#4A4A4A';
export const MAP_MARKER_OFF_MARKET_VISITED_TEXT_COLOR = '#999';
export const MAP_MARKER_ACTIVE_LISTING_TEXT_COLOR = '#FFF';

/* 201 Spear St */
export const MAP_FALLBACK_LOCATION = [37.794, -122.39366] as [number, number];

export const COMPLETE_MLS_COVERAGE_LEVEL = 30;

export const HALFTONE_IMAGES = [
  { url: halftone1, name: '0' },
  { url: halftone2, name: '1' },
  { url: halftone3, name: '2' },
  { url: halftone4, name: '3' },
  { url: halftone5, name: '4' },
  { url: halftoneEmpty, name: 'empty' },
];
