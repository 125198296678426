const AvmBreakdownToolTipText: React.FC = () => {
  return (
    <div>
      This is ComeHome's estimated market value for this home. It is not a
      formal appraisal. This estimate is based on our market knowledge, and it
      should be used as a starting point to determine a home's value.
      <br />
      <br />
      To determine how a home measures up against other typical homes in the
      same area, we first calculate medians for each attribute: the homes that
      are right in the middle of the range of properties in that area. After
      calculating the median values for lot size, square footage, age, and
      bedroom and bathroom count, we then compare the home you're evaluating to
      the medians and share that comparison with you. This is how we are able to
      determine what's typical in an area and what's not, and how the home
      you're viewing measures up against the typical features in the area.
    </div>
  );
};

export default AvmBreakdownToolTipText;
