import React from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import ExternalLink from '@client/components/generic/ExternalLink';
import theme from '@client/css-modules/DisclaimerFairHousing.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type Props = {
  theme: Theme;
  url: string;
  className?: string;
  ariaLabel: string;
  state: string;
};

const DisclaimerFairHousing: React.FC<Props> = ({
  theme,
  url,
  ariaLabel,
  state,
}) => {
  const { linkColor } = useCobrandStyles();

  return (
    <ExternalLink
      theme={theme}
      className={theme.DisclaimerFairHousing}
      ariaLabel={ariaLabel}
      style={{
        color: linkColor,
      }}
      href={url}
    >
      {state} State Fair Housing Notice
    </ExternalLink>
  );
};

const ThemedDisclaimerFairHousing = themr(
  'DisclaimerFairHousing',
  theme
)(DisclaimerFairHousing);

export default ThemedDisclaimerFairHousing;
