import { connect } from 'react-redux';
import { Theme } from '@friendsofreactjs/react-css-themr';

import { AlertPropertyDetails } from '@client/store/types/alerts';
import { NormalizedProperty } from '@client/store/types/property';
import PropertyIntro from '@client/components/PropertyIntro';
import {
  getPropertyBasicInfo,
  getPropertyListingStatusObject,
  getPropertyIsInWatchList,
  getPropertyDetailsNormalized,
} from '@client/store/selectors/property-details.selectors';
import { View } from '@client/routes/constants';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';

type OwnProps = {
  ariaLabel?: string;
  isShowingDaysOnMarket?: boolean;
  isShowingSimilarity?: boolean;
  isShowingCondensedPropertyInfo?: boolean;
  onStreetAddressLinkKeyDown?: () => void;
  propertyDetails?: NormalizedProperty | AlertPropertyDetails;
  rel?: string;
  theme?: Theme;
};

const mapStateToProps = (state, ownProps: OwnProps) => {
  return {
    /* For use in property cards, we pass in propertyDetails.  For use in the PDP, we pull the
     * object from state */
    propertyDetails:
      ownProps.propertyDetails || getPropertyDetailsNormalized(state),
    propertyBasicInfo: getPropertyBasicInfo(state),
    isAddedToWatchList: getPropertyIsInWatchList(state),
    listingStatus: getPropertyListingStatusObject(state),
    isFiringGeneralDetailsAnalyticsEvent:
      getCurrentView(state) === View.PROPERTY_DETAILS,
  };
};

export default connect(mapStateToProps, {})(PropertyIntro);
