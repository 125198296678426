import LaptopComeHome from '@client/assets/images/laptop-comehome.png';
import PoweredByComeHomeLogo from '@client/components/PoweredByLogo/PoweredByComeHomeLogo';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import styled from 'styled-components';

interface ColorBlockProps {
  $background: string;
}

const StyledMain = styled.main`
  position: relative;
  width: 100vw;
  height: 100vh;
  min-height: 554px;
`;

const StyledColorBlock = styled.div<ColorBlockProps>`
  background: ${(props) => props.$background};
  height: 31.2%;
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const StyledImg = styled.img`
  height: 445px;
  width: auto;
  position: absolute;
  right: 0;
  bottom: calc(31.2% - 156px);
`;

const StyledPoweredByLogo = styled(PoweredByComeHomeLogo)`
  position: absolute;
  bottom: 8%;
  width: 150px;
  left: 0;
  right: 0;
  margin: auto;
`;

const StyledText = styled.h1`
  font-size: 20px;
  margin: 0 auto;
  font-weight: 900;
  line-height: 122.6%;
  width: 256px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 22%;
  z-index: 1;
`;

const LODirectMobileError = () => {
  const { loDirectAuthBackgroundColor } = useCobrandStyles();

  return (
    <StyledMain>
      <StyledText>Please access this feature on a desktop device</StyledText>
      <StyledColorBlock $background={loDirectAuthBackgroundColor} />
      <StyledImg
        src={LaptopComeHome}
        alt="A laptop with the ComeHome logo on the screen"
      />
      <StyledPoweredByLogo useWhiteLogo />
    </StyledMain>
  );
};

export default LODirectMobileError;
