import React from 'react';
import MarketingModuleHouse from '@client/assets/images/marketing-module-house.svg';

const MarketingModuleHouseImg: React.FunctionComponent<
  React.HTMLAttributes<HTMLImageElement>
> = (props) => {
  return <img src={MarketingModuleHouse} alt={'house'} {...props} />;
};

export default MarketingModuleHouseImg;
