import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { routeChange } from '@src/redux-saga-router-plus/actions';

import {
  getNearbyListings,
  getPropertyAnalyticsEventAddress,
  getPropertyIsActiveStatusType,
} from '@client/store/selectors/property-details.selectors';
import NearbyListings from '@client/components/NearbyListings';
import { View } from '@client/routes/constants';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { AnalyticsEventAddress } from '@client/store/types/analytics';
import { getIsTabletOrSmallerSize } from '@client/store/selectors/match-media.selectors';

const mapStateToProps = (state) => {
  const nearbyListings = getNearbyListings(state);
  return {
    hasListings: !!nearbyListings.length,
    nearbyListings: nearbyListings,
    isCurrentPDPActiveListing: getPropertyIsActiveStatusType(state),
    subjectPropertyAddress: getPropertyAnalyticsEventAddress(state),
    isShowingDaysOnMarket: !getIsTabletOrSmallerSize(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handlePropertyClick: (
    slug: string,
    subjectPropertyAddress: AnalyticsEventAddress
  ) => {
    dispatch(routeChange({ view: View.PROPERTY_DETAILS, params: { slug } }));
    dispatch(
      reportEvent('click_other_properties_property_card', '', {
        address: subjectPropertyAddress,
      })
    );
  },
  reportNearbyListingsCarouselDotClick: (address: AnalyticsEventAddress) => {
    dispatch(
      reportEvent('click_other_properties_property_card', '', { address })
    );
  },
  handleNearbyListingsWatchClick: () =>
    dispatch(reportEvent('click_nearby_properties_watch')),
  handleNearbyListingsUnwatchClick: () =>
    dispatch(reportEvent('click_nearby_properties_unwatch')),
  handleNearbyListingsUnwatchConfirmClick: () =>
    dispatch(reportEvent('click_nearby_properties_unwatch_confirm')),
});

export default connect(mapStateToProps, mapDispatchToProps)(NearbyListings);
