import { ScreenSize } from '@client/components/MatchMedia';
import {
  COLUMN_LAYOUT_CHANGE,
  SCREEN_RESIZE,
  SCREEN_RESIZE_ZOOM_CHANGE,
} from '@client/store/actions/match-media.actions';
import { Action } from '@client/store/actions';

export const MATCH_MEDIA_INITIAL_STATE: MatchMediaState = {
  columnLayout: null,
  columnLayoutMediaQuery: null,
  mediaQuery: null,
  screenSize: null,
  deviceIOS: false,
  deviceAndroid: false,
  screenResizeZoomMediaQuery: null,
  screenZoomSize: null,
};

export type MatchMediaState = {
  columnLayout: string | null;
  columnLayoutMediaQuery: string | null;
  mediaQuery: string | null;
  screenSize: ScreenSize | null;
  deviceIOS: boolean;
  deviceAndroid: boolean;
  screenResizeZoomMediaQuery: string | null;
  screenZoomSize: string | null;
};

export default function matchMediaReducer(
  state = MATCH_MEDIA_INITIAL_STATE,
  action: Action
): MatchMediaState {
  switch (action.type) {
    case SCREEN_RESIZE:
      return {
        ...state,
        mediaQuery: action.payload.mediaQuery,
        screenSize: action.payload.screenSize,
        deviceIOS: action.payload.deviceIOS,
        deviceAndroid: action.payload.deviceAndroid,
      };
    case COLUMN_LAYOUT_CHANGE:
      return {
        ...state,
        columnLayoutMediaQuery: action.payload.columnLayoutMediaQuery,
        columnLayout: action.payload.columnLayout,
      };
      case SCREEN_RESIZE_ZOOM_CHANGE:
        return {
          ...state,
          screenResizeZoomMediaQuery: action.payload.screenResizeZoomMediaQuery,
          screenZoomSize: action.payload.screenZoomSize
        };
    default:
      return state;
  }
}
