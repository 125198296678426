import { TestProperty } from '@client/store/types/test-properties-page';

export const FETCH_TEST_PROPERTIES = 'GET_TEST_PROPERTIES';
export const FETCH_TEST_PROPERTIES_SUCCESS = 'GET_TEST_PROPERTIES_SUCCESS';

type FetchTestProperties = {
  type: typeof FETCH_TEST_PROPERTIES;
};
export const fetchTestProperties = (): FetchTestProperties => ({
  type: FETCH_TEST_PROPERTIES,
});

type FetchTestPropertiesSuccess = {
  type: typeof FETCH_TEST_PROPERTIES_SUCCESS;
  payload: { data: TestProperty[] };
};
export const fetchTestPropertiesSuccess = (
  data: TestProperty[]
): FetchTestPropertiesSuccess => ({
  type: FETCH_TEST_PROPERTIES_SUCCESS,
  payload: { data },
});

export type TestPropertiesAction =
  | FetchTestProperties
  | FetchTestPropertiesSuccess;
