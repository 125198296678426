import {
  EventChannel,
  Subscribe,
  Unsubscribe,
  buffers,
  eventChannel,
} from 'redux-saga';
import { HistoryType } from './types';

const BUFFER_LIMIT = 5;

export default function createHistoryChannel(
  history: HistoryType
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
): EventChannel<Subscribe<Function>> {
  function subscribe(emitter): Unsubscribe {
    let initialLocation;

    if (typeof history.getCurrentLocation === 'function') {
      initialLocation = history.getCurrentLocation();
    } else {
      initialLocation = history.location;
    }

    if (initialLocation) {
      emitter(initialLocation);
    }

    return history.listen((location) => {
      emitter(location);
    });
  }

  return eventChannel(subscribe, buffers.fixed(BUFFER_LIMIT));
}
