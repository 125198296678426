import React from 'react';

/**
 * Given a starting element and a classname, search the parent elements, returning the parent
 * element that contains the passed-in classname
 */
export const findParentElementForClassname = (
  startingEle: Element | Text,
  className: string,
  checkStartingEle?: boolean
): Element | Text | null => {
  let ele = startingEle;
  /* Check if DOMTokenList contains our target classname */
  const elementHasClassname = (ele: Element | Text) =>
    (ele as Element).classList &&
    (ele as Element).classList.contains(className);

  if (checkStartingEle && elementHasClassname(ele)) {
    return ele;
  }

  /* Iterate up DOM tree, checking each parent element for the className */
  while (ele.parentNode) {
    ele = ele.parentNode as Element;
    if (elementHasClassname(ele)) {
      return ele;
    }
  }
  return null;
};

/**
 * Determine if element is a descendant of a DOM node
 */
export const elementIsDescendant = (ele: Element, parent: Element): boolean => {
  let node: Node | null = ele || null;
  while (node !== null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

const getIsSyntheticEvent = (
  e: React.MouseEvent | React.KeyboardEvent | MouseEvent | KeyboardEvent
): e is React.MouseEvent | React.KeyboardEvent => {
  return !!(e as React.MouseEvent | React.KeyboardEvent).nativeEvent;
};

/**
 * Given an event target, return the data-event-name nearest to it in the DOM tree
 */
export const getDataAttrEventNameForDOMEventTarget = (
  e: React.MouseEvent | React.KeyboardEvent | MouseEvent | KeyboardEvent
) => {
  const nativeEvent = getIsSyntheticEvent(e) ? e.nativeEvent : e;
  /* Exit early if the JS event isn't eligible for triggering an analytics event */
  if (
    !(
      nativeEvent instanceof MouseEvent ||
      (nativeEvent instanceof KeyboardEvent &&
        (nativeEvent.key === ' ' || nativeEvent.key === 'Enter'))
    )
  ) {
    return {
      eventName: null,
      parentEventName: null,
    };
  }
  /* Prevent event from bubbling to any higher-level event reporting handlers */
  e.stopPropagation();
  let currentNode: HTMLElement | null = e.target as HTMLElement;

  /* Search for data-event-name in parentElement tree, stopping when we find one */
  while (currentNode && !currentNode?.dataset?.eventName) {
    currentNode = currentNode?.parentElement || null;
  }

  const eventName = currentNode?.dataset?.eventName;
  const parentEventName = currentNode?.dataset?.parentEventName;
  const eventDataJsonString = currentNode?.dataset?.eventDataJson;
  let eventData: Object | null = null;

  try {
    eventData = eventDataJsonString && JSON.parse(eventDataJsonString);
  } catch (e) {
    console.error('event-data-json value not parseable as JSON');
  }

  return {
    eventName: eventName || null,
    parentEventName: parentEventName || null,
    eventData,
  };
};

export interface AnchorsAttributes {
  href: string;
  innerText: string;
}

/**
 * We host www.housecanary.com from wpengine. They use Cloudflare
 * to scrape email addresses. Thus, we need to put back some email addresses
 * (e.g. support@housecanary.com) in some legal documents pages
 */
export const modifyAnchors = (
  anchors: HTMLCollectionOf<HTMLAnchorElement>,
  attributes: AnchorsAttributes,
  targetInnerText: string
) => {
  Array.from(anchors).forEach((v: HTMLAnchorElement) => {
    const href = v.getAttribute('href');
    const innerText = v.innerText;

    if (innerText === targetInnerText && href) {
      v.href = attributes.href;
      v.innerText = attributes.innerText;
    }
  });
};
