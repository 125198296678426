import { put, select } from 'redux-saga/effects';
import { routeChange } from '@src/redux-saga-router-plus/actions';

import { getIsLoggedIn } from '@client/store/selectors/auth.selectors';
import { View } from '@client/routes/constants';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';

export default function* routeLogin() {
  const shouldPreventComeHomeAuth = (yield select(
    getIsFeatureEnabled('prevent_comehome_auth')
  )) as boolean;
  const isLoggedIn = (yield select(getIsLoggedIn)) as boolean;

  /**
   * If prevent_comehome_auth feature is enabled, redirect to search.
   * For partners that use an OAuth integration for authentication,
   * we need to ensure that users cannot reach our standard login/signup screen
   * and create an account outside of the OAuth flow.
   */
  if (shouldPreventComeHomeAuth) {
    yield put(routeChange({ view: View.SEARCH }));
  } else {
    if (isLoggedIn) {
      yield put(routeChange({ view: View.WATCHLIST }));
    }
  }
}
