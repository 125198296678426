import PersonImagePlaceholder from '@client/components/generic/PersonImagePlaceholder';
import { useAuth } from '@client/hooks/auth.hooks';
import { isLOUser } from '@client/store/types/auth';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import React from 'react';
import styled from 'styled-components';

type Props = {
  margin?: string;
  height?: string;
  width?: string;
  onClick?: () => void;
  dataHcName?: string;
};

const StyledUserImgWrapperDiv = styled.div<{
  $height: Props['height'];
  $width: Props['width'];
  $margin: Props['margin'];
}>`
  height: ${(props) => props.$height || '54px'};
  width: ${(props) => props.$width || '54px'};
  border-radius: 50%;
  overflow: hidden;
  margin: ${(props) => props.$margin || '0'};
  background: #ededef;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledImg = styled.img<{ onClick: Props['onClick'] }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
`;

const LODirectUserImage: React.FC<Props> = ({
  dataHcName,
  margin,
  height,
  width,
  onClick,
}) => {
  const { user } = useAuth();
  if (!isLOUser(user)) return null; /* For type safety */
  const { first_name: firstName, last_name: lastName } = user;
  const usersName = `${firstName}${
    firstName && lastName ? ' ' : ''
  }${lastName}`;

  return (
    <StyledUserImgWrapperDiv
      data-hc-name={dataHcName || 'lo-direct-user-image-link'}
      $margin={margin}
      $height={height}
      $width={width}
    >
      {user?.headshot ? (
        <StyledImg
          src={user?.headshot}
          alt={`${usersName}`}
          onClick={onClick}
          onKeyDown={onClick && onEnterOrSpaceKey(onClick)}
        />
      ) : (
        <PersonImagePlaceholder onClick={onClick} />
      )}
    </StyledUserImgWrapperDiv>
  );
};

export default LODirectUserImage;
