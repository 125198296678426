import AdditionalHomeDetails from '@client/components/AdditionalHomeDetails';
import ConditionalFeature from '@client/components/ConditionalFeature';
import LoadingSection from '@client/components/generic/LoadingSection';
import GrantProgramPDPBanner from '@client/components/GrantProgram/GrantProgramPDPBanner';
import MLSAttributionPDPNWMLSException from '@client/components/MLSAttributionPDPNWMLSException';
import OpenHouseInfo from '@client/components/OpenHouseInfo';
import PDPRightRailCardChase from '@client/components/PDPRightRailCard/PDPRightRailCardChase';
import ScrollToTop from '@client/components/ScrollToTop';
import AvmBreakdownVerticalContainer from '@client/containers/avm-breakdown-vertical.container';
import BreakoutSectionsContainer from '@client/containers/breakout-sections.container';
import CarouselRowContainer from '@client/containers/carousel-row.container';
import LoanQualificationLetterContainer from '@client/containers/cobrand/chase/loan-qualification-letter-chase.container';
import Footer from '@client/containers/footer.container';
import ListingInfoContainer from '@client/containers/listing-info.container';
import MapPropertyPageContainer from '@client/containers/map-property-page.container';
import NearbyListingsContainer from '@client/containers/nearby-listings.container';
import PropertyIntroContainer from '@client/containers/property-intro.container';
import WatchListActionButtonContainer from '@client/containers/watchlist-action-button.container';
import theme from '@client/css-modules/PropertyPageMobileContentsChase.css';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  getPropertyDetailsNormalized,
  getPropertyIsInWatchList,
} from '@client/store/selectors/property-details.selectors';
import { PropertyPageMobileContentsProps } from '@client/store/types/cobranded-components/property-page';
import React from 'react';
import { useSelector } from 'react-redux';

/**
 * Contains the contents displayed within the PDP on mobile. Used in both `PropertyPage` and `PropertyPageModal`.
 */
const PropertyPageMobileContentsChase: React.FC<
  PropertyPageMobileContentsProps
> = ({
  isPDPDataLoaded,
  isTabletView,
  hidePDPRightRailCard,
  isCanaryUIFeatureEnabled,
}) => {
  const isPropertyInWatchList = useSelector(getPropertyIsInWatchList);
  const property = useSelector(getPropertyDetailsNormalized);
  const isTempChasePdpAddShareFunctionalityEnabled = useSelector(
    getIsFeatureEnabled('temp_chase_pdp_add_share_functionality')
  );

  const watchlistAddress = property && {
    street: property.streetAddress,
    city: property.city,
    state: property.state,
    zip: property.zipcode,
    unit: property.unit,
    address_id: property.hcAddressId,
    slug: property.slug,
  };

  return (
    <div className={theme.PropertyPageMobileContents}>
      {isTabletView ? null : (
        <CarouselRowContainer theme={theme} useFullWidthImage />
      )}
      <MLSAttributionPDPNWMLSException />
      {!isTabletView &&
        property &&
        !isTempChasePdpAddShareFunctionalityEnabled && (
          <div className={theme.WatchListButtonPositioner}>
            <WatchListActionButtonContainer
              address={watchlistAddress!}
              fullAddress={property.fullStreetAddress}
              isAddedToWatchList={isPropertyInWatchList}
              addressSlug={property.slug}
              theme={theme}
              shouldHandleCheckingForWatchListStatus={false}
            />
          </div>
        )}
      <PropertyIntroContainer
        theme={theme}
        isShowingCondensedPropertyInfo
        isShowingDaysOnMarket
      />
      {!hidePDPRightRailCard && (
        <PDPRightRailCardChase
          theme={theme}
          isShowingShareAndSaveSection={
            isTempChasePdpAddShareFunctionalityEnabled
          }
          isCanaryUIFeatureEnabled={isCanaryUIFeatureEnabled}
        />
      )}
      <LoanQualificationLetterContainer theme={theme} />
      <div className={theme.InitDataSection}>
        <LoadingSection
          isLoading={!isPDPDataLoaded}
          className={theme.InitDataLoadingSection}
        >
          {isPDPDataLoaded && (
            <>
              {!isTabletView && (
                <ConditionalFeature renderIfFeaturesEnabled={['grant_program']}>
                  <GrantProgramPDPBanner />
                </ConditionalFeature>
              )}
              <OpenHouseInfo theme={theme} />
              <ListingInfoContainer theme={theme} />
              <AdditionalHomeDetails />
              {isTabletView && (
                <ConditionalFeature renderIfFeaturesEnabled={['grant_program']}>
                  <GrantProgramPDPBanner />
                </ConditionalFeature>
              )}
              <MapPropertyPageContainer theme={theme} />
              <AvmBreakdownVerticalContainer theme={theme} />
              <BreakoutSectionsContainer theme={theme} />
              <NearbyListingsContainer theme={theme} />
              <ScrollToTop />
              <hr className={theme.SectionDivider} />
            </>
          )}
        </LoadingSection>
      </div>
      <div className={theme.FooterSection}>
        <Footer theme={theme} shouldUseSectionElement />
      </div>
    </div>
  );
};
export default PropertyPageMobileContentsChase;
