import classNames from 'classnames';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import { NormalizedProperty } from '@client/store/types/property';
import theme from '@client/css-modules/MapMarkerPopup.css';
import { placeholderFormatter } from '@client/utils/string.utils';
import {
  dollarsFormatter,
  numberFormatter,
} from '@client/utils/formatter.utils';
import MLSAttribution from '@client/components/MLSAttribution';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';

type MapMarkerPopupProps = {
  propertyData?: NormalizedProperty;
  theme: Theme;
  handleClick?: (propertyData: NormalizedProperty) => void;
};

type Color = {
  color: string;
};

const getColorStyle = (color: string | null): Color | undefined => {
  const colors = {
    green: theme.colorGreen,
    yellow: theme.colorYellow,
    red: theme.colorRed,
  };

  if (color) {
    return colors[color] ? { color: colors[color] } : { color: color };
  }

  return undefined;
};

/**
 * Popup to bind to a map marker
 */
export const MapMarkerPopup: React.FC<MapMarkerPopupProps> = ({
  propertyData,
  theme,
  handleClick,
}) => {
  /* When this component is actually rendered this will always be defined, but this check is needed
   * for typing since we're initially defining the component in a container without `propertyData`
   * then cloning it and adding propertyData in Maps.tsx */
  if (!propertyData || !handleClick) {
    return null;
  }

  const {
    city,
    state,
    zipcode,
    beds,
    baths,
    sqFt,
    // price
    value,
    statusLabel,
    similarity,
  } = propertyData;
  const labels = {
    price: {
      text: statusLabel,
    },
    similarity: {
      text: similarity,
      color: similarity === 'high' ? 'green' : null,
    },
  };

  const streetAddress = propertyData.streetAddress || 'Address Withheld';
  const fullCityStateZip =
    city && state && zipcode ? `${city}, ${state} ${zipcode}` : '';
  const similarityText =
    labels.similarity && labels.similarity.text ? labels.similarity.text : null;
  const similarityColor =
    labels.similarity && labels.similarity.color
      ? labels.similarity.color
      : null;
  const priceText =
    labels.price && labels.price.text ? labels.price.text : null;
  const defaultLabelBeds = 'Bd';
  const defaultLabelBaths = 'Ba';

  return (
    <div
      className={classNames(
        theme.MapMarkerPopup,
        theme['MapMarkerPopup-NoPhoto'],
        {
          [theme.MapMarkerPopupNoValue]: !propertyData.value,
        }
      )}
    >
      <div
        className={theme.PropertyCard}
        role="dialog"
        aria-modal="true"
        aria-label="Property details"
        onClick={() => handleClick(propertyData)}
      >
        <div className={theme.PropertyDetailsWrapper}>
          <button
            data-hc-name="popup-address-text"
            className={theme.Address}
            title={streetAddress}
            type="button"
            aria-label={`Navigate to property details for ${streetAddress}`}
            onKeyDown={onEnterOrSpaceKey(() => handleClick(propertyData))}
          >
            {streetAddress}
          </button>
          <div
            data-hc-name="popup-citystatezip-text"
            className={theme.CityStateZip}
            title={fullCityStateZip}
          >
            {!!city && fullCityStateZip}
          </div>
          <div
            className={theme.OtherDetails}
            data-hc-name="popup-otherdetails-text"
          >
            <div className={theme.SingleAttribute}>
              {placeholderFormatter(beds)} {defaultLabelBeds}
            </div>
            <div className={theme.SingleAttribute}>
              {placeholderFormatter(baths)} {defaultLabelBaths}
            </div>
            <div className={theme.SingleAttribute}>
              {sqFt ? numberFormatter(sqFt) : placeholderFormatter(sqFt)} Sq Ft
            </div>
          </div>
          {similarityText && (
            <div className={theme.Similarity}>
              Similarity:{' '}
              <span
                className={theme.SimilarityValue}
                style={getColorStyle(similarityColor)}
              >
                {similarityText}
              </span>
            </div>
          )}
          <div className={theme.PriceRow}>
            <div className={theme.PriceCell}>
              {labels.price && (
                <div className={theme.PriceLabelWrapper}>
                  <span className={theme.PriceLabel}>{priceText}</span>
                </div>
              )}
              {value && (
                <div className={theme.Price} data-hc-name="popup-price-text">
                  {dollarsFormatter(value)}
                </div>
              )}
            </div>
          </div>
          <div className={theme.MLSLabel} data-hc-name="popup-mlslabel-text">
            <MLSAttribution propertyDetails={propertyData} />
          </div>
        </div>
      </div>
    </div>
  );
};

const ThemedMapMarkerPopup = themr('MapMarkerPopup', theme)(MapMarkerPopup);
export default ThemedMapMarkerPopup;
