import {
  LOGIN_SUCCESS,
  CREATE_USER_SUCCESS,
  RESET_AUTH_DEPENDENT_STATE,
} from '@client/store/actions/auth.actions';

import { Action } from '@client/store/actions';

export type TrackingState = {
  trackingId: string | null;
};

const INITIAL_STATE: TrackingState = {
  trackingId: null,
};

export default function updateTracking(
  state = INITIAL_STATE,
  action: Action
): TrackingState {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        trackingId: action.payload.user?.tracking_id || null,
      };
    case RESET_AUTH_DEPENDENT_STATE:
      return {
        ...state,
        trackingId: null,
      };
    default:
      return state;
  }
}
