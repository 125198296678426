import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ScaffoldingMarketingCard from '@client/components/ScaffoldingUI/ScaffoldingMarketingCard/ScaffoldingMarketingCard';
import { getScaffoldingCMGMarketingCardDataForPDP } from '@client/store/slices/scaffolding-data.slice';

const StyledScaffoldingMarketingCardPDP = styled(ScaffoldingMarketingCard)`
  padding-top: 20px;
  height: 333px;
`;

const ScaffoldingMarketingCardPDP = () => {
  const elementInstruction = useSelector(
    getScaffoldingCMGMarketingCardDataForPDP
  );
  if (!elementInstruction) {
    return <></>;
  }

  return (
    <StyledScaffoldingMarketingCardPDP
      elementInstruction={elementInstruction}
    />
  );
};

export default ScaffoldingMarketingCardPDP;
