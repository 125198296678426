export const LO_ID_TEMPLATE_KEY = '#loid#';

export const replaceTemplateKeyWithLoId = (
  url: string,
  loId: string | null
): string => {
  if (loId && url.includes(LO_ID_TEMPLATE_KEY)) {
    return url.replace(LO_ID_TEMPLATE_KEY, loId);
  } else {
    return url;
  }
};
