import { SEARCH_SHOW_MLS_REGISTRATION_CONFIRM } from '@client/store/actions/search.actions';

export const REGISTER_EMAIL = 'REGISTER_EMAIL';
export const REGISTER_EMAIL_FAILURE = 'REGISTER_EMAIL_FAILURE';

type RegisterEmailAction = {
  type: typeof REGISTER_EMAIL;
  payload: {
    email: string;
  };
};

export const registerEmail = ({
  email,
}: RegisterEmailAction['payload']): RegisterEmailAction => ({
  type: REGISTER_EMAIL,
  payload: {
    email,
  },
});

type RegisterEmailSuccessAction = {
  type: typeof SEARCH_SHOW_MLS_REGISTRATION_CONFIRM;
};

type RegisterEmailFailureAction = {
  type: typeof REGISTER_EMAIL_FAILURE;
  meta: { toast: { message: string } };
};

export const registerEmailFailure = (
  message: string
): RegisterEmailFailureAction => ({
  type: REGISTER_EMAIL_FAILURE,
  meta: {
    toast: {
      message: `Error registering email: ${message}`,
    },
  },
});

export type MLSNotifyAction =
  | RegisterEmailAction
  | RegisterEmailSuccessAction
  | RegisterEmailFailureAction;
