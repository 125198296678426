import { Api } from '@hc/hcmaps-mapboxgl/lib/components/Maps';
import Popup from '@hc/hcmaps-mapboxgl/lib/components/Popup';
import { MapAndAPIContext } from '@hc/hcmaps-mapboxgl/lib/context/map-and-api-context';
import { lngLatToLatLng } from '@hc/hcmaps-mapboxgl/lib/utils';
import mapboxgl, { MapboxEvent, Popup as MapboxPopup, Marker } from 'mapbox-gl';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import markerGrantProgramLabelImage from '@client/assets/images/grant-program-marker-label.png';
import markerCheckImageUrl from '@client/assets/images/marker-check.png';
import theme from '@client/css-modules/MapMarkersLayer.css';
import mapMarkerSVGGenerator from '@client/inline-svgs/map-markers-generator';
import {
  FIT_POPUP_PADDING_WITH_DESKTOP_CONTROLS,
  MARKER_IMAGE_IDS,
  MARKER_POPUP_Y_OFFSET,
} from '@client/store/map-constants';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  CensusTractData,
  fetchCensusTractData,
  selectCensusTractData,
} from '@client/store/slices/grant-program.slice';
import {
  APIClusterMapMarker,
  BusinessMarker,
  MapMarker,
  MapMarkerGeoJSONFeature,
  MultiUnitMapMarker,
  PropertyMapMarker,
  SubjectMapMarker,
} from '@client/store/types/maps';
import { ReduxState } from '@client/store/types/redux-state';
import { key } from '@client/utils/component.utils';
import { findParentElementForClassname } from '@client/utils/dom.utils';
import { reportToSentry } from '@client/utils/error.utils';
import {
  computeBoundsArrayFromLatLngPoints,
  getIsPointInsideBounds,
  isAPIClusterMarker,
  isBusinessMarker,
  isMultiUnitClusterMarker,
  isOffMarketMultiUnitClusterMarker,
  isOffMarketPropertyMarker,
  isOnMarketMultiUnitClusterMarker,
  isOnMarketPropertyMarker,
  isPropertyMarker,
} from '@client/utils/maps.utils';
import { getIsActiveListing } from '@client/utils/property.utils';

const PROPERTY_MARKER_DIMENSIONS = ['42px', '60px'];
const PROPERTY_MARKER_OFFSET = [0, 12] as [number, number];
const BUSINESS_MARKER_DIMENSIONS = ['37px', '45px'];
const BUSINESS_MARKER_OFFSET = [0, 0] as [number, number];
const markerInnerClassname = theme.MarkerInner;
const markerCheckImageClassname = theme.MarkerCheckImage;
const markerGrantProgramLabelClassname = theme.MarkerGrantProgramLabel;

type TriggerProp = string | number | boolean | null;

type State = {
  popupFeature: any;
};

type Props = {
  layerId: string;
  /* A list of geoJSON Point features */
  markerFeatures: MapMarkerGeoJSONFeature[];
  /* A mapping of image ids to icon URLs to assign as the src for the marker images. These are used
   * in conjunction with the dynamically generated SVGs from map-markers-generator.ts  */
  markerImageUrls?: { [id: string]: string };
  /* A DOM node to be set as the popup's content. Can be used with `createPortal`
   * to remotely render content inside of popup */
  PopupContentNode?: HTMLDivElement;
  /* Callback executed upon clicking a feature with the feature as the only argument */
  onMarkerClick: (
    e: MouseEvent | KeyboardEvent,
    feature: MapMarkerGeoJSONFeature
  ) => void;
  /* Callback executed upon hovering a feature with the map api and the feature as arguments */
  onMarkerMouseEnter?: (
    api: Api | null,
    feature?: MapMarkerGeoJSONFeature
  ) => void;
  /* Callback executed upon un-hovering a feature with the map api as the only argument */
  onMarkerMouseLeave?: (api: Api | null) => void;
  /* Callback executed upon closing a popup associated with a feature */
  onPopupClose?: VoidFunction;
  /* Padding to be applied when panning the map to fit a newly opened popup */
  fitPopupPadding?: { x: number; y: number };
  /* Used to remove popup if markers have been changed */
  dataChangedTrigger?: TriggerProp;
  /* Whether an open popup should be hidden if dataChangedTrigger changes */
  hidePopupOnDataChanged?: boolean;
  /* Color to change map pins to with custom filter */
  mapPinsColor: string;
  /* Color to use for visited map pins */
  mapPinsVisitedColor: string;
  /* Whether component should handle repositioning map to fit new markers when markers change */
  shouldFitMarkersInMap?: boolean;
  /* If provided, no popup will be shown when clicking the marker with this address slug */
  noPopupForAddressSlug?: string;
  /* A map feature that, when passed, will cause a popup to auto-open above the feature on mount.
   * The feature must be a property marker. */
  autoOpenPopupForMarkerFeature?: MapMarkerGeoJSONFeature | null;
  shouldPutDataHCNameOnMarkers?: boolean;
  isGrantProgramFeatureEnabled: boolean;
  grantProgramEligibleCensusTractData: CensusTractData;
  handleFetchGrantProgramCensusTracts: () => void;
};

/**
 * Renders an HTML marker for each Point geometry feature in a given geoJSON data source
 *
 * The flow for map markers is as follows:
 *
 *  - Outside of this component
 *    - On the search page, markers are created and updated in Redux state on map move.  This involves
 *      SearchPageMap.tsx and search.saga.ts.  The sagas completely manage maintaining the set of raw
 *      marker objects to be rendered.  No matter the usage, this component renders exactly the markers
 *      passed into it.
 *    - Marker objects are passed into this component via the `markerFeatures` prop
 *
 *  - Within this component
 *    - Whenever `dataChangedTrigger` changes (it's computed from `markerFeatures`), `handleUpdateMarkers`
 *      runs which iterates `markerFeatures`, building new marker DOM objects for markers that don't yet
 *      exist in the cache, and referencing cached DOM objects when they do exist
 *    - The marker DOM objects that don't already exist within the map are added to the map via the Mapbox
 *      `marker.addTo(map)` method
 *    - Cleanup is done via comparing the current markers on the map with the previous markers on the map,
 *      removing all previous markers from the DOM that aren't present within the current marker set.  This
 *      is crucial to avoiding a memory leak
 *    - In `componentWillUnmount` another round of cleanup is done to remove all current markers from the DOM
 */
class MapMarkersLayer extends PureComponent<Props, State> {
  static contextType = MapAndAPIContext;
  declare context: React.ContextType<typeof MapAndAPIContext>;

  state: State = {
    popupFeature: null,
  };

  sourceId = `${this.props.layerId}-source`;
  mapboxClusterLayerId = `${this.props.layerId}-mapbox-cluster-layer`;
  multiUnitPopupCloseEventsByUId = {};
  markers: { [uId: string]: Marker } = {};
  markersOnScreen: { [uId: string]: Marker } = {};
  mapMarkerImageUrls: { [imageId: string]: string } = {};
  /* Allows us to return focus to the marker element that opened the popup after the popup closes */
  activeElementPriorToPopupOpen: null | HTMLElement = null;

  componentDidMount() {
    const { map } = this.context;
    const {
      markerImageUrls,
      shouldFitMarkersInMap,
      mapPinsColor,
      mapPinsVisitedColor,
      isGrantProgramFeatureEnabled,
      handleFetchGrantProgramCensusTracts,
    } = this.props;
    const markerSVGs = mapMarkerSVGGenerator(mapPinsColor, mapPinsVisitedColor);

    if (!map) {
      throw new Error(
        'Attempting to bind MapHTMLMarkersLayer map events before the map is available'
      );
    }

    /* Define a mapping of marker imageId to url/dataUri */
    Object.keys(MARKER_IMAGE_IDS).forEach((key) => {
      const imageId = MARKER_IMAGE_IDS[key] as string;
      /* First check for the marker to be defined via the SVG generator (preferred) */
      if (markerSVGs[imageId]) {
        this.mapMarkerImageUrls[imageId] =
          `data:image/svg+xml,${encodeURIComponent(markerSVGs[imageId])}`;
        /* Fallback to using passed-in PNG urls (currently only used for local-activity markers) */
      } else if (markerImageUrls && markerImageUrls[imageId]) {
        this.mapMarkerImageUrls[imageId] = markerImageUrls[imageId];
      }
    });

    if (shouldFitMarkersInMap) {
      this.fitMarkersInMap();
    }
    if (isGrantProgramFeatureEnabled) {
      handleFetchGrantProgramCensusTracts();
    }
    this.handleUpdateMarkers();
    map.on('moveend', this.setOffscreenMarkerTabIndex);
  }

  setOffscreenMarkerTabIndex = () => {
    const markers = this.markersOnScreen;
    const { map } = this.context;
    const mapBounds = map?.getBounds();

    for (const markerKey in markers) {
      if (markers.hasOwnProperty(markerKey) && mapBounds) {
        if (
          getIsPointInsideBounds(
            {
              southWest: mapBounds?.getSouthWest(),
              northEast: mapBounds?.getNorthEast(),
            },
            [
              markers[markerKey].getLngLat().lng,
              markers[markerKey].getLngLat().lat,
            ]
          )
        ) {
          markers[markerKey].getElement().tabIndex = 0;
        } else {
          markers[markerKey].getElement().tabIndex = -1;
        }
      }
    }
  };

  componentDidUpdate(prevProps: Props) {
    const {
      dataChangedTrigger,
      hidePopupOnDataChanged,
      shouldFitMarkersInMap,
      markerFeatures,
      autoOpenPopupForMarkerFeature,
      grantProgramEligibleCensusTractData,
      onMarkerClick,
    } = this.props;

    if (
      hidePopupOnDataChanged &&
      prevProps.dataChangedTrigger !== dataChangedTrigger
    ) {
      this.setState({ popupFeature: null });
    }
    if (prevProps.dataChangedTrigger !== dataChangedTrigger) {
      this.handleUpdateMarkers();
    }
    /* Reposition map to fit new markers, if desired */
    if (shouldFitMarkersInMap && this.markersFeaturesHaveChanged(prevProps)) {
      this.fitMarkersInMap();
    }
    /* Add or remove a check icon from a map marker after a property is added/removed from the watchlist.
     * As a perf optimization, only run when `dataChangedTrigger` doesn't change since in this case the
     * loaded tiles don't change, only a property within 1 marker object within the tiles.  This manual
     * process is necessary since the markers are not managed by React */
    if (
      prevProps.dataChangedTrigger === dataChangedTrigger &&
      markerFeatures.length === prevProps.markerFeatures.length
    ) {
      for (let i = 0; i < markerFeatures.length; i++) {
        const currMarkerProps = markerFeatures[i].properties;
        const prevMarkerProps = prevProps.markerFeatures[i].properties;
        const propertyIsEligibleForGrantProgram =
          isPropertyMarker(currMarkerProps) &&
          !!grantProgramEligibleCensusTractData?.find(
            (d) => d.tract_id === currMarkerProps.tractId
          );

        if (
          (isPropertyMarker(currMarkerProps) &&
            isPropertyMarker(prevMarkerProps)) ||
          (isMultiUnitClusterMarker(currMarkerProps) &&
            isMultiUnitClusterMarker(prevMarkerProps))
        ) {
          /* From unchecked to checked */
          if (
            currMarkerProps.isCheckedStyle &&
            !prevMarkerProps.isCheckedStyle &&
            /* We don't want the checkmark overlapping the grant program label */
            !propertyIsEligibleForGrantProgram
          ) {
            const markerInnerEle = document.querySelector(
              `[data-uid="${currMarkerProps.uId}"] .${markerInnerClassname}`
            ) as HTMLDivElement | null;
            if (markerInnerEle) {
              this.addCheckImageToMarker(markerInnerEle);
            }
            /* From checked to unchecked */
          } else if (
            !currMarkerProps.isCheckedStyle &&
            prevMarkerProps.isCheckedStyle
          ) {
            const markerInnerEle = document.querySelector(
              `[data-uid="${currMarkerProps.uId}"] .${markerInnerClassname}`
            ) as HTMLDivElement | null;
            const markerCheckImageEle = document.querySelector(
              `[data-uid="${currMarkerProps.uId}"] .${markerCheckImageClassname}`
            ) as HTMLImageElement | null;

            if (markerInnerEle && markerCheckImageEle) {
              this.removeCheckImageFromMarker(
                markerInnerEle,
                markerCheckImageEle
              );
            }
          }
        }
      }
    }
    /* Auto-open popup functionality. After the map mounts, `<Maps>` will pass `autoOpenPopupForMarkerFeature`
     * into this component. Then we execute this functionality, which basically simulates a user clicking
     * on the intended map marker. */
    if (
      !prevProps.autoOpenPopupForMarkerFeature &&
      autoOpenPopupForMarkerFeature &&
      onMarkerClick &&
      isPropertyMarker(autoOpenPopupForMarkerFeature.properties)
    ) {
      /* Not sure why TS can't convert the MapboxGeoJSONFeature type to MapMarkerGeoJSONFeature without
       * error.  MapMarkerGeoJSONFeature is a more specific variant of MapboxGeoJSONFeature */
      onMarkerClick(
        new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
          clientX: 0,
          clientY: 0,
        }),
        autoOpenPopupForMarkerFeature as unknown as MapMarkerGeoJSONFeature
      );
      this.setState({
        popupFeature: {
          ...autoOpenPopupForMarkerFeature,
          /* Don't focus this popup on open since we're auto-opening it when the component mounts */
          shouldFocusOnOpen: false,
        },
      });
    }

    /* If the grant program census tract data loads or is toggled "on" or "off" AFTER the map marker data,
     * we need to add/remove the grant program labels to eligible properties' map markers already on the map */
    if (
      !!prevProps.grantProgramEligibleCensusTractData !==
      !!grantProgramEligibleCensusTractData
    ) {
      for (let i = 0; i < markerFeatures.length; i++) {
        const markerProps = markerFeatures[i].properties;

        /* If we're seeking to add the labels */
        if (grantProgramEligibleCensusTractData) {
          if (
            (isPropertyMarker(markerProps) ||
              isMultiUnitClusterMarker(markerProps)) &&
            !markerProps.isCheckedStyle &&
            !!grantProgramEligibleCensusTractData.find(
              (d) => d.tract_id === markerProps.tractId
            )
          ) {
            const markerInnerEle = document.querySelector(
              `[data-uid="${markerProps.uId}"] .${markerInnerClassname}`
            ) as HTMLDivElement | null;
            if (markerInnerEle) {
              this.addGrantProgramLabelToMarker(markerInnerEle);
            }
          }
          /* If we're seeking to remove the labels */
        } else {
          if (
            isPropertyMarker(markerProps) ||
            isMultiUnitClusterMarker(markerProps)
          ) {
            const innerEle = document.querySelector(
              `[data-uid="${markerProps.uId}"] .${markerInnerClassname}`
            ) as HTMLDivElement | null;
            const grantProgramLabelImage = document.querySelector(
              `[data-uid="${markerProps.uId}"] .${markerGrantProgramLabelClassname}`
            ) as HTMLImageElement | null;

            if (innerEle && grantProgramLabelImage) {
              this.removeGrantProgramLabelFromMarker(
                innerEle,
                grantProgramLabelImage
              );
            }
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this.markers = {};
    const { map } = this.context;

    /* Remove all markers currently on-screen to allow the browser to garbage collect the DOM elements */
    for (const markerUId in this.markersOnScreen) {
      this.markersOnScreen[markerUId].remove();
    }
    this.markersOnScreen = {};
    if (map) {
      map.off('moveend', this.setOffscreenMarkerTabIndex);
    }
  }

  /* Returns a unique identifier for each set of marker features, used to check if the
   * set has been altered by a props update */
  getMarkerFeatureSetId(
    markerFeatures: MapMarkerGeoJSONFeature[]
  ): string | null {
    if (markerFeatures.length) {
      return markerFeatures
        .map(
          (feature) =>
            `${feature.geometry.coordinates[0]}${feature.geometry.coordinates[1]}`
        )
        .reduce((acc, curr) => acc + curr);
    } else {
      return null;
    }
  }

  /* Determine whether any marker features have changed by comparing current and previous marker
   * feature hashes */
  markersFeaturesHaveChanged(prevProps: Props) {
    return (
      this.getMarkerFeatureSetId(this.props.markerFeatures) !==
      this.getMarkerFeatureSetId(prevProps.markerFeatures)
    );
  }

  /**
   * Executed after clicking an HTML marker
   */
  handleMarkerClick = (e: MouseEvent | KeyboardEvent) => {
    e.stopPropagation();

    const { map } = this.context;
    const { onMarkerClick, noPopupForAddressSlug, markerFeatures } = this.props;

    if (!map) {
      throw new Error(
        'Marker click handler running when the map is unavailable'
      );
    }

    /* Technically `findParentElementForClassname` could return an SVGElement, but that can't happen in this case */
    const markerEle = findParentElementForClassname(
      e.target as Element,
      theme.Marker,
      true
    ) as HTMLElement;

    if (!markerEle) {
      reportToSentry(
        `Can't find ancestor .${theme.Marker} element`,
        {
          eventTargetParent: ((e.target as Element).parentNode as Element)
            ?.innerHTML,
        },
        'info'
      );
      return;
    }

    const markerEleImage = markerEle.querySelector('img');
    const markerInnerLabelEle = markerEle.querySelector<HTMLSpanElement>(
      `.${theme.MarkerLabelInner}`
    );
    const feature = markerFeatures.find(
      (feature) =>
        feature.properties && feature.properties.uId === markerEle.dataset.uid
    );

    if (!feature) {
      throw new Error(
        `No feature exists for clicked marker with uid ${markerEle.dataset.uid}`
      );
    }

    if (
      isPropertyMarker(feature.properties) &&
      feature.properties.addressSlug &&
      feature.properties.addressSlug === noPopupForAddressSlug
    ) {
      return;
    }

    if (onMarkerClick) {
      /* Discrete functionality is executed in Maps.tsx for clicking a property marker,
       * clicking a cluster marker, and clicking a multi-unit marker */
      /* Not sure why TS can't convert the MapboxGeoJSONFeature type to MapMarkerGeoJSONFeature without
       * error.  MapMarkerGeoJSONFeature is a more specific variant of MapboxGeoJSONFeature */
      onMarkerClick(e, feature as unknown as MapMarkerGeoJSONFeature);
    }

    /* If clicking a property marker, show a popup */
    if (isPropertyMarker(feature.properties)) {
      /* Set to focus popup on open for a11y compliance */
      this.setState({ popupFeature: { ...feature, shouldFocusOnOpen: true } });
    }
    /* Set visited marker styles */
    if (
      isPropertyMarker(feature.properties) &&
      feature.properties.visitedLabelColor
    ) {
      markerEle.style.color = feature?.properties?.visitedLabelColor;
      if (markerInnerLabelEle) {
        markerInnerLabelEle.style.backgroundColor =
          this.getInnerMarkerLabelBackgroundColor({
            ...feature.properties,
            isVisitedStyle: true,
          });
      }
    }
    if (
      isPropertyMarker(feature.properties) &&
      feature.properties.visitedImageId &&
      markerEleImage
    ) {
      markerEleImage.setAttribute(
        'src',
        this.mapMarkerImageUrls[feature.properties.visitedImageId]
      );
    }
  };

  /**
   * Un-render the popup, causing it to close
   */
  handleClosePopup = () => {
    this.setState({
      popupFeature: null,
    });
  };

  /**
   * React to the popup closing. This is triggered when the popup close is initiated by Mapbox via
   * clicking the built-in "X" button
   */
  onPopupClose = () => {
    this.setState({
      popupFeature: null,
    });
    if (this.activeElementPriorToPopupOpen) {
      this.activeElementPriorToPopupOpen.focus();
    }
    if (this.props.onPopupClose) {
      this.props.onPopupClose();
    }
  };

  /**
   * Position the map to fit all marker features and marker objects if desired
   */
  fitMarkersInMap = () => {
    const { api } = this.context;
    const { markerFeatures } = this.props;
    const map = api ? api.getMap() : null;
    const markerFeaturePositions = markerFeatures.map((feature) =>
      lngLatToLatLng(feature.geometry.coordinates)
    );

    if (!map || !api) {
      return;
    }

    const markerPositions = api.getMarkerPositions();
    const bounds = computeBoundsArrayFromLatLngPoints([
      ...markerFeaturePositions,
      ...markerPositions,
    ]);
    if (map && !map.isMoving()) {
      api.setMapPositionToBounds(bounds);
    } else if (map) {
      map.once('moveend', () => {
        api.setMapPositionToBounds(bounds);
      });
    }
  };

  /** Get the background-color for marker label based on type
   * Label background-color is needed to pass automated accessibility contrast
   * tests that cannot detect the color of the marker image.
   */
  getInnerMarkerLabelBackgroundColor = (markerProps: MapMarker): string => {
    const { mapPinsColor, mapPinsVisitedColor } = this.props;
    let innerLabelBackgroundColor = 'inherit';
    // API Cluster Marker
    if (isAPIClusterMarker(markerProps)) {
      innerLabelBackgroundColor = '#4A4A4A';
    }
    // Visited Map Pin
    else if (
      isPropertyMarker(markerProps) &&
      markerProps.isVisitedStyle &&
      getIsActiveListing(markerProps.normalizedPropertyData.status)
    ) {
      innerLabelBackgroundColor = mapPinsVisitedColor;
    }
    // On Market map pin
    else if (
      isOnMarketPropertyMarker(markerProps) ||
      isOnMarketMultiUnitClusterMarker(markerProps)
    ) {
      innerLabelBackgroundColor = mapPinsColor;
    }
    // Off Market map pin
    else if (
      isOffMarketPropertyMarker(markerProps) ||
      isOffMarketMultiUnitClusterMarker(markerProps)
    ) {
      innerLabelBackgroundColor = '#FFFFFF';
    }

    return innerLabelBackgroundColor;
  };

  /**
   * Create an HTML marker for every feature property if it doesn't already exist
   * and add it to the map
   */
  handleUpdateMarkers = () => {
    const { map } = this.context;
    const {
      markerFeatures: features,
      shouldPutDataHCNameOnMarkers,
      grantProgramEligibleCensusTractData,
    } = this.props;
    const newMarkers = {};

    /* Sometimes when transitioning between screen sizes the map re-initializes */
    if (!map || !map.getStyle()) {
      return;
    }

    for (let i = 0; i < features.length; i++) {
      const coords = features[i].geometry.coordinates;
      /* `null` markers should be filtered out before being set on Redux state */
      const markerProps = features[i].properties as NonNullable<MapMarker>;
      const uId = markerProps?.uId;
      let marker = uId && this.markers[uId];

      /* If we don't have an HTML marker cached, build it */
      if (!marker) {
        /* Main marker element */
        const markerEle = document.createElement('button');
        const markerInnerEle = document.createElement('div');
        markerInnerEle.classList.add(markerInnerClassname);

        /* Add accessibility support */
        markerEle.setAttribute('type', 'button');

        /**
         * IE 11 does not support adding more than one class name
         * using classList method, causing styling issues,
         * hence using className here.
         */
        markerEle.className = `${theme.Marker}`.concat(
          isAPIClusterMarker(markerProps)
            ? ` ${theme.MarkerCluster}`
            : isMultiUnitClusterMarker(markerProps)
              ? ` ${theme.MultiUnitCluster}`
              : ''
        );
        markerEle.setAttribute('data-uid', markerProps.uId);

        /* For accessibility we need to put a sensible label on the map markers */
        let labelAttribute;
        if (
          (markerProps as PropertyMapMarker | SubjectMapMarker)
            ?.normalizedPropertyData?.fullStreetAddress
        ) {
          labelAttribute = `${
            (markerProps as PropertyMapMarker).normalizedPropertyData
              .fullStreetAddress
          }, price ${(markerProps as PropertyMapMarker).label}`;
        } else if (
          (markerProps as BusinessMarker)?.normalizedPropertyData?.name
        ) {
          labelAttribute = (markerProps as BusinessMarker)
            .normalizedPropertyData.name;
        } else if (
          (markerProps as MultiUnitMapMarker | APIClusterMapMarker)?.label
        ) {
          labelAttribute = (
            markerProps as MultiUnitMapMarker | APIClusterMapMarker
          ).label;
        }

        if (labelAttribute) {
          markerEle.setAttribute('aria-label', labelAttribute);
        }

        if (shouldPutDataHCNameOnMarkers) {
          markerEle.setAttribute(
            'data-hc-name',
            `${markerProps.imageId}-marker`
          );
        }

        markerEle.style.width = isAPIClusterMarker(markerProps)
          ? `${100 * markerProps.imageScale}px`
          : isBusinessMarker(markerProps)
            ? BUSINESS_MARKER_DIMENSIONS[0]
            : PROPERTY_MARKER_DIMENSIONS[0];
        markerEle.style.height = isAPIClusterMarker(markerProps)
          ? `${100 * markerProps.imageScale}px`
          : isBusinessMarker(markerProps)
            ? BUSINESS_MARKER_DIMENSIONS[1]
            : PROPERTY_MARKER_DIMENSIONS[1];
        markerEle.style.color =
          isPropertyMarker(markerProps) && markerProps.isVisitedStyle
            ? markerProps.visitedLabelColor || 'inherit'
            : markerProps.labelColor || 'inherit';
        markerEle.style.fontSize = `${
          isAPIClusterMarker(markerProps)
            ? 14
            : isMultiUnitClusterMarker(markerProps)
              ? 10
              : 11
        }px`;
        /* Facilitate lower markers on the screen overlapping higher markers. Z-indexes will range from
         * 2,000,000 to 6,000,000 based on latitude */
        markerEle.style.zIndex = `${10000000 - coords[1] * 100000}`;
        markerEle.addEventListener('keydown', (e: KeyboardEvent) => {
          if (key.isReturn(e.key) || key.isSpace(e.key)) {
            this.handleMarkerClick(e);
          }
        });
        markerEle.addEventListener('click', this.handleMarkerClick);

        /* Marker image */
        const markerImageEle = document.createElement('img');
        markerImageEle.classList.add(theme.MarkerImage);
        const markerImageId =
          features[i].properties &&
          features[i].properties[
            (markerProps as PropertyMapMarker)?.isVisitedStyle
              ? 'visitedImageId'
              : 'imageId'
          ];
        markerImageEle.setAttribute(
          'src',
          this.mapMarkerImageUrls[markerImageId]
        );
        markerImageEle.setAttribute('alt', 'map marker');
        markerInnerEle.appendChild(markerImageEle);

        /* Given grant program census tract data, check for this property's eligibility and add
         * a label to the marker */
        if (
          (isPropertyMarker(markerProps) ||
            isMultiUnitClusterMarker(markerProps)) &&
          !!grantProgramEligibleCensusTractData?.find(
            (d) => d.tract_id === markerProps.tractId
          )
        ) {
          this.addGrantProgramLabelToMarker(markerInnerEle);
          /* Conditional marker check image */
        } else if (
          (isPropertyMarker(markerProps) ||
            isMultiUnitClusterMarker(markerProps)) &&
          markerProps.isCheckedStyle
        ) {
          this.addCheckImageToMarker(markerInnerEle);
        }

        /* Marker label (optional).  A label value of `null` gets converted to a string by Mapbox, so
         * check for that too */
        if (
          features[i].properties?.label &&
          features[i].properties?.label !== 'null'
        ) {
          const markerOuterLabelEle = document.createElement('span');
          const markerInnerLabelEle = document.createElement('span');

          if (features[i].properties?.label) {
            markerInnerLabelEle.innerText = features[i].properties!.label!;
          }
          markerInnerLabelEle.classList.add(theme.MarkerLabelInner);

          // Setting the background color of the label based on the
          // type of pin for automated accessibility testing.
          markerInnerLabelEle.style.backgroundColor =
            this.getInnerMarkerLabelBackgroundColor(markerProps);

          markerOuterLabelEle.classList.add(theme.MarkerLabel);
          markerOuterLabelEle.appendChild(markerInnerLabelEle);
          markerInnerEle.appendChild(markerOuterLabelEle);
        }

        markerEle.appendChild(markerInnerEle);
        marker = this.markers[uId] = new mapboxgl.Marker({
          element: markerEle,
          anchor: isAPIClusterMarker(markerProps) ? 'center' : 'bottom',
          offset: isAPIClusterMarker(markerProps)
            ? [0, 0]
            : isBusinessMarker(markerProps)
              ? BUSINESS_MARKER_OFFSET
              : PROPERTY_MARKER_OFFSET,
        }).setLngLat(coords);
      }
      newMarkers[uId] = marker;

      if (!this.markersOnScreen[uId]) {
        marker.addTo(map);
      }
    }
    /* For every marker we've added previously, remove those that are no longer visible */
    for (const markerUId in this.markersOnScreen) {
      if (!newMarkers[markerUId]) {
        /* Remove marker from map */
        this.markersOnScreen[markerUId].remove();
      }
    }
    this.markersOnScreen = newMarkers;
    this.setOffscreenMarkerTabIndex();
  };

  handleMouseEnter = (e: MouseEvent) => {
    const { map, api } = this.context;
    const { onMarkerMouseEnter, markerFeatures } = this.props;

    if (onMarkerMouseEnter && map) {
      /* Technically `findParentElementForClassname` could return an SVGElement, but that can't happen in this case */
      const markerEle = findParentElementForClassname(
        e.target as Element,
        theme.Marker
      ) as HTMLElement;

      if (markerEle) {
        const feature = markerFeatures.find(
          (feature) => feature.properties.uId === markerEle.dataset.uid
        );
        onMarkerMouseEnter(api, feature);
      }
    }
  };

  handleMouseLeave = () => {
    const { api } = this.context;
    const { onMarkerMouseLeave } = this.props;

    if (onMarkerMouseLeave) {
      onMarkerMouseLeave(api);
    }
  };

  addCheckImageToMarker = (markerInnerEle: HTMLDivElement) => {
    const markerCheckImageEle = document.createElement('img');
    markerCheckImageEle.classList.add(markerCheckImageClassname);
    markerCheckImageEle.setAttribute('src', markerCheckImageUrl);
    markerCheckImageEle.setAttribute('alt', 'map marker checked');
    markerInnerEle.appendChild(markerCheckImageEle);
  };

  removeCheckImageFromMarker = (
    markerInnerEle: HTMLDivElement,
    checkImageEle: HTMLImageElement
  ) => {
    markerInnerEle.removeChild(checkImageEle);
  };

  addGrantProgramLabelToMarker = (markerInnerEle: HTMLDivElement) => {
    const markerGrantProgramLabel = document.createElement('img');
    markerGrantProgramLabel.classList.add(markerGrantProgramLabelClassname);
    markerGrantProgramLabel.setAttribute('src', markerGrantProgramLabelImage);
    markerGrantProgramLabel.setAttribute(
      'alt',
      'property is eligible for a grant program'
    );
    markerGrantProgramLabel.setAttribute('data-hc-name', 'grant-marker');
    markerInnerEle.appendChild(markerGrantProgramLabel);
  };

  removeGrantProgramLabelFromMarker = (
    markerInnerEle: HTMLDivElement,
    grantProgramLabelImageEle: HTMLImageElement
  ) => {
    markerInnerEle.removeChild(grantProgramLabelImageEle);
  };

  /**
   * Focus an element within the popup, according to a defined precedence of focusable element types
   */
  focusPopupElement = (
    _,
    mapboxEvent: MapboxEvent & { target: MapboxPopup }
  ) => {
    const popup = mapboxEvent.target;
    /* Define an order of precedence */
    const focusQuerySelector = [
      'a[href]',
      "[tabindex]:not([tabindex='-1'])",
      'button:not([disabled])',
    ].join(', ');
    const firstFocusableElement = popup
      .getElement()
      .querySelector(focusQuerySelector) as HTMLElement;

    if (!firstFocusableElement) {
      reportToSentry('No focusable element within map popup found', {
        querySelector: focusQuerySelector,
        mapPopupElementHtml: popup.getElement().outerHTML,
      });
    } else {
      this.activeElementPriorToPopupOpen =
        document.activeElement as HTMLElement;
      firstFocusableElement.removeAttribute('aria-hidden');
      /* setTimeout is unfortunately necessary to prevent popup from immediately closing when attempting
       * to set focus. Not exactly sure why this occurs - it's likely due to some race condition between our
       * rendering of a React component into the popup via a React portal and the popup 'open' event firing */
      window.setTimeout(() => {
        firstFocusableElement.focus();
      }, 0);
    }
  };

  render() {
    const { PopupContentNode, fitPopupPadding } = this.props;
    const { popupFeature } = this.state;
    const popupPosition =
      popupFeature && lngLatToLatLng(popupFeature.geometry.coordinates.slice());

    return (
      <React.Fragment>
        {popupFeature && popupPosition && PopupContentNode && (
          <Popup
            ContentNode={PopupContentNode}
            position={popupPosition}
            mapboxOptions={{
              offset: {
                bottom: [0, MARKER_POPUP_Y_OFFSET],
              },
              /* Close popup on map click */
              closeOnClick: true,
              /* Use mapbox built-in popup close button */
              closeButton: true,
              /* Unfortunately due to something with the timing of our popup React portal rendering, enabling
               * this out-of-the-box functionality causes issues (popup immediately closes after opening
               * on Enter key press). Instead we need to focus the close button within the popup manually `onOpen` */
              focusAfterOpen: false,
            }}
            onOpen={
              popupFeature.shouldFocusOnOpen
                ? this.focusPopupElement
                : undefined
            }
            onClose={this.onPopupClose}
            handleClosePopup={this.handleClosePopup}
            fitPopupInMapViewport={true}
            fitPopupPadding={
              fitPopupPadding || FIT_POPUP_PADDING_WITH_DESKTOP_CONTROLS
            }
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  isGrantProgramFeatureEnabled: getIsFeatureEnabled('grant_program')(state),
  grantProgramEligibleCensusTractData: selectCensusTractData(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleFetchGrantProgramCensusTracts: (): void => {
    dispatch(fetchCensusTractData());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MapMarkersLayer);
