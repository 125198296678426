import {
  getCurrentQuery,
  getCurrentView,
} from '@src/redux-saga-router-plus/selectors';
import { subDays } from 'date-fns';
import { flatten, isEmpty, isEqual, omitBy } from 'lodash';
import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
} from 'reselect';

import { View } from '@client/routes/constants';
import {
  SEARCH_ACTIVE_VIEW_URL_PARAM_KEY,
  SEARCH_ACTIVE_VIEW_URL_PARAM_OPTIONS,
  SEARCH_MAP_MOBILE_FILTERS_SHOWN_URL_KEY,
  SEARCH_MAP_SELECTED_ADDRESS_SLUG_URL_PARAM_KEY,
  STATUSES,
} from '@client/store/constants';
import {
  ALWAYS_ACTIVE_FILTERS,
  FILTER_CONSUMER_API_KEYS,
  FILTER_GQL_TYPES,
  FILTER_KEYS,
  FilterKey,
  FILTERS_ORDER,
  HIDDEN_MOBILE_FILTERS,
  INITIAL_FILTER_VALUES,
  MIN_MAX_FILTER_DESIGNATOR,
  SCHOOL_FILTER_KEYS,
} from '@client/store/filter-constants';
import { MARKER_IMAGE_IDS } from '@client/store/map-constants';
import {
  getIsSearchListViewDefault,
  getSearchAdCardContent,
  getSearchFiltersOrder,
  getShouldShowConnectWithATopLocalAgent,
} from '@client/store/selectors/cobranding.selectors';
import {
  getIsDisplayMultiFamilySearchFiltersEnabled,
  getIsFeatureEnabled,
} from '@client/store/selectors/enabled-features.selectors';
import { HIDDEN_BOTTOM_NAV_FOR_VIEWS } from '@client/store/selectors/global-ui.selectors';
import { getIsShowingLenderCTACards } from '@client/store/selectors/loan-officer.selectors';
import {
  getIsSmallSize,
  getIsTabletOrSmallerSize,
} from '@client/store/selectors/match-media.selectors';
import { getActivePDPSlug } from '@client/store/selectors/router.selectors';
import {
  MapMarker,
  MapMarkerGeoJSONFeature,
  MapPropertyMarkerGeoJSONFeature,
} from '@client/store/types/maps';
import { PlaceIdPlace } from '@client/store/types/place-search';
import { ReduxState } from '@client/store/types/redux-state';
import { SavedSearchAPIFields } from '@client/store/types/saved-searches';
import { getGQLDateStringForDateObj } from '@client/utils/date.utils';
import {
  filterStateToUrlParams,
  FLAT_FILTER_CONTROLS,
  getAllPropertyTypeValues,
  getFormattedGQLValueForType,
} from '@client/utils/filters.utils';
import { buildSubjectMarker } from '@client/utils/maps.utils';
import { getIsActiveListing } from '@client/utils/property.utils';
import { getIsInView } from '@client/utils/routing.utils';
import { abbrNumberFormatter, capitalize } from '@client/utils/string.utils';
import { getSearchPageLocationUrlQueryParam } from '@client/utils/url-formatting.utils';
import { selectIsSRPGrantFilterOn } from '../slices/grant-program.slice';

/* Create a "selector creator" that uses lodash.isEqual instead of === to determine when to
 * return the memoized value instead of recalculating */
const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual
) as typeof createSelector;

/* Whether the map feature is a property marker feature (as opposed to a cluster, etc.) */
const getIsPropertyMapMarkerFeature = (
  markerFeature: MapMarkerGeoJSONFeature
): markerFeature is MapPropertyMarkerGeoJSONFeature => {
  return (
    !!markerFeature &&
    !!(markerFeature as MapPropertyMarkerGeoJSONFeature).properties
      ?.isProperty &&
    !!(markerFeature as MapPropertyMarkerGeoJSONFeature).properties
      ?.normalizedPropertyData
  );
};

export function getSearchState(state: ReduxState) {
  return state.search;
}

export const getCobrandingState = (state) => state.cobranding;

export const getCobrandId = createSelector(
  getCobrandingState,
  (cobrandingState) => cobrandingState.id
);

export const getPlaceSearchAddressResults = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.placeSearch.addresses;
  }
);

export const getPlaceSearchPlaceResults = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.placeSearch.places;
  }
);

export const getSearchText = createSelector(
  getSearchState,
  (searchState) => searchState.searchText
);

export const getPlaceSearchSessionToken = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.placeSearch.sessionToken;
  }
);

export const getPlaceSearchStatus = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.placeSearch.status;
  }
);

export const getSearchSetMapLocation = createSelector(
  getSearchState,
  (searchState) => searchState.setMapLocation
);

export const getSearchLocationBeforeApplyingSavedSearch = createSelector(
  getSearchState,
  (searchState) => searchState.locationBeforeApplyingSavedSearch
);

export const getSearchUserLocation = createSelector(
  getSearchState,
  (searchState) => {
    const { latitude, longitude } = searchState.userLocation;
    return { latitude, longitude };
  }
);

export const getSearchUserLocationStatus = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.userLocation.status;
  }
);

export const getSearchTileCache = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.tileCache;
  }
);

export const getSearchMarkersByTile = createSelector(
  getSearchState,
  (searchState) => searchState.markersByTile
);

/* Filter out all tiles keys from the object that don't contain markers as a performance
 * optimization since this object is being checked for deep equality in `getSearchMarkersGeoJSONFeatures` */
export const getFilteredSearchMarkersByTile = createSelector(
  getSearchMarkersByTile,
  (markersByTile) =>
    Object.keys(markersByTile).reduce((mem, currKey) => {
      if (markersByTile[currKey].length > 0) {
        mem[currKey] = markersByTile[currKey];
      }
      return mem;
    }, {})
);

export const getSearchMarkersGeoJSONFeatures = createDeepEqualSelector(
  getFilteredSearchMarkersByTile,
  (markersByTile) => {
    return flatten<NonNullable<MapMarker>>(
      Object.keys(markersByTile).map((tileKey) => markersByTile[tileKey])
    ).map((marker) => ({
      type: 'Feature' as 'Feature',
      properties: marker,
      geometry: {
        type: 'Point' as 'Point',
        coordinates: [marker.lng, marker.lat] as [number, number],
      },
    }));
  }
);

export const getSearchMarkerLoadedTilesChangedTrigger = createSelector(
  getFilteredSearchMarkersByTile,
  (markersByTile) => Object.keys(markersByTile).join(',')
);

export const getIsShowingMapMarkers = createSelector(
  getSearchState,
  (searchState) => searchState.isShowingMapMarkers
);

/* Whether the map contains only API-generated clusters and no markers */
export const getSearchMarkersAreAllClusters = (state) => false;
/* getSearchMarkersByTile
     getSearchAllTilesInViewportAreLoaded
     (markers, clusterMarkers) => (
        TODO reimplement if need be.  We'll need to track the loading state of each tile
        * in the viewport and only return `true` here when all the tiles are loaded
       false
     )
  */

export const getSearchFilters = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.filters;
  }
);

export const getIsUsingMobileFiltersUI = createSelector(
  getIsSmallSize,
  (isSmallSize) => isSmallSize
);

export const getSearchAreDefaultFiltersChanged = createSelector(
  getSearchFilters,
  (filters) => !isEqual(filters, INITIAL_FILTER_VALUES)
);

/* Get filters MLS State (ACTIVE, PENDING, WITHDRAWN, etc) */
export const getSearchMLSState = createSelector(
  getSearchFilters,
  (filters) => filters[FILTER_KEYS.MLS_STATE]
);

/* Define label objects for each active filter, using defined formatters if present
 * and reverting to defaults if not.
 * Currently only used for DESKTOP FILTER display */
export const getSearchActiveFiltersLabels = createSelector(
  getSearchFilters,
  getIsDisplayMultiFamilySearchFiltersEnabled,
  (filters, isDisplayMultiFamilySearchFiltersEnabled) => {
    /* Only include 'active' filters */
    const controls = FLAT_FILTER_CONTROLS.filter((filterControl) => {
      const key = filterControl.key;
      const isMinMaxFilter = key.indexOf(MIN_MAX_FILTER_DESIGNATOR) > -1;
      const value = filters[key];
      const allPropertyTypeValues = getAllPropertyTypeValues(
        isDisplayMultiFamilySearchFiltersEnabled
      );

      return (filterControl as any).getIsActive
        ? key === FILTER_KEYS.PROPERTY_TYPE
          ? (filterControl as any)?.getIsActive(value, allPropertyTypeValues)
          : (filterControl as any)?.getIsActive(value)
        : /* Default active determination: if it has a truthy value */
          isMinMaxFilter
          ? /* Min/max filter: active if either min or max has value */
            value[0] || value[1]
          : /* All other filters are array/list type: active when array not empty */
            !isEmpty(value);
      /* Create label object for each active filter */
    }).map((filterControl) => {
      const key = filterControl.key;
      const isMinMaxFilter = key.indexOf(MIN_MAX_FILTER_DESIGNATOR) > -1;
      const value = filters[key];
      const label = filterControl.summaryFormatter
        ? filterControl.summaryFormatter(value)
        : /* Default formatters */
          isMinMaxFilter
          ? /* If Min/Max */
            value[0] && value[1]
            ? /* If both are defined */
              `${value[0]}-${value[1]} ${(filterControl as any).label}`
            : `${value[0] ? 'Min' : 'Max'} ${value[0] || value[1]} ${
                (filterControl as any).label
              }`
          : `${value.join(',')} ${(filterControl as any).label}`;
      return { key, label };
    });
    return flatten(controls);
  }
);

export const getSearchListAgeDaysFilterAsDate = createSelector(
  getSearchFilters,
  (filters) => {
    const today = new Date();
    const filterValues = filters[FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX];

    return [
      filterValues[0]
        ? getGQLDateStringForDateObj(subDays(today, filterValues[0]))
        : null,
      filterValues[1]
        ? getGQLDateStringForDateObj(subDays(today, filterValues[1]))
        : null,
    ];
  }
);

/** Format the filter keys and values in preparation for the GraphQL request
 * from { propertyType: 'SFD', avmPriceMinMax: [100, 200] }
 * to   [ { propertyType: 'SFD' }, { minAvmPrice: 100 }, { maxAvmPrice: 200 } ]
 */
export const getGQLFormattedSearchFilters = createSelector(
  getSearchFilters,
  getSearchListAgeDaysFilterAsDate,
  (filters, listAgeDaysAsDate) => {
    type OutputFilter = { key: string; value: any; gqlType: string };
    let outputFilters: OutputFilter[] = [];
    const getOutputObj = ({
      gqlType,
      outputKey,
      value,
    }: {
      gqlType: string;
      outputKey: string;
      value: any;
    }) => ({
      key: outputKey,
      value: getFormattedGQLValueForType(value, gqlType),
      gqlType,
    });

    for (let key in filters) {
      /* We don't want this sent to GQL since we're transforming it into mix/max list date */
      if (key === FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX) {
        continue;
      }
      const filterControl = FLAT_FILTER_CONTROLS.find(
        (control) => control.key === key
      );
      const isMinMaxFilter = key.indexOf(MIN_MAX_FILTER_DESIGNATOR) > -1;
      const keyBase = capitalize(
        key.slice(0, -MIN_MAX_FILTER_DESIGNATOR.length)
      );
      const outputKeys = isMinMaxFilter
        ? [`min${keyBase}`, `max${keyBase}`]
        : [key];

      let currentOutputFilters = outputKeys.map((outputKey, i) => {
        let value = isMinMaxFilter ? filters[key][i] : filters[key];
        if (
          filterControl &&
          (filterControl as any).shouldSendNullToAPI &&
          (filterControl as any).shouldSendNullToAPI(value)
        ) {
          value = null;
        }
        return getOutputObj({
          gqlType: FILTER_GQL_TYPES[key],
          outputKey,
          value,
        });
      });

      outputFilters = [...currentOutputFilters, ...outputFilters];
    }

    return [
      ...outputFilters,
      getOutputObj({
        gqlType: FILTER_GQL_TYPES[FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX],
        outputKey: 'minListDate',
        value: listAgeDaysAsDate[0],
      }),
      getOutputObj({
        gqlType: FILTER_GQL_TYPES[FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX],
        outputKey: 'maxListDate',
        value: listAgeDaysAsDate[1],
      }),
    ];
  }
);

/**
 * Format the filter keys in preparation for the save-search request
 * from { propertyType: 'SFD', avmPriceMinMax: [100, 200] }
 * to   [ { property_type: 'SFD' }, { min_avm_price: 100 }, { max_avm_price: 200 } ]
 */
export const getConsumerAPIFormattedSearchFilters = createSelector(
  getSearchFilters,
  (filters) => {
    let outputFilters: Partial<SavedSearchAPIFields> = {};

    for (let key in filters) {
      const isMinMaxFilter = key.indexOf(MIN_MAX_FILTER_DESIGNATOR) > -1;
      const consumerAPIKeyBase = FILTER_CONSUMER_API_KEYS[key];
      const outputKeys = isMinMaxFilter
        ? [`min_${consumerAPIKeyBase}`, `max_${consumerAPIKeyBase}`]
        : [consumerAPIKeyBase];

      outputKeys.forEach((outputKey, i) => {
        let value = isMinMaxFilter ? filters[key][i] : filters[key];

        /* Special case: "geo precision" from uppercase to lowercase */
        if (key === FILTER_KEYS.GEO_PRECISION_MIN_MAX && value) {
          value = value.toLowerCase();
        }
        outputFilters[outputKey] = getFormattedGQLValueForType(
          value,
          FILTER_GQL_TYPES[key]
        );
      });
    }
    return outputFilters;
  }
);

export const getSearchTemporaryMarkerData = createSelector(
  getSearchState,
  (searchState) => searchState.temporaryMarkerData
);

export const getSearchTemporaryMarker = createDeepEqualSelector(
  getSearchTemporaryMarkerData,
  (temporaryMarkerData) =>
    temporaryMarkerData &&
    buildSubjectMarker({
      addressSlug: temporaryMarkerData.slug,
      latitude: temporaryMarkerData.latitude,
      longitude: temporaryMarkerData.longitude,
      label: abbrNumberFormatter(temporaryMarkerData.value),
      labelColor: null,
      buildingId: null,
      imageId: getIsActiveListing(temporaryMarkerData.status)
        ? MARKER_IMAGE_IDS.ON_MARKET
        : MARKER_IMAGE_IDS.OFF_MARKET,
      isPulsing: true,
    })
);

export const getSearchListProperties = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.propertyList.properties;
  }
);

export const getSearchListCursor = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.propertyList.cursor;
  }
);

export const getSearchListLastUpdatedTimestamp = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.propertyList.lastUpdatedTimestamp;
  }
);

export const getSearchMoreListPropertiesAvailable = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.propertyList.atEnd !== true;
  }
);

export const getSearchIsLoadingMoreListProperties = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.propertyList.isLoadingMore === true;
  }
);

export const getSearchPropertyTotalCount = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.propertyCount;
  }
);

export const getSearchIsShowingMobileFilters = createSelector(
  getCurrentQuery,
  (query) => !!(query as any)[SEARCH_MAP_MOBILE_FILTERS_SHOWN_URL_KEY]
);

export const getSearchConstrainedPlace = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.constrainedToPlace;
  }
);

export const getSearchPlaceGeoJSONDescription = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.placeGeoJSONDescription;
  }
);

export const getSearchFilterValues = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.filters;
  }
);

export const getSearchPropertyListIsInitStatus = createSelector(
  getSearchState,
  (searchState) => searchState.propertyList.status === STATUSES.INIT
);

export const getSearchShouldShowLoadingIndicatorWhenLoading = createSelector(
  getSearchState,
  (searchState) => searchState.propertyList.showLoadingIndicatorWhenLoading
);

export const getSearchPropertyListIsSuccessStatus = createSelector(
  getSearchState,
  (searchState) => searchState.propertyList.status === STATUSES.SUCCESS
);

export const getSearchPropertyListIsErrorStatus = createSelector(
  getSearchState,
  (searchState) => searchState.propertyList.status === STATUSES.ERROR
);

export const getSearchViewport = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.currentMapViewport;
  }
);

export const getSearchZoom = createSelector(getSearchState, (searchState) => {
  return searchState.currentMapZoom;
});

export const getSearchIsMobileMapPropertyCardActive = createSelector(
  getCurrentQuery,
  (query) => !!(query as any)[SEARCH_MAP_SELECTED_ADDRESS_SLUG_URL_PARAM_KEY]
);

/* Get the query params object used for updating the URL on map location change and
 * filter change */
export const getSearchLocationForQueryParam = createSelector(
  getSearchViewport,
  (mapViewport) => {
    const { southWest, northEast } = mapViewport || {};

    if (southWest && northEast) {
      return getSearchPageLocationUrlQueryParam({ southWest, northEast });
    } else {
      return null;
    }
  }
);

/**
 * Given the filter object from state, return an object formatted for a URL query string
 */
export const getSearchFiltersForQueryParams = createSelector(
  getSearchFilterValues,
  getIsDisplayMultiFamilySearchFiltersEnabled,
  (filters, isDisplayMultiFamilySearchFiltersEnabled) =>
    filterStateToUrlParams(filters, isDisplayMultiFamilySearchFiltersEnabled)
);

export const getSearchPulseLocations = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.mapPulseLocations;
  }
);

export const getSearchPlaceGeoJSON = createSelector(
  getSearchState,
  (searchState) => searchState.placeGeoJSON
);

export const getSearchMultiUnitMarkersByLatLng = createSelector(
  getSearchState,
  (searchState) => searchState.multiUnitMarkersByLatLng
);

export const getSearchActiveMultiUnitProperties = createSelector(
  getSearchState,
  (searchState) => searchState.activeMultiUnitProperties
);

/* Returns the number of zoom levels higher than the actual map zoom level at which
 * to request tiles.  The higher the zoom level, the more granular the tile requests,
 * which translates to more clusters at low zoom levels */
export const getSearchAdditionalTileZoom = createSelector(
  getIsSmallSize,
  (isSmallScreenSize) => (isSmallScreenSize ? 2 : 1)
);

/* The limit after which a cluster is returned for the tile.  Since we're showing 4x
 * the density of tiles on small screens, the per-tile limit should be lower on small screens */
export const getSearchTilePropertyLimit = createSelector(
  getIsSmallSize,
  (isSmallScreenSize) => (isSmallScreenSize ? 40 : 60)
);

export const getIsShowingSearchPageList = createSelector(
  getCurrentQuery,
  getIsSmallSize,
  getIsSearchListViewDefault,
  (query, isSmallScreenSize, isSearchListViewDefault) =>
    (query as any)[SEARCH_ACTIVE_VIEW_URL_PARAM_KEY] !== undefined
      ? (query as any)[SEARCH_ACTIVE_VIEW_URL_PARAM_KEY] ===
        SEARCH_ACTIVE_VIEW_URL_PARAM_OPTIONS.LIST
      : isSearchListViewDefault && isSmallScreenSize
);

export const getSearchMarkerFeaturesMinZoom = createSelector(
  getIsSmallSize,
  (isSmallScreenSize) => (isSmallScreenSize ? 8 : 9)
);

export const getMapPropertySort = createSelector(
  getSearchState,
  (searchState) => searchState.propertySort
);

export const getSearchListSortOrder = createSelector(
  getMapPropertySort,
  (propertySort) => propertySort.sortOrder
);

export const getSearchListSortField = createSelector(
  getMapPropertySort,
  (propertySort) => propertySort.sortField
);

// /* Currently only used for MOBILE FILTER display */
export const getInactiveFilterKeys = createSelector(
  getSearchFilterValues,
  (state) => getSearchFiltersOrder(state),
  (state) => getIsFeatureEnabled('school_data')(state),
  (state) => getIsFeatureEnabled('grant_program')(state),
  (state) => selectIsSRPGrantFilterOn(state),
  (state) =>
    getIsFeatureEnabled('temp_display_multi_family_search_filters')(state),
  (
    filterValues,
    filterSortOrder,
    isSchoolDataFeatureEnabled,
    isGrantProgramFeatureEnabled,
    isSRPGrantFilterOn,
    isDisplayMultiFamilySearchFiltersEnabled
  ) => {
    let inactiveFiltersKeysValues = omitBy(
      filterValues,
      (filterValue, filterKey) => {
        if (filterKey === FILTER_KEYS.PROPERTY_TYPE) {
          const allPropertyTypeValues = getAllPropertyTypeValues(
            isDisplayMultiFamilySearchFiltersEnabled
          );

          return (
            !isEqual(allPropertyTypeValues, filterValue) ||
            (HIDDEN_MOBILE_FILTERS as string[]).indexOf(filterKey) > -1
          );
        }

        return (
          !isEqual(INITIAL_FILTER_VALUES[filterKey], filterValue) ||
          (HIDDEN_MOBILE_FILTERS as string[]).indexOf(filterKey) > -1
        );
      }
    );

    /**
     * Search by mls filter section is hardcoded in the MobileFilterSelect by default when there is no filterSortOrder.
     * filterSortOrder is set on Parcon to sort the search filters including Search by MLS filter.
     * So if there is filterSortOrder, we need to add the Search by MLS filter to inactiveFiltersKeysValues
     * to display it in the mobile filter.
     */
    if (filterSortOrder) {
      inactiveFiltersKeysValues = {
        ...inactiveFiltersKeysValues,
        [FILTER_KEYS.SEARCH_BY_MLS]: null,
      };

      if (!isSRPGrantFilterOn && isGrantProgramFeatureEnabled) {
        inactiveFiltersKeysValues = {
          ...inactiveFiltersKeysValues,
          [FILTER_KEYS.GRANT_ELIGIBLE]: null,
        };
      }
    }

    return Object.keys(inactiveFiltersKeysValues)
      .filter((key) =>
        key === SCHOOL_FILTER_KEYS.ELEMENTARY ||
        key === SCHOOL_FILTER_KEYS.MIDDLE ||
        key === SCHOOL_FILTER_KEYS.HIGH
          ? isSchoolDataFeatureEnabled
          : true
      )
      .sort((aKey, bKey) =>
        filterSortOrder
          ? filterSortOrder.indexOf(aKey as FilterKey) -
            filterSortOrder.indexOf(bKey as FilterKey)
          : FILTERS_ORDER.indexOf(aKey as FilterKey) -
            FILTERS_ORDER.indexOf(bKey as FilterKey)
      ) as FilterKey[];
  }
);

/* Currently only used for MOBILE FILTER display */
export const getActiveFilters = createSelector(
  getSearchFilterValues,
  (state) =>
    getIsFeatureEnabled('temp_display_multi_family_search_filters')(state),
  (filterValues, isDisplayMultiFamilySearchFiltersEnabled) => {
    const activeFilters = omitBy(filterValues, (filterValue, filterKey) => {
      if (filterKey === FILTER_KEYS.PROPERTY_TYPE) {
        const allPropertyTypeValues = getAllPropertyTypeValues(
          isDisplayMultiFamilySearchFiltersEnabled
        );

        return (
          isEqual(allPropertyTypeValues, filterValue) ||
          HIDDEN_MOBILE_FILTERS.indexOf(filterKey as FilterKey) > -1
        );
      }
      return (
        isEqual(INITIAL_FILTER_VALUES[filterKey], filterValue) ||
        HIDDEN_MOBILE_FILTERS.indexOf(filterKey as FilterKey) > -1
      );
    });
    return {
      ...activeFilters,
      ...ALWAYS_ACTIVE_FILTERS.reduce((output, filterKey) => {
        output[filterKey] = filterValues[filterKey];
        return output;
      }, {}),
    } as { [key in FilterKey]: any };
  }
);

/**
 * Return whether "off market" properties will be included in the result set
 */
export const getSearchFilterSetIncludesOffMarketProperties = createSelector(
  getActiveFilters,
  (activeFilters) => {
    /* If the MLS state filter key isn't active, we're showing off-market properties */
    return (
      !activeFilters[FILTER_KEYS.MLS_STATE] ||
      /* If the MLS state filter key is active and it's either empty or it includes "off-market"
       * then we're showing off-market properties */
      (activeFilters[FILTER_KEYS.MLS_STATE] &&
        (activeFilters[FILTER_KEYS.MLS_STATE].length === 0 ||
          activeFilters[FILTER_KEYS.MLS_STATE].indexOf('OFF_MARKET') > -1))
    );
  }
);

export const getSearchIsShowingMLSCoverageLayer = createSelector(
  getSearchFilterSetIncludesOffMarketProperties,
  (filterSetIncludesOffMarketProperties) =>
    !filterSetIncludesOffMarketProperties
);

export const getSearchMLSCoverageLevels = createSelector(
  getSearchState,
  (searchState) =>
    searchState.mlsCoverageLayerFeaturesForMapArea.map(
      (featureProperties) => featureProperties && featureProperties.mls_coverage
    )
);

export const getSearchMLSCoverageLayerFeatures = createSelector(
  getSearchState,
  (searchState) => searchState.mlsCoverageLayerFeaturesForMapArea
);

/* Whether any incomplete coverage exists within the map area.  Updated on every map move */
export const getSearchIncompleteMLSCoverageExistsInMapArea = createSelector(
  getSearchMLSCoverageLevels,
  (coverageLevels) =>
    coverageLevels.indexOf(10) > -1 || coverageLevels.indexOf(20) > -1
);

/* Whether the searched-for place has limited MLS coverage. Updated on every place search.
 * `mlsCoverage` is set to `null` whenever we're setting `constrainedToPlace` without looking up the coverage,
 *  (currently this way when loading a saved-search) so we never want to show the modal in this case. */
export const getConstrainedToPlaceHasLimitedMLSCoverage = createSelector(
  getSearchConstrainedPlace,
  (constrainedToPlace) =>
    !!(
      constrainedToPlace &&
      (constrainedToPlace as any).mlsCoverage !== null &&
      (constrainedToPlace as any).mlsCoverage < 20
    )
);

/** If a property search with the relevant details below occurs,
 * the YT button and modal overlap the map controls.
 * This will return a boolean to determine if the YT button and modal placement
 * should be adjusted to not overlap the map controls
 */
export const selectConstrainedToPlaceHasNoMLSCoverage = createSelector(
  getSearchConstrainedPlace,
  getSearchListProperties,
  getSearchPropertyListIsSuccessStatus,
  (state) =>
    getIsFeatureEnabled('hide_search_sidebar_constrained_place_no_results')(
      state
    ),
  (
    constrainedToPlace,
    searchListProperties,
    isSearchPropertyListFetchSuccessful,
    isFeatureFlagEnabled
  ) =>
    Boolean(
      constrainedToPlace &&
        (constrainedToPlace as any).mlsCoverage !== null &&
        searchListProperties.length === 0 &&
        isSearchPropertyListFetchSuccessful &&
        isFeatureFlagEnabled &&
        getIsInView(View.SEARCH)
    )
);

/* Whether the user has dismissed the "incomplete coverage" notification modal */
export const getAllowShowingMLSCoverageModal = createSelector(
  getSearchState,
  (searchState) => searchState.allowShowingMLSCoverageModal
);

export const getIsShowingNoMLSModal = createSelector(
  getConstrainedToPlaceHasLimitedMLSCoverage,
  getAllowShowingMLSCoverageModal,
  (constrainedToPlaceHasLimitedMLSCoverage, allowShowingMLSCoverageModal) => {
    return (
      constrainedToPlaceHasLimitedMLSCoverage && allowShowingMLSCoverageModal
    );
  }
);

export const getSearchMLSCoverageStatus = createSelector(
  getSearchState,
  (searchState) => searchState.mlsCoverageStatus
);

export const getSelectedFilterInitialValue = createSelector(
  getSearchState,
  (searchState) => searchState.selectedFilterInitialValue
);

export const getSearchIsShowingMapBottomLayerGroupsList = createSelector(
  getSearchState,
  (searchState) => searchState.isShowingMapBottomLayerGroupsList
);

export const getSearchIsShowingMapBottomLayerControl = createSelector(
  getSearchState,
  (searchState) => searchState.isShowingMapBottomLayerControl
);

export const getSearchInvalidateMapSizeTrigger = createSelector(
  getSearchState,
  (searchState) => searchState.invalidateMapSizeTrigger
);

export const getSearchActiveFilterCount = createSelector(
  getActiveFilters,
  (activeFilters) => Object.keys(activeFilters).length
);

export const getFinalSearchActiveFilterCount = createSelector(
  getSearchActiveFilterCount,
  selectIsSRPGrantFilterOn,
  (state) => getIsFeatureEnabled('grant_program')(state),
  (activeFiltersCount, isSRPGrantFilterOn, isGrantProgramFeatureEnabled) => {
    /* Grant toggle exists outside of filters but is within the same UI */
    return isSRPGrantFilterOn && isGrantProgramFeatureEnabled
      ? activeFiltersCount + 1
      : activeFiltersCount;
  }
);

export const getSearchListPreviousScrollPosition = createSelector(
  getSearchState,
  (searchState) => searchState.propertyList.scrollPosition
);

/* Needed here due to avoid circular dependency issue that would occur in global-ui.selectors */
export const getIsShowingMobileBottomNav = createSelector(
  getCurrentView,
  getIsTabletOrSmallerSize,
  getActivePDPSlug,
  getSearchIsShowingMobileFilters,
  getSearchIsShowingMapBottomLayerGroupsList,
  getSearchIsShowingMapBottomLayerControl,
  (
    currentView,
    isTabletOrSmallerSize,
    activePDPSlug,
    isShowingMobileFilters,
    isShowingMapBottomLayerGroupsList,
    isShowingMapBottomLayerControl
  ) =>
    isTabletOrSmallerSize &&
    !activePDPSlug &&
    !isShowingMobileFilters &&
    !isShowingMapBottomLayerGroupsList &&
    !isShowingMapBottomLayerControl &&
    HIDDEN_BOTTOM_NAV_FOR_VIEWS.indexOf(currentView as View) === -1
);

export const getPlaceId = createSelector(
  getSearchConstrainedPlace,
  (constrainedToPlace) => {
    return (
      (constrainedToPlace && (constrainedToPlace as PlaceIdPlace).placeId) ||
      null
    );
  }
);

export const getShouldShowMLSRegistration = createSelector(
  getSearchState,
  (searchState) => searchState.showMLSRegistrationConfirm
);

export const getPreviousLoadedPropertyCount = createSelector(
  getSearchState,
  (searchState) => searchState.previousLoadedPropertyCount
);

export const getSearchListPropertiesWithAdCard = createSelector(
  getSearchListProperties,
  (state) => getIsFeatureEnabled('cta_cleanup')(state),
  (state) => getIsFeatureEnabled('loan_officer')(state),
  (state) => getIsFeatureEnabled('finance_cta')(state),
  getSearchAdCardContent,
  getIsShowingLenderCTACards,
  getShouldShowConnectWithATopLocalAgent,
  getPreviousLoadedPropertyCount,
  (
    propertyResults,
    isCTACleanupEnabled,
    isLoanOfficerEnabled,
    isFinanceCTAEnabled,
    showSearchAdCard,
    isShowingLenderCTACards,
    shouldShowTopLocalAgentAd,
    previousLoadedPropertyCount
  ) => {
    const SRP_GENERAL_AD_POSITION = 2;
    const SRP_LO_AD_POSITION = 2;
    const SRP_FINANCE_CTA_POSITION =
      propertyResults && propertyResults.length > 1 ? 2 : 1;
    const SRP_AD_CARD_POSITION =
      propertyResults && propertyResults.length > 1 ? 2 : 1;
    const propertyResultsCount = propertyResults.length;

    if (propertyResultsCount === 0) {
      return propertyResults;
    }

    /* Depending on which feature is enabled, insert a placeholder data object into the list of cards.
     * A special card will be rendered for this placeholder in SearchPageMap/SearchPageList */
    if (isShowingLenderCTACards) {
      let allCards = [] as any;
      let lenderCTACardPosition: number[] = [SRP_GENERAL_AD_POSITION];

      for (let i = 0; i < previousLoadedPropertyCount.length; i++) {
        let position;
        if (propertyResultsCount > previousLoadedPropertyCount[i]) {
          /**
           * Set the Ad card position every time that the load more button is clicked
           * and fetching more propertyResults.
           */
          position = previousLoadedPropertyCount[i] + SRP_GENERAL_AD_POSITION;
          // Make sure to not add duplicated position in the array
          if (!lenderCTACardPosition.includes(position)) {
            lenderCTACardPosition.push(position);
          }
        }
      }
      /**
       * Set the SearchList Properties cards with the AdCards
       * in multiple positions if the load more button is clicked.
       */
      propertyResults.map((ele, index) => {
        if (lenderCTACardPosition.includes(index)) {
          allCards.push({ isLenderCTACard: true });
        }

        allCards.push(ele);
      });
      return allCards;
    } else if (isLoanOfficerEnabled && !isCTACleanupEnabled) {
      return [
        ...propertyResults.slice(0, SRP_LO_AD_POSITION),
        { isLoanOfficerAd: true },
        ...propertyResults.slice(SRP_LO_AD_POSITION),
      ];
    } else if (isFinanceCTAEnabled) {
      return [
        ...propertyResults.slice(0, SRP_FINANCE_CTA_POSITION),
        { isFinanceAd: true },
        ...propertyResults.slice(SRP_FINANCE_CTA_POSITION),
      ];
    } else if (shouldShowTopLocalAgentAd) {
      return [
        ...propertyResults.slice(0, SRP_LO_AD_POSITION),
        { isTopAgentsRankedAd: true },
        ...propertyResults.slice(SRP_LO_AD_POSITION),
      ];
    } else if (showSearchAdCard) {
      return [
        ...propertyResults.slice(0, SRP_AD_CARD_POSITION),
        { isAdCardForCobrand: true },
        ...propertyResults.slice(SRP_AD_CARD_POSITION),
      ];
    } else {
      return propertyResults;
    }
  }
);

export const getSearchShowMobileMapDisclaimerFairHousingNY = createSelector(
  getIsShowingMapMarkers,
  getSearchMarkersGeoJSONFeatures,
  (isShowingMarkers, markerFeaturesData) => {
    return (
      isShowingMarkers &&
      markerFeaturesData &&
      markerFeaturesData.some(
        (markerFeature) =>
          markerFeature &&
          getIsPropertyMapMarkerFeature(markerFeature) &&
          markerFeature?.properties?.normalizedPropertyData?.state === 'NY'
      )
    );
  }
);

export const getSearchShowPropertyListDisclaimerFairHousingNY = createSelector(
  getSearchListProperties,
  (propertyList) => {
    return (
      propertyList && propertyList.some((property) => property?.state === 'NY')
    );
  }
);
