import {
  FILTER_KEYS,
  MLS_STATE_ALL_GROUPS,
  MlsStateAllGroup,
} from './filter-constants';
import { LAYER_GROUP_KEYS } from './map-constants';
import {
  LockedComponent,
  SectionId,
  SpecialUserType,
} from './types/locked-components';

export const LOCKED_SECTION_LIST: LockedComponent<MlsStateAllGroup> = {
  [SpecialUserType.Restricted]: [
    SectionId.HomeownerAutocompleteSearch,
    SectionId.PropertyCardRentalEstimate,
    SectionId.AvmFactors,
    SectionId.PdpRentalAvm,
    SectionId.PdpComps,
    SectionId.PdpCrime,
    SectionId.PdpForecast,
    SectionId.PdpLocalActivities,
    SectionId.PdpMortgageCalculator,
    SectionId.PdpNeighborhoodInsights,
    SectionId.PdpSaleHistory,
    SectionId.PdpSchools,
    SectionId.PdpHomeownerUpsellAd,
    LAYER_GROUP_KEYS.CRIME,
    LAYER_GROUP_KEYS.SCHOOLS,
    LAYER_GROUP_KEYS.FORECAST,
    FILTER_KEYS.BEST_PRIMARY_SCHOOL_PERCENTILE_MIN_MAX,
    FILTER_KEYS.BEST_MIDDLE_SCHOOL_PERCENTILE_MIN_MAX,
    FILTER_KEYS.BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX,
    FILTER_KEYS.SEARCH_BY_MLS,
    FILTER_KEYS.LIST_TO_AVM_PRICE_MIN_MAX,
    FILTER_KEYS.YEAR_RISK_OF_DECLINE_MIN_MAX,
    FILTER_KEYS.MARKET_GRADE_MIN_MAX,
    FILTER_KEYS.RENTAL_ESTIMATE_MIN_MAX,
    FILTER_KEYS.RENTAL_YIELD_MIN_MAX,
    FILTER_KEYS.CRIME_COUNTY_PERCENTILE_MIN_MAX,
    FILTER_KEYS.HPI_MIN_MAX,
    FILTER_KEYS.AVM_PRICE_MIN_MAX,
    FILTER_KEYS.CRIME_COUNTY_PERCENTILE_MIN_MAX,
    MLS_STATE_ALL_GROUPS.OFF_MARKET,
    MLS_STATE_ALL_GROUPS.ALL,
  ],
};

export const LOCKED_RENTAL_ESTIMATE_VALUE_PLACEHOLDER = '$0,000/month';
export const LOCKED_AVM_FACTOR_MEDIAN_VALUE_PLACEHOLDER =
  'Typical homes in this area are on average 0,000 sq. ft. and have 0 bathrooms.';
export const LOCKED_AVM_FACTOR_DESCRIPTION_PLACEHOLDER =
  '000 square feet larger than the typical square footage';
export const LOCKED_AVM_FACTOR_VALUE_DELTA_PLACEHOLDER = '+ $000,000 to value';
export const LOCKED_PDP_RENTAL_AVM_PLACEHOLDER = '$0,000/month';
export const LOCKED_PDP_SALE_HISTORY_PLACEHOLDER = 'Most Recent: $0,000,000';
export const LOCKED_PDP_SCHOOLS_PLACEHOLDER =
  'The closest schools are above/below average.';
export const LOCKED_PDP_COMPS_PLACEHOLDER =
  'Ranging from $000,000 to $0,000,000';
export const LOCKED_PDP_CRIME_PLACEHOLDER =
  'This block has a higher/lower crime report rate than 00% of the blocks in County.';
export const LOCKED_PDP_FORECAST_PLACEHOLDER = '0 Year: +0.0% at $0,000,000';
export const LOCKED_PDP_MORTGAGE_CALCULATOR_PLACEHOLDER =
  '$0,000 estimated mortgage payment based on 00% down payment and 0.00% interest rate with a $000,000 offer';
export const LOCKED_PDP_NEIGHBORHOOD_INSIGHTS_PLACEHOLDER =
  'In this area, price per square foot ranges from $000 - $0.0k and size ranges from 000 - 0000 square feet.';
