import ClickableLogo from '@client/components/ClickableLogo';
import DesktopNavItem from '@client/components/DesktopNavItem';
import {
  LINK_HEADINGS,
  LO_DIRECT_SIDEBAR_LINKS,
  NAMES_FOR_VIEWS,
} from '@client/components/LODirect/LODirectSidebar';
import LODirectUserImage from '@client/components/LODirect/LODirectUserImage';
import MenuIcon from '@client/components/MenuIcon';
import theme from '@client/css-modules/LODirect/LODirectSidebar.css';
import breakpoints from '@client/css-modules/_breakpoints.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import ComeHomeDirectLogoBlack from '@client/inline-svgs/comehome-direct-logo-black';
import LogoutIcon from '@client/inline-svgs/logout-icon';
import { LO_DIRECT_LOGOUT_QUERY_KEY, View } from '@client/routes/constants';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

const MobileTopSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 25px;
  background-color: #fff;
  box-sizing: border-box;
  @media (min-width: ${breakpoints.tabletScreenMaxWidth}) {
    display: none;
  }

  @media (max-width: ${breakpoints.tabletScreenMaxWidth}) {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 2;
  }
`;

const StyledClickableLogo = styled(ClickableLogo)`
  margin: 0;
  width: 200px;
`;

const DesktopNavItemContainer = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 2;
  height: calc(100% - 60px);
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  color: #545454;
  font-size: 14px;
`;

const StyledButton = styled.button`
  border: 0;
  border-top: 1px solid #d8d8d8;
  border-radius: 0;
  background: #fff;
  width: calc(100% - 48px);
  padding: 26px 0 42px;
  cursor: pointer;
  font-weight: 900;
  font-size: 14px;
  line-height: 22px;
  position: absolute;
  bottom: 0;
`;

const StyledSpan = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

type StyledDesktopNavItemProps = {
  $isCurrentView: boolean;
};

const StyledDesktopNavItem = styled.div<StyledDesktopNavItemProps>`
  font-weight: 900;
  line-height: 1.5;
  cursor: pointer;
  width: 304px;
  padding: 4px 8px 4px 8px;

  > div {
    margin-right: 0 !important;
    padding: 16px;
    border-radius: 8px;
    background-color: ${(props) =>
      props.$isCurrentView ? '#f2f7ff' : 'transparent'};
  }
`;

/**
 * Due to SSR requirements, the desktop vs. mobile experiences are shown/hidden via CSS.
 * Each is grouped by a top-level container in the render method.
 */
export const LODirectHeaderMobile: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const currentView = useSelector(getCurrentView);
  const [localCurrentView, setLocalCurrentView] = useState<View>(
    currentView || View.LO_DIRECT_LEADS
  );
  const { linkColor } = useCobrandStyles();
  const homeUrl = `${location.protocol}//${location.host}`;
  const dispatch = useDispatch();

  const toggleMobileMenu = (): void => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleRouteChange = (view: View) => {
    setLocalCurrentView(view);
    dispatch(routeChange({ view }));
  };

  return (
    <MobileTopSection>
      <MenuIcon
        ariaLabel={isMobileMenuOpen ? 'Close menu' : 'Open menu'}
        handleClick={toggleMobileMenu}
        active={isMobileMenuOpen}
        theme={theme}
      />
      <StyledClickableLogo
        dataHcName={'comehome-direct-logo'}
        ariaLabel="ComeHome Direct logo"
        manualDestination={{ url: homeUrl, target: '_self' }}
        Logo={(props) => <ComeHomeDirectLogoBlack {...props} role="img" />}
      />

      {isMobileMenuOpen && (
        <DesktopNavItemContainer>
          {LO_DIRECT_SIDEBAR_LINKS.map((view, idx) => (
            <StyledDesktopNavItem
              key={`${view.toLowerCase()}-navItem-${idx}`}
              $isCurrentView={localCurrentView === view}
            >
              <DesktopNavItem
                dataHcName={`lo-direct-${NAMES_FOR_VIEWS[
                  view
                ].toLowerCase()}-link`}
                view={view}
                key={`${view.toLowerCase()}-${idx}`}
                currentView={localCurrentView}
                activeColor={linkColor}
                hasActiveColorWhenHovered={false}
                onClick={() => handleRouteChange(view)}
              >
                {LINK_HEADINGS[view]}
              </DesktopNavItem>
            </StyledDesktopNavItem>
          ))}
          <StyledButton
            data-hc-name={`lo-direct-logout-button-link`}
            onClick={() =>
              dispatch(
                routeChange({
                  view: View.LO_DIRECT_LOGIN,
                  query: { [LO_DIRECT_LOGOUT_QUERY_KEY]: true },
                })
              )
            }
          >
            <StyledSpan>
              <LogoutIcon className={theme.Icon} /> Logout
            </StyledSpan>
          </StyledButton>
        </DesktopNavItemContainer>
      )}
      <LODirectUserImage
        height="35px"
        dataHcName={`lo-direct-account-link`}
        width="35px"
        onClick={() => {
          dispatch(routeChange({ view: View.LO_DIRECT_ACCOUNT }));
        }}
      />
    </MobileTopSection>
  );
};
