import { call, put, select } from 'redux-saga/effects';
import { watchLatest } from '@client/utils/saga.utils';
import { getLoanOfficerInfo } from '@client/store/selectors/loan-officer.selectors';
import {
  CLICK_FIND_NEW_AGENT_FORM_SUBMIT,
  CLICK_AGENT_LEAD_REFERRAL_NETWORK,
  SubmitFindNewAgentForm,
  SubmitAgentLeadReferralNetwork,
} from '@client/store/actions/find-agent.actions';
import { toastShow } from '@client/store/actions/toast.actions';
import { submitReferral } from '@client/store/sagas/referral-services.saga';
import { consumerApiClient } from '@client/services/consumer-api-client';

function* submitFindNewAgentFormSaga(action: SubmitFindNewAgentForm) {
  const loanOfficer = (yield select(getLoanOfficerInfo)) as ReturnType<
    typeof getLoanOfficerInfo
  >;
  try {
    yield call(submitReferral, action.payload, loanOfficer, '/refer/buyer');
    yield put(toastShow('Your request has been submitted.'));
  } catch (err) {
    yield put(toastShow('An error has occurred, please try again.'));
    throw err;
  }
}

function* submitAgentLeadReferralNetworkSaga(
  action: SubmitAgentLeadReferralNetwork
) {
  const isRequestATour = action.payload.tour_type;
  try {
    yield call(
      [consumerApiClient, consumerApiClient.sendAgentLeadReferralEmail],
      action.payload
    );
    if (!isRequestATour) {
      yield put(toastShow('Your request has been submitted.'));
    }
  } catch (err) {
    if (!isRequestATour) {
      yield put(toastShow('An error has occurred, please try again.'));
    }
    throw err;
  }
}

export default (sagaMiddleware) => {
  watchLatest(sagaMiddleware, {
    [CLICK_FIND_NEW_AGENT_FORM_SUBMIT]: submitFindNewAgentFormSaga,
    [CLICK_AGENT_LEAD_REFERRAL_NETWORK]: submitAgentLeadReferralNetworkSaga,
  });
};
