import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import defaultTheme from '@client/css-modules/PulseLoader.css';

type Props = {
  isLoading: boolean;
  style?: React.CSSProperties;
  theme: Theme;
  children?: React.ReactNode;
};

function PulseLoader({ isLoading, style, children, theme }: Props) {
  return isLoading ? (
    <div
      className={theme.PulseLoader}
      data-hc-name="pulse-loader"
      style={style}
    />
  ) : (
    <>{children}</>
  );
}

export default themr('PulseLoader', defaultTheme)(PulseLoader);
