import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import theme from '@client/css-modules/UnreadAlertsResetPage.css';
import { ReduxState } from '@client/store/types/redux-state';

type Props = {
  handleButtonClick: () => void;
};

const UnreadAlertsResetPage: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const hasResetUnreadAlerts = useSelector(
    (state: ReduxState) => state.alerts.hasResetUnreadAlerts
  );
  const handleButtonClick = () =>
    dispatch({ type: 'RESET_UNREAD_ALERTS_POSSIBLY_LOGGED_OUT' });

  return (
    <div className={theme.UnreadAlertsResetPage}>
      <div className={theme.Button} onClick={handleButtonClick}>
        <label>
          {hasResetUnreadAlerts ? 'Done!' : 'Reset top 2 alerts to "unread"'}
        </label>
      </div>
      {hasResetUnreadAlerts && (
        <button
          onClick={() => {
            window.history.back();
          }}
        >
          Return to previous page
        </button>
      )}
    </div>
  );
};

export default UnreadAlertsResetPage;
