import { queryStringContainsUnit } from '@client/utils/address-autocomplete.utils';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import { call, cancel, fork, put, select } from 'redux-saga/effects';

import { ALL_SEARCH_VIEWS, View } from '@client/routes/constants';
import { consumerApiClient } from '@client/services/consumer-api-client';
import { graphQLApiClient } from '@client/services/graphql-api-client';
import { clearCurrentSavedSearch } from '@client/store/actions/saved-search.actions';
import {
  fetchUserLocationSuccess,
  FETCH_USER_LOCATION,
  searchClearResultMapMarkers,
  SearchFetchPlaceDetailsAction,
  searchFetchPlaceDetailsSuccess,
  searchFetchPlacesSuccess,
  searchFetchPropertyList,
  SEARCH_CLEAR_PLACES,
  SEARCH_FETCH_PLACES,
  SEARCH_FETCH_PLACE_DETAILS,
} from '@client/store/actions/search.actions';
import { STATUSES } from '@client/store/constants';
import { saveLastSearchSaga } from '@client/store/sagas/last-search.saga';
import { PlaceAutocompleteResults } from '@client/store/sagas/queries/types';
import {
  getIsShowingSearchPageList,
  getPlaceSearchSessionToken,
  getSearchUserLocation,
  getSearchUserLocationStatus,
} from '@client/store/selectors/search.selectors';
import {
  ConstrainedToPlace,
  PlaceLookupResultWithPlace,
} from '@client/store/types/place-search';
import { reportToSentry } from '@client/utils/error.utils';
import {
  getBoundsFromCenterPointAndRadius,
  getDistance,
} from '@client/utils/maps.utils';
import {
  getValidAddressesFromPlaceSearch,
  getValidPlacesFromPlaceSearch,
} from '@client/utils/property.utils';
import { watchEvery, watchLatest } from '@client/utils/saga.utils';
import { getSearchPageLocationUrlQueryParam } from '@client/utils/url-formatting.utils';

type PlaceLookupSuccessArgs = {
  data: PlaceLookupResultWithPlace;
  constrainedToPlace: ConstrainedToPlace;
  shouldResetPropertyList: boolean;
};

/* In meters, places larger than this will be positioned in the map using bounds of this size */
const MAX_PLACE_BOUNDS_SIZE = 12000;
let activeTasks = {};
const getTaskKey = (term) => term.replace(/\s/g, '-');

function* searchPlaces(action) {
  const { term, buildingId } = action.payload;
  activeTasks[getTaskKey(term)] = yield fork(getPlacesData, {
    term,
    buildingId,
  });
}

function* getPlacesData({ term, buildingId }) {
  const userLocation = yield select(getSearchUserLocation);
  const { latitude, longitude } = userLocation;
  const groupResultsByBuilding = !queryStringContainsUnit(term);
  const sessionToken = yield select(getPlaceSearchSessionToken);
  const data = yield call(
    [graphQLApiClient, graphQLApiClient.getPlaceSearchDetails],
    {
      term,
      buildingId,
      sessionToken,
      latitude,
      longitude,
      groupResultsByBuilding,
    }
  );
  const { places, addresses, sessiontoken } =
    data.placeSearch as PlaceAutocompleteResults;
  const filteredAddresses = getValidAddressesFromPlaceSearch(addresses || []);
  const filteredPlaces = getValidPlacesFromPlaceSearch(places || []);
  yield put(
    searchFetchPlacesSuccess(filteredPlaces, filteredAddresses, sessiontoken)
  );

  delete activeTasks[getTaskKey(term)];
}

/**
 * Executed after clicking a place result from the search dropdown. Repositions the map to the new location.
 */
export function* getPlaceDetailsAndInitSearchMap(
  action: SearchFetchPlaceDetailsAction
) {
  const { id: placeId } = action.payload;
  const sessionToken = yield select(getPlaceSearchSessionToken);
  const data = (yield call(graphQLApiClient.getPlaceLookupDetails, {
    placeId,
    sessionToken,
  })) as PlaceLookupResultWithPlace;
  const { place } = data.placeLookup;
  const { mlscoverage, placeType, name, locality, region } = place || {};

  /* Clear existing map markers so that we can load only markers within the place constraint
   * after map move */
  yield put(searchClearResultMapMarkers());
  yield call(handlePlaceLookupSuccess, {
    data,
    constrainedToPlace: {
      placeId,
      mlsCoverage: mlscoverage || null,
      city: locality || null,
      state: region || null,
      /* The `name` field contains the zipcode only if `placeType` === 'postalcode' */
      zipcode: placeType === 'postalcode' ? name || null : null,
    },
    shouldResetPropertyList: true,
  });
}

/**
 * Set the map's location after a place is looked up following selecting a place result
 * from the autocomplete dropdown or a successful "city" or "zipcode" route lookup of a city or zipcode
 * @param {object} data - property-graph response data
 * @param {object} constrainedToPlace - object identifying the place to constrain the search results to
 * @yield {void}
 */
export function* handlePlaceLookupSuccess({
  data,
  constrainedToPlace,
  shouldResetPropertyList,
}: PlaceLookupSuccessArgs) {
  const {
    place: { viewport, location, shape, formattedAddress, placeId },
  } = data.placeLookup;

  /* exit saga and report error to Sentry when these page breaking properties are null */
  if (!data.placeLookup || !location || !viewport || !shape) {
    yield call(
      reportToSentry,
      'Null location, viewport, or/and shape for place',
      { placeId, placeLookup: data.placeLookup }
    );
  } else {
    const { latitude, longitude } = location!;
    const {
      northeastLatitude,
      northeastLongitude,
      southwestLatitude,
      southwestLongitude,
    } = viewport!;
    const distanceBetweenBounds = !!(
      northeastLatitude &&
      northeastLongitude &&
      southwestLatitude &&
      southwestLongitude
    )
      ? getDistance(
          { lat: northeastLatitude, lng: northeastLongitude },
          { lat: southwestLatitude, lng: southwestLongitude }
        )
      : +Infinity;
    /* Use the size of the bounds to generate a radius and create new bounds with some padding up to certain size.
     * After this, use a fixed radius to generate bounds */
    const radiusFromCenter =
      /* In rare cases we only have a point shape for a place, in which case we use a fixed radius.
       * Usually such places are small towns, thus a smaller radius will avoid us loading properties
       * from neighboring towns. */
      shape.type === 'Point'
        ? 2000
        : distanceBetweenBounds < MAX_PLACE_BOUNDS_SIZE
        ? distanceBetweenBounds / 2 + 200 /* Add a little padding */
        : MAX_PLACE_BOUNDS_SIZE / 2;

    if (!latitude || !longitude) {
      throw new Error(
        `Unexpected null latitude or longitude in place results for placeId ${placeId}`
      );
    }
    const placeBounds = yield call(
      getBoundsFromCenterPointAndRadius,
      [latitude, longitude],
      radiusFromCenter
    );
    const placeBoundsObj = {
      southWest: {
        lat: placeBounds[0][0],
        lng: placeBounds[0][1],
      },
      northEast: {
        lat: placeBounds[1][0],
        lng: placeBounds[1][1],
      },
    };
    const currentView = yield select(getCurrentView);
    const isOnSearchPage = ALL_SEARCH_VIEWS.includes(currentView);

    yield put(clearCurrentSavedSearch());
    yield put(
      searchFetchPlaceDetailsSuccess({
        placeBounds,
        constrainedToPlace,
        placeGeoJSON: shape,
        placeGeoJSONDescription: formattedAddress,
        shouldResetPropertyList,
      })
    );

    /* When in the mobile list view, we need to pull a new list of properties to sort */
    if (isOnSearchPage) {
      const isShowingSearchList = yield select(getIsShowingSearchPageList);
      if (isShowingSearchList) {
        yield put(
          searchFetchPropertyList({
            bounds: placeBoundsObj,
            showLoadingIndicator: true,
          })
        );
      }
      /* If not on the search page, navigate there. SearchListPage componentDidMount dispatches `searchFetchPropertyList` */
    } else {
      yield put(
        routeChange({
          view: View.SEARCH,
          query: getSearchPageLocationUrlQueryParam(placeBoundsObj),
        })
      );
      /* If we're already on the search page, the map moving will take care of saving the last-search.
       * If not, we need to save it now, since the last-search isn't saved on map init */
      yield call(saveLastSearchSaga, {});
    }
  }
}

/* Cancel the active requests in the browser */
function* cancelPendingQueries() {
  for (let key in activeTasks) {
    if (activeTasks.hasOwnProperty(key)) {
      yield cancel(activeTasks[key]);
    }
  }
  activeTasks = {};
}

function* getUserLocation() {
  const userLocationStatus = yield select(getSearchUserLocationStatus);
  if (userLocationStatus === STATUSES.INIT) {
    const data = yield call([
      consumerApiClient,
      consumerApiClient.getGeoLocation,
    ]);
    const { latitude, longitude } = data;
    yield put(fetchUserLocationSuccess({ latitude, longitude }));
  }
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [SEARCH_FETCH_PLACE_DETAILS]: getPlaceDetailsAndInitSearchMap,
    [SEARCH_CLEAR_PLACES]: cancelPendingQueries,
    [FETCH_USER_LOCATION]: getUserLocation,
  });
  watchLatest(sagaMiddleware, {
    [SEARCH_FETCH_PLACES]: searchPlaces,
  });
};
