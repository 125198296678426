import React from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import { Alert } from '@client/utils/reach-alert';

type Props = {
  theme: Theme;
};

const NoAddressResultsLink = ({ theme }: Props) => {
  return (
    <Alert
      as="span"
      type="assertive"
    >
      <span>
        Sorry, we can't find a match.
      </span>
      <span>
        Our data is growing every day. Please try another search.
      </span>
    </Alert>
  );
};

export default themr('NoAddressResultsLinkThemed', {})(NoAddressResultsLink);
