import ComeHomeLogoVariableSize from '@client/assets/images/powered-by-comehome.svg';
import React from 'react';

const ComeHomeLogoVariableSizeImg: React.FunctionComponent<
  React.HTMLAttributes<HTMLImageElement>
> = (props) => {
  return <img src={ComeHomeLogoVariableSize} {...props} />;
};

export default ComeHomeLogoVariableSizeImg;
