import classNames from 'classnames';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import defaultTheme from '@client/css-modules/LearnMoreAside.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type Props = {
  /** Link click callback */
  onMouseEnter?: (e: React.MouseEvent) => void;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  theme: Theme;
  style?: React.CSSProperties;
  learnMoreText?: string;
  children?: (LearnMoreLink: React.ComponentType) => JSX.Element;
};

/**
 * Component that renders text in a smaller, lighter font, either solely a "Learn More"
 * link, or text featuring a "Learn More" link
 */
export function LearnMoreAside({
  children,
  className,
  theme,
  onMouseEnter,
  style,
  learnMoreText,
  onClick,
}: Props) {
  const { linkColor } = useCobrandStyles();
  const LearnMoreLink = (): JSX.Element => (
    <div
      className={theme.LearnMoreLink}
      style={{
        color: linkColor,
        ...style,
      }}
      data-hc-name="learn-more-link"
    >
      {learnMoreText ? learnMoreText : 'Learn More'}
    </div>
  );

  return (
    <div
      className={classNames(theme.LearnMoreAside, className)}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      {children ? children(LearnMoreLink) : <LearnMoreLink />}
    </div>
  );
}

const ThemedLearnMoreAside = themr(
  'LearnMoreAside',
  defaultTheme
)(LearnMoreAside);
export default ThemedLearnMoreAside;
