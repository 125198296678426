import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import BinaryHorizontalToggle from '@client/components/generic/BinaryHorizontalToggle';

import { useFetchCensusTractDataEffect } from '@client/hooks/census-tract.hooks';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

import {
  selectIsSRPGrantFilterOn,
  toggleSRPGrantFilter,
} from '@client/store/slices/grant-program.slice';

const StyledFilterDiv = styled.div`
  width: 100%;
  padding: 15px 15px 15px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledLabel = styled.label`
  font-size: 14px;
  font-weight: 400;
  width: 11rem;
  padding-right: 15px;
  box-sizing: border-box;
`;

const StyledDescription = styled.div`
  font-size: 12px;
  font-weight: 300;
  max-width: 192px;
`;

const StyledToggleWrapper = styled.div`
  margin-left: 40px;
  position: relative;
`;

const StyledCheck = styled.div`
  position: absolute;
  height: 8px;
  width: 3px;
  border-right: 0.1rem solid #fff;
  border-bottom: 0.1rem solid #fff;
  top: 7px;
  left: 9px;
  transform: rotate(45deg);
`;

export const grantProgramFilterToggleDescription =
  'Highlight properties that qualify for a homebuyer grant.';

export default function GrantProgramFilterToggle() {
  const dispatch = useDispatch();
  useFetchCensusTractDataEffect();
  const isActive = useSelector(selectIsSRPGrantFilterOn);
  const { primaryButtonFillColor } = useCobrandStyles();

  return (
    <StyledFilterDiv data-hc-name="filter-row">
      <StyledLabel id="grant-eligible">Grant Eligible</StyledLabel>
      <StyledDescription>
        {grantProgramFilterToggleDescription}
      </StyledDescription>
      <StyledToggleWrapper>
        <BinaryHorizontalToggle
          ariaLabelledBy="grant-eligible"
          dataHcName="grant-toggle"
          selectedValue={isActive}
          handleSelect={() => dispatch(toggleSRPGrantFilter())}
          toggleButtonStyle={
            isActive ? { background: primaryButtonFillColor } : {}
          }
          data-event-name="click_grant_eligible_search"
          data-parent-event-name="searches_performed"
        />
        {isActive && <StyledCheck />}
      </StyledToggleWrapper>
    </StyledFilterDiv>
  );
}
