import { useDispatch, useSelector } from 'react-redux';
import { gql, useQuery } from 'urql';

import { routeChange } from '@src/redux-saga-router-plus/actions';
import { dollarsFormatter } from '@client/utils/formatter.utils';
import {
  normalizePropertyData,
  isPropertyDataValid,
  getPropertyValueAndValueLabel,
} from '@client/utils/property.utils';
import WatchListActionButtonContainer from '@client/containers/watchlist-action-button.container';
import theme from '@client/css-modules/RecentUserActivity/RecentPropertyWithGQLFetch.css';
import { View } from '@client/routes/constants';
import { getIsPropertyInWatchList } from '@client/store/selectors/watchlist.selectors';
import { Status, STATUSES } from '@client/store/constants';
import {
  PropertyDetailsForRecentProperty,
  PropertyDetailsForRecentPropertyVariables,
} from './__generated__/__generated__/property-details-for-recent-property';
import PulseLoader from '@client/components/PulseLoader';
import { getMapTilePhoto } from '@client/utils/photo.utils';
import { PropertyLookupWithAddressRequired } from '@client/store/types/property';
import {
  eventType,
  reportEvent,
} from '@client/store/actions/analytics.actions';

type RecentPropertyProps = {
  status: Status;
  propertyDetails: PropertyDetailsForRecentProperty['propertyLookup'];
  dataEventNameSaveClick: eventType;
  dataEventNamePropertyClick: eventType;
  onPropertyClick?: () => void;
};

function RecentProperty({
  status,
  onPropertyClick,
  propertyDetails,
  dataEventNameSaveClick,
  dataEventNamePropertyClick,
}: RecentPropertyProps) {
  const dispatch = useDispatch();
  const slug = propertyDetails?.address?.slug || '';
  const isAddedToWatchList = useSelector(getIsPropertyInWatchList(slug));
  const bestPhotoUrl =
    propertyDetails?.bestPhotos &&
    propertyDetails?.bestPhotos[0] &&
    propertyDetails?.bestPhotos[0].representation?.httpUrl;
  const photoUrlFallback = getMapTilePhoto({
    latitude: propertyDetails?.geoLocation?.latitude || 24.596161,
    longitude: propertyDetails?.geoLocation?.longitude || -82.1241451,
    propertyStatus: null,
    activePinUrl: '',
    size: [70, 110],
  });
  const valueAndValueLabel = propertyDetails
    ? getPropertyValueAndValueLabel(
        normalizePropertyData(
          propertyDetails as PropertyLookupWithAddressRequired
        )
      )
    : { value: 0, valueLabel: '' };
  const handleReportWatchClick = () => {
    dispatch(reportEvent(dataEventNameSaveClick, 'click_recent_activity'));
  };

  return (
    <div className={theme.RecentPropertyWrapper}>
      <PulseLoader theme={theme} isLoading={status === STATUSES.LOADING}>
        <div className={theme.RecentProperty}>
          <button
            role="link"
            data-hc-name="recent-property-item"
            className={theme.RecentPropertyItem}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              dispatch(
                routeChange({
                  view: View.PROPERTY_DETAILS,
                  params: { slug },
                })
              );
              dispatch(
                reportEvent(dataEventNamePropertyClick, 'click_recent_activity')
              );
              onPropertyClick?.();
            }}
          >
            <span
              className={theme.ImageWrapper}
              data-hc-name="recent-property-image"
              style={{ backgroundImage: `url(${photoUrlFallback})` }}
            >
              {bestPhotoUrl && (
                <img
                  className={theme.Image}
                  src={bestPhotoUrl}
                  alt={`${propertyDetails?.address?.streetAddress}`}
                />
              )}
            </span>
            <span
              className={theme.Details}
              data-hc-name="recent-property-details"
            >
              <span className={theme.DetailItem}>
                {propertyDetails?.address?.streetAddress}
                {propertyDetails?.address?.unit
                  ? ` ${propertyDetails?.address.unit}`
                  : ''}
              </span>
              <span className={theme.DetailItem}>
                {propertyDetails?.livingSpace?.bedrooms?.count ?? '0'} beds
                <span className={theme.Divider}>|</span>
                {propertyDetails?.livingSpace?.bathrooms?.summaryCount} baths
                <span className={theme.Divider}>|</span>
                {propertyDetails?.livingSpace?.livingArea} sq. ft.
              </span>
              <span className={theme.ListPrice}>
                {valueAndValueLabel.valueLabel}
                {valueAndValueLabel.valueLabel ? ' ' : ''}
                {(valueAndValueLabel.value &&
                  dollarsFormatter(valueAndValueLabel.value)) ||
                  '-'}
              </span>
            </span>
          </button>
          {slug && (
            <div
              className={theme.WatchListButtonWrapper}
              data-hc-name="viewed-home-save-button"
            >
              <WatchListActionButtonContainer
                isAddedToWatchList={isAddedToWatchList}
                shouldHandleCheckingForWatchListStatus
                isInModal
                addressSlug={slug}
                address={{
                  street: propertyDetails?.address?.streetAddress || '',
                  zip: propertyDetails?.address?.zipcode || '',
                  city: propertyDetails?.address?.city || '',
                  state: propertyDetails?.address?.state || '',
                  unit: propertyDetails?.address?.unit || '',
                  address_id: propertyDetails?.address?.hcAddressId,
                  slug: propertyDetails?.address?.slug || '',
                }}
                fullAddress={propertyDetails?.address?.fullAddress || ''}
                handleReportWatchClick={handleReportWatchClick}
                theme={theme}
              />
            </div>
          )}
        </div>
      </PulseLoader>
    </div>
  );
}

type Props = {
  addressId: number;
  dataEventNameSaveClick: eventType;
  dataEventNamePropertyClick: eventType;
  onPropertyClick?: () => void;
};
export default function RecentPropertyWithGQLFetch({
  onPropertyClick,
  addressId,
  dataEventNameSaveClick,
  dataEventNamePropertyClick,
}: Props) {
  const [propertyLookupResult] = useQuery({
    query: gql<
      PropertyDetailsForRecentProperty,
      PropertyDetailsForRecentPropertyVariables
    >`
      query PropertyDetailsForRecentPropertyItem($addressId: NumericID) {
        propertyLookup(id: { addressId: $addressId }) {
          address {
            slug
            fullAddress
            hcAddressId
            streetAddress
            unit
            city
            state
            zipcode
            zipcodePlus4
          }
          county {
            name
          }
          structure {
            yearBuilt
            stories
          }
          avm(qualityMethod: CONSUMER) {
            priceMean
          }
          bestPhotos(orderBy: MLS) {
            id
            storageUrl
            prediction
            confidence
            representation {
              httpUrl
              height
              width
            }
          }
          geoLocation {
            latitude
            longitude
          }
          latestListing {
            listingOfficeName
            status
            price
          }
          listDate
          listPrice
          livingSpace {
            livingArea
            bedrooms {
              count
            }
            bathrooms {
              summaryCount
            }
          }
          mlsState
          propertyType
          paymentEstimate {
            total
          }
          mls {
            mlsID
            regulations {
              logoOverlay
              photosLogoOverlay
            }
          }
        }
      }
    `,
    variables: { addressId },
  });
  /* This will be `null` when the GQL query is loading */
  const propertyLookupData = propertyLookupResult.data;
  const property = propertyLookupData?.propertyLookup || null;

  if (!propertyLookupData) {
    return (
      <RecentProperty
        status={STATUSES.LOADING}
        propertyDetails={property}
        dataEventNameSaveClick={dataEventNameSaveClick}
        dataEventNamePropertyClick={dataEventNamePropertyClick}
      />
    );
  }

  /* If property data is invalid don't attempt to render this property card */
  if (!isPropertyDataValid(property)) {
    return null;
  }

  return (
    <RecentProperty
      status={STATUSES.SUCCESS}
      propertyDetails={property}
      onPropertyClick={onPropertyClick}
      dataEventNameSaveClick={dataEventNameSaveClick}
      dataEventNamePropertyClick={dataEventNamePropertyClick}
    />
  );
}
