import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import theme from '@client/css-modules/PDPFinanceCTA.css';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import CTARequestForm from '@client/components/CTARequestForm';
import PillButton from '@client/components/generic/PillButton';
import { useAutoSaveProperty } from '@client/hooks/auto-save-property.hooks';

type Props = {
  isStandaloneModule: boolean;
};

const PDPFinanceCTA: React.FunctionComponent<Props> = ({
  isStandaloneModule,
}) => {
  const dispatch = useDispatch();
  const isShowingFinanceCTA = useSelector(getIsFeatureEnabled('finance_cta'));
  const [showForm, setShowForm] = useState(false);
  const autoSaveProperty = useAutoSaveProperty();
  const openForm = () => {
    dispatch(reportEvent('click_finance_cta_pdp'));
    setShowForm(true);
    autoSaveProperty();
  };
  const closeForm = () => {
    setShowForm(false);
  };
  return isShowingFinanceCTA ? (
    <div
      className={classNames(theme.PDPFinanceCTA, {
        [theme.PDPFinanceCTAStandalone]: isStandaloneModule,
      })}
    >
      <div className={theme.MainContent}>
        <div role="heading" aria-level={1} className={theme.CTAHeader}>
          We can help you…
        </div>
        <div className={theme.CTABody}>Finance your next home purchase.</div>
      </div>
      <PillButton
        ariaLabel={'Start now'}
        onClick={openForm}
        className={theme.PillButton}
      >
        Start now
      </PillButton>
      <CTARequestForm isActive={showForm} handleClose={closeForm} />
    </div>
  ) : null;
};

export default PDPFinanceCTA;
