import { createSelector } from 'reselect';

import { ReduxState } from '@client/store/types/redux-state';
import { getWatchListItems } from '@client/store/selectors/watchlist.selectors';

export const getShareProperty = (state: ReduxState) => state.shareProperty;

export const getSharePropertySlug = createSelector(
  getShareProperty,
  (shareProperty) => shareProperty.slug
);

export const getSharePropertyError = createSelector(
  getShareProperty,
  (shareProperty) => shareProperty.error
);

export const getSharePropertyShouldShowNotification = createSelector(
  getShareProperty,
  (shareProperty) => shareProperty.shouldShowNotification
);

export const getIsSharedPropertyAlreadySaved = createSelector(
  getSharePropertySlug,
  getWatchListItems,
  (sharePropertySlug, watchlistItems) =>
    watchlistItems.filter((item) => item.slug === sharePropertySlug).length > 0
);
