import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import CantFindAddressResultLink from '@client/components/AutoCompleteCantFindAddressLink/CantFindAddressResultLink';
import CantFindAddressLinkChase from '@client/components/AutoCompleteCantFindAddressLink/CantFindAddressLinkChase';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import { Theme } from '@friendsofreactjs/react-css-themr';

type Props = { theme?: Theme; className?: string };

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      /* For Chase, the same component should be rendered in both the "can't find address" and "no address results" cases */
      return CantFindAddressLinkChase;
    default:
      return CantFindAddressResultLink;
  }
};

const AutoCompleteCantFindAddressLinkCobranded = (props: Props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default AutoCompleteCantFindAddressLinkCobranded;
