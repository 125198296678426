import queryString from 'query-string';
import { Component } from 'react';
import { connect } from 'react-redux';

import AppContainer from '@client/components/AppContainer';
import UrqlAppWrapper from '@client/components/UrqlAppWrapper';
import SVGUniquerContext from '@client/context/svg-uniquer';
import { RELOAD_RETRY_QUERY_KEY } from '@client/routes/constants';
import { getABTestVariants } from '@client/store/selectors/ab-tests.selectors';
import { ReduxState } from '@client/store/types/redux-state';
import { reportToSentry } from '@client/utils/error.utils';
import { ABTestsProvider, VARIANTS, getFormattedABTestsData } from '../ab-test';
import { AriaAnnouncerProvider } from './context/aria-announcer';

if (module.hot) {
  module.hot.accept();
}

type StateProps = {
  testVariants: ReturnType<typeof getABTestVariants>;
};

const MAX_ASSET_LOADING_FAILURE_RETRIES = 5;

class App extends Component<StateProps> {
  currentSVGUId = 0;

  incrementSVGUId = () => {
    this.currentSVGUId++;
    return this.currentSVGUId;
  };

  SVGUniquerContextValue = { incrementSVGUId: this.incrementSVGUId };

  /** Catch and report all React errors
   * Errors within sagas are caught and reported in `saga.utils.js`
   */
  componentDidCatch(error: Error, errorInfo: unknown) {
    const query = queryString.parse(window.location.search);
    const currentReloadCount: number =
      (query[RELOAD_RETRY_QUERY_KEY] && +query[RELOAD_RETRY_QUERY_KEY]!) || 0;

    /* Handle case where a client attempts to load a `@loadable` static asset that no longer
     * exists after a deploy due to its hash changing.  A refresh will pull in the correct assets */
    if (
      error.message &&
      error.message.toLowerCase().indexOf('loading chunk') > -1 &&
      currentReloadCount < MAX_ASSET_LOADING_FAILURE_RETRIES
    ) {
      const newQuery = queryString.stringify({
        ...query,
        [RELOAD_RETRY_QUERY_KEY]: currentReloadCount + 1,
      });
      /* Reload page to attempt JS asset loading again, incrementing reload retry count */
      window.location.search = newQuery;
    } else {
      reportToSentry(error, {
        errorInfo,
        originalError: error,
      });
      /* Re-throw to log to console */
      throw error;
    }
  }

  render() {
    return (
      <UrqlAppWrapper>
        <ABTestsProvider
          abTests={getFormattedABTestsData(this.props.testVariants)}
          defaultVariant={VARIANTS.A}
        >
          <AriaAnnouncerProvider>
            <SVGUniquerContext.Provider value={this.SVGUniquerContextValue}>
              <AppContainer />
            </SVGUniquerContext.Provider>
          </AriaAnnouncerProvider>
        </ABTestsProvider>
      </UrqlAppWrapper>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  testVariants: getABTestVariants(state),
});

export default connect(mapStateToProps)(App);
