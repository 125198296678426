import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '@client/store/actions/modals.actions';
import { getIsModalActive } from '@client/store/selectors/modals.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import theme from '@client/css-modules/ContactNewAgentButton.css';
import {
  useYourTeamAgentData,
  useYourTeamModalState,
} from '@client/hooks/your-team.hooks';
import { getIsLoggedIn } from '@client/store/selectors/auth.selectors';

import PillButton from '@client/components/generic/PillButton';
import { ensureLoggedInThenAction } from '@client/store/actions/auth.actions';

const ContactNewAgentButton = () => {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(getIsLoggedIn);
  const isModalOpen = useSelector(getIsModalActive('contact-new-agent'));
  const isYourTeamEnabled = useSelector(getIsFeatureEnabled('your_team'));
  const { introducedAgentData, pendingAgentData } = useYourTeamAgentData();
  const { openYourTeamModal } = useYourTeamModalState();

  function handleClick() {
    if (
      isYourTeamEnabled &&
      isUserLoggedIn &&
      (introducedAgentData || pendingAgentData)
    ) {
      openYourTeamModal('agent');
    } else {
      dispatch(ensureLoggedInThenAction(openModal('contact-new-agent')));
    }
  }

  return (
    <div className={theme.ContactNewAgentButtonWrapper}>
      <PillButton
        ariaLabel="Contact Agent"
        theme={theme}
        dataHcName="contact-agent-button"
        className={theme.ContactNewAgentButton}
        tertiary
        disabled={isModalOpen}
        onClick={handleClick}
        data-event-name="click_contact_agent_pdp_railcard"
        data-parent-event-name="realestate_agent_contacts"
      >
        Contact Agent
      </PillButton>
    </div>
  );
};

export default ContactNewAgentButton;
