import React from 'react';
import classNames from 'classnames';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';

import defaultTheme from '@client/css-modules/TemporaryMapMarkerIcon.css';

type Props = {
  label: string;
  addressSlug: string;
  isPulsing: boolean;
  theme: Theme;
};
/**
 * A marker icon to be passed to the Mapbox marker constructor
 * Note: this is currently only used for the `temporaryMarker` in MapsSearchClient
 */
const TemporaryMapMarkerIcon: React.FC<Props> = ({
  label,
  addressSlug,
  isPulsing,
  theme,
}) => (
  <div
    className={classNames(theme.MapMarkerWrapper, {
      [theme['MapMarkerWrapper--Pulsing']]: isPulsing,
    })}
  >
    <div className={theme.MapMarker}>
      <div className={theme.MapMarkerIcon} data-hc-slug={addressSlug} />
      <div className={theme.MapMarkerLabel}>
        <span>{label}</span>
      </div>
    </div>
  </div>
);

const ThemedTemporaryMapMarkerIcon = themr(
  'TemporaryMapMarkerIcon',
  defaultTheme
)(TemporaryMapMarkerIcon);
export default ThemedTemporaryMapMarkerIcon;
