import { View } from '@client/routes/constants';
import {
  openEmailOptInModal,
  setModalDisplayedOnClaimHomeToTrue,
  setModalDisplayedOnSavePropertyToTrue,
  setModalDisplayedOnSaveSearchToTrue,
} from '@client/store/actions/email-opt-in.actions';
import {
  CLOSE_MODAL,
  CloseModalOpenedWithModalKeyAction,
  GO_BACK_OR_DISPATCH,
  GoBackOrDispatchAction,
  HIDE_BROKERAGE_ATTRIBUTION_MODAL,
  HIDE_CANT_FIND_ADDRESS_MODAL,
  HideCantFindAddressModalAction,
  OPEN_MODAL,
  OpenModalWithModalKeyAction,
  SHOW_BROKERAGE_ATTRIBUTION_MODAL,
  SHOW_CANT_FIND_ADDRESS_MODAL,
  ShowCantFindAddressModalAction,
  goBackOrDispatch,
} from '@client/store/actions/modals.actions';
import {
  CREATE_SAVED_SEARCH_SUCCESS,
  CreateSavedSearchSuccess,
} from '@client/store/actions/saved-search.actions';
import {
  ADD_TO_WATCHLIST_SUCCESS,
  AddToWatchListSuccessAction,
} from '@client/store/actions/watchlist.actions';
import {
  ACTIVE_MODAL_URL_KEY,
  BROKERAGE_ATTRIBUTION_MODAL_KEY,
  CANT_FIND_ADDRESS_MODAL_ACTIVE_KEY,
} from '@client/store/constants';
import {
  getIsEmailOptInModalAllowedToOpen,
  getOptInLocationDisplayStats,
} from '@client/store/selectors/email-opt-in.selectors';
import { getIsInitialRoute } from '@client/store/selectors/router.selectors';
import { watchEvery } from '@client/utils/saga.utils';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import {
  getCurrentParams,
  getCurrentQuery,
  getCurrentRoute,
  getCurrentView,
} from '@src/redux-saga-router-plus/selectors';
import { omitBy } from 'lodash';
import { put, select } from 'redux-saga/effects';

/* This is only used for hiding the modal if we're on the initial route in the app (the app was loaded with
 * the modal showing).  Otherwise `handleGoBackOrDispatch` handles hiding it */
/* TODO use toggleModalWithProvidedModalKeySaga to handle opening and closing this modal */
function* toggleCantFindAddressSaga(
  action: ShowCantFindAddressModalAction | HideCantFindAddressModalAction
) {
  const currentView = yield select(getCurrentView);
  const currentParams = yield select(getCurrentParams);
  const currentQuery = yield select(getCurrentQuery);
  const isHomeownerEmptyParam =
    currentView === View.HOMEOWNER && !currentParams.slug;

  yield put(
    routeChange({
      view: currentView,
      params: isHomeownerEmptyParam ? { slug: '' } : currentParams,
      query:
        action.type === SHOW_CANT_FIND_ADDRESS_MODAL
          ? { ...currentQuery, [CANT_FIND_ADDRESS_MODAL_ACTIVE_KEY]: true }
          : omitBy(
              currentQuery,
              (value, key) => key === CANT_FIND_ADDRESS_MODAL_ACTIVE_KEY
            ),
    })
  );
}

/* TODO use toggleModalWithProvidedModalKeySaga to handle opening and closing this modal */
function* toggleBrokerageAttributionModalSaga(action) {
  const currentRoute = yield select(getCurrentRoute);
  const currentQuery = currentRoute.query;

  yield put(
    routeChange({
      ...currentRoute,
      query:
        action.type === SHOW_BROKERAGE_ATTRIBUTION_MODAL
          ? { ...currentQuery, [BROKERAGE_ATTRIBUTION_MODAL_KEY]: true }
          : omitBy(
              currentQuery,
              (value, key) => key === BROKERAGE_ATTRIBUTION_MODAL_KEY
            ),
    })
  );
}

function* toggleModalWithProvidedModalKeySaga(
  action: OpenModalWithModalKeyAction | CloseModalOpenedWithModalKeyAction
) {
  const currentRoute = yield select(getCurrentRoute);
  const currentQuery = currentRoute.query;
  const actionToDispatch = routeChange({
    ...currentRoute,
    query:
      action.type === OPEN_MODAL
        ? { ...currentQuery, [ACTIVE_MODAL_URL_KEY]: action.payload.modalKey }
        : omitBy(currentQuery, (value, key) => key === ACTIVE_MODAL_URL_KEY),
  });
  const currentlyOpenModal = currentQuery[ACTIVE_MODAL_URL_KEY];

  if (action.type === OPEN_MODAL) {
    /* Make this idempotent - attempting to open a model that's already open (a no-op) shouldn't cause
     * a route change as this could corrupt the `isInitialRoute` state property, preventing a modal
     * that's opened during app init from being closeable */
    if (currentlyOpenModal !== action.payload.modalKey) {
      yield put(actionToDispatch);
    }
  } else {
    yield put(goBackOrDispatch(actionToDispatch));
  }
}

export function* handleGoBackOrDispatch(action: GoBackOrDispatchAction) {
  const actionToDispatch = action.payload.actionIfCantGoBack;
  const isInitialRoute = (yield select(getIsInitialRoute)) as ReturnType<
    typeof getIsInitialRoute
  >;

  /* If we're on the initial page in the app's nav history (just loaded the app), dispatch the provided
   * action instead of going back */
  if (isInitialRoute) {
    yield put(actionToDispatch);
  } else {
    window.history.back();
  }
}

type EmailOptInActions = CreateSavedSearchSuccess | AddToWatchListSuccessAction;

export function* displayEmailOptInModalAfterVerification() {
  const isModalAllowedToOpen = yield select(getIsEmailOptInModalAllowedToOpen);
  if (!isModalAllowedToOpen) {
    return;
  }
  yield put(openEmailOptInModal());
  yield put(setModalDisplayedOnClaimHomeToTrue());
}

export function* displayEmailOptInModalIfNeeded(action: EmailOptInActions) {
  const isModalAllowedToOpen = yield select(getIsEmailOptInModalAllowedToOpen);
  if (!isModalAllowedToOpen) {
    return;
  }

  const isSavedSearch = action.type === CREATE_SAVED_SEARCH_SUCCESS;
  const isWatchList = action.type === ADD_TO_WATCHLIST_SUCCESS;

  /* Open the email opt-in modal only the 1st time user performing each given action. */
  const placesShown = yield select(getOptInLocationDisplayStats);
  const shouldOpenModal =
    (isSavedSearch && !placesShown.saveSearch) ||
    (isWatchList && !placesShown.saveProperty);

  if (shouldOpenModal) {
    yield put(openEmailOptInModal());
  }

  /* The modal displays the first time any three different action types are performed,
   * so we need to track and set on state which ones have displayed already */
  if (isSavedSearch) {
    yield put(setModalDisplayedOnSaveSearchToTrue());
  } else if (isWatchList) {
    yield put(setModalDisplayedOnSavePropertyToTrue());
  }
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [SHOW_CANT_FIND_ADDRESS_MODAL]: toggleCantFindAddressSaga,
    [HIDE_CANT_FIND_ADDRESS_MODAL]: toggleCantFindAddressSaga,
    [GO_BACK_OR_DISPATCH]: handleGoBackOrDispatch,
    [SHOW_BROKERAGE_ATTRIBUTION_MODAL]: toggleBrokerageAttributionModalSaga,
    [HIDE_BROKERAGE_ATTRIBUTION_MODAL]: toggleBrokerageAttributionModalSaga,
    [OPEN_MODAL]: toggleModalWithProvidedModalKeySaga,
    [CLOSE_MODAL]: toggleModalWithProvidedModalKeySaga,
    [CREATE_SAVED_SEARCH_SUCCESS]: displayEmailOptInModalIfNeeded,
    [ADD_TO_WATCHLIST_SUCCESS]: displayEmailOptInModalIfNeeded,
  });
};
