import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import pluralize from 'pluralize';
import React from 'react';
import { connect } from 'react-redux';

import { View } from '@client/routes/constants';
import { getHomeownerPropertyDataForChartTooltip } from '@client/store/selectors/homeowner.selectors';
import { getPropertyDataForAvmDeepDiveCurrentPropertyTooltip } from '@client/store/selectors/property-details.selectors';
import {
  DEFAULT_BATH_DATA_LABEL,
  DEFAULT_BED_DATA_LABEL,
  getLabelPropertyData,
  numbersWithPlaceholder,
  placeholderFormatter,
} from '@client/utils/string.utils';

import VerticalSeparator from '@client/components/generic/VerticalSeparator';
import defaultTheme from '@client/css-modules/AvmDeepDiveCurrentPropertyTooltip.css';
import AvmFactorHouse from '@client/inline-svgs/avm-factor-house';

const mapStateToProps = (state) => {
  const isHomeownerPage =
    getCurrentView(state) === View.HOMEOWNER_PROPERTY_DETAILS;
  return {
    propertyData: isHomeownerPage
      ? getHomeownerPropertyDataForChartTooltip(state)
      : getPropertyDataForAvmDeepDiveCurrentPropertyTooltip(state),
  };
};

type PropertyData = {
  beds?: number | null;
  baths?: number | null;
  city?: string | null;
  daysOnMarket: number | null;
  sqFeet?: number | null;
  streetAddress?: string | null;
  state?: string | null;
  unit?: string | null;
  zipcode?: string | null;
};

type Props = {
  propertyData: PropertyData;
};

const AvmDeepDiveCurrentPropertyTooltip = ({ propertyData }: Props) => {
  const {
    beds,
    baths,
    city,
    daysOnMarket,
    sqFeet,
    streetAddress,
    state,
    unit,
    zipcode,
  } = propertyData;

  return (
    <React.Fragment>
      <div className={defaultTheme.HeadingSection}>
        <AvmFactorHouse className={defaultTheme.icon} />
        <div className={defaultTheme.Heading}>This home</div>
      </div>
      <div className={defaultTheme.PropertyDataContainer}>
        <div className={defaultTheme.Address}>
          <div>
            {streetAddress}, {unit}
          </div>
          <div>
            {city}, {state} {zipcode}
          </div>
        </div>
        <div className={defaultTheme.PropertyData}>
          <div>
            {placeholderFormatter(beds)}{' '}
            {getLabelPropertyData(beds, DEFAULT_BED_DATA_LABEL)}
          </div>
          <VerticalSeparator theme={defaultTheme} />
          <div>
            {placeholderFormatter(baths)}{' '}
            {getLabelPropertyData(baths, DEFAULT_BATH_DATA_LABEL)}
          </div>
          <VerticalSeparator theme={defaultTheme} />
          <div>{numbersWithPlaceholder(sqFeet, '-')} Sq Ft</div>
        </div>
        {daysOnMarket && (
          <div className={defaultTheme.DaysOnMarket}>
            {daysOnMarket} {pluralize('Day', daysOnMarket)} on Market
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, () => ({}))(
  AvmDeepDiveCurrentPropertyTooltip
);
