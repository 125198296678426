import {
  getPreviousParams,
  getPreviousQuery,
} from '@src/redux-saga-router-plus/selectors';
import { differenceInDays } from 'date-fns';
import { get, groupBy, uniqBy, values } from 'lodash';
import { createSelector } from 'reselect';

import {
  FORECAST_CHART_TYPES,
  NEIGHBORHOOD_INSIGHT_API_KEYS,
  NEIGHBORHOOD_INSIGHT_TYPES,
  NEIGHBORHOOD_INSIGHT_TYPES_OPTIONS,
  PROPERTY_STATUSES,
  PROPERTY_TYPES,
  RegressionType,
  SCHOOL_TYPES,
  STATUSES,
} from '@client/store/constants';
import {
  MAP_MARKER_OFF_MARKET_TEXT_COLOR,
  MARKER_IMAGE_IDS,
} from '@client/store/map-constants';
import { Property } from '@client/store/sagas/queries/types';
import { getIsLoggedIn } from '@client/store/selectors/auth.selectors';
import {
  getHideForecastBlockPdp,
  getIsPhotoLoginUpsell,
} from '@client/store/selectors/enabled-features.selectors';
import { getActivePDPSlug } from '@client/store/selectors/router.selectors';
import { AnalyticsEventAddress } from '@client/store/types/analytics';
import {
  LatitudeLongitudeObject,
  MultiUnitDataObject,
} from '@client/store/types/maps';
import {
  CompWithAddressAndGeoRequired,
  isFullPropertyLookupData,
  isInitPropertyLookupData,
  PartialCompSummary,
  SchoolsByLevel,
  TransferEventType,
} from '@client/store/types/property';
import { ReferralPropertyData } from '@client/store/types/property-contact-form';
import { ReduxState } from '@client/store/types/redux-state';
import {
  AvmFactorWithLocation,
  PropertyValuesWithRegressionTypes,
} from '@client/store/types/regression-data';
import { formatDate } from '@client/utils/date.utils';
import { titleFormatter } from '@client/utils/formatter.utils';
import {
  buildMultiUnitClusterMarker,
  buildMultiUnitDataObject,
  buildPropertyMarker,
  buildSubjectMarker,
  getMultiUnitClusterKey,
} from '@client/utils/maps.utils';
import {
  filterSaleHistory,
  getAnalyticsAddressForProperty,
  getDisplayTypeForTransferType,
  getIsActiveListing,
  getPropertyListingStatus,
  getPropertyListingStatusObjectForProperty,
  getPropertyPrice,
  isPropertyDataValid,
  normalizePropertyData,
} from '@client/utils/property.utils';
import {
  abbrNumberFormatter,
  dollarsWithPlaceholder,
  numbersWithPlaceholder,
  placeholderFormatter,
} from '@client/utils/string.utils';
import { getGlobalMLSLastRefreshedDateTime } from './mls-global-display-rules.selectors';

const INITIAL_SALES_TO_DISPLAY = 6;

type SubjectMarkerProperty = Partial<Property> & {
  isHomeMarker: true;
};
type ClusterMarker = {
  multiUnitClusterLocation: LatitudeLongitudeObject;
  multiUnitCount: number;
  childAddressSlugs: string[];
};
type CompMarkerProperty =
  | CompWithAddressAndGeoRequired
  | SubjectMarkerProperty
  | ClusterMarker;
const isSubjectMarker = (property): property is SubjectMarkerProperty =>
  (property as SubjectMarkerProperty).isHomeMarker;
const isClusterMarker = (property): property is ClusterMarker =>
  !!(property as ClusterMarker).multiUnitClusterLocation;

export function getPropertyDetailsState(state: ReduxState) {
  return state.propertyDetails;
}

export const getPropertyDataCache = createSelector(
  getPropertyDetailsState,
  (propertyDetailsState) => propertyDetailsState.propertyDataCache
);

export const getActivePDPProperty = createSelector(
  getActivePDPSlug,
  getPropertyDataCache,
  (activeSlug, dataCache) => (activeSlug ? dataCache[activeSlug] : null)
);

/* Note that all selectors in this file should be able to function when this selector returns null */
export const getActivePDPPropertyData = createSelector(
  getActivePDPProperty,
  (activePDPProperty) => (activePDPProperty ? activePDPProperty.data : null)
);

/* The active PDP property can have data in 3 cases:
 * - a search pin or card is clicked and data is transferred from the marker/card to PDP state for
 *   quicker PDP display. In this case the PDP 'init' and 'full' data statuses are both 'INIT'
 * - the PDP 'init' request completes
 * - the PDP 'full' request completes
 */
export const getActivePDPPropertyHasData = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => !!activePDPPropertyData
);

export const getAvmPriceMean = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    return activePDPPropertyData?.avm?.priceMean || null;
  }
);

export const getPropertyYearBuilt = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    return activePDPPropertyData?.structure?.yearBuilt || null;
  }
);

export const getPropertyGeneralDetails = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    const {
      livingSpace,
      site,
      structure,
      propertyType,
      association,
      latestAssessment,
    } = activePDPPropertyData || {};
    const bathrooms = livingSpace?.bathrooms?.summaryCount || null;
    const yearBuilt = structure?.yearBuilt || null;
    /* Only supporting a single association fee for now */
    const associationFee = (association?.fees || [])[0];
    const associationNotes = associationFee?.notes || null;
    /* Format values, always returning `null` for values that don't exist */
    let formattedValues = {
      'Living Area': livingSpace?.livingArea
        ? `${numbersWithPlaceholder(livingSpace.livingArea)} Sq.Ft.`
        : null,
      'Lot Size': site?.areaDescription || null,
      'Total Rooms':
        (livingSpace?.numberOfRooms || 0) > 0
          ? livingSpace?.numberOfRooms || null
          : null,
      Bedrooms:
        livingSpace?.bedrooms?.count || (null as string | number | null),
      /* Passing as string to avoid component lib converting this float to an int */
      Bathrooms: bathrooms ? bathrooms : null,
      Stories: structure?.stories || null,
      'Year Built': yearBuilt ? yearBuilt.toString() : null,
      'Property Type': propertyType
        ? PROPERTY_TYPES[propertyType] ??
          titleFormatter(propertyType.toLowerCase())
        : null,
      Zoning: site?.zoning?.code || null,
      'HOA Name': association?.name || null,
      'HOA Fee':
        associationFee?.frequency && associationFee?.amount
          ? `${dollarsWithPlaceholder(
              associationFee.amount
            )} ${associationFee.frequency.toLowerCase()}`
          : null,
      'HOA Includes':
        associationNotes && associationNotes.length > 0
          ? associationNotes
          : null,
      'Tax Year': latestAssessment?.taxSummary?.year || null,
      'Tax Amount': latestAssessment?.taxSummary?.amount
        ? `${dollarsWithPlaceholder(latestAssessment.taxSummary.amount)}`
        : null,
      'Property in Flood Zone':
        activePDPPropertyData && isFullPropertyLookupData(activePDPPropertyData)
          ? activePDPPropertyData.floodZone?.floodRiskDisplay || null
          : null,
    };
    /* Add placeholder formatting for null values */
    for (let key in formattedValues) {
      /* The bedrooms field is unique in that we want to display "0" when present */
      if (key === 'Bedrooms') {
        formattedValues[key] =
          formattedValues[key] === 0
            ? 0
            : placeholderFormatter(formattedValues[key]);
      } else {
        formattedValues[key] = placeholderFormatter(formattedValues[key]);
      }
    }
    return formattedValues;
  }
);

export const getLatestPropertyTaxFees = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    return activePDPPropertyData?.latestAssessment?.taxSummary?.amount || null;
  }
);

export const getPropertyHOAFees = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    const { association } = activePDPPropertyData || {};
    const associationFee = (association?.fees || [])[0];
    if (associationFee && associationFee.frequency === 'MONTHLY') {
      return associationFee.amount;
    } else {
      return null;
    }
  }
);

export const getPropertyLotSize = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    return activePDPPropertyData?.site?.areaDescription || null;
  }
);

export const getNeighborhoodInsightsChartType = createSelector(
  getPropertyDetailsState,
  (propertyDetailsState) => {
    const defaultNeighborhoodInsightsChartType =
      NEIGHBORHOOD_INSIGHT_TYPES_OPTIONS[0];
    return (
      propertyDetailsState.selectedNeighborhoodInsightsChartType ||
      defaultNeighborhoodInsightsChartType
    );
  }
);

/** This selector is used to get the subject value for the
 * neighborhood insights charts - depending on the chart type
 * selected on UI */
export const getSubjectValueForNeighborhoodChartType = createSelector(
  getActivePDPPropertyData,
  getAvmPriceMean,
  getNeighborhoodInsightsChartType,
  (activePDPPropertyData, currentValue, chartType) => {
    const { livingSpace, structure } = activePDPPropertyData || {};

    const livingArea = get(livingSpace, 'livingArea', null);
    switch (chartType) {
      case NEIGHBORHOOD_INSIGHT_TYPES.BUILDINGAREA:
        return livingArea;
      case NEIGHBORHOOD_INSIGHT_TYPES.VALUEPERSQFT:
        return livingArea && currentValue ? currentValue / livingArea : null;
      case NEIGHBORHOOD_INSIGHT_TYPES.AGE:
        // age is current year - yearBuilt
        const currYear = new Date().getFullYear();
        const yearBuilt = get(structure, 'yearBuilt', null);
        return yearBuilt ? currYear - yearBuilt : null;
      case NEIGHBORHOOD_INSIGHT_TYPES.BEDS:
        return livingSpace?.bedrooms?.count || null;
      case NEIGHBORHOOD_INSIGHT_TYPES.BATHS:
        return livingSpace?.bathrooms?.summaryCount || null;
    }
  }
);

/** this selector is used to get the neighborhood chart
 * data in - used to show the chart in the pop over */
export const getNeighborhoodInsightsChartData = createSelector(
  getActivePDPPropertyData,
  getNeighborhoodInsightsChartType,
  (activePDPPropertyData, chartType) => {
    /** get does not return the default value when the value of hpi is null
     * this it to be covered for that case
     */
    switch (chartType) {
      case NEIGHBORHOOD_INSIGHT_TYPES.BUILDINGAREA:
        return (
          get(
            activePDPPropertyData,
            ['block', 'histograms', 'buildingArea'],
            []
          ) || []
        );
      case NEIGHBORHOOD_INSIGHT_TYPES.VALUEPERSQFT:
        return (
          get(
            activePDPPropertyData,
            ['block', 'histograms', 'valuePerSqft'],
            []
          ) || []
        );
      case NEIGHBORHOOD_INSIGHT_TYPES.AGE:
        return (
          get(activePDPPropertyData, ['block', 'histograms', 'age'], []) || []
        );
      case NEIGHBORHOOD_INSIGHT_TYPES.BEDS:
        return (
          get(activePDPPropertyData, ['block', 'histograms', 'beds'], []) || []
        );
      case NEIGHBORHOOD_INSIGHT_TYPES.BATHS:
        return (
          get(activePDPPropertyData, ['block', 'histograms', 'baths'], []) || []
        );
    }
  }
);

export const getForecastChartType = createSelector(
  getPropertyDetailsState,
  getHideForecastBlockPdp,
  (propertyDetailsState, hideForecastBlockType) => {
    if (hideForecastBlockType) {
      return FORECAST_CHART_TYPES.ZIP;
    }
    const defaultForecastChartType = FORECAST_CHART_TYPES.BLOCK;
    return (
      propertyDetailsState.selectedForecastChartType || defaultForecastChartType
    );
  }
);

/** this selector is used to calculate the forecast chart data in
 * forecast-chart container - used to show the chart in the pop over */
export const getForecastChartData = createSelector(
  getActivePDPPropertyData,
  getForecastChartType,
  (activePDPPropertyData, chartType) => {
    if (
      activePDPPropertyData &&
      isFullPropertyLookupData(activePDPPropertyData)
    ) {
      if (chartType === FORECAST_CHART_TYPES.ZIP) {
        /** get does not return the default value when the value of hpi is null
         * this it to be covered for that case
         */
        return activePDPPropertyData.zip?.hpi || [];
      } else {
        return activePDPPropertyData.block?.valueTs || [];
      }
    } else {
      return [];
    }
  }
);

/** This is block forecast data one year, used for breakout sections overview */
export const getBlockForecastSummary = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    if (!activePDPPropertyData) {
      return null;
    }
    return (
      isInitPropertyLookupData(activePDPPropertyData)
        ? activePDPPropertyData.block?.valueTsForecastSummary || []
        : []
    )[0];
  }
);

/** This is zip forecast data for 1 year, used for breakout sections overview instead of getBlockForecastSummary if hide_forecast_block_pdp is true */
export const getZip1YearForecastSummary = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    if (!activePDPPropertyData) {
      return null;
    }
    return (
      isInitPropertyLookupData(activePDPPropertyData)
        ? activePDPPropertyData.zip?.hpiForecastSummary || []
        : []
    )[0];
  }
);

/** This is zip forecast data for 3 years, used for breakout sections overview */
export const getZip3YearForecastSummary = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    if (!activePDPPropertyData) {
      return null;
    }
    return (
      isInitPropertyLookupData(activePDPPropertyData)
        ? activePDPPropertyData.zip?.hpiForecastSummary || []
        : []
    )[2];
  }
);

type ValidForecastSummaryItem = {
  year: number;
  value: number;
  percent: number;
};
const isValidForecastSummaryItem = (item: {
  year: number | null;
  value: number | null;
  percent: number | null;
}): item is ValidForecastSummaryItem => {
  return !!(item.year && item.value && item.percent);
};

export const getForecastChartCalloutData = createSelector(
  getActivePDPPropertyData,
  getForecastChartType,
  (activePDPPropertyData, chartType) => {
    if (!activePDPPropertyData) {
      return [];
    }
    if (chartType === FORECAST_CHART_TYPES.ZIP) {
      return isInitPropertyLookupData(activePDPPropertyData) &&
        activePDPPropertyData.zip?.hpiForecastSummary
        ? activePDPPropertyData.zip.hpiForecastSummary.filter(
            isValidForecastSummaryItem
          ) || []
        : [];
    } else {
      return isInitPropertyLookupData(activePDPPropertyData) &&
        activePDPPropertyData.block?.valueTsForecastSummary
        ? activePDPPropertyData.block.valueTsForecastSummary.filter(
            isValidForecastSummaryItem
          ) || []
        : [];
    }
  }
);

export const getHasDataForForecastChart = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    if (!activePDPPropertyData) {
      return false;
    }
    const blockData = isFullPropertyLookupData(activePDPPropertyData)
      ? activePDPPropertyData?.block?.valueTs || []
      : [];
    const zipData = isFullPropertyLookupData(activePDPPropertyData)
      ? activePDPPropertyData?.zip?.hpi || []
      : [];
    return blockData.length > 0 || zipData.length > 0;
  }
);

export const getPropertyDetailsInitStatus = createSelector(
  getActivePDPProperty,
  (activePDPProperty) => activePDPProperty?.initDetailsStatus || STATUSES.INIT
);

export const getPropertyDetailsFullStatus = createSelector(
  getActivePDPProperty,
  (activePDPProperty) => activePDPProperty?.fullDetailsStatus || STATUSES.INIT
);

export const getPropertyListingStatusObject = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) =>
    activePDPPropertyData &&
    getPropertyListingStatusObjectForProperty(activePDPPropertyData)
);

export const getPropertyListingStatusObjectForAddressSlug = (addressSlug) =>
  createSelector(getPropertyDataCache, (propertyDataCache) => {
    const property = propertyDataCache[addressSlug];
    return (
      property &&
      property.data &&
      getPropertyListingStatusObjectForProperty(property.data)
    );
  });

export const getPropertyAddress = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => activePDPPropertyData?.address || null
);

export const getPropertyAddressSlug = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => activePDPPropertyData?.address?.slug || null
);

export const getActivePDPPropertyListingStatus = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) =>
    activePDPPropertyData
      ? getPropertyListingStatus(activePDPPropertyData)
      : null
);

export const getPropertyIsActiveStatusType = createSelector(
  getActivePDPPropertyListingStatus,
  (activePDPPropertyStatus) => {
    return getIsActiveListing(activePDPPropertyStatus);
  }
);

export const getPropertyLocation = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => activePDPPropertyData?.geoLocation || null
);

export const getPropertyAgentInfo = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    return {
      agentName: activePDPPropertyData?.latestListing?.agentName || null,
      email: activePDPPropertyData?.latestListing?.agentEmail || null,
      phone: activePDPPropertyData?.latestListing?.agentPhone || null,
      officeName:
        activePDPPropertyData?.latestListing?.listingOfficeName || null,
      licenseNumber: activePDPPropertyData?.latestListing?.agentLicense || null,
      buyerBrokerageCompensationDisplay:
        activePDPPropertyData?.latestListing
          ?.buyerBrokerageCompensationDisplay || null,
    };
  }
);

export const getCounty = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => activePDPPropertyData?.county?.name || null
);

export const getPropertyListingOfficeName = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) =>
    activePDPPropertyData?.latestListing?.listingOfficeName || null
);

export const getCrimeRate = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) =>
    activePDPPropertyData?.block?.crime?.all?.countyPercentile || null
);

export const getRentalAvm = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => activePDPPropertyData?.rentalAvm?.priceMean || null
);

export const getSchoolsByLevel = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    const rawSchools = activePDPPropertyData?.schools;

    if (rawSchools) {
      let schoolsByLevels: SchoolsByLevel = {
        [SCHOOL_TYPES.ELEMENTARY]: [],
        [SCHOOL_TYPES.MIDDLE]: [],
        [SCHOOL_TYPES.HIGH]: [],
      };

      rawSchools
        .sort((a, b) => (a.distanceMiles || 0) - (b.distanceMiles || 0))
        .forEach((school) => {
          if (school.levels) {
            school.levels.forEach((level) => {
              schoolsByLevels[level].push(school);
            });
          }
        });
      return schoolsByLevels;
    } else {
      return null;
    }
  }
);

export const getBestSchool = createSelector(getSchoolsByLevel, (schools) => {
  let bestSchool;
  if (schools) {
    for (let schoolLevel in schools) {
      // Must duplicate array so you don't sort the original
      const topSchool = [...schools[schoolLevel]].sort(
        (a, b) => b.score - a.score
      )[0];
      if (
        !bestSchool ||
        (topSchool && bestSchool && topSchool.score > bestSchool.score)
      ) {
        bestSchool = topSchool;
      }
    }
  }
  return bestSchool;
});

export const getAvmFactors = createSelector(
  getActivePDPProperty,
  (activePDPProperty) => activePDPProperty?.avmFactors || null
);

export const getAvmFactorsStatus = createSelector(
  getActivePDPProperty,
  (activePDPProperty) => activePDPProperty?.avmStatus || null
);

export const getIsAvmFactorsLoading = createSelector(
  getAvmFactorsStatus,
  (avmFactorsStatus) => avmFactorsStatus === STATUSES.INIT
);

const locationAvmFactor = {
  defaultDescription:
    'Use the map below to explore pricing trends in the area.',
  description: null,
  label: 'Location',
  type: 'LocationAvmFactor',
  value: null,
};
/**
 * currently locationAcmFactor is not enabled on property graph this selector must be
 * removed when the code related to locationAcmFactor is enabled.
 * For regressions Location is default and is always displayed, we use avmFactors to
 * decide which regressions we want to show, sometimes we have regressions data for
 * the property but the values aren't significant so we choose to not show.
 */
export const getAvmFactorsWithLocation = createSelector(
  getIsAvmFactorsLoading,
  getAvmFactors,
  (state, showLocationInDataDeepDives) => showLocationInDataDeepDives,
  (isAvmFactorsLoading, avmFactors, showLocationInDataDeepDives) => {
    let avmFactorsWithLocation: AvmFactorWithLocation[] = [];
    if (!isAvmFactorsLoading) {
      // default value for avmFactors is null
      if (avmFactors && avmFactors.length >= 1) {
        avmFactors.forEach((item) => {
          avmFactorsWithLocation.push({
            description: item.description,
            accessibleDescription: item.accessibleDescription,
            label: item.label,
            type: item.type,
            value: item.value,
          });
        });
      }

      if (showLocationInDataDeepDives) {
        avmFactorsWithLocation.unshift(locationAvmFactor);
      }
    }

    return avmFactorsWithLocation;
  }
);

export const getRegressionsData = createSelector(
  getIsAvmFactorsLoading,
  getActivePDPProperty,
  (state, showLocationInDataDeepDives) => showLocationInDataDeepDives,
  (isAvmFactorsLoading, activePDPProperty, showLocationInDataDeepDives) => {
    let regressionsData =
      activePDPProperty?.avmDeepDiveData?.regressionsData || [];
    if (!isAvmFactorsLoading) {
      /**
       * RegressionsData from backend is empty array or null but we have median block price heatmaps data,
       * adding in location to show in data deep dives in this case.
       */
      if (regressionsData.length === 0 && showLocationInDataDeepDives) {
        regressionsData.unshift({
          ...locationAvmFactor,
          type: RegressionType.LOCATION,
        });
      }
      /**
       * RegressionsData from backend has Location but we do not have median block price heatmaps data,
       * hence not showing location in data deep dives.
       */
      if (!showLocationInDataDeepDives) {
        regressionsData =
          regressionsData.filter(
            (item) => item.type.toUpperCase() !== RegressionType.LOCATION
          ) || [];
      }
    }
    return regressionsData;
  }
);

export const getDefaultDeepDivesFactorToLaunchWith = createSelector(
  getAvmFactors,
  getAvmFactorsWithLocation,
  (state, showLocationInDataDeepDives) => showLocationInDataDeepDives,
  (avmFactors, avmFactorsWithLocation, showLocationInDataDeepDives) => {
    if (showLocationInDataDeepDives) {
      return avmFactorsWithLocation && avmFactorsWithLocation.length >= 1
        ? avmFactorsWithLocation[0].label
        : null;
    } else {
      return avmFactors && avmFactors.length >= 1 ? avmFactors[0].label : null;
    }
  }
);

/**
 * tractStats count is total number of properties used in the regression check
 */
export const getTractStatsCount = createSelector(
  getActivePDPProperty,
  (activePDPProperty) =>
    activePDPProperty?.avmDeepDiveData?.tractStatsCount || null
);

export const getAvmDeepDiveDataStatus = createSelector(
  getActivePDPProperty,
  (activePDPProperty) => activePDPProperty?.avmDataDeepDiveStatus || null
);

export const getIsLoadingAvmDeepDiveData = createSelector(
  getAvmDeepDiveDataStatus,
  (status) => status === STATUSES.INIT
);

export const getPropertyValuesWithRegressionTypes = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    const { livingSpace, site, structure } = activePDPPropertyData || {};
    const currYear = new Date().getFullYear();
    const yearBuilt = structure?.yearBuilt || null;
    const ageOfProperty = yearBuilt ? currYear - yearBuilt : null;

    return {
      [RegressionType.LIVINGAREA]: livingSpace?.livingArea || null,
      [RegressionType.LOTSIZE]: site?.area || null,
      [RegressionType.BEDROOMS]: livingSpace?.bedrooms?.count || null,
      [RegressionType.BATHROOMS]: livingSpace?.bathrooms?.summaryCount || null,
      [RegressionType.AGE]: ageOfProperty,
      /* TODO: We shouldn't need this assertion here. This selector's return type and
       * PropertyValuesWithRegressionTypes appear to match, but can't resolve the error otherwise */
    } as PropertyValuesWithRegressionTypes;
  }
);

export const getAvmValue = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => activePDPPropertyData?.avm?.priceMean || null
);

export const getAvmFSD = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => activePDPPropertyData?.avm?.fsd || null
);

export const getPropertyBuildingId = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => activePDPPropertyData?.hcBuildingId
);

export const getPropertyZipcode = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => activePDPPropertyData?.address?.zipcode || null
);

export const getPropertyTractIdFromPropertyDetails = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => activePDPPropertyData?.block?.tractId || null
);

export const getPropertyBasicInfo = createSelector(
  getActivePDPPropertyData,
  getPropertyIsActiveStatusType,
  (activePDPPropertyData, isActiveListing) => {
    if (!activePDPPropertyData) {
      return null;
    }
    const { address, livingSpace, listDate, propertyType } =
      activePDPPropertyData;
    return {
      address: address || null,
      baths: livingSpace?.bathrooms?.summaryCount || null,
      beds: livingSpace?.bedrooms?.count || null,
      sqFeet: livingSpace?.livingArea || null,
      propertyType: propertyType
        ? PROPERTY_TYPES[propertyType] ??
          titleFormatter(propertyType.toLowerCase())
        : null,
      daysOnMarket:
        isActiveListing && listDate
          ? differenceInDays(new Date(), new Date(listDate))
          : null,
    };
  }
);

export const getPropertyDataForAvmDeepDiveCurrentPropertyTooltip =
  createSelector(
    getActivePDPPropertyData,
    getPropertyIsActiveStatusType,
    (activePDPPropertyData, isActiveListing) => {
      const { address, livingSpace, listDate } = activePDPPropertyData || {};
      const { city, streetAddress, state, zipcode } = address || {};
      return {
        streetAddress,
        city,
        state,
        zip: zipcode,
        baths: get(livingSpace, ['bathrooms', 'summaryCount']),
        beds: get(livingSpace, ['bedrooms', 'count']),
        sqFeet: get(livingSpace, 'livingArea'),
        daysOnMarket:
          isActiveListing && listDate
            ? differenceInDays(new Date(), new Date(listDate))
            : null,
      };
    }
  );

export const getPropertyFullAddress = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => activePDPPropertyData?.address?.fullAddress || null
);

/* Address data to be sent with analytics events within the PDP */
export const getPropertyAnalyticsEventAddress = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData): AnalyticsEventAddress | null => {
    return getAnalyticsAddressForProperty(
      activePDPPropertyData?.address || null
    );
  }
);

export const getPropertyMapMarkerFeature = createSelector(
  getPropertyAddressSlug,
  getPropertyLocation,
  getAvmPriceMean,
  getPropertyBuildingId,
  getPropertyIsActiveStatusType,
  getActivePDPPropertyData,
  (
    slug,
    location,
    value,
    buildingId,
    isActiveListing,
    activePDPPropertyData
  ) => {
    const marker =
      location &&
      slug &&
      location.latitude &&
      location.longitude &&
      activePDPPropertyData &&
      buildSubjectMarker({
        label: null,
        labelColor: null,
        addressSlug: slug,
        latitude: location.latitude,
        longitude: location.longitude,
        imageId: isActiveListing
          ? MARKER_IMAGE_IDS.SUBJECT
          : MARKER_IMAGE_IDS.SUBJECT_OFF_MARKET,
        buildingId: buildingId || null,
        normalizedPropertyData: normalizePropertyData(activePDPPropertyData),
      });
    return marker
      ? {
          type: 'Feature' as 'Feature',
          properties: marker,
          geometry: {
            type: 'Point' as 'Point',
            coordinates: [marker.lng, marker.lat] as [number, number],
          },
        }
      : null;
  }
);

export const getPropertyDetailsNormalized = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) =>
    activePDPPropertyData && normalizePropertyData(activePDPPropertyData)
);

export const getPropertyListingNotes = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) =>
    activePDPPropertyData?.latestListing?.publicRemarks || null
);

export const getPropertyCounty = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => activePDPPropertyData?.county || null
);

export const getPropertyIsInWatchList = createSelector(
  getActivePDPProperty,
  (activePDPProperty) => activePDPProperty?.isInWatchList || false
);

export const getShouldDisplayAgentInfo = createSelector(
  getPropertyIsActiveStatusType,
  getPropertyAgentInfo,
  (isActive, agentContact) => {
    /* Return true if active listing and any property in `agentContact` is populated */
    return (
      isActive && Object.keys(agentContact).some((key) => agentContact[key])
    );
  }
);

export const getSalesHistory = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    return filterSaleHistory(activePDPPropertyData?.transfers || []).map(
      (transfer) => ({
        ...transfer,
        /**
         * filterSaleHistory returns ONLY purchase, distressed, and foreclosure event type
         * type casting here since we are using Maybe<TransferEventType> for graphQL calls
         */
        displayType: getDisplayTypeForTransferType(
          transfer.eventType as TransferEventType
        ),
      })
    );
  }
);

export const getSaleHistoryTopSix = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    const saleHistory =
      activePDPPropertyData &&
      activePDPPropertyData.transfers &&
      filterSaleHistory(activePDPPropertyData.transfers);

    if (saleHistory && saleHistory.length > INITIAL_SALES_TO_DISPLAY) {
      return saleHistory.slice(0, INITIAL_SALES_TO_DISPLAY);
    }
    return saleHistory;
  }
);

export const getSaleHistoryRest = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    const saleHistory =
      activePDPPropertyData &&
      activePDPPropertyData.transfers &&
      filterSaleHistory(activePDPPropertyData.transfers);

    if (saleHistory && saleHistory.length > INITIAL_SALES_TO_DISPLAY) {
      return saleHistory.slice(INITIAL_SALES_TO_DISPLAY, saleHistory.length);
    }
    return null;
  }
);

export const getShouldDisplayMoreSales = createSelector(
  getActivePDPProperty,
  (activePDPProperty) => activePDPProperty?.shouldDisplayMoreSales || false
);

export const getRentalAvmValues = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    return activePDPPropertyData &&
      isFullPropertyLookupData(activePDPPropertyData)
      ? {
          priceUpper: activePDPPropertyData?.rentalAvm?.priceUpper || 0,
          priceLower: activePDPPropertyData?.rentalAvm?.priceLower || 0,
          priceMean: activePDPPropertyData?.rentalAvm?.priceMean || 0,
          fsd: activePDPPropertyData?.rentalAvm?.fsd || 0,
          quality: activePDPPropertyData?.rentalAvm?.quality || null,
        }
      : {
          priceUpper: null,
          priceLower: null,
          priceMean: activePDPPropertyData?.rentalAvm?.priceMean || 0,
          fsd: null,
          quality: null,
        };
  }
);

export const getRentalYield = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) =>
    activePDPPropertyData
      ? isFullPropertyLookupData(activePDPPropertyData)
        ? activePDPPropertyData.rentalYield
        : null
      : null
);

export const getCrimePopupState = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    if (!activePDPPropertyData) {
      return null;
    } else {
      return {
        all: {
          nationPercentile: isFullPropertyLookupData(activePDPPropertyData)
            ? activePDPPropertyData.block?.crime?.all?.nationPercentile || null
            : null,
          countyPercentile:
            activePDPPropertyData.block?.crime?.all?.countyPercentile || null,
        },
        property: {
          nationPercentile: isFullPropertyLookupData(activePDPPropertyData)
            ? activePDPPropertyData.block?.crime?.property?.nationPercentile ||
              null
            : null,
          countyPercentile: isFullPropertyLookupData(activePDPPropertyData)
            ? activePDPPropertyData.block?.crime?.property?.countyPercentile ||
              null
            : null,
        },
        violent: {
          nationPercentile: isFullPropertyLookupData(activePDPPropertyData)
            ? activePDPPropertyData.block?.crime?.violent?.nationPercentile ||
              null
            : null,
          countyPercentile: isFullPropertyLookupData(activePDPPropertyData)
            ? activePDPPropertyData.block?.crime?.violent?.countyPercentile ||
              null
            : null,
        },
      };
    }
  }
);

export const getIsCrimeDataMissing = createSelector(
  getCrimeRate,
  getCounty,
  (crimeRate, county) => !crimeRate || !county
);

export const getAllCrime = createSelector(getCrimePopupState, (crimeState) =>
  crimeState ? crimeState.all : null
);

export const getPropertyCrime = createSelector(
  getCrimePopupState,
  (crimeState) => (crimeState ? crimeState.property : null)
);

export const getViolentCrime = createSelector(
  getCrimePopupState,
  (crimeState) => (crimeState ? crimeState.violent : null)
);

export const getSelectedCrimeType = createSelector(
  getPropertyDetailsState,
  (propDetailsState) => propDetailsState.selectedCrime
);

export const getElementarySchools = createSelector(
  getSchoolsByLevel,
  (schoolsState) => (schoolsState ? schoolsState[SCHOOL_TYPES.ELEMENTARY] : [])
);

export const getClosestElementarySchoolName = createSelector(
  getSchoolsByLevel,
  (schoolsState) => {
    const school =
      schoolsState &&
      schoolsState[SCHOOL_TYPES.ELEMENTARY]
        .slice(0)
        .sort((a, b) => (a.distanceMiles || 0) - (b.distanceMiles || 0))[0];

    return (school && school.name) || null;
  }
);

export const getMiddleSchools = createSelector(
  getSchoolsByLevel,
  (schoolsState) => (schoolsState ? schoolsState[SCHOOL_TYPES.MIDDLE] : [])
);

export const getHighSchools = createSelector(
  getSchoolsByLevel,
  (schoolsState) => (schoolsState ? schoolsState[SCHOOL_TYPES.HIGH] : [])
);

export const getSelectedSchoolType = createSelector(
  getPropertyDetailsState,
  (propDetailsState) => propDetailsState && propDetailsState.selectedSchoolType
);

export const getComps = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    const rawComps = activePDPPropertyData?.comps || [];
    const filteredComps = rawComps.filter(
      (comp) => isPropertyDataValid(comp) && comp?.avm?.priceMean
    ) as CompWithAddressAndGeoRequired[];
    return uniqBy(filteredComps, (comp) => comp.address.slug);
  }
);

export const getCompsValueRange = createSelector(getComps, (comps) => {
  const pricesInAscendingOrder = comps
    .map((comp) => comp?.avm?.priceMean || null)
    .sort((a, b) => (a && b ? a - b : 0))
    .filter((val) => val) as number[];
  return pricesInAscendingOrder.length >= 2
    ? [pricesInAscendingOrder[0], pricesInAscendingOrder.slice(-1)[0]]
    : null;
});

export const getNormalizedCompsData = createSelector(getComps, (comps) =>
  comps.map((comp) => normalizePropertyData(comp, comp.summary || undefined))
);

/** Get comps properties and subject property grouped by a latLng identifier */
export const getCompsPropertiesGroupedByLatLng = createSelector(
  getComps,
  getActivePDPPropertyData,
  (comps, activePDPPropertyData) => {
    if (!activePDPPropertyData) {
      return null;
    }
    const subjectProperty = {
      ...activePDPPropertyData,
      isHomeMarker: true as true,
    };
    const properties = [...comps, subjectProperty];
    return groupBy(properties, (property) => {
      const { latitude, longitude } = property?.geoLocation || {};
      return latitude && longitude
        ? getMultiUnitClusterKey({ latitude, longitude })
        : null;
    });
  }
);

/** Get map markers for comps modal (including the subject property), with popup data */
export const getCompsModalMapMarkerFeatures = createSelector(
  getCompsPropertiesGroupedByLatLng,
  getPropertyIsActiveStatusType,
  (propertiesGroupedByLatLng, isSubjectActiveListing) => {
    if (!propertiesGroupedByLatLng) {
      return [];
    }
    let propertiesIncludingClusters: CompMarkerProperty[] = [];
    /* Collect multi-unit properties in an object keyed by latLng */
    Object.keys(propertiesGroupedByLatLng).forEach((latLng) => {
      if (
        propertiesGroupedByLatLng[latLng].length > 1 &&
        propertiesGroupedByLatLng[latLng][0]['geoLocation']['latitude'] &&
        propertiesGroupedByLatLng[latLng][0]['geoLocation']['longitude']
      ) {
        propertiesIncludingClusters.push({
          multiUnitClusterLocation: {
            latitude:
              propertiesGroupedByLatLng[latLng][0]['geoLocation']['latitude']!,
            longitude:
              propertiesGroupedByLatLng[latLng][0]['geoLocation']['longitude']!,
          },
          multiUnitCount: propertiesGroupedByLatLng[latLng].length,
          childAddressSlugs: propertiesGroupedByLatLng[latLng]
            .map((item) => item?.address?.slug)
            .filter((item) => item) as string[],
        });
      } else {
        propertiesIncludingClusters.push(
          propertiesGroupedByLatLng[latLng][0] as CompWithAddressAndGeoRequired
        );
      }
    });

    return values(propertiesIncludingClusters)
      .filter(
        (item) => isClusterMarker(item) || (item.address && item.address.slug)
      )
      .map((item) => {
        const marker = isClusterMarker(item)
          ? buildMultiUnitClusterMarker({
              /* All comps are supposed to be off-market so hardcoding the multi-unit marker color */
              imageId: MARKER_IMAGE_IDS.OFF_MARKET,
              labelColor: MAP_MARKER_OFF_MARKET_TEXT_COLOR,
              latitude: item.multiUnitClusterLocation.latitude,
              longitude: item.multiUnitClusterLocation.longitude,
              label: `${item.multiUnitCount} Units`,
              propertyCount: item.multiUnitCount,
              childAddressSlugs: item.childAddressSlugs,
            })
          : isSubjectMarker(item)
          ? buildSubjectMarker({
              addressSlug: item.address!.slug!,
              latitude: item.geoLocation?.latitude || null,
              longitude: item.geoLocation?.longitude || null,
              label: null,
              labelColor: MAP_MARKER_OFF_MARKET_TEXT_COLOR,
              imageId: isSubjectActiveListing
                ? MARKER_IMAGE_IDS.SUBJECT
                : MARKER_IMAGE_IDS.SUBJECT_OFF_MARKET,
              buildingId: null,
            })
          : buildPropertyMarker({
              addressSlug: item.address.slug!,
              latitude: item.geoLocation?.latitude || null,
              longitude: item.geoLocation?.longitude || null,
              label: abbrNumberFormatter(getPropertyPrice(item)),
              labelColor: MAP_MARKER_OFF_MARKET_TEXT_COLOR,
              imageId: MARKER_IMAGE_IDS.OFF_MARKET,
              buildingId: null,
              normalizedPropertyData: normalizePropertyData(
                item,
                item.summary || undefined
              ),
            });

        return marker
          ? {
              type: 'Feature',
              properties: marker,
              geometry: {
                type: 'Point',
                coordinates: [marker.lng, marker.lat],
              },
            }
          : null;
      });
  }
);

/** Get all multi-unit comps properties, mapped by latLng identifier */
export const getCompsMultiUnitPropertiesByLatLng = createSelector(
  getCompsPropertiesGroupedByLatLng,
  getActivePDPPropertyData,
  (propertiesGroupedByLatLng, activePDPPropertyData) => {
    let multiUnitMarkersByLatLng: {
      [latLng: string]: NonNullable<MultiUnitDataObject>[];
    } = {};

    if (!propertiesGroupedByLatLng || !activePDPPropertyData) {
      return {};
    }

    /* Collect multi-unit properties in an object keyed by latLng to set on state */
    Object.keys(propertiesGroupedByLatLng).forEach((latLng) => {
      if (propertiesGroupedByLatLng[latLng].length > 1) {
        multiUnitMarkersByLatLng[latLng] = propertiesGroupedByLatLng[latLng]
          .map((item) =>
            buildMultiUnitDataObject({
              addressSlug: item.address.slug!,
              latitude: item.geoLocation?.latitude || null,
              longitude: item.geoLocation?.longitude || null,
              /* We don't want this object's `normalizedPropertyData` to override the property cache data
               * for the subject property */
              shouldNotBeSetOnState: isSubjectMarker(item),
              normalizedPropertyData: isSubjectMarker(item)
                ? {
                    ...normalizePropertyData(activePDPPropertyData),
                    unit: `${get(activePDPPropertyData, [
                      'address',
                      'unit',
                    ])} (Subject Property)`,
                  }
                : normalizePropertyData(
                    item as CompWithAddressAndGeoRequired,
                    ((item as CompWithAddressAndGeoRequired)
                      .summary as PartialCompSummary) || undefined
                  ),
            })
          )
          .filter((val) => val) as NonNullable<MultiUnitDataObject>[];
      }
    });
    return multiUnitMarkersByLatLng;
  }
);

export const getPropertyCityState = createSelector(
  getPropertyAddress,
  (address) => `${get(address, ['city'])} ${get(address, ['state'])}`
);

export const getMedianValues = createSelector(
  getActivePDPProperty,
  (activePDPProperty) => activePDPProperty?.medianValues || null
);

export const getBlockHistograms = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => activePDPPropertyData?.block?.histograms || null
);

export const getNeighborhoodInsightOptions = createSelector(
  getBlockHistograms,
  (histogramData) => {
    return NEIGHBORHOOD_INSIGHT_TYPES_OPTIONS.filter((option) => {
      const dataPoint =
        (histogramData &&
          histogramData[NEIGHBORHOOD_INSIGHT_API_KEYS[option.type]]) ||
        [];
      return dataPoint.length > 0;
    });
  }
);

export const getHomePriceForMortgageCalculator = createSelector(
  getPropertyDetailsNormalized,
  (normalizedPropertyData) => {
    const listPrice = normalizedPropertyData?.listPrice ?? null;
    const avm = normalizedPropertyData?.avm ?? null;

    if (listPrice) {
      return listPrice;
    } else if (avm) {
      return avm;
    } else {
      return null;
    }
  }
);

export const getSchoolsSummary = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => activePDPPropertyData?.schoolsSummary || null
);

export const getLoanTerms = createSelector(
  getPropertyDetailsState,
  (propertyDetails) => get(propertyDetails, 'loanTerms')
);

export const getMortgageCalculationDetails = createSelector(
  getActivePDPProperty,
  (activePDPDetails) =>
    activePDPDetails?.mortgageCalculations ?? {
      mortgageId: null,
      interestRate: null,
      downPayment: null,
      downPaymentPct: null,
      homePrice: null,
      monthlyPayment: null,
      insurance: null,
    }
);

export const getMortgageSummary = createSelector(
  getActivePDPProperty,
  (activePDPDetails) => activePDPDetails?.mortgageSummary || null
);

export const getLoanTermsForDropdown = createSelector(
  getLoanTerms,
  (loanTerms) =>
    loanTerms.map((loanTerm) => {
      return { value: loanTerm.id, label: loanTerm.label.replace('-', ' ') };
    })
);

export const getMortgageCalculationDetailsStatus = createSelector(
  getActivePDPProperty,
  (activePDPDetails) =>
    activePDPDetails?.mortgageCalculationStatus ?? STATUSES.INIT
);

export const getNearbyListings = createSelector(
  getActivePDPPropertyData,
  (activePDPData) => {
    const listings =
      activePDPData && isFullPropertyLookupData(activePDPData)
        ? activePDPData.nearbyListings
        : null;
    return listings
      ? listings
          .filter(isPropertyDataValid)
          .map((listing) =>
            normalizePropertyData(
              listing as CompWithAddressAndGeoRequired,
              ((listing as CompWithAddressAndGeoRequired)
                .summary as PartialCompSummary) || undefined
            )
          )
      : [];
  }
);

export const getPropertyParcelGeoJSON = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) =>
    activePDPPropertyData && isFullPropertyLookupData(activePDPPropertyData)
      ? activePDPPropertyData?.parcel?.geometry
      : null
);

/* The ID of the property within the MLS */
export const getPropertyIdInMLS = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) =>
    activePDPPropertyData?.latestListing?.listingID ?? null
);

/* ID of the MLS that the property belongs to */
export const getMlsIdOfPropertyMls = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => activePDPPropertyData?.mls?.mlsID || null
);

export const getPropertyMlsFullName = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => activePDPPropertyData?.mls?.name || null
);

export const getPropertyMlsAbbrevName = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => activePDPPropertyData?.mls?.abbreviation || null
);

export const getPropertyMlsRegulations = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => activePDPPropertyData?.mls?.regulations || null
);

export const getPropertyMLSLogoOverlayPhoto = createSelector(
  getPropertyMlsRegulations,
  (mlsRegulations) =>
    mlsRegulations?.photosLogoOverlay
      ? mlsRegulations.logoOverlay || null
      : null
);

export const getPropertyMlsCopyrightStatement = createSelector(
  getPropertyMlsRegulations,
  (mlsRegulations) => mlsRegulations?.copyrightStatement || null
);

export const getPropertyMlsDisclaimer = createSelector(
  getPropertyMlsRegulations,
  (mlsRegulations) => mlsRegulations?.disclaimer || null
);

export const getPropertyMlsLogoUrl = createSelector(
  getPropertyMlsRegulations,
  (mlsRegulations) => mlsRegulations?.logo || null
);

export const getPropertyIsShowingPhotosLoginUpsell = createSelector(
  getPropertyMlsRegulations,
  getIsLoggedIn,
  getActivePDPPropertyListingStatus,
  getIsPhotoLoginUpsell,
  (mlsRegulations, isLoggedIn, listingStatus, isPhotoLoginUpsell) => {
    return !!(
      isPhotoLoginUpsell &&
      mlsRegulations?.photosClosedLogin &&
      (listingStatus === PROPERTY_STATUSES.SOLD ||
        listingStatus === PROPERTY_STATUSES.CLOSED) &&
      !isLoggedIn
    );
  }
);

export const getPropertyMlsLastRefreshedDateTime = createSelector(
  getActivePDPPropertyData,
  getGlobalMLSLastRefreshedDateTime,
  (activePDPPropertyData, globalMLSLastRefreshedDateTime) => {
    /* fallback to mls info fetched for SRP if not on pdp page */
    const timestamp =
      activePDPPropertyData?.mls?.lastRefreshed ||
      globalMLSLastRefreshedDateTime;
    return timestamp && formatDate(timestamp, 'MM/DD/YYYY [at] h[:]mma');
  }
);

export const getPropertyOpenHouseList = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    const openHouseList = activePDPPropertyData?.latestListing?.openHouse ?? [];
    return openHouseList.reduce((accum, openHouse) => {
      if (openHouse) {
        const { start, end, date } = openHouse;
        /**
         * Showing open house list containing date, start time, end time on UI
         */
        if (start && end && date) {
          if (Array.isArray(accum[date])) {
            accum[date].push({ ...openHouse });
          } else {
            accum[date] = [{ ...openHouse }];
          }
        }
      }
      return accum;
    }, {});
  }
);

export const getMonthlyPaymentEstimateForProperty = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => {
    if (!activePDPPropertyData) {
      return null;
    }
    return isFullPropertyLookupData(activePDPPropertyData)
      ? {
          associationFee:
            activePDPPropertyData.paymentEstimate?.associationFee || null,
          pmi: activePDPPropertyData.paymentEstimate?.pmi || null,
          loan: activePDPPropertyData.paymentEstimate?.loan || null,
          tax: activePDPPropertyData.paymentEstimate?.tax || null,
          total: activePDPPropertyData.paymentEstimate?.total || null,
        }
      : {
          associationFee: null,
          pmi: null,
          loan: null,
          tax: null,
          total: activePDPPropertyData.paymentEstimate?.total || null,
        };
  }
);

/* For auto fill the form in the find-agent-buyer page */
export const getPreviousPropertyData = createSelector(
  getPropertyDataCache,
  getPreviousParams,
  getPreviousQuery,
  (propertyDataCache, previousParams, previousQuery) => {
    const previousSlug = previousParams?.slug ?? previousQuery?.slug;
    return propertyDataCache[previousSlug]?.data;
  }
);

export const getPreviousPropertyAddress = createSelector(
  getPreviousPropertyData,
  (previousPropertyData) => {
    return getAnalyticsAddressForProperty(
      previousPropertyData?.address || null
    );
  }
);

export const getSelectedPropertyForReferBuyerApi = (
  addressSlug: string | null
) =>
  createSelector(
    getPropertyDataCache,
    (dataCache): ReferralPropertyData | null => {
      const property = addressSlug ? dataCache[addressSlug] : null;
      if (!property) {
        return null;
      }
      const data = property.data;
      const bestPhoto = data?.bestPhotos && data.bestPhotos[0];
      return {
        baths: data?.livingSpace?.bathrooms?.summaryCount || null,
        beds: data?.livingSpace?.bedrooms?.count || null,
        city: data?.address.city || null,
        state: data?.address.state || null,
        maxPrice: data?.listPrice ?? null,
        address: data?.address?.streetAddress ?? null,
        unit: data?.address?.unit ?? null,
        sqFt: data?.livingSpace?.livingArea ?? null,
        photo: bestPhoto?.representation?.httpUrl,
        mlsLogoOverlay: data?.mls?.regulations?.logoOverlay ?? null,
        zip: data?.address?.zipcode ?? null,
      };
    }
  );

export const getPropertyPageShouldShowHeatmapsWhenTogglingMapView =
  createSelector(
    getPropertyDetailsState,
    (propertyDetailsState) =>
      propertyDetailsState.shouldShowHeatmapsWhenTogglingMapView
  );
