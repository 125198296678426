/**
 * Set the webpack public path for React assets client-side.  During local development, client/server
 * React asset URLs should match, preventing React server/client HTML mismatch warnings.
 *
 * Note that the server and client URLs for SSR'd React assets will never match on remote servers
 * due to this client public path being dynamic per cobrand and the server asset paths being baked
 * into the server webpack bundle.  This doesn't adversely affect anything and React server/client
 * HTML mismatch warnings are disabled for the production build.
 */
const ASSET_PREFIX =
  process.env.ASSET_PREFIX && process.env.ASSET_PREFIX.length
    ? process.env.ASSET_PREFIX
    : process.env.NODE_ENV === 'production'
    ? '/static/'
    : '/';
try {
  let siteId = window.location.hostname.split('.', 1)[0];
  if (siteId === 'wwwcf') {
    // TEMP: support Cloudflare POC
    __webpack_public_path__ = ASSET_PREFIX.replace(
      /\/\/assets/,
      '//' + siteId + '.assetscf'
    );
  } else {
    __webpack_public_path__ = ASSET_PREFIX.replace(/\/\//, '//' + siteId + '.');
  }
} catch (e) {
  console.log('Could not determine site id, using default', e);
  __webpack_public_path__ = ASSET_PREFIX;
}
