import classNames from 'classnames';
import React from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import defaultTheme from '@client/css-modules/HorizontalSelectorTabs.css';
import * as Tabs from '@radix-ui/react-tabs';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type ValueType = number | string;
type Props = {
  /* Current selected value */
  value: ValueType;
  /** Selector items to render */
  options: { label: string; value: any }[];
  /* Divider to appear between options */
  OptionDivider?: React.ComponentType;
  /** Function to call when any selector item is clicked */
  onSelect: (value: ValueType) => void;
  theme: Theme;
  /* For screen readers, a label denoting what the group is controlling */
  ariaLabelledBy?: string;
  /* For screen readers, an id denoting what the control is controlling */
  ariaControls?: string;
  /* For screen readers, an id that can be used to associate the control with something else */
  id?: string;
  ['data-event-name']?: string;
  ['data-parent-event-name']?: string;
  getDataEventName?: (value: string) => string;
};

/**
 * A simple radio button style control allowing only a single option to be selected at once
 */
function HorizontalSelectorTabs({
  options,
  onSelect,
  value,
  theme,
  OptionDivider,
  ariaLabelledBy,
  ariaControls,
  id,
  ['data-event-name']: dataEventName,
  ['data-parent-event-name']: dataParentEventName,
  getDataEventName,
}: Props) {
  const { activeOptionUnderlineColor } = useCobrandStyles();
  return (
    <Tabs.Root
      value={value as string}
      onValueChange={(valueToChange: string) =>
        onSelect(
          options.find((option) => option.label === valueToChange)?.value
        )
      }
    >
      <Tabs.List
        id={id}
        className={classNames(theme.HorizontalSelectorTabs, {
          [theme.HorizontalSelectorWithDefaultDivider]: !OptionDivider,
        })}
        aria-labelledby={ariaLabelledBy}
      >
        {options.map(({ value: optionValue, label }, i) => (
          <Tabs.Trigger
            value={label}
            data-event-name={
              getDataEventName ? getDataEventName(optionValue) : dataEventName
            }
            data-parent-event-name={dataParentEventName}
            tabIndex={0}
            key={optionValue}
            className={theme.Option}
            onKeyDown={onEnterOrSpaceKey(() => onSelect(optionValue))}
            aria-controls={ariaControls}
            aria-label={label}
            aria-selected={value === optionValue}
          >
            <div className={theme.OptionLabelBlock}>
              <div className={theme.OptionLabelText}>{label}</div>
              <div
                className={theme.SelectedIndicator}
                style={
                  value === optionValue
                    ? { background: activeOptionUnderlineColor }
                    : {}
                }
              ></div>
            </div>
            {OptionDivider && i !== options.length - 1 && (
              <div className={theme.OptionDividerWrapper}>
                <>{OptionDivider}</>
              </div>
            )}
          </Tabs.Trigger>
        ))}
      </Tabs.List>
    </Tabs.Root>
  );
}

export default themr(
  'HorizontalSelectorTabsThemed',
  defaultTheme
)(HorizontalSelectorTabs);
