import { FETCH_TEST_PROPERTIES_SUCCESS } from '@client/store/actions/test-properties.actions';
import { STATUSES, Status } from '@client/store/constants';
import { Action } from '@client/store/actions';
import { TestProperty } from '@client/store/types/test-properties-page';

const INITIAL_STATE: TestPropertiesState = {
  status: STATUSES.INIT,
  properties: [],
};

export type TestPropertiesState = {
  status: Status;
  properties: TestProperty[];
};

export default function testPropertiesReducer(
  state = INITIAL_STATE,
  action: Action
): TestPropertiesState {
  switch (action.type) {
    case FETCH_TEST_PROPERTIES_SUCCESS:
      return {
        ...state,
        status: STATUSES.SUCCESS,
        properties: action.payload.data,
      };
    default:
      return state;
  }
}
