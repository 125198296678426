import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import ComeHomeLogoVariableSizeImg from '@client/components/ComeHomeLogoVariableSizeImg';
import HCLogoWithHouseCanaryTextImg from '@client/components/HCLogoWithHouseCanaryTextImg';
import { COBRAND_IDS } from '@client/store/types/cobranding';

type Props = {
  className?: string;
};

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.WWW:
      return HCLogoWithHouseCanaryTextImg;
    default:
      return ComeHomeLogoVariableSizeImg;
  }
};

const BrokerageAttributionLogo: React.FC<Props> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default BrokerageAttributionLogo;
