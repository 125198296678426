import { Action } from '@client/store/actions';
import {
  CLEAR_ACTIVE_NOTIFICATION,
  SET_ACTIVE_NOTIFICATION,
  SET_IS_SHOWING_ALTERNATE_YOUR_TEAM_TRIGGER_BUTTON,
  SET_MODAL_PORTAL_KEYS,
} from '@client/store/actions/modals.actions';
import {
  ModalKeyToRenderInPortal,
  NotificationKey,
} from '@client/store/constants';

export type ModalsState = {
  modalKeysToRenderInPortals: ModalKeyToRenderInPortal[] | null;
  activeNotification: NotificationKey | null;
  isShowingAlternateTriggerButton: boolean;
};

export const INITIAL_STATE: ModalsState = {
  modalKeysToRenderInPortals: null,
  activeNotification: null,
  isShowingAlternateTriggerButton: false,
};

export default function modalsReducer(
  state = INITIAL_STATE,
  action: Action
): ModalsState {
  switch (action.type) {
    case SET_MODAL_PORTAL_KEYS:
      return {
        ...state,
        modalKeysToRenderInPortals: action.payload.keys,
      };
    case SET_ACTIVE_NOTIFICATION:
      return {
        ...state,
        activeNotification: action.payload.activeNotification,
      };
    case CLEAR_ACTIVE_NOTIFICATION:
      return {
        ...state,
        activeNotification: null,
      };
    case SET_IS_SHOWING_ALTERNATE_YOUR_TEAM_TRIGGER_BUTTON:
      return {
        ...state,
        isShowingAlternateTriggerButton: action.payload,
      };
    default:
      return state;
  }
}
