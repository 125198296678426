import { useDispatch, useSelector } from 'react-redux';

import {
  getPropertyDetailsNormalized,
  getPropertyIsInWatchList,
} from '@client/store/selectors/property-details.selectors';
import { addToWatchList } from '@client/store/actions/watchlist.actions';
import { getIsLoggedIn } from '@client/store/selectors/auth.selectors';
import { WatchListPropertyData } from '@client/store/types/watchlist';

/**
 * this hook should be run on PDP since getPropertyIsInWatchList depends on VIEWS.PROPERTY_DETAILS
 */
export function useAutoSaveProperty() {
  const dispatch = useDispatch();
  const propertyDetails = useSelector(getPropertyDetailsNormalized);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const slug = propertyDetails?.slug;
  const isPropertyAlreadySaved = useSelector(getPropertyIsInWatchList);

  return (): void => {
    if (isLoggedIn && slug && !isPropertyAlreadySaved) {
      const watchlistAddress: WatchListPropertyData = {
        slug: propertyDetails.slug,
        street: propertyDetails.streetAddress,
        city: propertyDetails.city,
        state: propertyDetails.state,
        zip: propertyDetails.zipcode,
        unit: propertyDetails.unit,
        address_id: propertyDetails.hcAddressId,
      };

      dispatch(addToWatchList(watchlistAddress));
    }
  };
}
