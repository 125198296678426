import {
  ClaimedHomes,
  HomeownerResponseArray,
} from '@client/store/types/homeowner';
import { dollarsFormatterWithSign } from '@client/utils/string.utils';
/**
 * Given an API response for homeowner claimed homes, return an object to be set
 * on homeowner state. To be used both during SSR and client-side loading of homeowner
 */
export const parseHomeownerResponseArrayToObject = (
  claimedHomesArr: HomeownerResponseArray,
  claimedHomesInState?: ClaimedHomes
) => {
  let newData = claimedHomesArr.reduce((acc, cur) => {
    acc[cur.slug] = cur;
    return acc;
  }, {});

  /* Homeowner response doesn't include equity data, so we need to merge any existing equity data or it will be lost */
  if (claimedHomesInState) {
    for (let home in claimedHomesInState) {
      const currentHome = claimedHomesInState[home];
      if (currentHome.equity && !!newData[home]) {
        newData[home].equity = currentHome.equity;
      }
    }
  }

  return newData;
};

export const reformatAddress = (address) => ({
  street: address?.streetAddress,
  unit: address?.unit,
  city: address?.city,
  addrState: address?.state,
  zip: address?.zipcode,
});

export const dollarsFormatterWithSignAndPlaceHolder = (
  data: number,
  defaultValue?: any
) => {
  if (data > 0 || data < 0) {
    return dollarsFormatterWithSign(data);
  } else {
    return defaultValue || '';
  }
};
