import AvmFactorHouse from '@client/inline-svgs/avm-factor-house';
import ChaseAvmFactorHouse from '@client/inline-svgs/cobrand/chase/chase-avm-factor-house';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return ChaseAvmFactorHouse;
    default:
      return AvmFactorHouse;
  }
};

const LivingAreaAvmFactorCobranded: React.FC = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default LivingAreaAvmFactorCobranded;
