import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import loadable from '@loadable/component';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import scrollIntoView from 'scroll-into-view';

import defaultTheme from '@client/css-modules/BreakoutSections.css';
import NullStateIcon from '@client/inline-svgs/null-state';
import { ModalKey } from '@client/store/constants';
import { Comp } from '@client/store/sagas/queries/types';
import {
  MortgageCalculationsForUIComponents,
  MortgageSummaryCallParams,
} from '@client/store/types/property';
import { capitalize } from '@client/utils/string.utils';
import Comps from './Comps';
import Crime from './Crime';
import Forecast from './Forecast';
import LocalActivitiesModal from './LocalActivitiesModal';
import MortgageCalculator from './MortgageCalculator';
import NeighborhoodInsightsSection from './NeighborhoodInsights';
import RentalAvm from './RentalAvm';
import SaleHistory from './SaleHistory';
import Schools from './Schools';

type Props = {
  activePropertySlug: string | null;
  avmPriceMean: number | null;
  activeModalKey: ModalKey;
  bestSchool: {
    name: string;
    score: number;
    distanceMiles: number;
  };
  comps: Comp[];
  customBreakoutSectionOrder: string[] | null;
  fetchMortgageSummary: ({
    activePropertySlug,
    userChosenHomePrice,
    userChosenInterestRate,
    userChosenLoanTerm,
    userChosenDownpaymentPct,
  }: MortgageSummaryCallParams) => void;
  handleOpenModal: (sectionKey: ModalKey) => void;
  handleCloseModal: () => void;
  hasHistoValuePerSqFt: boolean;
  hasHistoBuildingArea: boolean;
  isSpecialUserTypesRestricted: boolean;
  isCrimeDataMissing: boolean;
  insightTextData: {
    buildingArea: {
      high: number | null;
      low: number | null;
    };
    valuePerSqFt: {
      high: number | null;
      low: number | null;
    };
  };
  mortgageCalculationDetails: MortgageCalculationsForUIComponents;
  mortgageSummary: string | null;
  rentalAVM: number | null;
  resetActivitiesFilter: () => void;
  shouldDisplayNeighborhoodInsights: boolean;
  saleHistory: {
    displayType: string | null;
    transferDate?: string | null;
    transferPrice?: number | null;
  }[];
  shouldScrollModalsIntoView: boolean;
  shouldDisplayLocalActivitiesForCobrand: boolean;
  shouldDisplayMortgageCalculator: boolean;
  useSlideInModals: boolean;
  style?: React.CSSProperties;
  theme: Theme;
};

type State = {
  activeSectionLeftPositioning: number | null;
};

const { previewSectionMinHeight, previewSectionMarginBottom } = defaultTheme;
/* Length at which the null state items list becomes a bulleted list */
const NULL_LIST_BULLETED_AT_LENGTH = 5;

const BreakoutSectionDialog = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ '@client/components/BreakoutSectionDialog'
    )
);
const CrimeContainer = loadable(
  () => import(/* webpackPrefetch: true */ '@client/containers/crime.container')
);
const SchoolsContainer = loadable(
  () =>
    import(/* webpackPrefetch: true */ '@client/containers/schools.container')
);
const SaleHistoryContainer = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ '@client/containers/sale-history.container'
    )
);
const RentalAvmContainer = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ '@client/containers/rental-avm.container'
    )
);
const ForecastChartContainer = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ '@client/containers/forecast-chart.container'
    )
);
const CompsContainer = loadable(
  () => import(/* webpackPrefetch: true */ '@client/containers/comps.container')
);
const LocalActivitiesContainer = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ '@client/containers/local-activities.container'
    )
);
const MortgageCalculatorContainer = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ '@client/containers/mortgage-calculator.container'
    )
);
const NeighborhoodInsights = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ '@client/components/NeighborhoodInsights'
    )
);

/* For these modals, use a custom dialog that displays above a particular preview section
 * column on desktop */
const USE_BREAKOUT_SECTION_DIALOG_FOR_MODALS: ModalKey[] = [
  'crime',
  'forecast',
  'mortgage_calculator',
  'neighborhood_insights',
  'rental_avm',
  'sale_history',
  'schools',
];

const PREVENT_BODY_SCROLLING_FOR_MODAL_KEY: ModalKey[] = [
  /* For the schools and forecast dialogs, preventing body scrolling is handled within
   * the component itself */
  'crime',
  'mortgage_calculator',
  'neighborhood_insights',
  'rental_avm',
  'sale_history',
];

const SECTION_MODALS_BY_KEY: { [key in ModalKey]?: JSX.Element } = {
  crime: <CrimeContainer />,
  schools: <SchoolsContainer />,
  rental_avm: <RentalAvmContainer />,
  mortgage_calculator: <MortgageCalculatorContainer />,
  neighborhood_insights: <NeighborhoodInsights />,
  forecast: <ForecastChartContainer />,
  sale_history: <SaleHistoryContainer />,
};

const SECTION_TITLES_BY_KEY: { [key in ModalKey]?: string } = {
  comps: 'Comparable Properties',
  crime: 'Crime Reports',
  forecast: 'Forecast',
  'local-activities-modal': 'Activities and Shopping',
  mortgage_calculator: 'Mortgage Calculator',
  neighborhood_insights: 'Neighborhood Insights',
  rental_avm: 'Rental Estimate',
  sale_history: 'Sale History',
  schools: 'Schools',
};

/**
 * Property info display that provides preview info in a list and detailed breakout
 * popovers on click
 */
class BreakoutSections extends Component<Props, State> {
  state: State = {
    activeSectionLeftPositioning: null,
  };

  breakoutDialogFirstColumn = null;
  breakoutDialogSecondColumn = null;

  componentDidMount() {
    const { activePropertySlug, fetchMortgageSummary, mortgageSummary } =
      this.props;
    if (activePropertySlug && !mortgageSummary) {
      fetchMortgageSummary({ activePropertySlug });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      activePropertySlug,
      fetchMortgageSummary,
      mortgageCalculationDetails,
    } = this.props;
    const { downPaymentPct, interestRate, homePrice, mortgageId } =
      mortgageCalculationDetails;
    if (
      activePropertySlug &&
      !isEqual(prevProps.mortgageCalculationDetails, mortgageCalculationDetails)
    ) {
      fetchMortgageSummary({
        activePropertySlug,
        userChosenHomePrice: homePrice,
        userChosenInterestRate: interestRate,
        userChosenLoanTerm: mortgageId,
        userChosenDownpaymentPct: downPaymentPct,
      });
    }
  }

  openSectionModal = (sectionKey: ModalKey) => {
    /* We know that this component's rendered element can't be of type Text */
    const thisNode = findDOMNode(this) as Element | null;
    if (!thisNode) {
      throw new Error('Node cannot be found in BreakoutSections component');
    }
    const previewSection = Array.from(
      thisNode.querySelectorAll('[data-section-key]')
    ).find((node) => node.getAttribute('data-section-key') === sectionKey);
    const { left } = previewSection
      ? previewSection.getBoundingClientRect()
      : { left: null };
    const { left: parentLeft } = thisNode.getBoundingClientRect();

    this.setState({
      /* Passed into the dialog component to ensure that the dialog appears horizontally
       * directly above its corresponding preview section */
      activeSectionLeftPositioning: (left || 0) - parentLeft,
    });
    this.props.handleOpenModal(sectionKey);
  };

  closeSectionModal = () => {
    this.props.handleCloseModal();
  };

  openCompsModal = () => {
    this.props.handleOpenModal('comps');
  };

  openActivitiesModal = () => {
    this.props.handleOpenModal('local-activities-modal');
    this.props.resetActivitiesFilter();
  };

  scrollDialogIntoView = (ele: HTMLDivElement | null, callback: () => void) => {
    scrollIntoView(
      findDOMNode(ele),
      {
        align: {
          top: 0,
          topOffset: 20,
        },
        time: 500,
      },
      /* setbreakoutSectionDialogMaxHeight as soon as scrollIntoView is finished */
      callback
    );
  };

  /**
   * Return a mapping of section keys to whether the section should indicate a null state
   * @return {object}
   */
  getIsNullStateForSectionKey = (): { [key in ModalKey]?: boolean } => {
    const {
      avmPriceMean,
      bestSchool,
      comps,
      isCrimeDataMissing,
      rentalAVM,
      saleHistory,
      shouldDisplayNeighborhoodInsights,
      shouldDisplayMortgageCalculator,
    } = this.props;

    const nullStates: { [key in ModalKey]?: boolean } = {
      comps: !Array.isArray(comps) || !comps.length,
      crime: isCrimeDataMissing,
      forecast: !avmPriceMean,
      neighborhood_insights: !shouldDisplayNeighborhoodInsights,
      rental_avm: !rentalAVM,
      sale_history: !Array.isArray(saleHistory) || !saleHistory.length,
      schools: !bestSchool,
      mortgage_calculator: !shouldDisplayMortgageCalculator,
    };

    return nullStates;
  };

  render() {
    const {
      isSpecialUserTypesRestricted,
      insightTextData,
      hasHistoValuePerSqFt,
      hasHistoBuildingArea,
      shouldScrollModalsIntoView,
      useSlideInModals,
      activeModalKey,
      shouldDisplayLocalActivitiesForCobrand,
      shouldDisplayNeighborhoodInsights,
      theme,
      style,
      customBreakoutSectionOrder,
    } = this.props;

    const { activeSectionLeftPositioning } = this.state;

    const isNullStateForSectionKey = this.getIsNullStateForSectionKey();
    const nullStateSectionKeys = (
      Object.keys(isNullStateForSectionKey) as ModalKey[]
    ).filter((sectionKey) => isNullStateForSectionKey[sectionKey]);
    const numRows = 5;

    const BreakoutSectionComponents = {
      mortgage_calculator: (
        <MortgageCalculator
          openSectionModal={this.openSectionModal}
          key="mortgage_calculator"
        />
      ),
      crime: (
        <Crime
          theme={theme}
          openSectionModal={this.openSectionModal}
          key="crime"
        />
      ),
      schools: (
        <Schools
          openSectionModal={this.openSectionModal}
          theme={theme}
          key="schools"
        />
      ),
      rental_avm: (
        <RentalAvm openSectionModal={this.openSectionModal} key="rental_avm" />
      ),
      neighborhood_insights: (
        <NeighborhoodInsightsSection
          theme={theme}
          openSectionModal={this.openSectionModal}
          shouldDisplayNeighborhoodInsights={shouldDisplayNeighborhoodInsights}
          hasHistoBuildingArea={hasHistoBuildingArea}
          hasHistoValuePerSqFt={hasHistoValuePerSqFt}
          insightTextData={insightTextData}
          key="neighborhood_insights"
        />
      ),
      forecast: (
        <Forecast
          theme={theme}
          openSectionModal={this.openSectionModal}
          key="forecast"
        />
      ),
      sale_history: (
        <SaleHistory
          theme={theme}
          openSectionModal={this.openSectionModal}
          key="sale_history"
        />
      ),
      comps: <Comps key="comps" />,
      local_activities_modal: (
        <LocalActivitiesModal key="local_activities_modal" />
      ),
    };
    const defaultConfig = [
      'mortgage_calculator',
      'crime',
      'schools',
      'rental_avm',
      'neighborhood_insights',
      'forecast',
      'sale_history',
      'comps',
      'local_activities_modal',
    ];
    const selectedConfig: string[] =
      customBreakoutSectionOrder || defaultConfig;

    return (
      <div
        className={theme.BreakoutSections}
        data-hc-name="avm-breakout-section"
        style={style}
      >
        <div
          className={theme.BreakoutSectionsInner}
          style={{
            height: `calc(10px + (${previewSectionMinHeight} + ${previewSectionMarginBottom}) * ${numRows})`,
          }}
        >
          {selectedConfig.map(
            (sectionKey) => BreakoutSectionComponents[sectionKey] as JSX.Element
          )}
          {nullStateSectionKeys.length > 0 && !isSpecialUserTypesRestricted && (
            <div
              className={classNames(
                theme.ConsolidatedNullState,
                theme.PreviewSection
              )}
            >
              <div className={theme.ConsolidatedNullStateLeftSide}>
                <NullStateIcon
                  imageAltProps={{
                    title: 'Missing property details data',
                    ariaLabelledBy: 'null-state-icon',
                  }}
                />
              </div>
              <div className={theme.ConsolidatedNullStateRightSide}>
                <div className={theme.ConsolidatedNullStateEmphasized}>
                  This property is missing data for:
                </div>
                <div className={theme.ConsolidatedNullStateValueList}>
                  {nullStateSectionKeys.length < NULL_LIST_BULLETED_AT_LENGTH &&
                    /* Output a string containing all of the null state field
                     * i.e. "Comparable properties, rental estimate, and sale history" */
                    capitalize(
                      nullStateSectionKeys
                        .map((key, index) => {
                          return index === nullStateSectionKeys.length - 1
                            ? index === 0
                              ? SECTION_TITLES_BY_KEY[key]
                              : `and ${SECTION_TITLES_BY_KEY[key]}`
                            : nullStateSectionKeys.length === 2
                              ? `${SECTION_TITLES_BY_KEY[key]} `
                              : `${SECTION_TITLES_BY_KEY[key]}, `;
                        })
                        .join('')
                        .toLowerCase()
                    )}
                  {nullStateSectionKeys.length >=
                    NULL_LIST_BULLETED_AT_LENGTH && (
                    <ul>
                      {nullStateSectionKeys.sort().map((key) => (
                        <li key={key}>{SECTION_TITLES_BY_KEY[key]}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <BreakoutSectionDialog
          theme={theme}
          isActive={
            activeModalKey &&
            USE_BREAKOUT_SECTION_DIALOG_FOR_MODALS.indexOf(activeModalKey) > -1
          }
          useSlideInModal={useSlideInModals}
          handleClose={this.closeSectionModal}
          activeSectionLeftPositioning={activeSectionLeftPositioning}
          shouldPreventBodyScrollingForDialog={
            PREVENT_BODY_SCROLLING_FOR_MODAL_KEY.indexOf(activeModalKey) > -1
          }
          handleScrollIntoView={
            shouldScrollModalsIntoView && this.scrollDialogIntoView
          }
        >
          {SECTION_MODALS_BY_KEY[activeModalKey]}
        </BreakoutSectionDialog>
        <CompsContainer
          isActive={activeModalKey === 'comps'}
          handleClose={this.closeSectionModal}
        />
        {
          /* This modal fetches its own data on mount so that the data won't be fetched if the section
           * shouldn't be shown for a cobrand */
          shouldDisplayLocalActivitiesForCobrand && (
            <LocalActivitiesContainer
              isActive={activeModalKey === 'local-activities-modal'}
              handleClose={this.closeSectionModal}
            />
          )
        }
      </div>
    );
  }
}

const ThemedBreakoutSections = themr(
  'BreakoutSections',
  defaultTheme
)(BreakoutSections);
export default ThemedBreakoutSections;
