import { CombinedError } from 'urql';

const UPSTREAM_OUTAGE_MESSAGE = 'Invalid status 5';

/**
 * This parses every response, looking for errors worthy of raising/logging to Kibana/Sentry
 */
export function checkErrors(error: CombinedError) {
  if (error != null) {
    if (error.networkError !== undefined) {
      throw new Error(error.networkError.message);
    }
    for (let err of error.graphQLErrors) {
      if (err.message.indexOf(UPSTREAM_OUTAGE_MESSAGE) > -1) {
        throw new Error(
          'property-graph upstream service outage: ' + err.message
        );
      }
    }
  }
}
