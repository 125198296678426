import AvmDeepDiveComponent from '@client/components/AvmDeepDiveComponent';
import AvmDeepDiveMobile from '@client/components/AvmDeepDiveMobile';
import HorizontalSeparator from '@client/components/generic/HorizontalSeparator';
import LoadingSection from '@client/components/generic/LoadingSection';
import Slider from '@client/components/Slider';
import ValueComparisonContainer from '@client/containers/homeowner-value-comparison.container';
import PropertyIntroContainer from '@client/containers/property-intro.container';
import SlideInModalContainer from '@client/containers/slide-in-modal.container';
import defaultTheme from '@client/css-modules/AvmDeepDive.css';
import { createModalPortal } from '@client/hocs/create-modal-portal';
import { RegressionType } from '@client/store/constants';
import {
  AvmFactorWithLocation,
  PropertyValuesWithRegressionTypes,
  RegressionData,
} from '@client/store/types/regression-data';
import { dollarsFormatterWithSign } from '@client/utils/string.utils';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import React, { Component } from 'react';
import { useAriaAnnouncer } from '@client/context/aria-announcer';

/* CROSS-FUNCTIONAL: this component is used in both Homeowner and Homebuyer. If
 * making changes that may affect the other team, please inform the respective
 * engineers that changes have been made */

type ExternalProps = {
  avmDeepDiveFactorActive: string | null;
  avmFactorsWithLocation: AvmFactorWithLocation[];
  handleClose: () => void;
  isActive: boolean;
  isLoading: boolean;
  isSmallScreen: boolean;
  isXLargeSize?: boolean;
  modalTitle?: string;
  propertyValuesWithRegressionTypes: PropertyValuesWithRegressionTypes;
  regressionsData: RegressionData[];
  tractStatsCount?: number;
  hpiLastYear?: number;
  hpiNow?: number;
  hpiNextYear?: number;
  showHomeValues?: boolean;
  fetchPropertyAvmDeepDiveData?: () => void;
  theme: Theme;
  homeownerModalHeaderBackgroundColor?: string;
  homeownerModalHeaderTextColor?: string;
  reportClickLeft?: () => void;
  reportClickRight?: () => void;
  reportCardSelection?: (cardType: string) => void;
};

interface Props extends ExternalProps {
  ariaAnnouncer?: (message: JSX.Element | string) => void;
}

type State = {
  currentSliderIndex: number;
  lastVisibleSlideIndex: number;
};

class AvmDeepDive extends Component<Props> {
  state: State = {
    currentSliderIndex: 0,
    lastVisibleSlideIndex: 0,
  };

  componentDidMount() {
    const { fetchPropertyAvmDeepDiveData } = this.props;

    if (fetchPropertyAvmDeepDiveData) {
      fetchPropertyAvmDeepDiveData();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!this.props.isActive && prevProps.isActive) {
      this.setState({ currentSliderIndex: 0 });
    }
    if (this.props.isActive
      && this.state.currentSliderIndex !== null
      && this.state.currentSliderIndex !== prevState.currentSliderIndex
      && this.props.ariaAnnouncer) {
      const { descriptions } = this.getAvmDeepDiveComponentsAndDescriptions();

      // Build ariaMessage based on number of slides visible starting at the currentSliderIndex.
      let ariaMessage = "";
      let ariaMessageIndex = this.state.currentSliderIndex;

      for (let i = 0; i < this.state.lastVisibleSlideIndex + 1; i++) {
        // Since it is an infinity scroll, it is possible we've looped around to the beginning.
        // If so, reset the ariaMessageIndex back to 0.
        if (ariaMessageIndex > this.props.regressionsData.length - 1) {
          ariaMessageIndex = 0;
        }

        if (descriptions[ariaMessageIndex]) {
          ariaMessage += `${descriptions[ariaMessageIndex]}. `;
        }
        ariaMessageIndex++;
      }

      // Announce the completed message.
      this.props.ariaAnnouncer(ariaMessage);
    }
  }

  componentWillUnmount() {
    const { isActive } = this.props;

    if (isActive) {
      this.handleDismiss();
    }
  }

  handleDismiss = (): void => {
    if (this.props.ariaAnnouncer) {
      this.props.ariaAnnouncer("");
    }
    this.props.handleClose();
  };

  handleLastVisibleSlideIndexChanged = (index: number) => {
    this.setState({ lastVisibleSlideIndex: index });
  }

  getModalTitle = (): string => this.props.modalTitle || 'Data Deep Dives';

  getSliderItem = (
    factor: RegressionData,
  ): { Component: JSX.Element } => {
    const { propertyValuesWithRegressionTypes, tractStatsCount, theme } =
      this.props;
    const { regression } = factor;
    const noDataStyling =
      regression && (!regression.line || regression.line.length < 2);
    const FactorLabel = (
      <h2 className={theme.DeepDiveComponentHeaderSection}>
        {factor.displayName || factor.label}
      </h2>
    );
    const FactorDescription =
      factor.type.toUpperCase() === RegressionType.LOCATION ? (
        factor.value ? (
          <div className={theme.DeepDiveComponentSummary}>
            {factor.accessibleDescription || factor.description}
          </div>
        ) : (
          <div className={theme.DeepDiveComponentSummary}>
            {factor.defaultDescription}
          </div>
        )
      ) : (
        <div className={theme.DeepDiveComponentSummary}>
          {factor.accessibleDescription || factor.description}&nbsp;
          <strong>{`${dollarsFormatterWithSign(
            factor.value
          )} to value.`}</strong>
        </div>
      );

    return {
      Component: (
        <div
          key={factor.type}
          data-slider-uid={factor.type}
          className={theme.DeepDiveComponent}
        >
          {FactorLabel}
          {FactorDescription}
          <div
            className={classNames(theme.ComponentContainer, {
              [theme.NoDataContainerStyle]: noDataStyling,
            })}
          >
            <AvmDeepDiveComponent
              theme={theme}
              factor={factor}
              count={tractStatsCount}
              propertyValues={propertyValuesWithRegressionTypes}
            />
          </div>
        </div>
      ),
    };
  };

  getAvmDeepDiveComponentsAndDescriptions = (): {
    components: JSX.Element[];
    descriptions: string[];
  } => {
    const { avmDeepDiveFactorActive, regressionsData } = this.props;
    let components: JSX.Element[] = [];
    let descriptions: string[] = [];

    if (regressionsData.length >= 1) {
      // Sort the regressionData by the avmDeepDiveFactorActive. 
      // This way we can use the index on the sortedRegressionData arrya to help with 
      // the item descriptions.
      const sortedRegressionData = regressionsData;
      sortedRegressionData.sort((a: RegressionData) => {
        return a.label === avmDeepDiveFactorActive ? -1 : 0;
      })
      sortedRegressionData.map((factor, index) => {
        const { Component } = this.getSliderItem(factor);
        components.push(Component);
        descriptions.push(`${factor.displayName || factor.label}, Item ${index + 1} of ${regressionsData.length}`);
      });
    }
    return {
      components,
      descriptions,
    };
  };

  getShouldHideArrows = (
    numberOfComponents: number,
    isXLargeSize?: boolean
  ): boolean => {
    if (numberOfComponents === 2 && isXLargeSize) return true;
    if (numberOfComponents === 1) return true;
    return false;
  };

  handleClickRight = (currentIndex: number) => {
    const { reportClickRight } = this.props;

    this.setState({ currentSliderIndex: currentIndex });
    if (reportClickRight) {
      reportClickRight();
    }
  };

  handleClickLeft = (currentIndex: number) => {
    const { reportClickLeft } = this.props;

    this.setState({ currentSliderIndex: currentIndex });
    if (reportClickLeft) {
      reportClickLeft();
    }
  };

  render() {
    const {
      avmDeepDiveFactorActive,
      avmFactorsWithLocation,
      propertyValuesWithRegressionTypes,
      isActive,
      isLoading,
      isSmallScreen,
      isXLargeSize,
      regressionsData,
      tractStatsCount,
      showHomeValues,
      theme,
      homeownerModalHeaderBackgroundColor,
      homeownerModalHeaderTextColor,
      reportCardSelection,
    } = this.props;
    const { components: avmDeepDiveComponents } =
      this.getAvmDeepDiveComponentsAndDescriptions();
    const shouldHideArrows = this.getShouldHideArrows(
      avmDeepDiveComponents.length,
      isXLargeSize
    );

    return (
      <SlideInModalContainer
        isActive={isActive && !!avmDeepDiveFactorActive}
        modalAriaLabel={this.getModalTitle()}
        className={theme.AvmDeepDivesModal}
        dataHcName={'avm-deep-dives-modal'}
        handleClose={this.handleDismiss}
        theme={theme}
      >
        <section className={theme.HeadingContainer}>
          {/* The styling is different on homeowner modal */}
          <h2
            className={theme.Heading}
            style={
              homeownerModalHeaderBackgroundColor &&
                homeownerModalHeaderTextColor
                ? {
                  background: homeownerModalHeaderBackgroundColor,
                  color: homeownerModalHeaderTextColor,
                }
                : {}
            }
          >
            {this.getModalTitle()}
          </h2>
          <div>
            <PropertyIntroContainer theme={theme} />
          </div>
          {showHomeValues && <ValueComparisonContainer theme={theme} />}
        </section>
        <LoadingSection theme={theme} isLoading={isLoading}>
          {isSmallScreen ? (
            <AvmDeepDiveMobile
              avmFactors={avmFactorsWithLocation}
              avmDeepDiveFactorActive={avmDeepDiveFactorActive}
              isLoadingRegressionData={isLoading}
              propertyValuesWithRegressionTypes={
                propertyValuesWithRegressionTypes
              }
              regressionsData={regressionsData}
              tractStatsCount={tractStatsCount}
              reportCardSelection={reportCardSelection}
              theme={theme}
            />
          ) : (
            <React.Fragment>
              <HorizontalSeparator theme={theme} />
              <div className={theme.SliderWrapper}>
                {avmDeepDiveComponents.length > 1 ? (
                  <Slider
                    theme={theme}
                    infinityMode={avmDeepDiveComponents.length >= 2}
                    hideRightArrow={shouldHideArrows}
                    hideLeftArrow={shouldHideArrows}
                    isTrackSwipeable={true}
                    incrementByComponentWidth
                    onClickNext={this.handleClickRight}
                    onClickPrev={this.handleClickLeft}
                    onLastVisibleSlideIndexChange={this.handleLastVisibleSlideIndexChanged}
                    uIdField="data-slider-uid"
                  >
                    {avmDeepDiveComponents}
                  </Slider>
                ) : (
                  <div className={theme.DeepDiveComponentsContainer}>
                    {avmDeepDiveComponents}
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </LoadingSection>
      </SlideInModalContainer>
    );
  }
}

export const ExternalAvmDeepDive = (props: ExternalProps) => {
  const announcer = useAriaAnnouncer()
  return <AvmDeepDive ariaAnnouncer={announcer} {...props} />
}
const ThemeAvmDeepDive = themr('AvmDeepDive', defaultTheme)(ExternalAvmDeepDive);
export default createModalPortal(ThemeAvmDeepDive, 'avm-deep-dives-modal');
