import React, { useState } from 'react';
import theme from '@client/css-modules/FormModalTopPhotoSection.css';

type Props = {
  fullName: string | null;
  mortgageCompany?: string;
  photo?: string;
  Heading: JSX.Element | string;
};

/* Content to be passed into FormModal's `FormPageTopContent` prop, commonly used between multiple
 * implementations, so pulled out into this component */
const FormModalTopPhotoSection: React.FC<Props> = ({
  fullName,
  mortgageCompany,
  photo,
  Heading,
}) => {
  const [isPhotoError, setIsPhotoError] = useState<boolean>(false);

  return (
    <div className={theme.Realtor} data-hc-name="dialog-header-text">
      {photo && !isPhotoError && (
        <div className={theme.PhotoColumn}>
          <img
            onError={() => {
              setIsPhotoError(true);
            }}
            className={theme.AgentPhoto}
            src={photo}
            alt={
              fullName && mortgageCompany
                ? `${fullName} from ${mortgageCompany}`
                : fullName
                ? fullName
                : 'Image of agent'
            }
          />
        </div>
      )}
      <div className={theme.AgentVerbiage}>{Heading}</div>
    </div>
  );
};

export default FormModalTopPhotoSection;
