import {
  FEEDBACK_FORM_SUBMIT,
  FEEDBACK_FORM_SUBMIT_SUCCESS,
  FEEDBACK_FORM_SUBMIT_ERROR,
  CLEAR_FEEDBACK_SUBMIT_STATUS,
} from '@client/store/actions/feedback.actions';
import { STATUSES, Status } from '@client/store/constants';
import { Action } from '@client/store/actions';

const INITIAL_STATE: FeedbackState = {
  status: STATUSES.INIT,
  error: null,
};

export type FeedbackState = {
  status: Status;
  error: string | null;
};

export default function feedbackReducer(
  state = INITIAL_STATE,
  action: Action
): FeedbackState {
  switch (action.type) {
    case FEEDBACK_FORM_SUBMIT:
      return {
        ...state,
        status: STATUSES.LOADING,
        error: null,
      };
    case FEEDBACK_FORM_SUBMIT_SUCCESS:
      return {
        ...state,
        status: STATUSES.SUCCESS,
        error: null,
      };
    case FEEDBACK_FORM_SUBMIT_ERROR:
      return {
        ...state,
        status: STATUSES.ERROR,
        error: action.payload,
      };
    case CLEAR_FEEDBACK_SUBMIT_STATUS:
      return {
        ...state,
        status: STATUSES.INIT,
        error: null,
      };
    default:
      return state;
  }
}
