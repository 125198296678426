import { SVGIconComponentType } from '@client/inline-svgs/types';
import AgeAvmFactorCobranded from './AgeAvmFactorCobranded';
import BasementAvmFactorCobranded from './BasementAvmFactorCobranded';
import BathroomsAvmFactorCobranded from './BathroomsAvmFactorCobranded';
import BedroomsAvmFactorCobranded from './BedroomsAvmFactorCobranded';
import LivingAreaAvmFactorCobranded from './LivingAreaAvmFactorCobranded';
import LocationAvmFactorCobranded from './LocationAvmFactorCobranded';
import LotSizeAvmFactorCobranded from './LotSizeAvmFactorCobranded';
import PoolAvmFactorCobranded from './PoolAvmFactorCobranded';

/* Keeping this here instead of in `types/cobranding` for ease of use in adding new components */
export type ComponentDefMapping = {
  /* SVG icons are mapped with API-returned AVM factor type as property name */
  LocationAvmFactor: SVGIconComponentType;
  AgeAvmFactor: SVGIconComponentType;
  LotSizeAvmFactor: SVGIconComponentType;
  BedroomsAvmFactor: SVGIconComponentType;
  BathroomsAvmFactor: SVGIconComponentType;
  LivingAreaAvmFactor: SVGIconComponentType;
  PoolAvmFactor: SVGIconComponentType;
  BasementAvmFactor: SVGIconComponentType;
};

const CobrandComponents: ComponentDefMapping = {
  LocationAvmFactor: LocationAvmFactorCobranded,
  AgeAvmFactor: AgeAvmFactorCobranded,
  LotSizeAvmFactor: LotSizeAvmFactorCobranded,
  BedroomsAvmFactor: BedroomsAvmFactorCobranded,
  BathroomsAvmFactor: BathroomsAvmFactorCobranded,
  LivingAreaAvmFactor: LivingAreaAvmFactorCobranded,
  PoolAvmFactor: PoolAvmFactorCobranded,
  BasementAvmFactor: BasementAvmFactorCobranded,
};

export default CobrandComponents;
