import {
  AfterAuthAction,
  AuthModalHeadingText,
  AuthModalPage,
  AuthModalSubHeadingText,
  ErrorObj,
  User,
  ValidationErrors,
} from '@client/store/types/auth';
import { Status } from '@client/store/types/statuses';
import {
  LOGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  AUTH_MODAL_HIDE,
  AUTH_MODAL_SHOW,
  INIT_FORGOT_PASSWORD,
  SUBMIT_FORGOT_PASSWORD,
  SUBMIT_FORGOT_PASSWORD_SUCCESS,
  SUBMIT_FORGOT_PASSWORD_ERROR,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_USER_EMAIL,
  UPDATE_USER_EMAIL_SUCCESS,
  UPDATE_USER_EMAIL_ERROR,
  UPDATE_RESET_PASSWORD_TOKEN,
  VERIFY_RESET_PASSWORD_FAILURE,
  FETCH_USER_PROFILE,
  FETCH_USER_PROFILE_ERROR,
  FETCH_NEW_ACCESS_TOKEN_SUCCESS,
  UPDATE_USER_INFO_ON_STATE,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  CREATE_USER_VALIDATION_ERRORS,
  CONFIRM_NEW_USER,
  CONFIRM_NEW_USER_SUCCESS,
  CONFIRM_NEW_USER_ERROR,
  CONFIRM_NEW_USER_EXPIRED,
  RESEND_CONFIRM_USER_EMAIL_SUCCESS,
  RESEND_CONFIRM_USER_EMAIL_FAILURE,
  RESET_AUTH_DEPENDENT_STATE,
  UPDATE_EMAIL_MODAL_HIDE,
  UPDATE_EMAIL_MODAL_SHOW,
  CLOSE_RESEND_CONFIRM_EMAIL_SUCCESS_MODAL,
  LOGIN_LO_DIRECT_USER,
  ACCEPT_TERMS_AND_CONDITIONS_SUCCESS,
  CLEAR_USER_SIGNUP_ERROR,
} from '@client/store/actions/auth.actions';
import { AUTH_MODAL_PAGES, STATUSES } from '@client/store/constants';
import { Action } from '@client/store/actions';

type AuthModal = {
  isOpen: boolean;
  afterAuthAction: AfterAuthAction;
  startingPage: AuthModalPage;
  subHeadingText: AuthModalSubHeadingText;
  headingText: AuthModalHeadingText;
};
const INITIAL_TOKEN = 'initialT0k3n';

export type UserState = User | null;

export type AuthState = {
  authModal: AuthModal;
  loginErrorMessage: string | null;
  signUpErrorMessage: string | null;
  /* Used to show server side validation errors on sign up form */
  validationErrors: ValidationErrors;
  pendingUser: {
    email?: string | null;
    firstname?: string | null;
    lastname?: string | null;
  };
  status: Status;
  token: string;
  user: UserState;
  chapiPartnerInviteId: string | null;
  userBuyingPower: number | null;
  hasMultipleHomes: boolean;
  referrer?: string;
  lenderCtaPartnerUserId: string | null;
};

export type UserProfileState = {
  emailUpdateStatus: string | null;
  emailUpdateModalIsOpen: boolean;
  status: Status;
  errorMessage: ErrorObj | string | null;
};

export type ConfirmUserState = {
  confirmUserStatus: string;
  confirmUserError: ErrorObj | string | null;
  status: Status;
  errorMessage: string | null;
  token: string;
  isShowingResendSuccessModal: boolean;
};

export type ForgotPasswordState = {
  temporaryToken: string;
  resetPasswordStatus: string | null;
  resetPasswordError: ErrorObj | string | null;
  status: Status;
  error: ErrorObj | string | null;
};

export const INITIAL_AUTH_STATE: AuthState = {
  authModal: {
    isOpen: false,
    afterAuthAction: null,
    startingPage: AUTH_MODAL_PAGES.SIGN_UP,
    subHeadingText: {
      [AUTH_MODAL_PAGES.SIGN_UP]: 'Please sign up for a ComeHome account.',
      [AUTH_MODAL_PAGES.LOGIN]: 'Please log in to your account',
    },
    headingText: {
      [AUTH_MODAL_PAGES.SIGN_UP]: 'Welcome',
      [AUTH_MODAL_PAGES.LOGIN]: 'Welcome',
    },
  },
  loginErrorMessage: null,
  signUpErrorMessage: null,
  validationErrors: {},
  pendingUser: {},
  /* TODO: separate this status into 2 statuses: login/signup UI status and user data status */
  status: STATUSES.SUCCESS,
  token: INITIAL_TOKEN,
  user: null,
  userBuyingPower: null,
  hasMultipleHomes: false,
  chapiPartnerInviteId: null,
  // set in url as a query param after clicking through from html property card
  lenderCtaPartnerUserId: null,
};

const INITIAL_CONFIRM_USER_STATE: ConfirmUserState = {
  confirmUserStatus: STATUSES.INIT,
  confirmUserError: null,
  status: STATUSES.INIT,
  token: INITIAL_TOKEN,
  errorMessage: null,
  isShowingResendSuccessModal: false,
};

const INITIAL_FORGOT_PASSWORD_STATE: ForgotPasswordState = {
  status: STATUSES.INIT,
  error: null,
  resetPasswordStatus: null,
  resetPasswordError: null,
  temporaryToken: INITIAL_TOKEN,
};

const INITIAL_USER_PROFILE_STATE: UserProfileState = {
  emailUpdateStatus: STATUSES.INIT,
  status: STATUSES.SUCCESS,
  errorMessage: null,
  emailUpdateModalIsOpen: false,
};

export function userProfileReducer(
  state = INITIAL_USER_PROFILE_STATE,
  action: Action
): UserProfileState {
  switch (action.type) {
    case UPDATE_EMAIL_MODAL_HIDE:
      return {
        ...state,
        emailUpdateModalIsOpen: false,
        emailUpdateStatus: STATUSES.INIT,
      };
    case UPDATE_EMAIL_MODAL_SHOW:
      return {
        ...state,
        emailUpdateModalIsOpen: true,
      };
    case FETCH_USER_PROFILE:
      return {
        ...state,
        status: STATUSES.LOADING,
      };
    case UPDATE_USER_EMAIL:
      return {
        ...state,
        emailUpdateStatus: STATUSES.UPDATING,
        errorMessage: null,
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        status: STATUSES.UPDATING,
        errorMessage: null,
      };
    case UPDATE_USER_EMAIL_SUCCESS:
      return {
        ...state,
        emailUpdateStatus: STATUSES.SUCCESS,
        errorMessage: null,
      };
    case UPDATE_USER_PROFILE_SUCCESS:
    case UPDATE_USER_INFO_ON_STATE:
      return {
        ...state,
        status: STATUSES.SUCCESS,
        errorMessage: null,
      };
    case UPDATE_USER_EMAIL_ERROR:
      return {
        ...state,
        emailUpdateStatus: STATUSES.ERROR,
        errorMessage: action.payload.error,
      };
    case FETCH_USER_PROFILE_ERROR:
    case UPDATE_USER_PROFILE_ERROR:
      return {
        ...state,
        status: STATUSES.ERROR,
        errorMessage: action.payload.error,
      };
    default: {
      return state;
    }
  }
}

export function authReducer(
  state = INITIAL_AUTH_STATE,
  action: Action
): AuthState {
  switch (action.type) {
    case AUTH_MODAL_HIDE:
      return {
        ...state,
        authModal: INITIAL_AUTH_STATE.authModal,
      };
    case AUTH_MODAL_SHOW:
      return {
        ...state,
        authModal: {
          afterAuthAction: action.payload?.afterAuthAction ?? null,
          startingPage:
            action.payload?.startingPage ??
            INITIAL_AUTH_STATE.authModal.startingPage,
          subHeadingText:
            action.payload?.subHeadingText ??
            INITIAL_AUTH_STATE.authModal.subHeadingText,
          headingText:
            action.payload?.headingText ??
            INITIAL_AUTH_STATE.authModal.headingText,
          isOpen: true,
        },
      };
    case CREATE_USER:
      return {
        ...state,
        status: STATUSES.UPDATING,
        signUpErrorMessage: null,
        validationErrors: {},
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        status: STATUSES.SUCCESS,
        user: action.payload.user,
        token: action.payload.token,
        authModal: INITIAL_AUTH_STATE.authModal,
      };
    case CREATE_USER_ERROR:
      return {
        ...state,
        status: STATUSES.ERROR,
        signUpErrorMessage: action.payload.errorMessage,
        pendingUser: {
          email: action.payload.email,
          firstname: action.payload.firstname,
          lastname: action.payload.lastname,
        },
      };
    case CLEAR_USER_SIGNUP_ERROR: {
      return {
        ...state,
        status: STATUSES.UPDATING,
        signUpErrorMessage: null,
        validationErrors: {},
      };
    }
    case CREATE_USER_VALIDATION_ERRORS:
      return {
        ...state,
        status: STATUSES.ERROR,
        validationErrors: action.payload.errors,
        pendingUser: {
          email: action.payload.email,
          firstname: action.payload.firstname,
          lastname: action.payload.lastname,
        },
      };
    case LOGIN_LO_DIRECT_USER:
    case LOGIN:
      return {
        ...state,
        status: STATUSES.LOADING,
        loginErrorMessage: null,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        status: STATUSES.ERROR,
        loginErrorMessage: action.payload.errorMessage,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        status: STATUSES.SUCCESS,
        user: action.payload.user,
        token: action.payload.token,
        loginErrorMessage: null,
      };
    case FETCH_NEW_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
      };
    case UPDATE_USER_INFO_ON_STATE:
      return {
        ...state,
        user: action.payload.user,
      };
    case ACCEPT_TERMS_AND_CONDITIONS_SUCCESS:
      return {
        ...state,
        user: state.user
          ? {
              ...state.user,
              terms_accepted: action.payload.termsAcceptedDate,
            }
          : null,
      };
    /* Executed after anonymous login following logout either due to user interaction or
     * 401 response when attempting to refresh logged-in user's cookie */
    case LOGOUT_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
      };
    /* Executed after anonymous login following logout either due to user interaction or
     * 401 response when attempting to refresh logged-in user's cookie */
    case RESET_AUTH_DEPENDENT_STATE:
      return {
        ...state,
        user: INITIAL_AUTH_STATE.user,
        status: INITIAL_AUTH_STATE.status,
      };
    default:
      return state;
  }
}

export function confirmUserReducer(
  state = INITIAL_CONFIRM_USER_STATE,
  action: Action
): ConfirmUserState {
  switch (action.type) {
    case CONFIRM_NEW_USER:
      return {
        ...state,
        confirmUserStatus: STATUSES.UPDATING,
      };

    case CONFIRM_NEW_USER_SUCCESS:
      return {
        ...state,
        confirmUserStatus: STATUSES.SUCCESS,
      };

    case CONFIRM_NEW_USER_ERROR:
      return {
        ...state,
        confirmUserStatus: STATUSES.ERROR,
        confirmUserError: action.payload.error,
      };

    case CONFIRM_NEW_USER_EXPIRED:
      return {
        ...state,
        confirmUserStatus: STATUSES.EXPIRED,
        confirmUserError: action.payload.error,
        token: action.payload.token,
      };

    case RESEND_CONFIRM_USER_EMAIL_FAILURE:
      return {
        ...state,
        confirmUserStatus: STATUSES.INIT,
        confirmUserError: action.payload.message,
      };

    case RESEND_CONFIRM_USER_EMAIL_SUCCESS:
      return {
        ...state,
        confirmUserStatus: STATUSES.INIT,
        isShowingResendSuccessModal: true,
      };

    case CLOSE_RESEND_CONFIRM_EMAIL_SUCCESS_MODAL:
      return {
        ...state,
        confirmUserStatus: STATUSES.INIT,
        isShowingResendSuccessModal: false,
      };

    default:
      return state;
  }
}

export function forgotPasswordReducer(
  state = INITIAL_FORGOT_PASSWORD_STATE,
  action: Action
): ForgotPasswordState {
  switch (action.type) {
    case INIT_FORGOT_PASSWORD:
      return {
        ...state,
        status: STATUSES.INIT,
      };
    case SUBMIT_FORGOT_PASSWORD:
      return {
        ...state,
        error: null,
        status: STATUSES.LOADING,
      };
    case SUBMIT_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        status: STATUSES.SUCCESS,
      };
    case SUBMIT_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        status: STATUSES.ERROR,
        error: action.payload.error,
      };
    case UPDATE_RESET_PASSWORD_TOKEN:
      return {
        ...state,
        temporaryToken: action.payload.token,
      };
    case VERIFY_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordStatus: STATUSES.ERROR,
        resetPasswordError: action.payload.error,
      };
    default:
      return state;
  }
}
