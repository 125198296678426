import { Response } from 'express';
import { get } from 'lodash';
import windowOrGlobal from 'window-or-global';
import SplitBuckets from 'split-buckets';

import getDomain from '../server/middleware/utility-functions/get-domain';
import { COOKIE_KEYS } from '../client/store/constants';
import {
  AB_TESTS,
  AB_TEST_FEATURE_FLAGS_ID,
  AB_TEST_QUERY_PREFIX,
  VARIANTS,
  CanaryRequest,
  ABTests,
} from './constants';

export const getInitialState = () => {
  return windowOrGlobal?.__REDUX_INITIAL_STATE__;
};

export const getListOfABTests = () => {
  const initialState = getInitialState();
  return initialState?.abTests?.testVariants;
};

export const setABTestsOnWindowObj = () => {
  windowOrGlobal.__LIST_EXPERIMENTS__ = () => getListOfABTests();
};

export function getCookieOptions({
  httpOnly,
  scopeToCobrand,
}: {
  httpOnly: boolean;
  scopeToCobrand: string;
}) {
  if (typeof httpOnly === 'undefined') {
    throw new Error('A options argument with an httpOnly key must be provided');
  }

  if (typeof scopeToCobrand === 'undefined') {
    throw new Error(
      'A options argument with an scopeToCobrand key must be provided'
    );
  }

  /* 1.99 years */
  const COOKIE_MAX_AGE_MILLISECONDS = 63070000000;

  /**
   * Setting secure flag to false for dev/local environments only
   */
  if (getIsLocal()) {
    return {
      maxAge: COOKIE_MAX_AGE_MILLISECONDS,
      domain: getDomain(scopeToCobrand),
      httpOnly,
      sameSite: 'lax' as 'lax',
      secure: false,
    };
  } else {
    return {
      maxAge: COOKIE_MAX_AGE_MILLISECONDS,
      domain: getDomain(scopeToCobrand),
      httpOnly,
      sameSite: 'lax' as 'lax',
      secure: true,
    };
  }
}

function getIsLocal() {
  // don't bother inspecting the hostname/port if we know we're running in dev mode
  if (process.env.NODE_ENV === 'development') {
    return true;
  }
  const port = get(windowOrGlobal, ['location', 'port'], false);
  return !!port;
}

function assignNewVariant(experimentName: string): string {
  const abTest = new SplitBuckets(experimentName, [
    {
      weight: 0.5,
      value: VARIANTS.A,
    },
    {
      weight: 0.5,
      value: VARIANTS.B,
    },
  ]);
  return abTest.getBucket(`${Math.random()}`);
}

/**
 * Loop through all defined AB tests, either using an override value, an existing cookie value, or
 * assigning a new value as the variant for each.  Then set the AB test variants in a cookie and on
 * the request object
 */
export const setABTestVariants = (
  req: CanaryRequest,
  res: Response,
  callback: () => void
) => {
  /* These are set by the `setEnabledFeatures` middleware which runs prior to this middleware */
  const enabledFeatures = req.enabledFeatures;
  const abTestsCookie = req.cookies[COOKIE_KEYS.AB_TESTS_COOKIE];
  const abTestsCookieParsed = abTestsCookie
    ? (JSON.parse(abTestsCookie) as ABTests)
    : null;
  const disableAllForQAAutomation =
    'qa_automation' in req.query ||
    COOKIE_KEYS.AB_TESTS_DISABLE_ALL in req.cookies;
  let abTestVariants = {};

  for (const key in AB_TESTS) {
    let abTestKey = AB_TESTS[key] as string;
    let abTestQueryOverrideValue =
      req.query[`${AB_TEST_QUERY_PREFIX}${abTestKey}`];
    /* If the AB test is enabled, set its variant either based on an override value, the existing
     * cookie value, or assign a new variant */
    if (
      AB_TEST_FEATURE_FLAGS_ID[abTestKey] &&
      enabledFeatures.includes(AB_TEST_FEATURE_FLAGS_ID[abTestKey]) &&
      !disableAllForQAAutomation
    ) {
      /* If we're seeking to override the variant via query param, use it */
      if (abTestQueryOverrideValue) {
        abTestVariants[abTestKey] = abTestQueryOverrideValue;
        /* If there's a variant set in an existing cookie, use it */
      } else if (abTestsCookieParsed && abTestsCookieParsed[abTestKey]) {
        abTestVariants[abTestKey] = abTestsCookieParsed[abTestKey];
        /* If we have no cookie value or override value, assign a new variant */
      } else {
        abTestVariants[abTestKey] = assignNewVariant(abTestKey);
      }
      /* If the AB test is disabled, set to `null`, which causes VARIANTS.A to be assigned in the JS */
    } else {
      abTestVariants[abTestKey] = null;
    }
  }

  req.abTests = abTestVariants;
  res.cookie(
    COOKIE_KEYS.AB_TESTS_COOKIE,
    JSON.stringify(abTestVariants, (k, v) => v ?? undefined),
    getCookieOptions({ scopeToCobrand: '', httpOnly: true })
  );

  if (disableAllForQAAutomation) {
    res.cookie(
      COOKIE_KEYS.AB_TESTS_DISABLE_ALL,
      'true',
      getCookieOptions({ scopeToCobrand: '', httpOnly: true })
    );
  }

  callback();
};
