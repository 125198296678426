import { call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { addYears } from 'date-fns';

import { watchEvery } from '@client/utils/saga.utils';
import {
  fetchMortgageData,
  cacheUserMortgageData,
  cacheUserRefinanceData,
  fetchRefinanceMortgageData,
  FetchRefinanceMortgageDataPayload,
} from '@client/store/slices/homeowner-mortgage.slice';
import { getActiveSlugForHomeownerAndSubpages } from '@client/store/selectors/router.selectors';
import { consumerApiClient } from '@client/services/consumer-api-client';
import { REFINANCING_API_KEYS } from '@client/store/homeowner-constants';
import { toastShow } from '@client/store/actions/toast.actions';
import { selectActiveHomeEquityData } from '@client/store/slices/homeowner-equity.slice';
import { removeNonDigits } from '@client/utils/string.utils';
import { getFirstOfMonthStringForDateObj } from '@client/utils/date.utils';

function getCurrentBalance(payments) {
  const today = new Date();
  const currentDate =
    today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0');
  return payments?.find((data) => data.date.indexOf(currentDate) > -1)?.balance;
}

function getBalanceOneYearLater(payments) {
  const dateOneYearLater = getFirstOfMonthStringForDateObj(
    addYears(new Date(), 1)
  ).substring(0, 7);
  return payments?.find((data) => data?.date.indexOf(dateOneYearLater) > -1)
    ?.balance;
}

function hasRequiredEquityField(
  equityData: ReturnType<typeof selectActiveHomeEquityData>
) {
  return !!(equityData?.interest_rate && equityData?.original_loan_amount);
}

function* fetchMortgageDataSaga() {
  try {
    const slugInParams = (yield select(
      getActiveSlugForHomeownerAndSubpages
    )) as ReturnType<typeof getActiveSlugForHomeownerAndSubpages>;
    const equityData = (yield select(selectActiveHomeEquityData)) as ReturnType<
      typeof selectActiveHomeEquityData
    >;

    if (hasRequiredEquityField(equityData) && slugInParams) {
      const apiResponse = yield call(
        [consumerApiClient, consumerApiClient.mortgageSchedule],
        {
          [REFINANCING_API_KEYS.ADDITIONAL_MONTHLY_PAYMENT]: 0,
          [REFINANCING_API_KEYS.INTEREST_RATE]: equityData?.interest_rate,
          [REFINANCING_API_KEYS.LOAN_AMOUNT]: equityData?.original_loan_amount,
          [REFINANCING_API_KEYS.LOAN_DATE]: equityData?.origination_date,
          [REFINANCING_API_KEYS.MORTGAGE_ID]: equityData?.loan_terms,
        }
      );
      yield put(
        cacheUserMortgageData({
          slug: slugInParams,
          monthlyPayment: apiResponse.monthly_payment,
          balance: getCurrentBalance(apiResponse.payments) ?? null,
          balanceOneYearLater:
            getBalanceOneYearLater(apiResponse.payments) ?? null,
        })
      );
    } else {
      /* there is no sufficient info to calculate public equity amount using mortgage data. so user mortgage data needs to be wiped out to display null values */
      if (slugInParams) {
        yield put(
          cacheUserMortgageData({
            slug: slugInParams,
            monthlyPayment: null,
            balance: null,
            balanceOneYearLater: null,
          })
        );
      }
    }
  } catch (error: any) {
    yield put(toastShow(`${error?.responseJSON?.status}. Please try again.`));
    throw error;
  }
}

function* fetchRefinanceMortgageDataSaga(
  action: PayloadAction<FetchRefinanceMortgageDataPayload>
) {
  try {
    const equityData = (yield select(selectActiveHomeEquityData)) as ReturnType<
      typeof selectActiveHomeEquityData
    >;
    const slug = action.payload.slug;
    const refinanceAmount = action.payload.refinanceAmount;
    const refinanceLoanTerm = action.payload.refinanceLoanTerm;
    const refinanceInterestRate = action.payload.refinanceInterestRate;
    const parsedRefinanceAmount = removeNonDigits(refinanceAmount);

    if (hasRequiredEquityField(equityData)) {
      const apiResponse = yield call(
        [consumerApiClient, consumerApiClient.mortgageSchedule],
        {
          [REFINANCING_API_KEYS.ADDITIONAL_MONTHLY_PAYMENT]: 0,
          [REFINANCING_API_KEYS.INTEREST_RATE]: refinanceInterestRate,
          [REFINANCING_API_KEYS.LOAN_AMOUNT]:
            parsedRefinanceAmount ?? equityData?.original_loan_amount,
          [REFINANCING_API_KEYS.LOAN_DATE]: equityData?.origination_date,
          [REFINANCING_API_KEYS.MORTGAGE_ID]:
            refinanceLoanTerm ?? equityData?.loan_terms,
        }
      );

      yield put(
        cacheUserRefinanceData({
          slug,
          refinanceMonthlyPayment: apiResponse.monthly_payment,
        })
      );
    }
  } catch (error: any) {
    yield put(toastShow(`${error?.responseJSON?.status}. Please try again.`));
    throw error;
  }
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [fetchMortgageData.type]: fetchMortgageDataSaga,
    [fetchRefinanceMortgageData.type]: fetchRefinanceMortgageDataSaga,
  });
};
