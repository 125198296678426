import classNames from 'classnames';
import React, { Component } from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import {
  ChaseCTAEventParams,
  ChasePropertyTypeJSBridgeEventParams,
} from '@client/store/types/chase-integration-data';
import Tooltip from '@client/components/generic/Tooltip';
import { NormalizedProperty } from '@client/store/types/property';
import { getIsActiveListing } from '@client/utils/property.utils';
import LoanQualificationLetterIcon from '@client/inline-svgs/loan-qualification-letter-icon';
import defaultTheme from '@client/css-modules/LoanQualificationLetter.css';
import ChevronIconCobranded from '@client/components/ChevronIcon/ChevronIconCobranded';
import { PropertyType } from '@client/store/sagas/queries/types';
import { getIsEligibleForFinancing } from '@client/components/cobrand/chase/chase-constants';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';

export type LoanQualificationLetterProps = {
  propertyDetails: NormalizedProperty | null;
  userBuyingPower: number | null;
  getJSBridgePropertyTypeForPropertyType: GetJSBridgePropertyTypeType;
  handleClick: (args: ChaseCTAEventParams) => void;
  yearBuilt: number | null;
  reportSeeLetterClick: () => void;
  reportOffMarketSeeLetterClick: () => void;
  isFeatureEnabledForWebApp: boolean;
  theme: Theme;
};

type GetJSBridgePropertyTypeType = (
  propertyType: PropertyType | null
) => ChasePropertyTypeJSBridgeEventParams['property_type'];

class LoanQualificationLetter extends Component<LoanQualificationLetterProps> {
  onClick = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent,
    getJSBridgePropertyTypeForPropertyType: GetJSBridgePropertyTypeType
  ) => {
    e.stopPropagation();
    const {
      propertyDetails,
      handleClick,
      yearBuilt,
      reportSeeLetterClick,
      reportOffMarketSeeLetterClick,
    } = this.props;

    if (!propertyDetails) {
      throw new Error('Offer letter link clicked without propertyDetails');
    }
    const {
      slug,
      county,
      city,
      listPrice,
      streetAddress,
      state,
      status,
      unit,
      zipcode,
      zipcodePlus4,
      propertyType,
    } = propertyDetails;
    const isActiveListing = getIsActiveListing(status);
    const destination = 'view_offer_letter';

    if (isActiveListing) {
      reportSeeLetterClick();
    } else {
      reportOffMarketSeeLetterClick();
    }

    handleClick({
      event_type: 'property',
      destination,
      street_address: streetAddress,
      unit: unit || null,
      city,
      state,
      zipcode: zipcodePlus4 ? `${zipcode}-${zipcodePlus4}` : zipcode,
      county: county || null,
      list_price: isActiveListing ? listPrice : null,
      property_type: getJSBridgePropertyTypeForPropertyType(propertyType),
      year_built: yearBuilt || null,
      ch_property_id: slug,
    });
  };

  render() {
    const {
      getJSBridgePropertyTypeForPropertyType,
      propertyDetails,
      userBuyingPower,
      isFeatureEnabledForWebApp,
      theme,
    } = this.props;
    const { propertyType } = propertyDetails || {};
    const isEligibleForFinancing = getIsEligibleForFinancing(
      propertyType,
      propertyDetails?.status
    );
    const isShowingSection =
      isEligibleForFinancing && isFeatureEnabledForWebApp;

    return isShowingSection ? (
      <section className={theme.LoanQualificationLetter}>
        <button
          type="button"
          className={theme.LoanQualificationLetterIconWrapper}
          onKeyDown={onEnterOrSpaceKey((e: React.KeyboardEvent) =>
            this.onClick(e, getJSBridgePropertyTypeForPropertyType)
          )}
          onClick={(e) => {
            this.onClick(e, getJSBridgePropertyTypeForPropertyType);
          }}
        >
          <LoanQualificationLetterIcon
            aria-label="loan-qualification-letter-icon"
            role="img"
            className={classNames(theme.LoanQualificationLetterIcon, {
              /* these checks below depend on userBuyingPower. Turning off because userBuyingPower isn't ready yet */
              [theme.LoanQualificationLetterIconEnabled]: true,
              [theme.LoanQualificationLetterIconDisabled]: false,
            })}
          />
        </button>
        <div className={theme.LoanQualificationContent}>
          <div className={theme.LoanQualificationText}>
            <span className={theme.LoanQualificationTextDesktop}>
              View, print or email your conditional approval letter
            </span>
            <span className={theme.LoanQualificationTextMobile}>
              View, print or email your conditional approval letter
            </span>
          </div>
          <div className={theme.SeeLetterButtonContainer}>
            <button
              type="button"
              aria-label="see-letter-button"
              className={theme.SeeLetterButton}
              onKeyDown={onEnterOrSpaceKey((e: React.KeyboardEvent) =>
                this.onClick(e, getJSBridgePropertyTypeForPropertyType)
              )}
              onClick={(e) => {
                this.onClick(e, getJSBridgePropertyTypeForPropertyType);
              }}
            >
              See letter
              <ChevronIconCobranded className={theme.ChevronIcon} />
            </button>
            {!userBuyingPower && (
              <Tooltip
                dataHcName="loan-qualification-letter-chase-tooltip"
                theme={theme}
                position={'top'}
                content={
                  <div className={theme.TooltipDescription}>
                    Create your conditional approval letter
                  </div>
                }
              />
            )}
          </div>
        </div>
      </section>
    ) : (
      <hr className={theme.SectionDividerLine} />
    );
  }
}

const ThemedLoanQualificationLetter = themr(
  'LoanQualificationLetter',
  defaultTheme
)(LoanQualificationLetter);
export default ThemedLoanQualificationLetter;
