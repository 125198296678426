import React from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import defaultTheme from '@client/css-modules/NoAvmPlaceholder.css';

export type NoAvmPlaceholderProps = {
  theme: Theme;
};

/**
 * A simple placeholder to be used when no AVM is available for a property
 */
const NoAvmPlaceholder: React.FunctionComponent<NoAvmPlaceholderProps> = ({
  theme,
}) => {
  return <div className={theme.NoAvmPlaceholder}>--</div>;
};

export default themr('NoAvmPlaceholderThemed', defaultTheme)(NoAvmPlaceholder);
