import { connect } from 'react-redux';
import HomeownerUpsellAd from '@client/components/HomeownerUpsellAd';
import { getActivePDPSlug } from '@client/store/selectors/router.selectors';
import { claimHomeIfLoggedInThenRedirect } from '@client/store/actions/homeowner.actions';

const mapStateToProps = (state) => {
  return {
    slug: getActivePDPSlug(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  claimAndRedirectToHomeowner: (slug: string) => {
    dispatch(claimHomeIfLoggedInThenRedirect(slug));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeownerUpsellAd);
