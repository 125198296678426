import { RouteDefsAllType, RouterOptionsType } from './types';

import Router from './route-matcher';

export default function buildRouteMatcher(
  routes: RouteDefsAllType,
  options: RouterOptionsType
) {
  const routeMatcher = new Router();

  Object.keys(routes).forEach((view) => {
    const routeDef = routes[view];
    const { saga = () => {} } = routeDef;
    // Wrap saga in sagaWrapper if required
    routeMatcher.addRoute(routeDef.path, saga, { ...routeDef, view });
  });

  return routeMatcher;
}
