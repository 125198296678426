import { createSelector } from 'reselect';

import { ReduxState } from '@client/store/types/redux-state';
import {
  ScreenSize,
  ColumnLayoutScreenSize,
  ScreenZoomScreenSize,
} from '@client/components/MatchMedia';

type SizeCollector = { [key in ScreenSize]: boolean };
type ColumnLayoutSizeCollector = { [key in ColumnLayoutScreenSize]: boolean };
type ScreenZoomSizeCollector = { [key in ScreenZoomScreenSize]: boolean };

const sizeXLargeMapping: SizeCollector = {
  xlarge: true,
  tablet: false,
  small: false,
  xsmall: false,
  xxsmall: false,
};
const sizeTabletMapping: SizeCollector = {
  xlarge: false,
  tablet: true,
  small: false,
  xsmall: false,
  xxsmall: false,
};
const sizeTabletOrSmallerMapping: SizeCollector = {
  xlarge: false,
  tablet: true,
  small: true,
  xsmall: true,
  xxsmall: true,
};
const sizeSmallOrSmallerMapping: SizeCollector = {
  xlarge: false,
  tablet: false,
  small: true,
  xsmall: true,
  xxsmall: true,
};
const sizeXSmallOrSmallerMapping: SizeCollector = {
  xlarge: false,
  tablet: false,
  small: false,
  xsmall: true,
  xxsmall: true,
};
const sizeXXSmallOrSmallerMapping: SizeCollector = {
  xlarge: false,
  tablet: false,
  small: false,
  xsmall: false,
  xxsmall: true,
};

const oneColumnLayoutMapping: ColumnLayoutSizeCollector = {
  onecolumn: true,
  twocolumn: false,
  threecolumn: false,
};

const twoColumnLayoutMapping: ColumnLayoutSizeCollector = {
  onecolumn: false,
  twocolumn: true,
  threecolumn: false,
};

const fourHundredZoomMapping: ScreenZoomSizeCollector = {
  fourhundredzoom: true
}


const getMatchMediaState = (state: ReduxState) => state.matchMedia;

/**
 * Only use the below for JS logic that CANNOT be enacted with CSS media queries alone
 * (or when we want to prevent a large component tree from rendering unnecessarily)
 */
export const getIsIOSDevice = createSelector(
  getMatchMediaState,
  (matchMedia) => matchMedia.deviceIOS
);

export const getIsAndroidDevice = createSelector(
  getMatchMediaState,
  (matchMedia) => matchMedia.deviceAndroid
);

export const getScreenSize = createSelector(
  getMatchMediaState,
  (matchMedia) => matchMedia.screenSize
);

export const getColumnLayoutSize = createSelector(
  getMatchMediaState,
  (matchMedia) => matchMedia.columnLayout
);
export const getScreenZoomSize = createSelector(
  getMatchMediaState,
  (matchMedia) => matchMedia.screenZoomSize
)

export const getIsSmallSize = createSelector(
  getScreenSize,
  (screenSize) => !!(screenSize && sizeSmallOrSmallerMapping[screenSize])
);

export const getIsXSmallSize = createSelector(
  getScreenSize,
  (screenSize) => !!(screenSize && sizeXSmallOrSmallerMapping[screenSize])
);

export const getIsXXSmallSize = createSelector(
  getScreenSize,
  (screenSize) => !!(screenSize && sizeXXSmallOrSmallerMapping[screenSize])
);

export const getIsXLargeSize = createSelector(
  getScreenSize,
  (screenSize) => !!(screenSize && sizeXLargeMapping[screenSize])
);

export const getIsTabletSize = createSelector(
  getScreenSize,
  (screenSize) => !!(screenSize && sizeTabletMapping[screenSize])
);

export const getIsTabletOrSmallerSize = createSelector(
  getScreenSize,
  (screenSize) => !!(screenSize && sizeTabletOrSmallerMapping[screenSize])
);

export const getIsTouchDevice = createSelector(
  getIsIOSDevice,
  getIsAndroidDevice,
  (isIOS, isAndroid) => !!(isIOS || isAndroid)
);

export const getIsMobile = createSelector(
  getIsTouchDevice,
  getIsSmallSize,
  (isTouchDevice, isSmallSize) => !!(isTouchDevice || isSmallSize)
);

export const getIsTabletTouchDevice = createSelector(
  getIsTabletSize,
  getIsTouchDevice,
  (isTabletSize, isTouchDevice) => isTabletSize && isTouchDevice
);

/* Returns true for any tablet touch device or any small touch or non-touch device (for backwards compatibility
 * with current UI) */
export const getIsTabletOrSmallerTouchDevice = createSelector(
  getIsTabletOrSmallerSize,
  getIsTouchDevice,
  getIsSmallSize,
  (isTabletOrSmallerSize, isTouchDevice, isSmallSize) =>
    !!((isTabletOrSmallerSize && isTouchDevice) || isSmallSize)
);

export const getIsOneColumnSize = createSelector(
  getColumnLayoutSize,
  (columnSize) => !!(columnSize && oneColumnLayoutMapping[columnSize])
);

export const getIsTwoColumnSize = createSelector(
  getColumnLayoutSize,
  (columnSize) => !!(columnSize && twoColumnLayoutMapping[columnSize])
);

export const getIsFourHundredZoom = createSelector(
  getScreenZoomSize,
  (screenZoom) => !!(screenZoom && fourHundredZoomMapping[screenZoom])
)
