import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { useLenderCtaUrlModifier } from '@client/hooks/lender-cta-config.hooks';
import { useOutboundCtaUrl } from '@client/hooks/outbound-cta-url.hooks';
import DollarCircleIconSVG from '@client/inline-svgs/dollar-circle';
import GreenHollowCheckIcon from '@client/inline-svgs/green-check-mark-hollow';
import {
  eventType,
  ParentEventType,
} from '@client/store/actions/analytics.actions';
import { getCobrandDisplayName } from '@client/store/selectors/cobranding.selectors';
import { GrantProgramConfigForView } from '@client/store/types/cobranding';
import { dollarsFormatter } from '@client/utils/formatter.utils';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import DisclaimerTooltip from '../DisclaimerTooltip';
import GrantProgramCTACobranded from './GrantProgramCTACobranded';

export type StyleProps = {
  $isNarrowLayout?: boolean;
  $useFullWidthCTA?: boolean;
};

const StyledContentContainer = styled.div`
  padding: 30px;
`;
const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;
const StyledContent = styled.div`
  max-width: 358px;
  font-size: 20px;
  font-weight: 900;
  margin: 15px 0;
`;
const StyledTopSectionContainer = styled.div`
  display: flex;
`;
const StyledUl = styled.ul<StyleProps>`
  padding: 0;
  margin: 10px 0 0 0;
`;
const StyledLi = styled.li`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  margin: 5px 20px 5px 0;
`;
const StyledP = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin: 15px 0 20px 0;
  padding: 0;
`;
const StyledListTitle = styled.h3`
  font-size: 12px;
  font-weight: 900;
`;
const StyledDollarCircleIconSVG = styled(DollarCircleIconSVG)`
  margin-right: 9px;
  width: 32px;
  height: 32px;
`;
const StyledBlackHollowCheckCircleIcon = styled(GreenHollowCheckIcon)`
  margin-right: 9px;
  & svg {
    display: flex;
    width: 24px;
    height: 24px;
  }
  & path {
    fill: black;
  }
`;

const StyledListItemsContainer = styled.div<StyleProps>`
  ${(props) => `flex-direction: ${props.$isNarrowLayout ? 'column' : 'row'};`}
  display: flex;
  flex-wrap: wrap;
`;

function CheckListItem({ text }: { text: string }) {
  return (
    <StyledLi>
      <StyledBlackHollowCheckCircleIcon aria-hidden="true" />
      {text}
    </StyledLi>
  );
}

export default function GrantProgramFullContent({
  isNarrowLayout,
  isPdpPage,
  isSmallScreen,
  useFullWidthCTA,
  grantAmount,
  grantProgramConfigForView,
  dataEventName,
  dataEventParentName,
}: {
  useFullWidthCTA?: boolean;
  grantAmount: number;
  grantProgramConfigForView: GrantProgramConfigForView;
  isNarrowLayout: boolean;
  isPdpPage: boolean;
  isSmallScreen?: boolean;
  dataEventName: eventType;
  dataEventParentName: ParentEventType;
}) {
  const cobrandDisplayName = useSelector(getCobrandDisplayName);
  const { pillButtonPrimaryBackgroundColor, linkColor } = useCobrandStyles();
  const {
    program_name,
    header,
    subheader,
    list_header,
    bullet_1,
    bullet_2,
    bullet_3,
    button_text_and_url,
    disclaimer_label,
    disclaimer_content,
  } = grantProgramConfigForView;
  const outboundCtaUrl = useOutboundCtaUrl(button_text_and_url.destination_url);
  const modifiedOutboundCtaUrl = useLenderCtaUrlModifier(outboundCtaUrl);
  if (!modifiedOutboundCtaUrl) {
    throw new Error(
      'Attempting to render GrantProgramFullContent without a destination_url'
    );
  }
  return (
    <StyledContentContainer data-hc-name="grant-program-content">
      <StyledTopSectionContainer>
        <div>
          <StyledHeader data-hc-name="grant-program-content-header">
            <StyledDollarCircleIconSVG aria-hidden="true" />
            {program_name || `${cobrandDisplayName} Grant Program`}
          </StyledHeader>
          <StyledContent data-hc-name="grant-program-content-body">
            {header || (
              <>
                <span style={{ color: pillButtonPrimaryBackgroundColor }}>
                  Qualify for up to {dollarsFormatter(grantAmount)}
                </span>{' '}
                towards a down payment on this home!
              </>
            )}
          </StyledContent>
        </div>
        {!isNarrowLayout && (
          <GrantProgramCTACobranded
            dataEventName={dataEventName}
            dataEventParentName={dataEventParentName}
            isNarrowLayout={false}
            isPdpPage={isPdpPage}
            buttonText={button_text_and_url.button_text}
            buttonUrl={modifiedOutboundCtaUrl}
            nativeAppNavKey={button_text_and_url.native_app_nav_key}
          />
        )}
      </StyledTopSectionContainer>
      <StyledP>
        {subheader ||
          `${cobrandDisplayName} is dedicated to supporting homeownership.`}
      </StyledP>
      <StyledUl data-hc-name="grant-program-content-check-list">
        <StyledListTitle>{list_header || 'Take the next step'}</StyledListTitle>
        {bullet_1 || bullet_2 || bullet_3 ? (
          <StyledListItemsContainer $isNarrowLayout={isNarrowLayout}>
            {bullet_1 && <CheckListItem text={bullet_1} />}
            {bullet_2 && <CheckListItem text={bullet_2} />}
            {bullet_3 && <CheckListItem text={bullet_3} />}
          </StyledListItemsContainer>
        ) : (
          <StyledListItemsContainer $isNarrowLayout={isNarrowLayout}>
            <CheckListItem text="Review Program Guidelines" />
            <CheckListItem text="Apply for the Homebuyer Program" />
            <CheckListItem text="Search for Qualifying Homes" />
          </StyledListItemsContainer>
        )}
      </StyledUl>
      {isNarrowLayout && (
        <GrantProgramCTACobranded
          dataEventName={dataEventName}
          dataEventParentName={dataEventParentName}
          isNarrowLayout
          isPdpPage={isPdpPage}
          buttonText={button_text_and_url.button_text}
          buttonUrl={modifiedOutboundCtaUrl}
          useFullWidthCTA={useFullWidthCTA}
          nativeAppNavKey={button_text_and_url.native_app_nav_key}
        />
      )}
      {disclaimer_label && disclaimer_content && (
        <DisclaimerTooltip
          labelAndContent={{
            label: disclaimer_label,
            content: disclaimer_content,
          }}
          position={isSmallScreen ? 'top' : 'bottom'}
          labelColor={linkColor}
          dataHcName="grant-program-disclaimer-link"
          labelFontSize="10px"
          stayFocusedOnBlur
        />
      )}
    </StyledContentContainer>
  );
}
