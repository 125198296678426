import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import GreenCheckIcon from '@client/inline-svgs/green-check';
import GreenCheckIconChase from '@client/inline-svgs/green-check-chase';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return GreenCheckIcon;
    default:
      return GreenCheckIconChase;
  }
};

const GreenCheckIconCobranded: React.FC<{ className: string }> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default GreenCheckIconCobranded;
