import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { ReduxState } from '@client/store/types/redux-state';
import { View } from '@client/routes/constants';
import { STATUSES, Status } from '@client/store/constants';
import { getActiveSlugForHomeownerAndSubpages } from '@client/store/selectors/router.selectors';

type SlugPayload = { slug: string };
interface Question {
  id: string;
  text: string;
  answers: string[];
}
interface Answer {
  question_id: string;
  text: string;
}
interface HomeownerVerificationData {
  slug: string;
  failedCount: number;
  isLocked: boolean;
  isVerified: boolean;
  questions: Question[];
  afterVerificationView?: View;
  questionsLoadingStatus: Status;
}
type HomeownerVerificationDataCache = Partial<HomeownerVerificationData> &
  SlugPayload;
export interface HomeownerVerificationState {
  [slug: string]: HomeownerVerificationData;
}

const initialState: HomeownerVerificationState = {};

export const HomeownerVerificationSlice = createSlice({
  name: 'homeownerVerification',
  initialState,
  reducers: {
    fetchVerificationData: () => {},
    putVerificationData: (_, action: PayloadAction<Answer[]>) => {},
    clearAfterVerificationViewCache: (
      state,
      action: PayloadAction<SlugPayload>
    ) => {
      const { slug } = action.payload;
      return {
        ...state,
        [slug]: {
          ...(state[slug] ?? {}),
          afterVerificationView: undefined,
        },
      };
    },
    cacheAfterVerificationView: (
      state,
      action: PayloadAction<
        SlugPayload & {
          afterVerificationView: View;
        }
      >
    ) => {
      const { slug } = action.payload;
      return {
        ...state,
        [slug]: {
          ...(state[slug] ?? {}),
          ...(state[slug] === undefined && {
            /* need to specify status to trigger fetch on logged out */
            questionsLoadingStatus: STATUSES.INIT,
          }),
          ...action.payload,
        },
      };
    },
    cacheVerificationData: (
      state,
      action: PayloadAction<HomeownerVerificationDataCache>
    ) => {
      const { slug } = action.payload;
      return {
        ...state,
        [slug]: {
          ...(state[slug] ?? {}),
          ...action.payload,
        },
      };
    },
    clearVerificationDataCache: (state, action: PayloadAction<SlugPayload>) => {
      const newState = { ...state };
      delete newState[action.payload.slug];
      return newState;
    },
  },
});

export const selectVerificationDataMap = (state: ReduxState) =>
  state.homeownerVerification;
export const selectVerificationData = createSelector(
  getActiveSlugForHomeownerAndSubpages,
  selectVerificationDataMap,
  (slug, homeownerVerificationDataMap) =>
    slug && homeownerVerificationDataMap[slug]
      ? homeownerVerificationDataMap[slug]
      : null
);
export const selectIsHomeVerified = createSelector(
  selectVerificationData,
  (verificationData) => !!verificationData?.isVerified
);
export const selectIsHomeLocked = createSelector(
  selectVerificationData,
  (verificationData) => !!verificationData?.isLocked
);
export const selectIsVerificationDataLoading = createSelector(
  selectVerificationData,
  (verificationData) =>
    verificationData?.questionsLoadingStatus === STATUSES.LOADING ||
    verificationData?.questionsLoadingStatus === STATUSES.INIT ||
    !verificationData
);

export const {
  fetchVerificationData,
  putVerificationData,
  cacheVerificationData,
  cacheAfterVerificationView,
  clearVerificationDataCache,
  clearAfterVerificationViewCache,
} = HomeownerVerificationSlice.actions;
export default HomeownerVerificationSlice.reducer;
