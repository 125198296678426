import { useSelector } from 'react-redux';

import { getCobrandStyles } from '@client/cobrand-settings/cobrand-styles';
import {
  getCobrandId,
  getDomainTheme,
} from '@client/store/selectors/cobranding.selectors';

export function useCobrandStyles() {
  const cobrandId = useSelector(getCobrandId);
  const domainTheme = useSelector(getDomainTheme);

  return getCobrandStyles(cobrandId, domainTheme);
}
