import { call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { watchEvery } from '@client/utils/saga.utils';
import {
  EquityFormDataForSlug,
  cacheUserEquityData,
  fetchEquityData,
  putEquityData,
} from '@client/store/slices/homeowner-equity.slice';
import {
  getActivePropertyClaimedHomeInfo,
  getHcAddressId,
  getHomeownerClaimedHomeEdits,
} from '@client/store/selectors/homeowner.selectors';
import { getActiveSlugForHomeownerAndSubpages } from '@client/store/selectors/router.selectors';
import { consumerApiClient } from '@client/services/consumer-api-client';
import { DateString, EquityData } from '@client/store/types/homeowner';
import {
  updateClaimedHomesSuccess,
  updateClaimedHomeError,
} from '@client/store/actions/homeowner.actions';
import { fetchMortgageData } from '@client/store/slices/homeowner-mortgage.slice';
import { toastShow } from '@client/store/actions/toast.actions';
import { STATUSES } from '@client/store/constants';

function* cachePublicEquityData() {
  const activeClaimedHome = (yield select(
    getActivePropertyClaimedHomeInfo
  )) as ReturnType<typeof getActivePropertyClaimedHomeInfo>;
  const slugInParams = (yield select(
    getActiveSlugForHomeownerAndSubpages
  )) as ReturnType<typeof getActiveSlugForHomeownerAndSubpages>;

  if (slugInParams) {
    yield put(
      cacheUserEquityData({
        slug: slugInParams,
        address_id: activeClaimedHome?.address_id ?? null,
        current_balance: null,
        current_equity: null,
        interest_rate: activeClaimedHome?.rate ?? null,
        original_loan_amount: activeClaimedHome?.principal ?? null,
        origination_date: (activeClaimedHome?.sale_date as DateString) ?? null,
        loan_terms: activeClaimedHome?.terms ?? null,
        data_source: 'public',
        monthly_payment_pi: null,
        monthly_payment_ti: null,
        monthly_payment_due_date: null,
        lender_data_tag: null,
        current_balance_as_of_date: null,
        second_mortgage_current_balance: null,
        second_mortgage_current_balance_as_of_date: null,
        second_mortgage_interest_rate: null,
        second_mortgage_loan_terms: null,
        second_mortgage_origination_date: null,
        second_mortgage_original_loan_amount: null,
        second_mortgage_monthly_payment_due_date: null,
        second_mortgage_monthly_payment_pi: null,
        second_mortgage_monthly_payment_ti: null,
        equityDataLoadingStatus: STATUSES.SUCCESS,
      })
    );
  }
}

function* fetchEquityDataSaga() {
  const slugInParams = (yield select(
    getActiveSlugForHomeownerAndSubpages
  )) as ReturnType<typeof getActiveSlugForHomeownerAndSubpages>;
  const addressId = (yield select(getHcAddressId)) as ReturnType<
    typeof getHcAddressId
  >;

  if (slugInParams && addressId) {
    yield put(
      cacheUserEquityData({
        slug: slugInParams,
        equityDataLoadingStatus: STATUSES.LOADING,
      })
    );

    try {
      const apiResponse = (yield call(
        [consumerApiClient, consumerApiClient.fetchEquityDataFromAPI],
        addressId
      )) as EquityData;

      yield put(
        cacheUserEquityData({
          slug: slugInParams,
          ...apiResponse,
          equityDataLoadingStatus: STATUSES.SUCCESS,
        })
      );
    } catch (err: any) {
      if (err?.statusCode === 404) {
        // Without user equity data or public equity data on state, estimated equity is blank on render
        yield call([{}, cachePublicEquityData]);
      } else {
        throw err;
      }
    }
  }
}

function* putPurchasePriceOnHomeowner(
  action: PayloadAction<EquityFormDataForSlug>
) {
  const homePurchasePrice = (
    (yield select(getActivePropertyClaimedHomeInfo)) as ReturnType<
      typeof getActivePropertyClaimedHomeInfo
    >
  )?.sale_price;
  const claimedHomeEditData = (yield select(
    getHomeownerClaimedHomeEdits
  )) as ReturnType<typeof getHomeownerClaimedHomeEdits>;
  const updatedHomePurchasePrice = action.payload.purchase_price;

  if (homePurchasePrice !== updatedHomePurchasePrice) {
    try {
      const homeownerApiResponse = yield call(
        [consumerApiClient, consumerApiClient.updateClaimedHomesData],
        {
          ...claimedHomeEditData,
          sale_price: {
            overridden: true,
            value: updatedHomePurchasePrice ?? 0,
          },
        }
      );
      yield put(updateClaimedHomesSuccess(homeownerApiResponse));
    } catch (error: any) {
      yield put(
        updateClaimedHomeError(
          error?.messageRaw?.SAVE_ERROR_MSG,
          'toast_error_save_reload'
        )
      );
      throw error;
    }
  }
}

/**
 * PUT /homeowner/equity -> update home equity info
 * GET /homeowner/equity -> fetch the fresh current_equity info
 * PUT /homeowner/activePropertyId -> update home purchase price
 * POST /mortgage/schedule -> calculate and cache new estimated payment
 */
function* putEquityDataSaga(action: PayloadAction<EquityFormDataForSlug>) {
  try {
    yield call([consumerApiClient, consumerApiClient.putEquityDataOnAPI], {
      address_id: (yield select(getHcAddressId)) as ReturnType<
        typeof getHcAddressId
      >,
      ...action.payload,
    });
    /* TODO: /equity endpoint will return a response in the future so that frontend does not have to do an extra GET call right after PUT here */
    yield call(fetchEquityDataSaga);
  } catch (error: any) {
    yield put(toastShow(`${error?.responseJSON?.status}. Please try again.`));
    throw error;
  }
  yield call([{}, putPurchasePriceOnHomeowner], action);
  yield put(fetchMortgageData());
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [fetchEquityData.type]: fetchEquityDataSaga,
    [putEquityData.type]: putEquityDataSaga,
  });
};
