import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import defaultTheme from '@client/css-modules/SearchMapNotificationNoMLS.css';
import TextInput from '@client/components/generic/TextInput';
import Arrow from '@client/inline-svgs/arrow';
import mlsLimitedDataIcon from 'assets/images/mls-limited-data.png';
import { registerEmail } from '@client/store/actions/mls-notify.actions';
import { SearchShowMLSRegistrationConfirm } from '@client/store/actions/search.actions';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';

type Props = { theme: Theme };

const SearchMapNotificationNoMLS = ({ theme }: Props) => {
  const dispatch = useDispatch();
  const [emailAddress, setEmailAddress] = useState('');
  const [showEmail, setShowEmail] = useState(false);
  const [showToast, setShowToast] = useState(true);
  const handleInput = (e) => {
    setEmailAddress(e.target.value);
  };
  const handleSubmit = (e) => {
    dispatch(
      registerEmail({
        email: emailAddress,
      })
    );
    dispatch(SearchShowMLSRegistrationConfirm());
    setShowToast(false);
  };
  return showToast ? (
    <div
      data-testid="search-map-notification-no-mls-wrapper"
      className={theme.MapNotificationInner}
      style={showEmail ? { display: 'inline' } : {}}
      onClick={() => setShowEmail(true)}
    >
      <div className={theme.TopRow}>
        <div className={theme.Header}>
          <img
            src={mlsLimitedDataIcon}
            className={theme.Image}
            height={'32px'}
            width={'36px'}
            alt=""
          />
          <div className={theme.ToastMessage}>
            <span className={theme.LimitedDataText}>
              We have limited data in the shaded area.{' '}
            </span>
            <span> Get notified when more data is available.</span>
          </div>
        </div>
        {!showEmail && (
          <button
            data-testid="show-email-button"
            className={theme.ArrowContainer}
            onClick={() => setShowEmail(true)}
            type="button"
            aria-label="Register for email notification"
          >
            <Arrow className={theme.RegisterArrowIcon} aria-hidden />
          </button>
        )}
      </div>
      {showEmail && (
        <div className={theme.SearchBar}>
          <TextInput
            style={{ margin: 'auto' }}
            label=""
            placeholder="Enter your email"
            onChange={handleInput}
            className={theme.InputElement}
            theme={theme}
          />
          <button
            disabled={emailAddress === ''}
            onClick={handleSubmit}
            className={theme.circle}
            aria-label="Submit email address"
          >
            <Arrow className={theme.ArrowIcon} aria-hidden />
          </button>
        </div>
      )}
    </div>
  ) : null;
};

export default themr(
  'SearchMapNotificationNoMLSThemed',
  defaultTheme
)(SearchMapNotificationNoMLS);
