import * as React from 'react';

import { MissingLotLinesPlaceholderProps } from '@client/store/types/cobranded-components/missing-lot-lines';
import { themr } from '@friendsofreactjs/react-css-themr';
import defaultTheme from '@client/css-modules/MissingLotLinesPlaceholder.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

const MissingLotLinesPlaceholderChase: React.FC<
  MissingLotLinesPlaceholderProps
> = ({ theme }) => {
  const { lotMapNullStateImageUrl } = useCobrandStyles();
  return (
    <div
      className={theme.LotMapNullState}
      style={{ backgroundImage: `url(${lotMapNullStateImageUrl}` }}
    />
  );
};

const ThemedMissingLotLinesPlaceholder = themr(
  'MissingLotLinesPlaceholderChase',
  defaultTheme
)(MissingLotLinesPlaceholderChase);
export default ThemedMissingLotLinesPlaceholder;
