import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import SearchMapNotificationNoMLS from './SearchMapNotificationNoMLS';
import SearchMapNotificationNoMLSChase from './SearchMapNotificationNoMLSChase';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import { Theme } from '@friendsofreactjs/react-css-themr';

type Props = { theme?: Theme; className?: string };

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return SearchMapNotificationNoMLSChase;
    default:
      return SearchMapNotificationNoMLS;
  }
};

const SearchMapNotificationNoMLSCobranded = (props: Props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default SearchMapNotificationNoMLSCobranded;
