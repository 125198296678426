import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { SVGIconComponentType } from '@client/inline-svgs/types';
import LendcoCarat from '@client/inline-svgs/cobrand/lendco/lendco-carat';
import ChaseCarat from '@client/inline-svgs/chase-carat';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import { MouseEvent } from 'react';

type Props = {
  className?: string;
  onClick?: (e: MouseEvent) => void;
};

const getComponentForCobrand = (cobrandId: string): SVGIconComponentType => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return ChaseCarat as SVGIconComponentType;
    default:
      return LendcoCarat as SVGIconComponentType;
  }
};

const CaratIconCobranded: React.FC<Props> = ({ className, onClick }) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component className={className} onClick={onClick} />;
};

export default CaratIconCobranded;
