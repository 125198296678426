import React from 'react';
import { useSelector } from 'react-redux';
import ScaffoldingUI from '@client/components/ScaffoldingUI/ScaffoldingUI';
import { getScaffoldingCMGHeaderDataForDesktop } from '@client/store/slices/scaffolding-data.slice';

const ScaffoldingHeaderDesktop: React.FC<{}> = () => {
  const elementInstruction = useSelector(getScaffoldingCMGHeaderDataForDesktop);
  if (!elementInstruction) {
    return <></>;
  }

  return <ScaffoldingUI elementInstruction={elementInstruction} />;
};

export default ScaffoldingHeaderDesktop;
