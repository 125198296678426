import React from 'react';

import PDPRightRailCard from '@client/components/PDPRightRailCard/PDPRightRailCard';
import LoadingSection from '@client/components/generic/LoadingSection';
import CarouselRowContainer from '@client/containers/carousel-row.container';
import PropertyIntroContainer from '@client/containers/property-intro.container';
import MapPropertyPageContainer from '@client/containers/map-property-page.container';
import ScrollToTop from '@client/components/ScrollToTop';
import BreakoutSectionsContainer from '@client/containers/breakout-sections.container';
import AvmBreakdownVerticalContainer from '@client/containers/avm-breakdown-vertical.container';
import ListingInfoContainer from '@client/containers/listing-info.container';
import OpenHouseInfo from '@client/components/OpenHouseInfo';
import NearbyListingsContainer from '@client/containers/nearby-listings.container';
import Footer from '@client/containers/footer.container';
import HomeownerUpsellAdContainer from '@client/containers/homeowner-upsell-ad.container';
import ConditionalFeature from '@client/components/ConditionalFeature';
import FindAgentBanner from '@client/components/FindAgent/FindAgentBanner';
import theme from '@client/css-modules/PropertyPageMobileContentsComehome.css';
import PDPFinanceCTA from '@client/components/PDPFinanceCTA';
import MLSAttributionPDPNWMLSException from '@client/components/MLSAttributionPDPNWMLSException';
import { PropertyPageMobileContentsProps } from '@client/store/types/cobranded-components/property-page';
import AdditionalHomeDetails from '@client/components/AdditionalHomeDetails';
import GrantProgramPDPBanner from '@client/components/GrantProgram/GrantProgramPDPBanner';
import { getPropertyDetailPageConfig } from '@client/store/selectors/cobranding.selectors';
import { useSelector } from 'react-redux';
import CustomPropertyBanner from '../CustomPropertyBanner';

/**
 * Contains the contents displayed within the PDP on mobile. Used in both `PropertyPage` and `PropertyPageModal`,
 * and is also the left-hand column of `PropertyPageTabletContents`.
 */
const PropertyPageMobileContentsComehome: React.FC<
  PropertyPageMobileContentsProps
> = ({
  isPDPDataLoaded,
  isActiveListing,
  shouldShowHomeownerAd,
  isTabletView,
  hidePDPRightRailCard,
  hideFinanceCTA,
  isCanaryUIFeatureEnabled,
}) => {

    const propertyPageData = useSelector(getPropertyDetailPageConfig)
    const showCustomBanner = propertyPageData.bannerHeadline && propertyPageData.bannerButtonLabel && propertyPageData.bannerDestinationUrl

    return (
      <div className={theme.PropertyPageMobileContents}>
        {isTabletView ? null : (
          <CarouselRowContainer theme={theme} useFullWidthImage />
        )}
        <MLSAttributionPDPNWMLSException />
        <PropertyIntroContainer
          theme={theme}
          isShowingCondensedPropertyInfo
          isShowingDaysOnMarket
        />
        {!hidePDPRightRailCard && (
          <PDPRightRailCard
            theme={theme}
            isShowingMonthlyPaymentDetails
            isShowingShareAndSaveSection
            isStandaloneModule
            isCanaryUIFeatureEnabled={isCanaryUIFeatureEnabled}
          />
        )}
        {!hideFinanceCTA && isActiveListing && (
          <PDPFinanceCTA isStandaloneModule />
        )}
        <div className={theme.InitDataSection}>
          <LoadingSection
            isLoading={!isPDPDataLoaded}
            className={theme.InitDataLoadingSection}
          >
            {isPDPDataLoaded && (
              <>
                {!isTabletView && (
                  <ConditionalFeature renderIfFeaturesEnabled={['grant_program']}>
                    <GrantProgramPDPBanner />
                  </ConditionalFeature>
                )}
                <OpenHouseInfo theme={theme} />
                <ListingInfoContainer theme={theme} />
                <AdditionalHomeDetails />
                {isTabletView && (
                  <ConditionalFeature renderIfFeaturesEnabled={['grant_program']}>
                    <GrantProgramPDPBanner />
                  </ConditionalFeature>
                )}
                {
                  /* The Homeowner ad is placed differently on the page for onsale/offsale properties */
                  !isActiveListing && shouldShowHomeownerAd && (
                    <HomeownerUpsellAdContainer theme={theme} />
                  )
                }
                {isActiveListing && (
                  <ConditionalFeature
                    renderIfFeaturesEnabled={['referral_services', 'your_team']}
                  >
                    <div className={theme.FindAgentWrapper}>
                      <FindAgentBanner isNarrowLayout />
                    </div>
                  </ConditionalFeature>
                )}
                {showCustomBanner &&
                  (<div
                    className={theme.FindAgentWrapper}
                    data-hc-name="find-agent-section"
                  >
                    <CustomPropertyBanner isNarrowLayout propertyPageData={propertyPageData} />
                  </div>
                  )}
                <MapPropertyPageContainer theme={theme} />
                <AvmBreakdownVerticalContainer theme={theme} />
                <BreakoutSectionsContainer theme={theme} />
                {
                  /* The Homeowner ad is placed differently on the page for onsale/offsale properties */
                  isActiveListing && shouldShowHomeownerAd && (
                    <HomeownerUpsellAdContainer theme={theme} />
                  )
                }
                <NearbyListingsContainer theme={theme} />
                <ScrollToTop />
                <hr className={theme.SectionDivider} />
              </>
            )}
          </LoadingSection>
        </div>
        <div className={theme.FooterSection}>
          <Footer theme={theme} shouldUseSectionElement />
        </div>
      </div>
    );
  };

export default PropertyPageMobileContentsComehome;
