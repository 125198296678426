import { connect } from 'react-redux';
import { getPropertyAddress } from '@client/store/selectors/property-details.selectors';
import { getPropertyAddress as getHomeownerPropertyAddress } from '@client/store/selectors/homeowner.selectors';
import { placeSlugForDescription } from '@client/utils/string.utils';
import Breadcrumbs from '@client/components/Breadcrumbs';

const mapStateToProps = (appState) => {
  const homeownerAddress = getHomeownerPropertyAddress(appState);
  const homebuyerAddress = getPropertyAddress(appState);
  const address = homebuyerAddress || homeownerAddress;
  const { city, streetAddress, unit, state, zipcode } = address || {};
  const cityState = address ? `${city}, ${state}` : null;
  const slug = address ? placeSlugForDescription(cityState) : null;

  return {
    placeSlug: slug,
    cityState: cityState,
    streetAddress: address ? `${streetAddress}${unit && `, ${unit}`}` : null,
    zipcode: address && zipcode ? zipcode : null,
  };
};

export default connect(mapStateToProps, {})(Breadcrumbs);
