import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentQuery } from '@src/redux-saga-router-plus/selectors';

import { View } from '@client/routes/constants';
import theme from '@client/css-modules/HomeownerHomeBuyerToggle.css';
import { PDP_PUBLIC_OWNER_TOGGLE } from '@client/store/constants';
import * as Tabs from '@radix-ui/react-tabs';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import { debounce } from 'lodash';

type Props = {
  isOnHomeowner: boolean;
  slug: string | null;
  reportNavigation: (isOnHomeowner: boolean) => void;
};

enum HomeTabs {
  HOMEOWNER_TAB = "HOMEOWNER_TAB",
  PUBLIC_TAB = "PUBLIC_TAB"
}

export default function HomeownerHomeBuyerToggle(props: Props) {
  const { isOnHomeowner, slug } = props;
  const dispatch = useDispatch();
  const currentQuery = useSelector(getCurrentQuery);
  const [currentTab, setCurrentTab] = useState<HomeTabs>(isOnHomeowner ? HomeTabs.HOMEOWNER_TAB : HomeTabs.PUBLIC_TAB)

  const resetFocusToTabDebounce = debounce((triggerId: string)=> {
    (document.getElementById(triggerId) as HTMLElement)?.focus();
  }, 800)

  return (
    <Tabs.Root
      activationMode="manual"
      defaultValue={HomeTabs.PUBLIC_TAB}
      value={currentTab}
      onValueChange={(valueToChange) => {
        props.reportNavigation(props.isOnHomeowner);
        setCurrentTab(HomeTabs[valueToChange])
        dispatch(routeChange(
          {
            view: props.isOnHomeowner ? View.PROPERTY_DETAILS : View.HOMEOWNER_PROPERTY_DETAILS,
            params: { slug },
            ...(currentQuery[PDP_PUBLIC_OWNER_TOGGLE]
              ? {
                query: {
                  [PDP_PUBLIC_OWNER_TOGGLE]: currentQuery[PDP_PUBLIC_OWNER_TOGGLE],
                },
              }
              : {})
          }
        ))
        resetFocusToTabDebounce(valueToChange)
      }}
    >
      <Tabs.List
        data-hc-name="view-toggles"
        className={theme.HomeownerHomeBuyerToggle}
      >
        {/* Since there is no Tabs.Panel for this control, we need to set the aria-controls to undefined.
            The Tabs package autogenerates an aria-controls property assuming there is a coresponding Tabs.Panel. */}
        <Tabs.Trigger
          id={HomeTabs.PUBLIC_TAB}
          value={HomeTabs.PUBLIC_TAB}
          aria-controls={undefined}
          data-hc-name="public-view-button"
          className={
            isOnHomeowner
              ? theme.ToggleButton
              : classNames(theme.ToggleButton, theme.isActive)
          }
        >

          {!isOnHomeowner && (
            <div className={theme.ScreenReaderOnly}>Currently showing</div>
          )}
          Public view

        </Tabs.Trigger>
        <Tabs.Trigger
          id={HomeTabs.HOMEOWNER_TAB}
          value={HomeTabs.HOMEOWNER_TAB}
          data-hc-name="owner-view-button"
          aria-controls={undefined}
          className={
            isOnHomeowner
              ? classNames(theme.ToggleButton, theme.isActive)
              : theme.ToggleButton
          }
        >
          {isOnHomeowner && (
            <div className={theme.ScreenReaderOnly}>Currently showing</div>
          )}
          Owner view
        </Tabs.Trigger>
      </Tabs.List>
    </Tabs.Root>
  );
}
