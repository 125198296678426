import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';

import PhoneCallIcon from '@client/inline-svgs/phonecall-icon';
import MessageIcon from '@client/inline-svgs/message-icon';
import { formatPhoneNumber } from '@client/utils/string.utils';
import { isPhoneNumberStandard } from '@client/utils/validations.forms';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import LOInfoProvider from '@client/hocs/lo-info-provider';
import defaultTheme from '@client/css-modules/LOContactInfo.css';
import { LOInfoProviderArgs } from '@client/store/types/loan-officer';
import { EVENTS, PARENT_EVENTS } from '@client/store/analytics-constants';

import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import { getCustomizationData } from '@client/store/selectors/cobranding.selectors';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type Props = {
  theme: Theme;
  hidePhoneAndEmail?: boolean;
  shouldShowContactLO?: boolean;
  contactLOButtonText?: string;
  dataHcName?: string;
  defaultPhoneNumber?: string | null;
  openContactLOModal?: () => void;
  contactLOButtonStyle?: React.CSSProperties;
  shouldUseDotSeparator?: boolean;
  shouldUseNewLayout?: boolean;
  shouldHidePhoneCallButtonOnMobile?: boolean;
  shouldDisplayNmlsIdOnSecondLine?: boolean;
};

const LOContactInfo: React.FC<Props> = ({
  theme,
  hidePhoneAndEmail,
  shouldShowContactLO,
  contactLOButtonText,
  dataHcName,
  defaultPhoneNumber,
  openContactLOModal,
  contactLOButtonStyle,
  shouldUseDotSeparator,
  shouldUseNewLayout,
  shouldHidePhoneCallButtonOnMobile,
  shouldDisplayNmlsIdOnSecondLine,
}) => {
  const isMobile = useSelector(getIsSmallSize);
  const [hasImgError, setHasImgError] = useState(false);
  const { lo_generic_info_subtitle } = useSelector(getCustomizationData);
  const { agentContactPhoneIconColor } = useCobrandStyles();

  return (
    <LOInfoProvider>
      {({ loData }: LOInfoProviderArgs) => {
        if (!loData) {
          return null;
        } else {
          const {
            company,
            phone,
            email,
            id,
            photoUrl,
            ohMlo,
            branchNMLS,
            branchAddress,
          } = loData;
          const loFullName = loData?.fullName;
          const isShowingPhoto = photoUrl && !hasImgError;

          const renderedPhone = phone ? phone : defaultPhoneNumber;

          const shouldUsePhoneCallButton =
            isMobile &&
            isPhoneNumberStandard(renderedPhone) &&
            !shouldHidePhoneCallButtonOnMobile;

          if (shouldUseNewLayout) {
            return (
              <section
                className={theme.GenericLOInfo}
                data-hc-name={dataHcName as string}
              >
                <div className={theme.ContactContent}>
                  <div className={theme.TopSection}>
                    <div
                      role="heading"
                      aria-level={1}
                      className={theme.AgentName}
                    >
                      {company} {lo_generic_info_subtitle}
                    </div>

                    {shouldUseDotSeparator &&
                      (company || lo_generic_info_subtitle) &&
                      !shouldDisplayNmlsIdOnSecondLine && (
                        <div className={theme.DotSeparator}> • </div>
                      )}
                    {id && !shouldDisplayNmlsIdOnSecondLine && (
                      <div className={theme.mlsId}>{id}</div>
                    )}
                  </div>
                  <div className={theme.BottomSection}>
                    {id && shouldDisplayNmlsIdOnSecondLine && (
                      <div className={theme.mlsId}>
                        {id}{' '}
                        {shouldUseDotSeparator && (
                          <span className={theme.DotSeparator}> • </span>
                        )}
                      </div>
                    )}

                    <div className={theme.PhoneAndEmail}>
                      {!shouldUsePhoneCallButton &&
                        renderedPhone &&
                        !hidePhoneAndEmail && (
                          <a
                            aria-label={`Call ${loFullName}`}
                            data-event-name={EVENTS.CLICK_LO_CONTACT_CALL}
                            data-parent-event-name={
                              PARENT_EVENTS.CLICK_LO_CONTACT
                            }
                            href={`tel:${renderedPhone}`}
                          >
                            {formatPhoneNumber(renderedPhone)}
                          </a>
                        )}
                      {!shouldUsePhoneCallButton &&
                        renderedPhone &&
                        email &&
                        !hidePhoneAndEmail && (
                          <span className={theme.DotSeparator}> • </span>
                        )}
                      {email && !hidePhoneAndEmail && (
                        <a
                          aria-label={`Send an email to ${loFullName}`}
                          href={`mailto:${email}`}
                        >
                          {email}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
                {shouldUsePhoneCallButton && renderedPhone && (
                  <div>
                    <a
                      style={{
                        color: agentContactPhoneIconColor,
                      }}
                      data-event-name={EVENTS.CLICK_LO_CONTACT_CALL}
                      data-parent-event-name={PARENT_EVENTS.CLICK_LO_CONTACT}
                      className={theme.PhoneCallLink}
                      href={`tel:${renderedPhone}`}
                      title={`Call ${name}`}
                    >
                      <PhoneCallIcon className={theme.PhoneCallIcon} />
                    </a>
                  </div>
                )}
              </section>
            );
          }

          return (
            <section
              className={theme.AgentContactInfo}
              data-hc-name={dataHcName as string}
            >
              <div className={theme.ContactContent}>
                <div className={theme.TopSection}>
                  {isShowingPhoto && (
                    <div className={theme.PhotoColumn}>
                      <img
                        className={theme.AgentPhoto}
                        onError={() => setHasImgError(true)}
                        src={photoUrl}
                        alt={`${loFullName} from ${company}`}
                      />
                    </div>
                  )}
                  <div>
                    <div className={theme.AgentName}>{loFullName}</div>
                    {company && <div className={theme.Company}>{company}</div>}
                    {!!branchAddress && (
                      <div className={theme.LoIdData}>
                        {branchAddress.street && (
                          <span>{`${branchAddress.street} `}</span>
                        )}
                        <span
                          className={theme.BranchAddressCityState}
                        >{`${branchAddress.city}, ${branchAddress.state}`}</span>
                      </div>
                    )}
                    {id && <div className={theme.LoIdData}>{id}</div>}
                    {ohMlo && <div className={theme.LoIdData}>{ohMlo}</div>}
                    {branchNMLS && (
                      <div className={theme.LoIdData}>{branchNMLS}</div>
                    )}
                  </div>
                </div>
                {!hidePhoneAndEmail && (
                  <div
                    className={
                      isShowingPhoto
                        ? classNames(theme.PhoneAndEmail, theme.withLeftMargin)
                        : theme.PhoneAndEmail
                    }
                  >
                    {!shouldUsePhoneCallButton &&
                      phone &&
                      !hidePhoneAndEmail && (
                        <a
                          data-event-name={EVENTS.CLICK_LO_CONTACT_CALL}
                          data-parent-event-name={
                            PARENT_EVENTS.CLICK_LO_CONTACT
                          }
                          href={`tel:${phone}`}
                          title={`Call ${loFullName}`}
                        >
                          {formatPhoneNumber(phone)}
                        </a>
                      )}
                    {!shouldUsePhoneCallButton &&
                      phone &&
                      email &&
                      !hidePhoneAndEmail && (
                        <span className={theme.PhoneAndEmailDivider}>
                          {' • '}
                        </span>
                      )}
                    {email && !hidePhoneAndEmail && (
                      <a
                        data-event-name={EVENTS.CLICK_LO_CONTACT_EMAIL}
                        data-parent-event-name={PARENT_EVENTS.CLICK_LO_CONTACT}
                        href={`mailto:${email}`}
                        title={`Send an email to ${loFullName}`}
                        aria-label={`Send an email to ${loFullName}`}
                        target="_blank"
                      >
                        {email}
                      </a>
                    )}
                  </div>
                )}
                {shouldShowContactLO && openContactLOModal && (
                  <button
                    onClick={openContactLOModal}
                    onKeyDown={onEnterOrSpaceKey(openContactLOModal)}
                    className={theme.ContactLOButton}
                    style={contactLOButtonStyle}
                  >
                    <MessageIcon className={theme.ContactLOMessageIcon} />
                    <span className={theme.ContactLOButtonText}>
                      Contact {contactLOButtonText}
                    </span>
                  </button>
                )}
              </div>
              {shouldUsePhoneCallButton && phone && (
                <div>
                  <a
                    style={{
                      color: agentContactPhoneIconColor,
                    }}
                    data-event-name={EVENTS.CLICK_LO_CONTACT_CALL}
                    data-parent-event-name={PARENT_EVENTS.CLICK_LO_CONTACT}
                    className={theme.PhoneCallLink}
                    href={`tel:${phone}`}
                    aria-label={`Call ${loFullName}`}
                    title={`Call ${loFullName}`}
                  >
                    <PhoneCallIcon className={theme.PhoneCallIcon} />
                  </a>
                </div>
              )}
            </section>
          );
        }
      }}
    </LOInfoProvider>
  );
};

const ThemedLOContactInfo = themr('LOContactInfo', defaultTheme)(LOContactInfo);

export default ThemedLOContactInfo;
