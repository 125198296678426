import { put, call } from 'redux-saga/effects';
import { watchLatest } from '@client/utils/saga.utils';
import {
  FETCH_MAP_LAYER_LEGEND_BREAKS,
  fetchMapLegendBreaksSuccess,
  mapLayerLegendBreaksNotAvailable,
} from '@client/store/actions/map-legend-breaks.actions';
import {
  latLngToTileCoords,
  getSourceKeyForLayerAndZoomLevel,
} from '@client/utils/maps.utils';
import { consumerApiClient } from '@client/services/consumer-api-client';
import { LegendBreaksCache, MapLegendStats } from '@client/store/types/maps';

/* Caching breaks for a specific tile range prevents unnecessarily frequent requests
 * due to this saga being triggered on map move */
const legendBreaksCache: LegendBreaksCache = {};

function* getMapLegendBreaks(action) {
  const { southWest, northEast, zoom, metric } = action.payload;
  const integerZoom = Math.floor(zoom);
  const sourceKeyword = getSourceKeyForLayerAndZoomLevel(metric, integerZoom);

  /* Only get breaks if zoom level is within range for active metric or if not metric is passed,
   * use the default zoom level -> source keyword mapping */
  if (sourceKeyword) {
    const tileNE = latLngToTileCoords({
      lat: northEast.lat,
      lng: northEast.lng,
      zoom: integerZoom,
    });
    const tileSW = latLngToTileCoords({
      lat: southWest.lat,
      lng: southWest.lng,
      zoom: integerZoom,
    });
    const tileRangeURLSegment = `${tileSW.x}-${tileNE.x}/${tileNE.y}-${tileSW.y}`;
    const cachedBreaks = legendBreaksCache[tileRangeURLSegment];

    /* Used cached data if available */
    if (cachedBreaks) {
      yield put(fetchMapLegendBreaksSuccess(cachedBreaks));
    } else {
      /* Don't attempt to fetch stats on negative tile coordinates, as this results in 404 response
       * (occurs when panning map over Asia) */
      if (tileSW.x > 0 && tileNE.x > 0) {
        const data = yield call(
          [consumerApiClient, consumerApiClient.getMapLegendBreaksData],
          sourceKeyword,
          integerZoom,
          tileRangeURLSegment
        );
        const propertyStats: MapLegendStats = data.property_stats;
        /* Add to cache */
        legendBreaksCache[tileRangeURLSegment] = propertyStats;
        yield put(fetchMapLegendBreaksSuccess(propertyStats));
      } else {
        yield put(mapLayerLegendBreaksNotAvailable());
      }
    }
  } else {
    yield put(mapLayerLegendBreaksNotAvailable());
  }
}

export default (sagaMiddleware) => {
  watchLatest(sagaMiddleware, {
    [FETCH_MAP_LAYER_LEGEND_BREAKS]: getMapLegendBreaks,
  });
};
