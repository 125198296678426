import React, { ReactNode } from 'react';
import theme from '@client/css-modules/AdminPage.css';
import SidebarNav from './SidebarNav';

const dataHcName = 'AdminPage';

const AdminPage = ({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) => (
  <div className={theme.AdminPage}>
    <SidebarNav />
    <div className={theme.Container}>
      <header>
        <h1 data-hc-name={`${dataHcName}-header`}>{title}</h1>
      </header>
      {children}
    </div>
  </div>
);

export default AdminPage;
