import {
  fetchUserLocationFromDevice,
  fetchUserLocationViaIP,
} from '@hc/hcmaps-mapboxgl/lib/services/user-location';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import {
  getCurrentQuery,
  getCurrentView,
} from '@src/redux-saga-router-plus/selectors';
import { flatten, get, groupBy, intersection, isEmpty, values } from 'lodash';
import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  select,
} from 'redux-saga/effects';

import HC_CONSTANTS from '@client/app.config';
import { ALL_SEARCH_VIEWS, View } from '@client/routes/constants';
import { graphQLApiClient } from '@client/services/graphql-api-client';
import { reportEvent } from '@client/store/actions/analytics.actions';
import {
  SEARCH_APPLY_SAVED_FILTERS,
  SEARCH_CANCEL_PENDING_QUERIES,
  SEARCH_CLEAR_ALL_FILTERS,
  SEARCH_FETCH_MAP_PROPERTIES_FOR_BOUNDS,
  SEARCH_FETCH_MORE_LIST_PROPERTIES,
  SEARCH_FETCH_MORE_SIDEBAR_PROPERTIES,
  SEARCH_FETCH_PLACE_DETAILS,
  SEARCH_FETCH_PROPERTY_LIST,
  SEARCH_FETCH_SELECTED_MAP_PROPERTY,
  SEARCH_FIELD_CLICK,
  SEARCH_GET_LAST_SEARCH_SUCCESS,
  SEARCH_GET_USER_LOCATION_SUCCESS,
  SEARCH_GET_USER_LOCATION_VIA_SELECTION,
  SEARCH_GET_USER_LOCATION_WHEN_NOT_AVAILABLE,
  SEARCH_HIGHLIGHT_SCHOOL_MARKERS,
  SEARCH_LIST_APPLY_SORT,
  SEARCH_PROPERTY_CARD_HOVER,
  SEARCH_REPORT_UPDATE_TEXT_BOX,
  SEARCH_ROUTE_CHANGE_TO_HIDE_PDP_MODAL,
  SEARCH_SET_MAP_LOCATION_TO_LAST_VIEWED_PDP,
  SEARCH_SET_PDP_MODAL_MARKER_DATA_AND_SHOW_MODAL,
  SEARCH_UPDATE_FILTER,
  SEARCH_UPDATE_URL_PARAMS,
  searchClearMapNonClusterMarkers,
  searchFetchPropertyList,
  SearchFetchPropertyListAction,
  searchFetchPropertyListSuccess,
  searchFetchSelectedMapPropertySuccess,
  searchFilterMapMarkersByZoom,
  searchGetUserLocationSuccess,
  searchListHandleOpenOrClose,
  searchRemoveTemporaryMarker,
  searchSetMapMarkersForTile,
  searchSetMapViewport,
  searchSetPropertyCount,
  searchSetPulseLocations,
  searchShowTemporaryMarker,
  searchUpdateMapMultiUnitPropertyCache,
  searchUpdateMapTileCache,
  searchUpdateUrlParams,
  SearchUpdateUrlParamsAction,
} from '@client/store/actions/search.actions';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import {
  PROPERTY_LIST_PAGE_SIZE,
  SEARCH_ACTIVE_VIEW_URL_PARAM_KEY,
  SEARCH_ACTIVE_VIEW_URL_PARAM_OPTIONS,
  SEARCH_MAP_MOBILE_FILTERS_SHOWN_URL_KEY,
  SEARCH_MAP_SELECTED_ADDRESS_SLUG_URL_PARAM_KEY,
  SESSION_STORAGE_KEYS,
} from '@client/store/constants';
import {
  FILTER_KEY_ABBREVIATIONS,
  FILTER_KEYS,
} from '@client/store/filter-constants';
import {
  MAP_FALLBACK_LOCATION,
  MAP_MARKER_ACTIVE_LISTING_TEXT_COLOR,
  MAP_MARKER_OFF_MARKET_TEXT_COLOR,
  MAP_MARKER_OFF_MARKET_VISITED_TEXT_COLOR,
  MARKER_IMAGE_IDS,
} from '@client/store/map-constants';
import {
  SpatialSearchCountResult,
  SpatialSearchDetail,
  SpatialSearchDetailResults,
  SpatialSearchOverCountResult,
  SpatialSearchResults,
} from '@client/store/sagas/queries/types';
import { getSearchResultsPageConfig } from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  getGQLFormattedSearchFilters,
  getIsShowingMapMarkers,
  getIsShowingSearchPageList,
  getSearchAdditionalTileZoom,
  getSearchConstrainedPlace,
  getSearchFiltersForQueryParams,
  getSearchFilterValues,
  getSearchIsShowingMobileFilters,
  getSearchListCursor,
  getSearchListSortField,
  getSearchListSortOrder,
  getSearchLocationForQueryParam,
  getSearchMarkerFeaturesMinZoom,
  getSearchMarkersByTile,
  getSearchText,
  getSearchTileCache,
  getSearchTilePropertyLimit,
  getSearchViewport,
  getSearchZoom,
} from '@client/store/selectors/search.selectors';
import { getWatchListItems } from '@client/store/selectors/watchlist.selectors';
import { updateContextualSearchId } from '@client/store/slices/analytics-data.slice';
import {
  BoundsObject,
  LatitudeLongitudeObject,
  LatLngObject,
  MapMarker,
  MultiUnitDataObject,
  TileCoord,
} from '@client/store/types/maps';
import { InitPropertyLookupWithAddressRequired } from '@client/store/types/property';
import {
  getIsSpatialSearchDetailWithAddress,
  ModifiedSpatialSearchDetailResponse,
  ModifiedSpatialSearchResponse,
  MultiUnitTileCacheEntry,
  SpatialSearchDetailWithAddressRequired,
} from '@client/store/types/search';
import { reportToSentry } from '@client/utils/error.utils';
import {
  buildAPIClusterMarker,
  buildMultiUnitClusterMarker,
  buildMultiUnitDataObject,
  buildPropertyMarker,
  filterMarkersByTile,
  getCenterPointOfTile,
  getChildTileCoordsForCoords,
  getMultiUnitClusterKey,
  getParentTileCoordsForCoords,
  getTileKeyForTile,
  getTilesInBounds,
  getZoomFromBounds,
} from '@client/utils/maps.utils';
import {
  getIsActiveListing,
  getPropertyPrice,
  normalizePropertyData,
} from '@client/utils/property.utils';
import { watchEvery, watchLatest } from '@client/utils/saga.utils';
import { abbrNumberFormatter } from '@client/utils/string.utils';

const isMultiUnitClusterDataItem = (
  data: MultiUnitTileCacheEntry | SpatialSearchDetailWithAddressRequired
): data is MultiUnitTileCacheEntry => {
  return !!(data as MultiUnitTileCacheEntry).multiUnitClusterLocation;
};

const isSpatialSearchResultsWithHits = (
  tileData: SpatialSearchResults
): tileData is SpatialSearchDetailResults => {
  return !!(tileData as SpatialSearchDetailResults).hits;
};
const isModifiedSpatialSearchResultsWithHits = (
  tileData: ModifiedSpatialSearchResponse['propertySpatialSearch']
): tileData is ModifiedSpatialSearchDetailResponse => {
  return !!(tileData as SpatialSearchDetailResults).hits;
};
const isSpatialSearchCountResult = (
  tileData: ModifiedSpatialSearchResponse['propertySpatialSearch']
): tileData is SpatialSearchCountResult => {
  return typeof (tileData as SpatialSearchCountResult).count === 'number';
};
const isSpatialSearchOverCountResult = (
  tileData: ModifiedSpatialSearchResponse['propertySpatialSearch']
): tileData is SpatialSearchOverCountResult => {
  return (
    typeof (tileData as SpatialSearchOverCountResult).moreThan === 'number'
  );
};

const { GEOLOCATION_ENDPOINT } = HC_CONSTANTS;
let activeTasks = {};

export const MAX_TILE_PROPERTIES_LIMIT = 500;
export const STOP_CLUSTERING_AT_ZOOM = 19;
const ALLOW_MIXED_MARKERS_AND_CLUSTERS_FOR_ZOOM = 13;
/* Number over which we won't display properties for a multi-unit cluster */
const MULTI_UNIT_MAX_PROPERTY_COUNT = 100;
const TASK_PREFIXES = {
  MARKERS: 'markers',
  PROPERTY_LIST: 'property-list',
};

/* If at a certain zoom level or below and a marker cluster is loaded from the API,
 * this boolean will be set to `true`, which will cause all existing markers to be
 * replaced with clusters and clusters to be used in place of future loaded markers */
let onlyClustersAllowedOnMap: boolean = false;
/* Used to cache marker stats for each tile so that we can replace the markers
 * in each tile with a single cluster if necessary */
let propertyMarkerStatsByTile: {
  markerCount: number;
  labelLocation: LatitudeLongitudeObject;
  tile;
}[] = [];
let currentZoom: number | null = null;
let initialMapPropertiesRequestSent: boolean = false;

const getFallbackLabelLocationForTile = (
  tile: TileCoord
): LatitudeLongitudeObject => {
  const tileCenter = getCenterPointOfTile(tile);
  return {
    latitude: tileCenter.lat,
    longitude: tileCenter.lng,
  };
};

/**
 * Get property data for a tile either from the cache or from the API, build markers from
 * that property data, and set markers on state.
 * @param {object} tile coords
 * @yield {void}
 */
export function* getPropertyDataForTile(
  { x, y, z }: TileCoord,
  lastZoom: number | null
) {
  const tile = { x, y, z };
  const tileKey = getTileKeyForTile(tile);
  const filtersMapping = yield select(getGQLFormattedSearchFilters);
  const tilePropertyLimit = yield select(getSearchTilePropertyLimit);
  /* Get all the properties that we can reasonably display in the UI when at full zoom.
   * There are multi-unit buildings with > 500 off-market properties, but we can't
   * yet display them appropriately */
  const limit =
    z >= STOP_CLUSTERING_AT_ZOOM
      ? MAX_TILE_PROPERTIES_LIMIT
      : tilePropertyLimit;
  const tileCache = yield select(getSearchTileCache);
  const cachedTile = tileCache[tileKey];
  let markerArr = [];
  let removeMarkersFromTileKeys;

  /* When zooming out, remove markers currently on the map that belong to child tiles and load the
   * this tile from the API since it might contain an API-cluster marker */
  if (lastZoom && lastZoom > z) {
    removeMarkersFromTileKeys = getChildTileCoordsForCoords(
      tile,
      lastZoom - z
    ).map((tile) => getTileKeyForTile(tile));
    /* When zooming in, possibly redistribute existing markers amongst children, possibly load the tile
     * from the API */
  } else if (lastZoom && lastZoom < z) {
    const parentTileCoords = getParentTileCoordsForCoords(tile, z - lastZoom);
    const parentTileKey = getTileKeyForTile(parentTileCoords);
    const mapMarkersByTile = yield select(getSearchMarkersByTile);
    const parentTileMarkers = mapMarkersByTile[parentTileKey];

    /* If parent tile has no markers, child tile will have no markers; skip loading tile from the API */
    if (parentTileMarkers && parentTileMarkers.length === 0) {
      yield put(
        searchSetMapMarkersForTile({
          tile,
          markers: [],
          removeMarkersFromTileKeys: [parentTileKey],
        })
      );
      return;
      /* If parent tile has no API-cluster markers, we know this tile won't have API-cluster markers.
       * Assign the parent's markers that belong within this tile to the tile and skip loading the tile
       * from the API */
    } else if (
      parentTileMarkers &&
      parentTileMarkers.filter(
        (marker) => marker.isProperty || marker.isMultiUnitCluster
      ).length > 0
    ) {
      const childTileMarkers = filterMarkersByTile(parentTileMarkers, {
        x,
        y,
        z,
      });
      const newParentTileMarkers =
        childTileMarkers.length === 0
          ? parentTileMarkers
          : parentTileMarkers.filter(
              (parentMarker) =>
                !childTileMarkers.find(
                  (childMarker) =>
                    parentMarker.uId === (childMarker && childMarker.uId)
                )
            );
      /* Replace parent markers, removing those now assigned to the child */
      yield put(
        searchSetMapMarkersForTile({
          tile: parentTileCoords,
          markers: newParentTileMarkers,
        })
      );
      /* Add new child markers */
      yield put(
        searchSetMapMarkersForTile({
          tile,
          markers: childTileMarkers,
        })
      );
      /* EXIT NOW, since we don't want to update the tile cache or load new markers from the API */
      return;
      /* If the parent tile contains an API-cluster marker or doesn't exist on state (which is a rare edge-case),
       * we must reload this tile from the API to get either a new cluster marker or property markers */
    } else {
      removeMarkersFromTileKeys = [parentTileKey];
    }
  }

  /* The below is executed only if the above conditional fails (i.e. we're not reassigning parent markers to this tile) */
  /* If the tile is cached, used the cached version */
  if (cachedTile) {
    markerArr = yield call(buildMarkersFromTilePropertyData, cachedTile, tile);
    /* If not cached, get from the API and add to cache */
  } else {
    const spatialId = yield call(getPropertiesRequestSpatialId, { tile });
    const { data, errors } = yield call(
      [graphQLApiClient, graphQLApiClient.getSpatialSearchMapData],
      { spatialId, filtersMapping, limit }
    );
    const propertySpatialSearch =
      data.propertySpatialSearch as SpatialSearchResults | null;
    let hitsGroupedByLatLng: {
      [key: string]: (MultiUnitTileCacheEntry | SpatialSearchDetail)[];
    } = {};

    /* In the rare case of property-graph returning invalid data, exit early (don't cache the tile)
     * and report to sentry */
    if (!propertySpatialSearch) {
      reportToSentry(
        errors?.length
          ? `null propertySpatialSearch result: ${errors[0]?.message}`
          : 'null result returned for propertySpatialSearch',
        {
          data,
          errors: errors && JSON.stringify(errors),
          spatialId,
          filtersMapping,
          limit,
        }
      );
      yield put(
        searchSetMapMarkersForTile({
          markers: [],
          tile,
          removeMarkersFromTileKeys,
        })
      );
      return;
    }

    const hits =
      isSpatialSearchResultsWithHits(propertySpatialSearch) &&
      propertySpatialSearch.hits;

    /* If properties are returned (i.e. not an API-returned cluster), build cache of multi-
     * unit properties */
    if (hits) {
      let multiUnitMarkersByLatLng = {} as {
        [key: string]: MultiUnitDataObject[];
      };
      hitsGroupedByLatLng = groupBy(hits, (hit) => {
        const { latitude, longitude } = hit.summary?.geoLocation || {};
        return (
          latitude &&
          longitude &&
          getMultiUnitClusterKey({ latitude, longitude })
        );
      });

      /* Collect multi-unit properties in an object keyed by latLng to set on state */
      Object.keys(hitsGroupedByLatLng).forEach((latLng) => {
        /* If multiple properties exist at the lat/lng, build multi-unit marker */
        if (hitsGroupedByLatLng[latLng].length > 1) {
          /* Add to collection for later property retrieval, limiting number to defined maximum */
          multiUnitMarkersByLatLng[latLng] = hitsGroupedByLatLng[latLng]
            .slice(0, MULTI_UNIT_MAX_PROPERTY_COUNT)
            /* Filter out any properties that have missing address or geoLocation data */
            .filter((item) => getIsSpatialSearchDetailWithAddress(item))
            .map((item) => {
              const verifiedItem =
                item as SpatialSearchDetailWithAddressRequired;
              return buildMultiUnitDataObject({
                addressSlug: verifiedItem.property.address.slug,
                latitude: verifiedItem.summary.geoLocation.latitude,
                longitude: verifiedItem.summary.geoLocation.longitude,
                normalizedPropertyData: normalizePropertyData(
                  /* Add geoLocation to property to conform with typing reqs */
                  {
                    ...verifiedItem.property,
                    geoLocation: verifiedItem.summary.geoLocation,
                  },
                  verifiedItem.summary
                ),
                shouldNotBeSetOnState: false,
              });
            })
            .filter((item): item is NonNullable<typeof item> => !!item);
          /* Overwrite value, setting cluster data */
          hitsGroupedByLatLng[latLng] = [
            {
              multiUnitClusterLocation: {
                latitude: (
                  hitsGroupedByLatLng[
                    latLng
                  ][0] as SpatialSearchDetailWithAddressRequired
                ).summary.geoLocation!.latitude!,
                longitude: (
                  hitsGroupedByLatLng[
                    latLng
                  ][0] as SpatialSearchDetailWithAddressRequired
                ).summary.geoLocation!.longitude!,
              },
              multiUnitCount: hitsGroupedByLatLng[latLng].length,
              childAddressSlugs: hitsGroupedByLatLng[latLng].map((hit) =>
                get(hit, ['property', 'address', 'slug'])
              ),
              tractId: (
                hitsGroupedByLatLng[
                  latLng
                ][0] as SpatialSearchDetailWithAddressRequired
              ).summary!.tractId,
              hasAllActiveListings:
                hitsGroupedByLatLng[latLng].filter((property) =>
                  getIsActiveListing(get(property, ['summary', 'mlsState']))
                ).length === hitsGroupedByLatLng[latLng].length,
            },
          ];
          /* If only a single property exists at the lat/lng, ensure it contains complete property data */
        } else {
          hitsGroupedByLatLng[latLng] = hitsGroupedByLatLng[latLng].filter(
            (item) => getIsSpatialSearchDetailWithAddress(item)
          );
        }
      });
      if (Object.keys(multiUnitMarkersByLatLng).length) {
        yield put(
          searchUpdateMapMultiUnitPropertyCache(multiUnitMarkersByLatLng)
        );
      }
    }
    const dataWithMultUnitClusters = isSpatialSearchResultsWithHits(
      propertySpatialSearch
    )
      ? {
          propertySpatialSearch: {
            ...(propertySpatialSearch || {}),
            hits:
              Object.keys(hitsGroupedByLatLng).length > 0
                ? flatten(values(hitsGroupedByLatLng))
                : [],
          },
        }
      : { propertySpatialSearch };
    markerArr = yield call(
      buildMarkersFromTilePropertyData,
      dataWithMultUnitClusters,
      tile
    );
    yield put(
      searchUpdateMapTileCache({ data: dataWithMultUnitClusters, tile })
    );
  }

  /* Add property and cluster markers to map */
  yield put(
    searchSetMapMarkersForTile({
      markers: markerArr.filter((marker) => marker),
      tile,
      removeMarkersFromTileKeys,
    })
  );
}

/**
 * Given property data for a tile, build and return markers for that tile
 * @param {object} API returned data for a tile
 * @param {number} the zoom level of the tile
 * @yield {array} markers (either property or cluster) for the tile
 */
export function* buildMarkersFromTilePropertyData(
  response: ModifiedSpatialSearchResponse,
  tile: TileCoord
) {
  const tileData = response.propertySpatialSearch;
  if (!tileData) {
    return [];
  }
  const visitedPropertiesString =
    window.sessionStorage &&
    window.sessionStorage.getItem(SESSION_STORAGE_KEYS.VISITED_PROPERTIES);
  const additionalTileZoom = (yield select(
    getSearchAdditionalTileZoom
  )) as ReturnType<typeof getSearchAdditionalTileZoom>;
  const smallerClusterSize = additionalTileZoom > 1;
  let output: MapMarker[] = [];

  /* If properties are returned (i.e. not a cluster) */
  if (isModifiedSpatialSearchResultsWithHits(tileData)) {
    const properties = tileData.hits as (
      | MultiUnitTileCacheEntry
      | SpatialSearchDetailWithAddressRequired
    )[];

    /* The label location that we'll use if we decided to switch individual markers
     * for clusters later */
    const labelCoordinates: [number, number] | null =
      (tileData as SpatialSearchDetailResults).labelLocation?.coordinates ||
      null;
    let labelLocation = labelCoordinates && {
      latitude: labelCoordinates[1],
      longitude: labelCoordinates[0],
    };
    /* API can return a null labelLocation in some cases of external services being unavailable */
    if (!labelLocation) {
      labelLocation = getFallbackLabelLocationForTile(tile);
    }
    /* If a cluster has been returned by the API previously, this method should return a cluster
     * for all future tiles, until a zoom or location change resets this flag */
    if (onlyClustersAllowedOnMap) {
      if (properties.length) {
        const clusterMarker = buildAPIClusterMarker({
          latitude: labelLocation.latitude,
          longitude: labelLocation.longitude,
          label: `${properties.length}`,
          smallerClusterSize,
        });
        output = clusterMarker ? [clusterMarker] : [];
      }
    } else {
      const watchListItems = (yield select(getWatchListItems)) as ReturnType<
        typeof getWatchListItems
      >;
      const propertyMarkers = properties.map((item) => {
        const status = get(item, ['summary', 'mlsState']);
        const slug = get(item, ['property', 'address', 'slug']);
        const isActiveListing = getIsActiveListing(status);
        return isMultiUnitClusterDataItem(item)
          ? buildMultiUnitClusterMarker({
              imageId: item.hasAllActiveListings
                ? MARKER_IMAGE_IDS.ON_MARKET
                : MARKER_IMAGE_IDS.OFF_MARKET,
              labelColor: item.hasAllActiveListings
                ? MAP_MARKER_ACTIVE_LISTING_TEXT_COLOR
                : MAP_MARKER_OFF_MARKET_TEXT_COLOR,
              latitude: item.multiUnitClusterLocation.latitude,
              longitude: item.multiUnitClusterLocation.longitude,
              label: `${item.multiUnitCount} Units`,
              propertyCount: item.multiUnitCount,
              childAddressSlugs: item.childAddressSlugs,
              isCheckedStyle: !!watchListItems.find((watchListItem) =>
                item.childAddressSlugs.includes(watchListItem.slug)
              ),
              tractId: item?.tractId || null,
            })
          : buildPropertyMarker({
              addressSlug: slug,
              imageId: isActiveListing
                ? MARKER_IMAGE_IDS.ON_MARKET
                : MARKER_IMAGE_IDS.OFF_MARKET,
              visitedImageId: isActiveListing
                ? MARKER_IMAGE_IDS.ON_MARKET_VISITED
                : MARKER_IMAGE_IDS.OFF_MARKET,
              latitude: get(item, ['summary', 'geoLocation', 'latitude']),
              longitude: get(item, ['summary', 'geoLocation', 'longitude']),
              label: abbrNumberFormatter(
                getPropertyPrice(
                  /* Add geoLocation to property to conform with typing reqs */
                  {
                    ...item.property,
                    geoLocation: item.summary.geoLocation,
                  },
                  item.summary
                )
              ),
              labelColor: isActiveListing
                ? MAP_MARKER_ACTIVE_LISTING_TEXT_COLOR
                : MAP_MARKER_OFF_MARKET_TEXT_COLOR,
              visitedLabelColor: isActiveListing
                ? MAP_MARKER_ACTIVE_LISTING_TEXT_COLOR
                : MAP_MARKER_OFF_MARKET_VISITED_TEXT_COLOR,
              normalizedPropertyData: normalizePropertyData(
                /* Add geoLocation to property to conform with typing reqs */
                {
                  ...item.property,
                  geoLocation: item.summary.geoLocation,
                },
                item.summary!
              ),
              buildingId: item?.summary?.hcBuildingId ?? null,
              tractId: item?.summary.tractId,
              isCheckedStyle: !!watchListItems.find(
                (watchListItem) => watchListItem.slug === slug
              ),
              isVisitedStyle:
                !!visitedPropertiesString &&
                visitedPropertiesString.split(',').indexOf(slug) > -1,
              isPulsing: false,
            });
      });
      output = propertyMarkers.filter(
        (marker): marker is NonNullable<typeof marker> => marker !== null
      );
      if (output.length && tile.z < ALLOW_MIXED_MARKERS_AND_CLUSTERS_FOR_ZOOM) {
        propertyMarkerStatsByTile.push({
          markerCount: output.length,
          labelLocation: labelLocation,
          tile,
        });
      }
    }
    /* If a cluster is returned by the API */
    /* API can return a null `propertySpatialSearch` in some cases of external services being unavailable */
  } else if (
    !isEmpty(tileData) &&
    (isSpatialSearchCountResult(tileData) ||
      isSpatialSearchOverCountResult(tileData))
  ) {
    /* API can return a null `labelLocation`` in some cases of external services being unavailable */
    const labelLocation =
      tileData.labelLocation || getFallbackLabelLocationForTile(tile);
    output = [];

    /* If we're not already only allowing clusters and if the zoom is low enough,
     * switch to only allowing clusters */
    if (
      !onlyClustersAllowedOnMap &&
      tile.z < ALLOW_MIXED_MARKERS_AND_CLUSTERS_FOR_ZOOM
    ) {
      yield put(searchClearMapNonClusterMarkers());
      /* Add clusters where properties markers used to exist */
      yield all(
        propertyMarkerStatsByTile.map((statsForTile) => {
          const clusterMarker = buildAPIClusterMarker({
            latitude: statsForTile.labelLocation.latitude,
            longitude: statsForTile.labelLocation.longitude,
            label: `${statsForTile.markerCount}`,
            smallerClusterSize,
          });
          return put(
            searchSetMapMarkersForTile({
              markers: clusterMarker ? [clusterMarker] : [],
              tile: statsForTile.tile,
            })
          );
        })
      );
      /* Once we've replaced the markers with clusters we don't want to replace them again */
      propertyMarkerStatsByTile = [];
      /* Set flag so that a cluster marker will be generated for all future tiles (until a zoom or location change) */
      onlyClustersAllowedOnMap = true;
    }
    const clusterMarker = buildAPIClusterMarker({
      latitude: labelLocation.latitude,
      longitude: labelLocation.longitude,
      label: isSpatialSearchOverCountResult(tileData)
        ? `${tileData.moreThan}+`
        : `${tileData.count}`,
      smallerClusterSize,
    });
    if (clusterMarker) {
      /* Output cluster marker */
      output.push(clusterMarker);
    }
  }
  return output;
}

/**
 * Fetch properties to be displayed in property cards either in the sidebar (desktop, tablet) or
 * the full page list (mobile). mockLastUpdatedTimestamp is only used in the tests to allow the
 * put assertion to pass.
 */
export function* getPropertyListData({
  bounds,
  zoom,
  cursor,
  append,
  sortOrder,
  sortField,
  mockLastUpdatedTimestamp,
}: {
  bounds: BoundsObject;
  zoom?: number;
  cursor: string;
  append: boolean;
  sortOrder?: string;
  sortField?: string;
  mockLastUpdatedTimestamp?: number;
}) {
  const filtersMapping = yield select(getGQLFormattedSearchFilters);
  const minMarkerFeatureZoom = (yield select(
    getSearchMarkerFeaturesMinZoom
  )) as number;

  /* Return early and display an error message if zoom is too low */
  if (zoom && zoom < minMarkerFeatureZoom) {
    yield put(
      searchFetchPropertyListSuccess({
        properties: [],
        totalCount: null,
        cursor: null,
        atEnd: false,
        append: false,
        watchlistProperties: [],
        isErrorResponse: false,
        lastUpdatedTimestamp:
          `${mockLastUpdatedTimestamp || ''}` || `${Date.now()}`,
      })
    );
    /* Need to update the zoom stored on state so that the error message on the mobile list page is correct */
    yield put(
      searchSetMapViewport({
        southWest: bounds.southWest,
        northEast: bounds.northEast,
        zoom,
        shouldResetPropertyListState: false,
      })
    );
    /* Exit early to avoid making unnecessary request */
    return;
  }
  const { southWest, northEast } = bounds;
  const spatialId = yield call(getPropertiesRequestSpatialId, {
    southWest,
    northEast,
  });

  const srpPageConfig = (yield select(
    getSearchResultsPageConfig
  )) as ReturnType<typeof getSearchResultsPageConfig>;

  const sort =
    sortOrder && sortField ? { field: sortField, order: sortOrder } : null;
  const { data, errors } = yield call(
    [graphQLApiClient, graphQLApiClient.getSpatialSearchListProperties],
    {
      includeRentalAvm: !!srpPageConfig.showRentalEstimate,
      spatialId,
      filtersMapping,
      limit: PROPERTY_LIST_PAGE_SIZE,
      cursor,
      sort,
    }
  );

  /* Can be null if query is overly large with filters, sort.  API is working on fixing, but need to account for it.
   * TODO add an error status to the action payload in this case and use it display a better error message */
  const detailResults =
    data.propertySpatialSearch as SpatialSearchDetailResults;
  /* Filter out any properties that have missing address or geoLocation data */
  const properties = detailResults?.hits?.filter((item) => {
    return getIsSpatialSearchDetailWithAddress(item);
  });

  if (!detailResults) {
    reportToSentry(
      errors?.length
        ? `null propertySpatialSearch result: ${errors[0]?.message}`
        : 'null result returned for propertySpatialSearch',
      { data, errors, spatialId, filtersMapping, cursor, sort }
    );
  }

  const outCursor = detailResults?.cursor || null;
  const atEnd = detailResults?.atEnd || false;
  const totalCount = detailResults?.totalCount || null;
  const watchlistProperties = yield select(getWatchListItems);
  yield put(
    searchFetchPropertyListSuccess({
      /**
       * Prevent property to be undefined to avoid type error in search.selectors.ts 👇🏼
       * https://sentry.io/organizations/housecanarycom/issues/2202304298/?project=1258985
       */
      properties: (properties ??
        []) as SpatialSearchDetailWithAddressRequired[],
      totalCount,
      cursor: outCursor,
      atEnd,
      append,
      watchlistProperties,
      isErrorResponse: detailResults === null,
      lastUpdatedTimestamp:
        `${mockLastUpdatedTimestamp || ''}` || `${Date.now()}`,
    })
  );
}

/**
 * Get the spatial ID for requesting properties, taking into account whether we're
 * seeking to constrain the search by a place slug or ID.  Tile params will be added
 * when requesting properties within map tiles and omitted when requesting properties
 * for the property list
 */
export function* getPropertiesRequestSpatialId({
  southWest,
  northEast,
  tile,
}: {
  southWest?: LatLngObject;
  northEast?: LatLngObject;
  tile?: TileCoord;
}) {
  const constrainedToPlace = yield select(getSearchConstrainedPlace);
  const { placeId } = constrainedToPlace || { placeId: null };

  return {
    ...(southWest && northEast
      ? {
          bbox: {
            swLng: southWest.lng,
            swLat: southWest.lat,
            neLng: northEast.lng,
            neLat: northEast.lat,
          },
        }
      : {}),
    ...(placeId ? { place: { placeId } } : {}),
    ...(tile ? { tile: { x: tile.x, y: tile.y, zoom: tile.z } } : {}),
  };
}

/**
 * Get data for the property list, displayed both with and without the map on desktop and
 * as a standalone "list" view on mobile
 */
export function* getPropertyListProperties(
  action: SearchFetchPropertyListAction
) {
  const currentViewportBounds = yield select(getSearchViewport);
  const sortOrder = yield select(getSearchListSortOrder);
  const sortField = yield select(getSearchListSortField);
  const cursor = yield select(getSearchListCursor);
  /* Use the bounds from the payload when passed (when loading properties after a place search
   * within the sort view) or use the viewport when not passed (when loading the list view from the map
   * or getting the property count when opening the mobile filters from the mobile map) */
  const bounds = action.payload.bounds || currentViewportBounds;
  const currentZoom = (yield select(getSearchZoom)) as number;
  /* If we're passing bounds, that means we want to get properties for those bounds, regardless
   * of the current map bounds.  So compute the zoom in this case instead of using the current zoom */
  const effectiveZoom = action.payload.bounds
    ? /* Generalizing the map dimensions.  We only need an approximate number here, since the goal is
       * to prevent API calls at very low zoom levels (i.e. too low zoom shouldn't happen often) */
      getZoomFromBounds(bounds, { width: 1000, height: 1000 })
    : currentZoom;

  /* When loading the list view on init the first execution of this saga won't have bounds
   * but a later execution of it (after fetching place details or user's location) will */
  if (bounds) {
    yield forkTracked(
      `${TASK_PREFIXES.PROPERTY_LIST}-full`,
      getPropertyListData,
      {
        bounds,
        zoom: effectiveZoom,
        append: false,
        cursor,
        sortOrder,
        sortField,
      }
    );

    /* TODO remove when we're ready to switch to using `totalCount` in the property list response */
    const searchCountSelector = yield call(
      getIsFeatureEnabled,
      'search_count_request'
    );
    const searchCountRequest = yield select(searchCountSelector);
    if (searchCountRequest) {
      yield forkTracked(
        `${TASK_PREFIXES.PROPERTY_LIST}-count`,
        getPropertyCount,
        { bounds }
      );
    }
    yield call(updateAnalyticsSearchState);
  }
}

/**
 * Given map bounds, get the number of properties within that match the current filters
 * This overwrites the `propertyCount` state value set by the `searchFetchPropertyListSuccess` action,
 * which currently is always null due to `totalCount` being null.
 * TODO remove when we're ready to switch to using `totalCount` in the property list response
 */
export function* getPropertyCount({ bounds }) {
  const spatialId = yield call(getPropertiesRequestSpatialId, bounds);
  const filtersMapping = yield select(getGQLFormattedSearchFilters);
  const data = yield call(
    [graphQLApiClient, graphQLApiClient.getSpatialSearchPropertyCount],
    { spatialId, filtersMapping }
  );
  const count = get(data, ['propertySpatialSearch', 'count']);
  const moreThan = get(data, ['propertySpatialSearch', 'moreThan']);
  yield put(searchSetPropertyCount(count || moreThan || null));
}

export function* getMoreListProperties() {
  const bounds = yield select(getSearchViewport);
  const cursor = yield select(getSearchListCursor);
  const sortOrder = yield select(getSearchListSortOrder);
  const sortField = yield select(getSearchListSortField);
  const currentZoom = (yield select(getSearchZoom)) as number;

  yield forkTracked(
    `${TASK_PREFIXES.PROPERTY_LIST}-full-next-page`,
    getPropertyListData,
    {
      bounds,
      zoom: currentZoom,
      append: true,
      sortOrder,
      sortField,
      cursor,
    }
  );
}

/** Given clicked-upon map property, get full details for the property to display it in the sidebar */
export function* getSelectedMapPropertyData(action) {
  const addressSlug = action.payload.slug;
  const propertyLookup = (yield call(
    [graphQLApiClient, graphQLApiClient.getPropertyDetailsInit],
    { slug: addressSlug }
  )) as InitPropertyLookupWithAddressRequired;

  yield put(searchFetchSelectedMapPropertySuccess(propertyLookup));
}

/* Add a pulse animation to a specific location on the map under an existing Mapbox symbol
 * marker OR add a temporary marker with its own CSS pulse animation */
export function* toggleMapMarkerPulse(action) {
  const { isHovered, property, isMarkerOnMap } = action.payload;

  /* If the sought property is represented on the map as a property marker */
  if (isMarkerOnMap) {
    if (isHovered) {
      yield put(
        searchSetPulseLocations([
          { latitude: property.latitude, longitude: property.longitude },
        ])
      );
    } else {
      yield put(searchSetPulseLocations(null));
    }
    /* If the sought property is not on the map - either an API cluster is shown for it,
     * it's within a multi-unit cluster, or it's within a Mapbox-generated cluster */
  } else {
    if (isHovered) {
      yield put(searchShowTemporaryMarker(property));
    } else {
      yield put(searchRemoveTemporaryMarker());
    }
  }
}

/* Apply a pulse animation to school markers within the school district of a hovered property marker */
export function* highlightSchoolMarkers(action) {
  const addressSlug = action.payload.addressSlug;
  const gaiaSchoolsById = action.payload.gaiaSchoolsById;

  if (addressSlug && gaiaSchoolsById) {
    const data = yield call(
      [graphQLApiClient, graphQLApiClient.getPropertySchoolsData],
      addressSlug
    );
    const gaiaSchoolIds = Object.keys(gaiaSchoolsById);
    const schoolIdsForProperty = data.propertyLookup.schools.map(
      (school) => school.id
    );
    const pulseLocations = intersection(
      schoolIdsForProperty,
      gaiaSchoolIds
    ).map((schoolId) => ({
      latitude: gaiaSchoolsById[schoolId].location.latitude,
      longitude: gaiaSchoolsById[schoolId].location.longitude,
    }));
    yield put(searchSetPulseLocations(pulseLocations));
  } else {
    yield put(searchSetPulseLocations(null));
  }
}

export function* updateAnalyticsSearchState() {
  // Update the analytics search-id
  yield put(updateContextualSearchId());

  // Pass the filter state and viewport state to Beacon
  const filterState = (yield select(getSearchFilterValues)) as ReturnType<
    typeof getSearchFilterValues
  >;
  const viewport = (yield select(getSearchViewport)) as ReturnType<
    typeof getSearchViewport
  >;
  const searchText = (yield select(getSearchText)) as ReturnType<
    typeof getSearchText
  >;
  const filterStateWithoutGeom = { ...filterState, geom: null };

  yield put(
    reportEvent('search_state_update', '', {
      filterState: filterStateWithoutGeom,
      currentMapViewport: viewport,
      searchText,
    })
  );
}

export function* updateSearchBoxText(action: {
  payload: { eventName: 'search_state_update' | 'click_search_field' };
}) {
  /* Debounce to avoid reporting analytics for each key entered */
  yield delay(1000);

  // Pass the filter state and viewport state to Beacon
  const filterState = yield select(getSearchFilterValues);
  const viewport = yield select(getSearchViewport);
  const searchText = yield select(getSearchText);
  const filterStateWithoutGeom = { ...filterState, geom: null };

  yield put(
    reportEvent(action.payload.eventName, PARENT_EVENTS.CLICK_SIGNUP, {
      filterState: filterStateWithoutGeom,
      currentMapViewport: viewport,
      searchText,
    })
  );
}

export function* onFiltersUpdated(action) {
  const mapViewport = yield select(getSearchViewport);
  const zoom = yield select(getSearchZoom);

  /* Location won't be changing so need to explicitly reset this */
  yield call(resetOnlyClustersFlag);

  /* If for some reason the map hasn't loaded yet (viewport not yet set), the sidebar and map
   * properties will load with the active filters when the map loads
   * https://sentry.io/organizations/housecanarycom/issues/918028074 */
  if (mapViewport) {
    const { southWest, northEast } = mapViewport;

    yield put(
      searchFetchPropertyList({
        bounds: { southWest, northEast },
        showLoadingIndicator: false,
      })
    );
    /* In rare cases when selecting a saved-search on the search page before the map loads, we won't yet
     * have a zoom in state. In this case, the map properties will load when the map loads. */
    if (typeof zoom === 'number') {
      yield call(findMapMarkerPropertiesForBounds, {
        payload: { southWest, northEast, zoom },
      });
    }
  }

  yield put(searchUpdateUrlParams({ shouldReplace: true }));
}

export function* updateRouteParams(action: SearchUpdateUrlParamsAction) {
  const {
    shouldReplace,
    shouldUpdateInitialHistoryLocationKey,
    selectedAddressSlug,
    isShowingSearchPageList,
    isShowingMobileFilters,
  } = action.payload;
  const searchLocationForQueryParam = (yield select(
    getSearchLocationForQueryParam
  )) as ReturnType<typeof getSearchLocationForQueryParam>;
  const searchFiltersForQueryParams = (yield select(
    getSearchFiltersForQueryParams
  )) as ReturnType<typeof getSearchFiltersForQueryParams>;
  const currentView = (yield select(getCurrentView)) as ReturnType<
    typeof getCurrentView
  >;
  const currentQuery = (yield select(getCurrentQuery)) as ReturnType<
    typeof getCurrentQuery
  >;

  /* Only allow updating search route params using this saga if we're currently on the search page.  This is
   * required due to map move/zoom events sometimes firing as we're transitioning to another page, causing
   * this saga to erroneously return the user back to the search page */
  if (currentView && !ALL_SEARCH_VIEWS.includes(currentView)) {
    if (process.env.NODE_ENV !== 'test') {
      console.error(
        `Attempt at updating search route params denied, as current view must be a search view.  Your view is ${currentView}`
      );
    }
    return;
  }

  /* If not defined, we're not seeking to change the active view. Use the current value from state */
  let effectiveIsShowingSearchList =
    isShowingSearchPageList === undefined
      ? yield select(getIsShowingSearchPageList)
      : isShowingSearchPageList;

  /* If not defined, we're not seeking to show or hide the mobile filters. Use the current value from state */
  let effectiveIsShowingMobileFilters =
    isShowingMobileFilters === undefined
      ? yield select(getSearchIsShowingMobileFilters)
      : isShowingMobileFilters;

  /* Remove all filter query params from the query params object. This way we can re-apply the existing
   * non-filter query params, then apply the newly-effective filter params afterward. This allows for the user
   * removing one or more filters */
  let currentQueryWithFilterKeysRemoved = { ...currentQuery };
  Object.keys(FILTER_KEYS).forEach((keyKey) => {
    const key = FILTER_KEYS[keyKey];
    delete currentQueryWithFilterKeysRemoved[FILTER_KEY_ABBREVIATIONS[key]];
  });

  yield put(
    routeChange({
      view: View.SEARCH,
      params: {},
      query: {
        /* Spread current query params (excluding filter query params) first so that they aren't lost */
        ...currentQueryWithFilterKeysRemoved,
        /* Map location query param */
        ...searchLocationForQueryParam,
        /* Filter query params */
        ...searchFiltersForQueryParams,
        /* For PDP modal and PDP sub-modal, add or remove from query */
        [SEARCH_MAP_SELECTED_ADDRESS_SLUG_URL_PARAM_KEY]: selectedAddressSlug,
        /* For the rest, use existing state values if not in action payload */
        [SEARCH_MAP_MOBILE_FILTERS_SHOWN_URL_KEY]:
          effectiveIsShowingMobileFilters || undefined,
        [SEARCH_ACTIVE_VIEW_URL_PARAM_KEY]: effectiveIsShowingSearchList
          ? SEARCH_ACTIVE_VIEW_URL_PARAM_OPTIONS.LIST
          : SEARCH_ACTIVE_VIEW_URL_PARAM_OPTIONS.MAP,
      },
      options: {
        replace: shouldReplace || false,
        ...(shouldUpdateInitialHistoryLocationKey
          ? { historyState: { shouldUpdateInitialHistoryLocationKey } }
          : {}),
      },
    })
  );

  if (isShowingSearchPageList !== undefined) {
    yield put(searchListHandleOpenOrClose());
  }
}

export function* updateRouteParamsToShowPDPModal(action) {
  const markerFeatureProps = action.payload.markerFeatureProps;

  yield put(
    searchUpdateUrlParams({
      selectedAddressSlug: markerFeatureProps.addressSlug,
      shouldReplace: true,
    })
  );
}

export function* updateRouteParamsToHidePDPModal(action) {
  yield put(
    searchUpdateUrlParams({
      shouldReplace: true,
    })
  );
}

export function* onApplySavedFilters() {
  const currentView = yield select(getCurrentView);

  if (ALL_SEARCH_VIEWS.indexOf(currentView) > -1) {
    yield call(onFiltersUpdated, { payload: {} });
  } else {
    /* Go to search page.  Map location and filters are already set on search reducer. */
    yield put(
      routeChange({
        view: View.SEARCH,
        params: {},
        query: {},
        options: { replace: true },
      })
    );
  }
}

export function resetOnlyClustersFlag() {
  /* Reset flag, again allowing individual property markers to be added to map  */
  onlyClustersAllowedOnMap = false;
  propertyMarkerStatsByTile = [];
}

/* Cancel the active requests in the browser */
function* cancelPendingQueries(prefix) {
  for (let key in activeTasks) {
    if (
      activeTasks.hasOwnProperty(key) &&
      (!prefix || key.indexOf(prefix) > -1)
    ) {
      yield cancel(activeTasks[key]);
    }
  }
  activeTasks = {};
}

function untrackTask(taskKey, fn, ...args) {
  return function* () {
    let result = yield call(fn, ...args);
    delete activeTasks[taskKey];
    return result;
  };
}

function* trackTask(taskKey, fn, ...args) {
  activeTasks[taskKey] = yield fork(untrackTask(taskKey, fn, ...args));
}

function forkTracked(taskKey, fn, ...args) {
  return call(trackTask, taskKey, fn, ...args);
}

/* Request the properties for each tile within the passed map bounds */
export function* findMapMarkerPropertiesForBounds(action) {
  const { southWest, northEast, zoom } = action.payload;
  const isShowingMarkers = yield select(getIsShowingMapMarkers);
  const currentView = yield select(getCurrentView);
  /* Due to how map move/zoom events are bound, there's a possible situation where this action is
   * dispatched as we're trying to leave the search view.  Don't fetch properties in this case, as it
   * will negate the navigation, taking the user back to the search page. */
  if (!isShowingMarkers || !ALL_SEARCH_VIEWS.includes(currentView)) {
    return;
  }
  /* Delay slightly to allow map zoom animation to complete following zoom button click
   * and allow for saga cancellation when calling saga repeatedly during a move */
  if (initialMapPropertiesRequestSent) {
    yield delay(200);
  }
  /* Mapbox renders 512px tiles. We want to send tile request at one or more zoom levels higher,
   * effectively 256px tiles, in order for the API to return more granular clusters.
   * This is also more efficient, fetching property markers for a smaller area closer
   * to the actual viewport size */
  const additionalTileZoom = yield select(getSearchAdditionalTileZoom);
  const effectiveZoom = Math.floor(zoom) + additionalTileZoom;
  const mapMarkersByTile = yield select(getSearchMarkersByTile);
  const minMarkerFeatureZoom = yield select(getSearchMarkerFeaturesMinZoom);
  /* Filter out tiles with markers currently on map.  We don't want to waste performance
   * processing/replacing existing map markers after a short pan */
  let tilesArray = getTilesInBounds({
    southWest,
    northEast,
    zoom: effectiveZoom,
  }).filter((tile) => !mapMarkersByTile[getTileKeyForTile(tile)]);

  /* Safety check - Prevent some Mapbox bug from reporting very large bounds and causing 100s of tile
   * requests to go out at once, crippling our API (Note - I've never seen more than 30 or so tiles in
   * viewport in practice) */
  if (tilesArray.length > 100) {
    tilesArray = tilesArray.slice(0, 100);
  }

  /* Reset clusters only flag when zooming inward */
  if (currentZoom && effectiveZoom > currentZoom) {
    yield call(resetOnlyClustersFlag);
  }
  if (zoom >= minMarkerFeatureZoom) {
    yield all(
      tilesArray.map((tile) =>
        forkTracked(
          `${TASK_PREFIXES.MARKERS}-${getTileKeyForTile(tile)}`,
          getPropertyDataForTile,
          tile,
          currentZoom
        )
      )
    );
  }
  /* While all markers for a different zoom level within the viewport should already have been
   * replaced by now, clean up any markers outside of the viewport from a previous zoom level */
  yield put(searchFilterMapMarkersByZoom(effectiveZoom));
  currentZoom = effectiveZoom;
  initialMapPropertiesRequestSent = true;
}

/**
 * Get the user's location from their device and, if given, set the map's location to the location
 * @returns {void}
 */
export function* fetchCurrentLocationAndSetMapPosition() {
  try {
    /* TODO here we should fetch search list properties for the user's location.  This'll allow
     * the search list/sidebar properties to load faster, rather than waiting on the map to load
     * first and report its position */

    const deviceLatLng = yield call(fetchUserLocationFromDevice);
    yield put(searchGetUserLocationSuccess(deviceLatLng));
  } catch (e: any) {
    console.warn('fallback to IP location: ', e);
    try {
      const iPLatLng = yield call(fetchUserLocationViaIP, GEOLOCATION_ENDPOINT);
      yield put(searchGetUserLocationSuccess(iPLatLng));
    } catch (e: any) {
      console.warn('fallback to default location: ', e);
      yield put(searchGetUserLocationSuccess(MAP_FALLBACK_LOCATION));
    }
  }
}

export function* fetchSearchListPropertiesIfShowingSearchList() {
  const isShowingSearchList = yield select(getIsShowingSearchPageList);

  /* When viewing property sort on init, we can't fetch properties until after we have
   * place details */
  if (isShowingSearchList) {
    yield put(
      searchFetchPropertyList({
        showLoadingIndicator: true,
      })
    );
  }
}

export function* handleGetLastSearchSuccess(action) {
  if (action.payload.isShowingSearchList !== undefined) {
    /* We'll only be here if the user hits the homepage root, so no need
     * to worry about persisting selected address slug in the URL */
    yield put(
      searchUpdateUrlParams({
        isShowingSearchPageList: action.payload.isShowingSearchList,
        shouldReplace: true,
      })
    );
  }

  yield put(
    searchFetchPropertyList({
      showLoadingIndicator: true,
    })
  );
}

export function* applySort() {
  yield put(
    searchFetchPropertyList({
      showLoadingIndicator: true,
    })
  );
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [SEARCH_APPLY_SAVED_FILTERS]: onApplySavedFilters,
    [SEARCH_FETCH_MORE_SIDEBAR_PROPERTIES]: getMoreListProperties,
    [SEARCH_FETCH_MORE_LIST_PROPERTIES]: getMoreListProperties,
    [SEARCH_CANCEL_PENDING_QUERIES]: cancelPendingQueries,
    [SEARCH_FETCH_SELECTED_MAP_PROPERTY]: getSelectedMapPropertyData,
    [SEARCH_PROPERTY_CARD_HOVER]: toggleMapMarkerPulse,
    [SEARCH_UPDATE_URL_PARAMS]: updateRouteParams,
    [SEARCH_FETCH_PLACE_DETAILS]: resetOnlyClustersFlag,
    [SEARCH_GET_USER_LOCATION_SUCCESS]:
      fetchSearchListPropertiesIfShowingSearchList,
    [SEARCH_GET_LAST_SEARCH_SUCCESS]: handleGetLastSearchSuccess,
    [SEARCH_SET_MAP_LOCATION_TO_LAST_VIEWED_PDP]:
      fetchSearchListPropertiesIfShowingSearchList,
    [SEARCH_HIGHLIGHT_SCHOOL_MARKERS]: highlightSchoolMarkers,
    [SEARCH_SET_PDP_MODAL_MARKER_DATA_AND_SHOW_MODAL]:
      updateRouteParamsToShowPDPModal,
    [SEARCH_ROUTE_CHANGE_TO_HIDE_PDP_MODAL]: updateRouteParamsToHidePDPModal,
    [SEARCH_GET_USER_LOCATION_VIA_SELECTION]:
      fetchCurrentLocationAndSetMapPosition,
    [SEARCH_GET_USER_LOCATION_WHEN_NOT_AVAILABLE]:
      fetchCurrentLocationAndSetMapPosition,
  });
  watchLatest(sagaMiddleware, {
    [SEARCH_CLEAR_ALL_FILTERS]: onFiltersUpdated,
    [SEARCH_FETCH_PROPERTY_LIST]: getPropertyListProperties,
    [SEARCH_FETCH_MAP_PROPERTIES_FOR_BOUNDS]: findMapMarkerPropertiesForBounds,
    [SEARCH_UPDATE_FILTER]: onFiltersUpdated,
    [SEARCH_LIST_APPLY_SORT]: applySort,
    [SEARCH_REPORT_UPDATE_TEXT_BOX]: updateSearchBoxText,
    [SEARCH_FIELD_CLICK]: updateSearchBoxText,
  });
};
