import { RefObject } from 'react';
import LenderCTAButton from './LegacyLenderCTAButton';
import { useSelector } from 'react-redux';
import { getCustomizationData } from '@client/store/selectors/cobranding.selectors';
import { Theme } from '@friendsofreactjs/react-css-themr';
import {
  getIsLoggedIn,
  getUserPartnerUserId,
  getChapiPartnerInviteId,
} from '@client/store/selectors/auth.selectors';
import { TopLocalAgentAdDetailsInterface } from '@hc/consumer-customization-data/lib/types';
import {
  getTARCTALink,
  useReportTAREvent,
} from '@client/components/cobrand/topagentsranked/top-agents-ranked-utils';
import { useAutoSaveProperty } from '@client/hooks/auto-save-property.hooks';

type Props = {
  className?: string;
  theme?: Theme;
  returnButtonRef?: RefObject<HTMLButtonElement>;
  reportAnalytics?: () => void;
  style?: { [cssKey: string]: string | number };
  ['data-event-name']?: string;
  ['data-parent-event-name']?: string;
};

export default function LenderCTAButtonTAR({
  className,
  theme,
  returnButtonRef,
  style,
}: Props) {
  const reportTAREvent = useReportTAREvent();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const partnerUserId = useSelector(getUserPartnerUserId);
  const chapiPartnerInviteId = useSelector(getChapiPartnerInviteId);
  const autoSaveProperty = useAutoSaveProperty();

  const customizationData = useSelector(getCustomizationData);
  const { top_local_agent_ad_details } = customizationData;
  const customConfigTitle = top_local_agent_ad_details?.pdp_button_title;

  const handleTalkToAnAgentClick = (
    tarAdDetails: TopLocalAgentAdDetailsInterface | null
  ) => {
    const ctaLink = getTARCTALink(
      tarAdDetails,
      isLoggedIn,
      partnerUserId,
      chapiPartnerInviteId
    );
    autoSaveProperty();
    reportTAREvent();
    window.open(ctaLink, '_blank');
  };

  return (
    <LenderCTAButton
      className={className}
      theme={theme}
      returnButtonRef={returnButtonRef}
      style={style}
      ctaTitle={customConfigTitle ?? 'Email an agent'}
      onClick={() => {
        handleTalkToAnAgentClick(top_local_agent_ad_details);
      }}
    />
  );
}
