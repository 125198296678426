import React from 'react';
import pluralize from 'pluralize';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import VerticalSeparator from '@client/components/generic/VerticalSeparator';
import {
  placeholderFormatter,
  numbersWithPlaceholder,
  getLabelPropertyData,
  DEFAULT_BED_DATA_LABEL,
  DEFAULT_BATH_DATA_LABEL,
} from '@client/utils/string.utils';
import defaultTheme from '@client/css-modules/PropertyBrief.css';

type Props = {
  beds: number | null;
  baths: number | null;
  sqFeet: any;
  daysOnMarket?: number | null;
  propertyType?: string | null;
  similarity?: string | null;
  theme: Theme;
};

const PropertyBrief: React.FC<Props> = ({
  beds,
  baths,
  sqFeet,
  daysOnMarket,
  propertyType,
  theme,
  similarity,
}) => {
  return (
    /** Only show property type if we have data according to design */
    <div className={theme.PropertyBrief} data-hc-name="property-info">
        {propertyType && (
          <>
            {propertyType}
            <VerticalSeparator theme={theme} />
          </>
        )}
        {`${placeholderFormatter(beds)} ${getLabelPropertyData(
          beds,
          DEFAULT_BED_DATA_LABEL
        )}`}
        <VerticalSeparator theme={theme} />
        {`${placeholderFormatter(baths)} ${getLabelPropertyData(
          baths,
          DEFAULT_BATH_DATA_LABEL
        )}`}
        <VerticalSeparator theme={theme} />
        {`${numbersWithPlaceholder(sqFeet, '-')} Sq Ft`}
        {(daysOnMarket || daysOnMarket === 0) && (
          <>
            <VerticalSeparator theme={theme} />
            {daysOnMarket === 0
              ? 'Listed Today'
              : `${daysOnMarket} ${pluralize('Day', daysOnMarket)} on Market`}
          </>
        )}
        {similarity && (
          <>
            <VerticalSeparator theme={theme} />
            Similarity: {similarity}
          </>
        )}
    </div>
  );
};

export default themr('PropertyBrief', defaultTheme)(PropertyBrief);
