import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { ReduxState } from '@client/store/types/redux-state';
import { EquityData, EquityFormFields } from '@client/store/types/homeowner';
import { getActiveSlugForHomeownerAndSubpages } from '@client/store/selectors/router.selectors';
import { Status, STATUSES } from '@client/store/constants';

type SlugPayload = { slug: string };
type LoadingStatus = { equityDataLoadingStatus: Status };
export type EquityDataForSlug = EquityData & SlugPayload & LoadingStatus;
type EquityDataCache = Partial<EquityData> & SlugPayload & LoadingStatus;
export type EquityFormDataForSlug = EquityFormFields & SlugPayload;
export interface HomeownerEquityState {
  [slug: string]: EquityDataForSlug;
}

const initialState: HomeownerEquityState = {};

export const homeownerEquitySlice = createSlice({
  name: 'homeownerEquity',
  initialState,
  reducers: {
    fetchEquityData: () => {},
    putEquityData: (state, action: PayloadAction<EquityFormDataForSlug>) => {
      const slug = action.payload.slug;
      return {
        ...state,
        [slug]: {
          ...(state[slug] ?? {}),
          ...action.payload,
        },
      };
    },
    cacheUserEquityData: (state, action: PayloadAction<EquityDataCache>) => {
      const slug = action.payload.slug;
      return {
        ...state,
        [slug]: {
          ...(state[slug] ?? {}),
          ...action.payload,
        },
      };
    },
  },
});

const selectEquityDataMap = (state: ReduxState) => state.homeownerEquity;
export const selectActiveHomeEquityData = createSelector(
  getActiveSlugForHomeownerAndSubpages,
  selectEquityDataMap,
  (slug, homeownerEquityDataMap) =>
    slug && homeownerEquityDataMap[slug] ? homeownerEquityDataMap[slug] : null
);
export const selectActiveHomeEquityAmount = createSelector(
  selectActiveHomeEquityData,
  (equityData) =>
    equityData?.current_balance && equityData?.current_equity
      ? equityData?.current_equity
      : null
);
export const selectEquityDataLoadingStatus = createSelector(
  selectActiveHomeEquityData,
  (equityData) =>
    equityData?.equityDataLoadingStatus === STATUSES.LOADING ||
    equityData?.equityDataLoadingStatus === STATUSES.INIT ||
    !equityData?.equityDataLoadingStatus
);

export const { fetchEquityData, putEquityData, cacheUserEquityData } =
  homeownerEquitySlice.actions;
export default homeownerEquitySlice.reducer;
