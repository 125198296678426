export default ({ latitude, longitude, slug }) => {
  return {
    query: /* GraphQL */ `query LocalActivities($latitude: Float, $longitude: Float, $slug: String) {
      propertyLookup (id: { slug: $slug}) {
        businessSummary
      }
      business(id: {
        latitude: $latitude
        longitude: $longitude
        limit: 10
        sortBy: "distance"
      }) {
          results {
            arts {
              ...BusinessInfo
            }
            bars {
              ...BusinessInfo
            }
            cafes {
              ...BusinessInfo
            }
            grocery {
              ...BusinessInfo
            }
            parks {
              ...BusinessInfo
            }
            restaurants {
              ...BusinessInfo
            }
        }
      }
    }
    fragment BusinessInfo on BusinessDetails {
      id
      alias
      name
      price
      rating
      reviewCount
      url
      imageURL
      coordinates {
        latitude
        longitude
      }
      distance
      categories {
        alias
        title
      }
      parentCategory
    }`,
    variables: {
      latitude,
      longitude,
      slug,
    },
  };
};
