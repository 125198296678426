import ComeHomeLogoPoweredBy from '@client/assets/images/come-home-logo-powered-by.svg';
import React from 'react';

type Props = {
  shouldUseAltText?: boolean;
};

const ComeHomeLogoPoweredByImg: React.FC<
  Props & React.HTMLAttributes<HTMLImageElement>
> = ({ shouldUseAltText, ...rest }) => {
  return (
    <img
      src={ComeHomeLogoPoweredBy}
      {...(shouldUseAltText && { alt: 'ComeHome powered by logo' })}
      {...rest}
    />
  );
};

export default ComeHomeLogoPoweredByImg;
