import { createSelector } from 'reselect';
import { get, isString } from 'lodash';
import {
  getCurrentQuery,
  getCurrentView,
  getCurrentParams,
} from '@src/redux-saga-router-plus/selectors';

import {
  ACTIVE_LISTING_STATUSES,
  SEARCH_MAP_SELECTED_ADDRESS_SLUG_URL_PARAM_KEY,
} from '@client/store/constants';
import { MlsStateGroup } from '@client/store/sagas/queries/types';
import { ReduxState } from '@client/store/types/redux-state';
import {
  View,
  PAGE_TITLE_FOR_VIEW,
  VIEW_PATH_PARAM_KEYS,
  HOMEOWNER_ROUTES,
} from '@client/routes/constants';
import { FILTER_KEYS } from '@client/store/filter-constants';

/**
 * In order to avoid circular dependencies, we need to access the various redux state
 * keys here, and recreate the selectors
 */
export function getHomeownerState(state: ReduxState) {
  return state.homeowner;
}

export function getPropertyDetailsState(state: ReduxState) {
  return state.propertyDetails;
}

export function getSearchState(state: ReduxState) {
  return state.search;
}

export function getIsInitialRoute(state: ReduxState) {
  return state.routerAdditionalInfo.isInitialRoute;
}

export function getCustomizationData(state: ReduxState) {
  return state.cobranding.domainScheme.customizationData;
}

/**
 * Check if a place page is displaying active listings
 * @param  {object} mlsState the mlsState
 * @return {boolean} whether or not it is showing an active listing type
 */
export const mlsStatesIncludeActiveListings = (
  mlsStates: MlsStateGroup[]
): boolean => {
  return ACTIVE_LISTING_STATUSES.some((activeListingStatus) =>
    mlsStates.includes(activeListingStatus)
  );
};

/**
 * Get the page title for Place pages (Search, City, Zipcode)
 * @param  {object} mlsState - the mlsState object from search.filters
 * @param  {string} placeDescription - optional description of the area ('Los Angeles CA', etc)
 * @return {string} the appropriate page title
 */
export const getPageTitleForPlacePage = (
  mlsStateFilterValues: MlsStateGroup[],
  placeDescription?: string
) => {
  if (mlsStatesIncludeActiveListings(mlsStateFilterValues)) {
    return placeDescription
      ? `${placeDescription} real estate and homes for sale`
      : 'Real estate and homes for sale';
  } else {
    return placeDescription
      ? `${placeDescription} real estate and homes for sale`
      : 'Home values';
  }
};

/* Homeowner selectors */
export const getHomeownerPropertyDataCache = createSelector(
  getHomeownerState,
  (homeownerState) => homeownerState.homeownerPropertyDataCache
);

export const getClaimedHomes = createSelector(
  getHomeownerState,
  (homeownerState) => homeownerState.claimedHomes
);

export const getActiveSlugForHomeownerAndSubpages = createSelector(
  getCurrentView,
  getCurrentParams,
  (view, params) => {
    if (view && HOMEOWNER_ROUTES.includes(view)) {
      return (params?.slug as string) || null;
    } else {
      return null;
    }
  }
);

export const getActiveHomeownerProperty = createSelector(
  getActiveSlugForHomeownerAndSubpages,
  getHomeownerPropertyDataCache,
  (activeSlug, dataCache) => (activeSlug ? dataCache[activeSlug] : null)
);

export const getActiveHomeownerPropertyData = createSelector(
  getActiveHomeownerProperty,
  (activeHomeownerProperty) => activeHomeownerProperty?.data || null
);

export const getHomeownerPropertyAddress = createSelector(
  getActiveHomeownerPropertyData,
  (activeHomeownerPropertyData) => activeHomeownerPropertyData?.address
);

export const getHomeownerPropertyFullAddress = createSelector(
  getHomeownerPropertyAddress,
  (activeHomeownerPropertyAddress) =>
    get(activeHomeownerPropertyAddress, 'fullAddress')
);

/* Property details selectors */
export const getPropertyDataCache = createSelector(
  getPropertyDetailsState,
  (propertyDetailsState) => propertyDetailsState.propertyDataCache
);

export const getActivePDPSlug = createSelector(
  getCurrentView,
  getCurrentParams,
  getCurrentQuery,
  (view, params, query) => {
    if (view === View.SEARCH) {
      return (
        (query[SEARCH_MAP_SELECTED_ADDRESS_SLUG_URL_PARAM_KEY] as string) ||
        null
      );
    } else if (view === View.PROPERTY_DETAILS) {
      return (
        (params[
          VIEW_PATH_PARAM_KEYS[View.PROPERTY_DETAILS]!.slice(1)
        ] as string) || null
      );
    } else {
      return null;
    }
  }
);

export const getActiveHomeownerOrPDPSlug = createSelector(
  getActiveSlugForHomeownerAndSubpages,
  getActivePDPSlug,
  (homeownerSlug, pdpSlug) => homeownerSlug || pdpSlug || null
);

export const getActivePDPProperty = createSelector(
  getActivePDPSlug,
  getPropertyDataCache,
  (activeSlug, dataCache) => (activeSlug ? dataCache[activeSlug] : null)
);

export const getActivePDPPropertyData = createSelector(
  getActivePDPProperty,
  (activePDPProperty) => (activePDPProperty ? activePDPProperty.data : null)
);

export const getHomeBuyerPropertyAddress = createSelector(
  getActivePDPPropertyData,
  (activePDPPropertyData) => activePDPPropertyData?.address?.fullAddress || null
);

/* Search selectors */
export const getSearchFilters = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.filters;
  }
);

export const getSearchMLSState = createSelector(
  getSearchFilters,
  (filters) => filters[FILTER_KEYS.MLS_STATE]
);

export const getSearchPlaceGeoJSONDescription = createSelector(
  getSearchState,
  (searchState) => {
    return searchState.placeGeoJSONDescription;
  }
);

export const getSearchPageTitle = createSelector(
  getSearchMLSState,
  getSearchPlaceGeoJSONDescription,
  (mlsState, placeDescription) =>
    getPageTitleForPlacePage(mlsState, placeDescription)
);

export const getLenderSpecificPageTitle = createSelector(
  getCustomizationData,
  (customizationData) => customizationData?.lender_specific_page_title
);

const createPageTitle = (title: string) => {
  return `${title} | ComeHome`;
};

export const getPageTitleForView = createSelector(
  getCurrentView,
  getHomeownerPropertyFullAddress,
  getHomeBuyerPropertyAddress,
  getSearchPageTitle,
  getLenderSpecificPageTitle,
  (
    view,
    ownerAddr?: string,
    buyerAddr?,
    searchTitle?: string,
    lenderSpecificPageTitle?: string | null
  ) => {
    if (lenderSpecificPageTitle) {
      return lenderSpecificPageTitle;
    } else {
      const title = view && PAGE_TITLE_FOR_VIEW[view];

      if (isString(title)) {
        return createPageTitle(title);
      } else {
        switch (view) {
          case View.HOMEOWNER:
            return createPageTitle('My Home');
          case View.HOMEOWNER_CLAIMED_HOMES:
            return createPageTitle('My Homes');
          case View.HOMEOWNER_PROPERTY_DETAILS:
            const defaultChoTitle = 'My Home';
            const homeOwnerPropertyDetailsTitle = ownerAddr
              ? `${ownerAddr} | ${defaultChoTitle}`
              : defaultChoTitle;
            return createPageTitle(homeOwnerPropertyDetailsTitle);
          case View.PROPERTY_DETAILS:
            return createPageTitle(`${buyerAddr} | Property Details`);
          case View.SEARCH:
          case View.CITIES:
          case View.ZIPCODE:
          case View.PLACE:
            const searchLocationTitle =
              searchTitle || 'Real estate and homes for sale';
            return createPageTitle(searchLocationTitle);
          default:
            return createPageTitle('Real estate and homes for sale');
        }
      }
    }
  }
);

export const getPreQualified = createSelector(
  getCurrentQuery,
  (query: {}) => get(query, 'prequalified') === 'true'
);
