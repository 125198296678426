import React from 'react';
import { themr } from '@friendsofreactjs/react-css-themr';

import defaultTheme from '@client/css-modules/AccountDetailsMenu.css';
import { AccountDetailsMenuProps } from '@client/store/types/cobranded-components/account-details-menu';
import { key, stopEvent } from '@client/utils/component.utils';
import {
  ACCOUNT_MENU_ITEMS_WITH_AUTH,
  AccountModalItem,
  AccountMenuItem,
  AccountNavItem,
  View,
} from '@client/routes/constants';
import ConditionalFeature from './ConditionalFeature';

function isModalItem(item?: AccountMenuItem): item is AccountModalItem {
  return !!(
    (item as AccountModalItem)?.modalKey && (item as AccountModalItem)?.title
  );
}
function isNavItem(item?: AccountMenuItem): item is AccountNavItem {
  return !!(item as AccountNavItem)?.route;
}

class AccountDetailsMenu extends React.Component<AccountDetailsMenuProps> {
  childRefs: HTMLLIElement[] = [];
  focusTimeout?: ReturnType<typeof setTimeout>;

  setRef = (el: HTMLLIElement) => {
    this.childRefs.push(el);
  };

  handleKeyDown = (
    e: React.KeyboardEvent,
    route: View | null,
    index: number,
    onClick?: () => void
  ) => {
    const keyPress = e.key;
    if (keyPress) {
      if (key.isSpace(keyPress) || key.isReturn(keyPress)) {
        stopEvent(e);
        const isLogoutRoute = route === View.LOGOUT;
        if (isLogoutRoute) {
          this.props.handleLogout();
        } else if (route) {
          this.props.handleRouteChange(route);
        } else if (onClick) {
          onClick();
        }
      } else if (key.isEscape(keyPress)) {
        this.props.handleCloseMenu();
      } else if (key.isArrowUp(keyPress)) {
        stopEvent(e);
        if (index !== 0) {
          this.childRefs[index - 1].focus();
        }
      } else if (key.isArrowDown(keyPress)) {
        stopEvent(e);
        if (index !== this.childRefs.length - 1) {
          this.childRefs[index + 1].focus();
        }
      } else if (keyPress === 'Tab') {
        /**
         * If a user tabs to the last menu button,
         * next tab will be on the first menu button.
         */
        if (index === this.childRefs.length - 1) {
          stopEvent(e);
          this.childRefs[0].focus();
        }
      }
    }
  };

  componentDidMount() {
    /*
     * Due to the nested nature of the <li>'s within the list, we need a short setTimeout hack to ensure that the li's have finished rendering:
     * https://stackoverflow.com/questions/35522220/react-ref-with-focus-doesnt-work-without-settimeout-my-example
     */
    this.focusTimeout = setTimeout(() => {
      this.childRefs[0].focus();
    }, 5);
  }

  componentWillUnmount() {
    clearTimeout(this.focusTimeout);
  }

  render() {
    const {
      handleLogout,
      theme,
      handleRouteChange,
      disableUserProfileEditing,
      handleOpenModal,
    } = this.props;

    return (
      <ul className={theme.MenuListContainer}>
        {ACCOUNT_MENU_ITEMS_WITH_AUTH.map((item: AccountMenuItem, index) => {
          if (
            isNavItem(item) &&
            disableUserProfileEditing &&
            item.route === View.PROFILE
          ) {
            return null;
          }
          return (
            <ConditionalFeature
              key={`${index}-cond-feature-wrapper`}
              renderIfFeaturesEnabled={
                isModalItem(item) && item.modalKey === 'recent-user-activity'
                  ? ['recent_user_activity']
                  : /* empty list renders children unconditionally */
                    []
              }
            >
              <li
                key={index}
                ref={this.setRef}
                role="tab"
                tabIndex={0}
                className={theme.ListItem}
                onKeyDown={(e) => {
                  if (isNavItem(item)) {
                    this.handleKeyDown(e, item.route, index);
                  } else if (isModalItem(item) && handleOpenModal) {
                    this.handleKeyDown(
                      e,
                      null,
                      index,
                      /* keyDown exception here to skip the regular handleRouteChange and fire this callback instead */
                      () => {
                        handleOpenModal(item.modalKey);
                      }
                    );
                  }
                }}
                onClick={() => {
                  if (isNavItem(item)) {
                    const route = item.route;
                    if (route === View.LOGOUT) {
                      handleLogout();
                    } else {
                      handleRouteChange(route);
                    }
                  }
                  if (isModalItem(item) && handleOpenModal) {
                    handleOpenModal(item.modalKey);
                  }
                }}
              >
                <span className={theme.ListItemTitle}>{item.title}</span>
              </li>
            </ConditionalFeature>
          );
        })}
      </ul>
    );
  }
}
const ThemedAccountDetailsMenu = themr(
  'AccountDetailsMenu',
  defaultTheme
)(AccountDetailsMenu);
export default ThemedAccountDetailsMenu;
