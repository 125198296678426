import { flatten } from 'lodash';
import { Map, Expression, FillPaint } from 'mapbox-gl';

import { LegendBreaksData } from '@client/store/types/maps';
import { LayerMetric, HALFTONE_IMAGES } from '@client/store/map-constants';

const TRANSPARENT_COLOR = 'rgba(0,0,0,0)';

/* Here we keep functionality that's shared between MapAvmDeepDiveLocation and MapLayersControl */

/**
 * Returns a UUID for a set of legend breaks for a specific layer
 */
export const getLegendBreaksUUIDForLayer = (
  legendBreaks: LegendBreaksData,
  layerId: LayerMetric
): string | undefined => {
  return (
    legendBreaks[layerId] &&
    `${layerId}-${legendBreaks[layerId]!.map((item) => item[0]).join('')}`
  );
};

/**
 * Load all images needed for monochrome a11y map layer into map
 */
export const loadHalftoneImages = (
  map: Map | null,
  onLoaded: () => void
): void => {
  let imagesLoadedCount = 0;

  if (!map) {
    throw new Error(
      'Attempting to load halftone images into the map before the map is available'
    );
  }

  HALFTONE_IMAGES.forEach((item) => {
    const imageId = `halftone-shade-${item.name}`;
    const image = new Image(8, 8);

    image.addEventListener('load', () => {
      /* Map might be unmounted by the time the image loads */
      if (map && map.getStyle()) {
        if (!map.hasImage(imageId)) {
          map.addImage(imageId, image, { pixelRatio: 2 });
        }
        imagesLoadedCount++;
        if (imagesLoadedCount === HALFTONE_IMAGES.length) {
          onLoaded();
        }
      }
    });
    image.src = item.url;
  });
};

/**
 * Generate a Mapbox GL expression for the halftone fill definition
 */
export const getBlocksLayerHalftoneDefinition = (
  legendBreaks: LegendBreaksData,
  layerId: LayerMetric
): Expression => {
  const legendBreaksForLayer = legendBreaks[layerId];

  if (!legendBreaksForLayer) {
    throw new Error(
      `Legend breaks not available for layer ${layerId} when getting layer halftone def`
    );
  }

  /* If no breaks are available for the given metric, apply transparent color to features */
  const value = ['number', ['get', layerId], -Infinity];
  return [
    'case',
    ...flatten(
      legendBreaksForLayer.map((item, index) => {
        let condition: Expression = [
          'all',
          ['>=', value, item[0]],
          ['<=', value, item[1]],
        ];
        if (index === legendBreaksForLayer.length - 1) {
          condition = ['>=', value, item[0]];
        }
        return [condition, `halftone-shade-${index}`];
      })
    ),
    'halftone-shade-empty',
  ] as Expression;
};

/**
 * Generate a Mapbox GL expression for the color fill definition
 */
export const getBlocksLayerFillInterpolateDefinition = (
  legendBreaks: LegendBreaksData,
  layerId: LayerMetric
): (string | number)[] => {
  const legendBreaksForLayer = legendBreaks[layerId];

  if (!legendBreaksForLayer) {
    throw new Error(
      `Legend breaks not available for layer ${layerId} when getting layer fill def`
    );
  }

  /* If no breaks are available for the given metric, apply transparent color to features */
  return flatten([
    /* -Infinity is set as the fallback value, rendering a transparent fill color */
    -Infinity,
    TRANSPARENT_COLOR,
    ...legendBreaksForLayer.map((breakDef) => [breakDef[0], breakDef[2]]),
  ]);
};

/**
 * Get the complete fill definition for a layer
 */
export const getBlocksLayerFillDefinition = (
  legendBreaks: LegendBreaksData,
  activeLayerId: LayerMetric,
  hasMonochromeOverlay: boolean
): FillPaint => {
  if (legendBreaks[activeLayerId]) {
    if (hasMonochromeOverlay) {
      return {
        'fill-pattern': getBlocksLayerHalftoneDefinition(
          legendBreaks,
          activeLayerId
        ),
      };
    } else {
      return {
        'fill-color': [
          'interpolate-hcl',
          ['linear'],
          /* -Infinity will be assigned to any `null` value, causing the fallback step color to be used */
          ['number', ['get', activeLayerId], -Infinity],
          ...getBlocksLayerFillInterpolateDefinition(
            legendBreaks,
            activeLayerId
          ),
        ] as Expression,
      };
    }
  } else {
    return {
      'fill-color': TRANSPARENT_COLOR,
    };
  }
};

/**
 * Generate CSS gradient defs for each layer group and id to be used in the legend color bar
 */
export const getLegendBreakGradients = (
  legendBreaks: LegendBreaksData,
  layerId: LayerMetric,
  hasMonochromeOverlay: boolean
) => {
  const legendBreaksForLayer = legendBreaks[layerId];

  if (hasMonochromeOverlay) {
    let patterns: string[] = [];
    for (let i = 0; i < 5; i++) {
      patterns.push(`url('${HALFTONE_IMAGES[i].url}')`);
    }
    return patterns;
  }

  return (
    legendBreaksForLayer &&
    legendBreaksForLayer
      .map((_, i) => {
        return i > 0
          ? `linear-gradient(to right, ${legendBreaksForLayer[i - 1][2]} 0%,${
              legendBreaksForLayer[i][2]
            } 100%)`
          : null;
      })
      .slice(1)
  );
};
