import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import ChaseMyHomeLogo from '@client/assets/images/cobrand/chase/chase-my-home-logo.svg';
import theme from '@client/css-modules/MyHomeHeaderChase.css';
import ChevronIconStandardGray from '@client/inline-svgs/chevron-standard-gray';
import { reportClickLenderNavBackToSite } from '@client/store/actions/analytics.actions';
import { getOverviewRedirectUrl } from '@client/store/sagas/chase-integration.saga';
import { getCustomizationData } from '@client/store/selectors/cobranding.selectors';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';

type MyHomeHeaderChaseProps = {
  isDesktopHeaderFullWidth: boolean;
  headerBackgroundColor: string;
};

const MyHomeHeaderChase: React.FC<MyHomeHeaderChaseProps> = ({
  headerBackgroundColor,
  isDesktopHeaderFullWidth,
}) => {
  const dispatch = useDispatch();
  const { cobrand_cta_redirect_hostname } = useSelector(getCustomizationData);

  const handleClickOverview = () => {
    dispatch(reportClickLenderNavBackToSite());
    /* Always redirect regardless of whether user is logged in */
    window.location.href = getOverviewRedirectUrl({
      hostname: cobrand_cta_redirect_hostname as string | null,
    });
  };

  return (
    <div
      className={theme.HeaderTopRowContainer}
      style={{ backgroundColor: headerBackgroundColor }}
    >
      <div
        className={classNames(theme.HeaderTopRow, {
          [theme.DesktopInnerContainerFullWidth]: isDesktopHeaderFullWidth,
        })}
      >
        <button
          aria-label="Overview"
          type="button"
          onKeyDown={onEnterOrSpaceKey(handleClickOverview)}
          onClick={handleClickOverview}
          className={theme.OverviewLink}
        >
          <ChevronIconStandardGray className={theme.ChevronIconOverview} />
          <span className={theme.OverviewLinkText}>Overview</span>
        </button>
        <div className={theme.MyHomeLogoContainer}>
          <img
            src={ChaseMyHomeLogo}
            alt={'Chase my home logo'}
            className={theme.MyHomeLogo}
          />
        </div>
      </div>
    </div>
  );
};

export default MyHomeHeaderChase;
