import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { ReduxState } from '@client/store/types/redux-state';
import { getActiveSlugForHomeownerAndSubpages } from '@client/store/selectors/router.selectors';

interface MortgageData {
  slug: string;
  monthlyPayment: number | null;
  balance: number | null;
  balanceOneYearLater: number | null;
}
export interface RefinanceData {
  slug: string;
  refinanceAmount?: number | null;
  refinanceLoanTerm?: string | null;
  refinanceMonthlyPayment?: number | null;
}

export type FetchRefinanceMortgageDataPayload = Pick<
  RefinanceData,
  'slug' | 'refinanceAmount' | 'refinanceLoanTerm'
> & { refinanceInterestRate: number };
export type CacheUserRefinanceDataPayload = Pick<
  RefinanceData,
  'slug' | 'refinanceMonthlyPayment'
>;

type HomeownerMortgageData = MortgageData & RefinanceData;
export interface HomeownerMortgageState {
  [slug: string]: HomeownerMortgageData;
}

const initialState: HomeownerMortgageState = {};

export const homeownerMortgageSlice = createSlice({
  name: 'homeownerMortgage',
  initialState,
  reducers: {
    fetchMortgageData: () => {},
    cacheUserMortgageData: (state, action: PayloadAction<MortgageData>) => {
      const slug = action.payload.slug;
      return {
        ...state,
        [slug]: {
          ...(state[slug] ?? {}),
          ...action.payload,
        },
      };
    },
    /* Put user-entered refi calculator form values on state */
    fetchRefinanceMortgageData: (
      state,
      action: PayloadAction<FetchRefinanceMortgageDataPayload>
    ) => {
      const slug = action.payload.slug;
      const refinanceAmount = action.payload.refinanceAmount;
      const refinanceLoanTerm = action.payload.refinanceLoanTerm;
      return {
        ...state,
        ...(refinanceAmount || refinanceLoanTerm
          ? {
              [slug]: {
                ...(state[slug] ?? {}),
                refinanceAmount,
                refinanceLoanTerm,
              },
            }
          : {}),
      };
    },
    /* Put api-returned monthly payment data on state */
    cacheUserRefinanceData: (
      state,
      action: PayloadAction<CacheUserRefinanceDataPayload>
    ) => {
      const slug = action.payload.slug;
      const refinanceMonthlyPayment = action.payload.refinanceMonthlyPayment;
      return {
        ...state,
        ...{
          [slug]: {
            ...(state[slug] ?? {}),
            refinanceMonthlyPayment,
          },
        },
      };
    },
  },
});

const selectMortgageDataMap = (state: ReduxState) => state.homeownerMortgage;
export const selectActiveHomeMortgageData = createSelector(
  getActiveSlugForHomeownerAndSubpages,
  selectMortgageDataMap,
  (slug, homeownerMortgageDataMap) =>
    slug && homeownerMortgageDataMap[slug]
      ? homeownerMortgageDataMap[slug]
      : null
);
export const selectActiveHomeMonthlyPayment = createSelector(
  selectActiveHomeMortgageData,
  (mortgageData) => (mortgageData ? mortgageData.monthlyPayment : null)
);
export const selectActiveHomeRefinanceMonthlyPayment = createSelector(
  selectActiveHomeMortgageData,
  (mortgageData) => (mortgageData ? mortgageData.refinanceMonthlyPayment : null)
);
export const selectActiveHomeBalance = createSelector(
  selectActiveHomeMortgageData,
  (mortgageData) => (mortgageData ? mortgageData.balance : null)
);
export const selectActiveHomeBalanceOneYearLater = createSelector(
  selectActiveHomeMortgageData,
  (mortgageData) => (mortgageData ? mortgageData.balanceOneYearLater : null)
);
export const selectActiveHomeRefinanceAmount = createSelector(
  selectActiveHomeMortgageData,
  (mortgageData) => (mortgageData ? mortgageData.refinanceAmount : null)
);
export const selectActiveHomeRefinanceLoanTerm = createSelector(
  selectActiveHomeMortgageData,
  (mortgageData) => (mortgageData ? mortgageData.refinanceLoanTerm : null)
);

export const {
  fetchMortgageData,
  cacheUserMortgageData,
  cacheUserRefinanceData,
  fetchRefinanceMortgageData,
} = homeownerMortgageSlice.actions;
export default homeownerMortgageSlice.reducer;
