import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import { createSelector } from 'reselect';

import {
  ADMIN_ROUTES,
  ALL_SEARCH_VIEWS,
  HOMEOWNER_BREAKOUT_ROUTES,
  HOMEOWNER_ROUTES,
  LO_DIRECT_ROUTES,
  View,
} from '@client/routes/constants';
import {
  CRIME_DATA_ATTRIBUTION,
  FORECAST_DATA_ATTRIBUTION,
  PRICE_DATA_ATTRIBUTION,
  SCHOOL_DATA_ATTRIBUTION,
} from '@client/store/constants';
import {
  LAYER_GROUP_KEYS,
  LAYER_GROUP_LABELS,
} from '@client/store/map-constants';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import { getActivePDPSlug } from '@client/store/selectors/router.selectors';

const BLANK_PAGE_FOR_VIEWS = [
  ...ADMIN_ROUTES,
  ...LO_DIRECT_ROUTES,
  View.WIDGET_DEMO_BANNER,
  View.WIDGET_DEMO_HERO,
  View.NATIVE_APP_TEST,
];

/* For use when we want to disallow scrolling on the container. i.e. fullscreen map views */
const FIXED_APP_CONTAINER_FOR_VIEWS = [...ALL_SEARCH_VIEWS];

/* For these views the footer is either hidden entirely or included deeper within the view */
const HIDDEN_DESKTOP_GLOBAL_FOOTER_FOR_VIEWS = [
  ...ALL_SEARCH_VIEWS,
  ...HOMEOWNER_BREAKOUT_ROUTES,
  View.UNAUTHENTICATED_ACCESS,
  View.PROPERTY_DETAILS,
  View.WELCOME_SEARCH,
  View.WELCOME_TRANSITION,
  View.WELCOME_TRANSITION_INSET,
  View.HOMEPAGE,
  View.PARTNER_API_CREDENTIAL,
  View.CHD_CLIENT_SIGN_UP,
];

const HIDDEN_MOBILE_GLOBAL_FOOTER_FOR_VIEWS = [
  ...ALL_SEARCH_VIEWS,
  ...HOMEOWNER_BREAKOUT_ROUTES,
  View.UNAUTHENTICATED_ACCESS,
  View.PROPERTY_DETAILS,
  View.WELCOME_SEARCH,
  View.WELCOME_TRANSITION,
  View.WELCOME_TRANSITION_INSET,
  View.LENDER_HOMEPAGE,
  View.HOMEPAGE,
  View.PARTNER_API_CREDENTIAL,
  View.CHD_CLIENT_SIGN_UP,
];

const HIDDEN_DESKTOP_GLOBAL_HEADER_FOR_VIEWS = [
  View.UNAUTHENTICATED_ACCESS,
  View.WELCOME_SEARCH,
  View.WELCOME_TRANSITION,
  View.WELCOME_TRANSITION_INSET,
  View.HOMEPAGE,
  View.PARTNER_API_CREDENTIAL,
  View.CHD_CLIENT_SIGN_UP,
] as View[];

const HIDDEN_MOBILE_GLOBAL_HEADER_FOR_VIEWS = [
  View.UNAUTHENTICATED_ACCESS,
  View.LENDER_HOMEPAGE,
  View.WELCOME_SEARCH,
  View.WELCOME_TRANSITION,
  View.WELCOME_TRANSITION_INSET,
  View.HOMEPAGE,
  View.PARTNER_API_CREDENTIAL,
  View.CHD_CLIENT_SIGN_UP,
] as View[];

export const HIDDEN_BOTTOM_NAV_FOR_VIEWS = [
  View.UNAUTHENTICATED_ACCESS,
  View.WELCOME_SEARCH,
  View.WELCOME_TRANSITION,
  View.WELCOME_TRANSITION_INSET,
  View.NATIVE_APP_ERROR,
  View.LENDER_HOMEPAGE,
  View.HOME_IMPROVEMENT,
  View.PRO_FINDER,
  View.HOMEPAGE,
  View.PARTNER_API_CREDENTIAL,
  View.CHD_CLIENT_SIGN_UP,
] as View[];

export const BACK_BUTTON_IN_TOP_NAV_FOR_VIEWS = [
  ...HOMEOWNER_BREAKOUT_ROUTES,
  View.PRO_FINDER,
] as View[];

const PERSISTENT_HEADER_SEARCH_FIELD_FOR_VIEWS = [...ALL_SEARCH_VIEWS];

const HIDDEN_HEADER_SEARCH_ICON_FOR_VIEWS = [
  ...ALL_SEARCH_VIEWS,
  View.NATIVE_APP_ERROR,
  View.HOMEPAGE,
];

const APP_CONTAINER_FLEXED_FOR_VIEW = [...ALL_SEARCH_VIEWS];

const FULL_WIDTH_DESKTOP_HEADER_FOR_VIEWS = [...ALL_SEARCH_VIEWS];

const APP_CONTAINER_WITH_MIN_HEIGHT = [
  ...HOMEOWNER_ROUTES.filter((route) => route !== View.HOME_INSURANCE),
] as View[];

const GREY_BACKGROUND_FOR_VIEWS = [View.HOMEOWNER_PROPERTY_DETAILS] as View[];

export const getAppContainerHasMinHeight = createSelector(
  getCurrentView,
  (currentView) =>
    !!(currentView && APP_CONTAINER_WITH_MIN_HEIGHT.indexOf(currentView) > -1)
);

export const getAppContainerIsFlexed = createSelector(
  getCurrentView,
  (currentView) =>
    !!(currentView && APP_CONTAINER_FLEXED_FOR_VIEW.indexOf(currentView) > -1)
);

export const getHasFullWidthDesktopHeaderForView = createSelector(
  getCurrentView,
  (currentView) =>
    !!(
      currentView &&
      FULL_WIDTH_DESKTOP_HEADER_FOR_VIEWS.indexOf(currentView) > -1
    )
);

export const getIsAppContainerFixed = createSelector(
  getCurrentView,
  (currentView) =>
    !!(currentView && FIXED_APP_CONTAINER_FOR_VIEWS.indexOf(currentView) > -1)
);

export const getIsShowingHeader = createSelector(
  getCurrentView,
  getIsSmallSize,
  (currentView, isSmallSize: boolean) => {
    if (isSmallSize) {
      return !!(
        currentView &&
        HIDDEN_MOBILE_GLOBAL_HEADER_FOR_VIEWS.indexOf(currentView) === -1
      );
    } else {
      return !!(
        currentView &&
        HIDDEN_DESKTOP_GLOBAL_HEADER_FOR_VIEWS.indexOf(currentView) === -1
      );
    }
  }
);

export const getIsShowingFooter = createSelector(
  getCurrentView,
  getIsSmallSize,
  (currentView, isSmallSize: boolean) => {
    if (isSmallSize) {
      return !!(
        currentView &&
        HIDDEN_MOBILE_GLOBAL_FOOTER_FOR_VIEWS.indexOf(currentView) === -1
      );
    } else {
      return !!(
        currentView &&
        HIDDEN_DESKTOP_GLOBAL_FOOTER_FOR_VIEWS.indexOf(currentView) === -1
      );
    }
  }
);

export const getIsShowingSearchIconForRoute = createSelector(
  getCurrentView,
  (currentView) =>
    !!(
      currentView &&
      HIDDEN_HEADER_SEARCH_ICON_FOR_VIEWS.indexOf(currentView) === -1
    )
);

export const getIsShowingPersistentSearchField = createSelector(
  getCurrentView,
  (currentView) =>
    !!(
      currentView &&
      PERSISTENT_HEADER_SEARCH_FIELD_FOR_VIEWS.indexOf(currentView) > -1
    )
);

export const getIsHidingPersistentHeaderSearchForPageOnTablet = createSelector(
  getCurrentView,
  (currentView) => !!(currentView && ALL_SEARCH_VIEWS.indexOf(currentView) > -1)
);

export const getIsShowingBackNavButtonForView = createSelector(
  getCurrentView,
  (currentView) =>
    !!(
      currentView && BACK_BUTTON_IN_TOP_NAV_FOR_VIEWS.indexOf(currentView) > -1
    )
);

export const getMapLayerGroupLabelsWithDataAttribution = createSelector(
  getActivePDPSlug,
  (activePDPSlug) => {
    const isShowingDataAttribution = !!activePDPSlug;
    /* Adding data attribution to PDP heatmaps to comply with NEREN.  Not showing within search page maps */
    return {
      [LAYER_GROUP_KEYS.CRIME]: {
        ...LAYER_GROUP_LABELS[LAYER_GROUP_KEYS.CRIME],
        ...(isShowingDataAttribution
          ? { dataAttribution: CRIME_DATA_ATTRIBUTION }
          : {}),
      },
      [LAYER_GROUP_KEYS.SCHOOLS]: {
        ...LAYER_GROUP_LABELS[LAYER_GROUP_KEYS.SCHOOLS],
        ...(isShowingDataAttribution
          ? { dataAttribution: SCHOOL_DATA_ATTRIBUTION }
          : {}),
      },
      [LAYER_GROUP_KEYS.PRICE]: {
        ...LAYER_GROUP_LABELS[LAYER_GROUP_KEYS.PRICE],
        ...(isShowingDataAttribution
          ? { dataAttribution: PRICE_DATA_ATTRIBUTION }
          : {}),
      },
      [LAYER_GROUP_KEYS.FORECAST]: {
        ...LAYER_GROUP_LABELS[LAYER_GROUP_KEYS.FORECAST],
        ...(isShowingDataAttribution
          ? { dataAttribution: FORECAST_DATA_ATTRIBUTION }
          : {}),
      },
    };
  }
);

/**
 * For use when we want a blank page sans header, footer etc which
 * are used for all pages except for the Views defined below
 */
export const getIsShowingBlankPage = createSelector(
  getCurrentView,
  (currentView) =>
    !!(currentView && BLANK_PAGE_FOR_VIEWS.indexOf(currentView) > -1)
);

export const getAppHasGreyBackground = createSelector(
  getCurrentView,
  (currentView) =>
    !!(currentView && GREY_BACKGROUND_FOR_VIEWS.includes(currentView))
);
