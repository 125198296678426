import { Component } from 'react';

import { SavedSearchItem } from '@client/store/types/saved-searches';
import LoadingSection from '@client/components/generic/LoadingSection';
import WatchlistHeaderContainer from '@client/containers/watchlist-header.container';
import WatchListAlertsLoggedOut from '@client/components/WatchListAlertsLoggedOut';
import HorizontalSeparator from '@client/components/generic/HorizontalSeparator';
import theme from '@client/css-modules/SavedSearches.css';
import AccessiblePageTitleContainer from '@client/containers/accessible-page-title.container';
import CobrandedDataComponent, {
  CobrandedDataComponentArgs,
} from '@client/components/CobrandedDataComponent';
import NoSavedSearchesSectionsCobranded from '@client/components/NoSavedSearchesSection/NoSavedSearchesSectionCobranded';
import ConfirmModalCobranded from '@client/components/ConfirmModal/ConfirmModalCobranded';
import SavedSearchPageCard from '@client/components/SavedSearchPageCard';
import SavedSearchesPageDefaultIconCobranded from '@client/components/SavedSearchesPageDefaultIcon/SavedSearchesPageDefaultIconCobranded';
import { eventType } from '@client/store/actions/analytics.actions';
import { View, PAGE_TITLE_FOR_VIEW } from '@client/routes/constants';
import { useAriaAnnouncer } from '@client/context/aria-announcer';
import { WatchlistPageConfig } from '@client/store/types/cobranding';

type Props = {
  isLoadingAuthentication: boolean;
  isLoggedIn: boolean;
  isSmallScreen: boolean;
  isSavedSearchesStatusInit: boolean;
  isUsingMobileFiltersUI: boolean;
  handleFetchSavedSearchesData: () => void;
  handleDeleteSavedSearch: (id: number) => void;
  handleOpenMobileFilters: () => void;
  handleOpenDesktopFilters: () => void;
  reportQuickFilterMobileRemovePropertyClick: () => void;
  savedSearches: SavedSearchItem[];
  savedSearchesPageConfig?: WatchlistPageConfig;
  savedSearchesFilterSummaryMap: object;
  handleReportEvent: (eventName: eventType) => void;
  ariaAnnouncer?: (message: JSX.Element | string) => void;
};

type State = {
  isShowingConfirmModal: boolean;
  selectedSearch: number;
  focusIndex: number;
  accessFiltersButtonId: string;
};

/**
 * A page to keep track of watched properties and manage alerts
 */
class SavedSearchesComponent extends Component<Props, State> {
  state = {
    isShowingConfirmModal: false,
    selectedSearch: 0,
    focusIndex: -1,
    accessFiltersButtonId: "access-filters",
  };

  componentDidMount() {
    /* Only attempt fetch if logged in, otherwise we display a message on the page */
    if (this.props.isLoggedIn) {
      this.props.handleFetchSavedSearchesData();
    }
  }

  componentDidUpdate(prevProps) {
    /* When logging in/singing up FROM the watchlist page, fetch watchlist after authenticating */
    if (!prevProps.isLoggedIn && this.props.isLoggedIn) {
      this.props.handleFetchSavedSearchesData();
    }

    // If very last item in saved searches is deleted, set focus to 'Access filters' button.
    if (this.props.savedSearches.length === 0 && prevProps.savedSearches.length !== 0) {
      const accessFiltersButton = document.getElementById(this.state.accessFiltersButtonId);
      // Give button time to load.
      window.setTimeout(() => {
        accessFiltersButton && accessFiltersButton.focus();
      }, 300);
    }
  }

  toggleConfirmModal = () => {
    this.setState({
      isShowingConfirmModal: !this.state.isShowingConfirmModal,
    });
  };

  setSelectedSearchAndOpenModal = (searchId, index) => {
    this.setState({
      selectedSearch: searchId,
      focusIndex: index,
    });
    this.toggleConfirmModal();
  };

  handleDeleteConfirm = () => {
    const {
      handleDeleteSavedSearch,
      reportQuickFilterMobileRemovePropertyClick,
      ariaAnnouncer,
      savedSearches,
    } = this.props;

    const focusIndex = 
      this.state.focusIndex === savedSearches.length - 1 ?
      this.state.focusIndex - 1 :
      savedSearches.findIndex(x => x.searchId === this.state.selectedSearch);

    this.setState({
      focusIndex: focusIndex
    })

    handleDeleteSavedSearch(this.state.selectedSearch);
    if (ariaAnnouncer) {
      ariaAnnouncer("Saved search removed");
    }
    reportQuickFilterMobileRemovePropertyClick();
    this.toggleConfirmModal();
  };

  handleAccessFilters = () => {
    const {
      isUsingMobileFiltersUI,
      handleOpenMobileFilters,
      handleOpenDesktopFilters,
    } = this.props;
    if (isUsingMobileFiltersUI) {
      handleOpenMobileFilters();
    } else {
      handleOpenDesktopFilters();
    }
  };

  render() {
    const {
      isLoadingAuthentication,
      isLoggedIn,
      isSavedSearchesStatusInit,
      savedSearches,
      savedSearchesPageConfig,
      savedSearchesFilterSummaryMap,
      handleReportEvent,
    } = this.props;

    const { focusIndex } = this.state;

    return (
      <div className={theme.SavedSearchesPage}>
        <AccessiblePageTitleContainer />
        <h1 className={theme.SavedSearchesAccessiblePageHeading}>
          {savedSearchesPageConfig?.savedSearchesLabel || PAGE_TITLE_FOR_VIEW[View.SAVED_SEARCHES]}
        </h1>
        <CobrandedDataComponent>
          {({
            customizationData: { delete_saved_search_confirm_modal_copy },
          }: CobrandedDataComponentArgs) => (
            <>
              <WatchlistHeaderContainer />
              {isLoggedIn ? (
                <LoadingSection
                  className={theme.LoadingSection}
                  isLoading={
                    isSavedSearchesStatusInit || isLoadingAuthentication
                  }
                >
                  {savedSearches.length > 0 ? (
                    <section
                      data-hc-name="saved-searches"
                      className={theme.SavedSearches}
                    >
                      {savedSearches.map((search, index) => (
                        <SavedSearchPageCard
                          savedSearch={search}
                          savedFiltersSummary={
                            savedSearchesFilterSummaryMap[search.searchId]
                          }
                          isFocused={index === focusIndex}
                          setSelectedSearchAndOpenModal={() => this.setSelectedSearchAndOpenModal(search.searchId, index)}
                          key={search.searchId}
                        />
                      ))}
                      <HorizontalSeparator
                        className={theme.DesktopAndTabletSeparator}
                        theme={theme}
                      />
                    </section>
                  ) : (
                    <NoSavedSearchesSectionsCobranded
                      handleAccessFilters={this.handleAccessFilters}
                      pillButtonId={this.state.accessFiltersButtonId}
                    />
                  )}
                </LoadingSection>
              ) : (
                <WatchListAlertsLoggedOut
                  icon={
                    <SavedSearchesPageDefaultIconCobranded
                      className={theme.SavedHomeHeadingIcon}
                      aria-hidden
                    />
                  }
                  title={savedSearchesPageConfig?.savedSearchesLabel || 'Saved searches'}
                  description={savedSearchesPageConfig?.savedSearchesDescription ||
                    "Looks like you haven't saved any searches yet. When you do, they will appear here."
                  }
                />
              )}
              <ConfirmModalCobranded
                isActive={this.state.isShowingConfirmModal}
                title={delete_saved_search_confirm_modal_copy.title as string}
                body={delete_saved_search_confirm_modal_copy.body as string}
                confirmText={
                  delete_saved_search_confirm_modal_copy.confirm_text
                }
                handleConfirm={this.handleDeleteConfirm}
                handleClose={this.toggleConfirmModal}
                handleReportEvent={handleReportEvent}
              />
            </>
          )}
        </CobrandedDataComponent>
      </div>
    );
  }
}

/* Functional Component Wrapper so we can use the AriaAnnouncer hook */
const SavedSearches = ({ ariaAnnouncer, ...props }: Props) => {
  const announcer = useAriaAnnouncer();
  return <SavedSearchesComponent ariaAnnouncer={announcer} {...props} />
}

export default SavedSearches
