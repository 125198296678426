import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import LenderCTACard from '@client/components/LenderCTACard/LenderCTACard';
import LenderCTACardChase from '@client/components/LenderCTACard/LenderCTACardChase';
import { LenderCTAProps } from '@client/store/types/cobranded-components/lender-cta-card';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return LenderCTACardChase;
    default:
      return LenderCTACard;
  }
};

const LenderCTACardCobranded: React.FC<LenderCTAProps> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default LenderCTACardCobranded;
