import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import ClickableLogo from '@client/components/ClickableLogo';
import RouterLink from '@client/components/RouterLink';

import ActivityIcon from '@client/inline-svgs/activity-icon';
import ClientsIcon from '@client/inline-svgs/clients-icon';
import ComeHomeDirectLogoBlack from '@client/inline-svgs/comehome-direct-logo-black';
import LightningIcon from '@client/inline-svgs/lightning-icon';
import LogoutIcon from '@client/inline-svgs/logout-icon';
import SupportIcon from '@client/inline-svgs/support-icon';

import theme from '@client/css-modules/LODirect/LODirectSidebar.css';
import breakpoints from '@client/css-modules/_breakpoints.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { LO_DIRECT_LOGOUT_QUERY_KEY, View } from '@client/routes/constants';
import {
  getCobrandDisplayName,
  getCobrandId,
} from '@client/store/selectors/cobranding.selectors';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';

const StyledNav = styled.nav`
  box-sizing: border-box;
  min-width: 304px;
  background: #fff;
  padding: 28px 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  @media (max-width: ${breakpoints.tabletScreenMaxWidth}) {
    display: none;
  }
`;

const StyledLogoPositioner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 22px;
  width: 100%;
`;
const StyledList = styled.ul`
  padding: 0;
`;

type LiProps = {
  $isCurrentView: boolean;
};

const StyledListItem = styled.li<LiProps>`
  background: ${(props) => (props.$isCurrentView ? '#f2f7ff' : 'transparent')};
  border-radius: 8px;
  cursor: pointer;
  list-style-type: none;
  margin-bottom: 8px;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;

  & a > span {
    padding: 16px;
  }
`;

const StyledButton = styled.button`
  border: 0;
  border-top: 1px solid #d8d8d8;
  border-radius: 0;
  background: #fff;
  width: 100%;
  padding: 26px 16px 42px;
  cursor: pointer;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
`;

const StyledSpan = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledLogo = styled(ComeHomeDirectLogoBlack)`
  height: auto;
  width: 255px;
  & svg {
    height: auto;
    width: 255px;
  }
`;

type LinkHeading = {
  [key: string]: React.ReactNode;
};

export const LO_DIRECT_SIDEBAR_LINKS = [
  View.LO_DIRECT_LEADS,
  View.LO_DIRECT_ACTIVITY,
  View.LO_DIRECT_CLIENTS,
  View.LO_DIRECT_SUPPORT,
];

export const NAMES_FOR_VIEWS = {
  [View.LO_DIRECT_LEADS]: 'Leads',
  [View.LO_DIRECT_ACTIVITY]: 'Activity',
  [View.LO_DIRECT_CLIENTS]: 'Clients',
  [View.LO_DIRECT_SUPPORT]: 'Support',
};

export const LINK_HEADINGS: LinkHeading = {
  [View.LO_DIRECT_LEADS]: (
    <StyledSpan>
      <LightningIcon className={theme.Icon} />{' '}
      {NAMES_FOR_VIEWS[View.LO_DIRECT_LEADS]}
    </StyledSpan>
  ),
  [View.LO_DIRECT_ACTIVITY]: (
    <StyledSpan>
      <ActivityIcon className={theme.Icon} />{' '}
      {NAMES_FOR_VIEWS[View.LO_DIRECT_ACTIVITY]}
    </StyledSpan>
  ),
  [View.LO_DIRECT_CLIENTS]: (
    <StyledSpan>
      <ClientsIcon className={theme.Icon} />{' '}
      {NAMES_FOR_VIEWS[View.LO_DIRECT_CLIENTS]}
    </StyledSpan>
  ),
  [View.LO_DIRECT_SUPPORT]: (
    <StyledSpan>
      <SupportIcon className={theme.Icon} />{' '}
      {NAMES_FOR_VIEWS[View.LO_DIRECT_SUPPORT]}
    </StyledSpan>
  ),
};

const LODirectSidebar: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const currentView = useSelector(getCurrentView);
  const cobrandDisplayName = useSelector(getCobrandDisplayName);
  const { linkColor, defaultTextColor, logoUrl } = useCobrandStyles();
  const isShowingChDirectLogo = useSelector(getCobrandId) === COBRAND_IDS.WWW;
  const EffectiveLogo = isShowingChDirectLogo
    ? (props) => <StyledLogo alt="ComeHome Direct logo" {...props} />
    : (props) => (
        <img
          src={logoUrl!}
          style={{ height: 'auto', width: 166 }}
          alt={`${cobrandDisplayName} logo`}
          {...props}
        />
      );

  return (
    <StyledNav>
      <div>
        <StyledLogoPositioner>
          <ClickableLogo
            ariaLabel={cobrandDisplayName}
            Logo={EffectiveLogo}
            dataHcName="lo-direct-brand-logo"
            theme={theme}
            manualDestination={{
              url: window?.location?.origin,
              target: '_blank',
            }}
            /* Matches .LendcoLogo height in Header.css */
            style={{ height: 50 }}
          />
        </StyledLogoPositioner>
        <StyledList>
          {LO_DIRECT_SIDEBAR_LINKS.map((view) => (
            <StyledListItem $isCurrentView={currentView === view} key={view}>
              <RouterLink
                style={{
                  color: currentView === view ? linkColor : defaultTextColor,
                  textDecoration: 'none',
                }}
                view={view}
                ariaLabel={NAMES_FOR_VIEWS[view]}
              >
                {LINK_HEADINGS[view]}
              </RouterLink>
            </StyledListItem>
          ))}
        </StyledList>
      </div>

      <StyledButton
        onClick={() =>
          dispatch(
            routeChange({
              view: View.LO_DIRECT_LOGIN,
              query: { [LO_DIRECT_LOGOUT_QUERY_KEY]: true },
            })
          )
        }
      >
        <StyledSpan>
          <LogoutIcon className={theme.Icon} /> Logout
        </StyledSpan>
      </StyledButton>
    </StyledNav>
  );
};

export default LODirectSidebar;
