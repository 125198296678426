import { LngLatBounds } from 'mapbox-gl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { propertyPageHasToggledHeatmaps } from '@client/store/actions/property-details.actions';
import {
  FIT_POPUP_PADDING_WITH_DESKTOP_CONTROLS,
  FIT_POPUP_PADDING_WITH_MOBILE_CONTROLS,
  LayerMetric,
} from '@client/store/map-constants';
import renderOnMountUntyped from '@client/hocs/render-on-mount-untyped';
import MapPropertyPage from '@client/components/MapPropertyPage';
import {
  getAvmFSD,
  getPropertyMapMarkerFeature,
  getClosestElementarySchoolName,
  getPropertyDetailsFullStatus,
  getPropertyParcelGeoJSON,
  getPropertyLocation,
  getPropertyLotSize,
  getPropertyPageShouldShowHeatmapsWhenTogglingMapView,
  getPropertyAddress,
} from '@client/store/selectors/property-details.selectors';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { fetchMapLegendBreaks } from '@client/store/actions/map-legend-breaks.actions';
import { getAccessToken } from '@client/store/selectors/auth.selectors';
import { STATUSES } from '@client/store/constants';
import { AnalyticsEventAddress } from '@client/store/types/analytics';

const mapStateToProps = (state) => {
  const markerFeature = getPropertyMapMarkerFeature(state);
  const isSmallSize = getIsSmallSize(state);
  const address = getPropertyAddress(state);
  const analyticsFormattedAddress: AnalyticsEventAddress = {
    street: address?.streetAddress ?? '',
    city: address?.city ?? '',
    state: address?.state ?? '',
    zip: address?.zipcode ?? '',
    unit: address?.unit ?? '',
    slug: address?.slug,
    address_id: address?.hcAddressId,
  };

  return {
    avmFSD: getAvmFSD(state),
    markerFeatures: markerFeature ? [markerFeature] : [],
    propertyLocation: getPropertyLocation(state),
    propertyAddress: analyticsFormattedAddress,
    disableDragging: getIsSmallSize(state),
    fitPopupPadding: getIsSmallSize(state)
      ? FIT_POPUP_PADDING_WITH_MOBILE_CONTROLS
      : FIT_POPUP_PADDING_WITH_DESKTOP_CONTROLS,
    isShowingMapZoomControls: !isSmallSize,
    mapGeoRequestAccessToken: getAccessToken(state),
    autoHighlightSchoolDistrictName: getClosestElementarySchoolName(state),
    isParcelDataLoaded:
      getPropertyDetailsFullStatus(state) === STATUSES.SUCCESS,
    parcelGeoJSON: getPropertyParcelGeoJSON(state),
    lotSizeDescription: getPropertyLotSize(state),
    shouldShowHeatmapsWhenTogglingMapView:
      getPropertyPageShouldShowHeatmapsWhenTogglingMapView(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleMapZoom: (
    isLayerEnabled: boolean,
    isZoomIn: boolean,
    address: AnalyticsEventAddress
  ) => {
    const eventName = isLayerEnabled
      ? 'click_heatmap_pdp_zoom'
      : isZoomIn
      ? 'click_map_pdp_zoom_in'
      : 'click_map_pdp_zoom_out';
    dispatch(
      reportEvent(eventName, '', { address, ch_property_id: address?.slug })
    );
  },
  handleMapDrag: (isLayerEnabled: boolean, address: AnalyticsEventAddress) => {
    const eventName = isLayerEnabled
      ? 'click_heatmap_pdp_drag'
      : 'click_map_pdp_drag';

    dispatch(
      reportEvent(eventName, '', { address, ch_property_id: address?.slug })
    );
  },
  handleGetMapLayerLegendBreaks: (
    bounds: LngLatBounds,
    zoom: number,
    metric: LayerMetric | null
  ) => {
    const southWest = bounds.getSouthWest();
    const northEast = bounds.getNorthEast();
    dispatch(fetchMapLegendBreaks({ southWest, northEast, zoom, metric }));
  },
  handleHasToggledHeatmaps: (hasEnabledHeatmaps: boolean) => {
    dispatch(propertyPageHasToggledHeatmaps(hasEnabledHeatmaps));
  },
});

export default renderOnMountUntyped(
  connect(mapStateToProps, mapDispatchToProps)(MapPropertyPage)
);
