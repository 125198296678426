import { watchEvery } from '@client/utils/saga.utils';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { consumerApiClient } from '@client/services/consumer-api-client';
import { updateUserProfile } from '@client/store/actions/auth.actions';
import { addToWatchList } from '@client/store/actions/watchlist.actions';
import { submitReferral } from '@client/store/sagas/referral-services.saga';
import {
  getCurrentUser,
  getIsLoggedIn,
  getUserContactInfo,
} from '@client/store/selectors/auth.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { getLoanOfficerInfo } from '@client/store/selectors/loan-officer.selectors';
import {
  getActivePDPPropertyData,
  getPropertyAgentInfo,
} from '@client/store/selectors/property-details.selectors';
import { getActivePDPSlug } from '@client/store/selectors/router.selectors';
import { submitForm } from '@client/store/slices/request-a-tour.slice';
import { ReferralFormValues } from '@client/store/types/property-contact-form';
import { RequestATourFormState } from '@client/store/types/request-a-tour';
import { camelCaseToStringWithSpaces } from '@client/utils/string.utils';

function* submitRequestATourFormSaga(
  action: PayloadAction<RequestATourFormState>
) {
  const formData = action.payload;
  const slug = (yield select(getActivePDPSlug)) as ReturnType<
    typeof getActivePDPSlug
  >;
  const isReferralServicesEnabled = yield select(
    getIsFeatureEnabled('referral_services')
  );

  /* Update users contact info if they have not previously provided a phone number
   * (it's not required to make an account) */
  const user = (yield select(getUserContactInfo)) as ReturnType<
    typeof getUserContactInfo
  >;
  const isLoggedIn = (yield select(getIsLoggedIn)) as ReturnType<
    typeof getIsLoggedIn
  >;

  if (isLoggedIn && !user.phone && formData.phone) {
    yield put(updateUserProfile({ phone: formData.phone }));
  }

  /* Upon making tour request we also add the property to the users watchlist */
  yield put(addToWatchList({ slug: slug! }));

  try {
    if (isReferralServicesEnabled) {
      yield call(submitReferralServicesTourRequest, formData);
    } else {
      yield call(submitAgentTourRequest, formData);
    }
  } catch (e) {
    throw new Error('Request a tour POST failed: ' + JSON.stringify(e));
  } finally {
    // Submit financing request even if tour request has failed
    if (formData.contactForFinancing) {
      yield call(submitRequestATourFinancing, formData);
    }
  }
}

function* submitReferralServicesTourRequest(formData: RequestATourFormState) {
  const user = (yield select(getCurrentUser)) as ReturnType<
    typeof getCurrentUser
  >;
  const lo = (yield select(getLoanOfficerInfo)) as ReturnType<
    typeof getLoanOfficerInfo
  >;
  const propertyData = (yield select(getActivePDPPropertyData)) as ReturnType<
    typeof getActivePDPPropertyData
  >;
  const slug = propertyData?.address.slug;

  let notes = '';
  /* break into a string of key/values for the `notes` */
  Object.keys(formData).forEach((key) => {
    notes += `${camelCaseToStringWithSpaces(key)}: ${formData[key]}` + '\n';
  });

  const postData: ReferralFormValues = {
    firstName: user?.first_name!,
    lastName: user?.last_name!,
    email: formData.email || user?.email || 'Not provided',
    message: undefined,
    phone: formData.phone || user?.phone || 'Not provided',
    formName: undefined,
    preApproved: undefined,
    entrySlug: slug,
    propertyDetailSlugs: [slug!],
    hasAgent: false,
    notes: [notes],
    agentId: undefined,
    incompleteAgentId: undefined,
    selectedProperty: {
      address: propertyData?.address?.streetAddress,
      baths: propertyData?.livingSpace?.bathrooms?.summaryCount,
      beds: propertyData?.livingSpace?.bedrooms?.count,
      city: propertyData?.address?.city,
      state: propertyData?.address?.state,
      maxPrice: propertyData?.listPrice,
      unit: propertyData?.address?.unit,
      zip: propertyData?.address?.zipcode,
    },
    tourRequest: true,
  };
  yield call(submitReferral, postData, lo, '/refer/buyer');
}

function* submitAgentTourRequest(formData: RequestATourFormState) {
  const slug = (yield select(getActivePDPSlug)) as ReturnType<
    typeof getActivePDPSlug
  >;
  const listingAgent = (yield select(getPropertyAgentInfo)) as ReturnType<
    typeof getPropertyAgentInfo
  >;
  yield call([consumerApiClient, consumerApiClient.postRequestATourToAgent], {
    agent_email: listingAgent?.email,
    agent_first_name: listingAgent?.agentName?.split(' ')[0],
    agent_last_name: listingAgent?.agentName?.split(' ').splice(1).join(' '), // In case there is a middle name etc.
    user_tour_email: formData.email || null,
    user_tour_phone: formData.phone || null,
    preferred_contact_type: formData.contactType,
    slug,
    time_of_day: formData.preferredTime,
    tour_type: formData.tourType,
  });
}

function* submitRequestATourFinancing(formData: RequestATourFormState) {
  const loanOfficer = (yield select(getLoanOfficerInfo)) as ReturnType<
    typeof getLoanOfficerInfo
  >;
  const slug = (yield select(getActivePDPSlug)) as ReturnType<
    typeof getActivePDPSlug
  >;
  yield call([consumerApiClient, consumerApiClient.postRequestATourFinancing], {
    loan_officer_email: formData.financingContactEmail,
    /* Send first name and last name if we have the separate fields, else send full name. Necessary
     * since this API doesn't accept the full name. */
    ...(loanOfficer?.firstName
      ? {
          loan_officer_first_name: loanOfficer?.firstName || null,
          loan_officer_last_name: loanOfficer?.lastName || null,
        }
      : {
          loan_officer_first_name: loanOfficer?.fullName || null,
          loan_officer_last_name: null,
        }),
    user_tour_email: formData.email || null,
    user_tour_phone: formData.phone || null,
    preferred_contact_type: formData.contactType,
    slug,
  });
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [submitForm.type]: submitRequestATourFormSaga,
  });
};
