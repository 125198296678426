import {
  SHARE_PROPERTY_SUBMIT_SUCCESS,
  SHARE_PROPERTY_SUBMIT_FAILURE,
  CLEAR_SHARE_PROPERTY,
} from '@client/store/actions/share-property.actions';
import { Action } from '@client/store/actions';

export type SharePropertyState = {
  slug: string | null;
  street: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  unit: string | null;
  address_id: number | null | undefined;
  error: string | null;
  shouldShowNotification: boolean;
};

export const INITIAL_SHARE_PROPERTY: SharePropertyState = {
  slug: null,
  street: null,
  city: null,
  state: null,
  zip: null,
  unit: null,
  address_id: null,
  error: null,
  shouldShowNotification: false,
};

export default function sharePropertyReducer(
  state = INITIAL_SHARE_PROPERTY,
  action: Action
): SharePropertyState {
  switch (action.type) {
    case SHARE_PROPERTY_SUBMIT_SUCCESS:
      return {
        ...state,
        slug: action.payload.slug,
        street: action.payload.street,
        city: action.payload.city,
        state: action.payload.state,
        zip: action.payload.zip,
        unit: action.payload.unit,
        address_id: action.payload.address_id,
        error: null,
        shouldShowNotification: true,
      };
    case SHARE_PROPERTY_SUBMIT_FAILURE:
      return {
        ...state,
        slug: null,
        error: action.payload.error,
        shouldShowNotification: false,
      };
    case CLEAR_SHARE_PROPERTY:
      return {
        ...state,
        ...INITIAL_SHARE_PROPERTY,
      };
    default:
      return state;
  }
}
