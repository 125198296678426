import { stripOutNonNumber } from '@client/utils/string.utils';
import { isValidPhoneNumber } from '@client/utils/validations.forms';
import { EMAIL_TEST } from '@client/utils/regex.utils';

export const MAX_EMAIL_LENGTH = 128;
export const MAX_NAME_LENGTH = 32;
export const MAX_ORG_LENGTH = 64;
export const MIN_PASSWORD_LENGTH = 8;
export const VALIDATION_ERROR = { 
  Required: 'Required',
  PasswordError: 'Password must be at least 8 characters',
  EmailRequired: 'Email is required',
  InvalidEmail: 'Invalid email',
  InvalidPhone: 'Invalid phone number',
}


export type PasswordValidation = boolean;
export type EmailValidation = boolean;
export type RequiredValidation = boolean;

type Email = string;
type Validator = (value: any) => Promise<string | null>;

export class ValidationError extends Error {
  constructor(msg: string) {
    super();
    this.name = 'ValidationError';
    this.message = msg;
  }
}

export const validateEmail = (val: Email): EmailValidation => {
  return EMAIL_TEST.test(val);
};

export const validatePassword = (
  val: string | null | undefined,
  minLength: number = MIN_PASSWORD_LENGTH
): PasswordValidation => {
  return val != null && val.length >= minLength;
};

export const validateRequired = (val: any): RequiredValidation => {
  return val !== '' && val != null;
};

export const validateFieldLength = (
  val: string | null | undefined,
  maxLength: number
) => {
  return val != null && val.length <= maxLength;
};

export const fieldLengthValidator = (
  value: string,
  maxLength: number,
  fieldDescription: string = 'Field'
): Promise<string | null> => {
  const isValid = validateFieldLength(value, maxLength);
  if (isValid) {
    return Promise.resolve(null);
  } else {
    return Promise.reject(
      new ValidationError(
        `${fieldDescription} must be less than ${maxLength} characters`
      )
    );
  }
};
export const nameLengthValidator = (value: string) =>
  fieldLengthValidator(value, MAX_NAME_LENGTH, 'Names');
export const orgLengthValidator = (value: string) =>
  fieldLengthValidator(value, MAX_ORG_LENGTH, 'Organization');

export const getIsRequiredValidator =
  (validator?: Validator, preFormatter?: (val: any) => any) =>
  (value: any): Promise<string | null> => {
    const effectiveVal = preFormatter ? preFormatter(value) : value;
    const hasValue = validateRequired(effectiveVal);
    if (!hasValue) {
      return Promise.reject(new ValidationError(VALIDATION_ERROR.Required));
    } else if (validator) {
      return validator(value);
    } else {
      return Promise.resolve(null);
    }
  };

export const getIsNotRequiredValidator =
  (validator: Validator, preFormatter?: (val: any) => any) =>
  (value: any): Promise<string | null> => {
    const effectiveVal = preFormatter ? preFormatter(value) : value;
    if (!effectiveVal) {
      return Promise.resolve(null);
    } else {
      return validator(value);
    }
  };

export const passwordValidator = (value: string): Promise<string | null> => {
  const isValid: PasswordValidation = validatePassword(value);
  if (isValid) {
    return Promise.resolve(null);
  } else {
    return Promise.reject(
      new ValidationError(VALIDATION_ERROR.PasswordError)
    );
  }
};

// There are different ways to approach this
// Example uses the native DOM validity api
export const emailValidator = (value: Email): Promise<string | null> => {
  const isValid: EmailValidation = !value || validateEmail(value);
  if (isValid) {
    return Promise.resolve(null);
  } else {
    return Promise.reject(new ValidationError(VALIDATION_ERROR.InvalidEmail));
  }
};

export const emailRequiredValidator = (
  value: Email
): Promise<string | null> => {
  const isPresent: RequiredValidation = validateRequired(value);
  const isValid: EmailValidation = validateEmail(value);
  if (isValid && isPresent) {
    return Promise.resolve(null);
  } else if (!isPresent) {
    return Promise.reject(new ValidationError(VALIDATION_ERROR.EmailRequired));
  } else {
    return Promise.reject(new ValidationError(VALIDATION_ERROR.InvalidEmail));
  }
};

export const phoneValidator = (value: string): Promise<string | null> => {
  if (!stripOutNonNumber(`${value}`).length || isValidPhoneNumber(value)) {
    return Promise.resolve(null);
  } else {
    return Promise.reject(new ValidationError(VALIDATION_ERROR.InvalidPhone));
  }
};

export const getValidator = () => ({
  validateEmail,
  validatePassword,
  getIsRequiredValidator,
});
