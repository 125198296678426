import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import theme from '@client/css-modules/NoResultsNotice.css';

type Props = {
  emptyMessage: JSX.Element | string;
  instructionMessage?: JSX.Element | string;
  icon: JSX.Element;
  theme: Theme;
};

const NoResultsNotice: React.FC<Props> = ({
  theme,
  emptyMessage,
  instructionMessage,
  icon,
}) => {
  return (
    <div className={theme.NoResultsNotice}>
      <div className={theme.IconSection} aria-hidden>
        {icon}
      </div>
      <div className={theme.EmptyMessage}>{emptyMessage}</div>
      <div className={theme.InstructionMessage}>{instructionMessage}</div>
    </div>
  );
};

const ThemedNoResultsNotice = themr('NoResultsNotice', theme)(NoResultsNotice);
export default ThemedNoResultsNotice;
