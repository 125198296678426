import { get, isEqual, uniqBy } from 'lodash';
import queryString from 'query-string';
import windowOrGlobal from 'window-or-global';

import { Action } from '@client/store/actions';
import {
  CLOSE_CONFIRM_EMAIL_BANNER,
  CLOSE_UPDATE_EMAIL_BANNER,
} from '@client/store/actions/app-banner.actions';
import { RESET_AUTH_DEPENDENT_STATE } from '@client/store/actions/auth.actions';
import { SET_LOCAL_STORAGE_PROPERTY_SEEN } from '@client/store/actions/property-details.actions';
import {
  FETCH_USER_LOCATION_SUCCESS,
  SearchApplySavedFiltersAction,
  SEARCH_APPLY_SAVED_FILTERS,
  SEARCH_CLEAR_ALL_FILTERS,
  SEARCH_CLEAR_CONSTRAINED_TO_PLACE,
  SEARCH_CLEAR_MAP_NON_CLUSTER_MARKERS,
  SEARCH_CLEAR_PLACES,
  SEARCH_CLEAR_RESULT_MAP_MARKERS,
  SEARCH_CLEAR_SELECTED_FILTER_INITIAL_VALUE,
  SEARCH_CLEAR_SET_MAP_LOCATION,
  SEARCH_DISMISS_MLS_COVERAGE_MODAL,
  SEARCH_FETCH_MORE_LIST_PROPERTIES,
  SEARCH_FETCH_MORE_SIDEBAR_PROPERTIES,
  SEARCH_FETCH_PLACES,
  SEARCH_FETCH_PLACES_SUCCESS,
  SEARCH_FETCH_PLACE_DETAILS_SUCCESS,
  SEARCH_FETCH_PROPERTY_LIST,
  SEARCH_FETCH_PROPERTY_LIST_SUCCESS,
  SEARCH_FETCH_SELECTED_MAP_PROPERTY_SUCCESS,
  SEARCH_FILTER_MAP_MARKERS_BY_ZOOM,
  SEARCH_GET_LAST_SEARCH_SUCCESS,
  SEARCH_GET_USER_LOCATION_SUCCESS,
  SEARCH_GET_USER_LOCATION_VIA_SELECTION,
  SEARCH_HIDE_MLS_REGISTRATION_CONFIRM,
  SEARCH_INVALIDATE_MAP_SIZE,
  SEARCH_LIST_APPLY_SORT,
  SEARCH_PERSIST_MAP_LOCATION,
  SEARCH_PROPERTY_LIST_UPDATE_SCROLL_POSITION,
  SEARCH_REMOVE_TEMPORARY_MARKER,
  SEARCH_REPORT_SHOWING_MAP_BOTTOM_LAYER_CONTROL,
  SEARCH_SET_ACTIVE_MULTI_UNIT_PROPERTIES,
  SEARCH_SET_MAP_LOCATION_TO_LAST_VIEWED_PDP,
  SEARCH_SET_MAP_MARKERS_FOR_TILE,
  SEARCH_SET_MAP_VIEWPORT,
  SEARCH_SET_MLS_LAYER_FEATURES,
  SEARCH_SET_PROPERTY_COUNT,
  SEARCH_SET_PULSE_LOCATIONS,
  SEARCH_SET_SELECTED_FILTER_INITIAL_VALUE,
  SEARCH_SHOW_MLS_REGISTRATION_CONFIRM,
  SEARCH_SHOW_TEMPORARY_MARKER,
  SEARCH_TOGGLE_MAP_BOTTOM_LAYER_GROUPS_LIST,
  SEARCH_TOGGLE_MARKERS,
  SEARCH_UPDATE_FILTER,
  SEARCH_UPDATE_MAP_MULTI_UNIT_PROPERTY_CACHE,
  SEARCH_UPDATE_MAP_TILE_CACHE,
  SEARCH_UPDATE_TEXT_BOX,
} from '@client/store/actions/search.actions';
import {
  ADD_TO_WATCHLIST,
  FETCH_WATCHLIST_SUCCESS,
  REMOVE_FROM_WATCHLIST,
} from '@client/store/actions/watchlist.actions';
import {
  SEARCH_MAP_LOCATION_URL_PARAM_KEY,
  SEARCH_MAP_URL_PARAM_DELIMINATOR,
  STATUSES,
} from '@client/store/constants';
import {
  FilterKey,
  FiltersState,
  FILTER_KEYS,
  INITIAL_FILTER_VALUES,
} from '@client/store/filter-constants';
import { COMPLETE_MLS_COVERAGE_LEVEL } from '@client/store/map-constants';
import {
  CurrentMapViewport,
  MapMarker,
  MLSCoverageLayerFeatureProperties,
  MultiUnitDataObject,
  PropertyMapMarker,
  SetMapLocation,
} from '@client/store/types/maps';
import { ConstrainedToPlace } from '@client/store/types/place-search';
import {
  NormalizedProperty,
  PropertyListProperty,
} from '@client/store/types/property';
import {
  AddressResult,
  PlaceResult,
  SearchListSortField,
  SearchListSortOrder,
} from '@client/store/types/search';
import { Status } from '@client/store/types/statuses';
import { urlParamsToInitialFilterState } from '@client/utils/filters.utils';
import {
  getBoundsFromCenterPointAndRadius,
  getIsPositionBounds,
  getTileKeyForTile,
  isMultiUnitClusterMarker,
  isPropertyMarker,
  TILE_KEY_ZOOM_REGEX,
} from '@client/utils/maps.utils';
import { normalizePropertyData } from '@client/utils/property.utils';
import { Geometry } from 'geojson';

/* Used to cause the map to reposition when we want to set a position that was set most recently */
const SMALL_LONGITUDE_ADJUSTMENT = 0.000001;
const searchString = get(windowOrGlobal, ['location', 'search'], '');
const query = queryString.parse(searchString);

export type PlaceSearchState = {
  addresses: AddressResult[];
  places: PlaceResult[];
  sessionToken: string | null;
  status: Status;
};

type UserLocationState = {
  latitude: number | null;
  longitude: number | null;
  status: 'SUCCESS' | 'INIT';
};

type PropertyListState = {
  status: Status;
  properties: PropertyListProperty[];
  showLoadingIndicatorWhenLoading: boolean;
  cursor: string | null;
  scrollPosition: number;
  atEnd: boolean | null;
  isLoadingMore: boolean;
  lastUpdatedTimestamp: string;
};

export const getInitialMlsStateFilterValue = () =>
  INITIAL_FILTER_VALUES[FILTER_KEYS.MLS_STATE];

export const getInitialPropertyTypeFilterValues = (
  isDisplayMultiFamilySearchFiltersEnabled?: boolean
) => {
  return isDisplayMultiFamilySearchFiltersEnabled
    ? INITIAL_FILTER_VALUES[FILTER_KEYS.PROPERTY_TYPE]
    : INITIAL_FILTER_VALUES[FILTER_KEYS.PROPERTY_TYPE].filter(
        (value) => value !== 'MULTI'
      );
};

/**
 * Calculate the initial map-search filter state from the given URL params
 */
export const getInitialSearchFilterState = (
  query: {},
  overrideListPrice?: number | null,
  isDisplayMultiFamilySearchFiltersEnabled?: boolean
): FiltersState => {
  return {
    ...INITIAL_FILTER_VALUES,
    [FILTER_KEYS.PROPERTY_TYPE]: getInitialPropertyTypeFilterValues(
      isDisplayMultiFamilySearchFiltersEnabled
    ),
    [FILTER_KEYS.MLS_STATE]: getInitialMlsStateFilterValue(),
    ...urlParamsToInitialFilterState(query),
    ...(overrideListPrice
      ? { [FILTER_KEYS.LIST_PRICE_MIN_MAX]: [null, overrideListPrice] }
      : {}),
  };
};

/**
 * Calculate the initial map-search location state from the given URL params
 */
export const getInitialSearchMapLocationState = (query: {}): SetMapLocation => {
  /* Get map location from URL as array */
  const mapLocationArray = query[SEARCH_MAP_LOCATION_URL_PARAM_KEY]
    ? query[SEARCH_MAP_LOCATION_URL_PARAM_KEY].split(
        SEARCH_MAP_URL_PARAM_DELIMINATOR
      )
    : [];

  return mapLocationArray.length === 4
    ? [
        [parseFloat(mapLocationArray[0]), parseFloat(mapLocationArray[1])],
        [parseFloat(mapLocationArray[2]), parseFloat(mapLocationArray[3])],
      ]
    : null;
};

/**
 * Return state after a saved-search has been selected in the UI, applying that saved search's location
 * and filter params to the SRP
 */
export const getSearchApplySavedSearchState = (
  currentSetMapLocation: SetMapLocation,
  data: SearchApplySavedFiltersAction['payload']
) => ({
  locationBeforeApplyingSavedSearch: currentSetMapLocation,
  /* Causes the map location to be changed immediately when on the map-search page */
  setMapLocation: getUniqueSetMapLocation(currentSetMapLocation, [
    [data.southWest.lat, data.southWest.lng],
    [data.northEast.lat, data.northEast.lng],
  ]),
  /* Needed for when saved-search is applied while viewing filters (and not viewing the map) */
  currentMapViewport: {
    southWest: data.southWest,
    northEast: data.northEast,
  },
  filters: {
    ...INITIAL_FILTER_VALUES,
    ...data.filters,
  },
  propertyList: {
    ...initialSearchState.propertyList,
    showLoadingIndicatorWhenLoading: true,
  },
  propertySort: {
    ...initialSearchState.propertySort,
    sortField: 'LIST_DATE' as 'LIST_DATE',
    sortOrder: 'DESCENDING' as 'DESCENDING',
  },
  constrainedToPlace: data.constrainedToPlace,
  placeGeoJSON: data.placeGeoJSON || initialSearchState.placeGeoJSON,
  placeGeoJSONDescription:
    data.placeGeoJSONDescription || initialSearchState.placeGeoJSONDescription,
});

export type SearchState = {
  currentMapViewport: CurrentMapViewport;
  currentMapZoom: number | null;
  constrainedToPlace: ConstrainedToPlace;
  placeSearch: PlaceSearchState;
  userLocation: UserLocationState;
  propertyList: PropertyListState;
  propertyCount: number | null;
  previousLoadedPropertyCount: number[];
  searchText: string;
  setMapLocation: SetMapLocation;
  markersByTile: {
    [key: string]: MapMarker[];
  };
  mapPulseLocations:
    | {
        latitude: number;
        longitude: number;
      }[]
    | null;
  temporaryMarkerData: NormalizedProperty | null;
  tileCache: {
    [key: string]: any; // TODO: Should not be any, but type seems complex
  };
  isShowingMapMarkers: boolean;
  isShowingMapBottomLayerGroupsList: boolean;
  isShowingMapBottomLayerControl: boolean;
  filters: typeof INITIAL_FILTER_VALUES;
  locationBeforeApplyingSavedSearch: SetMapLocation;
  placeGeoJSON: Geometry | null;
  placeGeoJSONDescription: string;
  multiUnitMarkersByLatLng: {
    [key: string]: MultiUnitDataObject[];
  };
  activeMultiUnitProperties: MultiUnitDataObject[] | null;
  invalidateMapSizeTrigger: number;
  propertySort: {
    sortField: SearchListSortField | null;
    sortOrder: SearchListSortOrder | null;
  };
  selectedFilterInitialValue: {
    key: FilterKey | null;
    values: any[];
  };
  mlsCoverageStatus: Status;
  mlsCoverageLayerFeaturesForMapArea: (null | MLSCoverageLayerFeatureProperties)[];
  allowShowingMLSCoverageModal: boolean;
  showMLSRegistrationConfirm: boolean;
};

/**
 * Given current state, return a 2 dimensional array to be used for the `setMapLocation` state field
 * @param  {object} state
 * @return {array}
 */
const getSetMapLocationFromCurrentViewport = (
  state: SearchState
): SetMapLocation => {
  return state.currentMapViewport
    ? [
        [
          state.currentMapViewport.northEast.lat,
          state.currentMapViewport.northEast.lng,
        ],
        [
          state.currentMapViewport.southWest.lat,
          state.currentMapViewport.southWest.lng,
        ],
      ]
    : state.setMapLocation;
};

/**
 * Given current state and a new `setMapLocation`, return a slightly different location if the
 * two locations are equal.  This is to force the map to reposition itself when setting to a
 * location that was set most recently.
 */
const getUniqueSetMapLocation = (
  oldLocation: SetMapLocation,
  newLocation: SetMapLocation
): SetMapLocation => {
  if (!newLocation) {
    return null;
  }
  if (isEqual(oldLocation, newLocation)) {
    if (getIsPositionBounds(newLocation)) {
      return [
        [newLocation[0][0], newLocation[0][1]],
        [newLocation[1][0], newLocation[1][1] + SMALL_LONGITUDE_ADJUSTMENT],
      ];
    } else {
      return [newLocation[0], newLocation[1] + SMALL_LONGITUDE_ADJUSTMENT];
    }
  } else {
    return newLocation;
  }
};

export const initialSearchState: SearchState = {
  currentMapViewport: null,
  /* This is needed for fetching new map properties when a map move doesn't happen */
  currentMapZoom: null,
  /* This constraint is set either in server-side state for cities/zipcode pages or after
   * searching for a place client side. It's cleared when a user clear the address search input field */
  constrainedToPlace: null,
  placeSearch: {
    addresses: [],
    places: [],
    sessionToken: null,
    status: STATUSES.INIT,
  },
  userLocation: {
    status: STATUSES.INIT,
    latitude: null,
    longitude: null,
  },
  propertyList: {
    status: STATUSES.INIT,
    properties: [],
    showLoadingIndicatorWhenLoading: true,
    cursor: null,
    atEnd: false,
    isLoadingMore: false,
    scrollPosition: 0,
    lastUpdatedTimestamp: '0',
  },
  propertyCount: null,
  /* Use previousLoadedPropertyCount to properly set the GeneralAdCard at the multiple positions with the property cards */
  previousLoadedPropertyCount: [],
  searchText: '',
  /* Initial state set server-side in search.js.  Also need here for hot-reloading. */
  setMapLocation: getInitialSearchMapLocationState(query),
  markersByTile: {},
  /* Locations for which to add a pulse animation, drawing attention to markers */
  mapPulseLocations: null,
  temporaryMarkerData: null,
  tileCache: {},
  isShowingMapMarkers: true,
  isShowingMapBottomLayerGroupsList: false,
  isShowingMapBottomLayerControl: false,
  /* Initial state set server-side in search.js.  Also need here for hot-reloading.  */
  filters: getInitialSearchFilterState(query),
  locationBeforeApplyingSavedSearch: null,
  placeGeoJSON: null,
  placeGeoJSONDescription: '',
  multiUnitMarkersByLatLng: {},
  activeMultiUnitProperties: null,
  /* Unfortunate but necessary due to Android browsers changing the window size when the
   * keyboard shows/hides */
  invalidateMapSizeTrigger: 0,
  propertySort: {
    sortField: null,
    sortOrder: null,
  },
  /* Used to return to the initial value when you cancel out of a mobile filter control */
  selectedFilterInitialValue: {
    key: null,
    values: [null, null],
  },
  mlsCoverageStatus: STATUSES.INIT,
  mlsCoverageLayerFeaturesForMapArea: [
    { mls_coverage: COMPLETE_MLS_COVERAGE_LEVEL },
  ],
  allowShowingMLSCoverageModal: true,
  showMLSRegistrationConfirm: false,
};

export default function searchReducer(
  state = initialSearchState,
  action: Action
): SearchState {
  switch (action.type) {
    case SEARCH_FETCH_PLACES:
      return {
        ...state,
        placeSearch: {
          ...state.placeSearch,
          status: STATUSES.LOADING,
        },
      };
    case SEARCH_FETCH_PLACES_SUCCESS:
      return {
        ...state,
        placeSearch: {
          addresses: (action.payload.addresses || []).map((address) => ({
            addressId: address.hcAddressId,
            buildingId: address.fields.hcBuildingId || null,
            count: address.fields.count || null,
            text: address.fields.fullLine || null,
            partialLine: address.fields.partialLine || null,
            /* This needs to be unique for each item in the autocomplete dropdown and is only actually
             * used for single unit addresses */
            slug: address.fields.count
              ? `${address.fields.slug}-multiunit`
              : address.fields.slug,
          })),
          places: (action.payload.places || []).map((place) => ({
            placeId: place.placeId,
            placeType: place.placeType,
            text: place.description,
          })),
          sessionToken: action.payload.sessionToken,
          status: STATUSES.SUCCESS,
        },
      };
    case SEARCH_CLEAR_PLACES:
      return {
        ...state,
        placeSearch: {
          ...state.placeSearch,
          addresses: initialSearchState.placeSearch.addresses,
          places: initialSearchState.placeSearch.places,
          status: initialSearchState.placeSearch.status,
        },
      };
    case SEARCH_CLEAR_SET_MAP_LOCATION:
      return {
        ...state,
        /* Note that this causes the map to be un-rendered.  It's re-rendered when a new
         * location is set */
        setMapLocation: null,
      };
    case SEARCH_SET_MAP_LOCATION_TO_LAST_VIEWED_PDP:
      return {
        ...state,
        setMapLocation: action.payload.setMapLocation,
        currentMapViewport: {
          southWest: {
            lat: action.payload.bounds[0][0],
            lng: action.payload.bounds[0][1],
          },
          northEast: {
            lat: action.payload.bounds[1][0],
            lng: action.payload.bounds[1][1],
          },
        },
      };
    case SEARCH_FETCH_PLACE_DETAILS_SUCCESS:
      return {
        ...state,
        constrainedToPlace: action.payload.constrainedToPlace,
        placeGeoJSONDescription: action.payload.placeGeoJSONDescription,
        setMapLocation: getUniqueSetMapLocation(
          state.setMapLocation,
          action.payload.placeBounds
        ),
        /* Setting this so that it's available when seeking to show the sort view on init */
        currentMapViewport: {
          southWest: {
            lat: action.payload.placeBounds[0][0],
            lng: action.payload.placeBounds[0][1],
          },
          northEast: {
            lat: action.payload.placeBounds[1][0],
            lng: action.payload.placeBounds[1][1],
          },
        },
        /* Only reset property list after searching for a place client-side. If loading the /cities
         * or /zipcode routes server-side, the property list is already set */
        propertyList: action.payload.shouldResetPropertyList
          ? initialSearchState.propertyList
          : state.propertyList,
        placeGeoJSON: action.payload.placeGeoJSON,
        /* Reset so that the "limited data coverage" map notification hides.  It'll reappear if we have
         * limited data coverage after the map layer loads for the new place. */
        mlsCoverageLayerFeaturesForMapArea: [
          { mls_coverage: COMPLETE_MLS_COVERAGE_LEVEL },
        ],
        /* Since this action updates the constrainedToPlace mlsCoverage property, allow the modal to be
         * shown again.  It'll be shown if mlsCoverage is below a certain threshold. */
        allowShowingMLSCoverageModal: true,
      };
    case FETCH_USER_LOCATION_SUCCESS:
      return {
        ...state,
        userLocation: {
          latitude: action.payload.latitude,
          longitude: action.payload.longitude,
          status: STATUSES.SUCCESS,
        },
      };
    case SEARCH_FETCH_PROPERTY_LIST:
      return {
        ...state,
        propertyList: {
          ...state.propertyList,
          status: STATUSES.LOADING,
          cursor: null,
          atEnd: false,
          showLoadingIndicatorWhenLoading:
            action.payload.showLoadingIndicator === undefined
              ? state.propertyList.showLoadingIndicatorWhenLoading
              : action.payload.showLoadingIndicator,
        },
        /* Need to also reset the previousLoadedPropertyCount when the map is reloaded to fetch the propertyList */
        previousLoadedPropertyCount:
          initialSearchState.previousLoadedPropertyCount,
      };
    case SEARCH_FETCH_MORE_SIDEBAR_PROPERTIES:
    case SEARCH_FETCH_MORE_LIST_PROPERTIES:
      return {
        ...state,
        propertyList: {
          ...state.propertyList,
          isLoadingMore: true,
        },
      };
    case SEARCH_FETCH_PROPERTY_LIST_SUCCESS:
      /* Ensure unique when fetching the next "page" of properties */
      let properties = uniqBy(
        [
          ...(action.payload.append ? state.propertyList.properties : []),
          ...action.payload.properties.map((item) => {
            /* Add geoLocation to property to conform with typing reqs */
            const propertyWithGeoLocation = {
              ...item.property,
              geoLocation: item.summary.geoLocation,
            };
            return {
              ...normalizePropertyData(propertyWithGeoLocation, item.summary!),
              isAddedToWatchList: !!action.payload.watchlistProperties.find(
                (watchlistItem) =>
                  watchlistItem.slug ===
                  get(item, ['property', 'address', 'slug'])
              ),
            };
          }),
        ],
        'slug'
      );
      return {
        ...state,
        propertyList: {
          ...state.propertyList,
          status: action.payload.isErrorResponse
            ? STATUSES.ERROR
            : STATUSES.SUCCESS,
          properties,
          cursor: action.payload.cursor,
          atEnd: action.payload.atEnd,
          isLoadingMore: false,
          showLoadingIndicatorWhenLoading: false,
          lastUpdatedTimestamp: action.payload.lastUpdatedTimestamp,
        },
        /* Only add the property length to the previousLoadedPropertyCount array if that number doesn't exist yet */
        previousLoadedPropertyCount:
          !state.previousLoadedPropertyCount.includes(properties.length)
            ? [...state.previousLoadedPropertyCount, properties.length]
            : [...state.previousLoadedPropertyCount],
        /* The incoming totalCount can be null if the new spatial backend is disabled.  Don't update
         * propertyCount in this case, as the previous value set via the dedicated property count
         * request (enabled via `search_count_request` flag) can still be used. */
        propertyCount:
          action.payload.totalCount === null
            ? state.propertyCount
            : action.payload.totalCount,
      };
    case SEARCH_SET_PROPERTY_COUNT:
      return {
        ...state,
        propertyCount: action.payload.count,
      };
    case SEARCH_UPDATE_TEXT_BOX:
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    /* Set marker data objects to be rendered on map for a tile */
    case SEARCH_SET_MAP_MARKERS_FOR_TILE:
      let setMarkersForTileMarkers = {
        ...state.markersByTile,
        [getTileKeyForTile(action.payload.tile)]: action.payload.markers,
      };
      action.payload.removeMarkersFromTileKeys.forEach((tileKey) => {
        delete setMarkersForTileMarkers[tileKey];
      });
      return {
        ...state,
        markersByTile: setMarkersForTileMarkers,
      };
    case SEARCH_FILTER_MAP_MARKERS_BY_ZOOM:
      return {
        ...state,
        markersByTile: Object.keys(state.markersByTile)
          .filter(
            (tileKey) =>
              (tileKey.match(TILE_KEY_ZOOM_REGEX) as string[])[1] ===
              action.payload.zoom.toString()
          ) // TODO: should not rely on a regex to extract this
          .reduce((output, tileKey) => {
            output[tileKey] = state.markersByTile[tileKey];
            return output;
          }, {}),
      };
    /* Update the tile cache with raw data returned from the API */
    case SEARCH_UPDATE_MAP_TILE_CACHE:
      return {
        ...state,
        tileCache: {
          ...state.tileCache,
          [getTileKeyForTile(action.payload.tile)]: action.payload.data,
        },
      };
    case SEARCH_CLEAR_MAP_NON_CLUSTER_MARKERS:
      return {
        ...state,
        markersByTile: Object.keys(state.markersByTile)
          .filter((tileKey) =>
            state.markersByTile[tileKey].find(
              (marker) => marker && marker['isCluster']
            )
          )
          .reduce((output, tileKey) => {
            output[tileKey] = state.markersByTile[tileKey];
            return output;
          }, {}),
      };
    case SEARCH_CLEAR_RESULT_MAP_MARKERS:
      return {
        ...state,
        markersByTile: initialSearchState.markersByTile,
        multiUnitMarkersByLatLng: initialSearchState.multiUnitMarkersByLatLng,
        tileCache: initialSearchState.tileCache,
      };
    case SEARCH_FETCH_SELECTED_MAP_PROPERTY_SUCCESS:
      const normalizedFullPropertyDetails = normalizePropertyData(
        action.payload.data
      );
      return {
        ...state,
        propertyList: {
          ...state.propertyList,
          /* Only add property to property list if it isn't already there */
          properties: state.propertyList.properties.find(
            (property) => property.slug === normalizedFullPropertyDetails.slug
          )
            ? state.propertyList.properties
            : /* Ensure unique to prevent adding property that already exists in property list */
              uniqBy(
                [
                  normalizedFullPropertyDetails,
                  ...state.propertyList.properties,
                ],
                'slug'
              ),
        },
      };
    case ADD_TO_WATCHLIST:
    case REMOVE_FROM_WATCHLIST:
      const addToWatchlistSoughtTileKey = Object.keys(state.markersByTile).find(
        (key) =>
          state.markersByTile[key].find(
            (marker) =>
              (isPropertyMarker(marker) &&
                marker.addressSlug === action.payload.slug) ||
              (isMultiUnitClusterMarker(marker) &&
                marker.childAddressSlugs.includes(action.payload.slug))
          )
      );
      const addToWatchlistUpdatedPropertyList =
        state.propertyList.properties.map((property) => ({
          ...property,
          isAddedToWatchList:
            action.payload.slug === property.slug
              ? action.type === ADD_TO_WATCHLIST
              : property.isAddedToWatchList,
        }));

      return {
        ...state,
        propertyList: {
          ...state.propertyList,
          properties: addToWatchlistUpdatedPropertyList,
        },
        /* Add or remove the check icon from a single property marker or a multi-unit marker */
        markersByTile: addToWatchlistSoughtTileKey
          ? {
              ...state.markersByTile,
              [addToWatchlistSoughtTileKey]: [
                ...state.markersByTile[addToWatchlistSoughtTileKey].map(
                  (marker) => {
                    /* If property we're seeking to add or remove from watchlist is a stand-alone property,
                     * simply toggle the checked style boolean to add/remove the check icon */
                    if (
                      marker &&
                      isPropertyMarker(marker) &&
                      marker.addressSlug === action.payload.slug
                    ) {
                      return {
                        ...marker,
                        isCheckedStyle: action.type === ADD_TO_WATCHLIST,
                      };
                      /* If it's part of a multi-unit building, we want to add the check icon when adding
                       * the property to watchlist, but only remove the check icon if removing the property
                       * from the watchlist AND there are no other properties in the building in the watchlist */
                    } else if (marker && isMultiUnitClusterMarker(marker)) {
                      if (
                        action.type === ADD_TO_WATCHLIST &&
                        /* If the multi-unit cluster contains the property which we're removing from watchlist */
                        marker.childAddressSlugs.includes(action.payload.slug)
                      ) {
                        return {
                          ...marker,
                          isCheckedStyle: true,
                        };
                      } else if (
                        action.type === REMOVE_FROM_WATCHLIST &&
                        /* If the multi-unit cluster contains the property which we're removing from watchlist */
                        marker.childAddressSlugs.includes(
                          action.payload.slug
                        ) &&
                        /* If the multi-unit cluster now has no properties in the watchlist */
                        !marker.childAddressSlugs.some((childAddressSlug) =>
                          addToWatchlistUpdatedPropertyList
                            .filter((property) => property.isAddedToWatchList)
                            .map((property) => property.slug)
                            .includes(childAddressSlug)
                        )
                      ) {
                        return {
                          ...marker,
                          isCheckedStyle: false,
                        };
                      } else {
                        return marker;
                      }
                    } else {
                      return marker;
                    }
                  }
                ),
              ],
            }
          : state.markersByTile,
      };
    case FETCH_WATCHLIST_SUCCESS:
      return {
        ...state,
        propertyList: {
          ...state.propertyList,
          properties: state.propertyList.properties.map((property) => ({
            ...property,
            isAddedToWatchList:
              /* Use existing value if present to account for adding a watchlist property just before
               * all properties are retrieved from API */
              property.isAddedToWatchList ||
              !!action.payload.data.find(
                (watchlistItem) => watchlistItem.slug === property.slug
              ),
          })),
        },
      };
    case RESET_AUTH_DEPENDENT_STATE:
      return {
        ...state,
        propertyList: {
          ...state.propertyList,
          properties: state.propertyList.properties.map((property) => ({
            ...property,
            isAddedToWatchList: false,
          })),
        },
      };
    case SEARCH_REMOVE_TEMPORARY_MARKER:
      return {
        ...state,
        temporaryMarkerData: initialSearchState.temporaryMarkerData,
      };
    case SEARCH_SHOW_TEMPORARY_MARKER:
      return {
        ...state,
        temporaryMarkerData: action.payload.data,
      };
    case SEARCH_TOGGLE_MARKERS:
      return {
        ...state,
        isShowingMapMarkers: action.payload.shouldShow,
      };
    case SEARCH_CLEAR_ALL_FILTERS:
      return {
        ...state,
        /* Clear markers since new properties will be loaded */
        markersByTile: initialSearchState.markersByTile,
        multiUnitMarkersByLatLng: initialSearchState.multiUnitMarkersByLatLng,
        tileCache: initialSearchState.tileCache,
        filters: {
          ...INITIAL_FILTER_VALUES,
          [FILTER_KEYS.PROPERTY_TYPE]: getInitialPropertyTypeFilterValues(
            action.payload.isDisplayMultiFamilySearchFiltersEnabled
          ),
        },
      };
    case SEARCH_APPLY_SAVED_FILTERS:
      return {
        ...state,
        ...getSearchApplySavedSearchState(state.setMapLocation, action.payload),
      };
    case SEARCH_GET_LAST_SEARCH_SUCCESS:
      return {
        ...state,
        setMapLocation: getUniqueSetMapLocation(state.setMapLocation, [
          [action.payload.southWest.lat, action.payload.southWest.lng],
          [action.payload.northEast.lat, action.payload.northEast.lng],
        ]),
        /* Setting this so that it's available when seeking to show the sort view on init */
        currentMapViewport: {
          southWest: {
            lat: action.payload.southWest.lat,
            lng: action.payload.southWest.lng,
          },
          northEast: {
            lat: action.payload.northEast.lat,
            lng: action.payload.northEast.lng,
          },
        },
        filters: {
          ...INITIAL_FILTER_VALUES,
          ...action.payload.filters,
        },
        constrainedToPlace: action.payload.constrainedToPlace,
        placeGeoJSON:
          action.payload.placeGeoJSON || initialSearchState.placeGeoJSON,
        placeGeoJSONDescription:
          action.payload.placeGeoJSONDescription ||
          initialSearchState.placeGeoJSONDescription,
        propertySort: {
          ...initialSearchState.propertySort,
          sortField: action.payload.sortField,
          sortOrder: action.payload.sortOrder,
        },
      };
    case SEARCH_UPDATE_FILTER:
      return {
        ...state,
        /* Clear markers since new properties will be loaded */
        markersByTile: initialSearchState.markersByTile,
        multiUnitMarkersByLatLng: initialSearchState.multiUnitMarkersByLatLng,
        tileCache: initialSearchState.tileCache,
        filters: {
          ...state.filters,
          [action.payload.key]: action.payload.value,
        },
      };
    case SEARCH_SET_MAP_VIEWPORT:
      return {
        ...state,
        currentMapViewport: {
          southWest: action.payload.southWest,
          northEast: action.payload.northEast,
        },
        currentMapZoom: action.payload.zoom,
        /* We only reset property list if the user is viewing the map on mobile when the map changes position.
         * This is so that we know to load fresh property list data when the user switches to list view */
        propertyList: action.payload.shouldResetPropertyListState
          ? initialSearchState.propertyList
          : state.propertyList,
        /* We only reset property count if the user is viewing the map on mobile when the map changes position
         * This is so that we don't flash an old count before it updates when opening mobile filters */
        propertyCount: action.payload.shouldResetPropertyListState
          ? initialSearchState.propertyCount
          : state.propertyCount,
      };
    case SEARCH_SET_PULSE_LOCATIONS:
      return {
        ...state,
        mapPulseLocations: action.payload.locations,
      };
    case SEARCH_CLEAR_CONSTRAINED_TO_PLACE:
    case SEARCH_GET_USER_LOCATION_VIA_SELECTION:
      return {
        ...state,
        constrainedToPlace: initialSearchState.constrainedToPlace,
        placeGeoJSON: initialSearchState.placeGeoJSON,
        /* We're tying the place boundary map layer to the presence of the
         * place description in the autocomplete */
        placeGeoJSONDescription: initialSearchState.placeGeoJSONDescription,
      };
    case SEARCH_UPDATE_MAP_MULTI_UNIT_PROPERTY_CACHE:
      const _newMultiUnitPropertyCache = {
        ...state.multiUnitMarkersByLatLng,
        ...action.payload.multiUnitMarkersByLatLng,
      };
      return {
        ...state,
        /* Optimize component render performance by only creating a new object when necessary */
        multiUnitMarkersByLatLng: isEqual(
          _newMultiUnitPropertyCache,
          state.multiUnitMarkersByLatLng
        )
          ? state.multiUnitMarkersByLatLng
          : _newMultiUnitPropertyCache,
      };
    case SEARCH_SET_ACTIVE_MULTI_UNIT_PROPERTIES:
      return {
        ...state,
        activeMultiUnitProperties: action.payload.properties,
      };
    case SEARCH_LIST_APPLY_SORT:
      const { sortField, sortOrder } = action.payload;
      return {
        ...state,
        propertySort: {
          ...state.propertySort,
          sortField,
          sortOrder,
        },
        propertyList: {
          ...state.propertyList,
          status: STATUSES.LOADING,
          cursor: null,
          atEnd: false,
          showLoadingIndicatorWhenLoading: true,
        },
      };
    /* Persist current map location upon leaving search page */
    case SEARCH_PERSIST_MAP_LOCATION:
      return {
        ...state,
        isShowingMapBottomLayerControl: false,
        setMapLocation: getSetMapLocationFromCurrentViewport(state),
      };
    case SEARCH_SET_SELECTED_FILTER_INITIAL_VALUE:
      return {
        ...state,
        selectedFilterInitialValue: {
          key: action.payload.key,
          values: action.payload.values,
        },
      };
    case SEARCH_CLEAR_SELECTED_FILTER_INITIAL_VALUE:
      return {
        ...state,
        selectedFilterInitialValue:
          initialSearchState.selectedFilterInitialValue,
      };
    case SEARCH_TOGGLE_MAP_BOTTOM_LAYER_GROUPS_LIST:
      return {
        ...state,
        isShowingMapBottomLayerGroupsList: action.payload.shouldShow,
      };
    case SEARCH_REPORT_SHOWING_MAP_BOTTOM_LAYER_CONTROL:
      return {
        ...state,
        isShowingMapBottomLayerControl: action.payload.isShowing,
      };
    case SEARCH_GET_USER_LOCATION_SUCCESS:
      const _userLocationBounds = getBoundsFromCenterPointAndRadius(
        [action.payload.setMapLocation[0], action.payload.setMapLocation[1]],
        1609 /* fairly arbitrary 1 mile radius in meters */
      );
      return {
        ...state,
        setMapLocation: getUniqueSetMapLocation(
          state.setMapLocation,
          action.payload.setMapLocation
        ),
        /* Setting this so that it's available when seeking to show the sort view on init */
        currentMapViewport: {
          southWest: {
            lat: _userLocationBounds[0][0],
            lng: _userLocationBounds[0][1],
          },
          northEast: {
            lat: _userLocationBounds[1][0],
            lng: _userLocationBounds[1][1],
          },
        },
      };
    case SEARCH_INVALIDATE_MAP_SIZE:
    case CLOSE_CONFIRM_EMAIL_BANNER:
    case CLOSE_UPDATE_EMAIL_BANNER:
      return {
        ...state,
        invalidateMapSizeTrigger: Math.random(),
      };
    case SEARCH_PROPERTY_LIST_UPDATE_SCROLL_POSITION:
      return {
        ...state,
        propertyList: {
          ...state.propertyList,
          scrollPosition: action.payload.position,
        },
      };
    case SEARCH_SET_MLS_LAYER_FEATURES:
      return {
        ...state,
        mlsCoverageStatus: STATUSES.SUCCESS,
        mlsCoverageLayerFeaturesForMapArea: action.payload.features,
      };
    case SEARCH_DISMISS_MLS_COVERAGE_MODAL:
      return {
        ...state,
        allowShowingMLSCoverageModal: false,
      };
    case SEARCH_HIDE_MLS_REGISTRATION_CONFIRM:
      return {
        ...state,
        showMLSRegistrationConfirm: false,
      };
    case SEARCH_SHOW_MLS_REGISTRATION_CONFIRM:
      return {
        ...state,
        showMLSRegistrationConfirm: true,
      };
    case SET_LOCAL_STORAGE_PROPERTY_SEEN:
      const seenPropertySoughtTileKey = Object.keys(state.markersByTile).find(
        (key) =>
          state.markersByTile[key].find(
            (marker) =>
              (marker as PropertyMapMarker)?.addressSlug === action.payload.slug
          )
      );

      if (!seenPropertySoughtTileKey) {
        return state;
      }
      return {
        ...state,
        markersByTile: {
          ...state.markersByTile,
          [seenPropertySoughtTileKey]: [
            ...state.markersByTile[seenPropertySoughtTileKey].map((marker) => {
              if (
                marker &&
                (marker as PropertyMapMarker)?.addressSlug ===
                  action.payload.slug
              ) {
                return {
                  ...marker,
                  isVisitedStyle: true,
                };
              } else {
                return marker;
              }
            }),
          ],
        },
      };
    default:
      return state;
  }
}
