import {
  HistoryType,
  RouteDefsAllType,
  RouterOptionsType,
  SagaMiddlewareType,
} from './types';

import { fork, takeEvery } from 'redux-saga/effects';

import router from './router';
import { goToRoute, goToPreviousRoute, logRouterError } from './sagas';
import { ROUTE_CHANGE, ROUTE_BACK, ROUTER_ERROR } from './actions';
import { Saga } from 'redux-saga';

export default function initRouter(
  history: HistoryType,
  ROUTES: RouteDefsAllType,
  sagaMiddleware: SagaMiddlewareType,
  options: RouterOptionsType
): void {
  function* watchRouteChange() {
    yield takeEvery(ROUTE_CHANGE, goToRoute as Saga, history, ROUTES);
  }
  sagaMiddleware.run(watchRouteChange);

  function* watchRouteBack() {
    yield takeEvery(
      ROUTE_BACK,
      goToPreviousRoute as Saga,
      history,
      ROUTES,
      options
    );
  }
  sagaMiddleware.run(watchRouteBack);

  function* watchRouterError() {
    yield takeEvery(ROUTER_ERROR, logRouterError);
  }
  sagaMiddleware.run(watchRouterError);

  function* mainRouterSaga() {
    yield fork(router, history, ROUTES, options);
  }
  sagaMiddleware.run(mainRouterSaga);
}
