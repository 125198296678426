import { call } from 'ramda';
import { put } from 'redux-saga/effects';
import { routeChange } from '@src/redux-saga-router-plus/actions';

import { handleLogout } from '@client/store/sagas/auth.saga';
import { View } from '@client/routes/constants';

export default function* routeLogout() {
  yield call(handleLogout);
  yield put(routeChange({ view: View.SEARCH }));
}
