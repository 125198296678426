import { put, PutEffect } from 'redux-saga/effects';
import { routeChange } from '@src/redux-saga-router-plus/actions';

import { View } from '@client/routes/constants';
import { reportToSentry } from '@client/utils/error.utils';
import { isPropertyDataValid } from '@client/utils/property.utils';
import {
  IncomingPropertyLookupData,
  PartialPropertyWithAddressAndGeoRequired,
  PropertyLookupWithAddressRequired,
} from '@client/store/types/property';

const BASE_ERROR_MSG =
  'Property graph responded with null or incomplete property data';

/* This should be used to facilitate client-side error page redirects when invalid property data is
 * retrieved in the API client */
export function* validatePropertyDataOrThrowError(
  slug: string,
  property: IncomingPropertyLookupData | null,
  shouldRedirectTo404: boolean,
  errors: any
): Generator<
  PutEffect<any>,
  PropertyLookupWithAddressRequired | PartialPropertyWithAddressAndGeoRequired
> {
  if (isPropertyDataValid(property)) {
    return property;
  } else {
    reportToSentry(
      `${BASE_ERROR_MSG}${errors?.length ? ' (with errors)' : ''}`,
      { slug, property, errors }
    );

    if (shouldRedirectTo404) {
      yield put(routeChange({ view: View.PAGE_404 }));
    }

    throw new Error(`${BASE_ERROR_MSG} for slug ` + slug);
  }
}
