import classNames from 'classnames';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import HudLogo from '@client/assets/images/hud-logo.svg';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import theme from '@client/css-modules/HUDLogo.css';

const HUD_LINK = 'https://www.hud.gov/';

type Props = {
  className?: string;
  theme: Theme;
};

const HUDLogo = ({ className, theme }: Props) => {
  return (
    <div
      className={classNames(theme.HudLogoContainer, className)}
      data-hc-name={'hud-logo'}
    >
      <button
        className={theme.HudLogoButton}
        onClick={() => {
          window.open(HUD_LINK, '_blank');
        }}
        onKeyDown={(e) => {
          onEnterOrSpaceKey(() => window.open(HUD_LINK, '_blank'));
        }}
        type="button"
      >
        <img
          className={theme.HudLogo}
          alt="Equal Housing Opportunity logo"
          src={HudLogo}
        />
      </button>
    </div>
  );
};

export const ThemedHUDLogo = themr('HUDLogo', theme)(HUDLogo);

export default ThemedHUDLogo;
