export default ({ placeId, sessionToken, placeSlug }) => ({
  query: /* GraphQL */ `
    query PlaceLookup(
      $placeId: String
      $sessionToken: String
      $placeSlug: String
    ) {
      placeLookup(
        id: { placeId: $placeId, sessiontoken: $sessionToken, slug: $placeSlug }
      ) {
        place {
          placeId
          placeType
          name
          locality
          region
          formattedAddress
          viewport {
            northeastLatitude
            northeastLongitude
            southwestLatitude
            southwestLongitude
          }
          location {
            latitude
            longitude
          }
          shape
          mlscoverage
        }
      }
    }
  `,
  variables: {
    placeId,
    sessionToken,
    placeSlug,
  },
});
