import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { routeChange } from '@src/redux-saga-router-plus/actions';

import { getIsTouchDevice } from '@client/store/selectors/match-media.selectors';
import { logTOUAcceptance } from '@client/store/actions/cobranding.actions';
import { reportCobrandTOUClick } from '@client/store/actions/analytics.actions';
import { searchFetchPlaceDetails } from '@client/store/actions/search.actions';
import AccessiblePageTitleContainer from '@client/containers/accessible-page-title.container';
import theme from '@client/css-modules/WelcomeTransitionPageChase.css';
import RouterLink from '@client/components/RouterLink';
import AutoCompleteSearchContainer from '@client/containers/autocomplete-search.container';
import { View } from '@client/routes/constants';
import WelcomeComeHomeText from '@client/assets/images/cobrand/chase/welcome-comehome-text.png';
import ComeHomeLogoImg from '@client/components/ComeHomeLogoImg';
import PillButton from '@client/components/generic/PillButton';
import { Result, AddressResult, PlaceResult } from '@client/store/types/search';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type Props = {};

/*
 * Shown the user upon entering the CH experience from a cobranded app only until
 * they search for a property, thereby accepting the terms of use
 */
const WelcomeTransitionPageChase: React.FC<Props> = ({}) => {
  const dispatch = useDispatch();
  const [selectedSearchItem, setSelectedSearchItem] = useState<null | Result>(
    null
  );
  const [isShowingTopSection, setIsShowingTopSection] = useState<boolean>(true);
  const [isDesktopOverlayRaised, setIsDesktopOverlayRaised] =
    useState<boolean>(false);
  const isTouchDevice = useSelector(getIsTouchDevice);

  const handleReportTOUClick = () => {
    dispatch(reportCobrandTOUClick());
  };

  const handleSearch = () => {
    const addressSlug = (selectedSearchItem as AddressResult)?.slug;
    const placeId = (selectedSearchItem as PlaceResult)?.placeId;

    if (addressSlug) {
      dispatch(
        routeChange({
          view: View.PROPERTY_DETAILS,
          params: { slug: addressSlug },
        })
      );
      dispatch(logTOUAcceptance({ slug: addressSlug }));
    } else if (placeId && (selectedSearchItem as PlaceResult)?.placeType) {
      /* Assert typing as this is only run if it is a PlaceResult */
      const resultItem = selectedSearchItem as PlaceResult;
      dispatch(
        searchFetchPlaceDetails({
          id: placeId,
          type: resultItem.placeType,
          text: resultItem.text,
        })
      );
      dispatch(logTOUAcceptance({ placeId }));
    }
  };

  const handleHideTopSection = () => {
    setIsShowingTopSection(false);
  };

  const handleLargeScreenInputMouseDown = () => {
    if (isTouchDevice) {
      setIsDesktopOverlayRaised(true);
    }
  };

  const { linkColor } = useCobrandStyles();

  return (
    <div
      className={classNames(theme.WelcomeTransitionPage, {
        [theme.WelcomeTransitionPageWithDesktopOverlayRaised]:
          isDesktopOverlayRaised,
      })}
    >
      <AccessiblePageTitleContainer />
      {isShowingTopSection && (
        <>
          <div className={theme.TopPhoto}>
            <img
              src={WelcomeComeHomeText}
              className={theme.ComeHomeLogoImg}
              alt="Welcome to ComeHome"
            />
          </div>
          <div className={theme.WelcomeTo}>Welcome to</div>
        </>
      )}
      <ComeHomeLogoImg className={theme.Logo} shouldUseAltText />
      <div className={theme.LargeScreenOverlay}>
        <div className={theme.FinePrint}>
          ComeHome is provided by HouseCanary, Inc., a real estate brokerage. By
          clicking "Search" you agree to the&nbsp;
          <RouterLink
            style={{ color: linkColor }}
            data-ignore-intercept
            onClick={handleReportTOUClick}
            view={View.TERMS_OF_USE}
            ariaLabel="terms of use"
          >
            Terms of Use.
          </RouterLink>
        </div>
        {/* Only shown on large screens via CSS */}
        <AutoCompleteSearchContainer
          placeholder={'Address, city or ZIP code'}
          hideSavedSearches
          hideSubmitButton
          noSearchIconBackground
          isStaticallyPositioned
          preventDefaultResultClickFunctionality
          onResultClick={setSelectedSearchItem}
          theme={{
            ...theme,
            AutoComplete: classNames(
              theme.AutoComplete,
              theme.AutoCompleteLargeScreen
            ),
          }}
          onInputMouseDown={handleLargeScreenInputMouseDown}
        />
        {/* Only shown on small screens via CSS */}
        <AutoCompleteSearchContainer
          placeholder={'Address, city or ZIP code'}
          hideSavedSearches
          hideSubmitButton
          noSearchIconBackground
          isStaticallyPositioned
          preventDefaultResultClickFunctionality
          onResultClick={setSelectedSearchItem}
          theme={{
            ...theme,
            AutoComplete: classNames(
              theme.AutoComplete,
              theme.AutoCompleteSmallScreen
            ),
          }}
          onInputMouseDown={handleHideTopSection}
        />
        <PillButton
          secondary
          theme={theme}
          onClick={handleSearch}
          disabled={!selectedSearchItem}
          ariaLabel="Search"
        >
          Search
        </PillButton>
      </div>
    </div>
  );
};

export default WelcomeTransitionPageChase;
