/* Ported from react-input-range
 * https://github.com/davidchin/react-input-range */

import React from 'react';

type Position = {
  x: number;
  y: number;
};

type Props = {
  classNames: { [className: string]: string };
  draggableTrack: boolean;
  onTrackDrag: (event: MouseEvent, eventTwo: MouseEvent) => void;
  onTrackMouseDown: (event: MouseEvent, position: Position) => void;
  percentages: { [key: string]: number };
  children?: React.ReactNode;
};

export default class Track extends React.Component<Props> {
  node: HTMLElement | null;
  trackDragEvent: null | MouseEvent;
  constructor(props) {
    super(props);

    this.node = null;
    this.trackDragEvent = null;
  }

  /**
   * @private
   * @return {ClientRect}
   */
  getClientRect = () => {
    return this.node && this.node.getBoundingClientRect();
  };

  /**
   * @private
   * @return {Object} CSS styles
   */
  getActiveTrackStyle = () => {
    const width = `${
      (this.props.percentages.max - this.props.percentages.min) * 100
    }%`;
    const left = `${this.props.percentages.min * 100}%`;

    return { left, width };
  };

  /**
   * Listen to mousemove event
   * @private
   * @return {void}
   */
  addDocumentMouseMoveListener = () => {
    this.removeDocumentMouseMoveListener();
    this.node &&
      this.node.ownerDocument.addEventListener(
        'mousemove',
        this.handleMouseMove
      );
  };

  /**
   * Listen to mouseup event
   * @private
   * @return {void}
   */
  addDocumentMouseUpListener = () => {
    this.removeDocumentMouseUpListener();
    this.node &&
      this.node.ownerDocument.addEventListener('mouseup', this.handleMouseUp);
  };

  /**
   * @private
   * @return {void}
   */
  removeDocumentMouseMoveListener = () => {
    this.node &&
      this.node.ownerDocument.removeEventListener(
        'mousemove',
        this.handleMouseMove
      );
  };

  /**
   * @private
   * @return {void}
   */
  removeDocumentMouseUpListener = () => {
    this.node &&
      this.node.ownerDocument.removeEventListener(
        'mouseup',
        this.handleMouseUp
      );
  };

  /**
   * @private
   * @param {SyntheticEvent} event
   * @return {void}
   */
  handleMouseMove = (event) => {
    if (!this.props.draggableTrack) {
      return;
    }

    if (this.trackDragEvent !== null) {
      this.props.onTrackDrag(event, this.trackDragEvent);
    }

    this.trackDragEvent = event;
  };

  /**
   * @private
   * @return {void}
   */
  handleMouseUp = () => {
    if (!this.props.draggableTrack) {
      return;
    }

    this.removeDocumentMouseMoveListener();
    this.removeDocumentMouseUpListener();
    this.trackDragEvent = null;
  };

  /**
   * @private
   * @param {SyntheticEvent} event - User event
   */
  handleMouseDown = (event) => {
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const trackClientRect = this.getClientRect();
    const position = {
      x: clientX - (trackClientRect?.left || 0),
      y: 0,
    };

    this.props.onTrackMouseDown(event, position);

    if (this.props.draggableTrack) {
      this.addDocumentMouseMoveListener();
      this.addDocumentMouseUpListener();
    }
  };

  /**
   * @private
   * @param {SyntheticEvent} event - User event
   */
  handleTouchStart = (event) => {
    event.preventDefault();

    this.handleMouseDown(event);
  };

  /**
   * @override
   * @return {JSX.Element}
   */
  render() {
    const activeTrackStyle = this.getActiveTrackStyle();

    return (
      <div
        className={this.props.classNames.track}
        onMouseDown={this.handleMouseDown}
        onTouchStart={this.handleTouchStart}
        ref={(node) => {
          this.node = node;
        }}
      >
        <div
          style={activeTrackStyle}
          className={this.props.classNames.activeTrack}
        />
        {this.props.children}
      </div>
    );
  }
}
