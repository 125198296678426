import { call, put } from 'redux-saga/effects';
import { watchEvery } from '@client/utils/saga.utils';

import { graphQLApiClient } from '@client/services/graphql-api-client';
import { consumerApiClient } from '@client/services/consumer-api-client';

import {
  fetchPropertyPhotosSuccess,
  fetchStreetViewUrlSuccess,
} from '@client/store/actions/property-photos.actions';

import {
  FETCH_PROPERTY_PHOTOS,
  FETCH_STREET_VIEW_URL,
  FetchPropertyPhotosAction,
  FetchStreetViewAvailabilityAction,
} from '@client/store/types/property-photos';
import { PropertyDetailsPhotosQuery } from '@client/store/sagas/queries/types';

/* Used to get photos for a mobile search property card and for a PDP property when logging-in after
 * viewing a property with restricted MLS photos */
function* getPropertyPhotosSaga(action: FetchPropertyPhotosAction) {
  const { slug, size } = action.payload;
  const data = (yield call(
    [graphQLApiClient, graphQLApiClient.getPropertyPhotos],
    { slug, size: size }
  )) as PropertyDetailsPhotosQuery;

  yield put(
    fetchPropertyPhotosSuccess({
      size,
      slug,
      bestPhotos: data?.propertyLookup?.bestPhotos ?? null,
      mls: data?.propertyLookup?.mls ?? null,
    })
  );
}

/* Fetches and sets the Google Street View availability for a certain lat,lng */
function* fetchPropertyStreetViewUrl(
  action: FetchStreetViewAvailabilityAction
) {
  const { slug, fullAddress, imageHeight, imageWidth } = action.payload;
  try {
    const { url } = yield call(
      [
        consumerApiClient,
        consumerApiClient.getStreetViewUrlForGivenFullAddress,
      ],
      fullAddress,
      imageHeight,
      imageWidth
    );
    if (!url) {
      throw new Error('No URL found in successful streetview response');
    }
    yield put(
      fetchStreetViewUrlSuccess({
        slug,
        url,
      })
    );
  } catch (e: any) {
    /* 404 case here is valid - means no streetview exists for the property */
    yield put(
      fetchStreetViewUrlSuccess({
        slug,
        url: null,
      })
    );
    if (e.statusCode !== 404) {
      throw e;
    }
  }
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [FETCH_PROPERTY_PHOTOS]: getPropertyPhotosSaga,
    [FETCH_STREET_VIEW_URL]: fetchPropertyStreetViewUrl,
  });
};
