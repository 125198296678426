export const APPLICATION_DID_MOUNT = 'APPLICATION_DID_MOUNT';

type ApplicationDidMount = {
  type: typeof APPLICATION_DID_MOUNT;
};

export const applicationDidMount = (): ApplicationDidMount => ({
  type: APPLICATION_DID_MOUNT,
});

export type ServerRenderingInfoAction = ApplicationDidMount;
