import { connect } from 'react-redux';
import { getShouldDisplayHomeownerHomeBuyerToggle } from '@client/store/selectors/homeowner.selectors';
import PropertyPageTopBarCobranded from '@client/components/PropertyPageTopBar/PropertyPageTopBarCobranded';

const mapStateToProps = (state) => {
  return {
    shouldDisplayToggleForUserAndProperty:
      getShouldDisplayHomeownerHomeBuyerToggle(state),
  };
};

export default connect(mapStateToProps, {})(PropertyPageTopBarCobranded);
