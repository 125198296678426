import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { generateUUID } from '@client/utils/analytics.utils';
import { ReduxState } from '@client/store/types/redux-state';
import { createSelector } from 'reselect';

export interface AnalyticsDataState {
  contextualSearchId: string | null;
  currentPDPViewId: string | null;
  genericViewId: string | null;
  currentHICEstimateId: string | null;
}

const initialState: AnalyticsDataState = {
  contextualSearchId: null,
  currentPDPViewId: null,
  genericViewId: null,
  currentHICEstimateId: null,
};

type Uuid = string;

export const analyticsDataSlice = createSlice({
  name: 'analyticsData',
  initialState: initialState,
  reducers: {
    _updateContextualSearchId: (state, action: PayloadAction<Uuid>) => ({
      ...state,
      contextualSearchId: action.payload,
    }),
    _updatePDPViewId: (state, action: PayloadAction<Uuid>) => ({
      ...state,
      currentPDPViewId: action.payload,
    }),
    clearPDPViewId: (state) => ({
      ...state,
      currentPDPViewId: null,
    }),
    _updateGenericViewId: (state, action: PayloadAction<Uuid>) => ({
      ...state,
      genericViewId: action.payload,
    }),
    _updateHICEstimateId: (state, action: PayloadAction<Uuid>) => ({
      ...state,
      currentHICEstimateId: action.payload,
    }),
  },
});

export function selectAnalyticsDataState(state: ReduxState) {
  return state.analyticsData;
}

export const selectGenericViewId = createSelector(
  selectAnalyticsDataState,
  (analyticsDataState) => analyticsDataState.genericViewId
);

export const selectContextualSearchId = createSelector(
  selectAnalyticsDataState,
  (analyticsDataState) => analyticsDataState.contextualSearchId
);

export const selectPDPViewId = createSelector(
  selectAnalyticsDataState,
  (analyticsDataState) => analyticsDataState.currentPDPViewId
);

export const selectHICEstimateId = createSelector(
  selectAnalyticsDataState,
  (analyticsDataState) => analyticsDataState.currentHICEstimateId
);

const {
  _updateContextualSearchId,
  _updatePDPViewId,
  _updateGenericViewId,
  _updateHICEstimateId,
} = analyticsDataSlice.actions;

export const { clearPDPViewId } = analyticsDataSlice.actions;

export const updateContextualSearchId = () =>
  _updateContextualSearchId(generateUUID());
export const updatePDPViewId = () => _updatePDPViewId(generateUUID());
export const updateGenericViewId = () => _updateGenericViewId(generateUUID());
export const updateHICEstimateId = () => _updateHICEstimateId(generateUUID());

export default analyticsDataSlice.reducer;
