export default (addressId) => ({
  query: /* GraphQL */ `
    query SlugForAddressId($addressId: NumericID) {
      propertyLookup(id: { addressId: $addressId }) {
        address {
          slug
        }
      }
    }
  `,
  variables: {
    addressId,
  },
});
