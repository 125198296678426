import { callSaga, watchEvery, watchLeading } from '@client/utils/saga.utils';
import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, delay, put, select } from 'redux-saga/effects';
import * as Sentry from 'sentry-isomorphic';

import {
  LO_DIRECT_DISABLED_ERROR_QUERY_KEY,
  View,
} from '@client/routes/constants';
import { authClient } from '@client/services/consumer-api-auth-client';
import { consumerApiClient } from '@client/services/consumer-api-client';
import { graphQLApiClient } from '@client/services/graphql-api-client';
import { reportEvent } from '@client/store/actions/analytics.actions';
import {
  CreateLODirectUserAction,
  createUserError,
  createUserSuccess,
  CREATE_LO_DIRECT_USER,
  DeleteLoDirectUserImageAction,
  DELETE_LO_DIRECT_USER_IMAGE,
  loginError,
  LoginLODirectUserAction,
  loginSuccess,
  LOGIN_LO_DIRECT_USER,
  PatchLoDirectUserProfileAction,
  PATCH_LO_DIRECT_USER_PROFILE,
  PostLoDirectUserImageAction,
  POST_LO_DIRECT_USER_IMAGE,
  updateUserInfoOnState,
} from '@client/store/actions/auth.actions';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { GRANT_TYPES } from '@client/store/constants';
import {
  handleLoginSideEffects,
  handleLogout,
} from '@client/store/sagas/auth.saga';
import {
  getCurrentUser,
  getUserId,
} from '@client/store/selectors/auth.selectors';
import { getIsComehomeBrandedLODirect } from '@client/store/selectors/cobranding.selectors';
import {
  deleteLoDirectClient,
  fetchAdditionalClientData,
  fetchAdditionalClientDataSuccess,
  fetchCSVTemplate,
  fetchLoDirectActivity,
  fetchLoDirectActivitySuccess,
  fetchLoDirectClients,
  fetchLoDirectClientsSuccess,
  fetchLoDirectIncreasedActivity,
  fetchLoDirectIncreasedActivitySuccess,
  fetchLoDirectLeads,
  fetchLoDirectLeadsError,
  fetchLoDirectLeadsSuccess,
  fetchOriginalLoanAmount,
  fetchOriginalLoanAmountSuccess,
  LoDirectClientsUploadPayload,
  logoutLODirectUser,
  patchLoDirectClient,
  patchLoDirectClientSuccess,
  postBulkLoDirectClients,
  postLoDirectClientsSuccess,
  postLODirectSupportEmail,
  postSingleLoDirectClient,
  putLODirectBulkUpload,
  putLODirectBulkUploadError,
  selectIsLoDirectSubscriptionVerified,
  selectLoDirectClients,
  setLeadAsRead,
  setLoDirectNotification,
  verifyLODirectSubscriptionIfNecessary,
  verifyLODirectSubscriptionSuccess,
} from '@client/store/slices/lo-direct.slice';
import { LOUserApiResponse } from '@client/store/types/consumer-api';
import { downloadFile } from '@client/utils/download-file.utils';
import { reportToSentry } from '@client/utils/error.utils';
import { routeChange } from '@src/redux-saga-router-plus/actions';

function* getLOUserId() {
  const userId = (yield select(getUserId)) as ReturnType<typeof getUserId>;

  /* This saga should never be run for logged-out users */
  if (!userId) {
    throw new Error('Attempting to get LO Direct clients without a user id');
  }

  return userId;
}

function* fetchLoDirectClientsSaga() {
  try {
    const clients = yield* callSaga(
      [consumerApiClient, consumerApiClient.getLoDirectClients],
      yield call(getLOUserId)
    );
    yield put(fetchLoDirectClientsSuccess(clients));
  } catch (e) {
    throw new Error('Fetch LO Clients failed: ' + JSON.stringify(e));
  }
}

function* postLoDirectSingleClientsSaga(
  action: PayloadAction<LoDirectClientsUploadPayload>
) {
  try {
    const loClientResponse = yield* callSaga(
      [consumerApiClient, consumerApiClient.postLoDirectClients],
      yield call(getLOUserId),
      { data: action.payload.data }
    );
    yield put(postLoDirectClientsSuccess(loClientResponse));
    if (!loClientResponse?.invalid_clients?.length) {
      yield put(
        setLoDirectNotification({
          message: 'Successfully added client!',
          type: 'success',
        })
      );
    } else if (loClientResponse?.invalid_clients?.length === 1) {
      yield put(
        setLoDirectNotification({
          message: 'Error adding client, please contact support',
          type: 'error',
        })
      );
    } else {
      console.error('Unexpected invalid client response', loClientResponse);
    }
  } catch (e) {
    yield put(setLoDirectNotification({ type: 'error' }));
    throw new Error('POST LO Clients failed: ' + JSON.stringify(e));
  }
}

function* postLoDirectBulkClientsSaga(
  action: PayloadAction<LoDirectClientsUploadPayload>
) {
  try {
    const loClientResponse = yield* callSaga(
      [consumerApiClient, consumerApiClient.postLoDirectClients],
      yield call(getLOUserId),
      { data: action.payload.data }
    );
    yield put(postLoDirectClientsSuccess(loClientResponse));
    if (!loClientResponse?.invalid_clients?.length) {
      yield put(
        setLoDirectNotification({
          message: 'Successfully added client!',
          type: 'success',
        })
      );
    }
  } catch (e) {
    yield put(setLoDirectNotification({ type: 'error' }));
    throw new Error('POST LO Clients failed: ' + JSON.stringify(e));
  }
}

function* patchLoDirectClientSaga(
  action: PayloadAction<LoDirectClientsUploadPayload>
) {
  try {
    const loClientResponse = yield* callSaga(
      [consumerApiClient, consumerApiClient.patchLoDirectClient],
      yield call(getLOUserId),
      { data: action.payload.data }
    );
    yield put(patchLoDirectClientSuccess(loClientResponse));
    yield put(
      setLoDirectNotification({
        message: 'Successfully updated client!',
        type: 'success',
      })
    );
  } catch (e) {
    yield put(setLoDirectNotification({ type: 'error' }));
    throw new Error('PATCH LO Clients failed: ' + JSON.stringify(e));
  }
}

function* pullPropertyDataForClient(client) {
  if (client.slug) {
    const clientData = yield* callSaga(
      [graphQLApiClient, graphQLApiClient.getLoDirectClientData],
      {
        slug: client.slug,
      }
    );
    return {
      ...client,
      avm: clientData?.propertyLookup?.avm?.priceMean,
      full_address: clientData?.propertyLookup?.address?.fullAddress,
    };
  } else {
    return {
      ...client,
      avm: null,
      full_address: null,
    };
  }
}

function* fetchAdditionalClientDataSaga() {
  const clients = yield select(selectLoDirectClients);
  const updatedClients = yield all(
    clients.map((client) => pullPropertyDataForClient(client))
  );
  yield put(fetchAdditionalClientDataSuccess({ clients: updatedClients }));
}

function* fetchCSVTemplateSaga(action: PayloadAction<{ userId: string }>) {
  const byteArray = yield call(
    [consumerApiClient, consumerApiClient.fetchCSVTemplate],
    action.payload.userId
  );
  yield downloadFile(
    new File([Buffer.from(byteArray, 'base64')], 'clients-template.csv')
  );
}

function* putLODirectBulkUploadSaga(
  action: PayloadAction<{ file: File; userId: string }>
) {
  yield call(getLOUserId); /* check valid loDirect user */
  try {
    const loClientResponse = yield* callSaga(
      [consumerApiClient, consumerApiClient.putLODirectBulkUpload],
      action.payload.file,
      action.payload.userId
    );
    yield put(postLoDirectClientsSuccess(loClientResponse));
  } catch (e) {
    yield put(putLODirectBulkUploadError());
    throw new Error('PUT csv upload LO Clients failed: ' + JSON.stringify(e));
  }
}

function* postLODirectSupportEmailSaga(
  action: PayloadAction<{ subject: string; message: string }>
) {
  try {
    yield* callSaga(
      [consumerApiClient, consumerApiClient.postLODirectSupportEmail],
      action.payload.subject,
      action.payload.message
    );
    yield put(
      setLoDirectNotification({
        message: 'Email sent!',
        type: 'success',
      })
    );
  } catch (e) {
    yield put(setLoDirectNotification({ type: 'error' }));
    throw new Error(
      'POST LODirectSupportEmailSaga failed: ' + JSON.stringify(e)
    );
  }
}

function* fetchOriginalLoanAmountSaga(
  action: PayloadAction<{ addressId: number; slug: string }>
) {
  try {
    const response = yield* callSaga(
      [consumerApiClient, consumerApiClient.getOriginalLoanAmount],
      action.payload.addressId
    );
    yield put(
      fetchOriginalLoanAmountSuccess({
        slug: action.payload.slug,
        loanAmount: response.original_loan_amount,
      })
    );
  } catch (e: any) {
    if (e?.statusCode !== 404) {
      throw new Error(
        'GET fetchOriginalLoanAmountSaga failed: ' + JSON.stringify(e)
      );
    }
  }
}

function* deleteLoDirectClientSaga(
  action: PayloadAction<{ clientId: string }>
) {
  try {
    yield* callSaga(
      [consumerApiClient, consumerApiClient.deleteLoDirectClient],
      action.payload.clientId,
      yield call(getLOUserId)
    );
    yield put(
      setLoDirectNotification({
        message: 'Client deleted!',
        type: 'success',
      })
    );
  } catch (e: any) {
    yield put(setLoDirectNotification({ type: 'error' }));
    throw new Error(
      'DELETE deleteLoDirectClientSaga failed: ' + JSON.stringify(e)
    );
  }
}

function* fetchLoDirectLeadsSaga() {
  const userId = yield select(getUserId);
  try {
    const response = yield* callSaga(
      [consumerApiClient, consumerApiClient.getLoDirectLeads],
      userId
    );
    yield put(fetchLoDirectLeadsSuccess(response));
  } catch (e) {
    yield put(fetchLoDirectLeadsError());
    throw new Error('GET fetchLoDirectLeadsSaga failed: ' + JSON.stringify(e));
  }
}

function* setLeadAsReadSaga(action: PayloadAction<{ messageId: string }>) {
  const userId = yield select(getUserId);
  yield* callSaga(
    [consumerApiClient, consumerApiClient.postLoMessageRead],
    action.payload.messageId,
    userId
  );
}

export function* patchLoDirectUserProfileSaga(
  action: PatchLoDirectUserProfileAction
) {
  try {
    yield put(
      updateUserInfoOnState({
        ...(yield select(getCurrentUser)),
        ...action.payload,
      })
    );
    yield* callSaga(
      [authClient, authClient.updateLoDirectUserInfo],
      action.payload
    );
    yield put(
      setLoDirectNotification({
        message: 'Profile updated!',
        type: 'success',
      })
    );
  } catch (error: any) {
    yield put(setLoDirectNotification({ type: 'error' }));
  }
}

export function* postLoDirectUserImageSaga(
  action: PostLoDirectUserImageAction
) {
  try {
    if (action.payload.userInfo.headshot) {
      yield* callSaga(
        [authClient, authClient.deleteLoDirectUserImage],
        action.payload.userInfo.id
      );
    }
    const response = yield call(
      [authClient, authClient.postLoDirectUserImage],
      action.payload
    );
    yield put(
      updateUserInfoOnState({
        ...action.payload.userInfo,
        headshot: response.url,
      })
    );
    yield put(
      setLoDirectNotification({
        message: 'Successfully uploaded image!',
        type: 'success',
      })
    );
  } catch (error: any) {
    yield put(setLoDirectNotification({ type: 'error' }));
  }
}

export function* deleteLoDirectUserImageSaga(
  action: DeleteLoDirectUserImageAction
) {
  try {
    yield* callSaga(
      [authClient, authClient.deleteLoDirectUserImage],
      action.payload.id
    );
    yield put(updateUserInfoOnState(action.payload));
  } catch (error: any) {
    yield put(setLoDirectNotification({ type: 'error' }));
  }
}

export function* handleLoginLODirectUser(action: LoginLODirectUserAction) {
  const {
    credentials: { email, password },
    afterAuthAction,
  } = action.payload;
  try {
    const { user, token }: { user: LOUserApiResponse; token: string } =
      yield* callSaga([authClient, authClient.login], {
        email,
        password,
        grant_type: GRANT_TYPES.LOUSER,
      });
    yield put(loginSuccess({ user, token }));

    /* If LO-user is known by us to have an active subscription, allow them through to the intended page */
    if (user.subscription_active) {
      yield call(handleLoginSideEffects, {
        user,
        loanOfficer: null,
        agent: null,
      });
      if (afterAuthAction) {
        yield put(afterAuthAction);
      }
      /* If LO-user has either no subscription or an expired subscription (we only care about WHY LO-user is inactive here,
         since we already know they're inactive) */
    } else {
      let subscriptionStatus: string | null = null;
      try {
        const { status }: { status: string } = yield call(
          [authClient, authClient.getLODirectUserSubscriptionStatus],
          user.id
        );
        subscriptionStatus = status;
      } catch (error: any) {
        if (error.statusCode === 404) {
          subscriptionStatus = null;
        } else {
          throw error;
        }
      }
      yield call(redirectNonActiveLODirectUser, subscriptionStatus);
    }
  } catch (error: any) {
    /* TODO rethrow error if unexpected status code */
    yield put(loginError(error.messageRaw || 'Unable to login'));
  }
}

export function* logoutLODirectUserSaga() {
  yield call(handleLogout);
}

export function* handleCreateLODirectUser(action: CreateLODirectUserAction) {
  const { userInfo, afterAuthAction, inviteToken } = action.payload;
  try {
    const { user, token } = yield* callSaga(
      [authClient, authClient.createLODirectUser],
      userInfo,
      inviteToken
    );
    yield call([Sentry, Sentry.setUser], user);
    yield put(createUserSuccess({ user, token }));
    yield put(
      reportEvent('lodirect_signup_complete', PARENT_EVENTS.CLICK_SIGNUP)
    );
    if (afterAuthAction) {
      yield put(afterAuthAction);
    }
  } catch (error: any) {
    if (error.statusCode < 500) {
      let effectiveErrorMessage =
        error.messageRaw ||
        error.message ||
        'An error occurred, please check your credentials and try again';
      /* signup UI checks 8 or more char already, so condensing the error message to be more relevant */
      if (
        effectiveErrorMessage.includes(
          'password must be 8 or more characters and cannot be a common dictionary word'
        )
      ) {
        effectiveErrorMessage = 'Password cannot be a common dictionary word';
      }
      yield put(
        createUserError({
          errorMessage: effectiveErrorMessage,
          email: userInfo.email,
          firstname: userInfo.first_name,
          lastname: userInfo.last_name,
        })
      );
    } else {
      /* Rethrow error if unexpected status code */
      throw error;
    }
  }
}
/* If LO-user has an inactive subscription, redirect to either the "plans", "resubscribe", or "login" pages
 * NOTE: this logic should match the app init logic in server-side-redirect.ts */
function* redirectNonActiveLODirectUser(subscriptionStatus: string | null) {
  const user = (yield select(getCurrentUser)) as ReturnType<
    typeof getCurrentUser
  >;
  const isComehomeCobrand = (yield select(
    getIsComehomeBrandedLODirect
  )) as ReturnType<typeof getIsComehomeBrandedLODirect>;

  /* If in the ComeHome cobrand, redirect to pages leading the user back to Stripe */
  if (isComehomeCobrand) {
    reportToSentry(
      'LO-user found with inactive subscription status',
      { subscriptionStatus, user: JSON.stringify(user) },
      'info'
    );
    /* If user has a non-active subscription status, redirect to the resubscribe page */
    if (subscriptionStatus) {
      yield put(routeChange({ view: View.LO_DIRECT_RESUBSCRIBE }));
      /* If user has no subscription status, redirect to the subscribe page */
    } else {
      yield put(routeChange({ view: View.LO_DIRECT_SUBSCRIBE }));
    }
    /* For non-Comehome cobrands, redirect to the login page with an error message shown, as these LO-users are Parcon managed */
  } else {
    yield put(
      routeChange({
        view: View.LO_DIRECT_LOGIN,
        query: { [LO_DIRECT_DISABLED_ERROR_QUERY_KEY]: true },
      })
    );
  }
}

function* verifyLODirectSubscriptionSaga() {
  const userId = (yield select(getUserId)) as ReturnType<typeof getUserId>;
  const isSubscriptionVerified = (yield select(
    selectIsLoDirectSubscriptionVerified
  )) as ReturnType<typeof selectIsLoDirectSubscriptionVerified>;

  if (userId && !isSubscriptionVerified) {
    /* Wait 5 seconds to allow Stripe webhook to update subscription status post subscribe */
    yield delay(5000);
    let subscriptionStatus: string | null = null;
    let isActive: boolean = false;
    try {
      const { status, is_active }: { status: string; is_active: boolean } =
        yield* callSaga(
          [authClient, authClient.getLODirectUserSubscriptionStatus],
          userId
        );
      subscriptionStatus = status;
      isActive = is_active;
    } catch (error: any) {
      if (error.statusCode === 404) {
        subscriptionStatus = null;
        isActive = false;
      } else {
        throw error;
      }
    }

    if (isActive) {
      yield put(verifyLODirectSubscriptionSuccess());
    } else {
      yield call(redirectNonActiveLODirectUser, subscriptionStatus);
    }
  }
}

function* fetchLoDirectIncreasedActivitySaga() {
  yield put(
    fetchLoDirectIncreasedActivitySuccess(
      yield* callSaga(
        [consumerApiClient, consumerApiClient.fetchLoDirectIncreasedActivity],
        yield select(getUserId)
      )
    )
  );
}

function* fetchLoDirectActivitySaga(
  action: PayloadAction<{ type: string; start: string; end: string }>
) {
  yield put(
    fetchLoDirectActivitySuccess({
      type: action.payload.type,
      activity: yield* callSaga(
        [consumerApiClient, consumerApiClient.fetchLoDirectActivity],
        yield select(getUserId),
        action.payload.start,
        action.payload.end
      ),
    })
  );
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [fetchLoDirectClients.type]: fetchLoDirectClientsSaga,
    [postBulkLoDirectClients.type]: postLoDirectBulkClientsSaga,
    [postSingleLoDirectClient.type]: postLoDirectSingleClientsSaga,
    [fetchAdditionalClientData.type]: fetchAdditionalClientDataSaga,
    [fetchCSVTemplate.type]: fetchCSVTemplateSaga,
    [putLODirectBulkUpload.type]: putLODirectBulkUploadSaga,
    [postLODirectSupportEmail.type]: postLODirectSupportEmailSaga,
    [fetchOriginalLoanAmount.type]: fetchOriginalLoanAmountSaga,
    [fetchLoDirectLeads.type]: fetchLoDirectLeadsSaga,
    [setLeadAsRead.type]: setLeadAsReadSaga,
    [deleteLoDirectClient.type]: deleteLoDirectClientSaga,
    [patchLoDirectClient.type]: patchLoDirectClientSaga,
    [POST_LO_DIRECT_USER_IMAGE]: postLoDirectUserImageSaga,
    [DELETE_LO_DIRECT_USER_IMAGE]: deleteLoDirectUserImageSaga,
    [PATCH_LO_DIRECT_USER_PROFILE]: patchLoDirectUserProfileSaga,
    [LOGIN_LO_DIRECT_USER]: handleLoginLODirectUser,
    [CREATE_LO_DIRECT_USER]: handleCreateLODirectUser,
    [fetchLoDirectIncreasedActivity.type]: fetchLoDirectIncreasedActivitySaga,
    [fetchLoDirectActivity.type]: fetchLoDirectActivitySaga,
    [logoutLODirectUser.type]: logoutLODirectUserSaga,
  });
  watchLeading(sagaMiddleware, {
    [verifyLODirectSubscriptionIfNecessary.type]:
      verifyLODirectSubscriptionSaga,
  });
};
