/**
 * We need to access local storage within a try..catch to ensure that the app doesn't crash when attempting
 * to access local storage when it isn't supported (i.e. when the widget is iframed and browser settings deny it).
 *
 * Local storage should only be used to enhance the experience or for debugging and should never be depended upon
 * for the app to function at a base level.
 */
export type LocalStorageKey =
  | 'addressSearchDebug'
  | 'hl'
  | 'chaseJSBridgeDebug'
  | 'reduxDebug'
  | 'mapsDebug'
  | 'addressSearchDebug'
  | 'cobrandAnalyticsDryRun'
  | 'cobrandAnalyticsLiveDebug'
  | 'enableSessionKeepAlive'
  | 'disableReactAxe'
  | 'enableReduxDevtools'
  | 'reduxActionsBlacklist'
  | 'minutesUserAgeAtWhichConfirmReminderDisplays'
  | 'recentlyViewedHomes'
  | 'recentlySearched'
  | 'SSORedirectDryRun';

export const localStorageUtil = {
  getItem: (key: LocalStorageKey): string | null => {
    try {
      // eslint-disable-next-line custom/no-localstorage-direct-access
      return window.localStorage.getItem(key);
    } catch (e: any) {
      return null;
    }
  },
  setItem: (key: LocalStorageKey, value: string) => {
    try {
      // eslint-disable-next-line custom/no-localstorage-direct-access
      window.localStorage.setItem(key, value);
    } catch (e: any) {
      /* No-op */
    }
  },
  removeItem: (key: LocalStorageKey) => {
    try {
      // eslint-disable-next-line custom/no-localstorage-direct-access
      window.localStorage.removeItem(key);
    } catch (e: any) {
      /* No-op */
    }
  },
};
