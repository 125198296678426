import PropertyPageDesktopContents from '@client/components/PropertyPageDesktopContents/PropertyPageDesktopContents';
import PropertyPageDesktopContentsChase from '@client/components/PropertyPageDesktopContents/PropertyPageDesktopContentsChase';
import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { PropertyPageDesktopContentsProps } from '@client/store/types/cobranded-components/property-page';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import PropertyPageDesktopContentsComehome from '@client/components/PropertyPageDesktopContents/PropertyPageDesktopContentsComehome';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return PropertyPageDesktopContentsChase;
    case COBRAND_IDS.WWW:
      return PropertyPageDesktopContentsComehome;
    default:
      return PropertyPageDesktopContents;
  }
};

const PropertyPageDesktopContentsCobranded: React.FC<
  Omit<PropertyPageDesktopContentsProps, 'theme'>
> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default PropertyPageDesktopContentsCobranded;
