import { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';

/**
 * Note: To use this hook, the handleResize fn should be wrapped in useCallback
 * to prevent rebinding the eventlistener on each render
 */
export const useWindowResize = (handleResize: VoidFunction, delay = 300) => {
  // Prevent aggressive re-rendering by debounce the resize handling
  const debouncedHandleResize = useCallback(debounce(handleResize, delay), [
    handleResize,
    delay,
  ]);
  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [debouncedHandleResize]);
};
