import React, { Component } from 'react';

import { LatitudeLongitudeObject } from '@client/store/types/maps';
import { MlsState } from '@client/store/sagas/queries/types';
import StreetViewImage from '@client/components/StreetViewImage';
import theme from '@client/css-modules/PhotoPlaceholder.css';
import { getMapTilePhoto } from '@client/utils/photo.utils';
import CobrandedDataComponent, {
  CobrandedDataComponentArgs,
} from '@client/components/CobrandedDataComponent';
type Props = {
  /* Street View status should be requested earlier in parallel with the photos for the property
   * for performance  */
  addressSlug: string | null;
  streetViewUrl: string | null;
  streetViewFailed: boolean;
  streetViewLocation: LatitudeLongitudeObject | null;
  propertyStatus?: MlsState | 'NOT_LISTED' | null;
  fullAddress: string | null;
  mapTileSize: [number, number];
  isHidingMapTilePin?: boolean;
  handleFetchStreetViewUrl: ({
    slug,
    fullAddress,
    imageHeight,
    imageWidth,
  }: {
    slug: string;
    fullAddress: string;
    imageHeight: number;
    imageWidth: number;
  }) => void;
};

/**
 * Used to provide either a streetview or a map tile photo when a property doesn't have photos
 */
export default class PhotoPlaceholder extends Component<Props> {
  render() {
    const {
      addressSlug,
      streetViewUrl,
      streetViewFailed,
      streetViewLocation,
      mapTileSize,
      propertyStatus,
      isHidingMapTilePin,
      fullAddress,
      handleFetchStreetViewUrl,
    } = this.props;

    return (
      <CobrandedDataComponent>
        {({
          customizationData: { active_pin_url_for_map_box_tile },
        }: CobrandedDataComponentArgs) => {
          const mapTilePhoto = streetViewLocation
            ? getMapTilePhoto({
                latitude: streetViewLocation.latitude,
                longitude: streetViewLocation.longitude,
                propertyStatus: propertyStatus || null,
                activePinUrl: active_pin_url_for_map_box_tile,
                size: mapTileSize,
                isHidingPin: !!isHidingMapTilePin,
              })
            : null;

          return (
            <div className={theme.PhotoPlaceholder}>
              {
                /* Not having a location for a property is not something we currently account for, but it is
                 * possible (i.e. new construction houses) */
                streetViewLocation &&
                  addressSlug &&
                  fullAddress &&
                  !streetViewFailed && (
                    /* We do want to render this component without a `streetViewUrl` initially since it triggers
                     * the fetching of a streetview url (with the appropriate dimensions) */
                    <div className={theme.StreetViewWrapper}>
                      <StreetViewImage
                        slug={addressSlug}
                        streetViewUrl={streetViewUrl}
                        fullAddress={fullAddress}
                        handleFetchStreetViewUrl={handleFetchStreetViewUrl}
                      />
                    </div>
                  )
              }
              {streetViewFailed && (
                <div
                  className={theme.MapTilePhoto}
                  role="img"
                  aria-label="Map tile image"
                  style={{ backgroundImage: `url(${mapTilePhoto})` }}
                />
              )}
            </div>
          );
        }}
      </CobrandedDataComponent>
    );
  }
}
