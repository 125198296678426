import { GeoPrecision, MlsStateGroup } from '@client/store/sagas/queries/types';

export const MIN_MAX_FILTER_DESIGNATOR = 'MinMax';

/* Keys used to refer to filters within the app. Key values are transformed into GQL
 * parameter keys via a selector */
export const FILTER_KEYS = {
  PROPERTY_TYPE: 'propertyType' as 'propertyType',
  UNITS_TOTAL_MIN_MAX: 'unitsTotalMinMax' as 'unitsTotalMinMax',
  MLS_STATE: 'mlsStateGroup' as 'mlsStateGroup',
  AVM_PRICE_MIN_MAX: 'avmPriceMinMax' as 'avmPriceMinMax',
  GRANT_ELIGIBLE: 'grantEligible' as 'grantEligible',
  BEDS_MIN_MAX: 'bedsMinMax' as 'bedsMinMax',
  BATHS_MIN_MAX: 'bathsMinMax' as 'bathsMinMax',
  SQFT_MIN_MAX: 'sqftMinMax' as 'sqftMinMax',
  LOT_AREA_MIN_MAX: 'lotAreaMinMax' as 'lotAreaMinMax',
  YEAR_BUILT_MIN_MAX: 'yearBuiltMinMax' as 'yearBuiltMinMax',
  BEST_PRIMARY_SCHOOL_PERCENTILE_MIN_MAX:
    'bestPrimarySchoolPercentileMinMax' as 'bestPrimarySchoolPercentileMinMax',
  BEST_MIDDLE_SCHOOL_PERCENTILE_MIN_MAX:
    'bestMiddleSchoolPercentileMinMax' as 'bestMiddleSchoolPercentileMinMax',
  BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX:
    'bestHighSchoolPercentileMinMax' as 'bestHighSchoolPercentileMinMax',
  SEARCH_BY_MLS: 'searchByMls' as 'searchByMls',
  LIST_PRICE_MIN_MAX: 'listPriceMinMax' as 'listPriceMinMax',
  GEO_PRECISION_MIN_MAX: 'geoPrecisionMinMax' as 'geoPrecisionMinMax',
  HPI_MIN_MAX: 'hpiMinMax' as 'hpiMinMax',
  HCCV: 'hccv' as 'hccv',
  PRICE_PER_SQFT50_MIN_MAX: 'pricePerSqft50MinMax' as 'pricePerSqft50MinMax',
  CRIME_COUNTY_PERCENTILE_MIN_MAX:
    'crimeCountyPercentileMinMax' as 'crimeCountyPercentileMinMax',
  MARKET_GRADE_MIN_MAX: 'marketGradeMinMax' as 'marketGradeMinMax',
  YEAR_RISK_OF_DECLINE_MIN_MAX:
    '12moRiskOfDeclineMinMax' as '12moRiskOfDeclineMinMax',
  LIST_TO_AVM_PRICE_MIN_MAX:
    'confidentListToAvmPriceMinMax' as 'confidentListToAvmPriceMinMax',
  RENTAL_ESTIMATE_MIN_MAX:
    'confidentRentalAvmPriceMinMax' as 'confidentRentalAvmPriceMinMax',
  RENTAL_YIELD_MIN_MAX:
    'confidentRentalYieldMinMax' as 'confidentRentalYieldMinMax',
  LIST_AGE_DAYS_MIN_MAX: 'listAgeDaysMinMax' as 'listAgeDaysMinMax',
};

export type FilterKey = (typeof FILTER_KEYS)[keyof typeof FILTER_KEYS];
export type FilterMinMaxNumberValue = [number | null, number | null];
export type FilterMinMaxStringValue = [string | null, string | null];
export type FilterMinMaxBooleanValue = [boolean | null, boolean | null];
export type FilterControls = (typeof CONTROL_TYPES)[keyof typeof CONTROL_TYPES];
export type FilterkeyAbbreviations =
  (typeof FILTER_KEY_ABBREVIATIONS)[keyof typeof FILTER_KEY_ABBREVIATIONS];

/**
 * FilterPropertyTypes is a subset of all PROPERTY_TYPES available
 */
export type FilterPropertyTypes =
  | 'MULTI'
  | 'SFR'
  | 'TOWNHOUSE'
  | 'CONDO'
  | 'COOP'
  | 'MANUFACTURED';

export type FiltersState = {
  [FILTER_KEYS.LIST_PRICE_MIN_MAX]: FilterMinMaxNumberValue;
  [FILTER_KEYS.PROPERTY_TYPE]: FilterPropertyTypes[];
  [FILTER_KEYS.UNITS_TOTAL_MIN_MAX]: FilterMinMaxNumberValue;
  [FILTER_KEYS.BEDS_MIN_MAX]: FilterMinMaxNumberValue;
  [FILTER_KEYS.BATHS_MIN_MAX]: FilterMinMaxNumberValue;
  [FILTER_KEYS.AVM_PRICE_MIN_MAX]: FilterMinMaxNumberValue;
  [FILTER_KEYS.SQFT_MIN_MAX]: FilterMinMaxNumberValue;
  [FILTER_KEYS.PRICE_PER_SQFT50_MIN_MAX]: FilterMinMaxNumberValue;
  [FILTER_KEYS.LOT_AREA_MIN_MAX]: FilterMinMaxNumberValue;
  [FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX]: FilterMinMaxNumberValue;
  [FILTER_KEYS.YEAR_BUILT_MIN_MAX]: FilterMinMaxNumberValue;
  [FILTER_KEYS.LIST_TO_AVM_PRICE_MIN_MAX]: FilterMinMaxNumberValue;
  [FILTER_KEYS.HPI_MIN_MAX]: FilterMinMaxNumberValue;
  [FILTER_KEYS.GEO_PRECISION_MIN_MAX]: [
    GeoPrecision | null,
    GeoPrecision | null,
  ];
  [FILTER_KEYS.CRIME_COUNTY_PERCENTILE_MIN_MAX]: FilterMinMaxNumberValue;
  [FILTER_KEYS.YEAR_RISK_OF_DECLINE_MIN_MAX]: FilterMinMaxNumberValue;
  [FILTER_KEYS.MARKET_GRADE_MIN_MAX]: FilterMinMaxStringValue;
  [FILTER_KEYS.BEST_PRIMARY_SCHOOL_PERCENTILE_MIN_MAX]: FilterMinMaxNumberValue;
  [FILTER_KEYS.BEST_MIDDLE_SCHOOL_PERCENTILE_MIN_MAX]: FilterMinMaxNumberValue;
  [FILTER_KEYS.BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX]: FilterMinMaxNumberValue;
  [FILTER_KEYS.RENTAL_ESTIMATE_MIN_MAX]: FilterMinMaxNumberValue;
  [FILTER_KEYS.RENTAL_YIELD_MIN_MAX]: FilterMinMaxNumberValue;
  [FILTER_KEYS.HCCV]: null | boolean;
  [FILTER_KEYS.MLS_STATE]: MlsStateGroup[];
};
export type FilterValue = FiltersState[keyof FiltersState];

export const SCHOOL_FILTER_KEYS = {
  ELEMENTARY:
    'bestPrimarySchoolPercentileMinMax' as 'bestPrimarySchoolPercentileMinMax',
  MIDDLE:
    'bestMiddleSchoolPercentileMinMax' as 'bestMiddleSchoolPercentileMinMax',
  HIGH: 'bestHighSchoolPercentileMinMax' as 'bestHighSchoolPercentileMinMax',
};

export const FILTER_SECTION_HEADING_ID = {
  SCHOOL: 'schools-heading',
};

const NULL_FILTER_MIN_MAX_VALUE = [null, null] as [null, null];
export const INITIAL_FILTER_VALUES: FiltersState = {
  [FILTER_KEYS.LIST_PRICE_MIN_MAX]: NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.PROPERTY_TYPE]: ['SFR', 'TOWNHOUSE', 'CONDO', 'COOP', 'MULTI'],
  [FILTER_KEYS.UNITS_TOTAL_MIN_MAX]: NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.BEDS_MIN_MAX]: NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.BATHS_MIN_MAX]: NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.AVM_PRICE_MIN_MAX]: NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.SQFT_MIN_MAX]: NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.PRICE_PER_SQFT50_MIN_MAX]: NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.LOT_AREA_MIN_MAX]: NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX]: NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.YEAR_BUILT_MIN_MAX]: NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.LIST_TO_AVM_PRICE_MIN_MAX]: NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.HPI_MIN_MAX]: NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.GEO_PRECISION_MIN_MAX]: ['PARCEL', null],
  [FILTER_KEYS.CRIME_COUNTY_PERCENTILE_MIN_MAX]: NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.YEAR_RISK_OF_DECLINE_MIN_MAX]: NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.MARKET_GRADE_MIN_MAX]: NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.BEST_PRIMARY_SCHOOL_PERCENTILE_MIN_MAX]:
    NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.BEST_MIDDLE_SCHOOL_PERCENTILE_MIN_MAX]:
    NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX]: NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.RENTAL_ESTIMATE_MIN_MAX]: NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.RENTAL_YIELD_MIN_MAX]: NULL_FILTER_MIN_MAX_VALUE,
  [FILTER_KEYS.HCCV]: null,
  [FILTER_KEYS.MLS_STATE]: ['ACTIVE'],
};

/* Mobile filters: Order to be shown on UI as per product priority */
export const FILTERS_ORDER = [
  FILTER_KEYS.MLS_STATE,
  FILTER_KEYS.LIST_PRICE_MIN_MAX,
  FILTER_KEYS.GRANT_ELIGIBLE,
  FILTER_KEYS.PROPERTY_TYPE,
  FILTER_KEYS.UNITS_TOTAL_MIN_MAX,
  FILTER_KEYS.BEDS_MIN_MAX,
  FILTER_KEYS.BATHS_MIN_MAX,
  FILTER_KEYS.AVM_PRICE_MIN_MAX,
  FILTER_KEYS.SQFT_MIN_MAX,
  FILTER_KEYS.PRICE_PER_SQFT50_MIN_MAX,
  FILTER_KEYS.LOT_AREA_MIN_MAX,
  FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX,
  FILTER_KEYS.YEAR_BUILT_MIN_MAX,
  FILTER_KEYS.LIST_TO_AVM_PRICE_MIN_MAX,
  FILTER_KEYS.HPI_MIN_MAX,
  FILTER_KEYS.GEO_PRECISION_MIN_MAX,
  FILTER_KEYS.CRIME_COUNTY_PERCENTILE_MIN_MAX,
  FILTER_KEYS.YEAR_RISK_OF_DECLINE_MIN_MAX,
  FILTER_KEYS.MARKET_GRADE_MIN_MAX,
  FILTER_KEYS.BEST_PRIMARY_SCHOOL_PERCENTILE_MIN_MAX,
  FILTER_KEYS.BEST_MIDDLE_SCHOOL_PERCENTILE_MIN_MAX,
  FILTER_KEYS.BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX,
  FILTER_KEYS.RENTAL_ESTIMATE_MIN_MAX,
  FILTER_KEYS.RENTAL_YIELD_MIN_MAX,
  FILTER_KEYS.HCCV,
  FILTER_KEYS.SEARCH_BY_MLS,
];

/* Filters to always show as active, regardless of value */
export const ALWAYS_ACTIVE_FILTERS = [FILTER_KEYS.MLS_STATE] as FilterKey[];

/* Types to send along with the parameters in the GQL requests */
export const FILTER_GQL_TYPES = {
  [FILTER_KEYS.HCCV]: 'Boolean',
  [FILTER_KEYS.PROPERTY_TYPE]: '[FilterPropertyTypes]',
  [FILTER_KEYS.UNITS_TOTAL_MIN_MAX]: 'Int',
  [FILTER_KEYS.MLS_STATE]: '[MlsStateGroup]',
  [FILTER_KEYS.AVM_PRICE_MIN_MAX]: 'Int',
  [FILTER_KEYS.BEDS_MIN_MAX]: 'Int',
  [FILTER_KEYS.BATHS_MIN_MAX]: 'Int',
  [FILTER_KEYS.SQFT_MIN_MAX]: 'Int',
  [FILTER_KEYS.LOT_AREA_MIN_MAX]: 'Int',
  [FILTER_KEYS.YEAR_BUILT_MIN_MAX]: 'Int',
  [FILTER_KEYS.BEST_PRIMARY_SCHOOL_PERCENTILE_MIN_MAX]: 'Float',
  [FILTER_KEYS.BEST_MIDDLE_SCHOOL_PERCENTILE_MIN_MAX]: 'Float',
  [FILTER_KEYS.BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX]: 'Float',
  [FILTER_KEYS.LIST_PRICE_MIN_MAX]: 'Int',
  [FILTER_KEYS.GEO_PRECISION_MIN_MAX]: 'GeoPrecision',
  [FILTER_KEYS.HPI_MIN_MAX]: 'Float',
  [FILTER_KEYS.PRICE_PER_SQFT50_MIN_MAX]: 'Float',
  [FILTER_KEYS.CRIME_COUNTY_PERCENTILE_MIN_MAX]: 'Float',
  [FILTER_KEYS.MARKET_GRADE_MIN_MAX]: 'MarketGrade',
  [FILTER_KEYS.YEAR_RISK_OF_DECLINE_MIN_MAX]: 'Float',
  [FILTER_KEYS.LIST_TO_AVM_PRICE_MIN_MAX]: 'Float',
  [FILTER_KEYS.RENTAL_ESTIMATE_MIN_MAX]: 'Int',
  [FILTER_KEYS.RENTAL_YIELD_MIN_MAX]: 'Float',
  /* Note that this filter value will be transformed into a date string and sent as 'minListDate'/'maxListDate' */
  [FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX]: 'Date',
};

/* Map our identifiers to identifiers by investor-backend API endpoints (for saved searches)
 * Note that 'min' or 'max' gets prepended to keys that require it in a selector
 */
export const FILTER_CONSUMER_API_KEYS = {
  [FILTER_KEYS.HCCV]: 'hccv',
  [FILTER_KEYS.PROPERTY_TYPE]: 'property_type',
  [FILTER_KEYS.UNITS_TOTAL_MIN_MAX]: 'units_total',
  [FILTER_KEYS.MLS_STATE]: 'mls_state_group',
  [FILTER_KEYS.AVM_PRICE_MIN_MAX]: 'avm_price',
  [FILTER_KEYS.BEDS_MIN_MAX]: 'beds',
  [FILTER_KEYS.BATHS_MIN_MAX]: 'baths',
  [FILTER_KEYS.SQFT_MIN_MAX]: 'sqft',
  [FILTER_KEYS.LOT_AREA_MIN_MAX]: 'lot_area',
  [FILTER_KEYS.YEAR_BUILT_MIN_MAX]: 'year_built',
  [FILTER_KEYS.BEST_PRIMARY_SCHOOL_PERCENTILE_MIN_MAX]:
    'best_primary_school_percentile',
  [FILTER_KEYS.BEST_MIDDLE_SCHOOL_PERCENTILE_MIN_MAX]:
    'best_middle_school_percentile',
  [FILTER_KEYS.BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX]:
    'best_high_school_percentile',
  [FILTER_KEYS.LIST_PRICE_MIN_MAX]: 'list_price',
  [FILTER_KEYS.GEO_PRECISION_MIN_MAX]: 'geo_precision',
  [FILTER_KEYS.HPI_MIN_MAX]: 'hpi',
  [FILTER_KEYS.PRICE_PER_SQFT50_MIN_MAX]: 'price_per_sqft_50',
  [FILTER_KEYS.CRIME_COUNTY_PERCENTILE_MIN_MAX]: 'crime_county_percentile',
  [FILTER_KEYS.MARKET_GRADE_MIN_MAX]: 'market_grade',
  [FILTER_KEYS.YEAR_RISK_OF_DECLINE_MIN_MAX]: '12mo_risk_of_decline',
  [FILTER_KEYS.LIST_TO_AVM_PRICE_MIN_MAX]: 'confident_list_to_avm_price',
  [FILTER_KEYS.RENTAL_ESTIMATE_MIN_MAX]: 'confident_rental_avm_price',
  [FILTER_KEYS.RENTAL_YIELD_MIN_MAX]: 'confident_rental_yield',
  [FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX]: 'list_age_days',
};

/* Map keys to abbreviations used for URL params */
export const FILTER_KEY_ABBREVIATIONS = {
  [FILTER_KEYS.PROPERTY_TYPE]: 'ptype',
  [FILTER_KEYS.UNITS_TOTAL_MIN_MAX]: 'units',
  [FILTER_KEYS.MLS_STATE]: 'st',
  [FILTER_KEYS.AVM_PRICE_MIN_MAX]: 'avm',
  [FILTER_KEYS.BEDS_MIN_MAX]: 'bd',
  [FILTER_KEYS.BATHS_MIN_MAX]: 'ba',
  [FILTER_KEYS.SQFT_MIN_MAX]: 'sqft',
  [FILTER_KEYS.LOT_AREA_MIN_MAX]: 'lot',
  [FILTER_KEYS.YEAR_BUILT_MIN_MAX]: 'yr',
  [FILTER_KEYS.BEST_PRIMARY_SCHOOL_PERCENTILE_MIN_MAX]: 'ps',
  [FILTER_KEYS.BEST_MIDDLE_SCHOOL_PERCENTILE_MIN_MAX]: 'ms',
  [FILTER_KEYS.BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX]: 'hs',
  [FILTER_KEYS.LIST_PRICE_MIN_MAX]: 'lprice',
  [FILTER_KEYS.GEO_PRECISION_MIN_MAX]: 'gp',
  [FILTER_KEYS.HPI_MIN_MAX]: 'hpi',
  [FILTER_KEYS.HCCV]: 'hccv',
  [FILTER_KEYS.PRICE_PER_SQFT50_MIN_MAX]: 'ppsqft',
  [FILTER_KEYS.CRIME_COUNTY_PERCENTILE_MIN_MAX]: 'cp',
  [FILTER_KEYS.MARKET_GRADE_MIN_MAX]: 'mg',
  [FILTER_KEYS.YEAR_RISK_OF_DECLINE_MIN_MAX]: 'risk',
  [FILTER_KEYS.LIST_TO_AVM_PRICE_MIN_MAX]: 'lprice_avm',
  [FILTER_KEYS.RENTAL_ESTIMATE_MIN_MAX]: 'rest',
  [FILTER_KEYS.RENTAL_YIELD_MIN_MAX]: 'ryld',
  [FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX]: 'ldate',
};

/* Map values to abbreviations used for URL params */
export const FILTER_VALUE_ABBREVIATIONS = {
  closed: 'cl',
  sold: 'so',
  cancelled: 'ca',
  expired: 'ex',
  withdrawn: 'wi',
  unknown: 'un',
  deleted: 'de',
  leased: 'le',
  townhouse: 'th',
  condo: 'cond',
  active: 'a',
  off_market: 'o',
  pending: 'p',
  contingent: 'c',
};

export const PROPERTY_TYPE_LABELS = {
  ALL: 'All',
  SFR: 'House',
  TOWNHOUSE: 'Townhouse',
  CONDO: 'Condo',
  COOP: 'Co-op',
  MULTI: 'Multifamily',
};

export const OFF_MARKET_VALUES_LABELS = {
  CLOSED: 'Closed',
  SOLD: 'Sold',
  CANCELLED: 'Cancelled',
  EXPIRED: 'Expired',
  WITHDRAWN: 'Withdraw',
  UNKNOWN: 'Unknown',
  DELETED: 'Deleted',
  LEASED: 'Leased',
};

export const FE_ONLY_MLS_STATES = {
  ALL: 'ALL',
};

export const CONTROL_TYPES = {
  CHECKBOX_PICKER: 'checkbox_picker',
  MAX_ONLY_PICKER: 'max_only_picker',
  MIN_MAX_PICKER: 'min_max_picker',
  MIN_ONLY_PICKER: 'min_only_picker',
  MIN_ONLY_BUTTONS: 'min_only_buttons',
  MIN_MAX_SLIDER: 'min_max_slider',
};

export const FORMATTING_TYPES = {
  DOLLAR: 'dollar',
  CUSTOM: 'custom',
  NONE: 'none',
};

export const LABEL_DEFAULTS = {
  NO_MIN: 'No Min',
  NO_MAX: 'No Max',
  ANY: 'Any',
  ALL: 'All',
};

export const HIDDEN_MOBILE_FILTERS = [
  FILTER_KEYS.GEO_PRECISION_MIN_MAX,
  FILTER_KEYS.MLS_STATE,
  FILTER_KEYS.HCCV,
] as FilterKey[];

export const HIDDEN_SAVED_SEARCH_DESCRIPTION_FILTERS = [
  FILTER_KEYS.GEO_PRECISION_MIN_MAX,
  FILTER_KEYS.HCCV,
] as FilterKey[];

export const CONTROL_CUSTOMIZATIONS = {
  MIN_ONLY_BUTTONS_SHOULD_ADD_PLUS:
    'min_only_buttons_should_add_plus' as 'min_only_buttons_should_add_plus',
  CUSTOM_NO_MAX_TEXT: 'custom_no_max_text' as 'custom_no_max_text',
  CUSTOM_NO_MIN_TEXT: 'custom_no_min_text' as 'custom_no_min_text',
  SHOULD_REVERSE_SLIDER_COLORS:
    'should_reverse_slider_colors' as 'should_reverse_slider_colors',
  SHOULD_EXPAND_UPWARDS: 'should_expand_upwards' as 'should_expand_upwards',
};

export const QL_TYPES = {
  STRING: 'string',
  FLOAT: 'float',
  INTEGER: 'integer',
};

export const MLS_STATE_ALL_GROUPS = {
  ACTIVE: FILTER_KEYS.MLS_STATE + '-' + 'ACTIVE',
  CONTINGENT: FILTER_KEYS.MLS_STATE + '-' + 'CONTINGENT',
  OFF_MARKET: FILTER_KEYS.MLS_STATE + '-' + 'OFF_MARKET',
  PENDING: FILTER_KEYS.MLS_STATE + '-' + 'PENDING',
  ALL: FILTER_KEYS.MLS_STATE + '-' + 'ALL',
};

export type MlsStateAllGroup =
  (typeof MLS_STATE_ALL_GROUPS)[keyof typeof MLS_STATE_ALL_GROUPS];
