import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import LendcoEmailIcon from '@client/inline-svgs/cobrand/lendco/lendco-email-icon';
const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return null;
    default:
      return LendcoEmailIcon;
  }
};

const EmailIconCobranded: React.FC<{ className: string }> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return Component ? <Component {...props} /> : null;
};

export default EmailIconCobranded;
