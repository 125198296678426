import { connect } from 'react-redux';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import { routeChange } from '@src/redux-saga-router-plus/actions';

import { getCobrandStyles } from '@client/cobrand-settings/cobrand-styles';
import { View } from '@client/routes/constants';
import MobileBottomNav, {
  MobileBottomNavItemKey,
} from '@client/components/MobileBottomNav';
import { getUnreadAlertsCount } from '@client/store/selectors/alerts.selectors';
import {
  searchRouteChangeToHidePDPModal,
  searchUpdateUrlParams,
} from '@client/store/actions/search.actions';
import {
  getCobrandId,
  getDomainTheme,
  getNavLinkLabels,
} from '@client/store/selectors/cobranding.selectors';
import {
  getIsShowingSearchPageList,
  getIsShowingMobileBottomNav,
} from '@client/store/selectors/search.selectors';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { getIsFourHundredZoom } from '@client/store/selectors/match-media.selectors';

type OwnProps = {
  isAlwaysShown?: boolean;
};

const mapStateToProps = (state, ownProps: OwnProps) => {
  const activeView = getCurrentView(state);
  const isShowingSearchList = getIsShowingSearchPageList(state);
  const cobrandStyles = getCobrandStyles(
    getCobrandId(state),
    getDomainTheme(state)
  );

  return {
    activeView,
    isShown: getIsShowingMobileBottomNav(state) || !!ownProps.isAlwaysShown,
    unreadAlertsCount: getUnreadAlertsCount(state),
    activeItemColor: cobrandStyles.mobileActiveNavItemColor,
    inactiveItemColor: cobrandStyles.mobileInactiveNavItemColor,
    activeItemBorderBottomStyle:
      cobrandStyles.mobileActiveNavItemBottomBorderStyle,
    isSearchPageListOpen: isShowingSearchList,
    isShowingMyHomeMobileBottomNavItem: getIsFeatureEnabled('homeowner')(state),
    isFourHundredPercentZoom: getIsFourHundredZoom(state),
    navLinkLabels: getNavLinkLabels(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleSearchRouteChange: (isShowingSearchPageList: boolean) => {
    dispatch(
      searchUpdateUrlParams({
        isShowingSearchPageList,
      })
    );
  },
  handleRouteChange: (view: View, queryParams?: {}) =>
    dispatch(routeChange({ view: view, query: queryParams })),
  handleHidePDPModalViaRouteChange: () =>
    dispatch(searchRouteChangeToHidePDPModal()),
  handleReportNavButtonClick: (itemId: MobileBottomNavItemKey) =>
    dispatch(reportEvent(`click_bottom_nam_${itemId}`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileBottomNav);
