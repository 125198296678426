import React from 'react';
import { themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import defaultTheme from '@client/css-modules/PropertyCardCTASection.css';
import { PropertyCardLenderCTASectionProps } from '@client/store/types/cobranded-components/property-card-lender-cta-section';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import MLSAttribution from '@client/components/MLSAttribution';
import LOInfoProvider from '@client/hocs/lo-info-provider';
import { LOInfoProviderArgs } from '@client/store/types/loan-officer';
import { getCustomizationData } from '@client/store/selectors/cobranding.selectors';
import { getUserPartnerUserId } from '@client/store/selectors/auth.selectors';
import { addPartnerPropertyAndUserIdToUrl } from '@client/utils/routing.utils';
import PillButton from '@client/components/generic/PillButton';
import PropertyCardValueCobranded from '@client/components/PropertyCardValue/PropertyCardValueCobranded';
import { reportLenderCtaSeeLoanOptionsClick } from '@client/store/actions/analytics.actions';

/**
 * A section containing price information and a lender CTA button
 * This component has specific styling for display within a property card
 */
const PropertyCardCTASection: React.FunctionComponent<
  PropertyCardLenderCTASectionProps
> = ({
  theme,
  value,
  valueLabel,
  isActiveListing,
  isShowingClickHintCTA,
  propertyDetails,
  forceLowerSpacing,
}) => {
  const dispatch = useDispatch();
  const customizationData = useSelector(getCustomizationData);
  const userId = useSelector(getUserPartnerUserId);
  const propertyId = propertyDetails.slug;

  function handleCtaClick(event, url) {
    event.stopPropagation();
    dispatch(reportLenderCtaSeeLoanOptionsClick(propertyDetails.slug));

    const fullLinkStr = addPartnerPropertyAndUserIdToUrl(
      url,
      customizationData,
      propertyId,
      userId
    );
    window.open(fullLinkStr, '_blank');
  }

  return (
    <LOInfoProvider>
      {({ loConfig, loCtaUrl }: LOInfoProviderArgs) => {
        // loConfig is undefined for ComeHome, chase cobrands
        const hbPdp = loConfig?.hbPdp;
        const buttonFill = hbPdp?.buttonColor ?? 'inherit';
        const buttonText = hbPdp?.cta ?? 'See loan options';
        const url = loCtaUrl;

        return (
          <div className={theme.PropertyCardCTASection}>
            <div className={theme.ValueSectionWrapper}>
              <PropertyCardValueCobranded
                value={value}
                valueLabel={valueLabel}
                theme={theme}
                isShowingClickHintCTA={
                  isActiveListing ? isShowingClickHintCTA : false
                }
                monthlyPaymentEstimate={propertyDetails.monthlyPaymentEstimate}
                bottomLabel={''}
              />
            </div>
            {isActiveListing ? (
              <PillButton
                ariaLabel={buttonText}
                className={theme.SeeAvailableLoanOptions}
                onClick={(event) => handleCtaClick(event, url)}
                onKeyDown={(event) =>
                  onEnterOrSpaceKey(() => handleCtaClick(event, url))
                }
                style={{ backgroundColor: buttonFill }}
              >
                {buttonText}
              </PillButton>
            ) : (
              <PillButton
                ariaLabel={buttonText}
                className={
                  forceLowerSpacing
                    ? classNames(
                        theme.ShowDetailsButton,
                        theme.forceLowerSpacing
                      )
                    : theme.ShowDetailsButton
                }
                onClick={(event) => handleCtaClick(event, url)}
                onKeyDown={(event) =>
                  onEnterOrSpaceKey(() => handleCtaClick(event, url))
                }
                tertiary
              >
                {buttonText}
              </PillButton>
            )}
            <div className={theme.MLSAttributionUnderCTASection}>
              <MLSAttribution propertyDetails={propertyDetails} />
            </div>
          </div>
        );
      }}
    </LOInfoProvider>
  );
};

export default themr(
  'PropertyCardCTASectionThemed',
  defaultTheme
)(PropertyCardCTASection);
