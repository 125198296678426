import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ScaffoldingMarketingCard from '@client/components/ScaffoldingUI/ScaffoldingMarketingCard/ScaffoldingMarketingCard';
import { getScaffoldingCMGMarketingCardDataForSRP } from '@client/store/slices/scaffolding-data.slice';
import exportConstants from '@client/css-modules/_exportConstants.css';
import fontsAnimationsStyles from '@client/css-modules/_fontsAnimations.css';

const StyledCardSearchPage = styled.div`
  margin-right: 15px;
  width: calc(${exportConstants.propertyCardWidth} - 15px);
  height: ${exportConstants.propertyCardHeight};
  border-radius: ${fontsAnimationsStyles['rounded-border']};
  border: 1px solid #f0f0f0;
  transition: box-shadow 0.2s, transform 0.2s;

  &:hover {
    box-shadow: ${fontsAnimationsStyles['box-shadow-property-card']};
  }
`;

const ScaffoldingMarketingCardSRP = () => {
  const elementInstruction = useSelector(
    getScaffoldingCMGMarketingCardDataForSRP
  );
  if (!elementInstruction) {
    return <></>;
  }

  return (
    <StyledCardSearchPage>
      <ScaffoldingMarketingCard elementInstruction={elementInstruction} />
    </StyledCardSearchPage>
  );
};

export default ScaffoldingMarketingCardSRP;
