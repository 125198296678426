import React from 'react';

import CaratIcon from '@client/components/CaratIcon';
import CobrandedStyles from '@client/components/CobrandedStyles';
import defaultTheme from '@client/css-modules/BreakoutSections.css';
import ConditionalWrapper from '@client/hocs/conditional-wrapper';
import {
  LockedComponent,
  LockedComponentProps,
} from '@client/hocs/locked-component';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';

type PreviewSectionProps = {
  title?: string;
  onClick?: () => void;
  theme: Theme;
  'data-section-key': string;
  dataEventName: string;
  children?: React.ReactNode;
  lockedComponent?: Omit<LockedComponentProps, 'children'>;
  placeholder?: string;
};

interface PreviewSectionContentProps extends PreviewSectionProps {
  breakoutAndPreviewSectionsTitleFontSize: string;
  breakoutAndPreviewSectionsTitleFontWeight: number;
  disabled?: boolean;
}

const PreviewSectionContent = ({
  title,
  children,
  onClick,
  theme,
  disabled,
  lockedComponent,
  dataEventName,
  placeholder,
  ['data-section-key']: dataSectionKey,
  breakoutAndPreviewSectionsTitleFontSize,
  breakoutAndPreviewSectionsTitleFontWeight,
  ...rest
}: PreviewSectionContentProps) => {
  return (
    <>
      <span className={theme.PreviewSectionColumn}>
        <h2
          className={classNames(theme.PreviewSectionTitle, {
            [theme.disabled]: disabled,
          })}
          data-hc-name={'avm-breakout-item-header'}
          style={{
            fontSize: breakoutAndPreviewSectionsTitleFontSize,
            fontWeight: breakoutAndPreviewSectionsTitleFontWeight,
          }}
        >
          {title}
        </h2>
        <LockedComponent
          className={theme.LockedComponentContainer}
          theme={theme}
          sectionId={lockedComponent?.sectionId}
          lockedFor={lockedComponent?.lockedFor || []}
        >
          {({ isLocked }) => (
            <span
              className={classNames(theme.PreviewSectionBody, {
                [theme.disabled]: isLocked,
              })}
              data-hc-name={'avm-breakout-item-body'}
            >
              {isLocked && placeholder ? placeholder : children}
            </span>
          )}
        </LockedComponent>
      </span>
      <span
        className={classNames(theme.PreviewSectionButton, {
          [theme.disabled]: disabled,
        })}
        data-hc-name={'avm-breakout-item-button'}
      >
        <CaratIcon className={theme.PreviewSectionCaratIcon} />
      </span>
    </>
  );
};

const PreviewSection: React.FC<PreviewSectionProps> = ({
  title,
  children,
  onClick,
  theme,
  lockedComponent,
  placeholder,
  dataEventName,
  ['data-section-key']: dataSectionKey,
  ...rest
}) => {
  return (
    <CobrandedStyles>
      {({
        breakoutAndPreviewSectionsTitleFontSize,
        breakoutAndPreviewSectionsTitleFontWeight,
      }) => (
        <LockedComponent
          className={theme.LockedComponentContainer}
          theme={theme}
          sectionId={lockedComponent?.sectionId}
          lockedFor={lockedComponent?.lockedFor || []}
          showLockedSection={false}
        >
          {({ isLocked }) => (
            <ConditionalWrapper
              condition={isLocked}
              trueConditionWrapper={(children) => (
                <div
                  className={theme.PreviewSection}
                  data-hc-name={'avm-breakout-item'}
                  data-section-key={dataSectionKey}
                  data-event-name={dataEventName}
                  {...rest}
                >
                  {children}
                </div>
              )}
              falseConditionWrapper={(children) => (
                <button
                  className={theme.PreviewSection}
                  data-hc-name={'avm-breakout-item'}
                  type="button"
                  onClick={onClick}
                  onKeyDown={onEnterOrSpaceKey(onClick!)}
                  data-section-key={dataSectionKey}
                  data-event-name={dataEventName}
                  {...rest}
                >
                  {children}
                </button>
              )}
            >
              <PreviewSectionContent
                title={title}
                children={children}
                onClick={isLocked ? undefined : onClick}
                theme={theme}
                disabled={isLocked}
                placeholder={placeholder}
                lockedComponent={lockedComponent}
                dataEventName={dataEventName}
                data-section-key={dataSectionKey}
                breakoutAndPreviewSectionsTitleFontSize={
                  breakoutAndPreviewSectionsTitleFontSize
                }
                breakoutAndPreviewSectionsTitleFontWeight={
                  breakoutAndPreviewSectionsTitleFontWeight
                }
              />
            </ConditionalWrapper>
          )}
        </LockedComponent>
      )}
    </CobrandedStyles>
  );
};

const ThemedPreviewSection = themr(
  'BreakoutSections',
  defaultTheme
)(PreviewSection);

export default ThemedPreviewSection;
