import { useDispatch } from 'react-redux';
import { IdToken } from '@client/store/types/native-app-integration';
import { AfterAuthAction } from '@client/store/types/auth';
import { loginOAuthOIDC } from '@client/store/actions/auth.actions';
import { reportToSentry } from '@client/utils/error.utils';

/**
 * Hooks for interfacing with a native app via a JSBridge. These hooks support the "GA" native app
 * integration and won't work for Chase's integration (found in chase-integration.saga).
 * These hooks are testable via the Native App Integration Test page (/native-app-integration-test)
 */
export function useNativeAppLenderCTAClick() {
  return function (
    navKey: string,
    params: { [key: string]: string | number | null }
  ) {
    if (typeof window.JavascriptBridgeHandler?.goToNativePage !== 'undefined') {
      reportToSentry(
        'GA JSBridge: Requesting native app page navigation',
        { navKey, params },
        'info'
      );
      window.JavascriptBridgeHandler.goToNativePage({ navKey, params });
    } else {
      throw new Error('JavascriptBridgeHandler.goToNativePage is not defined');
    }
  };
}

export const useNativeAppAuthentication = () => {
  const dispatch = useDispatch();

  return function (afterAuthAction?: AfterAuthAction): Promise<void> {
    const transactionId = Math.floor(Math.random() * 100000);

    return new Promise((resolve, reject) => {
      if (typeof window.JavascriptBridgeHandler === 'undefined') {
        throw new Error('JavascriptBridgeHandler is not defined');
      }
      if (
        typeof window.JSBridgeInternal__AuthenticateCallbacks === 'undefined'
      ) {
        window.JSBridgeInternal__AuthenticateCallbacks = {};
      }
      if (
        typeof window.JavascriptBridgeHandler.authenticateCallback ===
        'undefined'
      ) {
        window.JavascriptBridgeHandler.authenticateCallback = (
          transactionId: number,
          idToken: IdToken,
          error?: string
        ) => {
          if (
            window.JSBridgeInternal__AuthenticateCallbacks &&
            window.JSBridgeInternal__AuthenticateCallbacks[transactionId]
          ) {
            window.JSBridgeInternal__AuthenticateCallbacks[transactionId](
              idToken,
              error
            );
          } else {
            throw new Error(
              'JSBridgeInternal__AuthenticateCallbacks is not defined'
            );
          }
        };
      }
      /* A callback that we execute after the native app calls `authenticateCallback()` with the user's
       * OIDC ID token after authentication has taken place on their side. Then on our side we will
       * log the user in with the ID token. */
      window.JSBridgeInternal__AuthenticateCallbacks[transactionId] = (
        idToken: IdToken,
        error?: string
      ) => {
        if (
          typeof window.JSBridgeInternal__AuthenticateCallbacks !== 'undefined'
        ) {
          delete window.JSBridgeInternal__AuthenticateCallbacks[transactionId];
        }
        if (!idToken) {
          reject(
            new Error(
              'JavascriptBridgeHandler.authenticateCallback called without an idToken argument'
            )
          );
        }
        if (error) {
          reject(
            new Error(
              `JavascriptBridgeHandler.authenticateCallback received an error: ${error}`
            )
          );
        }
        reportToSentry(
          'GA JSBridge: Logging user in with idToken',
          { idToken },
          'info'
        );
        dispatch(loginOAuthOIDC(idToken, afterAuthAction));
      };

      if (typeof window.JavascriptBridgeHandler.authenticate !== 'undefined') {
        /* Trigger authentication within the native app. It's then the native app's responsibility
         * to authenticate the user and call the "authenticateCallback()" function with an
         * OIDC ID token for the user */
        reportToSentry(
          'GA JSBridge: Requesting native app authentication',
          { transactionId },
          'info'
        );
        window.JavascriptBridgeHandler.authenticate({
          transactionId,
        });
      } else {
        throw new Error('JavascriptBridgeHandler.authenticate is not defined');
      }
    });
  };
};
