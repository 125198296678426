import { useFetchCensusTractDataEffect } from '@client/hooks/census-tract.hooks';
import { getGrantProgramConfig } from '@client/store/selectors/cobranding.selectors';
import { getIsTabletOrSmallerSize } from '@client/store/selectors/match-media.selectors';
import { getPropertyTractIdFromPropertyDetails } from '@client/store/selectors/property-details.selectors';
import { selectGrantAmountForTractId } from '@client/store/slices/grant-program.slice';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import GrantProgramFullContent from './GrantProgramFullContent';

type StyleProps = { $isNarrowLayout: boolean };

const StyledBanner = styled.div<StyleProps>`
  background-color: #f1f1f1;
  border-radius: 14px;
  ${(props) => `margin: 40px ${props.$isNarrowLayout ? '10px' : '0'};`}
`;

export default function GrantProgramPDPBanner() {
  const grantProgramConfig = useSelector(getGrantProgramConfig);
  const propertyTractId = useSelector(getPropertyTractIdFromPropertyDetails);
  const isNarrowLayout = useSelector(getIsTabletOrSmallerSize);
  const grantAmount = useSelector(selectGrantAmountForTractId(propertyTractId));

  useFetchCensusTractDataEffect();

  if (!grantAmount) {
    return null;
  }
  return (
    <StyledBanner $isNarrowLayout={isNarrowLayout}>
      <GrantProgramFullContent
        dataEventName="click_grant_pdp_cta"
        dataEventParentName="lender_cta"
        isPdpPage={true}
        isNarrowLayout={isNarrowLayout}
        grantAmount={grantAmount}
        grantProgramConfigForView={
          grantProgramConfig?.gp_property_details_banner
        }
      />
    </StyledBanner>
  );
}
