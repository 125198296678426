import React, { Component } from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import { SEARCH_LIST_SORT_OPTIONS } from '@client/store/constants';
import { motion } from 'framer-motion';
import { key, stopEvent } from '@client/utils/component.utils';
import defaultTheme from '@client/css-modules/DropdownSort.css';
import CobrandedStyles from '@client/components/CobrandedStyles';
import {
  SearchListSortField,
  SearchListSortOrder,
} from '@client/store/types/search';

const MENU_ID = 'sortOptionsMenu';

type Props = {
  sortField: SearchListSortField | null;
  sortOrder: SearchListSortOrder | null;
  onChangeSort: (
    field: SearchListSortField,
    order: SearchListSortOrder
  ) => void;
  onOpenDropdown?: () => void;
  isDisabled: boolean;
  Button: React.ReactElement;
  theme: Theme;
  dataHcName?: string;
  dropdownLabelId?: string;
};

type State = {
  isShowingDropdown: boolean;
  selectedDropdownLiId: string | undefined;
  focusedLiId: string | undefined;
};

/**
 * An accessibility friendly dropdown with hardcoded sort options and specific styling and animations
 * TODO should most likely be made to be more reusable by accepting options as an argument and replace Dropdown.tsx
 */
class DropdownSort extends Component<Props, State> {
  sortButtonRef: React.RefObject<HTMLDivElement> = React.createRef();
  containerEle: HTMLDivElement | null = null;
  /* Cache of child <li> refs used to set and change focus for a11y */
  childRefs: HTMLLIElement[] = [];

  setLiRefsAndFocus = (el: HTMLLIElement) => {
    this.childRefs.push(el);

    /* After all the refs have been collected (after the list is opened), focus the first */
    if (this.childRefs.length === SEARCH_LIST_SORT_OPTIONS.length) {
      this.childRefs[0].focus();
    }
  };

  state = {
    isShowingDropdown: false,
    selectedDropdownLiId: undefined,
    focusedLiId: undefined,
  };

  componentDidUpdate(prevProps, prevState) {
    const { isShowingDropdown } = this.state;

    if (
      isShowingDropdown &&
      !prevState.isShowingDropdown &&
      this.containerEle
    ) {
      const liOptions: HTMLLIElement[] = [
        ...this.containerEle.querySelectorAll('li'),
      ];
      const currentSelected: HTMLLIElement | null =
        this.containerEle.querySelector('li[aria-selected="true"]');
      const selectedIndex: number = currentSelected
        ? liOptions.indexOf(currentSelected)
        : 0;
      /**
       * If there is selected li, set focus on that li when the dropdown is displayed,
       * otherwise, set focus on the first li
       */
      if (currentSelected) {
        currentSelected.focus();
        this.setState({
          focusedLiId: this.generateLiId(selectedIndex),
        });
      } else {
        const firstLi: HTMLElement | null =
          this.containerEle.querySelector('li');
        firstLi && firstLi.focus();
        this.setState({
          focusedLiId: this.generateLiId(selectedIndex),
        });
      }
    }
  }

  handleOnOptionKeyDown = (
    field: SearchListSortField,
    order: SearchListSortOrder,
    selectedDropdownLiId: string
  ): void => {
    const { onChangeSort } = this.props;
    onChangeSort(field, order);
    this.setState({ selectedDropdownLiId: selectedDropdownLiId });
  };

  toggleShowOptions = (): void => {
    if (!this.props.isDisabled) {
      if (!this.state.isShowingDropdown && this.props.onOpenDropdown) {
        this.props.onOpenDropdown();
      }
      this.setState({ isShowingDropdown: !this.state.isShowingDropdown });
      /* Reset childRefs when opening the list so that they may be collected again and the first focused */
      if (!this.state.isShowingDropdown) {
        this.childRefs = [];
      }
    }
  };

  handleOnKeyDownToggleShowOptions = (
    e: React.KeyboardEvent<HTMLElement>
  ): void => {
    const keyPress = e.key;
    if (keyPress) {
      if (key.isSpace(keyPress) || key.isReturn(keyPress)) {
        this.toggleShowOptions();
      }
    }
  };

  closeSortOptionsList = (): void => {
    this.setState({ isShowingDropdown: false });
    // Set focus on trigger button after the options list closes.
    if (this.sortButtonRef.current) {
      this.sortButtonRef.current.focus();
    }
  };

  handleChangeSort = (field, order): void => {
    const { onChangeSort } = this.props;

    this.closeSortOptionsList();
    onChangeSort(field, order);
  };

  recordDropdownContainerRef = (ele: HTMLDivElement | null) => {
    if (ele) {
      this.containerEle = ele;
    }
  };

  handleKeyDown = (
    e: React.KeyboardEvent<HTMLElement>,
    field: SearchListSortField,
    order: SearchListSortOrder,
    index: number
  ) => {
    const keyPress = e.key;
    const currentId = this.generateLiId(index);
    if (keyPress) {
      if (key.isSpace(keyPress) || key.isReturn(keyPress)) {
        stopEvent(e);
        this.handleOnOptionKeyDown(field, order, currentId);
        this.closeSortOptionsList();
      } else if (key.isEscape(keyPress)) {
        stopEvent(e);
        this.closeSortOptionsList();
      } else if (key.isArrowUp(keyPress)) {
        stopEvent(e);
        if (index !== 0) {
          this.childRefs[index - 1].focus();
          this.setState({
            focusedLiId: this.generateLiId(index - 1),
          });
        }
      } else if (key.isArrowDown(keyPress)) {
        stopEvent(e);
        if (index !== this.childRefs.length - 1) {
          this.childRefs[index + 1].focus();
          this.setState({
            focusedLiId: this.generateLiId(index + 1),
          });
        }
      } else if (keyPress === 'Tab') {
        /**
         * Per aria rules, if a user tabs it should take them out of the listbox and on to the next DOM tab point.
         */
        this.setState({
          isShowingDropdown: false,
        });
      }
    }
  };

  handleMouseEnter = (index: number, id: string) => {
    this.childRefs[index].focus();
    this.setState({
      focusedLiId: id,
    });
  };

  generateLiId = (index) => `dropdown-list-${index}`;

  render() {
    const {
      sortField,
      sortOrder,
      Button,
      theme,
      isDisabled,
      dataHcName,
      dropdownLabelId,
    } = this.props;

    const { isShowingDropdown, focusedLiId } = this.state;

    return (
      <div
        data-hc-name={dataHcName}
        className={theme.DropdownSort}
        ref={this.recordDropdownContainerRef}
      >
        {React.cloneElement(Button, {
          'aria-haspopup': 'listbox',
          'aria-expanded': isShowingDropdown,
          'aria-controls': MENU_ID,
          disabled: isDisabled,
          ref: this.sortButtonRef,
          onKeyDown: (e) => this.handleOnKeyDownToggleShowOptions(e),
          onClick: () => this.toggleShowOptions(),
        })}
        <CobrandedStyles>
          {({ defaultTextColor, selectedSortOptionColor }) => (
            <div id={MENU_ID}>
              {isShowingDropdown ? (
                <motion.div
                  initial={{ y: '-30px', opacity: 0 }}
                  animate={{
                    opacity: 1,
                    y: '0',
                    transition: {
                      ease: 'easeInOut',
                      duration: isShowingDropdown ? 0.1 : 0.2,
                    },
                  }}
                  key="menu"
                  className={theme.DropdownSortOptionsListWrapper}
                  style={{ color: defaultTextColor }}
                >
                  <motion.ul
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    role="listbox"
                    data-hc-name="sort-options"
                    aria-labelledby={dropdownLabelId}
                    aria-activedescendant={focusedLiId}
                    className={theme.DropdownSortOptionsList}
                  >
                    {SEARCH_LIST_SORT_OPTIONS.map(
                      ({ field, label, order }, index) => {
                        const DROPDOWN_LIST_ID = this.generateLiId(index);
                        const isSelected =
                          field === sortField && order === sortOrder;
                        return (
                          <React.Fragment key={index}>
                            <motion.li
                              id={DROPDOWN_LIST_ID}
                              ref={this.setLiRefsAndFocus}
                              initial={{ x: '-50%', opacity: 0 }}
                              animate={{
                                x: '0%',
                                opacity: 1,
                                transition: {
                                  damping: 0,
                                  delay: 0.03 * index,
                                },
                              }}
                              role="option"
                              /* Remove tab index on List Items so that the tab takes user out of list */
                              /* Items are still accessible via up/down keystrokes */
                              tabIndex={-1}
                              key={label}
                              aria-selected={isSelected ? true : undefined}
                              className={theme.DropdownSortOptionListItem}
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, field, order, index)
                              }
                              onMouseEnter={() =>
                                this.handleMouseEnter(index, DROPDOWN_LIST_ID)
                              }
                              onClick={() => {
                                this.handleChangeSort(field, order);
                              }}
                              {...(isSelected
                                ? {
                                    style: {
                                      color: selectedSortOptionColor,
                                      fontWeight: 'bold',
                                    },
                                  }
                                : {})}
                            >
                              {label}
                            </motion.li>
                          </React.Fragment>
                        );
                      }
                    )}
                  </motion.ul>
                </motion.div>
              ) : null}
            </div>
          )}
        </CobrandedStyles>
        {
          // To close the dropdown when you click outside it.
          isShowingDropdown && (
            <div
              className={theme.DropdownSortOptionsScreen}
              onClick={this.toggleShowOptions}
            />
          )
        }
      </div>
    );
  }
}

export default themr('DropdownSortThemed', defaultTheme)(DropdownSort);
