import { connect } from 'react-redux';
import ValueComparison from '@client/components/ValueComparison';
import {
  getHomeownerHpiForOneYearAgo,
  getHomeownerHpiForThisMonth,
  getHomeownerHpiForNextYear,
} from '@client/store/selectors/homeowner.selectors';

const mapStateToProps = (state) => {
  return {
    hpiLastYear: getHomeownerHpiForOneYearAgo(state),
    hpiNow: getHomeownerHpiForThisMonth(state),
    hpiNextYear: getHomeownerHpiForNextYear(state),
  };
};

export default connect(mapStateToProps)(ValueComparison);
