import { get, merge, omit } from 'lodash';
import { parseHomeownerResponseArrayToObject } from '@client/utils/homeowner.utils';
import {
  FETCH_HOMEOWNER_USER_DATA,
  FETCH_HOMEOWNER_PROPERTY_PHOTOS_SUCCESS,
  FETCH_HOMEOWNER_USER_DATA_SUCCESS,
  FETCH_CLAIMED_HOMES,
  FETCH_CLAIMED_HOMES_SUCCESS,
  FETCH_CLAIMED_HOMES_ERROR,
  CHANGE_CLAIMED_HOME_FORM_VALUE,
  UPDATE_CLAIMED_HOME_SUCCESS,
  UPDATE_CLAIMED_HOME_ERROR,
  UPDATE_LOAN_TERMS,
  UPDATE_LOAN_TERMS_ERROR,
  FETCH_HOMEOWNER_HOME_IMPROVEMENTS,
  FETCH_HOMEOWNER_HOME_IMPROVEMENTS_SUCCESS,
  FETCH_HOME_ADVISOR_ESTIMATED_COST,
  FETCH_HOME_ADVISOR_ESTIMATED_COST_SUCCESS,
  FETCH_PROPERTY_VALUE_FORECAST,
  FETCH_PROPERTY_VALUE_FORECAST_SUCCESS,
  UPDATE_CLAIMED_HOME_DETAILS,
  SET_EDIT_FORM_TO_INIT,
  CLAIM_PROPERTY_SUCCESS,
  UPDATE_HOMEOWNER_PROPERTY_DATA,
  FETCH_HOMEOWNER_MANAGE_HOMES_DATA_ERROR,
  DELETE_PROPERTY_FROM_CLAIMED_HOMES_STATE,
  RESET_CLAIMED_HOME_STATUS,
  RESET_HOMEOWNER_MANAGE_HOMES_STATE,
  SET_AVM_CHART_TIME_RANGE,
  SET_AVM_AT_TARGET_TIME_ON_CHART,
  SET_DATE_AT_TARGET_TIME_ON_CHART,
  ADD_SELECTED_HOME_PROJECT,
  DELETE_SELECTED_HOME_PROJECT,
  FETCH_HOME_INFO_FOR_PROFINDER_SUCCESS,
  SUBMIT_PROFINDER_REFERRAL_REQUEST,
  SUBMIT_PROFINDER_REFERRAL_REQUEST_SUCCESS,
  REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE,
  REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE_SUCCESS,
  FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS,
  FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS_SUCCESS,
  FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS_ERROR,
  SET_HOMEADVISOR_REVIEWS_STATUS_TO_SUCCESS,
  UPDATE_PROFINDER_COST_ESTIMATES,
  SET_PROFINDER_STATUS_TO_SUCCESS,
  REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE_ERROR,
  SUBMIT_PROFINDER_REFERRAL_REQUEST_ERROR,
  FETCH_HOMEOWNER_MANAGE_SINGLE_HOME_SUCCESS,
  SET_HOMEOWNER_MANAGE_HOME_STATUS_TO_LOADING,
  RESET_HOMEOWNER_PROP_DETAILS,
  FETCH_HOMEOWNER_PROP_DETAILS_SUCCESS,
  FETCH_HOMEOWNER_PROP_DETAILS_ERROR,
} from '@client/store/actions/homeowner.actions';
import { LOGOUT_SUCCESS } from '@client/store/actions/auth.actions';
import { STATUSES } from '@client/store/constants';
import {
  CLAIMED_HOME_KEYS,
  AVM_CHART_TIME_RANGES,
  HOME_ADVISOR_COST_GUIDE,
  HOME_IMPROVEMENTS_SCENARIOS_KEY,
  PROFINDER_TYPE_IDS,
} from '@client/store/homeowner-constants';
import { removeNullKeys } from '@client/utils/object.utils';
import {
  HomeownerPropertyDataCacheItem,
  HomeownerState,
  ProFinderMinMaxProps,
} from '@client/store/types/homeowner';
import { Action } from '@client/store/actions';

const claimedHomeFormOptionState = {
  overridden: false,
  value: null,
};

export const INITIAL_CLAIMED_HOME_FORM_STATE = {
  [CLAIMED_HOME_KEYS.SALE_DATE]: claimedHomeFormOptionState,
  [CLAIMED_HOME_KEYS.SALE_PRICE]: claimedHomeFormOptionState,
  [CLAIMED_HOME_KEYS.RATE]: claimedHomeFormOptionState,
  [CLAIMED_HOME_KEYS.TERMS]: claimedHomeFormOptionState,
  [CLAIMED_HOME_KEYS.PRINCIPAL]: claimedHomeFormOptionState,
  [CLAIMED_HOME_KEYS.LIVING_AREA]: claimedHomeFormOptionState,
  [CLAIMED_HOME_KEYS.LOT_SIZE]: claimedHomeFormOptionState,
  [CLAIMED_HOME_KEYS.ROOM_COUNT]: claimedHomeFormOptionState,
  [CLAIMED_HOME_KEYS.BEDS]: claimedHomeFormOptionState,
  [CLAIMED_HOME_KEYS.BATHS_FULL]: claimedHomeFormOptionState,
  [CLAIMED_HOME_KEYS.BATHS_PARTIAL]: claimedHomeFormOptionState,
  [CLAIMED_HOME_KEYS.BASEMENT]: claimedHomeFormOptionState,
  [CLAIMED_HOME_KEYS.POOL]: claimedHomeFormOptionState,
  [CLAIMED_HOME_KEYS.GARAGE_TYPE]: claimedHomeFormOptionState,
  [CLAIMED_HOME_KEYS.CONDITION]: claimedHomeFormOptionState,
  [CLAIMED_HOME_KEYS.OPTED_IN_TO_SELL]: false,
  afterMortgageUpdateAction: undefined,
};

/* State variables unique to each PDP */
export const HOMEOWNER_INITIAL_PROPERTY_DATA_CACHE_ITEM: HomeownerPropertyDataCacheItem =
  {
    initHomeownerPageStatus: STATUSES.INIT,
    fullHomeownerPageStatus: STATUSES.INIT,
    initHomeImprovementsStatus: STATUSES.INIT,
    initPropertyValueForecastStatus: STATUSES.INIT,
    initMortgageScheduleStatus: STATUSES.INIT,
    propertyCardDetailsStatus: STATUSES.INIT,
    updateClaimHomeStatus: STATUSES.INIT,
    avmStatus: STATUSES.INIT,
    avmDeepDiveStatus: STATUSES.INIT,
    photosStatus: STATUSES.INIT,
    /* Raw data returned from property-graph */
    homeImprovements: {},
    data: null,
    homeAdvisorEstimatedCost: {
      min: null,
      avg: null,
      max: null,
    },
    initHomeAdvisorEstimatedCostStatus: STATUSES.INIT,
  };

const getProFinderKeyFromCalcKey = (calcKey: string): string => {
  const HA_TO_PROFINDER_MAPPING = {
    [HOME_IMPROVEMENTS_SCENARIOS_KEY.BEDS]: PROFINDER_TYPE_IDS.BEDROOM,
    [HOME_IMPROVEMENTS_SCENARIOS_KEY.BATH]: PROFINDER_TYPE_IDS.BATHROOM,
    [HOME_IMPROVEMENTS_SCENARIOS_KEY.SQUARE_FOOTAGE]:
      PROFINDER_TYPE_IDS.ADDITION,
    [HOME_IMPROVEMENTS_SCENARIOS_KEY.POOL]: PROFINDER_TYPE_IDS.POOL,
  };
  return HA_TO_PROFINDER_MAPPING[calcKey];
};

const getProFinderAvgMaxAndMinCostEstimates = (
  key: string
): ProFinderMinMaxProps => {
  const COST_ESTIMATES_MAPPING = {
    [PROFINDER_TYPE_IDS.BEDROOM]:
      HOME_ADVISOR_COST_GUIDE[HOME_IMPROVEMENTS_SCENARIOS_KEY.BEDS],
    [PROFINDER_TYPE_IDS.BATHROOM]:
      HOME_ADVISOR_COST_GUIDE[HOME_IMPROVEMENTS_SCENARIOS_KEY.BATH],
    [PROFINDER_TYPE_IDS.ADDITION]:
      HOME_ADVISOR_COST_GUIDE[HOME_IMPROVEMENTS_SCENARIOS_KEY.SQUARE_FOOTAGE],
    [PROFINDER_TYPE_IDS.POOL]: null,
  };
  const estimateObj = COST_ESTIMATES_MAPPING[key];
  return {
    value: null,
    min: estimateObj
      ? estimateObj.addedSqFtCostGuide?.[0]?.minEstimatedCost
      : null,
    max: estimateObj
      ? estimateObj.addedSqFtCostGuide?.[
          estimateObj.addedSqFtCostGuide.length - 1
        ]?.maxEstimatedCost
      : null,
  };
};

const isFinalClaimedHomeToLoadDataFor = (state: HomeownerState) => {
  const homesArr = Object.keys(state.claimedHomes);
  const homesWithData = homesArr.filter(
    (home) => !!state.claimedHomes?.[home]?.data
  );
  /* Needs +1 as when this is run it is currently adding that home to state, and it has not been added yet */
  return homesWithData.length + 1 >= homesArr.length;
};

/**
 * Returns an existing `homeownerPropertyDataCache` item if it exists or returns the "init" `homeownerPropertyDataCache`
 * item state if not
 */
const getHomeownerPropertyCacheItemOrInitForSlug = (
  slug: string,
  state: HomeownerState
): HomeownerPropertyDataCacheItem => {
  const item = state.homeownerPropertyDataCache[slug];
  return item || HOMEOWNER_INITIAL_PROPERTY_DATA_CACHE_ITEM;
};

export const HOMEOWNER_INITIAL_STATE: HomeownerState = {
  homeownerUserDataStatus: STATUSES.INIT,
  homeownerPropertyDataCache: {},
  homeImprovementCalculator: {
    selectedHomeProjects: {},
  },
  claimedHomesStatus: STATUSES.INIT,
  initManageHomesDataStatus: STATUSES.INIT,
  claimedHomes: {},
  editClaimedHomeForm: INITIAL_CLAIMED_HOME_FORM_STATE,
  loanTermOptions: [],
  avmChart: {
    timeRange: AVM_CHART_TIME_RANGES.TEN_YEAR,
    dateAtTargetTimeOnChart: null,
    avmAtTargetTimeOnChart: null,
  },
  proFinder: {
    proFinderStatus: STATUSES.INIT,
    proFinderReferralStatus: STATUSES.INIT,
    proFinderReviewsModalStatus: STATUSES.INIT,
    costEstimates: {
      [PROFINDER_TYPE_IDS.POOL]: {
        value: null,
        max: null,
        min: null,
      },
      /* set to { avg, max, min } based on logic in controller */
      [PROFINDER_TYPE_IDS.BEDROOM]: getProFinderAvgMaxAndMinCostEstimates(
        PROFINDER_TYPE_IDS.BEDROOM
      ),
      [PROFINDER_TYPE_IDS.BATHROOM]: getProFinderAvgMaxAndMinCostEstimates(
        PROFINDER_TYPE_IDS.BATHROOM
      ),
      [PROFINDER_TYPE_IDS.ADDITION]: getProFinderAvgMaxAndMinCostEstimates(
        PROFINDER_TYPE_IDS.ADDITION
      ),
    },
    referrals: {
      projectId: null,
      businesses: [
        /* STRUCTURE FROM API RESULTS:
         * {
         *   business_id
         *   name
         *   rating
         *   reviews
         *   quoteContact
         *   quoteStatus
         * }
         */
      ],
    },
    quoteContact: null,
    activeReviewId: null,
    reviews: {
      /* STRUCTURE UPON GETTING PROFINDER API RESULTS:
       * [businessId]: [
       *   {
       *     author,
       *     body,
       *     date,
       *     rating
       *   }
       * ]
       */
    },
  },
  updateClaimHomeStatus: STATUSES.INIT,
};

export default function homeownerReducer(
  state = HOMEOWNER_INITIAL_STATE,
  action: Action
): HomeownerState {
  switch (action.type) {
    case FETCH_HOMEOWNER_PROPERTY_PHOTOS_SUCCESS:
      return {
        ...state,
        homeownerPropertyDataCache: {
          ...state.homeownerPropertyDataCache,
          [action.payload.slug]: {
            ...getHomeownerPropertyCacheItemOrInitForSlug(
              action.payload.slug,
              state
            ),
            data: {
              ...getHomeownerPropertyCacheItemOrInitForSlug(
                action.payload.slug,
                state
              ).data,
              photos: action.payload.photos,
            },
          },
        },
      };
    case RESET_HOMEOWNER_PROP_DETAILS:
      return {
        ...state,
        avmChart: HOMEOWNER_INITIAL_STATE.avmChart,
        editClaimedHomeForm: INITIAL_CLAIMED_HOME_FORM_STATE,
        homeownerPropertyDataCache: {
          ...state.homeownerPropertyDataCache,
          [action.payload.slug]: {
            ...HOMEOWNER_INITIAL_PROPERTY_DATA_CACHE_ITEM,
            initHomeownerPageStatus: STATUSES.LOADING,
          },
        },
      };
    case FETCH_HOMEOWNER_PROP_DETAILS_SUCCESS:
      const __currentCacheItem1 = getHomeownerPropertyCacheItemOrInitForSlug(
        action.payload.slug,
        state
      );
      return {
        ...state,
        homeownerPropertyDataCache: {
          ...state.homeownerPropertyDataCache,
          [action.payload.slug]: {
            ...__currentCacheItem1,
            avmStatus: STATUSES.SUCCESS,
            propertyCardDetailsStatus: STATUSES.SUCCESS,
            photosStatus: STATUSES.SUCCESS,
            initHomeownerPageStatus: STATUSES.SUCCESS,
            data: merge(
              {},
              __currentCacheItem1.data,
              removeNullKeys(action.payload.data)
            ),
          },
        },
      };
    case FETCH_HOMEOWNER_PROP_DETAILS_ERROR:
      const __currentCacheItem2 = getHomeownerPropertyCacheItemOrInitForSlug(
        action.payload.slug,
        state
      );
      return {
        ...state,
        homeownerPropertyDataCache: {
          ...state.homeownerPropertyDataCache,
          [action.payload.slug]: {
            ...__currentCacheItem2,
            initHomeownerPageStatus: STATUSES.ERROR,
          },
        },
      };
    case FETCH_HOMEOWNER_USER_DATA:
      return {
        ...state,
        homeownerUserDataStatus: STATUSES.LOADING,
      };
    case FETCH_HOMEOWNER_USER_DATA_SUCCESS:
      return {
        ...state,
        homeownerUserDataStatus: STATUSES.SUCCESS,
      };
    case FETCH_CLAIMED_HOMES:
      return {
        ...state,
        claimedHomesStatus: STATUSES.LOADING,
      };
    case FETCH_CLAIMED_HOMES_SUCCESS:
      return {
        ...state,
        claimedHomesStatus: STATUSES.SUCCESS,
        claimedHomes: {
          ...state.claimedHomes,
          ...parseHomeownerResponseArrayToObject(
            action.payload.claimedHomes,
            state.claimedHomes
          ),
        },
      };
    case FETCH_CLAIMED_HOMES_ERROR:
      return {
        ...state,
        claimedHomesStatus: STATUSES.ERROR,
        claimedHomes: {},
      };
    case UPDATE_CLAIMED_HOME_DETAILS:
      return {
        ...state,
        updateClaimHomeStatus: STATUSES.LOADING,
      };
    case UPDATE_CLAIMED_HOME_SUCCESS:
      return {
        ...state,
        updateClaimHomeStatus: STATUSES.SUCCESS,
        claimedHomes: {
          ...state.claimedHomes,
          [action.payload?.homeData?.slug]: {
            ...state.claimedHomes[
              action.payload.homeData.slug
            ] /* Allows us to keep the equity data which is not included in the homeowner response */,
            ...action.payload.homeData,
          },
        },
        editClaimedHomeForm: {
          ...INITIAL_CLAIMED_HOME_FORM_STATE,
          afterMortgageUpdateAction:
            state.editClaimedHomeForm.afterMortgageUpdateAction,
        },
      };
    case RESET_CLAIMED_HOME_STATUS:
      return {
        ...state,
        updateClaimHomeStatus: STATUSES.SUCCESS,
        editClaimedHomeForm: {
          ...INITIAL_CLAIMED_HOME_FORM_STATE,
          afterMortgageUpdateAction:
            state.editClaimedHomeForm.afterMortgageUpdateAction,
        },
      };
    case UPDATE_CLAIMED_HOME_ERROR:
      return {
        ...state,
        updateClaimHomeStatus: STATUSES.ERROR,
      };
    case CHANGE_CLAIMED_HOME_FORM_VALUE:
      const key = action.payload.key;
      // this `if` statement is to account for if the user selects a form but then
      // leaves it with the same value. Having the conditional allows us to keep the
      // previous `overridden` value, so if they've updated it but then touch the
      // field, or left an unedited field value, we will know.
      if (state.editClaimedHomeForm[key].value !== action.payload.value) {
        return {
          ...state,
          editClaimedHomeForm: {
            ...state.editClaimedHomeForm,
            [key]: {
              overridden: true,
              /* set the default to be null to prevent the bad request from sending the empty string value.
              "value": "" could occur when adding input value, saving it, and then deleting the value  */
              value: action.payload.value || null,
            },
          },
        };
      } else {
        return state;
      }
    case UPDATE_LOAN_TERMS:
      return {
        ...state,
        loanTermOptions: action.payload.loanTerms,
      };
    case UPDATE_LOAN_TERMS_ERROR:
      return {
        ...state,
        loanTermOptions: [],
      };
    case FETCH_HOMEOWNER_HOME_IMPROVEMENTS:
      return {
        ...state,
        homeownerPropertyDataCache: {
          ...state.homeownerPropertyDataCache,
          [action.payload.slug]: {
            ...getHomeownerPropertyCacheItemOrInitForSlug(
              action.payload.slug,
              state
            ),
            initHomeImprovementsStatus: STATUSES.LOADING,
          },
        },
      };
    case FETCH_HOMEOWNER_HOME_IMPROVEMENTS_SUCCESS:
      return {
        ...state,
        homeownerPropertyDataCache: {
          ...state.homeownerPropertyDataCache,
          [action.payload.slug]: {
            ...getHomeownerPropertyCacheItemOrInitForSlug(
              action.payload.slug,
              state
            ),
            initHomeImprovementsStatus: STATUSES.SUCCESS,
            homeImprovements: {
              ...action.payload.data.improvements,
              condition: action.payload.data.condition,
            },
          },
        },
      };
    case FETCH_HOME_ADVISOR_ESTIMATED_COST:
      return {
        ...state,
        homeownerPropertyDataCache: {
          ...state.homeownerPropertyDataCache,
          [action.payload.slug]: {
            ...getHomeownerPropertyCacheItemOrInitForSlug(
              action.payload.slug,
              state
            ),
            initHomeAdvisorEstimatedCostStatus: STATUSES.LOADING,
          },
        },
      };
    case FETCH_HOME_ADVISOR_ESTIMATED_COST_SUCCESS:
      return {
        ...state,
        homeownerPropertyDataCache: {
          ...state.homeownerPropertyDataCache,
          [action.payload.slug]: {
            ...getHomeownerPropertyCacheItemOrInitForSlug(
              action.payload.slug,
              state
            ),
            initHomeAdvisorEstimatedCostStatus: STATUSES.SUCCESS,
            homeAdvisorEstimatedCost: {
              ...action.payload.data,
            },
          },
        },
      };
    case FETCH_PROPERTY_VALUE_FORECAST:
      return {
        ...state,
        homeownerPropertyDataCache: {
          ...state.homeownerPropertyDataCache,
          [action.payload.slug]: {
            ...getHomeownerPropertyCacheItemOrInitForSlug(
              action.payload.slug,
              state
            ),
            initPropertyValueForecastStatus: STATUSES.LOADING,
          },
        },
      };
    case FETCH_PROPERTY_VALUE_FORECAST_SUCCESS:
      const __currentCacheItem3 = getHomeownerPropertyCacheItemOrInitForSlug(
        action.payload.slug,
        state
      );
      return {
        ...state,
        homeownerPropertyDataCache: {
          ...state.homeownerPropertyDataCache,
          [action.payload.slug]: {
            ...__currentCacheItem3,
            initPropertyValueForecastStatus: STATUSES.SUCCESS,
            data: {
              ...__currentCacheItem3.data,
              ...action.payload.propertyValueForecastData,
            },
          },
        },
      };
    case SET_EDIT_FORM_TO_INIT:
      return {
        ...state,
        updateClaimHomeStatus: STATUSES.INIT,
      };
    case UPDATE_HOMEOWNER_PROPERTY_DATA:
    case CLAIM_PROPERTY_SUCCESS:
      const data = action.payload.propertyData;
      const slug = data.slug;
      return {
        ...state,
        claimedHomes: {
          ...state.claimedHomes,
          [slug]: {
            ...state.claimedHomes[slug],
            ...data,
          },
        },
      };
    case FETCH_HOMEOWNER_MANAGE_SINGLE_HOME_SUCCESS:
      return {
        ...state,
        initManageHomesDataStatus: isFinalClaimedHomeToLoadDataFor(state)
          ? STATUSES.SUCCESS
          : STATUSES.LOADING,
        claimedHomes: {
          ...state.claimedHomes,
          ...(action.payload.data?.propertyLookup?.address?.slug
            ? {
                [action.payload.data?.propertyLookup?.address?.slug]: {
                  ...state.claimedHomes[
                    action.payload.data.propertyLookup.address.slug
                  ],
                  data: action.payload.data.propertyLookup,
                },
              }
            : {}),
        },
      };
    case SET_HOMEOWNER_MANAGE_HOME_STATUS_TO_LOADING:
      return {
        ...state,
        initManageHomesDataStatus: STATUSES.LOADING,
      };
    case FETCH_HOMEOWNER_MANAGE_HOMES_DATA_ERROR:
      return {
        ...state,
        initManageHomesDataStatus: STATUSES.FAILED,
      };
    case DELETE_PROPERTY_FROM_CLAIMED_HOMES_STATE:
      return {
        ...state,
        homeownerPropertyDataCache: omit(
          { ...state.homeownerPropertyDataCache },
          action.payload.slug
        ),
        claimedHomes: omit({ ...state.claimedHomes }, action.payload.slug),
      };
    case RESET_HOMEOWNER_MANAGE_HOMES_STATE:
      return {
        ...state,
        initManageHomesDataStatus: STATUSES.INIT,
      };
    case SET_AVM_CHART_TIME_RANGE:
      return {
        ...state,
        avmChart: {
          ...state.avmChart,
          timeRange: get(
            action,
            ['timeRange', 'time'],
            AVM_CHART_TIME_RANGES.TEN_YEAR
          ),
        },
      };
    /* This is set when a user hovers on the AVM chart */
    case SET_AVM_AT_TARGET_TIME_ON_CHART:
      return {
        ...state,
        avmChart: {
          ...state.avmChart,
          avmAtTargetTimeOnChart: action.payload.value,
        },
      };
    case SET_DATE_AT_TARGET_TIME_ON_CHART:
      return {
        ...state,
        avmChart: {
          ...state.avmChart,
          dateAtTargetTimeOnChart: action.payload.date,
        },
      };
    /* FOR HOME IMPROVEMENT PAGE */
    case ADD_SELECTED_HOME_PROJECT:
      return {
        ...state,
        homeImprovementCalculator: {
          ...state.homeImprovementCalculator,
          selectedHomeProjects: {
            ...state.homeImprovementCalculator.selectedHomeProjects,
            ...(action.payload.slug && {
              [action.payload.slug]: {
                ...state.homeImprovementCalculator.selectedHomeProjects[
                  action.payload.slug
                ],
                [get(action, ['payload', 'selectedItemName'])]: get(action, [
                  'payload',
                  'value',
                ]),
              },
            }),
          },
        },
        proFinder: {
          ...state.proFinder,
          costEstimates: {
            ...state.proFinder.costEstimates,
            [getProFinderKeyFromCalcKey(action.payload.selectedItemName)]: {
              ...state.proFinder.costEstimates[
                getProFinderKeyFromCalcKey(action.payload.selectedItemName)
              ],
              value: action.payload.value,
            },
          },
        },
      };
    case DELETE_SELECTED_HOME_PROJECT:
      return {
        ...state,
        homeImprovementCalculator: {
          ...state.homeImprovementCalculator,
          selectedHomeProjects: {
            ...state.homeImprovementCalculator.selectedHomeProjects,
            [action.payload.slug]: omit(
              {
                ...state.homeImprovementCalculator.selectedHomeProjects[
                  action.payload.slug
                ],
              },
              [action.payload.selectedItemName]
            ),
          },
        },
        proFinder: {
          ...state.proFinder,
          costEstimates: {
            ...state.proFinder.costEstimates,
            [getProFinderKeyFromCalcKey(action.payload.selectedItemName)]: {
              ...state.proFinder.costEstimates[
                getProFinderKeyFromCalcKey(action.payload.selectedItemName)
              ],
              value: null,
            },
          },
        },
      };
    case UPDATE_PROFINDER_COST_ESTIMATES:
      return {
        ...state,
        proFinder: {
          ...state.proFinder,
          costEstimates: {
            ...state.proFinder.costEstimates,
            [getProFinderKeyFromCalcKey(action.payload.id)]: {
              ...state.proFinder.costEstimates[
                getProFinderKeyFromCalcKey(action.payload.id)
              ],
              min: action.payload.min,
              max: action.payload.max,
            },
          },
        },
      };
    /* TODO the profinder data loading flow should be refactored to dispatch the same
     * `getHomeownerPropertyIfNecessary` action on page load that the other homeowner pages use,
     * as that would cause the `address` and many other properties to be loaded into state */
    case FETCH_HOME_INFO_FOR_PROFINDER_SUCCESS:
      return {
        ...state,
        homeownerPropertyDataCache: {
          ...state.homeownerPropertyDataCache,
          [action.payload.slug]: {
            ...getHomeownerPropertyCacheItemOrInitForSlug(
              action.payload.slug,
              state
            ),
            data: {
              ...getHomeownerPropertyCacheItemOrInitForSlug(
                action.payload.slug,
                state
              ).data,
              address: action.payload.data.propertyLookup.address,
            },
          },
        },
        proFinder: {
          ...state.proFinder,
          proFinderStatus: STATUSES.SUCCESS,
        },
      };
    case SET_PROFINDER_STATUS_TO_SUCCESS:
      return {
        ...state,
        proFinder: {
          ...state.proFinder,
          proFinderStatus: STATUSES.SUCCESS,
        },
      };
    case SUBMIT_PROFINDER_REFERRAL_REQUEST:
      return {
        ...state,
        proFinder: {
          ...state.proFinder,
          proFinderReferralStatus: STATUSES.LOADING,
          referrals: HOMEOWNER_INITIAL_STATE.proFinder.referrals,
          reviews: {},
        },
      };
    case SUBMIT_PROFINDER_REFERRAL_REQUEST_SUCCESS:
      return {
        ...state,
        proFinder: {
          ...state.proFinder,
          proFinderReferralStatus: STATUSES.SUCCESS,
          referrals: {
            projectId: action.payload.projectId,
            businesses: action.payload.businesses?.length
              ? action.payload.businesses.map((business) => ({
                  ...business,
                  quoteStatus: STATUSES.INIT,
                  quoteContact: null,
                }))
              : [],
          },
        },
      };
    case SUBMIT_PROFINDER_REFERRAL_REQUEST_ERROR:
      return {
        ...state,
        proFinder: {
          ...state.proFinder,
          proFinderReferralStatus: STATUSES.ERROR,
        },
      };
    case REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE:
      return {
        ...state,
        proFinder: {
          ...state.proFinder,
          referrals: {
            ...state.proFinder.referrals,
            businesses: state.proFinder.referrals.businesses.map(
              (business) => ({
                ...business,
                quoteStatus:
                  business.business_id === action.payload.businessId
                    ? STATUSES.LOADING
                    : business.quoteStatus,
              })
            ),
          },
        },
      };
    case REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE_SUCCESS:
      return {
        ...state,
        proFinder: {
          ...state.proFinder,
          referrals: {
            ...state.proFinder.referrals,
            businesses: state.proFinder.referrals.businesses.map(
              (business) => ({
                ...business,
                quoteStatus:
                  business.business_id === action.payload.businessId
                    ? STATUSES.SUCCESS
                    : business.quoteStatus,
                quoteContact:
                  business.business_id === action.payload.businessId
                    ? action.payload.quoteContact
                    : business.quoteContact,
              })
            ),
          },
        },
      };
    case REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE_ERROR:
      return {
        ...state,
        proFinder: {
          ...state.proFinder,
          referrals: {
            ...state.proFinder.referrals,
            businesses: state.proFinder.referrals.businesses.map(
              (business) => ({
                ...business,
                quoteStatus:
                  business.business_id === action.payload.businessId
                    ? STATUSES.ERROR
                    : business.quoteStatus,
                quoteContact:
                  business.business_id === action.payload.businessId
                    ? null
                    : business.quoteContact,
              })
            ),
          },
        },
      };
    case FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS:
      return {
        ...state,
        proFinder: {
          ...state.proFinder,
          proFinderReviewsModalStatus: STATUSES.LOADING,
          activeReviewId: action.payload.businessId,
        },
      };
    case FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS_SUCCESS:
      return {
        ...state,
        proFinder: {
          ...state.proFinder,
          proFinderReviewsModalStatus: STATUSES.SUCCESS,
          reviews: {
            ...state.proFinder.reviews,
            [action.payload.businessId]: action.payload.reviews,
          },
        },
      };
    case FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS_ERROR:
      return {
        ...state,
        proFinder: {
          ...state.proFinder,
          proFinderReviewsModalStatus: STATUSES.ERROR,
        },
      };
    case SET_HOMEADVISOR_REVIEWS_STATUS_TO_SUCCESS:
      return {
        ...state,
        proFinder: {
          ...state.proFinder,
          proFinderReviewsModalStatus: STATUSES.SUCCESS,
        },
      };
    case LOGOUT_SUCCESS: {
      return {
        ...state,
        ...HOMEOWNER_INITIAL_STATE,
      };
    }
    default:
      return state;
  }
}
