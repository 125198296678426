import { MlsState, PropertyType } from '@client/store/sagas/queries/types';

export const SUPPORTED_PROPERTY_TYPES_CHASE = [
  'SINGLE_FAMILY_DETACHED',
  'SINGLE_FAMILY_ATTACHED',
  'CONDO',
  'MULTI_FAMILY',
  'COOP',
];
export const ACTIVE_LISTING_STATUSES = ['ACTIVE', 'PENDING', 'CONTINGENT'];

export const getIsEligibleForFinancing = (
  propertyType: PropertyType | null | undefined,
  mlsState: MlsState | 'NOT_LISTED' | null | undefined
): boolean => {
  if (propertyType && mlsState) {
    return (
      SUPPORTED_PROPERTY_TYPES_CHASE.includes(propertyType) &&
      ACTIVE_LISTING_STATUSES.includes(mlsState)
    );
  }
  return false;
};
