import React from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

type Props = {
  theme: Theme;
};

const CantFindAddressResultLink = ({ theme }: Props) => {
  return (
    <span className={theme.CantFindAddressResultLink}>
      {' '}
      Can't find your address?
    </span>
  );
};

export default themr(
  'CantFindAddressResultLinkThemed',
  {}
)(CantFindAddressResultLink);
