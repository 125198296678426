import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import PropertyCardValueCobranded from '@client/components/PropertyCardValue/PropertyCardValueCobranded';
import defaultTheme from '@client/css-modules/PropertyCardValueSection.css';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { getMonthlyPaymentEstimateForProperty } from '@client/store/selectors/property-details.selectors';
import { NormalizedProperty } from '@client/store/types/property';
import {
  getIsActiveListing,
  getPropertyValueAndValueLabel,
} from '@client/utils/property.utils';
import PropertyCardLenderCTASectionCobranded, {
  getPropertyCardCTAComponentForCobrand,
} from './PropertyCardLenderCTASection/PropertyCardLenderCTASectionCobranded';

type Props = {
  /* Property details object, retrieved from API and normalized */
  propertyDetails: NormalizedProperty;
  /* A prop to pass through to <PropertyCardValue> used for showing/hiding a
   * "more details" click teaser */
  isShowingClickHintCTA?: boolean;
  isShowingLegacyLenderCTA?: boolean;
  onPropertyDetailsSwipe?: (args: { direction: any; event: Event }) => void;
  /* Handler to expand the PDP modal the full PDP page when applicable */
  onPropertyDetailsClick?: () => void;
  onShowPropertyInfo?: (slug: string, isActiveListing: boolean) => void;
  theme: Theme;
  bottomLabel: string | JSX.Element;
  shouldHidePropertyCardValueInPropertyIntro?: boolean;
  forceLowerSpacing?: boolean;
};

/**
 * Contains the price content within <PropertyCard>
 */
const PropertyCardValueSection: React.FC<Props> = ({
  propertyDetails,
  isShowingClickHintCTA,
  isShowingLegacyLenderCTA,
  theme,
  bottomLabel,
  shouldHidePropertyCardValueInPropertyIntro,
  forceLowerSpacing,
}) => {
  const monthlyPaymentEstimateObj = useSelector(
    getMonthlyPaymentEstimateForProperty
  );
  const isActiveListing = getIsActiveListing(propertyDetails.status);
  const monthlyPaymentEstimateForProperty =
    monthlyPaymentEstimateObj && monthlyPaymentEstimateObj.total;
  const cobrandId = useSelector(getCobrandId);

  return (
    <div
      data-hc-name={'property-info-and-value'}
      className={classNames(theme.PropertyCardValueSection, {
        [theme.HidePropertyCardValueInPropertyIntro]:
          !shouldHidePropertyCardValueInPropertyIntro,
      })}
    >
      <div
        className={classNames(theme.PropertyCardPriceInfo, {
          [theme.ShowPropertyCardPriceInfo]:
            !!shouldHidePropertyCardValueInPropertyIntro,
        })}
      >
        {
          /* If we're showing the lender CTA in a property card (as when in WatchList) */
          isShowingLegacyLenderCTA &&
          getPropertyCardCTAComponentForCobrand(cobrandId) ? (
            <PropertyCardLenderCTASectionCobranded
              isActiveListing={isActiveListing}
              /* Provides `value` and `valueLabel` props */
              {...getPropertyValueAndValueLabel(propertyDetails)}
              propertyDetails={propertyDetails}
              isShowingClickHintCTA={!!isShowingClickHintCTA}
              theme={theme}
              forceLowerSpacing={forceLowerSpacing}
            />
          ) : (
            /* If we're not in a cobranded experience and we want to show only a value with no CTA
             * (such as on the search map and list views or the non-cobranded PDP) */
            <PropertyCardValueCobranded
              /* Provides `value` and `valueLabel` props */
              {...getPropertyValueAndValueLabel(propertyDetails)}
              monthlyPaymentEstimate={
                propertyDetails['monthlyPaymentEstimate'] ||
                monthlyPaymentEstimateForProperty
              }
              isShowingClickHintCTA={!!isShowingClickHintCTA}
              theme={theme}
              bottomLabel={bottomLabel}
              rentalEstimate={propertyDetails.rentalValue}
            />
          )
        }
      </div>
    </div>
  );
};

export const ThemedPropertyCardValueSection = themr(
  'PropertyCardValueSection',
  defaultTheme
)(PropertyCardValueSection);
export default ThemedPropertyCardValueSection;
