import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import ComehomeHeaderLogoWithHouse from '@client/components/ComehomeHeaderLogoWithHouse';
import ComeHomeLogoImg from '@client/components/ComeHomeLogoImg';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return ComehomeHeaderLogoWithHouse;
    case COBRAND_IDS.WWW:
      return ComeHomeLogoImg;
    default:
      return ComehomeHeaderLogoWithHouse;
  }
};

const ComeHomeHeaderLogoCobranded: React.FC = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default ComeHomeHeaderLogoCobranded;
