import { openModal } from '@client/store/actions/modals.actions';
import { LOCKED_PDP_COMPS_PLACEHOLDER } from '@client/store/locked-components-constants';
import {
  getComps,
  getCompsValueRange,
} from '@client/store/selectors/property-details.selectors';
import {
  SectionId,
  SpecialUserType,
} from '@client/store/types/locked-components';
import { dollarsFormatter } from '@client/utils/formatter.utils';
import { pluralize } from '@client/utils/string.utils';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PreviewSection from './PreviewSection';

const Comps = () => {
  const dispatch = useDispatch();
  const comps = useSelector(getComps);
  const compsValueRange = useSelector(getCompsValueRange);

  return (
    <>
      {Array.isArray(comps) && comps.length > 0 && (
        <PreviewSection
          data-section-key={'comps'}
          dataEventName={'click_property_details_comps'}
          onClick={() => dispatch(openModal('comps'))}
          title="Comparable Properties"
          lockedComponent={{
            sectionId: SectionId.PdpComps,
            lockedFor: [SpecialUserType.Restricted],
          }}
          placeholder={LOCKED_PDP_COMPS_PLACEHOLDER}
        >
          {comps && comps.length > 0 && (
            <React.Fragment>
              <div>{`${comps.length} Comparable ${pluralize(
                'Property',
                comps.length
              )}`}</div>
              {compsValueRange &&
                (compsValueRange[0] !== compsValueRange[1]
                  ? `Ranging from ${dollarsFormatter(
                      compsValueRange[0]
                    )} to ${dollarsFormatter(compsValueRange[1])}`
                  : `Valued at ${dollarsFormatter(compsValueRange[0])}`)}
            </React.Fragment>
          )}
        </PreviewSection>
      )}
    </>
  );
};

export default Comps;
