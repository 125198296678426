import React from 'react';

import BreadcrumbContainer from '@client/containers/breadcrumbs.container';
import { PropertyPageTopBarProps } from '@client/store/types/cobranded-components/property-page-top-bar';
import theme from '@client/css-modules/PropertyPageTopBarChase.css';

const PropertyPageTopBarChase: React.FC<PropertyPageTopBarProps> = () => {
  return (
    <div className={theme.PropertyPageTopBar}>
      <div className={theme.PropertyPageTopBarInner}>
        <BreadcrumbContainer />
      </div>
    </div>
  );
};

export default PropertyPageTopBarChase;
