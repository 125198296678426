import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import defaultTheme from '@client/css-modules/PDPExpertRailCard.css';

import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { useLoInfoProps } from '@client/hooks/lo-info-provider.hooks';
import {
  useYourTeamAgentData,
  useYourTeamAgentDataFetchEffect,
  useYourTeamModalState,
} from '@client/hooks/your-team.hooks';
import { getLoanOfficerInfo } from '@client/store/selectors/loan-officer.selectors';

import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';

import CaratIconCobranded from '@client/components/CaratIcon/CaratIconCobranded';

const nonBtnPaddingStyles = css`
  padding-left: 37px;
  padding-right: 37px;
`;

const StyledSection = styled.section<{ $bgColor: string }>`
  background: ${(props) => props.$bgColor};
  color: #fff;
  padding: 27px 0 26px;
  box-sizing: border-box;
`;

const StyledHeading = styled.div`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.2px;
  ${nonBtnPaddingStyles}
`;

const StyledAgentRow = styled.button`
  background: transparent;
  border-radius: 8px;
  border: none;
  color: #fff;
  text-align: left;
  margin-left: 29px;
  margin-right: 29px;
  margin-bottom: 8px;
  padding: 12px 8px;
  width: 100%;
  max-width: calc(100% - 58px);
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const StyledImage = styled.img`
  height: 50px;
  width: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  margin-right: 9px;
  box-sizing: border-box;
  object-fit: cover;
  object-position: center top;
`;

const StyledAgentType = styled.div`
  font-size: 10px;
  font-weight: 800;
  margin: 12px 0 5px;
  ${nonBtnPaddingStyles}
`;

const StyledFlexSpan = styled.span`
  display: flex;
`;

const StyledName = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 2px;
`;

const StyledInfo = styled.span`
  display: block;
  font-size: 11px;
  font-weight: 400;
`;

const StyledFlexAgentInfo = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

interface AgentItemProps {
  agentType: 'Loan Officer' | 'Real Estate Agent';
  agentName: string;
  brokerName: string | null;
  agentId?: string;
  agentImage?: string;
  theme: Theme;
  onClick: () => void;
}

const AgentItem = ({
  agentType,
  agentName,
  brokerName,
  agentId,
  agentImage,
  onClick,
  theme,
}: AgentItemProps) => (
  <>
    <div>
      <StyledAgentType>{agentType}</StyledAgentType>
    </div>
    <StyledAgentRow
      type="button"
      onClick={onClick}
      onKeyDown={onEnterOrSpaceKey(onClick)}
    >
      <StyledFlexSpan>
        {agentImage && (
          <StyledImage src={agentImage} alt={`Photo of ${agentName}`} />
        )}
        <StyledFlexAgentInfo>
          <span>
            <StyledName>{agentName}</StyledName>
            {brokerName && <StyledInfo>{brokerName}</StyledInfo>}
            {agentId && <StyledInfo>{agentId}</StyledInfo>}
          </span>
          <span>
            <CaratIconCobranded className={theme.CaratIcon} />
          </span>
        </StyledFlexAgentInfo>
      </StyledFlexSpan>
    </StyledAgentRow>
  </>
);

const PDPExpertRailCard = ({ theme }) => {
  useYourTeamAgentDataFetchEffect(true);
  const { introducedAgentData, genericAgentData } = useYourTeamAgentData();
  const { openYourTeamModal } = useYourTeamModalState();
  const { loConfig } = useLoInfoProps();
  const { loanOfficerBg } = useCobrandStyles();
  const loanOfficer = useSelector(getLoanOfficerInfo);

  const hbPdp = loConfig?.hbPdp;
  const bgColor = hbPdp?.bgColor ?? loanOfficerBg;
  if (!loanOfficer && !introducedAgentData && !genericAgentData) {
    return null;
  } else {
    return (
      <StyledSection $bgColor={bgColor}>
        <StyledHeading>
          Have questions? Contact your
          <br />
          real estate experts.
        </StyledHeading>
        {loanOfficer && (
          <AgentItem
            agentName={loanOfficer.fullName}
            agentType="Loan Officer"
            brokerName={loanOfficer.company}
            agentId={loanOfficer.id}
            agentImage={loanOfficer.photoUrl}
            theme={theme}
            onClick={() => openYourTeamModal('lo')}
          />
        )}
        {introducedAgentData ? (
          <AgentItem
            agentName={`${introducedAgentData?.first_name}${
              introducedAgentData?.first_name && introducedAgentData.last_name
                ? ' '
                : ''
            }${introducedAgentData?.last_name}`}
            agentType="Real Estate Agent"
            brokerName={introducedAgentData.brokerage_name}
            agentId={`License #${introducedAgentData.license_number}`}
            agentImage={introducedAgentData.headshot}
            theme={theme}
            onClick={() => openYourTeamModal('agent')}
          />
        ) : genericAgentData ? (
          <AgentItem
            agentName={`${genericAgentData?.full_name}`}
            agentType="Real Estate Agent"
            brokerName={genericAgentData.brokerage_name}
            agentImage={genericAgentData.headshot}
            theme={theme}
            onClick={() => openYourTeamModal('agent')}
          />
        ) : null}
      </StyledSection>
    );
  }
};

const ThemedPDPExpertRailCard = themr(
  'PDPExpertRailCard',
  defaultTheme
)(PDPExpertRailCard);
export default ThemedPDPExpertRailCard;
