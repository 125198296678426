import React from 'react';
import SearchIconComehome from '@client/assets/images/search-icon-comehome.svg';

const SearchIconComehomeImg: React.FunctionComponent<
  React.HTMLAttributes<HTMLImageElement>
> = (props) => {
  return <img src={SearchIconComehome} alt={'Search Icon'} {...props} />;
};

export default SearchIconComehomeImg;
