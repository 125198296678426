import {
  EVENTS,
  FILTER_EVENTS,
  GA_EVENTS,
  PARENT_EVENTS,
} from '@client/store/analytics-constants';
import { FilterKey } from '@client/store/filter-constants';

import {
  AnalyticsEventAddress,
  AnalyticsTopLevelItems,
} from '@client/store/types/analytics';
import { FilterPickerValue } from '@client/store/types/filters';
import { LoanOfficerContact } from '@client/store/types/loan-officer';
import { UserAnalyticsInfoWithFormData } from '@client/store/types/user-data-analytics';

/**
 * A place for actions whose only purpose is to feature a `meta` props for analytics
 * reporting.  It's preferable to add a `meta` tag to existing actions for reporting.
 */

type PageviewProps = {
  props: {
    ch_property_id: string;
  };
};

export const reportPageView = (isAppInit: boolean, props?: PageviewProps) => ({
  type: 'REPORT_PAGEVIEW',
  isAppInit,
  meta: {
    analytics: {
      event: EVENTS.PAGE_VIEW,
      gaType: GA_EVENTS.PAGEVIEW,
      ...props,
    },
    /* Allows us to avoid reporting duplicate pageview events if we redirect immediately after
     * loading a page */
    debounce: {
      time: 500,
      leading: false,
      trailing: true,
    },
  },
});

export const reportPropertyDetailsView = (
  slug: string | null,
  address: AnalyticsEventAddress | null,
  loanOfficerId: string | null,
  isHomeowner: boolean = false
) => ({
  type: 'REPORT_PROPERTY_DETAILS_VIEW',
  meta: {
    analytics: {
      event: 'property_details_page_view',
      props: {
        ...address,
        ch_property_id: slug,
        lo_id: loanOfficerId,
        homeowner: isHomeowner,
      },
    },
  },
});

export const reportPropertyDetailsWatchClick = (slug: string) => ({
  type: 'REPORT_PROPERTY_DETAILS_WATCH_CLICK',
  meta: {
    analytics: {
      event: 'click_property_details_watch',
      parentEvent: PARENT_EVENTS.CLICK_PROPERTY_WATCHING,
      props: { ch_property_id: slug },
    },
  },
});

export const reportPropertyDetailsUnwatchClick = (slug: string) => ({
  type: 'REPORT_PROPERTY_DETAILS_UNWATCH_CLICK',
  meta: {
    analytics: {
      event: 'click_property_details_unwatch',
      parentEvent: PARENT_EVENTS.CLICK_PROPERTY_WATCHING,
      props: { ch_property_id: slug },
    },
  },
});

export const reportPropertyDetailsUnwatchConfirmClick = (slug: string) => ({
  type: 'REPORT_PROPERTY_DETAILS_UNWATCH_CONFIRM_CLICK',
  meta: {
    analytics: {
      event: 'click_property_details_unwatch_confirm',
      parentEvent: PARENT_EVENTS.CLICK_PROPERTY_WATCHING,
      props: { ch_property_id: slug },
    },
  },
});

export const reportPropertyCardClick = () => ({
  type: 'REPORT_PROPERTY_CARD_CLICK',
  meta: {
    analytics: {
      event: EVENTS.CLICK_PROPERTY_CARD,
      parentEvent: PARENT_EVENTS.CLICK_PROPERTY_CARD,
    },
  },
});

export const reportNavSignupClick = () => ({
  type: 'REPORT_NAV_SIGNUP_CLICK',
  meta: {
    analytics: {
      parentEvent: PARENT_EVENTS.CLICK_NAV,
      event: 'click_nav_signup',
    },
  },
});

export const reportNavHomeOwnerClick = () => ({
  type: 'REPORT_NAV_HOMEOWNER_CLICK',
  meta: {
    analytics: {
      parentEvent: PARENT_EVENTS.CLICK_NAV,
      event: 'click_nav_homeowner',
    },
  },
});

export const reportNavBuyClick = () => ({
  type: 'REPORT_NAV_BUY_CLICK',
  meta: {
    analytics: {
      parentEvent: PARENT_EVENTS.CLICK_NAV,
      event: 'click_nav_buy',
    },
  },
});

export const reportSearchAddress = (slug) => ({
  type: 'REPORT_SEARCH_ADDRESS',
  meta: {
    analytics: {
      event: 'click_search_address',
      props: {
        result_address_slug: slug,
      },
    },
  },
});

export const reportSearchLocation = (area) => ({
  type: 'REPORT_SEARCH_LOCATION',
  meta: {
    analytics: {
      event:
        area && isNaN(parseInt(area.slice(-5), 10))
          ? 'click_search_city'
          : 'click_search_zip',
      props: {
        result_address_location: area,
      },
    },
  },
});

export const reportWelcomeSearchInputFocus = (
  isAddressOnlySearch: boolean
) => ({
  type: 'REPORT_WELCOME_SEARCH_INPUT_FOCUS',
  meta: {
    analytics: {
      event: EVENTS.CLICK_WELCOME_SEARCH_FIELD,
      props: {
        isAddressOnlySearch,
      },
    },
  },
});

export const reportSearchInputEnterPress = () => ({
  type: 'REPORT_SEARCH_INPUT_ENTER_PRESS',
  meta: {
    analytics: {
      event: 'click_search_query',
    },
  },
});

export const reportSearchClickCurrentLocation = () => ({
  type: 'REPORT_SEARCH_CLICK_CURRENT_LOCATION',
  meta: {
    analytics: {
      event: 'click_search_user_location',
    },
  },
});

export const reportHitDlPageOnInvalidDevice = () => ({
  type: 'REPORT_HIT_DL_PAGE_ON_INVALID_DEVICE',
  meta: {
    analytics: {
      event: 'error_invalid_device',
    },
    toast: {
      message:
        'The ComeHome app can only be downloaded on Android or iOS devices',
    },
  },
});

type RecordMapSearchUpdateFilter = {
  type: string;
  meta?: {
    analytics: { event: string; parentEvent: string; props?: any };
  };
};

export const reportSearchFilterValueSelection = (
  filterKey: FilterKey,
  descriptor: string | null,
  value: FilterPickerValue | null
) => {
  let action: RecordMapSearchUpdateFilter = {
    type: 'RECORD_SEARCH_FILTER_UPDATE',
  };
  const baseEventName = FILTER_EVENTS[filterKey];
  if (baseEventName) {
    action.meta = {
      analytics: {
        event: `${baseEventName}${descriptor ? `_${descriptor}` : ''}${
          value ? `_${value}` : ''
        }`,
        parentEvent: PARENT_EVENTS.CLICK_SEARCH_FILTERS,
      },
    };
  }

  return action;
};

export const reportHomeownerMarketSnapshotView = () => ({
  type: 'REPORT_HOMEOWNER_MARKET_SNAPSHOT_VIEW',
  meta: {
    analytics: {
      event: 'click_owner_snapshot_market_tooltip',
    },
  },
});

export const reportHomeownerSnapshotSourcesView = () => ({
  type: 'REPORT_HOMEOWNER_SNAPSHOT_SOURCES_VIEW',
  meta: {
    analytics: {
      event: 'click_owner_snapshot_sources',
    },
  },
});

export const reportSearchNoAddressResultsForQuery = (userInput: string) => ({
  type: 'REPORT_SEARCH_NO_ADDRESS_RESULTS_FOR_QUERY',
  meta: {
    analytics: {
      event: EVENTS.NO_ADDRESS_RESULTS_FOR_QUERY,
      props: {
        user_input: userInput,
      },
    },
  },
});

export const reportSearchListNoPropertyResults = () => ({
  type: 'REPORT_SEARCH_NO_PROPERTY_RESULTS',
  meta: {
    analytics: {
      event: EVENTS.SEARCH_LIST_NO_SEARCH_PROPERTY_RESULTS,
    },
    debounce: {
      time: 300,
    },
  },
});

export const reportSearchListHasPropertyResults = () => ({
  type: 'REPORT_SEARCH_HAS_PROPERTY_RESULTS',
  meta: {
    analytics: {
      event: EVENTS.SEARCH_LIST_HAS_SEARCH_PROPERTY_RESULTS,
    },
    debounce: {
      time: 300,
    },
  },
});

export const reportCarouselRightArrowClick = (
  address?: AnalyticsEventAddress
) => ({
  type: 'REPORT_CAROUSAL_RIGHT_ARROW_CLICK',
  meta: {
    analytics: {
      event: 'click_carousel_right_arrow',
      ...(address && {
        props: {
          address,
          ch_property_id: address.slug,
        },
      }),
    },
  },
});

export const reportCobrandTOUClick = () => ({
  type: 'REPORT_COBRAND_TOU_CLICK',
  meta: {
    analytics: {
      event: 'click_cobrand_tou',
    },
  },
});

export const reportCHOClickLOContact = (slug: string | null) => ({
  type: 'REPORT_CHO_CLICK_LO_CONTACT',
  meta: {
    analytics: {
      parentEvent: PARENT_EVENTS.CLICK_LO_CONTACT,
      event: 'click_owner_lo_contact',
      props: {
        ch_property_id: slug,
      },
    },
  },
});

export const reportOwnerRefiClickLOContact = () => ({
  type: 'REPORT_OWNER_REFI_CLICK_LO_CONTACT',
  meta: {
    analytics: {
      parentEvent: PARENT_EVENTS.CLICK_LO_CONTACT,
      event: 'click_owner_refi_lo_contact',
    },
  },
});

// The message from the LO contact modal on the search results page is successfully sent
export const getClickBuyerLOModalContactInfo = () => ({
  type: 'REPORT_CLICK_BUYER_LO_MODAL_CTA_SUCCESS',
  meta: {
    analytics: {
      parentEvent: PARENT_EVENTS.CLICK_LO_CONTACT,
      event: 'click_buyer_lo_modal_cta_success',
    },
  },
});

export const reportClickBuyerLOModalCTASuccess = (
  visitorInfo: LoanOfficerContact,
  address: AnalyticsEventAddress | null,
  loanOfficerId: string
) => {
  const clickInfo = getClickBuyerLOModalContactInfo();
  clickInfo.meta.analytics['props'] = {
    ...address,
    ...visitorInfo,
    lo_id: loanOfficerId,
  };
  return clickInfo;
};

export const getClickOwnerLOModalContactInfo = () => ({
  type: 'REPORT_CLICK_OWNER_LO_MODAL_CTA_SUCCESS',
  meta: {
    analytics: {
      parentEvent: PARENT_EVENTS.CLICK_LO_CONTACT,
      event: 'click_owner_lo_modal_cta_success',
    },
  },
});

export const reportClickOwnerLOModalCTASuccess = (
  visitorInfo: LoanOfficerContact,
  address: AnalyticsEventAddress | null,
  loanOfficerId: string
) => {
  const clickInfo = getClickOwnerLOModalContactInfo();
  clickInfo.meta.analytics['props'] = {
    ...address,
    ...visitorInfo,
    lo_id: loanOfficerId,
  };
  return clickInfo;
};

export const reportClickSearchMapAutoCompleteSearchButton = () => ({
  type: 'REPORT_CLICK_SEARCH_MAP_AUTOCOMPLETE_SEARCH_BUTTON',
  meta: {
    analytics: {
      event: EVENTS.CLICK_SEARCH_MAP_AUTOCOMPLETE_SEARCH_BUTTON,
    },
  },
});

export const reportClickSearchTroubleFindingAddress = () => ({
  type: 'REPORT_CLICK_SEARCH_TROUBLE_FINDING_ADDRESS',
  meta: {
    analytics: {
      event: 'click_search_trouble_finding_address',
    },
  },
});

export const reportClickSearchStillExploreLoanOptions = () => ({
  type: 'REPORT_CLICK_SEARCH_STILL_EXPLORE_LOAN_OPTIONS',
  meta: {
    analytics: {
      event: EVENTS.CLICK_SEARCH_STILL_EXPLORE_LOAN_OPTIONS,
    },
  },
});

export const reportClickLoanOptionsSubmitBuyer = (
  slug: string | null,
  userAnalyticsInfoWithFormData: UserAnalyticsInfoWithFormData,
  cta_type?: string
) => ({
  type: 'REPORT_CLICK_BUYER_LOAN_OPTIONS_SUBMIT',
  meta: {
    analytics: {
      event: 'click_buyer_loan_options_submit',
      parentEvent: PARENT_EVENTS.CLICK_BUYER_LOAN_OPTIONS,
      props: {
        ch_property_id: slug,
        form_data: userAnalyticsInfoWithFormData,
        cta_type,
      },
    },
  },
});

export const reportClickLoanOptionsSubmitOwner = (
  slug: string | null,
  userAnalyticsInfoWithFormData: UserAnalyticsInfoWithFormData,
  cta_type?: string
) => ({
  type: 'REPORT_CLICK_OWNER_LOAN_OPTIONS_SUBMIT',
  meta: {
    analytics: {
      event: 'click_owner_loan_options_submit',
      parentEvent: PARENT_EVENTS.CLICK_OWNER_LOAN_OPTIONS,
      props: {
        ch_property_id: slug,
        form_data: userAnalyticsInfoWithFormData,
        cta_type,
      },
    },
  },
});

// For Chase CTAs and Watchlist on other partners
export const reportLenderCtaSeeLoanOptionsClick = (slug: string) => ({
  type: 'REPORT_LENDER_CTA_SEE_LOAN_OPTIONS_CLICK',
  meta: {
    analytics: {
      event: 'click_lender_cta_see_loan_options',
      parentEvent: PARENT_EVENTS.CLICK_LENDER_CTA,
      props: {
        slug,
      },
    },
  },
});

export const reportClickLenderNavBackToSite = () => ({
  type: 'CLICK_LENDER_NAV_BACK_TO_SITE',
  meta: {
    analytics: {
      parentEvent: PARENT_EVENTS.CLICK_LENDER_NAV,
      event: 'click_lender_nav_back_to_site',
    },
  },
});

export const reportClickLenderNavLink1 = () => ({
  type: 'CLICK_LENDER_NAV_LINK_1',
  meta: {
    analytics: {
      parentEvent: PARENT_EVENTS.CLICK_LENDER_NAV,
      event: 'click_lender_nav_link_1',
    },
  },
});

export const reportClickLenderNavLink2 = () => ({
  type: 'CLICK_LENDER_NAV_LINK_2',
  meta: {
    analytics: {
      parentEvent: PARENT_EVENTS.CLICK_LENDER_NAV,
      event: 'click_lender_nav_link_2',
    },
  },
});

export const reportClickLenderNavLink3 = () => ({
  type: 'CLICK_LENDER_NAV_LINK_3',
  meta: {
    analytics: {
      parentEvent: PARENT_EVENTS.CLICK_LENDER_NAV,
      event: 'click_lender_nav_link_3',
    },
  },
});

export const reportClickCobrandSignIn = () => ({
  type: 'CLICK_SIGN_IN_EXTERNAL',
  meta: {
    analytics: {
      parentEvent: PARENT_EVENTS.CLICK_SIGN_IN,
      event: 'click_sign_in_external',
    },
  },
});

export const reportClickExternalCTALinkOnLOCardInHomeImprovement = () => ({
  type: 'CLICK_URL_CTA_IMPROVEMENTCALC',
  meta: {
    analytics: {
      parentEvent: PARENT_EVENTS.CLICK_URL_CTA,
      event: 'click_url_cta_improvementcalc',
    },
  },
});

export type eventType =
  | 'click_grant_info_srp_card'
  | 'click_grant_modal_cta'
  | 'click_grant_pdp_cta'
  | 'click_request_a_tour_no_agent_request_tour_button'
  | 'visit'
  | 'property_details_page_view'
  | 'report_update_mortgage_calculator_details'
  | 'click_watch_property_success' // This event name is referenced within LO Direct - do not change
  | 'click_property_details_unwatch_confirm'
  | 'click_save_search_success' // This event name is referenced within LO Direct - do not change
  | 'click_settings_save_search_delete'
  | 'click_ad_cta'
  | 'click_buyer-lo-contact'
  | 'click_buyer_lo_modal_cta'
  | 'click_buyer_lo_modal_cta_success'
  | 'click_buyer_loan_options'
  | 'click_contact_agent_pdp_railcard_form_submit'
  | 'click_buyer_pdp_contact'
  | 'click_lender_cta_owner'
  | 'click_lender_cta_see_loan_options'
  | 'click_lo_contact_call'
  | 'click_lo_contact_email'
  | 'click_owner_lo_contact'
  | 'click_owner_loan_options'
  | 'click_url_cta_pdp'
  | 'click_owner_loan_options_submit'
  | 'click_url_cta_srp'
  | 'click_your_team_button'
  | 'click_your_team_close_button'
  | 'click_your_team_lender_no_lo_contact_button'
  | 'click_your_team_lo_contact_button_1'
  | 'click_your_team_lo_contact_button_2'
  | 'click_your_team_lo_contact_button_3'
  | 'click_your_team_lo_contact_button_4'
  | 'click_your_team_lo_contact_button_5'
  | 'click_your_team_lo_send_button'
  | 'click_your_team_lo_lender_cta'
  | 'click_your_team_lo_send_success'
  | 'click_your_team_lo_send_error'
  | 'click_your_team_no_lo_lender_cta'
  | 'click_buyer_loan_options_submit'
  | 'click_lender_cta'
  | 'click_loan_details_refi_calc_cta'
  | 'click_owner_refi_calc_terms'
  | 'click_owner_improvement_state_update'
  | 'click_owner_profinder_submit'
  | 'search_state_update'
  | 'click_property_details_owner_promo'
  | 'click_owner_claim_select_address'
  | 'click_owner_manage_dashboard_remove'
  | 'click_find_agent_buyer_submit'
  | 'click_find_agent_seller_submit'
  | 'click_find_agent_header_submit'
  | 'click_your_team_re_agent_lender_cta'
  | 'click_your_team_re_agent_contact_button_1'
  | 'click_your_team_re_agent_contact_button_2'
  | 'click_your_team_re_agent_contact_button_3'
  | 'click_your_team_re_agent_contact_button_4'
  | 'click_your_team_re_agent_contact_button_5'
  | 'click_your_team_re_agent_send_button'
  | 'click_your_team_re_agent_send_success'
  | 'click_your_team_re_agent_send_error'
  | 'click_nav_homepage_find_agent_component'
  | 'click_concierge_team_cta_1'
  | 'click_concierge_team_cta_2'
  | 'click_concierge_team_cta_3'
  | 'click_concierge_team_cta_4'
  | 'click_your_team_find_an_agent_tab'
  | 'click_your_team_use_this_agent_button_previous_agent'
  | 'click_your_team_use_this_agent_button_lender_recommended_agent'
  | 'click_your_team_get_paired_with_an_agent'
  | 'click_your_team_choose_another_agent_confirmation_page'
  | 'click_your_team_choose_another_agent_question_page'
  | 'click_your_team_choose_another_agent'
  | 'click_your_team_user_form_submit'
  | 'click_find_agent_card_ho'
  | 'click_find_agent_banner_pdp'
  | 'click_talk_to_agent_top_agents_ranked'
  | 'view_propensity_to_sell_modal'
  | 'view_pmi_modal'
  | 'click_owner_snapshot_chart_select'
  | 'click_home_insurance_homeowner_pdp'
  | 'click_url_cta_owner'
  | 'click_owner_sales_property'
  | 'click_comehome_logo'
  | 'click_partner_agent_wizard_upload_photo'
  | 'click_partner_agent_wizard_first_name'
  | 'click_partner_agent_wizard_last_name'
  | 'click_partner_agent_wizard_email'
  | 'click_partner_agent_wizard_phone'
  | 'click_partner_agent_landing_first_name'
  | 'click_partner_agent_landing_last_name'
  | 'click_partner_agent_landing_email'
  | 'click_partner_agent_landing_phone'
  | 'click_partner_agent_landing_teaser_submit'
  | 'click_partner_agent_wizard_change_photo'
  | 'click_partner_agent_wizard_remove_photo'
  | 'click_sort_sqft_savedhome_asc'
  | 'click_sort_sqft_savedhome_desc'
  | 'click_sort_listprice_savedhome_asc'
  | 'click_sort_listprice_savedhome_desc'
  | 'click_sort_beds_savedhome_asc'
  | 'click_sort_beds_savedhome_desc'
  | 'click_sort_baths_savedhome_asc'
  | 'click_sort_baths_savedhome_desc'
  | 'click_sort_daysmarket_savedhome_asc'
  | 'click_sort_daysmarket_savedhome_desc'
  | 'click_property_details_savedhome'
  | 'click_known_agent_owner_sign_up'
  | 'click_known_agent_buyer_sign_up'
  | 'click_known_agent_owner_contact_email'
  | 'click_known_agent_buyer_contact_email'
  | 'click_known_agent_owner_contact_phone'
  | 'click_known_agent_buyer_contact_phone'
  | 'click_known_agent_owner_contact_message'
  | 'click_known_agent_buyer_contact_message'
  | 'click_known_agent_owner_contact_first_name'
  | 'click_known_agent_buyer_contact_first_name'
  | 'click_known_agent_owner_contact_last_name'
  | 'click_known_agent_buyer_contact_last_name'
  | 'click_known_agent_owner_contact_submit'
  | 'click_known_agent_buyer_contact_submit'
  | 'click_known_agent_owner_contact_close'
  | 'click_known_agent_buyer_contact_close'
  | 'click_known_agent_owner_contact_success_close'
  | 'click_known_agent_buyer_contact_success_close'
  | 'click_known_agent_owner_contact_terms'
  | 'click_known_agent_buyer_contact_terms'
  | 'click_buyer_lo_modal_success_sign_up'
  | 'click_owner_lo_modal_success_sign_up'
  | 'click_owner_lo_modal_cta'
  | 'click_finance_cta_submit_close'
  | 'click_finance_cta_submit'
  | 'click_email_modal_submission'
  | 'click_email_modal_intent'
  | 'click_email_modal_close'
  | 'click_nav_mobile_back_to_search'
  | 'click_owner_edit_details'
  | 'scroll_homeowner_pdp_down'
  | 'scroll_homeowner_pdp_up'
  | 'click_owner_edit_beds'
  | 'click_owner_edit_full_baths'
  | 'click_owner_edit_half_baths'
  | 'click_owner_edit_sqft'
  | 'click_owner_edit_total_rooms'
  | 'click_owner_edit_lot_size'
  | 'click_owner_edit_basement'
  | 'click_owner_edit_pool'
  | 'click_owner_edit_garage_type'
  | 'click_owner_edit_condition'
  | 'click_owner_your_loan_balance_cta'
  | 'click_loan_details_edit'
  | 'click_loan_details_save'
  | 'click_loan_details_refi_calc_mortgage'
  | 'click_loan_details_refi_calc_interest_rate'
  | 'click_finance_cta_owner'
  | 'click_banner_home_search_buyer'
  | 'click_banner_home_search_owner'
  | 'click_search_button_homepage'
  | 'click_search_field'
  | 'click_homeowner_search'
  | 'click_cta_homepage_selling_options'
  | 'click_cta_homepage_buyer'
  | 'click_cta_homepage_owner'
  | 'click_lender_referral_dismiss'
  | 'click_lender_referral_brokerage_info'
  | 'close_hamburger_menu'
  | 'open_hamburger_menu'
  | 'click_see_latest_homes'
  | 'click_finance_cta_pdp'
  | 'submit_pmi_modal'
  | 'dismiss_pmi_modal'
  | 'submit_propensity_to_sell_modal'
  | 'click_propensity_to_sell_interested_in_selling_yes'
  | 'click_propensity_to_sell_interested_in_selling_no'
  | 'click_propensity_to_sell_interested_in_selling_ask_later'
  | 'click_propensity_to_sell_confirmation_yes'
  | 'click_propensity_to_sell_confirmation_no'
  | 'click_remove_property_savedhome'
  | 'click_finance_cta_search'
  | 'generic_terms_modal_confirmed'
  | 'click_your_team_lo_contact_button_1'
  | 'click_your_team_find_an_agent_form_submit'
  | 'click_alerts_property'
  | 'click_alerts_watch'
  | 'click_watchlist_unwatch_confirm'
  | 'click_alerts_unwatch_confirm'
  | 'click_search_homes'
  | 'click_login_back_button'
  | 'click_signup_back_button'
  | 'click_search_bar'
  | 'click_search_bar_exit'
  | 'click_carousel_left_arrow'
  | 'click_pdp_photo_carousel_login_upsell'
  | 'click_pdp_main_photo'
  | 'click_owner_profinder_results_reviews'
  | 'click_owner_profinder_results_quote'
  | 'click_owner_profinder_reviews_back'
  | 'click_owner_profinder_reviews_quote'
  | 'click_owner_profinder_choice_response'
  | 'click_owner_profinder_free_text'
  | 'click_owner_profinder_free_text_submit'
  | 'click_pdp_off_market_see_loan_products'
  | 'click_lender_cta_setup_buying_power'
  | 'click_pdp_off_market_see_letter'
  | 'click_see_letter'
  | 'click_comp_property_card'
  | 'click_comp_right_arrow'
  | 'click_comp_left_arrow'
  | 'click_comp_map'
  | 'click_find_agent_cancel_owner_form'
  | 'click_new_agent_owner_contact'
  | 'click_store_ios'
  | 'click_store_android'
  | 'click_owner_profinder_back'
  | 'click_nav_profile'
  | 'click_nav_login'
  | 'click_nav_settings'
  | 'click_nav_alerts'
  | 'click_nav_watchlist'
  | 'click_nav_homeowner'
  | 'click_nav_buy'
  | 'click_nav_signup'
  | 'click_nav_avatar'
  | 'click_app_global_nav_download_ios'
  | 'click_app_global_nav_download_android'
  | 'click_owner_improvement_back'
  | 'click_homeowner_search_exit'
  | 'click_owner_claim_type_address'
  | 'click_owner_public_view'
  | 'click_property_details_owner_view'
  | 'click_owner_manage_dashboard_add'
  | 'click_owner_dashboard'
  | 'click_property_details_contact_agent'
  | 'click_buyer_loan_options_close'
  | 'click_owner_loan_options_close'
  | 'signin_email'
  | 'signin_password'
  | 'click_manage_alerts'
  | 'click_heatmap_crime'
  | 'click_heatmap_school'
  | 'click_heatmap_price'
  | 'click_heatmap_forecast'
  | 'click_heatmap_pdp_zoom'
  | 'click_map_pdp_zoom_in'
  | 'click_map_pdp_zoom_out'
  | 'click_heatmap_pdp_drag'
  | 'click_map_pdp_drag'
  | 'click_bottom_nam_map'
  | 'click_bottom_nam_list'
  | 'click_bottom_nam_saved'
  | 'click_bottom_nam_alerts'
  | 'click_bottom_nam_settings'
  | 'click_bottom_nam_search'
  | 'click_bottom_nam_homeowner'
  | 'click_mobile_filter_apply'
  | 'click_mobile_filter_cancel'
  | 'click_buying_power_ok'
  | 'click_buyer_power_cancel'
  | 'click_mobile_filter_item'
  | 'click_mobile_filter_back'
  | 'click_mobile_filter_show_results'
  | 'click_mobile_filter_add_filter'
  | 'click_property_details_update_mortgage_calc_details'
  | 'click_property_details_mortgage_calculator'
  | 'click_other_properties_property_card'
  | 'click_nearby_properties_unwatch'
  | 'click_nearby_properties_unwatch_confirm'
  | 'click_nearby_properties_watch'
  | 'click_partner_agent_landing_nav_faq'
  | 'click_partner_agent_landing_get_started'
  | 'click_partner_agent_landing_body_faq'
  | 'click_partner_agent_landing_send_email'
  | 'click_partner_agent_landing_nav_logo'
  | 'click_property_photos_watch'
  | 'click_property_photos_unwatch'
  | 'click_property_photos_unwatch_confirm'
  | 'click_property_details_general_details'
  | 'click_property_info'
  | 'click_settings_privacy'
  | 'click_saved_search_remove_property'
  | 'click_search_filters_advanced_filters'
  | 'click_sort_search_change'
  | 'click_sort_search_menu_open'
  | 'click_search_list_unwatch'
  | 'click_search_list_watch'
  | 'click_search_filters'
  | 'click_search_map_marker'
  | 'click_search_results_property'
  | 'click_search_map_popup'
  | 'click_filter_map'
  | 'click_heatmap_home_zoom'
  | 'click_map_home_zoom_in'
  | 'click_map_home_zoom_out'
  | 'click_heatmap_home_drag'
  | 'click_map_home_drag'
  | 'click_search_list_unwatch_confirm'
  | 'click_heatmap_item'
  | 'change_quick_filter_proptype'
  | 'change_quick_filter_list_price'
  | 'change_quick_filter_beds'
  | 'click_mobile_filter_property_type'
  | 'click_mobile_filter_list_price'
  | 'click_mobile_filter_beds'
  | 'click_settings_terms'
  | 'click_settings_privacy'
  | 'click_settings_feedback'
  | 'click_settings_legal'
  | 'click_settings_notifications'
  | 'signup_land_page'
  | 'signup_email'
  | 'signup_password'
  | 'signup_firstname'
  | 'signup_lastname'
  | 'click_watchlist_tab'
  | 'click_saved_searches'
  | 'click_watchlist_property'
  | 'click_watchlist_unwatch'
  | 'no_properties_in_watchlist'
  | 'click_view_option_grid_savedhome'
  | 'click_view_option_list_savedhome'
  | 'click_homeowner_home_sales_see_all'
  | 'click_homeowner_home_sales_map_view'
  | 'click_owner_sales_card_back'
  | 'click_owner_verify_cta'
  | 'click_our_brokers_page'
  | 'click_our_brokers_send_cta_success'
  | 'click_our_brokers_get_started'
  | 'click_our_brokers_first_name'
  | 'click_our_brokers_last_name'
  | 'click_our_brokers_email'
  | 'click_our_brokers_message'
  | 'click_our_brokers_send_cta'
  | 'click_concierge_team_cta_1'
  | 'click_owner_equity'
  | 'click_email_notifications_toggle'
  | 'view_known_agent_buyer_contact_success'
  | 'view_known_agent_owner_contact_success'
  | 'view_known_agent_buyer_contact_fail'
  | 'view_known_agent_owner_contact_fail'
  | 'view_new_agent_buyer_contact_success'
  | 'view_new_agent_owner_contact_success'
  | 'view_new_agent_buyer_contact_fail'
  | 'view_new_agent_buyer_contact_fail'
  | 'signup_complete'
  | 'signin_complete'
  | 'click_our_brokers'
  | 'click_first_name_input'
  | 'click_last_name_input'
  | 'click_phone_input'
  | 'click_state_input'
  | 'click_city_input'
  | 'click_zipcode_input'
  | 'click_owner_verify_success'
  | 'click_email_input'
  | 'search_newuser_nolocation'
  | 'click_owner_lo_modal_cta_error'
  | 'click_buyer_lo_modal_cta_error'
  | 'click_email_terms_of_use'
  | 'click_email_privacy'
  | 'click_email_unsubscribe'
  | 'click_email_contact'
  | 'click_email_comehome_link'
  | 'click_lender_brokerage_page_cta'
  | 'click_pdp_share_message_send_cta_success'
  | 'click_pdp_share_message_send_cta_error'
  | 'click_partner_agent_wizard_back'
  | 'click_partner_agent_wizard_submission'
  | 'click_partner_agent_wizard_next'
  | 'click_partner_agent_success_close'
  | 'click_partner_agent_wizard_license_state'
  | 'click_partner_agent_wizard_realtor_license'
  | 'click_partner_agent_wizard_realtor_license_type'
  | 'click_partner_agent_wizard_realtor_coverage_area'
  | 'click_partner_agent_wizard_brokerage_name'
  | 'click_partner_agent_wizard_brokerage_license'
  | 'click_partner_agent_wizard_brokerage_email'
  | 'click_partner_agent_wizard_brokerage_address1'
  | 'click_partner_agent_wizard_brokerage_address2'
  | 'click_partner_agent_wizard_brokerage_city'
  | 'click_partner_agent_wizard_state'
  | 'click_partner_agent_wizard_brokerage_zip'
  | 'click_partner_agent_landing_expand_faq_1'
  | 'click_partner_agent_landing_expand_faq_2'
  | 'click_partner_agent_landing_expand_faq_3'
  | 'click_partner_agent_landing_expand_faq_4'
  | 'click_partner_agent_landing_expand_faq_5'
  | 'click_partner_agent_landing_collapse_faq_1'
  | 'click_partner_agent_landing_collapse_faq_2'
  | 'click_partner_agent_landing_collapse_faq_3'
  | 'click_partner_agent_landing_collapse_faq_4'
  | 'click_partner_agent_landing_collapse_faq_5'
  | 'click_partner_agent_success_add_state'
  | 'click_partner_agent_success_comehome'
  | 'click_partner_agent_success_housecanary'
  | 'click_confirm_modal_back_button'
  | 'show_monochrome_toggle_info'
  | 'hide_monochrome_toggle_info'
  | 'click_find_agent_banner_ho'
  | 'click_find_agent_banner_pdp'
  | 'click_find_agent_card_pdp'
  | 'click_find_agent_card_ho'
  | 'click_talk_to_agent_top_agents_ranked'
  | 'click_owner_edit_sale_price'
  | 'click_owner_edit_loan_amount'
  | 'click_owner_edit_save'
  | 'click_owner_edit_terms'
  | 'click_owner_edit_sale_date'
  | 'click_owner_edit_rate'
  | 'click_recent_activity_search_dropdown_view_search_activity'
  | 'click_recent_activity_search_dropdown_save_property'
  | 'click_recent_activity_search_dropdown_click_property'
  | 'click_recent_activity_srp_view_search_activity'
  | 'click_recent_activity_activity_page_save_search'
  | 'click_recent_activity_activity_page_save_property'
  | 'click_recent_activity_activity_page_show_all'
  | 'click_recent_activity_activity_page_click_search'
  | 'click_recent_activity_activity_page_click_property'
  | 'click_recent_activity_activity_page_null_state_start_search'
  | 'tou_entry_modal_confirmed'
  | 'click_tou_entry_modal_continue'
  | 'click_tou_entry_modal_dismiss'
  | 'click_chd_self_signup_step_1_submit'
  | 'click_chd_self_signup_final_submit'
  | 'lodirect_signup_complete'
  | 'lodirect_yourteam_url' // This event name is referenced within LO Direct - do not change
  | 'lodirect_yourteam_message_box'
  | 'lodirect_yourteam_message_submit'
  | 'lodirect_yourteam_message_submit_success'
  | 'click_grant_eligible_search'
  | 'click_alternate_cta_ho';

export type ParentEventType =
  | ''
  | 'visits'
  | 'click_banner_search'
  | 'click_buyer_loan_options'
  | 'click_cta'
  | 'click_email_modal'
  | 'click_lender_cta'
  | 'click_lo_contact'
  | 'click_find_agent'
  | 'click_nav'
  | 'click_our_brokers'
  | 'click_owner_claim'
  | 'click_owner_edit'
  | 'click_owner_equity'
  | 'click_owner_improvement'
  | 'click_owner_profinder'
  | 'click_owner_refi'
  | 'click_owner_snapshot'
  | 'click_owner_verify'
  | 'click_pdp_modules'
  | 'click_property_card'
  | 'click_property_details'
  | 'click_property_watching'
  | 'click_property_unwatching'
  | 'click_remove_property'
  | 'click_search'
  | 'click_search_filters'
  | 'click_signup'
  | 'click_chd_self_signup'
  | 'click_sort_baths'
  | 'click_sort_beds'
  | 'click_sort_listprice'
  | 'click_sort_daysmarket'
  | 'click_sort_sqft'
  | 'click_store'
  | 'click_url_cta'
  | 'click_your_team'
  | 'click_view_option'
  | 'lender_cta'
  | 'pmi'
  | 'propensity_to_sell'
  | 'realestate_agent_contacts'
  | 'click_recent_activity'
  | 'click_request-a-tour'
  | 'searches_performed';

/**
 * A generic analytics event func
 * Removes the need for new actions with similar payloads
 * e.g.) dispatch(reportEvent("click_cta"))
 */
export const reportEvent = (
  /* The actual event name, not a key in the EVENTS object */
  event: eventType,
  /* The actual event parent name, not a key in the EVENTS object */
  parentEvent?: ParentEventType | null,
  props?: object | null,
  topLevelItems?: AnalyticsTopLevelItems
) => ({
  type: event,
  meta: {
    analytics: {
      ...(parentEvent ? { parentEvent } : {}),
      event,
      props: props || {},
      topLevelItems: topLevelItems || {},
    },
  },
});
