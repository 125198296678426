export const ZIPCODE_TEST = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const CITY_TEST = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

export const EMAIL_TEST =
// eslint-disable-next-line security/detect-unsafe-regex
/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_TEST = /[^0-9\- ()]/;

export const DIGIT = /[^0-9]/g;

export const NOT_HTML = /[&<>"'`=\/]/g;

export const NEW_LINE = /\n/g;

