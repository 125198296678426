// NOTE: This is done as a string with replace tokens so we can extract
// the result type to generate types
const QUERY_TEMPLATE = /* GraphQL */ `query SpatialSearchListProperties(
  $spatialId: PropertiesIdentifierInput!,
  $limit: Int,
  $cursor: String,
  $sort: SpatialSortInput,
  #extraFiltersDef
) {
  propertySpatialSearch(
    id: $spatialId,
    pagination: {
      paginate: true,
      cursor: $cursor
    },
    sort: $sort,
    limit: $limit,
    #extraFiltersValues
  ) {
    ... on SpatialSearchDetailResults {
      cursor
      atEnd
      totalCount
      totalCountType
      hits {
        property {
          latestListing {
            listingOfficeName
          }
          address {
            slug
            fullAddress
            hcAddressId
            streetAddress
            unit
            city
            state
            zipcode
          }
          paymentEstimate {
            total
          }
          mls {
            lastRefreshed
            mlsID
            regulations {
              copyrightStatement
              disclaimer
              logo
              logoOverlay
              photosLogoOverlay
            }
          }
        }
        summary {
          avm {
            priceMean
          }
          #rentalAvmFragment
          beds
          baths {
            summaryCount
          }
          sqft
          mlsState
          listPrice
          listDate
          hcBuildingId
          geoLocation {
            latitude
            longitude
          }
          propertyType
          hcMlsId
          yearBuilt
          tractId
        }
      }
    }
  }
}
fragment RentalAvmFragment on PropertySummary {
  rentalAvm(qualityMethod: CONSUMER) {
    fsd
    priceLower
    priceMean
    priceUpper
    quality
  }
}`;

export default ({ includeRentalAvm, spatialId, filtersMapping, limit, cursor, sort }) => {
  const output = {
    query: QUERY_TEMPLATE.replace(
      '#extraFiltersDef',
      filtersMapping.map((item) => `$${item.key}: ${item.gqlType}`).join(',')
    ).replace(
      '#extraFiltersValues',
      filtersMapping.map((item) => `${item.key}: $${item.key}`).join(',')
    ),
    variables: {
      spatialId,
      limit,
      cursor,
      sort,
      ...filtersMapping.reduce((mem, curr) => {
        mem[curr.key] = curr.value;
        return mem;
      }, {}),
    },
  }

  if (includeRentalAvm) {
    output.query = output.query.replace('#rentalAvmFragment', '...RentalAvmFragment');
  }
  return output;
};
