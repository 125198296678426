import { ReduxState } from '@client/store/types/redux-state';
import { createSelector } from 'reselect';

export function getMLSDisplayRulesState(state: ReduxState) {
  return state.mlsGlobalDisplayRules;
}

export const getIsShowingComehomeByHouseCanaryHeaderLogo = createSelector(
  getMLSDisplayRulesState,
  (mlsDisplayRulesState) => mlsDisplayRulesState.showComehomeByHouseCanaryLogo
);

export const getGlobalMLSRegulations = createSelector(
  getMLSDisplayRulesState,
  (mlsDisplayRulesState) => mlsDisplayRulesState?.mlsRegulations || null
);

export const getGlobalMLSLastRefreshedDateTime = createSelector(
  getMLSDisplayRulesState,
  (mlsDisplayRulesState) => mlsDisplayRulesState?.lastRefreshed || null
);

export const getIsShowingBrokerLinkInFooter = createSelector(
  getMLSDisplayRulesState,
  (mlsDisplayRulesState) => mlsDisplayRulesState.requiresBrokerLinkInFooter
);
