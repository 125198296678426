import ComeHomeLogo from '@client/assets/images/come-home-logo.svg';
import React from 'react';

type Props = {
  shouldUseAltText?: boolean;
};

const ComeHomeLogoImg: React.FC<
  Props & React.HTMLAttributes<HTMLImageElement>
> = ({ shouldUseAltText, ...rest }) => (
  <img
    src={ComeHomeLogo}
    {...(shouldUseAltText && { alt: 'ComeHome Logo' })}
    {...rest}
  />
);

export default ComeHomeLogoImg;
