import classNames from 'classnames';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';

import defaultTheme from '@client/css-modules/HorizontalSeparator.css';

type Props = {
  className?: string;
  theme: Theme;
};

const HorizontalSeparator: React.FC<Props> = ({ className, theme }) => (
  <div className={classNames(theme.HorizontalSeparator, className)} />
);

export default themr('HorizontalSeparator', defaultTheme)(HorizontalSeparator);
