import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import { dollarsWithPlaceholder } from '@client/utils/string.utils';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import defaultTheme from '@client/css-modules/PropertyCardValueStacked.css';
import NoAvmPlaceholderCobranded from '@client/components/NoAvmPlaceholder/NoAvmPlaceholderCobranded';

export type PropertyCardValueStackedProps = {
  theme: Theme;
  valueLabel: string | JSX.Element;
  value: number | null;
  bottomLabel: string | JSX.Element;
  monthlyPaymentEstimate?: number | null;
};

/**
 * The price and the price label stacked vertically
 */
const PropertyCardValueStacked: React.FunctionComponent<
  PropertyCardValueStackedProps
> = ({ theme, value, valueLabel, bottomLabel, monthlyPaymentEstimate }) => {
  const isEstimatedMortgagePaymentsFeatureActivated = useSelector(
    getIsFeatureEnabled('estimated_mortgage_payments')
  );
  return (
    <div
      className={classNames(theme.PropertyCardValueStacked, {
        [theme.PropertyCardValueNoEstimatedPayment]:
          !isEstimatedMortgagePaymentsFeatureActivated,
      })}
    >
      <div
        className={classNames(theme.PriceRow, {
          [theme.PriceRowNoEstimatedPayment]:
            !isEstimatedMortgagePaymentsFeatureActivated,
        })}
        data-hc-name={'property-status'}
      >
        <div className={theme.ValueLabel} data-hc-name={'property-status-text'}>
          {valueLabel}
        </div>
        <div className={theme.Value} data-hc-name={'property-price'}>
          {value ? (
            dollarsWithPlaceholder(value)
          ) : (
            <NoAvmPlaceholderCobranded theme={theme} />
          )}
        </div>
      </div>
      {isEstimatedMortgagePaymentsFeatureActivated && (
        <>
          <div className={theme.HorizontalSeparator} />
          <div
            className={theme.EstimatedMonthlyPayment}
            data-hc-name={'property-monthly-payment'}
          >
            Estimated monthly payment{' '}
            {dollarsWithPlaceholder(monthlyPaymentEstimate)}
          </div>
        </>
      )}
      {bottomLabel && <div className={theme.BottomLabel}>{bottomLabel}</div>}
    </div>
  );
};

export default themr(
  'PropertyCardValueStackedThemed',
  defaultTheme
)(PropertyCardValueStacked);
