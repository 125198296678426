import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { Component } from 'react';

import blurredPhoto1 from '@client/assets/images/blurred-property-photo-1.jpg';
import blurredPhoto2 from '@client/assets/images/blurred-property-photo-2.jpg';
import blurredPhoto3 from '@client/assets/images/blurred-property-photo-3.jpg';
import blurredPhoto4 from '@client/assets/images/blurred-property-photo-4.jpg';
import blurredPhoto5 from '@client/assets/images/blurred-property-photo-5.jpg';
import LoadingSection from '@client/components/generic/LoadingSection';
import ImagePreloader, {
  PreloadedImages,
} from '@client/components/ImagePreloader';
import Slider from '@client/components/Slider';
import TransformedImage, {
  autoCrop,
} from '@client/components/TransformedImage';
import CarouselDialogContainer from '@client/containers/carousel-dialog.container';
import PhotoListModalContainer from '@client/containers/photo-list-modal.container';
import PhotoPlaceholderContainer from '@client/containers/photo-placeholder.container';
import defaultTheme from '@client/css-modules/CarouselRow.css';
import { reportToSentry } from '@client/utils/error.utils';

import { AnalyticsEventAddress } from '@client/store/types/analytics';
import { NormalizedProperty } from '@client/store/types/property';
import { PropertyPhotosSizes } from '@client/store/types/property-photos';

const MIN_CAROUSEL_PHOTOS = 2;
const SLIDER_INCREMENT_ANIMATION_DURATION = 0.3;
const BLURRED_UPSELL_PHOTOS = [
  blurredPhoto1,
  blurredPhoto2,
  blurredPhoto3,
  blurredPhoto4,
  blurredPhoto5,
] as string[];

type LoginUpsellProps = {
  theme: Theme;
  onRef?: (ele: HTMLDivElement) => void;
  onClickLogin: () => void;
  style?: React.CSSProperties;
  className?: string;
};

const LoginUpsell: React.FC<LoginUpsellProps> = ({
  theme,
  onRef,
  onClickLogin,
  style,
  className,
}) => (
  <div
    className={classNames(theme.LoginUpsell, { [className || '']: className })}
    ref={onRef}
    style={style}
  >
    <div className={theme.LoginUpsellMessage}>
      <button type="button" onClick={onClickLogin}>
        Join or Log in
      </button>
      &nbsp;to
      <br /> see all photos for this property.
    </div>
  </div>
);

type Props = {
  addressSlug: string | null;
  propertyDetails: NormalizedProperty | null;
  photos: string[];
  handleShowPhotosListModal: () => void;
  handleReportClickNext: (address: AnalyticsEventAddress) => void;
  handleReportClickPrev: (address: AnalyticsEventAddress) => void;
  photoSize: PropertyPhotosSizes;
  photosStatusIsInit: boolean;
  photosStatusIsLoading: boolean;
  useFullWidthImage?: boolean;
  shouldShowDesktopPhotosModal: boolean;
  isAddedToWatchList: boolean;
  isAppMounted: boolean;
  mapTileSize: [number, number];
  isShowingLoginUpsell: boolean;
  handleReportLoginUpsell: () => void;
  handleShowAuthModal: () => void;
  handleReportClickPropertyDetailsMainPhoto: (
    address: AnalyticsEventAddress
  ) => void;
  theme: Theme;
  overlay: string | null;
  imagePreloadCount: number;
};

type State = {
  /* Display a loading indicator until the first few images have been pre-loaded offscreen,
   * then render these images in the carousel */
  firstImagesPreloaded: boolean;
  /* Store React components for each image or canvas element to render in Carousel after they load  */
  preloadedImages: PreloadedImages;
  resizeCount: number;
  initialModalPhotoUrl: string | null;
};

function formatPropertyDetailsForAnalyticsAddress(
  propertyDetails: NormalizedProperty
) {
  return {
    street: propertyDetails.streetAddress,
    city: propertyDetails.city,
    state: propertyDetails.state,
    zip: propertyDetails.zipcode,
    unit: propertyDetails.unit,
    address_id: propertyDetails.hcAddressId,
    slug: propertyDetails.slug,
  };
}

const getPhotoAriaLabel = (currentCount: number, totalCount: number) =>
  `Property photo #${currentCount} of ${totalCount}`;

export class CarouselRow extends Component<Props, State> {
  state: State = {
    firstImagesPreloaded: false,
    preloadedImages: {},
    resizeCount: 0,
    initialModalPhotoUrl: null,
  };

  debouncedResizeHandler: any = null;

  handleFirstImagesPreloaded = (preloadedImages: PreloadedImages) => {
    this.setState({
      firstImagesPreloaded: true,
      preloadedImages,
    });
  };

  /* Sometimes IE11 browsers on old machines fail when trying to manipulate many images
   * with "Not enough memory" error.  Prevent this from affecting rest of page.
   * https://sentry.io/housecanarycom/consumer-web-prod/issues/672847776
   */
  componentDidCatch(error, errorInfo) {
    reportToSentry(error, {
      errorInfo,
      originalError: error,
    });
    console.error(error);
  }

  componentDidMount() {
    this.addResizeEventListeners();
  }

  componentWillUnmount() {
    this.removeResizeEventListeners();
    if (this.debouncedResizeHandler) {
      this.debouncedResizeHandler.cancel();
    }
  }

  handleTogglePhotoModal = (uId: string | number): void => {
    const {
      handleShowPhotosListModal,
      handleReportClickPropertyDetailsMainPhoto,
      isShowingLoginUpsell,
    } = this.props;
    if (isShowingLoginUpsell) {
      this.handleLoginUpsellClick();
    } else {
      const address = this.props.propertyDetails
        ? formatPropertyDetailsForAnalyticsAddress(this.props.propertyDetails)
        : null;
      this.setState({ initialModalPhotoUrl: uId as string }, () => {
        address && handleReportClickPropertyDetailsMainPhoto(address);
        handleShowPhotosListModal();
      });
    }
  };

  handleResize = (): void => {
    this.setState({ resizeCount: this.state.resizeCount + 1 });
  };

  addResizeEventListeners = (): void => {
    this.debouncedResizeHandler = debounce(this.handleResize, 300);

    if (this.debouncedResizeHandler) {
      window.addEventListener('resize', this.debouncedResizeHandler);
    }
  };

  removeResizeEventListeners = (): void => {
    if (this.debouncedResizeHandler) {
      window.removeEventListener('resize', this.debouncedResizeHandler);
    }
  };

  handleLoginUpsellClick = () => {
    this.props.handleShowAuthModal();
    this.props.handleReportLoginUpsell();
  };

  render() {
    const {
      addressSlug,
      photos,
      photoSize,
      propertyDetails,
      photosStatusIsLoading,
      photosStatusIsInit,
      useFullWidthImage,
      shouldShowDesktopPhotosModal,
      isAddedToWatchList,
      isAppMounted,
      mapTileSize,
      handleReportClickNext,
      handleReportClickPrev,
      isShowingLoginUpsell,
      theme,
      overlay,
      imagePreloadCount,
    } = this.props;
    const {
      firstImagesPreloaded,
      preloadedImages,
      resizeCount,
      initialModalPhotoUrl,
    } = this.state;
    const effectivePhotos = isShowingLoginUpsell
      ? BLURRED_UPSELL_PHOTOS
      : photos;
    const hasNoPhotos = effectivePhotos.length === 0;
    const hasTooFewPhotosForCarousel =
      effectivePhotos.length < MIN_CAROUSEL_PHOTOS;
    const imageUrlsToPreload = photos.slice(0, imagePreloadCount);
    /* If all photos in the first batch fail to load (usually indicative of a blocking browser extension) */
    const firstBatchPhotosFailedToLoad =
      firstImagesPreloaded &&
      imageUrlsToPreload.length > 0 &&
      imageUrlsToPreload.map((url) => !!preloadedImages[url]).indexOf(true) ===
        -1;
    const isWaitingOnImagePreloading =
      photos.length > 0 &&
      imagePreloadCount > 0 &&
      !firstImagesPreloaded &&
      !hasTooFewPhotosForCarousel;
    const isShowingLoadingIndicator =
      !isAppMounted ||
      (isAppMounted && (photosStatusIsInit || photosStatusIsLoading)) ||
      (isAppMounted && isWaitingOnImagePreloading);
    const fullAddress = propertyDetails && propertyDetails.fullAddress;
    const address = propertyDetails
      ? formatPropertyDetailsForAnalyticsAddress(propertyDetails)
      : null;

    return (
      addressSlug &&
      propertyDetails && (
        <section data-hc-name="carousel-section" className={theme.CarouselRow}>
          {isAppMounted && imageUrlsToPreload.length > 0 && (
            <ImagePreloader
              alt={`Photos of ${fullAddress}`}
              urls={imageUrlsToPreload}
              onLoad={this.handleFirstImagesPreloaded}
            />
          )}
          {isShowingLoadingIndicator ? (
            <LoadingSection theme={theme} isLoading />
          ) : hasNoPhotos || firstBatchPhotosFailedToLoad ? (
            <PhotoPlaceholderContainer
              addressSlug={addressSlug}
              mapTileSize={mapTileSize}
              propertyStatus={propertyDetails.status}
              fullAddress={propertyDetails.fullAddress}
              streetViewLocation={{
                latitude: propertyDetails.latitude,
                longitude: propertyDetails.longitude,
              }}
            />
          ) : hasTooFewPhotosForCarousel ? (
            <div
              data-testid="carousel-single-photo"
              className={theme.SinglePhoto}
              style={{ backgroundImage: `url("${effectivePhotos[0]}")` }}
            />
          ) : (
            <Slider
              key={`slider${resizeCount}`}
              theme={theme}
              uIdField="data-url"
              infinityMode
              onClickNext={() => handleReportClickNext(address!)}
              onClickPrev={() => handleReportClickPrev(address!)}
              incrementByComponentWidth={!useFullWidthImage}
              incrementAnimationDuration={SLIDER_INCREMENT_ANIMATION_DURATION}
              /* When wanting to show the upsell ad and showing full-width images, we want to
               * let the user increment to the next photo, where they'll see the upsell ad */
              hideRightArrow={isShowingLoginUpsell && !useFullWidthImage}
              hideLeftArrow={isShowingLoginUpsell && !useFullWidthImage}
              onTap={this.handleTogglePhotoModal}
            >
              {effectivePhotos
                .map((url, idx) => {
                  /* Will be `false` if img failed to load */
                  const PreloadedImage = preloadedImages[url];
                  let SliderItem: JSX.Element | false;

                  /* On certain screen sizes we're using full-width divs with background images */
                  if (useFullWidthImage) {
                    SliderItem = (
                      <div
                        key={url}
                        data-url={url}
                        role="link"
                        aria-label={getPhotoAriaLabel(
                          idx + 1,
                          effectivePhotos.length
                        )}
                        className={theme.FullWidthSliderItem}
                        style={{ backgroundImage: `url('${url}')` }}
                      >
                        {BLURRED_UPSELL_PHOTOS.indexOf(url) > -1 && (
                          <LoginUpsell
                            className={theme.LoginUpsellFullWidth}
                            theme={theme}
                            onClickLogin={this.handleLoginUpsellClick}
                          />
                        )}
                      </div>
                    );
                    /* If not using full-width images and the current photo is an upsell photo, use
                     * an <img> element for it */
                  } else if (
                    isShowingLoginUpsell &&
                    BLURRED_UPSELL_PHOTOS.indexOf(url) > -1
                  ) {
                    SliderItem = (
                      <img
                        alt={'Hidden - login to see additional property photos'}
                        key={url}
                        data-url={url}
                        src={url}
                        data-get-width-using-onload
                        className={theme.Photo}
                      />
                    );
                  } else {
                    /** If the image is one of the preloaded ones (first 3 images), it will be an object */
                    /** If the image is not one of the preloaded ones, it will be undefined */
                    /** If the image is false, then the image failed to load - don't render it */
                    SliderItem = PreloadedImage !== false && (
                      <TransformedImage
                        role="link"
                        alt={getPhotoAriaLabel(idx + 1, effectivePhotos.length)}
                        key={url}
                        data-url={url}
                        src={url}
                        className={theme.Photo}
                        transforms={[autoCrop()]}
                        data-get-width-using-onload
                      />
                    );
                  }
                  return SliderItem;
                })
                .filter((Img) => Img)}
            </Slider>
          )}
          {shouldShowDesktopPhotosModal ? (
            /* Active status controlled via Redux state set via a URL param */
            <CarouselDialogContainer
              initialSlideUrl={initialModalPhotoUrl}
              photoSize={photoSize}
              addressSlug={propertyDetails.slug}
            />
          ) : (
            /* Active status controlled via Redux state set via a URL param */
            <PhotoListModalContainer
              isAddedToWatchList={isAddedToWatchList}
              propertyDetails={propertyDetails}
              selectedPhotoUrl={initialModalPhotoUrl}
              photoSize={photoSize}
            />
          )}
          {isShowingLoginUpsell && !useFullWidthImage && (
            <LoginUpsell
              theme={theme}
              onClickLogin={this.handleLoginUpsellClick}
            />
          )}
          {overlay && (
            <div
              className={theme.Overlay}
              data-hc-name="mls-logo"
              style={{
                backgroundImage: `url(${overlay})`,
              }}
            />
          )}
        </section>
      )
    );
  }
}

const ThemedCarouselRow = themr('CarouselRow', defaultTheme)(CarouselRow);
export default ThemedCarouselRow;
