import { FeedbackData } from '@client/store/types/feedback';

export const FEEDBACK_PAGE_LOADED = 'FEEDBACK_PAGE_LOADED';
export const FEEDBACK_FORM_SUBMIT = 'FEEDBACK_FORM_SUBMIT';
export const FEEDBACK_FORM_SUBMIT_SUCCESS = 'FEEDBACK_FORM_SUBMIT_SUCCESS';
export const FEEDBACK_FORM_SUBMIT_ERROR = 'FEEDBACK_FORM_SUBMIT_ERROR';
export const CLEAR_FEEDBACK_SUBMIT_STATUS = 'CLEAR_FEEDBACK_SUBMIT_STATUS';

type FeedbackPageLoaded = {
  type: typeof FEEDBACK_PAGE_LOADED;
};
export const feedbackPageLoaded = (): FeedbackPageLoaded => ({
  type: FEEDBACK_PAGE_LOADED,
});

export type SubmitFeedbackFormAction = {
  type: typeof FEEDBACK_FORM_SUBMIT;
  payload: {
    data: FeedbackData;
  };
};
export const submitFeedbackForm = (
  data: FeedbackData
): SubmitFeedbackFormAction => ({
  type: FEEDBACK_FORM_SUBMIT,
  payload: {
    data,
  },
});

type SubmitFeedbackFormSuccess = {
  type: typeof FEEDBACK_FORM_SUBMIT_SUCCESS;
};
export const submitFeedbackFormSuccess = (): SubmitFeedbackFormSuccess => ({
  type: FEEDBACK_FORM_SUBMIT_SUCCESS,
});

type ClearFeedbackSubmitStatus = {
  type: typeof CLEAR_FEEDBACK_SUBMIT_STATUS;
};
export const clearFeedbackSubmitStatus = (): ClearFeedbackSubmitStatus => ({
  type: CLEAR_FEEDBACK_SUBMIT_STATUS,
});

type SubmitFeedbackFormError = {
  type: typeof FEEDBACK_FORM_SUBMIT_ERROR;
  payload: string;
};
export const submitFeedbackFormError = (
  error: string
): SubmitFeedbackFormError => ({
  type: FEEDBACK_FORM_SUBMIT_ERROR,
  payload: error,
});

export type FeedbackAction =
  | FeedbackPageLoaded
  | SubmitFeedbackFormAction
  | SubmitFeedbackFormSuccess
  | ClearFeedbackSubmitStatus
  | SubmitFeedbackFormError;
