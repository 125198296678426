import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { useSelector } from 'react-redux';

import PillButton from '@client/components/generic/PillButton';
import defaultTheme from '@client/css-modules/YourTeam/YourTeamTriggerButton.css';
import { getYourTeamCustomButtonText } from '@client/store/selectors/cobranding.selectors';

import { useIsLOTabDisabled } from '@client/hooks/your-team.hooks';
import WellsFargoLogo from '@client/inline-svgs/cobrand/wellsfargo/wells-fargo-logo';
import { selectConstrainedToPlaceHasNoMLSCoverage } from '@client/store/selectors/search.selectors';
import { YourTeamModalType } from '@client/store/types/your-team';
import classNames from 'classnames';

type Props = {
  theme: Theme;
  openYourTeamModal: (modalType: YourTeamModalType) => void;
  dataEventName?: string;
  dataParentEventName?: string;
};

function YourTeamTriggerButtonWells({ theme, openYourTeamModal }: Props) {
  const customButtonText = useSelector(getYourTeamCustomButtonText);
  const isLOTabDisabled = useIsLOTabDisabled();
  const isLOTabEnabled = !isLOTabDisabled;
  const shouldAdjustButtonPlacement = useSelector(
    selectConstrainedToPlaceHasNoMLSCoverage
  );

  return (
    <PillButton
      className={classNames(theme.YourTeamTriggerButtonWellsFargo, {
        [theme.YourTeamTriggerButtonWellsFargoAdjusted]:
          shouldAdjustButtonPlacement,
      })}
      dataHcName="your-team-button"
      customBackgroundColor="#ffffff"
      customTextColor="#4a4a4a"
      onClick={() => openYourTeamModal(isLOTabEnabled ? 'lo' : 'agent')}
    >
      <WellsFargoLogo className={theme.ModalButtonSVG_Wells} />{' '}
      {customButtonText ? customButtonText : 'Have Questions?'}
    </PillButton>
  );
}

export default themr(
  'YourTeamTriggerButtonWells',
  defaultTheme
)(YourTeamTriggerButtonWells);
