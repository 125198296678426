import { v4 as uuidv4 } from 'uuid';

const VISIT_ID = 'visitID' as 'visitID';
export const getVisitId = () => {
  let visitID: string;
  visitID = window.sessionStorage.getItem(VISIT_ID) as string;

  if (!visitID) {
    visitID = uuidv4();
    window.sessionStorage.setItem(VISIT_ID, visitID);
  }
  return visitID;
};
