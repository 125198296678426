import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import WatchListActionButton from '@client/components/WatchListActionButton';
import {
  ensureLoginThenAddToWatchList,
  removeFromWatchList,
  fetchIsPropertyInWatchList,
} from '@client/store/actions/watchlist.actions';
import { getIsLoggedIn } from '@client/store/selectors/auth.selectors';
import { AnalyticsEventAddress } from '@client/store/types/analytics';
import { getWatchlistPageConfig } from '@client/store/selectors/cobranding.selectors';

type OwnProps = {
  shouldHandleCheckingForWatchListStatus: boolean;
  isAddedToWatchList?: boolean;
};

const mapStateToProps = (state, ownProps: OwnProps) => {
  return {
    isAddedToWatchList: !!ownProps.isAddedToWatchList,
    isLoggedIn: getIsLoggedIn(state),
    shouldHandleCheckingForWatchListStatus:
      ownProps.shouldHandleCheckingForWatchListStatus,
    watchlistPageConfig: getWatchlistPageConfig(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleAddToWatchListWithEnsureLogin: (
    analyticsAddress: AnalyticsEventAddress
  ) => dispatch(ensureLoginThenAddToWatchList(analyticsAddress)),
  handleRemoveFromWatchList: (slug) => dispatch(removeFromWatchList(slug)),
  getIsPropertyInWatchList: (slug) =>
    dispatch(fetchIsPropertyInWatchList(slug))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WatchListActionButton);
