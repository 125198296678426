import React from 'react';
import { Map, MapboxGeoJSONFeature } from 'mapbox-gl';
import { debounce, uniqBy } from 'lodash';
import { MapAndAPIContext } from '@hc/hcmaps-mapboxgl/lib/context/map-and-api-context';

import { key } from '@client/utils/component.utils';
import theme from '@client/css-modules/SymbolsLayerAccessibilityControl.css';
import { LatLngObject } from '@client/store/types/maps';

type Props = {
  layerIds: string[];
  uniqBy?: (feature: MapboxGeoJSONFeature) => string | number;
  featureFilter: (feature: MapboxGeoJSONFeature) => boolean;
  getLatLng?: (feature: MapboxGeoJSONFeature) => LatLngObject | null;
  getDataAttr?: (feature: MapboxGeoJSONFeature) => [string, string];
  getAccessibilityLabel: (feature: MapboxGeoJSONFeature) => string;
  indicatorSize?: {
    width: number;
    height: number;
  };
  indicatorTranslateRelativeToAnchor: {
    x: string;
    y: string;
  };
  onEnterOrSpaceKeypress?: (
    e: KeyboardEvent,
    feature: MapboxGeoJSONFeature
  ) => void;
};

const defaultIndicatorSize = { width: 40, height: 40 };
const defaultIndicatorTranslate = { x: '-50%', y: '-50%' };

/* Typical location of Point feature coordinates in Gaia responses */
const defaultGetLatLng = (feature: any): LatLngObject | null => {
  return feature && feature.properties
    ? { lat: feature.properties.lat, lng: feature.properties.lon }
    : null;
};

/**
 * Map control to make features within the passed-in `layerIds` screenreader accessible
 * Ported from https://github.com/mapbox/mapbox-gl-accessibility and modified heavily
 */
class SymbolsLayerAccessibilityControl {
  constructor(options: Props) {
    this.options = options;
  }

  options: Props;
  map: Map | null = null;
  features: Array<
    { marker?: HTMLButtonElement } & MapboxGeoJSONFeature
  > | null = null;
  container: HTMLDivElement | null = null;

  clearMarkers = () => {
    if (this.features) {
      this.features.forEach((feature) => {
        if (feature.marker && this.map) {
          this.map.getCanvasContainer().removeChild(feature.marker);
          delete feature.marker;
        }
      });
    }
  };

  queryFeatures = () => {
    this._debouncedQueryFeatures.cancel();
    this.clearMarkers();

    if (!this.map) {
      throw new Error('Map not available when attempting to query features');
    }

    this.features = this.map.queryRenderedFeatures(undefined, {
      layers: this.options.layerIds,
    });
    this.features = this.options.uniqBy
      ? uniqBy(this.features, this.options.uniqBy)
      : this.features;
    this.features.forEach((feature) => {
      if (this.options.featureFilter && !this.options.featureFilter(feature)) {
        return;
      }
      if (!this.map) {
        throw new Error(
          'Map not available when attempting to iterate features'
        );
      }

      let { width, height } =
        this.options.indicatorSize || defaultIndicatorSize;
      const label = this.options.getAccessibilityLabel(feature);

      feature.marker = document.createElement('button');

      if (label) {
        feature.marker.setAttribute('aria-label', label);
        feature.marker.setAttribute('title', label);
      }
      if (this.options.getDataAttr) {
        feature.marker.setAttribute(...this.options.getDataAttr(feature));
      }
      feature.marker.setAttribute('role', 'link');
      feature.marker.setAttribute('tabindex', '0');
      feature.marker.style.display = 'block';

      const effectiveGetLatLng = this.options.getLatLng || defaultGetLatLng;
      const latLngObject = effectiveGetLatLng(feature);
      const effectiveTranslate =
        this.options.indicatorTranslateRelativeToAnchor ||
        defaultIndicatorTranslate;

      if (!latLngObject) {
        return;
      }
      const position = this.map.project([
        latLngObject.lng,
        latLngObject.lat,
      ] as [number, number]);

      feature.marker.style.width = `${width}px`;
      feature.marker.style.height = `${height}px`;
      feature.marker.style.transform = `translate(${effectiveTranslate.x}, ${effectiveTranslate.y}) translate(${position.x}px, ${position.y}px)`;
      feature.marker.className = theme.AccessibleMarkerIndicator;

      if (this.options.onEnterOrSpaceKeypress) {
        /* This should be garbage-collected when the feature is removed from the map */
        feature.marker.addEventListener('keydown', (e) => {
          if (
            this.options.onEnterOrSpaceKeypress &&
            (key.isReturn(e.key) || key.isSpace(e.key))
          ) {
            this.options.onEnterOrSpaceKeypress(e, feature);
          }
        });
      }

      this.map.getCanvasContainer().appendChild(feature.marker);
    });
  };

  _debouncedQueryFeatures = debounce(this.queryFeatures, 100);

  _movestart = () => {
    this._debouncedQueryFeatures.cancel();
    this.clearMarkers();
  };

  _render = () => {
    if (this.map && !this.map.isMoving()) {
      this._debouncedQueryFeatures();
    }
  };

  onAdd(map: Map) {
    this.map = map;
    this.map.on('movestart', this._movestart);
    this.map.on('moveend', this._render);
    this.map.on('render', this._render);
    this._debouncedQueryFeatures();

    this.container = document.createElement('div');
    return this.container;
  }

  onRemove() {
    if (this.container) {
      this.container.parentNode &&
        this.container.parentNode.removeChild(this.container);
    }
    if (this.map) {
      this.map.off('movestart', this._movestart);
      this.map.off('moveend', this._render);
      this.map.off('render', this._render);
      this.map = null;
    }
    this._debouncedQueryFeatures.cancel();
    this.map = null;
  }
}

class AccessibilityControl extends React.PureComponent<Props> {
  control: SymbolsLayerAccessibilityControl | null = null;

  static contextType = MapAndAPIContext;
  declare context: React.ContextType<typeof MapAndAPIContext>;

  componentDidMount() {
    const { map } = this.context;
    const {
      uniqBy,
      featureFilter,
      getLatLng,
      getAccessibilityLabel,
      getDataAttr,
      indicatorSize,
      indicatorTranslateRelativeToAnchor,
      onEnterOrSpaceKeypress,
      layerIds,
    } = this.props;

    this.control = new SymbolsLayerAccessibilityControl({
      uniqBy,
      featureFilter,
      getLatLng,
      getAccessibilityLabel,
      getDataAttr,
      indicatorSize,
      indicatorTranslateRelativeToAnchor,
      onEnterOrSpaceKeypress,
      layerIds,
    });
    if (map && this.control) {
      map.addControl(this.control);
    }
  }

  componentWillUnmount() {
    const { map } = this.context;

    if (map && map.getStyle() && this.control) {
      map.removeControl(this.control);
    }
  }

  render() {
    return null;
  }
}

export default AccessibilityControl;
