import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { LngLatBounds } from 'mapbox-gl';

import { LayerMetric } from '@client/store/map-constants';
import MapLayersControl from '@client/components/MapLayersControl';
import {
  eventType,
  ParentEventType,
  reportEvent,
} from '@client/store/actions/analytics.actions';
import { fetchMapLegendBreaks } from '@client/store/actions/map-legend-breaks.actions';

import { getMapLegendBreaks } from '@client/store/selectors/map-legend-breaks.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { getMapLayerGroupLabelsWithDataAttribution } from '@client/store/selectors/global-ui.selectors';

const mapStateToProps = (state) => {
  return {
    isSchoolDataEnabled: getIsFeatureEnabled('school_data')(state),
    isMapNotificationAboveYourTeamButton:
      getIsFeatureEnabled('your_team')(state),
    legendBreaks: getMapLegendBreaks(state),
    layerGroupLabelsWithDataAttribution:
      getMapLayerGroupLabelsWithDataAttribution(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleGetMapLayerLegendBreaks: (
    bounds: LngLatBounds,
    zoom: number,
    metric: LayerMetric
  ) => {
    const southWest = bounds.getSouthWest();
    const northEast = bounds.getNorthEast();
    dispatch(fetchMapLegendBreaks({ southWest, northEast, zoom, metric }));
  },
  reportHeatmapCrimeClick: () => dispatch(reportEvent('click_heatmap_crime')),
  reportHeatmapSchoolClick: () => dispatch(reportEvent('click_heatmap_school')),
  reportHeatmapPriceClick: () => dispatch(reportEvent('click_heatmap_price')),
  reportHeatmapForecastClick: () =>
    dispatch(reportEvent('click_heatmap_forecast')),
  reportEvent: (event: eventType, parentEvent: ParentEventType | null) =>
    dispatch(reportEvent(event, parentEvent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapLayersControl);
