import { call, select, put } from 'redux-saga/effects';

import { PropertyLookupWithAddressRequired } from '@client/store/types/property';
import {
  FETCH_WATCHLIST,
  ADD_TO_WATCHLIST,
  fetchWatchListSuccess,
  REMOVE_FROM_WATCHLIST,
  FETCH_WATCHLIST_ITEM,
  fetchWatchListItemError,
  fetchWatchListItemSuccess,
  addToWatchList,
  ENSURE_LOGIN_THEN_ADD_TO_WATCHLIST,
  FETCH_IS_PROPERTY_IN_WATCHLIST,
  fetchIsPropertyInWatchListSuccess,
  addToWatchListSuccess,
  fetchWatchList,
  FETCH_WATCHLIST_IF_NOT_IN_STATE,
} from '@client/store/actions/watchlist.actions';
import { ensureLoggedInThen } from '@client/store/sagas/auth.saga';
import { consumerApiClient } from '@client/services/consumer-api-client';
import { graphQLApiClient } from '@client/services/graphql-api-client';
import { PROPERTY_SEE_LOAN_OPTIONS } from '@client/store/actions/property-details.actions';
import { watchEvery, watchLeading } from '@client/utils/saga.utils';
import { updateUserProfile } from '@client/store/actions/auth.actions';
import { SETTINGS_KEYS, STATUSES } from '@client/store/constants';
import { setActiveNotification } from '@client/store/actions/modals.actions';
import { getSharePropertyShouldShowNotification } from '@client/store/selectors/share-property.selectors';
import { getAllUserSettings } from '@client/store/selectors/auth.selectors';
import {
  getWatchListStatus,
  getWatchListItems,
} from '@client/store/selectors/watchlist.selectors';

function* fetchWatchListDataIfNotInState() {
  const watchListStatus = yield select(getWatchListStatus);
  if (watchListStatus !== STATUSES.SUCCESS) {
    yield put(fetchWatchList());
  }
}

function* fetchWatchListData() {
  const watchlistData = yield call([
    consumerApiClient,
    consumerApiClient.getWatchListData,
  ]);
  yield put(fetchWatchListSuccess(watchlistData));
}

function* ensureLoginThenAddToWatchList(action) {
  yield call(ensureLoggedInThen, addToWatchList, action.payload);
}

export function* addToWatchListSaga(action) {
  yield call(
    [consumerApiClient, consumerApiClient.addToWatchList],
    action.payload.slug
  );
  yield put(addToWatchListSuccess(action.payload));

  /* shared property triggers addToWatchListSaga and so this is to prevent double notifications */
  const isShowingSharedPropertyNotification = (yield select(
    getSharePropertyShouldShowNotification
  )) as ReturnType<typeof getSharePropertyShouldShowNotification>;
  const userEmailNotificationSettings = (yield select(
    getAllUserSettings
  )) as ReturnType<typeof getAllUserSettings>;
  const shouldTurnOnSavedPropertyEmailNotification =
    !isShowingSharedPropertyNotification &&
    !userEmailNotificationSettings.send_email;

  if (shouldTurnOnSavedPropertyEmailNotification) {
    yield put(updateUserProfile({ [SETTINGS_KEYS.SEND_EMAIL]: true }, true));
    yield put(
      setActiveNotification('saved-property-email-setting-notification')
    );
  }
}

function* fetchWatchListEntryForProperty(action) {
  const { slug } = action.payload;
  const watchList = yield select(getWatchListItems);

  const watchListStatus = yield select(getWatchListStatus);
  let isInWatchList = watchList.some(
    (watchListItem) => watchListItem.slug === slug
  );

  if (watchListStatus !== STATUSES.SUCCESS) {
    const result = yield call(
      [consumerApiClient, consumerApiClient.getWatchListEntryForProperty],
      slug
    );
    isInWatchList = !!result.address_id;
  }

  yield put(
    fetchIsPropertyInWatchListSuccess({
      isInWatchList,
      slug,
    })
  );
}

function* removeFromWatchList(action) {
  yield call(
    [consumerApiClient, consumerApiClient.removeFromWatchList],
    action.payload.slug
  );
}

function* fetchWatchListItemPropertyDetails(action) {
  let addressId = action.payload.addressId;
  // addressId has to be a string
  if (typeof addressId === 'number') {
    addressId = addressId.toString();
  }

  if (addressId) {
    try {
      const propertyLookup = (yield call(
        [graphQLApiClient, graphQLApiClient.getWatchListItemPropertyDetails],
        addressId
      )) as PropertyLookupWithAddressRequired;
      yield put(fetchWatchListItemSuccess(addressId, propertyLookup));
    } catch (e: any) {
      /* this is to handle an edge case where property graph returns
        null as data and frontend is in loading state forever with error in the console
      */
      yield put(
        fetchWatchListItemError(
          addressId,
          'Watch list item property details missing'
        )
      );
    }
  }
}

function* updateWatchListPropertyUpdatedDate(action) {
  yield call(
    [consumerApiClient, consumerApiClient.patchWatchListPropertyUpdatedDate],
    action.payload.slug
  );
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [FETCH_WATCHLIST]: fetchWatchListData,
    [ENSURE_LOGIN_THEN_ADD_TO_WATCHLIST]: ensureLoginThenAddToWatchList,
    [ADD_TO_WATCHLIST]: addToWatchListSaga,
    [REMOVE_FROM_WATCHLIST]: removeFromWatchList,
    [FETCH_WATCHLIST_ITEM]: fetchWatchListItemPropertyDetails,
    [PROPERTY_SEE_LOAN_OPTIONS]: updateWatchListPropertyUpdatedDate,
    [FETCH_WATCHLIST_IF_NOT_IN_STATE]: fetchWatchListDataIfNotInState,
  });
  watchLeading(sagaMiddleware, {
    [FETCH_IS_PROPERTY_IN_WATCHLIST]: fetchWatchListEntryForProperty,
  });
};
