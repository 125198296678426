import { configureStore } from '@reduxjs/toolkit';
import initRouter from '@src/redux-saga-router-plus';
import { Store } from 'redux';
import createDebounce from 'redux-debounced';
import createSagaMiddleware from 'redux-saga';
import createSentryMiddleware from 'redux-sentry-middleware';
import * as Sentry from 'sentry-isomorphic';

import { getRoutesConfig } from '@client/routes';
import history from '@client/routes/history';
import routerOptions from '@client/routes/options';
import toastMiddleware from '@client/store/middleware/toast.middleware';
import { PartialReduxState } from '@client/store/types/redux-state';
import { isClientSide } from '@client/utils/isomorphic.utils';
import { localStorageUtil } from '@client/utils/local-storage.utils';
import reducers from './reducers';
import { setupReduxActionsBlacklist } from './redux-actions-blacklist';
import runSagas from './sagas';

/**
 * Setup the store.  Runs both during SSR and on client
 */
export default (initialState: PartialReduxState): Store => {
  if (isClientSide) {
    let initialLocationKey = (history as any).location.key;
    let isViewingInitialPage = true;

    (history as any).listen((location, action) => {
      /* If we replace state on the first route change, the initial key in the stack changes */
      if (isViewingInitialPage && action === 'REPLACE') {
        initialLocationKey = location.key;
      }

      isViewingInitialPage = initialLocationKey === location.key;
    });

    /**
     * Chase-specific Native App integration "back" functionality
     */
    const lenderIntegrationFunctions = {
      shouldNativeHandleButtonPressed: function () {
        const locationKey = (history as any).location.key;
        /* If we're on the initial item in the browser history stack, the parent native app will exit
         * out of our web view. If we're not on the initial item in the stack, the web app will go 'back'
         * similar to pressing the back button in the browser. */
        const shouldNativeAppExitWebview = locationKey === initialLocationKey;
        if (!shouldNativeAppExitWebview) {
          (history as any).goBack();
        }

        return shouldNativeAppExitWebview;
      },
      showBackArrow: function () {
        return true;
      },
      isUsingAppkitJsBridge: function () {
        return true;
      },
    };

    /* Required for lender integration.  This method is executed, by them, upon pressing the 'back'
     * button in the native app's ever-present navigation bar that sits above our web view */
    (window as any).require = (path) => {
      if (path === 'common/lib/jsBridge') {
        return lenderIntegrationFunctions;
      } else {
        return null;
      }
    };

    (window as any).NativeToThirdPartyWVChaseJSBridgeHandler =
      lenderIntegrationFunctions;

    /**
     * GA Native App integration "back" functionality (separated out for clarity/stability)
     */
    const shouldNativeAppHandleBackEvent = function () {
      const locationKey = (history as any).location.key;
      /* If we're on the initial item in the browser history stack, the parent native app will exit
       * out of our web view. If we're not on the initial item in the stack, the web app will go 'back'
       * similar to pressing the back button in the browser. */
      const shouldNativeAppExitWebview = locationKey === initialLocationKey;
      if (!shouldNativeAppExitWebview) {
        (history as any).goBack();
      }

      return shouldNativeAppExitWebview;
    };

    if (typeof window.JavascriptBridgeHandler === 'undefined') {
      window.JavascriptBridgeHandler = {
        shouldNativeAppHandleBackEvent,
      };
    } else {
      window.JavascriptBridgeHandler.shouldNativeAppHandleBackEvent =
        shouldNativeAppHandleBackEvent;
    }
  }

  const routeConfig = getRoutesConfig({
    isHomepageEnabled: !!initialState.enabledFeatures?.includes('homepage'),
  });
  const sagaMiddleware = createSagaMiddleware();
  const debounceMiddleware = createDebounce();

  /** Setup store */
  let middleware = [sagaMiddleware, toastMiddleware, debounceMiddleware];
  if (process.env.LOCALHOST !== 'true' || process.env.FORCE_SENTRY) {
    middleware.unshift(
      createSentryMiddleware(Sentry, {
        stateTransformer: (state) => undefined,
      })
    );
  }
  if (isClientSide) {
    const analyticsMiddleware = require('./middleware/analytics.middleware');
    middleware.push(analyticsMiddleware.default(initialState));
    middleware.push(analyticsMiddleware.raw(initialState));
  }

  const shouldEnableDevTools = !!(
    (process.env.NODE_ENV === 'development' ||
      (isClientSide &&
        /* eslint-disable-next-line custom/explain-localstorage */
        localStorageUtil.getItem('enableReduxDevtools'))) &&
    (global as any).__REDUX_DEVTOOLS_EXTENSION__
  );

  const store = configureStore({
    reducer: reducers,
    preloadedState: initialState,
    middleware,
    devTools: shouldEnableDevTools && setupReduxActionsBlacklist(),
  });

  if (isClientSide) {
    runSagas(sagaMiddleware);
    initRouter(history!, routeConfig, sagaMiddleware, routerOptions);
  }

  if ((module as any).hot) {
    // Enable Webpack hot module replacement for reducers
    (module as any).hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};
