import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { getIsLoggedIn } from '@client/store/selectors/auth.selectors';

export const AuthStatusScreenReader = () => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const [status, setStatus] = useState('');
  const statusRef = useRef(status);
  statusRef.current = status;

  /**
   * Prevent the screen reader from announcing the same login status more than once by resetting the text content to an empty string using a timer
   *
   * We use the timer to also update the success login state to trigger a read on Safari, which does not consistently read aria-live content on page load. (Reads as expected on Chrome and on log outs)
   *
   * Using the ref to access the login state between new closure re-renders that occur asynchronously with setTimeouts
   */
  useEffect(() => {
    let resetTimer: ReturnType<typeof setTimeout>;
    if (isLoggedIn) {
      let statusTimer = setTimeout(() => {
        setStatus('You are logged in');
        resetTimer = setTimeout(() => {
          setStatus('');
        }, 10000);
      }, 5000);
      return () => {
        clearTimeout(statusTimer);
        clearTimeout(resetTimer);
      };
    } else {
      setStatus('You are logged out');
      resetTimer = setTimeout(() => {
        setStatus('');
      }, 8000);
      return () => {
        clearTimeout(resetTimer);
      };
    }
  }, [isLoggedIn]);

  return (
    <span
      style={{
        height: '1px',
        overflow: 'hidden',
        position: 'absolute',
        whiteSpace: 'nowrap',
        width: '1px',
      }}
      id="authStatusScreenReader"
      role="status"
      aria-live="polite"
    >
      {statusRef.current}
    </span>
  );
};
