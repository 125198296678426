export const OPT_IN_BUYER_EMAILS = 'OPT_IN_BUYER_EMAILS';
export const USER_INVITE_AGREE_TO_TERMS = 'USER_INVITE_AGREE_TO_TERMS';

export type OptInBuyerEmails = {
  type: typeof OPT_IN_BUYER_EMAILS;
};

export const optInBuyerEmails = (): OptInBuyerEmails => ({
  type: OPT_IN_BUYER_EMAILS,
});

export type UserInviteAgreeToTerms = {
  type: typeof USER_INVITE_AGREE_TO_TERMS;
};

export const userInviteAgreeToTerms = (): UserInviteAgreeToTerms => ({
  type: USER_INVITE_AGREE_TO_TERMS,
});
