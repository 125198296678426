import classNames from 'classnames';
import React from 'react';

import defaultTheme from '@client/css-modules/FlatButton.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';

export type FlatButtonProps = {
  onClick: (e: React.MouseEvent | React.KeyboardEvent) => void;
  className?: string;
  icon?: JSX.Element;
  label: JSX.Element | string;
  disabled?: boolean;
  buttonStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  theme: Theme;
};

/**
 * A simple, Flat Button
 */
const FlatButton = React.forwardRef(
  (
    {
      onClick,
      className,
      icon,
      label,
      disabled,
      theme,
      labelStyle = {},
      buttonStyle = {},
      ...rest
    }: FlatButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const { flatButtonColor } = useCobrandStyles();
    return (
      <button
        type="button"
        disabled={disabled}
        onClick={onClick}
        onKeyDown={onEnterOrSpaceKey(onClick)}
        ref={ref}
        className={classNames(theme.FlatButton, className)}
        style={buttonStyle}
        {...rest}
      >
        <span
          className={theme.FlatButtonLabel}
          style={{
            color: flatButtonColor,
            ...labelStyle,
          }}
        >
          {label}
        </span>
        {icon && <span className={theme.FlatButtonIcon}>{icon}</span>}
      </button>
    );
  }
);

export default themr('FlatButton', defaultTheme)(FlatButton);
