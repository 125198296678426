import { put, call } from 'redux-saga/effects';

import {
  FEEDBACK_FORM_SUBMIT,
  submitFeedbackFormSuccess,
  submitFeedbackFormError,
  SubmitFeedbackFormAction,
} from '@client/store/actions/feedback.actions';
import { watchEvery } from '@client/utils/saga.utils';
import { consumerApiClient } from '@client/services/consumer-api-client';

function* submitFeedbackFormSaga(action: SubmitFeedbackFormAction) {
  const emailInfo = action.payload.data;
  const isValid =
    emailInfo?.email && emailInfo?.message && emailInfo?.issue_type;

  if (isValid) {
    try {
      yield call(
        [consumerApiClient, consumerApiClient.submitFeedback],
        emailInfo
      );
      yield put(submitFeedbackFormSuccess());
    } catch (error: any) {
      yield put(submitFeedbackFormError(error));
      // Rethrow for Sentry
      throw error;
    }
  } else {
    yield put(submitFeedbackFormError('Form submission did not contain data'));
  }
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [FEEDBACK_FORM_SUBMIT]: submitFeedbackFormSaga,
  });
};
