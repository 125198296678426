import { all, call, put, take, fork, cancel } from 'redux-saga/effects';
import {
  HistoryType,
  RouteChangeType,
  RouteDefsAllType,
  RouteFnType,
  RouterOptionsType,
  RouteType,
} from './types';
import {
  routeChange,
  routeUpdate,
  routerError,
  ActionRouterErrorType,
} from './actions';

import { buildErrorMetadata, buildQueryObj } from './utils';
import buildRouteMatcher from './buildRouteMatcher';
import createHistoryChannel from './createHistoryChannel';
import { View } from '@client/routes/constants';

const MOCK_ROUTE = {
  view: View.HOMEPAGE,
  params: {},
  query: {},
  path: '/foo/bar',
};

export const isFunc = (test: any): boolean => typeof test === 'function';

export const redirectFactory = (redirect: RouteChangeType) =>
  function* () {
    yield put(routeChange(redirect));
  };

export const callbackFactory = (fn: RouteFnType) =>
  function* (route: RouteType) {
    return yield call(fn, route);
  };

export const error404 = (): ActionRouterErrorType =>
  routerError(404, null, null, buildErrorMetadata(MOCK_ROUTE));

export default function (
  history: HistoryType,
  routes: RouteDefsAllType,
  options: RouterOptionsType = {}
) {
  // Setup login/logout callbacks
  let isLoggedInCheck;
  let handlerLoginRequiredFailed;
  let handlerLogoutRequiredFailed;
  let handlerError404;
  if (options.isLoggedInFn && isFunc(options.isLoggedInFn)) {
    isLoggedInCheck = callbackFactory(options.isLoggedInFn);
  }

  // Support a route redirect or a function call option for loginRequired failure
  if (options.loginRequiredRedirect && options.loginRequiredRedirect.view) {
    handlerLoginRequiredFailed = redirectFactory(options.loginRequiredRedirect);
  } else if (options.loginRequiredFn && isFunc(options.loginRequiredFn)) {
    handlerLoginRequiredFailed = callbackFactory(options.loginRequiredFn);
  }

  // Support a route redirect or a function call option for logoutRequired failure
  if (options.logoutRequiredRedirect && options.logoutRequiredRedirect.view) {
    handlerLogoutRequiredFailed = redirectFactory(
      options.logoutRequiredRedirect
    );
  } else if (options.logoutRequiredFn && isFunc(options.logoutRequiredFn)) {
    handlerLogoutRequiredFailed = callbackFactory(options.logoutRequiredFn);
  }

  // Support a route redirect or a function call option for 404 errors
  if (options.error404Redirect && options.error404Redirect.view) {
    handlerError404 = redirectFactory(options.error404Redirect);
  } else if (options.error404Fn && isFunc(options.error404Fn)) {
    handlerError404 = callbackFactory(options.error404Fn);
  }

  const routeMatcher = buildRouteMatcher(routes, options);

  function* router() {
    const historyChannel = yield call(createHistoryChannel, history);
    let effects: any[] = [];
    // While loop is executed whenever the browser location changes
    while (true) {
      const location = yield take(historyChannel);
      const path = location.pathname;
      const query = buildQueryObj();
      const match = routeMatcher.match(path);
      if (match) {
        if (effects.length) {
          for (let i = 0; i < effects.length; i++) {
            yield cancel(effects[i]);
          }
          effects = [];
        }
        const { routeDef, params } = match;
        const { view, loginRequired, logoutRequired } = routeDef;
        const routeObj = {
          view,
          params,
          query,
          path,
          loginRequired,
          logoutRequired,
        };
        // Login/Logout Checks
        const loginCheckPassed = yield call(loginCheck, routeObj);
        if (!loginCheckPassed) {
          continue;
        }
        // Update Route on State
        yield put(routeUpdate(routeObj));
        // Run the Route Lifecycle
        effects.push(
          yield fork(routeLifecycle, routeObj, routeDef, match.saga)
        );
        if (
          /* This is only implemented by our Native App Test Page (/native-app-integration-test) for debugging purposes */
          typeof window.JSBridgeInternal__LocationChangeHandler === 'function'
        ) {
          window.JSBridgeInternal__LocationChangeHandler(window.location.href);
        }
      } else {
        // Dispatch error action
        yield put(error404());
        // Call user-supplied handler
        if (isFunc(handlerError404)) {
          yield call(handlerError404, MOCK_ROUTE);
        }
      }
    }
  }

  function* loginCheck(routeObj) {
    const { view, params, query, loginRequired, logoutRequired } = routeObj;
    if ((loginRequired || logoutRequired) && isLoggedInCheck) {
      const isLoggedIn = yield call(isLoggedInCheck, { view, params, query });
      if (isFunc(handlerLoginRequiredFailed) && !isLoggedIn && loginRequired) {
        yield all([
          put(routerError(401, null, null, buildErrorMetadata(routeObj))),
          call(handlerLoginRequiredFailed, { view, params, query }),
        ]);
        return false;
      } else if (
        isFunc(handlerLogoutRequiredFailed) &&
        isLoggedIn &&
        logoutRequired
      ) {
        yield all([
          put(routerError(403, null, null, buildErrorMetadata(routeObj))),
          call(handlerLogoutRequiredFailed, { view, params, query }),
        ]);
        return false;
      }
    }
    return true;
  }

  function* routeLifecycle(routeObj, routeDef, routeSaga) {
    yield call(routeSaga, routeObj);
    yield fork(routeLifecycleNonBlockingAfter, routeObj, routeDef);
  }

  function* routeLifecycleNonBlockingAfter(routeObj, routeDef) {
    const tasks: any[] = [];
    if (options.afterRouteSaga && isFunc(options.afterRouteSaga)) {
      tasks.push(fork(options.afterRouteSaga, routeObj));
    }

    if (tasks.length) {
      yield all(tasks);
    }
  }

  return router();
}
