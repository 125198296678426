import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';

import Dropdown from '@client/components/generic/Dropdown';
import defaultTheme from '@client/css-modules/DropdownRange.css';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';

type Value = string | number | null;
type Source = { label: string; value: Value }[];
type DropdownRangeProps = {
  className?: string;
  theme: Theme;
  handleChange: (values: Value[]) => void;
  handleReportValueSelection: (
    position: 'min' | 'max',
    value: string | number | null
  ) => void;
  values: Value[];
  sources: Source[];
  userBuyingPower?: number | null;
  labelForLeftDropDown?: string;
  labelForRightDropDown?: string;
  ariaLabelledBy?: string;
  label?: string;
  /** Makes the options list expand upwards instead of down - use in cases where the list would otherwise expand off-screen */
  shouldExpandUpwards?: boolean;
};

/**
 * A form component comprised of two Dropdown components, used to represent a range of values
 */
const DropdownRange: React.FC<DropdownRangeProps> = ({
  className,
  theme,
  handleChange,
  handleReportValueSelection,
  values,
  sources,
  userBuyingPower,
  labelForLeftDropDown,
  labelForRightDropDown,
  ariaLabelledBy,
  shouldExpandUpwards,
}) => {
  return (
    <AccessibleElementUniqueId>
      {({ uid }) => (
        <div
          aria-labelledby={ariaLabelledBy}
          role="group"
          className={classNames(theme.DropdownRange, {
            [className || '']: !!className,
          })}
        >
          <div className={theme.DropdownWrapper}>
            {labelForLeftDropDown && (
              <div className={theme.LabelWrapper}>
                <div id={`left-label-${uid}`} className={theme.Label}>
                  {labelForLeftDropDown}
                </div>
              </div>
            )}
            <Dropdown
              theme={theme}
              className={theme.DropdownRangeMin}
              onChange={(value) => {
                handleChange([value, values[1]]);
                handleReportValueSelection('min', value);
              }}
              shouldNotAddAriaLabel
              ariaLabelledBy={`${ariaLabelledBy} left-label-${uid}`}
              source={sources[0]}
              value={values[0]}
              handleReportValueSelection={() => void 0}
              shouldExpandUpwards={shouldExpandUpwards}
            />
          </div>
          <div
            className={classNames(theme.DropdownRangeSeparator, {
              [theme.DropdownRangeSeparatorWithMarginTop]:
                labelForLeftDropDown || labelForRightDropDown,
            })}
          >
            -
          </div>
          <div className={theme.DropdownWrapper}>
            {labelForRightDropDown && (
              <div className={theme.LabelWrapper}>
                <div id={`right-label-${uid}`} className={theme.Label}>
                  {labelForRightDropDown}
                </div>
              </div>
            )}
            <Dropdown
              theme={theme}
              className={theme.DropdownRangeMax}
              shouldNotAddAriaLabel
              ariaLabelledBy={`${ariaLabelledBy} right-label-${uid}`}
              onChange={(value) => {
                handleChange([values[0], value]);
                handleReportValueSelection('max', value);
              }}
              source={sources[1]}
              value={values[1]}
              userBuyingPower={userBuyingPower}
              handleReportValueSelection={() => void 0}
              shouldExpandUpwards={shouldExpandUpwards}
            />
          </div>
        </div>
      )}
    </AccessibleElementUniqueId>
  );
};

export default themr('ThemedDropdownRange', defaultTheme)(DropdownRange);
