import Cookies from 'js-cookie';

import { COOKIE_KEYS } from '@client/store/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  eventType,
  reportEvent,
} from '@client/store/actions/analytics.actions';
import { getUserContactInfo } from '@client/store/selectors/auth.selectors';

import { put, select } from 'redux-saga/effects';
import { PARENT_EVENTS } from '@client/store/analytics-constants';

export function setIsAgentFormPostedInCookie(userId: string | null) {
  if (!getIsAgentFormPostedInCookie(userId)) {
    Cookies.set(`${COOKIE_KEYS.YT2_AGENT_FORM_POSTED}_${userId}`, 'true', {
      expires: 729,
      domain: `.${document?.location?.hostname}`,
      /* This cookie is only used to show/hide some UX */
      secure: false,
      sameSite: 'lax',
    });
  }
}

export function getIsAgentFormPostedInCookie(userId: string | null) {
  return (
    Cookies.get(`${COOKIE_KEYS.YT2_AGENT_FORM_POSTED}_${userId}`) === 'true'
  );
}

export function clearIsAgentFormPostedInCookie(userId: string | null) {
  if (getIsAgentFormPostedInCookie(userId)) {
    Cookies.remove(`${COOKIE_KEYS.YT2_AGENT_FORM_POSTED}_${userId}`, {
      expires: 729,
      domain: `.${document?.location?.hostname}`,
      /* This cookie is only used to show/hide some UX */
      secure: false,
      sameSite: 'lax',
    });
  }
}

/**
 * @returns a function that dispatches a reportEvent action
 * with the common YourTeam properties
 */
export const useReportYourTeamEvent = () => {
  const dispatch = useDispatch();
  const contactInfo = useSelector(getUserContactInfo);

  return (eventName: eventType, props: Object) => {
    dispatch(
      reportEvent(eventName, PARENT_EVENTS.CLICK_YOUR_TEAM, {
        ...props,
        ...contactInfo,
      })
    );
  };
};

/**
 * A helper function for reporting events from the YourTeam component
 * through generator functions
 * @param eventName the name of the event to report
 * @param parentName the parent name of the event to report
 * @param props an object of properties to report
 */
export function* putYourTeamReportAction(eventName: eventType, props: Object) {
  const contactInfo = yield select(getUserContactInfo);
  yield put(
    reportEvent(eventName, PARENT_EVENTS.CLICK_YOUR_TEAM, {
      ...props,
      ...contactInfo,
    })
  );
}
