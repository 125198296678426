import React from 'react';

type RenderOnMountState = { isMounted: boolean };

/**
 * Deprecated: Please instead use `hocs/render-on-mount` until we have a chance to completely
 * migrate away from this untyped HOC
 *
 * Given a component, render it after this component mounts
 */
export default (WrappedComponent) => {
  class RenderOnMount extends React.Component<any, RenderOnMountState> {
    state = {
      isMounted: false,
    };

    componentDidMount() {
      this.setState({ isMounted: true });
    }

    render() {
      const { isMounted } = this.state;
      if (!isMounted) {
        return null;
      }
      return <WrappedComponent {...this.props} />;
    }
  }
  return RenderOnMount;
};
