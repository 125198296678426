import React from 'react';
import {
  createClient,
  fetchExchange,
  dedupExchange,
  cacheExchange,
  errorExchange,
  Provider as UrqlProvider,
} from 'urql';
import { devtoolsExchange } from '@urql/devtools';
import { useSelector, useDispatch } from 'react-redux';

import { checkErrors } from '@client/urql-exchanges/check-errors-exchange';
import { retryExchange } from '@client/urql-exchanges/retry-exchange';
import HC_CONSTANTS from '@client/app.config';
import { urqlAuthExchange } from '@client/urql-exchanges/auth-exchange';
import { getAccessToken } from '@client/store/selectors/auth.selectors';
import { urqlInitiatedUnauthorizedLogout } from '@client/store/actions/auth.actions';

/**
 * Urql is a graphql client that allows components to handle their own data-fetching and caching,
 * removing the need to involve Redux state or sagas.
 * https://formidable.com/open-source/urql/docs/basics/react-preact/
 */
const UrqlAppWrapper: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const sessionToken = useSelector(getAccessToken);
  const dispatch = useDispatch();
  const authExchange = urqlAuthExchange(sessionToken, () => {
    dispatch(urqlInitiatedUnauthorizedLogout());
  });
  const urqlClient = createClient({
    fetchOptions: {
      headers: {
        'X-Graph-Profile': 'consumer',
      },
    },
    url: `${HC_CONSTANTS.PROPERTY_GRAPH_URL}/graphql`,
    /* We haven't yet implemented server-side rendering with urql. If we need this in the future for
     * SEO, we need to decide on an implementation path.  Currently, if a `useQuery` hook is SSR'd
     * the result object will always be in a "fetching" (loading) state on the server, but no request
     * will be sent
     * https://formidable.com/open-source/urql/docs/advanced/server-side-rendering/ */
    suspense: false,
    /* Order is crucial here.  More info about exchanges:
     * https://formidable.com/open-source/urql/docs/architecture/#the-client-and-exchanges */
    exchanges: [
      devtoolsExchange,
      errorExchange({
        onError: checkErrors,
      }),
      dedupExchange,
      cacheExchange,
      authExchange,
      retryExchange,
      fetchExchange,
    ],
  });

  return <UrqlProvider value={urqlClient}>{children}</UrqlProvider>;
};

export default UrqlAppWrapper;
