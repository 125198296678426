import React from 'react';
import { themr } from '@friendsofreactjs/react-css-themr';

import defaultTheme from '@client/css-modules/MenuNotificationCount.css';

const MenuNotificationCount = ({ count, theme }) => (
  <React.Fragment>
    {count > 0 && (
      <div
        role="status"
        className={theme.MenuNotificationCount}
        aria-label={`You have ${count} alerts`}
      >
        {count < 100 && (
          <span className={theme.NotificationCounter}>{count}</span>
        )}
      </div>
    )}
  </React.Fragment>
);

const ThemedMenuNotificationCount = themr(
  'MenuNotificationCount',
  defaultTheme
)(MenuNotificationCount);
export default ThemedMenuNotificationCount;
