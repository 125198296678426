import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useRef,
} from 'react';

interface AriaAnnouncerContextType {
  announce: (message: JSX.Element | string) => void;
}

const AriaAnnouncerContext = createContext<
  AriaAnnouncerContextType | undefined
>(undefined);

export function AriaAnnouncerProvider({ children }: { children: ReactNode }) {
  const liveRegionRef = useRef<HTMLDivElement | null>(null);
  const [ariaMessage, setAriaMessage] = useState<JSX.Element | string>('');
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const announce = (message: JSX.Element | string) => {
    // clear existing message
    clearTimeout(timeoutId);
    setAriaMessage('');

    // in a timeout set the new message
    setTimeoutId( 
      setTimeout(() => {
        setAriaMessage(message);
      }, 1000)
    );
  };

  return (
    <AriaAnnouncerContext.Provider value={{ announce }}>
      <div
        id="aria-live-region"
        ref={liveRegionRef}
        aria-live="assertive"
        aria-atomic
        style={{
          position: 'absolute',
          width: 1,
          height: 1,
          overflow: 'hidden',
          clip: 'rect(1px, 1px, 1px, 1px)',
        }}
      >
        {ariaMessage}
      </div>
      {children}
    </AriaAnnouncerContext.Provider>
  );
}

export function useAriaAnnouncer() {
  const context = useContext(AriaAnnouncerContext);
  return context?.announce;
}
