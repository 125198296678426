import Cookies from 'js-cookie';

import { getCobrandIdFromHostname } from '@client/cobrand-settings/cobrand-utils';
import { getVisitId } from '@client/utils/visit.util';
import { SearchWidgetStoreType } from '@src/widgets/search-widget/store/types';
import {
  ActionType,
  TrackerObjType,
  StoreType,
  EventDefType,
  EventNameType,
  UtmParamsType,
  EventGeneralContext,
} from '../types';
import { getEventDef, testEventDefValid, getUTMParamsVals } from '../utils';

export const trackKinesis = (
  url: string,
  eventDef: EventDefType,
  appName: string,
  eventGeneralContext: EventGeneralContext | {},
  utm?: {},
  visitIdActionPayloadValue?: string
): EventNameType => {
  const data = {
    app: appName,
    page_group: eventDef.pageGroup,
    event_name: eventDef.event,
    event_type: eventDef.eventType,
    event_data: eventDef.props,
    ...eventGeneralContext,
    ...utm,
    ...eventDef.topLevelItems,
    http_user_agent: navigator.userAgent,
    http_url: window.location.href,
    http_path: window.location.pathname,
    http_host: window.location.hostname,
    os: navigator.platform,
    cookie_id: Cookies.get('hcid'), // the device id
    partner_subdomain: getCobrandIdFromHostname(window.location.hostname),
    visit_id: getVisitId() || visitIdActionPayloadValue, // identifies the visit (new id per tab and browser)
  };
  let request = new window.XMLHttpRequest();
  request.open('POST', url, true);
  request.withCredentials = true; // Forwards cookie for tracking cookie id
  request.setRequestHeader('Content-type', 'application/json');
  request.send(JSON.stringify(data));
  return eventDef.event;
};

export const kinesisTracker = (
  appName: string,
  ehrmantrautHostname: string,
  eventGeneralContextSelector: (state: any) => EventGeneralContext | {},
  utmParams: UtmParamsType = []
): false | TrackerObjType => {
  // Validate Dependencies
  if (!appName || typeof appName !== 'string') {
    console.warn('You must provide an appName string to use kinesis events.');
    return false;
  }
  if (
    !ehrmantrautHostname ||
    typeof ehrmantrautHostname !== 'string' ||
    ehrmantrautHostname.slice(0, 4) !== 'http'
  ) {
    console.warn(
      'You must provide a valid ehrmantraut hostname to use kinesis events.'
    );
    return false;
  }
  if (
    !eventGeneralContextSelector ||
    typeof eventGeneralContextSelector !== 'function'
  ) {
    console.warn(
      'You must provide an user info selector to use kinesis events.'
    );
    return false;
  }

  const KINESIS_URL = `${ehrmantrautHostname}/v1/t`;
  const eventKinesis = (
    action: ActionType & { payload?: { visitId?: string } },
    store: StoreType | SearchWidgetStoreType
  ): boolean | string => {
    // Sends a Kinesis event to ehrmantraut
    const eventDef = getEventDef(action, 'kinesis');
    const visitIdActionPayloadValue = action?.payload?.visitId;
    if (!testEventDefValid(eventDef)) return false;
    return trackKinesis(
      KINESIS_URL,
      eventDef,
      appName,
      eventGeneralContextSelector(store.getState()),
      getUTMParamsVals(utmParams),
      visitIdActionPayloadValue
    );
  };

  return {
    key: 'kinesis',
    fn: eventKinesis,
  };
};

export default kinesisTracker;
