import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { ReduxState } from '@client/store/types/redux-state';
import { SavedSearchAPIFields } from '@client/store/types/saved-searches';

export type RecentUserActivityState = {
  recentSearches: SavedSearchAPIFields[] | null;
  recentProperties: RecentPropertyResponseItem[] | null;
};

export type RecentPropertyResponseItem = {
  address_id: number;
  updated: string;
  created: string;
};

const initialState: RecentUserActivityState = {
  recentSearches: null,
  recentProperties: null,
};

export const RecentUserActivitySlice = createSlice({
  name: 'recentUserActivity',
  initialState,
  reducers: {
    goToRecentSearch: (_, action: PayloadAction<{ searchId: number }>) => {},
    postRecentSearchSave: (
      _,
      action: PayloadAction<{ searchId: number }>
    ) => {},
    fetchRecentSearches: () => {},
    fetchRecentSearchesSuccess: (
      state,
      action: PayloadAction<SavedSearchAPIFields[]>
    ) => ({
      ...state,
      recentSearches: action.payload,
    }),
    fetchRecentProperties: () => {},
    fetchRecentPropertiesSuccess: (
      state,
      action: PayloadAction<RecentPropertyResponseItem[]>
    ) => ({
      ...state,
      recentProperties: action.payload,
    }),
  },
});

function selectRecentUserActivityState(
  state: ReduxState
): RecentUserActivityState {
  return state.recentUserActivity;
}
export const selectRecentSearches = createSelector(
  selectRecentUserActivityState,
  (recentUserActivityState) => recentUserActivityState.recentSearches
);
export const selectRecentSearch = (searchId: number) =>
  createSelector(selectRecentUserActivityState, (recentUserActivityState) =>
    recentUserActivityState.recentSearches?.find(
      (search) => search.search_id === searchId
    )
  );
export const selectRecentProperties = createSelector(
  selectRecentUserActivityState,
  (recentUserActivityState) => recentUserActivityState.recentProperties
);

export const {
  fetchRecentSearches,
  fetchRecentSearchesSuccess,
  fetchRecentProperties,
  fetchRecentPropertiesSuccess,
  postRecentSearchSave,
  goToRecentSearch,
} = RecentUserActivitySlice.actions;
export default RecentUserActivitySlice.reducer;
