import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import React from 'react';

import CobrandedStyles from '@client/components/CobrandedStyles';
import ScrollSectionIntoView from '@client/components/ScrollSectionIntoView';
import defaultTheme from '@client/css-modules/Dropdown.css';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { key, stopEvent } from '@client/utils/component.utils';
import { elementIsDescendant } from '@client/utils/dom.utils';

type Value = string | number | null;

export type DropdownProps = {
  className?: string;
  dataHcName?: string;
  onChange: (value: Value) => void;
  handleReportValueSelection: (value: Value) => void;
  source: Array<{ label: string; value: Value }>;
  value: Value;
  label?: string;
  dropdownButtonId?: string;
  shouldNotAddAriaLabel?: boolean;
  ariaLabelledBy?: string;
  noSelectedPlaceHolderText?: string;
  theme: Theme;
  userBuyingPower?: number | null;
  buttonStyle?: React.CSSProperties;
  /** Makes the options list expand upwards instead of down - use in cases where the list would otherwise expand off-screen */
  shouldExpandUpwards?: boolean;
  /* Allows overriding the max-height of the dropdown list */
  dropdownListMaxHeight?: string;
};

type DropdownState = {
  isShowingList: boolean;
  dropdownListActiveDescendantId: string;
};

class Dropdown extends React.Component<DropdownProps, DropdownState> {
  dropdownContainer: React.RefObject<HTMLDivElement>;
  dropdownListButton: React.RefObject<HTMLButtonElement>;

  constructor(props: DropdownProps) {
    super(props);
    this.dropdownContainer = React.createRef();
    this.dropdownListButton = React.createRef();
  }

  state: DropdownState = {
    isShowingList: false,
    dropdownListActiveDescendantId: '',
  };

  componentDidUpdate() {
    this.setActiveDescendantId();
  }

  getCurrentFocusedOption = () => {
    const { current: dropdownContainer } = this.dropdownContainer;
    let curSelectedOption: HTMLElement | null = null;
    if (dropdownContainer) {
      curSelectedOption = dropdownContainer.querySelector(
        'li[role="option"][data-focused="true"]'
      );
      if (!curSelectedOption) {
        curSelectedOption =
          dropdownContainer.querySelector('li[role="option"]');
        if (curSelectedOption) {
          curSelectedOption.setAttribute('data-focused', 'true');
        }
      }
    }

    return curSelectedOption;
  };

  setActiveDescendantId = (activeElementId?: string) => {
    const { dropdownListActiveDescendantId } = this.state;

    if (activeElementId) {
      if (dropdownListActiveDescendantId !== activeElementId) {
        this.setState({
          dropdownListActiveDescendantId: activeElementId,
        });
      }
    } else {
      const curSelectedOption = this.getCurrentFocusedOption();
      if (curSelectedOption) {
        curSelectedOption.focus();
        const optionId = curSelectedOption.id;
        if (dropdownListActiveDescendantId !== optionId) {
          this.setState({
            dropdownListActiveDescendantId: optionId,
          });
        }
      }
    }
  };

  handleOnOutsideClick = (e: MouseEvent) => {
    const { isShowingList } = this.state;
    const { current: dropdownContainer } = this.dropdownContainer;
    if (
      isShowingList &&
      dropdownContainer &&
      e.target &&
      !elementIsDescendant(e.target as Element, dropdownContainer)
    ) {
      this.closeDropdownList();
    }
  };

  handleOnKeydown = (e: KeyboardEvent) => {
    const pressedKey = e.key;
    const { isShowingList } = this.state;

    if (isShowingList) {
      if (key.isArrowUp(pressedKey) || key.isArrowDown(pressedKey)) {
        stopEvent(e);
        const { current: dropdownContainer } = this.dropdownContainer;
        if (dropdownContainer) {
          const currentFocusedOption: HTMLElement | null =
            dropdownContainer.querySelector(
              'li[role="option"][data-focused="true"]'
            );
          if (currentFocusedOption) {
            if (key.isArrowUp(pressedKey)) {
              this.handleUpButtonPress(currentFocusedOption);
            }

            if (key.isArrowDown(pressedKey)) {
              this.handleDownButtonPress(currentFocusedOption);
            }
          }
        }
      } else if (key.isTab(pressedKey) || key.isEscape(pressedKey)) {
        stopEvent(e);
        this.closeDropdownList();
      }
    }
  };

  handleUpButtonPress = (curEle: HTMLElement) => {
    const previousEle: HTMLElement | null =
      curEle.previousElementSibling as HTMLElement;
    if (previousEle) {
      curEle.setAttribute('data-focused', 'false');
      previousEle.setAttribute('data-focused', 'true');
      previousEle.focus();
      this.setActiveDescendantId(previousEle.id);
    }
  };

  handleDownButtonPress = (curEle: HTMLElement) => {
    const nextEle: HTMLElement | null =
      curEle.nextElementSibling as HTMLElement;
    if (nextEle) {
      curEle.setAttribute('data-focused', 'false');
      nextEle.setAttribute('data-focused', 'true');
      nextEle.focus();
      this.setActiveDescendantId(nextEle.id);
    }
  };

  handleDropdownButtonClick = () => {
    const { isShowingList } = this.state;
    const newIsShowingListValue = !isShowingList;
    this.setState({
      isShowingList: newIsShowingListValue,
    });

    /**
     * Bind listeners only when dropdown list opens
     */
    if (newIsShowingListValue) {
      const { current: dropdownContainer } = this.dropdownContainer;
      window.addEventListener('click', this.handleOnOutsideClick);
      if (dropdownContainer) {
        dropdownContainer.addEventListener('keydown', this.handleOnKeydown);
      }
    }
  };

  handleOnOptionClick = (e: React.MouseEvent<HTMLElement>, value: Value) => {
    stopEvent(e);
    this.handleExecuteCallbackAndReportEvent(value);
  };

  handleOnOptionKeyDown = (
    e: React.KeyboardEvent<HTMLElement>,
    value: Value
  ) => {
    if (key.isReturn(e.key) || key.isSpace(e.key)) {
      stopEvent(e);
      this.handleExecuteCallbackAndReportEvent(value);
    }
  };

  handleExecuteCallbackAndReportEvent = (value: Value) => {
    const { onChange, handleReportValueSelection } = this.props;
    onChange(value);
    this.closeDropdownList();
    handleReportValueSelection(value);
  };

  closeDropdownList = () => {
    const { current: dropdownListButton } = this.dropdownListButton;
    if (dropdownListButton) {
      dropdownListButton.focus();
    }
    this.setState({ isShowingList: false });

    /**
     *  In order to not affect performance, Remove Event listeners when
     *  dropdown list closes.
     */
    const { current: dropdownContainer } = this.dropdownContainer;
    window.removeEventListener('click', this.handleOnOutsideClick);
    if (dropdownContainer) {
      dropdownContainer.removeEventListener('keydown', this.handleOnKeydown);
    }
  };

  render() {
    const {
      className,
      dataHcName,
      theme,
      value,
      source,
      noSelectedPlaceHolderText,
      dropdownButtonId,
      shouldNotAddAriaLabel,
      ariaLabelledBy,
      userBuyingPower,
      buttonStyle,
      shouldExpandUpwards,
      dropdownListMaxHeight,
    } = this.props;
    const { isShowingList, dropdownListActiveDescendantId } = this.state;
    const parseOptionId = (optionLabel: string) =>
      String(optionLabel).toLowerCase().replace(/\s/g, '-');
    const selectedOption = source.find((option) => option.value === value);
    const buttonText = selectedOption
      ? selectedOption.label
      : noSelectedPlaceHolderText;
    return (
      <CobrandedStyles>
        {({ dropdownListOptionSelected }) => (
          <AccessibleElementUniqueId>
            {({ uid }) => (
              <div
                className={classNames(theme.DropdownContainer, {
                  [className || '']: !!className,
                })}
                ref={this.dropdownContainer}
              >
                <button
                  style={buttonStyle}
                  ref={this.dropdownListButton}
                  className={theme.DropdownListButton}
                  data-hc-name={dataHcName}
                  onClick={this.handleDropdownButtonClick}
                  onKeyDown={onEnterOrSpaceKey(this.handleDropdownButtonClick)}
                  id={dropdownButtonId}
                  aria-describedby={uid}
                  aria-labelledby={ariaLabelledBy ? ariaLabelledBy : undefined}
                  aria-haspopup="listbox"
                  aria-expanded={isShowingList}
                  /**
                   * If there is a visible label, no need to add aria label to prevent improperly
                   * using aria attributes.
                   */
                  aria-label={shouldNotAddAriaLabel ? undefined : buttonText}
                >
                  <span
                    id={uid}
                    className={classNames(theme.DropdownButtonText, {
                      [theme.DropdownButtonTextNoSelection]:
                        buttonText === noSelectedPlaceHolderText,
                    })}
                  >
                    {selectedOption &&
                      userBuyingPower &&
                      selectedOption.value === userBuyingPower && (
                        <span
                          style={{
                            color: dropdownListOptionSelected,
                            display: 'inline',
                          }}
                        >
                          Buying power&nbsp;
                        </span>
                      )}
                    {buttonText}
                  </span>
                  <span className={theme.DropdownButtonIcon} />
                </button>

                {isShowingList && (
                  <ScrollSectionIntoView>
                    <ul
                      className={classNames(theme.DropdownList, {
                        [theme.ExpandUpwards]: shouldExpandUpwards,
                      })}
                      aria-activedescendant={dropdownListActiveDescendantId}
                      tabIndex={0}
                      aria-labelledby={
                        ariaLabelledBy ? ariaLabelledBy : undefined
                      }
                      role="listbox"
                      style={
                        dropdownListMaxHeight
                          ? { maxHeight: dropdownListMaxHeight }
                          : undefined
                      }
                    >
                      {source.map((option, index) => (
                        <li
                          key={index}
                          tabIndex={0}
                          id={`${parseOptionId(option.label)}`}
                          aria-selected={value === option.value}
                          data-focused={value === option.value}
                          className={classNames(theme.DropdownListOption, {
                            [theme.Active]: !!(
                              value === option.value && !userBuyingPower
                            ),
                            [className || '']: className,
                          })}
                          onKeyDown={onEnterOrSpaceKey((e) =>
                            this.handleOnOptionKeyDown(e, option.value)
                          )}
                          onClick={(e) =>
                            this.handleOnOptionClick(e, option.value)
                          }
                          role="option"
                        >
                          {userBuyingPower &&
                          option.value === userBuyingPower ? (
                            <>
                              <span
                                style={{ color: dropdownListOptionSelected }}
                              >
                                Buying power&nbsp;
                              </span>
                              <span>{option.label}</span>
                            </>
                          ) : (
                            option.label
                          )}
                        </li>
                      ))}
                    </ul>
                  </ScrollSectionIntoView>
                )}
              </div>
            )}
          </AccessibleElementUniqueId>
        )}
      </CobrandedStyles>
    );
  }
}

export default themr('DropdownThemed', defaultTheme)(Dropdown);
