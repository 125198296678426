import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import {
  ParamsType,
  QueryType,
  RouteChangeOptionsType,
  RouteDefsAllType,
  RouteTypeWithPotentiallyNullView,
  ViewType,
} from '../types';

import { ROUTE_BACK, routeBack, routeChange } from '../actions';
import { ATTRIBUTE_IGNORE_INTERCEPT, OS_MAC } from '../constants';
import { getCurrentRoute } from '../selectors';
import { applyRouteChangeOptionsAll, buildFullPath } from '../utils';

const mapStateToProps = (state) => {
  return {
    currentRoute: getCurrentRoute(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { routerAction } = ownProps;
  return {
    onDispatchRouteChange: (view, params, query, options) => {
      if (routerAction === 'BACK' || routerAction === ROUTE_BACK) {
        dispatch(routeBack());
      } else {
        dispatch(routeChange({ view, params, query, options }));
      }
    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { options } = ownProps;
  const { currentRoute } = stateProps;
  const view = ownProps.view;
  const params = ownProps.params;
  const query = ownProps.query;
  const { nextView, nextParams, nextQuery } = applyRouteChangeOptionsAll(
    view,
    params,
    query,
    currentRoute
  );
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onDispatchRouteChange: () =>
      dispatchProps.onDispatchRouteChange(view, params, query, options),
    nextView,
    nextParams,
    nextQuery,
  };
};

type Props = {
  currentRoute: RouteTypeWithPotentiallyNullView;
  view: ViewType;
  params: ParamsType;
  query: QueryType;
  nextView: ViewType;
  nextParams: ParamsType;
  nextQuery: QueryType;
  options: RouteChangeOptionsType;
  children?: JSX.Element;
  onClick: (e: React.MouseEvent) => void;
  onDispatchRouteChange: () => void;
};

const LinkFactory = (ROUTES: RouteDefsAllType, os: string): ComponentType => {
  const eventTargetKey = os === OS_MAC ? 'metaKey' : 'ctrlKey';

  class Link extends React.PureComponent<Props> {
    static defaultProps = {
      params: {},
      query: {},
      options: {},
      onClick: () => {},
    };

    handleClick = (e: React.MouseEvent) => {
      const targetBlankAttr =
        e.currentTarget && (e.currentTarget as HTMLAnchorElement).target;
      // Do not prevent default is the user is trying to open the link in a new tab
      const targetBlank =
        e[eventTargetKey] || e.shiftKey || targetBlankAttr === '_blank';
      const { onClick, onDispatchRouteChange } = this.props;
      if (!targetBlank) {
        e.preventDefault();
        onDispatchRouteChange();
      }
      onClick(e);
    };

    render() {
      const {
        children,
        currentRoute,
        view,
        params,
        query,
        nextView,
        nextParams,
        nextQuery,
        options,
        onClick,
        onDispatchRouteChange,
        ...rest
      } = this.props;
      rest[ATTRIBUTE_IGNORE_INTERCEPT] = true;
      const nextRouteDef = ROUTES[nextView];

      if (!nextRouteDef) {
        throw new Error('Route def not found for view ' + nextView);
      }

      return (
        <a
          href={buildFullPath(nextRouteDef, nextParams, nextQuery)}
          {...rest}
          onClick={this.handleClick}
        >
          {children}
        </a>
      );
    }
  }

  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(Link);
};

export default LinkFactory;
