import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import React from 'react';
import { useSelector } from 'react-redux';

import PillButton from '@client/components/generic/PillButton';
import defaultTheme from '@client/css-modules/NativeAppError.css';
import ErrorIcon from '@client/inline-svgs/error-icon';
import { getSeeLoanOptionsRedirectUrl } from '@client/store/sagas/chase-integration.saga';
import {
  getCustomizationData,
  getIsInsideNativeApp,
} from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';

type Props = {
  theme: Theme;
};

/**
 * An error page that can be navigated to directly via URL (useful for when an API needs to redirect
 * a parent native app to an error page).
 *
 * Note that our 404.tsx and 500.tsx are used when errors occur in our node backend
 */
const NativeAppError: React.FunctionComponent<Props> = ({ theme }) => {
  const isInsideNativeApp = useSelector(getIsInsideNativeApp);
  const { should_show_cta_on_error_page, cobrand_cta_redirect_hostname } =
    useSelector(getCustomizationData);
  const isTestChaseCtaDest = useSelector(
    getIsFeatureEnabled('test_chase_cta_dest')
  );
  const isOnChaseWebview = isInsideNativeApp && should_show_cta_on_error_page;
  return (
    <div className={theme.NativeAppError}>
      <ErrorIcon />
      {isOnChaseWebview ? (
        <>
          <p>It looks like something went wrong,</p>
          <p>you can still explore loan options.</p>
        </>
      ) : (
        <>
          <p>It looks like something went wrong.</p>
          <p>Please try again later.</p>
          <p>Thanks for your patience.</p>
        </>
      )}
      {
        /* This CTA button is only shown on Chase webview so there is no postJSBridge fired */
        isOnChaseWebview && (
          <PillButton
            className={theme.CTAButton}
            onClick={() => {
              window.location.href = getSeeLoanOptionsRedirectUrl({
                hostname: cobrand_cta_redirect_hostname as string,
                isTestChaseCtaDest,
              });
            }}
            ariaLabel={'See loan options'}
          >
            See loan options
          </PillButton>
        )
      }
    </div>
  );
};

export default themr('RouteableErrorThemed', defaultTheme)(NativeAppError);
