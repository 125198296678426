import { SSO_QUERY_KEYS } from '@client/store/sso-constants';
import queryString from 'query-string';

import HC_CONSTANTS from '@client/app.config';
import { VIEW_PATHS } from '@client/routes/constants';
import { reportClickCobrandSignIn } from '@client/store/actions/analytics.actions';
import {
  CREATE_SAVED_SEARCH,
  createSavedSearch,
} from '@client/store/actions/saved-search.actions';
import {
  ADD_TO_WATCHLIST,
  addToWatchList,
} from '@client/store/actions/watchlist.actions';
import {
  AGENT_ID_URL_KEY,
  LOAN_OFFICER_ID_URL_KEY,
} from '@client/store/constants';
import { getAuthModalAfterAuthAction } from '@client/store/selectors/auth.selectors';
import {
  getCobrandAuthScheme,
  getCobrandId,
} from '@client/store/selectors/cobranding.selectors';
import { getLoanOfficerId } from '@client/store/selectors/loan-officer.selectors';
import {
  selectCompleteConnectedAgent,
  setYourTeamModalType,
} from '@client/store/slices/your-team.slice';
import { YourTeamModalType } from '@client/store/types/your-team';
import { localStorageUtil } from '@client/utils/local-storage.utils';
import {
  ActionRouteChangeType,
  ROUTE_CHANGE,
} from '@src/redux-saga-router-plus/actions';
import { getCurrentQuery } from '@src/redux-saga-router-plus/selectors';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Compute the relative path passed to the SSO redirect endpoint, used to redirect the user back to
 * the ComeHome app after they have logged in.
 */
export const useSSOReturnToComehomeURLPathname = (): string => {
  const afterAuthAction = useSelector(getAuthModalAfterAuthAction);
  const currentQuery = useSelector(getCurrentQuery);
  const completeConnectedAgentId =
    useSelector(selectCompleteConnectedAgent)?.id ?? null;
  const associatedLoanOfficerId = useSelector(getLoanOfficerId);
  let newQuery = { ...currentQuery };

  /* If the user is associated with an LO/agent, add the LO/agent id to the query so that the user gets
   * associated with the LO/user after returning from authentication (in set-agent.ts/set-loan-officer.ts) */
  if (completeConnectedAgentId) {
    newQuery[AGENT_ID_URL_KEY] = completeConnectedAgentId;
  }
  if (associatedLoanOfficerId) {
    newQuery[LOAN_OFFICER_ID_URL_KEY] = associatedLoanOfficerId;
  }

  /* If no afterAuthAction associated with the login attempt, return the current pathname and query */
  if (!afterAuthAction) {
    return addQueryStringToPathname(window.location.pathname, newQuery);
  }

  /* If the afterAuthAction is ROUTE_CHANGE with a view defined, return the associated view path */
  if (
    afterAuthAction?.type === ROUTE_CHANGE &&
    (afterAuthAction as ActionRouteChangeType)?.payload?.view
  ) {
    return addQueryStringToPathname(
      VIEW_PATHS[(afterAuthAction as ActionRouteChangeType)?.payload?.view!],
      newQuery
    ).replace(
      ':slug',
      (afterAuthAction as ActionRouteChangeType)?.payload?.params?.slug
    );
  }

  /* Else, return the current pathname, appending the attempted action string and a few whitelisted params
   * as query params */
  /* Remove old keys if they exist */
  Object.values(SSO_QUERY_KEYS).forEach((key) => {
    delete newQuery[key];
  });
  newQuery[SSO_QUERY_KEYS.SSO_ACTION] = afterAuthAction.type;
  if (afterAuthAction?.payload?.slug) {
    newQuery[SSO_QUERY_KEYS.SLUG] = afterAuthAction?.payload?.slug || null;
  }
  if (
    afterAuthAction?.type === setYourTeamModalType.type &&
    afterAuthAction?.payload?.modalType
  ) {
    newQuery[SSO_QUERY_KEYS.YT_MODAL_TYPE] =
      afterAuthAction?.payload?.modalType;
  }
  return addQueryStringToPathname(window.location.pathname, newQuery);
};

/**
 * After the user returns to the app post-authentication, dispatch the action that was attempted prior to
 * authentication
 */
export const useDispatchActionsAfterSSO = () => {
  const dispatch = useDispatch();
  const query = useSelector(getCurrentQuery);
  const actionAfterSSO = query?.[SSO_QUERY_KEYS.SSO_ACTION];
  const slug = query?.[SSO_QUERY_KEYS.SLUG];

  return () => {
    /* We're whitelisting several actions that we're allowing to be executed after app load. Allowing
     * arbitrary actions to be executed after app load could be a security risk */
    if (actionAfterSSO === ADD_TO_WATCHLIST && slug) {
      dispatch(addToWatchList({ slug }));
    } else if (actionAfterSSO === CREATE_SAVED_SEARCH) {
      dispatch(createSavedSearch());
    } else if (actionAfterSSO === setYourTeamModalType.type) {
      dispatch(
        setYourTeamModalType({
          modalType: query?.[SSO_QUERY_KEYS.YT_MODAL_TYPE] as YourTeamModalType,
        })
      );
    }
  };
};

/**
 * Redirect the user to the SSO endpoint, passing the return URL as a query param
 */
export const useSSORedirect = () => {
  const dispatch = useDispatch();
  const authScheme = useSelector(getCobrandAuthScheme);
  const comehomePathname = useSSOReturnToComehomeURLPathname();
  const cobrandId = useSelector(getCobrandId);

  return () => {
    dispatch(reportClickCobrandSignIn());
    switch (authScheme) {
      case 'saml':
        const uriEncodedPathname = encodeURIComponent(comehomePathname);
        const samlURL = `${HC_CONSTANTS.COBRAND_SAML_SIGN_IN_REDIRECT_URL}?site_id=${cobrandId}&url=${uriEncodedPathname}`;
        logReturnURL(comehomePathname);
        handleLocationChange(samlURL);
        break;
      case 'oauth':
        const base64EncodedPathname = btoa(comehomePathname);
        const oauthURL = `${HC_CONSTANTS.COBRAND_OAUTH_SIGN_IN_REDIRECT_URL}?redirect_uri=${base64EncodedPathname}`;
        logReturnURL(comehomePathname);
        handleLocationChange(oauthURL);
        break;
      default:
        throw new Error(
          'useSSORedirect received an unexpected auth scheme: ' + authScheme
        );
    }
  };
};

const logReturnURL = (relativePath: string): void => {
  const port = window.location.port;
  /* eslint-disable-next-line custom/explain-localstorage */
  if (localStorageUtil.getItem('SSORedirectDryRun')) {
    console.log(
      `▷ [SSO Redirect Dry Run] ComeHome Return URL: https://${
        window.location.hostname
      }${port ? ':' + port : ''}${relativePath}`
    );
  }
};

const addQueryStringToPathname = (
  pathname: string,
  queryObject: { [key: string]: string }
): string =>
  `${pathname}${
    Object.keys(queryObject).length
      ? `?${queryString.stringify(queryObject)}`
      : ''
  }`;

const handleLocationChange = (url: string): void => {
  /* eslint-disable-next-line custom/explain-localstorage */
  if (localStorageUtil.getItem('SSORedirectDryRun')) {
    console.log(`▷ [SSO Redirect Dry Run] Redirect URL: ${url}`);
  } else {
    window.location.href = url;
  }
};
