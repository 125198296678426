import React from 'react';
import { findDOMNode } from 'react-dom';
import { values, get, throttle } from 'lodash';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import scrollIntoView from 'scroll-into-view';
import classNames from 'classnames';

import LoadingSection from '@client/components/generic/LoadingSection';
import defaultTheme from '@client/css-modules/LazilyRenderedList.css';
import { findParentElementForClassname } from '@client/utils/dom.utils';
import LazilyRenderedComponent from '@client/components/LazilyRenderedComponent';

/* Only report items in viewport once scrolling stops */
const REPORT_ITEMS_IN_VIEWPORT_DELAY = 300;
const REACHED_BOTTOM_BUFFER = 100;

interface ItemsDimensionsStyleBase {
  width: string;
}

interface ItemsDimensionsStyleBaseWithMinHeight
  extends ItemsDimensionsStyleBase {
  minHeight: string;
}

interface ItemsDimensionsStyleBaseWithHeight extends ItemsDimensionsStyleBase {
  height: string;
}

type Props = {
  /* The field on the child to use as a React key */
  llKeyField: string;
  /* Whether you want to manually activate the loading spinner at the bottom of the component.
   * If fetching more properties using `props.onReachedBottom`, this is handled automatically
   * within the component */
  showBottomLoadingIndicator?: boolean;
  /* The number of px above and below the viewport at which a child item should be rendered */
  preloadBuffer?: number;
  /* Optional callback that fires when a child item enters the viewport, executed 200ms after scroll stops */
  handleReportItemsInViewport?: (llKey: string[], position: number) => void;
  /* Child items must have a defined width and height for lazy loading calculation */
  itemDimensionsStyle:
    | ItemsDimensionsStyleBaseWithMinHeight
    | ItemsDimensionsStyleBaseWithHeight;
  /* Optional: defining width and height for lazy loading in the child component.
  It will be use for setting the dimensions of the card on the specific child instead of using itemDimensionsStyle */
  customDimensionsStyle?: string;
  /* The classname of an element to bind scroll events to for rendering items. Useful when placing
   * the lazily-rendered list inside of a larger scrollable list of page elements. */
  scrollableAncestorClassName?: string;
  useSelfAsScrollableAncestor?: boolean;
  /* Trigger to cause recalculation of which child elements should be rendered, useful when
   * the parent container size changes and more child elements might need to be rendered */
  updateItemsToRenderTrigger?: any;
  /* Whether there are more properties to load once the bottom is reached */
  isMoreToLoad?: boolean;
  /* Event to fire when the bottom of the scrollable area has been reached.  Can be used to fetch
   * the next page of list items from the API and update the passed-in children. */
  onReachedBottom?: () => void;
  /* Event to fire during scroll when the list has not yet reached the bottom. */
  onNotReachedBottom?: () => void;
  /* An event handler called on scroll (throttled to 100 ms intervals) */
  onScroll?: () => void;
  /* Callback called with total items rendered after each new batch of items are rendered */
  onRenderItems?: (llKey: string[]) => void;
  /* The key of an item that should be scrolled to */
  keyToScroll?: string;
  /* Trigger to cause scrollable container to scroll to top */
  scrollToTopTrigger?: any;
  shouldScrollToTopOnChildChange?: boolean;
  /* Whether items should stay rendered once they're rendered initially. i.e. after scrolling down a
   * long list and reaching the bottom, all items stay rendered no matter the scroll position of the list.
   * This is useful when it's more costly to render items than keep them in the DOM, like a grid of photos */
  shouldKeepItemsRendered?: boolean;
  className?: string;
  theme: Theme;
  dataHcName?: string;
  dataHcLastUpdated?: string;
  ariaLabel?: string;
  shouldRenderAllChildren?: boolean;
  /* A string representing a valid HTML element, for instance 'ul', 'div', or 'section' */
  parentHTMLEl?: keyof JSX.IntrinsicElements;
  /* A string representing a valid HTML element. If props.parentHTML is an element that requires a valid
   * child (think `ul` and `li`, `tbody` and `tr`, etc) this should relate to the parent element */
  childHTMLEl?: keyof JSX.IntrinsicElements;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

type State = {
  itemComponentsToRender: React.ReactNode[];
  localShowBottomLoadingIndicator: boolean;
};

function getIsReactElement(
  reactChild: React.ReactNode | React.ReactInstance
): reactChild is React.ReactElement {
  return (reactChild as React.ReactElement).props !== undefined;
}

/*
 * A component that only renders child items that are inside of the viewport.  Can optionally be
 * used to handle loading more items when the bottom of the list has been scrolled to.
 */
class LazilyRenderedList extends React.PureComponent<Props, State> {
  state: State = {
    itemComponentsToRender: [],
    localShowBottomLoadingIndicator: false,
  };
  itemNodesById: { [id: string]: React.ReactInstance } = {};
  renderItemsScrollBinding: (() => void) | null = null;
  /* Memoize this to over recalculating both the prev and current checksum on componentDidUpdate */
  childChecksum: string | null = null;
  /* When we're adding more items to the list after scrolling to the bottom, we want to stay at the bottom */
  localShouldScrollToTopOnChildrenChange: boolean = true;
  /* Keep track of the most recently scrolled-to key so that we don't scroll to it again after the
   * child components change */
  scrolledToKey: string | null = null;
  removeEmphasizedClassTimeout: number | null = null;
  scrollableAncestor: Element | Text | null | Window = null;
  handleReportItemsInViewportTimeout: number | null = null;
  rereportItemsDuringLocalDevTimeout: number = 0;

  constructor(props) {
    super(props);
    this.setItemsToRender = this.setItemsToRender.bind(this);
    /**
     * We need to store a reference to the throttle function
     * in order to remove the event listener.
     */
    this.renderItemsScrollBinding = throttle(this.setItemsToRender, 1000, {
      trailing: true,
      leading: false,
    });
  }

  componentDidMount() {
    this.setScrollableAncestor();
    if (this.scrollableAncestor) {
      this.setupEventsAndReportItems();
    }
    this.childChecksum = this.getChildChecksum(this.props.children);
    this.localShouldScrollToTopOnChildrenChange =
      !!this.props.shouldScrollToTopOnChildChange;

    /* To fix items in list not appearing during local development. Sometimes the container
     * element won't have a height on mount due to CSS not yet having loaded */
    if (process.env.NODE_ENV === 'development') {
      this.rereportItemsDuringLocalDevTimeout = window.setTimeout(() => {
        this.setupEventsAndReportItems();
      }, 1000);
    }
  }

  componentWillUnmount() {
    if (this.scrollableAncestor) {
      this.removeEventListeners(this.scrollableAncestor);
    }
    if (this.removeEmphasizedClassTimeout) {
      window.clearTimeout(this.removeEmphasizedClassTimeout);
    }
    this.scrollableAncestor = null;

    if (process.env.NODE_ENV === 'development') {
      window.clearTimeout(this.rereportItemsDuringLocalDevTimeout);
    }
  }

  componentDidUpdate(prevProps) {
    const newChildChecksum = this.getChildChecksum(this.props.children);

    if (
      this.props.shouldScrollToTopOnChildChange !==
      prevProps.shouldScrollToTopOnChildChange
    ) {
      this.localShouldScrollToTopOnChildrenChange = false;
    }

    if (
      /* When we need to recalculate which child elements are rendered */
      newChildChecksum !== this.childChecksum ||
      prevProps.updateItemsToRenderTrigger !==
        this.props.updateItemsToRenderTrigger
    ) {
      /* After children have changed, ensure scrolled to top */
      if (
        this.localShouldScrollToTopOnChildrenChange &&
        this.props.shouldScrollToTopOnChildChange
      ) {
        this.scrollListToTop();
        /* If we're adding additional items after a scroll to the bottom, don't scroll to top */
      } else {
        this.localShouldScrollToTopOnChildrenChange = true;
        this.setState({ localShowBottomLoadingIndicator: false });
      }
      this.readAndReportItemsInViewport();
      this.setItemsToRender({ forceItemRenderRecalculation: true });
    }
    if (
      /* If `keyToScroll` is newly provided OR if `keyToScroll` is present and children change */
      (newChildChecksum !== this.childChecksum ||
        this.props.keyToScroll !== prevProps.keyToScroll) &&
      this.props.keyToScroll
    ) {
      this.scrollToItem(this.props.keyToScroll);
    }
    if (!this.props.keyToScroll) {
      this.scrolledToKey = null;
    }
    if (this.props.keyToScroll) {
      this.scrollToItem(this.props.keyToScroll);
    }
    if (this.props.scrollToTopTrigger !== prevProps.scrollToTopTrigger) {
      this.scrollListToTop();
    }
    this.childChecksum = newChildChecksum;
  }

  setScrollableAncestor = (): void => {
    const { scrollableAncestorClassName, useSelfAsScrollableAncestor } =
      this.props;
    const thisNode = findDOMNode(this);

    this.scrollableAncestor = useSelfAsScrollableAncestor
      ? thisNode
      : scrollableAncestorClassName
      ? thisNode
        ? findParentElementForClassname(thisNode, scrollableAncestorClassName)
        : null
      : window;
  };

  /* Scroll to an item in the list when `keyToScroll` provided and item is present in list */
  scrollToItem = (key: string): void => {
    const { theme } = this.props;
    const node = findDOMNode(this);
    const itemToScroll =
      node && (node as Element).querySelector(`[data-scroll-to-key="${key}"]`);

    if (itemToScroll && key !== this.scrolledToKey) {
      scrollIntoView(
        itemToScroll,
        { time: 500, align: { top: 0, topOffset: 10 } },
        () => {
          itemToScroll.classList.add(theme.EmphasizedListItem);
          this.removeEmphasizedClassTimeout = window.setTimeout(() => {
            itemToScroll.classList.remove(theme.EmphasizedListItem);
          }, 800);
        }
      );
      this.scrolledToKey = key;
    }
  };

  scrollListToTop = (): void => {
    if (this.scrollableAncestor?.constructor === window.Window) {
      window.scrollTo(0, 0);
    } else if (
      this.scrollableAncestor &&
      this.scrollableAncestor instanceof HTMLElement
    ) {
      this.scrollableAncestor.scrollTop = 0;
    }
  };

  getScrollableAncestorScrollPosition = (): number | null => {
    if (this.scrollableAncestor?.constructor === window.Window) {
      return window.scrollY || window.pageYOffset;
    } else if (
      this.scrollableAncestor &&
      this.scrollableAncestor instanceof HTMLElement
    ) {
      return this.scrollableAncestor.scrollTop;
    } else {
      return null;
    }
  };

  getScrollableAncestorHeight = (): number | null => {
    if (this.scrollableAncestor?.constructor === window.Window) {
      return window.innerHeight;
    } else if (
      this.scrollableAncestor &&
      this.scrollableAncestor instanceof HTMLElement
    ) {
      return this.scrollableAncestor?.offsetHeight;
    } else {
      return null;
    }
  };

  removeEventListeners = (scrollableAncestor) => {
    scrollableAncestor.removeEventListener(
      'scroll',
      this.renderItemsScrollBinding
    );
    if (this.props.handleReportItemsInViewport) {
      scrollableAncestor.removeEventListener(
        'scroll',
        this.readAndReportItemsInViewport
      );
    }
    if (this.handleReportItemsInViewportTimeout) {
      window.clearTimeout(this.handleReportItemsInViewportTimeout);
    }
  };

  /* Executed on `componentDidMount` */
  setupEventsAndReportItems() {
    this.readAndReportItemsInViewport();
    this.setItemsToRender();

    if (this.scrollableAncestor && this.renderItemsScrollBinding) {
      this.scrollableAncestor.addEventListener(
        'scroll',
        this.renderItemsScrollBinding
      );
    }
    if (this.props.handleReportItemsInViewport && this.scrollableAncestor) {
      this.scrollableAncestor.addEventListener(
        'scroll',
        this.readAndReportItemsInViewport
      );
      if (!React.Children.count(this.props.children)) {
        this.props.handleReportItemsInViewport([], 0);
      }
    }
  }

  getItemComponentsInViewport = (): React.ReactNode[] => {
    const offsetHeight = this.getScrollableAncestorHeight();
    const scrollTop = this.getScrollableAncestorScrollPosition();

    if (offsetHeight !== null && scrollTop !== null) {
      return values(this.itemNodesById).filter((o) => {
        const itemNode = findDOMNode(o);
        if (!(itemNode instanceof HTMLElement)) {
          return false;
        } else {
          const cardOffset =
            itemNode.offsetTop - scrollTop + itemNode.offsetHeight;
          return cardOffset < offsetHeight && cardOffset >= 0;
        }
      }) as any[];
      /* When called before the component mounts */
    } else {
      return [];
    }
  };

  getItemComponentsToRender = (): React.ReactNode[] => {
    const { shouldKeepItemsRendered } = this.props;
    const { itemComponentsToRender } = this.state;
    const offsetHeight = this.getScrollableAncestorHeight();
    const scrollTop = this.getScrollableAncestorScrollPosition();
    const effectivePreloadBuffer = this.props.preloadBuffer || 0;

    if (offsetHeight !== null && scrollTop !== null) {
      return values(this.itemNodesById).filter((o) => {
        const itemNode = findDOMNode(o);
        if (!(itemNode instanceof HTMLElement)) {
          return false;
        }
        const cardOffset =
          itemNode.offsetTop - scrollTop + itemNode.offsetHeight;
        const itemLLKey = getIsReactElement(o) && o.props.llKey;

        /* If we want to keep rendered items rendered and the item we're on is already rendered... */
        if (
          shouldKeepItemsRendered &&
          itemComponentsToRender.find(
            (item) => getIsReactElement(item) && item.props.llKey === itemLLKey
          )
        ) {
          return true;
        }

        /* If setting a static height for the cards, we'll un-render items above the viewport
         * for performance */
        if (
          (this.props.itemDimensionsStyle as ItemsDimensionsStyleBaseWithHeight)
            .height
        ) {
          return (
            cardOffset <= offsetHeight + effectivePreloadBuffer &&
            cardOffset >= 0 - effectivePreloadBuffer
          );
          /* If setting a minHeight (which allows items to be a dynamic height) we'll need to keep
           * items above the viewport rendered to avoid the list items changing positioning on the
           * page as you scroll */
        } else if (
          (
            this.props
              .itemDimensionsStyle as ItemsDimensionsStyleBaseWithMinHeight
          ).minHeight
        ) {
          return cardOffset <= offsetHeight + effectivePreloadBuffer;
        } else {
          throw new Error(
            'Must provide either height or minHeight to in itemDimensionStyle prop'
          );
        }
      }) as any[];
      /* When called before the component mounts */
    } else {
      return [];
    }
  };

  getChildChecksum = (children: React.ReactNode): string => {
    return React.Children.toArray(children)
      .map((child) => (child ? this.getItemLLKey(child) : ''))
      .join('');
  };

  /* Determines which item components to render using `preloadBuffer` and renders
   * them in the list.  Executed (and throttled) on scroll */
  setItemsToRender = (
    options: { forceItemRenderRecalculation: boolean } = {
      forceItemRenderRecalculation: false,
    }
  ) => {
    const {
      children,
      onReachedBottom,
      onNotReachedBottom,
      isMoreToLoad,
      onScroll,
      shouldKeepItemsRendered,
      onRenderItems,
    } = this.props;
    const { itemComponentsToRender } = this.state;

    if (onReachedBottom) {
      const offsetHeight = this.getScrollableAncestorHeight();
      const scrollTop = this.getScrollableAncestorScrollPosition();

      if (offsetHeight !== null && scrollTop !== null) {
        const thisNode = findDOMNode(this);
        const atBottomScrollTop =
          thisNode && thisNode instanceof HTMLElement
            ? thisNode.clientHeight - offsetHeight
            : +Infinity;
        if (
          scrollTop > 0 &&
          atBottomScrollTop - scrollTop < REACHED_BOTTOM_BUFFER
        ) {
          onReachedBottom();

          if (isMoreToLoad) {
            this.setState({ localShowBottomLoadingIndicator: true });
            this.localShouldScrollToTopOnChildrenChange = false;
          }
        } else if (onNotReachedBottom) {
          onNotReachedBottom();
        }
      }
    }

    if (onScroll) {
      onScroll();
    }

    /* If we want to keep items rendered and all are already rendered exit now for perf optimization */
    if (
      !options.forceItemRenderRecalculation &&
      shouldKeepItemsRendered &&
      itemComponentsToRender.length === React.Children.count(children)
    ) {
      return;
    } else {
      const itemComponentsToRender = this.getItemComponentsToRender();
      this.setState({ itemComponentsToRender });

      if (onRenderItems) {
        onRenderItems(
          itemComponentsToRender.map(
            (item) => getIsReactElement(item) && item.props.llKey
          )
        );
      }
    }
  };

  /* Determines which items are within viewport and reports via callback */
  readAndReportItemsInViewport = () => {
    if (this.handleReportItemsInViewportTimeout) {
      window.clearTimeout(this.handleReportItemsInViewportTimeout);
    }

    if (this.props.handleReportItemsInViewport) {
      /* Debounce: only report items in viewport once scrolling stops */
      this.handleReportItemsInViewportTimeout = window.setTimeout(() => {
        const scrollTop = this.getScrollableAncestorScrollPosition();
        if (
          this.props.handleReportItemsInViewport &&
          typeof scrollTop === 'number'
        ) {
          this.props.handleReportItemsInViewport(
            this.getItemComponentsInViewport().map((o) =>
              getIsReactElement(o) ? o.props.llKey : ''
            ),
            scrollTop
          );
        }
      }, REPORT_ITEMS_IN_VIEWPORT_DELAY);
    }
  };

  cacheItemComponent = (node: React.ReactInstance | null) => {
    if (node && getIsReactElement(node)) {
      this.itemNodesById[node.props.llKey] = node;
    }
  };

  removeItemComponentFromCache = (key) => {
    delete this.itemNodesById[key];
  };

  getItemLLKey = (item) => {
    const { llKeyField } = this.props;
    return get(item, ['props'].concat(llKeyField.split('.')));
  };

  getItemCustomDimensionsStyle = (item) => {
    const { customDimensionsStyle } = this.props;
    return (
      customDimensionsStyle &&
      get(item, ['props'].concat(customDimensionsStyle.split('.')))
    );
  };

  render() {
    const {
      children,
      className,
      showBottomLoadingIndicator,
      itemDimensionsStyle,
      theme,
      dataHcName,
      dataHcLastUpdated,
      ariaLabel,
      shouldRenderAllChildren,
      parentHTMLEl = 'ul',
      childHTMLEl = 'li',
      style,
    } = this.props;
    const { localShowBottomLoadingIndicator } = this.state;
    const ParentHTMLEl = parentHTMLEl;

    return (
      <ParentHTMLEl
        data-hc-name={dataHcName}
        data-hc-last-updated={dataHcLastUpdated}
        className={classNames(className, theme.UlStyledAsDiv)}
        aria-label={ariaLabel}
        style={style}
      >
        {React.Children.map(children, (child) => {
          const key = (child && this.getItemLLKey(child)) as string | number;
          const childCustomDimensionsStyle =
            child && this.getItemCustomDimensionsStyle(child);
          return (
            /* This component must be the same vertical size both before and after
             * its child content is rendered for lazy loading to work best */
            child && (
              <LazilyRenderedComponent
                theme={theme}
                key={`${key}`}
                llKey={`${key}`}
                style={
                  childCustomDimensionsStyle
                    ? childCustomDimensionsStyle
                    : itemDimensionsStyle
                }
                removeComponentFromCache={this.removeItemComponentFromCache}
                shouldRenderChild={
                  shouldRenderAllChildren ||
                  !!this.state.itemComponentsToRender.find(
                    (o) => getIsReactElement(o) && o.props.llKey === `${key}`
                  )
                }
                ref={this.cacheItemComponent}
                childHTMLEl={childHTMLEl}
              >
                {child}
              </LazilyRenderedComponent>
            )
          );
        })}
        {(showBottomLoadingIndicator || localShowBottomLoadingIndicator) && (
          <div className={theme.LoadingMoreWrapper}>
            <LoadingSection
              theme={theme}
              className={theme.LoadingMoreIndicator}
              isLoading
            />
          </div>
        )}
      </ParentHTMLEl>
    );
  }
}

const ThemedLazilyRenderedList = themr(
  'LazilyRenderedList',
  defaultTheme
)(LazilyRenderedList);
export default ThemedLazilyRenderedList;
