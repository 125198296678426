/* Ported from react-input-range
 * https://github.com/davidchin/react-input-range */

export function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

/**
 * Convert a point into a percentage value
 * @param {Point} position
 * @param {ClientRect} clientRect
 * @return {number} Percentage value
 */
export function getPercentageFromPosition(position, clientRect) {
  const length = clientRect.width;
  const sizePerc = position.x / length;

  return sizePerc || 0;
}

/**
 * Convert a point into a model value
 * @param {Point} position
 * @param {number} minValue
 * @param {number} maxValue
 * @param {ClientRect} clientRect
 * @return {number}
 */
export function getValueFromPosition(position, minValue, maxValue, clientRect) {
  const sizePerc = getPercentageFromPosition(position, clientRect);
  const valueDiff = maxValue - minValue;

  return minValue + valueDiff * sizePerc;
}

/**
 * Convert props into a range value
 * @param {Object} props
 * @param {boolean} isMultiValue
 * @return {Range}
 */
export function getValueFromProps(props, isMultiValue) {
  if (isMultiValue) {
    return { ...props.value };
  }

  return {
    min: props.minValue,
    max: props.value,
  };
}

/**
 * Convert a model value into a percentage value
 * @param {number} value
 * @param {number} minValue
 * @param {number} maxValue
 * @return {number}
 */
export function getPercentageFromValue(value, minValue, maxValue) {
  const validValue = clamp(value, minValue, maxValue);
  const valueDiff = maxValue - minValue;
  const valuePerc = (validValue - minValue) / valueDiff;

  return valuePerc || 0;
}

/**
 * Convert model values into percentage values
 * @param {Range} values
 * @param {number} minValue
 * @param {number} maxValue
 * @return {Range}
 */
export function getPercentagesFromValues(values, minValue, maxValue) {
  return {
    min: getPercentageFromValue(values.min, minValue, maxValue),
    max: getPercentageFromValue(values.max, minValue, maxValue),
  };
}

/**
 * Convert a value into a point
 * @param {number} value
 * @param {number} minValue
 * @param {number} maxValue
 * @param {ClientRect} clientRect
 * @return {Point} Position
 */
export function getPositionFromValue(value, minValue, maxValue, clientRect) {
  const length = clientRect.width;
  const valuePerc = getPercentageFromValue(value, minValue, maxValue);
  const positionValue = valuePerc * length;

  return {
    x: positionValue,
    y: 0,
  };
}

/**
 * Convert a range of values into points
 * @param {Range} values
 * @param {number} minValue
 * @param {number} maxValue
 * @param {ClientRect} clientRect
 * @return {Range}
 */
export function getPositionsFromValues(values, minValue, maxValue, clientRect) {
  return {
    min: getPositionFromValue(values.min, minValue, maxValue, clientRect),
    max: getPositionFromValue(values.max, minValue, maxValue, clientRect),
  };
}

/**
 * Convert an event into a point
 * @param {Event} event
 * @param {ClientRect} clientRect
 * @return {Point}
 */
export function getPositionFromEvent(event, clientRect) {
  const length = clientRect.width;
  const { clientX } = event.touches ? event.touches[0] : event;

  return {
    x: clamp(clientX - clientRect.left, 0, length),
    y: 0,
  };
}

/**
 * Convert a value into a step value
 * @param {number} value
 * @param {number} valuePerStep
 * @return {number}
 */
export function getStepValueFromValue(value, valuePerStep) {
  return Math.round(value / valuePerStep) * valuePerStep;
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Calculate the distance between pointA and pointB
 * @param {Point} pointA
 * @param {Point} pointB
 * @return {number} Distance
 */
export function distanceTo(pointA, pointB) {
  const xDiff = (pointB.x - pointA.x) ** 2;
  const yDiff = (pointB.y - pointA.y) ** 2;

  return Math.sqrt(xDiff + yDiff);
}

/**
 * Check if a value is defined
 * @param {*} value
 * @return {boolean}
 */
export function isDefined(value) {
  return value !== undefined && value !== null;
}

/**
 * Check if a value is a number
 * @param {*} value
 * @return {boolean}
 */
export function isNumber(value) {
  return typeof value === 'number';
}

/**
 * Check if a value is an object
 * @param {*} value
 * @return {boolean}
 */
export function isObject(value) {
  return value !== null && typeof value === 'object';
}

/**
 * Calculate the absolute difference between two numbers
 * @param {number} numA
 * @param {number} numB
 * @return {number}
 */
export function length(numA, numB) {
  return Math.abs(numA - numB);
}

/**
 * Calculate the decimal precision of a number, returning the number of digits to the right of the decimal
 * i.e. getDigitsAfterDecimal(1.2334) => 4
 */
export const getDigitsAfterDecimal = (num: number) => {
  const numStr = String(num);
  if (numStr.includes('.')) {
    return numStr.split('.')[1].length;
  }
  return 0;
};

/**
 * Round a floating point number to a certain decimal precision
 * i.e. roundToDecimals(1.9734, 2) => 1.98
 */
export const roundToDecimals = (num: number, numDigits: number) => {
  return parseFloat(num.toFixed(numDigits));
};
