import { differenceWith, uniqBy } from 'lodash';

import { Action } from '@client/store/actions';
import {
  ALERTS_MARK_ALL_READ,
  FETCH_ALERTS_SUCCESS,
  FETCH_ALERTS_UNREAD_COUNT_SUCCESS,
  FETCH_ALERT_MLS_DETAILS_SUCCESS,
  RESET_UNREAD_ALERTS_SUCCESS,
} from '@client/store/actions/alerts.actions';
import { STATUSES, Status } from '@client/store/constants';
import { Alert, AlertData } from '@client/store/types/alerts';

export type AlertsState = {
  status: Status;
  items: Alert[];
  unreadAlertsCount: number;
  hasResetUnreadAlerts: boolean;
  markAsReadUrl: string | null;
  nextPageUrl: string | null;
};

const INITIAL_STATE: AlertsState = {
  status: STATUSES.INIT,
  items: [],
  unreadAlertsCount: 0,
  hasResetUnreadAlerts: false,
  markAsReadUrl: null,
  nextPageUrl: null,
};

export default function alertsReducer(
  state = INITIAL_STATE,
  action: Action
): AlertsState {
  switch (action.type) {
    case FETCH_ALERTS_UNREAD_COUNT_SUCCESS:
      return {
        ...state,
        unreadAlertsCount: action.payload.unreadAlertsCount,
      };
    case FETCH_ALERTS_SUCCESS:
      const _itemsInResponseButNotState = uniqBy(
        differenceWith(
          action.payload.alerts,
          state.items,
          (respVal: AlertData, stateVal: Alert) => {
            return stateVal.id === respVal.id;
          }
        )
          .filter((alert: AlertData) => alert.notification.data)
          .map((alert: AlertData) => {
            const photoUrl = alert.notification.data.photo_url;

            return {
              propertyDetails: {
                fullStreetAddress:
                  alert.notification.data.details.address_details.address,
                streetAddress:
                  alert.notification.data.details.address_details.address,
                slug: alert.notification.data.details.address_details.slug,
                city: alert.notification.data.details.address_details.city,
                state: alert.notification.data.details.address_details.state,
                zipcode:
                  alert.notification.data.details.address_details.zipcode,
                unit: alert.notification.data.details.address_details.unit,
                fullAddress:
                  alert.notification.data.details.address_details.address_full,
                listingOfficeName:
                  alert.notification.data.details?.listing_office ?? null,
                latitude:
                  alert.notification.data.details.address_details.latitude,
                longitude:
                  alert.notification.data.details.address_details.longitude,
                sqFt: alert.notification.data.details.sqft,
                baths: alert.notification.data.details.baths,
                beds: alert.notification.data.details.beds,
                hcMlsId: alert.notification.data.details?.mls_id ?? null,
                hcAddressId: alert.notification.data.details.address_id,
                status:
                  alert.notification.data.details.new_details?.mls_state ??
                  null,
                listPrice:
                  alert.notification.data.details.new_details?.list_price ??
                  null,
                dateOfSale:
                  alert.notification.data.details.new_details?.sale_date ??
                  null,
                dateOfListing:
                  alert.notification.data.details.new_details?.list_date ??
                  null,
                salePrice:
                  alert.notification.data.details.new_details?.sale_price ??
                  null,
                photos: !photoUrl ? [] : [photoUrl],
              },
              addressId: alert.notification.data.details.address_id,
              id: alert.id,
              alertCreationDate: alert.notification.created,
              isUnread: !alert.notification.read,
              type: alert.notification.data.type,
              subtype: alert.notification.data.subtype,
              priorListPrice:
                alert.notification.data.details.prior_details?.list_price,
            };
          }),
        'id'
      );
      return {
        ...state,
        items: [...state.items, ..._itemsInResponseButNotState].sort((a, b) => {
          /* Newest first */
          /** Using getTime to make typeScript happy */
          return (
            new Date(b.alertCreationDate).getTime() -
            new Date(a.alertCreationDate).getTime()
          );
        }),
        markAsReadUrl: action.payload.markAsReadUrl,
        nextPageUrl: !action.payload.nextPageUrl
          ? null
          : action.payload.nextPageUrl,
        status: STATUSES.SUCCESS,
      };
    case ALERTS_MARK_ALL_READ:
      return {
        ...state,
        items: [
          ...state.items.map((alert) => ({
            ...alert,
            isUnread: false,
          })),
        ],
        status: STATUSES.SUCCESS,
        unreadAlertsCount: INITIAL_STATE.unreadAlertsCount,
        hasResetUnreadAlerts: false,
      };
    case RESET_UNREAD_ALERTS_SUCCESS:
      return {
        ...state,
        hasResetUnreadAlerts: true,
      };
    case FETCH_ALERT_MLS_DETAILS_SUCCESS:
      const { slug, mlsPhotoOverlayDetails } = action.payload;
      const alert = state.items.find(
        (item) => item.propertyDetails.slug === slug
      );
      const { logoOverlay, photosLogoOverlay } =
        mlsPhotoOverlayDetails?.regulations ?? {};
      if (alert) {
        return {
          ...state,
          items: [
            ...state.items.map((alert) => {
              if (alert.propertyDetails.slug === slug) {
                return {
                  ...alert,
                  propertyDetails: {
                    ...alert?.propertyDetails,
                    mlsLogoOverlay: photosLogoOverlay ? logoOverlay : null,
                  },
                };
              } else {
                return alert;
              }
            }),
          ],
        };
      } else {
        return state;
      }
    default:
      return state;
  }
}
