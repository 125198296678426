import { values, uniqBy } from 'lodash';

import {
  LegendBreaksBin,
  LegendBreaksData,
  MapLegendStats,
} from '@client/store/types/maps';
import {
  LAYER_METRICS,
  LAYER_GROUP_FEATURE_COLORS,
} from '@client/store/map-constants';
import { STATUSES, Status } from '@client/store/constants';
import {
  FETCH_MAP_LAYER_LEGEND_BREAKS_SUCCESS,
  MAP_LAYER_LEGEND_BREAKS_NOT_AVAILABLE,
} from '@client/store/actions/map-legend-breaks.actions';
import { getLayerGroupIdForLayerMetric } from '@client/utils/maps.utils';
import { Action } from '@client/store/actions';

export type MapLegendBreaksState = {
  status: Status;
  data: LegendBreaksData;
};

const INITIAL_STATE: MapLegendBreaksState = {
  status: STATUSES.INIT,
  data: {},
};

/**
 * Given a breaks mapping API object, return an object containing legend break ranges
 * and colors for a single metric
 * @param  {string} metric - the layer metric
 * @param  {object} breaksMapping - an object containing breaks for all metrics
 * @return {object}
 */
function getLegendBreaksForMetric(
  metric: string,
  breaksMapping: MapLegendStats
) {
  const breakValues: number[] = breaksMapping[metric].bins;
  const groupIdForMetric: string | undefined =
    getLayerGroupIdForLayerMetric(metric);
  let breaks: LegendBreaksBin[] = [];

  if (!groupIdForMetric) {
    throw new Error(`groupId not defined for metric ${metric}`);
  }

  if (breakValues && breakValues.length) {
    for (let i = 0; i < breakValues.length - 1; i++) {
      breaks.push([
        breakValues[i],
        breakValues[i + 1],
        LAYER_GROUP_FEATURE_COLORS[groupIdForMetric][i],
        null,
        null,
      ]);
    }
  } else {
    throw new Error(
      `Legend breaks must be an array with length > 0. You passed ${breaks}`
    );
  }
  /* We're only using the lower bound of each bin to compute the Mapbox color assignment expression */
  return uniqBy(breaks, (item) => item[0]);
}

export default function mapsSharedReducer(
  state = INITIAL_STATE,
  action: Action
): MapLegendBreaksState {
  switch (action.type) {
    case FETCH_MAP_LAYER_LEGEND_BREAKS_SUCCESS:
      return {
        ...state,
        status: STATUSES.SUCCESS,
        data: values(LAYER_METRICS)
          .map((metric) => ({
            metric,
            breaks:
              action.payload.metricStats[metric] &&
              getLegendBreaksForMetric(metric, action.payload.metricStats),
          }))
          .filter((item) => item.breaks)
          .reduce((mem, curr) => {
            mem[curr.metric] = curr.breaks;
            return mem;
          }, {}),
      };
    case MAP_LAYER_LEGEND_BREAKS_NOT_AVAILABLE:
      return {
        ...state,
        status: STATUSES.SUCCESS,
        data: INITIAL_STATE.data,
      };
    default:
      return state;
  }
}
