import { useSelector } from 'react-redux';

import PillButton from '@client/components/generic/PillButton';
import theme from '@client/css-modules/CTAButtonPDP.css';
import { useLenderCtaUrlModifier } from '@client/hooks/lender-cta-config.hooks';
import { useNativeAppLenderCTAClick } from '@client/hooks/native-app-integration.hooks';
import { useOutboundCtaUrl } from '@client/hooks/outbound-cta-url.hooks';
import { useSecondaryCtaPdpConfig } from '@client/hooks/secondary-cta-pdp-config.hooks';
import { getIsInsideNativeApp } from '@client/store/selectors/cobranding.selectors';
import { getActiveHomeownerOrPDPSlug } from '@client/store/selectors/router.selectors';
import { AnalyticsDefaultLenderCTAForPDP } from '@client/store/types/analytics';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';

export const SecondaryCTAButtonPDP = () => {
  const secondaryCtaPdpConfig = useSecondaryCtaPdpConfig();
  const nativeAppLenderCTAClick = useNativeAppLenderCTAClick();
  const isInsideNativeApp = useSelector(getIsInsideNativeApp);
  const contextualAddressSlug = useSelector(getActiveHomeownerOrPDPSlug);
  const { ctaText, ctaUrl, ctaButtonBgColor, ctaTextColor, nativeAppNavKey } =
    secondaryCtaPdpConfig;
  const modifiedCtaUrl = useLenderCtaUrlModifier(ctaUrl);
  const outboundCtaUrl = useOutboundCtaUrl(modifiedCtaUrl);

  if (!outboundCtaUrl || !ctaText || !ctaButtonBgColor || !ctaTextColor) {
    return null;
  }

  const onClick = () => {
    if (isInsideNativeApp && nativeAppNavKey) {
      nativeAppLenderCTAClick(nativeAppNavKey, {
        addressSlug: contextualAddressSlug,
      });
    } else {
      window.open(outboundCtaUrl, '_blank');
    }
  };
  const clickEventDataJson: AnalyticsDefaultLenderCTAForPDP = {
    area: 'pdp',
    cta_url: outboundCtaUrl,
    button_copy: ctaText,
  };

  return (
    <>
      {outboundCtaUrl && ctaText && (
        <div className={theme.CTAButtonPDP}>
          <PillButton
            data-event-name={'click_secondary_cta_pdp'}
            data-parent-event-name={'secondary_cta_pdp'}
            data-event-data-json={JSON.stringify(clickEventDataJson)}
            ariaLabel={ctaText}
            className={theme.CTAButton}
            onClick={onClick}
            onKeyDown={onEnterOrSpaceKey(onClick)}
            customBackgroundColor={ctaButtonBgColor || undefined}
            customTextColor={ctaTextColor || undefined}
          >
            {ctaText}
          </PillButton>
        </div>
      )}
    </>
  );
};
