import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import ConfirmModal from './ConfirmModal';
import ConfirmModalChase from './ConfirmModalChase';
import { ConfirmModalProps } from '@client/store/types/cobranded-components/confirm-modal';
import { COBRAND_IDS } from '@client/store/types/cobranding';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return ConfirmModalChase;
    default:
      return ConfirmModal;
  }
};

const ConfirmModalCobranded: React.FC<ConfirmModalProps> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default ConfirmModalCobranded;
