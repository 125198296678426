import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import AvmBreakdownAvmValueChase from './AvmBreakdownAvmValueChase';
import AvmBreakdownAvmValue from './AvmBreakdownAvmValue';
import { AvmBreakdownAvmValueProps } from '@client/store/types/cobranded-components/avm-breakdown-avm-value';
import { COBRAND_IDS } from '@client/store/types/cobranding';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return AvmBreakdownAvmValueChase;
    default:
      return AvmBreakdownAvmValue;
  }
};

const AvmBreakdownAvmValueCobranded: React.FC<AvmBreakdownAvmValueProps> = (
  props
) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default AvmBreakdownAvmValueCobranded;
