import React, { Component } from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import { PropertyIntroDetails } from '@client/store/types/property';
import { titleFormatter } from '@client/utils/formatter.utils';
import defaultTheme from '@client/css-modules/PropertyDataTable.css';

export const LIST_ITEM_ANIMATION_VARIANTS = {
  animate: ({ i }) => ({
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.05 + i * 0.05,
      duration: 0.15,
      easing: 'easeIn',
    },
  }),
  initial: {
    opacity: 0,
    scale: 0.95,
  },
};

type Props = {
  content: PropertyIntroDetails;
  mlsNameAbbreviated: string | null;
  theme: Theme;
};

class PropertyDataTable extends Component<Props> {
  render() {
    const { content, theme, mlsNameAbbreviated } = this.props;

    return (
      <>
        <table className={theme.PropertyDataTable}>
          <caption className={theme.Heading}>
            Additional home details
          </caption>
          <tbody className={theme.DataList}>
            {Object.keys(content).map((key, index) => {
              const data = content[key] as typeof content[keyof typeof content];
              const title = titleFormatter(key);

              return (
                <tr key={title} className={theme.propDetailsItem}>
                  <th scope="row" className={theme.propDetailsType} data-type={title}>
                    {title}
                  </th>
                  <td className={theme.propDetailsData}>
                    {typeof data === 'boolean'
                      ? data === false
                        ? 'No'
                        : 'Yes'
                      : data}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {mlsNameAbbreviated && (
          <div key="legal" className={theme.Legal}>
            {`Data provided by ${mlsNameAbbreviated}.`}
          </div>
        )}
      </>
    );
  }
}

export default themr('PropertyDataTable', defaultTheme)(PropertyDataTable);
