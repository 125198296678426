import loadable from '@loadable/component';
import { values } from 'lodash';

import NativeAppError from '@client/components/NativeAppError';
import { PageLoadingSpinner } from '@client/components/PageLoadingSpinner';
import PrivacyPolicyPageContainer from '@client/containers/privacy-policy-page.container';
import PropertyPageContainer from '@client/containers/property-page.container';
import SavedSearchesContainer from '@client/containers/saved-searches.container';
import SearchPageContainer from '@client/containers/search-page.container';
import TermsOfUsePageContainer from '@client/containers/terms-of-use-page.container';
import withHomeownerPropertyEventReporting from '@client/hocs/with-homeowner-property-event-reporting';
import withLODirectMobileBlock from '@client/hocs/with-lo-direct-mobile-block';
import withLODirectSubscriptionVerification from '@client/hocs/with-lo-direct-subscription-verification';
import AdminAnalyticsPage from '@client/pages/AdminAnalyticsPage';
import UnreadAlertsResetPage from '@client/pages/UnreadAlertsResetPage';
import UnsubscribePage from '@client/pages/UnsubscribePage';
import WelcomeTransitionPageChase from '@client/pages/WelcomeTransitionPageChase';
import routeDisplayCustomerAdminPortal from '@client/routes/admin.route';
import routeCity from '@client/routes/city.route';
import routeConfirmUser from '@client/routes/confirm-user.route';
import {
  COMMON_QUERY_PARAMS,
  LO_DIRECT_LOGIN_REDIRECT_KEY,
  LO_DIRECT_LOGOUT_QUERY_KEY,
  LO_DIRECT_SIGNUP_PLANS_BACK_VIEW_KEY,
  PAGE_TITLE_FOR_VIEW,
  PROFINDER_QUERY_STRING,
  ROOT_PATH,
  UNSUBSCRIBE_QUERY_STRING,
  USER_UNSUBSCRIBE_QUERY_STRING,
  USER_UNSUBSCRIBE_QUERY_TOKEN,
  View,
  VIEW_PATHS,
} from '@client/routes/constants';
import routeFeedback from '@client/routes/feedback.route';
import routeForgotPassword from '@client/routes/forgot-password.route';
import routeHomeownerManageHomes from '@client/routes/homeowner-manage-homes.route';
import routeHomeownerPropertyDetails from '@client/routes/homeowner-property-details.route';
import routeLODirectResetPassword from '@client/routes/lo-direct-reset-password.route';
import routeLogin from '@client/routes/login.route';
import routeLogout from '@client/routes/logout.route';
import routePlace from '@client/routes/place.route';
import routeResetPassword from '@client/routes/reset-password.route';
import routeSearch from '@client/routes/search.route';
import routeSignUp from '@client/routes/sign-up.route';
import routeUnsubscribe from '@client/routes/unsubscribe.route';
import routeUserUnsubscribe from '@client/routes/user_unsubscribe.route';
import routeZipcode from '@client/routes/zipcode.route';
import {
  PDP_PUBLIC_OWNER_TOGGLE,
  SEARCH_ACTIVE_VIEW_URL_PARAM_KEY,
  SEARCH_MAP_DESKTOP_FILTERS_SHOWN_URL_KEY,
  SEARCH_MAP_LOCATION_URL_PARAM_KEY,
  SEARCH_MAP_MOBILE_FILTERS_SHOWN_URL_KEY,
  SEARCH_MAP_SELECTED_ADDRESS_SLUG_URL_PARAM_KEY,
  WELCOME_SEARCH_ONLY_ADDRESS_URL_KEY,
  WELCOME_TRANSITION_INSET_ONLY_ADDRESS_URL_KEY,
} from '@client/store/constants';
import { FILTER_KEY_ABBREVIATIONS } from '@client/store/filter-constants';
import { HOMEOWNER_HEADLINE_KEY } from '@client/store/homeowner-constants';
import { SSO_QUERY_KEYS } from '@client/store/sso-constants';
import { PartialObj } from '@client/types/utils';
import { handleErrors } from '@client/utils/saga.utils';
import { RouteDefOptionType } from '@src/redux-saga-router-plus/types';

const pageFallbackOptions = { fallback: PageLoadingSpinner };

const AdminPage = loadable(
  () => import('@client/pages/AdminPage'),
  pageFallbackOptions
);
const AdminAPIPage = loadable(
  () => import('@client/pages/AdminAPIPage'),
  pageFallbackOptions
);
const AdminSupportPage = loadable(
  () => import('@client/pages/AdminSupportPage'),
  pageFallbackOptions
);

const AlertsPageContainer = loadable(
  () => import('@client/containers/alerts.container'),
  pageFallbackOptions
);
const BrokerageInfoPage = loadable(
  () =>
    import('@client/components/BrokerageInfoPage/BrokerageInfoPageCobranded'),
  pageFallbackOptions
);
const CHDClientSignUpPage = loadable(
  () => import('@client/pages/CHDClientSignUpPage'),
  pageFallbackOptions
);
const CHDClientInviteSignUpPage = loadable(
  () => import('@client/pages/CHDClientInviteSignUpPage'),
  pageFallbackOptions
);
const CHOProFinderPageContainer = loadable(
  () => import('@client/containers/cho-pro-finder-page.container'),
  pageFallbackOptions
);
const ConciergeTeamPage = loadable(
  () => import('@client/pages/ConciergeTeamPage'),
  pageFallbackOptions
);
const ConfirmUserPageContainer = loadable(
  () => import('@client/containers/confirm-user.container'),
  pageFallbackOptions
);
const FeedbackPageContainer = loadable(
  () => import('@client/containers/feedback.container'),
  pageFallbackOptions
);
const ForgotPasswordPageContainer = loadable(
  () => import('@client/containers/forgot-password.container'),
  pageFallbackOptions
);
const HomeImprovementPageContainer = loadable(
  () => import('@client/containers/home-improvement-page.container'),
  pageFallbackOptions
);
const HomeInsurancePage = loadable(
  () => import('@client/pages/HomeInsurancePage'),
  pageFallbackOptions
);
const Homepage = loadable(
  () => import('@client/pages/Homepage'),
  pageFallbackOptions
);
const HomeValuationFullHistoryPage = loadable(
  () => import('@client/pages/HomeValuationFullHistoryPage'),
  pageFallbackOptions
);
const HomeownerManageHomesPageContainer = loadable(
  () => import('@client/containers/homeowner-manage-homes.container'),
  pageFallbackOptions
);
const HomeownerWelcomePageContainer = loadable(
  () => import('@client/containers/homeowner-welcome-page'),
  pageFallbackOptions
);
const HODashboardPageContainer = loadable(
  () => import('@client/containers/ho-dashboard-page.container'),
  pageFallbackOptions
);
const HOHomeDetailsPage = loadable(
  () => import('@client/pages/HOHomeDetailsPage'),
  pageFallbackOptions
);
const LegalPage = loadable(
  () => import('@client/pages/LegalPage'),
  pageFallbackOptions
);
const LenderHomePage = loadable(
  () => import('@client/pages/LenderHomePage'),
  pageFallbackOptions
);
const LoanDetailsPage = loadable(
  () => import('@client/pages/LoanDetailsPage'),
  pageFallbackOptions
);
const LODirectAccountView = loadable(
  () => import('@client/components/LODirect/LODirectAccountView'),
  pageFallbackOptions
);
const LODirectAccountPlansView = loadable(
  () => import('@client/components/LODirect/LODirectAccountPlansView'),
  pageFallbackOptions
);
const LODirectActivityView = loadable(
  () => import('@client/components/LODirect/LODirectActivityView'),
  pageFallbackOptions
);
const LODirectClientsView = loadable(
  () => import('@client/components/LODirect/LODirectClientsView'),
  pageFallbackOptions
);
const LODirectForgotPasswordView = loadable(
  () => import('@client/components/LODirect/LODirectForgotPasswordView'),
  pageFallbackOptions
);
const LODirectLeadsView = loadable(
  () => import('@client/components/LODirect/LODirectLeadsView'),
  pageFallbackOptions
);
const LODirectLoginView = loadable(
  () => import('@client/components/LODirect/LODirectLoginView'),
  pageFallbackOptions
);
const LODirectResetPasswordView = loadable(
  () => import('@client/components/LODirect/LODirectResetPasswordView'),
  pageFallbackOptions
);
const LODirectResubscribeView = loadable(
  () => import('@client/components/LODirect/LoDirectResubscribeView'),
  pageFallbackOptions
);
const LODirectInviteSignupView = loadable(
  () => import('@client/components/LODirect/LODirectInviteSignupView'),
  pageFallbackOptions
);
const LODirectSignupView = loadable(
  () => import('@client/components/LODirect/LODirectSignupView'),
  pageFallbackOptions
);
const LODirectSignupPlansView = loadable(
  () => import('@client/components/LODirect/LODirectSignupPlansView'),
  pageFallbackOptions
);
const LODirectSubscribeView = loadable(
  () => import('@client/components/LODirect/LODirectSubscribeView')
);
const LODirectSupportView = loadable(
  () => import('@client/components/LODirect/LODirectSupportView'),
  pageFallbackOptions
);
const LoginPage = loadable(
  () => import('@client/pages/LoginPage'),
  pageFallbackOptions
);
const MlsNumberPage = loadable(
  () => import('@client/pages/MlsNumberPage'),
  pageFallbackOptions
);
const NotificationsPage = loadable(
  () => import('@client/pages/NotificationsPage'),
  pageFallbackOptions
);
const Page404 = loadable(
  () => import('@client/pages/404'),
  pageFallbackOptions
);
const PartnerAPICredentialPage = loadable(
  () => import('@client/pages/PartnerAPICredentialPage'),
  pageFallbackOptions
);
const ResetPasswordPageContainer = loadable(
  () => import('@client/containers/reset-password.container'),
  pageFallbackOptions
);
const SettingsContainer = loadable(
  () => import('@client/containers/settings.container'),
  pageFallbackOptions
);
const SignupPage = loadable(
  () => import('@client/pages/SignUpPage'),
  pageFallbackOptions
);
const TermsAndDefinitionsPage = loadable(
  () => import('@client/pages/TermsAndDefinitionsPage'),
  pageFallbackOptions
);
const TestPropertiesPageContainer = loadable(
  () => import('@client/containers/test-properties-page.container'),
  pageFallbackOptions
);
const UserProfilePageContainer = loadable(
  () => import('@client/containers/user-profile.container'),
  pageFallbackOptions
);
const UnauthorizedAccessPage = loadable(
  () => import('@client/pages/UnauthorizedAccessPage'),
  pageFallbackOptions
);
const WatchListPageContainer = loadable(
  () => import('@client/containers/watchlist.container'),
  pageFallbackOptions
);
const WelcomeSearchPageChase = loadable(
  () => import('@client/pages/WelcomeSearchPageChase'),
  pageFallbackOptions
);
const WelcomeTransitionInsetChasePage = loadable(
  () => import('@client/pages/WelcomeTransitionInsetChase'),
  pageFallbackOptions
);
const HomeVerificationPage = loadable(
  () => import('@client/pages/HomeVerificationPage'),
  pageFallbackOptions
);
const WidgetDemoHeroPage = loadable(
  () => import('@client/pages/WidgetDemoHeroPage'),
  pageFallbackOptions
);
const WidgetDemoBannerPage = loadable(
  () => import('@client/pages/WidgetDemoBannerPage'),
  pageFallbackOptions
);
const NativeAppIntegrationTestPage = loadable(
  () => import('@client/pages/NativeAppIntegrationTestPage'),
  pageFallbackOptions
);

/* Breaking these out to allow one of them to be dynamically assigned as the root route (/) based on a feature flag */
const SEARCH_PAGE_CONFIG = {
  path: VIEW_PATHS[View.SEARCH],
  query: [
    SEARCH_MAP_LOCATION_URL_PARAM_KEY,
    SEARCH_MAP_SELECTED_ADDRESS_SLUG_URL_PARAM_KEY,
    SEARCH_MAP_MOBILE_FILTERS_SHOWN_URL_KEY,
    SEARCH_ACTIVE_VIEW_URL_PARAM_KEY,
    SEARCH_MAP_DESKTOP_FILTERS_SHOWN_URL_KEY,
    SSO_QUERY_KEYS.SSO_ACTION,
    SSO_QUERY_KEYS.SLUG,
    ...values(FILTER_KEY_ABBREVIATIONS),
  ],
  component: SearchPageContainer,
  saga: handleErrors(routeSearch),
};
const HOMEPAGE_CONFIG = {
  path: VIEW_PATHS[View.HOMEPAGE],
  component: Homepage,
};

// Sample ROUTES object:
// {
//   <view_id>: {
//     path: '/path/with/:params/:optional?',  = Url w/ params defined (:<name> = required | :<name>? = optional)
//     query: ['accepted', 'query', 'params'], = Accepted query string names
//     loginRequired: boolean,                    = Ensure user is logged in before resolving
//     logoutRequired: boolean,                   = Ensure user is NOT logged in before resolving
//     saga: <SagaToRunWhenPathIsMatched>      = Saga that is run when the url is visited
//     component: <ReactComponentToRender>     = The component that is rendered when the route resolves
//   }
// }
export const STATIC_ROUTE_CONFIG = {
  ...(process.env.NODE_ENV === 'development' && {
    [View.TEST_SVGS]: {
      path: VIEW_PATHS[View.TEST_SVGS],
      title: PAGE_TITLE_FOR_VIEW[View.TEST_SVGS],
      component: require('@client/pages/TestSvgsPage').default,
    },
  }),
  [View.ADMIN]: {
    path: VIEW_PATHS[View.ADMIN],
    title: PAGE_TITLE_FOR_VIEW[View.ADMIN],
    component: AdminPage,
    saga: routeDisplayCustomerAdminPortal,
  },
  [View.ADMIN_ANALYTICS]: {
    path: VIEW_PATHS[View.ADMIN_ANALYTICS],
    title: PAGE_TITLE_FOR_VIEW[View.ADMIN],
    component: AdminAnalyticsPage,
    saga: routeDisplayCustomerAdminPortal,
  },
  // [VIEWS.ADMIN_ROI]: {
  //   path: VIEW_PATHS[VIEWS.ADMIN_ROI],
  //   title: PAGE_TITLE_FOR_VIEW[VIEWS.ADMIN],
  //   component: AdminROIPage,
  //   saga: routeDisplayCustomerAdminPortal,
  // },
  [View.ADMIN_API]: {
    path: VIEW_PATHS[View.ADMIN_API],
    title: PAGE_TITLE_FOR_VIEW[View.ADMIN],
    component: AdminAPIPage,
    saga: routeDisplayCustomerAdminPortal,
  },
  [View.ADMIN_SUPPORT]: {
    path: VIEW_PATHS[View.ADMIN_SUPPORT],
    title: PAGE_TITLE_FOR_VIEW[View.ADMIN],
    component: AdminSupportPage,
    saga: routeDisplayCustomerAdminPortal,
  },
  [View.ALERTS]: {
    path: VIEW_PATHS[View.ALERTS],
    title: PAGE_TITLE_FOR_VIEW[View.ALERTS],
    component: AlertsPageContainer,
  },
  [View.BROKERAGE_INFO]: {
    path: VIEW_PATHS[View.BROKERAGE_INFO],
    title: PAGE_TITLE_FOR_VIEW[View.BROKERAGE_INFO],
    component: BrokerageInfoPage,
  },
  [View.CHD_CLIENT_SIGN_UP]: {
    path: VIEW_PATHS[View.CHD_CLIENT_SIGN_UP],
    title: PAGE_TITLE_FOR_VIEW[View.CHD_CLIENT_SIGN_UP],
    component: CHDClientSignUpPage,
  },
  [View.CHD_CLIENT_INVITE_SIGN_UP]: {
    path: VIEW_PATHS[View.CHD_CLIENT_INVITE_SIGN_UP],
    title: PAGE_TITLE_FOR_VIEW[View.CHD_CLIENT_INVITE_SIGN_UP],
    component: CHDClientInviteSignUpPage,
  },
  [View.CITIES]: {
    path: VIEW_PATHS[View.CITIES],
    // Title handled via saga so that we can use the city name
    saga: handleErrors(routeCity),
    component: SearchPageContainer,
  },
  [View.CONCIERGE_TEAM]: {
    path: VIEW_PATHS[View.CONCIERGE_TEAM],
    title: PAGE_TITLE_FOR_VIEW[View.CONCIERGE_TEAM],
    component: ConciergeTeamPage,
  },
  [View.CONFIRM_USER]: {
    path: VIEW_PATHS[View.CONFIRM_USER],
    title: PAGE_TITLE_FOR_VIEW[View.CONFIRM_USER],
    query: ['token'],
    component: ConfirmUserPageContainer,
    saga: handleErrors(routeConfirmUser),
  },
  [View.NATIVE_APP_ERROR]: {
    path: VIEW_PATHS[View.NATIVE_APP_ERROR],
    title: PAGE_TITLE_FOR_VIEW[View.NATIVE_APP_ERROR],
    component: NativeAppError,
  },
  [View.FEEDBACK]: {
    path: VIEW_PATHS[View.FEEDBACK],
    title: PAGE_TITLE_FOR_VIEW[View.FEEDBACK],
    component: FeedbackPageContainer,
    saga: handleErrors(routeFeedback),
  },
  [View.FORGOT_PASSWORD]: {
    path: VIEW_PATHS[View.FORGOT_PASSWORD],
    title: PAGE_TITLE_FOR_VIEW[View.FORGOT_PASSWORD],
    component: ForgotPasswordPageContainer,
    saga: handleErrors(routeForgotPassword),
    logoutRequired: true,
  },
  [View.HOMEOWNER]: {
    path: VIEW_PATHS[View.HOMEOWNER],
    query: ['ref', 'code', HOMEOWNER_HEADLINE_KEY],
    component: HomeownerWelcomePageContainer,
  },
  [View.HOMEOWNER_CLAIMED_HOMES]: {
    path: VIEW_PATHS[View.HOMEOWNER_CLAIMED_HOMES],
    query: ['ref', 'code', HOMEOWNER_HEADLINE_KEY],
    component: HomeownerManageHomesPageContainer,
    saga: routeHomeownerManageHomes,
  },
  [View.HOME_DETAILS]: {
    path: VIEW_PATHS[View.HOME_DETAILS],
    title: PAGE_TITLE_FOR_VIEW[View.HOME_DETAILS],
    query: ['ref', 'code', HOMEOWNER_HEADLINE_KEY],
    component: withHomeownerPropertyEventReporting(HOHomeDetailsPage),
  },
  [View.HOMEOWNER_PROPERTY_DETAILS]: {
    path: VIEW_PATHS[View.HOMEOWNER_PROPERTY_DETAILS],
    query: ['ref', 'code', PDP_PUBLIC_OWNER_TOGGLE],
    component: withHomeownerPropertyEventReporting(HODashboardPageContainer),
    saga: routeHomeownerPropertyDetails,
  },
  [View.HOME_IMPROVEMENT]: {
    path: VIEW_PATHS[View.HOME_IMPROVEMENT],
    component: withHomeownerPropertyEventReporting(
      HomeImprovementPageContainer
    ),
    loginRequired: true,
  },
  [View.HOME_INSURANCE]: {
    path: VIEW_PATHS[View.HOME_INSURANCE],
    component: HomeInsurancePage,
  },
  [View.HOME_VALUATION_FULL_HISTORY]: {
    path: VIEW_PATHS[View.HOME_VALUATION_FULL_HISTORY],
    component: withHomeownerPropertyEventReporting(
      HomeValuationFullHistoryPage
    ),
  },
  [View.PRO_FINDER]: {
    path: VIEW_PATHS[View.PRO_FINDER],
    query: [PROFINDER_QUERY_STRING],
    component: withHomeownerPropertyEventReporting(CHOProFinderPageContainer),
    loginRequired: true,
  },
  [View.LEGAL]: {
    path: VIEW_PATHS[View.LEGAL],
    title: PAGE_TITLE_FOR_VIEW[View.LEGAL],
    component: LegalPage,
  },
  [View.LOAN_DETAILS]: {
    path: VIEW_PATHS[View.LOAN_DETAILS],
    title: PAGE_TITLE_FOR_VIEW[View.LOAN_DETAILS],
    component: withHomeownerPropertyEventReporting(LoanDetailsPage),
  },
  [View.LO_DIRECT_ACCOUNT]: {
    path: VIEW_PATHS[View.LO_DIRECT_ACCOUNT],
    title: PAGE_TITLE_FOR_VIEW[View.LO_DIRECT_ACCOUNT],
    component: withLODirectMobileBlock(
      withLODirectSubscriptionVerification(LODirectAccountView)
    ),
  },
  [View.LO_DIRECT_ACCOUNT_PLANS]: {
    path: VIEW_PATHS[View.LO_DIRECT_ACCOUNT_PLANS],
    title: PAGE_TITLE_FOR_VIEW[View.LO_DIRECT_ACCOUNT_PLANS],
    component: LODirectAccountPlansView,
  },
  [View.LO_DIRECT_ACTIVITY]: {
    path: VIEW_PATHS[View.LO_DIRECT_ACTIVITY],
    title: PAGE_TITLE_FOR_VIEW[View.LO_DIRECT_ACTIVITY],
    component: withLODirectMobileBlock(
      withLODirectSubscriptionVerification(LODirectActivityView)
    ),
  },
  [View.LO_DIRECT_CLIENTS]: {
    path: VIEW_PATHS[View.LO_DIRECT_CLIENTS],
    title: PAGE_TITLE_FOR_VIEW[View.LO_DIRECT_CLIENTS],
    component: withLODirectMobileBlock(
      withLODirectSubscriptionVerification(LODirectClientsView)
    ),
  },
  [View.LO_DIRECT_FORGOT_PASSWORD]: {
    path: VIEW_PATHS[View.LO_DIRECT_FORGOT_PASSWORD],
    title: PAGE_TITLE_FOR_VIEW[View.LO_DIRECT_FORGOT_PASSWORD],
    component: LODirectForgotPasswordView,
  },
  [View.LO_DIRECT_LEADS]: {
    path: VIEW_PATHS[View.LO_DIRECT_LEADS],
    title: PAGE_TITLE_FOR_VIEW[View.LO_DIRECT_LEADS],
    component: withLODirectMobileBlock(
      withLODirectSubscriptionVerification(LODirectLeadsView)
    ),
  },
  [View.LO_DIRECT_LOGIN]: {
    path: VIEW_PATHS[View.LO_DIRECT_LOGIN],
    title: PAGE_TITLE_FOR_VIEW[View.LO_DIRECT_LOGIN],
    component: LODirectLoginView,
    query: [LO_DIRECT_LOGOUT_QUERY_KEY, LO_DIRECT_LOGIN_REDIRECT_KEY],
  },
  [View.LO_DIRECT_RESET_PASSWORD]: {
    path: VIEW_PATHS[View.LO_DIRECT_RESET_PASSWORD],
    title: PAGE_TITLE_FOR_VIEW[View.LO_DIRECT_RESET_PASSWORD],
    query: ['token'],
    component: LODirectResetPasswordView,
    saga: handleErrors(routeLODirectResetPassword),
  },
  [View.LO_DIRECT_RESUBSCRIBE]: {
    path: VIEW_PATHS[View.LO_DIRECT_RESUBSCRIBE],
    title: PAGE_TITLE_FOR_VIEW[View.LO_DIRECT_RESUBSCRIBE],
    component: LODirectResubscribeView,
  },
  [View.LO_DIRECT_SUBSCRIBE]: {
    path: VIEW_PATHS[View.LO_DIRECT_SUBSCRIBE],
    title: PAGE_TITLE_FOR_VIEW[View.LO_DIRECT_SUBSCRIBE],
    component: LODirectSubscribeView,
  },
  [View.LO_DIRECT_INVITE_SIGNUP]: {
    path: VIEW_PATHS[View.LO_DIRECT_INVITE_SIGNUP],
    title: PAGE_TITLE_FOR_VIEW[View.LO_DIRECT_INVITE_SIGNUP],
    component: LODirectInviteSignupView,
  },
  [View.LO_DIRECT_SIGNUP]: {
    path: VIEW_PATHS[View.LO_DIRECT_SIGNUP],
    title: PAGE_TITLE_FOR_VIEW[View.LO_DIRECT_SIGNUP],
    component: LODirectSignupView,
  },
  [View.LO_DIRECT_SIGNUP_PLANS]: {
    path: VIEW_PATHS[View.LO_DIRECT_SIGNUP_PLANS],
    title: PAGE_TITLE_FOR_VIEW[View.LO_DIRECT_SIGNUP_PLANS],
    component: LODirectSignupPlansView,
    query: [LO_DIRECT_SIGNUP_PLANS_BACK_VIEW_KEY],
  },
  [View.LO_DIRECT_SUPPORT]: {
    path: VIEW_PATHS[View.LO_DIRECT_SUPPORT],
    title: PAGE_TITLE_FOR_VIEW[View.LO_DIRECT_SUPPORT],
    component: withLODirectMobileBlock(
      withLODirectSubscriptionVerification(LODirectSupportView)
    ),
  },
  [View.LOGIN]: {
    path: VIEW_PATHS[View.LOGIN],
    title: PAGE_TITLE_FOR_VIEW[View.LOGIN],
    component: LoginPage,
    saga: handleErrors(routeLogin),
    logoutRequired: true,
  },
  [View.LOGOUT]: {
    path: VIEW_PATHS[View.LOGOUT],
    title: PAGE_TITLE_FOR_VIEW[View.LOGOUT],
    /* We redirect immediately in this saga */
    saga: handleErrors(routeLogout),
    /* Prevent a redux-saga-router error due to no defined component here */
    component: () => null,
  },
  [View.MLS_NUMBER]: {
    path: VIEW_PATHS[View.MLS_NUMBER],
    title: PAGE_TITLE_FOR_VIEW[View.MLS_NUMBER],
    component: MlsNumberPage,
  },
  [View.NATIVE_APP_TEST]: {
    path: VIEW_PATHS[View.NATIVE_APP_TEST],
    title: PAGE_TITLE_FOR_VIEW[View.NATIVE_APP_TEST],
    component: NativeAppIntegrationTestPage,
  },
  /* For explicitly sending the user here for when there's bad data, etc. */
  [View.PAGE_404]: {
    path: VIEW_PATHS[View.PAGE_404],
    title: PAGE_TITLE_FOR_VIEW[View.PAGE_404],
    component: Page404,
  },
  [View.PARTNER_API_CREDENTIAL]: {
    query: ['token'],
    component: PartnerAPICredentialPage,
    path: VIEW_PATHS[View.PARTNER_API_CREDENTIAL],
    title: PAGE_TITLE_FOR_VIEW[View.PARTNER_API_CREDENTIAL],
  },
  [View.PLACE]: {
    path: VIEW_PATHS[View.PLACE],
    // Title handled via saga so that we can use the zipcode, city, or state in the title
    saga: handleErrors(routePlace),
    component: SearchPageContainer,
  },
  [View.PRIVACY_POLICY]: {
    path: VIEW_PATHS[View.PRIVACY_POLICY],
    title: PAGE_TITLE_FOR_VIEW[View.PRIVACY_POLICY],
    component: PrivacyPolicyPageContainer,
  },
  [View.PROPERTY_DETAILS]: {
    path: VIEW_PATHS[View.PROPERTY_DETAILS],
    // Title handled via saga so that we can use the Address
    query: [
      'ref',
      'code',
      SSO_QUERY_KEYS.SSO_ACTION,
      SSO_QUERY_KEYS.SLUG,
      PDP_PUBLIC_OWNER_TOGGLE,
    ],
    component: PropertyPageContainer,
  },
  [View.PROFILE]: {
    path: VIEW_PATHS[View.PROFILE],
    title: PAGE_TITLE_FOR_VIEW[View.PROFILE],
    component: UserProfilePageContainer,
    loginRequired: true,
  },
  [View.RESET_PASSWORD]: {
    path: VIEW_PATHS[View.RESET_PASSWORD],
    title: PAGE_TITLE_FOR_VIEW[View.RESET_PASSWORD],
    query: ['token'],
    component: ResetPasswordPageContainer,
    saga: handleErrors(routeResetPassword),
  },
  [View.SAVED_SEARCHES]: {
    path: VIEW_PATHS[View.SAVED_SEARCHES],
    title: PAGE_TITLE_FOR_VIEW[View.SAVED_SEARCHES],
    component: SavedSearchesContainer,
  },
  [View.SETTINGS]: {
    path: VIEW_PATHS[View.SETTINGS],
    title: PAGE_TITLE_FOR_VIEW[View.SETTINGS],
    component: SettingsContainer,
    loginRequired: true,
  },
  [View.SIGN_UP]: {
    path: VIEW_PATHS[View.SIGN_UP],
    title: PAGE_TITLE_FOR_VIEW[View.SIGN_UP],
    query: ['ref', 'code'],
    component: SignupPage,
    logoutRequired: true,
    saga: routeSignUp,
  },
  [View.TERMS_OF_USE]: {
    path: VIEW_PATHS[View.TERMS_OF_USE],
    title: PAGE_TITLE_FOR_VIEW[View.TERMS_OF_USE],
    component: TermsOfUsePageContainer,
  },
  [View.TERMS_AND_DEFINITIONS]: {
    path: VIEW_PATHS[View.TERMS_AND_DEFINITIONS],
    title: PAGE_TITLE_FOR_VIEW[View.TERMS_AND_DEFINITIONS],
    component: TermsAndDefinitionsPage,
  },
  [View.TEST_PROPERTIES]: {
    path: VIEW_PATHS[View.TEST_PROPERTIES],
    title: PAGE_TITLE_FOR_VIEW[View.TEST_PROPERTIES],
    component: TestPropertiesPageContainer,
  },
  [View.UNAUTHENTICATED_ACCESS]: {
    path: VIEW_PATHS[View.UNAUTHENTICATED_ACCESS],
    title: PAGE_TITLE_FOR_VIEW[View.UNAUTHENTICATED_ACCESS],
    component: UnauthorizedAccessPage,
  },
  [View.UNREAD_ALERTS_RESET]: {
    path: VIEW_PATHS[View.UNREAD_ALERTS_RESET],
    title: PAGE_TITLE_FOR_VIEW[View.UNREAD_ALERTS_RESET],
    component: UnreadAlertsResetPage,
  },
  [View.WATCHLIST]: {
    path: VIEW_PATHS[View.WATCHLIST],
    title: PAGE_TITLE_FOR_VIEW[View.WATCHLIST],
    component: WatchListPageContainer,
  },
  [View.ZIPCODE]: {
    path: VIEW_PATHS[View.ZIPCODE],
    // Title handled via saga so that we can use the zipcode in the title
    saga: handleErrors(routeZipcode),
    component: SearchPageContainer,
  },
  [View.NOTIFICATIONS]: {
    path: VIEW_PATHS[View.NOTIFICATIONS],
    title: PAGE_TITLE_FOR_VIEW[View.NOTIFICATIONS],
    component: NotificationsPage,
    loginRequired: true,
  },
  [View.WELCOME_SEARCH]: {
    path: VIEW_PATHS[View.WELCOME_SEARCH],
    query: [WELCOME_SEARCH_ONLY_ADDRESS_URL_KEY],
    title: PAGE_TITLE_FOR_VIEW[View.WELCOME_SEARCH],
    component: WelcomeSearchPageChase,
  },
  [View.WELCOME_TRANSITION]: {
    path: VIEW_PATHS[View.WELCOME_TRANSITION],
    title: PAGE_TITLE_FOR_VIEW[View.WELCOME_TRANSITION],
    component: WelcomeTransitionPageChase,
  },
  [View.LENDER_HOMEPAGE]: {
    path: VIEW_PATHS[View.LENDER_HOMEPAGE],
    query: ['buyingpower'],
    title: PAGE_TITLE_FOR_VIEW[View.LENDER_HOMEPAGE],
    component: LenderHomePage,
  },
  [View.UNSUBSCRIBE]: {
    path: VIEW_PATHS[View.UNSUBSCRIBE],
    query: [UNSUBSCRIBE_QUERY_STRING, 'email'],
    title: PAGE_TITLE_FOR_VIEW[View.UNSUBSCRIBE],
    saga: handleErrors(routeUnsubscribe),
    component: UnsubscribePage,
  },
  [View.WELCOME_TRANSITION_INSET]: {
    path: VIEW_PATHS[View.WELCOME_TRANSITION_INSET],
    query: [WELCOME_TRANSITION_INSET_ONLY_ADDRESS_URL_KEY],
    title: PAGE_TITLE_FOR_VIEW[View.WELCOME_TRANSITION_INSET],
    component: WelcomeTransitionInsetChasePage,
  },
  [View.USER_UNSUBSCRIBE]: {
    path: VIEW_PATHS[View.USER_UNSUBSCRIBE],
    query: [
      USER_UNSUBSCRIBE_QUERY_STRING,
      USER_UNSUBSCRIBE_QUERY_TOKEN,
      'email',
    ],
    component: UnsubscribePage,
    title: PAGE_TITLE_FOR_VIEW[View.UNSUBSCRIBE],
    saga: handleErrors(routeUserUnsubscribe),
  },
  [View.VERIFICATION]: {
    path: VIEW_PATHS[View.VERIFICATION],
    title: PAGE_TITLE_FOR_VIEW[View.VERIFICATION],
    component: withHomeownerPropertyEventReporting(HomeVerificationPage),
  },
  [View.WIDGET_DEMO_BANNER]: {
    path: VIEW_PATHS[View.WIDGET_DEMO_BANNER],
    title: PAGE_TITLE_FOR_VIEW[View.WIDGET_DEMO_BANNER],
    component: WidgetDemoBannerPage,
  },
  [View.WIDGET_DEMO_HERO]: {
    path: VIEW_PATHS[View.WIDGET_DEMO_HERO],
    title: PAGE_TITLE_FOR_VIEW[View.WIDGET_DEMO_HERO],
    component: WidgetDemoHeroPage,
  },
};

export const getRoutesConfig = ({
  isHomepageEnabled,
}: {
  isHomepageEnabled: boolean;
}): PartialObj<View, RouteDefOptionType> => {
  /* Associate either VIEWS.HOMEPAGE or VIEWS.SEARCH with the root path (/) depending on feature flag
   * This needs to match the server routing config for SSR in src/app.ts */
  const rootConfig = isHomepageEnabled
    ? {
        [View.HOMEPAGE]: HOMEPAGE_CONFIG,
        [View.SEARCH]: SEARCH_PAGE_CONFIG,
      }
    : {
        [View.SEARCH]: {
          ...SEARCH_PAGE_CONFIG,
          path: ROOT_PATH,
        },
      };
  const routesConfigWithRoot = {
    ...STATIC_ROUTE_CONFIG,
    ...rootConfig,
  };
  /* Append COMMON_QUERY_PARAMS to each route definition */
  const routesConfigWithCommonQueryParams = Object.keys(routesConfigWithRoot)
    .map((routeKey) => {
      const routeConfig = routesConfigWithRoot[routeKey];
      return {
        key: routeKey,
        def: {
          ...routeConfig,
          query: [...(routeConfig.query || []), ...COMMON_QUERY_PARAMS],
        },
      };
    })
    .reduce(
      (mem, curr) => {
        mem[curr.key] = curr.def;
        return mem;
      },
      {} as typeof STATIC_ROUTE_CONFIG & {
        [View.HOMEPAGE]: RouteDefOptionType;
        [View.SEARCH]: RouteDefOptionType;
      }
    );

  return routesConfigWithCommonQueryParams;
};
