import { MARKER_IMAGE_IDS } from '@client/store/map-constants';

const onMarket = (
  color: string
): string => `<svg width="37px" height="53px" viewBox="0 3 42 61" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<defs>
    <ellipse id="path-1" cx="16" cy="44" rx="10" ry="3"></ellipse>
    <filter x="-35.0%" y="-83.3%" width="170.0%" height="333.3%" filterUnits="objectBoundingBox" id="filter-2">
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0987318841 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
    </filter>
    <path d="M27.1715085,4.70529124 C24.1653381,1.67124946 20.1685236,-0.00209813087 15.9166154,-3.19744231e-13 C11.6656607,-0.00209813087 7.66572579,1.66993689 4.65903538,4.70529124 C1.65624548,7.73732042 -0.000433305531,11.7747757 2.96651592e-13,16.067919 C-0.000433305531,20.3591372 1.65572541,24.3948424 4.66180908,27.4294968 L15.9171355,38.7921245 L27.0441783,27.5589158 C27.117508,27.4848871 27.1451583,27.4574107 27.1706417,27.4319469 C27.1996789,27.4020204 27.2265491,27.3749815 27.2541994,27.3469801 L27.9196273,26.6722332 C30.4616363,23.7047824 31.8648683,19.9289654 31.8332308,15.9506631 C31.8013332,11.7005719 30.146388,7.70905648 27.1715085,4.70529124 Z" id="path-3"></path>
</defs>
<g id="Atoms/-Pins-/-On-Market-/-Blank" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Group" transform="translate(5.000000, 8.000000)">
        <g id="Oval-3-Copy-3" fill="black" fill-opacity="1">
            <use filter="url(#filter-2)" xlink:href="#path-1"></use>
        </g>
        <g id="Fill-1-Copy-7">
            <use fill="black" fill-opacity="1" filter="url(#filter-4)" xlink:href="#path-3"></use>
            <path stroke="#FFFFFF" stroke-width="2" d="M15.916122,-0.999999878 C11.3979352,-1.00223039 7.14515326,0.774501565 3.94858202,4.00154695 C0.759735582,7.22144436 -1.00046016,11.5087286 -0.999999995,16.06802 C-1.00046016,20.6250803 0.759053814,24.9105986 3.95136587,28.1332513 L15.9171355,40.2130952 L27.9646527,28.0507244 L28.6558959,27.3498043 C31.3686822,24.1960115 32.8668762,20.177473 32.8331992,15.9427108 C32.7993287,11.4297247 31.0410731,7.19133277 27.8820233,4.00160904 C24.6858313,0.775783727 20.4363055,-1.00223039 15.916122,-0.999999878 Z" fill="${color}" fill-rule="evenodd"></path>
        </g>
    </g>
</g>
</svg>`;

const offMarket =
  (): string => `<svg width="37px" height="53px" viewBox="0 3 42 61" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<defs>
    <ellipse id="path-1" cx="16" cy="44" rx="10" ry="3"></ellipse>
    <filter x="-35.0%" y="-83.3%" width="170.0%" height="333.3%" filterUnits="objectBoundingBox" id="filter-2">
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0987318841 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
    </filter>
    <path d="M27.1715085,4.70529124 C24.1653381,1.67124946 20.1685236,-0.00209813087 15.9166154,-3.19744231e-13 C11.6656607,-0.00209813087 7.66572579,1.66993689 4.65903538,4.70529124 C1.65624548,7.73732042 -0.000433305531,11.7747757 2.96651592e-13,16.067919 C-0.000433305531,20.3591372 1.65572541,24.3948424 4.66180908,27.4294968 L15.9171355,38.7921245 L27.0441783,27.5589158 C27.117508,27.4848871 27.1451583,27.4574107 27.1706417,27.4319469 C27.1996789,27.4020204 27.2265491,27.3749815 27.2541994,27.3469801 L27.9196273,26.6722332 C30.4616363,23.7047824 31.8648683,19.9289654 31.8332308,15.9506631 C31.8013332,11.7005719 30.146388,7.70905648 27.1715085,4.70529124 Z" id="path-3"></path>
</defs>
<g id="Atoms/-Pins-/-On-Market-/-Blank" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Group" transform="translate(5.000000, 8.000000)">
        <g id="Oval-3-Copy-3" fill="black" fill-opacity="1">
            <use filter="url(#filter-2)" xlink:href="#path-1"></use>
        </g>
        <g id="Fill-1-Copy-7">
            <use fill="black" fill-opacity="1" filter="url(#filter-4)" xlink:href="#path-3"></use>
            <path stroke="#e9e9e9" stroke-width="2" d="M15.916122,-0.999999878 C11.3979352,-1.00223039 7.14515326,0.774501565 3.94858202,4.00154695 C0.759735582,7.22144436 -1.00046016,11.5087286 -0.999999995,16.06802 C-1.00046016,20.6250803 0.759053814,24.9105986 3.95136587,28.1332513 L15.9171355,40.2130952 L27.9646527,28.0507244 L28.6558959,27.3498043 C31.3686822,24.1960115 32.8668762,20.177473 32.8331992,15.9427108 C32.7993287,11.4297247 31.0410731,7.19133277 27.8820233,4.00160904 C24.6858313,0.775783727 20.4363055,-1.00223039 15.916122,-0.999999878 Z" fill="#FFFFFF" fill-rule="evenodd"></path>
        </g>
    </g>
</g>
</svg>`;

const subject = (
  color: string
): string => `<svg width="37px" height="53px" viewBox="0 3 42 61" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<defs>
    <ellipse id="path-1" cx="16" cy="44" rx="10" ry="3"></ellipse>
    <filter x="-35.0%" y="-83.3%" width="170.0%" height="333.3%" filterUnits="objectBoundingBox" id="filter-2">
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0987318841 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
    </filter>
    <path d="M27.1715085,4.70529124 C24.1653381,1.67124946 20.1685236,-0.00209813087 15.9166154,-3.19744231e-13 C11.6656607,-0.00209813087 7.66572579,1.66993689 4.65903538,4.70529124 C1.65624548,7.73732042 -0.000433305531,11.7747757 2.96651592e-13,16.067919 C-0.000433305531,20.3591372 1.65572541,24.3948424 4.66180908,27.4294968 L15.9171355,38.7921245 L27.0441783,27.5589158 C27.117508,27.4848871 27.1451583,27.4574107 27.1706417,27.4319469 C27.1996789,27.4020204 27.2265491,27.3749815 27.2541994,27.3469801 L27.9196273,26.6722332 C30.4616363,23.7047824 31.8648683,19.9289654 31.8332308,15.9506631 C31.8013332,11.7005719 30.146388,7.70905648 27.1715085,4.70529124 Z" id="path-3"></path>
</defs>
<g id="Atoms/-Pins-/-On-Market-/-ComeHome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Group" transform="translate(5.000000, 8.000000)">
        <g id="Oval-3-Copy" fill="black" fill-opacity="1">
            <use filter="url(#filter-2)" xlink:href="#path-1"></use>
        </g>
        <g id="Fill-1-Copy-5">
            <use fill="black" fill-opacity="1" filter="url(#filter-4)" xlink:href="#path-3"></use>
            <path stroke="#FFFFFF" stroke-width="2" d="M15.916122,-0.999999878 C11.3979352,-1.00223039 7.14515326,0.774501565 3.94858202,4.00154695 C0.759735582,7.22144436 -1.00046016,11.5087286 -0.999999995,16.06802 C-1.00046016,20.6250803 0.759053814,24.9105986 3.95136587,28.1332513 L15.9171355,40.2130952 L27.9646527,28.0507244 L28.6558959,27.3498043 C31.3686822,24.1960115 32.8668762,20.177473 32.8331992,15.9427108 C32.7993287,11.4297247 31.0410731,7.19133277 27.8820233,4.00160904 C24.6858313,0.775783727 20.4363055,-1.00223039 15.916122,-0.999999878 Z" fill="${color}" fill-rule="evenodd"></path>
        </g>
        <g id="Group-8" transform="translate(7.000000, 8.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <path d="M12.6295088,6.25208634 L8.51962036,2.14420257 C8.26162558,1.88630118 7.84343243,1.88630118 7.58543765,2.14420257 L7.58543765,2.14420257 C7.46149903,2.26811009 7.39186842,2.43618343 7.39186842,2.61143712 C7.39186842,2.78669081 7.46149903,2.95476414 7.58543765,3.07867167 L12.5112325,8.00217542 C12.5872496,8.07808162 12.6299128,8.18113288 12.6297952,8.28855884 L12.6297952,11.5533298 C12.6293214,11.7761653 12.4485442,11.9565582 12.2257082,11.9565577 L5.86026393,11.9565577 C5.49537682,11.9565577 5.19957738,12.2523571 5.19957738,12.6172442 L5.19957738,12.6172442 C5.19957738,12.9821313 5.49537682,13.2779308 5.86026393,13.2779308 L13.3305754,13.2779308 C13.6730495,13.278089 13.9508521,13.000671 13.9511683,12.6581971 L13.9511683,7.82748153 C13.9511683,7.66473213 13.8864258,7.50866681 13.7713195,7.39361065 L12.6295088,6.25208634 Z" id="Path-Copy" opacity="0.802385603"></path>
            <path d="M4.06063052,15.7422601 L14.9179987,15.7422601 C15.3560698,15.7422601 15.7761819,15.5681588 16.0858375,15.2582883 C16.3954931,14.9484179 16.5693031,14.5281851 16.5689995,14.0901142 L16.5689995,7.10694085 C16.5689995,6.66480335 16.3915659,6.24116086 16.0764197,5.93105053 L10.436671,0.382085388 C9.91995925,-0.126469106 9.0911347,-0.1274881 8.57317406,0.379794321 L2.9053598,5.93047776 C2.58852351,6.24073761 2.40991609,6.66549721 2.40991609,7.10894553 L2.40991609,14.0901142 C2.40961256,14.5281355 2.58338326,14.9483249 2.8929769,15.2581871 C3.20257054,15.5680493 3.62260906,15.7422601 4.06063052,15.7422601 L4.06063052,15.7422601 Z M9.50592486,1.33717409 L10.6460173,2.45607411 L12.962,4.72881293 L14.8040181,6.53617869 C15.0354159,6.76328075 15.2198469,6.95458487 15.2267201,7.30941393 C15.251349,8.61303125 15.2204196,9.9249537 15.2204196,11.2308621 L15.2204196,13.6971961 C15.2204956,13.8888007 15.1444342,14.0725837 15.0089761,14.2080955 C14.8735181,14.3436073 14.6897653,14.4197415 14.4981606,14.4197415 L4.47474094,14.4197415 C4.2829376,14.4197415 4.09901086,14.3434504 3.96351979,14.2076907 C3.82802872,14.071931 3.7521017,13.8878535 3.75248196,13.6960506 L3.75677771,11.4247437 C3.75677771,10.1709571 3.78541605,8.91144278 3.75992793,7.65937447 C3.75735048,7.5396662 3.74732706,7.42024431 3.75276834,7.30053604 C3.77166965,6.86723793 4.12993531,6.61321583 4.41689149,6.33084178 L5.34105079,5.42386549 L7.72490637,3.08468572 L9.50592486,1.33717409 Z" id="Shape-Copy"></path>
            <path d="M5.95247939,0.581121865 C5.6892107,0.317862465 5.26237767,0.317862465 4.99910899,0.581121865 L1.34829315,4.20702234 L0.482269691,5.07275942 C0.173430723,5.38157454 4.97379915e-14,5.80044701 4.97379915e-14,6.2371944 L4.97379915e-14,12.486367 C4.97379915e-14,12.8586879 0.301825712,13.1605136 0.674146582,13.1605136 L0.674146582,13.1605136 C1.04646745,13.1605136 1.34829315,12.8586879 1.34829315,12.486367 L1.34829315,6.45370027 C1.34830348,6.23595101 1.4348401,6.02712989 1.58885522,5.87320108 L5.95247939,1.53449227 C6.21573879,1.27122358 6.21573879,0.844390553 5.95247939,0.581121865 Z" id="Path-Copy-2" opacity="0.802385603"></path>
            <path d="M9.51594828,6.90475415 L9.05973949,6.41160191 C8.94317657,6.29373679 8.78443957,6.22719652 8.61867206,6.22671011 C8.45290455,6.22622901 8.29378189,6.29184167 8.17653303,6.40902446 L8.17653303,6.40902446 C8.05899596,6.52661943 7.99335071,6.68632441 7.99421133,6.85258564 C7.99507195,7.01884688 8.062367,7.17786372 8.18111516,7.29423561 L8.94003122,8.0388325 L9.99478136,9.08212729 C10.0092964,9.09674348 10.0174311,9.11651393 10.0174056,9.13711291 L10.0174056,9.55752377 C10.0174056,9.60048128 9.95382853,9.63542006 9.91087102,9.63542006 L6.85630546,9.60678172 C6.51229616,9.60678172 6.23342152,9.88565636 6.23342152,10.2296657 L6.23342152,10.1924358 C6.23342152,10.5364451 6.51229616,10.8153198 6.85630546,10.8153198 L10.8891568,10.8153198 C11.1209616,10.8153198 11.3089652,10.6275727 11.3092813,10.395768 L11.3092813,8.80204431 C11.3092813,8.68859886 11.2633178,8.57999414 11.1818406,8.50105534 L9.51594828,6.90475415 Z" id="Path-Copy-3" opacity="0.599516369"></path>
        </g>
    </g>
</g>
</svg>`;

const subjectOffMarket =
  (): string => `<svg width="37px" height="53px" viewBox="0 3 42 61" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<defs>
    <ellipse id="path-1" cx="16" cy="44" rx="10" ry="3"></ellipse>
    <filter x="-35.0%" y="-83.3%" width="170.0%" height="333.3%" filterUnits="objectBoundingBox" id="filter-2">
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0987318841 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
    </filter>
    <path d="M27.1715085,4.70529124 C24.1653381,1.67124946 20.1685236,-0.00209813087 15.9166154,-3.19744231e-13 C11.6656607,-0.00209813087 7.66572579,1.66993689 4.65903538,4.70529124 C1.65624548,7.73732042 -0.000433305531,11.7747757 2.96651592e-13,16.067919 C-0.000433305531,20.3591372 1.65572541,24.3948424 4.66180908,27.4294968 L15.9171355,38.7921245 L27.0441783,27.5589158 C27.117508,27.4848871 27.1451583,27.4574107 27.1706417,27.4319469 C27.1996789,27.4020204 27.2265491,27.3749815 27.2541994,27.3469801 L27.9196273,26.6722332 C30.4616363,23.7047824 31.8648683,19.9289654 31.8332308,15.9506631 C31.8013332,11.7005719 30.146388,7.70905648 27.1715085,4.70529124 Z" id="path-3"></path>
</defs>
<g id="Atoms/-Pins-/-On-Market-/-ComeHome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Group" transform="translate(5.000000, 8.000000)">
        <g id="Oval-3-Copy" fill="black" fill-opacity="1">
            <use filter="url(#filter-2)" xlink:href="#path-1"></use>
        </g>
        <g id="Fill-1-Copy-5">
            <use fill="black" fill-opacity="1" filter="url(#filter-4)" xlink:href="#path-3"></use>
            <path stroke="#e9e9e9" stroke-width="2" d="M15.916122,-0.999999878 C11.3979352,-1.00223039 7.14515326,0.774501565 3.94858202,4.00154695 C0.759735582,7.22144436 -1.00046016,11.5087286 -0.999999995,16.06802 C-1.00046016,20.6250803 0.759053814,24.9105986 3.95136587,28.1332513 L15.9171355,40.2130952 L27.9646527,28.0507244 L28.6558959,27.3498043 C31.3686822,24.1960115 32.8668762,20.177473 32.8331992,15.9427108 C32.7993287,11.4297247 31.0410731,7.19133277 27.8820233,4.00160904 C24.6858313,0.775783727 20.4363055,-1.00223039 15.916122,-0.999999878 Z" fill="#FFFFFF" fill-rule="evenodd"></path>
        </g>
        <g id="Group-8" transform="translate(7.000000, 8.000000)" fill="#2d2e57" fill-rule="nonzero">
            <path d="M12.6295088,6.25208634 L8.51962036,2.14420257 C8.26162558,1.88630118 7.84343243,1.88630118 7.58543765,2.14420257 L7.58543765,2.14420257 C7.46149903,2.26811009 7.39186842,2.43618343 7.39186842,2.61143712 C7.39186842,2.78669081 7.46149903,2.95476414 7.58543765,3.07867167 L12.5112325,8.00217542 C12.5872496,8.07808162 12.6299128,8.18113288 12.6297952,8.28855884 L12.6297952,11.5533298 C12.6293214,11.7761653 12.4485442,11.9565582 12.2257082,11.9565577 L5.86026393,11.9565577 C5.49537682,11.9565577 5.19957738,12.2523571 5.19957738,12.6172442 L5.19957738,12.6172442 C5.19957738,12.9821313 5.49537682,13.2779308 5.86026393,13.2779308 L13.3305754,13.2779308 C13.6730495,13.278089 13.9508521,13.000671 13.9511683,12.6581971 L13.9511683,7.82748153 C13.9511683,7.66473213 13.8864258,7.50866681 13.7713195,7.39361065 L12.6295088,6.25208634 Z" id="Path-Copy" opacity="0.802385603"></path>
            <path d="M4.06063052,15.7422601 L14.9179987,15.7422601 C15.3560698,15.7422601 15.7761819,15.5681588 16.0858375,15.2582883 C16.3954931,14.9484179 16.5693031,14.5281851 16.5689995,14.0901142 L16.5689995,7.10694085 C16.5689995,6.66480335 16.3915659,6.24116086 16.0764197,5.93105053 L10.436671,0.382085388 C9.91995925,-0.126469106 9.0911347,-0.1274881 8.57317406,0.379794321 L2.9053598,5.93047776 C2.58852351,6.24073761 2.40991609,6.66549721 2.40991609,7.10894553 L2.40991609,14.0901142 C2.40961256,14.5281355 2.58338326,14.9483249 2.8929769,15.2581871 C3.20257054,15.5680493 3.62260906,15.7422601 4.06063052,15.7422601 L4.06063052,15.7422601 Z M9.50592486,1.33717409 L10.6460173,2.45607411 L12.962,4.72881293 L14.8040181,6.53617869 C15.0354159,6.76328075 15.2198469,6.95458487 15.2267201,7.30941393 C15.251349,8.61303125 15.2204196,9.9249537 15.2204196,11.2308621 L15.2204196,13.6971961 C15.2204956,13.8888007 15.1444342,14.0725837 15.0089761,14.2080955 C14.8735181,14.3436073 14.6897653,14.4197415 14.4981606,14.4197415 L4.47474094,14.4197415 C4.2829376,14.4197415 4.09901086,14.3434504 3.96351979,14.2076907 C3.82802872,14.071931 3.7521017,13.8878535 3.75248196,13.6960506 L3.75677771,11.4247437 C3.75677771,10.1709571 3.78541605,8.91144278 3.75992793,7.65937447 C3.75735048,7.5396662 3.74732706,7.42024431 3.75276834,7.30053604 C3.77166965,6.86723793 4.12993531,6.61321583 4.41689149,6.33084178 L5.34105079,5.42386549 L7.72490637,3.08468572 L9.50592486,1.33717409 Z" id="Shape-Copy"></path>
            <path d="M5.95247939,0.581121865 C5.6892107,0.317862465 5.26237767,0.317862465 4.99910899,0.581121865 L1.34829315,4.20702234 L0.482269691,5.07275942 C0.173430723,5.38157454 4.97379915e-14,5.80044701 4.97379915e-14,6.2371944 L4.97379915e-14,12.486367 C4.97379915e-14,12.8586879 0.301825712,13.1605136 0.674146582,13.1605136 L0.674146582,13.1605136 C1.04646745,13.1605136 1.34829315,12.8586879 1.34829315,12.486367 L1.34829315,6.45370027 C1.34830348,6.23595101 1.4348401,6.02712989 1.58885522,5.87320108 L5.95247939,1.53449227 C6.21573879,1.27122358 6.21573879,0.844390553 5.95247939,0.581121865 Z" id="Path-Copy-2" opacity="0.802385603"></path>
            <path d="M9.51594828,6.90475415 L9.05973949,6.41160191 C8.94317657,6.29373679 8.78443957,6.22719652 8.61867206,6.22671011 C8.45290455,6.22622901 8.29378189,6.29184167 8.17653303,6.40902446 L8.17653303,6.40902446 C8.05899596,6.52661943 7.99335071,6.68632441 7.99421133,6.85258564 C7.99507195,7.01884688 8.062367,7.17786372 8.18111516,7.29423561 L8.94003122,8.0388325 L9.99478136,9.08212729 C10.0092964,9.09674348 10.0174311,9.11651393 10.0174056,9.13711291 L10.0174056,9.55752377 C10.0174056,9.60048128 9.95382853,9.63542006 9.91087102,9.63542006 L6.85630546,9.60678172 C6.51229616,9.60678172 6.23342152,9.88565636 6.23342152,10.2296657 L6.23342152,10.1924358 C6.23342152,10.5364451 6.51229616,10.8153198 6.85630546,10.8153198 L10.8891568,10.8153198 C11.1209616,10.8153198 11.3089652,10.6275727 11.3092813,10.395768 L11.3092813,8.80204431 C11.3092813,8.68859886 11.2633178,8.57999414 11.1818406,8.50105534 L9.51594828,6.90475415 Z" id="Path-Copy-3" opacity="0.599516369"></path>
        </g>
    </g>
</g>
</svg>`;

const cluster =
  (): string => `<svg height="100" width="100" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <circle cx="50" cy="50" r="50" fill="#4a4a4a" />
</svg>`;

const mapPinSVGGenerator = (
  color: string,
  lighterColor: string
): {
  [key in (typeof MARKER_IMAGE_IDS)[keyof typeof MARKER_IMAGE_IDS]]:
    | string
    | null;
} => ({
  [MARKER_IMAGE_IDS.SUBJECT]: subject(color),
  [MARKER_IMAGE_IDS.SUBJECT_OFF_MARKET]: subjectOffMarket(),
  [MARKER_IMAGE_IDS.OFF_MARKET]: offMarket(),
  [MARKER_IMAGE_IDS.ON_MARKET]: onMarket(color),
  [MARKER_IMAGE_IDS.ON_MARKET_VISITED]: onMarket(lighterColor),
  [MARKER_IMAGE_IDS.CLUSTER]: cluster(),
  [MARKER_IMAGE_IDS.BARS]: null,
  [MARKER_IMAGE_IDS.CAFES]: null,
  [MARKER_IMAGE_IDS.ENTERTAINMENT]: null,
  [MARKER_IMAGE_IDS.GROCERY]: null,
  [MARKER_IMAGE_IDS.PARKS]: null,
  [MARKER_IMAGE_IDS.RESTAURANTS]: null,
});

export default mapPinSVGGenerator;
