import { Action } from '@client/store/actions';
import { TOAST_SHOW, TOAST_HIDE } from '@client/store/actions/toast.actions';

export type ToastState = {
  open: boolean;
  message: string;
  messageId: number | null;
  autoHideDuration: number;
};

const MIN_DURATION = 6000;
const ONE_SECOND_IN_MS = 1000;
const MIN_DURATION_WITHOUT_ACCOUNT_FOR_WORD_COUNT = 5000;

const INITIAL_STATE: ToastState = {
  open: false,
  message: '',
  messageId: null,
  autoHideDuration: MIN_DURATION,
};

/**
 * NOTE: Please follow best practices for accessibility outlined in
 * https://medium.com/@sheribyrnehaber/designing-toast-messages-for-accessibility-fb610ac364be
 */

const getWordCount = (message: string) =>
  message && typeof message === 'string' ? message.split(' ').length : 0;

const getToastDuration = (message: string) => {
  const wordCount = getWordCount(message);

  if (wordCount <= 120) {
    return MIN_DURATION;
  } else {
    const secondsNeeded = Math.ceil(wordCount / 120);
    return (
      secondsNeeded * ONE_SECOND_IN_MS +
      MIN_DURATION_WITHOUT_ACCOUNT_FOR_WORD_COUNT
    );
  }
};

export default function toastReducer(
  state = INITIAL_STATE,
  action: Action
): ToastState {
  switch (action.type) {
    case TOAST_SHOW:
      return {
        open: true,
        autoHideDuration: getToastDuration(action.payload.message),
        message: action.payload.message,
        messageId: action.payload.messageId,
      };

    case TOAST_HIDE:
      return INITIAL_STATE;

    default:
      return state;
  }
}
