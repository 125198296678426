import { select, call } from 'redux-saga/effects';
import { getSearchSetMapLocation } from '@client/store/selectors/search.selectors';
import { getLastSearchSaga } from '@client/store/sagas/last-search.saga';
import { SEARCH_ACTIVE_VIEW_URL_PARAM_KEY } from '@client/store/constants';

export default function* searchPageRoute({ view, params, query }) {
  const setMapLocation = yield select(getSearchSetMapLocation);
  const activeView = query[SEARCH_ACTIVE_VIEW_URL_PARAM_KEY];

  /* The map won't be rendered until a location is set in state */
  if (!setMapLocation) {
    /* If a `view` query param exists, don't override it by using the "search_view" field
     * from the user's last-search */
    yield call(getLastSearchSaga, { shouldRespectSavedView: !activeView });
  }
}
