import PropertyPageTopBar from '@client/components/PropertyPageTopBar/PropertyPageTopBar';
import PropertyPageTopBarChase from '@client/components/PropertyPageTopBar/PropertyPageTopBarChase';
import PropertyPageTopBarComehome from '@client/components/PropertyPageTopBar/PropertyPageTopBarComehome';
import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { PropertyPageTopBarProps } from '@client/store/types/cobranded-components/property-page-top-bar';
import { COBRAND_IDS } from '@client/store/types/cobranding';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.WWW:
      return PropertyPageTopBarComehome;
    case COBRAND_IDS.CHASE:
      return PropertyPageTopBarChase;
    default:
      return PropertyPageTopBar;
  }
};

const PropertyPageTopBarCobranded: React.FC<
  Omit<PropertyPageTopBarProps, 'theme'>
> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default PropertyPageTopBarCobranded;
