import { Action } from '@client/store/actions';

type Reducer<T> = (state: T | undefined, action: Action) => T;

/** Helper to clear reducers on several actions, so we don't need to repeat common clear actions in many reducers */
export function clearOnActions<T>(
  reducer: Reducer<T>,
  clearActions: string[]
): Reducer<T> {
  if (!clearActions || Array.isArray(clearOnActions)) {
    throw new Error(
      'clearOnActions needs to be called with an array of actions'
    );
  }
  return (state, action) => {
    if (action.type && clearActions.indexOf(action.type) > -1) {
      return reducer(undefined, action);
    } else {
      return reducer(state, action);
    }
  };
}
