import { verifyLODirectSubscriptionIfNecessary } from '@client/store/slices/lo-direct.slice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

/**
 * Check the LO Direct user's subscription status, redirecting them to the appropriate error page if necessary
 */
const withLODirectSubscriptionStatusCheck =
  <P extends object>(WrappedComponent: React.ComponentType<P>): React.FC<P> =>
  (props: P) => {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(verifyLODirectSubscriptionIfNecessary());
    }, []);

    return <WrappedComponent {...props} />;
  };

export default withLODirectSubscriptionStatusCheck;
