export default ({ latitude, longitude }) => ({
  query: /* GraphQL */ `query Geocode($longitude: Float, $latitude: Float) {
    geocode(id: {
      latitude: $latitude
      longitude: $longitude
    }) {
      results {
        addressComponents {
          shortName
          longName
          types
        }
        types
        formattedAddress
      }
    }
  }`,
  variables: {
    latitude,
    longitude,
  },
});
