import React from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import defaultTheme from '@client/css-modules/FormError.css';
import classNames from 'classnames';
import ErrorExclamation from '@client/inline-svgs/error-exclamation';

type FormErrorProps = {
  /** The text content of the error */
  value: string | JSX.Element | null | undefined;
  /** Themr theme to style the component */
  theme: Theme;
  style?: {};
  ariaDescribedby?: string;
  role?: string;
  className?: string;
};

/**
 *  Form success validation text to be positioned under fields that pass validation.
 */
const FormError: React.FunctionComponent<FormErrorProps> = ({
  theme,
  value,
  ariaDescribedby,
  role,
  className,
  ...rest
}) => {
  return (
    <div
      className={
        className ? classNames(theme.FormError, className) : theme.FormError
      }
      {...rest}
    >
      <div
        id={ariaDescribedby}
        role={role}
        className={theme.FormErrorAlert}
      >
        {value && (
          <div role="alert">
            <ErrorExclamation 
              className={theme.FormErrorIcon}
              imageAltProps={{
                title: "Error: ",
                ariaLabelledBy: "icon-form-error",
              }}            
            />
            <span className={theme.FormErrorMessage}>{value}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default themr('ThemedFormError', defaultTheme)(FormError);
