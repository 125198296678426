import { Component } from 'react';

import FlatButton from '@client/components/generic/FlatButton';
import LoadingSection from '@client/components/generic/LoadingSection';
import PropertyCard from '@client/components/PropertyCard';
import ResizableContainer from '@client/components/ResizableContainer';
import { SearchMapPageProps } from '@client/components/SearchPageMap';
import SearchTopBarFiltersContainer from '@client/containers/search-top-bar-filters.container';
import propertyCardTheme from '@client/css-modules/PropertyCard.css';
import theme from '@client/css-modules/SearchPageMap.css';
import MobileFilterIcon from '@client/inline-svgs/mobile-filter';
import SortIcon from '@client/inline-svgs/sort-icon';
import { STATUSES } from '@client/store/constants';
import { NormalizedProperty } from '@client/store/types/property';
import { parseCSSAmount } from '@client/utils/string.utils';

const { cardHeight, cardWidth } = propertyCardTheme;
const { sidebarHorizontalPadding } = theme;
const cardWidthInt = parseCSSAmount(cardWidth);
const sidebarHorizontalPaddingInt = parseCSSAmount(sidebarHorizontalPadding);
const PROPERTY_CARD_DIMENSIONS = {
  width: cardWidth,
  height: cardHeight,
};

/*
 * Cut-down version of SearchPageMap.jsx to be used when SSRing the map-search page
 */
export default class SearchPageMap extends Component<SearchMapPageProps> {
  handleSidebarPropertyClick = (
    normalizedPropertyData: NormalizedProperty
  ): void => {
    this.props.handleReportSidebarPropertyClick();
    this.props.handleNavigateToPDP(normalizedPropertyData);
  };

  render() {
    const {
      isShowingSearchInput,
      sidebarProperties,
      disableSidebarDragging,
      allowShowingMobileButtons,
      isShowingSidebarForScreenSize,
      isUsingLegacySaveSearchButton,
      sidebarPropertiesCursor,
      handleOpenSearchList,
    } = this.props;

    return (
      <div className={theme.SearchPageMap}>
        <div className={theme.TopBar}>
          <div
            className={theme.TopBarRowAbove}
            style={{
              justifyContent: isUsingLegacySaveSearchButton
                ? 'space-between'
                : 'flex-start',
            }}
          >
            {isShowingSearchInput && <div className={theme.AutoComplete} />}
            <SearchTopBarFiltersContainer handleSaveSearch={() => void 0} />
          </div>
        </div>
        {allowShowingMobileButtons && (
          <div key="mobile-btns" className={theme.MobileButtons}>
            <FlatButton
              theme={theme}
              className={theme.MobileButton}
              aria-label={'Filters'}
              label={'Filters'}
              onClick={() => void 0}
              icon={<MobileFilterIcon className={theme.MobileButtonIcon} />}
            />
            <FlatButton
              theme={theme}
              className={theme.MobileButton}
              aria-label={'Sort'}
              label={'Sort'}
              onClick={handleOpenSearchList}
              icon={<SortIcon className={theme.MobileButtonIcon} />}
            />
          </div>
        )}
        <div className={theme.MainContent}>
          <div className={theme.MapSection} />
          {isShowingSidebarForScreenSize && (
            <ResizableContainer
              theme={theme}
              allowResize={!disableSidebarDragging}
              stepWidth={cardWidthInt}
              totalPadding={sidebarHorizontalPaddingInt}
              snapToIncrementAfterResize
            >
              <LoadingSection
                className={theme.SidebarSectionInner}
                /* SSR the loading spinner only on the search route. The city and zipcode
                 * routes will have sidebar properties on init */
                isLoading={sidebarProperties.length === 0}
              >
                <div
                  className={theme.SidebarContents}
                  data-hc-name="property-section"
                >
                  {sidebarProperties.map((item) => (
                    <div
                      key={item.slug}
                      data-scroll-to-key={item.slug}
                      style={PROPERTY_CARD_DIMENSIONS}
                    >
                      <PropertyCard
                        theme={theme}
                        key={item.slug}
                        photoSize={'MEDIUM' as 'MEDIUM'}
                        isShowingWatchListActionButton
                        isShowingDaysOnMarket
                        propertyDetails={item}
                        status={STATUSES.SUCCESS}
                        shouldHandleCheckingForWatchListStatus={false}
                        isAddedToWatchList={false}
                        goToProperty={() => {
                          this.handleSidebarPropertyClick(item);
                        }}
                        handleReportWatchClick={() => void 0}
                        handleReportUnwatchClick={() => void 0}
                        handleReportUnwatchConfirmClick={() => void 0}
                      />
                    </div>
                  ))}
                  {sidebarPropertiesCursor && (
                    <a
                      className={theme.SeeMoreLink}
                      href={`?cursor=${sidebarPropertiesCursor}`}
                    >
                      See More
                    </a>
                  )}
                </div>
              </LoadingSection>
            </ResizableContainer>
          )}
        </div>
      </div>
    );
  }
}
