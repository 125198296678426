import React, { Component } from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import { dollarsFormatterWithSign } from '@client/utils/string.utils';
import SmallModal from '@client/components/generic/SmallModal';
import AvmDeepDiveTypicalHomeTooltip from '@client/components/AvmDeepDiveTypicalHomeTooltip';
import AvmDeepDiveCurrentPropertyTooltip from '@client/components/AvmDeepDiveCurrentPropertyTooltip';
import CobrandedStyles from '@client/components/CobrandedStyles';
import defaultTheme from '@client/css-modules/PieChart.css';

const homeIconDataURI = `data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTg
iPz4KPHN2ZyB3aWR0aD0iMjdweCIgaGVpZ2h0PSIyNXB4IiB2aWV3Qm94PSIwIDAgMjcgMjUiIHZlcnNpb249I
jEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy5
3My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU1LjIgKDc4MTgxKSAtIGh0d
HBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5Ib21lIENvcHkgNjwvdGl0bGU+CiAgICA8ZGVzYz5
DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb
2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSIzLS0
tQVZNLVBvb2wiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yOTQuMDAwMDAwLCAtNDc3LjAwMDAwMCkiPgogICAgI
CAgICAgICA8ZyBpZD0iSG9tZS1Db3B5LTYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI5NS4wMDAwMDAsIDQ3OC4
wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yLjk4Mjk1NDU1LDIwLjEyNSBMMi45ODI5NTQ1N
SwxMCBMMTMsMy4xNTg5NTA2MiBMMTguMTgxODE4MiwzLjg2ODgyNzE2IEwxOCwyIEMxOS4zMzMzMzMzLDEuMzM
zMzMzMzMgMjAuMzQwOTA5MSwxLjI0OTgzNzI0IDIxLjAyMjcyNzMsMS43NDk1MTE3MiBDMjIuMDQ1NDU0NSwyL
jQ5OTAyMzQ0IDIwLjgzNjExOTIsNS40NTE1OTQ4MSAyMS4wMjI3MjczLDcgQzIxLjE0NzEzMjYsOC4wMzIyNzA
xMyAyMS40Nzg1NzIsOS4wMzIyNzAxMyAyMi4wMTcwNDU1LDEwIEwyMi4wMTcwNDU1LDIwLjEyNSBMMi45ODI5N
TQ1NSwyMC4xMjUgWiIgaWQ9IlBhdGgtNCIgZmlsbD0iI0ZFRkVGRSI+PC9wYXRoPgogICAgICAgICAgICAgICA
gPHBhdGggZD0iTTAuNTY4MTgxODE4LDIwLjMwMjQ2OTEgTDI0LjQzMTgxODIsMjAuMzAyNDY5MSIgaWQ9Ikxpb
mUtMiIgc3Ryb2tlPSIjNEE0QTRBIiBzdHJva2Utd2lkdGg9IjAuODc4NDYiIHN0cm9rZS1saW5lY2FwPSJyb3
VuZCIgZmlsbC1ydWxlPSJub256ZXJvIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjEuODc
1LDEwLjUwNjE3MjggTDIxLjg3NSwyMC4yMTI3NDU1IE0zLjEyNSwyMC4yMTI3NDU1IEwzLjEyNSwxMC41MDYx
NzI4IiBpZD0iU2hhcGUiIHN0cm9rZT0iIzRBNEE0QSIgc3Ryb2tlLXdpZHRoPSIwLjg3ODQ2IiBzdHJva2Utb
GluZWNhcD0icm91bmQiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPgogICAgICAgICAgICAgICAgPHBhdG
ggZD0iTTIuMjAwNDgxMzUsMTEuMDEyNzg2IEMxLjk1MDA5OTA0LDExLjIwMTg0MzIgMS41OTM3ODcxNSwxMS4
xNTIyMjk3IDEuNDA0NjM2NDcsMTAuOTAxOTcxIEMxLjQwMzY3NjY2LDEwLjkwMDcwMTEgMS40MDI3MjIyLDEw
Ljg5OTQyNzIgMS40MDE3NzMxLDEwLjg5ODE0OTMgTDAuMzk0NTAzNTk3LDkuNTQxOTA3NjUgQzAuMjA3NjAzM
TUsOS4yOTAyNTQ4NyAwLjI2MDAwMTM3NCw4LjkzNDg0MTk3IDAuNTExNTgyNTksOC43NDc3NzAzNCBMMTEuNz
k4MTc1OSwwLjM1NTI0NjMxMSBDMTIuMzA2OTk3OCwtMC4wMjMxMDUyMzk3IDEzLjAwNTEwNjUsLTAuMDE4NDk
yMDY0NyAxMy41MDg4Nzg4LDAuMzY2NTUwODEzIEwyNC40OTQyNTc2LDguNzYyODg3MDggQzI0LjczMjI4NCw4
Ljk0NDgxNTIxIDI0Ljc4ODA0NTIsOS4yODAyMjk1NiAyNC42MjE2NzM4LDkuNTI5MzIwNjMgTDIzLjczNzAyL
DEwLjg1MzgyMzYgQzIzLjU2Mjc5MDQsMTEuMTE0NjgwMSAyMy4yMDk5NzkxLDExLjE4NDk3NDcgMjIuOTQ4OT
kzOCwxMS4wMTA4MzEyIEMyMi45Mzg3MjE1LDExLjAwMzk3NjkgMjIuOTI4Njc1NSwxMC45OTY3ODk3IDIyLjk
xODg3MTksMTAuOTg5MjgxIEwxMy41MDY3ODc3LDMuNzgwNDY1NjkgQzEyLjk5OTkwNCwzLjM5MjIzODEyIDE
yLjI5NjEyNjIsMy4zODk4MjMwNyAxMS43ODY1ODc1LDMuNzc0NTYyNzIgTDIuMjAwNDgxMzUsMTEuMDEyNzg2
IFoiIGlkPSJTaGFwZSIgc3Ryb2tlPSIjNEE0QTRBIiBzdHJva2Utd2lkdGg9IjAuODc4NDYiIGZpbGw9IiNG
RkZGRkYiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj48L3BhdGg+CiAgICAg
ICAgICAgICAgICA8cGF0aCBkPSJNMTguMTgxODE4MiwzLjg2ODgyNzE2IEwxOC4xODE4MTgyLDEuOTg3NjU0
MzIgQzE4LjE4MTgxODIsMS42NzQwMTExMyAxOC40MzYyMDE4LDEuNDE5NzUzMDkgMTguNzUsMS40MTk3NTMw
OSBMMjAuNDU0NTQ1NSwxLjQxOTc1MzA5IEMyMC43NjgzNDM2LDEuNDE5NzUzMDkgMjEuMDIyNzI3MywxLjY3
NDAxMTEzIDIxLjAyMjcyNzMsMS45ODc2NTQzMiBMMjEuMDIyNzI3Myw2LjA2OTQ0NDQ0IiBpZD0iUGF0aCIg
c3Ryb2tlPSIjNEE0QTRBIiBzdHJva2Utd2lkdGg9IjAuODc4NDYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIg
ZmlsbC1ydWxlPSJub256ZXJvIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTAuMjI3Mjcy
NywyMC4xMjUgTDEwLjIyNzI3MjcsMTUuMDQ5MzgyNyBDMTAuMjI3MjcyNywxNC41Nzg5MTc5IDEwLjYwODg0
ODIsMTQuMTk3NTMwOSAxMS4wNzk1NDU1LDE0LjE5NzUzMDkgTDEzLjkyMDQ1NDUsMTQuMTk3NTMwOSBDMTQu
MzkxMTUxOCwxNC4xOTc1MzA5IDE0Ljc3MjcyNzMsMTQuNTc4OTE3OSAxNC43NzI3MjczLDE1LjA0OTM4Mjcg
TDE0Ljc3MjcyNzMsMjAuMTI1IiBpZD0iUGF0aCIgc3Ryb2tlPSIjNEE0QTRBIiBzdHJva2Utd2lkdGg9IjAu
ODc4NDYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgZmlsbC1ydWxlPSJub256ZXJvIj48L3BhdGg+CiAgICAg
ICAgICAgICAgICA8cGF0aCBkPSJNOC4zNDgwNTU0NSwyMC40NDQ0NDQ0IEwxNi42NTE2NjcxLDIwLjQ0NDQ0
NDQgTDE4LjE0NDkwNzUsMjIuNTE4OTM4MSBDMTguMjM1NjU0LDIyLjY0NTAwODMgMTguMjA3MDE4NSwyMi44
MjA3NzMgMTguMDgwOTQ4NCwyMi45MTE1MTk1IEMxOC4wMzMwODcyLDIyLjk0NTk3MDQgMTcuOTc1NjA4MSwy
Mi45NjQ1MDYyIDE3LjkxNjYzNzIsMjIuOTY0NTA2MiBMNy4wODMwODUzNCwyMi45NjQ1MDYyIEM2LjkyNzc
1MTQyLDIyLjk2NDUwNjIgNi44MDE4Mjg0LDIyLjgzODU4MzIgNi44MDE4Mjg0LDIyLjY4MzI0OTIgQzYuODA
xODI4NCwyMi42MjQyNzg0IDYuODIwMzY0MTMsMjIuNTY2Nzk5MyA2Ljg1NDgxNTA3LDIyLjUxODkzODEgTDg
uMzQ4MDU1NDUsMjAuNDQ0NDQ0NCBaIiBpZD0iUmVjdGFuZ2xlIiBzdHJva2U9IiM0QTRBNEEiIHN0cm9rZS1
3aWR0aD0iMC44Nzg0NiIgZmlsbD0iIzEzQzlBMSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2UtbGluZWN
hcD0icm91bmQiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yMS4wMjI3MjczLDMuMTU4OTU
wNjIgTDE5Ljc0NDMxODIsMy4xNTg5NTA2MiIgaWQ9IlBhdGgtMTAiIHN0cm9rZT0iIzRBNEE0QSIgc3Ryb2t
lLXdpZHRoPSIwLjg3ODQ2IiBzdHJva2UtbGluZWNhcD0icm91bmQiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9
wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=`;

const GREY_CIRCLE_RADIUS = 130;
const BLUE_SECTOR_RADIUS = 150;
const CHART_HEIGHT = 400;
const CHART_WIDTH = 400;
/**
 * this is to keep the center of the chart centered in cartesian plain
 * as well as the UI
 */
const CENTER_X = CHART_WIDTH / 2;
const CENTER_Y = CHART_HEIGHT / 2;
const HOME_IMAGE_WIDTH = 30;
const HOME_IMAGE_HEIGHT = 30;
const GREY_COLOR = '#E5E5E5';
const THRESHOLD_FOR_LEGEND_DISPLAY = 25;

type PieChartData = {
  areaPercent: number;
  description: string;
  accessibleDescription?: string;
  label: string;
  type: string;
  value: string;
};

type Props = {
  count: number;
  data: PieChartData;
  showDataSummaryForSmallScreens: boolean;
  theme: Theme;
  ariaLabel?: string;
};

type State = {
  isDialogActive: boolean;
  isShowingCurrentPropertyTooltip: boolean;
};

class PieChart extends Component<Props, State> {
  state: State = {
    isDialogActive: false,
    isShowingCurrentPropertyTooltip: false,
  };

  getCoordinatesForPercent = (angle: number): [number, number] => {
    const angleInRadians = (angle * Math.PI) / 180;
    const x = CENTER_X + BLUE_SECTOR_RADIUS * Math.cos(angleInRadians);
    const y = CENTER_Y + BLUE_SECTOR_RADIUS * Math.sin(angleInRadians);

    return [x, y];
  };

  getPathData = (percent: number): string => {
    const arcStartingPoint = this.getCoordinatesForPercent(0);
    const arcEndingPoint = this.getCoordinatesForPercent(percent * 360);
    const largeArcFlag = percent > 0.5 ? 1 : 0;

    return [
      `M ${arcStartingPoint[0]} ${arcStartingPoint[1]}`,
      `A ${BLUE_SECTOR_RADIUS} ${BLUE_SECTOR_RADIUS} 0 ${largeArcFlag} 1 ${arcEndingPoint[0]} ${arcEndingPoint[1]}`,
      `L ${CENTER_X} ${CENTER_Y}`,
    ].join(' ');
  };

  handleTextElementWithFactorClick = () => {
    const { showDataSummaryForSmallScreens } = this.props;
    if (showDataSummaryForSmallScreens) {
      this.setState({
        isDialogActive: true,
        isShowingCurrentPropertyTooltip: true,
      });
    }
  };

  handleTextElementWithoutFactorClick = () => {
    const { showDataSummaryForSmallScreens } = this.props;
    if (showDataSummaryForSmallScreens) {
      this.setState({ isDialogActive: true });
    }
  };

  getTextElementsForWithoutFactor = (
    percent: number,
    propertyCount: number,
    label: string,
    labelXShift: number
  ): JSX.Element => {
    const { theme } = this.props;
    return (
      <React.Fragment>
        {percent >= THRESHOLD_FOR_LEGEND_DISPLAY ? (
          <g
            id="without-factor-text-elements"
            onClick={this.handleTextElementWithoutFactorClick}
          >
            <text
              x={CENTER_X + 40}
              y={CENTER_Y - 80}
              stroke="#000000"
              fill="#000000"
              textAnchor="middle"
              className={theme.Label}
            >
              {percent}%
            </text>
            {!!propertyCount && (
              <text
                x={CENTER_X + 40}
                y={CENTER_Y - 60}
                dx={10 + this.getAdditionalLabelXShift(propertyCount)}
                stroke="#000000"
                fill="#000000"
                textAnchor="middle"
                className={theme.LabelSubText}
              >
                {propertyCount} homes
              </text>
            )}
            <text
              x={CENTER_X + 40}
              y={CENTER_Y - 45}
              dx={labelXShift}
              stroke="#000000"
              fill="#000000"
              textAnchor="middle"
              className={theme.LabelSubText}
            >
              {label}
            </text>
          </g>
        ) : null}
      </React.Fragment>
    );
  };

  getTextElementsForWithFactor = (
    percent: number,
    propertyCount: number,
    label: string,
    labelXShift: number
  ): JSX.Element => {
    const { theme } = this.props;
    return (
      <React.Fragment>
        {percent >= THRESHOLD_FOR_LEGEND_DISPLAY ? (
          <g
            id="with-factor-text-elements"
            onClick={this.handleTextElementWithFactorClick}
          >
            <text
              x={CENTER_X + 40}
              y={CENTER_Y + 50}
              stroke="#FFFFFF"
              fill="#FFFFFF"
              textAnchor="middle"
              className={theme.Label}
            >
              {percent}%
            </text>
            <image
              x={CENTER_X + 65 + (percent === 100 ? 7 : 0)}
              y={CENTER_Y + 25}
              width={HOME_IMAGE_WIDTH}
              height={HOME_IMAGE_HEIGHT}
              xlinkHref={homeIconDataURI}
            />
            {!!propertyCount && (
              <text
                x={CENTER_X + 40}
                y={CENTER_Y + 70}
                dx={10 + this.getAdditionalLabelXShift(propertyCount)}
                stroke="#FFFFFF"
                fill="#FFFFFF"
                textAnchor="middle"
                className={theme.LabelSubText}
              >
                {propertyCount} homes
              </text>
            )}
            <text
              x={CENTER_X + 40}
              y={CENTER_Y + 85}
              dx={labelXShift}
              stroke="#FFFFFF"
              fill="#FFFFFF"
              textAnchor="middle"
              className={theme.LabelSubText}
            >
              {label}
            </text>
          </g>
        ) : null}
      </React.Fragment>
    );
  };

  handleClose = () => {
    if (this.state.isShowingCurrentPropertyTooltip) {
      this.setState({
        isDialogActive: false,
        isShowingCurrentPropertyTooltip: false,
      });
    } else {
      this.setState({ isDialogActive: false });
    }
  };

  getAdditionalLabelXShift = (label: number | string): number => {
    let labelXShift = 0;
    if (label && label.toString().length >= 4) {
      labelXShift = 4;
    }
    return labelXShift;
  };

  render() {
    const { count, data, showDataSummaryForSmallScreens, theme, ariaLabel } =
      this.props;
    const { areaPercent } = data;
    const withFactorPercent = Math.round(areaPercent * 100);
    /**
     * accounting for null value for tractStats count
     * do not show label for number of homes when count is null
     */
    const propertiesWithFactor = count ? Math.round(areaPercent * count) : 0;
    const withoutFactorPercent = 100 - withFactorPercent;
    const propertiesWithoutFactor = propertiesWithFactor
      ? count - propertiesWithFactor
      : 0;
    let withFactorLabel;
    let withoutFactorLabel;
    let withFactorLabelXShift;
    let withoutFactorLabelXShift;
    /**
     * Hard coded these values to make UX match the mocks
     */
    if (data.label === 'Pool') {
      withFactorLabel = 'have pool';
      withFactorLabelXShift = 6;
      withoutFactorLabel = 'no pool';
      withoutFactorLabelXShift = 1;
    } else {
      withFactorLabel = 'have basement';
      withFactorLabelXShift = 23;
      withoutFactorLabel = 'no basement';
      withoutFactorLabelXShift = 17;
    }

    return (
      <CobrandedStyles>
        {({ pieChartPrimaryColor }) => (
          <div className={theme.ChartContainer}>
            {showDataSummaryForSmallScreens && (
              <div className={theme.DeepDiveComponentSummary}>
                {data.accessibleDescription || data.description}:&nbsp;
                <strong>{`${dollarsFormatterWithSign(
                  data.value
                )} to value`}</strong>
              </div>
            )}
            {withFactorPercent < THRESHOLD_FOR_LEGEND_DISPLAY && (
              <div className={theme.ChartLegendItem}>
                <div className={theme.withFactor} />
                <div>
                  {withFactorPercent}% {withFactorLabel}{' '}
                </div>
              </div>
            )}
            {withoutFactorPercent < THRESHOLD_FOR_LEGEND_DISPLAY && (
              <div className={theme.ChartLegendItem}>
                <div className={theme.NoFactor} />
                <div>
                  {withoutFactorPercent}% {withoutFactorLabel}
                </div>
              </div>
            )}
            <div className={theme.Chart}>
              <svg
                viewBox={`0 0 ${CHART_WIDTH} ${CHART_HEIGHT}`}
                aria-label={ariaLabel}
                id="pie-chart-svg"
              >
                <circle
                  id="grey-background-circle"
                  cx={CENTER_X}
                  cy={CENTER_Y}
                  r={GREY_CIRCLE_RADIUS}
                  fill={GREY_COLOR}
                />
                {
                  /**
                   * Text elements are always places in the 25% or 45 degree from start angle (0)
                   * that way it works for all angles, note that we show a legend below the chart
                   * when the angle is less than 25%
                   */
                  this.getTextElementsForWithoutFactor(
                    withoutFactorPercent,
                    propertiesWithoutFactor,
                    withoutFactorLabel,
                    withoutFactorLabelXShift
                  )
                }
                {areaPercent === 1 ? (
                  /* getPathData method works for values between 0 and 0.99, this fixes the edge case of 1 */
                  <circle
                    id="electric-blue-circle"
                    cx={CENTER_X}
                    cy={CENTER_Y}
                    r={BLUE_SECTOR_RADIUS}
                    fill={pieChartPrimaryColor}
                  />
                ) : (
                  <path
                    id="electric-blue-circle"
                    d={this.getPathData(areaPercent)}
                    fill={pieChartPrimaryColor}
                  />
                )}
                {this.getTextElementsForWithFactor(
                  withFactorPercent,
                  propertiesWithFactor,
                  withFactorLabel,
                  withFactorLabelXShift
                )}
              </svg>
            </div>
            <SmallModal
              isActive={this.state.isDialogActive}
              handleClose={this.handleClose}
            >
              {this.state.isShowingCurrentPropertyTooltip ? (
                <AvmDeepDiveCurrentPropertyTooltip />
              ) : (
                <AvmDeepDiveTypicalHomeTooltip
                  numberOfHomes={propertiesWithoutFactor}
                  label={withoutFactorLabel}
                />
              )}
            </SmallModal>
          </div>
        )}
      </CobrandedStyles>
    );
  }
}

export default themr('ThemedPieChart', defaultTheme)(PieChart);
