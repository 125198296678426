import React from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import defaultTheme from '@client/css-modules/PropertyCardValueInline.css';
import { dollarsWithPlaceholder } from '@client/utils/string.utils';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import NoAvmPlaceholderCobranded from '@client/components/NoAvmPlaceholder/NoAvmPlaceholderCobranded';
import ChevronIconCobranded from '@client/components/ChevronIcon/ChevronIconCobranded';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { useCobrandUtils } from '@client/hooks/cobrand-utils.hooks';

export type PropertyCardValueInlineProps = {
  theme: Theme;
  valueLabel: string | JSX.Element;
  value: number | null;
  isShowingClickHintCTA?: boolean;
  onShowDetailsClick?: () => void;
  bottomLabel: string | JSX.Element;
  labelStyle?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
};

/**
 * The price and the price label inline horizontally with a "Show Details" CTA underneath
 */
const PropertyCardValueInline: React.FunctionComponent<
  PropertyCardValueInlineProps
> = ({
  theme,
  value,
  valueLabel,
  isShowingClickHintCTA,
  onShowDetailsClick,
  labelStyle,
  valueStyle,
  bottomLabel,
}) => {
  /**
   * onEnterOrSpaceKey function requires a callback function to be called,
   * passing in a NOOP when onShowDetailsClick is not defined
   */
  const handleShowDetailsClick = !onShowDetailsClick
    ? () => void 0
    : onShowDetailsClick;

  const { linkColor } = useCobrandStyles();
  const { getFormattedCTAText } = useCobrandUtils();

  return (
    <>
      <div className={theme.PropertyCardValueInline}>
        <div className={theme.PriceRow} data-hc-name="property-status">
          <div
            className={theme.ValueLabel}
            style={labelStyle}
            data-hc-name="property-status-text"
          >
            {valueLabel}
          </div>
          <div
            className={theme.Value}
            style={valueStyle}
            data-hc-name="property-price"
          >
            {value ? (
              dollarsWithPlaceholder(value)
            ) : (
              <NoAvmPlaceholderCobranded theme={theme} />
            )}
          </div>
        </div>
        {isShowingClickHintCTA && (
          <div className={theme.ShowDetailsAndChevronIcon}>
            <button
              type="button"
              aria-label="Show Details"
              className={theme.ShowDetailsCTA}
              onClick={handleShowDetailsClick}
              style={{ color: linkColor }}
              onKeyDown={onEnterOrSpaceKey(handleShowDetailsClick)}
            >
              {getFormattedCTAText('Show Details')}
            </button>
            <ChevronIconCobranded className={theme.ChevronIcon} />
          </div>
        )}
      </div>
      {bottomLabel && <div className={theme.BottomLabel}>{bottomLabel}</div>}
    </>
  );
};

export default themr(
  'PropertyCardValueStackedThemed',
  defaultTheme
)(PropertyCardValueInline);
