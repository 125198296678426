import { Action } from '@client/store/actions';
import { FETCH_INIT_PROPERTY_DETAILS_SUCCESS } from '@client/store/actions/property-details.actions';
import {
  SEARCH_FETCH_PROPERTY_LIST_SUCCESS,
  SEARCH_SET_MAP_MARKERS_FOR_TILE,
} from '@client/store/actions/search.actions';
import { NWMLS_ID } from '@client/store/constants';
import { Mls, MlsRegulations } from '@client/store/sagas/queries/types';
import { PropertyMapMarker } from '@client/store/types/maps';
import { SpatialSearchDetailWithAddressRequired } from '@client/store/types/search';
import { getShowBrokerLinkForMLSId } from '@client/utils/property.utils';
import { PartialDeep } from 'type-fest';

export const INITIAL_STATE: MLSGlobalDisplayRulesState = {
  showComehomeByHouseCanaryLogo: false,
  mlsRegulations: null,
  lastRefreshed: null,
  requiresBrokerLinkInFooter: false,
};

export type MLSGlobalDisplayRulesState = {
  showComehomeByHouseCanaryLogo: boolean;
  mlsRegulations?: Partial<MlsRegulations>[] | null;
  lastRefreshed?: string | null;
  requiresBrokerLinkInFooter: boolean;
};

export const getShowByHouseCanaryLogoForMLSId = (
  mlsId: number | null
): boolean => mlsId === NWMLS_ID;

/* find and return all properties that are under MLS regulation */
const getPropertyUnderMLSRegulations = (
  properties: SpatialSearchDetailWithAddressRequired[]
): SpatialSearchDetailWithAddressRequired[] | undefined => {
  const mlsProperties = properties.filter((hit) => hit?.property?.mls?.mlsID);
  return mlsProperties;
};

/* pluck out MLS info object from propertyUnderMLSRegulations */
const getMlsInfo = (
  propertyUnderMLSRegulations: SpatialSearchDetailWithAddressRequired
): PartialDeep<Mls> | null =>
  propertyUnderMLSRegulations?.property?.mls || null;

const getMLSLastRefreshed = (mlsInfo: PartialDeep<Mls>): string =>
  mlsInfo.lastRefreshed;

const getMLSRegulationInfo = (
  mlsInfo: PartialDeep<Mls>
): Partial<MlsRegulations> | null => mlsInfo.regulations || null;

const getUniqMLSProperties = (
  propertyUnderMLSRegulations?: SpatialSearchDetailWithAddressRequired[],
  track = new Set()
): SpatialSearchDetailWithAddressRequired[] | undefined =>
  propertyUnderMLSRegulations?.filter((p) => {
    const id = p?.property?.mls?.mlsID;
    return track.has(id) ? false : track.add(id);
  });

export default function mlsGlobalDisplayRulesReducer(
  state = INITIAL_STATE,
  action: Action
): MLSGlobalDisplayRulesState {
  switch (action.type) {
    case SEARCH_FETCH_PROPERTY_LIST_SUCCESS:
      const propertyUnderMLSRegulations = getPropertyUnderMLSRegulations(
        action.payload.properties
      );

      const uniqMLSProperties = getUniqMLSProperties(
        propertyUnderMLSRegulations
      );
      const mlsInfoList = uniqMLSProperties?.map((property) => {
        return getMlsInfo(property);
      });

      const lastRefreshed = mlsInfoList?.[0]
        ? getMLSLastRefreshed(mlsInfoList[0])
        : null;

      const mlsRegulations: Partial<MlsRegulations>[] | null = mlsInfoList?.map(
        (mlsInfo) => {
          return mlsInfo ? getMLSRegulationInfo(mlsInfo) : null;
        }
      ) as Partial<MlsRegulations>[] | null;

      return {
        ...state,
        lastRefreshed,
        mlsRegulations,
        showComehomeByHouseCanaryLogo: state.showComehomeByHouseCanaryLogo
          ? true
          : !!action.payload.properties.some((propertyResult) =>
              getShowByHouseCanaryLogoForMLSId(
                propertyResult?.property?.mls?.mlsID ?? null
              )
            ),
        requiresBrokerLinkInFooter: !!action.payload.properties.some(
          (propertyResult) => {
            const mlsId = propertyResult?.property?.mls?.mlsID;
            return getShowBrokerLinkForMLSId(mlsId);
          }
        ),
      };
    case SEARCH_SET_MAP_MARKERS_FOR_TILE:
      return {
        ...state,
        showComehomeByHouseCanaryLogo: state.showComehomeByHouseCanaryLogo
          ? true
          : action.payload.markers.some(
              (marker) =>
                marker &&
                (marker as PropertyMapMarker)!.normalizedPropertyData &&
                getShowByHouseCanaryLogoForMLSId(
                  (marker as PropertyMapMarker)!.normalizedPropertyData.hcMlsId
                )
            ),
      };
    case FETCH_INIT_PROPERTY_DETAILS_SUCCESS:
      return {
        ...state,
        showComehomeByHouseCanaryLogo: getShowByHouseCanaryLogoForMLSId(
          action.payload.data?.mls?.mlsID || null
        ),
        requiresBrokerLinkInFooter: getShowBrokerLinkForMLSId(
          action.payload.data?.mls?.mlsID
        ),
      };
    default:
      return state;
  }
}
