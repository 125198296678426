import { Action } from '@client/store/actions';
import { Status } from '@client/store/types/statuses';
import { STATUSES } from '@client/store/constants';

import {
  OPEN_EMAIL_OPT_IN_MODAL,
  CLOSE_EMAIL_OPT_IN_MODAL,
  SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN,
  SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN_SUCCESS,
  SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN_ERROR,
  CLEAR_USER_EMAIL_UPDATE_FROM_OPT_IN_ERROR,
  SET_MODAL_DISPLAYED_ON_SAVE_PROPERTY_TO_TRUE,
  SET_MODAL_DISPLAYED_ON_SAVE_SEARCH_TO_TRUE,
  SET_MODAL_DISPLAYED_ON_CLAIM_HOME_TO_TRUE,
} from '@client/store/actions/email-opt-in.actions';
import { ErrorObj } from '@client/store/types/auth';

export type EmailOptInState = {
  emailUpdateStatus: string | null;
  status: Status;
  errorMessage: ErrorObj | string | null;
  isDisplayingEmailOptInModal: boolean;
  placesAlreadyDisplayed: {
    saveProperty: boolean;
    saveSearch: boolean;
    claimHome: boolean;
  };
};

const INITIAL_EMAIL_OPT_IN_STATE: EmailOptInState = {
  emailUpdateStatus: STATUSES.INIT,
  status: STATUSES.SUCCESS,
  errorMessage: null,
  isDisplayingEmailOptInModal: false,
  placesAlreadyDisplayed: {
    saveProperty: false,
    saveSearch: false,
    claimHome: false,
  },
};

export function emailOptInReducer(
  state = INITIAL_EMAIL_OPT_IN_STATE,
  action: Action
): EmailOptInState {
  switch (action.type) {
    case OPEN_EMAIL_OPT_IN_MODAL:
      return {
        ...state,
        isDisplayingEmailOptInModal: true,
      };
    case CLOSE_EMAIL_OPT_IN_MODAL:
      return {
        ...state,
        isDisplayingEmailOptInModal: false,
      };
    case SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN:
      return {
        ...state,
        emailUpdateStatus: STATUSES.UPDATING,
        errorMessage: null,
      };
    case SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN_SUCCESS:
      return {
        ...state,
        emailUpdateStatus: STATUSES.SUCCESS,
        errorMessage: null,
      };
    case SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN_ERROR:
      return {
        ...state,
        emailUpdateStatus: STATUSES.ERROR,
        errorMessage: action.payload.error,
      };
    case CLEAR_USER_EMAIL_UPDATE_FROM_OPT_IN_ERROR:
      return {
        ...state,
        emailUpdateStatus: INITIAL_EMAIL_OPT_IN_STATE.emailUpdateStatus,
        errorMessage: INITIAL_EMAIL_OPT_IN_STATE.errorMessage,
      };
    case SET_MODAL_DISPLAYED_ON_SAVE_PROPERTY_TO_TRUE:
      return {
        ...state,
        placesAlreadyDisplayed: {
          ...state.placesAlreadyDisplayed,
          saveProperty: true,
        },
      };
    case SET_MODAL_DISPLAYED_ON_SAVE_SEARCH_TO_TRUE:
      return {
        ...state,
        placesAlreadyDisplayed: {
          ...state.placesAlreadyDisplayed,
          saveSearch: true,
        },
      };
    case SET_MODAL_DISPLAYED_ON_CLAIM_HOME_TO_TRUE:
      return {
        ...state,
        placesAlreadyDisplayed: {
          ...state.placesAlreadyDisplayed,
          claimHome: true,
        },
      };
    default: {
      return state;
    }
  }
}
