import {
  BROKERAGE_CONTACT_SUCCESS,
  BROKERAGE_CONTACT_ERROR,
  CLOSE_BROKERAGE_CONTACT_MODAL,
} from '@client/store/actions/brokerage-info.actions';
import { Action } from '@client/store/actions';

export type BrokerageInfoState = {
  shouldOpenSuccessModal: boolean;
  shouldOpenErrorModal: boolean;
};

export const INITIAL_STATE = {
  shouldOpenSuccessModal: false,
  shouldOpenErrorModal: false,
};

export default function brokerageInfoReducer(
  state: BrokerageInfoState = INITIAL_STATE,
  action: Action
): BrokerageInfoState {
  switch (action.type) {
    case BROKERAGE_CONTACT_SUCCESS:
      return {
        ...state,
        shouldOpenSuccessModal: true,
        shouldOpenErrorModal: false,
      };
    case BROKERAGE_CONTACT_ERROR:
      return {
        ...state,
        shouldOpenSuccessModal: false,
        shouldOpenErrorModal: true,
      };
    case CLOSE_BROKERAGE_CONTACT_MODAL:
      return {
        ...state,
        shouldOpenSuccessModal: false,
        shouldOpenErrorModal: false,
      };
    default:
      return state;
  }
}
