const UNIT_INDICATORS = ['apt', 'unit', 'ste', 'suite', '#'];

/**
 * Return a React element for a suggestion, highlighting letters that match letters
 * present in the input string
 * @param  {string} the user's text entry input
 * @param  {array} an array of regex expressions
 * @return {array} an array of React nodes
 */
export const buildHighlightedText = (string: string, matchers: RegExp[]) => {
  return string.split(/\s+/).map((word, i) => {
    const prefix = i === 0 ? '' : ' ';
    for (let a = 0; a < matchers.length; a++) {
      const match = word.match(matchers[a]);
      if (match) {
        return (
          <span key={i}>
            {prefix}
            <b>{match[1]}</b>
            {match[2]}
          </span>
        );
      }
    }
    return (
      <span key={i}>
        {prefix}
        {word}
      </span>
    );
  });
};

/* Turn each word in the query string into a regex that we can use to match against the words in the search text.
 * Each word is mapped into a regexp that is anchored at the start and end of the text, and matches the word,
 * optionally interspersed with non-word characters, and followed by any other text.
 * Some examples of the produced regexes:
 *   San -> ^(\W*S\W*a\W*n\W*)(.*)$
 *   S.t. -> ^(\W*S\W*t\W*)(.*)$
 * A couple of regex notes:
 *   \W is any non-word character (punctuation, etc.)
 */
export const getQueryStringMatchers = (queryString: string) => {
  return queryString.split(/\s+/g).map(
    (word) =>
      new RegExp(
        '^(\\W*' +
          word.replace(/./g, (c) => {
            if (/\W/.test(c)) {
              return '';
            } else {
              return `[${c.toLowerCase()}|${c.toUpperCase()}]\\W*`;
            }
          }) +
          ')(.*)$'
      )
  );
};

/**
 * Given a string, replace accented or otherwise non-standard characters with standard
 * equivalents from a static map
 * @param  {string}
 * @return {string}
 */
export const replaceAccentedCharacters = (string: string) =>
  [...string].map((char) => accentMap[char] || char).join('');

/**
 * Determine whether a given query string contains a unit designator
 * @param queryString - string
 * @returns {boolean}
 */
export const queryStringContainsUnit = (queryString: string) => {
  let regExp = new RegExp(` ${UNIT_INDICATORS.join('| ')}`, 'i');
  return regExp.test(queryString);
};

/**
 * Find and return the unit designator within an address slug
 * @param slug - string
 * @returns {string}
 */
export const getIndicatorForPartialAddress = (slug: string) => {
  let regExp = new RegExp(`-${UNIT_INDICATORS.join('-|-')}-`, 'i');
  let match = slug.match(regExp);
  return match && match[0];
};

export const getCommonUnitIndicatorFromSearchResults = (
  resultList: Array<{ slug: string }>
): string | null => {
  let unitIndicator: string | null;
  const slugArr = resultList.map((r) => r.slug);

  // If only a single result, don't attempt to reduce
  if (slugArr.length === 1) {
    unitIndicator = getIndicatorForPartialAddress(slugArr[0]);
  } else {
    unitIndicator = slugArr.reduce((acc, curr) => {
      const currentIndicator = getIndicatorForPartialAddress(curr);
      const prevIndicator = acc && getIndicatorForPartialAddress(acc);
      if (currentIndicator === prevIndicator && currentIndicator) {
        return currentIndicator;
      } else {
        return '';
      }
    });
  }
  return unitIndicator && unitIndicator.replace(/-/g, '');
};

const accentMap = {
  ẚ: 'a',
  Á: 'A',
  á: 'a',
  À: 'A',
  à: 'a',
  Ă: 'A',
  ă: 'a',
  Ắ: 'A',
  ắ: 'a',
  Ằ: 'A',
  ằ: 'a',
  Ẵ: 'A',
  ẵ: 'a',
  Ẳ: 'A',
  ẳ: 'a',
  Â: 'A',
  â: 'a',
  Ấ: 'A',
  ấ: 'a',
  Ầ: 'A',
  ầ: 'a',
  Ẫ: 'A',
  ẫ: 'a',
  Ẩ: 'A',
  ẩ: 'a',
  Ǎ: 'A',
  ǎ: 'a',
  Å: 'A',
  å: 'a',
  Ǻ: 'A',
  ǻ: 'a',
  Ä: 'A',
  ä: 'a',
  Ǟ: 'A',
  ǟ: 'a',
  Ã: 'A',
  ã: 'a',
  Ȧ: 'A',
  ȧ: 'a',
  Ǡ: 'A',
  ǡ: 'a',
  Ą: 'A',
  ą: 'a',
  Ā: 'A',
  ā: 'a',
  Ả: 'A',
  ả: 'a',
  Ȁ: 'A',
  ȁ: 'a',
  Ȃ: 'A',
  ȃ: 'a',
  Ạ: 'A',
  ạ: 'a',
  Ặ: 'A',
  ặ: 'a',
  Ậ: 'A',
  ậ: 'a',
  Ḁ: 'A',
  ḁ: 'a',
  Ⱥ: 'A',
  ⱥ: 'a',
  Ǽ: 'A',
  ǽ: 'a',
  Ǣ: 'A',
  ǣ: 'a',
  Ḃ: 'B',
  ḃ: 'b',
  Ḅ: 'B',
  ḅ: 'b',
  Ḇ: 'B',
  ḇ: 'b',
  Ƀ: 'B',
  ƀ: 'b',
  ᵬ: 'B',
  Ɓ: 'b',
  ɓ: 'b',
  Ƃ: 'B',
  ƃ: 'b',
  Ć: 'C',
  ć: 'c',
  Ĉ: 'C',
  ĉ: 'c',
  Č: 'C',
  č: 'c',
  Ċ: 'C',
  ċ: 'c',
  Ç: 'C',
  ç: 'c',
  Ḉ: 'C',
  ḉ: 'c',
  Ȼ: 'C',
  ȼ: 'c',
  Ƈ: 'C',
  ƈ: 'c',
  ɕ: 'c',
  Ď: 'D',
  ď: 'd',
  Ḋ: 'D',
  ḋ: 'd',
  Ḑ: 'D',
  ḑ: 'd',
  Ḍ: 'D',
  ḍ: 'd',
  Ḓ: 'D',
  ḓ: 'd',
  Ḏ: 'D',
  ḏ: 'd',
  Đ: 'D',
  đ: 'd',
  ᵭ: 'd',
  Ɖ: 'D',
  ɖ: 'd',
  Ɗ: 'D',
  ɗ: 'd',
  Ƌ: 'D',
  ƌ: 'd',
  ȡ: 'd',
  ð: 'd',
  É: 'E',
  Ə: 'e',
  Ǝ: 'E',
  ǝ: 'e',
  é: 'e',
  È: 'E',
  è: 'e',
  Ĕ: 'E',
  ĕ: 'e',
  Ê: 'E',
  ê: 'e',
  Ế: 'E',
  ế: 'e',
  Ề: 'E',
  ề: 'e',
  Ễ: 'E',
  ễ: 'e',
  Ể: 'E',
  ể: 'e',
  Ě: 'E',
  ě: 'e',
  Ë: 'E',
  ë: 'e',
  Ẽ: 'E',
  ẽ: 'e',
  Ė: 'E',
  ė: 'e',
  Ȩ: 'E',
  ȩ: 'e',
  Ḝ: 'E',
  ḝ: 'e',
  Ę: 'E',
  ę: 'e',
  Ē: 'E',
  ē: 'e',
  Ḗ: 'E',
  ḗ: 'e',
  Ḕ: 'E',
  ḕ: 'e',
  Ẻ: 'E',
  ẻ: 'e',
  Ȅ: 'E',
  ȅ: 'e',
  Ȇ: 'E',
  ȇ: 'e',
  Ẹ: 'E',
  ẹ: 'e',
  Ệ: 'E',
  ệ: 'e',
  Ḙ: 'E',
  ḙ: 'e',
  Ḛ: 'E',
  ḛ: 'e',
  Ɇ: 'E',
  ɇ: 'e',
  ɚ: 'e',
  ɝ: 'e',
  Ḟ: 'F',
  ḟ: 'f',
  ᵮ: 'f',
  Ƒ: 'F',
  ƒ: 'f',
  Ǵ: 'G',
  ǵ: 'g',
  Ğ: 'G',
  ğ: 'g',
  Ĝ: 'G',
  ĝ: 'g',
  Ǧ: 'G',
  ǧ: 'g',
  Ġ: 'G',
  ġ: 'g',
  Ģ: 'G',
  ģ: 'g',
  Ḡ: 'G',
  ḡ: 'g',
  Ǥ: 'G',
  ǥ: 'g',
  Ɠ: 'G',
  ɠ: 'g',
  Ĥ: 'H',
  ĥ: 'h',
  Ȟ: 'H',
  ȟ: 'h',
  Ḧ: 'H',
  ḧ: 'h',
  Ḣ: 'H',
  ḣ: 'h',
  Ḩ: 'H',
  ḩ: 'h',
  Ḥ: 'H',
  ḥ: 'h',
  Ḫ: 'H',
  ḫ: 'h',
  ẖ: 'h',
  Ħ: 'H',
  ħ: 'h',
  Ⱨ: 'H',
  ⱨ: 'h',
  Í: 'I',
  í: 'i',
  Ì: 'I',
  ì: 'i',
  Ĭ: 'I',
  ĭ: 'i',
  Î: 'I',
  î: 'i',
  Ǐ: 'I',
  ǐ: 'i',
  Ï: 'I',
  ï: 'i',
  Ḯ: 'I',
  ḯ: 'i',
  Ĩ: 'I',
  ĩ: 'i',
  İ: 'I',
  Į: 'I',
  į: 'i',
  Ī: 'I',
  ī: 'i',
  Ỉ: 'I',
  ỉ: 'i',
  Ȉ: 'I',
  ȉ: 'i',
  Ȋ: 'I',
  ȋ: 'i',
  Ị: 'I',
  ị: 'i',
  Ḭ: 'I',
  ḭ: 'i',
  ı: 'i',
  Ɨ: 'I',
  ɨ: 'i',
  Ĵ: 'J',
  ĵ: 'j',
  ǰ: 'j',
  ȷ: 'j',
  Ɉ: 'j',
  ɉ: 'j',
  ʝ: 'j',
  ɟ: 'j',
  ʄ: 'j',
  Ḱ: 'K',
  ḱ: 'k',
  Ǩ: 'K',
  ǩ: 'k',
  Ķ: 'K',
  ķ: 'k',
  Ḳ: 'K',
  ḳ: 'k',
  Ḵ: 'K',
  ḵ: 'k',
  Ƙ: 'K',
  ƙ: 'k',
  Ⱪ: 'K',
  ⱪ: 'k',
  Ĺ: 'L',
  ĺ: 'l',
  Ľ: 'L',
  ľ: 'l',
  Ļ: 'L',
  ļ: 'l',
  Ḷ: 'L',
  ḷ: 'l',
  Ḹ: 'L',
  ḹ: 'l',
  Ḽ: 'L',
  ḽ: 'l',
  Ḻ: 'L',
  ḻ: 'l',
  Ł: 'L',
  ł: 'l',
  Ŀ: 'L',
  ŀ: 'l',
  Ƚ: 'L',
  ƚ: 'l',
  Ⱡ: 'L',
  ⱡ: 'l',
  Ɫ: 'L',
  ɫ: 'l',
  ɬ: 'l',
  ɭ: 'l',
  ȴ: 'l',
  Ḿ: 'M',
  ḿ: 'm',
  Ṁ: 'M',
  ṁ: 'm',
  Ṃ: 'M',
  ṃ: 'm',
  ɱ: 'm',
  Ń: 'N',
  ń: 'n',
  Ǹ: 'N',
  ǹ: 'n',
  Ň: 'N',
  ň: 'n',
  Ñ: 'N',
  ñ: 'n',
  Ṅ: 'N',
  ṅ: 'n',
  Ņ: 'N',
  ņ: 'n',
  Ṇ: 'N',
  ṇ: 'n',
  Ṋ: 'N',
  ṋ: 'n',
  Ṉ: 'N',
  ṉ: 'n',
  Ɲ: 'N',
  ɲ: 'n',
  Ƞ: 'N',
  ƞ: 'n',
  ɳ: 'n',
  ȵ: 'n',
  Ó: 'O',
  ó: 'o',
  Ò: 'O',
  ò: 'o',
  Ŏ: 'O',
  ŏ: 'o',
  Ô: 'O',
  ô: 'o',
  Ố: 'O',
  ố: 'o',
  Ồ: 'O',
  ồ: 'o',
  Ỗ: 'O',
  ỗ: 'o',
  Ổ: 'O',
  ổ: 'o',
  Ǒ: 'O',
  ǒ: 'o',
  Ö: 'O',
  ö: 'o',
  Ȫ: 'O',
  ȫ: 'o',
  Ő: 'O',
  ő: 'o',
  Õ: 'O',
  õ: 'o',
  Ṍ: 'O',
  ṍ: 'o',
  Ṏ: 'O',
  ṏ: 'o',
  Ȭ: 'O',
  ȭ: 'o',
  Ȯ: 'O',
  ȯ: 'o',
  Ȱ: 'O',
  ȱ: 'o',
  Ø: 'O',
  ø: 'o',
  Ǿ: 'O',
  ǿ: 'o',
  Ǫ: 'O',
  ǫ: 'o',
  Ǭ: 'O',
  ǭ: 'o',
  Ō: 'O',
  ō: 'o',
  Ṓ: 'O',
  ṓ: 'o',
  Ṑ: 'O',
  ṑ: 'o',
  Ỏ: 'O',
  ỏ: 'o',
  Ȍ: 'O',
  ȍ: 'o',
  Ȏ: 'O',
  ȏ: 'o',
  Ơ: 'O',
  ơ: 'o',
  Ớ: 'O',
  ớ: 'o',
  Ờ: 'O',
  ờ: 'o',
  Ỡ: 'O',
  ỡ: 'o',
  Ở: 'O',
  ở: 'o',
  Ợ: 'O',
  ợ: 'o',
  Ọ: 'O',
  ọ: 'o',
  Ộ: 'O',
  ộ: 'o',
  Ɵ: 'O',
  ɵ: 'o',
  Ṕ: 'P',
  ṕ: 'p',
  Ṗ: 'P',
  ṗ: 'p',
  Ᵽ: 'P',
  Ƥ: 'p',
  ƥ: 'P',
  ʠ: 'q',
  Ɋ: 'Q',
  ɋ: 'q',
  Ŕ: 'R',
  ŕ: 'r',
  Ř: 'R',
  ř: 'r',
  Ṙ: 'R',
  ṙ: 'r',
  Ŗ: 'R',
  ŗ: 'r',
  Ȑ: 'R',
  ȑ: 'r',
  Ȓ: 'R',
  ȓ: 'r',
  Ṛ: 'R',
  ṛ: 'r',
  Ṝ: 'R',
  ṝ: 'r',
  Ṟ: 'R',
  ṟ: 'r',
  Ɍ: 'R',
  ɍ: 'r',
  ᵲ: 'r',
  ɼ: 'r',
  Ɽ: 'R',
  ɽ: 'r',
  ɾ: 'r',
  ᵳ: 'r',
  ß: 's',
  Ś: 'S',
  ś: 's',
  Ṥ: 'S',
  ṥ: 's',
  Ŝ: 'S',
  ŝ: 's',
  Š: 'S',
  š: 's',
  Ṧ: 'S',
  ṧ: 's',
  Ṡ: 'S',
  ṡ: 's',
  ẛ: 's',
  Ş: 'S',
  ş: 's',
  Ṣ: 'S',
  ṣ: 's',
  Ṩ: 'S',
  ṩ: 's',
  Ș: 'S',
  ș: 's',
  ʂ: 's',
  Þ: 't',
  þ: 't',
  Ť: 'T',
  ť: 't',
  ẗ: 't',
  Ṫ: 'T',
  ṫ: 't',
  Ţ: 'T',
  ţ: 't',
  Ṭ: 'T',
  ṭ: 't',
  Ț: 'T',
  ț: 't',
  Ṱ: 'T',
  ṱ: 't',
  Ṯ: 'T',
  ṯ: 't',
  Ŧ: 'T',
  ŧ: 't',
  Ⱦ: 'T',
  ⱦ: 't',
  ᵵ: 't',
  ƫ: 't',
  Ƭ: 'T',
  ƭ: 't',
  Ʈ: 'T',
  ʈ: 't',
  ȶ: 't',
  Ú: 'U',
  ú: 'u',
  Ù: 'U',
  ù: 'u',
  Ŭ: 'U',
  ŭ: 'u',
  Û: 'U',
  û: 'u',
  Ǔ: 'U',
  ǔ: 'u',
  Ů: 'U',
  ů: 'u',
  Ü: 'U',
  ü: 'u',
  Ǘ: 'U',
  ǘ: 'u',
  Ǜ: 'U',
  ǜ: 'u',
  Ǚ: 'U',
  ǚ: 'u',
  Ǖ: 'U',
  ǖ: 'u',
  Ű: 'U',
  ű: 'u',
  Ũ: 'U',
  ũ: 'u',
  Ṹ: 'U',
  ṹ: 'u',
  Ų: 'U',
  ų: 'u',
  Ū: 'U',
  ū: 'u',
  Ṻ: 'U',
  ṻ: 'u',
  Ủ: 'U',
  ủ: 'u',
  Ȕ: 'U',
  ȕ: 'u',
  Ȗ: 'U',
  ȗ: 'u',
  Ư: 'U',
  ư: 'u',
  Ứ: 'U',
  ứ: 'u',
  Ừ: 'U',
  ừ: 'u',
  Ữ: 'U',
  ữ: 'u',
  Ử: 'U',
  ử: 'u',
  Ự: 'U',
  ự: 'u',
  Ụ: 'U',
  ụ: 'u',
  Ṳ: 'U',
  ṳ: 'u',
  Ṷ: 'U',
  ṷ: 'u',
  Ṵ: 'U',
  ṵ: 'u',
  Ʉ: 'U',
  ʉ: 'u',
  Ṽ: 'V',
  ṽ: 'v',
  Ṿ: 'V',
  ṿ: 'v',
  Ʋ: 'V',
  ʋ: 'v',
  Ẃ: 'W',
  ẃ: 'w',
  Ẁ: 'W',
  ẁ: 'w',
  Ŵ: 'W',
  ŵ: 'w',
  ẘ: 'w',
  Ẅ: 'W',
  ẅ: 'w',
  Ẇ: 'W',
  ẇ: 'w',
  Ẉ: 'W',
  ẉ: 'w',
  Ẍ: 'X',
  ẍ: 'x',
  Ẋ: 'X',
  ẋ: 'x',
  Ý: 'Y',
  ý: 'y',
  Ỳ: 'Y',
  ỳ: 'y',
  Ŷ: 'Y',
  ŷ: 'y',
  ẙ: 'y',
  Ÿ: 'Y',
  ÿ: 'y',
  Ỹ: 'Y',
  ỹ: 'y',
  Ẏ: 'Y',
  ẏ: 'y',
  Ȳ: 'Y',
  ȳ: 'y',
  Ỷ: 'Y',
  ỷ: 'y',
  Ỵ: 'Y',
  ỵ: 'y',
  ʏ: 'y',
  Ɏ: 'Y',
  ɏ: 'y',
  Ƴ: 'Y',
  ƴ: 'y',
  Ź: 'Z',
  ź: 'z',
  Ẑ: 'Z',
  ẑ: 'z',
  Ž: 'Z',
  ž: 'z',
  Ż: 'Z',
  ż: 'z',
  Ẓ: 'Z',
  ẓ: 'z',
  Ẕ: 'Z',
  ẕ: 'z',
  Ƶ: 'Z',
  ƶ: 'z',
  Ȥ: 'Z',
  ȥ: 'z',
  ʐ: 'z',
  ʑ: 'z',
  Ⱬ: 'Z',
  ⱬ: 'z',
  Ǯ: 'z',
  ǯ: 'z',
  ƺ: 'z',
  '２': '2',
  '６': '6',
  Ｂ: 'B',
  Ｆ: 'F',
  Ｊ: 'J',
  Ｎ: 'N',
  Ｒ: 'R',
  Ｖ: 'V',
  Ｚ: 'Z',
  ｂ: 'b',
  ｆ: 'f',
  ｊ: 'j',
  ｎ: 'n',
  ｒ: 'r',
  ｖ: 'v',
  ｚ: 'z',
  '１': '1',
  '５': '5',
  '９': '9',
  Ａ: 'A',
  Ｅ: 'E',
  Ｉ: 'I',
  Ｍ: 'M',
  Ｑ: 'Q',
  Ｕ: 'U',
  Ｙ: 'Y',
  ａ: 'a',
  ｅ: 'e',
  ｉ: 'i',
  ｍ: 'm',
  ｑ: 'q',
  ｕ: 'u',
  ｙ: 'y',
  '０': '0',
  '４': '4',
  '８': '8',
  Ｄ: 'D',
  Ｈ: 'H',
  Ｌ: 'L',
  Ｐ: 'P',
  Ｔ: 'T',
  Ｘ: 'X',
  ｄ: 'd',
  ｈ: 'h',
  ｌ: 'l',
  ｐ: 'p',
  ｔ: 't',
  ｘ: 'x',
  '３': '3',
  '７': '7',
  Ｃ: 'C',
  Ｇ: 'G',
  Ｋ: 'K',
  Ｏ: 'O',
  Ｓ: 'S',
  Ｗ: 'W',
  ｃ: 'c',
  ｇ: 'g',
  ｋ: 'k',
  ｏ: 'o',
  ｓ: 's',
  ｗ: 'w',
};
