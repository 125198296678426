/* Ported from react-input-range
 * https://github.com/davidchin/react-input-range */

import React from 'react';
import {
  capitalize,
  distanceTo,
  isDefined,
  isObject,
  length,
  getValueFromProps,
  getPositionsFromValues,
  getValueFromPosition,
  getStepValueFromValue,
  getPositionFromEvent,
  getPercentagesFromValues,
  getDigitsAfterDecimal,
  roundToDecimals,
} from '@client/utils/input-range.utils';
import Slider from '@client/components/generic/InputRangeSlider';
import Label from '@client/components/generic/InputRangeLabel';
import Track from '@client/components/generic/InputRangeTrack';

/**
 * Note: for now, we're using global CSS included in the bundle via the Webpack `entry`. Should be
 * converted to CSS Modules if we intend to keep this library in our codebase.
 */
const DEFAULT_CLASS_NAMES = {
  activeTrack: 'input-range__track input-range__track--active',
  disabledInputRange: 'input-range input-range--disabled',
  inputRange: 'input-range',
  labelContainer: 'input-range__label-container',
  maxLabel: 'input-range__label input-range__label--max',
  minLabel: 'input-range__label input-range__label--min',
  slider: 'input-range__slider',
  sliderContainer: 'input-range__slider-container',
  track: 'input-range__track input-range__track--background',
  valueLabel: 'input-range__label input-range__label--value',
};

const DOWN_ARROW = 40;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const UP_ARROW = 38;

type ValueProp =
  | number
  | {
      min: number;
      max: number;
    };

type Props = {
  allowSameValues?: boolean;
  ariaLabelledby?: string;
  ariaControls?: string;
  classNames: { [key: string]: string };
  disabled?: boolean;
  draggableTrack?: boolean;
  formatLabel?: (value: number) => string;
  maxValue: number;
  minValue: number;
  name?: string;
  onChangeStart?: (value: number | ValueProp) => void;
  onChange: (value: number | ValueProp) => void;
  onChangeComplete?: (value: number | ValueProp) => void;
  step: number;
  value: ValueProp;
  formatAriaValues?: (value: number) => number;
};

export default class InputRange extends React.Component<Props> {
  static defaultProps = {
    allowSameValues: false,
    classNames: DEFAULT_CLASS_NAMES,
    disabled: false,
    maxValue: 10,
    minValue: 0,
    step: 1,
  };

  startValue: number | {} | null;
  node: HTMLElement | null;
  trackNode: Track | null;
  isSliderDragging: boolean;
  lastKeyMoved: string | null;

  constructor(props) {
    super(props);
    this.startValue = null;
    this.node = null;
    this.trackNode = null;
    this.isSliderDragging = false;
    this.lastKeyMoved = null;
  }

  componentWillUnmount() {
    this.removeDocumentMouseUpListener();
    this.removeDocumentTouchEndListener();
  }

  /**
   * Return the CSS class name of the component
   * @return {string}
   */
  getComponentClassName = () => {
    if (!this.props.disabled) {
      return this.props.classNames.inputRange;
    } else {
      return this.props.classNames.disabledInputRange;
    }
  };

  /**
   * Return the bounding rect of the track
   * @return {ClientRect}
   */
  getTrackClientRect = () => {
    return this.trackNode?.getClientRect();
  };

  /**
   * Return the slider key closest to a point
   * @param {Point} position
   * @return {string}
   */
  getKeyByPosition = (position) => {
    const values = getValueFromProps(this.props, this.isMultiValue());
    const positions = getPositionsFromValues(
      values,
      this.props.minValue,
      this.props.maxValue,
      this.getTrackClientRect()
    );

    if (this.isMultiValue()) {
      const distanceToMin = distanceTo(position, positions.min);
      const distanceToMax = distanceTo(position, positions.max);

      if (distanceToMin < distanceToMax) {
        return 'min';
      }
    }

    return 'max';
  };

  /**
   * Return all the slider keys
   * @return {string[]}
   */
  getKeys = () => {
    if (this.isMultiValue()) {
      return ['min', 'max'];
    }

    return ['max'];
  };

  /**
   * Return true if the difference between the new and the current value is
   * greater or equal to the step amount of the component
   * @param {Range} values
   * @return {boolean}
   */
  hasStepDifference = (values) => {
    const currentValues = getValueFromProps(this.props, this.isMultiValue());
    const digitsAfterDecimalInStep = getDigitsAfterDecimal(this.props.step);

    /* Rounding the values to match the significant decimal digits in the `step`.  This allows us to get
     * precise floating point numbers for the value comparison i.e. 1.9999999995 -> 2.00 */
    return (
      roundToDecimals(
        length(values.min, currentValues.min),
        digitsAfterDecimalInStep
      ) >= this.props.step ||
      roundToDecimals(
        length(values.max, currentValues.max),
        digitsAfterDecimalInStep
      ) >= this.props.step
    );
  };

  /**
   * Return true if the component accepts a min and max value
   * @return {boolean}
   */
  isMultiValue = () => {
    return isObject(this.props.value);
  };

  /**
   * Return true if the range is within the max and min value of the component
   * @param {Range} values
   * @return {boolean}
   */
  isWithinRange = (values) => {
    if (this.isMultiValue()) {
      const isWithinMinAndMaxBoundaries =
        values.min >= this.props.minValue && values.max <= this.props.maxValue;

      return this.props.allowSameValues
        ? isWithinMinAndMaxBoundaries && values.min <= values.max
        : isWithinMinAndMaxBoundaries && values.min < values.max;
    }

    return (
      values.max >= this.props.minValue && values.max <= this.props.maxValue
    );
  };

  /**
   * Return true if the new value should trigger a render
   * @param {Range} values
   * @return {boolean}
   */
  shouldUpdate = (values) => {
    return this.isWithinRange(values) && this.hasStepDifference(values);
  };

  /**
   * Update the position of a slider
   * @param {string} key
   * @param {Point} position
   * @return {void}
   */
  updatePosition = (key, position) => {
    const values = getValueFromProps(this.props, this.isMultiValue());
    const positions = getPositionsFromValues(
      values,
      this.props.minValue,
      this.props.maxValue,
      this.getTrackClientRect()
    );

    positions[key] = position;
    this.lastKeyMoved = key;

    this.updatePositions(positions);
  };

  /**
   * Update the positions of multiple sliders
   * @param {Object} positions
   * @param {Point} positions.min
   * @param {Point} positions.max
   * @return {void}
   */
  updatePositions = (positions) => {
    const values = {
      min: getValueFromPosition(
        positions.min,
        this.props.minValue,
        this.props.maxValue,
        this.getTrackClientRect()
      ),
      max: getValueFromPosition(
        positions.max,
        this.props.minValue,
        this.props.maxValue,
        this.getTrackClientRect()
      ),
    };

    const transformedValues = {
      min: getStepValueFromValue(values.min, this.props.step),
      max: getStepValueFromValue(values.max, this.props.step),
    };

    this.updateValues(transformedValues);
  };

  /**
   * Update the value of a slider
   * @param {string} key
   * @param {number} value
   * @return {void}
   */
  updateValue = (key, newValue) => {
    const values = getValueFromProps(this.props, this.isMultiValue());

    /* If new value is within range, proceed with setting new value */
    if (
      this.isWithinRange({
        ...values,
        [key]: newValue,
      })
    ) {
      values[key] = newValue;
      this.updateValues(values);
    }
  };

  /**
   * Update the values of multiple sliders
   * @param {Range|number} values
   * @return {void}
   */
  updateValues = (values) => {
    if (!this.shouldUpdate(values)) {
      return;
    }

    this.props.onChange(this.isMultiValue() ? values : values.max);
  };

  /**
   * Increment the value of a slider by key name
   * @param {string} key
   * @return {void}
   */
  incrementValue = (key) => {
    const values = getValueFromProps(this.props, this.isMultiValue());
    const newValue = values[key] + this.props.step;

    this.updateValue(key, newValue);
  };

  /**
   * Decrement the value of a slider by key name
   * @param {string} key
   * @return {void}
   */
  decrementValue = (key) => {
    const values = getValueFromProps(this.props, this.isMultiValue());
    const newValue = values[key] - this.props.step;

    this.updateValue(key, newValue);
  };

  /**
   * Listen to mouseup event
   * @return {void}
   */
  addDocumentMouseUpListener = () => {
    this.removeDocumentMouseUpListener();
    this.node &&
      this.node.ownerDocument.addEventListener('mouseup', this.handleMouseUp);
  };

  /**
   * Listen to touchend event
   * @return {void}
   */
  addDocumentTouchEndListener = () => {
    this.removeDocumentTouchEndListener();
    this.node &&
      this.node.ownerDocument.addEventListener('touchend', this.handleTouchEnd);
  };

  /**
   * Stop listening to mouseup event
   * @return {void}
   */
  removeDocumentMouseUpListener = () => {
    this.node &&
      this.node.ownerDocument.removeEventListener(
        'mouseup',
        this.handleMouseUp
      );
  };

  /**
   * Stop listening to touchend event
   * @return {void}
   */
  removeDocumentTouchEndListener = () => {
    this.node &&
      this.node.ownerDocument.removeEventListener(
        'touchend',
        this.handleTouchEnd
      );
  };

  /**
   * Handle any "mousemove" event received by the slider
   * @param {SyntheticEvent} event
   * @param {string} key
   * @return {void}
   */
  handleSliderDrag = (event, key) => {
    if (this.props.disabled) {
      return;
    }

    const position = getPositionFromEvent(event, this.getTrackClientRect());
    this.isSliderDragging = true;
    requestAnimationFrame(() => this.updatePosition(key, position));
  };

  /**
   * Handle any "mousemove" event received by the track
   * @param {SyntheticEvent} event
   * @return {void}
   */
  handleTrackDrag = (event, prevEvent) => {
    if (
      this.props.disabled ||
      !this.props.draggableTrack ||
      this.isSliderDragging
    ) {
      return;
    }

    const { maxValue, minValue, value } = this.props;

    const position = getPositionFromEvent(event, this.getTrackClientRect());
    const usefulValue = getValueFromPosition(
      position,
      minValue,
      maxValue,
      this.getTrackClientRect()
    );
    const stepValue = getStepValueFromValue(usefulValue, this.props.step);

    const prevPosition = getPositionFromEvent(
      prevEvent,
      this.getTrackClientRect()
    );
    const prevValue = getValueFromPosition(
      prevPosition,
      minValue,
      maxValue,
      this.getTrackClientRect()
    );
    const prevStepValue = getStepValueFromValue(prevValue, this.props.step);

    const offset = prevStepValue - stepValue;
    const min = typeof value === 'number' ? value : value.min;
    const max = typeof value === 'number' ? value : value.min;
    const transformedValues = {
      min: min - offset,
      max: max - offset,
    };

    this.updateValues(transformedValues);
  };

  /**
   * Handle any "keydown" event received by the slider
   * @param {SyntheticEvent} event
   * @param {string} key
   * @return {void}
   */
  handleSliderKeyDown = (event, key) => {
    if (this.props.disabled) {
      return;
    }

    const keyCode = event.keyCode;
    const iOSKeyName = event.key;

    if (
      keyCode === LEFT_ARROW ||
      keyCode === DOWN_ARROW ||
      iOSKeyName === 'UIKeyInputLeftArrow'
    ) {
      event.preventDefault();
      this.decrementValue(key);
    } else if (
      keyCode === RIGHT_ARROW ||
      keyCode === UP_ARROW ||
      iOSKeyName === 'UIKeyInputRightArrow'
    ) {
      event.preventDefault();
      this.incrementValue(key);
    }
  };

  /**
   * Handle any "mousedown" event received by the track
   * @param {SyntheticEvent} event
   * @param {Point} position
   * @return {void}
   */
  handleTrackMouseDown = (event, position) => {
    if (this.props.disabled) {
      return;
    }

    const { maxValue, minValue, value } = this.props;

    event.preventDefault();

    const usableValue = getValueFromPosition(
      position,
      minValue,
      maxValue,
      this.getTrackClientRect()
    );
    const stepValue = getStepValueFromValue(usableValue, this.props.step);

    const min = typeof value === 'number' ? value : value.min;
    const max = typeof value === 'number' ? value : value.max;

    if (!this.props.draggableTrack || stepValue > max || stepValue < min) {
      this.updatePosition(this.getKeyByPosition(position), position);
    }
  };

  /**
   * Handle the start of any mouse/touch event
   * @return {void}
   */
  handleInteractionStart = () => {
    if (this.props.onChangeStart) {
      this.props.onChangeStart(this.props.value);
    }

    if (this.props.onChangeComplete && !isDefined(this.startValue)) {
      this.startValue = this.props.value;
    }
  };

  /**
   * Handle the end of any mouse/touch event
   * @return {void}
   */
  handleInteractionEnd = () => {
    if (this.isSliderDragging) {
      this.isSliderDragging = false;
    }

    if (!this.props.onChangeComplete || !isDefined(this.startValue)) {
      return;
    }

    if (this.startValue !== this.props.value) {
      this.props.onChangeComplete(this.props.value);
    }

    this.startValue = null;
  };

  /**
   * Handle any "keydown" event received by the component
   * @param {SyntheticEvent} event
   * @return {void}
   */
  handleKeyDown = (event) => {
    this.handleInteractionStart();
  };

  /**
   * Handle any "keyup" event received by the component
   * @param {SyntheticEvent} event
   * @return {void}
   */
  handleKeyUp = (event) => {
    this.handleInteractionEnd();
  };

  /**
   * Handle any "mousedown" event received by the component
   * @param {SyntheticEvent} event
   * @return {void}
   */
  handleMouseDown = (event) => {
    this.handleInteractionStart();
    this.addDocumentMouseUpListener();
  };

  /**
   * Handle any "mouseup" event received by the component
   * @param {SyntheticEvent} event
   */
  handleMouseUp = (event) => {
    this.handleInteractionEnd();
    this.removeDocumentMouseUpListener();
  };

  /**
   * Handle any "touchstart" event received by the component
   * @param {SyntheticEvent} event
   * @return {void}
   */
  handleTouchStart = (event) => {
    this.handleInteractionStart();
    this.addDocumentTouchEndListener();
  };

  /**
   * Handle any "touchend" event received by the component
   * @param {SyntheticEvent} event
   */
  handleTouchEnd = (event) => {
    this.handleInteractionEnd();
    this.removeDocumentTouchEndListener();
  };

  /**
   * Return JSX of sliders
   * @return {JSX.Element}
   */
  renderSliders = () => {
    const values = getValueFromProps(this.props, this.isMultiValue());
    const percentages = getPercentagesFromValues(
      values,
      this.props.minValue,
      this.props.maxValue
    );
    const keys =
      this.props.allowSameValues && this.lastKeyMoved === 'min'
        ? this.getKeys().reverse()
        : this.getKeys();

    return keys.map((key) => {
      const value = values[key];
      const percentage = percentages[key];

      let { maxValue, minValue } = this.props;

      if (key === 'min') {
        maxValue = values.max;
      } else {
        minValue = values.min;
      }

      const slider = (
        <Slider
          formatAria={this.props.formatAriaValues}
          ariaLabelledby={this.props.ariaLabelledby}
          ariaControls={this.props.ariaControls}
          classNames={this.props.classNames}
          formatLabel={this.props.formatLabel}
          key={key}
          maxValue={maxValue}
          minValue={minValue}
          onSliderDrag={this.handleSliderDrag}
          onSliderKeyDown={this.handleSliderKeyDown}
          percentage={percentage}
          type={key}
          value={value}
        />
      );

      return slider;
    });
  };

  /**
   * Return JSX of hidden inputs
   * @return {JSX.Element}
   */
  renderHiddenInputs = () => {
    if (!this.props.name) {
      return [];
    }

    const isMultiValue = this.isMultiValue();
    const values = getValueFromProps(this.props, isMultiValue);

    return this.getKeys().map((key) => {
      const value = values[key];
      const name = isMultiValue
        ? `${this.props.name}${capitalize(key)}`
        : this.props.name;

      return <input key={key} type="hidden" name={name} value={value} />;
    });
  };

  render() {
    const componentClassName = this.getComponentClassName();
    const values = getValueFromProps(this.props, this.isMultiValue());
    const percentages = getPercentagesFromValues(
      values,
      this.props.minValue,
      this.props.maxValue
    );

    return (
      <div
        aria-disabled={this.props.disabled}
        ref={(node) => {
          this.node = node;
        }}
        className={componentClassName}
        onKeyDown={this.handleKeyDown}
        onKeyUp={this.handleKeyUp}
        onMouseDown={this.handleMouseDown}
        onTouchStart={this.handleTouchStart}
      >
        <Label
          classNames={this.props.classNames}
          formatLabel={this.props.formatLabel}
          type="min"
        >
          {this.props.minValue}
        </Label>

        <Track
          classNames={this.props.classNames}
          draggableTrack={!!this.props.draggableTrack}
          ref={(trackNode) => {
            this.trackNode = trackNode;
          }}
          percentages={percentages}
          onTrackDrag={this.handleTrackDrag}
          onTrackMouseDown={this.handleTrackMouseDown}
        >
          {this.renderSliders()}
        </Track>

        <Label
          classNames={this.props.classNames}
          formatLabel={this.props.formatLabel}
          type="max"
        >
          {this.props.maxValue}
        </Label>

        {this.renderHiddenInputs()}
      </div>
    );
  }
}
