import React, { useState } from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import ShareButton from '@client/components/ShareButton';
import ShareModal from '@client/components/ShareModal';

type Props = {
  id?: string;
  slug: string | null;
  theme: Theme;
};

/**
 * A share icon that triggers an included share modal that references the passed-in
 * property slug
 */
const ShareButtonWithModal: React.FunctionComponent<Props> = ({
  theme,
  slug,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setIsModalOpen(true);
  };
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={theme.ShareButtonWithModal}>
      <ShareButton onClick={handleOpen} theme={theme} />
      <ShareModal
        isActive={isModalOpen}
        onCloseButtonClick={handleClose}
      />
    </div>
  );
};

export default themr('ShareButtonWithModalThemed', {})(ShareButtonWithModal);
