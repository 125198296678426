import {
  format,
  differenceInDays,
  differenceInMonths,
  getYear,
  getMonth,
  parse,
  isToday,
  isYesterday,
  isThisWeek,
} from 'date-fns';
import React from 'react';

const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT_COMPACT = 'MM/DD/YY';

/**
 * Format a date
 */
export const formatDate = (
  dateStr: string | number | Date,
  customFormat?: string
): string => format(new Date(dateStr), customFormat || DATE_FORMAT);

/**
 * Determine if a date is less than X months ago
 */
export const isLessThanMonthsOld = (dateStr: string, months: number): boolean =>
  differenceInMonths(new Date(), new Date(dateStr)) < months;

/**
 * Determine the number of days old the provided date is (difference between provided date and current date in days)
 */
export const getDaysOld = (dateStr: string): number =>
  differenceInDays(new Date(), new Date(dateStr));

/**
 * Given a Date object, return a formatted string representing the first day of
 * the given month and year
 */
export const getFirstOfMonthStringForDateObj = (dateObj: Date): string =>
  format(new Date(getYear(dateObj), getMonth(dateObj), 1), 'YYYY-MM-DD');

/**
 * Given a Date object, return a formatted string representing the first day of
 * the given month and year
 */
export const getGQLDateStringForDateObj = (dateObj: Date): string =>
  format(dateObj, 'YYYY-MM-DD');

export const displayDateCompact = (dateStr: string): string =>
  format(parse(dateStr), DATE_FORMAT_COMPACT);

export const getVerbalMonthAndYear = (dateObj: Date | string): string =>
  format(dateObj, 'MMMM YYYY');

export const parseDateTimeRelatively = (
  dateFromApi: string,
  isAt?: boolean
) => {
  const date = new Date(dateFromApi);
  const timeFormat = 'h:mm a';
  const isAtStr = isAt ? 'at ' : '';

  if (isToday(date)) {
    return (
      <>
        Today {isAtStr}
        {format(date, timeFormat)}
      </>
    );
  } else if (isYesterday(date)) {
    return (
      <>
        Yesterday {isAtStr}
        {format(date, timeFormat)}
      </>
    );
  } else if (isThisWeek(date)) {
    return <>This Week</>;
  } else return <>{format(date, 'MMM D, YYYY')}</>;
};
