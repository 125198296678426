import {
  DEFAULT_STATS_ZOOM_MAPPING,
  LAYER_GROUP_LAYER_METRICS,
  LAYER_SOURCE_ZOOM_MAPPING,
  MAP_MARKER_ACTIVE_LISTING_TEXT_COLOR,
  MARKER_IMAGE_IDS,
  SCHOOL_DEFAULT_COLOR,
  SCHOOLS_LEGEND_INTERVALS,
} from '@client/store/map-constants';
import { Business } from '@client/store/types/local-activities';
import {
  APIClusterMapMarker,
  BoundsArray,
  BoundsObject,
  BusinessMarker,
  LatitudeLongitudeObject,
  LatLngObject,
  LegendColorTable,
  LegendInterval,
  MapMarker,
  MultiUnitDataObject,
  MultiUnitMapMarker,
  PositionArray,
  PropertyMapMarker,
  SetMapLocation,
  SubjectMapMarker,
  TileCoord,
} from '@client/store/types/maps';
import { NormalizedProperty } from '@client/store/types/property';
import { calcMetersToMiles } from '@client/utils/property.utils';
import { abbrNumberFormatter } from '@client/utils/string.utils';
import geoViewport from '@mapbox/geo-viewport';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { Geometry, MultiPolygon, Polygon } from 'geojson';
import mapboxgl, { LngLatBounds } from 'mapbox-gl';

/* In meters */
const EARTH_RADIUS = 6371008.8;
const METERS_IN_ONE_LATITUDE_DEGREE = 111111;

type BuildPropertyMarkerArgs = {
  addressSlug: string;
  latitude: number | null;
  longitude: number | null;
  label: string | null;
  labelColor: string | null;
  buildingId: number | null;
  tractId?: string | null;
  isCheckedStyle?: boolean;
  isVisitedStyle?: boolean;
  imageId: string;
  visitedImageId?: string;
  visitedLabelColor?: string;
  isPulsing?: boolean;
  normalizedPropertyData: NormalizedProperty;
};

type BuildSubjectMarkerArgs = {
  addressSlug: string;
  latitude: number | null;
  longitude: number | null;
  label: string | null;
  labelColor: string | null;
  buildingId: number | null;
  imageId: string;
  isPulsing?: boolean;
  normalizedPropertyData?: NormalizedProperty;
};

type BuildMultiUnitClusterMarkerArgs = {
  latitude: number | null;
  longitude: number | null;
  label: string;
  labelColor: string;
  imageId: string;
  propertyCount: number;
  childAddressSlugs: string[];
  isCheckedStyle?: boolean;
  tractId?: string | null;
};

type BuildAPIClusterMarkerArgs = {
  latitude: number | null;
  longitude: number | null;
  label: string;
  smallerClusterSize?: boolean;
};

type BuildMultiUnitDataObjectArgs = {
  addressSlug: string;
  latitude: number | null;
  longitude: number | null;
  normalizedPropertyData: NormalizedProperty;
  shouldNotBeSetOnState: boolean;
};

/**
 * Create a map marker object to be passed to the maps library
 */
export const buildPropertyMarker = ({
  addressSlug,
  latitude,
  longitude,
  label,
  labelColor,
  buildingId,
  isCheckedStyle,
  isVisitedStyle,
  imageId,
  visitedImageId,
  visitedLabelColor,
  isPulsing,
  tractId,
  normalizedPropertyData,
}: BuildPropertyMarkerArgs): PropertyMapMarker | null => {
  const marker = {
    isProperty: true as true,
    uId: `marker${latitude}${longitude}${addressSlug}`,
    lat: latitude as number,
    lng: longitude as number,
    label,
    labelColor,
    imageId,
    visitedImageId,
    visitedLabelColor,
    addressSlug,
    buildingId,
    isCheckedStyle: !!isCheckedStyle,
    isVisitedStyle: !!isVisitedStyle,
    isPulsing: !!isPulsing,
    tractId,
    normalizedPropertyData,
  };
  return marker.lat && marker.lng ? marker : null;
};

/* Same as PropertyMapMarker but with an optional normalizedPropertyData field */
export const buildSubjectMarker = ({
  addressSlug,
  latitude,
  longitude,
  label,
  labelColor,
  buildingId,
  imageId,
  isPulsing,
  normalizedPropertyData,
}: BuildSubjectMarkerArgs): SubjectMapMarker | null => {
  const marker = {
    isProperty: true as true,
    uId: `marker${latitude}${longitude}${addressSlug}`,
    lat: latitude as number,
    lng: longitude as number,
    label,
    labelColor,
    imageId,
    addressSlug,
    buildingId,
    isPulsing,
    normalizedPropertyData,
  };
  return marker.lat && marker.lng ? marker : null;
};

/**
 * Create a Yelp Business map marker object to be passed to the maps library
 * the majority of options are passed from Yelp's API data.
 * alias,
 * @param  {string} options.url - the link back to Yelps page for the business,
 * @param  {object} options.coordinates - the lat and long coordinates,
 * @param  {string} options.name - business name,
 * @param  {string} options.parentCategory - the category we group them in within the api response,
 * @param  {string} options.imageURL - the url for the image,
 * @param  {string} options.price - a string of dollar signs showing how expensive the business is,
 * @param  {array} options.categories - an array of categories that the business falls under,
 * @param  {number} options.distance - the distance from the property in meters,
 * @param  {number} options.reviewCount - the amount of ratings on Yelp,
 * @param  {string} options.rating - the yelp star rating,
 * @param  {string} options.alias - the yelp slug
 * non-yelp options:
 * @param  {boolean} options.isPulsing - used to determine marker style
 * @return {object}
 */
export const buildBusinessMarker = ({
  alias,
  url,
  coordinates,
  name,
  parentCategory,
  imageURL,
  price,
  categories,
  distance,
  reviewCount,
  rating,
}: Business): BusinessMarker | null => {
  const marker = {
    name,
    url,
    isProperty: true as true,
    isBusinessMarker: true as true,
    uId: `marker${coordinates?.latitude}${coordinates?.longitude}${alias}`,
    lat: coordinates?.latitude,
    lng: coordinates?.longitude,
    imageId: parentCategory,
    normalizedPropertyData: {
      name,
      imageURL,
      price,
      reviewCount,
      rating,
      category: categories && categories[0] && categories[0].title,
      distanceInMiles: distance && calcMetersToMiles(distance),
      linkUrl: url,
      uId: alias,
      slug: alias,
    },
  };
  return marker.lat && marker.lng && categories && categories[0]
    ? (marker as BusinessMarker)
    : null;
};

/**
 * Create a map marker object to be passed to the maps library, used to represent
 * a API-returned cluster of properties
 */
export const buildAPIClusterMarker = ({
  latitude,
  longitude,
  label,
  smallerClusterSize,
}: BuildAPIClusterMarkerArgs): APIClusterMapMarker | null => {
  /* Using a .png image for the marker and scaling based on the cluster count */
  const imageScaleRange = smallerClusterSize ? [0.4, 0.6] : [0.5, 0.8];
  /* An upper limit to the size that the cluster can be */
  const propertyCount = parseInt(label, 10);
  /* Property counts higher than this number won't have an effect on the size of the cluster */
  const SCALE_UPPER_PROPERTY_LIMIT = 1000;
  const effectivePropertyCount =
    propertyCount > SCALE_UPPER_PROPERTY_LIMIT
      ? SCALE_UPPER_PROPERTY_LIMIT
      : propertyCount;
  const rawImageScale =
    imageScaleRange[0] +
    ((imageScaleRange[1] - imageScaleRange[0]) * effectivePropertyCount) /
      SCALE_UPPER_PROPERTY_LIMIT;
  const marker = {
    isCluster: true as true,
    uId: `cluster${latitude}${longitude}-${label}`,
    lat: latitude as number,
    lng: longitude as number,
    imageId: MARKER_IMAGE_IDS.CLUSTER,
    imageScale:
      rawImageScale > 1
        ? 1
        : rawImageScale /* Prevent overly large image scaling */,
    label,
    labelColor: MAP_MARKER_ACTIVE_LISTING_TEXT_COLOR,
  };
  return marker.lat && marker.lng ? marker : null;
};

/**
 * Create a map marker object to be passed to the maps library, used to represent
 * a multi-unit cluster of properties
 */
export const buildMultiUnitClusterMarker = ({
  latitude,
  longitude,
  label,
  labelColor,
  imageId,
  propertyCount,
  childAddressSlugs,
  isCheckedStyle,
  tractId,
}: BuildMultiUnitClusterMarkerArgs): MultiUnitMapMarker | null => {
  const marker = {
    isMultiUnitCluster: true as true,
    uId: `multiunitcluster${latitude}${longitude}`,
    lat: latitude as number,
    lng: longitude as number,
    imageId,
    label,
    labelColor,
    propertyCount,
    childAddressSlugs,
    isCheckedStyle: !!isCheckedStyle,
    tractId: tractId || null,
  };
  return marker.lat && marker.lng ? marker : null;
};

/**
 * Creates an object representing a multi-unit property for display in a multi-unit select list
 * @param  {string} options.addressSlug
 * @param  {number} options.latitude
 * @param  {number} options.longitude
 * @param  {object} options.normalizedPropertyData
 * @param  {object} options.shouldNotBeSetOnState - in some cases such as when we include the comps
 *   subject property in the multi-unit comps selector, we don't want to override the subject state
 *   data with the normalizedPropertyData
 * @return {object}
 */
export const buildMultiUnitDataObject = ({
  addressSlug,
  latitude,
  longitude,
  normalizedPropertyData,
  shouldNotBeSetOnState,
}: BuildMultiUnitDataObjectArgs): MultiUnitDataObject | null => {
  const data = {
    isProperty: true as true,
    lat: latitude as number,
    lng: longitude as number,
    addressSlug,
    normalizedPropertyData,
    shouldNotBeSetOnState,
  };
  return data.lat && data.lng ? data : null;
};

export const isPropertyMarker = (
  marker: MapMarker
): marker is PropertyMapMarker =>
  !!marker && !!(marker as PropertyMapMarker).isProperty;
export const isAPIClusterMarker = (
  marker: MapMarker
): marker is APIClusterMapMarker =>
  !!marker && !!(marker as APIClusterMapMarker).isCluster;
export const isMultiUnitClusterMarker = (
  marker: MapMarker
): marker is MultiUnitMapMarker =>
  !!marker && !!(marker as MultiUnitMapMarker).isMultiUnitCluster;
export const isBusinessMarker = (
  marker: MapMarker | BusinessMarker
): marker is BusinessMarker =>
  !!marker && !!(marker as BusinessMarker).isBusinessMarker;
export const isOnMarketPropertyMarker = (
  marker: MapMarker
): marker is PropertyMapMarker =>
  isPropertyMarker(marker) &&
  ((marker as PropertyMapMarker).imageId === MARKER_IMAGE_IDS.ON_MARKET ||
    (marker as PropertyMapMarker).imageId === MARKER_IMAGE_IDS.SUBJECT);
export const isOffMarketPropertyMarker = (
  marker: MapMarker
): marker is PropertyMapMarker =>
  isPropertyMarker(marker) &&
  ((marker as PropertyMapMarker).imageId === MARKER_IMAGE_IDS.OFF_MARKET ||
    (marker as PropertyMapMarker).imageId ===
      MARKER_IMAGE_IDS.SUBJECT_OFF_MARKET);
export const isOnMarketMultiUnitClusterMarker = (
  marker: MapMarker
): marker is MultiUnitMapMarker =>
  isMultiUnitClusterMarker(marker) &&
  ((marker as MultiUnitMapMarker).imageId === MARKER_IMAGE_IDS.ON_MARKET ||
    (marker as MultiUnitMapMarker).imageId === MARKER_IMAGE_IDS.SUBJECT);
export const isOffMarketMultiUnitClusterMarker = (
  marker: MapMarker
): marker is MultiUnitMapMarker =>
  isMultiUnitClusterMarker(marker) &&
  ((marker as MultiUnitMapMarker).imageId === MARKER_IMAGE_IDS.OFF_MARKET ||
    (marker as MultiUnitMapMarker).imageId ===
      MARKER_IMAGE_IDS.SUBJECT_OFF_MARKET);
/**
 * Create an object with color, label from Intervals object to be used for labels, tooltip in the map legend
 * @param  [object]
 * @param  {object} labelFormatterOptions
 * @return {object}
 */
export const getColorTableFromIntervals = ({
  intervals,
  isCurrencyBased,
  isPercentBased,
  valueSuffix,
}: {
  intervals: LegendInterval[];
  isCurrencyBased: boolean;
  isPercentBased: boolean;
  valueSuffix: string;
}): LegendColorTable => {
  /* Allow overriding but fallback to our current assumption */
  let prefix = isCurrencyBased ? '$' : '';
  let suffix =
    valueSuffix !== undefined ? valueSuffix : isPercentBased ? '%' : '';

  return intervals.map((interval, i) => {
    let low;
    let high;

    /* Label override, currently only used for schools layer */
    if (interval[3]) {
      return {
        color: interval[2],
        label: interval[3],
        patternImageUrl: null,
      };
    }
    if (!isPercentBased) {
      if (interval[0] < 0) {
        interval[0] = 0;
      }
      if (interval[1] < 0) {
        interval[1] = 0;
      }
      if (i !== 0) {
        const current = parseInt(`${interval[0]}`, 10);
        const previous = parseInt(`${intervals[i - 1][1]}`, 10);

        if (current === previous) {
          interval[0] = current + 1;
        }
      }
      low = abbrNumberFormatter(interval[0]);
      high = abbrNumberFormatter(interval[1]);
    } else {
      low = (interval[0] * 100).toFixed(1);
      if (i !== 0) {
        low = (parseFloat(low) + 0.1).toFixed(1);
      }
      high = (interval[1] * 100).toFixed(1);
    }
    low = `${prefix}${low}${suffix}`;
    high = `${prefix}${high}${suffix}`;

    return { color: interval[2], label: [low, high], patternImageUrl: null };
  });
};

export const getPreferredPlaceTypesForAreaSize = (size: number): string[] => {
  if (size > 0 && size < 10000) {
    return ['neighborhood', 'sublocality'];
  } else if (size >= 10000 && size < 20000) {
    return ['sublocality'];
  } else {
    return [];
  }
};

/**
 * Given Google geocode results for a lat,lng, return a place identifier string
 * @param  {array} results from the Google geocode API
 * @param  {string} diagonal distance between the northeast and southwest viewport bounds in meters
 * @return {string} description of a place
 */
export const getPlaceIdentifierStringFromGeocodeResults = (
  results,
  areaSize
) => {
  /* A list of types that we're looking through, in order of precedence */
  const effectiveTypesToSearch = [
    ...(areaSize ? getPreferredPlaceTypesForAreaSize(areaSize) : []),
    'administrative_area_level_3', // town
    'locality', // city
    'administrative_area_level_2', // county
    'postal_code', // zip
    'administrative_area_level_1', // state
  ];
  let output = '';
  /* Search through results, looking for a formatted address associated with the type we seek */
  for (
    let typeIndex = 0;
    typeIndex < effectiveTypesToSearch.length;
    typeIndex++
  ) {
    for (let resultIndex = 0; resultIndex < results.length; resultIndex++) {
      if (
        results[resultIndex].types.indexOf(effectiveTypesToSearch[typeIndex]) >
        -1
      ) {
        output = results[resultIndex].formattedAddress;
        break;
      }
    }
    if (output) {
      break;
    }
  }
  /* Remove "USA" and zipcode from formatted address, if present */
  output = output.replace(/, USA$/i, '').replace(/ \d{5}$/, '');
  return output;
};

/**
 * Given a school's rank, return the color used to fill its district layer
 */
export const getSchoolDistrictFeatureColor = (rank: number): string => {
  for (let i = 0; i < SCHOOLS_LEGEND_INTERVALS.length; i++) {
    if (
      rank > SCHOOLS_LEGEND_INTERVALS[i][0] &&
      rank <= SCHOOLS_LEGEND_INTERVALS[i][1]
    ) {
      return (
        SCHOOLS_LEGEND_INTERVALS[i][2] ||
        SCHOOL_DEFAULT_COLOR /* this fallback should never be hit */
      );
    }
  }
  return SCHOOL_DEFAULT_COLOR;
};

/**
 * Given lat, lng coordinates, return tile coordinates for the given zoom level
 * https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames
 */
export const latLngToTileCoords = ({
  lat,
  lng,
  zoom,
}: {
  lat: number;
  lng: number;
  zoom: number;
}): Omit<TileCoord, 'z'> => {
  return {
    x: Math.floor(((lng + 180) / 360) * Math.pow(2, zoom)),
    y: Math.floor(
      ((1 -
        Math.log(
          Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)
        ) /
          Math.PI) /
        2) *
        Math.pow(2, zoom)
    ),
  };
};

/**
 * Given tile coordinates, return a lat,lng for the given zoom level
 * https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames
 */
export const tileCoordsToLatLng = ({ x, y, z }: TileCoord): LatLngObject => {
  if (!z) {
    throw new Error(
      `Zoom value is required to be passed to tileCoordsToLatLng, you passed ${z}`
    );
  }
  const n = Math.PI - (2 * Math.PI * y) / Math.pow(2, z);
  return {
    lat: (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))),
    lng: (x / Math.pow(2, z)) * 360 - 180,
  };
};

/**
 * Given a layer metric, get the id of the layer group to which it belongs
 */
export const getLayerGroupIdForLayerMetric = (
  metric: string
): string | undefined => {
  return Object.keys(LAYER_GROUP_LAYER_METRICS).find((groupId) => {
    return LAYER_GROUP_LAYER_METRICS[groupId].indexOf(metric) > -1;
  });
};

/**
 * Given a layer metric and zoom level, return the appropriate source key for requesting legend
 * statistics from the API.  Returns a source key, i.e. 'consumer_blocks', 'consumer_block_groups'
 */
export const getSourceKeyForLayerAndZoomLevel = (
  metric: string,
  zoom: number
): string | undefined => {
  /* If we have a metric (if a layer is active), use the zoom mapping defined for the layer.
   * If no metric is available, use a "default" zoom mapping (needed so that breaks are
   * ready for when a layer is activated) */
  const sourceMapping = metric
    ? LAYER_SOURCE_ZOOM_MAPPING[metric]
    : DEFAULT_STATS_ZOOM_MAPPING;

  return Object.keys(sourceMapping).find((key) => {
    const zoomRange = sourceMapping[key];
    return zoom >= zoomRange[0] && zoom < (zoomRange[1] || Infinity);
  });
};

/**
 * Determine whether the passed position is a [ lat, lng ] point
 */
export function getIsPositionPoint(
  position: SetMapLocation | number
): position is PositionArray {
  return (
    !!position &&
    Array.isArray(position) &&
    position.length === 2 &&
    typeof position[0] === 'number' &&
    typeof position[1] === 'number'
  );
}

/**
 * Determine whether the passed position is an array of 2 [ lat, lng ] point bounds
 */
export function getIsPositionBounds(
  position: SetMapLocation
): position is BoundsArray {
  return (
    !!position &&
    Array.isArray(position) &&
    position.length === 2 &&
    getIsPositionPoint(position[0]) &&
    getIsPositionPoint(position[1])
  );
}
/**
 * Given a set of tile coords, return the tile coords of the tile n zoom level(s) lower
 */
export const getParentTileCoordsForCoords = (
  { x, y, z },
  zoomLevelsOut = 1
): TileCoord => {
  const divisor = Math.pow(2, zoomLevelsOut);
  return {
    x: Math.floor(x / divisor),
    y: Math.floor(y / divisor),
    z: z - zoomLevelsOut,
  };
};

/**
 * Given a set of tile coords, return a set of n tile coords belonging to the tiles at
 * the same position n zoom level(s) higher
 */
export const getChildTileCoordsForCoords = (
  tile,
  zoomLevelsIn = 1
): TileCoord[] => {
  const multiplier = Math.pow(2, zoomLevelsIn);
  const startX = tile.x * multiplier;
  const startY = tile.y * multiplier;

  let tiles: { x: number; y: number; z: number }[] = [];

  for (let x = startX; x < startX + multiplier; x++) {
    for (let y = startY; y < startY + multiplier; y++) {
      tiles.push({ x, y, z: tile.z + zoomLevelsIn });
    }
  }
  return tiles;
};

/* Generate a tile key for a set of tile coords */
export const getTileKeyForTile = ({ x, y, z }: TileCoord): string =>
  `${x}:${y}:${z}`;

/* Generate a multi-unit cluster marker key given a lat,lng */
export const getMultiUnitClusterKey = ({
  latitude,
  longitude,
}: LatitudeLongitudeObject): string => `${latitude}${longitude}`;

/* Regex for getting the zoom level from a tile key */
export const TILE_KEY_ZOOM_REGEX = /\d+:\d+:(\d+)/;

/**
 * Calculate the array of tiles within the given set of bounds
 */
export const getTilesInBounds = ({
  southWest,
  northEast,
  zoom,
}: BoundsObject): TileCoord[] => {
  if (!zoom || !Number.isInteger(zoom)) {
    throw new Error(
      `Zoom provided to getTilesInBounds must be an integer, you passed: ${zoom}`
    );
  }
  const tileNE = latLngToTileCoords({
    lat: northEast.lat,
    lng: northEast.lng,
    zoom,
  });
  const tileSW = latLngToTileCoords({
    lat: southWest.lat,
    lng: southWest.lng,
    zoom,
  });
  const numRows = tileSW.y - tileNE.y + 1;
  const numCols = tileNE.x - tileSW.x + 1;
  let tiles: { x: number; y: number; z: number }[] = [];

  for (let x = tileSW.x; x < tileSW.x + numCols; x++) {
    for (let y = tileNE.y; y < tileNE.y + numRows; y++) {
      tiles.push({ x, y, z: zoom });
    }
  }
  return tiles;
};

/**
 * Given { lat, lng } bounds, return bounds that are padded by the given ratio
 * A negative ratio reduces the size of the bounds (i.e. pass -0.10 to reduce bounds by 10%)
 */
export const padBoundsByPercentage = (
  { southWest, northEast }: BoundsObject,
  bufferRatio: number
): BoundsObject => {
  const latBuffer = Math.abs(southWest.lat - northEast.lat) * bufferRatio;
  const lngBuffer = Math.abs(southWest.lng - northEast.lng) * bufferRatio;

  return {
    southWest: {
      lng: southWest.lng - lngBuffer,
      lat: southWest.lat - latBuffer,
    },
    northEast: {
      lng: northEast.lng + lngBuffer,
      lat: northEast.lat + latBuffer,
    },
  };
};

/**
 * Given { lat, lng } bounds, return bounds that are padded by the given distances in
 * meters at each edge of the map
 * A positive meter amount increases the area of the bounds, which a negative amount
 * decreases the area
 */
export const padBoundsByMeters = (
  { southWest, northEast }: BoundsObject,
  {
    north,
    south,
    east,
    west,
  }: { north: number; south: number; east: number; west: number }
): BoundsObject => {
  const paddedSW = offsetLatLngPointByMeters(
    { lat: southWest.lat, lng: southWest.lng },
    [-west, -south]
  );
  const paddedNE = offsetLatLngPointByMeters(
    { lat: northEast.lat, lng: northEast.lng },
    [east, north]
  );
  return {
    southWest: { lng: paddedSW.lng, lat: paddedSW.lat },
    northEast: { lng: paddedNE.lng, lat: paddedNE.lat },
  };
};

/**
 * Convert degrees to radians
 */
export const degreesToRadians = (degrees: number): number => {
  const radians = degrees % 360;
  return (radians * Math.PI) / 180;
};

/**
 * Convert radians to degrees
 */
export const radiansToDegrees = (radians: number): number => {
  const degrees = radians % (2 * Math.PI);
  return (degrees * 180) / Math.PI;
};

/**
 * Given two point coordinates, finds the geographic bearing between them,
 * i.e. the angle measured in degrees from the north line (0 degrees)
 * Returns the bearing in decimal degrees, between -180 and 180 degrees (positive clockwise)
 */
export const getBearing = (start: LatLngObject, end: LatLngObject): number => {
  const lng1 = degreesToRadians(start.lng);
  const lng2 = degreesToRadians(end.lng);
  const lat1 = degreesToRadians(start.lat);
  const lat2 = degreesToRadians(end.lat);
  const a = Math.sin(lng2 - lng1) * Math.cos(lat2);
  const b =
    Math.cos(lat1) * Math.sin(lat2) -
    Math.sin(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1);

  return radiansToDegrees(Math.atan2(a, b));
};

/**
 * Given two point coordinates, finds the distance in meters between them
 * Returns distance in meters
 */
export const getDistance = (start: LatLngObject, end: LatLngObject): number => {
  const distLat = degreesToRadians(end.lat - start.lat);
  const distLng = degreesToRadians(end.lng - start.lng);
  const lat1 = degreesToRadians(start.lat);
  const lat2 = degreesToRadians(end.lat);

  const a =
    Math.pow(Math.sin(distLat / 2), 2) +
    Math.pow(Math.sin(distLng / 2), 2) * Math.cos(lat1) * Math.cos(lat2);

  return 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * EARTH_RADIUS;
};

/**
 * Given a set of lat,lng bounds, compute the center point
 */
export const getCenterPointFromBounds = ({
  southWest,
  northEast,
}: BoundsObject): LatLngObject => {
  return new mapboxgl.LngLatBounds(
    [southWest.lng, southWest.lat],
    [northEast.lng, northEast.lat]
  ).getCenter();
};

export const getZoomFromMapDimensionsAndFraction = (
  mapPx: number,
  worldPx: number,
  fraction: number
): number => {
  return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
};

export const getLatRad = (lat: number): number => {
  const sin = Math.sin((lat * Math.PI) / 180);
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
};

export const getZoomFromBounds = (
  { southWest, northEast }: BoundsObject,
  mapDim: { height: number; width: number }
): number => {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 20;
  const latFraction =
    (getLatRad(northEast.lat) - getLatRad(southWest.lat)) / Math.PI;
  const lngDiff = northEast.lng - southWest.lng;
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;
  const latZoom = getZoomFromMapDimensionsAndFraction(
    mapDim.height,
    WORLD_DIM.height,
    latFraction
  );
  const lngZoom = getZoomFromMapDimensionsAndFraction(
    mapDim.width,
    WORLD_DIM.width,
    lngFraction
  );

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
};

export const getCenterPointAndZoomLevelFromBounds = (
  { southWest, northEast }: BoundsObject,
  mapDim: { height: number; width: number }
): { center: LatLngObject; zoom: number } => {
  const { width, height } = mapDim;
  return {
    center: getCenterPointFromBounds({ southWest, northEast }),
    zoom: getZoomFromBounds({ southWest, northEast }, { height, width }),
  };
};

/**
 * Given a tile coordinate, return the lat,lng at the center of the tile
 */
export const getCenterPointOfTile = ({ x, y, z }: TileCoord): LatLngObject => {
  const southWest = tileCoordsToLatLng({ x, y: y + 1, z });
  const northEast = tileCoordsToLatLng({ x: x + 1, y, z });

  return getCenterPointFromBounds({ southWest, northEast });
};

/**
 * Given a single [lat,lng] center point, compute a set of [lat, lng] bounds extended by a certain number of meters
 */
export const getBoundsFromCenterPointAndRadius = (
  [lat, lng]: PositionArray,
  radius: number
): BoundsArray => {
  if (!lat || !lng) {
    throw new Error(
      `A valid [lat, lng] must be passed to getBoundsFromCenterPointAndRadius, you passed ${[
        lat,
        lng,
      ]}`
    );
  }
  const lngLat = new mapboxgl.LngLat(lng, lat);
  const lngLatBounds = lngLat.toBounds(radius).toArray();
  return [
    [lngLatBounds[0][1], lngLatBounds[0][0]],
    [lngLatBounds[1][1], lngLatBounds[1][0]],
  ];
};

/**
 * Given a single [lat,lng] center point, a zoom level, and the map canvas dimensions, compute a set of [lat, lng] bounds
 */
export const getBoundsFromCenterPointAndZoom = (
  [lat, lng]: PositionArray,
  zoom: number,
  { width, height }: { width: number; height: number } /* Canvas dimensions */
): BoundsArray => {
  const bounds = geoViewport.bounds(
    [lng, lat],
    zoom,
    [width, height],
    512 /* tile size */
  );
  return [
    [bounds[1], bounds[0]],
    [bounds[3], bounds[2]],
  ];
};

/**
 * Offset the given { lat, lng } point by certain number of meters in each direction
 */
export const offsetLatLngPointByMeters = (
  { lat, lng }: LatLngObject,
  [metersX, metersY]: number[]
): LatLngObject => {
  return {
    lat: lat + metersY / METERS_IN_ONE_LATITUDE_DEGREE,
    lng:
      lng +
      metersX /
        (METERS_IN_ONE_LATITUDE_DEGREE * Math.cos((lat * Math.PI) / 180)),
  };
};

/**
 * For a plain array of [lat, lng] points, return a Mapbox LngLatBounds object
 */
export const getMapboxGLBoundsForBounds = (
  latLngBounds: BoundsArray
): LngLatBounds => {
  const bounds = new mapboxgl.LngLatBounds([
    [latLngBounds[0][1], latLngBounds[0][0]],
    [latLngBounds[1][1], latLngBounds[1][0]],
  ]);
  return bounds;
};

/**
 * Filter the passed-in marker array (each marker must contain lat and lng properties)
 * by the given tile coordinates, returning only markers within the given tile
 */
export const filterMarkersByTile = (
  markers: MapMarker[],
  { x, y, z }: TileCoord
): MapMarker[] => {
  const northWestBound = tileCoordsToLatLng({ x, y, z });
  const southEastBound = tileCoordsToLatLng({ x: x + 1, y: y + 1, z });
  return markers.filter(
    (marker) =>
      marker &&
      marker.lat < northWestBound.lat &&
      marker.lat > southEastBound.lat &&
      marker.lng > northWestBound.lng &&
      marker.lng < southEastBound.lng
  );
};

export const computeMapboxGLBoundsFromLngLatPoints = (
  points: PositionArray[] /* Ensure points are in lng,lat format */,
  padBoundsByPercentageAmount: number
) => {
  const bounds = new mapboxgl.LngLatBounds();
  points.forEach((point) => {
    bounds.extend(point);
  });
  const paddedBounds = padBoundsByPercentage(
    {
      northEast: bounds.getNorthEast(),
      southWest: bounds.getSouthWest(),
    },
    padBoundsByPercentageAmount
  );
  return new mapboxgl.LngLatBounds([
    [paddedBounds.southWest.lng, paddedBounds.southWest.lat],
    [paddedBounds.northEast.lng, paddedBounds.northEast.lat],
  ]);
};

/**
 * Given a set of [ lat, lng ] points, return a bounds array that contain all points
 * Note: use the above `pointsToPaddedMapboxGLBounds` method to get a Mapbox GL bounds object
 */
export const computeBoundsArrayFromLatLngPoints = (
  points: PositionArray[] /* Ensure points are in lat,lng format */,
  padBoundsByPercentageAmount?: number
): BoundsArray => {
  const bounds = new mapboxgl.LngLatBounds();
  points.forEach((point) => {
    bounds.extend([point[1], point[0]]);
  });

  const boundsObject = {
    northEast: bounds.getNorthEast(),
    southWest: bounds.getSouthWest(),
  };
  const potentiallyPaddedBounds = padBoundsByPercentageAmount
    ? padBoundsByPercentage(boundsObject, padBoundsByPercentageAmount)
    : boundsObject;

  return [
    [
      potentiallyPaddedBounds.northEast.lat,
      potentiallyPaddedBounds.northEast.lng,
    ],
    [
      potentiallyPaddedBounds.southWest.lat,
      potentiallyPaddedBounds.southWest.lng,
    ],
  ];
};

const isPolygon = (geoJSON: Geometry | null): geoJSON is Polygon =>
  !!(geoJSON?.type === 'Polygon');
const isMultiPolygon = (geoJSON: Geometry | null): geoJSON is MultiPolygon =>
  !!(geoJSON?.type === 'MultiPolygon');
/**
 * Given a geoJSON object and a point, return whether the point is contained within any
 * of the geoJSON geometries
 */
export const getIsPointInsideGeoJSON = (
  geoJSON: Geometry | null /* geoJSON object, i.e. { type: 'Polygon', coordinates: [] } */,
  lngLatPoint: PositionArray
): boolean => {
  const isValidGeoJSON = isPolygon(geoJSON) || isMultiPolygon(geoJSON);
  if (!isValidGeoJSON) {
    throw new Error(
      `geoJSON object, ${geoJSON}, passed to getIsPointInsideGeoJSON must be of type MultiPolygon or Polygon`
    );
  }

  return booleanPointInPolygon(lngLatPoint, geoJSON as Polygon | MultiPolygon);
};

/**
 * Given bounds and a point, return whether the point lies within the bounds
 */
export const getIsPointInsideBounds = (
  latLngBounds: BoundsObject,
  lngLatPoint: PositionArray
): boolean => {
  const { southWest, northEast } = latLngBounds;
  const polygon = {
    type: 'Feature' as 'Feature',
    geometry: {
      type: 'Polygon' as 'Polygon',
      coordinates: [
        [
          [southWest.lng, southWest.lat],
          [northEast.lng, southWest.lat],
          [northEast.lng, northEast.lat],
          [southWest.lng, northEast.lat],
          [southWest.lng, southWest.lat],
        ],
      ],
    },
    properties: {},
  };
  return booleanPointInPolygon(lngLatPoint, polygon);
};
