import { ActionType, EventDefType, TrackersType, UtmParamsType } from './types';

// Used for jasmine spyOn
export const windowHelpers = {
  getQueryString: (): string => window.location.search,
};

export const checkActionHasAnalytics = (
  action: ActionType,
  trackers: TrackersType
): boolean => {
  // Ensures that the object has analytics metadata
  let hasAnalytics = false;
  if (action.meta) {
    // Check for individual tracker events if the 'analytics' key is not present
    for (let i = 0; i < trackers.length; i++) {
      if (action.meta[trackers[i].key]) {
        hasAnalytics = true;
        break;
      }
    }
  }
  return hasAnalytics;
};

export const getEventDef = (
  action: ActionType,
  trackerKey: string
): EventDefType => {
  if (action?.meta?.[trackerKey]) {
    return action.meta[trackerKey];
  }

  return { event: '' };
};

export const testEventDefValid = (eventDef: EventDefType): boolean =>
  !!(eventDef && eventDef.event) || false;

export const actionHasValidEventDef = (
  action: ActionType,
  trackerKey: string
): boolean => testEventDefValid(getEventDef(action, trackerKey));

// trackers: ({ fn?: (() => void | string); key?: (string | boolean) } | boolean)[]
export const removeInvalidTrackers = (trackers: TrackersType) =>
  trackers.filter(
    (tracker) =>
      typeof tracker === 'object' &&
      tracker.hasOwnProperty('fn') &&
      typeof tracker.fn === 'function' &&
      tracker.hasOwnProperty('key') &&
      typeof tracker.key === 'string'
  );

export const getQueryParams = (): {} => {
  let params = {};
  const queryString = windowHelpers.getQueryString();
  if (queryString) {
    queryString
      .slice(1)
      .split('&')
      .forEach((param) => {
        const kv = param.split('=');
        params[kv[0]] = kv[1];
      });
  }
  return params;
};

export const getUTMParamsVals = (utmParams: UtmParamsType = []) => {
  let utmParamsVals = {};
  if (utmParams && utmParams.length) {
    const queryParams = getQueryParams();
    utmParams.forEach((param) => {
      if (queryParams[param]) {
        utmParamsVals[param] = queryParams[param];
      }
    });
  }
  return utmParamsVals;
};
