import AdBlockDetectionContainer from '@client/containers/ad-block-detection.container';
import AppBannerContainer from '@client/containers/app-banner.container';
import Footer from '@client/containers/footer.container';
import HeaderContainer from '@client/containers/header.container';
import MobileBottomNavContainer from '@client/containers/mobile-bottom-nav.container';
import theme from '@client/css-modules/AppContainer.css';
import { View } from '@client/routes/constants';
import { HEADER_FOCUS_STATE_IDS } from '@client/store/constants';
import { getIsDisplayingEmailOptInModal } from '@client/store/selectors/email-opt-in.selectors';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { AuthStatusScreenReader } from '../AuthStatusScreenReader';
import { usePageState } from './pageLayoutWrapper.hooks';

const PageWrapper = ({ children }: { children?: React.ReactNode }) => {
  const mainWrapperRef = useRef<HTMLElement | null>(null);
  const currentView = useSelector(getCurrentView);

  const {
    isShowingBlankPage,
    isAppContainerFlexed,
    headerIsShown,
    appContainerHasMinHeight,
    hasGreyBackground,
    footerIsShown,
    disableAdBlockDetection,
    isAppBannerOpen,
    isAppContainerBlurred,
  } = usePageState();
  const isDisplayingEmailOptInModal = useSelector(
    getIsDisplayingEmailOptInModal
  );

  if (isShowingBlankPage) {
    return <>{children}</>;
  }

  const handleSetFocus = (e) => {
    e.preventDefault();
    const targetId =
      currentView === View.HOMEPAGE
        ? `#${HEADER_FOCUS_STATE_IDS.HOMEPAGE}`
        : '#main';
    document.location = targetId;
  };

  return (
    <div
      className={classNames(theme.RouterContainer, {
        [theme.RouterContainerBlurred]: isAppContainerBlurred,
        [theme.RouterContainerFlexed]: isAppContainerFlexed,
      })}
    >
      {/* DO NOT REMOVE: Empty span needed for focus to be reset to the top of the page on naviagtion. */}
      <span id="top" tabIndex={-1}></span>
      {!isDisplayingEmailOptInModal && <AuthStatusScreenReader />}
      {isAppBannerOpen && <AppBannerContainer />}
      <a
        className={theme.SkipToMainContent}
        href="#main"
        onClick={handleSetFocus}
      >
        Skip to main content
      </a>
      {headerIsShown && (
        <>
          <header
            className={theme.Header}
            data-hc-name={'header'}
            role="banner"
          >
            <HeaderContainer />
            {
              /**
               * Not showing Ad block detection on mobile screen sizes
               */
              !disableAdBlockDetection && <AdBlockDetectionContainer />
            }
          </header>
        </>
      )}
      <main
        role="main"
        id="main"
        ref={mainWrapperRef}
        className={classNames(theme.MainContainer, {
          [theme.MainContainerFlexed]: isAppContainerFlexed,
          /*
           * Set min-height on selected routes to prevent page flickering lazily-loaded assets
           */
          [theme.MainContainerWithMinHeight]: appContainerHasMinHeight,
        })}
        style={{
          backgroundColor: hasGreyBackground ? '#f4f4f4' : '#fff',
        }}
      >
        {children}
      </main>
      {footerIsShown && (
        <Footer
          theme={theme}
          style={{
            backgroundColor: hasGreyBackground ? '#f4f4f4' : '#fff',
          }}
        />
      )}
      <MobileBottomNavContainer theme={theme} />
    </div>
  );
};

export default PageWrapper;
