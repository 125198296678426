import Tooltip from '@client/components/generic/Tooltip';
import { Theme } from '@friendsofreactjs/react-css-themr';

interface Props {
  labelAndContent: {
    label: string;
    content: string;
  };
  labelColor: string;
  theme?: Theme;
  dataHcName: string;
  offset?: [number, number];
  containerClassName?: string;
  labelFontSize?: string;
  tooltipClassName?: string;
  position?: 'top' | 'bottom';
  hasTransparentScreen?: boolean;
  stayFocusedOnBlur?: boolean;
}

const DisclaimerTooltip = ({
  labelAndContent: { label, content } = { label: '', content: '' },
  labelColor,
  theme,
  dataHcName,
  offset,
  containerClassName = '',
  labelFontSize = '12px',
  tooltipClassName = '',
  position = 'bottom',
  hasTransparentScreen = false,
  stayFocusedOnBlur,
}: Props) =>
  content && label ? (
    <div className={containerClassName}>
      <Tooltip
        trigger={
          <small
            style={{
              color: labelColor,
              textDecorationColor: labelColor,
              fontSize: labelFontSize,
              textDecoration: 'underline',
              fontWeight: 400,
            }}
          >
            {label}
          </small>
        }
        content={
          <small
            style={{
              fontSize: labelFontSize,
              lineHeight: '16px',
              color: '#4a4a4a',
              fontWeight: 400,
            }}
            dangerouslySetInnerHTML={{ __html: content }}
          ></small>
        }
        theme={theme}
        dataHcName={dataHcName}
        offset={offset}
        className={tooltipClassName}
        position={position}
        hasTransparentScreen={hasTransparentScreen}
        stayFocusedOnBlur={stayFocusedOnBlur}
      />
    </div>
  ) : null;

export default DisclaimerTooltip;
