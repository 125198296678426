import {
  Theme,
  themr,
  WithOptionalTheme,
} from '@friendsofreactjs/react-css-themr';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { Component, HTMLProps, Ref } from 'react';
import { createPortal } from 'react-dom';
import { FocusOn } from 'react-focus-on';
import { connect } from 'react-redux';

import InfoIconCobranded from '@client/components/InfoIcon/InfoIconCobranded';
import { useAriaAnnouncer } from '@client/context/aria-announcer';
import defaultTheme from '@client/css-modules/Tooltip.css';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import { getModalTargetDOMId } from '@client/hocs/create-modal-portal';
import CloseIcon from '@client/inline-svgs/close';
import { View } from '@client/routes/constants';
import { ModalKeyToRenderInPortal } from '@client/store/constants';
import { getIsMobile } from '@client/store/selectors/match-media.selectors';
import { verifyModalPortalCreated } from '@client/store/selectors/modals.selectors';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import {
  disableBodyScroll,
  enableBodyScroll,
} from '@client/utils/body-scroll-lock';
import { key, stopEvent } from '@client/utils/component.utils';
import { elementIsDescendant } from '@client/utils/dom.utils';
import { throttle } from 'lodash';

const TOOLTIP_ANIMATION_VARIANTS = {
  animate: {
    opacity: 1,
    transition: { duration: 0.05 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2 },
  },
};

const TOOLTIP_SCREEN_ANIMATION_VARIANTS = {
  animate: {
    opacity: 1,
    transition: { duration: 0.2 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2 },
  },
};

type MarginFromEdge = { x: number; y: number };

type State = {
  isShowingPlaceholder: boolean;
  isShowingTooltip: boolean;
  shiftAmount: number;
  maxHeight: number | null;
  portalAnchorVerticalShift: number;
  portalDestination: null | HTMLElement;
  isScrolling: boolean;
};

type Props = {
  /* Used to reset tooltip state on route change, useful when tooltip is used in
   * components like header which are not re-rendered on route change */
  currentView: View | null;
  /* Text content or a component to be displayed within the tooltip */
  content: JSX.Element | string;
  /* Text content or a component that when hovered or clicked, will cause the tooltip
   * to display */
  trigger?: JSX.Element | string;
  /* To add the style to a tooltip icon on hover by using dangerouslySetInnerHTML */
  childrenToUseOnHover?: React.ReactNode;
  /* Whether a click or tap on the `trigger` causes the tooltip to display and
   * hide. When true, a "screen" displays behind the tooltip, causing a click outside
   * to close the tooltip */
  clickToTrigger: boolean;
  /* Whether the close button should be hidden when `clickToTrigger` is true (it's always
   * hidden otherwise) */
  shouldHideCloseButton?: boolean;
  shouldHideFromScreenReader?: boolean;
  /* Whether the tooltip displays above or below the trigger icon */
  position?: 'top' | 'bottom';
  /* An array of 2 numbers to be applied to a `translate` style on the tooltip wrapper.
   * Use with caution: if the tooltip is partially offscreen, it will be fit onscreen
   * before applying this offset */
  offset?: [number, number];
  /** Whether to display the tooltip immediately on init */
  showOnInit?: boolean;
  /* Whether to disable the user's ability to trigger the tooltip.  When combined
   * with `showOnInit` and `hideDelay`, the tooltip can be used as a one-time
   * tutorial item/helper text. */
  preventUserTriggering?: boolean;
  /* A duration to delay the display of the tooltip after it's triggered (via click,
   * hover, or component init) */
  showDelay?: number;
  /* The delay in milliseconds after it has displayed the after which the tooltip
   * will auto-hide itself */
  hideDelay?: number;
  /* An optional callback to be executed when the tooltip is hidden */
  afterHide?: () => void;
  /* The maximum width the tooltip can be. Works by setting negative `left` and `right` properties
   * on the tooltip's wrapper element. A max-width of 100vw is assigned to .TooltipContent in the CSS,
   * so this prop is only needed if seeking a smaller max-width than 100vw */
  maxWidth?: number;
  /* Whether the tooltip background screen is transparent */
  hasTransparentScreen?: boolean;
  /* Whether to scroll the tooltip into view if it appears completely or partially
   * above or below the fold.  Defaults to true. */
  shouldScrollIntoView?: boolean;
  /* Callback executed after tooltip display. Example use case: analytics event reporting */
  afterDisplay?: () => void;
  marginFromEdge?: MarginFromEdge;
  /* Class name */
  className?: string;
  /* The causes a touchscreen scroll to close the tooltip immediately and the scroll to
   * be effective */
  shouldCloseOnScreenTouchStart?: boolean;
  shouldCloseOnTooltipClick?: boolean;
  closeTooltip?: boolean;
  /* A number a pixel to shift the tooltip content away from the tooltip arrow.  If the
   * tooltip is partially offscreen after this is applied, the tooltip will be repositioned
   * so that it's entirely onscreen */
  initialShiftAmount?: number;
  /* When enabled, will add a max-height on load as well as resize to keep the Tooltip within screen.
   * This is useful for when the Tooltip is used in a scrollable container. */
  preventViewportHeightOverflow?: boolean;
  /* CSS themr props */
  theme: Theme;
  /* Callback fired whenever tooltip is toggled */
  handleToggleTooltip?: () => void;
  dataHcName: string;
  triggerAriaLabel?: string;
  triggerAriaDescribedBy?: string;
  contentAriaLabelledBy?: string;
  /* Id applied to the outer most div wrapping the Tooltip content. */
  contentWrapperId?: string;
  /* Callback fired to announce content in aria announcer context. */
  ariaAnnouncer?: (message: JSX.Element | string) => void;
  style?: React.CSSProperties;
  modalKey: ModalKeyToRenderInPortal | null;
  /* Used to simulate the rendering of the tooltip in a Jest environment */
  testPortalId?: string;
  scrollableParentClassname?: string;
  triggerTooltipWhenTrue?: boolean;
  /* In some cases (such as the search page filters) we may have inner components that need to be accessible with space and enter keys.
   * Enabling this prop causes the tooltip to only close if the user uses the escape key, instead of escape, enter, or space. */
  disableClosingTooltipWithEnterAndSpace?: boolean;
  stayFocusedOnBlur?: boolean;
  /* For event reporting */
  ['data-event-name']?: string;
  ['data-parent-event-name']?: string;
};

interface TriggerElementProps
  extends HTMLProps<HTMLDivElement | HTMLButtonElement> {
  ariaControls?: string;
  shouldHideFromScreenReader?: boolean;
  isShowingTooltip?: boolean;
  dataHcName?: string;
}

const mapStateToProps = (
  state,
  ownProps: Omit<
    WithOptionalTheme<Props>,
    'clickToTrigger' | 'currentView' | 'modalKey'
  > & { clickToTrigger?: boolean; modalKey?: ModalKeyToRenderInPortal }
) => {
  return {
    clickToTrigger: ownProps.clickToTrigger || getIsMobile(state),
    currentView: getCurrentView(state),
    /* Return the portal id only once the portal destination node has been mounted */
    modalKey: verifyModalPortalCreated(
      ownProps.modalKey ? ownProps.modalKey : 'tooltip'
    )(state),
  };
};

/** Internal component for setting tooltip attributes based on
 * if the trigger should be hidden from the screen reader or not.
 * In the case the tooltip trigger should be hidden from screen readers
 * create a div instead of a button and do not apply the aria-controls attribute.
 */
const TooltipTrigger = React.forwardRef(
  (
    {
      ariaControls,
      shouldHideFromScreenReader,
      isShowingTooltip,
      dataHcName,
      children,
      ...rest
    }: TriggerElementProps,
    ref
  ) => {
    return shouldHideFromScreenReader ? (
      <div {...rest} ref={ref as Ref<HTMLDivElement>}>
        {children}
      </div>
    ) : (
      <button
        {...rest}
        type="button"
        aria-controls={ariaControls}
        aria-expanded={isShowingTooltip}
        ref={ref as Ref<HTMLButtonElement>}
        data-hc-name={dataHcName}
      >
        {children}
      </button>
    );
  }
);

/**
 * Displays a trigger icon indicator that, when hovered or tapped, animates in a tooltip with supplied
 * content rendered in a high-level portal, centered under or above the indicator. The tooltip will
 * reposition itself if it's partially offscreen.
 *
 * Flow:
 * - After clicking or hovering the indicator, first display the tooltip in a placeholder inside of this
 *     component with visibility:hidden
 * - After this placeholder renders, look at the dimensions/position of the tooltip content.  If it's
 *     partially offscreen, set a shift amount to fit all of the content onscreen
 * - After calculating the shift amount, set the same tooltip content to render in a fixed-position portal,
 *      at the exact viewport location of the placeholder
 *
 * Gotchas:
 * - It's tricky to apply theme-based CSS overrides since the content (.TooltipContent) is rendered in a
 *     portal.  This means that nesting the .TooltipContent class under a parent theme's class won't work,
 *     due to .TooltipContent not living under that parent in the DOM.  If you need to override, don't nest
 *     the classnames in the parent theme.  You may need to use !important for the styles due to this.
 * - Tooltip rendering partly offscreen or not auto-scrolling onscreen correctly?  This is usually due
 *     to differences in .TooltipContent width before/after it's in the portal (see "flow" above). Make sure
 *     you're not overriding tooltip styles via nesting in the parent component theme and try hardcoding
 *     the width of the topmost element in the passed `content` prop.
 *
 * Need a tooltip that doesn't render above everything in a portal?  Use SimpleTooltip.tsx
 */
class TooltipComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isShowingPlaceholder: false,
      isShowingTooltip: false,
      shiftAmount: props.initialShiftAmount || 0,
      maxHeight: null,
      portalAnchorVerticalShift: 0,
      portalDestination: null,
      isScrolling: false,
    };
  }

  showDelayTimeout?: number;
  hideDelayTimeout?: number;
  tooltipContent: HTMLElement | null = null;
  tooltipContentScrollableContainer: HTMLElement | null = null;
  tooltipTriggerEle: React.RefObject<HTMLDivElement | HTMLButtonElement> =
    React.createRef();
  closeTooltipAfterMouseLeaveTimeout?: number;
  isMouseInsideContent: boolean = false;
  setBodyScrollLockTimeout?: number;

  componentDidMount() {
    const { showDelay, hideDelay, showOnInit, testPortalId, modalKey } =
      this.props;
    if (showOnInit) {
      this.showTooltip();
    }
    if (hideDelay) {
      const effectiveHideDelay = showDelay ? showDelay + hideDelay : hideDelay;
      this.hideDelayTimeout = window.setTimeout(
        this.hideTooltip,
        effectiveHideDelay
      );
    }
    const portalDestinationId =
      testPortalId || (modalKey && getModalTargetDOMId(modalKey));
    if (portalDestinationId) {
      const destinationEle = document.getElementById(portalDestinationId);
      this.setState({
        portalDestination: destinationEle,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentView !== this.props.currentView) {
      this.setState({
        isShowingTooltip: false,
        isShowingPlaceholder: false,
        shiftAmount: 0,
      });
    }

    if (prevState.isShowingTooltip !== this.state.isShowingTooltip) {
      this.props.handleToggleTooltip && this.props.handleToggleTooltip();
      if (!this.state.isShowingTooltip) {
        this.unlockBodyScrolling();
      }
    }

    if (
      prevProps.triggerTooltipWhenTrue === false &&
      this.props.triggerTooltipWhenTrue
    ) {
      this.showTooltip();
    }

    if (
      !prevProps.modalKey &&
      this.props.modalKey &&
      getModalTargetDOMId(this.props.modalKey)
    ) {
      const destinationEle = document.getElementById(
        getModalTargetDOMId(this.props.modalKey)
      );
      this.setState({
        portalDestination: destinationEle,
      });
    }

    if (
      prevProps.closeTooltip !== this.props.closeTooltip &&
      this.props.closeTooltip
    ) {
      this.hideTooltip();
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.hideDelayTimeout);
    window.clearTimeout(this.showDelayTimeout);
    window.clearTimeout(this.closeTooltipAfterMouseLeaveTimeout);
    window.clearTimeout(this.setBodyScrollLockTimeout);
    this.props.ariaAnnouncer && this.props.ariaAnnouncer('');
    this.unlockBodyScrolling();
  }

  bindWindowEvents = (): void => {
    const { preventViewportHeightOverflow } = this.props;
    if (preventViewportHeightOverflow) {
      window.addEventListener('resize', this.handleOnResize);
    }
    const { scrollableParent } = this.getScrollableParent();
    scrollableParent.addEventListener('scrollend', this.onScrollEnd);
  };

  unbindWindowEvents = (): void => {
    const { preventViewportHeightOverflow } = this.props;
    if (preventViewportHeightOverflow) {
      window.removeEventListener('resize', this.handleOnResize);
    }
    const { scrollableParent } = this.getScrollableParent();
    scrollableParent.removeEventListener('scrollend', this.onScrollEnd);
  };

  adjustHeight = (): void => {
    if (!this.tooltipContent) {
      return;
    }
    const { top: tooltipTop } = this.tooltipContent.getBoundingClientRect();
    const marginFromEdge = this.getMarginFromEdge();
    this.setState({
      maxHeight:
        window.innerHeight -
        tooltipTop -
        marginFromEdge.y -
        40 /* .TooltipContentWrapper padding */ -
        180 /* on resize, this applies spacing at bottom of screen so the tooltip doesn't go off screen */,
    });
  };

  handleOnResize = throttle(this.adjustHeight, 300);

  getScrollableParent = (): {
    scrollableParent: HTMLElement | typeof window;
    offsetTop: number;
  } => {
    const { scrollableParentClassname } = this.props;

    if (scrollableParentClassname) {
      const ele = document.querySelector(
        `.${scrollableParentClassname}`
      ) as HTMLElement;
      if (!ele) {
        console.error(
          `Classname .${scrollableParentClassname} cannot be found in the document, falling back to scrolling the window`
        );
      }
      return {
        scrollableParent: ele || window,
        offsetTop:
          (ele && ele.scrollTop) || window.scrollY || window.pageYOffset,
      };
    } else {
      return {
        scrollableParent: window,
        offsetTop: window.scrollY || window.pageYOffset,
      };
    }
  };

  onScrollEnd = () => {
    if (this.state.isScrolling) {
      this.setState({
        portalAnchorVerticalShift: 0,
        isScrolling: false,
      });
    }
  };

  lockBodyScrolling = () => {
    const { preventViewportHeightOverflow } = this.props;
    if (
      !preventViewportHeightOverflow &&
      this.tooltipContentScrollableContainer
    ) {
      /* Ensure this happens after the "scroll into view" animation completes */
      this.setBodyScrollLockTimeout = window.setTimeout(() => {
        if (this.tooltipContentScrollableContainer) {
          disableBodyScroll(this.tooltipContentScrollableContainer);
        }
      }, 200);
    }
  };

  unlockBodyScrolling = () => {
    const { preventViewportHeightOverflow } = this.props;
    if (
      !preventViewportHeightOverflow &&
      this.tooltipContentScrollableContainer
    ) {
      window.clearTimeout(this.setBodyScrollLockTimeout);
      enableBodyScroll(this.tooltipContentScrollableContainer);
    }
  };

  handleOnKeyDown = (e: React.KeyboardEvent): void => {
    const { disableClosingTooltipWithEnterAndSpace } = this.props;
    const { isShowingTooltip } = this.state;
    const pressedKey = e.key;

    if (
      !disableClosingTooltipWithEnterAndSpace &&
      (key.isSpace(pressedKey) || key.isReturn(pressedKey))
    ) {
      const curActiveEle = e.target as Element;
      const tooltipContent = this.tooltipContent;
      const eleisNotDescendant =
        curActiveEle &&
        tooltipContent &&
        !elementIsDescendant(curActiveEle!, tooltipContent);

      if (isShowingTooltip && curActiveEle && eleisNotDescendant) {
        stopEvent(e);
        this.hideTooltip();
      }
    } else if (key.isEscape(pressedKey)) {
      if (isShowingTooltip) {
        stopEvent(e);
        this.hideTooltip();
      }
    }
  };

  showTooltip = (): void => {
    /* Render the tooltip (visibility: hidden) to determine if it's entirely
     * within the viewport, repositioning if not */
    if (this.props.showDelay) {
      this.showDelayTimeout = window.setTimeout(() => {
        this.setState({ isShowingPlaceholder: true });
      }, this.props.showDelay);
    } else {
      this.setState({ isShowingPlaceholder: true });
    }

    this.bindWindowEvents();
  };

  toggleTooltip = (): void => {
    const { preventUserTriggering } = this.props;
    const { isShowingTooltip } = this.state;
    if (!preventUserTriggering && !isShowingTooltip) {
      this.showTooltip();
    } else if (isShowingTooltip) {
      this.hideTooltip();
    }
  };

  hideTooltip = (e?): void => {
    const { shouldCloseOnScreenTouchStart, initialShiftAmount } = this.props;
    /* In the case of `shouldCloseOnScreenTouchStart=true`, we want the event to propagate,
     * for example to allow the scrolling of a list to succeed */
    if (e && !shouldCloseOnScreenTouchStart) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.unlockBodyScrolling();
    this.setState({
      isShowingPlaceholder: false,
      isShowingTooltip: false,
      shiftAmount: initialShiftAmount || 0,
      portalAnchorVerticalShift: 0,
    });

    window.clearTimeout(this.showDelayTimeout);

    if (this.props.ariaAnnouncer) {
      this.props.ariaAnnouncer('');
    }

    if (this.props.afterHide) {
      this.props.afterHide();
    }

    this.unbindWindowEvents();
  };

  getMarginFromEdge = (): MarginFromEdge => {
    const { marginFromEdge } = this.props;

    return typeof marginFromEdge !== 'undefined'
      ? marginFromEdge
      : { x: 20, y: 20 };
  };

  getShouldScrollIntoView = (): boolean => {
    const { shouldScrollIntoView, preventViewportHeightOverflow } = this.props;

    return typeof shouldScrollIntoView !== 'undefined'
      ? shouldScrollIntoView
      : !preventViewportHeightOverflow;
  };

  /**
   * Shift the tooltip to be completely within the viewport if it's partially offscreen
   * By the time that we shift the tooltip it's already been fit to be narrow enough
   * to fit within the viewport.  Use the initial shift amount, if supplied, to help calculate
   * the final amount.
   */
  getShift = (TooltipContentEle: HTMLElement | null): number => {
    const marginFromEdge = this.getMarginFromEdge();
    const { initialShiftAmount } = this.props;
    const currentShiftAmount = initialShiftAmount || 0;

    if (TooltipContentEle === null) {
      return 0;
    }
    const { left, width } = TooltipContentEle.getBoundingClientRect();
    const right = left + width;
    if (left < 0) {
      return currentShiftAmount + Math.abs(left) + marginFromEdge.x;
    } else if (right > window.innerWidth - marginFromEdge.x) {
      return (
        currentShiftAmount - (right - window.innerWidth + marginFromEdge.x)
      );
    } else {
      return currentShiftAmount;
    }
  };

  /**
   * After tooltip placeholder is rendered, check and calculate new position if not entirely within viewport,
   * then set visible tooltip to display using new position
   */
  handleCalculatePositionAndShowTooltip = (ele): void => {
    const { preventViewportHeightOverflow, ariaAnnouncer } = this.props;
    let portalAnchorVerticalShift = 0;

    /* Keep track of the content element to reference a parent element when tabbing to decide
     * whether tooltip should close or not */
    if (ele) {
      this.tooltipContent = ele;
    }

    if (ele && this.tooltipContent) {
      const shouldScrollIntoView = this.getShouldScrollIntoView();
      const marginFromEdge = this.getMarginFromEdge();
      const { top: tooltipTop, height } =
        this.tooltipContent.getBoundingClientRect();
      // Round to nearest whole number.
      const tooltipBottom = Math.round(tooltipTop + height);
      const pageBottom = window.innerHeight - marginFromEdge.y;
      const pageTop = marginFromEdge.y;

      /* If too long for bottom of viewport */
      if (tooltipBottom > pageBottom) {
        if (preventViewportHeightOverflow) {
          this.setState({
            maxHeight:
              window.innerHeight -
              tooltipTop -
              marginFromEdge.y -
              40 /* .TooltipContentWrapper padding */,
          });
        } else if (shouldScrollIntoView) {
          const { scrollableParent, offsetTop } = this.getScrollableParent();
          this.setState({ isScrolling: true });
          scrollableParent.scroll({
            top: offsetTop + (tooltipBottom - pageBottom),
            behavior: 'smooth',
          });
          portalAnchorVerticalShift = tooltipBottom - pageBottom;
        }
        /* If too high for top of viewport (can occur with position=top) */
      } else if (tooltipTop < 0 && shouldScrollIntoView) {
        const { scrollableParent, offsetTop } = this.getScrollableParent();
        this.setState({ isScrolling: true });
        scrollableParent.scroll({
          top: offsetTop - Math.abs(tooltipTop) - pageTop,
          behavior: 'smooth',
        });
        portalAnchorVerticalShift = tooltipTop - pageTop;
      }
      const shiftAmount = this.getShift(this.tooltipContent);

      this.setState({
        isShowingTooltip: true,
        isShowingPlaceholder: false,
        shiftAmount,
        portalAnchorVerticalShift,
      });
      if (ariaAnnouncer && !this.props.shouldHideFromScreenReader) {
        ariaAnnouncer(this.props.content);
      }
    }
  };

  onTriggerMouseEnter = (e: React.MouseEvent): void => {
    window.clearTimeout(this.closeTooltipAfterMouseLeaveTimeout);
    this.showTooltip();
  };

  onTriggerMouseLeave = (e: React.MouseEvent): void => {
    this.closeTooltipAfterMouseLeaveTimeout = window.setTimeout(() => {
      if (!this.isMouseInsideContent) {
        this.hideTooltip();
      }
    }, 100);
  };

  onBlur = (e?) => {
    const { initialShiftAmount } = this.props;
    this.setState({
      isShowingPlaceholder: false,
      isShowingTooltip: false,
      shiftAmount: initialShiftAmount || 0,
      portalAnchorVerticalShift: 0,
    });
    if (this.props.ariaAnnouncer) {
      this.props.ariaAnnouncer('');
    }
  };

  onContentMouseEnter = (e: React.MouseEvent): void => {
    this.isMouseInsideContent = true;
  };

  onContentMouseLeave = (e: React.MouseEvent): void => {
    this.isMouseInsideContent = false;
    this.hideTooltip();
  };

  tooltipPortalContentWrapperRef = (ele: HTMLDivElement) => {
    const { afterDisplay } = this.props;
    if (ele) {
      /* This element to remain scrollable is a child of this element */
      this.tooltipContentScrollableContainer = ele.querySelector(
        `.${defaultTheme.TooltipContentScrollableContainer}`
      );
      this.lockBodyScrolling();

      if (afterDisplay) {
        afterDisplay();
      }
    } else {
      // content element is no longer present. Refocus back to trigger button.
      let focusEle = this.tooltipTriggerEle.current;
      if (focusEle && focusEle.tabIndex < 0) {
        focusEle = focusEle.querySelector(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
      }

      setTimeout(() => {
        // If focus has remained on the trigger, or focus has been lost and put onto the body
        // then put the focus back onto the trigger element.
        if (
          document.activeElement === focusEle ||
          document.activeElement === document.body
        ) {
          focusEle?.focus();
        }
      }, 0);
    }
  };

  render() {
    const {
      content,
      trigger,
      childrenToUseOnHover,
      clickToTrigger,
      theme,
      position,
      offset,
      className,
      hasTransparentScreen,
      shouldCloseOnScreenTouchStart,
      shouldCloseOnTooltipClick,
      shouldHideCloseButton,
      shouldHideFromScreenReader,
      triggerAriaLabel,
      triggerAriaDescribedBy,
      contentAriaLabelledBy,
      contentWrapperId,
      dataHcName,
      style,
      preventUserTriggering,
      maxWidth,
      initialShiftAmount,
      stayFocusedOnBlur,
    } = this.props;
    const {
      shiftAmount,
      isShowingPlaceholder,
      isShowingTooltip,
      maxHeight,
      portalAnchorVerticalShift,
      portalDestination,
    } = this.state;

    const effectivePosition = position || 'bottom';
    const effectiveMaxWidth = maxWidth || 400;
    const tooltipTriggerEleBoundingRect = this.tooltipTriggerEle?.current
      ? this.tooltipTriggerEle?.current?.getBoundingClientRect()
      : null;
    const contentWrapperTransform = offset
      ? `translate(${offset[0]}px, ${offset[1]}px)`
      : 'none';

    /* Broken out for reuse between the tooltip placeholder and visible portal tooltip */
    const TooltipContentWrapperProps = {
      className: classNames(theme.TooltipContentWrapper, {
        [theme.TooltipContentWrapperPositionBottom]:
          effectivePosition === 'bottom',
        [theme.TooltipContentWrapperPositionTop]: effectivePosition === 'top',
      }),
    };

    /* Broken out for reuse between the tooltip placeholder and visible portal tooltip */
    const TooltipContent = (
      <FocusOn
        returnFocus={false}
        onClickOutside={this.hideTooltip}
        /* If we ever want to turn on scroll lock behavior in this context,
        https://github.com/theKashey/react-focus-on/blob/master/src/types.ts#L73-L76
        we will need to remove the funky margin the scroll lock places on the body when in mobile view.
        https://github.com/theKashey/react-remove-scroll-bar/blob/master/src/component.tsx#L65
        */
        scrollLock={false}
      >
        <div
          className={classNames(theme.TooltipContent, {
            [theme.TooltipContentWithCloseIcon]:
              clickToTrigger && !shouldHideCloseButton,
          })}
          onClick={shouldCloseOnTooltipClick ? this.hideTooltip : () => null}
        >
          {clickToTrigger && !shouldHideCloseButton && (
            <button
              type="button"
              data-hc-name="close-icon"
              aria-label="Close Tooltip"
              className={theme.CloseIcon}
              onKeyDown={onEnterOrSpaceKey(this.hideTooltip)}
              onClick={this.hideTooltip}
            >
              <CloseIcon />
            </button>
          )}
          <div
            className={theme.TooltipContentScrollableContainer}
            data-hc-name={'tooltip-content'}
            style={
              maxHeight
                ? {
                    maxHeight: `${
                      maxHeight - 20
                    }px` /* -20px keeps text from extending beyond container, due to padding at top of container */,
                    overflowY: 'auto',
                  }
                : {}
            }
          >
            {content}
          </div>
          <div
            className={theme.TooltipArrow}
            style={{
              left: `calc(50% - 10px - ${shiftAmount}px)`,
            }}
          >
            <div aria-hidden="true" className={theme.ArrowText}>
              {effectivePosition === 'bottom' ? '▲' : '▼'}
            </div>
          </div>
        </div>
      </FocusOn>
    );

    return (
      <div
        data-hc-name={dataHcName}
        className={classNames(theme.Tooltip, {
          [className || '']: className,
        })}
        style={style}
        onKeyDown={this.handleOnKeyDown}
      >
        <AccessibleElementUniqueId>
          {({ uid }) => (
            <>
              {isShowingTooltip && childrenToUseOnHover && childrenToUseOnHover}
              <TooltipTrigger
                id={`trigger-${uid}`}
                shouldHideFromScreenReader={shouldHideFromScreenReader}
                dataHcName={`${dataHcName}-button`}
                ariaControls={
                  contentWrapperId ? contentWrapperId : `tooltip-${uid}`
                }
                className={theme.Trigger}
                isShowingTooltip={isShowingTooltip}
                aria-describedby={triggerAriaDescribedBy || undefined}
                onKeyDown={
                  !preventUserTriggering
                    ? onEnterOrSpaceKey(this.toggleTooltip)
                    : undefined
                }
                // If we have a triggerAriaLabel set that as the aria-label,
                // If a trigger element is present, then set the aria-label to undefined,
                // screen reader will read text inside the trigger.
                // If a trigger is not passed then we are using the InfoIconCobranded component
                // set the aria-label to "More Info"
                aria-label={
                  triggerAriaLabel
                    ? triggerAriaLabel
                    : trigger
                      ? undefined
                      : 'More Info'
                }
                onClick={this.toggleTooltip}
                onMouseLeave={
                  !clickToTrigger && !preventUserTriggering
                    ? this.onTriggerMouseLeave
                    : undefined
                }
                onMouseEnter={
                  !clickToTrigger && !preventUserTriggering
                    ? this.onTriggerMouseEnter
                    : undefined
                }
                onBlur={
                  !clickToTrigger &&
                  !preventUserTriggering &&
                  !stayFocusedOnBlur
                    ? this.onBlur
                    : undefined
                }
                ref={this.tooltipTriggerEle}
              >
                {trigger || <InfoIconCobranded className={theme.InfoIcon} />}
              </TooltipTrigger>

              {
                /* Placeholder tooltip render */
                isShowingPlaceholder && (
                  <div
                    style={
                      tooltipTriggerEleBoundingRect
                        ? {
                            transform: contentWrapperTransform,
                            /* Left and right values applied WITHOUT the final shift amount when rendering placeholder tooltip.
                             * Shift amount is determined using this placeholder's positioning and dimensions */
                            left: `${
                              -(effectiveMaxWidth / 2) +
                              (initialShiftAmount || 0) +
                              tooltipTriggerEleBoundingRect.width / 2
                            }px`,
                            right: `${
                              -(effectiveMaxWidth / 2) -
                              (initialShiftAmount || 0) +
                              tooltipTriggerEleBoundingRect.width / 2
                            }px`,
                          }
                        : {}
                    }
                    {...TooltipContentWrapperProps}
                  >
                    {React.cloneElement(TooltipContent, {
                      ref: this.handleCalculatePositionAndShowTooltip,
                    })}
                  </div>
                )
              }

              {
                /* Visible tooltip render */

                portalDestination &&
                  createPortal(
                    <div
                      role="tooltip"
                      id={contentWrapperId || `tooltip-${uid}`}
                      aria-labelledby={
                        contentAriaLabelledBy || `trigger-${uid}`
                      }
                    >
                      <AnimatePresence>
                        {
                          /* Main tooltip content and tooltip screen */
                          isShowingTooltip &&
                            !isShowingPlaceholder &&
                            tooltipTriggerEleBoundingRect && (
                              <div
                                data-testid="content-wrapper"
                                className={classNames(
                                  theme.TooltipPortalContentWrapper,
                                  {
                                    [theme.TooltipPortalContentWrapperWithTransparentScreen]:
                                      hasTransparentScreen,
                                  }
                                )}
                                style={{
                                  top:
                                    tooltipTriggerEleBoundingRect.top +
                                    (effectivePosition === 'bottom'
                                      ? tooltipTriggerEleBoundingRect.height
                                      : 0) -
                                    portalAnchorVerticalShift,
                                  left: tooltipTriggerEleBoundingRect.left,
                                  width: tooltipTriggerEleBoundingRect.width,
                                }}
                                ref={this.tooltipPortalContentWrapperRef}
                              >
                                <motion.div
                                  data-testid="tooltip-content"
                                  variants={TOOLTIP_ANIMATION_VARIANTS}
                                  exit="exit"
                                  initial="exit"
                                  animate="animate"
                                  key="tooltip"
                                  style={{
                                    transform: contentWrapperTransform,
                                    /* Left and right values applied WITH final shift amount when rendering visible tooltip */
                                    left: `${
                                      -(effectiveMaxWidth / 2) +
                                      shiftAmount +
                                      tooltipTriggerEleBoundingRect.width / 2
                                    }px`,
                                    right: `${
                                      -(effectiveMaxWidth / 2) -
                                      shiftAmount +
                                      tooltipTriggerEleBoundingRect.width / 2
                                    }px`,
                                    maxHeight: maxHeight
                                      ? `${maxHeight}px`
                                      : undefined,
                                  }}
                                  {...TooltipContentWrapperProps}
                                  onMouseEnter={
                                    !clickToTrigger
                                      ? this.onContentMouseEnter
                                      : undefined
                                  }
                                  onMouseLeave={
                                    !clickToTrigger
                                      ? this.onContentMouseLeave
                                      : undefined
                                  }
                                >
                                  {TooltipContent}
                                </motion.div>
                                <motion.div
                                  variants={TOOLTIP_SCREEN_ANIMATION_VARIANTS}
                                  exit="exit"
                                  initial="exit"
                                  animate="animate"
                                  key="screen"
                                  data-testid="screen"
                                  className={classNames(theme.TooltipScreen, {
                                    [theme.TooltipScreenAutoClose]:
                                      !clickToTrigger,
                                  })}
                                  onClick={
                                    !shouldCloseOnScreenTouchStart
                                      ? this.hideTooltip
                                      : undefined
                                  }
                                  onTouchStart={
                                    shouldCloseOnScreenTouchStart
                                      ? this.hideTooltip
                                      : undefined
                                  }
                                />
                              </div>
                            )
                        }
                      </AnimatePresence>
                    </div>,
                    portalDestination
                  )
              }
            </>
          )}
        </AccessibleElementUniqueId>
      </div>
    );
  }
}
/* Functional Component Wrapper so we can use the AriaAnnouncer hook */
const Tooltip = ({ ariaAnnouncer, ...props }: Props) => {
  const ariaAnnouncerDefault = useAriaAnnouncer();

  // In most cases, we will want to use the announce method from the AriaAnnouncerProvider.
  // However, in some cases, like dialogs for example, we may need to pass in a custom announce
  // method. handle case where a custom announcer has been passed into the Tooltip component.
  const handleAriaAnnouncer = (message: JSX.Element | string) => {
    if (ariaAnnouncer) {
      ariaAnnouncer(message);
    } else if (ariaAnnouncerDefault) {
      ariaAnnouncerDefault(message);
    }
  };
  return (
    <>
      <TooltipComponent ariaAnnouncer={handleAriaAnnouncer} {...props} />
    </>
  );
};

const ThemedTooltip = themr('ThemedTooltip', defaultTheme)(Tooltip);
export default connect(mapStateToProps, {})(ThemedTooltip);
