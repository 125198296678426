import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { pull } from 'lodash';
import React, { Component } from 'react';

import defaultTheme from '@client/css-modules/MobileCheckboxPicker.css';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import { FilterKey } from '@client/store/filter-constants';
import { SpecialUserType } from '@client/store/types/locked-components';
import Checkbox from './Checkbox';

export type MobileCheckboxPickerProps = {
  dataHcName?: string;
  values: (string | number)[];
  labelFormatter: (val: string | number) => string;
  setValueForStateFormatter: (newSelected: (string | number)[]) => string[];
  onChange: (values: (string | number)[]) => void;
  filterKey: FilterKey;
  getValueForControlFormatter: (
    currentValues: (string | number)[]
  ) => (string | number)[];
  handleReportValueSelection: (value: string | number) => void;
  currentValues: (string | number)[];
  ariaLabelledBy: string;
  description: string;
  theme: Theme;
};

type State = {
  selected: (string | number)[];
};

class MobileCheckboxPicker extends Component<MobileCheckboxPickerProps, State> {
  values: (string | number)[] = [];
  state: State = {
    selected: [],
  };

  constructor(props: MobileCheckboxPickerProps) {
    super(props);
    this.values = props.values;
  }

  componentDidMount() {
    const { currentValues, getValueForControlFormatter } = this.props;
    this.setState({ selected: getValueForControlFormatter(currentValues) });
  }

  updateValues = (value: string | number | null): void => {
    const { onChange, setValueForStateFormatter, handleReportValueSelection } =
      this.props;
    const { selected } = this.state;
    const isSelecting = !value ? false : !selected.includes(value);

    let newSelected = selected.slice();

    if (value) {
      if (isSelecting) {
        newSelected.push(value);
        handleReportValueSelection(value);
      } else {
        pull(newSelected, value);
      }

      // set to 'All' if all are selected:
      if (newSelected.length === this.values.length) {
        newSelected = [];
      }

      this.setState({
        selected: newSelected,
      });

      onChange(setValueForStateFormatter(newSelected));
    } else {
      this.setState({
        selected: [],
      });

      onChange(setValueForStateFormatter([]));
    }
  };

  handleOnClick = (
    e: React.MouseEvent,
    value: string | number | null
  ): void => {
    e.stopPropagation();
    this.updateValues(value);
  };

  render() {
    const { selected } = this.state;
    const { theme, description, labelFormatter, dataHcName, ariaLabelledBy } =
      this.props;

    return (
      <AccessibleElementUniqueId>
        {({ uid }) => (
          <fieldset
            className={theme.CheckboxPicker}
            data-hc-name={dataHcName ? dataHcName : 'checkbox-picker'}
          >
            {description && (
              <legend
                className={theme.CheckboxPickerGroupLabelOffscreen}
                id={uid}
              >
                {description}
              </legend>
            )}
            <div
              className={theme.CheckboxPickerGroup}
              role="group"
              aria-labelledby={ariaLabelledBy || uid}
            >
              <Checkbox
                key={'All'}
                theme={theme}
                lockedComponent={{
                  sectionId: `${this.props.filterKey}-ALL`,
                  lockedFor: [SpecialUserType.Restricted],
                }}
                label={'All'}
                onClick={(e) => this.handleOnClick(e, null)}
                isChecked={!selected.length}
              />
              {this.values.map((val) => (
                <Checkbox
                  key={val}
                  theme={theme}
                  lockedComponent={{
                    sectionId: `${this.props.filterKey}-${val}`,
                    lockedFor: [SpecialUserType.Restricted],
                  }}
                  label={labelFormatter(val)}
                  onClick={(e) => this.handleOnClick(e, val)}
                  isChecked={selected.includes(val)}
                />
              ))}
            </div>
          </fieldset>
        )}
      </AccessibleElementUniqueId>
    );
  }
}

export default themr(
  'MobileCheckboxPicker',
  defaultTheme
)(MobileCheckboxPicker);
