import { get } from 'lodash';
import windowOrGlobal from 'window-or-global';

export default function isLocal() {
  // don't bother inspecting the hostname/port if we know we're running in dev mode
  if (process.env.NODE_ENV === 'development') {
    return true;
  }
  const port = get(windowOrGlobal, ['location', 'port'], false);
  return !!port;
}
