import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { Component } from 'react';

import CobrandedStyles from '@client/components/CobrandedStyles';
import defaultTheme from '@client/css-modules/SmallModal.css';
import iOSModalScrollingFix from '@client/hocs/ios-modal-scrolling-fix';
import CloseIcon from '@client/inline-svgs/close';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { key, stopEvent } from '@client/utils/component.utils';
import { FocusOn } from 'react-focus-on';

const DEFAULT_SHOW_HIDE_DURATION = 0.3;

type Props = {
  dataHcName?: string;
  isActive: boolean;
  handleClose: (e: React.MouseEvent | React.KeyboardEvent) => void;
  className?: string;
  showHideDuration?: number;
  handleReturnFocus?: () => void;
  theme: Theme;
  scrollToTopOnMount?: boolean;
  isHidingCloseIcon?: boolean;
  displayNormalCloseIcon?: boolean;
  modalAriaLabel?: string;
  anchorToBottom?: boolean;
  style?: React.CSSProperties;
  customCloseIcon?: JSX.Element;
  children?: React.ReactNode;
};

/**
 * A small, centered dialog with a screen.  Differs from SlideInModal in that it's not fullscreen on mobile.
 * The dialog's body becomes scrollable if the content exceeds the height of the screen.
 */
class SmallModal extends Component<Props> {
  ScrollableDialogBody = iOSModalScrollingFix('div');
  componentDidUpdate(prevProps) {
    /* If provided, execute parent component's manual focusing of triggering element.  Otherwise,
     * FocusOn returnFocus will handle returning the focus.  This doesn't work in some cases on iOS
     * with VoiceOver enabled */
    if (
      !this.props.isActive &&
      prevProps.isActive &&
      this.props.handleReturnFocus
    ) {
      this.props.handleReturnFocus();
    }
  }

  handleOnKeyDown = (e): void => {
    if (key.isEscape(e.key)) {
      stopEvent(e);
      this.props.handleClose(e);
    }
  };

  handleCloseClick = (e): void => {
    stopEvent(e);
    this.props.handleClose(e);
  };

  scrollToTop = (ele): void => {
    if (this.props.scrollToTopOnMount && ele) {
      ele.scrollTop = 0;
    }
  };

  render() {
    const {
      dataHcName,
      isActive,
      children,
      className,
      theme,
      showHideDuration,
      handleReturnFocus,
      isHidingCloseIcon,
      displayNormalCloseIcon,
      modalAriaLabel,
      anchorToBottom,
      style,
      customCloseIcon,
    } = this.props;
    const ScrollableDialogBody = this.ScrollableDialogBody;
    const ANIMATION_VARIANTS = {
      hidden: {
        opacity: 0,
        transition: {
          duration: showHideDuration || DEFAULT_SHOW_HIDE_DURATION,
        },
      },
      show: {
        opacity: 1,
        transition: {
          duration: showHideDuration || DEFAULT_SHOW_HIDE_DURATION,
        },
      },
    };

    return (
      <CobrandedStyles>
        {({ modalBorderRadius }) => (
          <div data-hc-name={dataHcName}>
            <AnimatePresence>
              {isActive && (
                <motion.div
                  variants={ANIMATION_VARIANTS}
                  initial={'hidden'}
                  animate={'show'}
                  exit={'hidden'}
                  key="screen"
                  className={classNames(theme.Screen, {
                    [theme.DarkScreen]: anchorToBottom,
                  })}
                />
              )}
              {isActive && (
                <motion.div
                  variants={ANIMATION_VARIANTS}
                  initial={'hidden'}
                  animate={'show'}
                  exit={'hidden'}
                  className={classNames(theme.SmallModal, {
                    [theme.BottomAnchored]: anchorToBottom,
                    [theme.NoTopPadding]: customCloseIcon,
                    [className || '']: className,
                  })}
                  onKeyDown={this.handleOnKeyDown}
                  role="dialog"
                  aria-modal="true"
                  aria-label={modalAriaLabel}
                >
                  <ScrollableDialogBody
                    shouldAlwaysPreventBodyScrolling
                    className={theme.Modal}
                    style={{
                      borderRadius: anchorToBottom ? 0 : modalBorderRadius,
                      ...style,
                    }}
                    forwardedRef={this.scrollToTop}
                  >
                    <FocusOn
                      returnFocus={!handleReturnFocus}
                      onClickOutside={this.handleCloseClick}
                      scrollLock={false}
                    >
                      {anchorToBottom &&
                        (customCloseIcon ? (
                          customCloseIcon
                        ) : displayNormalCloseIcon ? (
                          <button
                            aria-label="Close modal"
                            className={theme.CloseIcon}
                            data-hc-name="close-dialog-button"
                            onClick={this.handleCloseClick}
                            onKeyDown={onEnterOrSpaceKey(this.handleCloseClick)}
                          >
                            <CloseIcon />
                          </button>
                        ) : (
                          <button
                            aria-label="Close modal"
                            data-hc-name="close-dialog-button"
                            className={theme.FloatingCloseIcon}
                            onClick={this.handleCloseClick}
                          >
                            <CloseIcon />
                          </button>
                        ))}
                      {!isHidingCloseIcon &&
                        !anchorToBottom &&
                        (customCloseIcon ? (
                          customCloseIcon
                        ) : (
                          <button
                            aria-label="Close modal"
                            className={theme.CloseIcon}
                            data-hc-name="close-dialog-button"
                            onClick={this.handleCloseClick}
                            onKeyDown={onEnterOrSpaceKey(this.handleCloseClick)}
                          >
                            <CloseIcon />
                          </button>
                        ))}
                      {children}
                    </FocusOn>
                  </ScrollableDialogBody>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
      </CobrandedStyles>
    );
  }
}

const ThemedSmallModal = themr('SmallModal', defaultTheme)(SmallModal);
export default ThemedSmallModal;
