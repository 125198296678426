import { put, call, select } from 'redux-saga/effects';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
import {
  reportClickBuyerLOModalCTASuccess,
  reportEvent,
  reportClickOwnerLOModalCTASuccess,
  getClickBuyerLOModalContactInfo,
  getClickOwnerLOModalContactInfo,
} from '@client/store/actions/analytics.actions';
import {
  CONTACT_LOAN_OFFICER,
  ContactLoanOfficerAction,
  contactLoanOfficerError,
  contactLoanOfficerSuccess,
} from '@client/store/actions/loan-officer.actions';
import { HOMEOWNER_ROUTES, HOME_BUYER_ROUTES } from '@client/routes/constants';
import {
  getCurrentView,
  getCurrentRoute,
} from '@src/redux-saga-router-plus/selectors';
import { getUserId } from '@client/store/selectors/auth.selectors';
import { getABTestVariants } from '@client/store/selectors/ab-tests.selectors';
import { getGenericLOInfo } from '@client/store/selectors/cobranding.selectors';
import { getHOAnalyticsEventAddress } from '@client/store/selectors/homeowner.selectors';
import { getPropertyDetailsNormalized } from '@client/store/selectors/property-details.selectors';
import {
  getLoanOfficerInfo,
  getLoanOfficerId,
} from '@client/store/selectors/loan-officer.selectors';
import { watchEvery } from '@client/utils/saga.utils';
import { consumerApiClient } from '@client/services/consumer-api-client';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { LoanOfficerContact } from '@client/store/types/loan-officer';
import { AnalyticsEventAddress } from '@client/store/types/analytics';

/**
 * Saga used to contact LO, email will be sent to LO
 * @param action: { payload: formValues: { email: string, message: string, phone: string } } }
 */
export function* contactLoanOfficerSaga(action: ContactLoanOfficerAction) {
  const loInfo = (yield select(getLoanOfficerInfo)) as ReturnType<
    typeof getLoanOfficerInfo
  >;
  const genericLOInfo = (yield select(getGenericLOInfo)) as ReturnType<
    typeof getGenericLOInfo
  >;
  const currentView = (yield select(getCurrentView)) as ReturnType<
    typeof getCurrentView
  >;
  const route = (yield select(getCurrentRoute)) as ReturnType<
    typeof getCurrentRoute
  >;
  let query = route.query;
  let browsing_path = route.path;
  let officer_email_address;
  let officer_first_name;
  let referral_source;
  let analyticsDataObj;
  let slug;
  let form_type;
  let isHomebuyerView;
  let isHomeownerView;

  if (!isEmpty(query)) {
    delete query.modal;
    browsing_path =
      browsing_path?.concat(`?${queryString.stringify(query)}`) || null;
  }

  if (loInfo) {
    officer_email_address = loInfo.email;
    officer_first_name = loInfo.firstName || loInfo.fullName;
    referral_source = loInfo.company;
  } else {
    /**
     * genericLOInfo will be defined here, we show ContactLoanOfficerModal
     * only when either loInfo or genericLOInfo are defined
     */
    officer_email_address = genericLOInfo.email;
    officer_first_name = genericLOInfo.name;
    referral_source = genericLOInfo.name;
  }

  const {
    firstName: customer_first_name,
    lastName: customer_last_name,
    email: customer_email_address,
    message: customer_message,
    phone: customer_phone_number,
  } = action.payload.formValues;

  const getFormType = (view) => {
    isHomeownerView = HOMEOWNER_ROUTES.includes(view);
    isHomebuyerView = HOME_BUYER_ROUTES.includes(view);
    if (isHomeownerView) {
      return 'owner';
    } else if (isHomebuyerView) {
      return 'buyer';
    }
    return null;
  };

  /**
   * On contact loan officer, we can get a slug from the current slug
   * e.g. https://lendco.qa.ch.housecanary.net/property-details/301-Mission-St-Apt-5C-San-Francisco-CA-94105?modal=contact-loan-officer
   */
  slug = route.params.slug;
  form_type = getFormType(currentView);

  if (isHomeownerView) {
    analyticsDataObj = getClickOwnerLOModalContactInfo();
  }
  if (isHomebuyerView) {
    analyticsDataObj = getClickBuyerLOModalContactInfo();
  }

  const analyticsData = analyticsDataObj.meta.analytics;
  const { event: event_name, parentEvent: event_type } = analyticsData;
  const abTestVariants = yield select(getABTestVariants);
  const event_data = {
    experiments: {
      ...abTestVariants,
    },
  };
  const http_path = route.path;
  const http_url = window.location.href;
  const http_host = window.location.hostname;
  const user_id = yield select(getUserId);
  const email = customer_email_address;
  const submit_event = {
    event_name,
    event_type,
    event_data,
    http_path,
    user_id,
    email,
    http_url,
    http_host,
  };
  const lo_id = (yield select(getLoanOfficerId)) as string | null;

  /**
   * Expected payload format for contact loan officer API
   */
  const contactLOData = {
    customer_first_name,
    customer_last_name,
    customer_email_address,
    customer_message,
    customer_phone_number,
    officer_email_address,
    officer_first_name,
    referral_source,
    browsing_path,
    lo_id,
    form_name: 'Contact loan officer',
    form_type,
    slug,
    submit_event,
  };

  try {
    yield call(
      [consumerApiClient, consumerApiClient.contactLoanOfficer],
      contactLOData
    );
    yield put(contactLoanOfficerSuccess());

    const loContactInfo: LoanOfficerContact = {
      visitor_email: customer_email_address,
      visitor_first_name: customer_first_name,
      visitor_last_name: customer_last_name,
      visitor_phone: customer_phone_number,
      visitor_comments: customer_message,
    };

    /**
     * There is no guarantee that the LO is being contact from
     * a property details page
     */

    if (isHomeownerView) {
      const homeownerAddress: AnalyticsEventAddress | null = (yield select(
        getHOAnalyticsEventAddress
      )) as ReturnType<typeof getHOAnalyticsEventAddress>;
      yield put(
        reportClickOwnerLOModalCTASuccess(
          loContactInfo,
          homeownerAddress,
          lo_id as string
        )
      );
    }

    const homeBuyerPDPPropertyDetails = (yield select(
      getPropertyDetailsNormalized
    )) as ReturnType<typeof getPropertyDetailsNormalized>;
    if (homeBuyerPDPPropertyDetails) {
      // PDP-specific analytics
      const propertyAddress: AnalyticsEventAddress = {
        slug: homeBuyerPDPPropertyDetails.slug,
        street: homeBuyerPDPPropertyDetails.streetAddress,
        city: homeBuyerPDPPropertyDetails.city,
        state: homeBuyerPDPPropertyDetails.state,
        zip: homeBuyerPDPPropertyDetails.zipcode,
        unit: homeBuyerPDPPropertyDetails.unit,
        address_id: homeBuyerPDPPropertyDetails.hcAddressId,
      };
      yield put(
        reportClickBuyerLOModalCTASuccess(
          loContactInfo,
          propertyAddress,
          lo_id as string
        )
      );
    } else if (isHomebuyerView) {
      // SRP (etc) analytics
      yield put(
        reportClickBuyerLOModalCTASuccess(loContactInfo, null, lo_id as string)
      );
    }
  } catch (e: any) {
    yield put(
      contactLoanOfficerError(
        'Sorry, there was an error sending email, please try again.'
      )
    );
    if (isHomeownerView) {
      yield put(
        reportEvent(
          'click_owner_lo_modal_cta_error',
          PARENT_EVENTS.CLICK_LO_CONTACT
        )
      );
    } else if (isHomebuyerView) {
      yield put(
        reportEvent(
          'click_buyer_lo_modal_cta_error',
          PARENT_EVENTS.CLICK_LO_CONTACT
        )
      );
    }
    /* Rethrow for Sentry reporting */
    throw e;
  }
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [CONTACT_LOAN_OFFICER]: contactLoanOfficerSaga,
  });
};
