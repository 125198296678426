import React from 'react';
import theme from '@client/css-modules/ScaffoldingMarketingCard.css';

import ScaffoldingUI, {
  ElementProps,
} from '@client/components/ScaffoldingUI/ScaffoldingUI';

type Props = {
  elementInstruction: ElementProps;
};

const ScaffoldingMarketingCard: React.FC<Props> = ({ elementInstruction }) => {
  return (
    <div className={theme.ScaffoldingMarketingWrapper}>
      <div className={theme.ScaffoldingMarketingInnerWrapper}>
        <ScaffoldingUI elementInstruction={elementInstruction} />
      </div>
    </div>
  );
};

export default ScaffoldingMarketingCard;
