import styled from 'styled-components';

const StyledWrapper = styled.div``;

const StyledToggle = styled.button`
  padding: 0;
  margin: 22px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #4a4a4a;
  background: #fff;
  &[aria-checked='true'] :first-child,
  &[aria-checked='false'] :last-child {
    background: #4a4a4a;
    color: #fff;
  }
  &[aria-checked='false'] :first-child,
  &[aria-checked='true'] :last-child {
    color: #4a4a4a;
  }
  cursor: pointer;
  height: 40px;
  border-radius: 4px;
`;

const StyledToggleOption = styled.span`
  pointer-events: none;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
`;

type Props = {
  ariaLabelledBy: string;
  isToggleOn: boolean;
  height?: number;
  onChange: (value: boolean) => void;
};

const GrantProgramMobileToggle = ({
  ariaLabelledBy,
  isToggleOn,
  onChange,
}: Props) => {
  return (
    <StyledWrapper>
      <StyledToggle
        data-hc-name="grant-program-filter"
        role="switch"
        aria-checked={isToggleOn}
        aria-labelledby={ariaLabelledBy}
        onClick={() => {
          onChange(!isToggleOn);
        }}
      >
        <StyledToggleOption>On</StyledToggleOption>
        <StyledToggleOption>Off</StyledToggleOption>
      </StyledToggle>
    </StyledWrapper>
  );
};

export default GrantProgramMobileToggle;
