import GrantProgramCTA from '@client/components/GrantProgram/GrantProgramCTA';
import GrantProgramCTAChase from '@client/components/GrantProgram/GrantProgramCTAChase';
import {
  eventType,
  ParentEventType,
} from '@client/store/actions/analytics.actions';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import { useSelector } from 'react-redux';

export interface Props {
  buttonText?: string;
  buttonUrl: string;
  useFullWidthCTA?: boolean;
  isNarrowLayout: boolean;
  isPdpPage: boolean;
  dataEventName: eventType;
  dataEventParentName: ParentEventType;
  nativeAppNavKey?: string;
}

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return GrantProgramCTAChase;
    default:
      return GrantProgramCTA;
  }
};

const GrantProgramCTACobranded: React.FC<Props> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default GrantProgramCTACobranded;
