import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import MobileFilters from '@client/components/MobileFilters';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { clearCurrentSavedSearch } from '@client/store/actions/saved-search.actions';
import {
  searchCancelPendingQueries,
  searchClearSelectedFilterInitialValue,
  searchFetchPropertyList,
  searchUpdateFilter,
} from '@client/store/actions/search.actions';
import { STATUSES } from '@client/store/constants';
import { getUserBuyingPower } from '@client/store/selectors/auth.selectors';
import {
  getSaveSearchButtonConfig,
  getSearchFiltersOrder,
} from '@client/store/selectors/cobranding.selectors';
import {
  getIsFeatureEnabled,
  getSearchListMaxResultCount,
} from '@client/store/selectors/enabled-features.selectors';
import { getIsMobile } from '@client/store/selectors/match-media.selectors';
import {
  getHasSavedCurrentSearch,
  getSavedSearchSavingStatus,
} from '@client/store/selectors/saved-search.selectors';
import {
  getActiveFilters,
  getSearchPropertyTotalCount,
  getSelectedFilterInitialValue,
} from '@client/store/selectors/search.selectors';
import {
  selectIsSRPGrantFilterOn,
  toggleSRPGrantFilter,
} from '@client/store/slices/grant-program.slice';

const mapStateToProps = (state) => {
  const initialFilterValue = getSelectedFilterInitialValue(state);
  const isGrantToggleOn = selectIsSRPGrantFilterOn(state);
  const { key } = initialFilterValue;
  return {
    needsMobileHeightAdjustment: getIsMobile(state),
    activeFilterValuesByKey: getActiveFilters(state),
    propertyCount: getSearchPropertyTotalCount(state),
    hasSavedCurrentFilter: getHasSavedCurrentSearch(state) as boolean,
    isSavingCurrentFilter:
      getSavedSearchSavingStatus(state) === STATUSES.UPDATING,
    initialFilterValue,
    isEditingAFilter: !!key,
    userBuyingPower: getUserBuyingPower(state),
    maxResultCount: getSearchListMaxResultCount(state),
    isDisplayMultiFamilySearchFiltersEnabled: getIsFeatureEnabled(
      'temp_display_multi_family_search_filters'
    )(state),
    isShowingGrantToggle:
      getIsFeatureEnabled('grant_program')(state) && isGrantToggleOn,
    isGrantToggleEnabled: isGrantToggleOn,
    toggleSRPGrantFilter: toggleSRPGrantFilter,
    saveSearchButtonConfig: getSaveSearchButtonConfig(state),
    filterSortOrder: getSearchFiltersOrder(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  reportQuickFilterMobileBackBtnClick: () =>
    dispatch(reportEvent('click_mobile_filter_back')),
  reportQuickFilterMobileShowResultsClick: () => {
    dispatch(reportEvent('click_mobile_filter_show_results'));
  },
  reportQuickFilterMobileAddFilterClick: () =>
    dispatch(reportEvent('click_mobile_filter_add_filter')),
  handleFilterValueChange: (key, value) => {
    dispatch(searchCancelPendingQueries());
    dispatch(searchUpdateFilter(key, value));
    dispatch(clearCurrentSavedSearch());
  },
  handleFetchPropertyListAndCount: () => dispatch(searchFetchPropertyList({})),
  clearInitialFilterValue: () =>
    dispatch(searchClearSelectedFilterInitialValue()),
  toggleSRPGrantFilter: () => dispatch(toggleSRPGrantFilter()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileFilters);
