import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PillButton from '@client/components/generic/PillButton';
import theme from '@client/css-modules/AdCardForCobrand.css';
import { useOutboundCtaUrl } from '@client/hooks/outbound-cta-url.hooks';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { getCustomizationData } from '@client/store/selectors/cobranding.selectors';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';

const AdCardForCobrand: React.FC = () => {
  const dispatch = useDispatch();
  const { search_ad_card_content } = useSelector(getCustomizationData);
  const introText = search_ad_card_content?.intro_text;
  const headlineText = search_ad_card_content?.headline_text;
  const CTAText = search_ad_card_content?.cta_text;
  const CTALink = search_ad_card_content?.cta_link;
  const outboundCtaUrl = useOutboundCtaUrl(CTALink || null);

  const handleAdCardCTAClick = (ctaLink: string) => {
    dispatch(reportEvent('click_ad_cta'));
    outboundCtaUrl && window.open(outboundCtaUrl, '_blank');
  };

  /* For safety, it'll never be mistakenly rendered for a cobrand, even if the logic in search.selectors is altered */
  if (!search_ad_card_content) {
    return null;
  }

  return (
    <div className={theme.AdCardForCobrand}>
      <div className={theme.IntroText}>{introText}</div>
      <div className={classNames(theme.HeadlineText)}>{headlineText}</div>
      {CTALink && CTAText && (
        <div className={theme.ButtonContainer}>
          <PillButton
            theme={theme}
            className={theme.Button}
            onKeyDown={onEnterOrSpaceKey(
              () => CTALink && handleAdCardCTAClick(CTALink)
            )}
            ariaLabel={`Open the ${CTAText} link`}
            onClick={() => CTALink && handleAdCardCTAClick(CTALink)}
          >
            {CTAText}
          </PillButton>
        </div>
      )}
    </div>
  );
};

export default AdCardForCobrand;
