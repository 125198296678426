import {
  CRIME_TYPES,
  FORECAST_CHART_TYPES,
  NEIGHBORHOOD_INSIGHT_TYPES,
  SCHOOL_TYPES,
} from '@client/store/constants';
import { Avm, AvmFactor } from '@client/store/sagas/queries/types';
import { AvmFactorType } from '@client/store/types/avm-break-down';
import {
  FullPropertyLookupWithAddressRequired,
  InitPropertyLookupWithAddressRequired,
  MortgageCalculationDetails,
  MortgageCalculationsForUIComponents,
  MortgageSummaryCallParams,
  MortgageTerm,
} from '@client/store/types/property';

export const CHANGE_FORECAST_CHART_TYPE = 'CHANGE_FORECAST_CHART_TYPE';
export const CHANGE_NEIGHBORHOOD_INSIGHTS_CHART_TYPE =
  'CHANGE_NEIGHBORHOOD_INSIGHTS_CHART_TYPE';
export const FETCH_MORTGAGE_AND_TERMS = 'FETCH_MORTGAGE_AND_TERMS';
export const FETCH_MORTGAGE_AND_TERMS_SUCCESS =
  'FETCH_MORTGAGE_AND_TERMS_SUCCESS';
export const FETCH_MORTGAGE_AND_TERMS_ERROR = 'FETCH_MORTGAGE_AND_TERMS_ERROR';
export const FETCH_MORTGAGE_SUMMARY = 'FETCH_MORTGAGE_SUMMARY';
export const FETCH_MORTGAGE_SUMMARY_SUCCESS = 'FETCH_MORTGAGE_SUMMARY_SUCCESS';
export const FETCH_MORTGAGE_SUMMARY_ERROR = 'FETCH_MORTGAGE_SUMMARY_ERROR';
export const FETCH_INIT_PROPERTY_DETAILS = 'FETCH_INIT_PROPERTY_DETAILS';
export const FETCH_INIT_PROPERTY_DETAILS_SUCCESS =
  'FETCH_INIT_PROPERTY_DETAILS_SUCCESS';
export const FETCH_INIT_PROPERTY_DETAILS_ERROR =
  'FETCH_INIT_PROPERTY_DETAILS_ERROR';
export const SET_LOCAL_STORAGE_PROPERTY_SEEN =
  'SET_LOCAL_STORAGE_PROPERTY_SEEN';
export const FETCH_FULL_PROPERTY_DETAILS = 'FETCH_FULL_PROPERTY_DETAILS';
export const FETCH_FULL_PROPERTY_DETAILS_SUCCESS =
  'FETCH_FULL_PROPERTY_DETAILS_SUCCESS';
export const FETCH_PROPERTY_AVM_FACTORS = 'FETCH_PROPERTY_AVM_FACTORS';
export const FETCH_PROPERTY_AVM_FACTORS_SUCCESS =
  'FETCH_PROPERTY_AVM_FACTORS_SUCCESS';
export const PROPERTY_AVM_FACTORS_DO_NOT_EXIST =
  'PROPERTY_AVM_FACTORS_DO_NOT_EXIST';
export const FETCH_PROPERTY_AVM_DEEP_DIVE_DATA =
  'FETCH_PROPERTY_AVM_DEEP_DIVE_DATA';
export const FETCH_PROPERTY_AVM_DEEP_DIVE_DATA_SUCCESS =
  'FETCH_PROPERTY_AVM_DEEP_DIVE_DATA_SUCCESS';
export const CHANGE_CRIME_TYPE = 'CHANGE_CRIME_TYPE';
export const CHANGE_SCHOOL_TYPE = 'CHANGE_SCHOOL_TYPE';
export const PROPERTY_PAGE_HAS_TOGGLED_HEATMAPS =
  'PROPERTY_PAGE_HAS_TOGGLED_HEATMAPS';
export const SHOW_MORE_SALES = 'SHOW_MORE_SALES';
export const PROPERTY_SEE_LOAN_OPTIONS = 'PROPERTY_SEE_LOAN_OPTIONS';
export const REPORT_PROPERTY_PAGE_MORTGAGE_CALCULATOR =
  'REPORT_PROPERTY_PAGE_MORTGAGE_CALCULATOR';

export type FetchMortgageAndTermsAction = {
  type: typeof FETCH_MORTGAGE_AND_TERMS;
  payload: {
    activePropertySlug: string;
    data: MortgageCalculationDetails;
    isFetchingDefaultTerms: boolean;
  };
};

export const fetchMortgageAndTerms = (
  activePropertySlug: string,
  data: MortgageCalculationDetails,
  isFetchingDefaultTerms: boolean
): FetchMortgageAndTermsAction => ({
  type: FETCH_MORTGAGE_AND_TERMS,
  payload: {
    activePropertySlug,
    data,
    isFetchingDefaultTerms,
  },
});

type FetchMortgageAndTermsSuccessAction = {
  type: typeof FETCH_MORTGAGE_AND_TERMS_SUCCESS;
  payload: {
    activePropertySlug: string;
    loanTerms: MortgageTerm[];
    mortgageCalculations: MortgageCalculationsForUIComponents;
  };
};

export const fetchMortgageAndTermsSuccess = (
  activePropertySlug: string,
  loanTerms: MortgageTerm[],
  mortgageCalculations: MortgageCalculationsForUIComponents
): FetchMortgageAndTermsSuccessAction => ({
  type: FETCH_MORTGAGE_AND_TERMS_SUCCESS,
  payload: {
    activePropertySlug,
    loanTerms,
    mortgageCalculations,
  },
});

type FetchMortgageAndTermsErrorAction = {
  type: typeof FETCH_MORTGAGE_AND_TERMS_ERROR;
  payload: {
    activePropertySlug: string;
    error: string;
  };
};

export const fetchMortgageAndTermsError = (
  activePropertySlug: string,
  error: string
): FetchMortgageAndTermsErrorAction => ({
  type: FETCH_MORTGAGE_AND_TERMS_ERROR,
  payload: {
    activePropertySlug,
    error,
  },
});

export type FetchMortgageSummaryAction = {
  type: typeof FETCH_MORTGAGE_SUMMARY;
  payload: MortgageSummaryCallParams;
};

export const fetchMortgageSummary = ({
  activePropertySlug,
  userChosenHomePrice,
  userChosenInterestRate,
  userChosenLoanTerm,
  userChosenDownpaymentPct,
}: {
  activePropertySlug: string;
  userChosenHomePrice?: number | null;
  userChosenInterestRate?: number | string | null;
  userChosenLoanTerm?: string | null;
  userChosenDownpaymentPct?: number | null;
}): FetchMortgageSummaryAction => ({
  type: FETCH_MORTGAGE_SUMMARY,
  payload: {
    activePropertySlug,
    userChosenHomePrice,
    userChosenInterestRate,
    userChosenLoanTerm,
    userChosenDownpaymentPct,
  },
});

type FetchMortgageSummarySuccessAction = {
  type: typeof FETCH_MORTGAGE_SUMMARY_SUCCESS;
  payload: {
    activePropertySlug: string;
    summary: string;
  };
};

export const fetchMortgageSummarySuccess = (
  activePropertySlug: string,
  summary: string
): FetchMortgageSummarySuccessAction => ({
  type: FETCH_MORTGAGE_SUMMARY_SUCCESS,
  payload: {
    activePropertySlug,
    summary,
  },
});

type FetchMortgageSummaryErrorAction = {
  type: typeof FETCH_MORTGAGE_SUMMARY_ERROR;
  payload: {
    error: string;
  };
};

export const fetchMortgageSummaryError = (
  error: string
): FetchMortgageSummaryErrorAction => ({
  type: FETCH_MORTGAGE_SUMMARY_ERROR,
  payload: {
    error,
  },
});

type ChangeNeighborhoodInsightsChartTypeAction = {
  type: typeof CHANGE_NEIGHBORHOOD_INSIGHTS_CHART_TYPE;
  payload: {
    value: (typeof NEIGHBORHOOD_INSIGHT_TYPES)[keyof typeof NEIGHBORHOOD_INSIGHT_TYPES];
  };
};

export const changeNeighborhoodInsightsChartType = (
  value: ChangeNeighborhoodInsightsChartTypeAction['payload']['value']
): ChangeNeighborhoodInsightsChartTypeAction => ({
  type: CHANGE_NEIGHBORHOOD_INSIGHTS_CHART_TYPE,
  payload: {
    value,
  },
});

type ChangeForecastChartTypeAction = {
  type: typeof CHANGE_FORECAST_CHART_TYPE;
  payload: {
    value: (typeof FORECAST_CHART_TYPES)[keyof typeof FORECAST_CHART_TYPES];
  };
};

export const changeForecastChartType = (
  value: ChangeForecastChartTypeAction['payload']['value']
): ChangeForecastChartTypeAction => ({
  type: CHANGE_FORECAST_CHART_TYPE,
  payload: { value },
});

/* Gets all property details needed for the initial display of the PDP */
type FetchInitPropertyDetailsAction = {
  type: typeof FETCH_INIT_PROPERTY_DETAILS;
  payload: {
    slug: string;
  };
};

export const fetchInitPropertyDetails = (
  slug: string
): FetchInitPropertyDetailsAction => ({
  type: FETCH_INIT_PROPERTY_DETAILS,
  payload: {
    slug,
  },
});

export type FetchInitPropertyDetailsSuccessAction = {
  type: typeof FETCH_INIT_PROPERTY_DETAILS_SUCCESS;
  payload: {
    slug: string;
    data: InitPropertyLookupWithAddressRequired;
  };
};

export const fetchInitPropertyDetailsSuccess = ({
  slug,
  data,
}: FetchInitPropertyDetailsSuccessAction['payload']): FetchInitPropertyDetailsSuccessAction => ({
  type: FETCH_INIT_PROPERTY_DETAILS_SUCCESS,
  payload: {
    slug,
    data,
  },
});

/* Gets all property details for the full functionality of the PDP */
type FetchFullPropertyDetailsAction = {
  type: typeof FETCH_FULL_PROPERTY_DETAILS;
  payload: {
    slug: string;
  };
};

export const fetchFullPropertyDetails = (
  slug: string
): FetchFullPropertyDetailsAction => ({
  type: FETCH_FULL_PROPERTY_DETAILS,
  payload: {
    slug,
  },
});

export type FetchFullPropertyDetailsSuccessAction = {
  type: typeof FETCH_FULL_PROPERTY_DETAILS_SUCCESS;
  payload: {
    slug: string;
    data: FullPropertyLookupWithAddressRequired;
  };
};

export const fetchFullPropertyDetailsSuccess = ({
  slug,
  data,
}: FetchFullPropertyDetailsSuccessAction['payload']): FetchFullPropertyDetailsSuccessAction => ({
  type: FETCH_FULL_PROPERTY_DETAILS_SUCCESS,
  payload: {
    slug,
    data,
  },
});

type FetchInitPropertyDetailsErrorAction = {
  type: typeof FETCH_INIT_PROPERTY_DETAILS_ERROR;
  payload: {
    slug: string;
  };
};

export const fetchInitPropertyDetailsError = ({
  slug,
}: FetchInitPropertyDetailsErrorAction['payload']): FetchInitPropertyDetailsErrorAction => ({
  type: FETCH_INIT_PROPERTY_DETAILS_ERROR,
  payload: {
    slug,
  },
});

type SetLocalStoragePropertySeenAction = {
  type: typeof SET_LOCAL_STORAGE_PROPERTY_SEEN;
  payload: {
    slug: string;
  };
};

export const setLocalStoragePropertySeen = (
  slug: string
): SetLocalStoragePropertySeenAction => ({
  type: SET_LOCAL_STORAGE_PROPERTY_SEEN,
  payload: {
    slug,
  },
});

type FetchPropertyAvmFactorsAction = {
  type: typeof FETCH_PROPERTY_AVM_FACTORS;
};

export const fetchPropertyAvmFactors = (): FetchPropertyAvmFactorsAction => ({
  type: FETCH_PROPERTY_AVM_FACTORS,
});

type FetchPropertyAvmDeepDiveDataAction = {
  type: typeof FETCH_PROPERTY_AVM_DEEP_DIVE_DATA;
};

export const fetchPropertyAvmDeepDiveData =
  (): FetchPropertyAvmDeepDiveDataAction => ({
    type: FETCH_PROPERTY_AVM_DEEP_DIVE_DATA,
  });

type FetchPropertyAvmDeepDiveDataSuccessAction = {
  type: typeof FETCH_PROPERTY_AVM_DEEP_DIVE_DATA_SUCCESS;
  payload: {
    slug: string;
    regressionsData: any;
    tractStatsCount: number;
    avmFactorsWithLocation: any;
  };
};

export const fetchPropertyAvmDeepDiveDataSuccess = ({
  slug,
  regressionsData,
  tractStatsCount,
  avmFactorsWithLocation,
}: FetchPropertyAvmDeepDiveDataSuccessAction['payload']): FetchPropertyAvmDeepDiveDataSuccessAction => ({
  type: FETCH_PROPERTY_AVM_DEEP_DIVE_DATA_SUCCESS,
  payload: {
    slug,
    regressionsData,
    tractStatsCount,
    avmFactorsWithLocation,
  },
});

type FetchPropertyAvmFactorsSuccessAction = {
  type: typeof FETCH_PROPERTY_AVM_FACTORS_SUCCESS;
  payload: {
    slug: string;
    avmFactors: (AvmFactor & { __typename: AvmFactorType })[];
    avm: Avm;
  };
};

export const fetchPropertyAvmFactorsSuccess = ({
  slug,
  avmFactors,
  avm,
}: FetchPropertyAvmFactorsSuccessAction['payload']): FetchPropertyAvmFactorsSuccessAction => ({
  type: FETCH_PROPERTY_AVM_FACTORS_SUCCESS,
  payload: {
    slug,
    avmFactors,
    avm,
  },
});

type ChangeCrimeTypeAction = {
  type: typeof CHANGE_CRIME_TYPE;
  payload: {
    type: (typeof CRIME_TYPES)[keyof typeof CRIME_TYPES];
  };
};

export const changeCrimeType = (
  type: ChangeCrimeTypeAction['payload']['type']
): ChangeCrimeTypeAction => ({
  type: CHANGE_CRIME_TYPE,
  payload: {
    type,
  },
});

type ChangeSchoolsTypeAction = {
  type: typeof CHANGE_SCHOOL_TYPE;
  payload: {
    schoolsType: (typeof SCHOOL_TYPES)[keyof typeof SCHOOL_TYPES];
  };
};

export const changeSchoolsType = (
  schoolsType: ChangeSchoolsTypeAction['payload']['schoolsType']
): ChangeSchoolsTypeAction => ({
  type: CHANGE_SCHOOL_TYPE,
  payload: { schoolsType },
});

type ShowMoreSalesAction = {
  type: typeof SHOW_MORE_SALES;
  payload: {
    slug: string;
  };
};

export const showMoreSales = (slug: string): ShowMoreSalesAction => ({
  type: SHOW_MORE_SALES,
  payload: {
    slug,
  },
});

type PropertySeeLoanOptionsAction = {
  type: typeof PROPERTY_SEE_LOAN_OPTIONS;
  payload: {
    slug: string;
  };
};

export const propertySeeLoanOptions = (
  slug: string
): PropertySeeLoanOptionsAction => ({
  type: PROPERTY_SEE_LOAN_OPTIONS,
  payload: {
    slug,
  },
});

type PropertyPageHasToggledHeatmaps = {
  type: typeof PROPERTY_PAGE_HAS_TOGGLED_HEATMAPS;
  payload: {
    hasEnabledHeatmaps: boolean;
  };
};

export const propertyPageHasToggledHeatmaps = (
  hasEnabledHeatmaps: boolean
): PropertyPageHasToggledHeatmaps => ({
  type: PROPERTY_PAGE_HAS_TOGGLED_HEATMAPS,
  payload: {
    hasEnabledHeatmaps,
  },
});

type ReportPropertyPageMortgageCalculator = {
  type: typeof REPORT_PROPERTY_PAGE_MORTGAGE_CALCULATOR;
  payload: {
    eventName: string;
    data: {} | null;
  };
};

export const reportPropertyPageMortgageCalculator = (
  eventName: string,
  data?: {}
): ReportPropertyPageMortgageCalculator => ({
  type: REPORT_PROPERTY_PAGE_MORTGAGE_CALCULATOR,
  payload: {
    eventName,
    data: data || null,
  },
});

export type PropertyDetailsAction =
  | FetchInitPropertyDetailsSuccessAction
  | FetchMortgageAndTermsAction
  | FetchMortgageAndTermsSuccessAction
  | FetchMortgageAndTermsErrorAction
  | FetchMortgageSummaryAction
  | FetchMortgageSummarySuccessAction
  | FetchMortgageSummaryErrorAction
  | ChangeNeighborhoodInsightsChartTypeAction
  | ChangeForecastChartTypeAction
  | FetchInitPropertyDetailsAction
  | FetchFullPropertyDetailsAction
  | FetchFullPropertyDetailsSuccessAction
  | FetchInitPropertyDetailsErrorAction
  | SetLocalStoragePropertySeenAction
  | FetchPropertyAvmFactorsAction
  | FetchPropertyAvmDeepDiveDataAction
  | FetchPropertyAvmDeepDiveDataSuccessAction
  | FetchPropertyAvmFactorsSuccessAction
  | ChangeCrimeTypeAction
  | ChangeSchoolsTypeAction
  | ShowMoreSalesAction
  | PropertySeeLoanOptionsAction
  | PropertyPageHasToggledHeatmaps
  | ReportPropertyPageMortgageCalculator;
