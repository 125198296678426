import { createSelector } from 'reselect';

import { ReduxState } from '@client/store/types/redux-state';
import { AttemptedRouteState } from '@client/store/reducers/attempted-route.reducer';

export function getAttemptedRouteState(state: ReduxState): AttemptedRouteState {
  return state.attemptedRoute;
}

export const getAttemptedView = createSelector(
  getAttemptedRouteState,
  (attemptedRouteState) => attemptedRouteState.view
);

export const getAttemptedParams = createSelector(
  getAttemptedRouteState,
  (attemptedRouteState) => attemptedRouteState.params
);

export const getAttemptedPath = createSelector(
  getAttemptedRouteState,
  (attemptedRouteState) => attemptedRouteState.path
);

export const getAttemptedQuery = createSelector(
  getAttemptedRouteState,
  (attemptedRouteState) => attemptedRouteState.query
);
