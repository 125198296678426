import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import NoAvmPlaceholder, {
  NoAvmPlaceholderProps,
} from '@client/components/NoAvmPlaceholder/NoAvmPlaceholder';
import { COBRAND_IDS } from '@client/store/types/cobranding';
const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return null;
    default:
      return NoAvmPlaceholder;
  }
};

const NoAvmPlaceholderCobranded: React.FC<NoAvmPlaceholderProps> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return Component ? <Component {...props} /> : null;
};

export default NoAvmPlaceholderCobranded;
