import { createSelector } from 'reselect';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReduxState } from '@client/store/types/redux-state';
import { Status, STATUSES } from '@client/store/constants';

export type AdminPortalState = {
  embeddingUrl: {
    fetchStatus: Status;
    data: string | null;
  };
  token: string;
};

const fetchInitialState = {
  fetchStatus: STATUSES.INIT,
  data: null,
};

export const INITIAL_ADMIN_PORTAL_STATE: AdminPortalState = {
  embeddingUrl: fetchInitialState,
  token: '',
};

export const AdminPortalSlice = createSlice({
  name: 'adminPortal',
  initialState: INITIAL_ADMIN_PORTAL_STATE,
  reducers: {
    fetchAdminPortalAccessToken: () => {},
    fetchAdminPortalDashboardUrl: (state) => ({
      ...state,
      embeddingUrl: {
        fetchStatus: STATUSES.LOADING,
        data: null,
      },
    }),
    fetchAdminPortalDashboardUrlSuccess: (
      state,
      action: PayloadAction<{ embedding_url: string }>
    ) => ({
      ...state,
      embeddingUrl: {
        fetchStatus: STATUSES.SUCCESS,
        data: action.payload.embedding_url,
      },
    }),
    fetchAdminPortalDashboardUrlError: (state) => ({
      ...state,
      embeddingUrl: {
        fetchStatus: STATUSES.ERROR,
        data: null,
      },
    }),
    fetchAdminAccessTokenSuccess: (state, action: PayloadAction<string>) => ({
      ...state,
      token: action.payload,
    }),
  },
});

export function selectAdminPortal(state: ReduxState) {
  return state.adminPortal;
}

export const selectEmbeddingUrl = createSelector(
  selectAdminPortal,
  (adminPortalState) => adminPortalState.embeddingUrl.data
);

export const selectAdminPortalAccessToken = createSelector(
  selectAdminPortal,
  (adminPortalState) => adminPortalState.token
);

export const {
  fetchAdminPortalDashboardUrl,
  fetchAdminPortalDashboardUrlSuccess,
  fetchAdminPortalDashboardUrlError,
  fetchAdminAccessTokenSuccess,
  fetchAdminPortalAccessToken,
} = AdminPortalSlice.actions;

export default AdminPortalSlice.reducer;
