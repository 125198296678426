import { consumerApiClient } from '@client/services/consumer-api-client';
import { graphQLApiClient } from '@client/services/graphql-api-client';
import {
  LOGIN_SUCCESS,
  updateUserProfile,
} from '@client/store/actions/auth.actions';
import { setActiveNotification } from '@client/store/actions/modals.actions';
import {
  createSavedSearch,
  createSavedSearchError,
  createSavedSearchSuccess,
  CREATE_SAVED_SEARCH,
  DeleteSavedSearch,
  deleteSavedSearchError,
  deleteSavedSearchSuccess,
  DELETE_SAVED_SEARCH,
  ENSURE_LOGIN_THEN_CREATE_SAVED_SEARCH,
  fetchSavedSearches,
  fetchSavedSearchesError,
  fetchSavedSearchesSuccess,
  FETCH_SAVED_SEARCHES,
  FETCH_SAVED_SEARCHES_IF_NECESSARY,
  reportClickSaveSearchCTA,
  SelectSavedSearch,
  SELECT_SAVED_SEARCH,
  updateSavedSearchSuccess,
  UPDATE_SAVED_SEARCH,
} from '@client/store/actions/saved-search.actions';
import {
  searchApplySavedFilters,
  searchClearResultMapMarkers,
  SEARCH_SET_MAP_VIEWPORT,
} from '@client/store/actions/search.actions';
import { SETTINGS_KEYS, STATUSES } from '@client/store/constants';
import { marshallSavedSearchResponse } from '@client/store/reducers/saved-search.reducer';
import { ensureLoggedInThen } from '@client/store/sagas/auth.saga';
import { GeocodeQuery } from '@client/store/sagas/queries/types';
import {
  getAllUserSettings,
  getIsLoggedIn,
} from '@client/store/selectors/auth.selectors';
import {
  getSavedSearches,
  getSavedSearchesStatus,
} from '@client/store/selectors/saved-search.selectors';
import {
  getConsumerAPIFormattedSearchFilters,
  getSearchConstrainedPlace,
  getSearchMarkerFeaturesMinZoom,
  getSearchPlaceGeoJSON,
  getSearchViewport,
  getSearchZoom,
} from '@client/store/selectors/search.selectors';
import { SavedSearchAPIFields } from '@client/store/types/saved-searches';
import {
  getCenterPointFromBounds,
  getDistance,
  getPlaceIdentifierStringFromGeocodeResults,
} from '@client/utils/maps.utils';
import { watchEvery } from '@client/utils/saga.utils';
import { call, put, select, take } from 'redux-saga/effects';
import { getIsDisplayMultiFamilySearchFiltersEnabled } from '../selectors/enabled-features.selectors';

export const CANNOT_SAVE_SEARCH_ERROR_MSG =
  'Cannot save your search in this area. Please move the map and try again.';

export function* createSavedSearchSaga() {
  const currentZoom = yield select(getSearchZoom);
  const mapFeaturesMinZoom = yield select(getSearchMarkerFeaturesMinZoom);

  if (currentZoom && currentZoom < mapFeaturesMinZoom) {
    yield put(
      createSavedSearchError(
        'Please zoom the map in further to save your search'
      )
    );
    return;
  }

  const searchData = yield call(getSavedSearchFieldsForPlace);
  if (searchData) {
    try {
      const newSearch = yield call(
        [consumerApiClient, consumerApiClient.createSavedSearch],
        searchData
      );
      yield put(createSavedSearchSuccess(newSearch));
      const userEmailNotificationSettings = (yield select(
        getAllUserSettings
      )) as ReturnType<typeof getAllUserSettings>;

      if (!userEmailNotificationSettings.send_email_search) {
        yield put(
          updateUserProfile({ [SETTINGS_KEYS.SEND_EMAIL_SEARCH]: true }, true)
        );
        yield put(
          setActiveNotification('saved-search-email-setting-notification')
        );
      } else {
        yield put(setActiveNotification('saved-search-notification'));
      }
    } catch (e: any) {
      yield put(
        createSavedSearchError(
          "We're sorry, but an error has occurred. Please try again later."
        )
      );
      throw e;
    }
  }
}

export function* updateSavedSearch() {
  const searchData = yield call(getSavedSearchFieldsForPlace);
  const newSearch = yield call(
    [consumerApiClient, consumerApiClient.updateSavedSearch],
    searchData
  );
  yield put(updateSavedSearchSuccess(newSearch));
}

export function* getSavedSearchFieldsForPlace(): Generator<
  any,
  Partial<SavedSearchAPIFields> | null
> {
  const filtersMapping = (yield select(
    getConsumerAPIFormattedSearchFilters
  )) as ReturnType<typeof getConsumerAPIFormattedSearchFilters>;
  let viewport = (yield select(getSearchViewport)) as ReturnType<
    typeof getSearchViewport
  >;

  /* When coming to the site via a SAML login, it's possible for this to run prior to the
   * viewport being set. In this case, we'll need to wait until the viewport gets set on state. */
  if (!viewport) {
    yield take(SEARCH_SET_MAP_VIEWPORT);
    viewport = (yield select(getSearchViewport)) as NonNullable<
      ReturnType<typeof getSearchViewport>
    >;
  }
  const { southWest, northEast } = viewport;
  const geom = (yield select(getSearchPlaceGeoJSON)) as ReturnType<
    typeof getSearchPlaceGeoJSON
  >;
  const spatialId = (yield select(getSearchConstrainedPlace)) as ReturnType<
    typeof getSearchConstrainedPlace
  >;
  const shouldPassSpatialId = spatialId && geom && geom.type !== 'Point';
  const mapCenterPoint = getCenterPointFromBounds({ southWest, northEast });
  const geocodeResponse = (yield call(
    [graphQLApiClient, graphQLApiClient.getGeocode],
    {
      latitude: mapCenterPoint.lat,
      longitude: mapCenterPoint.lng,
    }
  )) as GeocodeQuery;

  if (
    !geocodeResponse?.geocode?.results ||
    geocodeResponse?.geocode.results.length === 0
  ) {
    yield put(createSavedSearchError(CANNOT_SAVE_SEARCH_ERROR_MSG));
    return null;
  } else {
    const viewportSize = getDistance(northEast, southWest);
    const placeIdentifier = getPlaceIdentifierStringFromGeocodeResults(
      geocodeResponse.geocode.results,
      viewportSize
    );
    /* If Google API doesn't any result types in our whitelist */
    if (!placeIdentifier) {
      yield put(createSavedSearchError(CANNOT_SAVE_SEARCH_ERROR_MSG));
      return null;
    }

    return {
      /* Ensure the name stays under the API limit */
      name: placeIdentifier.substring(0, 128),
      send_email: true,
      send_mobile: true,
      ...(shouldPassSpatialId && spatialId.placeId
        ? { place_id: spatialId.placeId }
        : {}),
      ...(shouldPassSpatialId ? { geom } : {}),
      ...(shouldPassSpatialId
        ? {
            sw_lat: null,
            sw_lng: null,
            ne_lat: null,
            ne_lng: null,
          }
        : {
            sw_lat: southWest.lat,
            sw_lng: southWest.lng,
            ne_lat: northEast.lat,
            ne_lng: northEast.lng,
          }),
      ...filtersMapping,
    };
  }
}

export function* ensureLoggedInThenCreateSavedSearch(action: {
  payload: { location: 'map' | 'list' | 'filters' };
}) {
  const searchData = yield call(getSavedSearchFieldsForPlace);
  yield put(
    reportClickSaveSearchCTA(action.payload.location, {
      ...searchData,
      geom: null,
    })
  );
  yield call(ensureLoggedInThen, createSavedSearch);
}

/**
 * Fetch saved searches only if request has not yet been started. Several components
 * that require the saved searches list dispatch this saga's corresponding action on mount.
 */
export function* fetchSavedSearchesIfNotAlreadyFetched(action) {
  const savedSearchesFetchStatus = yield select(getSavedSearchesStatus);
  if (savedSearchesFetchStatus === STATUSES.INIT) {
    yield put(fetchSavedSearches());
  }
}

/**
 * Request saved searches from the API
 */
export function* fetchSavedSearchData(action) {
  try {
    const isLoggedIn = yield select(getIsLoggedIn);
    if (isLoggedIn) {
      const savedSearches = yield call([
        consumerApiClient,
        consumerApiClient.getSavedSearchData,
      ]);
      if (savedSearches) {
        yield put(fetchSavedSearchesSuccess(savedSearches));
      } else {
        throw new Error(
          `/searches returned an invalid response: ${savedSearches}`
        );
      }
    }
  } catch (e: any) {
    yield put(fetchSavedSearchesError(e));
    throw e;
  }
}

/**
 * Transform API data to app state and apply the saved search
 */
export function* transformAndApplySavedSearch(action: SelectSavedSearch) {
  const { searchId } = action.payload;
  const savedSearches = (yield select(getSavedSearches)) as ReturnType<
    typeof getSavedSearches
  >;
  const isDisplayMultiFamilySearchFiltersEnabled = (yield select(
    getIsDisplayMultiFamilySearchFiltersEnabled
  )) as ReturnType<typeof getIsDisplayMultiFamilySearchFiltersEnabled>;
  const search = savedSearches.find((item) => item.searchId === searchId);
  if (search) {
    const {
      filters,
      southWest,
      northEast,
      constrainedToPlace,
      placeGeoJSON,
      placeGeoJSONDescription,
    } = marshallSavedSearchResponse(
      search,
      isDisplayMultiFamilySearchFiltersEnabled
    );
    /* Clear markers to prepare for fetching new markers for new set of filters */
    yield put(searchClearResultMapMarkers());
    /* Note: this causes all filter values to be set and map position to be set.
     * Search list properties will be loaded via listening saga */
    yield put(
      searchApplySavedFilters({
        filters,
        southWest,
        northEast,
        constrainedToPlace,
        placeGeoJSON,
        placeGeoJSONDescription,
      })
    );
  }
}

export function* deleteSavedSearch(action: DeleteSavedSearch) {
  const searchId = action.payload.searchId;
  try {
    const response = yield call(
      [consumerApiClient, consumerApiClient.deleteSavedSearchData],
      searchId
    );
    if (response) {
      yield put(deleteSavedSearchSuccess(searchId));
    }
  } catch (e: any) {
    yield put(
      deleteSavedSearchError('Error removing saved search. Please try again.')
    );
    throw e;
  }
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [FETCH_SAVED_SEARCHES_IF_NECESSARY]: fetchSavedSearchesIfNotAlreadyFetched,
    [FETCH_SAVED_SEARCHES]: fetchSavedSearchData,
    [LOGIN_SUCCESS]: fetchSavedSearchData,
    [ENSURE_LOGIN_THEN_CREATE_SAVED_SEARCH]:
      ensureLoggedInThenCreateSavedSearch,
    [CREATE_SAVED_SEARCH]: createSavedSearchSaga,
    [DELETE_SAVED_SEARCH]: deleteSavedSearch,
    [SELECT_SAVED_SEARCH]: transformAndApplySavedSearch,
    [UPDATE_SAVED_SEARCH]: updateSavedSearch,
  });
};
