import { retryExchange as urqlRetryExchange } from '@urql/exchange-retry';
import { reportToSentry } from '@client/utils/error.utils';

const UPSTREAM_OUTAGE_MESSAGE = 'Invalid status 5';

/**
 * Handles retrying requests if a network error or a property-graph upstream outage error occurs.
 * Also logs Sentry error before retrying.
 */
export const retryExchange = urqlRetryExchange({
  initialDelayMs: 1000,
  maxDelayMs: 8000,
  randomDelay: true,
  maxNumberAttempts: 3,
  retryIf: (err) => {
    let shouldRetry: boolean = false;
    for (let gqlError of err.graphQLErrors) {
      if (gqlError.message.indexOf(UPSTREAM_OUTAGE_MESSAGE) > -1) {
        shouldRetry = true;
      }
    }
    if (shouldRetry) {
      reportToSentry('Error executing property-graph request. Retrying.', {
        error: err,
      });
    }
    return shouldRetry;
  },
});
