import { FC } from 'react';
import { themr } from '@friendsofreactjs/react-css-themr';

import defaultTheme from '@client/css-modules/DisclosureAsterisk.css';
import { DisclosureAsteriskProps } from '@client/store/types/cobranded-components/disclosure';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

const DisclosureAsterisk: FC<DisclosureAsteriskProps> = ({
  theme,
  shouldShowLighterColor,
}) => {
  const { lighterDisclosureAsteriskColor } = useCobrandStyles();
  return (
    <div
      className={theme.Asterisk}
      style={
        shouldShowLighterColor ? { color: lighterDisclosureAsteriskColor } : {}
      }
    >
      *
    </div>
  );
};

const ThemedDisclosureAsterisk = themr(
  'DisclosureAsterisk',
  defaultTheme
)(DisclosureAsterisk);
export default ThemedDisclosureAsterisk;
