import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import Layer from '@hc/hcmaps-mapboxgl/lib/components/Layer';
import LayerSource from '@hc/hcmaps-mapboxgl/lib/components/LayerSource';
import Maps, { Api } from '@hc/hcmaps-mapboxgl/lib/components/Maps';
import Marker from '@hc/hcmaps-mapboxgl/lib/components/Marker';
import {
  fetchUserLocationFromDevice,
  fetchUserLocationViaIP,
} from '@hc/hcmaps-mapboxgl/lib/services/user-location';
import classNames from 'classnames';
import { Geometry } from 'geojson';
import { throttle, values } from 'lodash';
import mapboxgl, {
  FitBoundsOptions,
  LngLat,
  Map,
  MapboxEvent,
  MapboxGeoJSONFeature,
  MapMouseEvent,
} from 'mapbox-gl';
import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';

import HC_CONSTANTS from '@client/app.config';
import CobrandedStyles from '@client/components/CobrandedStyles';
import ImagePreloader from '@client/components/ImagePreloader';
import MapHTMLMarkersLayer from '@client/components/MapHTMLMarkersLayer';
import MapMarkerPulseLayer from '@client/components/MapMarkerPulseLayer';
import MapMLSCoverageLayer from '@client/components/MapMLSCoverageLayer';
import MapNavControl from '@client/components/MapNavControl';
import TemporaryMapMarkerIcon from '@client/components/TemporaryMapMarkerIcon';
import MapLayersControlContainer from '@client/containers/map-layers-control.container';
import defaultTheme from '@client/css-modules/Maps.css';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import renderOnMountUntyped from '@client/hocs/render-on-mount-untyped';
import {
  LayerGroup,
  LayerMetric,
  MAP_FALLBACK_LOCATION,
  MAPBOX_LAYER_IDS,
  SCHOOL_MARKER_IMAGE_URL_BY_ID,
} from '@client/store/map-constants';
import { getMapPlaceBoundaryLayerLineColor } from '@client/store/selectors/cobranding.selectors';
import {
  APIClusterMapMarker,
  LatitudeLongitudeObject,
  MapMarker,
  MapMarkerGeoJSONFeature,
  MLSCoverageLayerFeatureProperties,
  MultiUnitMapMarker,
  PropertyMapMarker,
  SetMapLocation,
  SubjectMapMarker,
} from '@client/store/types/maps';
import { NormalizedProperty } from '@client/store/types/property';
import { ReduxState } from '@client/store/types/redux-state';
import { reportToSentry } from '@client/utils/error.utils';
import { localStorageUtil } from '@client/utils/local-storage.utils';
import {
  isAPIClusterMarker,
  isMultiUnitClusterMarker,
  isPropertyMarker,
} from '@client/utils/maps.utils';
import pulseImage from 'assets/images/marker-pulse-ellipse.png';

const {
  GEOLOCATION_ENDPOINT,
  MAPBOX_ACCESS_TOKEN,
  MAP_STYLE_URL_PRIMARY,
  GEOTILE_DATA_URL,
} = HC_CONSTANTS;

type TriggerProp = string | number | boolean | null;

type Props = {
  /* Constant identifier for the layer group that should be active on map init */
  defaultActiveLayerGroup?: LayerGroup;
  /** Whether to reposition map to fit all markers whenever markers change */
  fitMarkersOnMarkerChange?: boolean;
  /* Text for a generic notification to show in place of the bottom map controls */
  MapNotification?: string | false | JSX.Element;
  /* Whether to show the map layers control, enabling the ability to toggle heatmap layers */
  hasMapLayersControl?: boolean;
  /** DEPRECATED - An array of marker data objects for which Mapbox marker objects will be rendered on the map.
   * These markers have React-managed icons and cannot have popups */
  temporaryMarkers?: MapMarker[];
  /* React-managed marker components to be rendered on the map.  Useful when desiring a custom icon */
  customSources?: React.ComponentType<LayerSource>[];
  customLayers?: React.ComponentType<Layer>[];
  customPopup?: JSX.Element;
  /** GeoJSON feature data to be used by MapMarkersLayer to render Mapbox-managed symbols on the map
   * These "symbol" markers have .png icons and are styled using Mapbox style properties */
  markerFeatures?: MapMarkerGeoJSONFeature[];
  /* A map feature that, when passed, will cause a popup to auto-open above the feature on mount.
   * The feature must be a property marker */
  autoOpenPopupForMarkerFeature?: MapMarkerGeoJSONFeature | null;
  markerFeaturesChangedTrigger?: TriggerProp;
  /** A component to be used for the popup when a MapMarkersLayer symbol is clicked */
  MarkerPopup?: JSX.Element;
  /** Used to restrict minimum zoom for the map */
  minZoom?: number;
  fitMapTrigger?: TriggerProp;
  /** Whether the map positioning should be set to fit all markers within map bounds */
  shouldFitMarkersInMap?: boolean;
  /* Property that, when updated, causes the map to recalculate its size, filling up its parent container */
  invalidateSizeTrigger?: TriggerProp;
  /** Location that, when updated, will cause the map to reposition */
  setMapLocation: SetMapLocation;
  showZoomControls?: boolean;
  /* Whether to query the user's device for geolocation information.  If `false`, attempts
   * to retrieve location information via IP address */
  shouldGetGeolocationFromDevice?: boolean;
  /* Padding used with fitting either bounds or a point in the map */
  fitBoundsOptions?: FitBoundsOptions;
  /* Locations to add a Mapbox marker containing only a pulse animation.  Used to draw attention to
   * Mapbox-managed symbol layer markers which we can't otherwise "pulse" */
  pulseLocations?: LatitudeLongitudeObject[] | null;
  /* GeoJSON object to be drawn on the map, representing the boundary of a place */
  placeBoundaryGeoJSON?: Geometry | null;
  placeBoundaryUpdateTrigger?: TriggerProp;
  /* The name of a school district boundary that should be highlighted on init of schools layer */
  autoHighlightSchoolDistrictName?: string | null;
  /* Placement of the zoom controls */
  zoomButtonLocation?:
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right';
  /* Placement of the mapbox attribution button */
  attributionLocation?:
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right';
  /* Whether an open popup should be hidden if markerFeaturesChangedTrigger changes */
  hidePopupOnMarkerFeaturesChanged?: boolean;
  /* The padding from the edge of the viewport to add when panning the map to fit the popup */
  fitPopupPadding?: {
    x: number;
    y: number;
  };
  /* Access token to tell Mapbox to send along with Gaia tile requests.  This is required whenever
   * hasMapLayersControl==true */
  geoRequestAccessToken?: string;
  useFloatingLayerGroupsControl?: boolean;
  isShowingBottomLayerGroupsControl?: boolean;
  allowShowingBottomControl?: boolean;
  /* A mapping of ids to image URLs to be added to the map and referred to within symbols layers.
   * These are used by MapHTMLMarkersLayer in conjunction with the dynamically generated SVGs from
   * map-markers-generator.ts */
  markerImageUrls?: { [id: string]: string };
  /* A slug, when provided, that prevents a popup from being rendered when a marker with the
   * specified slug is clicked */
  noPopupForAddressSlug?: string;
  /* In certain cases we want to close the map bottom layers control from the outside.
   * The active map layer state should most likely be moved completely to Redux, but this is
   * a more complicated refactor */
  closeBottomLayerControlTrigger?: TriggerProp;
  allowScrollZoom?: boolean;
  dataHcName?: string;
  /* Whether to disable 1-finger dragging.  Useful when the map is on a scrollable mobile page */
  disableDragging?: boolean;
  isShowingMLSCoverageLayer?: boolean;
  isShowingMarkers?: boolean;
  tileURL?: string;
  zoomWhenPositioningToPoint?: number;
  mapPlaceBoundaryLayerLineColor: string;
  handleMapClick?: (api: Api, e: MapMouseEvent) => void;
  handleMarkerFeatureClick?: (
    e: MouseEvent | KeyboardEvent,
    api: Api,
    featureProps: PropertyMapMarker
  ) => void;
  handleAPIClusterMarkerClick?: (
    e: MouseEvent | KeyboardEvent,
    api: Api,
    featureProps: APIClusterMapMarker
  ) => void;
  handleMultiUnitMarkerClick?: (
    e: MouseEvent | KeyboardEvent,
    api: Api,
    featureProps: MultiUnitMapMarker
  ) => void;
  handleZoomStart?: (api: Api, e: MapMouseEvent) => void;
  handleMoveStart?: (api: Api, e: MapMouseEvent) => void;
  handleZoom?: (api: Api, e: MapMouseEvent) => void;
  handleZoomEnd?: (api: Api, e: MapMouseEvent) => void;
  handleMoveEnd?: (
    api: Api,
    e: MapMouseEvent,
    { activeLayerMetric }: { activeLayerMetric: LayerMetric | null }
  ) => void;
  /* Fired when the user clicks the zoom control */
  handleClickZoomIn?: (api: Api) => void;
  /* Fired when the user clicks the zoom control */
  handleClickZoomOut?: (api: Api) => void;
  /* Fired after the first visually complete rendering of the map has occurred */
  handleMapBackgroundLoaded?: (api: Api) => void;
  /* Called on marker mouseover with the map api and feature as arguments.  Called again with empty
   * args on marker mouseout */
  handleMarkerFeatureHover?: (
    api: Api | null,
    feature?: MapMarkerGeoJSONFeature
  ) => void;
  handleActiveLayerChange?: (
    metric: LayerMetric | null,
    center: LngLat,
    zoom: number
  ) => void;
  handleMarkerFeaturePopupClose?: VoidFunction;
  handlePropertyClick?: (propertyData: NormalizedProperty) => void;
  handleToggleMarkers?: (api: Api, isShowingMarkers: boolean) => void;
  /* Callback executed after school district is auto-highlighted when enabling the schools layer */
  handleAutoActiveSchoolDistrictActivate?: (
    api: Api,
    schoolFeature: MapboxGeoJSONFeature
  ) => void;
  /* Callback fired whenever data for the MLS coverage layer is loaded initially or after map move */
  onMLSCoverageChange?: (
    features: (MLSCoverageLayerFeatureProperties | null)[]
  ) => void;
  handleCloseBottomGroupsControl?: VoidFunction;
  theme: Theme;
  showPropertiesDefaultOn?: boolean;
  shouldPutDataHCNameOnMarkers?: boolean;
};

type State = {
  localMarkerPopupData: NormalizedProperty | null;
  localAutoOpenPopupForMarkerFeature: MapMarkerGeoJSONFeature | null;
  activeLayerGroup: LayerGroup | null;
  markerImagesLoaded: boolean;
  isMapInitializationError: boolean;
  customSourcesHaveMounted: boolean;
  debouncedMarkerFeaturesChangedTrigger: TriggerProp | undefined;
};

const getIsTemporaryMarker = (
  marker: MapMarker
): marker is PropertyMapMarker | SubjectMapMarker => {
  return (
    !!(marker as PropertyMapMarker | SubjectMapMarker).addressSlug &&
    !!(marker as PropertyMapMarker | SubjectMapMarker).isPulsing
  );
};

export class MapsComponent extends Component<Props, State> {
  state: State = {
    /* Data for a marker's popup that's already possessed by the map marker */
    localMarkerPopupData: null,
    /* The marker feature for which we want to auto-open a popup after the map initializes.
     * This state object is necessary since we need to wait for the map to be ready before we display
     * the popup */
    localAutoOpenPopupForMarkerFeature: null,
    activeLayerGroup: null,
    markerImagesLoaded: false,
    isMapInitializationError: false,
    customSourcesHaveMounted: false,
    debouncedMarkerFeaturesChangedTrigger: null,
  };

  /* The Mapbox map instance */
  map: Map | null = null;
  /* The HC Maps library API */
  mapApi: Api | null = null;
  activeLayerMetric: LayerMetric | null = null;
  /* If we've attempted to get IP based location after user denies permission required to
   * get device based location.  If the attempt to get IP location fails, report the error */
  attemptedToGetIPLocationAfterDeviceLocationFailure: boolean = false;
  popupContentNode: HTMLDivElement = document.createElement('div');
  throttledHandleMarkerFeaturesChanged: any = null;
  invalidateMapSizeAfterMountTimeout: number = 0;

  /* Use matchMedia listener to center/fit the map before printing */
  fitMapBeforePrint = (mql) => {
    if (mql.matches) {
      this.fitMap();
    }
  };

  handleDeviceOrientationChange = () => {
    if (this.map) {
      this.map.resize();
    }
  };

  componentDidMount() {
    const { customLayers, customSources } = this.props;

    window.matchMedia('print').addListener(this.fitMapBeforePrint);
    this.throttledHandleMarkerFeaturesChanged = throttle(
      this.handleMarkerFeaturesChanged,
      100,
      { trailing: true }
    );
    window.addEventListener(
      'orientationchange',
      this.handleDeviceOrientationChange
    );
    /* Assume that custom layers need to mount after custom sources when custom sources are provided */
    if (customSources && customLayers) {
      this.setState({ customSourcesHaveMounted: true });
    }
  }

  componentWillUnmount() {
    window.matchMedia('print').removeListener(this.fitMapBeforePrint);
    if (process.env.NODE_ENV === 'development') {
      window.clearTimeout(this.invalidateMapSizeAfterMountTimeout);
    }
    this.throttledHandleMarkerFeaturesChanged.cancel();
    window.removeEventListener(
      'orientationchange',
      this.handleDeviceOrientationChange
    );
    if (this.map) {
      this.map.off('touchstart', this.handleTouchStartWhenDraggingDisabled);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      fitMapTrigger,
      invalidateSizeTrigger,
      markerFeaturesChangedTrigger,
    } = this.props;

    if (fitMapTrigger !== prevProps.fitMapTrigger) {
      this.fitMap();
    }
    if (invalidateSizeTrigger !== prevProps.invalidateSizeTrigger && this.map) {
      this.map.resize({ isProgrammaticResize: true });
    }
    if (
      markerFeaturesChangedTrigger !== prevProps.markerFeaturesChangedTrigger
    ) {
      this.throttledHandleMarkerFeaturesChanged();
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ isMapInitializationError: true });

    reportToSentry(`Handled error: ${error}`, {
      errorInfo,
      originalError: error,
    });
  }

  onMoveEnd = (api: Api, e: MapMouseEvent) => {
    const { handleMoveEnd } = this.props;

    if (handleMoveEnd) {
      handleMoveEnd(api, e, { activeLayerMetric: this.activeLayerMetric });
    }
  };

  onActiveLayerChange = (metric: LayerMetric | null) => {
    const { handleActiveLayerChange } = this.props;
    this.activeLayerMetric = metric;
    if (this.map && handleActiveLayerChange) {
      handleActiveLayerChange(metric, this.map.getCenter(), this.map.getZoom());
    }
  };

  fitMap = () => {
    if (this.mapApi) {
      this.mapApi.setMapPositionBasedOnProps();
    }
  };

  handleMarkerImagesLoaded = () => {
    this.setState({ markerImagesLoaded: true });
  };

  handleMarkerFeaturesChanged = () => {
    const { markerFeaturesChangedTrigger } = this.props;
    this.setState({
      debouncedMarkerFeaturesChangedTrigger: markerFeaturesChangedTrigger,
    });
  };

  handleTouchStartWhenDraggingDisabled = (e: MapboxEvent<TouchEvent>): void => {
    const map = this.map;
    const oe = e.originalEvent;
    if (map && oe && 'touches' in oe) {
      if (oe.touches.length > 1) {
        oe.stopImmediatePropagation();
        map.dragPan.enable();
      } else {
        map.dragPan.disable();
      }
    }
  };

  afterMount = (api: Api) => {
    this.mapApi = api;
    const { disableDragging, handleMapBackgroundLoaded } = this.props;

    if (this.mapApi) {
      if (handleMapBackgroundLoaded) {
        handleMapBackgroundLoaded(this.mapApi);
      }

      this.map = api.getMap();

      if (!this.map) {
        return;
      }

      /* Add Mapbox attribution */
      this.map.addControl(
        new mapboxgl.AttributionControl({
          compact: true,
        }),
        this.props.attributionLocation
          ? this.props.attributionLocation
          : 'bottom-right'
      );

      /* Enable the ability to 2-finger pan the map when 1-finger dragging is disabled */
      if (disableDragging) {
        this.map.dragPan.disable();
        this.map.scrollZoom.disable();
        this.map.touchPitch.disable();
        this.map.on('touchstart', this.handleTouchStartWhenDraggingDisabled);
      }

      const mapAttributionControl = document.querySelector(
        `.${defaultTheme.MapContainer} .mapboxgl-ctrl-attrib`
      );
      if (mapAttributionControl) {
        mapAttributionControl.setAttribute('data-event-name', `click_map_info`);
      }

      /* To fix map sizing issue during local development hot reloading caused by
       * the map initializing before the CSS has been loaded */
      if (process.env.NODE_ENV === 'development') {
        this.invalidateMapSizeAfterMountTimeout = window.setTimeout(() => {
          if (this.map) {
            this.map.resize();
          }
        }, 1000);
      }

      /* If desired, trigger the auto-opening of a map marker popup */
      if (this.props.autoOpenPopupForMarkerFeature) {
        this.setState({
          localAutoOpenPopupForMarkerFeature:
            this.props.autoOpenPopupForMarkerFeature,
        });
      }

      /* SECURITY: localStorage does not store sensitive information here */
      /* eslint-disable-next-line custom/explain-localstorage */
      if (localStorageUtil.getItem('mapsDebug')) {
        this.map.showTileBoundaries = true;
        (window as any)._map = this.map;
        (window as any)._mapboxgl = mapboxgl;
        const mapId = this.map.getContainer().id;
        const then = Date.now();
        console.log(`Map id ${mapId} has mounted`);

        this.map.on('load', () => {
          const now = Date.now();
          console.log(
            `Map id ${mapId} has loaded ${Math.ceil(
              now - then
            )}ms after mounting`
          );
        });
      }
    }
  };

  handleMarkerClick = (
    e: MouseEvent | KeyboardEvent,
    feature: MapMarkerGeoJSONFeature
  ) => {
    const {
      handleMarkerFeatureClick,
      handleAPIClusterMarkerClick,
      MarkerPopup,
      handleMultiUnitMarkerClick,
    } = this.props;

    if (!feature || !feature.properties) {
      throw new Error(
        `Invalid marker feature clicked: ${JSON.stringify(feature)}`
      );
    }
    if (!this.map || !this.mapApi) {
      throw new Error(
        'Attempting to handle marker click without map available'
      );
    }
    /* If API generated or Mapbox-generated cluster, zoom in 1 level to cluster */
    if (isAPIClusterMarker(feature.properties)) {
      const zoom = this.map.getZoom();
      this.map.easeTo({
        center: feature.geometry.coordinates,
        zoom: zoom + 1,
        duration: 300,
      });
      if (handleAPIClusterMarkerClick) {
        handleAPIClusterMarkerClick(e, this.mapApi, feature.properties);
      }
      /* If marker feature or Mapbox marker object, show popup */
    } else if (isPropertyMarker(feature.properties)) {
      const normalizedPropertyData = feature.properties.normalizedPropertyData;
      /* Mapbox stringifies geoJSON feature property objects, parse */
      if (
        normalizedPropertyData &&
        typeof normalizedPropertyData === 'string' &&
        MarkerPopup
      ) {
        try {
          this.setState({
            localMarkerPopupData: JSON.parse(normalizedPropertyData),
          });
        } catch (e) {
          throw new Error(
            `normalizedPropertyData ${normalizedPropertyData} is not parseable JSON`
          );
        }
        /* Will be a plain object when clicking a marker within a multi-unit cluster */
      } else if (
        normalizedPropertyData &&
        normalizedPropertyData.constructor === Object &&
        MarkerPopup
      ) {
        this.setState({ localMarkerPopupData: normalizedPropertyData });
        /* If no data provided for popup, throw error */
      } else if (MarkerPopup) {
        throw new Error(
          `No normalizedPropertyData property found for marker with addressSlug ${feature.properties.addressSlug}`
        );
      }
      if (handleMarkerFeatureClick) {
        handleMarkerFeatureClick(e, this.mapApi, feature.properties);
      }
      /* If a multi-unit cluster, get child properties from props and pass into MapMarkersLayer */
    } else if (
      isMultiUnitClusterMarker(feature.properties) &&
      handleMultiUnitMarkerClick
    ) {
      handleMultiUnitMarkerClick(e, this.mapApi, feature.properties);
    }
  };

  transformMapboxRequest = (url: string, resourceType: string) => {
    const { geoRequestAccessToken } = this.props;

    /* If Mapbox is requesting a Gaia tile (happens when only when map includes a <MapLayersControl>) */
    if (resourceType === 'Tile' && url.indexOf(GEOTILE_DATA_URL) === 0) {
      if (!geoRequestAccessToken) {
        throw new Error(
          'geoRequestAccessToken required when attempting to fetch Gaia data'
        );
      }
      return {
        url,
        headers: {
          Authorization: `Bearer ${geoRequestAccessToken}`,
        },
        credentials: 'include' as 'include',
      };
    } else {
      return { url };
    }
  };

  render() {
    const {
      allowScrollZoom,
      allowShowingBottomControl,
      autoHighlightSchoolDistrictName,
      closeBottomLayerControlTrigger,
      customLayers,
      customPopup,
      customSources,
      dataHcName,
      defaultActiveLayerGroup,
      disableDragging,
      fitBoundsOptions,
      fitMarkersOnMarkerChange,
      fitPopupPadding,
      handleAutoActiveSchoolDistrictActivate,
      handleClickZoomIn,
      handleClickZoomOut,
      handleCloseBottomGroupsControl,
      handleMapClick,
      handleMarkerFeatureHover,
      handleMarkerFeaturePopupClose,
      handleMoveStart,
      handlePropertyClick,
      handleToggleMarkers,
      handleZoom,
      handleZoomEnd,
      handleZoomStart,
      hasMapLayersControl,
      hidePopupOnMarkerFeaturesChanged,
      isShowingBottomLayerGroupsControl,
      isShowingMarkers,
      isShowingMLSCoverageLayer,
      mapPlaceBoundaryLayerLineColor,
      MapNotification,
      markerFeatures,
      markerImageUrls,
      MarkerPopup,
      minZoom,
      noPopupForAddressSlug,
      onMLSCoverageChange,
      placeBoundaryGeoJSON,
      placeBoundaryUpdateTrigger,
      pulseLocations,
      setMapLocation,
      shouldFitMarkersInMap,
      shouldGetGeolocationFromDevice,
      showZoomControls,
      temporaryMarkers,
      theme,
      tileURL,
      useFloatingLayerGroupsControl,
      zoomButtonLocation,
      zoomWhenPositioningToPoint,
      showPropertiesDefaultOn,
      shouldPutDataHCNameOnMarkers,
    } = this.props;
    const {
      markerImagesLoaded,
      debouncedMarkerFeaturesChangedTrigger,
      localMarkerPopupData,
      localAutoOpenPopupForMarkerFeature,
      isMapInitializationError,
      customSourcesHaveMounted,
    } = this.state;

    return isMapInitializationError ? (
      <div className={theme.MapErrorMessage}>
        <div>
          <h2>Unfortunately we cannot display this map.</h2>
          <div>Please ensure that WebGL is enabled in your browser.</div>
          <span className={theme.TimeStamp}>{new Date().toUTCString()}</span>
        </div>
      </div>
    ) : (
      <React.Fragment>
        <AccessibleElementUniqueId>
          {({ uid }) => (
            <Maps
              id={uid}
              className={classNames(theme.MapContainer, {
                [theme.MapContainerWithTopLayerGroupsControl]:
                  useFloatingLayerGroupsControl,
              })}
              mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
              fitMarkersOnMarkerChange={
                typeof fitMarkersOnMarkerChange === 'undefined'
                  ? true
                  : fitMarkersOnMarkerChange
              }
              mapboxOptions={{
                minZoom: !minZoom ? 0 : minZoom,
                scrollZoom: allowScrollZoom,
                dragPan: !disableDragging,
                trackResize: true,
                /* Instead, adding a custom attribution in `afterMount` */
                attributionControl: false,
                style: tileURL || MAP_STYLE_URL_PRIMARY,
                transformRequest: this.transformMapboxRequest,
              }}
              zoomWhenPositioningToPoint={zoomWhenPositioningToPoint}
              fitBoundsOptions={fitBoundsOptions}
              afterMount={this.afterMount}
              data-hc-name={dataHcName}
              onZoomStart={handleZoomStart}
              onMoveStart={handleMoveStart}
              onZoom={handleZoom}
              onZoomEnd={handleZoomEnd}
              onMoveEnd={this.onMoveEnd}
              onClick={handleMapClick}
              mapPosition={setMapLocation}
              resolveFallbackLocation={
                shouldGetGeolocationFromDevice
                  ? fetchUserLocationFromDevice
                  : fetchUserLocationViaIP.bind(this, GEOLOCATION_ENDPOINT)
              }
              /* If the above promises are rejected, go to SF */
              hardcodedFallbackLocation={MAP_FALLBACK_LOCATION}
              onUserLocationError={(error) => {
                console.warn(error);
                /* If we attempted to get location from device but user failed to give permission,
                 * attempt to get location via IP.  At this point, map will already be positioned
                 * to hardcoded fallback location.  If we successfully get IP location, reposition again */
                if (
                  shouldGetGeolocationFromDevice &&
                  !this.attemptedToGetIPLocationAfterDeviceLocationFailure
                ) {
                  this.attemptedToGetIPLocationAfterDeviceLocationFailure =
                    true;
                  fetchUserLocationViaIP(GEOLOCATION_ENDPOINT).then(
                    ([latitude, longitude]) => {
                      this.mapApi &&
                        this.mapApi.setMapPosition([latitude, longitude]);
                    }
                  );
                  /* Returns 200 status for invalid user location (seen during local development) */
                } else if ((error as any).status !== 200) {
                  reportToSentry(error);
                }
              }}
            >
              {
                /* Map heatmap layers control */
                hasMapLayersControl && (
                  <MapLayersControlContainer
                    mapAriaId={uid}
                    theme={theme}
                    fitPopupPadding={fitPopupPadding}
                    defaultActiveLayerGroup={defaultActiveLayerGroup}
                    onActiveLayerChange={this.onActiveLayerChange}
                    handleToggleMarkers={handleToggleMarkers}
                    autoHighlightSchoolDistrictName={
                      autoHighlightSchoolDistrictName
                    }
                    handleAutoActiveSchoolDistrictActivate={
                      handleAutoActiveSchoolDistrictActivate
                    }
                    beneathLayerId={
                      markerFeatures
                        ? MAPBOX_LAYER_IDS.MARKERS
                        : MAPBOX_LAYER_IDS.SCHOOL_SYMBOLS
                    }
                    MapNotification={MapNotification}
                    showPropertiesDefaultOn={showPropertiesDefaultOn}
                    isShowingMarkers={isShowingMarkers}
                    useFloatingLayerGroupsControl={
                      useFloatingLayerGroupsControl
                    }
                    isShowingBottomLayerGroupsControl={
                      isShowingBottomLayerGroupsControl
                    }
                    allowShowingBottomControl={allowShowingBottomControl}
                    closeBottomLayerControlTrigger={
                      closeBottomLayerControlTrigger
                    }
                    handleCloseBottomGroupsControl={
                      handleCloseBottomGroupsControl
                    }
                  />
                )
              }
              {markerFeatures && markerImagesLoaded && (
                <CobrandedStyles>
                  {({ mapPinsColor, mapPinsVisitedColor }) => (
                    <MapHTMLMarkersLayer
                      mapPinsColor={mapPinsColor}
                      mapPinsVisitedColor={mapPinsVisitedColor}
                      layerId={MAPBOX_LAYER_IDS.MARKERS}
                      markerFeatures={markerFeatures!}
                      noPopupForAddressSlug={noPopupForAddressSlug}
                      shouldFitMarkersInMap={shouldFitMarkersInMap}
                      dataChangedTrigger={debouncedMarkerFeaturesChangedTrigger}
                      hidePopupOnDataChanged={hidePopupOnMarkerFeaturesChanged}
                      markerImageUrls={markerImageUrls}
                      PopupContentNode={
                        MarkerPopup ? this.popupContentNode : undefined
                      }
                      fitPopupPadding={fitPopupPadding}
                      onMarkerClick={this.handleMarkerClick}
                      autoOpenPopupForMarkerFeature={
                        localAutoOpenPopupForMarkerFeature
                      }
                      onMarkerMouseEnter={handleMarkerFeatureHover}
                      onMarkerMouseLeave={handleMarkerFeatureHover}
                      onPopupClose={handleMarkerFeaturePopupClose}
                      shouldPutDataHCNameOnMarkers={
                        shouldPutDataHCNameOnMarkers
                      }
                    />
                  )}
                </CobrandedStyles>
              )}
              {temporaryMarkers &&
                temporaryMarkers.map((marker) => (
                  <Marker
                    key={marker.uId}
                    position={[marker.lat, marker.lng]}
                    fitInMap={shouldFitMarkersInMap}
                    Icon={
                      <TemporaryMapMarkerIcon
                        theme={theme}
                        /* See `buildMarker` in maps.utils for marker definition */
                        label={marker.label ?? ''}
                        addressSlug={
                          getIsTemporaryMarker(marker) ? marker.addressSlug : ''
                        }
                        isPulsing={
                          getIsTemporaryMarker(marker) && !!marker.isPulsing
                        }
                      />
                    }
                  />
                ))}
              {pulseLocations && (
                <MapMarkerPulseLayer
                  layerId="pulse-layer"
                  imageUrl={pulseImage}
                  positions={pulseLocations}
                  positionsUpdateTrigger={pulseLocations
                    .map(
                      (location) => `${location.latitude}${location.longitude}`
                    )
                    .join('')}
                  beneathLayerId={MAPBOX_LAYER_IDS.MARKERS}
                />
              )}
              {placeBoundaryGeoJSON && (
                <Layer
                  layerId="place-boundary-layer"
                  type="line"
                  minZoom={4}
                  paint={{
                    'line-width': 3,
                    'line-color': mapPlaceBoundaryLayerLineColor,
                  }}
                  Source={
                    <LayerSource
                      sourceId="place-boundary-layer-source"
                      type="geojson"
                      data={{
                        type: 'FeatureCollection',
                        features: [
                          {
                            type: 'Feature',
                            geometry: placeBoundaryGeoJSON,
                            properties: {},
                          },
                        ],
                      }}
                      minZoom={4}
                      maxZoom={22}
                      dataChangedTrigger={placeBoundaryUpdateTrigger}
                    />
                  }
                  beneathLayerId={MAPBOX_LAYER_IDS.MARKERS}
                />
              )}
              {isShowingMLSCoverageLayer && (
                <MapMLSCoverageLayer
                  beneathLayerId={MAPBOX_LAYER_IDS.MARKERS}
                  onMLSCoverageChange={onMLSCoverageChange}
                />
              )}
              {(typeof showZoomControls === 'undefined' ||
                showZoomControls) && (
                <MapNavControl
                  /* Do not change this, as it determines which classname is applied to the zoom controls, which is then
                   * keyed on in our CSS to move the zoom controls on certain pages */
                  location={zoomButtonLocation || 'top-right'}
                  onZoomIn={handleClickZoomIn}
                  onZoomOut={handleClickZoomOut}
                />
              )}
              {
                /* Additional sources to add to the Mapbox map instance */
                customSources as React.ReactNode /* TODO: remove typecast once @hc/mapboxgl is upgraded to React 18 */
              }
              {
                /* Additional layers to render on the map */
                (!customSources || customSourcesHaveMounted) &&
                  (customLayers as React.ReactNode) /* TODO: remove typecast once @hc/mapboxgl is upgraded to React 18 */
              }
              {customPopup}
            </Maps>
          )}
        </AccessibleElementUniqueId>
        {
          /* The popup for the clicked-upon <MapMarkersLayer> feature.  Note that the passed-in `MarkerPopup`
           * component will have to handle `property` being `null` while it's loading if fetching `popupData` externally */
          MarkerPopup &&
            localMarkerPopupData &&
            createPortal(
              React.cloneElement(MarkerPopup, {
                key: localMarkerPopupData.slug,
                theme,
                handleClick: handlePropertyClick,
                propertyData: localMarkerPopupData,
              }),
              this.popupContentNode
            )
        }
        {/* Preload necessary marker images upfront for quicker layer display */}
        <ImagePreloader
          alt={
            localMarkerPopupData
              ? `Photos of ${localMarkerPopupData.fullAddress}`
              : 'Property photo'
          }
          urls={[
            pulseImage,
            ...(markerImageUrls ? values(markerImageUrls) : []),
            ...(hasMapLayersControl
              ? values(SCHOOL_MARKER_IMAGE_URL_BY_ID)
              : []),
          ]}
          onLoad={this.handleMarkerImagesLoaded}
        />
      </React.Fragment>
    );
  }
}

const ThemedMapsComponent = themr('MapsComponent', defaultTheme)(MapsComponent);

const mapStateToProps = (state: ReduxState) => ({
  mapPlaceBoundaryLayerLineColor: getMapPlaceBoundaryLayerLineColor(state),
});

export default connect(mapStateToProps)(
  renderOnMountUntyped(ThemedMapsComponent)
);
