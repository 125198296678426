import React, { HTMLProps } from "react";
import { Theme } from "@friendsofreactjs/react-css-themr";
import scrollIntoView from 'scroll-into-view';

interface Props extends HTMLProps<HTMLDivElement> {
  isActive?: boolean;
  timing?: number;
  topPosition?: number;
  theme?: Theme;
};

/**
* Wrapper Component used to scroll into view wrapped components when
* is active.
*/
export default class ScrollActiveElementIntoView extends React.Component<Props> {
  static defaultProps = {
    timing: 300,
    topPosition: 0.5,
  }

  scrollToElement?: JSX.Element;

  setScrollRef = (el) => {
    this.scrollToElement = el;
  }

  componentDidMount(): void {
    if (this.props.isActive) {
      this.scrollToActiveElement();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.props.isActive) {
      this.scrollToActiveElement();
    }
  }

  scrollToActiveElement = () => {
    const itemToScroll = this.scrollToElement;
    scrollIntoView(itemToScroll, {
      time: this.props.timing,
      align: {
        top: this.props.topPosition,
      },
    });
  }

  render() {
    const { children, theme } = this.props;
    return <div className={theme?.ScrollActiveElementIntoView} ref={this.setScrollRef}>{children}</div>;

  }
}


