import { createSelector } from 'reselect';

import { ReduxState } from '@client/store/types/redux-state';
import { AuthState } from '@client/store/reducers/auth.reducer';

export function getInviteState(state: ReduxState) {
  return state.invite;
}

export const getInviteId = createSelector(
  getInviteState,
  (inviteState) => inviteState.inviteId
);

export const getInviteEmail = createSelector(
  getInviteState,
  (inviteState) => inviteState.email
);

const getBuyerEmailOptedIn = createSelector(
  getInviteState,
  (inviteState) => inviteState.buyerEmailOptedIn
);

export const getLastSearchPlaceDesc = createSelector(
  getInviteState,
  (inviteState) => inviteState.lastSearch?.place_desc || ''
);

const getHomeownerSlug = createSelector(
  getInviteState,
  (inviteState) => inviteState.homeownerSlug
);

const getAcceptedTermsOfUseDate = createSelector(
  getInviteState,
  (inviteState) => inviteState.touAcceptedDate
);

export const getInvitedUserInfo = createSelector(
  getInviteState,
  (inviteState) => ({
    firstName: inviteState.firstName,
    lastName: inviteState.lastName,
    email: inviteState.email,
    phone: inviteState.phone,
  })
);

/** Copy from auth.selectors to avoid circular dependency */
function getAuthState(state: ReduxState): AuthState {
  return state.auth;
}

const getCurrentUser = createSelector(getAuthState, (auth) => {
  return auth.user;
});

export const getShowInviteModalForBuyer = createSelector(
  getCurrentUser,
  getInviteId,
  getBuyerEmailOptedIn,
  getHomeownerSlug,
  (currentUser, inviteId, buyerEmailOptedIn, homeownerSlug) => {
    const userIsNotLoggedIn = !currentUser;
    const userComingFromInvite = !!inviteId;
    const isNotHomeowner = !homeownerSlug;
    const isNotEmailOptedIn = !buyerEmailOptedIn;

    return (
      userIsNotLoggedIn &&
      userComingFromInvite &&
      isNotHomeowner &&
      isNotEmailOptedIn
    );
  }
);

export const getShowInviteModalForSeller = createSelector(
  getCurrentUser,
  getInviteId,
  getHomeownerSlug,
  getAcceptedTermsOfUseDate,
  (currentUser, inviteId, homeownerSlug, acceptedTermsOfUseDate) => {
    const userIsNotLoggedIn = !currentUser;
    const userComingFromInvite = !!inviteId;
    const isHomeowner = !!homeownerSlug;
    const hasNotAcceptedTermsOfUse = !acceptedTermsOfUseDate;

    return (
      userIsNotLoggedIn &&
      userComingFromInvite &&
      isHomeowner &&
      hasNotAcceptedTermsOfUse
    );
  }
);
