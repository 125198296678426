import { createSelector } from 'reselect';
import { ReduxState } from '@client/store/types/redux-state';

export function getLegalContentState(state: ReduxState) {
  return state.legalContent;
}

export const getLegalContentPrivacyPolicy = createSelector(
  getLegalContentState,
  (legalContentState) => legalContentState.privacyPolicy
);

export const getLegalContentPrivacyPolicyCA = createSelector(
  getLegalContentState,
  (legalContentState) => legalContentState.privacyPolicyCA
);

export const getLegalContentTermsOfUse = createSelector(
  getLegalContentState,
  (legalContentState) => legalContentState.termsOfUse
);
