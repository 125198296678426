/* Ported from react-input-range
 * https://github.com/davidchin/react-input-range */

import React from 'react';

type Props = {
  classNames: { [key: string]: string };
  formatLabel?: (children: any, type: string) => any;
  type: string;
  children?: React.ReactNode;
};

const Label: React.FC<Props> = (props) => {
  const labelValue = props.formatLabel
    ? props.formatLabel(props.children, props.type)
    : props.children;

  return (
    <span className={props.classNames[`${props.type}Label`]}>
      <span className={props.classNames.labelContainer}>{labelValue}</span>
    </span>
  );
};

export default Label;
