import { RouteType } from '@src/redux-saga-router-plus/types';

export const SET_ATTEMPTED_ROUTE = 'SET_ATTEMPTED_ROUTE';
export const CLEAR_ATTEMPTED_ROUTE = 'CLEAR_ATTEMPTED_ROUTE';

type SetAttemptedRoute = {
  type: typeof SET_ATTEMPTED_ROUTE;
  payload: RouteType;
};
export const setAttemptedRoute = (routeObj: RouteType): SetAttemptedRoute => ({
  type: SET_ATTEMPTED_ROUTE,
  payload: routeObj,
});

type ClearAttemptedRoute = {
  type: typeof CLEAR_ATTEMPTED_ROUTE;
};
export const clearAttemptedRoute = (): ClearAttemptedRoute => ({
  type: CLEAR_ATTEMPTED_ROUTE,
});

export type AttemptedRouteAction = SetAttemptedRoute | ClearAttemptedRoute;
