import { connect } from 'react-redux';

import {
  reportLenderCtaSeeLoanOptionsClick,
  reportEvent,
} from '@client/store/actions/analytics.actions';
import LenderPropertyCTAChase from '@client/components/cobrand/chase/LenderPropertyCTAChase';
import { getUserBuyingPower } from '@client/store/selectors/auth.selectors';
import { chaseCTAClick } from '@client/store/actions/cobranding.actions';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { getJSBridgePropertyTypeForPropertyType } from '@client/store/sagas/chase-integration.saga';

import { ChaseCTAEventParams } from '@client/store/types/chase-integration-data';

const mapStateToProps = (state) => {
  return {
    userBuyingPower: getUserBuyingPower(state),
    isBuyingPowerFeatureActivated: getIsFeatureEnabled('buying_power')(state),
    getJSBridgePropertyTypeForPropertyType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleClick: (eventParams: ChaseCTAEventParams) => {
    dispatch(chaseCTAClick(eventParams));
  },
  handleReportOffMarketSeeLoanProductsClick: () => {
    dispatch(reportEvent('click_pdp_off_market_see_loan_products'));
  },
  handleReportLenderCtaSeeLoanOptionsClick: (slug: string) => {
    dispatch(reportLenderCtaSeeLoanOptionsClick(slug));
  },
  handleReportLenderCtaSetupBuyingPowerClick: (slug: string) => {
    dispatch(reportEvent('click_lender_cta_setup_buying_power', '', { slug }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LenderPropertyCTAChase);
