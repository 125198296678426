import { call } from 'redux-saga/effects';

import { UNSUBSCRIBE_QUERY_STRING } from '@client/routes/constants';
import { consumerApiClient } from '@client/services/consumer-api-client';

export default function* routeUnsubscribe({ query }) {
  /* Only send the request if it will contain a valid invite id */
  if (query[UNSUBSCRIBE_QUERY_STRING]) {
    yield call(
      [consumerApiClient, consumerApiClient.unsubscribeFromEmailNotifications],
      query[UNSUBSCRIBE_QUERY_STRING]
    );
  } else {
    throw new Error(
      `Unsubscribe page hit without a ${UNSUBSCRIBE_QUERY_STRING} query param`
    );
  }
}
