import { FilterKey } from '../filter-constants';
import { LayerGroup } from '../map-constants';

export enum SpecialUserType {
  Restricted = 'restricted',
}

export enum SectionId {
  HomeownerAutocompleteSearch = 'homeowner-autocomplete-search',
  PropertyCardRentalEstimate = 'property-card-rental-estimate',
  AvmFactors = 'avm-factors',
  PdpRentalAvm = 'pdp-rental-avm',
  PdpComps = 'pdp-comps',
  PdpCrime = 'pdp-crime',
  PdpForecast = 'pdp-forecast',
  PdpLocalActivities = 'pdp-local-activities',
  PdpMortgageCalculator = 'pdp-mortgage-calculator',
  PdpNeighborhoodInsights = 'pdp-neighborhood_insights',
  PdpSaleHistory = 'pdp-sale-history',
  PdpSchools = 'pdp-schools',
  PdpHomeownerUpsellAd = 'pdp-homeowner-upsell-ad',
}

export type LockedComponentType<T> = SectionId | LayerGroup | FilterKey | T;

export interface LockedComponent<T> {
  [SpecialUserType.Restricted]: LockedComponentType<T>[];
}
