import classNames from 'classnames';
import React, { Component } from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import defaultTheme from '@client/css-modules/MinOnlyButtons.css';
import { FilterKey } from '@client/store/filter-constants';

export type MinOnlyButtonsProps = {
  onChange: (value: [number | null, null]) => void;
  setValueForStateFormatter: () => void;
  getValueForControlFormatter: (val: number | string) => number;
  handleReportValueSelection: (value: string | number | null) => void;
  labelFormatter: (val: number) => string;
  filterKey: FilterKey;
  values: (number | string)[];
  currentValues: [number | null, number | null];
  theme: Theme;
};

type State = {
  selected: number | null;
};

class MinOnlyButtons extends Component<MinOnlyButtonsProps, State> {
  state: State = {
    selected: this.props.currentValues[0],
  };

  handleClick = (value: number | null): void => {
    const { onChange, handleReportValueSelection } = this.props;
    onChange([value, null]);
    /* Updating control value via local state for performance */
    this.setState({ selected: value });
    handleReportValueSelection(value);
  };

  render() {
    const { theme, labelFormatter, values, getValueForControlFormatter } =
      this.props;
    const { selected } = this.state;

    return (
      <>
        {values.map((rawVal) => {
          const val = getValueForControlFormatter(rawVal);
          const isSelected = selected === val;

          return (
            <button
              type="button"
              aria-label={`${val} or more`}
              aria-pressed={isSelected}
              className={classNames(theme.Button, {
                [theme.active]: isSelected,
              })}
              onClick={() => this.handleClick(val)}
              key={val}
              onKeyDown={onEnterOrSpaceKey(() => this.handleClick(val))}
            >
              {labelFormatter(val)}
            </button>
          );
        })}
        <button
          type="button"
          aria-label="All"
          className={classNames(theme.Button, { [theme.active]: !selected })}
          onClick={() => this.handleClick(null)}
          onKeyDown={onEnterOrSpaceKey(() => this.handleClick(null))}
        >
          All
        </button>
      </>
    );
  }
}

const ThemedMinOnlyButtons = themr(
  'MinOnlyButtons',
  defaultTheme
)(MinOnlyButtons);
export default ThemedMinOnlyButtons;
