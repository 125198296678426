import React from 'react';
import { useSelector } from 'react-redux';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import defaultTheme from '@client/css-modules/BuyingPowerPill.css';
import { dollarsFormatter } from '@client/utils/formatter.utils';
import { getCobrandDisplayName } from '@client/store/selectors/cobranding.selectors';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type Props = {
  theme: Theme;
  value: number;
  onClick?: () => void;
};

/**
 * A simple pill displaying a user's buying power
 */
const BuyingPowerPill: React.FunctionComponent<Props> = ({
  theme,
  value,
  onClick,
}) => {
  const cobrandDisplayName = useSelector(getCobrandDisplayName);
  const { activeFilterPillColor } = useCobrandStyles();
  return (
    <span
      className={theme.BuyingPowerPill}
      style={{ background: activeFilterPillColor }}
      onClick={onClick}
    >
      <div className={theme.Value}>Up to {dollarsFormatter(value)}</div>
      <div className={theme.Subtext}>
        {cobrandDisplayName
          ? `${cobrandDisplayName} buying power`
          : 'Buying power'}
      </div>
    </span>
  );
};

export default themr('BuyingPowerPillThemed', defaultTheme)(BuyingPowerPill);
