import React from 'react';
import classNames from 'classnames';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import defaultTheme from '@client/css-modules/BasePill.css';

export type BasePillProps = {
  className?: string;
  theme: Theme;
  children?: React.ReactNode;
};

export const BasePill = ({ theme, className, children }: BasePillProps) => {
  return <div className={classNames(theme.Pill, className)}>{children}</div>;
};

export const ThemedBasePill = themr('ThemedBasePill', defaultTheme)(BasePill);
export default ThemedBasePill;
