import {
  SavedSearchAPIResponse,
  SavedSearchAPIFields,
} from '@client/store/types/saved-searches';
import { PARENT_EVENTS } from '../analytics-constants';
import { removeNullKeys } from '@client/utils/object.utils';

export const CREATE_SAVED_SEARCH = 'CREATE_SAVED_SEARCH';
export const CREATE_SAVED_SEARCH_SUCCESS = 'CREATE_SAVED_SEARCH_SUCCESS';
export const ENSURE_LOGIN_THEN_CREATE_SAVED_SEARCH =
  'ENSURE_LOGIN_THEN_CREATE_SAVED_SEARCH';
export const UPDATE_SAVED_SEARCH = 'UPDATE_SAVED_SEARCH';
export const UPDATE_SAVED_SEARCH_SUCCESS = 'UPDATE_SAVED_SEARCH_SUCCESS';
export const FETCH_SAVED_SEARCHES = 'FETCH_SAVED_SEARCHES';
export const FETCH_SAVED_SEARCHES_IF_NECESSARY =
  'FETCH_SAVED_SEARCHES_IF_NECESSARY';
export const FETCH_SAVED_SEARCHES_SUCCESS = 'FETCH_SAVED_SEARCHES_SUCCESS';
export const FETCH_SAVED_SEARCHES_ERROR = 'FETCH_SAVED_SEARCHES_ERROR';
export const DELETE_SAVED_SEARCH = 'DELETE_SAVED_SEARCH';
export const DELETE_SAVED_SEARCH_ERROR = 'DELETE_SAVED_SEARCH_ERROR';
export const SELECT_SAVED_SEARCH = 'SELECT_SAVED_SEARCH';
export const DELETE_SAVED_SEARCH_SUCCESS = 'DELETE_SAVED_SEARCH_SUCCESS';
export const CLEAR_CURRENT_SAVED_SEARCH = 'CLEAR_CURRENT_SAVED_SEARCH';
export const CREATE_SAVED_SEARCH_ERROR = 'CREATE_SAVED_SEARCH_ERROR';
export const CLEAR_SAVED_SEARCH_STATE = 'CLEAR_SAVED_SEARCH_STATE';

type EnsureLoggedInThenCreateSavedSearch = {
  type: typeof ENSURE_LOGIN_THEN_CREATE_SAVED_SEARCH;
  payload: { location: 'map' | 'list' | 'filters' };
};
export const ensureLoggedInThenCreateSavedSearch = (
  location: 'map' | 'list' | 'filters'
): EnsureLoggedInThenCreateSavedSearch => ({
  type: ENSURE_LOGIN_THEN_CREATE_SAVED_SEARCH,
  payload: { location },
});

type CreateSavedSearch = {
  type: typeof CREATE_SAVED_SEARCH;
};
export const createSavedSearch = (): CreateSavedSearch => ({
  type: CREATE_SAVED_SEARCH,
});

type SavedSearchEvent = Partial<SavedSearchAPIFields>;
export type CreateSavedSearchSuccess = {
  type: typeof CREATE_SAVED_SEARCH_SUCCESS;
  meta: {
    analytics: {
      event: 'click_save_search_success';
      props: {
        saved_search_id: number;
        saved_search_state: SavedSearchEvent;
      };
    };
  };
  payload: {
    response: SavedSearchAPIResponse;
  };
};
export const createSavedSearchSuccess = (
  response: SavedSearchAPIResponse
): CreateSavedSearchSuccess => ({
  type: CREATE_SAVED_SEARCH_SUCCESS,
  meta: {
    analytics: {
      event: 'click_save_search_success',
      props: removeNullKeys({
        saved_search_id: response.search_id,
        /* All properties that are returned by the create saved-search API (POST /users/{id}/searches)
         * should be included here so that LO Direct LO-user can see all client search criteria */
        saved_search_state: {
          name: response.search.name,
          min_12mo_risk_of_decline: response.search.min_12mo_risk_of_decline,
          max_12mo_risk_of_decline: response.search.max_12mo_risk_of_decline,
          min_avm_fsd: response.search.min_avm_fsd,
          max_avm_fsd: response.search.max_avm_fsd,
          min_avm_lower_price: response.search.min_avm_lower_price,
          max_avm_lower_price: response.search.max_avm_lower_price,
          min_avm_price: response.search.min_avm_price,
          max_avm_price: response.search.max_avm_price,
          min_avm_upper_price: response.search.min_avm_upper_price,
          max_avm_upper_price: response.search.max_avm_upper_price,
          max_baths: response.search.max_baths,
          min_baths: response.search.min_baths,
          max_beds: response.search.max_beds,
          min_beds: response.search.min_beds,
          max_best_high_school_percentile:
            response.search.max_best_high_school_percentile,
          min_best_high_school_percentile:
            response.search.min_best_high_school_percentile,
          min_best_middle_school_percentile:
            response.search.min_best_middle_school_percentile,
          max_best_middle_school_percentile:
            response.search.max_best_middle_school_percentile,
          min_best_primary_school_percentile:
            response.search.min_best_primary_school_percentile,
          max_best_primary_school_percentile:
            response.search.max_best_primary_school_percentile,
          min_confident_list_to_avm_price:
            response.search.min_confident_list_to_avm_price,
          max_confident_list_to_avm_price:
            response.search.max_confident_list_to_avm_price,
          min_confident_rental_avm_price:
            response.search.min_confident_rental_avm_price,
          max_confident_rental_avm_price:
            response.search.max_confident_rental_avm_price,
          min_confident_rental_yield:
            response.search.min_confident_rental_yield,
          max_confident_rental_yield:
            response.search.max_confident_rental_yield,
          max_crime_county_percentile:
            response.search.max_crime_county_percentile,
          min_crime_county_percentile:
            response.search.min_crime_county_percentile,
          min_crime_national_percentile:
            response.search.min_crime_national_percentile,
          max_crime_national_percentile:
            response.search.max_crime_national_percentile,
          min_geo_precision: response.search.min_geo_precision,
          max_geo_precision: response.search.max_geo_precision,
          min_hpi: response.search.min_hpi,
          max_hpi: response.search.max_hpi,
          max_list_age_days: response.search.max_list_age_days,
          min_list_age_days: response.search.min_list_age_days,
          max_list_price: response.search.max_list_price,
          min_list_price: response.search.min_list_price,
          min_list_price_rental_yield:
            response.search.min_list_price_rental_yield,
          max_list_price_rental_yield:
            response.search.max_list_price_rental_yield,
          min_list_to_avm_price: response.search.min_list_to_avm_price,
          max_list_to_avm_price: response.search.max_list_to_avm_price,
          min_lot_area: response.search.min_lot_area,
          max_lot_area: response.search.max_lot_area,
          max_market_grade: response.search.max_market_grade,
          min_market_grade: response.search.min_market_grade,
          min_parking_spots: response.search.min_parking_spots,
          max_parking_spots: response.search.max_parking_spots,
          min_price_per_sqft_50: response.search.min_price_per_sqft_50,
          max_price_per_sqft_50: response.search.max_price_per_sqft_50,
          min_rental_avm_fsd: response.search.min_rental_avm_fsd,
          max_rental_avm_fsd: response.search.max_rental_avm_fsd,
          min_rental_avm_price: response.search.min_rental_avm_price,
          max_rental_avm_price: response.search.max_rental_avm_price,
          min_rental_yield: response.search.min_rental_yield,
          max_rental_yield: response.search.max_rental_yield,
          min_sale_price: response.search.min_sale_price,
          max_sale_price: response.search.max_sale_price,
          max_sqft: response.search.max_sqft,
          min_sqft: response.search.min_sqft,
          max_year_built: response.search.max_year_built,
          min_year_built: response.search.min_year_built,
          mls_state_group: response.search.mls_state_group,
          property_type: response.search.property_type,
          ne_lat: response.search.ne_lat,
          ne_lng: response.search.ne_lng,
          sw_lat: response.search.sw_lat,
          sw_lng: response.search.sw_lng,
          place_id: response.search.place_id,
        },
      }),
    },
  },
  payload: {
    response,
  },
});

export const reportClickSaveSearchCTA = (
  section: 'map' | 'list' | 'filters',
  { geom, ...searchDataWithoutGeom }: Partial<SavedSearchAPIFields>
) => ({
  type: 'REPORT_CLICK_SAVE_SEARCH_CTA',
  meta: {
    analytics: {
      event:
        section === 'map'
          ? 'click_save_search_cta_map_view'
          : section === 'list'
          ? 'click_save_search_cta_list_view'
          : 'click_save_search_cta_mobile_filters',
      parentEvent: PARENT_EVENTS.CLICK_SAVE_SEARCH,
      props: {
        saved_search_state: searchDataWithoutGeom,
      },
    },
  },
});

type UpdateSavedSearch = {
  type: typeof UPDATE_SAVED_SEARCH;
};
export const updateSavedSearch = (): UpdateSavedSearch => ({
  type: UPDATE_SAVED_SEARCH,
});

type UpdateSavedSearchSuccess = {
  type: typeof UPDATE_SAVED_SEARCH_SUCCESS;
  payload: {
    search: SavedSearchAPIResponse;
  };
};
export const updateSavedSearchSuccess = (
  search: SavedSearchAPIResponse
): UpdateSavedSearchSuccess => ({
  type: UPDATE_SAVED_SEARCH_SUCCESS,
  payload: {
    search,
  },
});

type FetchSavedSearchesIfNecessary = {
  type: typeof FETCH_SAVED_SEARCHES_IF_NECESSARY;
};
export const fetchSavedSearchesIfNecessary =
  (): FetchSavedSearchesIfNecessary => ({
    type: FETCH_SAVED_SEARCHES_IF_NECESSARY,
  });

type FetchSavedSearches = {
  type: typeof FETCH_SAVED_SEARCHES;
};
export const fetchSavedSearches = (): FetchSavedSearches => ({
  type: FETCH_SAVED_SEARCHES,
});

type FetchSavedSearchesSuccess = {
  type: typeof FETCH_SAVED_SEARCHES_SUCCESS;
  payload: {
    data: SavedSearchAPIResponse[];
  };
};
export const fetchSavedSearchesSuccess = (
  data: SavedSearchAPIResponse[]
): FetchSavedSearchesSuccess => ({
  type: FETCH_SAVED_SEARCHES_SUCCESS,
  payload: {
    data,
  },
});

type FetchSavedSearchesError = {
  type: typeof FETCH_SAVED_SEARCHES_ERROR;
  error: any;
};
export const fetchSavedSearchesError = (error): FetchSavedSearchesError => ({
  type: FETCH_SAVED_SEARCHES_ERROR,
  error,
});

export type DeleteSavedSearch = {
  type: typeof DELETE_SAVED_SEARCH;
  payload: {
    searchId: number;
  };
};
export const deleteSavedSearch = (searchId: number): DeleteSavedSearch => ({
  type: DELETE_SAVED_SEARCH,
  payload: {
    searchId,
  },
});

type DeleteSavedSearchSuccess = {
  type: typeof DELETE_SAVED_SEARCH_SUCCESS;
  payload: {
    searchId: number;
  };
};
export const deleteSavedSearchSuccess = (
  searchId: number
): DeleteSavedSearchSuccess => ({
  type: DELETE_SAVED_SEARCH_SUCCESS,
  payload: {
    searchId,
  },
});

type DeleteSavedSearchError = {
  type: typeof DELETE_SAVED_SEARCH_ERROR;
  meta: {
    toast: {
      message: string;
    };
  };
};
export const deleteSavedSearchError = (
  message: string
): DeleteSavedSearchError => ({
  type: DELETE_SAVED_SEARCH_ERROR,
  meta: {
    toast: {
      message,
    },
  },
});

export type SelectSavedSearch = {
  type: typeof SELECT_SAVED_SEARCH;
  meta: {
    analytics: {
      event: string;
    };
  };
  payload: {
    searchId: number;
  };
};
export const selectSavedSearch = (
  searchId: number,
  eventName: string
): SelectSavedSearch => ({
  type: SELECT_SAVED_SEARCH,
  meta: {
    analytics: {
      event: eventName,
    },
  },
  payload: {
    searchId,
  },
});

type ClearCurrentSavedSearch = {
  type: typeof CLEAR_CURRENT_SAVED_SEARCH;
};
export const clearCurrentSavedSearch = (): ClearCurrentSavedSearch => ({
  type: CLEAR_CURRENT_SAVED_SEARCH,
});

type CreateSavedSearchError = {
  type: typeof CREATE_SAVED_SEARCH_ERROR;
  payload: {
    message: string;
  };
};
export const createSavedSearchError = (
  message: string
): CreateSavedSearchError => ({
  type: CREATE_SAVED_SEARCH_ERROR,
  payload: {
    message,
  },
});

type ClearSavedSearchState = {
  type: typeof CLEAR_SAVED_SEARCH_STATE;
};
export const clearSavedSearchState = (): ClearSavedSearchState => ({
  type: CLEAR_SAVED_SEARCH_STATE,
});

export type SavedSearchAction =
  | EnsureLoggedInThenCreateSavedSearch
  | CreateSavedSearch
  | CreateSavedSearchSuccess
  | UpdateSavedSearch
  | UpdateSavedSearchSuccess
  | FetchSavedSearchesIfNecessary
  | FetchSavedSearches
  | FetchSavedSearchesSuccess
  | FetchSavedSearchesError
  | DeleteSavedSearch
  | DeleteSavedSearchSuccess
  | DeleteSavedSearchError
  | SelectSavedSearch
  | ClearCurrentSavedSearch
  | CreateSavedSearchError
  | ClearSavedSearchState;
