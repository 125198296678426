import React from 'react';
import { themr } from '@friendsofreactjs/react-css-themr';

import defaultTheme from '@client/css-modules/AvmBreakdownAvmValue.css';
import { AvmBreakdownAvmValueProps } from '@client/store/types/cobranded-components/avm-breakdown-avm-value';
import { dollarsFormatter } from '@client/utils/formatter.utils';

/**
 * A branding-specific display of the AVM value, for use inside the AVM breakdown
 */
const AvmBreakdownAvmValue: React.FunctionComponent<
  AvmBreakdownAvmValueProps
> = ({ theme, value }) => {
  return (
    <>
      <div className={theme.AvmLabel} data-hc-name={'avm-label'}>
        This home's estimated value
      </div>
      <div className={theme.AvmBreakdownAvmValue} data-hc-name={'avm-price'}>
        {dollarsFormatter(value)}
      </div>
      <div className={theme.AvmSubtext} data-hc-name={'avm-sub-text'}>
        ComeHome Estimated Value
      </div>
    </>
  );
};

export default themr(
  'AvmBreakdownAvmValueThemed',
  defaultTheme
)(AvmBreakdownAvmValue);
