import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { getActiveModalKey } from '@client/store/selectors/modals.selectors';
import { getAllPropertyPhotosForAddressSlug } from '@client/store/selectors/property-photos.selectors';

import PhotoListModal from '@client/components/PhotoListModal';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { closeModal } from '@client/store/actions/modals.actions';

import { PropertyPhotosSizes } from '@client/store/types/property-photos';
import { NormalizedProperty } from '@client/store/types/property';

type OwnProps = {
  propertyDetails: NormalizedProperty | null;
  photoSize: PropertyPhotosSizes;
  /* photos and shouldHideSavePropertyButton are used when displaying modal in HOAvmBanner */
  photos?: string[];
  shouldHideSavePropertyButton?: boolean;
};

const mapStateToProps = (state, ownProps: OwnProps) => {
  const slug = ownProps.propertyDetails ? ownProps.propertyDetails.slug : null;
  const photos =
    ownProps.photos ??
    getAllPropertyPhotosForAddressSlug(slug)(state, {
      size: ownProps.photoSize,
    });
  return {
    photos,
    isActive: getActiveModalKey(state) === 'photo-list',
    shouldHideSavePropertyButton: !!ownProps.shouldHideSavePropertyButton,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleClose: () => dispatch(closeModal()),
  handlePropertyPhotosWatchClick: (slug) =>
    dispatch(
      reportEvent('click_property_photos_watch', '', { ch_property_id: slug })
    ),
  handlePropertyPhotosUnwatchClick: (slug) =>
    dispatch(
      reportEvent('click_property_photos_unwatch', '', { ch_property_id: slug })
    ),
  handlePropertyPhotosUnwatchConfirmClick: (slug) =>
    dispatch(
      reportEvent('click_property_photos_unwatch_confirm', '', {
        ch_property_id: slug,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoListModal);
