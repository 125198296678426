import React, { FC } from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import defaultTheme from '@client/css-modules/CloseButton.css';
import CloseIcon from '@client/inline-svgs/close';
import classNames from 'classnames';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';

type CloseButtonProps = {
  onClick: () => void;
  theme: Theme;
  className?: string;
  ariaLabel: string;
  dataHcName?: string;
};

const CloseButton: FC<CloseButtonProps> = ({
  theme,
  onClick,
  className,
  ariaLabel,
  dataHcName,
}) => (
  <button
    data-hc-name={dataHcName || 'close-button'}
    type="button"
    aria-label={ariaLabel || 'Close Button'}
    className={classNames(theme.CloseButton, { className: !!className })}
    onClick={onClick}
    onKeyDown={onEnterOrSpaceKey(onClick)}
  >
    <CloseIcon />
  </button>
);

export default themr('CloseButton', defaultTheme)(CloseButton);
