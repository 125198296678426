import { useSelector } from 'react-redux';

import { getCobrandUtils } from '@client/cobrand-settings/cobrand-utils';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';

export function useCobrandUtils() {
  const cobrandId = useSelector(getCobrandId);

  return getCobrandUtils(cobrandId);
}
