export default ({ slug }) => ({
  query: /* GraphQL */ `query AlertCardMlsDetails($slug: String) {
      propertyLookup (id: { slug: $slug}) {
        mls {
          regulations {
            logoOverlay
            photosLogoOverlay
          }
        }
      }
    }`,
  variables: {
    slug
  }
});
