import { connect } from 'react-redux';

import { getActiveModalKey } from '@client/store/selectors/modals.selectors';
import AvmBreakdown from '@client/components/AvmBreakdown';
import {
  getAvmFactors,
  getAvmFSD,
  getAvmValue,
  getIsLoadingAvmDeepDiveData,
  getRegressionsData,
  getIsAvmFactorsLoading,
  getMedianValues,
  getPropertyLocation,
  getDefaultDeepDivesFactorToLaunchWith,
} from '@client/store/selectors/property-details.selectors';
import { getIsApplicationMounted } from '@client/store/selectors/server-rendering-info.selectors';
import { fetchMapLegendBreaks } from '@client/store/actions/map-legend-breaks.actions';
import { getShowLocationInDataDeepDives } from '@client/store/selectors/map-legend-breaks.selectors';
import {
  fetchPropertyAvmFactors,
  fetchPropertyAvmDeepDiveData,
} from '@client/store/actions/property-details.actions';
import { openModal, closeModal } from '@client/store/actions/modals.actions';

const mapStateToProps = (state) => {
  const showLocationInDataDeepDives = getShowLocationInDataDeepDives(state);
  return {
    avmFactors: getAvmFactors(state),
    avmFSD: getAvmFSD(state),
    avmValue: getAvmValue(state),
    isAppMounted: getIsApplicationMounted(state),
    isAvmFactorsLoading: getIsAvmFactorsLoading(state),
    defaultDeepDivesFactorToLaunchWith: getDefaultDeepDivesFactorToLaunchWith(
      state,
      showLocationInDataDeepDives
    ),
    medianValues: getMedianValues(state),
    propertyLocation: getPropertyLocation(state),
    isAvmDeepDiveModalActive: getActiveModalKey(state) === 'data_deep_dives',
    showLocationInDataDeepDives,
    regressionsData: getRegressionsData(state, showLocationInDataDeepDives),
    isLoadingRegressionsData: getIsLoadingAvmDeepDiveData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAvmFactors: () => dispatch(fetchPropertyAvmFactors()),
  fetchPropertyAvmDeepDiveData: () => dispatch(fetchPropertyAvmDeepDiveData()),
  handleShowAvmDeepDiveModal: () => {
    dispatch(openModal('data_deep_dives'));
  },
  handleHideAvmDeepDiveModal: () => dispatch(closeModal()),
  handleGetMapLayerLegendBreaks: (bounds, zoom) => {
    const southWest = bounds.getSouthWest();
    const northEast = bounds.getNorthEast();
    dispatch(
      fetchMapLegendBreaks({ southWest, northEast, zoom, metric: undefined })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AvmBreakdown);
