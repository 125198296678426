export default ({ slug }) => ({
  query: /* GraphQL */ `
    query LoDirectAvmQuery($slug: String) {
      propertyLookup(id: { slug: $slug }) {
        address {
          fullAddress
        }
        avm(qualityMethod: CONSUMER) {
          priceMean
        }
      }
    }
  `,
  variables: {
    slug,
  },
});
