import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LODirectHeaderMobile } from '@client/components/LODirect/LODirectHeaderMobile';
import LODirectMobileError from '@client/components/LODirect/LODirectMobileError';
import { View } from '@client/routes/constants';
import { getIsTabletOrSmallerSize } from '@client/store/selectors/match-media.selectors';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import {
  getCurrentQuery,
  getCurrentView,
} from '@src/redux-saga-router-plus/selectors';

/**
 * LO Direct is currently for desktop/tablet view only
 */
const withLODirectMobileBlock =
  <P extends object>(WrappedComponent: React.ComponentType<P>): React.FC<P> =>
  (props: P) => {
    const currentView = useSelector(getCurrentView);
    const isTabletOrSmallerSize = useSelector(getIsTabletOrSmallerSize);
    // Note: Clients included until clients page is ready
    const loMobileErrorViewList = [
      View.LO_DIRECT_ACTIVITY,
      View.LO_DIRECT_ACCOUNT,
      View.LO_DIRECT_SUPPORT,
    ];

    /**
     * TODO: delete below logic once mobile accounts page is built and removed from the loMobileErrorViewList.
     */
    const dispatch = useDispatch();
    const query = useSelector(getCurrentQuery);
    const isInitSignupTraffic =
      !!query['confirm'] &&
      currentView === View.LO_DIRECT_ACCOUNT &&
      isTabletOrSmallerSize;

    useEffect(() => {
      if (isInitSignupTraffic) {
        /* temporarily reroutes initial signup traffic to avoid isInitSignupTraffic from seeing LODirectMobileError */
        dispatch(routeChange({ view: View.LO_DIRECT_CLIENTS, query }));
      }
    }, []);

    /* prevents flashing error page */
    if (isInitSignupTraffic) {
      return <></>;
    }

    return currentView &&
      isTabletOrSmallerSize &&
      loMobileErrorViewList.includes(currentView) ? (
      <>
        <LODirectHeaderMobile />
        <LODirectMobileError />
      </>
    ) : (
      <WrappedComponent {...props} />
    );
  };

export default withLODirectMobileBlock;
