import { DOMAIN_SCHEME_INITIAL_STATE } from '@client/store/constants';
import { CobrandId, DomainTheme } from '@client/store/types/cobranding';
import { Action } from '@client/store/actions';

export type CobrandingState = {
  id: CobrandId | string;
  domainScheme: DomainTheme;
  /* Set by query param in initial-state.ts. Used to show hide various UX elements. Used to know whether
   * to engage with JSBridge for native app <-> web app communication (via native-app-integration.hooks) */
  isInsideNativeApp: boolean;
  /* Set by query param in initial-state.ts. If true, we assume that the user is entering the app already
   * authenticated, and thus should authenticate the user immediately to log them in. Note that this should
   * be a "silent" authentication if implemented by the lender partner correctly - the user should not be prompted */
  authenticateViaNativeAppOnMount: boolean;
};

const INITIAL_STATE: CobrandingState = {
  id: 'www',
  domainScheme: DOMAIN_SCHEME_INITIAL_STATE,
  isInsideNativeApp: false,
  authenticateViaNativeAppOnMount: false,
};

export default function cobrandingReducer(
  state = INITIAL_STATE,
  action: Action
): CobrandingState {
  switch (action.type) {
    default:
      return state;
  }
}
