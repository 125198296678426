import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import * as Tabs from '@radix-ui/react-tabs';

import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { WatchlistHeaderProps } from '@client/store/types/cobranded-components/watchlist-header';
import theme from '@client/css-modules/WatchListHeader.css';
import {
  WATCHLIST_HEADER_SECTION_CONTENT,
  WATCHLIST_HEADER_SECTION_KEY,
} from '@client/store/constants';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { getWatchlistPageConfig } from '@client/store/selectors/cobranding.selectors';


const WatchListHeader: React.FunctionComponent<WatchlistHeaderProps> = ({
  isWatchListRoute,
  isSavedSearchesRoute,
  goToWatchlist,
  goToSavedSearches,
}) => {
  const {
    watchlistHeaderItemColor,
    savedAndAlertsHeaderActiveItemUnderlineColor,
  } = useCobrandStyles();

  const defaultTabSelected = isWatchListRoute
    ? WATCHLIST_HEADER_SECTION_KEY.SAVED_HOMES
    : WATCHLIST_HEADER_SECTION_KEY.SAVED_SEARCHES;

  const watchlistPageConfig = useSelector(getWatchlistPageConfig);

  return (
    <Tabs.Root
      activationMode='manual'
      defaultValue={defaultTabSelected}
      onValueChange={(value: string) =>
        value === WATCHLIST_HEADER_SECTION_KEY.SAVED_HOMES
          ? goToWatchlist()
          : goToSavedSearches()
      }
      className={theme.WatchListHeader}
    >
      <Tabs.List className={theme.WatchListTopNavTabList}>
        <Tabs.Trigger
          id={`${WATCHLIST_HEADER_SECTION_KEY.SAVED_HOMES}_TAB`}
          value={WATCHLIST_HEADER_SECTION_KEY.SAVED_HOMES}
          data-hc-name="saved-homes-button"
          aria-label={`View ${WATCHLIST_HEADER_SECTION_CONTENT.SAVED_HOMES.title} ${WATCHLIST_HEADER_SECTION_CONTENT.SAVED_HOMES.subTitle}`}
          aria-controls={undefined}
          aria-selected={isWatchListRoute}
          onKeyDown={onEnterOrSpaceKey(goToWatchlist)}
          className={classNames(theme.NavButton, {
            [theme.ActiveNav]: isWatchListRoute,
          })}
        >
          <div
            data-hc-name="title"
            className={theme.Label}
            style={
              isWatchListRoute
                ? {
                    color: watchlistHeaderItemColor,
                  }
                : {}
            }
          >
            {watchlistPageConfig?.savedHomesLabel || WATCHLIST_HEADER_SECTION_CONTENT.SAVED_HOMES.title}
          </div>
          <div className={theme.SubText} data-hc-name="desc">
            {watchlistPageConfig?.savedHomesSubtext || WATCHLIST_HEADER_SECTION_CONTENT.SAVED_HOMES.subTitle}
          </div>
          <div
            data-hc-name="highlight"
            className={theme.ActiveTabLabel}
            style={
              isWatchListRoute
                ? {
                    background: savedAndAlertsHeaderActiveItemUnderlineColor,
                  }
                : {}
            }
          />
        </Tabs.Trigger>
        <Tabs.Trigger
          id={`${WATCHLIST_HEADER_SECTION_KEY.SAVED_SEARCHES}_TAB`}
          value={WATCHLIST_HEADER_SECTION_KEY.SAVED_SEARCHES}
          data-hc-name="saved-searches-button"
          aria-label={`View ${WATCHLIST_HEADER_SECTION_CONTENT.SAVED_SEARCHES.title} ${WATCHLIST_HEADER_SECTION_CONTENT.SAVED_SEARCHES.subTitle}`}
          aria-controls={undefined}
          aria-selected={isSavedSearchesRoute}
          onKeyDown={onEnterOrSpaceKey(goToSavedSearches)}
          className={classNames(theme.NavButton, {
            [theme.ActiveNav]: isSavedSearchesRoute,
          })}
        >
          <div
            data-hc-name="title"
            className={theme.Label}
            style={
              isSavedSearchesRoute
                ? {
                    color: watchlistHeaderItemColor,
                  }
                : {}
            }
          >
            {watchlistPageConfig?.savedSearchesLabel || WATCHLIST_HEADER_SECTION_CONTENT.SAVED_SEARCHES.title}
          </div>
          <div className={theme.SubText} data-hc-name="desc">
            {watchlistPageConfig?.savedSearchesSubtext || WATCHLIST_HEADER_SECTION_CONTENT.SAVED_SEARCHES.subTitle}
          </div>
          <div
            data-hc-name="highlight"
            className={theme.ActiveTabLabel}
            style={
              isSavedSearchesRoute
                ? {
                    background: savedAndAlertsHeaderActiveItemUnderlineColor,
                  }
                : {}
            }
          />
        </Tabs.Trigger>
      </Tabs.List>
    </Tabs.Root>
  );
};

export default WatchListHeader;
