import React from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import ChevronIconStandardGray from '@client/inline-svgs/chevron-standard-gray';
import defaultTheme from '@client/css-modules/ThumbnailCarousel.css';
import ImagePreloader from '@client/components/ImagePreloader';
import { connect } from 'react-redux';
import { getIsMobile } from '@client/store/selectors/match-media.selectors';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { reportCarouselRightArrowClick } from '@client/store/actions/analytics.actions';
import { SVGImageAltProps } from '@client/inline-svgs/types';
import { useAriaAnnouncer } from '@client/context/aria-announcer';

const mapStateToProps = (state) => ({
  shouldPreloadNextImageOnInit: getIsMobile(state),
});

const mapDispatchToProps = (dispatch) => ({
  reportCarouselRightArrowClick: () =>
    dispatch(reportCarouselRightArrowClick()),
});

type Props = {
  /* Class to apply to the root element */
  className?: string;
  /* Array of image URLs to render inside of carousel */
  urls: string[];
  /* Whether to always show controls */
  alwaysShowControls?: boolean;
  shouldPreloadNextImageOnInit: boolean;
  style?: { [_: string]: any };
  onClick?: (e: React.MouseEvent<HTMLElement>, currentIndex: number) => void;
  onMouseLeave?: () => void;
  onMouseEnter?: () => void;
  onClickNext?: (nextIndex: number) => void;
  onClickPrev?: (nextIndex: number) => void;
  reportCarouselRightArrowClick: () => void;
  title?: string;
  theme: Theme;
  index?: number;
  alt?: string;
  id?: string;
  overlay?: string | null;
  fullAddress?: string | null;
  ariaAnnouncer?: (message: JSX.Element | string) => void;
};

type State = {
  currentIndex: number;
  isShowingControls: boolean | null;
  imageToPreload: string | null;
};

type ArrowProps = {
  direction: string;
  onClick: (e: React.MouseEvent | React.KeyboardEvent) => void;
  dataHcName: string;
  imageAltProps?: SVGImageAltProps;
};

const Arrow = (props: ArrowProps) => {
  const { direction, onClick, dataHcName, imageAltProps } = props;
  const ariaLabel = direction === "right" ? "Next photo" : "Previous photo";
  return (
    <button
      aria-label={ariaLabel}
      data-hc-name={dataHcName}
      type="button"
      onClick={onClick}
      onKeyDown={onEnterOrSpaceKey(onClick)}
      className={classNames(
        defaultTheme.Arrow,
        defaultTheme[`Arrow-${direction}`]
      )}
    >
      <ChevronIconStandardGray imageAltProps={imageAltProps} />
    </button>
  );
};

/**
 * Displays a simple image thumbnail carousel for use in property cards and other
 * list items.
 */
class ThumbnailCarouselComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentIndex: props.index !== undefined ? props.index : 0,
      isShowingControls: false,
      /* An image URL to preload offscreen */
      imageToPreload: props.shouldPreloadNextImageOnInit
        ? props.urls[1]
          ? props.urls[1]
          : null
        : null,
    };
  }

  onMouseEnterTimeout: number | undefined = undefined;

  componentDidUpdate(_: any, prevState: Readonly<State>): void {
    const { ariaAnnouncer, fullAddress, urls } = this.props;
    const { currentIndex } = this.state;
    if (
      ariaAnnouncer && 
      currentIndex !== null && 
      currentIndex !== prevState.currentIndex &&
      fullAddress &&
      urls.length
      ) {
      const ariaMessage = `Photo ${currentIndex + 1} of ${urls.length} for ${fullAddress}`;
      ariaAnnouncer(ariaMessage);
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.onMouseEnterTimeout);
  }

  onMouseEnter = (): void => {
    const { alwaysShowControls, urls } = this.props;
    if (!alwaysShowControls) {
      /* No need to maintain this on state when we are always showing controls
       * This will allow us to change whether controls are always shown based on the props */
      this.setState({ isShowingControls: true });
    }
    /* After hovering the photo for a short time, preload the next photo */
    this.onMouseEnterTimeout = window.setTimeout(() => {
      this.setState({ imageToPreload: urls[1] });
    }, 300);
    if (this.props.onMouseEnter) {
      this.props.onMouseEnter();
    }
  };

  onMouseLeave = (): void => {
    if (!this.props.alwaysShowControls) {
      this.setState({ isShowingControls: false });
    }
    window.clearTimeout(this.onMouseEnterTimeout);
    if (this.props.onMouseLeave) {
      this.props.onMouseLeave();
    }
  };

  handleClickNext = (e: React.MouseEvent | React.KeyboardEvent): void => {
    const { currentIndex } = this.state;
    const { urls, onClickNext, reportCarouselRightArrowClick } = this.props;
    const numItemsToRender = urls.length;
    const newIndex =
      currentIndex === numItemsToRender - 1 ? 0 : currentIndex + 1;
    const preloadIndex = newIndex + 1 === urls.length ? 0 : newIndex + 1;
    e.stopPropagation();
    reportCarouselRightArrowClick();

    this.setState({
      currentIndex: newIndex,
      imageToPreload: urls[preloadIndex],
    });
    if (onClickNext) {
      onClickNext(newIndex);
    }
  };

  handleClickPrev = (e: React.MouseEvent | React.KeyboardEvent): void => {
    const { currentIndex } = this.state;
    const { urls, onClickPrev } = this.props;
    const numItemsToRender = urls.length;
    const newIndex =
      currentIndex === 0 ? numItemsToRender - 1 : currentIndex - 1;
    const preloadIndex = newIndex - 1 === -1 ? urls.length - 1 : newIndex - 1;
    e.stopPropagation();

    this.setState({
      currentIndex: newIndex,
      imageToPreload: urls[preloadIndex],
    });
    if (onClickPrev) {
      onClickPrev(newIndex);
    }
  };

  render() {
    const { currentIndex, isShowingControls, imageToPreload } = this.state;
    const {
      urls,
      className,
      alwaysShowControls,
      onClick,
      theme,
      index = null,
      alt,
      id,
      overlay,
    } = this.props;
    const numItemsToRender = urls.length;
    const indexToUse = index !== null ? index : currentIndex;

    return (
      <div
        data-hc-name={'thumbnail-carousel'}
        className={classNames(theme['ThumbnailCarousel'], className)}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={(e) => {
          if (typeof onClick === 'function') {
            e.stopPropagation();
            onClick(e, indexToUse);
          }
        }}
      >
        <div
          data-hc-name={'image'}
          id={id}
          className={theme.CurrentImage}
          style={{
            backgroundImage: `url('${urls[indexToUse]}')`,
            backgroundColor: 'initial',
          }}
          role="img"
          aria-label={alt}
        />
        {overlay && (
          <div
            className={theme.Overlay}
            style={{ backgroundImage: `url('${overlay}')` }}
          />
        )}
        {(isShowingControls || alwaysShowControls) && numItemsToRender > 0 && (
          <div className={theme.Controls}>
            {numItemsToRender > 1 && [
              <Arrow
                dataHcName={'previous-link'}
                key="arrow-left"
                direction="left"
                onClick={this.handleClickPrev}
                imageAltProps={{
                  title: "Back Arrow",
                  ariaLabelledBy: "back-arrow"
                }}
              />,
              <Arrow
                dataHcName={'next-link'}
                key="arrow-right"
                direction="right"
                onClick={this.handleClickNext}
                imageAltProps={{
                  title: "Next Arrow",
                  ariaLabelledBy: "next-arrow"
                }}
              />,
            ]}
          </div>
        )}
        {imageToPreload && (
          <ImagePreloader
            alt={alt}
            urls={[imageToPreload]}
            renderCanvasElement={false}
          />
        )}
      </div>
    );
  }
}

const ThumbnailCarousel = (props: Props) => {
  const announcer = useAriaAnnouncer();
  return <ThumbnailCarouselComponent ariaAnnouncer={announcer} {...props} />;
};

const ThemedThumbnailCarousel = themr(
  'ThemedThumbnailCarousel',
  defaultTheme
)(ThumbnailCarousel);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThemedThumbnailCarousel);
