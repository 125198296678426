import { reportToSentry } from '@client/utils/error.utils';
import {
  call,
  takeEvery,
  takeLatest,
  takeLeading,
  CallEffect,
  SagaReturnType,
} from 'redux-saga/effects';
import { forIn } from 'lodash';

/** Catch and report all errors within sagas
 * Errors within React components are caught and reported in `AppContainer.tsx` */
export const handleErrors = (saga) => {
  return function* (...args) {
    try {
      yield call(saga, ...args);
    } catch (originalError) {
      try {
        yield call(reportToSentry, originalError, {
          sagaArgs: args && JSON.stringify(args),
          fullError: originalError,
        });
      } catch (e: any) {
        yield call(reportToSentry, originalError, {
          sagaArgs: 'Too long to report',
          fullError: originalError,
        });
      }
      yield call(console.error, originalError);
    }
  };
};

const _handleActionMapping = (sagaMiddleware, actionMapping, effect) => {
  forIn(actionMapping, (saga, action) => {
    function* watchAction() {
      yield effect(action, handleErrors(saga));
    }
    sagaMiddleware.run(watchAction);
  });
};

const _handleActionList = (sagaMiddleware, actions, saga, effect) => {
  function* watchAction() {
    yield effect(actions, handleErrors(saga));
  }
  sagaMiddleware.run(watchAction);
};

export const watchEvery = (sagaMiddleware, actions, saga?) => {
  if (!saga && actions.constructor === Object) {
    _handleActionMapping(sagaMiddleware, actions, takeEvery);
  } else {
    _handleActionList(sagaMiddleware, actions, saga, takeEvery);
  }
};

export const watchLatest = (sagaMiddleware, actions, saga?) => {
  if (!saga && actions.constructor === Object) {
    _handleActionMapping(sagaMiddleware, actions, takeLatest);
  } else {
    _handleActionList(sagaMiddleware, actions, saga, takeLatest);
  }
};

export const watchLeading = (sagaMiddleware, actions, saga?) => {
  if (!saga && actions.constructor === Object) {
    _handleActionMapping(sagaMiddleware, actions, takeLeading);
  } else {
    _handleActionList(sagaMiddleware, actions, saga, takeLeading);
  }
};

type SagaGenCall<T extends (...args: any[]) => any> = Generator<
  CallEffect<SagaReturnType<T>>,
  SagaReturnType<T>,
  any
>;

/**
 * Helper method to apply return types to called sagas
 *
 * From: yield call([apiClient, apiClient.login], { user, pass });
 * To:   yield* callSaga([apiClient, apiClient.login], { user, pass });
 */
export const callSaga = function* <Fn extends (...args: any[]) => any>(
  fn: [any, Fn],
  ...args: Parameters<Fn>
): SagaGenCall<Fn> {
  return yield call(fn, ...args);
};
