import { connect } from 'react-redux';

import renderOnMount from '@client/hocs/render-on-mount';
import MapAvmDeepDiveLocation from '@client/components/MapAvmDeepDiveLocation';
import {
  getPropertyMapMarkerFeature,
  getPropertyLocation,
} from '@client/store/selectors/property-details.selectors';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import { getMapLegendBreaks } from '@client/store/selectors/map-legend-breaks.selectors';
import { fetchMapLegendBreaks } from '@client/store/actions/map-legend-breaks.actions';
import { getAccessToken } from '@client/store/selectors/auth.selectors';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import { View } from '@client/routes/constants';
import {
  getHomeownerMapMarkerFeature,
  getHomeownerPropertyLocation,
} from '@client/store/selectors/homeowner.selectors';

const mapStateToProps = (state) => {
  const isHomeownerView =
    getCurrentView(state) === View.HOMEOWNER_PROPERTY_DETAILS;
  const markerFeature = isHomeownerView
    ? getHomeownerMapMarkerFeature(state)
    : getPropertyMapMarkerFeature(state);

  return {
    markerFeatures: markerFeature ? [markerFeature] : [],
    propertyLocation: isHomeownerView
      ? getHomeownerPropertyLocation(state)
      : getPropertyLocation(state),
    disableDragging: getIsSmallSize(state),
    legendBreaks: getMapLegendBreaks(state),
    isShowingMapZoomControls: !getIsSmallSize(state),
    mapGeoRequestAccessToken: getAccessToken(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleGetMapLayerLegendBreaks: (bounds, zoom) => {
    const southWest = bounds.getSouthWest();
    const northEast = bounds.getNorthEast();
    dispatch(
      fetchMapLegendBreaks({ southWest, northEast, zoom, metric: undefined })
    );
  },
});

export default renderOnMount(
  connect(mapStateToProps, mapDispatchToProps)(MapAvmDeepDiveLocation)
);
