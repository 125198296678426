import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { NoSavedSearchesProps } from '@client/store/types/saved-searches';
import NoSavedSearches from './NoSavedSearches';
import NoSavedSearchesChase from './NoSavedSearchesChase';
import { COBRAND_IDS } from '@client/store/types/cobranding';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return NoSavedSearchesChase;
    default:
      return NoSavedSearches;
  }
};

const NoSavedSearchesSectionCobranded: React.FC<NoSavedSearchesProps> = (
  props
) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default NoSavedSearchesSectionCobranded;
