import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import createStore from '@client/store';
import { getVisitId } from '@client/utils/visit.util';
import { PartialReduxState } from '@client/store/types/redux-state';
import App from '@client/App';
import { setABTestsOnWindowObj } from '../../ab-test';

const state = (window as any).__REDUX_INITIAL_STATE__ as PartialReduxState;
setABTestsOnWindowObj();

getVisitId();

const reduxStore = createStore(state);

const ClientAppWrapper: React.VFC = () => {
  return (
    <ReduxProvider store={reduxStore}>
      <App />
    </ReduxProvider>
  );
};

export default ClientAppWrapper;
