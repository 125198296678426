import React from 'react';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';

import HorizontalToggle from '@client/components/generic/HorizontalToggle';
import defaultTheme from '@client/css-modules/BinaryHorizontalToggle.css';

const TOGGLE_OPTIONS = [
  { value: false, label: ' ' },
  { value: true, label: ' ' },
];

type BinaryHorizontalToggleProps = {
  ariaLabel?: string;
  ariaLabelledBy?: string;
  dataHcName?: string;
  id?: string;
  /** Callback executed on select, with the selected option's value as the argument */
  handleSelect: (value: boolean) => void;
  /** The value that should be shown as selected */
  selectedValue: boolean;
  toggleButtonStyle?: React.CSSProperties;
  theme?: Theme;
  /* For event reporting */
  ['data-event-name']?: string;
  ['data-parent-event-name']?: string;
};
/**
 * A simple HorizontalToggle that only has on/off options
 */
const BinaryHorizontalToggle: React.FC<BinaryHorizontalToggleProps> = (
  props
) => {
  const {
    ariaLabel,
    ariaLabelledBy,
    dataHcName,
    id,
    theme,
    handleSelect,
    selectedValue,
    toggleButtonStyle,
  } = props;

  return (
    <HorizontalToggle
      data-event-name={props['data-event-name']}
      data-parent-event-name={props['data-parent-event-name']}
      ariaLabel={ariaLabel}
      ariaLabelledBy={ariaLabelledBy}
      dataHcName={dataHcName}
      id={id}
      theme={theme}
      handleSelect={handleSelect}
      offState={!selectedValue}
      options={TOGGLE_OPTIONS}
      selectedValue={selectedValue}
      selectedMaskOffset={2}
      widthMultiplier={20}
      toggleButtonStyle={toggleButtonStyle ? toggleButtonStyle : {}}
    />
  );
};

const ThemedBinaryHorizontalToggle = themr(
  'BinaryHorizontalToggle',
  defaultTheme
)(BinaryHorizontalToggle);
export default ThemedBinaryHorizontalToggle;
