import classNames from 'classnames';
import React, { Component } from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import AvmFactorTypeIcon from '@client/components/AvmFactorTypeIcon';
import LoadingSection from '@client/components/generic/LoadingSection';
import AvmDeepDiveComponent from '@client/components/AvmDeepDiveComponent';
import {
  AvmFactorWithLocation,
  PropertyValuesWithRegressionTypes,
  RegressionData,
} from '@client/store/types/regression-data';
import defaultTheme from '@client/css-modules/AvmDeepDiveMobile.css';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import CobrandedStyles from '@client/components/CobrandedStyles';

type Props = {
  avmFactors: AvmFactorWithLocation[];
  avmDeepDiveFactorActive: string | null;
  isLoadingRegressionData: boolean;
  propertyValuesWithRegressionTypes: PropertyValuesWithRegressionTypes;
  regressionsData: RegressionData[];
  tractStatsCount: number | undefined;
  theme: Theme;
  reportCardSelection?: (cardType: string) => void;
};

type State = {
  activeSection: string | null;
};

export class AvmDeepDiveMobile extends Component<Props, State> {
  state: State = {
    activeSection: null,
  };

  componentDidMount() {
    const { avmDeepDiveFactorActive, isLoadingRegressionData } = this.props;
    if (!isLoadingRegressionData && avmDeepDiveFactorActive) {
      this.setState({
        activeSection: this.getRegressionTypeForFactor(avmDeepDiveFactorActive),
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { avmDeepDiveFactorActive, isLoadingRegressionData } = this.props;
    if (
      avmDeepDiveFactorActive &&
      !isLoadingRegressionData &&
      isLoadingRegressionData !== prevProps.isLoadingRegressionData
    ) {
      this.setState({
        activeSection: this.getRegressionTypeForFactor(avmDeepDiveFactorActive),
      });
    }
  }

  handleSelectedAvmFactor = (factorType) => {
    const { reportCardSelection } = this.props;
    if (reportCardSelection) {
      reportCardSelection(factorType);
    }
    this.setState({
      activeSection: this.getRegressionTypeForFactor(factorType),
    });
  };

  getRegressionTypeForFactor = (factorType: string): string | null => {
    const { regressionsData } = this.props;
    if (regressionsData.length >= 1) {
      const activeRegression = regressionsData.filter(
        (item) => item.label === factorType
      )[0];

      if (activeRegression) {
        return activeRegression.type.toUpperCase();
      } else {
        /**
         * this is to handle the case where activeRegression being undefined, that could happen
         * in some cases, 1. Location is still default but we might not have data for it),
         * 2. As we are tracking if the deep dives modal is open using url params for back button
         * functionality to work as expected
         */
        return regressionsData[0].type.toUpperCase();
      }
    } else {
      return null;
    }
  };

  getComponentHtml = (factor) => {
    const regressionDataForAvmFactor =
      this.getRegressionDataForSelectedAvmFactorType(this.state.activeSection);
    const isSelected =
      regressionDataForAvmFactor &&
      regressionDataForAvmFactor.label === factor.label;
    const { theme } = this.props;
    return (
      <CobrandedStyles key={factor.type}>
        {({ avmDeepDiveFactorBorderColor }) => (
          <button
            type="button"
            aria-label={factor.label}
            key={factor.type}
            className={classNames(theme.AvmFactorIcon, {
              [theme.AvmFactorIconPositive]: factor.value >= 0,
              [theme.AvmFactorIconNegative]: factor.value < 0,
            })}
            style={
              isSelected
                ? {
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: avmDeepDiveFactorBorderColor,
                  }
                : {}
            }
            onClick={() => this.handleSelectedAvmFactor(factor.label)}
            onKeyDown={onEnterOrSpaceKey(() =>
              this.handleSelectedAvmFactor(factor.label)
            )}
          >
            <div className={theme.AvmFactorIconLabel}>{factor.label}</div>
            <AvmFactorTypeIcon type={factor.type} className={theme.icon} />
          </button>
        )}
      </CobrandedStyles>
    );
  };

  getAvmFactorsForTabs = (): JSX.Element[] => {
    const { avmFactors, avmDeepDiveFactorActive } = this.props;
    let avmTabComponents: JSX.Element[] = [];

    avmFactors.forEach((factor) => {
      if (factor.label === avmDeepDiveFactorActive) {
        avmTabComponents.splice(0, 0, this.getComponentHtml(factor));
      } else {
        avmTabComponents.push(this.getComponentHtml(factor));
      }
    });
    return avmTabComponents;
  };

  getRegressionDataForSelectedAvmFactorType = (regressionType) => {
    const { regressionsData } = this.props;
    if (regressionsData.length >= 0) {
      const selectedAvmFactor = regressionsData.filter(
        (item) => item.type.toUpperCase() === regressionType
      )[0];
      if (selectedAvmFactor) {
        return regressionsData.filter(
          (item) => item.type.toUpperCase() === regressionType
        )[0];
      } else {
        /**
         * this is to handle the case where activeRegression being undefined, that could happen
         * in some cases, 1. Location is still default but we might not have data for it),
         * 2. As we are tracking if the deep dives modal is open using url params for back button
         * functionality to work as expected
         */
        return regressionsData[0];
      }
    } else {
      return null;
    }
  };

  render() {
    const {
      isLoadingRegressionData,
      propertyValuesWithRegressionTypes,
      tractStatsCount,
      theme,
    } = this.props;

    return (
      <LoadingSection theme={theme} isLoading={isLoadingRegressionData}>
        <div className={theme.TabsContainer}>{this.getAvmFactorsForTabs()}</div>
        <div className={theme.ActiveSection}>
          <AvmDeepDiveComponent
            count={tractStatsCount}
            factor={this.getRegressionDataForSelectedAvmFactorType(
              this.state.activeSection
            )}
            showDataSummaryForSmallScreens
            theme={theme}
            propertyValues={propertyValuesWithRegressionTypes}
          />
        </div>
      </LoadingSection>
    );
  }
}

const ThemedAvmDeepDiveMobile = themr(
  'AvmDeepDiveMobile',
  defaultTheme
)(AvmDeepDiveMobile);
export default ThemedAvmDeepDiveMobile;
