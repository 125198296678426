import React, { Component } from 'react';

import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { ConfirmModalProps } from '@client/store/types/cobranded-components/confirm-modal';
import { createModalPortal } from '@client/hocs/create-modal-portal';
import SlideInModalContainer from '@client/containers/slide-in-modal.container';
import theme from '@client/css-modules/ConfirmModal.css';
import PillButton from '@client/components/generic/PillButton';

/**
 * A flexible confirmation modal
 */
class ConfirmModal extends Component<ConfirmModalProps, {}> {

  render() {
    const {
      isActive,
      title,
      body,
      handleConfirm,
      handleClose,
      handleReturnFocus,
      handleReportEvent,
    } = this.props;

    return (
      <SlideInModalContainer
        className={theme.ConfirmModal}
        theme={theme}
        modalAriaLabel={title}
        isActive={isActive}
        handleReturnFocus={handleReturnFocus}
        handleClose={(e) => {
          if (isActive && handleReportEvent) {
            handleReportEvent('click_confirm_modal_back_button');
            handleClose(e);
          }
        }}
        hideCloseIcon
      >
        <div className={theme.ConfirmModalContent} aria-live="assertive">
          <div
            className={theme.ConfirmModalTitle}
            role="heading"
            aria-level={2}
          >
            {title}
          </div>
          <div className={theme.ConfirmModalBody}>{body}</div>
          <div className={theme.ConfirmModalFooter}>
            <PillButton
              ariaLabel="confirm"
              theme={theme}
              onKeyDown={onEnterOrSpaceKey(handleConfirm)}
              onClick={handleConfirm}
            >
              Confirm
            </PillButton>
            <PillButton
              data-event-name="click_confirm_modal_cancel"
              ariaLabel="cancel"
              theme={theme}
              onClick={handleClose}
              onKeyDown={onEnterOrSpaceKey(handleClose)}
              deemphasized
            >
              Cancel
            </PillButton>
          </div>
        </div>
      </SlideInModalContainer>
    );
  }
}

export default createModalPortal(ConfirmModal, 'confirm-modal');
