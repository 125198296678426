import { STATUSES } from '@client/store/constants';
import {
  fetchCensusTractData,
  selectCensusTractStatus,
} from '@client/store/slices/grant-program.slice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * useEffect hook to ensure census tract data is fetched on client-side if not fetched already
 */
export function useFetchCensusTractDataEffect() {
  const dispatch = useDispatch();
  const censusTractStatus = useSelector(selectCensusTractStatus);

  useEffect(() => {
    if (censusTractStatus === STATUSES.INIT) {
      dispatch(fetchCensusTractData());
    }
  }, [dispatch, censusTractStatus]);
}
