import classNames from 'classnames';
import defaultTheme from '@client/css-modules/CircleButton.css';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type Props = {
  className?: string;
  ariaLabel: string;
  isActive?: boolean;
  onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  tabIndex?: number;
  type?: "button"| "submit" | "reset";
  style?: React.CSSProperties;
  theme: Theme;
  ['data-event-name']?: string;
  ['data-parent-event-name']?: string;
  children?: React.ReactNode;
};

/**
 * A circular button with an `isActive` prop that animates in a button background
 */
const CircleButton = ({
  children,
  className,
  theme,
  ariaLabel,
  isActive,
  onClick,
  tabIndex,
  type = "button",
  ...rest
}: Props) => {
  const { circleButtonActiveBackground, circleButtonInactiveBackground } =
    useCobrandStyles();
  const backgroundColor = isActive ? circleButtonActiveBackground : circleButtonInactiveBackground;
  return (
    <button
      tabIndex={tabIndex}
      type={type}
      aria-label={ariaLabel}
      aria-pressed={isActive ? true : false}
      className={classNames(theme.CircleButton, theme.CircleButtonBackground, className, {
        [theme.ActiveButton]: isActive,
      })}
      onKeyDown={onClick ? onEnterOrSpaceKey(onClick) : undefined}
      onClick={onClick}
      {...rest}
      style={{
        background: backgroundColor,
        ...rest.style,
      }}
    >
      <span className={theme.ChildrenContainer}>{children}</span>
    </button>
  );
};

export default themr('CircleButton', defaultTheme)(CircleButton);
