import { getActionHasAnalytics } from '@client/utils/analytics.utils';
import { Action } from '@client/store/actions';
import { AnalyticsEvent } from '@client/store/analytics-constants';
import { ActionType } from '@src/analytics-redux-middleware/types';
import { SearchWidgetReduxState } from '@src/widgets/search-widget/store/types';
import { ReduxState } from '@client/store/types/redux-state';

type AppOrWidgetState = ReduxState | SearchWidgetReduxState;

export const googleAnalytics4Tracker = (
  initialState: AppOrWidgetState,
  getEventGeneralContext: (state: AppOrWidgetState) => {},
  setUserInfoActions: string[],
  getEventProps: (state: AppOrWidgetState, action: ActionType) => {}
) => {
  const setEventGeneralContext = (state: AppOrWidgetState) => {
    if (window.gtag) {
      window.gtag('set', 'user_properties', getEventGeneralContext(state));
    } else {
      throw new Error(
        'Attempting to send GA4 event without gtag defined on window'
      );
    }
  };

  setEventGeneralContext(initialState);

  return {
    key: 'ga-4',
    fn: <State extends ReduxState | SearchWidgetReduxState>(
      action: Action,
      store: { getState: () => State }
    ) => {
      const state = store.getState();
      let actionType = action?.type as string;

      if (setUserInfoActions.includes(actionType)) {
        setEventGeneralContext(state);
      }

      if (getActionHasAnalytics(action)) {
        const eventName: AnalyticsEvent = action.meta.analytics.event;
        const props = getEventProps(state, action);

        /* GA4 records the page view event automatically */
        if (eventName === 'pageview') {
          return;
        }
        if (window.gtag) {
          window.gtag('event', eventName, props);
        } else {
          throw new Error(
            'Attempting to send GA4 event without gtag defined on window'
          );
        }
      }
    },
  };
};
