export default ({
  term,
  buildingId,
  sessionToken,
  latitude,
  longitude,
  addressLookupType,
}) => ({
  query: /* GraphQL */ `query PlaceSearch(
    $term: String!,
    $buildingId: NumericID,
    $sessionToken: String,
    $addressLookupType: AddressLookupType,
    $latitude: Float,
    $longitude: Float
  ) {
    placeSearch (id: {
      term: $term
      buildingId: $buildingId
      sessiontoken: $sessionToken
      placeType: REGIONS
      addressLookupType: $addressLookupType,
      latitude: $latitude,
      longitude: $longitude
    }) {
      sessiontoken
      places {
        placeId
        placeType
        description
      }
      addresses {
        hcAddressId
        fields {
          hcBuildingId
          count
          fullLine
          hasAvm
          isUnit
          latitudeLongitude
          partialLine
          slug
        }
      }
    }
  }`,
  variables: {
    term,
    buildingId,
    sessionToken,
    addressLookupType,
    latitude,
    longitude,
  },
});
