import {
  Address,
  Property,
  PropertySummary,
  SpatialSearchCountResult,
  SpatialSearchDetail,
  SpatialSearchDetailResults,
  SpatialSearchOverCountResult,
} from '@client/store/sagas/queries/types';
import { RecentPropertyResponseItem } from '@client/store/slices/recent-user-activity.slice';
import {
  PartialPropertySummary,
  WithAddressRequired,
} from '@client/store/types/property';
import { getIsPropertyAddressValid } from '@client/utils/property.utils';
import { PartialDeep } from 'type-fest';

export type SearchListSortField = 'LIST_PRICE' | 'LIST_DATE';
export type SearchListSortOrder = 'DESCENDING' | 'ASCENDING';

export type AddressResult = {
  addressId?: number | null;
  buildingId: number | null;
  count?: number | null;
  text: string | null;
  partialLine: string | null;
  slug: string;
};

export type PlaceResult = {
  placeId: string;
  placeType: string;
  text: string | null;
  count?: number | null;
  partialLine?: string | null;
};

export type SavedSearch = {
  searchId: number;
  name: string;
  displayName: string;
};

export type StaticElement = {
  eleId?: string;
  text?: string;
};

export type Result = AddressResult | PlaceResult | StaticElement;
export type AutoCompleteItems =
  | Result
  | SavedSearch
  | RecentPropertyResponseItem;

export type SpatialSearchDetailWithAddressRequired = {
  property: WithAddressRequired<PartialDeep<Property>>;
  summary: PartialPropertySummary & {
    geoLocation: { latitude: number; longitude: number };
  };
};

type SpatialSearchDetailPartial = Omit<
  SpatialSearchDetail,
  'address' | 'property' | 'summary'
> & {
  address?: PartialDeep<Address> | null;
  property: PartialDeep<Property> | null;
  summary: PartialDeep<PropertySummary> | null;
};

export const getIsSpatialSearchDetailWithAddress = (
  item: MultiUnitTileCacheEntry | SpatialSearchDetailPartial
): boolean => {
  const property = (item as SpatialSearchDetail).property;
  const summary = (item as SpatialSearchDetail).summary;
  return !!(
    property &&
    summary &&
    getIsPropertyAddressValid(property) &&
    summary.geoLocation?.latitude &&
    summary.geoLocation?.longitude
  );
};

export type MultiUnitTileCacheEntry = {
  multiUnitClusterLocation: {
    latitude: number;
    longitude: number;
  };
  multiUnitCount: number;
  childAddressSlugs: string[];
  hasAllActiveListings: boolean;
  tractId?: string | null;
};

export type ModifiedSpatialSearchDetailResponse = Omit<
  SpatialSearchDetailResults,
  'hits'
> & {
  hits: (MultiUnitTileCacheEntry | SpatialSearchDetail)[];
};

export type ModifiedSpatialSearchResponse = {
  propertySpatialSearch:
    | SpatialSearchCountResult
    | SpatialSearchOverCountResult
    | ModifiedSpatialSearchDetailResponse;
};

export const isPlaceWithNonNullText = <T extends PlaceResult>(
  item?: PlaceResult
): item is Omit<T, 'text'> & { text: string } => {
  return typeof item?.text === 'string';
};

export const isAddressWithNonNullText = <T extends AddressResult>(
  item?: AddressResult
): item is Omit<T, 'text'> & { text: string } => {
  return typeof item?.text === 'string';
};

export const isAddressResult = (
  item?: AutoCompleteItems
): item is AddressResult => {
  return !!(item as AddressResult)?.slug;
};

export const isPlaceResult = (
  item?: AutoCompleteItems
): item is PlaceResult => {
  return !!(item as PlaceResult)?.placeId;
};

export const isSavedSearch = (
  item?: AutoCompleteItems
): item is SavedSearch => {
  return !!(item as SavedSearch)?.searchId;
};

export const isRecentPropertyItem = (
  item?: AutoCompleteItems
): item is RecentPropertyResponseItem => {
  return !!(item as RecentPropertyResponseItem)?.address_id;
};

export const isStaticElement = (
  item?: AutoCompleteItems
): item is StaticElement => {
  return !!(item as StaticElement)?.eleId;
};
