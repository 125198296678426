import { isEmpty, isPlainObject, transform } from 'lodash';

/**
 * Given an iterable object or array, remove all keys that have `null` values.
 * Useful for making GraphQL responses (which return `null` for any key that
 * doesn't have a value) work well with JS default parameters.
 */
export const removeNullKeys = <T extends {}>(iterable: T): T => {
  /* Assert that `cleanDeep` will always return a truthy value given that we're checking for an object to be
   * passed in */
  const output = cleanDeep(iterable, {
    emptyArrays: false,
    emptyObjects: false,
    emptyStrings: false,
    nullValues: true,
    undefinedValues: false,
  });
  return output as T;
};

/* Moved here from
 * https://github.com/nunofgs/clean-deep/blob/master/src/index.js
 */
export const cleanDeep = (
  object: {},
  {
    cleanValues = [],
    emptyArrays = true,
    emptyObjects = true,
    emptyStrings = true,
    nullValues = true,
    undefinedValues = true,
  }: {
    cleanValues?: any[];
    emptyArrays?: boolean;
    emptyObjects?: boolean;
    emptyStrings?: boolean;
    nullValues?: boolean;
    undefinedValues?: boolean;
  } = {}
): {} | any[] | undefined => {
  return transform(object, (result: {} = {}, value: any, key: any) => {
    // Recurse into arrays and objects.
    if (Array.isArray(value) || isPlainObject(value)) {
      value = cleanDeep(value, {
        cleanValues,
        emptyArrays,
        emptyObjects,
        emptyStrings,
        nullValues,
        undefinedValues,
      });
    }

    // Exclude specific values.
    if (cleanValues.includes(value)) {
      return;
    }

    // Exclude empty objects.
    if (emptyObjects && isPlainObject(value) && isEmpty(value)) {
      return;
    }

    // Exclude empty arrays.
    if (emptyArrays && Array.isArray(value) && !value.length) {
      return;
    }

    // Exclude empty strings.
    if (emptyStrings && value === '') {
      return;
    }

    // Exclude null values.
    if (nullValues && value === null) {
      return;
    }

    // Exclude undefined values.
    if (undefinedValues && value === undefined) {
      return;
    }

    // Append when recursing arrays.
    if (Array.isArray(result)) {
      return result.push(value);
    }

    result[key] = value;
    return true;
  });
};

export const flattenDeepWhile = (
  array: any,
  shouldFlattenForValue: (value: any) => boolean
) => {
  const baseFlatten = (
    array: any[],
    predicate: (value: any) => boolean,
    result: any[]
  ) => {
    let index = -1;

    while (++index < array.length) {
      let value = array[index];
      if (Array.isArray(value) && predicate(value)) {
        baseFlatten(value, predicate, result);
      } else {
        result.push(value);
      }
    }
    return result;
  };

  return array !== null && array.length > 0
    ? baseFlatten(array, shouldFlattenForValue, [])
    : [];
};
