import React from 'react';
import classNames from 'classnames';
import { themr } from '@friendsofreactjs/react-css-themr';

import { ButtonMenuButtonProps } from '@client/store/types/cobranded-components/button-menu-button';
import defaultTheme from '@client/css-modules/ButtonMenuButton.css';

/**
 * The button rendered in <ButtonMenu />
 */
const ButtonMenuButton = ({
  theme,
  className,
  isActive,
  children,
  ariaControls,
  ariaLabel,
  ariaLabelledBy,
  onKeyDown,
  onClick,
  id,
}: ButtonMenuButtonProps) => (
  <button
    className={classNames(theme.ButtonMenuButton, {
      [theme.Active]: !!isActive,
      [className || '']: className,
    })}
    aria-label={ariaLabel ? ariaLabel : undefined}
    id={id ? id : undefined}
    aria-labelledby={ariaLabelledBy ? ariaLabelledBy : undefined}
    aria-haspopup="true"
    aria-expanded={isActive}
    aria-controls={ariaControls ? ariaControls : undefined}
    onKeyDown={onKeyDown}
    onClick={onClick}
  >
    {children}
  </button>
);

export default themr('ButtonMenuButtonThemed', defaultTheme)(ButtonMenuButton);
