import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { consumerApiClient } from '@client/services/consumer-api-client';
import { watchEvery } from '@client/utils/saga.utils';
import {
  fetchPartnerAPICredential,
  fetchPartnerAPICredentialFailure,
  fetchPartnerAPICredentialSuccess,
} from '@client/store/slices/partner-api-credential.slice';

function* fetchPartnerAPICredentialSaga(
  action: PayloadAction<{ token: string }>
) {
  try {
    yield put(
      fetchPartnerAPICredentialSuccess({
        credential: yield call(
          [consumerApiClient, consumerApiClient.fetchPartnerAPICredential],
          action.payload.token
        ),
      })
    );
  } catch (e: any) {
    yield put(fetchPartnerAPICredentialFailure());
    if (
      /* error parsing token, empty token, expired token */
      e?.statusCode !== 401 &&
      /* token already used, API key limit reached */
      e?.statusCode !== 409
    ) {
      /* possible cases, 500 server error or 404 partner not found */
      throw new Error(
        `fetchPartnerAPICredential failed with status code: ${e?.statusCode}`
      );
    }
  }
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [fetchPartnerAPICredential.type]: fetchPartnerAPICredentialSaga,
  });
};
