import { consumerApiClient } from '@client/services/consumer-api-client';
import { STATUSES } from '@client/store/constants';
import {
  fetchCensusTractData,
  fetchCensusTractDataLoading,
  fetchCensusTractDataSuccess,
  selectCensusTractStatus,
} from '@client/store/slices/grant-program.slice';
import { watchLeading } from '@client/utils/saga.utils';
import { call, put, select } from 'redux-saga/effects';

export function* fetchCensusTractDataSaga() {
  const censusTractStatus = (yield select(
    selectCensusTractStatus
  )) as ReturnType<typeof selectCensusTractStatus>;
  try {
    if (censusTractStatus === STATUSES.INIT) {
      /* Ensure that subsequent fetchCensusTractData calls will not trigger a fetch */
      yield put(fetchCensusTractDataLoading());
      const response = yield call([
        consumerApiClient,
        consumerApiClient.fetchCensusTract,
      ]);
      yield put(fetchCensusTractDataSuccess(response));
    }
  } catch (e: any) {
    if (e?.statusCode !== 404) {
      throw new Error('fetchCensusTractDataSaga failed: ' + JSON.stringify(e));
    }
  }
}

export default (sagaMiddleware) => {
  watchLeading(sagaMiddleware, {
    [fetchCensusTractData.type]: fetchCensusTractDataSaga,
  });
};
