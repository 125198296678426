import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '@client/store/types/redux-state';
import { getCurrentRoute } from '@src/redux-saga-router-plus/selectors';
import { getRoutesConfig } from '@client/routes';
import { View } from '@client/routes/constants';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';

type Props = {
  params: {};
  query: {};
  view: View | null;
  isHomepageEnabled: boolean;
};

const mapStateToProps = (state: ReduxState) => {
  const { view, params, query } = getCurrentRoute(state);
  return {
    view,
    params,
    query,
    isHomepageEnabled: getIsFeatureEnabled('homepage')(state),
  };
};

const Router: React.FC<Props> = ({
  params,
  query,
  view,
  isHomepageEnabled,
}) => {
  const routeConfig = getRoutesConfig({ isHomepageEnabled });
  const routeConfigForView = view && routeConfig[view];
  const Component = view && routeConfigForView && routeConfigForView.component;

  // useEffect redirects focus back to the top of the page
  // on view change. Element with id="top" is located in PageLayoutWrapper component.
  useEffect(() => {
    document.getElementById("top")?.focus()
  }, [view])

  return Component ? (
    <Component view={view} params={params} query={query} />
  ) : null;
};

export default connect(mapStateToProps)(Router);
