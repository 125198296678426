import { createSelector } from 'reselect';

import { ReduxState } from '@client/store/types/redux-state';
import { ABTestsState } from '@client/store/reducers/ab-tests.reducer';

export function getABTestsState(state: ReduxState): ABTestsState {
  return state.abTests;
}

export const getABTestVariants = createSelector(
  getABTestsState,
  (abTestsState) => abTestsState.testVariants
);
