import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import React from 'react';

import ButtonMenuButtonCobranded from '@client/components/ButtonMenuButton/ButtonMenuButtonCobranded';
import defaultTheme from '@client/css-modules/ButtonMenu.css';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { key, stopEvent } from '@client/utils/component.utils';
import { elementIsDescendant } from '@client/utils/dom.utils';

type ButtonMenuProps = {
  dataHcName: string;
  onClick: () => void;
  textButton: string;
  label?: string;
  ulId?: string;
  ariaLabelledBy?: string;
  menuItems: React.ReactNode[];
  theme: Theme;
};

type ButtonMenuState = {
  isMenuOpen: boolean;
};

class ButtonMenu extends React.Component<ButtonMenuProps, ButtonMenuState> {
  menuButtonContainer: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.menuButtonContainer = React.createRef();
  }

  state = {
    isMenuOpen: false,
  };

  componentWillUnmount() {
    this.unBindWindowEvents();
  }

  bindWindowEvents = (): void => {
    window.addEventListener('click', this.handleOnOutsideClick);
    window.addEventListener('keydown', this.handleOnKeyDown);
  };

  unBindWindowEvents = (): void => {
    window.removeEventListener('click', this.handleOnOutsideClick);
    window.removeEventListener('keydown', this.handleOnKeyDown);
  };

  handleOnButtonMenuClick = (
    e: React.MouseEvent | React.KeyboardEvent
  ): void => {
    const { isMenuOpen } = this.state;
    const { onClick } = this.props;
    const updatedIsMenuOpen = !isMenuOpen;

    if (updatedIsMenuOpen) {
      onClick();
      this.bindWindowEvents();
    } else {
      this.unBindWindowEvents();
    }

    this.setState({
      isMenuOpen: updatedIsMenuOpen,
    });
  };

  stopEventAndClose = (e: KeyboardEvent): void => {
    stopEvent(e);
    this.closeButtonMenu();
  };

  handleOnKeyDown = (e: KeyboardEvent): void => {
    const { isMenuOpen } = this.state;
    const { current: menuButtonContainer } = this.menuButtonContainer;
    const pressedKey = e.key;
    const curActiveEle = e.target as Element;
    const eleIsNotDescendant =
      curActiveEle &&
      menuButtonContainer &&
      !elementIsDescendant(curActiveEle, menuButtonContainer);

    if (key.isTab(pressedKey)) {
      const nodes = menuButtonContainer
        ? menuButtonContainer.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
          )
        : [];
      const isLastNode = curActiveEle === nodes[nodes.length - 1];
      /**
       * when tabbing through a different parent element, event.target does not return the highlighted element but rather the element in which the event is fired from. And this is a workaround to detect if the tab is going out of the dropdown or not by using the last node as a pivot
       */
      const isSafeToClose =
        eleIsNotDescendant || (isLastNode && !key.isBackTab(e));

      if (isMenuOpen && curActiveEle && isSafeToClose) {
        this.stopEventAndClose(e);
      }
    } else if (key.isEscape(pressedKey)) {
      if (isMenuOpen) {
        this.stopEventAndClose(e);
      }
    }
  };

  closeButtonMenu = (): void => {
    this.unBindWindowEvents();
    this.setState({ isMenuOpen: false });
  };

  handleOnOutsideClick = (e: MouseEvent): void => {
    const { isMenuOpen } = this.state;
    const { current } = this.menuButtonContainer;

    if (
      isMenuOpen &&
      current &&
      !elementIsDescendant(e.target as Element, current)
    ) {
      stopEvent(e);
      this.closeButtonMenu();
    }
  };

  render() {
    const {
      dataHcName,
      textButton,
      label,
      ulId,
      ariaLabelledBy,
      menuItems,
      theme,
    } = this.props;
    const { isMenuOpen } = this.state;

    return (
      <AccessibleElementUniqueId>
        {({ uid }) => (
          <div
            className={theme.ButtonMenuContainer}
            data-hc-name={dataHcName}
            ref={this.menuButtonContainer}
          >
            <ButtonMenuButtonCobranded
              isActive={isMenuOpen}
              id={uid}
              ariaControls={ulId}
              ariaLabel={label}
              ariaLabelledBy={ariaLabelledBy}
              onKeyDown={onEnterOrSpaceKey(this.handleOnButtonMenuClick)}
              onClick={this.handleOnButtonMenuClick}
            >
              {textButton}
            </ButtonMenuButtonCobranded>
            {isMenuOpen && (
              <ul className={theme.Menu} role="menu" aria-labelledby={uid}>
                {menuItems.map((menuItem, idx) => (
                  <li key={idx} role="menuitem">
                    {menuItem}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </AccessibleElementUniqueId>
    );
  }
}

const ThemedButtonMenu = themr('ButtonMenu', defaultTheme)(ButtonMenu);
export default ThemedButtonMenu;
