import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import React from 'react';

import defaultTheme from '@client/css-modules/TextButton.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

export type TextButtonProps = {
  ariaLabel?: string;
  ariaHasPopup?:
    | boolean
    | 'dialog'
    | 'menu'
    | 'grid'
    | 'listbox'
    | 'tree'
    | 'true'
    | 'false'
    | undefined;
  className?: string;
  dataHcName?: string;
  tabIndex?: number;
  onClick: (e: React.MouseEvent) => void;
  style?: object;
  theme: Theme;
  /* For event reporting */
  ['data-event-name']?: string;
  ['data-parent-event-name']?: string;
  children?: React.ReactNode;
};

/* A <button> element that is styled to look like a traditional link */
const TextButton = ({
  ariaLabel,
  ariaHasPopup,
  className,
  dataHcName,
  children,
  onClick,
  style = {},
  theme,
  ['data-event-name']: dataEventName,
  ['data-parent-event-name']: dataParentEventName,
  tabIndex,
}: TextButtonProps) => {
  const { textButtonLinkColor } = useCobrandStyles();
  return (
    <button
      style={{
        color: textButtonLinkColor,
        ...style,
      }}
      data-event-name={dataEventName}
      data-parent-event-name={dataParentEventName}
      data-hc-name={dataHcName ? dataHcName : undefined}
      className={classNames(theme.TextButton, className)}
      onClick={onClick}
      aria-label={ariaLabel}
      aria-haspopup={ariaHasPopup ?? false}
      tabIndex={tabIndex}
    >
      {children}
    </button>
  );
};

const ThemedTextButton = themr('ThemedTextButton', defaultTheme)(TextButton);
export default ThemedTextButton;
