import { call, put, select } from 'redux-saga/effects';

import { graphQLApiClient } from '@client/services/graphql-api-client';
import {
  FETCH_LOCAL_ACTIVITIES,
  FetchLocalActivitiesAction,
  fetchLocalActivitiesError,
  fetchLocalActivitiesSuccess,
} from '@client/store/actions/local-activities.actions';
import {
  GeoLocation,
  LocalActivitiesQuery,
} from '@client/store/sagas/queries/types';
import { getPropertyAddressSlug } from '@client/store/selectors/property-details.selectors';
import { watchEvery } from '@client/utils/saga.utils';

function* getLocalActivitiesSaga(action: FetchLocalActivitiesAction) {
  const propertyLocation = action.payload.propertyLocation as GeoLocation;
  const slug = yield select(getPropertyAddressSlug);
  const data = yield call(
    [graphQLApiClient, graphQLApiClient.getLocalActivities],
    {
      latitude: propertyLocation.latitude,
      longitude: propertyLocation.longitude,
      slug,
    }
  );
  if (data && !data.errors) {
    yield put(fetchLocalActivitiesSuccess(data as LocalActivitiesQuery));
  } else {
    yield put(fetchLocalActivitiesError());
  }
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [FETCH_LOCAL_ACTIVITIES]: getLocalActivitiesSaga,
  });
};
