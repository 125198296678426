import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import SearchIconLendco from '@client/inline-svgs/search-icon-lendco';
import SearchIconComehomeImg from '@client/components/cobrand/comehome/SearchIconComehomeImg';
import SearchIconChaseImg from '@client/components/cobrand/chase/SearchIconChaseImg';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return SearchIconChaseImg;
    case COBRAND_IDS.WWW:
      return SearchIconComehomeImg;
    default:
      return SearchIconLendco;
  }
};

const SearchIconCobranded: React.FC<{ className: string }> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default SearchIconCobranded;
