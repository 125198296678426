import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import defaultTheme from '@client/css-modules/MenuIcon.css';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';

type MenuIconProps = {
  active: boolean;
  theme: Theme;
  handleClick: () => void;
  ariaLabel?: string;
  color?: string;
  buttonRef?: React.MutableRefObject<HTMLButtonElement | null>;
};

const MenuIcon: FC<MenuIconProps> = ({
  theme,
  handleClick,
  active,
  ariaLabel,
  color,
  buttonRef,
}) => {
  const dispatch = useDispatch();
  const handleMenuIconClick = () => {
    dispatch(
      reportEvent(active ? 'close_hamburger_menu' : 'open_hamburger_menu')
    );
    handleClick();
  };

  return (
    <button
      type="button"
      aria-label={ariaLabel}
      data-hc-name={ariaLabel}
      aria-expanded={active}
      className={theme.MenuIcon}
      onKeyDown={onEnterOrSpaceKey(handleMenuIconClick)}
      onClick={handleMenuIconClick}
      ref={buttonRef}
    >
      <div
        className={classNames(theme.MenuIconLine, { [theme.active]: active })}
        style={color ? { backgroundColor: color } : {}}
      >
        <div
          className={theme.MenuIconLineChild}
          style={color ? { backgroundColor: color } : {}}
        />
        <div
          className={theme.MenuIconLineChild}
          style={color ? { backgroundColor: color } : {}}
        />
      </div>
    </button>
  );
};

const ThemedMenuIcon = themr('MenuIcon', defaultTheme)(MenuIcon);

export default ThemedMenuIcon;
