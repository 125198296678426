import { useDispatch, useSelector } from 'react-redux';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { getUserContactInfo } from '@client/store/selectors/auth.selectors';
import { TopLocalAgentAdDetailsInterface } from '@hc/consumer-customization-data/lib/types';

const TAR_LINK_SIGNUP_FALLBACK = 'https://www.topagentsranked.com/signup02';
const TAR_HOME_LINK_FALLBACK = 'https://topagentsranked.com/home';

export const getTARCTALink = (
  details: TopLocalAgentAdDetailsInterface | null,
  isLoggedIn: boolean,
  partnerUserId: string | null,
  chapiPartnerInviteId: string | null
) => {
  const loggedOutUrl = details?.signup_url ?? TAR_LINK_SIGNUP_FALLBACK;
  const rootHomeUrl = details?.home_url ?? TAR_HOME_LINK_FALLBACK;

  if (isLoggedIn && partnerUserId) {
    return `${rootHomeUrl}?uuid=${partnerUserId}`;
  } else if (chapiPartnerInviteId) {
    return `${rootHomeUrl}?uuid=${chapiPartnerInviteId}`;
  } else {
    return loggedOutUrl;
  }
};

/**
 * @returns a function that dispatches a reportEvent action
 * with the common TopAgentRanked properties
 */
export const useReportTAREvent = () => {
  const dispatch = useDispatch();
  const contactInfo = useSelector(getUserContactInfo);

  return () => {
    dispatch(
      reportEvent(
        'click_talk_to_agent_top_agents_ranked',
        PARENT_EVENTS.CLICK_LENDER_CTA,
        {
          ...contactInfo,
        }
      )
    );
  };
};
