import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import InfoIcon from '@client/inline-svgs/info-icon';
import InfoIconChase from '@client/inline-svgs/info-icon-chase';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return InfoIconChase;
    default:
      return InfoIcon;
  }
};

const InfoIconCobranded: React.FC<{ className: string }> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default InfoIconCobranded;
