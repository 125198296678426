import React from 'react';
import SearchIconChase from '@client/assets/images/cobrand/chase/search-icon-chase.svg';

const SearchIconChaseImg: React.FunctionComponent<
  React.HTMLAttributes<HTMLImageElement>
> = (props) => {
  return <img src={SearchIconChase} alt={'Search Icon'} {...props} />;
};

export default SearchIconChaseImg;
