import { View } from '@client/routes/constants';
import { EVENTS, PARENT_EVENTS } from '@client/store/analytics-constants';
import {
  AfterAuthAction,
  AuthModalHeadingText,
  AuthModalPage,
  AuthModalSubHeadingText,
  ErrorObj,
  LODirectUserSignupFields,
  User,
} from '@client/store/types/auth';
import { LOUserApiResponse } from '@client/store/types/consumer-api';

const SIGNUP_ERROR = 'signup_error';

export const RESET_AUTH_DEPENDENT_STATE = 'RESET_AUTH_DEPENDENT_STATE';

export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_LO_DIRECT_USER = 'LOGIN_LO_DIRECT_USER';
export const LOGIN_OAUTH_OIDC = 'LOGIN_OAUTH_OIDC';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_THEN_REDIRECT = 'LOGOUT_THEN_REDIRECT';
export const LOGOUT_WITHOUT_REDIRECT = 'LOGOUT_WITHOUT_REDIRECT';
export const URQL_INITIATED_UNAUTHORIZED_LOGOUT =
  'URQL_INITIATED_UNAUTHORIZED_LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SET_ACCOUNT_DETAILS_FROM_STORAGE =
  'SET_ACCOUNT_DETAILS_FROM_STORAGE';
export const AUTH_MODAL_HIDE = 'AUTH_MODAL_HIDE';
export const AUTH_MODAL_SHOW = 'AUTH_MODAL_SHOW';

export const INIT_FORGOT_PASSWORD = 'INIT_FORGOT_PASSWORD';
export const SUBMIT_FORGOT_PASSWORD = 'SUBMIT_FORGOT_PASSWORD';
export const SUBMIT_FORGOT_PASSWORD_SUCCESS = 'SUBMIT_FORGOT_PASSWORD_SUCCESS';
export const SUBMIT_FORGOT_PASSWORD_ERROR = 'SUBMIT_FORGOT_PASSWORD_ERROR';

export const SUBMIT_PASSWORD_RESET_THEN_REDIRECT =
  'SUBMIT_PASSWORD_RESET_THEN_REDIRECT';
export const SUBMIT_PASSWORD_RESET_SUCCESS = 'SUBMIT_PASSWORD_RESET_SUCCESS';

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_ERROR = 'UPDATE_USER_PROFILE_ERROR';
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';
export const UPDATE_USER_EMAIL_SUCCESS = 'UPDATE_USER_EMAIL_SUCCESS';
export const UPDATE_USER_EMAIL_ERROR = 'UPDATE_USER_EMAIL_ERROR';

export const UPDATE_RESET_PASSWORD_TOKEN = 'UPDATE_RESET_PASSWORD_TOKEN';
export const VERIFY_RESET_PASSWORD_FAILURE = 'VERIFY_RESET_PASSWORD_FAILURE';

export const FETCH_NEW_ACCESS_TOKEN = 'FETCH_NEW_ACCESS_TOKEN';
export const FETCH_NEW_ACCESS_TOKEN_SUCCESS = 'FETCH_NEW_ACCESS_TOKEN_SUCCESS';
export const FETCH_NEW_ACCESS_TOKEN_FAILURE = 'FETCH_NEW_ACCESS_TOKEN_FAILURE';

export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const FETCH_USER_PROFILE_ERROR = 'FETCH_USER_PROFILE_ERROR';
export const UPDATE_USER_INFO_ON_STATE = 'UPDATE_USER_INFO_ON_STATE';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const CREATE_LO_DIRECT_USER = 'CREATE_LO_DIRECT_USER';
export const SELF_CREATE_LO_DIRECT_CLIENT = 'SELF_CREATE_LO_DIRECT_CLIENT';
export const CREATE_LO_DIRECT_CLIENT_FROM_INVITE =
  'CREATE_LO_DIRECT_CLIENT_FROM_INVITE';
export const CREATE_USER_VALIDATION_ERRORS = 'CREATE_USER_VALIDATION_ERRORS';

export const CONFIRM_NEW_USER = 'CONFIRM_NEW_USER';
export const CONFIRM_NEW_USER_SUCCESS = 'CONFIRM_NEW_USER_SUCCESS';
export const CONFIRM_NEW_USER_ERROR = 'CONFIRM_NEW_USER_ERROR';
export const CONFIRM_NEW_USER_EXPIRED = 'CONFIRM_NEW_USER_EXPIRED';

export const RESEND_CONFIRM_USER_EMAIL = 'RESEND_CONFIRM_USER_EMAIL';
export const RESEND_CONFIRM_USER_EMAIL_SUCCESS =
  'RESEND_CONFIRM_USER_EMAIL_SUCCESS';
export const RESEND_CONFIRM_USER_EMAIL_FAILURE =
  'RESEND_CONFIRM_USER_EMAIL_FAILURE';
export const CLOSE_RESEND_CONFIRM_EMAIL_SUCCESS_MODAL =
  'CLOSE_RESEND_CONFIRM_EMAIL_SUCCESS_MODAL';

export const UPDATE_EMAIL_MODAL_HIDE = 'UPDATE_EMAIL_MODAL_HIDE';
export const UPDATE_EMAIL_MODAL_SHOW = 'UPDATE_EMAIL_MODAL_SHOW';

export const ACCEPT_TERMS_AND_CONDITIONS = 'ACCEPT_TERMS_AND_CONDITIONS';
export const ACCEPT_TERMS_AND_CONDITIONS_SUCCESS =
  'ACCEPT_TERMS_AND_CONDITIONS_SUCCESS';
export const ENSURE_LOGGED_IN_THEN_ACTION = 'ENSURE_LOGGED_IN_THEN_ACTION';

/* Only used for testing. Meant for use in conjunction with ability to dispatch redux actions
 * via the browser console. See App.jsx for details. */
export const REGEN_COOKIE = 'REGEN_COOKIE';

export const PATCH_LO_DIRECT_USER_PROFILE = 'PATCH_LO_DIRECT_USER_PROFILE';
export const DELETE_LO_DIRECT_USER_IMAGE = 'DELETE_LO_DIRECT_USER_IMAGE';
export const POST_LO_DIRECT_USER_IMAGE = 'POST_LO_DIRECT_USER_IMAGE';

export const CLEAR_USER_SIGNUP_ERROR = 'CLEAR_USER_SIGNUP_ERROR';

type ClearUserSignUpErrorAction = {
  type: typeof CLEAR_USER_SIGNUP_ERROR;
};

export const clearUserSignUpError = (): ClearUserSignUpErrorAction => ({
  type: CLEAR_USER_SIGNUP_ERROR,
});

type FetchUserProfileAction = {
  type: typeof FETCH_USER_PROFILE;
};

export const fetchUserProfile = (): FetchUserProfileAction => ({
  type: FETCH_USER_PROFILE,
});

type FetchUserProfileErrorAction = {
  type: typeof FETCH_USER_PROFILE_ERROR;
  payload: {
    error: string;
  };
  meta: {
    toast: {
      message: string;
    };
  };
};

export const fetchUserProfileError = (
  message: string
): FetchUserProfileErrorAction => ({
  type: FETCH_USER_PROFILE_ERROR,
  payload: {
    error: message,
  },
  meta: {
    toast: {
      message,
    },
  },
});

type FetchNewAccessTokenAction = {
  type: typeof FETCH_NEW_ACCESS_TOKEN;
};

export const fetchNewAccessToken = (): FetchNewAccessTokenAction => ({
  type: FETCH_NEW_ACCESS_TOKEN,
});

type FetchNewAccessTokenSuccessAction = {
  type: typeof FETCH_NEW_ACCESS_TOKEN_SUCCESS;
  payload: {
    token: string;
  };
};

export const fetchNewAccessTokenSuccess = (
  token: string
): FetchNewAccessTokenSuccessAction => ({
  type: FETCH_NEW_ACCESS_TOKEN_SUCCESS,
  payload: { token },
});

type FetchNewAccessTokenFailureAction = {
  type: typeof FETCH_NEW_ACCESS_TOKEN_FAILURE;
};

export const fetchNewAccessTokenFailure =
  (): FetchNewAccessTokenFailureAction => ({
    type: FETCH_NEW_ACCESS_TOKEN_FAILURE,
  });

type UpdateUserInfoOnStateAction = {
  type: typeof UPDATE_USER_INFO_ON_STATE;
  payload: {
    user: User;
  };
};

// used to update only user details
export const updateUserInfoOnState = (
  user: User
): UpdateUserInfoOnStateAction => ({
  type: UPDATE_USER_INFO_ON_STATE,
  payload: { user },
});

type ResetAuthDependentStateAction = {
  type: typeof RESET_AUTH_DEPENDENT_STATE;
};

export const resetAuthDependentState = (): ResetAuthDependentStateAction => ({
  type: RESET_AUTH_DEPENDENT_STATE,
});

export type Credentials = {
  email: string;
  password: string;
};

type LoginAction = {
  type: typeof LOGIN;
  payload: {
    credentials: Credentials;
    routeAfterLoginSuccess?: View;
    afterAuthAction?: AfterAuthAction;
  };
};

export const login = (
  credentials: Credentials,
  routeAfterLoginSuccess?: View,
  afterAuthAction?: AfterAuthAction
): LoginAction => {
  return {
    type: LOGIN,
    payload: {
      credentials,
      routeAfterLoginSuccess,
      afterAuthAction,
    },
  };
};

export type LoginOAuthOIDCAction = {
  type: typeof LOGIN_OAUTH_OIDC;
  payload: { idToken: string; afterAuthAction?: AfterAuthAction };
};

export const loginOAuthOIDC = (
  idToken: string,
  afterAuthAction?: AfterAuthAction
): LoginOAuthOIDCAction => {
  return {
    type: LOGIN_OAUTH_OIDC,
    payload: {
      idToken,
      afterAuthAction,
    },
  };
};

export interface LoginLODirectUserAction {
  type: typeof LOGIN_LO_DIRECT_USER;
  payload: {
    credentials: Credentials;
    afterAuthAction: AfterAuthAction;
  };
}

export const loginLODirectUser = (
  credentials: Credentials,
  afterAuthAction: AfterAuthAction
): LoginLODirectUserAction => {
  return {
    type: LOGIN_LO_DIRECT_USER,
    payload: {
      credentials,
      afterAuthAction,
    },
  };
};

type LoginErrorAction = {
  type: typeof LOGIN_ERROR;
  payload: { errorMessage: string };
  meta: {
    analytics: {
      event: typeof EVENTS.SIGNIN_ERROR;
    };
  };
};

export const loginError = (errorMessage: string): LoginErrorAction => ({
  type: LOGIN_ERROR,
  payload: { errorMessage },
  meta: {
    analytics: {
      event: EVENTS.SIGNIN_ERROR,
    },
  },
});

type UserTokenObj = {
  user: User | null;
  token: string;
};

type LoginSuccessAction = {
  type: typeof LOGIN_SUCCESS;
  payload: UserTokenObj;
};

export const loginSuccess = ({
  user,
  token,
}: UserTokenObj): LoginSuccessAction => ({
  type: LOGIN_SUCCESS,
  payload: { user, token },
});

type LogoutSuccessAction = {
  type: typeof LOGOUT_SUCCESS;
  payload: { token: string };
};

export const logoutSuccess = (token: string): LogoutSuccessAction => ({
  type: LOGOUT_SUCCESS,
  payload: { token },
});

type LogOutAction = {
  type: typeof LOGOUT;
  meta: {
    analytics: {
      event: typeof EVENTS.CLICK_NAV_LOGOUT;
    };
  };
};

export const logOut = (): LogOutAction => ({
  type: LOGOUT,
  meta: {
    analytics: {
      event: EVENTS.CLICK_NAV_LOGOUT,
    },
  },
});

export type LogOutThenRedirectAction = {
  type: typeof LOGOUT_THEN_REDIRECT;
  payload: {
    url: string;
  };
  meta: {
    analytics: {
      event: typeof EVENTS.CLICK_NAV_LOGOUT;
    };
  };
};

export const logOutThenRedirect = (url: string): LogOutThenRedirectAction => ({
  type: LOGOUT_THEN_REDIRECT,
  payload: {
    url,
  },
  meta: {
    analytics: {
      event: EVENTS.CLICK_NAV_LOGOUT,
    },
  },
});

type AuthModalHideAction = {
  type: typeof AUTH_MODAL_HIDE;
};

export const authModalHide = (): AuthModalHideAction => ({
  type: AUTH_MODAL_HIDE,
});

type AuthModalShowAction = {
  type: typeof AUTH_MODAL_SHOW;
  payload?: {
    startingPage?: AuthModalPage;
    subHeadingText?: AuthModalSubHeadingText;
    headingText?: AuthModalHeadingText;
    afterAuthAction?: AfterAuthAction;
  };
};

/* Show signup/login modal and pass action to dispatch immediately after successful authentication */
export const authModalShow = (
  showAuthModalPayload?: AuthModalShowAction['payload']
): AuthModalShowAction => ({
  type: AUTH_MODAL_SHOW,
  payload: showAuthModalPayload,
});

type InitForgotPasswordAction = {
  type: typeof INIT_FORGOT_PASSWORD;
};

export const initForgotPassword = (): InitForgotPasswordAction => ({
  type: INIT_FORGOT_PASSWORD,
});

export type SubmitForgotPasswordAction = {
  type: typeof SUBMIT_FORGOT_PASSWORD;
  payload: {
    email: string;
    type: 'lo-direct' | null;
    hideSuccessToast: boolean;
  };
};

export const submitForgotPassword = (
  email: string,
  type: 'lo-direct' | null,
  hideSuccessToast: boolean
): SubmitForgotPasswordAction => ({
  type: SUBMIT_FORGOT_PASSWORD,
  payload: { email, type, hideSuccessToast },
});

type SubmitForgotPasswordSuccessAction = {
  type: typeof SUBMIT_FORGOT_PASSWORD_SUCCESS;
  meta: {
    toast: {
      message: string;
    };
  };
};

export const submitForgotPasswordSuccess = (
  message: string
): SubmitForgotPasswordSuccessAction => ({
  type: SUBMIT_FORGOT_PASSWORD_SUCCESS,
  meta: {
    toast: {
      message,
    },
  },
});

type SubmitForgotPasswordErrorAction = {
  type: typeof SUBMIT_FORGOT_PASSWORD_ERROR;
  payload: { error: ErrorObj };
};

export const submitForgotPasswordError = (
  error: ErrorObj
): SubmitForgotPasswordErrorAction => ({
  type: SUBMIT_FORGOT_PASSWORD_ERROR,
  payload: { error },
});

type SubmitPasswordResetThenRedirectAction = {
  type: typeof SUBMIT_PASSWORD_RESET_THEN_REDIRECT;
  payload: { newPassword: string; routeAfterReset: View };
};

export const submitPasswordResetThenRedirect = (
  newPassword: string,
  routeAfterReset: View
): SubmitPasswordResetThenRedirectAction => ({
  type: SUBMIT_PASSWORD_RESET_THEN_REDIRECT,
  payload: { newPassword, routeAfterReset },
});

type SubmitPasswordResetSuccessAction = {
  type: typeof SUBMIT_PASSWORD_RESET_SUCCESS;
  meta: {
    toast: {
      message: string;
    };
  };
};

export const submitPasswordResetSuccess = (
  message: string
): SubmitPasswordResetSuccessAction => ({
  type: SUBMIT_PASSWORD_RESET_SUCCESS,
  meta: {
    toast: {
      message,
    },
  },
});

export type UpdateUserProfileAction = {
  type: typeof UPDATE_USER_PROFILE;
  payload: { userInfo: Partial<User>; disableToast?: boolean; toastMessage?: string; };
};

export const updateUserProfile = (
  userInfo: Partial<User>,
  disableToast?: boolean,
  toastMessage?: string,
): UpdateUserProfileAction => ({
  type: UPDATE_USER_PROFILE,
  payload: { userInfo, disableToast, toastMessage },
});

type UpdateUserProfileSuccessAction = {
  type: typeof UPDATE_USER_PROFILE_SUCCESS;
  payload: { message: string };
  meta: {
    toast: {
      message: string;
    };
  };
};

export const updateUserProfileSuccess = (
  message: string
): UpdateUserProfileSuccessAction => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  payload: { message },
  meta: {
    toast: {
      message,
    },
  },
});

type UpdateUserProfileErrorAction = {
  type: typeof UPDATE_USER_PROFILE_ERROR;
  payload: { error: ErrorObj };
};

export const updateUserProfileError = (
  error: ErrorObj
): UpdateUserProfileErrorAction => ({
  type: UPDATE_USER_PROFILE_ERROR,
  payload: { error },
});

export type UpdateUserEmailAction = {
  type: typeof UPDATE_USER_EMAIL;
  payload: { userInfo: Partial<User> };
};

export const updateUserEmail = (
  userInfo: Partial<User>
): UpdateUserEmailAction => ({
  type: UPDATE_USER_EMAIL,
  payload: { userInfo },
});

type UpdateUserEmailSuccessAction = {
  type: typeof UPDATE_USER_EMAIL_SUCCESS;
};

export const updateUserEmailSuccess = (): UpdateUserEmailSuccessAction => ({
  type: UPDATE_USER_EMAIL_SUCCESS,
});

type UpdateUserEmailErrorAction = {
  type: typeof UPDATE_USER_EMAIL_ERROR;
  payload: { error: ErrorObj };
};

export const updateUserEmailError = (
  error: ErrorObj
): UpdateUserEmailErrorAction => ({
  type: UPDATE_USER_EMAIL_ERROR,
  payload: { error },
});

type UpdateEmailModalOpenAction = {
  type: typeof UPDATE_EMAIL_MODAL_SHOW;
};

export const updateEmailModalOpen = (): UpdateEmailModalOpenAction => ({
  type: UPDATE_EMAIL_MODAL_SHOW,
});

type UpdateEmailModalHideAction = {
  type: typeof UPDATE_EMAIL_MODAL_HIDE;
};

export const updateEmailModalHide = (): UpdateEmailModalHideAction => ({
  type: UPDATE_EMAIL_MODAL_HIDE,
});

type UpdateResetPasswordTokenAction = {
  type: typeof UPDATE_RESET_PASSWORD_TOKEN;
  payload: { token: string };
};

export const updateResetPasswordToken = (
  token: string
): UpdateResetPasswordTokenAction => ({
  type: UPDATE_RESET_PASSWORD_TOKEN,
  payload: { token },
});

type VerifyResetPasswordFailureAction = {
  type: typeof VERIFY_RESET_PASSWORD_FAILURE;
  payload: { error: ErrorObj };
};

export const verifyResetPasswordFailure = (
  error: ErrorObj
): VerifyResetPasswordFailureAction => ({
  type: VERIFY_RESET_PASSWORD_FAILURE,
  payload: { error },
});

export type CreateUserAction = {
  type: typeof CREATE_USER;
  payload: {
    userInfo: {
      first_name: string;
      last_name: string;
      email: string;
      phone: string | null;
      password: string;
    };
    afterAuthAction: AfterAuthAction;
  };
};

export const createUser = (
  userInfo: CreateUserAction['payload']['userInfo'],
  afterAuthAction: AfterAuthAction
): CreateUserAction => ({
  type: CREATE_USER,
  payload: { userInfo, afterAuthAction },
});

export type CreateLODirectClientFromInviteAction = {
  type: typeof CREATE_LO_DIRECT_CLIENT_FROM_INVITE;
  payload: {
    userInfo: {
      loan_officer_id: string;
      first_name: string;
      last_name: string;
      email: string;
      phone: string | null;
      password: string;
      is_buyer: null;
      slug: null;
      loan_amount: null;
      buying_price_point: null;
    };
  };
};

export const createLODirectClientFromInvite = (userInfo: {
  loan_officer_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string | null;
  password: string;
}): CreateLODirectClientFromInviteAction => ({
  type: CREATE_LO_DIRECT_CLIENT_FROM_INVITE,
  payload: {
    userInfo: {
      ...userInfo,
      is_buyer: null,
      slug: null,
      loan_amount: null,
      buying_price_point: null,
    },
  },
});

export type SelfCreateLODirectClientAction = {
  type: typeof SELF_CREATE_LO_DIRECT_CLIENT;
  payload: {
    userInfo: {
      loan_officer_id: string;
      first_name: string;
      last_name: string;
      email: string;
      phone: string | null;
      password: string;
      is_buyer: boolean;
      slug: string;
      loan_amount: number | null;
      buying_price_point: number | null;
    };
  };
};

export const selfCreateLODirectClient = (
  userInfo: SelfCreateLODirectClientAction['payload']['userInfo']
): SelfCreateLODirectClientAction => ({
  type: SELF_CREATE_LO_DIRECT_CLIENT,
  payload: { userInfo },
});

export interface CreateLODirectUserAction {
  type: typeof CREATE_LO_DIRECT_USER;
  payload: {
    userInfo: LODirectUserSignupFields;
    afterAuthAction: AfterAuthAction;
    inviteToken?: string;
  };
}

export const createLODirectUser = (
  userInfo: LODirectUserSignupFields,
  afterAuthAction: AfterAuthAction,
  inviteToken?: string
): CreateLODirectUserAction => ({
  type: CREATE_LO_DIRECT_USER,
  payload: { userInfo, afterAuthAction, inviteToken },
});

type CreateUserSuccessAction = {
  type: typeof CREATE_USER_SUCCESS;
  payload: UserTokenObj;
};

export const createUserSuccess = ({
  user,
  token,
}: UserTokenObj): CreateUserSuccessAction => ({
  type: CREATE_USER_SUCCESS,
  payload: { user, token },
});

type CreateUserErrorPayload = {
  errorMessage: string;
  email?: string | null;
  firstname?: string | null;
  lastname?: string | null;
};
type CreateUserErrorAction = {
  type: typeof CREATE_USER_ERROR;
  payload: CreateUserErrorPayload;
  meta: {
    analytics: {
      event: typeof SIGNUP_ERROR;
      parentEvent: typeof PARENT_EVENTS.CLICK_SIGNUP;
    };
  };
};

export const createUserError = ({
  errorMessage,
  email,
  firstname,
  lastname,
}: CreateUserErrorPayload): CreateUserErrorAction => ({
  type: CREATE_USER_ERROR,
  payload: { errorMessage, email, firstname, lastname },
  meta: {
    analytics: {
      event: SIGNUP_ERROR,
      parentEvent: PARENT_EVENTS.CLICK_SIGNUP,
    },
  },
});

type CreateUserValidationErrorsPayload = {
  errors: any;
  email?: string | null;
  firstname?: string | null;
  lastname?: string | null;
};
type CreateUserValidationErrorsAction = {
  type: typeof CREATE_USER_VALIDATION_ERRORS;
  payload: CreateUserValidationErrorsPayload;
  meta: {
    analytics: {
      event: typeof SIGNUP_ERROR;
      parentEvent: typeof PARENT_EVENTS.CLICK_SIGNUP;
    };
  };
};

export const createUserValidationErrors = ({
  errors,
  email,
  firstname,
  lastname,
}: CreateUserValidationErrorsPayload): CreateUserValidationErrorsAction => ({
  type: CREATE_USER_VALIDATION_ERRORS,
  payload: { errors, email, firstname, lastname },
  meta: {
    analytics: {
      event: SIGNUP_ERROR,
      parentEvent: PARENT_EVENTS.CLICK_SIGNUP,
    },
  },
});

type ConfirmNewUserAction = {
  type: typeof CONFIRM_NEW_USER;
  payload: { token: string };
};

export const confirmNewUser = (token: string): ConfirmNewUserAction => ({
  type: CONFIRM_NEW_USER,
  payload: { token },
});

type ConfirmNewUserSuccessAction = {
  type: typeof CONFIRM_NEW_USER_SUCCESS;
  meta: {
    toast: {
      message: string;
    };
  };
};

export const confirmNewUserSuccess = (
  message: string
): ConfirmNewUserSuccessAction => ({
  type: CONFIRM_NEW_USER_SUCCESS,
  meta: {
    toast: {
      message,
    },
  },
});

type ConfirmNewUserErrorAction = {
  type: typeof CONFIRM_NEW_USER_ERROR;
  payload: { error: string };
};

export const confirmNewUserError = (
  error: string
): ConfirmNewUserErrorAction => ({
  type: CONFIRM_NEW_USER_ERROR,
  payload: { error },
});

type ConfirmNewUserExpiredTokenAction = {
  type: typeof CONFIRM_NEW_USER_EXPIRED;
  payload: { error: string; token: string };
};

export const confirmNewUserExpiredToken = (
  error: string,
  token: string
): ConfirmNewUserExpiredTokenAction => ({
  type: CONFIRM_NEW_USER_EXPIRED,
  payload: { error, token },
});

export type ResendConfirmUserEmailAction = {
  type: typeof RESEND_CONFIRM_USER_EMAIL;
  payload: { token: string | null };
};

export const resendConfirmUserEmail = (
  token: string | null
): ResendConfirmUserEmailAction => ({
  type: RESEND_CONFIRM_USER_EMAIL,
  payload: { token },
});

type ResendConfirmUserEmailSuccessAction = {
  type: typeof RESEND_CONFIRM_USER_EMAIL_SUCCESS;
  payload: { message: string };
};

export const resendConfirmUserEmailSuccess = (
  message: string
): ResendConfirmUserEmailSuccessAction => ({
  type: RESEND_CONFIRM_USER_EMAIL_SUCCESS,
  payload: { message },
});

type ResendConfirmUserEmailFailureAction = {
  type: typeof RESEND_CONFIRM_USER_EMAIL_FAILURE;
  payload: { message: string };
};

export const resendConfirmUserEmailFailure = (
  message: string
): ResendConfirmUserEmailFailureAction => ({
  type: RESEND_CONFIRM_USER_EMAIL_FAILURE,
  payload: { message },
});

type CloseResendConfirmEmailSuccessModalAction = {
  type: typeof CLOSE_RESEND_CONFIRM_EMAIL_SUCCESS_MODAL;
};
export const closeResendConfirmEmailSuccessModal =
  (): CloseResendConfirmEmailSuccessModalAction => ({
    type: CLOSE_RESEND_CONFIRM_EMAIL_SUCCESS_MODAL,
  });

type AcceptTermsAndConditionsAction = {
  type: typeof ACCEPT_TERMS_AND_CONDITIONS;
  meta: {
    analytics: {
      event: typeof EVENTS.GENERIC_TERMS_MODAL_CONFIRMED;
    };
  };
};
export const acceptTermsAndConditions = (): AcceptTermsAndConditionsAction => ({
  type: ACCEPT_TERMS_AND_CONDITIONS,
  meta: {
    analytics: {
      event: EVENTS.GENERIC_TERMS_MODAL_CONFIRMED,
    },
  },
});

type AcceptTermsAndConditionsSuccessAction = {
  type: typeof ACCEPT_TERMS_AND_CONDITIONS_SUCCESS;
  payload: {
    termsAcceptedDate: string;
  };
};
export const acceptTermsAndConditionsSuccess = (
  termsAcceptedDate: string
): AcceptTermsAndConditionsSuccessAction => ({
  type: ACCEPT_TERMS_AND_CONDITIONS_SUCCESS,
  payload: {
    termsAcceptedDate,
  },
});

type UrqlInitiatedUnauthorizedLogoutAction = {
  type: typeof URQL_INITIATED_UNAUTHORIZED_LOGOUT;
};
export const urqlInitiatedUnauthorizedLogout =
  (): UrqlInitiatedUnauthorizedLogoutAction => ({
    type: URQL_INITIATED_UNAUTHORIZED_LOGOUT,
  });

export type EnsureLoggedInThenAction = {
  type: typeof ENSURE_LOGGED_IN_THEN_ACTION;
  payload: {
    afterAuthAction: NonNullable<AfterAuthAction>;
    startingPage?: AuthModalPage;
    subHeadingText?: AuthModalSubHeadingText;
    headingText?: AuthModalHeadingText;
  };
};
export const ensureLoggedInThenAction = (
  action: NonNullable<AfterAuthAction>,
  options?: {
    startingPage?: EnsureLoggedInThenAction['payload']['startingPage'];
    subHeadingText?: EnsureLoggedInThenAction['payload']['subHeadingText'];
    headingText?: EnsureLoggedInThenAction['payload']['headingText'];
  }
): EnsureLoggedInThenAction => ({
  type: ENSURE_LOGGED_IN_THEN_ACTION,
  payload: {
    afterAuthAction: action,
    startingPage: options?.startingPage,
    subHeadingText: options?.subHeadingText,
    headingText: options?.headingText,
  },
});

export type PatchLoDirectUserProfileAction = {
  type: typeof PATCH_LO_DIRECT_USER_PROFILE;
  payload: Partial<LOUserApiResponse>;
};
export const patchLoDirectUserProfileAction = (
  payload: Partial<LOUserApiResponse>
): PatchLoDirectUserProfileAction => ({
  type: PATCH_LO_DIRECT_USER_PROFILE,
  payload,
});

export type DeleteLoDirectUserImageAction = {
  type: typeof DELETE_LO_DIRECT_USER_IMAGE;
  payload: LOUserApiResponse;
};
export const deleteLoDirectUserImageAction = (
  payload: LOUserApiResponse
): DeleteLoDirectUserImageAction => ({
  type: DELETE_LO_DIRECT_USER_IMAGE,
  payload,
});

export type PostLoDirectUserImageAction = {
  type: typeof POST_LO_DIRECT_USER_IMAGE;
  payload: {
    image: File;
    userInfo: LOUserApiResponse;
  };
};
export const postLoDirectUserImageAction = (payload: {
  image: File;
  userInfo: LOUserApiResponse;
}): PostLoDirectUserImageAction => ({
  type: POST_LO_DIRECT_USER_IMAGE,
  payload,
});

export type AuthAction =
  | DeleteLoDirectUserImageAction
  | PostLoDirectUserImageAction
  | PatchLoDirectUserProfileAction
  | FetchUserProfileAction
  | FetchUserProfileErrorAction
  | FetchNewAccessTokenAction
  | FetchNewAccessTokenSuccessAction
  | FetchNewAccessTokenFailureAction
  | UpdateUserInfoOnStateAction
  | ResetAuthDependentStateAction
  | LoginAction
  | LoginLODirectUserAction
  | LoginErrorAction
  | LoginSuccessAction
  | LogoutSuccessAction
  | LogOutAction
  | LogOutThenRedirectAction
  | AuthModalHideAction
  | AuthModalShowAction
  | InitForgotPasswordAction
  | SubmitForgotPasswordAction
  | SubmitForgotPasswordSuccessAction
  | SubmitForgotPasswordErrorAction
  | SubmitPasswordResetThenRedirectAction
  | SubmitPasswordResetSuccessAction
  | UpdateUserProfileAction
  | UpdateUserProfileSuccessAction
  | UpdateUserProfileErrorAction
  | UpdateUserEmailAction
  | UpdateUserEmailSuccessAction
  | UpdateUserEmailErrorAction
  | UpdateEmailModalOpenAction
  | UpdateEmailModalHideAction
  | UpdateResetPasswordTokenAction
  | VerifyResetPasswordFailureAction
  | CreateUserAction
  | CreateLODirectUserAction
  | CreateUserSuccessAction
  | CreateUserErrorAction
  | CreateUserValidationErrorsAction
  | ConfirmNewUserAction
  | ConfirmNewUserSuccessAction
  | ConfirmNewUserErrorAction
  | ConfirmNewUserExpiredTokenAction
  | ResendConfirmUserEmailAction
  | ResendConfirmUserEmailSuccessAction
  | ResendConfirmUserEmailFailureAction
  | CloseResendConfirmEmailSuccessModalAction
  | AcceptTermsAndConditionsAction
  | AcceptTermsAndConditionsSuccessAction
  | UrqlInitiatedUnauthorizedLogoutAction
  | EnsureLoggedInThenAction
  | LoginOAuthOIDCAction
  | ClearUserSignUpErrorAction
  | SelfCreateLODirectClientAction
  | CreateLODirectClientFromInviteAction;
