import { call, put, select } from 'redux-saga/effects';
import { routeChange } from '@src/redux-saga-router-plus/actions';

import { searchClearSetMapLocation } from '@client/store/actions/search.actions';
import { PlaceLookupResultWithPlace } from '@client/store/types/place-search';
import { getIsInitialRoute } from '@client/store/selectors/router.selectors';
import { ParamsType, QueryType } from '@src/redux-saga-router-plus/types';
import { View } from '@client/routes/constants';
import { handlePlaceLookupSuccess } from '@client/store/sagas/place-search.saga';
import { graphQLApiClient } from '@client/services/graphql-api-client';

/* Here we're rendering the maps-search page, set to the location of a specific market
 * indicated by the URL params */
export default function* getLatLngCoordsForPlace({
  view,
  params,
  query,
}: {
  view: View;
  params: ParamsType;
  query: QueryType;
}) {
  const { placeSlug } = params;
  const isInitialRoute = (yield select(getIsInitialRoute)) as ReturnType<
    typeof getIsInitialRoute
  >;

  /* Clear the current map location to prevent the most recently set map location from persisting when
   * navigating back to the SRP client-side. This causes the map to be unrendered. The map is rendered
   * again when a map location is set as part of `handlePlaceLookupSuccess` */
  yield put(searchClearSetMapLocation());
  const data = (yield call(
    [graphQLApiClient, graphQLApiClient.getPlaceLookupDetails],
    { placeSlug }
  )) as PlaceLookupResultWithPlace;

  if (!data?.placeLookup?.place?.placeId) {
    yield put(routeChange({ view: View.PAGE_404 }));
  } else {
    const { place } = data.placeLookup;
    const { mlscoverage } = place;
    yield call(handlePlaceLookupSuccess, {
      data,
      constrainedToPlace: {
        placeId: place.placeId,
        mlsCoverage: mlscoverage,
        city: null,
        state: null,
        zipcode: null,
      },
      /* If we're loading this route on init, the property list has been set on the server-side,
       * and doesn't need to be reloaded once the map renders */
      shouldResetPropertyList: !isInitialRoute,
    });
  }
}
