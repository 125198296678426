import { createSelector } from 'reselect';
import { ReduxState } from '../types/redux-state';
import {
  getIsLoggedIn,
  getUserEmailAddress,
  getUserTermsAcceptedDate,
} from '@client/store/selectors/auth.selectors';
import { getCurrentQuery } from '@src/redux-saga-router-plus/selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { ACTIVE_MODAL_URL_KEY } from '@client/store/constants';
import { Query } from '@client/routes/constants';

function getEmailOptInState(state: ReduxState) {
  return state.emailOptIn;
}

// Use this to open and close the email opt-in modal
export const getIsDisplayingEmailOptInModal = createSelector(
  getEmailOptInState,
  (emailOptInState) => emailOptInState.isDisplayingEmailOptInModal
);

export const getIsForceTermsAcceptanceEnabled = (state) =>
  getIsFeatureEnabled('force_terms_acceptance')(state);

/**
 * If a user logged in, 'should_display_email_opt_in_modal' flag is enabled,
 * and there is no user email address,
 * this will return true, otherwise it will return false
 */
export const getIsEmailOptInModalAllowedToOpen = createSelector(
  getIsLoggedIn,
  getUserEmailAddress,
  (state) => getIsFeatureEnabled('should_display_email_opt_in_modal')(state),
  getIsForceTermsAcceptanceEnabled,
  getUserTermsAcceptedDate,
  (
    isLoggedIn,
    userEmailAddressExists,
    isDisplayingEmailOptInFeatureEnabled,
    isForceTermsAcceptanceEnabled,
    areTermsAccepted
  ) =>
    Boolean(
      isLoggedIn &&
        !userEmailAddressExists &&
        isDisplayingEmailOptInFeatureEnabled &&
        (!isForceTermsAcceptanceEnabled || areTermsAccepted)
    )
);

export const getIsEmailOptInModalAllowedToOpenAndNoOtherModalOpen =
  createSelector(
    getIsEmailOptInModalAllowedToOpen,
    getCurrentQuery,
    (isEmailOptInModalAllowedToOpen, query: Query) => {
      return !query?.[ACTIVE_MODAL_URL_KEY] && isEmailOptInModalAllowedToOpen;
    }
  );

export const getEmailOptInUpdateStatus = createSelector(
  getEmailOptInState,
  (emailOptInState) => emailOptInState.emailUpdateStatus
);

export const getEmailOptInSubmitErrorMsg = createSelector(
  getEmailOptInState,
  (emailOptInState) => emailOptInState.errorMessage
);

export const getOptInLocationDisplayStats = createSelector(
  getEmailOptInState,
  (emailOptInState) => emailOptInState.placesAlreadyDisplayed
);

export const getDidEmailOptInDisplayAfterSaveSearch = createSelector(
  getOptInLocationDisplayStats,
  (locationState) => locationState.saveSearch
);
