import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import geojsonExtent from '@mapbox/geojson-extent';

import { selectSavedSearch } from '@client/store/actions/saved-search.actions';
import {
  getIsTabletSize,
  getIsSmallSize,
} from '@client/store/selectors/match-media.selectors';
import HorizontalSeparator from '@client/components/generic/HorizontalSeparator';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { getCenterPointAndZoomLevelFromBounds } from '@client/utils/maps.utils';
import { getFullFeatureMapTilePhoto } from '@client/utils/photo.utils';
import { SavedSearchPageCardProps } from '@client/store/types/saved-searches';
import { EVENTS } from '@client/store/analytics-constants';
import CloseIcon from '@client/inline-svgs/close';
import theme from '@client/css-modules/SavedSearchPageCard.css';
import PillButton from '@client/components/generic/PillButton';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { parseCSSAmount } from '@client/utils/string.utils';

const {
  savedSearchPageCardDesktopMapTileWidth,
  savedSearchPageCardTabletMapTileWidth,
  savedSearchPageCardMobileMapTileWidth,
  savedSearchPageCardMapTileHeight,
} = theme;

const parsedSavedSearchPageCardDesktopMapTileWidth = parseCSSAmount(
  savedSearchPageCardDesktopMapTileWidth
);
const parsedSavedSearchPageCardTabletMapTileWidth = parseCSSAmount(
  savedSearchPageCardTabletMapTileWidth
);
const parsedSavedSearchPageCardMobileMapTileWidth = parseCSSAmount(
  savedSearchPageCardMobileMapTileWidth
);
const parsedSavedSearchPageCardMapTileHeight = parseCSSAmount(
  savedSearchPageCardMapTileHeight
);

const SavedSearchPageCard: React.FC<SavedSearchPageCardProps> = ({
  savedSearch,
  savedFiltersSummary,
  setSelectedSearchAndOpenModal,
  isFocused,
}) => {
  const dispatch = useDispatch();
  const isTabletSize = useSelector(getIsTabletSize);
  const isSmallSize = useSelector(getIsSmallSize);
  const handleSavedSearchClick = (id) => {
    dispatch(selectSavedSearch(id, EVENTS.CLICK_SETTINGS_SAVED_SEARCH));
  };

  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const getMapDimensionsForScreenSize = () => {
    if (isTabletSize) {
      //  convert width and height to number before calling maps util method
      return {
        height: +parsedSavedSearchPageCardMapTileHeight,
        width: +parsedSavedSearchPageCardTabletMapTileWidth,
      };
    } else if (isSmallSize) {
      return {
        height: +parsedSavedSearchPageCardMapTileHeight,
        width: +parsedSavedSearchPageCardMobileMapTileWidth,
      };
    } else {
      return {
        height: +parsedSavedSearchPageCardMapTileHeight,
        width: +parsedSavedSearchPageCardDesktopMapTileWidth,
      };
    }
  };

  const { filter } = savedSearch;
  const { sw_lat, sw_lng, ne_lat, ne_lng, geom } = filter;
  let mapTilePhotoUrl;
  const mapSize = getMapDimensionsForScreenSize();

  if (sw_lat && sw_lng && ne_lat && ne_lng) {
    const mapBounds = {
      southWest: { lat: sw_lat, lng: sw_lng },
      northEast: { lat: ne_lat, lng: ne_lng },
    };
    const { center, zoom } = getCenterPointAndZoomLevelFromBounds(
      mapBounds,
      mapSize
    );
    mapTilePhotoUrl = getFullFeatureMapTilePhoto({
      center,
      size: [mapSize.width, mapSize.height],
      zoom,
    });
  } else if (geom) {
    const WSENBounds = geojsonExtent(geom);
    const mapBounds = {
      southWest: { lat: WSENBounds[1], lng: WSENBounds[0] },
      northEast: { lat: WSENBounds[3], lng: WSENBounds[2] },
    };
    const { center, zoom } = getCenterPointAndZoomLevelFromBounds(
      mapBounds,
      mapSize
    );
    mapTilePhotoUrl = getFullFeatureMapTilePhoto({
      center,
      size: [mapSize.width, mapSize.height],
      zoom,
    });
  }

  if(isFocused) {
    closeButtonRef?.current?.focus()
  }
  const { savedSearchCardButtonStyle } = useCobrandStyles();
  return (
    <>
      <HorizontalSeparator
        className={theme.DesktopAndTabletSeparator}
        theme={theme}
      />
      <div className={theme.SavedSearch} data-hc-name="saved-search">
        <div
          data-hc-name="map"
          className={theme.MapContainer}
          style={{
            backgroundImage: mapTilePhotoUrl
              ? `url(${mapTilePhotoUrl})`
              : 'none',
          }}
        />
        <div className={theme.SearchDetails} data-hc-name="search-details">
          <div
            className={theme.SearchName}
            data-hc-name="name"
            role="heading"
            aria-level={2}
          >
            {savedSearch.name}
            {isSmallSize && (
              <button
                type="button"
                data-event-name="click_remove_saved_search"

                aria-label={`remove ${savedSearch.name}`}
                className={theme.MobileCloseIcon}
                onKeyDown={onEnterOrSpaceKey(() =>
                  setSelectedSearchAndOpenModal(savedSearch.searchId)
                )}
                onClick={(_) =>
                  setSelectedSearchAndOpenModal(savedSearch.searchId)
                }
                ref={closeButtonRef}
              >
                <CloseIcon />

              </button>
            )}

          </div>
          <div className={theme.SavedSearchFilters}>
            {!savedFiltersSummary
              ? 'You have saved a city or zip. There are no other filters applied to this saved search.'
              : savedFiltersSummary}
          </div>
          <PillButton
            ariaLabel={`Load homes for ${savedSearch.name}`}
            className={theme.LoadHomesButton}
            onClick={() => handleSavedSearchClick(savedSearch.searchId)}
            style={savedSearchCardButtonStyle}
          >
            Load homes
          </PillButton>
          <HorizontalSeparator
            className={theme.MobileSeparator}
            theme={theme}
          />
        </div>
        <div className={theme.CloseIconContainer}>
          {!isSmallSize && (
            <button
              type="button"
              data-event-name="click_remove_saved_search"
              aria-label={`remove ${savedSearch.name}`}
              className={theme.MobileCloseIcon}
              onKeyDown={onEnterOrSpaceKey(() =>
                setSelectedSearchAndOpenModal(savedSearch.searchId)
              )}
              onClick={(_) =>
                setSelectedSearchAndOpenModal(savedSearch.searchId)
              }
              ref={closeButtonRef}
            >

              <CloseIcon />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default SavedSearchPageCard;
