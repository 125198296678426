import { get } from 'lodash';
import root from 'window-or-global';

const NON_PROD_HOSTNAME_REGEX =
  /([a-z]+)\.(dev|stage|qa|demo|giantsqa)\.ch\.housecanary\.net/;

const PROD_HOSTNAME_REGEX = /([a-z]+)\.comehome\.com/;

export type OptionsForEnv = {
  getNonProdEnv: () => string;
  getSentryURL: () => string;
  getIsAllowedFeatureFlagUrlAndCustomizationDataJSONOverride: () => boolean;
  hostname: string | null;
  isProd: boolean;
  isLocal: boolean;
};

export type ConfigMap = {
  cobrandOauthSignInRedirectUrl?: string;
  cobrandSamlSignInRedirectUrl?: string;
  comehomeBrandedHostname: string;
  consumerAppHostname: string;
  consumerAPI: string;
  beaconURL: string;
  graphQlURL: string;
  sentryURL: string;
  cobrandAnalyticsReportingScriptUrls: {
    chase: string | null;
  };
  vectilesURL: string;
  allowFeatureFlagAndCustomizationDataJSONOverride: boolean;
  stripePublishableKey: string;
  stripePricingTableId: string;
  stripeBillingLink: string;
  coveredInsuranceIframeUrl: string;
};

export type Config = {
  opts: OptionsForEnv;
  getConfigMap: () => ConfigMap;
  getProdConfigMap: () => ConfigMap;
  getNonProdConfigMap: () => ConfigMap;
};

export class Options {
  constructor(
    hostname: string | null,
    port: string | boolean,
    localApiEnv: string | null
  ) {
    this.hostname = hostname;
    this.localApiEnv = localApiEnv;
    const hostMatches =
      hostname !== null ? hostname.match(PROD_HOSTNAME_REGEX) || [] : [];
    this.isProd =
      hostMatches.length > 0 /* When on client */ ||
      localApiEnv === 'prod'; /* When on server */
    /* Accounts for local, localhost, or any subdomain with a port number */
    this.isLocal = !!port;
  }

  hostname: string | null = null;
  localApiEnv: string | null = '';
  isProd: boolean = false;
  isLocal: boolean = false;

  getNonProdEnv(): string {
    const matches = this.hostname
      ? this.hostname.match(NON_PROD_HOSTNAME_REGEX) || []
      : [];
    /* Destructure, using localApiEnv when on the server (where hostname == '') */
    let [, , env = this.localApiEnv] = matches;
    if (typeof env === 'string' && env.endsWith('-new')) {
      env = env.slice(0, -4);
    }
    /* We know that when hostname is an empty string (on the server) that localApiEnv will be defined */
    return env as string;
  }

  getSentryURL(): string {
    if (this.isLocal && !process.env.FORCE_SENTRY) {
      return '';
    }
    if (this.isProd) {
      return 'https://1891ca9ff5bc416bbb0349a074c3b41f@sentry.io/1258985';
    }
    const env = this.getNonProdEnv();
    if (env === 'qa' || env === 'stage' || env === 'giantsqa') {
      return 'https://2b44c6e26dfc4117be4b4a4325a65089@sentry.io/1229140';
    } else if (env === 'demo') {
      return 'https://b14759fb6340490da1c1b2ff47f00cf2@sentry.io/1381732';
    }
    return 'https://867c221a770a4647b29dfa07072cf6be@sentry.io/1195417';
  }

  getIsAllowedFeatureFlagUrlAndCustomizationDataJSONOverride(): boolean {
    const env = this.getNonProdEnv();
    if (env === 'stage' || env === 'qa' || env === 'demo' || env === 'dev') {
      return true;
    }
    return false;
  }
}

export class ClientConfig {
  constructor(opts: OptionsForEnv) {
    this.opts = opts;
  }

  opts: OptionsForEnv = {
    getNonProdEnv: (): string => '',
    getSentryURL: (): string => '',
    getIsAllowedFeatureFlagUrlAndCustomizationDataJSONOverride: (): boolean =>
      false,
    hostname: null,
    isProd: false,
    isLocal: false,
  };

  getConfigMap(): ConfigMap {
    if (this.opts.isProd) {
      let confMap = this.getProdConfigMap();
      // TEMP: Use a different API set for CloudFlare enabled host name
      // to support POC
      if (this.opts.hostname === 'wwwcf.comehome.com') {
        confMap.consumerAPI = 'https://apicf.comehome.com';
        confMap.graphQlURL = 'https://property-graph-cf.comehome.com';
      }
      return confMap;
    }
    return this.getNonProdConfigMap();
  }

  getProdConfigMap(): ConfigMap {
    const h = this.opts.hostname;
    const subdomain = h?.split('.')[0];

    return {
      cobrandOauthSignInRedirectUrl: `https://api.comehome.com/v1/oauth/${subdomain}/authorize`,
      cobrandSamlSignInRedirectUrl:
        'https://api.comehome.com/v2/auth/saml/login',
      comehomeBrandedHostname: 'https://www.comehome.com',
      consumerAppHostname: `https://${h}`,
      consumerAPI: 'https://api.comehome.com',
      beaconURL: 'https://beacon.comehome.com',
      graphQlURL: 'https://property-graph.comehome.com',
      sentryURL: this.opts.getSentryURL(),
      cobrandAnalyticsReportingScriptUrls: {
        chase:
          'https://www.chase.com/apps/chase/clientlibs/foundation/scripts/Reporting.js',
      },
      vectilesURL: 'https://vectiles.comehome.com',
      allowFeatureFlagAndCustomizationDataJSONOverride:
        this.opts.getIsAllowedFeatureFlagUrlAndCustomizationDataJSONOverride(),
      stripePublishableKey:
        'pk_live_51NBJTZAMrSx38qVqDx08mdcURhKWQ1hwYbiryqUBU69MMPwByLhi3vntDP76EhrMOuZagmWj93ef44S2NCdvB5uV008Fj2FcAU',
      stripePricingTableId: 'prctbl_1NBLrrAMrSx38qVqUoytgsHA',
      stripeBillingLink:
        'https://billing.stripe.com/p/login/aEU2c8757dxqcneaEE',
      coveredInsuranceIframeUrl: 'https://getquote.itscovered.com/housecanary',
    };
  }

  getNonProdConfigMap(): ConfigMap {
    const env = this.opts.getNonProdEnv();
    const h = this.opts.hostname;
    const subdomain = h?.split('.')[0];
    const stripeConfig = getStripeConfigForNonProdEnv(env);

    return {
      cobrandOauthSignInRedirectUrl: `https://api.${env}.ch.housecanary.net/v1/oauth/${subdomain}/authorize`,
      cobrandSamlSignInRedirectUrl: `https://api.${env}.ch.housecanary.net/v2/auth/saml/login`,
      comehomeBrandedHostname: this.opts.isLocal
        ? process.env.HTTP_PROTOCOL === 'true'
          ? `http://www.local.${env}.ch.housecanary.net:${window.location.port}`
          : `https://www.local.${env}.ch.housecanary.net:${window.location.port}`
        : `https://www.${env}.ch.housecanary.net`,
      consumerAppHostname: this.opts.isLocal
        ? process.env.HTTP_PROTOCOL === 'true'
          ? `http://${this.opts.hostname}:${window.location.port}`
          : `https://${this.opts.hostname}:${window.location.port}`
        : `https://${this.opts.hostname}`,
      consumerAPI: `https://api.${env}.ch.housecanary.net`,
      beaconURL: `https://beacon.${env}.ch.housecanary.net`,
      graphQlURL: `https://property-graph.${env}.ch.housecanary.net`,
      sentryURL: this.opts.getSentryURL(),
      cobrandAnalyticsReportingScriptUrls: {
        chase:
          env === 'demo' || env === 'giantsqa'
            ? 'https://wwwq3.chase.com/apps/chase/clientlibs/foundation/scripts/Reporting.js'
            : null,
      },
      vectilesURL: `https://vectiles.${env}.ch.housecanary.net`,
      allowFeatureFlagAndCustomizationDataJSONOverride:
        this.opts.getIsAllowedFeatureFlagUrlAndCustomizationDataJSONOverride(),
      stripePublishableKey: stripeConfig.key,
      stripePricingTableId: stripeConfig.tableId,
      stripeBillingLink:
        'https://billing.stripe.com/p/login/test_aEU29jdX35FS1yg000',
      coveredInsuranceIframeUrl:
        'https://sandbox-getquote.itscovered.com/housecanary',
    };
  }
}

function getStripeConfigForNonProdEnv(env: string): {
  key: string;
  tableId: string;
} {
  switch (env) {
    case 'giantsqa':
    case 'qa':
      return {
        key: 'pk_test_51NBJTZAMrSx38qVqTncxeaeffyd1lt0bOs1UOkzEmNhyxOaobfiYSjPlySXOdMyfIOWjgRNKTYIG2RDr2xXAPGn300oXFluxRe',
        tableId: 'prctbl_1NBLcAAMrSx38qVqWKV6IEY7',
      };
    case 'stage':
      return {
        key: 'pk_test_51NBJTZAMrSx38qVqTncxeaeffyd1lt0bOs1UOkzEmNhyxOaobfiYSjPlySXOdMyfIOWjgRNKTYIG2RDr2xXAPGn300oXFluxRe',
        tableId: 'prctbl_1NO2KsAMrSx38qVqMCfCf3xK',
      };
    case 'demo':
      return {
        key: 'pk_test_51NBJTZAMrSx38qVqTncxeaeffyd1lt0bOs1UOkzEmNhyxOaobfiYSjPlySXOdMyfIOWjgRNKTYIG2RDr2xXAPGn300oXFluxRe',
        tableId: 'prctbl_1NO2M1AMrSx38qVqt74eQLoY',
      };
    default: /* DEV */
      return {
        key: 'pk_test_51NBJTZAMrSx38qVqTncxeaeffyd1lt0bOs1UOkzEmNhyxOaobfiYSjPlySXOdMyfIOWjgRNKTYIG2RDr2xXAPGn300oXFluxRe',
        tableId: 'prctbl_1NO2BSAMrSx38qVqvfgrF12U',
      };
  }
}

export class ServerConfig {
  constructor(opts: OptionsForEnv) {
    this.opts = opts;
  }

  opts: OptionsForEnv = {
    getNonProdEnv: (): string => '',
    getSentryURL: (): string => '',
    getIsAllowedFeatureFlagUrlAndCustomizationDataJSONOverride: (): boolean =>
      false,
    hostname: null,
    isProd: false,
    isLocal: false,
  };

  getConfigMap(): ConfigMap {
    if (this.opts.isProd) {
      return this.getProdConfigMap();
    }
    return this.getNonProdConfigMap();
  }

  getProdConfigMap(): ConfigMap {
    return {
      comehomeBrandedHostname: 'https://www.comehome.com',
      consumerAppHostname: '',
      consumerAPI: 'https://api.prod.ch.housecanary.net',
      beaconURL: '',
      graphQlURL: 'https://property-graph.prod.ch.housecanary.net',
      sentryURL: this.opts.getSentryURL(),
      cobrandAnalyticsReportingScriptUrls: {
        chase:
          'https://www.chase.com/apps/chase/clientlibs/foundation/scripts/Reporting.js',
      },
      vectilesURL: 'https://vectiles.comehome.com',
      allowFeatureFlagAndCustomizationDataJSONOverride:
        this.opts.getIsAllowedFeatureFlagUrlAndCustomizationDataJSONOverride(),
      stripePublishableKey:
        'pk_live_51NBJTZAMrSx38qVqDx08mdcURhKWQ1hwYbiryqUBU69MMPwByLhi3vntDP76EhrMOuZagmWj93ef44S2NCdvB5uV008Fj2FcAU',
      stripePricingTableId: 'prctbl_1NBLrrAMrSx38qVqUoytgsHA',
      stripeBillingLink:
        'https://billing.stripe.com/p/login/aEU2c8757dxqcneaEE',
      coveredInsuranceIframeUrl: 'https://getquote.itscovered.com/housecanary',
    };
  }

  getNonProdConfigMap(): ConfigMap {
    const env = this.opts.getNonProdEnv();
    const stripeConfig = getStripeConfigForNonProdEnv(env);

    return {
      comehomeBrandedHostname: this.opts.isLocal
        ? `http://www.local.${env}.ch.housecanary.net:${window.location.port}`
        : `https://www.${env}.ch.housecanary.net`,
      consumerAppHostname: '',
      consumerAPI: `https://api.${env}.ch.housecanary.net`,
      beaconURL: '',
      graphQlURL: `https://property-graph.${env}.ch.housecanary.net`,
      sentryURL: this.opts.getSentryURL(),
      cobrandAnalyticsReportingScriptUrls: {
        chase:
          env === 'demo' || env === 'giantsqa'
            ? 'https://wwwq3.chase.com/apps/chase/clientlibs/foundation/scripts/Reporting.js'
            : null,
      },
      vectilesURL: `https://vectiles.${env}.ch.housecanary.net`,
      allowFeatureFlagAndCustomizationDataJSONOverride:
        this.opts.getIsAllowedFeatureFlagUrlAndCustomizationDataJSONOverride(),
      stripePublishableKey: stripeConfig.key,
      stripePricingTableId: stripeConfig.tableId,
      stripeBillingLink:
        'https://billing.stripe.com/p/login/test_aEU29jdX35FS1yg000',
      coveredInsuranceIframeUrl:
        'https://sandbox-getquote.itscovered.com/housecanary',
    };
  }
}

const optionsForEnv = (): OptionsForEnv => {
  /* This is defined on remote servers environments as 'qa', 'prod', etc */
  const localApiEnv = process.env.LOCAL_API_ENV || 'dev';
  const hostname = get(root, ['location', 'hostname'], null);
  const port = get(root, ['location', 'port'], false);

  return new Options(hostname, port, localApiEnv);
};

const clientConfigForEnv = (): Config => {
  const opts = optionsForEnv();
  return new ClientConfig(opts);
};

const serverConfigForEnv = (): Config => {
  const opts = optionsForEnv();
  return new ServerConfig(opts);
};

const getConfigMap = (): ConfigMap => {
  let configMap;
  // tslint:disable-next-line
  if (typeof window === 'undefined') {
    const serverConfig = serverConfigForEnv();
    configMap = serverConfig.getConfigMap();
  } else {
    const clientConfig = clientConfigForEnv();
    configMap = clientConfig.getConfigMap();
  }
  return configMap;
};

const CONFIG_MAP = getConfigMap();

export { CONFIG_MAP };
