import React from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import { SavedSearchItem } from '@client/store/types/saved-searches';
import Dropdown from '@client/components/generic/Dropdown';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import defaultTheme from '@client/css-modules/SavedSearchFilterDropdown.css';
import { useSelector } from 'react-redux';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';

type SavedSearchFilterDropdownProps = {
  options: SavedSearchItem[];
  selected: number | null;
  handleChange: (value: string | number | null) => void;
  theme: Theme;
};

const SavedSearchFilterDropdown: React.FC<SavedSearchFilterDropdownProps> = ({
  options,
  selected,
  handleChange,
  theme,
}) => {
  const isInvestmentExperienceEnabled = useSelector(getIsFeatureEnabled('investment_experience'));
  return (
    <AccessibleElementUniqueId>
      {({ uid }) => (
        <div key="saved-search" className={theme.SavedSearchFilterDropdown}>
          <div className={theme.FilterRow}>
            <label htmlFor={uid} className={theme.FilterLabel}>
            {isInvestmentExperienceEnabled ? 'Saved Buy Box' : 'Saved Filter'} Name
            </label>
            <Dropdown
              dropdownButtonId={uid}
              /**
               * If there is a visible label, no need to add aria label to prevent improperly
               * using aria attributes.
               */
              shouldNotAddAriaLabel
              theme={theme}
              onChange={(value) => handleChange(value)}
              source={options.map((val) => {
                return { value: val.searchId, label: val.displayName };
              })}
              /* We don't currently need analytics for interaction with this dropdown */
              handleReportValueSelection={() => void 0}
              noSelectedPlaceHolderText={isInvestmentExperienceEnabled ? 'No buy box selected' : 'No search selected'}
              value={selected}
            />
          </div>
        </div>
      )}
    </AccessibleElementUniqueId>
  );
};

const ThemedSavedSearchFilterDropdown = themr(
  'SavedSearchFilterDropdown',
  defaultTheme
)(SavedSearchFilterDropdown);

export default ThemedSavedSearchFilterDropdown;
