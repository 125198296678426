import { FILTER_KEYS } from '@client/store/filter-constants';
import { AnalyticsEvent, EVENTS } from '@client/store/analytics-constants';
import { View } from '@client/routes/constants';
import {
  SEARCH_ACTIVE_VIEW_URL_PARAM_KEY,
  WELCOME_SEARCH_ONLY_ADDRESS_URL_KEY,
} from '@client/store/constants';

const CHASE_PAGEVIEW_EVENT = 'pg_name' as 'pg_name';
const CHASE_CLICK_EVENT = 'wa_lnk' as 'wa_lnk';

type EventProps = { [key: string]: any } | undefined;
type ValueFunction = (props: EventProps) => string;

export type ChaseEventMapping = {
  event: AnalyticsEvent;
  props?: EventProps;
  view?: View;
  query?: {};
  notInQuery?: boolean;
  data: {
    key: typeof CHASE_PAGEVIEW_EVENT | typeof CHASE_CLICK_EVENT;
    value: string | ValueFunction;
  };
}[];

export const CHASE_EVENT_MAPPING = [
  {
    /* Interactions #8 */ event: EVENTS.CLICK_WELCOME_SEARCH_FIELD,
    props: {
      isAddressOnlySearch: false,
    },
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_addresssearch',
    },
  },
  {
    /* Interactions #9 */ event: EVENTS.CLICK_MOBILE_FILTER_ITEM,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_selectfiltertype',
    },
  },
  {
    /* Interactions #10 */ event: EVENTS.CLICK_MOBILE_FILTER_BACK,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_back',
    },
  },
  {
    /* Interactions #11 */ event: EVENTS.CLICK_MOBILE_FILTER_APPLY,
    props: {
      filterKey: FILTER_KEYS.LIST_PRICE_MIN_MAX,
    },
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_applyprice',
    },
  },
  {
    /* Interactions #12 */ event: EVENTS.CLICK_MOBILE_FILTER_CANCEL,
    props: {
      filterKey: FILTER_KEYS.LIST_PRICE_MIN_MAX,
    },
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_cancelprice',
    },
  },
  {
    /* Interactions #13 */ event: EVENTS.CLICK_MOBILE_FILTER_SHOW_RESULTS,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_showresults',
    },
  },
  {
    /* Interactions #14 */ event: EVENTS.CLICK_MOBILE_FILTER_ADD_FILTER,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_addfilter',
    },
  },
  {
    /* Interactions #15 */ event: 'click_save_search_cta_mobile_filters',
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_savesearch',
    },
  },
  {
    /* Interactions #16 */ event: EVENTS.CLICK_SAVED_SEARCH_REMOVE_PROPERTY,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_removeproperty',
    },
  },
  /* Interaction #17 is blank in events document */
  /* Interaction #18 involves a UI and button we not longer use */
  {
    /* Interactions #19 */ event: 'click_bottom_nav_map',
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_ft_map',
    },
  },
  {
    /* Interactions #20 */ event: 'click_bottom_nav_list',
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_ft_list',
    },
  },
  {
    /* Interactions #21 */ event: 'click_bottom_nav_saved',
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_ft_saved',
    },
  },
  {
    /* Interactions #22 */ event: 'click_bottom_nav_alerts',
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_ft_alert',
    },
  },
  {
    /* Interactions #23 */ event: EVENTS.CLICK_PROPERTY_CARD,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_showdetails',
    },
  },
  {
    /* Interactions #24 */ event: 'click_lender_cta_see_loan_options',
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_seeloanoption',
    },
  },
  {
    /* Interactions #25 */ event: EVENTS.CLICK_LENDER_CTA_SETUP_BUYING_POWER,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_setupbuyingpower',
    },
  },
  {
    /* Interactions #26 */ event: EVENTS.CLICK_SEE_LETTER,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_seeletter',
    },
  },
  {
    /* Interactions #27 */ event: 'click_search_list_watch',
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_saveproperty',
    },
  },
  {
    /* Interactions #27 */ event: 'click_property_details_watch',
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_saveproperty',
    },
  },
  {
    /* Interactions #27 */ event: 'click_property_photos_watch',
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_saveproperty',
    },
  },
  {
    /* Interactions #27 */ event: EVENTS.CLICK_NEARBY_PROPERTIES_WATCH,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_saveproperty',
    },
  },
  {
    /* Interactions #28 */ event: 'click_carousel_right_arrow',
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_carnext',
    },
  },
  /* Interaction #29 is covered by #24 */
  {
    /* Interactions #30 */ event: EVENTS.CLICK_WATCHLIST_TAB,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_savedhomes',
    },
  },
  {
    /* Interactions #31 */ event: EVENTS.CLICK_SAVED_SEARCHES,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_savedsearches',
    },
  },
  {
    /* Interactions #32 */ event: EVENTS.CLICK_PROPERTY_INFO,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_propertyinfo',
    },
  },
  {
    /* Interactions #33 */ event: EVENTS.CLICK_SEARCH_FILTERS,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_filters',
    },
  },
  {
    /* Interactions #34 */ event: 'click_save_search_cta_list_view',
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_savesearch',
    },
  },
  {
    /* Interactions #35 */ event: EVENTS.CLICK_SEARCH_SORT_MENU_OPEN,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_sort',
    },
  },
  /* Interaction #36 is covered by #23 */
  {
    /* Interactions #37 */
    event: EVENTS.CLICK_SEARCH_MAP_AUTOCOMPLETE_SEARCH_BUTTON,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_mapsearch',
    },
  },
  {
    /* Interactions #38 */ event: EVENTS.CLICK_SEARCH_MAP_MARKER,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_mapoverlay',
    },
  },
  {
    /* Interactions #39 */ event: 'click_save_search_cta_map_view',
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_savepropertymap',
    },
  },
  {
    /* Interactions #40 */ event: 'click_filter_map',
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_filtersmap',
    },
  },
  {
    /* Interactions #41 */ event: EVENTS.CLICK_HEATMAP_ITEM,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_heatmap',
    },
  },
  {
    /* Interactions #42 */ event: EVENTS.CLICK_HEATMAP_CRIME,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_crime',
    },
  },
  {
    /* Interactions #42 */ event: EVENTS.CLICK_HEATMAP_SCHOOL,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_school',
    },
  },
  {
    /* Interactions #42 */ event: EVENTS.CLICK_HEATMAP_PRICE,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_price',
    },
  },
  {
    /* Interactions #42 */ event: EVENTS.CLICK_HEATMAP_FORECAST,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_forecast',
    },
  },
  {
    /* Interactions #43 */ event: 'click_search_trouble_finding_address',
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_findingaddress',
    },
  },
  {
    /* Interactions #44 */
    event: EVENTS.CLICK_SEARCH_STILL_EXPLORE_LOAN_OPTIONS,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_exploreloanoption',
    },
  },
  /* Interactions #45 is covered by #24 */
  {
    /* Interactions #46 */ event: EVENTS.CLICK_BUYING_POWER_OK,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_buyingpw_ok',
    },
  },
  {
    /* Interactions #47 */ event: EVENTS.CLICK_BUYING_POWER_CANCEL,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_buyingpw_cancel',
    },
  },
  {
    /* Interactions #48 */ event: 'click_see_latest_homes',
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_seelatest_homes',
    },
  },
  /* Interaction #49 Not sure if we need to handle error reporting or if the API does */
  {
    /* Interactions #50 */ event: EVENTS.CLICK_WELCOME_SEARCH_FIELD,
    props: {
      isAddressOnlySearch: true,
    },
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_enteraddress',
    },
  },
  {
    /* Interactions #51 */ event: 'click_pdp_see_all_details',
    props: {
      isOffMarketProperty: true,
    },
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_offm_seedetails',
    },
  },
  {
    /* Interactions #52 */ event: EVENTS.CLICK_PDP_OFF_MARKET_SEE_LOAN_PRODUCTS,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_offm_seeloaninfo',
    },
  },
  {
    /* Interactions #53 */ event: 'click_pdp_off_market_see_letter',
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'hf_cob_offm_seeletter',
    },
  },
  /* Pages #1 and #2 are part of the Chase app */
  {
    /* Pages #3 */ event: EVENTS.PAGE_VIEW,
    view: View.WELCOME_SEARCH,
    query: {
      [WELCOME_SEARCH_ONLY_ADDRESS_URL_KEY]: 'true',
    },
    notInQuery: true,
    data: {
      key: CHASE_PAGEVIEW_EVENT,
      value: 'Search New Property',
    },
  },
  {
    /* Pages #4 */ event: EVENTS.CLICK_MOBILE_FILTER_ADD_FILTER,
    data: {
      key: CHASE_PAGEVIEW_EVENT,
      value: 'Select Filter',
    },
  },
  {
    /* Pages #5 */ event: EVENTS.SEARCH_LIST_HAS_SEARCH_PROPERTY_RESULTS,
    data: {
      key: CHASE_PAGEVIEW_EVENT,
      value: 'Property Search Results',
    },
  },
  /* We're sending an inquiry about Page #6 */
  {
    /* Pages #7 */ event: EVENTS.PAGE_VIEW,
    view: View.WATCHLIST,
    data: {
      key: CHASE_PAGEVIEW_EVENT,
      value: 'Saved Home Search',
    },
  },
  {
    /* Pages #8 */ event: EVENTS.CLICK_PROPERTY_DETAILS_GENERAL_DETAILS,
    data: {
      key: CHASE_PAGEVIEW_EVENT,
      value: 'Property Details',
    },
  },
  {
    /* Pages #9 */ event: EVENTS.PAGE_VIEW,
    view: View.SEARCH,
    query: {
      [SEARCH_ACTIVE_VIEW_URL_PARAM_KEY]: 'map',
    },
    data: {
      key: CHASE_PAGEVIEW_EVENT,
      value: 'Map',
    },
  },
  {
    /* Pages #10 */ event: EVENTS.NO_ADDRESS_RESULTS_FOR_QUERY,
    data: {
      key: CHASE_PAGEVIEW_EVENT,
      value: 'Having Difficulty',
    },
  },
  {
    /* Pages #11 */ event: EVENTS.CLICK_SHOW_CANT_FIND_ADDRESS_MODAL,
    data: {
      key: CHASE_PAGEVIEW_EVENT,
      value: 'Having Difficulty - Enter the address',
    },
  },
  {
    /* Pages #12 */ event: EVENTS.NO_PROPERTIES_IN_WATCHLIST,
    data: {
      key: CHASE_PAGEVIEW_EVENT,
      value: 'Having Difficulty - No Homes Saved',
    },
  },
  {
    /* Pages #13 */ event: EVENTS.PAGE_VIEW,
    view: View.NATIVE_APP_ERROR,
    data: {
      key: CHASE_PAGEVIEW_EVENT,
      value: 'Error',
    },
  },
  {
    /* Pages #14 */ event: EVENTS.PAGE_VIEW,
    view: View.WELCOME_SEARCH,
    query: {
      [WELCOME_SEARCH_ONLY_ADDRESS_URL_KEY]: 'true',
    },
    data: {
      key: CHASE_PAGEVIEW_EVENT,
      value: 'Enter Home Address',
    },
  },
  {
    /* Pages #15 */ event: EVENTS.PAGE_VIEW,
    view: View.ALERTS,
    data: {
      key: CHASE_PAGEVIEW_EVENT,
      value: 'Home Property Listing',
    },
  },
  {
    /* Pages #16 */ event: EVENTS.PAGE_VIEW,
    view: View.PROPERTY_DETAILS,
    data: {
      key: CHASE_PAGEVIEW_EVENT,
      value: 'Home Property Details',
    },
  },
  {
    /* Pages #16 */
    /* The search page expanded PDP modal should also count as a "Home Property Details" pageview */
    event: 'click_search_expand_pdp_modal',
    data: {
      key: CHASE_PAGEVIEW_EVENT,
      value: 'Home Property Details',
    },
  },
  {
    /* Pages #17 */ event: EVENTS.SEARCH_LIST_NO_SEARCH_PROPERTY_RESULTS,
    data: {
      key: CHASE_PAGEVIEW_EVENT,
      value: 'No Results',
    },
  },
  {
    event: EVENTS.CLICK_COMEHOME_LOGO,
    data: {
      key: CHASE_CLICK_EVENT,
      value: 'Click ComeHome logo',
    },
  },
];
