import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuthModalPage } from '@client/store/types/auth';
import theme from '@client/css-modules/WatchListAlertsLoggedOut.css';
import { AUTH_MODAL_PAGES } from '@client/store/constants';
import { authModalShow } from '@client/store/actions/auth.actions';
import PillButton from '@client/components/generic/PillButton';
import { BASE_UTILS } from '@client/cobrand-settings/cobrand-utils';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { getWatchlistPageConfig } from '@client/store/selectors/cobranding.selectors';
import CobrandedStyles from '@client/components/CobrandedStyles';

type Props = {
  icon: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
};

/**
 * Displayed when viewing either the Watchlist or Alerts page when logged out
 */
const WatchListAlertsLoggedOut: React.FC<Props> = ({
  icon,
  title,
  description,
}) => {
  const dispatch = useDispatch();
  const showAuthModal = (key: AuthModalPage) => {
    dispatch(authModalShow({ startingPage: key }));
  };

  const watchlistPageConfig = useSelector(getWatchlistPageConfig)

  return (
    <div
      className={theme.WatchListAlertsLoggedOut}
      data-hc-name="logged-out-section"
    >
    <div className={theme.IconSection} data-hc-name="icon">
      {watchlistPageConfig?.savedHomesIconUrl ? (
        <CobrandedStyles>
          {({pillButtonPrimaryBackgroundColor }) => (
            <div 
              className={theme.CustomIconWrapper}
              style={{backgroundColor: pillButtonPrimaryBackgroundColor}}
          >
              <img 
                width="45" 
                height="45" 
                className={theme.CustomIcon} 
                src={watchlistPageConfig.savedHomesIconUrl || undefined} 
                alt="" 
                aria-hidden 
              />
            </div>
          )}
        </CobrandedStyles>
        ) : (
          <div>
            {icon}
          </div>
          )
        }
      </div>
      <div className={theme.TitleSection} data-hc-name="title">
        <h1>
          {title}
        </h1>
      </div>
      <div className={theme.DescriptionSection} data-hc-name="desc">
        {description}
      </div>
      <div className={theme.ButtonSection}>
        <PillButton
          data-event-name="click_signup_cta"
          data-parent-event-name={PARENT_EVENTS.CLICK_SIGNUP}
          dataHcName="signup-button"
          ariaLabel="sign up"
          onClick={() => {
            showAuthModal(AUTH_MODAL_PAGES.SIGN_UP);
          }}
          className={theme.PrimaryButton}
        >
          {BASE_UTILS.getFormattedCTAText('Sign Up')}
        </PillButton>
        <PillButton
          data-event-name="click_login_cta"
          dataHcName="login-button"
          onClick={() => showAuthModal(AUTH_MODAL_PAGES.LOGIN)}
          ariaLabel="login"
          theme={theme}
          className={theme.SecondaryButton}
          secondary
        >
          Login
        </PillButton>
      </div>
    </div>
  );
};

export default WatchListAlertsLoggedOut;
