import { MapLegendStats, LatLngObject } from '@client/store/types/maps';
import { LayerMetric } from '@client/store/map-constants';

export const FETCH_MAP_LAYER_LEGEND_BREAKS = 'FETCH_MAP_LAYER_LEGEND_BREAKS';
export const FETCH_MAP_LAYER_LEGEND_BREAKS_SUCCESS =
  'FETCH_MAP_LAYER_LEGEND_BREAKS_SUCCESS';
export const MAP_LAYER_LEGEND_BREAKS_NOT_AVAILABLE =
  'MAP_LAYER_LEGEND_BREAKS_NOT_AVAILABLE';

type FetchMapLegendBreaksAction = {
  type: typeof FETCH_MAP_LAYER_LEGEND_BREAKS;
  payload: {
    southWest: LatLngObject;
    northEast: LatLngObject;
    zoom: number;
    metric?: LayerMetric | null;
  };
};

export const fetchMapLegendBreaks = ({
  southWest,
  northEast,
  zoom,
  metric,
}: FetchMapLegendBreaksAction['payload']): FetchMapLegendBreaksAction => ({
  type: FETCH_MAP_LAYER_LEGEND_BREAKS,
  payload: {
    southWest,
    northEast,
    zoom,
    metric,
  },
});

type FetchMapLegendBreaksSuccessAction = {
  type: typeof FETCH_MAP_LAYER_LEGEND_BREAKS_SUCCESS;
  payload: {
    metricStats: MapLegendStats;
  };
};

export const fetchMapLegendBreaksSuccess = (
  metricStats: MapLegendStats
): FetchMapLegendBreaksSuccessAction => ({
  type: FETCH_MAP_LAYER_LEGEND_BREAKS_SUCCESS,
  payload: {
    metricStats,
  },
});

type MapLayerLegendBreaksNotAvailableAction = {
  type: typeof MAP_LAYER_LEGEND_BREAKS_NOT_AVAILABLE;
};

export const mapLayerLegendBreaksNotAvailable =
  (): MapLayerLegendBreaksNotAvailableAction => ({
    type: MAP_LAYER_LEGEND_BREAKS_NOT_AVAILABLE,
  });

export type MapLegendBreaksAction =
  | FetchMapLegendBreaksAction
  | FetchMapLegendBreaksSuccessAction
  | MapLayerLegendBreaksNotAvailableAction;
