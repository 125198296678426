import { connect } from 'react-redux';

import MLSCopyrightSection from '@client/components/MLSCopyrightSection';
import { getGlobalMLSRegulations } from '@client/store/selectors/mls-global-display-rules.selectors';
import {
  getPropertyMlsLastRefreshedDateTime,
  getPropertyMlsRegulations,
} from '@client/store/selectors/property-details.selectors';

const mapStateToProps = (state) => {
  return {
    globalMLSRegulations: getGlobalMLSRegulations(state),
    propertyMLSRegulations: getPropertyMlsRegulations(state),
    refreshedDateTime: getPropertyMlsLastRefreshedDateTime(state),
  };
};

export default connect(mapStateToProps, {})(MLSCopyrightSection);
