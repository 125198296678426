import { connect } from 'react-redux';
import HeaderBackArrow from '@client/components/HeaderBackArrow';
import { routeChange, routeBack } from '@src/redux-saga-router-plus/actions';
import { HOMEOWNER_BREAKOUT_ROUTES, View } from '@client/routes/constants';
import {
  getHomeownerShapedPropertyAddress,
  getIsBuyerOrOwnerPropertyInClaimedHomes,
} from '@client/store/selectors/homeowner.selectors';
import { getIsXSmallSize } from '@client/store/selectors/match-media.selectors';
import {
  getCurrentView,
  getCurrentParams,
  getPreviousView,
} from '@src/redux-saga-router-plus/selectors';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { fetchHomeownerUserDataIfNecessary } from '@client/store/actions/homeowner.actions';

const mapStateToProps = (state) => {
  const previousView = getPreviousView(state);
  const currentView = getCurrentView(state);
  return {
    isPropertyInClaimedHomes: getIsBuyerOrOwnerPropertyInClaimedHomes(state),
    propertyAddress: getHomeownerShapedPropertyAddress(state).address,
    isXSmallSize: getIsXSmallSize(state),
    currentView,
    currentSlug: getCurrentParams(state).slug,
    hasPreviousRoute: !!previousView && previousView !== currentView,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchHomeownerUserDataIfNeeded: () =>
    dispatch(fetchHomeownerUserDataIfNecessary()),
  handleBackButtonClick: ({ view, slug, shouldUseBack }) => {
    const isViewThatHasSpecificDestination =
      view === View.PRO_FINDER || view === View.HOME_IMPROVEMENT;
    if (shouldUseBack && !isViewThatHasSpecificDestination) {
      dispatch(routeBack());
    } else {
      let destinationView: View;
      if (view === View.PRO_FINDER) {
        dispatch(
          reportEvent('click_owner_profinder_back', 'click_owner_profinder')
        );
        destinationView = View.HOME_IMPROVEMENT;
      } else if (HOMEOWNER_BREAKOUT_ROUTES.includes(view)) {
        destinationView = View.HOMEOWNER_PROPERTY_DETAILS;
      } else {
        destinationView = slug ? View.PROPERTY_DETAILS : View.SEARCH;
      }

      dispatch(
        routeChange({
          view: destinationView,
          ...(slug ? { params: { slug } } : {}),
        })
      );
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBackArrow);
