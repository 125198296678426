import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import PropertyPage from '@client/pages/PropertyPage';
import { STATUSES } from '@client/store/constants';
import {
  fetchInitPropertyDetails,
  fetchFullPropertyDetails,
  fetchMortgageAndTerms,
  setLocalStoragePropertySeen,
} from '@client/store/actions/property-details.actions';
import {
  eventType,
  reportEvent,
  ParentEventType,
  reportPropertyDetailsView,
} from '@client/store/actions/analytics.actions';
import {
  getActivePDPPropertyHasData,
  getHomePriceForMortgageCalculator,
  getMortgageCalculationDetailsStatus,
  getPropertyDetailsInitStatus,
  getPropertyDetailsNormalized,
  getPropertyIsActiveStatusType,
} from '@client/store/selectors/property-details.selectors';
import {
  getIsSmallSize,
  getIsTabletSize,
} from '@client/store/selectors/match-media.selectors';
import { getLoanOfficerId } from '@client/store/selectors/loan-officer.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { getIsApplicationMounted } from '@client/store/selectors/server-rendering-info.selectors';
import { getIsBuyerOrOwnerPropertyInClaimedHomes } from '@client/store/selectors/homeowner.selectors';
import { NormalizedProperty } from '@client/store/types/property';
import {
  AnalyticsEventAddress,
  AnalyticsTopLevelItems,
} from '@client/store/types/analytics';
import { updatePDPViewId } from '@client/store/slices/analytics-data.slice';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import { getIDSelectorByView } from '@client/utils/analytics.utils';
import { fetchHomeownerUserDataIfNecessary } from '@client/store/actions/homeowner.actions';

type OwnProps = {
  params: {
    slug: string;
  };
};

const mapStateToProps = (state, ownProps: OwnProps) => {
  const PDPInitStatus = getPropertyDetailsInitStatus(state);
  const source = getCurrentView(state);
  const sourceId = getIDSelectorByView(source)(state);
  const isReferralServicesEnabled =
    getIsFeatureEnabled('referral_services')(state);

  return {
    activePropertyHasData: getActivePDPPropertyHasData(state),
    addressSlug: ownProps.params.slug,
    failedToLoad: PDPInitStatus === STATUSES.FAILED,
    homePrice: getHomePriceForMortgageCalculator(state),
    isActiveListing: getPropertyIsActiveStatusType(state),
    isAppMounted: getIsApplicationMounted(state),
    isCanaryUIFeatureEnabled: getIsFeatureEnabled('canary_ui')(state),
    isHidingAvmBreakdown: getIsFeatureEnabled('hide_ch_value_modal_pdp')(state),
    isPDPDataInit: PDPInitStatus === STATUSES.INIT,
    isPDPDataLoaded: PDPInitStatus === STATUSES.SUCCESS,
    isSmallSize: getIsSmallSize(state),
    isReferralServicesEnabled,
    isTabletSize: getIsTabletSize(state),
    loanOfficerId: getLoanOfficerId(state),
    mortgageCalculationDefaultsStatus:
      getMortgageCalculationDetailsStatus(state),
    shouldShowHomeownerAd:
      !getIsBuyerOrOwnerPropertyInClaimedHomes(state) &&
      getIsFeatureEnabled('homeowner')(state),
    property: getPropertyDetailsNormalized(state),
    source: source || undefined,
    sourceId: sourceId || undefined,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchInitData: (slug: string) => {
    dispatch(fetchInitPropertyDetails(slug));
    dispatch(fetchHomeownerUserDataIfNecessary());
  },
  fetchFullData: (slug: string) => dispatch(fetchFullPropertyDetails(slug)),
  fetchMortgageCalculationDefaults: ({
    activePropertySlug,
    data,
    isFetchingDefaultTerms,
  }: {
    activePropertySlug: string;
    data: { homePrice: number | null };
    isFetchingDefaultTerms: boolean;
  }) => {
    dispatch(
      fetchMortgageAndTerms(activePropertySlug, data, isFetchingDefaultTerms)
    );
  },
  setLocalStoragePropertySeen: (slug: string) =>
    dispatch(setLocalStoragePropertySeen(slug)),
  reportPageview: (
    address: NormalizedProperty | null,
    loanOfficerId: string | null
  ) => {
    let pdpAddress: AnalyticsEventAddress | null = null;
    if (address) {
      const analyticsAddress: AnalyticsEventAddress = {
        street: address?.streetAddress,
        unit: address.unit,
        city: address.city,
        state: address.state,
        zip: address.zipcode,
        slug: address.slug,
        address_id: address.hcAddressId,
      };

      pdpAddress = analyticsAddress;
    }
    dispatch(updatePDPViewId());
    dispatch(
      reportPropertyDetailsView(
        address?.slug || null,
        pdpAddress,
        loanOfficerId
      )
    );
  },
  handleReportEvent: (
    event: eventType,
    parentEvent: ParentEventType | null,
    address: AnalyticsEventAddress,
    topLevelItems?: AnalyticsTopLevelItems
  ) => {
    dispatch(reportEvent(event, parentEvent, address, topLevelItems));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyPage);
