import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createModalPortal } from '@client/hocs/create-modal-portal';
import TextInput from '@client/components/generic/TextInput';
import TextArea from '@client/components/generic/TextArea';
import FormModal, {
  FieldValuesByUId,
} from '@client/components/generic/FormModal';
import FormModalTopPhotoSection from '@client/components/generic/FormModalTopPhotoSection';
import EmailField from '@client/components/generic/EmailField';
import { formatPhoneInputForDisplay } from '@client/utils/string.utils';
import {
  getUserEmailAddress,
  getUserFirstName,
  getUserLastName,
  getUserPhoneNumber,
} from '@client/store/selectors/auth.selectors';
import { reportEvent } from '@client/store/actions/analytics.actions';
import theme from '@client/css-modules/CTARequestForm.css';
import PillButton from '@client/components/generic/PillButton';
import GreenCheckIconCobranded from '@client/components/GreenCheckIcon/GreenCheckIconCobranded';

type Props = {
  isActive: boolean;
  handleClose: () => void;
};

const CTARequestForm: React.FunctionComponent<Props> = ({
  isActive,
  handleClose,
}) => {
  const dispatch = useDispatch();

  const userEmail = useSelector(getUserEmailAddress);
  const userFirstName = useSelector(getUserFirstName);
  const userLastName = useSelector(getUserLastName);
  const userPhone = useSelector(getUserPhoneNumber);

  const closeModal = () => {
    dispatch(reportEvent('click_finance_cta_submit_close'));
    handleClose();
  };

  const handleSubmit = (formValues: FieldValuesByUId) => {
    dispatch(
      reportEvent('click_finance_cta_submit', '', {
        first_name: formValues.firstname,
        last_name: formValues.lastname,
        email: formValues.email,
        phone: formValues.phone,
        comments: formValues.comments,
      })
    );
    dispatch(
      reportEvent('click_finance_cta_submit', '', {
        first_name: formValues.firstname,
        last_name: formValues.lastname,
        email: formValues.email,
        phone: formValues.phone,
        comments: formValues.comments,
      })
    );
  };

  const FormPageTopContent = (
    <FormModalTopPhotoSection
      fullName={null}
      Heading={
        'Please provide your contact information so that we can match you with one of our lender partners.'
      }
    />
  );

  const CTARequestSuccess = ({ PillButton, theme }) => (
    <div className={theme.RequestSuccessContent}>
      <div className={theme.HeaderContent}>
        <GreenCheckIconCobranded className={theme.GreenCheckIcon} />
        <div className={theme.RequestSuccessHeader}>
          Thank you for submitting your contact information.
        </div>
      </div>
      <div className={theme.RequestSuccessBody}>
        We are working hard to match you up with one of our lending partners,
        and will reach out with next steps.
      </div>
      <div className={theme.ButtonWrapper}>
        <PillButton
          className={theme.CloseButton}
          theme={theme}
          onClick={closeModal}
          ariaLabel={'lender-close-button'}
        >
          Close
        </PillButton>
      </div>
    </div>
  );

  return (
    <FormModal
      isActive={isActive}
      FormPageTopContent={FormPageTopContent}
      fields={[
        {
          uId: 'firstname',
          component: TextInput,
          label: 'First Name*',
          required: true,
          initialValue: userFirstName,
          'data-hc-name': 'first-name-field',
        },
        {
          uId: 'lastname',
          component: TextInput,
          label: 'Last Name*',
          required: true,
          initialValue: userLastName,
          'data-hc-name': 'last-name-field',
        },
        {
          uId: 'email',
          component: EmailField,
          label: 'Email*',
          required: true,
          initialValue: userEmail,
          'data-hc-name': 'email-field',
        },
        {
          uId: 'phone',
          component: TextInput,
          label: 'Phone number*',
          required: true,
          initialValue: formatPhoneInputForDisplay(userPhone),
          'data-hc-name': 'phone-field',
        },
        {
          uId: 'comments',
          component: TextArea,
          label: 'Leave a message (optional)',
          required: false,
          'data-hc-name': 'message-field',
        },
      ]}
      SuccessPageContent={CTARequestSuccess({
        PillButton,
        theme,
      })}
      handleSubmit={handleSubmit}
      handleClose={closeModal}
      modalAriaLabel={'Finance CTA modal'}
      submitButtonAriaLabel={'Send contact info'}
      analyticsEventsByFieldUId={{
        firstname: undefined,
        lastname: undefined,
        email: undefined,
        phone: undefined,
        comments: undefined,
      }}
      analyticsEventsMisc={{
        submitFormButton: undefined,
        closeButtonAfterSubmit: undefined,
        closeButtonBeforeSubmit: undefined,
      }}
      theme={theme}
    />
  );
};

export default createModalPortal(CTARequestForm, 'cta-agent-form');
