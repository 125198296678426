import { get } from 'lodash';
import windowOrGlobal from 'window-or-global';

import HC_CONSTANTS from '@client/app.config';
import {
  PATH_URL_MAP_TO_VIEW,
  View,
  VIEW_PATHS,
} from '@client/routes/constants';
import { MlsStateGroup } from '@client/store/sagas/queries/types';
import { CustomizationDataPartial } from '@client/store/types/cobranding';
import { mlsStatesIncludeActiveListings } from '@client/utils/property.utils';

type QueryStringObj = {
  [key: string]: string;
} | null;
type QueryStringArr = QueryStringObj[];

/*
 * Get the page title for Place pages (Search, City, Zipcode)
 * @param  {object} mlsState - the mlsState object from search.filters
 * @param  {string} placeDescription - optional description of the area ('Los Angeles CA', etc)
 * @return {string} the appropriate page title
 */
export const getPageTitleForPlacePage = (
  mlsStateFilterValue: MlsStateGroup[],
  placeDescription?: string
): string => {
  let title: string;
  if (mlsStatesIncludeActiveListings(mlsStateFilterValue)) {
    title = placeDescription
      ? `${placeDescription} real estate and homes for sale`
      : 'Real estate and homes for sale';
  } else {
    title = placeDescription
      ? `${placeDescription} real estate and homes for sale`
      : 'Home values';
  }
  return title;
};

/* Returns whether you're in a current view at app init time by manually matching the route path.
 * Useful to determine the current view when setting initial state via hot-reloading reducers */
export const getIsInView = (viewKey: View | null): boolean => {
  const getFirstSegmentFromPathname = (pathname: string | null) => {
    const matches = pathname && pathname.match(/(\/[a-z-]+)\//);
    return matches && matches[1];
  };

  const routePathToCheck = viewKey && VIEW_PATHS[viewKey];
  const currentRoutePath = get(windowOrGlobal, ['location', 'pathname'], '');
  return (
    getFirstSegmentFromPathname(routePathToCheck) ===
    getFirstSegmentFromPathname(currentRoutePath)
  );
};

/* Returns the cookie domain for a hostname, scoped to the environment
 * i.e. '.qa.ch.housecanary.net' or '.comehome.com' */
export const getCookieDomainFromHostname = (hostname: string): string => {
  return hostname.replace(/^[A-z-_]+/g, '');
};

export const buildBrandedHost = (host: string): string => {
  return host ? `https://${host}` : HC_CONSTANTS.COMEHOME_BRANDED_HOSTNAME;
};

/* Checks for query params on URL, formats and adds additional query params as needed */
export const addQueryString = (url, queryStrArr: QueryStringArr): string => {
  if (!queryStrArr.length) {
    return url;
  } else {
    const hasExistingQueryString = url.includes('?');
    const newQueryStrings = queryStrArr.map((query) => {
      if (query) {
        const key = Object.keys(query)[0];
        return `${key}=${query[key]}`;
      } else {
        return undefined;
      }
    });
    const fullLinkStr = `${url}${
      hasExistingQueryString ? '&' : '?'
    }${newQueryStrings.join('&')}`;
    return fullLinkStr;
  }
};

export const addPartnerPropertyAndUserIdToUrl = (
  url: string,
  customizationData: CustomizationDataPartial,
  propertyId: string | null,
  userId: string | null
) => {
  const partnerCtaPropertyIdKey = customizationData.partner_cta_property_id_key;
  const partnerCtaUserIdKey = customizationData.partner_cta_user_id_key;
  let queryArray: QueryStringArr = [];

  if (partnerCtaPropertyIdKey && propertyId) {
    queryArray.push({ [partnerCtaPropertyIdKey]: propertyId });
  }
  if (partnerCtaUserIdKey && userId) {
    queryArray.push({ [partnerCtaUserIdKey]: userId });
  }

  const fullLinkStr = addQueryString(url, queryArray);
  return fullLinkStr;
};

export type UrlObject = {
  url: string | undefined;
  protocol: string | undefined;
  domain: string | undefined;
  path: string | undefined;
  query: string | undefined;
};

/**
 * Takes a url string and returns an object with the url, protocol, domain, path, and query broken out
 */
export const parseUrl = (url: string): UrlObject => {
  const urlParsingRegex =
    // eslint-disable-next-line security/detect-unsafe-regex
    /^(http|https):\/\/?([^:\/\s]+)?(\/[\w\-\.]+[^#?\s]+)?(.*)?(#[\w\-]+)?$/g;
  const dataArr = urlParsingRegex.exec(url);

  return {
    url: dataArr?.[0] || undefined,
    protocol: dataArr?.[1] || undefined,
    domain: dataArr?.[2] || undefined,
    path: dataArr?.[3] || undefined,
    query: dataArr?.[4] || undefined,
  };
};

/**
 * Check if the url path matches a VIEW, and return the VIEW key if so
 */
export const getTargetView = (ctaLinkFromParcon?: string): View | null => {
  if (ctaLinkFromParcon) {
    const urlObj = parseUrl(ctaLinkFromParcon);

    if (urlObj.path) {
      const view = PATH_URL_MAP_TO_VIEW[urlObj.path] as View;
      if (view) {
        return view;
      }
    }
  }
  return null;
};
