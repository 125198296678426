import { LOCKED_SECTION_LIST } from '@client/store/locked-components-constants';
import { getUserSpecialUserType } from '@client/store/selectors/auth.selectors';
import { getSSOModalConfig } from '@client/store/selectors/cobranding.selectors';
import {
  LockedComponentType,
  SpecialUserType,
} from '@client/store/types/locked-components';
import { useSelector } from 'react-redux';

interface Props<T> {
  sectionId?: LockedComponentType<T>;
  lockedFor: SpecialUserType[];
}

export type LockedComponentArgs = {
  isLocked: boolean;
};

export function useLockedComponent<T extends string>({
  sectionId,
  lockedFor,
}: Props<T>) {
  const ssoModalConfig = useSelector(getSSOModalConfig);
  const userSpecialType = useSelector(getUserSpecialUserType);

  const isSpecialUserTypesRestricted =
    userSpecialType === SpecialUserType.Restricted;
  const isSectionInLockedList =
    !!sectionId &&
    lockedFor.some((userType) => {
      return LOCKED_SECTION_LIST[userType].includes(sectionId);
    });
  const isLocked =
    isSpecialUserTypesRestricted &&
    isSectionInLockedList &&
    !!ssoModalConfig?.specialUserTypeSsoCtaText &&
    !!ssoModalConfig?.specialUserTypeSsoCtaUrl;

  return { isLocked };
}
