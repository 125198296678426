/**
 * Keep top-level keys in alpha order please
 */
export default ({ slug, zipHpiStart, zipHpiEnd }) => ({
  query: `query HomeownerClaimedHomeQuery(
      $slug: String,
      $zipHpiStart: Date,
      $zipHpiEnd: Date
    ) {
    propertyLookup (id: { slug: $slug}) {
      address {
        slug
        hcAddressId
        fullAddress
        streetAddress
        unit
        city
        state
        zipcode
      }
      bestPhotos (limit: 10, orderBy: MLS) {
        representation {
          httpUrl
          height
          width
        }
      }
      geoLocation {
        latitude
        longitude
      }
      latestListing {
        listingOfficeName
      }
      mlsState
      mls {
        mlsID
        regulations {
          logoOverlay
          photosLogoOverlay
        }
      }
      zip {
        hpiHistorical (start: $zipHpiStart, end: $zipHpiEnd ) {
          adjustedValue
          month
        }
      }
    }
  }`,
  variables: {
    slug,
    zipHpiStart,
    zipHpiEnd,
  },
});
