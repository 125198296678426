import theme from '@client/css-modules/MapLegendBreak.css';
import { motion } from 'framer-motion';

type Props = {
  color: string;
  i: number;
  small?: boolean;
  halftone: boolean;
  children?: React.ReactNode;
};

const MapLegendBreak: React.FC<Props> = ({
  color,
  children,
  i,
  small,
  halftone,
}) => (
  <motion.div
    className={
      theme.LegendItemColor +
      (halftone ? ` ${theme.LegendItemColorHalftone}` : '') +
      (halftone && small ? ` ${theme.LegendItemColorSmall}` : '')
    }
    animate={{
      opacity: 1,
      transition: {
        delay: 0.42 + i * 0.06,
        duration: 0.2,
        easing: 'easeIn',
      },
    }}
    initial={{ opacity: 0 }}
  >
    <div className={theme.LegendItemColorTile} style={{ background: color }}>
      {children}
    </div>
  </motion.div>
);

export default MapLegendBreak;
