import React, { useEffect, useRef } from 'react';

type MethodProps = {
  eventType: string;
  style?: string;
  actionOnElementId: string;
  /**
   * Use resetStyleOnSelf to reset the style of the element
   * to the original style before action happened.
   * The element is specified in the actionOnElementId
   *
   * For example: Mobile header
   * There is a header nav 1 and sub-nav1 which is "display: none".
   * 1. Click on a header nav.
   *   => display a sub-nav1 by changing its style to "display: block"
   * 2. Then click on a header nav 1 again.
   *   => hide a sub-nav1 by changing its style to the original style
   *   which is "display: none".
   */
  resetStyleOnSelf?: boolean;
  /**
   * Use resetStyleOnOthers to reset the styles of all displayed elements
   * to the original styles before actions happened.
   * For example: Desktop header
   * There are header nav 1, sub-nav1 which is "display: none",
   * and header nav 2, sub-nav2 which is "display: none".
   * 1. Click on header nav 1
   *    => display sub-nav1 by changing its style to "display: block"
   * 2. Then click on header nav 2
   *    => hide sub-nav1 by changing its style to the original style
   *    which is "display: none".
   *    => display sub-nav2 by changing its style to "display: block"
   */
  resetStyleOnOthers?: boolean;
};

export type ElementProps = {
  htmlElement: string;
  attributes: {
    style?: string;
    href?: string;
    id?: string;
  };
  methods?: MethodProps[];
  children?: any[];
  text?: string;
};

type SizeProps = {
  mobile?: { element: ElementProps };
  desktop?: { element: ElementProps };
};

export type InstructionProps = {
  component: string;
  sizes?: SizeProps;
  element?: ElementProps;
};

type ScaffoldingUIProps = {
  elementInstruction: ElementProps;
};

const ScaffoldingUI: React.FC<ScaffoldingUIProps> = ({
  elementInstruction,
}) => {
  const scaffoldingRef = useRef<HTMLDivElement | null>(null);
  let targetElementId: string | null = null;
  type CurrentDisplayedElement = {
    elementId: string;
    styleBeforeAction: string;
  };
  let currentDisplayedElements: CurrentDisplayedElement[] = [];
  /**
   * There is the Threat model that lists all supported html elements,
   * html attributes, and event types here
   * https://housecanary.atlassian.net/wiki/spaces/CON/pages/2406711312/CMG+SDK#Threat-Model
   *
   */
  const SUPPORTED_JSON_DATA = {
    htmlElements: ['div', 'span', 'button', 'img', 'a', 'ul', 'li', 'nav'],
    htmlAttributes: ['id', 'style', 'src', 'alt', 'href', 'target', 'tabindex'],
    eventTypes: ['click', 'mouseover', 'mouseleave'],
  };

  // Returns a camelCase string
  const getCamelCasedStyle = (prop) => {
    let camelCasedProp = prop.replace(/-([a-z])/g, function (i) {
      return i[1].toUpperCase();
    });
    return camelCasedProp.replace(' ', '');
  };

  /**
   * Converts style from string to object.
   * Then use the style object to override the style of a target element
   *
   * For example:
   * overrideStyleStr = 'font-size: 15px'
   * overrideStyleArr = ['font-size: 15px']
   * camelCasedProp = 'fontSize'
   * styleValue = '15px'
   * Then use the camelCasedProp and styleValue to override
   * the style of a target element
   */
  const overrideStyle = (targetElement, overrideStyleStr) => {
    const overrideStyleArr = overrideStyleStr.split(';');

    for (let i = 0; i < overrideStyleArr.length - 1; i++) {
      const splitStyle = overrideStyleArr[i].split(':');
      const prop = splitStyle[0];
      let camelCasedProp = getCamelCasedStyle(prop);
      const styleValue = splitStyle[1];
      targetElement.style[camelCasedProp] = styleValue;
    }
  };

  const addCurrentDisplayedElement = (targetElementId, styleBeforeAction) => {
    const displayedElement = {
      elementId: targetElementId,
      styleBeforeAction: styleBeforeAction,
    };
    /**
     * There are some cases that we set multiple event listeners to an element.
     * If an elementId is already existed in the currentDisplayedElements array,
     * don't add it in again.
     */
    let isElementIdExisted = false;

    if (currentDisplayedElements.length > 0) {
      const filteredArr = currentDisplayedElements.filter(
        (ele) => ele.elementId === targetElementId
      );
      if (filteredArr.length > 0) {
        isElementIdExisted = true;
      }
    }
    !isElementIdExisted && currentDisplayedElements.push(displayedElement);
  };

  /**
   * Change the style of an element to the original style before action happened
   * and remove that element from the currentDisplayedElements array.
   */
  const resetStyleAndUpdateCurrentDisplayedElementsArr = (
    targetElementId,
    eventMethod
  ) => {
    if (eventMethod?.resetStyleOnSelf) {
      const targetElementStyleData = currentDisplayedElements.filter(
        (ele) => ele.elementId === targetElementId
      );
      if (targetElementStyleData.length > 0) {
        const obj = targetElementStyleData[0];
        const targetElement = document.getElementById(targetElementId);
        overrideStyle(targetElement, obj.styleBeforeAction);

        // remove the target element from the currentDisplayedElements array
        currentDisplayedElements = currentDisplayedElements.filter(
          (ele) => ele.elementId !== targetElementId
        );
      }
    }
  };

  const handleEventListener = (
    targetElementId,
    styleBeforeAction,
    eventMethod
  ) => {
    const targetElement = document.getElementById(targetElementId);

    if (targetElement) {
      const filteredArr = currentDisplayedElements.filter(
        (ele) => ele.elementId === targetElementId
      );
      const isTargetElementDisplayed = filteredArr.length > 0 ? true : false;

      if (eventMethod?.resetStyleOnOthers) {
        for (let i = 0; i < currentDisplayedElements.length; i++) {
          resetStyleAndUpdateCurrentDisplayedElementsArr(
            currentDisplayedElements[i].elementId,
            eventMethod
          );
        }
      }

      if (eventMethod?.resetStyleOnSelf && isTargetElementDisplayed) {
        resetStyleAndUpdateCurrentDisplayedElementsArr(
          targetElementId,
          eventMethod
        );
      } else {
        /**
         * Add the current displayed element in the currentDisplayedElements array.
         * Then we can use the information listed in the array to reset style of target elements.
         * For example: Mobile header
         * Contains
         * - header nav1, sub-nav1 (display: none)
         * - header nav2, sub-nav2 (display: none)
         * 1. click on header nav1
         *   => display sub-nav1 by changing the style to display:block
         *   => add sub-nav1 in the currentDisplayedElements array
         * 2. click on header nav2
         *   => display sub-nav2 by changing the style to display:block
         *   => add sub-nav2 in the currentDisplayedElements array
         * 3. click on header nav 2 again
         *   => hide sub-nav2 by changing the style to the original style
         *      before the action happened which is display:none
         */
        if (eventMethod?.eventType === 'click') {
          addCurrentDisplayedElement(targetElementId, styleBeforeAction);
        }
        // override style of the target element on action
        if (eventMethod?.style) {
          overrideStyle(targetElement, eventMethod.style);
        }
      }
    }
  };

  const setEventListener = (eventMethod, currentEle, targetElementId) => {
    const handleEvent = () => {
      let targetElement: HTMLElement | null = null;
      let styleBeforeAction: string = '';

      targetElement = document.getElementById(targetElementId);

      if (targetElement) {
        styleBeforeAction = targetElement.style.cssText;
        handleEventListener(targetElementId, styleBeforeAction, eventMethod);
      }
    };

    currentEle.addEventListener(eventMethod.eventType, handleEvent);
  };

  /**
   * Recursive function that loop through the JSON data to build the html elements
   * based on the JSON data with unlimited children elements
   *
   * Steps
   * 1. build current element
   * 2. for loop - check if there are children array, do recursive call.
   * 3. add an element inside a parent div or in the sibling level based on JSON data
   * 5. append the html that is built in the DOM.
   *
   * Note: There is the Threat model that lists all supported html elements,
   * html attributes, and event types here
   * https://housecanary.atlassian.net/wiki/spaces/CON/pages/2406711312/CMG+SDK#Threat-Model
   *
   */
  const buildHTMLElements = (
    instructions,
    parentElement?: HTMLElement
  ): HTMLElement | undefined | null => {
    if (typeof document !== 'undefined') {
      if (instructions) {
        const supportedHtmlElements = SUPPORTED_JSON_DATA.htmlElements;
        const supportedHtmlAttributes = SUPPORTED_JSON_DATA.htmlAttributes;
        const supportedEventTypes = SUPPORTED_JSON_DATA.eventTypes;

        if (
          !supportedHtmlElements.includes(
            instructions.htmlElement.toLowerCase()
          )
        ) {
          return;
        }

        const currentEle = document.createElement(instructions.htmlElement);

        for (const property in instructions.attributes) {
          const value = instructions.attributes[property];
          // Only set attributes that are supported and don't have "script" in the value.
          if (
            value &&
            value.indexOf('script') === -1 &&
            supportedHtmlAttributes.includes(property)
          ) {
            currentEle.setAttribute(
              property,
              instructions.attributes[property]
            );
          }
        }

        if (instructions?.text) {
          currentEle.innerText = instructions.text;
        }

        if (instructions?.methods) {
          for (let i of instructions.methods) {
            if (supportedEventTypes.includes(i.eventType)) {
              if (i?.actionOnElementId) {
                targetElementId = i.actionOnElementId;
              }
              setEventListener(i, currentEle, targetElementId);
            }
          }
        }

        if (parentElement) {
          parentElement.appendChild(currentEle);
        } else {
          parentElement = currentEle;
        }

        if (instructions?.children) {
          if (instructions.children.length > 0) {
            for (let i of instructions.children) {
              buildHTMLElements(i.element, currentEle);
            }
          }
        } else {
          buildHTMLElements(instructions.element, currentEle);
        }

        return parentElement;
      }
    }

    return null;
  };

  const appendScaffoldingReactChild = (elementInstruction) => {
    const scaffoldingComponent = buildHTMLElements(elementInstruction);

    if (scaffoldingRef?.current && scaffoldingComponent) {
      scaffoldingRef.current.appendChild(scaffoldingComponent);
    }
  };

  useEffect(() => {
    appendScaffoldingReactChild(elementInstruction);
  }, []);

  return <div ref={scaffoldingRef} id="scaffoldingWrapper"></div>;
};

export default ScaffoldingUI;
