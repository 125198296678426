import React, { Component } from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import { LegendColorTable } from '@client/store/types/maps';
import { VisuallyHidden } from '@reach/visually-hidden';
import defaultTheme from '@client/css-modules/MapLegend.css';
import MapLegendBreak from '@client/components/MapLegendBreak';

type Color = string | null;

type Props = {
  colorTable: LegendColorTable | null;
  colors?: Color[];
  halftone: boolean;
  small?: boolean;
  theme: Theme;
};

/**
 * A legend to be displayed alongside the map filter controls
 */
class MapLegend extends Component<Props> {
  render() {
    const { colorTable, colors, halftone, small, theme } = this.props;

    return (
      <div
        className={theme.MapLegend}
        data-hc-name="map-layer-legend"
      >
        {
          <span key="LegendItemLabel" className={theme.LegendItemLabel}>
            {colorTable && colorTable[0].label[0]}
            <VisuallyHidden as="span">
              (Low)
            </VisuallyHidden>
          </span>
        }
        {
          /* If we have color gradients, display them.  Else fall back to color table def */
          colors ? (
            colors
              .filter((color) => color)
              .map((color, i) => (
                <MapLegendBreak
                  key={i}
                  i={i}
                  color={color as NonNullable<Color>}
                  halftone={halftone}
                  small={small}
                />
              ))
          ) : (
            <div className={theme.NoDataPlaceholder} />
          )
        }
        {
          <span key="LegendItemLabelEnd" className={theme.LegendItemLabel}>
            {colorTable && colorTable.slice(-1)[0].label[1]}
            <VisuallyHidden as="span">
              (High)
            </VisuallyHidden>
          </span>
        }
      </div>
    );
  }
}

const ThemedMapLegend = themr('MapLegend', defaultTheme)(MapLegend);
export default ThemedMapLegend;
