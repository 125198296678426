import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import theme from '@client/css-modules/SRPFinanceCTA.css';
import { reportEvent } from '@client/store/actions/analytics.actions';
import CTARequestForm from '@client/components/CTARequestForm';
import PillButton from '@client/components/generic/PillButton';

const SRPFinanceCTA: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const openForm = () => {
    dispatch(reportEvent('click_finance_cta_search'));
    setShowForm(true);
  };
  const closeForm = () => {
    setShowForm(false);
  };
  return (
    <div className={theme.SRPFinanceCTA}>
      <div className={theme.MainContent}>
        <div className={theme.CTAHeader}>We can help you…</div>
        <div className={theme.CTABody}>Finance your next home purchase.</div>
      </div>
      <PillButton
        ariaLabel={'Start now'}
        onClick={openForm}
        className={theme.PillButton}
      >
        Start now
      </PillButton>
      <CTARequestForm isActive={showForm} handleClose={closeForm} />
    </div>
  );
};

export default SRPFinanceCTA;
