import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import AvmFactorDeltaValueDefault from './AvmFactorDeltaValueDefault';
import AvmFactorDeltaValueChase from './AvmFactorDeltaValueChase';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import { AVMFactorDeltaValueProps } from '@client/store/types/cobranded-components/avm-factor-delta-value';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return AvmFactorDeltaValueChase;
    default:
      return AvmFactorDeltaValueDefault;
  }
};

const AVMFactorDeltaValueCobranded: React.FC<AVMFactorDeltaValueProps> = (
  props
) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default AVMFactorDeltaValueCobranded;
