import { themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ContactNewAgentButton from '@client/components/ContactNewAgentModal/ContactNewAgentButton';
import Disclosure from '@client/components/Disclosure';
import DisclosureAsterisk from '@client/components/generic/DisclosureAsterisk';
import MLSAttribution from '@client/components/MLSAttribution';
import PDPExpertRailCard from '@client/components/PDPExpertRailCard';
import PDPFinanceCTA from '@client/components/PDPFinanceCTA';

import HorizontalSeparator from '@client/components/generic/HorizontalSeparator';
import VerticalSeparator from '@client/components/generic/VerticalSeparator';
import ImageCTAOrLenderCTA from '@client/components/ImageCTAOrLenderCTA';
import LegacyLenderCTAButtonCobranded from '@client/components/LegacyLenderCTAButton/LegacyLenderCTAButtonCobranded';
import LenderCTAButtonPDP from '@client/components/LenderCTAButtonPDP/LenderCTAButtonPDP';
import PDPLoanOfficerAdCobranded from '@client/components/PDPLoanOfficerAd/PDPLoanOfficerAdCobranded';
import RequestATourButton from '@client/components/RequestATour/RequestATourButton';
import ScaffoldingMarketingCardPDP from '@client/components/ScaffoldingUI/ScaffoldingMarketingCard/ScaffoldingMarketingCardPDP';
import ShareButtonWithModal from '@client/components/ShareButtonWithModal';
import WatchListActionButtonContainer from '@client/containers/watchlist-action-button.container';
import defaultTheme from '@client/css-modules/PDPRightRailCard.css';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import { LockedComponent } from '@client/hocs/locked-component';
import LendcoChevron from '@client/inline-svgs/cobrand/lendco/lendco-chevron';
import {
  reportPropertyDetailsUnwatchClick,
  reportPropertyDetailsUnwatchConfirmClick,
  reportPropertyDetailsWatchClick,
} from '@client/store/actions/analytics.actions';
import { EVENTS, PARENT_EVENTS } from '@client/store/analytics-constants';
import { NWMLS_ID, STATUSES } from '@client/store/constants';
import { LOCKED_RENTAL_ESTIMATE_VALUE_PLACEHOLDER } from '@client/store/locked-components-constants';
import {
  getEstimatedPaymentDisclosureText,
  getPropertyDetailPageConfig,
} from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { getIsShowingLenderCTACards } from '@client/store/selectors/loan-officer.selectors';
import {
  getLoanTerms,
  getMonthlyPaymentEstimateForProperty,
  getMortgageCalculationDetails,
  getMortgageCalculationDetailsStatus,
  getPropertyDetailsNormalized,
  getPropertyIsInWatchList,
  getRentalAvmValues,
} from '@client/store/selectors/property-details.selectors';
import { PDPRightRailCardProps } from '@client/store/types/cobranded-components/pdp-right-rail-card';
import {
  SectionId,
  SpecialUserType,
} from '@client/store/types/locked-components';
import { MortgageTerm } from '@client/store/types/property';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { dollarsFormatter } from '@client/utils/formatter.utils';
import {
  getIsActiveListing,
  getMLSIdFromPropertyDetails,
  getPropertyValueAndValueLabel,
} from '@client/utils/property.utils';
import { dollarsWithPlaceholder } from '@client/utils/string.utils';
import { SecondaryCTAButtonPDP } from '../SecondaryCTAButtonPDP/SecondaryCTAButtonPDP';

const PDPRightRailCard = ({
  isShowingMonthlyPaymentDetails,
  isShowingShareAndSaveSection,
  isStandaloneModule,
  isCanaryUIFeatureEnabled,
  lenderCTAForwardedRef,
  onClick,
  theme,
}: PDPRightRailCardProps) => {
  // Feature Flags
  const isShowingLOCard = useSelector(getIsFeatureEnabled('loan_officer'));
  const isFinanceCTAEnabled = useSelector(getIsFeatureEnabled('finance_cta'));
  const isYourTeamEnabled = useSelector(getIsFeatureEnabled('your_team'));
  const isCTACleanupEnabled = useSelector(getIsFeatureEnabled('cta_cleanup'));
  const isInvestmentExperienceEnabled = useSelector(
    getIsFeatureEnabled('investment_experience')
  );
  const isShowingLenderCTAButton = useSelector(getIsShowingLenderCTACards);
  const isEstimatedMortgagePaymentsFeatureActivated = useSelector(
    getIsFeatureEnabled('estimated_mortgage_payments')
  );
  const isRequestATourEnabled = useSelector(
    getIsFeatureEnabled('request_a_tour')
  );
  const isContactAgentPDPCTAEnabled = useSelector(
    getIsFeatureEnabled('contact_agent_pdp_cta')
  );
  const isReferralServicesEnabled = useSelector(
    getIsFeatureEnabled('referral_services')
  );
  const isAgentReferralLeadNetworkEnabled = useSelector(
    getIsFeatureEnabled('agent_lead_referral_network')
  );
  const isShowingLegacyLenderCTA = useSelector(
    getIsFeatureEnabled('legacy_lender_cta')
  );
  const isPDPExpertRailCardEnabled = useSelector(
    getIsFeatureEnabled('pdp_expert_railcard')
  );

  const isShowingContactAgentButton =
    isAgentReferralLeadNetworkEnabled ||
    (isContactAgentPDPCTAEnabled && isReferralServicesEnabled);

  const propertyDetailPageConfig = useSelector(getPropertyDetailPageConfig);

  const [isShowingExpandedMortgageInfo, setIsShowingExpandedMortgageInfo] =
    useState<boolean>(false);
  const [loanDetails, setLoanDetails] = useState<{
    term: number | string;
    isArm: boolean;
  }>({ term: 30, isArm: false });
  const dispatch = useDispatch();
  const handlePropertyDetailsWatchClick = (slug) => {
    dispatch(reportPropertyDetailsWatchClick(slug));
  };

  const handlePropertyDetailsUnwatchClick = (slug) => {
    dispatch(reportPropertyDetailsUnwatchClick(slug));
  };

  const handlePropertyDetailsUnwatchConfirmClick = (slug) => {
    dispatch(reportPropertyDetailsUnwatchConfirmClick(slug));
  };

  const handleInfoToggle = () => {
    setIsShowingExpandedMortgageInfo(!isShowingExpandedMortgageInfo);
  };

  const estimatedPaymentDisclosureText = useSelector(
    getEstimatedPaymentDisclosureText
  );
  const isAddedToWatchList = useSelector(getPropertyIsInWatchList);
  const mortgageDetails = useSelector(getMortgageCalculationDetails);
  const { interestRate, mortgageId, monthlyPayment } = mortgageDetails;
  const loanTerms = useSelector(getLoanTerms);
  const propertyDetails = useSelector(getPropertyDetailsNormalized);
  const rentalAvm = useSelector(getRentalAvmValues);
  const isActiveListing = propertyDetails
    ? getIsActiveListing(propertyDetails.status)
    : false;
  const monthlyPaymentEstimateForProperty = useSelector(
    getMonthlyPaymentEstimateForProperty
  );
  const mortgageCalculationDefaultsStatus = useSelector(
    getMortgageCalculationDetailsStatus
  );
  const getLoanTermForMortgageId = (
    loanTerms: MortgageTerm[],
    mortgageId?: string | null
  ): { term: number | string; isArm: boolean } => {
    let term;
    let isArm;
    if (loanTerms.length > 0 && mortgageId) {
      loanTerms.find((loanTerm) => {
        if (loanTerm.id === mortgageId) {
          isArm = loanTerm.is_arm;
          if (!isArm) {
            term = loanTerm.term;
          } else {
            /**
             * Needed to show relevant UX
             */
            if (loanTerm.label === '7/1 ARM') {
              term = '7/1';
            }
            if (loanTerm.label === '5/1 ARM') {
              term = '5/1';
            }
          }
        }
      });
    }
    return { term, isArm };
  };

  const accountForNullToNumber = (value: number | null | undefined) => {
    return !value ? 0 : +value;
  };

  const getTotalEstimatedMonthlyPayment = (
    monthlyPayment,
    associationFee,
    pmi,
    tax
  ): number => {
    return (
      accountForNullToNumber(monthlyPayment) +
      accountForNullToNumber(associationFee) +
      accountForNullToNumber(pmi) +
      accountForNullToNumber(tax)
    );
  };

  useEffect(() => {
    setLoanDetails(getLoanTermForMortgageId(loanTerms, mortgageId));
  }, [loanTerms, mortgageId]);

  const { fullAddress, slug } = propertyDetails || {};

  if (!propertyDetails || !slug || !fullAddress) {
    return null;
  }

  const valueAndValueLabel = !propertyDetails
    ? { value: 0, valueLabel: '' }
    : getPropertyValueAndValueLabel(propertyDetails);
  const { value, valueLabel } = valueAndValueLabel;

  const { associationFee, pmi, tax } = monthlyPaymentEstimateForProperty || {};
  const total = getTotalEstimatedMonthlyPayment(
    monthlyPayment,
    associationFee,
    pmi,
    tax
  );
  const MORTGAGE_TEXT_ANIMATION_CONFIG = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 0.01, delay: 0.055 } },
    exit: { opacity: 0, transition: { duration: 0.01, delay: 0.055 } },
  };

  const watchlistAddress = {
    street: propertyDetails.streetAddress,
    city: propertyDetails.city,
    state: propertyDetails.state,
    zip: propertyDetails.zipcode,
    unit: propertyDetails.unit,
    address_id: propertyDetails.hcAddressId,
    slug: propertyDetails.slug,
  };

  return (
    <>
      <div
        data-hc-name="summary-options-panel"
        className={classNames(theme.PDPRightRailCard, {
          [theme.PDPRightRailCardWithBoxShadow]: isStandaloneModule,
          [theme.PDPRightRailCardWithMonthlyPaymentDetails]:
            isShowingMonthlyPaymentDetails,
          [theme.PDPRightRailCardWithHiddenShareAndSave]:
            !isShowingShareAndSaveSection,
        })}
        onClick={onClick}
      >
        <div className={theme.ContentWrapper}>
          <div className={theme.ListingStatusSectionWrapper}>
            <div
              className={classNames(theme.ListingStatusSection, {
                [theme.ListingStatusWithNoEstimatedPayment]:
                  !isEstimatedMortgagePaymentsFeatureActivated,
              })}
              data-hc-name="listing-status"
            >
              <div className={theme.ListingStatus}>
                {valueLabel} {value ? dollarsFormatter(value) : '-'}
              </div>

              {propertyDetailPageConfig?.showRentalEstimate && (
                <div className={theme.RentalEstimate}>
                  <div className={theme.RentalEstimateLabel}>
                    Rental Estimate:{' '}
                  </div>

                  <LockedComponent
                    sectionId={SectionId.PropertyCardRentalEstimate}
                    lockedFor={[SpecialUserType.Restricted]}
                    theme={theme}
                    className={theme.LockedComponentContainer}
                  >
                    {({ isLocked }) => (
                      <div
                        className={classNames(theme.RentalEstimateValue, {
                          [theme.disabled]: isLocked,
                        })}
                      >
                        {isLocked
                          ? `${LOCKED_RENTAL_ESTIMATE_VALUE_PLACEHOLDER}`
                          : `${dollarsWithPlaceholder(rentalAvm.priceMean)}/month`}
                      </div>
                    )}
                  </LockedComponent>
                </div>
              )}

              {
                /**
                 * We are making sure feature flag to show estimated mortgage payments is on
                 * homePrice/value is not null, homePrice is needed to calculate mortgage payments
                 * By checking for mortgageCalculationDefaultsStatus to be success, we want to make
                 * we are not showing estimated payment that changes up on fetching all the required data
                 */
                isEstimatedMortgagePaymentsFeatureActivated &&
                  !!value &&
                  mortgageCalculationDefaultsStatus === STATUSES.SUCCESS && (
                    <>
                      <HorizontalSeparator theme={theme} />
                      <div
                        className={theme.MonthlyPaymentsSection}
                        data-hc-name="monthly-payment"
                      >
                        <div className={theme.Label}>
                          Estimated monthly payment{' '}
                          {dollarsWithPlaceholder(total)}
                        </div>
                        {value && isShowingMonthlyPaymentDetails && (
                          <button
                            className={theme.ChevronIconButton}
                            data-testid="mortgage-info"
                            type="button"
                            aria-expanded={isShowingExpandedMortgageInfo}
                            aria-label="Toggle mortgage info"
                            onKeyDown={onEnterOrSpaceKey(handleInfoToggle)}
                            onClick={handleInfoToggle}
                          >
                            <LendcoChevron
                              className={classNames(theme.ChevronIcon, {
                                [theme.active]: isShowingExpandedMortgageInfo,
                              })}
                            />
                          </button>
                        )}
                      </div>
                    </>
                  )
              }
            </div>

            <AnimatePresence>
              {isShowingMonthlyPaymentDetails &&
                isShowingExpandedMortgageInfo && (
                  <motion.section
                    className={theme.MortgageInfo}
                    key="MortgageInfoAnimation"
                    initial={{ height: 0 }}
                    animate={{
                      height: isShowingExpandedMortgageInfo ? 'auto' : 0,
                      transition: { duration: 0.35, easing: 'easeInOut' },
                    }}
                    exit={{
                      height: 0,
                      transition: { duration: 0.35, easing: 'easeInOut' },
                    }}
                  >
                    <motion.div
                      className={theme.MortgageContent}
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: {
                          duration: 0.045,
                          easing: 'easeIn',
                        },
                      }}
                      exit={{
                        opacity: 0,
                        transition: { duration: 0.045, easing: 'easeIn' },
                      }}
                      key="MortgageContentAnimation"
                    >
                      <div className={theme.MortgageDefaultsContainer}>
                        <motion.div
                          {...MORTGAGE_TEXT_ANIMATION_CONFIG}
                          className={theme.MortgageDefault}
                          key="MortgageTermTextAnimation"
                        >
                          <div className={theme.Value}>{loanDetails.term}</div>
                          <div className={theme.Label}>
                            {loanDetails.isArm ? 'ARM' : 'Year fixed'}
                          </div>
                        </motion.div>
                        <motion.div
                          {...MORTGAGE_TEXT_ANIMATION_CONFIG}
                          className={theme.MortgageDefault}
                          key="MortgageTextAnim"
                        >
                          <VerticalSeparator theme={theme} />
                        </motion.div>
                        <motion.div
                          {...MORTGAGE_TEXT_ANIMATION_CONFIG}
                          className={theme.MortgageDefault}
                          key="MortgageRateTextAnimation"
                        >
                          <div className={theme.Value}>
                            {!interestRate ? '0.00%' : interestRate + '%'}
                          </div>
                          <div className={theme.Label}>
                            Rate
                            {!!estimatedPaymentDisclosureText && (
                              <DisclosureAsterisk theme={theme} />
                            )}
                          </div>
                        </motion.div>
                      </div>
                      <div className={theme.AdditionalPropertyDetails}>
                        <motion.div
                          {...MORTGAGE_TEXT_ANIMATION_CONFIG}
                          className={theme.PropertyDetailsRow}
                          key="MortgageLoanTextAnimation"
                        >
                          <div className={theme.Label}>Mortgage Payment</div>
                          <div className={theme.Value}>
                            {dollarsWithPlaceholder(monthlyPayment)}
                          </div>
                        </motion.div>
                        <motion.div
                          {...MORTGAGE_TEXT_ANIMATION_CONFIG}
                          className={theme.PropertyDetailsRow}
                          key="MortgageTaxTextAnimation"
                        >
                          <div className={theme.Label}>Property taxes</div>
                          <div className={theme.Value}>
                            {dollarsWithPlaceholder(tax)}
                          </div>
                        </motion.div>
                        <motion.div
                          {...MORTGAGE_TEXT_ANIMATION_CONFIG}
                          className={theme.PropertyDetailsRow}
                          key="MortgageInsuranceTextAnimation"
                        >
                          <div className={theme.Label}>Insurance</div>
                          <div className={theme.Value}>
                            {pmi ? dollarsFormatter(pmi) : '--'}
                          </div>
                        </motion.div>
                        <motion.div
                          {...MORTGAGE_TEXT_ANIMATION_CONFIG}
                          className={theme.PropertyDetailsRow}
                          key="MortgageHoaTextAnimation"
                        >
                          <div className={theme.Label}>HOA fees</div>
                          <div className={theme.Value}>
                            {dollarsWithPlaceholder(associationFee)}
                          </div>
                        </motion.div>
                      </div>
                      <motion.div
                        {...MORTGAGE_TEXT_ANIMATION_CONFIG}
                        className={theme.PropertyDetailsRow}
                        key="MortgageHoaTextDisclosure"
                      >
                        <Disclosure
                          theme={theme}
                          showEstimatedPaymentDisclosure
                        />
                      </motion.div>
                    </motion.div>
                  </motion.section>
                )}
            </AnimatePresence>
          </div>
          {isCanaryUIFeatureEnabled && (
            <ScaffoldingMarketingCardPDP key="ScaffoldingMarketingCardPDP" />
          )}

          {!isCanaryUIFeatureEnabled && isShowingLenderCTAButton && (
            <ImageCTAOrLenderCTA
              area="pdp"
              theme={theme}
              LenderCTA={
                <LenderCTAButtonPDP
                  theme={theme}
                  dataEventName={EVENTS.CLICK_LENDER_CTA}
                  dataParentEventName={PARENT_EVENTS.CLICK_LENDER_CTA}
                  forwardedRef={lenderCTAForwardedRef}
                />
              }
            />
          )}

          {isInvestmentExperienceEnabled && <SecondaryCTAButtonPDP />}

          {!isCanaryUIFeatureEnabled &&
            !isShowingLenderCTAButton &&
            isShowingLegacyLenderCTA && (
              <LegacyLenderCTAButtonCobranded
                data-event-name={EVENTS.CLICK_LENDER_CTA}
                data-parent-event-name={EVENTS.CLICK_LENDER_CTA}
                theme={theme}
              />
            )}

          {isShowingContactAgentButton && <ContactNewAgentButton />}

          <div className={theme.ShareAndSavePropertySection}>
            {isRequestATourEnabled && isActiveListing ? (
              <>
                <RequestATourButton theme={theme} />
                <WatchListActionButtonContainer
                  address={watchlistAddress}
                  fullAddress={fullAddress}
                  isAddedToWatchList={isAddedToWatchList}
                  addressSlug={slug}
                  theme={theme}
                  isShowingButtonLabel
                  handleReportWatchClick={handlePropertyDetailsWatchClick}
                  handleReportUnwatchClick={handlePropertyDetailsUnwatchClick}
                  handleReportUnwatchConfirmClick={
                    handlePropertyDetailsUnwatchConfirmClick
                  }
                  shouldHandleCheckingForWatchListStatus={true}
                />
                <div
                  className={theme.ShareButtonContainer}
                  data-hc-name="share-button"
                >
                  <AccessibleElementUniqueId>
                    {({ uid }) => (
                      <>
                        <ShareButtonWithModal
                          id={uid}
                          theme={theme}
                          slug={slug}
                        />
                        <label htmlFor={uid} className={theme.ButtonLabel}>
                          Share
                        </label>
                      </>
                    )}
                  </AccessibleElementUniqueId>
                </div>
              </>
            ) : (
              <>
                <div
                  className={theme.ShareButtonContainer}
                  data-hc-name="share-button"
                >
                  <AccessibleElementUniqueId>
                    {({ uid }) => (
                      <>
                        <ShareButtonWithModal
                          id={uid}
                          theme={theme}
                          slug={slug}
                        />
                        <label htmlFor={uid} className={theme.ButtonLabel}>
                          Share
                        </label>
                      </>
                    )}
                  </AccessibleElementUniqueId>
                </div>
                <WatchListActionButtonContainer
                  address={watchlistAddress}
                  fullAddress={fullAddress}
                  isAddedToWatchList={isAddedToWatchList}
                  addressSlug={slug}
                  theme={theme}
                  isShowingButtonLabel
                  handleReportWatchClick={handlePropertyDetailsWatchClick}
                  handleReportUnwatchClick={handlePropertyDetailsUnwatchClick}
                  handleReportUnwatchConfirmClick={
                    handlePropertyDetailsUnwatchConfirmClick
                  }
                  shouldHandleCheckingForWatchListStatus={true}
                />
              </>
            )}
          </div>
        </div>

        {getMLSIdFromPropertyDetails(propertyDetails) !== NWMLS_ID && (
          <MLSAttribution
            className={theme.MLSAttribution}
            propertyDetails={propertyDetails}
          />
        )}

        {isPDPExpertRailCardEnabled && isYourTeamEnabled ? (
          <PDPExpertRailCard />
        ) : isFinanceCTAEnabled ? (
          <div className={theme.PDPFinanceCTAContainer}>
            {isActiveListing && <PDPFinanceCTA isStandaloneModule={false} />}
          </div>
        ) : /* TODO cleanup this logic */
        isShowingLOCard &&
          !isCTACleanupEnabled &&
          !isCanaryUIFeatureEnabled &&
          !(isShowingLenderCTAButton && isYourTeamEnabled) ? (
          <PDPLoanOfficerAdCobranded />
        ) : null}
      </div>
    </>
  );
};

const ThemedPDPRightRailCard = themr(
  'PDPRightRailCard',
  defaultTheme
)(PDPRightRailCard);
export default React.memo(ThemedPDPRightRailCard);
