import { connect } from 'react-redux';
import { routeChange } from '@src/redux-saga-router-plus/actions';

import SavedSearches from '@client/pages/SavedSearches';
import {
  getSavedSearches,
  getSavedSearchesStatus,
  getSavedSearchFilterSummaryMap,
} from '@client/store/selectors/saved-search.selectors';
import {
  getIsLoggedIn,
  getAccountDetailsIsLoading,
} from '@client/store/selectors/auth.selectors';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import {
  eventType,
  reportEvent,
} from '@client/store/actions/analytics.actions';
import { getIsUsingMobileFiltersUI } from '@client/store/selectors/search.selectors';
import { EVENTS } from '@client/store/analytics-constants';
import {
  STATUSES,
  SEARCH_MAP_DESKTOP_FILTERS_SHOWN_URL_KEY,
  SEARCH_MAP_MOBILE_FILTERS_SHOWN_URL_KEY,
} from '@client/store/constants';
import { View } from '@client/routes/constants';

import {
  deleteSavedSearch,
  selectSavedSearch,
  fetchSavedSearchesIfNecessary,
} from '@client/store/actions/saved-search.actions';
import { getWatchlistPageConfig } from '@client/store/selectors/cobranding.selectors';

const mapStateToProps = (state) => {
  const savedSearchesFetchStatus = getSavedSearchesStatus(state);

  return {
    isLoadingAuthentication: getAccountDetailsIsLoading(state),
    isLoggedIn: getIsLoggedIn(state),
    isSmallScreen: getIsSmallSize(state),
    isSavedSearchesStatusInit:
      savedSearchesFetchStatus === STATUSES.INIT ||
      savedSearchesFetchStatus === STATUSES.LOADING,
    isUsingMobileFiltersUI: getIsUsingMobileFiltersUI(state),
    savedSearches: getSavedSearches(state),
    savedSearchesPageConfig: getWatchlistPageConfig(state),
    savedSearchesFilterSummaryMap: getSavedSearchFilterSummaryMap(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleFetchSavedSearchesData: () => {
      dispatch(fetchSavedSearchesIfNecessary());
    },
    handleDeleteSavedSearch: (saved_search_id) => {
      dispatch(reportEvent('click_settings_privacy', '', { saved_search_id }));
      dispatch(deleteSavedSearch(saved_search_id));
    },
    handleSavedSearchClick: (id) =>
      dispatch(selectSavedSearch(id, EVENTS.CLICK_SETTINGS_SAVED_SEARCH)),
    reportQuickFilterMobileRemovePropertyClick: () =>
      dispatch(reportEvent('click_saved_search_remove_property')),
    handleOpenMobileFilters: () => {
      /* When navigating directly to the mobile filters modal from another page, we need to first navigate
       * to the search page then to the filters modal since exiting the filters modal works via going "back"
       * in the browser, and we expect to be on the search page when we exit the modal */
      dispatch(routeChange({ view: View.SEARCH }));
      window.setTimeout(() => {
        dispatch(
          routeChange({
            view: View.SEARCH,
            query: { [SEARCH_MAP_MOBILE_FILTERS_SHOWN_URL_KEY]: true },
          })
        );
      }, 0);
    },
    handleOpenDesktopFilters: () => {
      dispatch(
        routeChange({
          view: View.SEARCH,
          query: { [SEARCH_MAP_DESKTOP_FILTERS_SHOWN_URL_KEY]: true },
        })
      );
    },
    handleReportEvent: (eventName: eventType) => {
      dispatch(reportEvent(eventName));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedSearches);
