import { routeChange } from '@src/redux-saga-router-plus/actions';
import { call, put, select } from 'redux-saga/effects';

import { View } from '@client/routes/constants';
import { consumerApiClient } from '@client/services/consumer-api-client';
import { graphQLApiClient } from '@client/services/graphql-api-client';
import {
  ALERTS_FETCH_NEXT_PAGE,
  ALERTS_GO_TO_PROPERTY,
  ALERTS_MARK_ALL_READ,
  ENSURE_LOGIN_THEN_REDIRECT_TO_NOTIFICATIONS_PAGE,
  FETCH_ALERT_MLS_DETAILS,
  FETCH_ALERTS,
  FETCH_ALERTS_UNREAD_COUNT,
  FetchAlertMlsDetails,
  fetchAlertMlsDetailsSuccess,
  fetchAlertsSuccess,
  fetchAlertsUnreadCountSuccess,
  GoToAlertProperty,
} from '@client/store/actions/alerts.actions';
import { ensureLoggedInThen } from '@client/store/sagas/auth.saga';
import { getAlertsNextPageUrl } from '@client/store/selectors/alerts.selectors';
import { watchEvery } from '@client/utils/saga.utils';

function* getAlertsSaga(action) {
  const { alerts, parsedUrlValues } = yield call(
    [consumerApiClient, consumerApiClient.getAlerts],
    action
  );
  yield put(
    fetchAlertsSuccess({
      alerts,
      markAsReadUrl: parsedUrlValues['mark-as-read'],
      /* Will be undefined if no next page */
      nextPageUrl: parsedUrlValues['next'],
    })
  );
}

function* getAlertMlsData(action: FetchAlertMlsDetails) {
  const { slug } = action.payload;
  const response = yield call(
    [graphQLApiClient, graphQLApiClient.getAlertCardMlsDetails],
    slug
  );
  yield put(
    fetchAlertMlsDetailsSuccess({
      slug,
      mlsPhotoOverlayDetails: response?.propertyLookup?.mls ?? null,
    })
  );
}

function* getAlertsUnReadCountSaga() {
  const response = yield call([
    consumerApiClient,
    consumerApiClient.getUnreadAlertsCount,
  ]);
  yield put(fetchAlertsUnreadCountSuccess(response.count));
}

function* getAlertsNextPage() {
  const nextPageUrl = yield select(getAlertsNextPageUrl);
  yield call(getAlertsSaga, { payload: { nextPageUrl } });
}

function* markAllRead() {
  yield call([consumerApiClient, consumerApiClient.markAllAlertsAsRead]);
}

function* goToAlertProperty(action: GoToAlertProperty) {
  const slug = action.payload.slug;
  const addressId = action.payload.addressId;

  /* Prefer given slug but fallback to getting slug from addressId */
  if (slug) {
    yield put(routeChange({ view: View.PROPERTY_DETAILS, params: { slug } }));
  } else if (addressId) {
    const data = yield call(
      [graphQLApiClient, graphQLApiClient.getSlugForAddressId],
      addressId
    );
    yield put(
      routeChange({
        view: View.PROPERTY_DETAILS,
        params: { slug: data.propertyLookup.address.slug },
      })
    );
  }
}

function* ensureLoggedInThenRedirectToNotificationsPageSaga() {
  yield call(ensureLoggedInThen, routeChange, { view: View.NOTIFICATIONS });
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [FETCH_ALERTS]: getAlertsSaga,
    [FETCH_ALERT_MLS_DETAILS]: getAlertMlsData,
    [FETCH_ALERTS_UNREAD_COUNT]: getAlertsUnReadCountSaga,
    [ALERTS_MARK_ALL_READ]: markAllRead,
    [ALERTS_GO_TO_PROPERTY]: goToAlertProperty,
    [ALERTS_FETCH_NEXT_PAGE]: getAlertsNextPage,
    [ENSURE_LOGIN_THEN_REDIRECT_TO_NOTIFICATIONS_PAGE]:
      ensureLoggedInThenRedirectToNotificationsPageSaga,
  });
};
