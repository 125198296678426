import { watchEvery } from '@client/utils/saga.utils';
import { call, put, select } from 'redux-saga/effects';

import {
  SHARE_PROPERTY_SUBMIT,
  sharePropertySubmitSuccess,
  sharePropertySubmitFailure,
  SharePropertySubmitAction,
  SHARE_PROPERTY_SUBMIT_SUCCESS,
  SharePropertySubmitSuccessAction,
} from '@client/store/actions/share-property.actions';
import { reportEvent } from '@client/store/actions/analytics.actions';
import {
  getAllUserSettings,
  getIsLoggedIn,
} from '@client/store/selectors/auth.selectors';
import { consumerApiClient } from '@client/services/consumer-api-client';
import { addToWatchList } from '@client/store/actions/watchlist.actions';
import {
  CREATE_USER_SUCCESS,
  LOGIN_SUCCESS,
  updateUserProfile,
} from '@client/store/actions/auth.actions';
import {
  getIsSharedPropertyAlreadySaved,
  getShareProperty,
} from '@client/store/selectors/share-property.selectors';
import { setActiveNotification } from '@client/store/actions/modals.actions';
import { SETTINGS_KEYS } from '@client/store/constants';
import { reportToSentry } from '@client/utils/error.utils';

/**
 * Saga for PDP share modal, calls consumer-app-backend API to send share email.
 * @param action
 */
export function* submitShareProperty(action: SharePropertySubmitAction) {
  try {
    const slug = action.payload.addressSlug;

    const sharePropertyPayload = {
      lo_id: action.payload.loId,
      email: action.payload.emails,
      sender: {
        name: action.payload.usersName,
        email: action.payload.usersEmail,
      },
      message: action.payload.message,
    };
    yield call(
      [consumerApiClient, consumerApiClient.shareProperty],
      slug,
      sharePropertyPayload
    );

    const propertyShareSuccessPayload = {
      slug: action.payload.addressSlug,
      street: action.payload.street,
      city: action.payload.city,
      state: action.payload.state,
      zip: action.payload.zip,
      unit: action.payload.unit,
      address_id: action.payload.address_id,
    };
    yield put(sharePropertySubmitSuccess(propertyShareSuccessPayload));
    yield put(
      reportEvent('click_pdp_share_message_send_cta_success', '', {
        ch_property_id: slug,
        users_name: action.payload.usersName,
        users_email: action.payload.usersEmail,
        people: action.payload.emails,
        message: action.payload.message,
      })
    );
  } catch (e) {
    yield put(
      sharePropertySubmitFailure('Something went wrong. Please try again.')
    );
    yield put(reportEvent('click_pdp_share_message_send_cta_error'));
    /* Calling reportToSentry directly since throwing an error here complicates testing this saga */
    reportToSentry(e);
  }
}

export function* autoSaveSharedProperty(
  action: SharePropertySubmitSuccessAction
) {
  const sharedProperty = (yield select(getShareProperty)) as ReturnType<
    typeof getShareProperty
  >;
  const isLoggedIn = (yield select(getIsLoggedIn)) as ReturnType<
    typeof getIsLoggedIn
  >;
  const isPropertyAlreadySaved = (yield select(
    getIsSharedPropertyAlreadySaved
  )) as ReturnType<typeof getIsSharedPropertyAlreadySaved>;
  const userEmailNotificationSettings = (yield select(
    getAllUserSettings
  )) as ReturnType<typeof getAllUserSettings>;

  /*
   * Shared property slug is saved in the redux state upon SHARE_PROPERTY_SUBMIT_SUCCESS
   * When logged out, save shared property upon LOGIN_SUCCESS or CREATE_USER_SUCCESS.
   * If already logged in, save shared property right away
   */
  if (isLoggedIn && sharedProperty.slug) {
    if (!isPropertyAlreadySaved) {
      const watchlistPayload = {
        slug: sharedProperty.slug,
        street: sharedProperty.street as string,
        city: sharedProperty.city as string,
        state: sharedProperty.state as string,
        zip: sharedProperty.zip as string,
        unit: sharedProperty.unit,
        address_id: sharedProperty.address_id,
      };

      yield put(addToWatchList(watchlistPayload));
    }
    if (!userEmailNotificationSettings.send_email) {
      yield put(updateUserProfile({ [SETTINGS_KEYS.SEND_EMAIL]: true }, true));
      yield put(
        setActiveNotification(
          'shared-property-saved-email-setting-notification'
        )
      );
    } else {
      yield put(setActiveNotification('shared-property-saved-notification'));
    }
  }
}

export default function registerSharePropertySaga(sagaMiddleware) {
  watchEvery(sagaMiddleware, {
    [SHARE_PROPERTY_SUBMIT]: submitShareProperty,
    [SHARE_PROPERTY_SUBMIT_SUCCESS]: autoSaveSharedProperty,
    [LOGIN_SUCCESS]: autoSaveSharedProperty,
    [CREATE_USER_SUCCESS]: autoSaveSharedProperty,
  });
}
