import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import * as Tabs from '@radix-ui/react-tabs';

import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { WatchlistHeaderProps } from '@client/store/types/cobranded-components/watchlist-header';
import theme from '@client/css-modules/WatchListHeaderChase.css';
import {
  WATCHLIST_HEADER_SECTION_CONTENT,
  WATCHLIST_HEADER_SECTION_KEY,
} from '@client/store/constants';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { getWatchlistPageConfig } from '@client/store/selectors/cobranding.selectors';

const WatchListHeaderChase: React.FunctionComponent<WatchlistHeaderProps> = ({
  isSmallScreenSize,
  isWatchListRoute,
  isSavedSearchesRoute,
  goToWatchlist,
  goToSavedSearches,
}) => {
  const { watchlistHeaderItemColor } = useCobrandStyles();

  const defaultTabSelected = isWatchListRoute
    ? WATCHLIST_HEADER_SECTION_KEY.SAVED_HOMES
    : WATCHLIST_HEADER_SECTION_KEY.SAVED_SEARCHES;

  const watchlistPageConfig = useSelector(getWatchlistPageConfig);

  return (
    <Tabs.Root
      activationMode='manual'
      defaultValue={defaultTabSelected}
      onValueChange={(value: string) =>
        value === WATCHLIST_HEADER_SECTION_KEY.SAVED_HOMES
          ? goToWatchlist()
          : goToSavedSearches()
      }
      className={theme.WatchListHeader}
    >
      <Tabs.List className={theme.WatchListTopNavTabList}>
        <Tabs.Trigger
          id={`${WATCHLIST_HEADER_SECTION_KEY.SAVED_HOMES}_TAB`}
          value={WATCHLIST_HEADER_SECTION_KEY.SAVED_HOMES}
          data-hc-name="saved-homes-button"
          aria-label={`View ${WATCHLIST_HEADER_SECTION_CONTENT.SAVED_HOMES.title} ${WATCHLIST_HEADER_SECTION_CONTENT.SAVED_HOMES.subTitle}`}
          aria-controls={undefined}
          aria-selected={isWatchListRoute}
          onKeyDown={onEnterOrSpaceKey(goToWatchlist)}
          className={classNames(theme.NavButton, {
            [theme.ActiveNav]: isWatchListRoute,
          })}
          style={
            isWatchListRoute
              ? {
                  borderBottom: !isSmallScreenSize
                    ? '2px solid #0092FF'
                    : 'none',
                }
              : { borderBottom: '2px solid white' }
          }
        >
          <div className={theme.SavedHomesIconLabel}>
            <span
              role="heading"
              aria-level={1}
              className={theme.Label}
              style={
                isWatchListRoute
                  ? {
                      color: isSmallScreenSize
                        ? watchlistHeaderItemColor
                        : '#414042',
                      borderBottom: isSmallScreenSize
                        ? '1px solid #414042'
                        : 'none',
                    }
                  : {}
              }
            >
              {watchlistPageConfig?.savedHomesLabel || WATCHLIST_HEADER_SECTION_CONTENT.SAVED_HOMES.title}
            </span>
          </div>
          <div className={theme.SubText}>
            {watchlistPageConfig?.savedHomesSubtext || WATCHLIST_HEADER_SECTION_CONTENT.SAVED_HOMES.subTitle}
          </div>
        </Tabs.Trigger>
        <Tabs.Trigger
          id={`${WATCHLIST_HEADER_SECTION_KEY.SAVED_SEARCHES}_TAB`}
          value={WATCHLIST_HEADER_SECTION_KEY.SAVED_SEARCHES}
          data-hc-name="saved-searches-button"
          aria-label={`View ${WATCHLIST_HEADER_SECTION_CONTENT.SAVED_SEARCHES.title} ${WATCHLIST_HEADER_SECTION_CONTENT.SAVED_SEARCHES.subTitle}`}
          aria-controls={undefined}
          aria-selected={isSavedSearchesRoute}
          onKeyDown={onEnterOrSpaceKey(goToSavedSearches)}
          className={classNames(theme.NavButton, {
            [theme.ActiveNav]: isSavedSearchesRoute,
          })}
          style={
            isSavedSearchesRoute
              ? {
                  borderBottom: !isSmallScreenSize
                    ? '2px solid #0092FF'
                    : 'none',
                }
              : { borderBottom: '2px solid white' }
          }
        >
          <div className={theme.SavedSearchesButtonText}>
            <span
              role="heading"
              aria-level={1}
              style={
                isSavedSearchesRoute
                  ? {
                      color: isSmallScreenSize
                        ? watchlistHeaderItemColor
                        : '#414042',
                      borderBottom: isSmallScreenSize
                        ? '1px solid #414042'
                        : 'none',
                    }
                  : {}
              }
              className={theme.Label}
            >
              {watchlistPageConfig?.savedSearchesLabel || WATCHLIST_HEADER_SECTION_CONTENT.SAVED_SEARCHES.title}
            </span>
          </div>
          <div className={theme.SubText}>
            {watchlistPageConfig?.savedSearchesSubtext || WATCHLIST_HEADER_SECTION_CONTENT.SAVED_SEARCHES.subTitle}
          </div>
        </Tabs.Trigger>
      </Tabs.List>
    </Tabs.Root>
  );
};

export default WatchListHeaderChase;
