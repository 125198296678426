import { connect } from 'react-redux';

import { MlsState } from '@client/store/sagas/queries/types';
import { getPropertyListingStatusObjectForAddressSlug } from '@client/store/selectors/property-details.selectors';
import { getStreetViewStateForAddressSlug } from '@client/store/selectors/property-photos.selectors';
import PhotoPlaceholder from '@client/components/PhotoPlaceholder';
import { STATUSES } from '@client/store/constants';
import { fetchStreetViewUrl } from '@client/store/actions/property-photos.actions';

type OwnProps = {
  addressSlug: string | null;
  mapTileSize?: [number, number];
  propertyStatus?: MlsState | 'NOT_LISTED' | null;
};

const mapStateToProps = (state, ownProps: OwnProps) => {
  const addressSlug = ownProps.addressSlug;
  const streetViewState = getStreetViewStateForAddressSlug(addressSlug)(state);
  const listingStatusObject =
    getPropertyListingStatusObjectForAddressSlug(addressSlug)(state);

  return {
    addressSlug,
    propertyStatus: listingStatusObject && listingStatusObject.status,
    mapTileSize: ownProps.mapTileSize || [300, 300],
    streetViewUrl: streetViewState ? streetViewState.url : null,
    streetViewFailed: streetViewState
      ? streetViewState.status === STATUSES.FAILED
      : false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleFetchStreetViewUrl: ({
    slug,
    fullAddress,
    imageHeight,
    imageWidth,
  }: {
    slug: string;
    fullAddress: string;
    imageHeight: number;
    imageWidth: number;
  }) => {
    dispatch(
      fetchStreetViewUrl({ slug, fullAddress, imageHeight, imageWidth })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoPlaceholder);
