export default (slug) => ({
  query: /* GraphQL */ `query AvmDeepDiveData($slug: String) {
    propertyLookup (id: { slug: $slug}) {
      tractStats {
        count
      }
      regressions {
        displayName
        __typename
        ... on AgeRegression {
          ageAreaMedian: areaMedian
          displayName
          regression {
            line {
              x
              y
            }
          }
        }
        ... on LotSizeRegression {
          lotAreaMedian: areaMedian
          displayName
          regression {
            line {
              x
              y
            }
          }
        }
        ... on LivingAreaRegression {
          livingAreaMedian: areaMedian
          displayName
          regression {
            line {
              x
              y
            }
          }
        }
        ... on BedroomsRegression {
          bedroomsAreaMedian: areaMedian
          displayName
          regression {
            line {
              x
              y
            }
          }
        }
        ... on BasementRegression {
          areaPercent
          displayName
        }
        ... on LocationRegression {
          locationAreaMedian: areaMedian
          displayName
        }
        ... on BathroomsRegression {
          areaMedian
          displayName
          regression {
            line {
              x
              y
            }
          }
        }
        ... on PoolRegression {
          areaPercent
          displayName
        }
      }
    }
  }`,

  variables: {
    slug,
  },
});
