export default ({ slug }) => ({
  query: `query CHOProFinderQuery(
      $slug: String
    ) {
    propertyLookup (id: { slug: $slug}) {
      address {
        slug
        hcAddressId
        fullAddress
        streetAddress
        unit
        city
        state
        zipcode
      }
    }
  }`,
  variables: {
    slug,
  },
});
