import geojsonExtent from '@mapbox/geojson-extent';
import { call, delay, put, select } from 'redux-saga/effects';

import { consumerApiClient } from '@client/services/consumer-api-client';
import { reportEvent } from '@client/store/actions/analytics.actions';
import {
  SEARCH_CLEAR_ALL_FILTERS,
  SEARCH_LIST_APPLY_SORT,
  SEARCH_LIST_HANDLE_OPEN_OR_CLOSE,
  SEARCH_MAP_MOVED_AFTER_INIT,
  SEARCH_UPDATE_FILTER,
  searchGetLastSearchSuccess,
  searchGetUserLocationWhenNotAvailable,
  searchSetMapLocationToLastViewedPDP,
} from '@client/store/actions/search.actions';
import { updateAnalyticsSearchState } from '@client/store/sagas/search.saga';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import { getPropertyDataCache } from '@client/store/selectors/property-details.selectors';
import {
  getConsumerAPIFormattedSearchFilters,
  getIsShowingSearchPageList,
  getSearchConstrainedPlace,
  getSearchListSortField,
  getSearchListSortOrder,
  getSearchPlaceGeoJSON,
  getSearchViewport,
} from '@client/store/selectors/search.selectors';
import { BoundsObject } from '@client/store/types/maps';
import { reportToSentry } from '@client/utils/error.utils';
import { consumerAPIFiltersToStateFilters } from '@client/utils/filters.utils';
import { getBoundsFromCenterPointAndRadius } from '@client/utils/maps.utils';
import { watchLatest } from '@client/utils/saga.utils';
import { getIsDisplayMultiFamilySearchFiltersEnabled } from '../selectors/enabled-features.selectors';

export function* getLastSearchSaga({
  shouldRespectSavedView,
}: {
  shouldRespectSavedView: boolean;
}) {
  try {
    const data = yield call([
      consumerApiClient,
      consumerApiClient.getLastSearch,
    ]);
    const isSmallScreen = yield select(getIsSmallSize);
    const isDisplayMultiFamilySearchFiltersEnabled = yield select(
      getIsDisplayMultiFamilySearchFiltersEnabled
    );

    /* eslint-disable camelcase */
    const {
      sw_lat,
      sw_lng,
      ne_lat,
      ne_lng,
      geom,
      search_view,
      sort_field,
      sort_order,
      place_id,
      ...filterData
    } = data;
    let southWest = { lat: sw_lat, lng: sw_lng };
    let northEast = { lat: ne_lat, lng: ne_lng };

    if (geom) {
      const WSENBounds = geojsonExtent(geom);
      southWest = { lat: WSENBounds[1], lng: WSENBounds[0] };
      northEast = { lat: WSENBounds[3], lng: WSENBounds[2] };
    }

    if (!southWest.lat || !southWest.lng) {
      reportToSentry(
        'Unable to determine map position from retrieved last-search data',
        data
      );
    }
    const filters = consumerAPIFiltersToStateFilters(
      filterData,
      isDisplayMultiFamilySearchFiltersEnabled
    );

    /* Only show the dedicated search list view on mobile, no matter the value of the query param.
     * This check prevents issues after changing your screen size after being on the mobile list page */
    const isShowingSearchList = search_view === 'list' && isSmallScreen;

    yield put(
      searchGetLastSearchSuccess({
        filters,
        southWest,
        northEast,
        constrainedToPlace: place_id
          ? {
              placeId: place_id,
              mlsCoverage: null,
              city: null,
              state: null,
              zipcode: null,
            }
          : null,
        placeGeoJSON: geom,
        placeGeoJSONDescription: data.name,
        ...(shouldRespectSavedView ? { isShowingSearchList } : {}),
        sortField: sort_field,
        sortOrder: sort_order,
      })
    );

    yield call(updateAnalyticsSearchState);

    /* Will return a 404 if a last-search doesn't exist for the user */
  } catch (e: any) {
    /* If last-search doesn't exist */
    if (e.statusCode === 404 || e.statusCode === 500) {
      /* First, check to see if we have a previously viewed PDP in the cache, setting to the map
       * to that location if so */
      const propertyDataCache = yield select(getPropertyDataCache);
      const latestPropertyKey = Object.keys(propertyDataCache).sort(
        (a, b) =>
          propertyDataCache[b].fetchInitSuccessTime -
          propertyDataCache[a].fetchInitSuccessTime
      )[0];

      /* Use most recently viewed PDP property's location for search map if available */
      if (
        latestPropertyKey &&
        propertyDataCache[latestPropertyKey] &&
        propertyDataCache[latestPropertyKey].data?.geoLocation?.latitude &&
        propertyDataCache[latestPropertyKey].data?.geoLocation?.longitude
      ) {
        const { latitude, longitude } =
          propertyDataCache[latestPropertyKey].data.geoLocation;
        /* Getting bounds an arbitrary radius around the previously viewed PDP property */
        const boundsForPropertyList = getBoundsFromCenterPointAndRadius(
          [latitude, longitude],
          2000
        );
        yield put(
          searchSetMapLocationToLastViewedPDP(
            [latitude, longitude],
            boundsForPropertyList
          )
        );
      } else {
        yield put(reportEvent('search_newuser_nolocation'));
        yield put(searchGetUserLocationWhenNotAvailable());
      }
    } else {
      throw e;
    }
  }
}

export function* saveLastSearchSaga(action: { payload?: BoundsObject | null }) {
  const boundsFromAction =
    action?.payload?.southWest && action?.payload?.northEast
      ? action.payload
      : null;
  const filtersMapping = (yield select(
    getConsumerAPIFormattedSearchFilters
  )) as ReturnType<typeof getConsumerAPIFormattedSearchFilters>;
  const mapViewport = (yield select(getSearchViewport)) as ReturnType<
    typeof getSearchViewport
  >;

  /* For safety, don't attempt to save unless we have a map viewport in state */
  if (!mapViewport && !boundsFromAction) {
    return;
  }

  const { southWest, northEast } = (boundsFromAction || mapViewport)!;
  /* Ensure the viewport object is formed correctly */
  if (!southWest.lat || !southWest.lng) {
    throw new Error('Attempting to save last search with undefined bounds');
  }

  const geom = (yield select(getSearchPlaceGeoJSON)) as ReturnType<
    typeof getSearchPlaceGeoJSON
  >;
  const spatialId = (yield select(getSearchConstrainedPlace)) as ReturnType<
    typeof getSearchConstrainedPlace
  >;
  const shouldPassSpatialId = geom && geom.type !== 'Point';
  const isViewingSearchList = (yield select(
    getIsShowingSearchPageList
  )) as ReturnType<typeof getIsShowingSearchPageList>;
  const sortOrder = (yield select(getSearchListSortOrder)) as ReturnType<
    typeof getSearchListSortOrder
  >;
  const sortField = (yield select(getSearchListSortField)) as ReturnType<
    typeof getSearchListSortField
  >;

  const searchData = {
    ...(shouldPassSpatialId && spatialId?.placeId
      ? { place_id: spatialId.placeId }
      : {}),
    ...(shouldPassSpatialId
      ? {
          sw_lat: null,
          sw_lng: null,
          ne_lat: null,
          ne_lng: null,
        }
      : {
          sw_lat: southWest.lat,
          sw_lng: southWest.lng,
          ne_lat: northEast.lat,
          ne_lng: northEast.lng,
        }),
    ...filtersMapping,
    search_view: isViewingSearchList ? 'list' : 'map',
    sort_order: sortOrder,
    sort_field: sortField,
  };

  yield call([consumerApiClient, consumerApiClient.saveLastSearch], searchData);
}

function* delayedSaveLastSearchSaga(action) {
  /* Debounce to avoid updating overly often when moving map or updating filters quickly */
  yield delay(800);
  yield call(saveLastSearchSaga, action);
}

export default (sagaMiddleware) => {
  watchLatest(sagaMiddleware, {
    [SEARCH_LIST_APPLY_SORT]: delayedSaveLastSearchSaga,
    [SEARCH_LIST_HANDLE_OPEN_OR_CLOSE]: delayedSaveLastSearchSaga,
    [SEARCH_UPDATE_FILTER]: delayedSaveLastSearchSaga,
    [SEARCH_CLEAR_ALL_FILTERS]: delayedSaveLastSearchSaga,
    [SEARCH_MAP_MOVED_AFTER_INIT]: delayedSaveLastSearchSaga,
  });
};
