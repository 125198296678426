import React from 'react';
import { themr } from '@friendsofreactjs/react-css-themr';
import { dollarsFormatter } from '@client/utils/formatter.utils';

import defaultTheme from '@client/css-modules/AvmBreakdownAvmValueChase.css';
import { AvmBreakdownAvmValueProps } from '@client/store/types/cobranded-components/avm-breakdown-avm-value';

/**
 * A branding-specific display of the AVM value, for use inside the AVM breakdown
 */
const AvmBreakdownAvmValueChase: React.FunctionComponent<
  AvmBreakdownAvmValueProps
> = ({ theme, value }) => {
  return (
    <>
      <div className={theme.AvmLabel}>Home worth and why:</div>
      <div className={theme.AvmBreakdownAvmValue}>
        {dollarsFormatter(value)}
      </div>
    </>
  );
};

export default themr(
  'AvmBreakdownAvmValueChaseThemed',
  defaultTheme
)(AvmBreakdownAvmValueChase);
