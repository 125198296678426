import React, { Component } from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import defaultTheme from '@client/css-modules/AuthButton.css';
import AccountIcon from '@client/inline-svgs/account-icon';
import Tooltip from '@client/components/generic/Tooltip';
import MenuNotificationCount from '@client/components/MenuNotificationCount';
import FlatButton from '@client/components/generic/FlatButton';
import CobrandedDataComponent, {
  CobrandedDataComponentArgs,
} from '@client/components/CobrandedDataComponent';
import CobrandedStyles from '@client/components/CobrandedStyles';
import AccountDetailsMenu from '@client/components/AccountDetailsMenu';
import { View } from '@client/routes/constants';
import { ModalKey } from '@client/store/constants';

type Props = {
  isLoggedIn: boolean;
  handleRouteChange: (routeKey: View) => void;
  handleLogout: () => void;
  handleShowAuthModal: () => void;
  handleAccountAvatarClick: () => void;
  unreadAlertsCount: number;
  theme: Theme;
  disableUserProfileEditing?: boolean;
  handleOpenModal: (modalKey: ModalKey) => void;
};

type State = {
  /* This only serves to cause the tooltip to re-render on route change in order to hide it */
  currentRouteKey: string;
};

class AuthButton extends Component<Props, State> {
  state: State = {
    currentRouteKey: 'initial',
  };

  redirectToLoginPage = (): void => {
    this.props.handleShowAuthModal();
  };

  localHandleRouteChange = (routeKey: View): void => {
    this.setState({ currentRouteKey: routeKey });
    this.props.handleRouteChange(routeKey);
  };

  localHandleLogout = (): void => {
    this.setState({ currentRouteKey: 'logout' });
    this.props.handleLogout();
  };

  render() {
    const {
      isLoggedIn,
      theme,
      handleAccountAvatarClick,
      unreadAlertsCount,
      disableUserProfileEditing,
    } = this.props;
    const { currentRouteKey } = this.state;

    return (
      <CobrandedStyles>
        {({ authButtonHoverColor }) =>
          isLoggedIn ? (
            <nav className={theme.AuthDropdownContainer}>
              <style
                dangerouslySetInnerHTML={{
                  __html: `
                    .${theme.AuthDropdownContainer}:hover svg .element-to-fill  {
                      fill: ${authButtonHoverColor} !important
                    }
                  `,
                }}
              />
              <Tooltip
                dataHcName="account-menu-tooltip"
                key={currentRouteKey}
                clickToTrigger
                shouldHideCloseButton
                shouldCloseOnTooltipClick
                handleToggleTooltip={handleAccountAvatarClick}
                hasTransparentScreen
                triggerAriaLabel={'Open account menu'}
                marginFromEdge={{ x: 5, y: 5 }}
                trigger={
                  <div className={theme.AuthButtonContainer}>
                    <AccountIcon />
                    <CobrandedDataComponent>
                      {({
                        customizationData: {
                          is_showing_unread_alerts_count_on_account_icon,
                        },
                      }: CobrandedDataComponentArgs) => (
                        <>
                          {is_showing_unread_alerts_count_on_account_icon && (
                            <MenuNotificationCount
                              theme={theme}
                              count={unreadAlertsCount}
                            />
                          )}
                        </>
                      )}
                    </CobrandedDataComponent>
                  </div>
                }
                content={
                  <AccountDetailsMenu
                    handleCloseMenu={() => {
                      /* Tooltip handles it's own closing when esc is pressed */
                    }}
                    handleRouteChange={this.localHandleRouteChange}
                    handleLogout={this.localHandleLogout}
                    unreadAlertsCount={unreadAlertsCount}
                    disableUserProfileEditing={disableUserProfileEditing}
                    handleOpenModal={this.props.handleOpenModal}
                  />
                }
                theme={theme}
              />
            </nav>
          ) : (
            <nav>
              <FlatButton
                onClick={() => this.redirectToLoginPage()}
                theme={theme}
                label="Sign up"
                aria-label="Sign up"
              />
            </nav>
          )
        }
      </CobrandedStyles>
    );
  }
}

const ThemedAuthBtn = themr('AuthButton', defaultTheme)(AuthButton);
export default ThemedAuthBtn;
