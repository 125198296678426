import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { LngLatBounds } from 'mapbox-gl';
import { Component } from 'react';

import AvmFactorDeltaValueCobranded from '@client/components/AVMFactorDeltaValue/AVMFactorDeltaValueCobranded';
import AvmBreakdownAvmDefinitionCobranded from '@client/components/AvmBreakdownAvmDefinition/AvmBreakdownAvmDefinitionCobranded';
import AvmBreakdownAvmValueCobranded from '@client/components/AvmBreakdownAvmValue/AvmBreakdownAvmValueCobranded';
import AvmFactorTypeIcon from '@client/components/AvmFactorTypeIcon';
import CobrandedComponent from '@client/components/CobrandedComponent';
import PillButton from '@client/components/generic/PillButton';
import AvmDeepDiveContainer from '@client/containers/avm-deep-dive.container';
import theme from '@client/css-modules/AvmBreakdown.css';
import { LockedComponent } from '@client/hocs/locked-component';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { ICON_FOR_AVM_FACTOR_TYPE } from '@client/store/constants';
import {
  LOCKED_AVM_FACTOR_DESCRIPTION_PLACEHOLDER,
  LOCKED_AVM_FACTOR_MEDIAN_VALUE_PLACEHOLDER,
  LOCKED_AVM_FACTOR_VALUE_DELTA_PLACEHOLDER,
} from '@client/store/locked-components-constants';
import { AvmFactor, MedianValues } from '@client/store/types/avm-break-down';
import {
  SectionId,
  SpecialUserType,
} from '@client/store/types/locked-components';
import { LatitudeLongitudeObject } from '@client/store/types/maps';
import { RegressionData } from '@client/store/types/regression-data';
import {
  buildMedianValues,
  DEEP_DIVES_VIRTUAL_LOCATION_MAP_ZOOM,
  getHasAvmFactors,
  getShowDataDeepDives,
  VIRTUAL_MAP_DIMENSIONS,
} from '@client/utils/avm-data-deep-dives.utils';
import {
  getBoundsFromCenterPointAndZoom,
  getMapboxGLBoundsForBounds,
} from '@client/utils/maps.utils';

type Props = {
  avmFSD: number | null;
  isAppMounted: boolean;
  isAvmFactorsLoading: boolean;
  avmFactors: AvmFactor[] | null;
  avmValue: number | null;
  defaultDeepDivesFactorToLaunchWith: string | null;
  isLoadingRegressionsData: boolean;
  fetchAvmFactors: () => void;
  fetchPropertyAvmDeepDiveData: () => void;
  isAvmDeepDiveModalActive: boolean;
  handleShowAvmDeepDiveModal: () => void;
  handleHideAvmDeepDiveModal: () => void;
  handleGetMapLayerLegendBreaks: (bounds: LngLatBounds, zoom: number) => void;
  propertyLocation: LatitudeLongitudeObject | null;
  regressionsData: RegressionData[];
  medianValues: MedianValues | null;
  showLocationInDataDeepDives: boolean;
};

type State = {
  avmDeepDiveFactorActive: string | null;
};

class AvmBreakdown extends Component<Props, State> {
  state: State = {
    avmDeepDiveFactorActive: null,
  };

  componentDidMount() {
    const { isAppMounted } = this.props;

    /* Since multiple versions of this component mount on PDP SSR for multiple screen sizes (then some
     * unmount), ensure we're not sending init requests multiple times when SSRing the PDP by only
     * fetching init data after app mounts */
    if (isAppMounted) {
      this.fetchComponentData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { isAppMounted } = this.props;

    if (prevProps.isAvmFactorsLoading && !this.props.isAvmFactorsLoading) {
      this.props.fetchPropertyAvmDeepDiveData();
    }
    if (!prevProps.isAppMounted && isAppMounted) {
      this.fetchComponentData();
    }
  }

  fetchComponentData = () => {
    const {
      isAvmFactorsLoading,
      fetchAvmFactors,
      fetchPropertyAvmDeepDiveData,
      handleGetMapLayerLegendBreaks,
      propertyLocation,
    } = this.props;

    /* This should always be defined when the component mounts */
    if (propertyLocation) {
      const { latitude, longitude } = propertyLocation;
      const mapBounds = getBoundsFromCenterPointAndZoom(
        [latitude, longitude],
        DEEP_DIVES_VIRTUAL_LOCATION_MAP_ZOOM,
        VIRTUAL_MAP_DIMENSIONS
      );

      fetchAvmFactors();
      /**
       * Make sure medium block price heatmap data exists before we render location in
       * data deep dives, fetching heatmap legend breaks with approximate map size and zoom.
       */
      handleGetMapLayerLegendBreaks(
        getMapboxGLBoundsForBounds(mapBounds),
        DEEP_DIVES_VIRTUAL_LOCATION_MAP_ZOOM
      );
    } else {
      throw new Error('AvmBreakdown mounted without propertyLocation');
    }

    if (!isAvmFactorsLoading) {
      fetchPropertyAvmDeepDiveData();
    }
  };

  handleOpenAvmDeepDive = (factorType: string | null) => {
    const {
      avmFactors,
      avmValue,
      isLoadingRegressionsData,
      regressionsData,
      showLocationInDataDeepDives,
      handleShowAvmDeepDiveModal,
    } = this.props;
    const hasAvmFactors = getHasAvmFactors(avmValue, avmFactors);

    if (
      getShowDataDeepDives(
        hasAvmFactors,
        isLoadingRegressionsData,
        regressionsData,
        showLocationInDataDeepDives
      )
    ) {
      this.setState({ avmDeepDiveFactorActive: factorType });
      handleShowAvmDeepDiveModal();
    }
  };

  render() {
    const {
      avmFactors,
      avmFSD,
      avmValue,
      defaultDeepDivesFactorToLaunchWith,
      handleHideAvmDeepDiveModal,
      isAvmFactorsLoading,
      isAvmDeepDiveModalActive,
      isLoadingRegressionsData,
      medianValues,
      regressionsData,
      showLocationInDataDeepDives,
    } = this.props;

    const hasAvmFactors = getHasAvmFactors(avmValue, avmFactors);
    const showDataDeepDives = getShowDataDeepDives(
      hasAvmFactors,
      isLoadingRegressionsData,
      regressionsData,
      showLocationInDataDeepDives
    );
    const medianValuesComponents = medianValues
      ? buildMedianValues(medianValues)
      : [];

    return (
      <CobrandedComponent>
        {({
          styles: {
            avmBreakdownBackground,
            avmBreakdownBorderRadius,
            avmBreakdownBoxShadow,
            saveSearchDesktopButtonFontWeight,
          },
          utils: { getShouldDisplayAVMForFSD, getFormattedCTAText },
        }) => {
          const isShowingAVMForFSD =
            avmFSD && getShouldDisplayAVMForFSD(avmFSD);

          return !isAvmFactorsLoading && isShowingAVMForFSD ? (
            <>
              <div
                className={theme.SectionHeading}
                role="heading"
                aria-level={3}
                data-hc-name={'avm-header'}
              >
                More Information On This Home
              </div>
              <div
                className={classNames(theme.AvmBreakdownContainer, {
                  [theme.NoAvmFactors]:
                    !hasAvmFactors || isEmpty(medianValuesComponents),
                })}
                data-hc-name={'avm-breakdown'}
                style={{
                  borderRadius: avmBreakdownBorderRadius,
                  boxShadow: avmBreakdownBoxShadow,
                }}
              >
                {avmValue ? (
                  <div
                    style={{
                      background: avmBreakdownBackground,
                      borderBottomLeftRadius: avmBreakdownBorderRadius,
                      borderTopLeftRadius: avmBreakdownBorderRadius,
                    }}
                    className={theme.AvmValueSection}
                  >
                    <div
                      className={theme.AvmValueWrapper}
                      onClick={() =>
                        this.handleOpenAvmDeepDive(
                          defaultDeepDivesFactorToLaunchWith
                        )
                      }
                    >
                      <AvmBreakdownAvmValueCobranded
                        value={avmValue}
                        theme={theme}
                      />
                    </div>
                    <AvmBreakdownAvmDefinitionCobranded
                      noAvmFactors={
                        !hasAvmFactors || isEmpty(medianValuesComponents)
                      }
                      showDataDeepDives={showDataDeepDives}
                    />
                  </div>
                ) : (
                  <div
                    className={theme.NoAvmSection}
                    style={{ background: avmBreakdownBackground }}
                  >
                    We don't have enough information to calculate a value.
                  </div>
                )}
                {
                  <LockedComponent
                    sectionId={SectionId.AvmFactors}
                    lockedFor={[SpecialUserType.Restricted]}
                    theme={theme}
                    className={theme.LockedComponentContainer}
                    lockMessage="to see more information on this home"
                  >
                    {({ isLocked }) => (
                      <div
                        className={classNames(theme.AvmFactorsSection, {
                          [theme.HideSeeMoreAndCenterContent]:
                            !showDataDeepDives,
                          [theme.disabled]: isLocked,
                        })}
                        data-hc-name={'avm-factors'}
                      >
                        <>
                          {medianValuesComponents.length > 0 && (
                            <div
                              className={theme.AvmFactor}
                              key="median-values"
                            >
                              <div
                                onClick={() =>
                                  this.handleOpenAvmDeepDive(
                                    defaultDeepDivesFactorToLaunchWith
                                  )
                                }
                              >
                                {isLocked
                                  ? LOCKED_AVM_FACTOR_MEDIAN_VALUE_PLACEHOLDER
                                  : medianValuesComponents}
                              </div>
                            </div>
                          )}
                          {avmFactors && hasAvmFactors && (
                            <>
                              {avmFactors.map(
                                (factor) =>
                                  /* Ensure that we have an icon for the factor type before displaying */
                                  ICON_FOR_AVM_FACTOR_TYPE.includes(
                                    factor.type
                                  ) && (
                                    <div
                                      className={theme.AvmFactor}
                                      key={factor.type}
                                      onClick={() =>
                                        this.handleOpenAvmDeepDive(factor.label)
                                      }
                                    >
                                      {/**
                                       * Only Icons used for chase cobrand have classnames
                                       * AvmFactorIconPositive & AvmFactorIconNegative on them.
                                       * On all other cobrands these classnames won't be effective
                                       */}
                                      <div
                                        className={classNames(
                                          theme.AvmFactorIcon,
                                          {
                                            [theme.AvmFactorIconPositive]:
                                              factor.value >= 0,
                                            [theme.AvmFactorIconNegative]:
                                              factor.value < 0,
                                          }
                                        )}
                                      >
                                        <div
                                          className={theme.AvmFactorIconLabel}
                                        >
                                          {factor.label}
                                        </div>
                                        <AvmFactorTypeIcon
                                          type={factor.type}
                                          className={theme.icon}
                                          aria-hidden
                                        />
                                      </div>
                                      <div>
                                        <div
                                          className={theme.AvmFactorDescription}
                                        >
                                          {isLocked
                                            ? LOCKED_AVM_FACTOR_DESCRIPTION_PLACEHOLDER
                                            : factor.description}
                                        </div>
                                        {isLocked ? (
                                          <>
                                            {
                                              LOCKED_AVM_FACTOR_VALUE_DELTA_PLACEHOLDER
                                            }
                                          </>
                                        ) : (
                                          <AvmFactorDeltaValueCobranded
                                            value={factor.value}
                                            theme={theme}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )
                              )}
                            </>
                          )}
                          {isEmpty(medianValuesComponents) &&
                            !hasAvmFactors && (
                              <div className={theme.DefaultAvmValueExplanation}>
                                This is ComeHome’s estimated market value for
                                this home. It is not a formal appraisal. This
                                estimate is based on our market knowledge, and
                                it should be used as a starting point to
                                determine a home’s value.
                              </div>
                            )}
                          {showDataDeepDives && (
                            <div className={theme.DefaultCTAContainer}>
                              <PillButton
                                data-event-name={'click_pdp_data_deep_dive'}
                                data-parent-event-name={
                                  PARENT_EVENTS.CLICK_PDP_MODULES
                                }
                                ariaLabel="See more"
                                className={theme.SeeMoreCTA}
                                onClick={() =>
                                  this.handleOpenAvmDeepDive(
                                    defaultDeepDivesFactorToLaunchWith
                                  )
                                }
                                style={{
                                  fontWeight: saveSearchDesktopButtonFontWeight,
                                }}
                              >
                                {getFormattedCTAText('See More')}
                              </PillButton>
                            </div>
                          )}
                        </>
                      </div>
                    )}
                  </LockedComponent>
                }
              </div>
              <AvmDeepDiveContainer
                isActive={isAvmDeepDiveModalActive}
                avmDeepDiveFactorActive={this.state.avmDeepDiveFactorActive}
                handleClose={handleHideAvmDeepDiveModal}
              />
            </>
          ) : null;
        }}
      </CobrandedComponent>
    );
  }
}

export default AvmBreakdown;
