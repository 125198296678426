import { ReduxState } from '@client/store/types/redux-state';

/** Whether the app is being rendered via the server or the client. We'll only
 * render certain child components when rendered from the server. */
export function getIsApplicationMounted(state: ReduxState) {
  return state.serverRenderingInfo.applicationDidMount;
}

/* This is set on a per-page basis in the /server/pages page handlers to control whether the
 * React components for the page should SSR */
export function getShouldServerSideRender(state: ReduxState) {
  return state.serverRenderingInfo.shouldServerSideRender;
}
