import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LOContactInfo from '@client/components/LOContactInfo';
import GenericLOInfo from '@client/components/GenericLOInfo';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import { openModal } from '@client/store/actions/modals.actions';
import theme from '@client/css-modules/SearchLoanOfficerAd.css';
import ContactLOButton from '@client/components/ContactLOButton';
import { getCustomizationData } from '@client/store/selectors/cobranding.selectors';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { useLoInfoProps } from '@client/hooks/lo-info-provider.hooks';

const SearchLoanOfficerAd: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const isUsingMobileLayout = useSelector(getIsSmallSize);
  const { external_url_for_cta_btn_on_lo_card_in_srp } =
    useSelector(getCustomizationData);
  const { pillButtonPrimaryBackgroundColor } = useCobrandStyles();
  const {
    lastMessageDate,
    loConfig,
    loData,
    genericLoData,
    showLOCard,
    getLOCardIntroText,
  } = useLoInfoProps();

  if (
    !showLOCard ||
    (!loData && !genericLoData && !external_url_for_cta_btn_on_lo_card_in_srp)
  ) {
    return null;
  } else {
    /* loConfig is undefined for ComeHome, chase cobrands */
    const email = loData ? loData.email : genericLoData?.email;
    const name = loData ? loData.fullName : genericLoData?.name;
    const hbSrp = loConfig?.hbSrp;
    const contactButtonText = loData?.firstName ?? loData?.fullName ?? 'Us';
    const headline = hbSrp?.headline ?? 'Finance your next home purchase.';
    const introText = getLOCardIntroText(
      hbSrp?.enableIntroText,
      hbSrp?.introText,
      hbSrp?.introAltText
    );
    const buttonFill = hbSrp?.buttonColor ?? pillButtonPrimaryBackgroundColor;
    /* Please note that on the search page, the Loan officer ad does not have a cobranded background color */
    return (
      <div className={theme.SearchLoanOfficerAd}>
        <div className={theme.CanHelpText}>{introText}</div>
        <div className={theme.FinanceText}>{headline}</div>
        <ContactLOButton
          theme={theme}
          style={{ background: buttonFill }}
          buttonText={contactButtonText}
          buttonCTAUrl={external_url_for_cta_btn_on_lo_card_in_srp}
          data-event-name={
            external_url_for_cta_btn_on_lo_card_in_srp
              ? 'click_url_cta_srp'
              : 'click_buyer-lo-contact'
          }
          data-parent-event-name={
            external_url_for_cta_btn_on_lo_card_in_srp
              ? 'click_url_cta'
              : 'click_lo_contact'
          }
          handleClickWithoutExternalCtaLink={() => {
            dispatch(openModal('contact-loan-officer'));
          }}
        />
        {loData ? (
          <LOContactInfo
            theme={theme}
            hidePhoneAndEmail={isUsingMobileLayout}
          />
        ) : (
          <GenericLOInfo
            theme={theme}
            hidePhoneAndEmail={isUsingMobileLayout}
          />
        )}
        {isUsingMobileLayout && email && (
          <a
            href={`mailto:${email}`}
            title={`Send an email to ${name}`}
            className={theme.LOEmailLink}
          >
            {email}
          </a>
        )}
        {lastMessageDate && (
          <div className={theme.LastMessageDate}>
            Last message sent on {lastMessageDate}
          </div>
        )}
      </div>
    );
  }
};

export default SearchLoanOfficerAd;
