import { get, merge } from 'lodash';
import windowOrGlobal from 'window-or-global';

import { View } from '@client/routes/constants';
import { Action } from '@client/store/actions';
import { RESET_AUTH_DEPENDENT_STATE } from '@client/store/actions/auth.actions';
import {
  CHANGE_CRIME_TYPE,
  CHANGE_FORECAST_CHART_TYPE,
  CHANGE_NEIGHBORHOOD_INSIGHTS_CHART_TYPE,
  CHANGE_SCHOOL_TYPE,
  FETCH_FULL_PROPERTY_DETAILS,
  FETCH_FULL_PROPERTY_DETAILS_SUCCESS,
  FETCH_INIT_PROPERTY_DETAILS,
  FETCH_INIT_PROPERTY_DETAILS_ERROR,
  FETCH_INIT_PROPERTY_DETAILS_SUCCESS,
  FETCH_MORTGAGE_AND_TERMS,
  FETCH_MORTGAGE_AND_TERMS_ERROR,
  FETCH_MORTGAGE_AND_TERMS_SUCCESS,
  FETCH_MORTGAGE_SUMMARY_SUCCESS,
  FETCH_PROPERTY_AVM_DEEP_DIVE_DATA_SUCCESS,
  FETCH_PROPERTY_AVM_FACTORS_SUCCESS,
  PROPERTY_PAGE_HAS_TOGGLED_HEATMAPS,
  SHOW_MORE_SALES,
} from '@client/store/actions/property-details.actions';
import {
  SEARCH_ADD_PROPERTY_LIST_PROPERTY_TO_PROPERTY_DATA_CACHE,
  SEARCH_SET_MULTI_UNIT_MARKER_DATA,
  SEARCH_SET_PDP_MODAL_MARKER_DATA_AND_SHOW_MODAL,
} from '@client/store/actions/search.actions';
import {
  ADD_TO_WATCHLIST,
  FETCH_IS_PROPERTY_IN_WATCHLIST_SUCCESS,
  REMOVE_FROM_WATCHLIST,
} from '@client/store/actions/watchlist.actions';
import {
  CRIME_TYPES,
  FORECAST_CHART_TYPES,
  NEIGHBORHOOD_INSIGHT_TYPES,
  SCHOOL_TYPES,
  STATUSES,
} from '@client/store/constants';
import { Mls, MlsRegulations } from '@client/store/sagas/queries/types';
import { AvmFactor, MedianValues } from '@client/store/types/avm-break-down';
import { MultiUnitDataObject } from '@client/store/types/maps';
import {
  FullPropertyLookupWithAddressRequired,
  InitPropertyLookupWithAddressRequired,
  MortgageCalculationsForUIComponents,
  MortgageTerm,
  NormalizedProperty,
} from '@client/store/types/property';
import { Status } from '@client/store/types/statuses';
import { getIsInView } from '@client/utils/routing.utils';

const pathname = get(windowOrGlobal, ['location', 'pathname'], '');
/* Needed only for hot-reloading */
const initialAddressSlug = getIsInView(View.PROPERTY_DETAILS)
  ? pathname && pathname.match && pathname.match(/\/[a-z-]+\/([\w-]+)/)[1]
  : null;
const PROPERTY_DATA_CACHE_MAX_SIZE = 20;

export type PropertyDataCacheItem = {
  /* The "init" request contains a subset of the "full" details - values needed for SSRing and
   * displaying PDP content above the fold */
  initDetailsStatus: Status;
  /* The "full" request contains all details including the "init" details */
  fullDetailsStatus: Status;
  avmStatus: Status;
  avmDataDeepDiveStatus: Status;
  avmDeepDiveData: {
    regressionsData: any;
    tractStatsCount: any;
  } | null;
  mortgageCalculationStatus: Status;
  fetchInitSuccessTime: number;
  /* At any time, the `data` for a property can be either the result of the "init" OR the "full"
   * property details GQL requests since these requests can technically be fulfilled in any order.
   * After the "full" request completes, its data overwrites the "init" data. */
  data:
    | InitPropertyLookupWithAddressRequired
    | FullPropertyLookupWithAddressRequired
    | null;
  isInWatchList: boolean;
  shouldDisplayMoreSales: boolean;
  avmFactors: AvmFactor[] | null;
  medianValues: MedianValues | null;
  mortgageSummary: string | null;
  mortgageCalculations: MortgageCalculationsForUIComponents;
};

export type PropertyDataCache = {
  [key: string]: PropertyDataCacheItem | undefined;
};

export type PropertyDetailsState = {
  selectedCrime: (typeof CRIME_TYPES)[keyof typeof CRIME_TYPES];
  selectedSchoolType: (typeof SCHOOL_TYPES)[keyof typeof SCHOOL_TYPES];
  selectedForecastChartType: (typeof FORECAST_CHART_TYPES)[keyof typeof FORECAST_CHART_TYPES];
  selectedNeighborhoodInsightsChartType: (typeof NEIGHBORHOOD_INSIGHT_TYPES)[keyof typeof NEIGHBORHOOD_INSIGHT_TYPES];
  shouldShowHeatmapsWhenTogglingMapView: boolean;
  /* loanTerms are same for all the properties in the cache, used only on PDP */
  loanTerms: MortgageTerm[];
  /* A cache of property data objects keyed by address slug used to persist data for a quicker display
   * of the PDP.  Each cache entry's `data` property is overwritten upon loading a full PDP. */
  propertyDataCache: PropertyDataCache;
};

/* State variables unique to each PDP */
export const PROPERTY_DATA_CACHE_ITEM_INITIAL_STATE: PropertyDataCacheItem = {
  initDetailsStatus: STATUSES.INIT,
  fullDetailsStatus: STATUSES.INIT,
  avmStatus: STATUSES.INIT,
  avmDataDeepDiveStatus: STATUSES.INIT,
  avmDeepDiveData: null,
  fetchInitSuccessTime: 0,
  mortgageCalculationStatus: STATUSES.INIT,
  /* Raw data returned from property-graph */
  data: null,
  isInWatchList: false,
  shouldDisplayMoreSales: false,
  avmFactors: null,
  medianValues: null,
  /* mortgage calculation summary is fetched on node
  server for SEO - breakout sections overview */
  mortgageSummary: null,
  /**
   * Default state to avoid uncontrolled input to controlled
   * input warning in React
   */
  mortgageCalculations: {
    mortgageId: null,
    interestRate: null,
    downPayment: null,
    downPaymentPct: null,
    homePrice: null,
    monthlyPayment: null,
    insurance: null,
  },
};

/* State variables that should persist between different PDPs */
export const PROPERTY_DETAILS_INITIAL_STATE: PropertyDetailsState = {
  selectedCrime: CRIME_TYPES.ALL,
  selectedSchoolType: SCHOOL_TYPES.ELEMENTARY,
  selectedForecastChartType: FORECAST_CHART_TYPES.BLOCK,
  selectedNeighborhoodInsightsChartType:
    NEIGHBORHOOD_INSIGHT_TYPES.BUILDINGAREA,
  /* Set to true initially, then update whenever heatmaps are enabled/disabled to remember selection */
  shouldShowHeatmapsWhenTogglingMapView: true,
  /* loanTerms are same for all the properties in the cache, used only on PDP */
  loanTerms: [],
  /* A cache of property data objects keyed by address slug to facilitate persisting some data when
   * toggling between property cards on the mobile maps page. Currently overwritten upon loading a full PDP. */
  propertyDataCache: {
    /* Set server-side in property-details.js.  Also need here for hot-reloading. */
    ...(initialAddressSlug
      ? { [initialAddressSlug]: PROPERTY_DATA_CACHE_ITEM_INITIAL_STATE }
      : {}),
  },
};

/* Needed to match the generated property-graph types */
export const INITIAL_MLS_STATE: Mls = {
  abbreviation: null,
  fips: null,
  lastRefreshed: null,
  mlsID: null,
  name: null,
  regulations: null,
};

export const INITIAL_MLS_REGULATIONS_STATE: MlsRegulations = {
  active: null,
  augmenting: null,
  cancelled: null,
  closed: null,
  closedLoginPrice: null,
  comingSoon: null,
  comingling: null,
  contingent: null,
  contingentWithKickOut: null,
  cooperatingBrokerage: null,
  copyrightStatement: null,
  deleted: null,
  disclaimer: null,
  expired: null,
  leased: null,
  logo: null,
  logoOverlay: null,
  pending: null,
  photosClosedAll: null,
  photosClosedFirstOnly: null,
  photosClosedHistory: null,
  photosClosedLogin: null,
  photosLogoOverlay: null,
  sold: null,
  unknown: null,
  withdrawn: null,
};

/**
 * Transform data from a map marker (in spatial-search GQL object format) to the "init" property
 * details response format expected by selectors consuming PDP data, to be set in the `propertyDataCache`
 * so that the PDP displays immediately with some data after clicking a search property.
 * Note that we're merging the `propertyDataCache[slug]` entry for the property to maintain some of the
 * status props, but we're overwriting `propertyDataCache[slug].data`.
 * Also note that after this method's returned object is set on state, `initDetailsStatus` is still
 * `INIT`, causing PropertyPage.tsx to fetch the full init data for the property when it mounts.
 */
export const getNewPropertyDataStateFromMarkerData = (
  markerData: {
    addressSlug: string;
    normalizedPropertyData: NormalizedProperty | string;
  },
  propertyDataCache: {
    [key: string]: PropertyDataCacheItem | undefined;
  }
): Omit<PropertyDataCacheItem, 'data'> & {
  data: InitPropertyLookupWithAddressRequired;
} => {
  const normalizedPropertyDataJSON = markerData.normalizedPropertyData;
  const normalizedPropertyData =
    typeof normalizedPropertyDataJSON === 'string'
      ? (JSON.parse(normalizedPropertyDataJSON) as NormalizedProperty)
      : normalizedPropertyDataJSON;
  const existingDataCacheObject = propertyDataCache[markerData.addressSlug];
  const address = {
    streetAddress: normalizedPropertyData.streetAddress,
    fullAddress: normalizedPropertyData.fullAddress,
    slug: normalizedPropertyData.slug,
    city: normalizedPropertyData.city,
    state: normalizedPropertyData.state,
    zipcode: normalizedPropertyData.zipcode,
    unit: normalizedPropertyData.unit,
    hcAddressId: null,
    zipcodePlus4: null,
    id: null,
    msaId: null,
  };

  return {
    ...(existingDataCacheObject
      ? existingDataCacheObject
      : PROPERTY_DATA_CACHE_ITEM_INITIAL_STATE),
    data: {
      address,
      association: null,
      avm: {
        priceMean: normalizedPropertyData.avm,
        fsd: null,
      },
      /* We cache photos for properties in the `propertyPhotoDataCache` state object */
      bestPhotos: null,
      block: {
        crime: null,
        valueTsForecastSummary: null,
        histograms: null,
        tractId: normalizedPropertyData.tractId || null,
      },
      comps: null,
      county: {
        name: normalizedPropertyData.county || null,
      },
      geoLocation: {
        latitude: normalizedPropertyData.latitude,
        longitude: normalizedPropertyData.longitude,
      },
      hcBuildingId: null,
      latestAssessment: null,
      latestListing: {
        agentEmail: null,
        agentLicense: null,
        agentName: null,
        agentPhone: null,
        listingID: null,
        buyerBrokerageCompensationDisplay: null,
        listingOfficeName: normalizedPropertyData.listingOfficeName || null,
        openHouse: null,
        price: null,
        publicRemarks: null,
        status: null,
        statusDate: null,
      },
      listDate: normalizedPropertyData.dateOfListing
        ? `${normalizedPropertyData.dateOfListing}`
        : null,
      listPrice: normalizedPropertyData.listPrice,
      livingSpace: {
        numberOfRooms: null,
        livingArea: parseInt(`${normalizedPropertyData.sqFt}`, 10),
        bedrooms: {
          count: normalizedPropertyData.beds,
        },
        bathrooms: {
          summaryCount: normalizedPropertyData.baths,
        },
      },
      mlsState:
        normalizedPropertyData.status === 'NOT_LISTED'
          ? 'UNKNOWN'
          : normalizedPropertyData.status,
      mls: {
        mlsID: normalizedPropertyData.hcMlsId,
        abbreviation: null,
        lastRefreshed: null,
        name: null,
        regulations: null,
      },
      paymentEstimate: {
        total: normalizedPropertyData.monthlyPaymentEstimate || null,
      },
      propertyType: normalizedPropertyData.propertyType,
      rentalAvm: null,
      schools: null,
      schoolsSummary: null,
      site: null,
      structure: null,
      transfers: null,
      zip: null,
    },
  };
};

/**
 * Returns an existing `propertyDataCache` item if it exists or returns the "init" `propertyDataCache`
 * item state if not
 */
const getPropertyCacheItemOrInitForSlug = (
  slug: string,
  state: PropertyDetailsState
): PropertyDataCacheItem => {
  const item = state.propertyDataCache[slug];
  return item || PROPERTY_DATA_CACHE_ITEM_INITIAL_STATE;
};

/**
 * Convert the cache to an array, sort by time fetched, slice to the max cache size, convert back to an object
 */
const ensureBelowCacheLimit = (
  cache: PropertyDataCache,
  maxSize: number
): PropertyDataCache => {
  const keys = Object.keys(cache);
  if (keys.length <= maxSize) {
    return cache;
  } else {
    return keys
      .map((slug) => cache[slug]) // Convert to array
      .sort((a, b) =>
        a && b ? b.fetchInitSuccessTime - a.fetchInitSuccessTime : 0
      ) // Smallest number last
      .slice(0, maxSize) // Limit to max size
      .reduce((mem, curr) => {
        // Back to object
        if (curr?.data?.address.slug) {
          mem[curr.data.address.slug] = curr;
        }
        return mem;
      }, {});
  }
};

export default function propertyDetailsReducer(
  state = PROPERTY_DETAILS_INITIAL_STATE,
  action: Action
): PropertyDetailsState {
  switch (action.type) {
    case FETCH_INIT_PROPERTY_DETAILS:
      /* TS needs these set to a single variable for undefined checking */
      const __currentPropertyCacheItem1 =
        state.propertyDataCache[action.payload.slug];
      return {
        ...state,
        propertyDataCache: {
          /* Ensure the existing properties are at or below the cache limit by removing the earliest
           * fetched properties that are over the limit */
          ...ensureBelowCacheLimit(
            state.propertyDataCache,
            PROPERTY_DATA_CACHE_MAX_SIZE
          ),
          [action.payload.slug]: {
            /* If we already have data in the cache (coming from search page), don't overwrite.
             * Otherwise, initialize it */
            ...getPropertyCacheItemOrInitForSlug(action.payload.slug, state),
            /* If we already have data in the cache, don't overwrite SUCCESS status so that PDP
             * displays instantly otherwise set to LOADING */
            initDetailsStatus: __currentPropertyCacheItem1
              ? __currentPropertyCacheItem1.initDetailsStatus
              : STATUSES.LOADING,
          },
        },
      };
    case FETCH_INIT_PROPERTY_DETAILS_SUCCESS:
      return {
        ...state,
        propertyDataCache: {
          ...state.propertyDataCache,
          [action.payload.slug]: {
            ...getPropertyCacheItemOrInitForSlug(action.payload.slug, state),
            initDetailsStatus: STATUSES.SUCCESS,
            fetchInitSuccessTime: Date.now(),
            /* Merge here since the "full" request could return before this "init" request, as both
             * are sent at the same time when loading a PDP client-side */
            data: merge(
              {},
              getPropertyCacheItemOrInitForSlug(action.payload.slug, state)
                .data,
              action.payload.data
            ),
          },
        },
      };
    case FETCH_INIT_PROPERTY_DETAILS_ERROR:
      return {
        ...state,
        propertyDataCache: {
          ...state.propertyDataCache,
          [action.payload.slug]: {
            ...PROPERTY_DATA_CACHE_ITEM_INITIAL_STATE,
            initDetailsStatus: STATUSES.FAILED,
          },
        },
      };
    case ADD_TO_WATCHLIST:
      return {
        ...state,
        propertyDataCache: {
          ...state.propertyDataCache,
          [action.payload.slug]: {
            ...getPropertyCacheItemOrInitForSlug(action.payload.slug, state),
            isInWatchList: true,
          },
        },
      };
    case FETCH_IS_PROPERTY_IN_WATCHLIST_SUCCESS:
      /* TS needs these set to a single variable for undefined checking */
      const __currentPropertyCacheItem2 =
        state.propertyDataCache[action.payload.slug];
      return {
        ...state,
        propertyDataCache: {
          ...state.propertyDataCache,
          [action.payload.slug]: {
            ...getPropertyCacheItemOrInitForSlug(action.payload.slug, state),
            /* Don't allow this response to change this state variable from `true` to `false`.
             * This is needed due to the unpredictable order of API response resolution
             * following adding to watchlist immediately after signing in/up.  */
            isInWatchList:
              __currentPropertyCacheItem2 &&
              __currentPropertyCacheItem2.isInWatchList === true &&
              action.payload.isInWatchList === false
                ? __currentPropertyCacheItem2.isInWatchList
                : action.payload.isInWatchList,
          },
        },
      };
    case REMOVE_FROM_WATCHLIST:
      return {
        ...state,
        propertyDataCache: {
          ...state.propertyDataCache,
          [action.payload.slug]: {
            ...getPropertyCacheItemOrInitForSlug(action.payload.slug, state),
            isInWatchList: false,
          },
        },
      };
    case RESET_AUTH_DEPENDENT_STATE:
      return {
        ...state,
        propertyDataCache: {
          /* just trying to wipe out isInWatchList */
          ...Object.keys(state.propertyDataCache)
            .map((slug) => ({
              slug,
              ...state.propertyDataCache[slug],
              isInWatchList: false,
            }))
            .reduce((mem, curr) => {
              const slug = curr.data?.address.slug;
              if (slug) {
                mem[slug] = curr;
              }
              return mem;
            }, {}),
        },
      };
    case FETCH_FULL_PROPERTY_DETAILS:
      return {
        ...state,
        propertyDataCache: {
          ...state.propertyDataCache,
          [action.payload.slug]: {
            ...getPropertyCacheItemOrInitForSlug(action.payload.slug, state),
            fullDetailsStatus: STATUSES.LOADING,
          },
        },
      };
    case FETCH_FULL_PROPERTY_DETAILS_SUCCESS:
      return {
        ...state,
        propertyDataCache: {
          ...state.propertyDataCache,
          [action.payload.slug]: {
            ...getPropertyCacheItemOrInitForSlug(action.payload.slug, state),
            fullDetailsStatus: STATUSES.SUCCESS,
            /* Can overwrite the data since the "full" request contains all properties from the "init".
             * If this no longer becomes true, TypeScript should error here. */
            data: action.payload.data,
          },
        },
      };
    case CHANGE_NEIGHBORHOOD_INSIGHTS_CHART_TYPE:
      return {
        ...state,
        selectedNeighborhoodInsightsChartType:
          NEIGHBORHOOD_INSIGHT_TYPES[action.payload.value],
      };
    case CHANGE_FORECAST_CHART_TYPE:
      return {
        ...state,
        selectedForecastChartType: FORECAST_CHART_TYPES[action.payload.value],
      };
    case CHANGE_CRIME_TYPE: {
      return {
        ...state,
        selectedCrime: action.payload.type,
      };
    }
    case CHANGE_SCHOOL_TYPE:
      return {
        ...state,
        selectedSchoolType: action.payload.schoolsType,
      };
    case SHOW_MORE_SALES:
      return {
        ...state,
        propertyDataCache: {
          ...state.propertyDataCache,
          [action.payload.slug]: {
            ...getPropertyCacheItemOrInitForSlug(action.payload.slug, state),
            shouldDisplayMoreSales: true,
          },
        },
      };
    case FETCH_PROPERTY_AVM_FACTORS_SUCCESS:
      let medianValues = {} as MedianValues;
      return {
        ...state,
        propertyDataCache: {
          ...state.propertyDataCache,
          [action.payload.slug]: {
            ...getPropertyCacheItemOrInitForSlug(action.payload.slug, state),
            avmFactors:
              action.payload.avmFactors &&
              action.payload.avmFactors.map(
                ({
                  __typename,
                  displayName,
                  comparisonDescription,
                  accessibleComparisonDescription,
                  value,
                  ...rest
                }) => {
                  medianValues = {
                    ...medianValues,
                    ...rest,
                  };
                  return {
                    type: __typename,
                    label: displayName,
                    description: comparisonDescription,
                    value: value,
                    ...(accessibleComparisonDescription && {
                      accessibleDescription: accessibleComparisonDescription,
                    }),
                  };
                }
              ),
            // TODO re-implement how this is calculated
            medianValues: medianValues,
            avmStatus: STATUSES.SUCCESS,
          },
        },
      };
    case FETCH_PROPERTY_AVM_DEEP_DIVE_DATA_SUCCESS:
      return {
        ...state,
        propertyDataCache: {
          ...state.propertyDataCache,
          [action.payload.slug]: {
            ...getPropertyCacheItemOrInitForSlug(action.payload.slug, state),
            avmDeepDiveData: {
              regressionsData:
                action.payload.regressionsData &&
                action.payload.regressionsData.reduce(
                  (accum, { displayName, __typename, ...rest }) => {
                    const regressionDataByType =
                      action.payload.avmFactorsWithLocation &&
                      action.payload.avmFactorsWithLocation.find(
                        (item) => item.label === displayName
                      );
                    if (displayName === 'Location') {
                      return accum.concat([
                        {
                          ...regressionDataByType,
                          ...rest,
                          type: __typename,
                          defaultDescription:
                            'Use the map below to explore pricing trends in the area.',
                          label: 'Location',
                        },
                      ]);
                    } else if (!regressionDataByType) {
                      return accum;
                    } else {
                      return accum.concat([
                        {
                          ...regressionDataByType,
                          ...rest,
                          type: __typename,
                        },
                      ]);
                    }
                  },
                  []
                ),
              tractStatsCount: action.payload.tractStatsCount,
            },
            avmDataDeepDiveStatus: STATUSES.SUCCESS,
          },
        },
      };
    /* Upon clicking a map marker, transfer data stored in the marker to the format expected by
     * `property-details` selectors, to render in a property-card. This overwrites the propertyDataCache
     * entry */
    case SEARCH_SET_PDP_MODAL_MARKER_DATA_AND_SHOW_MODAL:
      return {
        ...state,
        propertyDataCache: {
          ...state.propertyDataCache,
          [action.payload.markerFeatureProps.addressSlug]:
            getNewPropertyDataStateFromMarkerData(
              action.payload.markerFeatureProps,
              state.propertyDataCache
            ),
        },
      };
    /* Upon clicking a multi-unit map marker, transfer data stored in the markers to the format expected
     * by `property-details` selectors, to render in a property-card. This overwrites the propertyDataCache
     * entries */
    case SEARCH_SET_MULTI_UNIT_MARKER_DATA:
      if (action.payload.properties) {
        return {
          ...state,
          propertyDataCache: {
            ...state.propertyDataCache,
            ...action.payload.properties
              .filter(
                (property) =>
                  !(property as NonNullable<MultiUnitDataObject>)
                    .shouldNotBeSetOnState
              )
              .map((property) =>
                getNewPropertyDataStateFromMarkerData(
                  property,
                  state.propertyDataCache
                )
              )
              .reduce((mem, property) => {
                const addressSlug = property.data.address.slug;
                mem[addressSlug] = property;
                return mem;
              }, {}),
          },
        };
      } else {
        return state;
      }
    case SEARCH_ADD_PROPERTY_LIST_PROPERTY_TO_PROPERTY_DATA_CACHE:
      return {
        ...state,
        propertyDataCache: {
          ...state.propertyDataCache,
          [action.payload.addressSlug]: getNewPropertyDataStateFromMarkerData(
            {
              addressSlug: action.payload.addressSlug,
              normalizedPropertyData: action.payload.normalizedPropertyData,
            },
            state.propertyDataCache
          ),
        },
      };
    case FETCH_MORTGAGE_SUMMARY_SUCCESS:
      return {
        ...state,
        propertyDataCache: {
          ...state.propertyDataCache,
          [action.payload.activePropertySlug]: {
            ...getPropertyCacheItemOrInitForSlug(
              action.payload.activePropertySlug,
              state
            ),
            mortgageSummary: action.payload.summary,
          },
        },
      };
    case FETCH_MORTGAGE_AND_TERMS:
      return {
        ...state,
        propertyDataCache: {
          ...state.propertyDataCache,
          [action.payload.activePropertySlug]: {
            ...getPropertyCacheItemOrInitForSlug(
              action.payload.activePropertySlug,
              state
            ),
            mortgageCalculationStatus: STATUSES.LOADING,
          },
        },
      };
    case FETCH_MORTGAGE_AND_TERMS_SUCCESS:
      return {
        ...state,
        loanTerms: action.payload.loanTerms,
        propertyDataCache: {
          ...state.propertyDataCache,
          [action.payload.activePropertySlug]: {
            ...getPropertyCacheItemOrInitForSlug(
              action.payload.activePropertySlug,
              state
            ),
            mortgageCalculationStatus: STATUSES.SUCCESS,
            mortgageCalculations: action.payload.mortgageCalculations,
          },
        },
      };
    case FETCH_MORTGAGE_AND_TERMS_ERROR:
      return {
        ...state,
        propertyDataCache: {
          ...state.propertyDataCache,
          [action.payload.activePropertySlug]: {
            ...getPropertyCacheItemOrInitForSlug(
              action.payload.activePropertySlug,
              state
            ),
            mortgageCalculationStatus: STATUSES.ERROR,
          },
        },
      };
    case PROPERTY_PAGE_HAS_TOGGLED_HEATMAPS:
      return {
        ...state,
        shouldShowHeatmapsWhenTogglingMapView:
          action.payload.hasEnabledHeatmaps,
      };
    default:
      return state;
  }
}
