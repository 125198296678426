import {
  PathType,
  RouteDefType,
  RouteFnType,
  RouteMatcherInfoType,
  RouteMatcherMatchType,
  RouteMatcherMatchExtendedType,
  RouteMatcherRoutesType,
} from './types';
// Copied from ruta3: https://github.com/bevacqua/ruta3
import { pathToRegExp } from './utils';

const match = (
  routes: RouteMatcherRoutesType,
  uri: string,
  startAt?: number
): RouteMatcherMatchType | null => {
  let i = startAt || 0;

  for (const len = routes.length; i < len; ++i) {
    const route = routes[i];
    const re = route.re;
    const keys = route.keys;
    let splats: string[] = [];
    const params = {};

    const captures = uri.match(re);
    if (captures) {
      const len = captures.length;
      for (let j = 1; j < len; ++j) {
        const value =
          typeof captures[j] === 'string'
            ? decodeURIComponent(captures[j])
            : captures[j];
        const key = keys && keys[j - 1];
        if (key) {
          params[key] = value;
        } else {
          splats.push(value);
        }
      }

      return {
        params: params,
        splats: splats,
        route: route.src,
        next: i + 1,
        index: route.index,
      };
    }
  }

  return null;
};

function routeInfo(path: string | RegExp, index: number): RouteMatcherInfoType {
  let re;
  const keys = [];

  if (path instanceof RegExp) {
    re = path;
  } else {
    re = pathToRegExp(path, keys);
  }

  return {
    re: re,
    src: path.toString(),
    keys: keys,
    index: index,
  };
}

function Router(this: any): void {
  this.routes = [];
  this.routeMap = [];
}

Router.prototype.addRoute = function (
  path: PathType,
  saga: RouteFnType,
  routeDef: RouteDefType
): void {
  if (!path) {
    throw new Error(' route requires a path');
  }
  if (!saga) {
    throw new Error(' route ' + path.toString() + ' requires a saga');
  }

  const route = routeInfo(path, this.routeMap.length);
  route.saga = saga;
  this.routes.push(route);
  this.routeMap.push([path, saga, routeDef]);
};

Router.prototype.match = function (
  uri: string,
  startAt?: number
): RouteMatcherMatchExtendedType | null {
  const routeMatch = match(this.routes, uri, startAt);
  if (routeMatch) {
    return {
      ...routeMatch,
      saga: this.routeMap[routeMatch.index][1],
      routeDef: this.routeMap[routeMatch.index][2],
      next: this.match.bind(this, uri, routeMatch.next),
    };
  }
  return null;
};

export default Router;
