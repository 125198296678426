import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import { key } from '@client/utils/component.utils';
import LazilyRenderedList from '@client/components/LazilyRenderedList';
import SlideInModal from '@client/components/generic/SlideInModal';
import ThumbnailCarousel from '@client/components/ThumbnailCarousel';
import defaultTheme from '@client/css-modules/CarouselDialog.css';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import { parseCSSAmount } from '@client/utils/string.utils';
import CobrandedStyles from '@client/components/CobrandedStyles';
import { createModalPortal } from '@client/hocs/create-modal-portal';

const SCROLLABLE_CONTAINER_CLASSNAME = 'CarouselDialogScrollableContainer';
const {
  'carousel-dialog-thumbnail-width-height': thumbnailWidthHeight,
  'carousel-dialog-thumbnail-container-max-width': thumbnailContainerMaxWidth,
} = defaultTheme;
const thumbnailWidthHeightInt = parseCSSAmount(thumbnailWidthHeight);
const thumbnailContainerMaxWidthInt = parseCSSAmount(
  thumbnailContainerMaxWidth
);
const ITEM_DIMENSIONS_STYLE = {
  width: thumbnailWidthHeight,
  height: thumbnailWidthHeight,
};

type Props = {
  /* Array of image URLs to render inside of slider */
  urls: Array<string>;
  /* Whether dialog is displayed */
  isActive: boolean;
  /* Index of initial slide to display */
  initialSlide: number;
  /* Callback method that should handle changing `isActive` prop to false  */
  onClose: () => void;
  /* Whether to display navigation arrows in the dialog */
  showArrows: boolean;
  /* Selector for automated testing. */
  dataHcName: string;
  /* Whether to scroll to the top of the dialog on photo thumbnail click */
  scrollTopOnThumbnailClick: boolean;
  theme: Theme;
  fullAddress?: string | null;
};

/**
 * A fullscreen dialog that displays a photo carousel accompanied by a grid of thumbnails underneath
 *
 * Flow:
 * - Don't do much on initial render.  Most of the work happens when this modal is set to active
 *     (!props.isActive => props.isActive)
 * - When active, render the large carousel at the top and placeholder divs for all of the thumbnail
 *     images (within LazilyRenderedList)
 * - The LazilyRenderedList `onRenderItems` callback fires after the thumbnail placeholder divs that
 *     fit within the viewport are rendered
 * - This callback kicks off the preloading of these thumbnail images that fit within the viewport
 * - After each thumbnail image preloads, an `onLoad` callback causes it to actually render inside of
 *     its placeholder (via a background-image style)
 * - When the user scrolls the thumbnail list, the last 2 steps are repeated: more items are reported
 *     in the viewport, more images are preloaded, and more thumbnail images render on the screen.
 */
const CarouselDialog: React.FC<Props> = (props) => {
  const { isActive, urls, onClose, theme, fullAddress, initialSlide } = props;
  const [imageIndex, setImageIndex] = useState(initialSlide || 0);
  const [shouldRenderThumbnails, setShouldRenderThumbnails] = useState(false);
  const [shouldRenderAllChildren, setShouldRenderAllChildren] = useState(false);
  const [urlsToPreload, setUrlsToPreload] = useState<string[]>([]);
  const [urlsToRender, setUrlsToRender] = useState<string[]>([]);
  const thumbnailTilesPerRow = Math.floor(
    thumbnailContainerMaxWidthInt / thumbnailWidthHeightInt
  );
  const shouldRenderThumbnailsTimeoutRef = useRef(0);
  const goToInitialSlideTimeoutRef = useRef(0);
  const imageCount = urls.length;
  /* We need this to set the dialog content's height before its content is populated.  This is required for
   * SlideInModal to scroll correctly when it overflows the screen */
  const imageListMinHeight = `${
    Math.ceil(imageCount / thumbnailTilesPerRow) *
      (thumbnailWidthHeightInt + 2) /* margin */ +
    40 /* padding */
  }px`;

  useEffect(() => {
    if (isActive) {
      goToSlide(initialSlide);
      renderThumbnails();
    } else {
      unrenderThumbnails();
      setShouldRenderAllChildren(false);
    }
  }, [isActive, initialSlide]);

  useEffect(() => {
    setImageIndex(initialSlide);
  }, [initialSlide]);

  useEffect(() => {
    return () => {
      window.clearTimeout(shouldRenderThumbnailsTimeoutRef.current);
      window.clearTimeout(goToInitialSlideTimeoutRef.current);
    };
  }, []);

  const renderThumbnails = () => {
    /* Give the SlideInModal time to animate in first */
    shouldRenderThumbnailsTimeoutRef.current = window.setTimeout(() => {
      setShouldRenderThumbnails(true);
    }, 800);
  };

  /* Need to unrender thumbnails when modal becomes inactive to prepare it becoming active next time */
  const unrenderThumbnails = () => {
    setUrlsToPreload([]);
    setUrlsToRender([]);
    setShouldRenderThumbnails(false);
  };

  const goToSlide = (index: number = 0): void => {
    setImageIndex(index);
  };

  const goToNextSlide = (): void => {
    const newIndex = imageIndex < urls.length - 1 ? imageIndex + 1 : 0;
    setImageIndex(newIndex);
  };

  const goToPrevSlide = (): void => {
    const newIndex = imageIndex > 0 ? imageIndex - 1 : urls.length - 1;
    setImageIndex(newIndex);
  };

  // This announces the x of y image for screenreaders if !shouldRenderAllChildren
  const renderInfoContent = (
    currentIndex: number,
    urls: Array<string>
  ): JSX.Element => {
    return (
      <div>
        {currentIndex + 1} of {urls.length}
      </div>
    );
  };

  const handleStartImagePreload = (urls: string[]): void => {
    setUrlsToPreload(urls);
  };

  const handleImagePreloaded = (url: string): void => {
    setUrlsToRender((urlsToRender) => urlsToRender.concat([url]));
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (key.isArrowLeft(e.key)) {
      goToPrevSlide();
    } else if (key.isArrowRight(e.key)) {
      goToNextSlide();
    }
  };

  return imageCount > 0 ? (
    <div className={theme.CarouselDialog}>
      <AccessibleElementUniqueId>
        {({ uid }) => (
          <SlideInModal
            isFullScreen={false}
            theme={{
              ...theme,
              ScrollableContainer: SCROLLABLE_CONTAINER_CLASSNAME,
            }}
            isActive={isActive}
            handleClose={onClose}
            handleKeyDown={handleKeyDown}
            modalAriaLabel={'Photo list'}
          >
            <div
              className={theme['Nav']}
              data-hc-name="dialog-close"
              onClick={onClose}
            />
            <ThumbnailCarousel
              id={uid}
              alt={
                fullAddress ? `Photo of ${fullAddress}` : 'Photo of property'
              }
              alwaysShowControls={true}
              urls={urls}
              theme={theme}
              onClickNext={() => {
                if (shouldRenderAllChildren) {
                  setShouldRenderAllChildren(false);
                }
                goToNextSlide();
              }}
              onClickPrev={() => {
                if (shouldRenderAllChildren) {
                  setShouldRenderAllChildren(false);
                }
                goToPrevSlide();
              }}
              onClick={goToNextSlide}
              index={imageIndex}
              fullAddress={fullAddress}
            />
            <div className={theme.RenderPropContent}>
              {renderInfoContent(imageIndex, urls)}
            </div>
            <div
              className={theme.LazilyRenderedListContainer}
              style={{ minHeight: imageListMinHeight }}
            >
              {shouldRenderThumbnails && (
                <CobrandedStyles>
                  {({ photoModalSelectedBorderColor }) => {
                    return (
                      <LazilyRenderedList
                        dataHcName="photo-list-photos"
                        className={theme.ImageList}
                        preloadBuffer={100}
                        scrollableAncestorClassName={
                          SCROLLABLE_CONTAINER_CLASSNAME
                        }
                        theme={theme}
                        itemDimensionsStyle={ITEM_DIMENSIONS_STYLE}
                        llKeyField="data-src"
                        shouldKeepItemsRendered
                        onRenderItems={handleStartImagePreload}
                        ariaLabel="thumbnails"
                        shouldRenderAllChildren={shouldRenderAllChildren}
                      >
                        {urls.map((url, index) => {
                          const shouldRenderUrl =
                            shouldRenderAllChildren ||
                            urlsToRender.includes(url);
                          const isSelected = index === imageIndex;

                          return shouldRenderUrl ? (
                            <button
                              key={url}
                              type="button"
                              onClick={() => {
                                goToSlide(index);
                              }}
                              className={classNames(theme.Image, {
                                [theme['active']]: isSelected,
                              })}
                              data-src={url}
                              aria-controls={uid}
                              aria-label={`${index + 1} of ${imageCount}`}
                              onKeyUp={(e) => {
                                // enables equal behavior for users not using the mouse
                                if (e.key === 'Tab') {
                                  goToSlide(index);
                                  // the user will not be able to tab through all thumbnails unless we disable lazy loading
                                  if (!shouldRenderAllChildren) {
                                    setShouldRenderAllChildren(false);
                                  }
                                }
                              }}
                              style={{
                                backgroundImage: `url('${url}')`,
                                opacity: 1,
                                border: isSelected
                                  ? `3px solid ${photoModalSelectedBorderColor}`
                                  : 'none',
                              }}
                            />
                          ) : (
                            <div
                              key={url}
                              data-src={url}
                              className={theme.Image}
                            />
                          );
                        })}
                      </LazilyRenderedList>
                    );
                  }}
                </CobrandedStyles>
              )}
            </div>
            {urlsToPreload.map((url) => (
              <link
                className={theme.OffscreenImg}
                rel="preload"
                href={url}
                key={url}
                as="image"
                onLoad={() => {
                  handleImagePreloaded(url);
                }}
              />
            ))}
          </SlideInModal>
        )}
      </AccessibleElementUniqueId>
    </div>
  ) : null;
};

const ThemedCarouselDialog = themr(
  'ThemedCarouselDialog',
  defaultTheme
)(CarouselDialog);
export default createModalPortal(ThemedCarouselDialog, 'photo-list');
