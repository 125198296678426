/**
 * Utility methods to be used for each cobrandId
 * Note that tests will fail if all util defs don't contain the exact same keys and all values are
 * of type 'function'
 * Please define an empty function for all cobrands for which the util method isn't used.
 * Please keep in alpha order.
 */
import {
  COBRAND_IDS,
  CobrandId,
  ComehomeRedirectUtilInputType,
} from '@client/store/types/cobranding';
import chaseNoResultsBackgroundImage from '@client/assets/images/no-results-background-chase.jpg';
import lendcoNoResultsBackgroundImage from '@client/assets/images/cobrand/lendco/lendco-no-results-background.png';
import HC_CONSTANTS from '@client/app.config';
const { HC_BROKERAGE_INFO_URL } = HC_CONSTANTS;

/**
 * Given the hostname in the URL during SSR, return the matching cobrand ID
 */
export function getCobrandIdFromHostname(hostname: string): string {
  const hostnameFirstSegment = hostname.split('.', 1)[0];
  /* Exception to allow accessing the site via local IP (i.e. http://192.168.133.24:3000)
   * We need this for testing on mobile phones during development */
  const isLocalIP = hostname.match(/^\d+\.\d+\.\d+\.\d+$/);

  if (hostnameFirstSegment === 'www' || isLocalIP) {
    return 'www';
  } else {
    return hostnameFirstSegment;
  }
}

export function getRoundedInterestRateForFAMCobrands(
  value?: number
): number | string | undefined {
  /**
   * For a brief moment while we fetch default mortgage terms
   * interest rate or value here is undefined, check here to protect
   * from the value becoming NaN
   */
  if (value) {
    const decimalPart = parseInt(value.toString(), 10);
    const fractionPart = +(value % 1).toFixed(2);

    if (fractionPart >= 0.01 && fractionPart <= 0.12) {
      return (decimalPart + 0.125).toFixed(3);
    } else if (fractionPart >= 0.13 && fractionPart <= 0.25) {
      return (decimalPart + 0.25).toFixed(3);
    } else if (fractionPart >= 0.26 && fractionPart <= 0.37) {
      return (decimalPart + 0.375).toFixed(3);
    } else if (fractionPart >= 0.38 && fractionPart <= 0.5) {
      return (decimalPart + 0.5).toFixed(3);
    } else if (fractionPart >= 0.51 && fractionPart <= 0.62) {
      return (decimalPart + 0.625).toFixed(3);
    } else if (fractionPart >= 0.63 && fractionPart <= 0.75) {
      return (decimalPart + 0.75).toFixed(3);
    } else if (fractionPart >= 0.76 && fractionPart <= 0.87) {
      return (decimalPart + 0.875).toFixed(3);
    } else if (fractionPart >= 0.88 && fractionPart <= 0.99) {
      return (decimalPart + 1.0).toFixed(3);
    } else {
      return value;
    }
  } else {
    return value;
  }
}

const getComeHomeLogoRedirect = ({
  campaign,
  cobrandId,
  medium,
}: ComehomeRedirectUtilInputType): { url: string; target: string } => {
  const campaignURLSegment = campaign ? `&utm_campaign=${campaign}` : '';
  const url = `${HC_BROKERAGE_INFO_URL}/?utm_medium=${medium}&utm_source=${cobrandId}${campaignURLSegment}`;
  return { url, target: '_blank' };
};

export const BASE_UTILS = {
  getComeHomeLogoRedirect,
  getShouldDisplayAVMForFSD: (fsdValue: number | null): boolean => true,
  getNoResultsBackgroundImageUrl: (): string => lendcoNoResultsBackgroundImage,
  getRoundedInterestRate: (value: number): number =>
    Math.round((value + Number.EPSILON) * 100) / 100,
  getFormattedCTAText: (raw: string): string =>
    raw
      .split(/\s/)
      .map((word, i) => `${word.charAt(0).toUpperCase()}${word.substring(1)}`)
      .join(' '),
};

const FAM_COMMON_UTILS = {
  getRoundedInterestRate: getRoundedInterestRateForFAMCobrands,
};

export const COBRANDED_UTILS = {
  [COBRAND_IDS.WWW]: {
    ...BASE_UTILS,
    getComeHomeLogoRedirect: (): null => null,
  },
  [COBRAND_IDS.CHASE]: {
    getComeHomeLogoRedirect,
    getShouldDisplayAVMForFSD: (fsdValue: number | null): boolean =>
      !!fsdValue && fsdValue <= 0.1,
    getNoResultsBackgroundImageUrl: (): string => chaseNoResultsBackgroundImage,
    getRoundedInterestRate: (value: number): number => value,
    getFormattedCTAText: (raw: string): string =>
      raw
        .split(/\s/)
        .map(
          (word, i) =>
            `${
              i > 0
                ? word.charAt(0).toLowerCase()
                : word.charAt(0).toUpperCase()
            }${word.substring(1)}`
        )
        .join(' '),
  },
  FAM_COBRAND: {
    ...BASE_UTILS,
    ...FAM_COMMON_UTILS,
  },
};

export type UtilDefMapping =
  typeof COBRANDED_UTILS[keyof typeof COBRANDED_UTILS];

export function getCobrandUtils(cobrandId: CobrandId | string): UtilDefMapping {
  if (COBRANDED_UTILS[cobrandId]) {
    return COBRANDED_UTILS[cobrandId];
  } else {
    return BASE_UTILS;
  }
}
