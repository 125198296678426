import classNames from 'classnames';
import React from 'react';
import { themr } from '@friendsofreactjs/react-css-themr';

import LenderPropertyCTAContainerChase from '@client/containers/cobrand/chase/lender-property-cta-chase.container';
import { PropertyCardLenderCTASectionProps } from '@client/store/types/cobranded-components/property-card-lender-cta-section';
import defaultTheme from '@client/css-modules/PropertyCardCTASectionChase.css';
import MLSAttribution from '@client/components/MLSAttribution';
import PropertyCardValueCobranded from '@client/components/PropertyCardValue/PropertyCardValueCobranded';

/**
 * A section containing price information and a lender CTA button
 * This component has specific styling for display within a property card
 */
const PropertyCardCTASectionChase: React.FunctionComponent<
  PropertyCardLenderCTASectionProps
> = ({
  theme,
  propertyDetails,
  value,
  valueLabel,
  isActiveListing,
  isShowingClickHintCTA,
}) => {
  return (
    <div
      className={classNames(theme.PropertyCardChaseCTASection, {
        [theme.CTASectionActiveListing]: isActiveListing,
      })}
    >
      <div className={theme.ValueSectionWrapper}>
        <PropertyCardValueCobranded
          value={value}
          valueLabel={valueLabel}
          theme={theme}
          isShowingClickHintCTA={isShowingClickHintCTA}
          bottomLabel={<MLSAttribution propertyDetails={propertyDetails} />}
        />
      </div>
      {isActiveListing && (
        <LenderPropertyCTAContainerChase
          propertyDetails={propertyDetails}
          effectivePropertyPrice={value}
          theme={theme}
        />
      )}
    </div>
  );
};

export default themr(
  'PropertyCardCTASectionChaseThemed',
  defaultTheme
)(PropertyCardCTASectionChase);
