import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';

import { getIsInsideNativeApp } from '@client/store/selectors/cobranding.selectors';
import { getIsInitialRoute } from '@client/store/selectors/router.selectors';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { View } from '@client/routes/constants';
import defaultTheme from '@client/css-modules/HeaderMobileSearchHomesNav.css';
import MobileSearchHomesBackArrowCobranded from '@client/components/MobileSearchHomesBackArrow/MobileSearchHomesBackArrowCobranded';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type Props = {
  theme: Theme;
};

const HeaderMobileSearchHomesNav: React.FC<Props> = ({ theme }) => {
  const dispatch = useDispatch();
  const handleNavigationToSearchPage = () => {
    dispatch(reportEvent('click_nav_mobile_back_to_search'));
    dispatch(routeChange({ view: View.SEARCH }));
  };
  const isInsideNativeApp = useSelector(getIsInsideNativeApp);
  const isInitialRoute = useSelector(getIsInitialRoute);
  const currentView = useSelector(getCurrentView);
  const { MobileSearchHomesBackButtonTextColor } = useCobrandStyles();

  const isInitialRouteHomeBuyerPDP = () => {
    return isInitialRoute && currentView === View.PROPERTY_DETAILS;
  };

  return isInitialRouteHomeBuyerPDP() && !isInsideNativeApp ? (
    <div className={theme.HeaderMobileSearchHomesNav}>
      <button
        className={theme.HeaderMobileSearchHomesButton}
        onClick={handleNavigationToSearchPage}
        onKeyDown={onEnterOrSpaceKey(handleNavigationToSearchPage)}
        aria-label="Go to search page"
      >
        <MobileSearchHomesBackArrowCobranded
          className={theme.MobileSearchHomesBackArrow}
        />
        <div
          className={theme.SearchHomesText}
          style={{ color: MobileSearchHomesBackButtonTextColor }}
        >
          Search homes
        </div>
      </button>
    </div>
  ) : null;
};

export default themr(
  'ThemedHeaderMobileSearchHomesNav',
  defaultTheme
)(HeaderMobileSearchHomesNav);
