import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import SearchLoanOfficerAd from './SearchLoanOfficerAd';
import { COBRAND_IDS } from '@client/store/types/cobranding';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
    case COBRAND_IDS.WWW:
    case COBRAND_IDS.LENDINGTREE:
      /* Returning null causes the card to be omitted from the property card list */
      return null;
    default:
      return SearchLoanOfficerAd;
  }
};

const SearchLoanOfficerAdCobranded: React.FC<{}> = () => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return Component ? <Component /> : null;
};

export default SearchLoanOfficerAdCobranded;
