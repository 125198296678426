import AdditionalHomeDetails from '@client/components/AdditionalHomeDetails';
import ConditionalFeature from '@client/components/ConditionalFeature';
import FindAgentBanner from '@client/components/FindAgent/FindAgentBanner';
import HorizontalSeparator from '@client/components/generic/HorizontalSeparator';
import GrantProgramPDPBanner from '@client/components/GrantProgram/GrantProgramPDPBanner';
import MLSAttributionPDPNWMLSException from '@client/components/MLSAttributionPDPNWMLSException';
import OpenHouseInfo from '@client/components/OpenHouseInfo';
import PDPRightRailCard from '@client/components/PDPRightRailCard/PDPRightRailCard';
import AvmBreakdownContainer from '@client/containers/avm-breakdown.container';
import BreakoutSectionsContainer from '@client/containers/breakout-sections.container';
import BrokerageAttributionContainer from '@client/containers/brokerage-attribution.container';
import CarouselRowContainer from '@client/containers/carousel-row.container';
import Footer from '@client/containers/footer.container';
import HomeownerUpsellAdContainer from '@client/containers/homeowner-upsell-ad.container';
import ListingInfoContainer from '@client/containers/listing-info.container';
import MapPropertyPageContainer from '@client/containers/map-property-page.container';
import NearbyListingsContainer from '@client/containers/nearby-listings.container';
import PropertyIntroContainer from '@client/containers/property-intro.container';
import theme from '@client/css-modules/PropertyPageDesktopContentsComehome.css';
import { getPropertyDetailPageConfig } from '@client/store/selectors/cobranding.selectors';
import { PropertyPageDesktopContentsProps } from '@client/store/types/cobranded-components/property-page';
import { useSelector } from 'react-redux';
import CustomPropertyBanner from '../CustomPropertyBanner';

/**
 * Contains the contents displayed within the PDP on desktop.
 */
export default function PropertyPageDesktopContentsComehome({
  isActiveListing,
  shouldShowHomeownerAd,
  isCanaryUIFeatureEnabled,
}: PropertyPageDesktopContentsProps) {
  const propertyPageData = useSelector(getPropertyDetailPageConfig)
  const showCustomBanner = propertyPageData.bannerHeadline && propertyPageData.bannerButtonLabel && propertyPageData.bannerDestinationUrl
  return (
    <div className={theme.PropertyPageDesktopContents}>
      <CarouselRowContainer />
      <div className={theme.Columns}>
        <div className={theme.LeftSide}>
          <MLSAttributionPDPNWMLSException />
          <div className={theme.AttributionWrapper}>
            <BrokerageAttributionContainer
              isMinimalStyling={false}
              theme={theme}
            />
          </div>
          <PropertyIntroContainer addressAsHeader theme={theme} />
          <OpenHouseInfo />
          <ListingInfoContainer />
          <AdditionalHomeDetails />
          <ConditionalFeature renderIfFeaturesEnabled={['grant_program']}>
            <GrantProgramPDPBanner />
          </ConditionalFeature>
          {
            /* The Homeowner ad is placed differently on the page for onsale/offsale properties */
            !isActiveListing && shouldShowHomeownerAd && (
              <HomeownerUpsellAdContainer />
            )
          }
          {isActiveListing && (
            <ConditionalFeature
              renderIfFeaturesEnabled={['referral_services', 'your_team']}
            >
              <div
                className={theme.FindAgentWrapper}
                data-hc-name="find-agent-section"
              >
                <FindAgentBanner isNarrowLayout={false} />
              </div>
            </ConditionalFeature>
          )}
          {showCustomBanner &&
            (<div
              className={theme.FindAgentWrapper}
              data-hc-name="find-agent-section"
            >
              <CustomPropertyBanner isNarrowLayout={false} propertyPageData={propertyPageData} />
            </div>
          )}
          <MapPropertyPageContainer />
          <HorizontalSeparator theme={theme} />
          <AvmBreakdownContainer />
          <BreakoutSectionsContainer />
          {
            /* The Homeowner ad is placed differently on the page for onsale/offsale properties */
            isActiveListing && shouldShowHomeownerAd && (
              <HomeownerUpsellAdContainer />
            )
          }
          <NearbyListingsContainer />
        </div>
        <div className={theme.RightSide} data-hc-name={'right-side-column'}>
          <PDPRightRailCard
            theme={theme}
            isCanaryUIFeatureEnabled={isCanaryUIFeatureEnabled}
            isShowingMonthlyPaymentDetails
            isShowingShareAndSaveSection
            isStandaloneModule
          />
        </div>
      </div>
      <HorizontalSeparator theme={theme} />
      <Footer theme={theme} shouldUseSectionElement />
    </div>
  );
}
