import React from 'react';
import { connect } from 'react-redux';

import { getCobrandStyles } from '@client/cobrand-settings/cobrand-styles';
import {
  UtilDefMapping,
  getCobrandUtils,
} from '@client/cobrand-settings/cobrand-utils';
import {
  getCobrandId,
  getDomainTheme,
} from '@client/store/selectors/cobranding.selectors';
import { DomainTheme, StyleDefMapping } from '@client/store/types/cobranding';

type RenderProp = (args: CobrandedComponentArgs) => React.ReactNode;

type Props = {
  cobrandId: string;
  domainTheme: DomainTheme;
  children: RenderProp;
};

export type CobrandedComponentArgs = {
  styles: StyleDefMapping;
  utils: UtilDefMapping;
  cobrandId: string;
};

const mapStateToProps = (state) => ({
  cobrandId: getCobrandId(state),
  domainTheme: getDomainTheme(state),
});

/**
 * Component that provides branding info via a `children` render prop to allow child components
 * to use styles and utils
 *
 * Example usage:
 *
 *  <CobrandedComponent>
 *    { ({ styles: { HeaderLogo } }) => <Logo className={theme.HeaderLogo} /> }
 *  </CobrandedComponent>
 */
const CobrandedComponent: React.FC<Props> = ({
  cobrandId,
  domainTheme,
  children,
}) => {
  const styles = getCobrandStyles(cobrandId, domainTheme);
  const utils = getCobrandUtils(cobrandId);

  return children({
    styles,
    utils,
    /**
     * To add a new key/value pair, please follow these steps
     *
     * 1. In the consumer-customization-data repo
     * Follow steps in README,
     * to add a new key/value pair in the default customization data.
     * https://git.housecanary.net/Engineering/consumer-customization-data
     *
     * 2. In consumer-web repo
     *  - Add a type of a new key in the CustomizationData interface in src/client/store/types/cobranding.ts
     * 3. You can override the default customization in the Custom Configurations field on Parcon.
     */
    cobrandId,
  }) as React.ReactElement; /* Needed to satisfy the need of `connect` to infer types from React.FC */
};

export default connect(mapStateToProps, {})(CobrandedComponent);
