import React from 'react';
import { useSelector } from 'react-redux';

import MLSAttribution from '@client/components/MLSAttribution';
import { getMLSIdFromPropertyDetails } from '@client/utils/property.utils';
import { CommonPropertyDetails } from '@client/store/types/property';
import { getPropertyDetailsNormalized } from '@client/store/selectors/property-details.selectors';
import { NWMLS_ID } from '@client/store/constants';
import theme from '@client/css-modules/MLSAttributionPDPNWMLSException.css';

/*
 * This Comp is a specific exception to NorthWest MLS
 * Courtesy of xxx is placed underneath the photo carousel of PDP
 * instead of Listings Provided by NWMLS like in the property cards
 */
const MLSAttributionPDPNWMLSException: React.FC<{}> = () => {
  const propertyDetails = useSelector(
    getPropertyDetailsNormalized
  ) as CommonPropertyDetails;

  return getMLSIdFromPropertyDetails(propertyDetails) === NWMLS_ID ? (
    <MLSAttribution
      className={theme.MLSAttributionPDPNWMLSException}
      propertyDetails={propertyDetails}
    />
  ) : null;
};

export default MLSAttributionPDPNWMLSException;
