import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { StyleDefMapping } from '@client/store/types/cobranding';

type CobrandedStylesChildren = {
  children: (args: StyleDefMapping) => JSX.Element | null;
};

/**
 * Component that provides branding styles via a `children` render prop to allow child components
 * to use that info in their declaration
 *
 * Example usage:
 *
 *  <CobrandedStyles>
 *    { ({ activeBuyingPowerColor }) => <button style={{ color: activeBuyingPowerColor }} /> }
 *  </CobrandedStyles>
 *
 */
export default function CobrandedStyles({ children }: CobrandedStylesChildren) {
  return children(useCobrandStyles());
}
