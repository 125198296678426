import { uniqBy } from 'lodash';

import { Action } from '@client/store/actions';
import {
  FETCH_LOCAL_ACTIVITIES,
  FETCH_LOCAL_ACTIVITIES_ERROR,
  FETCH_LOCAL_ACTIVITIES_SUCCESS,
  RESET_LOCAL_ACTIVITIES_FILTER,
  SET_LOCAL_ACTIVITIES_FILTER,
} from '@client/store/actions/local-activities.actions';
import {
  ACTIVITY_IDS,
  ACTIVITY_TITLES,
  ActivityId,
  STATUSES,
} from '@client/store/constants';
import { LocalActivitiesQuery } from '@client/store/sagas/queries/types';
import { Business } from '@client/store/types/local-activities';
import { Status } from '@client/store/types/statuses';

export type BusinessResults = {
  [activityId in ActivityId]: Business[];
};

const BUSINESS_RESULTS_INIT_STATE = {
  [ACTIVITY_IDS.ALL]: [],
  [ACTIVITY_IDS.RESTAURANTS]: [],
  [ACTIVITY_IDS.BARS]: [],
  [ACTIVITY_IDS.GROCERY]: [],
  [ACTIVITY_IDS.CAFES]: [],
  [ACTIVITY_IDS.ENTERTAINMENT]: [],
  [ACTIVITY_IDS.PARKS]: [],
};

const BUSINESS_RESULTS_API_NULL_STATE: NonNullable<
  LocalActivitiesQuery['business']
>['results'] = {
  [ACTIVITY_IDS.RESTAURANTS]: [],
  [ACTIVITY_IDS.BARS]: [],
  [ACTIVITY_IDS.GROCERY]: [],
  [ACTIVITY_IDS.CAFES]: [],
  [ACTIVITY_IDS.PARKS]: [],
  arts: [],
};

export type LocalActivitiesState = {
  status: Status;
  error: null | string;
  results: BusinessResults;
  activeFilter: null | ActivityId;
  activeFilterTitle: null | string;
  counts: {
    [activityId in ActivityId]: number;
  };
  searchRadius: number;
  businessSummary: null | string;
};

const INITIAL_STATE: LocalActivitiesState = {
  status: STATUSES.INIT,
  error: null,
  results: BUSINESS_RESULTS_INIT_STATE,
  activeFilter: null,
  activeFilterTitle: null,
  counts: {
    [ACTIVITY_IDS.ALL]: 0,
    [ACTIVITY_IDS.RESTAURANTS]: 0,
    [ACTIVITY_IDS.BARS]: 0,
    [ACTIVITY_IDS.GROCERY]: 0,
    [ACTIVITY_IDS.CAFES]: 0,
    [ACTIVITY_IDS.ENTERTAINMENT]: 0,
    [ACTIVITY_IDS.PARKS]: 0,
  },
  searchRadius: 0,
  businessSummary: null,
};

/* Add parentCategory field (currently API returns `null` for this), add "all" category, rename 'arts' category to 'entertainment' */
const marshallBusinessResults = (
  results: NonNullable<LocalActivitiesQuery['business']>['results']
): BusinessResults => {
  let allResults: Business[] = [];
  const futureResults: BusinessResults = BUSINESS_RESULTS_INIT_STATE;

  for (let type in results) {
    const futureType = type === 'arts' ? 'entertainment' : type;
    futureResults[futureType] = results[type].map((result) => ({
      ...result,
      parentCategory: futureType,
    }));
    allResults.push(results[type]);
  }

  return {
    ...futureResults,
    all: uniqBy(allResults, (business) => business.id),
  };
};

const getMaxDistance = (allResults: Business[]): number => {
  const radii = allResults
    .map((business) => business.distance)
    .filter((val) => !!val) as number[];
  return Math.max(...radii);
};

const getInitialFilter = (results: BusinessResults): ActivityId | null => {
  for (let type in ACTIVITY_IDS) {
    let filter = ACTIVITY_IDS[type];
    if (results[filter] && results[filter].length > 0) {
      return filter;
    }
  }
  return null;
};

export default function localActivitiesReducer(
  state = INITIAL_STATE,
  action: Action
): LocalActivitiesState {
  switch (action.type) {
    case FETCH_LOCAL_ACTIVITIES:
      return {
        ...state,
        status: STATUSES.LOADING,
        error: INITIAL_STATE.error,
        results: INITIAL_STATE.results,
        counts: INITIAL_STATE.counts,
        searchRadius: INITIAL_STATE.searchRadius,
      };
    case FETCH_LOCAL_ACTIVITIES_SUCCESS:
      const results =
        action.payload.data.business?.results ||
        BUSINESS_RESULTS_API_NULL_STATE;
      const parsedResults = marshallBusinessResults(results);
      const initialFilter = getInitialFilter(parsedResults);

      return {
        ...state,
        status: STATUSES.SUCCESS,
        results: parsedResults,
        counts: {
          [ACTIVITY_IDS.ALL]: parsedResults[ACTIVITY_IDS.ALL].length,
          [ACTIVITY_IDS.RESTAURANTS]:
            parsedResults[ACTIVITY_IDS.RESTAURANTS].length,
          [ACTIVITY_IDS.BARS]: parsedResults[ACTIVITY_IDS.BARS].length,
          [ACTIVITY_IDS.GROCERY]: parsedResults[ACTIVITY_IDS.GROCERY].length,
          [ACTIVITY_IDS.CAFES]: parsedResults[ACTIVITY_IDS.CAFES].length,
          [ACTIVITY_IDS.ENTERTAINMENT]:
            parsedResults[ACTIVITY_IDS.ENTERTAINMENT].length,
          [ACTIVITY_IDS.PARKS]: parsedResults[ACTIVITY_IDS.PARKS].length,
        },
        searchRadius: parsedResults[ACTIVITY_IDS.ALL]
          ? getMaxDistance(parsedResults[ACTIVITY_IDS.ALL])
          : 0,
        activeFilter: initialFilter,
        activeFilterTitle: initialFilter && ACTIVITY_TITLES[initialFilter],
        businessSummary:
          action.payload.data.propertyLookup?.businessSummary || null,
      };
    case FETCH_LOCAL_ACTIVITIES_ERROR:
      return {
        ...state,
        status: STATUSES.ERROR,
      };
    case SET_LOCAL_ACTIVITIES_FILTER:
      return {
        ...state,
        activeFilter: action.payload.type,
        activeFilterTitle: action.payload.title,
      };
    case RESET_LOCAL_ACTIVITIES_FILTER:
      const filter = getInitialFilter(state.results);
      return {
        ...state,
        activeFilter: filter,
        activeFilterTitle: filter && ACTIVITY_TITLES[filter],
      };
    default:
      return state;
  }
}
