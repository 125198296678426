import { call, put } from 'redux-saga/effects';

import { watchEvery } from '@client/utils/saga.utils';
import { ensureLoggedInThen } from '@client/store/sagas/auth.saga';
import { consumerApiClient } from '@client/services/consumer-api-client';
import { resetUnreadAlertsSuccess } from '@client/store/actions/alerts.actions';

function* resetUnreadAlertsPossiblyLoggedOut() {
  yield call(ensureLoggedInThen, () => ({ type: 'RESET_UNREAD_ALERTS' }));
}

function* resetUnreadAlerts() {
  yield call([consumerApiClient, consumerApiClient.resetUnreadAlerts]);
  yield put(resetUnreadAlertsSuccess());
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    RESET_UNREAD_ALERTS_POSSIBLY_LOGGED_OUT: resetUnreadAlertsPossiblyLoggedOut,
    RESET_UNREAD_ALERTS: resetUnreadAlerts,
  });
};
