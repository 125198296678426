import { ModalKey } from '@client/store/constants';
import { LOCKED_AVM_FACTOR_VALUE_DELTA_PLACEHOLDER } from '@client/store/locked-components-constants';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  getAvmPriceMean,
  getBlockForecastSummary,
  getZip1YearForecastSummary,
  getZip3YearForecastSummary,
} from '@client/store/selectors/property-details.selectors';
import {
  SectionId,
  SpecialUserType,
} from '@client/store/types/locked-components';
import { getForecastRangeValue } from '@client/utils/chart-config.utils';
import { dollarsFormatter } from '@client/utils/formatter.utils';
import { Theme } from '@friendsofreactjs/react-css-themr';
import { useSelector } from 'react-redux';
import PreviewSection from './PreviewSection';

interface Props {
  theme: Theme;
  openSectionModal: (modalKey: ModalKey) => void;
}

const Forecast = ({ theme, openSectionModal }: Props) => {
  const avmPriceMean = useSelector(getAvmPriceMean);
  const forecastBlockSummary = useSelector(getBlockForecastSummary);
  const forecastZip1YearSummary = useSelector(getZip1YearForecastSummary);
  const forecastZip3YearSummary = useSelector(getZip3YearForecastSummary);
  const isHidingBlockLevelForecast = useSelector(
    getIsFeatureEnabled('hide_forecast_block_pdp')
  );

  return (
    <>
      {avmPriceMean && (
        <PreviewSection
          data-section-key={'forecast'}
          dataEventName={'click_property_details_forecast'}
          onClick={() => openSectionModal('forecast')}
          title="Forecast: 3 Year Growth"
          lockedComponent={{
            sectionId: SectionId.PdpForecast,
            lockedFor: [SpecialUserType.Restricted],
          }}
          placeholder={LOCKED_AVM_FACTOR_VALUE_DELTA_PLACEHOLDER}
        >
          <>
            {forecastBlockSummary?.value && !isHidingBlockLevelForecast && (
              <div>
                1 Year:&nbsp;
                <span className={theme.HighlightedValue}>
                  {getForecastRangeValue(forecastBlockSummary.percent)}
                </span>
                <span> at {dollarsFormatter(forecastBlockSummary.value)}</span>
              </div>
            )}
            {forecastZip1YearSummary?.value && isHidingBlockLevelForecast && (
              <div>
                1 Year:&nbsp;
                <span className={theme.HighlightedValue}>
                  {getForecastRangeValue(forecastZip1YearSummary.percent)}
                </span>
                <span>
                  {' '}
                  at {dollarsFormatter(forecastZip1YearSummary.value)}
                </span>
              </div>
            )}
            {forecastZip3YearSummary?.value && (
              <div>
                3 Year:&nbsp;
                <span className={theme.HighlightedValue}>
                  {getForecastRangeValue(forecastZip3YearSummary.percent)}
                </span>
                <span>
                  {' '}
                  at {dollarsFormatter(forecastZip3YearSummary.value)}
                </span>
              </div>
            )}
          </>
        </PreviewSection>
      )}
    </>
  );
};

export default Forecast;
