import HCLogoWithHouseCanaryText from '@client/assets/images/hc-logo-with-house-canary-text.svg';
import React from 'react';

const HCLogoWithHouseCanaryTextImg: React.FunctionComponent<
  React.HTMLAttributes<HTMLImageElement>
> = (props) => {
  return <img src={HCLogoWithHouseCanaryText} {...props} />;
};

export default HCLogoWithHouseCanaryTextImg;
