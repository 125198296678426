export default ({ slug }) => ({
  query: `query HomeownerPropertyDetailsQuery(
      $slug: String
    ) {
    propertyLookup (id: { slug: $slug}) {
      address {
        slug
        hcAddressId
        fullAddress
        streetAddress
        unit
        city
        state
        zipcode
        zipcodePlus4
      }
      avm (qualityMethod: CONSUMER) {
        priceMean
        priceUpper
      }
      saleDate
      salePrice
      transfers(order: DESCENDING) {
        transferDate
        transferPrice
        eventType
      }
    }
  }`,
  variables: {
    slug,
  },
});
