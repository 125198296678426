import { createSelector } from 'reselect';
import { getCurrentQuery } from '@src/redux-saga-router-plus/selectors';

import {
  ACTIVE_MODAL_URL_KEY,
  BROKERAGE_ATTRIBUTION_MODAL_KEY,
  CANT_FIND_ADDRESS_MODAL_ACTIVE_KEY,
  ModalKey,
  ModalKeyToRenderInPortal,
  NotificationKey,
} from '@client/store/constants';
import { ReduxState } from '@client/store/types/redux-state';

export function getModalsState(state: ReduxState) {
  return state.modals;
}
export const getIsShowingCantFindAddressModal = createSelector(
  getCurrentQuery,
  (query: {}) =>
    query?.[CANT_FIND_ADDRESS_MODAL_ACTIVE_KEY] &&
    query[CANT_FIND_ADDRESS_MODAL_ACTIVE_KEY] === 'true'
);

export const getModalPortalKeys = createSelector(
  getModalsState,
  (modalState) => modalState.modalKeysToRenderInPortals
);

export const verifyModalPortalCreated = (key: ModalKeyToRenderInPortal) =>
  createSelector(getModalPortalKeys, (modalKeysToRenderInPortals) =>
    modalKeysToRenderInPortals && modalKeysToRenderInPortals.indexOf(key) > -1
      ? key
      : null
  );

export const getIsShowingBrokerageModal = createSelector(
  getCurrentQuery,
  (query: {}) =>
    query[BROKERAGE_ATTRIBUTION_MODAL_KEY] &&
    query[BROKERAGE_ATTRIBUTION_MODAL_KEY] === 'true'
);

export const getActiveModalKey = createSelector(
  getCurrentQuery,
  (query: {}) => (query[ACTIVE_MODAL_URL_KEY] as ModalKey) || null
);

export const getIsModalActive = (modalKey: ModalKey) =>
  createSelector(
    getActiveModalKey,
    (activeModalKey) => !!(activeModalKey === modalKey)
  );

export const getActiveNotification = createSelector(
  getModalsState,
  (modalState) => modalState.activeNotification
);

export const getIsNotificationActive = (key: NotificationKey) =>
  createSelector(
    getActiveNotification,
    (activeNotificationKey) => !!(activeNotificationKey === key)
  );

export const getIsShowingAlternateYourTeamTriggerButton = createSelector(
  getModalsState,
  (modalsState) => modalsState.isShowingAlternateTriggerButton
);
