import AccountIcon from '@client/inline-svgs/account-icon';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import React from 'react';
import styled from 'styled-components';

type Props = {
  onClick?: () => void;
  ['data-hc-name']?: string;
};

const StyledDiv = styled.div<{ onClick: Props['onClick'] }>`
  width: 54px;
  height: 54px;
  border-radius: 28px;
  background: #4a4a4a;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};

  & svg {
    height: 17px;
    width: 17px;

    & .element-to-fill {
      height: 19px;
      width: 18px;
      fill: #fff;
    }
  }
`;

const PersonImagePlaceholder: React.FC<Props> = ({
  onClick,
  ['data-hc-name']: dataHcName,
}) => {
  return (
    <StyledDiv
      onClick={onClick}
      onKeyDown={onClick && onEnterOrSpaceKey(onClick)}
      data-hc-name={dataHcName}
    >
      <AccountIcon />
    </StyledDiv>
  );
};

export default PersonImagePlaceholder;
