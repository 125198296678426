import { ScreenSize, ScreenZoomScreenSize } from '@client/components/MatchMedia';

export const SCREEN_RESIZE = 'SCREEN_RESIZE';
export const COLUMN_LAYOUT_CHANGE = 'COLUMN_LAYOUT_CHANGE';
export const SCREEN_RESIZE_ZOOM_CHANGE = 'SCREEN_RESIZE_ZOOM_CHANGE';

export type ScreenResizeAction = {
  type: typeof SCREEN_RESIZE;
  payload: {
    mediaQuery: string;
    screenSize: ScreenSize;
    deviceIOS: boolean;
    deviceAndroid: boolean;
  };
};

export type ColumnLayoutChangeAction = {
  type: typeof COLUMN_LAYOUT_CHANGE;
  payload: {
    columnLayoutMediaQuery: string;
    columnLayout: ScreenSize;
  };
};

export type ScreenResizeZoomChangeAction = {
  type: typeof SCREEN_RESIZE_ZOOM_CHANGE;
  payload: {
    screenResizeZoomMediaQuery: string;
    screenZoomSize: ScreenZoomScreenSize
  }
}

/**
 * Update screen size after breakpoint is passed
 */
export const screenResize = (
  payload: ScreenResizeAction['payload']
): ScreenResizeAction => {
  return { type: SCREEN_RESIZE, payload };
};

export const columnLayoutChange = (
  payload: ColumnLayoutChangeAction['payload']
): ColumnLayoutChangeAction => {
  return { type: COLUMN_LAYOUT_CHANGE, payload };
};

export const screenResizeZoomChange = (
  payload: ScreenResizeZoomChangeAction['payload']
): ScreenResizeZoomChangeAction => {
  return {type: SCREEN_RESIZE_ZOOM_CHANGE, payload};
}

export type MatchMediaAction = ScreenResizeAction | ColumnLayoutChangeAction | ScreenResizeZoomChangeAction;
