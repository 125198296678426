import classNames from 'classnames';
import React from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import defaultTheme from '@client/css-modules/TextArea.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

export type TextAreaType = 'text' | 'email' | 'password' | 'tel';

export type TextAreaProps = {
  disabled?: boolean;
  label?: string;
  required?: boolean;
  type?: TextAreaType;
  theme: Theme;
  value?: string | number | string[] | undefined;
  className?: string;
  role?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  tabIndex?: number;
  error?: string | JSX.Element | undefined;
  multiline?: boolean;
  offScreenLabel?: boolean;
  rows?: number;
  maxLength?: number;
  readOnly?: boolean;
  errorAsValue?: string | undefined;
  style?: React.CSSProperties;
  showAccessibilityBorder?: boolean;
  ariaLabel?: string;
  ariaDescribedby?: string;
  'data-hc-name'?: string;
  'data-event-name'?: string;
  'data-parent-event-name'?: string;
};

const TextAreaRef = React.forwardRef((props: TextAreaProps, ref) => {
  const {
    error,
    value,
    theme,
    label,
    multiline,
    errorAsValue,
    rows = 1,
    offScreenLabel,
    className,
    maxLength,
    showAccessibilityBorder,
    style,
    ['data-hc-name']: dataHcName,
    ['data-event-name']: dataEventName,
    ['data-parent-event-name']: dataParentEventName,
    ...rest
  } = props;
  const { accessibilityFocusColor, textAreaBorder } = useCobrandStyles();

  return (
    <AccessibleElementUniqueId>
      {({ uid }) => (
        <div className={classNames(theme.InputWrapper, className)}>
          <div className={theme.InputElementContainer}>
            <textarea
              data-hc-name={dataHcName}
              data-event-name={dataEventName}
              data-parent-event-name={dataParentEventName}
              style={{
                ...style,
                ...(offScreenLabel ? { marginTop: 0 } : {}),
                ...(showAccessibilityBorder
                  ? { border: `2px solid ${accessibilityFocusColor}` }
                  : {}),
              }}
              id={uid}
              className={theme.InputElement}
              value={value}
              rows={rows}
              aria-multiline="true"
              ref={ref as React.RefObject<HTMLTextAreaElement>}
              {...rest}
            >
              <style
                dangerouslySetInnerHTML={{
                  __html: `
                    .${theme.InputElement}:focus {
                      border-bottom: 1px solid ${textAreaBorder}
                    }
                    .${theme.InputElement}:focus::placeholder {
                      color: ${textAreaBorder}
                    }
                  `,
                }}
              />
            </textarea>
            {label && (
              <label
                htmlFor={uid}
                className={classNames(theme.Label, {
                  [theme.OffScreenLabel]: offScreenLabel,
                })}
              >
                {label}
              </label>
            )}
            {multiline && maxLength && (
              <div className={theme.CharacterCount}>
                {`${value ? value.toString().length : 0}/${maxLength}`}
              </div>
            )}
          </div>
          {!errorAsValue && error && <div className={theme.Error}>{error}</div>}
        </div>
      )}
    </AccessibleElementUniqueId>
  );
});

type TextAreaState = {
  showAccessibilityBorder: boolean;
};

class TextArea extends React.Component<
  TextAreaProps & { forwardedRef?: React.RefObject<HTMLTextAreaElement> },
  TextAreaState
> {
  state = {
    showAccessibilityBorder: false,
  };

  handleOnBlur = (e: React.FocusEvent<HTMLTextAreaElement>): void => {
    this.setState({ showAccessibilityBorder: false });
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  handleOnFocus = (e: React.FocusEvent<HTMLTextAreaElement>): void => {
    const isFocusAllowed =
      document.body.className.indexOf('no-focus-outline') < 0;
    this.setState({ showAccessibilityBorder: isFocusAllowed });
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  render() {
    const { forwardedRef, onFocus, onBlur, ariaLabel, ariaDescribedby, ...rest } = this.props;
    const { showAccessibilityBorder } = this.state;
    return (
      <TextAreaRef
        {...rest}
        aria-label={ariaLabel}
        aria-describedby={ariaDescribedby}
        onFocus={this.handleOnFocus}
        onBlur={this.handleOnBlur}
        showAccessibilityBorder={showAccessibilityBorder}
        {...(forwardedRef ? { ref: forwardedRef } : {})}
      />
    );
  }
}

const ThemedTextArea = themr('TextArea', defaultTheme)(TextArea);
export default ThemedTextArea;
