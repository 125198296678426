import validatorInputTheme from '@client/css-modules/ValidatorInput.css';
import ErrorExclamation from '@client/inline-svgs/error-exclamation';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { debounce } from 'lodash';
import React, { CSSProperties, SyntheticEvent } from 'react';

type CancelableType<T> = {
  cancel: () => void;
  promise: Promise<CancellationResult<T>>;
};

type CancellationResult<T> = {
  isCancelled: boolean;
  reason: T;
};

type ValidatorType = {
  validating: boolean;
  error: string | null;
  dirty: boolean;
  dataHcName?: string;
  ariaDescribedby?: string;
  validationWrapperStyles?: CSSProperties;
  validateOnChange?: boolean;
};

type ValidatorState = ValidatorType & {
  valid: boolean;
  currentValue: string;
};

type ValidateResult = string | null;

type ValidateFunc<T> = (_: T) => Promise<ValidateResult>;

// creating a manual input event
type ManualInputEvent = {
  type: string;
  target: {
    [key: string]: any;
    name?: string;
    value?: any;
  };
};

type InputEvent = SyntheticEvent | ManualInputEvent;

export type ValidatorProps<T> = {
  validate: ValidateFunc<T> | ValidateFunc<T>[];
  revalidateTrigger?: string | boolean;
  onValid?: (value: T, evt: InputEvent) => void;
  onInvalid?: (message: string, evt: InputEvent) => void;
  onChange?: (evt: InputEvent) => void;
  onBlur?: (evt: React.FocusEvent<HTMLInputElement>) => void;
  debounce?: number;
  name?: string;
  value?: T;
  error?: string;
  dataHcName?: string;
  validateOnChange?: boolean;
};

const VALIDATION_DEBOUNCE = 400;

class ValidatorException extends Error {
  isCancelled: boolean;
  constructor(isCancelled, message) {
    super(message);
    this.name = 'ValidatorException';
    this.isCancelled = isCancelled;
  }
}

// Makes it possible to handle canceling and acting on promises
function makeCancelable<T>(prom: Promise<T>): CancelableType<T> {
  let isCancelled = false;
  const wrappedPromise = new Promise<CancellationResult<T>>(
    (resolve, reject) => {
      return prom
        .then((reason) => {
          return resolve({ isCancelled, reason });
        })
        .catch((err) => {
          const message = typeof err === 'string' ? err : err.message;
          return reject(new ValidatorException(isCancelled, message));
        });
    }
  );
  return {
    cancel: () => {
      isCancelled = true;
    },
    promise: wrappedPromise,
  };
}

// Validator HOC
function Validator<T>(WrappedComponent: React.ElementType) {
  class ValidatedField extends React.Component<
    ValidatorProps<T>,
    ValidatorState
  > {
    static defaultProps = {
      validate: [() => Promise.resolve(null)],
      onValid: (value?: any, e?: InputEvent) => undefined,
      onInvalid: (message?: string, evt?: InputEvent) => undefined,
      onChange: () => undefined,
      validateOnChange: false,
      debounce: VALIDATION_DEBOUNCE,
    };

    state: ValidatorState = {
      error: null,
      valid: true,
      validating: false,
      dirty: false,
      currentValue: '',
    };

    currentValidator: CancelableType<ValidateResult[]> | null;

    constructor(props: ValidatorProps<T>) {
      super(props);
      this.currentValidator = null;
    }

    componentDidMount() {
      const value = this.props.value;
      // Initialize validity
      if (value) {
        this.handleChange(null, value);
      }
    }

    componentDidUpdate(prevProps: ValidatorProps<T>) {
      if (prevProps.revalidateTrigger !== this.props.revalidateTrigger) {
        this.handleChange(null, this.state.currentValue);
      }
    }

    componentWillUnmount() {
      this.cancelCurrentValidator();
      this.currentValidator = null;
      this.handleDebounced.cancel();
    }

    cancelCurrentValidator() {
      if (this.currentValidator) {
        this.currentValidator.cancel();
      }
    }

    validateValue(value: any) {
      const validators = Array.isArray(this.props.validate)
        ? this.props.validate
        : [this.props.validate];
      const promises = validators.map((vFn) => vFn(value));
      return Promise.all(promises);
    }

    getValidatorPromise(value: any) {
      this.cancelCurrentValidator();
      this.setState({
        validating: true,
      });
      this.currentValidator = makeCancelable(this.validateValue(value));
      return this.currentValidator.promise;
    }

    handleResolve(
      value: any,
      e: InputEvent,
      { isCancelled }: { isCancelled: boolean }
    ) {
      const isValid = !!value;
      if (!isCancelled) {
        this.setState({ error: null, valid: isValid, validating: false });
        if (e && this.props.onValid) {
          this.props.onValid(value, e);
        }
      }
    }

    handleReject(
      value: any,
      e: InputEvent,
      { isCancelled, message }: ValidatorException
    ) {
      if (!isCancelled) {
        this.setState({ error: message, valid: false, validating: false });
        if (e && this.props.onInvalid) {
          this.props.onInvalid(message, e);
        }
      }
    }

    handleDebounced = debounce((value: any, e: InputEvent) => {
      // Call the promise based validator.
      this.getValidatorPromise(value)
        .then(this.handleResolve.bind(this, value, e))
        .catch(this.handleReject.bind(this, value, e));
    });

    handleChange = (e: SyntheticEvent | null, value?: any) => {
      const event = e || {
        type: 'change',
        target: { name: this.props.name, value },
      };
      if (e) {
        e.persist();
      }
      const target = event.target as HTMLInputElement;
      const updatedVal = target.value;

      if (this.props.onChange) {
        this.props.onChange(event);
      }

      this.setState({
        dirty: true,
        currentValue: updatedVal,
      });

      if (this.props.validateOnChange) {
        this.setState({
          validating: true,
        });
        this.handleDebounced(updatedVal, event);
      } else {
        if (this.props.onValid) {
          this.props.onValid(updatedVal as any, event);
        }
        this.setState({
          valid: true,
          error: null,
        });
      }
    };

    handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      const event = e || {
        type: 'change',
        target: { name: this.props.name, value: this.state.currentValue },
      };
      if (e) {
        e.persist();
      }
      if (!this.props.validateOnChange) {
        this.handleDebounced(this.state.currentValue, event);
      }
      if (this.props.onBlur) {
        this.props.onBlur(event);
      }
    };

    render() {
      const {
        onInvalid,
        validate,
        onValid,
        debounce,
        /* Provide ability to revalidate the input after something externally changes, such as the input
         * changing from required to optional */
        revalidateTrigger,
        // Need to destructure so as not to pass it to the native element.
        validateOnChange,
        ...props
      } = this.props;
      const error = this.props.error || this.state.error;
      // omit dirty and valid since we don't want to pass it to our input field.
      const { dirty, valid, currentValue, ...restOfState } = this.state;

      return (
        <WrappedComponent
          {...props}
          {...restOfState}
          error={error}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
      );
    }
  }
  return ValidatedField;
}

function ValidatorInput<T>(WrappedInput: React.ElementType) {
  const ValidatorInput = ({
    theme,
    validating,
    error,
    dataHcName,
    ariaDescribedby,
    validationWrapperStyles,
    ...props
  }: ValidatorType & { theme: Theme }) => {
    return (
      <div
        className={theme.ValidatorInput}
        data-hc-name={dataHcName}
        style={validationWrapperStyles || {}}
      >
        <WrappedInput
          {...props}
          ariaDescribedby={ariaDescribedby}
          error={
            <div id={ariaDescribedby} className={theme.ErrorAlert}>
              {error && (
                <>
                  <ErrorExclamation className={theme.ErrorIcon} />
                  <div role="alert">{error}</div>
                </>
              )}
            </div>
          }
          theme={theme}
        />
      </div>
    );
  };
  return Validator<T>(
    themr('ValidatorInputThemed', validatorInputTheme)(ValidatorInput)
  );
}

export default ValidatorInput;
