import AvmFactorBasement from '@client/inline-svgs/avm-factor-basement';
import ChaseAvmFactorBasement from '@client/inline-svgs/cobrand/chase/chase-avm-factor-basement';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return ChaseAvmFactorBasement;
    default:
      return AvmFactorBasement;
  }
};

const BasementAvmFactorCobranded: React.FC = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default BasementAvmFactorCobranded;
