import React, { Component } from 'react';
import { debounce } from 'lodash';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import defaultTheme from '@client/css-modules/StreetViewImage.css';

type Props = {
  slug: string;
  theme: Theme;
  /* This will be `null` on initial render, then populated after `handleFetchStreetViewUrl` is called */
  streetViewUrl: string | null;
  fullAddress: string;
  handleFetchStreetViewUrl: ({
    slug,
    fullAddress,
    imageHeight,
    imageWidth,
  }: {
    slug: string;
    fullAddress: string;
    imageHeight: number;
    imageWidth: number;
  }) => void;
};

/**
 * Renders a static Google Street View image
 */
class StreetViewImage extends Component<Props> {
  streetViewImageDiv: HTMLDivElement | null = null;
  debouncedResizeHandler: any = null;

  componentDidMount() {
    this.debouncedResizeHandler = debounce(this.handleResize, 300);

    if (this.debouncedResizeHandler) {
      window.addEventListener('resize', this.debouncedResizeHandler);
    }
  }

  componentWillUnmount() {
    if (this.debouncedResizeHandler) {
      window.removeEventListener('resize', this.debouncedResizeHandler);
    }
  }

  streetViewImageEleRef = (element: HTMLDivElement) => {
    this.streetViewImageDiv = element;
    this.fetchStreetViewUrlForEleDimensions(element);
  };

  handleResize = (): void => {
    if (this.streetViewImageDiv) {
      this.fetchStreetViewUrlForEleDimensions(this.streetViewImageDiv);
    }
  };

  fetchStreetViewUrlForEleDimensions = (element: HTMLElement): void => {
    const { handleFetchStreetViewUrl, fullAddress, slug } = this.props;
    if (element) {
      const { height, width } = element.getBoundingClientRect();

      handleFetchStreetViewUrl({
        slug,
        fullAddress,
        /* Certain devices return long floats for `width` and `height` values.  Need to send
         * integer values with the streetview image request */
        imageHeight: Math.round(height * 1.5),
        imageWidth: Math.round(width * 1.5),
      });
    }
  };

  render() {
    const { theme, fullAddress, streetViewUrl } = this.props;
    return (
      <div
        ref={this.streetViewImageEleRef}
        className={theme.StreetViewImage}
        role="img"
        aria-label={`Google street view image of ${fullAddress}`}
        style={
          streetViewUrl
            ? { backgroundImage: `url(${streetViewUrl})` }
            : undefined
        }
      />
    );
  }
}

const ThemedStreetViewImage = themr(
  'StreetViewImage',
  defaultTheme
)(StreetViewImage);
export default ThemedStreetViewImage;
