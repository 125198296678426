import { useSelector } from 'react-redux';
import React from 'react';
import { createPortal } from 'react-dom';

import { ModalKeyToRenderInPortal } from '@client/store/constants';
import { verifyModalPortalCreated } from '@client/store/selectors/modals.selectors';

export const getModalTargetDOMId = (modalKey: ModalKeyToRenderInPortal) =>
  `modal_${modalKey}`;

/* Used to render a modal into the <Modals /> high-level component from elsewhere using a React portal.
 * This is convenient when you want to define a modal in a particular component's JSX in order to pass props
 * into it.  If you just need props from Redux state, it's best to define the modal within the JSX of <Modals />
 *
 * Outside of render():
 * const MyModal = createModalPortal(ImportedModalComponent, 'modal-key');
 *
 * In render():
 * <MyModal isActive={this.state.isShowingModal} />
 */
export const createModalPortal = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  modalKey: ModalKeyToRenderInPortal
): React.FC<P> => {
  const ModalPortalRenderer: React.FC<P> = (props) => {
    const modalPortalId = useSelector(verifyModalPortalCreated(modalKey));
    const portalDestination =
      modalPortalId &&
      document.getElementById(getModalTargetDOMId(modalPortalId));
    /* `modalPortalId` won't exist on state until after the (high-level)
     * destination element mounts, which happens after all other components mount */
    return (
      portalDestination &&
      createPortal(<WrappedComponent {...props} />, portalDestination)
    );
  };
  return ModalPortalRenderer;
};
