export const BROKERAGE_CONTACT_SUCCESS = 'BROKERAGE_CONTACT_SUCCESS';
export const BROKERAGE_CONTACT_ERROR = 'BROKERAGE_CONTACT_ERROR';
export const CLOSE_BROKERAGE_CONTACT_MODAL = 'CLOSE_BROKERAGE_CONTACT_MODAL';

type BrokerageContactSuccess = {
  type: typeof BROKERAGE_CONTACT_SUCCESS;
};

type BrokerageContactError = {
  type: typeof BROKERAGE_CONTACT_ERROR;
};

type CloseBrokerageContact = {
  type: typeof CLOSE_BROKERAGE_CONTACT_MODAL;
};

export const brokerageContactSuccess = (): BrokerageContactSuccess => ({
  type: BROKERAGE_CONTACT_SUCCESS,
});

export const brokerageContactError = (): BrokerageContactError => ({
  type: BROKERAGE_CONTACT_ERROR,
});

export const closeBrokerageContactModal = (): CloseBrokerageContact => ({
  type: CLOSE_BROKERAGE_CONTACT_MODAL,
});

export type BrokerageInfoActions =
  | BrokerageContactSuccess
  | BrokerageContactError
  | CloseBrokerageContact;
