import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import TextInput from '@client/components/generic/TextInput';
import EmailField from '@client/components/generic/EmailField';
import TextArea from '@client/components/generic/TextArea';
import { formatPhoneInputForDisplay } from '@client/utils/string.utils';
import {
  isValidPhoneNumber,
  isValidEmailAddress,
} from '@client/utils/validations.forms';
import defaultTheme from '@client/css-modules/FormModal.css';
import PillButton from '@client/components/generic/PillButton';
import SmallModal from '@client/components/generic/SmallModal';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';

const REQUIRED_ERROR = 'Required';

/**
 * This is a generic layout-orientated component to provide a consistent form-in-modal for use
 * throughout the app.
 * - It supports 2 "pages": a form page, and a "success" page that optionally displays after the
 *   form is submitted.
 * - It supports custom form fields via a config prop and accepts optional content that's displayed
 *   above the form fields and below the form fields.
 * - It handles validating all of the form elements and allowing submitting only when the elements are valid.
 * - On submit it executes a callback, passing all form data as an argument
 *
 * Note: This component shouldn't connect to Redux state all at to retain its re-usability.
 */

/* For ease of use in implementing this component, some defaults are provided here.  These props can
 * all be passed in manually/overridden if desired. */
const DEFAULT_PROPS_FOR_FIELD_UID: { [key: string]: { [key: string]: any } } = {
  phone: { type: 'tel' },
  message: { type: 'text', showAccessibilityBorder: true, rows: 5 },
};
const DEFAULT_FORMATTER_FOR_FIELD_UID: { [key: string]: Formatter } = {
  phone: formatPhoneInputForDisplay,
};
/* Default validators for commonly used form elements. Can override by passing in a `validator` for the
 * field. No need to handle the 'required: true' case here, as it's handled in `getEffectiveValidatorForField`*/
const DEFAULT_VALIDATOR_FOR_FIELD_UID: { [key: string]: Validator } = {
  email: (value?: string) =>
    value && isValidEmailAddress(value) ? true : false,
  phone: (value?: string) =>
    value && isValidPhoneNumber(value) ? true : false,
  message: (value?: string): string | boolean =>
    !value || value.length <= 500
      ? true
      : 'Please limit your message to 500 characters',
};

type Formatter = <T>(val: T) => T;
/* Validators can have 3 types of return values:
 * true - value is valid
 * false - valid is invalid, but no error message should be displayed
 * string - valid is invalid, display the error message underneath the field */
type Validator = (val: string) => string | false | true;
type Field = {
  /* This can be any string. If default props/validators/formatters are desired, use one of the strings
   * in the defaults objects above. These are used for unique identifier purposes within this component
   * AND for identifying the entered values in the arg passed to the `handleSubmit` callback prop */
  uId: string;
  /* Currently supported field components, need to be listed explicitly for type checking. Add more if desired */
  component: typeof EmailField | typeof TextInput | typeof TextArea;
  type?: 'text' | 'email' | 'password' | 'tel';
  label: string;
  initialValue?: string | null;
  required?: boolean;
  formatter?: Formatter;
  validator?: Validator;
  customErrorMessage?: string;
  validateOnChange?: boolean;
  ['data-event-name']?: string;
  ['data-parent-event-name']?: string;
  ['data-hc-name']?: string;
};
export type FieldValuesByUId = { [key: string]: string };
export type AllowShowingErrorMessageForFieldUId = { [key: string]: boolean };

type FormFieldProps = {
  field: Field,
  value: any,
  isAllowingErrorMessageForField?: boolean,
  handleUpdateField: (fieldUId: string, value: string) => void,
  handleReportFieldInteraction: (fieldUId: string) => void,
  handleAllowShowingErrorMessageForField: (fieldUId: string) => void,
  theme: Theme,
}

type Props = {
  dataHcName?: string;
  isActive: boolean;
  /* Content to display above the form fields */
  FormPageTopContent?: JSX.Element;
  /* Form field configuration */
  fields: Field[];
  /* Content to display below the form fields */
  FormPageBottomContent?: JSX.Element;
  /* Optional content to display after a successful form submission */
  SuccessPageContent?: JSX.Element;
  /* An error message that displays at the bottom of the modal */
  APIErrorMessage?: string | null;
  modalAriaLabel: string;
  submitButtonAriaLabel: string;
  analyticsEventsByFieldUId?: { [fieldUId: string]: (() => void) | undefined };
  analyticsEventsMisc?: {
    submitFormButton?: () => void;
    closeButtonAfterSubmit?: () => void;
    closeButtonBeforeSubmit?: () => void;
  };
  handleSubmit: (fieldValuesByUId: FieldValuesByUId) => void;
  handleClose: () => void;
  theme: Theme;
  /* Click handler fired on top level parent element */
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  isBottomContentAboveCTA?: boolean;
  ctaText?: string;
};

const getEffectiveValidatorForField = (field: Field): Validator => {
  const defaultValidator = DEFAULT_VALIDATOR_FOR_FIELD_UID[field.uId];
  return (
    /* Passed-in validator */
    field.validator ||
    /* Default validator configured above.  If the field is required, display the required error if default validator returns false */
    (defaultValidator &&
      (field.required
        ? (val) => defaultValidator(val) || field.customErrorMessage || REQUIRED_ERROR
        : defaultValidator)) ||
    /* Fallback validator.  Always valid if not required, invalid if required and value is falsy */
    ((val) => (field.required ? !!val || field.customErrorMessage || REQUIRED_ERROR : true))
  );
};

const FormModalFormField: React.FC<FormFieldProps> = (
  {
    field,
    value,
    theme,
    isAllowingErrorMessageForField,
    handleUpdateField,
    handleReportFieldInteraction,
    handleAllowShowingErrorMessageForField
  }) => {

  const defaultProps = DEFAULT_PROPS_FOR_FIELD_UID[field.uId];
  const formatter =
    field.formatter ||
    DEFAULT_FORMATTER_FOR_FIELD_UID[field.uId] ||
    function <T>(val: T): T {
      return val;
    };
  const validator = getEffectiveValidatorForField(field);
  const [validatorOutput, setValidatorOutput] = useState<string | boolean>(true);

  return (
    <div className={theme.FieldWrapper} key={field.uId}>
      <field.component
        {...(defaultProps ? defaultProps : {})}
        label={field.label}
        required={field.required}
        value={value}
        type={field.type}
        onChange={(e) => {
          handleUpdateField(field.uId, formatter(e.target.value))
          if (field.validateOnChange) {
            setValidatorOutput(validator(value))
          } else {
            setValidatorOutput(true);
          }
        }
        }
        onBlur={(e) => {
          handleReportFieldInteraction(field.uId);
          handleAllowShowingErrorMessageForField(field.uId);
          if (!field.validateOnChange) {
            setValidatorOutput(validator(value))
          }
        }}
        error={
          typeof validatorOutput === 'string' &&
            isAllowingErrorMessageForField
            ? validatorOutput
            : undefined
        }
        validateOnChange={field.validateOnChange}
        data-testid={`testField--${field.uId}`}
        theme={theme}
        {...(field['data-event-name'] && {
          'data-event-name': field['data-event-name'],
        })}
        {...(field['data-parent-event-name'] && {
          'data-parent-event-name': field['data-parent-event-name'],
        })}
        {...(field['data-hc-name'] && {
          'data-hc-name': field['data-hc-name'],
        })}
      />
    </div>
  );
}

const FormModal: React.FC<Props> = ({
  isActive,
  dataHcName,
  handleSubmit,
  handleClose,
  FormPageTopContent,
  FormPageBottomContent,
  SuccessPageContent,
  APIErrorMessage,
  fields,
  modalAriaLabel,
  submitButtonAriaLabel,
  analyticsEventsByFieldUId,
  analyticsEventsMisc,
  theme,
  onClick,
  isBottomContentAboveCTA,
  ctaText,
}) => {
  const isAnchoredToBottom = useSelector(getIsSmallSize);
  const initialFieldValuesByUId = fields.reduce((mem, curr) => {
    mem[curr.uId] = curr.initialValue || '';
    return mem;
  }, {});
  const initialAllowShowingFieldErrorMessageByUId = fields.reduce(
    (mem, curr) => {
      mem[curr.uId] = false;
      return mem;
    },
    {}
  );
  const [fieldValuesByUId, setFieldValuesByUId] = useState<FieldValuesByUId>(
    initialFieldValuesByUId
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAllowingErrorMessageForField, setIsAllowingErrorMessageForField] =
    useState<AllowShowingErrorMessageForFieldUId>(
      initialAllowShowingFieldErrorMessageByUId
    );

  const areAllFieldsValid = (() => {
    return fields.every((field) => {
      const validator = getEffectiveValidatorForField(field);
      return validator(fieldValuesByUId[field.uId]) === true;
    });
  })();

  /* Whenever `isActive` becomes false (by closing the modal from within this component or its parent)
   * we need to reset the state of the modal */
  useEffect(() => {
    if (!isActive) {
      setIsSubmitted(false);
      setFieldValuesByUId(initialFieldValuesByUId);
      setIsAllowingErrorMessageForField(
        initialAllowShowingFieldErrorMessageByUId
      );
    }
  }, [isActive]);

  /* When field initial values change after rendering the modal but before opening, we need to reset
   * initial values state */
  useEffect(() => {
    if (!isActive) {
      setFieldValuesByUId(initialFieldValuesByUId);
    }
  }, [fields, isActive]);

  const handleUpdateField = (fieldUId: string, value: string): void => {
    setFieldValuesByUId({ ...fieldValuesByUId, [fieldUId]: value });
  };

  const handleReportFieldInteraction = (fieldUId: string) => {
    const event = analyticsEventsByFieldUId?.[fieldUId];
    if (event) {
      event();
    }
  };

  const handleClickModalCloseIcon = () => {
    if (isSubmitted && !APIErrorMessage) {
      if (analyticsEventsMisc?.closeButtonAfterSubmit) {
        analyticsEventsMisc.closeButtonAfterSubmit();
      }
    } else {
      if (analyticsEventsMisc?.closeButtonBeforeSubmit) {
        analyticsEventsMisc.closeButtonBeforeSubmit();
      }
    }
    handleClose();
  };

  const handleClickFormSubmitButton = () => {
    setIsSubmitted(true);
    handleSubmit(fieldValuesByUId);
    if (analyticsEventsMisc?.submitFormButton) {
      analyticsEventsMisc.submitFormButton();
    }
    if (!SuccessPageContent) {
      handleClose();
    }
  };

  const handleAllowShowingErrorMessageForField = (fieldUId: string) => {
    setIsAllowingErrorMessageForField({
      ...isAllowingErrorMessageForField,
      [fieldUId]: true,
    });
  };

  const isShowingFormPage =
    !SuccessPageContent || !isSubmitted || !!APIErrorMessage;
  const isShowingSuccessPage = !isShowingFormPage && !!SuccessPageContent;

  return (
    <SmallModal
      dataHcName={dataHcName}
      isActive={isActive}
      modalAriaLabel={modalAriaLabel}
      handleClose={handleClickModalCloseIcon}
      theme={theme}
      anchorToBottom={isAnchoredToBottom}
    >
      <div className={theme.FormModalWrapper} onClick={onClick}>
        {isShowingFormPage && (
          <div className={theme.FormPage}>
            {FormPageTopContent}
            {fields.map((field) => {
              return (
                <FormModalFormField
                  key={field.uId}
                  field={field}
                  theme={theme}
                  isAllowingErrorMessageForField={isAllowingErrorMessageForField[field.uId]}
                  value={fieldValuesByUId[field.uId]}
                  handleUpdateField={handleUpdateField}
                  handleAllowShowingErrorMessageForField={handleAllowShowingErrorMessageForField}
                  handleReportFieldInteraction={handleReportFieldInteraction}
                />
              )
            })}
            {isBottomContentAboveCTA && FormPageBottomContent}
            <div>
              <PillButton
                dataHcName="send-button"
                style={{
                  cursor: !areAllFieldsValid ? 'not-allowed' : 'pointer',
                }}
                className={theme.SubmitButton}
                disabled={!areAllFieldsValid}
                ariaLabel={submitButtonAriaLabel}
                onClick={handleClickFormSubmitButton}
                onKeyDown={onEnterOrSpaceKey(handleClickFormSubmitButton)}
                theme={theme}
                tertiary={!areAllFieldsValid}
              >
                {ctaText || 'Send'}
              </PillButton>
              {APIErrorMessage && (
                <div className={theme.APIErrorMessage}>{APIErrorMessage}</div>
              )}
            </div>
            {!isBottomContentAboveCTA && FormPageBottomContent}
          </div>
        )}
        {isShowingSuccessPage && (
          <div className={theme.SuccessPage}>{SuccessPageContent}</div>
        )}
      </div>
    </SmallModal>
  );
};

const ThemedFormModal = themr('FormModal', defaultTheme)(FormModal);
export default ThemedFormModal;
