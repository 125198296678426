import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { ReduxState } from '@client/store/types/redux-state';
import { ElementProps } from '@client/components/ScaffoldingUI/ScaffoldingUI';

export type ScaffoldingDataState = {
  hasError: boolean;
  header: {
    desktop: ElementProps | undefined;
    mobile: ElementProps | undefined;
  };
  marketingCard: {
    propertyPage: ElementProps | undefined;
    searchPage: ElementProps | undefined;
    homeownerPage: ElementProps | undefined;
    homeImprovementPage: ElementProps | undefined;
  };
};

const initialState: ScaffoldingDataState = {
  hasError: false,
  header: {
    desktop: undefined,
    mobile: undefined,
  },
  marketingCard: {
    propertyPage: undefined,
    searchPage: undefined,
    homeownerPage: undefined,
    homeImprovementPage: undefined,
  },
};

export const scaffoldingDataSlice = createSlice({
  name: 'scaffoldingData',
  initialState,
  reducers: {},
});

const getScaffoldingDataState = (state: ReduxState) => state.scaffoldingData;

export const getScaffoldingData = createSelector(
  getScaffoldingDataState,
  (scaffoldingDataState) => scaffoldingDataState
);

const getScaffoldingCMGHeaderData = createSelector(
  getScaffoldingData,
  (scaffoldingDataState) => scaffoldingDataState.header
);

export const getScaffoldingCMGHeaderDataForDesktop = createSelector(
  getScaffoldingCMGHeaderData,
  (scaffoldingCMGHeaderDataState) => scaffoldingCMGHeaderDataState.desktop
);

export const getScaffoldingCMGHeaderDataForMobile = createSelector(
  getScaffoldingCMGHeaderData,
  (scaffoldingCMGHeaderDataState) => scaffoldingCMGHeaderDataState.mobile
);

const getScaffoldingCMGMarketingCardData = createSelector(
  getScaffoldingData,
  (scaffoldingDataState) => scaffoldingDataState.marketingCard
);

export const getScaffoldingCMGMarketingCardDataForHO = createSelector(
  getScaffoldingCMGMarketingCardData,
  (scaffoldingCMGMarketingCardDataState) =>
    scaffoldingCMGMarketingCardDataState.homeownerPage
);

export const getScaffoldingCMGMarketingCardDataForHomeImprovement =
  createSelector(
    getScaffoldingCMGMarketingCardData,
    (scaffoldingCMGMarketingCardDataState) =>
      scaffoldingCMGMarketingCardDataState.homeImprovementPage
  );

export const getScaffoldingCMGMarketingCardDataForPDP = createSelector(
  getScaffoldingCMGMarketingCardData,
  (scaffoldingCMGMarketingCardDataState) =>
    scaffoldingCMGMarketingCardDataState.propertyPage
);

export const getScaffoldingCMGMarketingCardDataForSRP = createSelector(
  getScaffoldingCMGMarketingCardData,
  (scaffoldingCMGMarketingCardDataState) =>
    scaffoldingCMGMarketingCardDataState.searchPage
);

export default scaffoldingDataSlice.reducer;
