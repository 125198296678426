import { Status, STATUSES } from '@client/store/constants';
import { ReduxState } from '@client/store/types/redux-state';
import { SavedSearchAPIFields } from '@client/store/types/saved-searches';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export type LoDirectClient = {
  id?: string;
  buying_price_point?: number;
  email: string;
  first_name: string;
  is_buyer: boolean;
  last_name: string;
  loan_amount?: number;
  loan_officer_id: string | null;
  phone: string;
  slug?: string;
  full_address?: string;
  accepted_date: null | string;
};

export type LoDirectLead = {
  client: {
    buying_price_point: number;
    email: string;
    first_name: string;
    id: string;
    is_buyer: boolean;
    last_name: string;
    loan_amount: number;
    phone: string;
    slug?: string;
  };
  created: string;
  id: string;
  message: string;
  page: string | null;
  read: boolean;
  slug: string | null;
};

export type LoDirectLeadWithAddress = LoDirectLead & {
  address: {
    city: string;
    slug: string;
    state: string;
    streetAddress: string;
    unit?: string;
    zipcode: string;
  };
};

export type LoDirectIncreasedActivity = {
  ClientID: string;
  Percentage: number;
};

export type LoDirectRecentActivity = {
  ch_property_id: string;
  client_id: string;
  created: string;
  event_data: {
    saved_search_state?: SavedSearchAPIFields;
  };
  event_name: string;
  event_ts: string;
  id: string;
  loan_officer_id: string;
  source: string;
};

export type LoDirectActivity = {
  accepted_date: string;
  buying_price_point: number;
  email: string;
  first_name: string;
  id: string;
  is_buyer: boolean;
  last_name: string;
  loan_amount: number;
  phone: string;
  recent_activity: LoDirectRecentActivity[];
  slug: string;
  total_activity_count: number;
};

export type LoDirectNotificationType = {
  type: 'success' | 'error' | null;
  message?: string;
  customWidth?: number;
  customHeight?: number;
  hideDefaultIcon?: boolean;
  isTopPosition?: boolean;
  showCloseIcon?: boolean;
};

export type LoDirectState = {
  fetchClientStatus: Status;
  clientFormStatus: Status;
  leadsStatus: Status;
  clients: LoDirectClient[];
  invalid_clients: LoDirectInvalidClient[];
  fileName: string;
  originalLoanAmountMapping: { [slug: string]: number };
  leads: LoDirectLead[];
  /* "verified" means that we've checked the subscription status of the LO-user a few seconds after the LO Direct
   * experience has loaded and seen it to be "active" */
  isSubscriptionVerified: boolean;
  increasedActivity: LoDirectIncreasedActivity[];
  /* When data for each activity date-range is loaded, it's set on this object, keyed by the date-range type.
   * If the value for a date-range is undefined, that means the date-range hasn't loaded yet. */
  activity: { [type: string]: LoDirectActivity[] | undefined };
  increasedActivityStatus: Status;
  notification: LoDirectNotificationType;
};

export type LoDirectClientsUploadPayload = {
  data: Array<Omit<LoDirectClient, 'accepted_date' | 'loan_officer_id'>>;
};

export type LoDirectInvalidClient = {
  address: {
    error: string;
    value: {
      street_address: string;
      city: string;
      state: string;
      zipcode: string;
      slug: string;
      unit: string;
    };
  };
  buying_price_point: {
    error: string;
    value: number;
  };
  email: {
    error: string;
    value: string;
  };
  first_name: {
    error: string;
    value: string;
  };
  is_buyer: {
    error: string;
    value: boolean;
  };
  last_name: {
    error: string;
    value: string;
  };
  loan_amount: {
    error: string;
    value: number;
  };
  phone: {
    error: string;
    value: string;
  };
  slug: {
    error: string;
    value: string;
  };
  message: string;
};

export type LoDirectClientsUploadResponse = {
  clients: LoDirectClient[];
  invalid_clients: LoDirectInvalidClient[];
};

const INITIAL_LO_DIRECT_STATE: LoDirectState = {
  fetchClientStatus: STATUSES.INIT,
  clientFormStatus: STATUSES.INIT,
  leadsStatus: STATUSES.INIT,
  increasedActivityStatus: STATUSES.INIT,
  clients: [],
  invalid_clients: [],
  fileName: '',
  originalLoanAmountMapping: {},
  leads: [],
  isSubscriptionVerified: false,
  increasedActivity: [],
  activity: {},
  notification: {
    message: '',
    type: null,
  },
};

export const LoDirectSlice = createSlice({
  name: 'loDirect',
  initialState: INITIAL_LO_DIRECT_STATE,
  reducers: {
    fetchLoDirectClients: (state) => ({
      ...state,
      fetchClientStatus: STATUSES.LOADING,
    }),
    postSingleLoDirectClient: (
      state,
      action: PayloadAction<LoDirectClientsUploadPayload>
    ) => {
      return {
        ...state,
        clientFormStatus: STATUSES.LOADING,
      };
    },
    postBulkLoDirectClients: (
      state,
      action: PayloadAction<LoDirectClientsUploadPayload>
    ) => {
      return {
        ...state,
        clientFormStatus: STATUSES.LOADING,
      };
    },
    patchLoDirectClient: (
      state,
      action: PayloadAction<LoDirectClientsUploadPayload>
    ) => {
      return { ...state, clientFormStatus: STATUSES.LOADING };
    },
    deleteLoDirectClient: (
      state,
      action: PayloadAction<{ clientId: string }>
    ) => ({
      ...state,
      clients: state.clients.filter(
        (client) => client.id !== action.payload.clientId
      ),
    }),
    fetchLoDirectClientsSuccess: (
      state,
      action: PayloadAction<{ data: LoDirectClient[] }>
    ) => {
      return {
        ...state,
        clients: action.payload.data ?? [],
        fetchClientStatus: STATUSES.SUCCESS,
      };
    },
    postLoDirectClientsSuccess: (
      state,
      action: PayloadAction<LoDirectClientsUploadResponse>
    ) => {
      return {
        ...state,
        clients: [...state.clients, ...action.payload.clients],
        invalid_clients: action.payload.invalid_clients ?? [],
        clientFormStatus: STATUSES.SUCCESS,
      };
    },
    patchLoDirectClientSuccess: (
      state,
      action: PayloadAction<LoDirectClientsUploadResponse>
    ) => {
      return {
        ...state,
        clients: state.clients.map((client) => {
          const patchedClient = action.payload.clients[0];
          if (patchedClient.id === client.id) {
            return patchedClient;
          } else {
            return client;
          }
        }),
        invalid_clients: action.payload.invalid_clients ?? [],
        clientFormStatus: STATUSES.SUCCESS,
      };
    },
    resetInvalidClientState: (state) => {
      return {
        ...state,
        invalid_clients: [],
        clientFormStatus: STATUSES.INIT,
        fileName: '',
      };
    },
    resetClientFormStatus: (state) => {
      return {
        ...state,
        clientFormStatus: STATUSES.INIT,
      };
    },
    fetchAdditionalClientData: (state) => state,
    fetchAdditionalClientDataSuccess: (
      state,
      action: PayloadAction<
        Omit<LoDirectClientsUploadResponse, 'invalid_clients'>
      >
    ) => {
      return {
        ...state,
        clients: [...state.clients, ...action.payload.clients],
      };
    },
    deleteInvalidClientEditRow: (
      state,
      action: PayloadAction<{ rowIdx: number }>
    ) => {
      return {
        ...state,
        invalid_clients: state.invalid_clients.filter(
          (_, idx) => idx !== action.payload.rowIdx
        ),
      };
    },
    editInvalidClientEditRow: (
      state,
      action: PayloadAction<{
        rowIdx: number;
        key: string;
        val: string | number;
      }>
    ) => {
      let newInvalidClients = [...state.invalid_clients];
      const targetInvalidClient = {
        ...newInvalidClients[action.payload.rowIdx],
      };
      newInvalidClients[action.payload.rowIdx] = {
        ...targetInvalidClient,
        [action.payload.key]: {
          ...targetInvalidClient[action.payload.key],
          value: action.payload.val,
        },
      };
      return {
        ...state,
        invalid_clients: newInvalidClients,
      };
    },
    fetchCSVTemplate: (_, action: PayloadAction<{ userId: string }>) => {},
    putLODirectBulkUpload: (
      state,
      action: PayloadAction<{ file: File; userId: string }>
    ) => ({
      ...state,
      clientFormStatus: STATUSES.LOADING,
      fileName: action.payload.file.name,
    }),
    putLODirectBulkUploadError: (state) => ({
      ...state,
      clientFormStatus: STATUSES.FAILED,
    }),
    postLODirectSupportEmail: (
      _,
      action: PayloadAction<{ subject: string; message: string }>
    ) => {},
    fetchOriginalLoanAmount: (
      _,
      action: PayloadAction<{ addressId: number; slug: string }>
    ) => {},
    fetchOriginalLoanAmountSuccess: (
      state,
      action: PayloadAction<{ slug: string; loanAmount: number }>
    ) => ({
      ...state,
      originalLoanAmountMapping: {
        ...state.originalLoanAmountMapping,
        [action.payload.slug]: action.payload.loanAmount,
      },
    }),
    fetchLoDirectActivity: (
      state,
      action: PayloadAction<{ type: string; start: string; end: string }>
    ) => {},
    fetchLoDirectActivitySuccess: (
      state,
      action: PayloadAction<{ type: string; activity: LoDirectActivity[] }>
    ) => ({
      ...state,
      activityStatus: STATUSES.SUCCESS,
      activity: {
        ...state.activity,
        [action.payload.type]: action.payload.activity,
      },
    }),
    fetchLoDirectIncreasedActivity: (state) => ({
      ...state,
      increasedActivityStatus: STATUSES.LOADING,
    }),
    fetchLoDirectIncreasedActivitySuccess: (
      state,
      action: PayloadAction<LoDirectIncreasedActivity[]>
    ) => ({
      ...state,
      increasedActivityStatus: STATUSES.SUCCESS,
      increasedActivity: action.payload ?? [],
    }),
    fetchLoDirectLeads: (state) => ({
      ...state,
      leadsStatus: STATUSES.LOADING,
    }),
    fetchLoDirectLeadsSuccess: (
      state,
      action: PayloadAction<{ leads: LoDirectLead[] }>
    ) => ({
      ...state,
      leadsStatus: STATUSES.SUCCESS,
      leads: action.payload.leads,
    }),
    fetchLoDirectLeadsError: (state) => ({
      ...state,
      leadsStatus: STATUSES.ERROR,
    }),
    setLeadAsRead: (state, action: PayloadAction<{ messageId: string }>) => ({
      ...state,
      leads: state.leads.map((lead) => {
        if (lead.id === action.payload.messageId) {
          return {
            ...lead,
            read: true,
          };
        } else {
          return lead;
        }
      }),
    }),
    verifyLODirectSubscriptionIfNecessary: (_) => {},
    verifyLODirectSubscriptionSuccess: (state) => ({
      ...state,
      isSubscriptionVerified: true,
    }),
    logoutLODirectUser: () => {},
    setLoDirectNotification: (
      state,
      action: PayloadAction<LoDirectNotificationType>
    ) => ({
      ...state,
      notification: {
        type: action.payload.type,
        message: action.payload.message,
        customHeight: action.payload.customHeight,
        customWidth: action.payload.customWidth,
        showCloseIcon: action.payload.showCloseIcon,
        isTopPosition: action.payload.isTopPosition,
        hideDefaultIcon: action.payload.hideDefaultIcon,
      },
    }),
    clearLoDirectNotification: (state) => ({
      ...state,
      notification: {
        /* retain custom props to avoid jerky animation. will be overidden when setLoDirectNotification called */
        ...state.notification,
        type: null,
        message: '',
      },
    }),
  },
});

function selectLoDirectState(state: ReduxState) {
  return state.loDirect;
}
export const selectLoDirectClients = createSelector(
  selectLoDirectState,
  (loDirectState) => loDirectState.clients
);
export const selectLoDirectClientWithId = (clientId: string) =>
  createSelector(selectLoDirectState, (loDirectState) =>
    loDirectState.clients.find((client) => client.id === clientId)
  );
export const selectLoDirectInvalidClients = createSelector(
  selectLoDirectState,
  (loDirectState) => loDirectState.invalid_clients
);
export const selectLoDirectClientFormStatus = createSelector(
  selectLoDirectState,
  (loDirectState) => loDirectState.clientFormStatus
);
export const selectLoDirectBulkUploadFileName = createSelector(
  selectLoDirectState,
  (loDirectState) => loDirectState.fileName
);
export const selectOriginalLoanAmountMapping = createSelector(
  selectLoDirectState,
  (loDirectState) => loDirectState.originalLoanAmountMapping
);
export const selectLoDirectLeads = createSelector(
  selectLoDirectState,
  (loDirectState) => loDirectState.leads
);
export const selectLoDirectLeadsStatus = createSelector(
  selectLoDirectState,
  (loDirectState) => loDirectState.leadsStatus
);
export const selectIsLoDirectSubscriptionVerified = createSelector(
  selectLoDirectState,
  (loDirectState) => loDirectState.isSubscriptionVerified
);
export const selectLoDirectIncreasedActivity = createSelector(
  selectLoDirectState,
  (loDirectState) => loDirectState.increasedActivity
);
export const selectLoDirectIncreasedActivityStatus = createSelector(
  selectLoDirectState,
  (loDirectState) => loDirectState.increasedActivityStatus
);
export const selectLoDirectActivity = createSelector(
  selectLoDirectState,
  (loDirectState) => loDirectState.activity
);
export const selectFetchClientsStatus = createSelector(
  selectLoDirectState,
  (loDirectState) => loDirectState.fetchClientStatus
);
export const selectLoDirectNotification = createSelector(
  selectLoDirectState,
  (loDirectState) => loDirectState.notification
);

export const {
  fetchLoDirectClients,
  postSingleLoDirectClient,
  postBulkLoDirectClients,
  postLoDirectClientsSuccess,
  patchLoDirectClient,
  patchLoDirectClientSuccess,
  fetchLoDirectClientsSuccess,
  resetInvalidClientState,
  fetchAdditionalClientData,
  fetchAdditionalClientDataSuccess,
  deleteInvalidClientEditRow,
  editInvalidClientEditRow,
  resetClientFormStatus,
  fetchCSVTemplate,
  putLODirectBulkUpload,
  putLODirectBulkUploadError,
  postLODirectSupportEmail,
  fetchOriginalLoanAmount,
  fetchOriginalLoanAmountSuccess,
  fetchLoDirectLeads,
  fetchLoDirectLeadsSuccess,
  fetchLoDirectLeadsError,
  setLeadAsRead,
  deleteLoDirectClient,
  verifyLODirectSubscriptionIfNecessary,
  verifyLODirectSubscriptionSuccess,
  fetchLoDirectIncreasedActivity,
  fetchLoDirectIncreasedActivitySuccess,
  fetchLoDirectActivity,
  fetchLoDirectActivitySuccess,
  logoutLODirectUser,
  setLoDirectNotification,
  clearLoDirectNotification,
} = LoDirectSlice.actions;

export default LoDirectSlice.reducer;
