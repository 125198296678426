import { useDispatch } from 'react-redux';
import React from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import {
  reportClickSearchStillExploreLoanOptions,
  reportClickSearchTroubleFindingAddress,
} from '@client/store/actions/analytics.actions';
import defaultTheme from '@client/css-modules/CantFindAddressLinkChase.css';
import { Alert } from '@client/utils/reach-alert';

type Props = {
  theme: Theme;
};

/* Note: The CTA click event is handled in the parent AutoComplete component, which maps to dispatching
 * an action in autocomplete-search.container.tsx */
const CantFindAddressLinkChase = ({ theme }: Props) => {
  const dispatch = useDispatch();
  return (
    <Alert as="span" className={theme.CantFindAddressLinkChase}>
      <span
        className={theme.CantFindAddressLinkChase}
        onClick={() => {
          dispatch(reportClickSearchTroubleFindingAddress());
        }}
      >
        Having trouble finding your address?
      </span>
      <span
        className={theme.CantFindAddressLinkChase}
        onClick={() => {
          dispatch(reportClickSearchStillExploreLoanOptions());
        }}
      >
        That's ok, you can still explore loan options.
      </span>
    </Alert>
  );
};

export default themr(
  'CantFindAddressLinkChaseThemed',
  defaultTheme
)(CantFindAddressLinkChase);
