import { connect } from 'react-redux';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';

import AdBlockDetection from '@client/components/AdBlockDetection';

const mapStateToProps = (state) => {
  return {
    currentView: getCurrentView(state),
  };
};

export default connect(mapStateToProps, {})(AdBlockDetection);
