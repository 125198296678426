import { Action } from '@client/store/actions';

export type InviteState = {
  buyerEmailOptedIn: boolean;
  email: string;
  firstName: string;
  hasExistingSession: boolean;
  homeownerSlug: string;
  inviteId: string;
  inviteType: string;
  isInviteAccepted: boolean;
  lastName: string;
  lastSearch: {
    place_desc?: string;
  };
  loanOfficerId: string;
  partnerId: string;
  phone: string;
  touAcceptedDate: string | null;
};

export const INITIAL_INVITE_STATE: InviteState = {
  inviteId: '',
  inviteType: '',
  firstName: '',
  lastName: '',
  phone: '',
  partnerId: '',
  hasExistingSession: false,
  isInviteAccepted: false,
  loanOfficerId: '',
  lastSearch: {},
  homeownerSlug: '',
  email: '',
  buyerEmailOptedIn: false,
  touAcceptedDate: null,
};

export default function inviteReducer(
  state = INITIAL_INVITE_STATE,
  action: Action
): InviteState {
  return state;
}
