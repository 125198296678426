import { consumerApiClient } from '@client/services/consumer-api-client';
import { watchEvery } from '@client/utils/saga.utils';
import { call, put } from 'redux-saga/effects';
import {
  fetchAdminPortalDashboardUrl,
  fetchAdminPortalDashboardUrlError,
  fetchAdminPortalDashboardUrlSuccess,
} from '../slices/admin-portal.slice';

function* fetchCustomerPortalDashboardUrl() {
  try {
    const dashboardUrl = yield call([
      consumerApiClient,
      consumerApiClient.fetchCustomerPortalDashboardUrl,
    ]);
    yield put(fetchAdminPortalDashboardUrlSuccess(dashboardUrl));
  } catch (error) {
    yield put(fetchAdminPortalDashboardUrlError());
    throw new Error('There was an error with your request.');
  }
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [fetchAdminPortalDashboardUrl.type]: fetchCustomerPortalDashboardUrl,
  });
};
