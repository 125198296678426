import classNames from 'classnames';
import LearnMoreAside from '@client/components/LearnMoreAside';
import Tooltip from '@client/components/generic/Tooltip';
import AvmBreakdownToolTipTextChase from '@client/components/cobrand/chase/AvmBreakdownToolTipTextChase';
import { AvmBreakdownAvmDefinitionProps } from '@client/store/types/cobranded-components/avm-breakdown-avm-definition';
import theme from '@client/css-modules/AvmBreakdownAvmDefinitionChase.css';

const LearnMore = () => {
  return <span style={{ textDecoration: 'underline' }}>Learn More</span>;
};

const AvmBreakdownAvmDefinitionChase: React.FC<
  AvmBreakdownAvmDefinitionProps
> = ({ noAvmFactors, showDataDeepDives, tooltipPosition }) => {
  return (
    <div
      className={classNames(theme.HcAvmExplanation, {
        [theme.NoAvmFactors]: noAvmFactors || !showDataDeepDives,
      })}
    >
      This home value estimate is not determined by JPMorgan Chase and is the
      sole product of HouseCanary.
      {
        <LearnMoreAside theme={theme}>
          {() => (
            <Tooltip
              dataHcName="avm-definition-chase-tooltip"
              content={<AvmBreakdownToolTipTextChase />}
              position={tooltipPosition}
              trigger={<LearnMore />}
              theme={theme}
            />
          )}
        </LearnMoreAside>
      }
    </div>
  );
};

export default AvmBreakdownAvmDefinitionChase;
