/**
 * Keep top-level keys in alpha order please
 */

export default ({ slug }) => ({
  query: `query PropertyValueForecastQuery(
    $slug: String
  ) {
    propertyLookup (id: { slug: $slug}) {
      block {
       valueTsForecast {
          adjustedValue
          month
        }
        histograms {
          age (rebin: {bins: 5, method: TAILS}) {
            count
            end
            start
          }
          baths (rebin: {bins: 5, method: TAILS}) {
            count
            end
            start
          }
          beds (rebin: {bins: 5, method: TAILS}) {
            count
            end
            start
          }
          buildingArea(rebin: {bins: 5, method: TAILS}) {
            count
            end
            start
          }
        }
      }
    }
  }`,
  variables: {
    slug,
  },
});
