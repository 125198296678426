import { connect } from 'react-redux';
import AvmDeepDive from '@client/components/AvmDeepDive';
import {
  getIsAvmFactorsLoading,
  getAvmFactorsWithLocation,
  getRegressionsData,
  getTractStatsCount,
  getIsLoadingAvmDeepDiveData,
  getPropertyValuesWithRegressionTypes,
} from '@client/store/selectors/property-details.selectors';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import { getShowLocationInDataDeepDives } from '@client/store/selectors/map-legend-breaks.selectors';

/* CROSS-FUNCTIONAL: Homeowner is using the same AVM Deep Dive component, if
 * debugging or making changes that may affect them, or which they should know
 * about, please reach out to a homeowner engineer */

const mapStateToProps = (state) => {
  const showLocationInDataDeepDives = getShowLocationInDataDeepDives(state);
  const isAvmFactorsLoading = getIsAvmFactorsLoading(state);

  return {
    isAvmFactorsLoading,
    avmFactorsWithLocation: getAvmFactorsWithLocation(
      state,
      showLocationInDataDeepDives
    ),
    propertyValuesWithRegressionTypes:
      getPropertyValuesWithRegressionTypes(state),
    isLoading: getIsLoadingAvmDeepDiveData(state) || isAvmFactorsLoading,
    isSmallScreen: getIsSmallSize(state),
    regressionsData: getRegressionsData(state, showLocationInDataDeepDives),
    tractStatsCount: getTractStatsCount(state),
  };
};

export default connect(mapStateToProps, {})(AvmDeepDive);
