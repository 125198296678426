import { WatchlistItemPropertyLookupWithAddressRequired } from '@client/store/types/property';
import {
  APIWatchListItem,
  WatchListSortField,
  WatchListSortOrder,
  WatchListPropertyData,
} from '@client/store/types/watchlist';
import { AnalyticsEventAddress } from '../types/analytics';
import { PARENT_EVENTS } from '@client/store/analytics-constants';

export const FETCH_WATCHLIST = 'FETCH_WATCHLIST';
export const FETCH_WATCHLIST_ITEM = 'FETCH_WATCHLIST_ITEM';
export const FETCH_WATCHLIST_SUCCESS = 'FETCH_WATCHLIST_SUCCESS';
export const FETCH_WATCHLIST_ITEM_SUCCESS = 'FETCH_WATCHLIST_ITEM_SUCCESS';
export const FETCH_WATCHLIST_ITEM_ERROR = 'FETCH_WATCHLIST_ITEM_ERROR';
export const FETCH_WATCHLIST_ERROR = 'FETCH_WATCHLIST_ERROR';
export const ADD_TO_WATCHLIST = 'ADD_TO_WATCHLIST';
export const ENSURE_LOGIN_THEN_ADD_TO_WATCHLIST =
  'ENSURE_LOGIN_THEN_ADD_TO_WATCHLIST';
export const ADD_TO_WATCHLIST_SUCCESS = 'ADD_TO_WATCHLIST_SUCCESS';
export const REMOVE_FROM_WATCHLIST = 'REMOVE_FROM_WATCHLIST';
export const FETCH_IS_PROPERTY_IN_WATCHLIST = 'FETCH_IS_PROPERTY_IN_WATCHLIST';
export const FETCH_IS_PROPERTY_IN_WATCHLIST_SUCCESS =
  'FETCH_IS_PROPERTY_IN_WATCHLIST_SUCCESS';
export const WATCHLIST_APPLY_SORT = 'WATCHLIST_APPLY_SORT';
export const FETCH_WATCHLIST_IF_NOT_IN_STATE =
  'FETCH_WATCHLIST_IF_NOT_IN_STATE';

type FetchWatchListIfNotInStateAction = {
  type: typeof FETCH_WATCHLIST_IF_NOT_IN_STATE;
};

export const fetchWatchListIfNotInState =
  (): FetchWatchListIfNotInStateAction => ({
    type: FETCH_WATCHLIST_IF_NOT_IN_STATE,
  });

type FetchWatchListAction = {
  type: typeof FETCH_WATCHLIST;
};

export const fetchWatchList = (): FetchWatchListAction => ({
  type: FETCH_WATCHLIST,
});

type FetchWatchListItemAction = {
  type: typeof FETCH_WATCHLIST_ITEM;
  payload: {
    addressId: number;
  };
};

export const fetchWatchListItem = (
  addressId: number
): FetchWatchListItemAction => ({
  type: FETCH_WATCHLIST_ITEM,
  payload: {
    addressId,
  },
});

type FetchWatchListItemSuccessAction = {
  type: typeof FETCH_WATCHLIST_ITEM_SUCCESS;
  payload: {
    addressId: number;
    data: WatchlistItemPropertyLookupWithAddressRequired;
  };
};

export const fetchWatchListItemSuccess = (
  addressId: number,
  data: FetchWatchListItemSuccessAction['payload']['data']
): FetchWatchListItemSuccessAction => ({
  type: FETCH_WATCHLIST_ITEM_SUCCESS,
  payload: {
    addressId,
    data,
  },
});

type FetchWatchListItemErrorAction = {
  type: typeof FETCH_WATCHLIST_ITEM_ERROR;
  payload: {
    addressId: number;
    errorMessage: string;
  };
};

export const fetchWatchListItemError = (
  addressId: number,
  errorMessage: string
): FetchWatchListItemErrorAction => ({
  type: FETCH_WATCHLIST_ITEM_ERROR,
  payload: {
    addressId,
    errorMessage,
  },
});

type FetchWatchListSuccessAction = {
  type: typeof FETCH_WATCHLIST_SUCCESS;
  payload: {
    data: APIWatchListItem[];
  };
};

export const fetchWatchListSuccess = (
  data: APIWatchListItem[]
): FetchWatchListSuccessAction => ({
  type: FETCH_WATCHLIST_SUCCESS,
  payload: {
    data,
  },
});

export type AddToWatchListAction = {
  type: typeof ADD_TO_WATCHLIST;
  payload: WatchListPropertyData;
};

export const addToWatchList = (
  data: WatchListPropertyData
): AddToWatchListAction => ({
  type: ADD_TO_WATCHLIST,
  payload: {
    slug: data.slug as string,
    ...(data.street && { street: data.street }),
    ...(data.city && { city: data.city }),
    ...(data.state && { state: data.state }),
    ...(data.zip && { zip: data.zip }),
    ...(data.unit && { unit: data.unit }),
    ...(data.address_id && { address_id: data.address_id }),
  },
});

export type AddToWatchListSuccessAction = {
  type: typeof ADD_TO_WATCHLIST_SUCCESS;
  meta: {
    analytics: {
      event: 'click_watch_property_success';
      parentEvent: typeof PARENT_EVENTS.CLICK_PROPERTY_WATCHING;
      props: AnalyticsEventAddress;
    };
  };
};

export const addToWatchListSuccess = (
  data: AnalyticsEventAddress
): AddToWatchListSuccessAction => ({
  type: ADD_TO_WATCHLIST_SUCCESS,
  meta: {
    analytics: {
      event: 'click_watch_property_success',
      parentEvent: PARENT_EVENTS.CLICK_PROPERTY_WATCHING,
      props: data,
    },
  },
});

type EnsureLoginThenAddToWatchListAction = {
  type: typeof ENSURE_LOGIN_THEN_ADD_TO_WATCHLIST;
  payload: AnalyticsEventAddress;
};

export const ensureLoginThenAddToWatchList = (
  data: AnalyticsEventAddress
): EnsureLoginThenAddToWatchListAction => ({
  type: ENSURE_LOGIN_THEN_ADD_TO_WATCHLIST,
  payload: data,
});

type RemoveFromWatchListAction = {
  type: typeof REMOVE_FROM_WATCHLIST;
  payload: { slug: string };
};

export const removeFromWatchList = (
  slug: string
): RemoveFromWatchListAction => ({
  type: REMOVE_FROM_WATCHLIST,
  payload: { slug },
});

type FetchIsPropertyInWatchListAction = {
  type: typeof FETCH_IS_PROPERTY_IN_WATCHLIST;
  payload: { slug: string };
};

export const fetchIsPropertyInWatchList = (
  slug: string
): FetchIsPropertyInWatchListAction => ({
  type: FETCH_IS_PROPERTY_IN_WATCHLIST,
  payload: {
    slug,
  },
});

type FetchIsPropertyInWatchListSuccessAction = {
  type: typeof FETCH_IS_PROPERTY_IN_WATCHLIST_SUCCESS;
  payload: {
    slug: string;
    isInWatchList: boolean;
  };
};

export const fetchIsPropertyInWatchListSuccess = ({
  slug,
  isInWatchList,
}: {
  slug: string;
  isInWatchList: boolean;
}): FetchIsPropertyInWatchListSuccessAction => ({
  type: FETCH_IS_PROPERTY_IN_WATCHLIST_SUCCESS,
  payload: {
    slug,
    isInWatchList,
  },
});

type WatchListApplySortAction = {
  type: typeof WATCHLIST_APPLY_SORT;
  payload: {
    sortField: WatchListSortField;
    sortOrder: WatchListSortOrder;
  };
};

export const watchlistApplySort = (
  sortField: WatchListSortField,
  sortOrder: WatchListSortOrder
): WatchListApplySortAction => ({
  type: WATCHLIST_APPLY_SORT,
  payload: {
    sortField,
    sortOrder,
  },
});

export type WatchListAction =
  | FetchWatchListAction
  | FetchWatchListItemAction
  | FetchWatchListItemSuccessAction
  | FetchWatchListItemErrorAction
  | FetchWatchListSuccessAction
  | AddToWatchListAction
  | AddToWatchListSuccessAction
  | EnsureLoginThenAddToWatchListAction
  | RemoveFromWatchListAction
  | WatchListApplySortAction
  | FetchIsPropertyInWatchListAction
  | FetchIsPropertyInWatchListSuccessAction;
