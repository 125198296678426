import MissingLotLinesPlaceholder from '@client/components/MissingLotLinesPlaceholder/MissingLotLinesPlaceholder';
import MissingLotLinesPlaceholderChase from '@client/components/MissingLotLinesPlaceholder/MissingLotLinesPlaceholderChase';
import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { MissingLotLinesPlaceholderProps } from '@client/store/types/cobranded-components/missing-lot-lines';
import { COBRAND_IDS } from '@client/store/types/cobranding';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return MissingLotLinesPlaceholderChase;
    default:
      return MissingLotLinesPlaceholder;
  }
};

const MissingLotLinesPlaceholderCobranded: React.FC<
  MissingLotLinesPlaceholderProps
> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default MissingLotLinesPlaceholderCobranded;
