import HC_CONSTANTS from '@client/app.config';
import { APIClientError } from '@client/utils/error.utils';
import { addBreadcrumb, captureException } from '@sentry/browser';
import Cookies from 'js-cookie';
import { isEmpty, isString, omit } from 'lodash';
import { Selector } from 'react-redux';
import {
  call,
  cancelled,
  fork,
  put,
  select,
  spawn,
  take,
} from 'redux-saga/effects';

/* DO NOT import/use any selectors or actions from the main app's Redux store into this file, as it's
 * also used by the widget, which uses its own Redux store */

type HttpClientOptions = {
  fetchNewAccessTokenActionObject: { type: string };
  fetchNewAccessTokenSuccessActionString: string;
  fetchNewAccessTokenFailureActionString: string;
  getAccessTokenSelector: Selector<any, string>;
};

type RequestBody = {} | ReadableStream<Uint8Array>;

type Headers = {
  /* Note: passing an empty string as a header value causes the header key to be removed before the
   * request is sent (instead of the browser actually sending the header as an empty value).
   * This strategy can be used to remove default headers via overriding them in `makeAuthRequest` */
  [key: string]: string;
};

type RequestOptions = {
  headers: Headers;
  method: string;
  cache?:
    | 'default'
    | 'force-cache'
    | 'no-cache'
    | 'no-store'
    | 'only-if-cached'
    | 'reload';
  credentials?: 'include' | 'omit' | 'same-origin';
  signal?: AbortSignal;
};

type DeferredRequestCache = {
  [key: string]: {
    url: string;
    data?: RequestBody;
    requestOptions: RequestOptions;
    promise: ExternallyResolvablePromise<Response>;
  };
};

type CacheDeferredRequestArgs = {
  url: string;
  data?: RequestBody;
  requestOptions: RequestOptions;
  key: string;
};

interface ExternallyResolvablePromise<T> extends Promise<T> {
  resolve(response: Partial<Response>): Promise<Response>;
  reject(response: Partial<Response>): Promise<Response>;
}

/**
 * Returns a dummy promise that's externally resolvable to serve as a mechanism to delay a calling
 * API-client saga whose request returned a 401 until the request can be resent with a valid token
 * and the new response is received
 */
const getExternallyResolvablePromise =
  (): ExternallyResolvablePromise<Response> => {
    let res;
    let rej;
    const promise = new Promise<Response>((resolve, reject) => {
      res = resolve;
      rej = reject;
    });
    (promise as ExternallyResolvablePromise<Response>).resolve = (a) => {
      res(a);
      return promise;
    };
    (promise as ExternallyResolvablePromise<Response>).reject = (a) => {
      rej(a);
      return promise;
    };
    return promise as ExternallyResolvablePromise<Response>;
  };

/**
 * Helper function to extract JSON/Text.  Typically called in the respective API client
 * after receiving response.
 */
export const handleResponse = function (
  response: Response
): Promise<Response | string> {
  /* 200 OK and 201 Created and 202 Accepted */
  if (
    response.status === 200 ||
    response.status === 201 ||
    response.status === 202
  ) {
    return _responseWithContentType(response);
    /* 204 No Response */
  } else if (response.status === 204) {
    return new Promise((resolve) => {
      resolve({} as Response);
    });
  } else {
    /**
     * extract the error response sent by the server
     * this will be caught in the respective caller/saga file
     */
    return _responseWithContentType(response).then((errorResponse) => {
      const errorMessage =
        (errorResponse as any).message && (errorResponse as any).status
          ? `${response.status} ${(errorResponse as Response).status}: ${
              (errorResponse as any).message
            }`
          : (errorResponse as any).status
          ? `${response.status} ${(errorResponse as Response).status}`
          : response.status;

      throw new APIClientError(String(errorMessage).split('\n')[0], {
        statusCode: response.status,
        statusText: (errorResponse as any).status,
        messageRaw: (errorResponse as any).error,
        requestUrl: response.url,
        requestBody: response.body,
        responseJSON: errorResponse,
      });
    });
  }
};

/**
 * Check content type header and return either JSON or plain text
 */
const _responseWithContentType = function (
  response: Response
): Promise<Response | string> {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.includes('application/json')) {
    return response.json();
  } else {
    return response.text();
  }
};

/** Want to know more about how our authentication works?
 * Refer - https://housecanary.atlassian.net/wiki/spaces/CON/pages/679510027/ComeHome+API+Authentication
 */
export class HttpClient {
  constructor(options: HttpClientOptions) {
    /* Making these dynamic allows this http client to be used for both the widget and the main app
     * (which have different Redux stores) */
    this.fetchNewAccessTokenActionObject =
      options.fetchNewAccessTokenActionObject;
    this.fetchNewAccessTokenSuccessActionString =
      options.fetchNewAccessTokenSuccessActionString;
    this.fetchNewAccessTokenFailureActionString =
      options.fetchNewAccessTokenFailureActionString;
    this.getAccessTokenSelector = options.getAccessTokenSelector;
  }

  deferredRequestCache: DeferredRequestCache = {};
  fetchNewAccessTokenActionObject: HttpClientOptions['fetchNewAccessTokenActionObject'];
  fetchNewAccessTokenSuccessActionString: HttpClientOptions['fetchNewAccessTokenSuccessActionString'];
  fetchNewAccessTokenFailureActionString: HttpClientOptions['fetchNewAccessTokenFailureActionString'];
  getAccessTokenSelector: HttpClientOptions['getAccessTokenSelector'];

  /**
   * Returns a new deferred promise after adding it to a cache
   */
  cacheDeferredRequest = ({
    url,
    data,
    requestOptions,
    key,
  }: CacheDeferredRequestArgs): ExternallyResolvablePromise<Response> => {
    const promise = getExternallyResolvablePromise();
    this.deferredRequestCache[key] = { url, data, requestOptions, promise };
    return promise;
  };

  /**
   * If there are items in the cache, we're still waiting on the new token to be received
   * and therefore we should continue to defer incoming requests.  When the new token is
   * received, we clear this cache and allow incoming requests to go out as normal.
   */
  getAreDeferredRequestsPending = (): boolean =>
    Object.keys(this.deferredRequestCache).length > 0;

  /**
   * Execute basic fetch
   */
  handleFetch({
    url,
    data,
    requestOptions,
  }: {
    url: string;
    data?: RequestBody;
    requestOptions: Partial<RequestOptions> & { method: string };
  }): Promise<Response> {
    const dataIsFormData = data instanceof FormData;
    const options =
      !dataIsFormData && (!data || isEmpty(data))
        ? { ...requestOptions }
        : {
            ...requestOptions,
            body: !dataIsFormData ? JSON.stringify(data) : data,
          };
    return window.fetch(url, options);
  }

  /**
   * Execute the fetch, deferring certain requests until we've received a valid access token
   */
  handleFetchAndAccountFor401({
    url,
    data,
    requestOptions,
    needsValidToken,
  }: {
    url: string;
    data?: RequestBody;
    requestOptions: RequestOptions;
    needsValidToken?: boolean;
  }): Promise<
    | Response
    | {
        key: string;
        deferredPromise: ExternallyResolvablePromise<Response>;
        originalResponse: Response;
      }
  > {
    const dataIsFormData = data instanceof FormData;
    const options =
      !dataIsFormData && (!data || isEmpty(data))
        ? { ...requestOptions }
        : {
            ...requestOptions,
            body: !dataIsFormData ? JSON.stringify(data) : data,
          };
    const key = `${Date.now()}`;

    /* Remove header keys with empty string values.  This provides a way to remove default headers via
     * overriding them in `makeAuthRequest` */
    if (options.headers) {
      const keys = Object.keys(options.headers);
      keys.forEach((key) => {
        if (options.headers[key] === '') {
          delete options.headers[key];
        }
      });
    }

    /* If there are 1 or more deferred requests pending this means we're waiting on a new token,
     * and should defer this request as well */
    if (this.getAreDeferredRequestsPending() && needsValidToken) {
      return this.cacheDeferredRequest({ url, data, requestOptions, key });
      /* If not, do the fetch as normal */
    } else {
      addBreadcrumb({
        category: 'fetch',
        level: 'info',
        message: 'http-client fetch request',
        data: {
          url,
          method: requestOptions.method,
          ...((options as any).body
            ? {
                body: JSON.stringify((options as any).body),
              }
            : {}),
        },
      });
      return window
        .fetch(url, options)
        .then((response) => {
          addBreadcrumb({
            category: 'fetch',
            level: 'info',
            message: 'http-client fetch response',
            data: {
              url,
              method: requestOptions.method,
              status_code: response.status,
              reason: response.statusText,
            },
          });
          if (response.status === 401) {
            /* We've already requested a new token and will re-send requests after its received;
             * simply return the deferred promise */
            if (this.getAreDeferredRequestsPending()) {
              return this.cacheDeferredRequest({
                url,
                data,
                requestOptions,
                key,
              });
              /* The first 401 response has been received */
            } else {
              /* Cause the calling saga to resume execution, deferring all subsequent incoming requests
               * until after a new token is received */
              return {
                key,
                deferredPromise: this.cacheDeferredRequest({
                  url,
                  data,
                  requestOptions,
                  key,
                }),
                originalResponse: response,
              };
            }
            /* If successful */
          } else {
            return response;
          }
        }) /* window.fetch catch() will only be called when a network error occurs */
        .catch((err: unknown) => {
          captureException(err);
          return Promise.reject(err);
        });
    }
  }

  /**
   * Re-send a previously failed request, now with a newly received access token
   */
  *resendUnauthorizedRequest({
    url,
    data,
    requestOptions,
    promise,
  }: {
    url: string;
    data?: RequestBody;
    requestOptions: RequestOptions;
    promise: ExternallyResolvablePromise<Response>;
  }) {
    const accessToken = yield select(this.getAccessTokenSelector);
    const newRequestOptions = {
      ...requestOptions,
      headers: {
        ...requestOptions.headers,
        ...(requestOptions.headers['Authorization']
          ? { Authorization: `Bearer ${accessToken}` }
          : {}),
        ...(requestOptions.headers['Hc-Api-Auth']
          ? { 'Hc-Api-Auth': `Bearer ${accessToken}` }
          : {}),
      },
    };
    const response = yield call([this, this.handleFetchAndAccountFor401], {
      url,
      data,
      requestOptions: newRequestOptions,
    });
    /* This should never happen, but if the re-request with the new token also returns a 401,
     * forward the response to be handled by the callee */
    if (response.deferredPromise) {
      /* Remove from the request cache so that future requests don't hang waiting for it */
      delete this.deferredRequestCache[response.key];
      /* Allow the calling saga to handle the error */
      promise.resolve(response.originalResponse);
      /* Cause the original calling API-client saga to resume execution - we're done! */
    } else {
      promise.resolve(response);
    }
  }

  /**
   * When fetching a new access token fails, this means that the user was authenticated and
   * their (in-cookie) refresh token has expired.  A different saga handles redirecting them
   * the login page, but we still need to handle cleaning up the deferred promises here
   */
  *rejectDeferredPromisesOnRefreshCookieFail() {
    yield take(this.fetchNewAccessTokenFailureActionString);

    const requestKeys = Object.keys(this.deferredRequestCache);
    for (let i = 0; i < requestKeys.length; i++) {
      const { promise } = this.deferredRequestCache[requestKeys[i]];
      promise.reject({
        status: 401,
        statusText: 'Unauthorized, must log in again',
      });
    }
    /* Clear so that new incoming requests do NOT get deferred */
    this.deferredRequestCache = {};
  }

  /**
   * Fetch a new access token and re-make previously deferring requests with new token
   */
  *fetchNewTokenAndResendRequests() {
    yield put(this.fetchNewAccessTokenActionObject);
    yield call(
      [console, console.log],
      'Previous token expired, fetching new access token'
    );

    yield spawn([this, this.rejectDeferredPromisesOnRefreshCookieFail]);
    yield take(this.fetchNewAccessTokenSuccessActionString);
    yield call(
      [console, console.log],
      'New access token received, resending prior requests'
    );

    const requestsToResend = { ...this.deferredRequestCache };
    /* Clear so that the below re-requests and new incoming requests do NOT get deferred */
    this.deferredRequestCache = {};

    /* Re-send requests and resolve promises for those requests that either:
     * - were the first request with a 401 response
     * - came back with a 401 response after the first 401
     * - never went out at all due to waiting on the new token after the first 401 */
    const requestKeys = Object.keys(requestsToResend);
    for (let i = 0; i < requestKeys.length; i++) {
      const { url, data, requestOptions, promise } =
        requestsToResend[requestKeys[i]];
      yield fork([this, this.resendUnauthorizedRequest], {
        url,
        data,
        requestOptions,
        promise,
      });
    }
  }

  /**
   * Execute the fetch request and handle re-authenticating if a request fails with a 401
   * @returns {Promise}
   */
  *handleFetchAndReAuth({
    url,
    data = {},
    requestOptions,
    needsValidToken,
  }: {
    url: string;
    data?: RequestBody;
    requestOptions: RequestOptions;
    needsValidToken?: boolean;
  }) {
    /* If the requests are being deferred due to a previous 401 response, a promise is returned
     * from `handleFetchAndAccountFor401` that isn't resolved until a new token is fetched and the
     * request is resent and re-received */
    const response = yield call([this, this.handleFetchAndAccountFor401], {
      url,
      data,
      requestOptions,
      needsValidToken,
    });

    /* If request returned successfully, cause the original calling API-client saga to resume execution */
    if (!response.deferredPromise) {
      return response;
      /* If the first 401 response has been received, fetch new access token then re-send other requests
       * that were deferred while waiting on the new token */
    } else {
      yield fork([this, this.fetchNewTokenAndResendRequests]);
      /* Return a promise so that the calling saga is suspended until its fetch has completed after getting
       * the new access token */
      return yield response.deferredPromise;
    }
  }

  /**
   * Http fetch request with added headers required for authentication
   * @returns {Promise}
   */
  *makeAuthRequest({
    url,
    data = {},
    requestOptions,
  }: {
    url: string;
    data?: RequestBody;
    requestOptions: Partial<RequestOptions> & { method: string };
  }) {
    const accessToken = yield select(this.getAccessTokenSelector);
    const effectiveRequestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'device-id': Cookies.get('hcid') as string,
        'X-platform': navigator.userAgent,
        'X-SiteID': window.location.hostname.split('.', 1)[0],
        ...(requestOptions?.headers || {}),
      },
      cache: 'reload' as 'reload',
      credentials: 'include' as 'include',
      ...omit(requestOptions, 'headers'),
    };
    return yield call([this, this.handleFetchAndReAuth], {
      url,
      data,
      requestOptions: effectiveRequestOptions,
      needsValidToken: true,
    });
  }

  *makeAdminPortalAuthRequest({
    url,
    requestOptions,
    accessToken,
  }: {
    url: string;
    requestOptions: Partial<RequestOptions> & { method: string };
    accessToken;
  }) {
    return yield call([this, this.handleFetchAndReAuth], {
      url,
      requestOptions: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'device-id': Cookies.get('hcid') as string,
          'X-platform': navigator.userAgent,
          ...(requestOptions?.headers || {}),
        },
        cache: 'reload' as 'reload',
        credentials: 'include' as 'include',
        ...omit(requestOptions, 'headers'),
      },
      needsValidToken: true,
    });
  }

  /**
   * Http fetch request with property-graph specific headers and the ability to cancel the request
   * via canceling a forked saga
   * @returns {Promise}
   */
  *makeGraphQLRequest({
    url,
    query,
    requestOptions = {},
  }: {
    url?: string;
    query?: RequestBody;
    requestOptions?: Partial<RequestOptions>;
  }) {
    const accessToken = yield select(this.getAccessTokenSelector);
    let controller = new window.AbortController();
    let signal = controller.signal;
    if (isString(query)) {
      query = { query };
    }
    const graphQLUrl = url || HC_CONSTANTS.GRAPH_QL_URL;
    const effectiveRequestOptions = {
      signal,
      headers: {
        'Hc-Api-Auth': `Bearer ${accessToken}`,
        'X-Graph-Profile': 'consumer',
        'Content-Type': 'application/json',
        ...(requestOptions.headers || {}),
      },
      method: 'POST',
      cache: 'reload' as 'reload',
      ...omit(requestOptions, 'headers'),
    };

    try {
      return yield call([this, this.handleFetchAndReAuth], {
        url: graphQLUrl,
        data: query,
        requestOptions: effectiveRequestOptions,
        needsValidToken: true,
      });
    } finally {
      if (yield cancelled()) {
        controller.abort();
      }
    }
  }
}
