import { call, put } from 'redux-saga/effects';

import HC_CONSTANTS from '@client/app.config';
import { handleResponse, HttpClient } from '@client/services/http-client';
import {
  fetchNewAccessToken,
  FETCH_NEW_ACCESS_TOKEN_FAILURE,
  FETCH_NEW_ACCESS_TOKEN_SUCCESS,
} from '@client/store/actions/auth.actions';
import { fetchHomeownerManageSingleHomeSuccess } from '@client/store/actions/homeowner.actions';
import alertCardMlsDetailsQuery from '@client/store/sagas/queries/alert-card-mls-details.query';
import avmDeepDiveDataQuery from '@client/store/sagas/queries/avm-deep-dive-data.query';
import avmFactorsQuery from '@client/store/sagas/queries/avm-factors.query';
import choProFinderQuery from '@client/store/sagas/queries/cho-profinder.query';
import geocodeQuery from '@client/store/sagas/queries/geocode.query';
import homeownerHomeImprovementsQuery from '@client/store/sagas/queries/homeowner-home-improvements.query';
import homeownerManageHomeCardData from '@client/store/sagas/queries/homeowner-manage-home-card.query';
import homeownerPlaceSearchQuery from '@client/store/sagas/queries/homeowner-place-search.query';
import homeownerInitQuery from '@client/store/sagas/queries/homeowner-property-details-init.query';
import loDirectClientsQuery from '@client/store/sagas/queries/lo-direct-clients.query';
import loanDetailsInitQuery from '@client/store/sagas/queries/loan-details-init.query';
import localActivitiesQuery from '@client/store/sagas/queries/local-activities.query';
import placeLookupQuery from '@client/store/sagas/queries/place-lookup.query';
import placeSearchQuery from '@client/store/sagas/queries/place-search.query';
import fullPropertyDetailsQuery from '@client/store/sagas/queries/property-details-full.query';
import propertyDetailsInitQuery from '@client/store/sagas/queries/property-details-init.query';
import watchListPropDetailsQuery from '@client/store/sagas/queries/property-details-watchlist-item.query';
import propertyPhotosQuery from '@client/store/sagas/queries/property-photos.query';
import propertySchoolsQuery from '@client/store/sagas/queries/property-schools.query';
import propertyValueForecastQuery from '@client/store/sagas/queries/property-value-forecast.query';
import slugForAddressId from '@client/store/sagas/queries/slug-for-address-id.query';
import spatialSearchListPropertiesQuery from '@client/store/sagas/queries/spatial-search-list-properties.query';
import spatialSearchMapPropertiesQuery from '@client/store/sagas/queries/spatial-search-map-properties.query';
import spatialSearchPropertyCountQuery from '@client/store/sagas/queries/spatial-search-property-count.query';
import {
  ImageSize,
  PlaceLookupQuery,
  PlaceSearchQuery,
  PropertyDetailsForWatchlistItemQuery,
  PropertyDetailsFullQuery,
  PropertyDetailsInitQuery,
  PropertyDetailsPhotosQuery,
} from '@client/store/sagas/queries/types';
import { validatePropertyDataOrThrowError } from '@client/store/sagas/validate-property.saga';
import { getAccessToken } from '@client/store/selectors/auth.selectors';
import { PDP_CAROUSEL_PHOTO_SIZE } from '@client/store/types/property';

const httpClient = new HttpClient({
  fetchNewAccessTokenActionObject: fetchNewAccessToken(),
  fetchNewAccessTokenSuccessActionString: FETCH_NEW_ACCESS_TOKEN_SUCCESS,
  fetchNewAccessTokenFailureActionString: FETCH_NEW_ACCESS_TOKEN_FAILURE,
  getAccessTokenSelector: getAccessToken,
});

class GraphQLApiClient {
  *getAlertCardMlsDetails(slug) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: alertCardMlsDetailsQuery({ slug }),
      }
    );
    const { data } = yield call(handleResponse, fullResponse);
    return data;
  }

  *getTestProperties() {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        url: `${HC_CONSTANTS.PROPERTY_GRAPH_URL}/internal/test_properties_list`,
      }
    );
    const data = yield call(handleResponse, fullResponse);
    return data;
  }

  *getLocalActivities({ latitude, longitude, slug }) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: localActivitiesQuery({ latitude, longitude, slug }),
      }
    );
    const { data } = yield call(handleResponse, fullResponse);
    return data;
  }

  *getWatchListItemPropertyDetails(addressId: string) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: watchListPropDetailsQuery(addressId),
      }
    );
    const { data, errors } = yield call(handleResponse, fullResponse);
    const propertyLookup = (data as PropertyDetailsForWatchlistItemQuery)
      .propertyLookup;
    const validatedProperty = yield call(
      validatePropertyDataOrThrowError,
      addressId,
      propertyLookup,
      false,
      errors
    );
    return validatedProperty;
  }

  *getGeocode({ latitude, longitude }) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: geocodeQuery({ latitude, longitude }),
      }
    );
    const { data } = yield call(handleResponse, fullResponse);
    return data;
  }

  *getPropertyDetailsInit({ slug }) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: propertyDetailsInitQuery({
          slug,
          photoSize: PDP_CAROUSEL_PHOTO_SIZE,
        }),
      }
    );
    const { data, errors } = yield call(handleResponse, fullResponse);
    const propertyLookup = (data as PropertyDetailsInitQuery).propertyLookup;
    const validatedProperty = yield call(
      validatePropertyDataOrThrowError,
      slug,
      propertyLookup,
      true,
      errors
    );
    return validatedProperty;
  }

  *getCHOProFinderData({ slug }) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: choProFinderQuery({
          slug,
        }),
      }
    );
    const { data } = yield call(handleResponse, fullResponse);
    return data;
  }

  *getPropertyPhotos({ slug, size }: { slug: string; size: ImageSize }) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: propertyPhotosQuery({
          slug,
          size,
        }),
      }
    );
    const { data } = yield call(handleResponse, fullResponse);
    return data as PropertyDetailsPhotosQuery;
  }

  *getFullPropertyDetails(
    slug,
    dateFiveYearsAgo,
    dateOneYearAhead,
    dateThreeYearsAhead
  ) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: fullPropertyDetailsQuery({
          slug,
          photoSize: PDP_CAROUSEL_PHOTO_SIZE,
          dateFiveYearsAgo,
          dateOneYearAhead,
          dateThreeYearsAhead,
        }),
      }
    );
    const { data, errors } = yield call(handleResponse, fullResponse);
    const propertyLookup = (data as PropertyDetailsFullQuery).propertyLookup;
    const validatedProperty = yield call(
      validatePropertyDataOrThrowError,
      slug,
      propertyLookup,
      true,
      errors
    );
    return validatedProperty;
  }

  *getAvmFactors(addressSlug) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: avmFactorsQuery(addressSlug),
      }
    );
    const { data } = yield call(handleResponse, fullResponse);
    return data;
  }

  *getAvmDeepDiveData(addressSlug) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: avmDeepDiveDataQuery(addressSlug),
      }
    );
    const { data } = yield call(handleResponse, fullResponse);
    return data;
  }

  *getPlaceLookupDetails({
    placeId,
    sessionToken,
    placeSlug,
  }: {
    placeId?: string;
    sessionToken?: string;
    placeSlug?: string;
  }) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: placeLookupQuery({ placeId, sessionToken, placeSlug }),
      }
    );
    const { data } = yield call(handleResponse, fullResponse);
    return data as PlaceLookupQuery;
  }

  *getPlaceSearchDetails({
    term,
    buildingId,
    sessionToken,
    latitude,
    longitude,
    groupResultsByBuilding,
  }: {
    term: string;
    buildingId?: string;
    sessionToken?: string;
    latitude?: number;
    longitude?: number;
    groupResultsByBuilding?: boolean;
  }) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: placeSearchQuery({
          term,
          buildingId,
          sessionToken,
          latitude,
          longitude,
          addressLookupType:
            groupResultsByBuilding && !buildingId
              ? 'BUILDING_LOOKUP'
              : 'LOOKUP',
        }),
      }
    );
    const { data } = yield call(handleResponse, fullResponse);
    return data as PlaceSearchQuery;
  }

  *getSlugForAddressId(addressId) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: slugForAddressId({ addressId }),
      }
    );
    const { data } = yield call(handleResponse, fullResponse);
    return data;
  }

  *getSpatialSearchMapData({ spatialId, filtersMapping, limit }) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: spatialSearchMapPropertiesQuery({
          spatialId,
          filtersMapping,
          limit,
        }),
      }
    );
    const { data, errors } = yield call(handleResponse, fullResponse);
    return { data, errors };
  }

  *getSpatialSearchListProperties({
    includeRentalAvm,
    spatialId,
    filtersMapping,
    limit,
    cursor,
    sort,
  }) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: spatialSearchListPropertiesQuery({
          includeRentalAvm,
          spatialId,
          filtersMapping,
          limit,
          cursor,
          sort,
        }),
      }
    );
    const { data, errors } = yield call(handleResponse, fullResponse);
    return { data, errors };
  }

  *getPropertySchoolsData(slug) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: propertySchoolsQuery({ slug }),
      }
    );
    const { data } = yield call(handleResponse, fullResponse);
    return data;
  }

  *getHomeownerPropDetailsData({ slug, zipHpiStart, zipHpiEnd }) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: homeownerInitQuery({ slug, zipHpiStart, zipHpiEnd }),
      }
    );
    const { data, errors } = yield call(handleResponse, fullResponse);
    const propertyLookup = data?.propertyLookup;
    const validatedProperty = yield call(
      validatePropertyDataOrThrowError,
      slug,
      propertyLookup,
      true,
      errors
    );
    return validatedProperty;
  }

  *getLoanDetailsInitData({ slug }) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: loanDetailsInitQuery({ slug }),
      }
    );
    const { data } = yield call(handleResponse, fullResponse);
    return data;
  }

  *getHomeownerPlaceSearchData(params) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: homeownerPlaceSearchQuery({ params }),
      }
    );
    const { data } = yield call(handleResponse, fullResponse);
    return data;
  }

  *getHomeownerManageHomeCardData({ slug, zipHpiStart, zipHpiEnd }) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: homeownerManageHomeCardData({
          slug,
          zipHpiStart,
          zipHpiEnd,
        }),
      }
    );
    const { data } = yield call(handleResponse, fullResponse);
    yield put(fetchHomeownerManageSingleHomeSuccess(data));
    return data;
  }

  *getHomeownerHomeImprovements(
    slug,
    bedsAdditionalSqFt,
    bathAdditionalSqFt,
    squareFootageAdditionalSqFt
  ) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: homeownerHomeImprovementsQuery({
          slug,
          bedsAdditionalSqFt,
          bathAdditionalSqFt,
          squareFootageAdditionalSqFt,
        }),
      }
    );
    const { data } = yield call(handleResponse, fullResponse);
    return data;
  }

  *getPropertyValueForecastData(slug) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: propertyValueForecastQuery({ slug }),
      }
    );
    const { data } = yield call(handleResponse, fullResponse);
    return data;
  }

  *getSpatialSearchPropertyCount({ spatialId, filtersMapping }) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: spatialSearchPropertyCountQuery({ spatialId, filtersMapping }),
      }
    );
    const { data } = yield call(handleResponse, fullResponse);
    return data;
  }

  *getLoDirectClientData({ slug }) {
    const fullResponse = yield call(
      [httpClient, httpClient.makeGraphQLRequest],
      {
        query: loDirectClientsQuery({
          slug,
        }),
      }
    );
    const { data } = yield call(handleResponse, fullResponse);
    return data;
  }
}

export const graphQLApiClient = new GraphQLApiClient();
