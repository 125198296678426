import { PartialDeep } from 'type-fest';
import {
  MlsState,
  ListingStatus,
  Photo,
} from '@client/store/sagas/queries/types';
import { LatLngObject } from '@client/store/types/maps';
import HC_CONSTANTS from '@client/app.config';
import { getIsActiveListing } from '@client/utils/property.utils';

const { MAPBOX_STATIC_TILES } = HC_CONSTANTS;
const {
  DOMAIN,
  FULL_FEATURED_MAP_ID,
  TOKEN,
  USERNAME,
  MAP_ID,
  OFF_MARKET_PIN_URL,
} = MAPBOX_STATIC_TILES;

/**
 * Get a map tile image for use as placeholder when no other images exist
 */
export const getMapTilePhoto = ({
  latitude,
  longitude,
  propertyStatus,
  activePinUrl,
  size,
  isHidingPin = false,
  zoom,
}: {
  latitude: number;
  longitude: number;
  propertyStatus: MlsState | ListingStatus | null;
  activePinUrl: string;
  size?: [number, number];
  isHidingPin?: boolean;
  zoom?: number;
}): string => {
  const isActiveListing = getIsActiveListing(propertyStatus);
  const pinUrl = encodeURIComponent(
    isActiveListing ? activePinUrl : OFF_MARKET_PIN_URL
  );
  const effectiveSize = size || [300, 300];
  const effectiveSizeString = effectiveSize.join('x');
  const url = `${DOMAIN}/styles/v1/${USERNAME}/${MAP_ID}/static${
    !isHidingPin ? `/url-${pinUrl}(${longitude},${latitude})` : ''
  }/${longitude},${latitude},${
    zoom || 14.25
  }/${effectiveSizeString}@2x?logo=false&access_token=${TOKEN}`;
  /* Replace parentheses, which causes inline background-image url() definition to fail */
  return url.replace(/\(/g, '%28').replace(/\)/g, '%29');
};

export const getFullFeatureMapTilePhoto = ({
  center,
  size,
  zoom,
}: {
  center: LatLngObject;
  size: [number, number];
  zoom: number;
}): string => {
  const { lat: latitude, lng: longitude } = center;
  const effectiveSize = size || [300, 300];
  const effectiveSizeString = effectiveSize.join('x');
  const url = `${DOMAIN}/styles/v1/${USERNAME}/${FULL_FEATURED_MAP_ID}/static/${longitude},${latitude},${zoom}/${effectiveSizeString}@2x?logo=false&access_token=${TOKEN}`;
  /* Replace parentheses, which causes inline background-image url() definition to fail */
  return url.replace(/\(/g, '%28').replace(/\)/g, '%29');
};

/**
 * Map array of photos objects to array of photo URLs
 */
export const getPhotoUrlsFromPhotos = (
  photos?: PartialDeep<Photo | undefined>[] | null
): string[] =>
  photos ? photos.map((photoObj) => photoObj?.representation?.httpUrl!) : [];
