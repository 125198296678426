import { LenderCTAsAreas } from '@client/store/types/analytics';
import { Theme } from '@friendsofreactjs/react-css-themr';

export const CARD_TYPES = {
  FULL: 'FULL',
  SMALL: 'SMALL',
  BOTH: 'BOTH',
};

type CardType = (typeof CARD_TYPES)[keyof typeof CARD_TYPES];

export interface LenderCTAProps {
  theme?: Theme;
  cardType: CardType;
  area: Exclude<LenderCTAsAreas, 'pdp' | 'ho_alt'>;
  ordinal?: number;
}
