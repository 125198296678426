import { routeChange, ROUTER_ERROR } from '@src/redux-saga-router-plus/actions';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import { isEmpty } from 'lodash';
import { call, delay, put, select } from 'redux-saga/effects';
import * as Sentry from 'sentry-isomorphic';

import HC_CONSTANTS from '@client/app.config';
import { ALL_SEARCH_VIEWS, View } from '@client/routes/constants';
import { authClient } from '@client/services/consumer-api-auth-client';
import { consumerApiClient } from '@client/services/consumer-api-client';
import { reportEvent } from '@client/store/actions/analytics.actions';
import {
  clearAttemptedRoute,
  setAttemptedRoute,
} from '@client/store/actions/attempted-route.actions';
import {
  acceptTermsAndConditionsSuccess,
  ACCEPT_TERMS_AND_CONDITIONS,
  authModalHide,
  authModalShow,
  clearUserSignUpError,
  confirmNewUserError,
  confirmNewUserExpiredToken,
  confirmNewUserSuccess,
  CONFIRM_NEW_USER,
  CreateLODirectClientFromInviteAction,
  CreateUserAction,
  createUserError,
  createUserSuccess,
  createUserValidationErrors,
  CREATE_LO_DIRECT_CLIENT_FROM_INVITE,
  CREATE_USER,
  EnsureLoggedInThenAction,
  ENSURE_LOGGED_IN_THEN_ACTION,
  fetchNewAccessTokenFailure,
  fetchNewAccessTokenSuccess,
  fetchUserProfileError,
  FETCH_NEW_ACCESS_TOKEN,
  FETCH_USER_PROFILE,
  LOGIN,
  loginError,
  LoginOAuthOIDCAction,
  loginSuccess,
  LOGIN_OAUTH_OIDC,
  LOGOUT,
  logoutSuccess,
  LogOutThenRedirectAction,
  LOGOUT_THEN_REDIRECT,
  LOGOUT_WITHOUT_REDIRECT,
  REGEN_COOKIE,
  ResendConfirmUserEmailAction,
  resendConfirmUserEmailFailure,
  resendConfirmUserEmailSuccess,
  RESEND_CONFIRM_USER_EMAIL,
  resetAuthDependentState,
  SelfCreateLODirectClientAction,
  SELF_CREATE_LO_DIRECT_CLIENT,
  SubmitForgotPasswordAction,
  submitForgotPasswordError,
  submitForgotPasswordSuccess,
  submitPasswordResetSuccess,
  SUBMIT_FORGOT_PASSWORD,
  SUBMIT_PASSWORD_RESET_THEN_REDIRECT,
  UpdateUserEmailAction,
  updateUserEmailError,
  updateUserEmailSuccess,
  updateUserInfoOnState,
  UpdateUserProfileAction,
  updateUserProfileError,
  updateUserProfileSuccess,
  UPDATE_USER_EMAIL,
  UPDATE_USER_PROFILE,
  URQL_INITIATED_UNAUTHORIZED_LOGOUT,
  verifyResetPasswordFailure,
} from '@client/store/actions/auth.actions';
import { LOG_TOU_ACCEPTANCE } from '@client/store/actions/cobranding.actions';
import {
  closeEmailOptInModal,
  SubmitUserEmailUpdateFromOptInAction,
  submitUserEmailUpdateFromOptInError,
  submitUserEmailUpdateFromOptInSuccess,
  SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN,
} from '@client/store/actions/email-opt-in.actions';
import { fetchLoanOfficerInfoSuccess } from '@client/store/actions/loan-officer.actions';
import {
  fetchMortgageAndTerms,
  fetchMortgageSummary,
} from '@client/store/actions/property-details.actions';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { AUTH_MODAL_PAGES, GRANT_TYPES } from '@client/store/constants';
import {
  getAttemptedParams,
  getAttemptedQuery,
  getAttemptedView,
} from '@client/store/selectors/attempted-route.selectors';
import {
  getForgotPasswordTemporaryToken,
  getIsLoggedIn,
  getRedirectPostLogin,
  getRedirectPostSignUp,
  getUserEmailAddress,
} from '@client/store/selectors/auth.selectors';
import { getLoanOfficerId } from '@client/store/selectors/loan-officer.selectors';
import {
  getHomePriceForMortgageCalculator,
  getMortgageCalculationDetails,
} from '@client/store/selectors/property-details.selectors';
import { getActivePDPSlug } from '@client/store/selectors/router.selectors';
import {
  fetchAdminAccessTokenSuccess,
  fetchAdminPortalAccessToken,
} from '@client/store/slices/admin-portal.slice';
import { setLoDirectNotification } from '@client/store/slices/lo-direct.slice';
import {
  cacheAgentData,
  selectCompleteConnectedAgent,
} from '@client/store/slices/your-team.slice';
import { AfterAuthAction, User } from '@client/store/types/auth';
import {
  LoanOfficerAPIResponse,
  UserAPIResponse,
} from '@client/store/types/consumer-api';
import { CompleteAgentData } from '@client/store/types/your-team';
import { reportToSentry } from '@client/utils/error.utils';
import { callSaga, watchEvery } from '@client/utils/saga.utils';

const setSentryUser = (user: User | null) => {
  Sentry.setUser(
    user
      ? {
          ...user,
          ...(user.email ? { email: user.email } : { email: undefined }),
        }
      : null
  );
};

/**
 * Dispatch a callback action either immediately or, if not logged in, after a successful login
 * @param {string} callbackAction - action to dispatch after confirming user is authenticated
 * @param {Object} args - arguments callbackAction must be called with
 */
export function* ensureLoggedInThen(callbackAction, ...args) {
  const isLoggedIn = yield select(getIsLoggedIn);

  if (!isLoggedIn) {
    yield put(authModalShow({ afterAuthAction: callbackAction(...args) }));
  } else {
    yield put(callbackAction(...args));
  }
}

export function* ensureLoggedInThenActionSaga(
  action: EnsureLoggedInThenAction
) {
  const isLoggedIn = yield select(getIsLoggedIn);

  if (!isLoggedIn) {
    yield put(
      authModalShow({
        afterAuthAction: action.payload.afterAuthAction,
        startingPage: action.payload.startingPage,
        subHeadingText: action.payload.subHeadingText,
        headingText: action.payload.headingText,
      })
    );
  } else {
    yield put(action.payload.afterAuthAction);
  }
}

/**
 * Creates an LO Direct client via the client submitting the sign-up form themselves either from an
 * an invite email (some data already exists on the invite record) or from a shared link (the user
 * enters in all of the data)
 */
export function* handleCreateCHDClient(
  action: SelfCreateLODirectClientAction | CreateLODirectClientFromInviteAction
) {
  const { userInfo } = action.payload;
  /* Need to clear the error so that if the same error occurs a second time, the useEffect condition sees it as a new error */
  yield put(clearUserSignUpError());
  const completeConnectedAgent = (yield select(
    selectCompleteConnectedAgent
  )) as ReturnType<typeof selectCompleteConnectedAgent>;

  try {
    const { user, token } = yield* callSaga(
      [authClient, authClient.selfCreateLODirectClient],
      {
        loan_officer_id: userInfo.loan_officer_id,
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        email: userInfo.email,
        phone: userInfo.phone,
        password: userInfo.password,
        application: HC_CONSTANTS.APPLICATION_NAME,
        agent_id: completeConnectedAgent?.id || null,
        /* Don't send these fields if they're null. They're passed as `null` if the user is signing up
         * from /chd-client-invite-sign-up after clicking an email invite link following a LO Direct client upload.
         * The fields already exist on the invite record in that case and will be transfered to the user after signup */
        ...(userInfo.buying_price_point && {
          buying_price_point: userInfo.buying_price_point,
        }),
        ...(userInfo.is_buyer && { is_buyer: userInfo.is_buyer }),
        ...(userInfo.loan_amount && { loan_amount: userInfo.loan_amount }),
        ...(userInfo.slug && { slug: userInfo.slug }),
      }
    );

    setSentryUser(user);
    yield put(createUserSuccess({ user, token }));

    /**
     * We need to report signup complete via a action,
     * do not change this to do it using meta on loginSuccess action,
     * that causes a bug where email, user_id won't be set for the event
     */
    yield put(reportEvent('signup_complete', PARENT_EVENTS.CLICK_SIGNUP));

    const isRoutingToHomeowner = userInfo.slug && !userInfo.buying_price_point;
    yield put(
      routeChange({
        view: isRoutingToHomeowner
          ? View.HOMEOWNER_PROPERTY_DETAILS
          : View.SEARCH,
        ...(isRoutingToHomeowner ? { params: { slug: userInfo.slug } } : {}),
      })
    );
    yield put(
      setLoDirectNotification({
        message: `Thanks for registering!`,
        type: 'success',
      })
    );
  } catch (error: any) {
    const validationErrorMessage = error?.responseJSON?.message ?? null;
    if (validationErrorMessage) {
      yield put(
        setLoDirectNotification({
          /* i.e. "password must be 8 or more characters and cannot be a common dictionary word" */
          message: `Error: ${validationErrorMessage}`,
          type: 'error',
        })
      );
    } else {
      yield put(
        setLoDirectNotification({
          message:
            error.messageRaw || error.message
              ? /* i.e. "User already registered" */
                `Error: ${error.messageRaw || error.message}`
              : 'An error occurred, please try again',
          type: 'error',
        })
      );
    }
  }
}

/**
 * Creates a standard ComeHome user (i.e. not a user associated with a LO Direct LO-user)
 * via the user submitting the sign-up form
 */
export function* handleCreateUser(action: CreateUserAction) {
  const { userInfo, afterAuthAction } = action.payload;
  const loanOfficerId = (yield select(getLoanOfficerId)) as ReturnType<
    typeof getLoanOfficerId
  >;
  const completeConnectedAgent = (yield select(
    selectCompleteConnectedAgent
  )) as ReturnType<typeof selectCompleteConnectedAgent>;

  try {
    const redirectRouteObj = yield select(getRedirectPostSignUp);
    const { user, token, loanOfficer } = yield* callSaga(
      [authClient, authClient.createUser],
      {
        ...userInfo,
        application: HC_CONSTANTS.APPLICATION_NAME,
        loan_officer_id: loanOfficerId,
        agent_id: completeConnectedAgent?.id || null,
      }
    );

    setSentryUser(user);
    // put accountDetails on state
    yield put(createUserSuccess({ user, token }));

    if (loanOfficer) {
      yield put(fetchLoanOfficerInfoSuccess({ loInfo: loanOfficer }));
    }
    /**
     * We need to report signup complete via a action,
     * do not change this to do it using meta on loginSuccess action,
     * that causes a bug where email, user_id won't be set for the event
     */
    yield put(reportEvent('signup_complete', PARENT_EVENTS.CLICK_SIGNUP));

    // run the call back
    if (afterAuthAction) {
      yield put(afterAuthAction);
    }

    // Need to check against VIEWS.SEARCH since its value is an empty string, which is falsey:
    if (redirectRouteObj) {
      yield put(
        routeChange({
          view: redirectRouteObj.view,
          ...(redirectRouteObj.params
            ? { params: redirectRouteObj.params }
            : {}),
        })
      );
    } else {
      yield put(authModalHide());
    }
  } catch (error: any) {
    const validationErrors = error?.responseJSON?.errors ?? null;
    if (!isEmpty(validationErrors)) {
      // put validation errors on state
      yield put(
        createUserValidationErrors({
          errors: validationErrors,
          email: userInfo.email,
          firstname: userInfo.first_name,
          lastname: userInfo.last_name,
        })
      );
    } else {
      yield put(
        createUserError({
          errorMessage:
            error.messageRaw ||
            'An error occurred, please check your credentials and try again',
          email: userInfo.email,
          firstname: userInfo.first_name,
          lastname: userInfo.last_name,
        })
      );
    }
  }
}

export function* handleResendConfirmUserEmail(
  action: ResendConfirmUserEmailAction
) {
  const { token } = action.payload;
  try {
    const resendDetails = yield call(
      [authClient, authClient.resendConfirmationEmail],
      token
    );
    if (isEmpty(resendDetails.response)) {
      yield put(
        resendConfirmUserEmailSuccess('Check email to confirm your account')
      );
    }
  } catch (error: any) {
    yield put(resendConfirmUserEmailFailure(error.messageRaw));
  }
}

export function* handleConfirmUser(action) {
  const { token } = action.payload;
  try {
    if (!token) {
      throw new Error('Unable to confirm the user without a token');
    }
    yield call([authClient, authClient.confirmUserEmail], token);
    const isLoggedIn = yield select(getIsLoggedIn);
    yield put(routeChange({ view: View.SEARCH }));
    if (isLoggedIn) {
      /**
       * User is auto logged in after sign up, clicks the confirm link
       * from the email, show account confirmation toast (assuming they use
       * same browser and have the auth token set)
       */
      yield put(confirmNewUserSuccess('Account confirmed.'));
    } else {
      /**
       * User is not logged in, clicks the confirm link from the email,
       * show account confirmation toast along with the login modal
       */
      yield put(confirmNewUserSuccess('Account confirmed, Login to continue.'));
      yield put(authModalShow({ startingPage: AUTH_MODAL_PAGES.LOGIN }));
    }
  } catch (error: any) {
    if (error && error.statusCode === 401) {
      yield put(
        confirmNewUserExpiredToken(
          'Your confirmation email is expired or invalid.',
          token
        )
      );
    } else if (error && error.statusCode === 400) {
      yield put(confirmNewUserError('Your confirmation email is invalid.'));
    } else {
      yield put(confirmNewUserError(error.messageRaw));
    }
  }
}

export function* handleLoginSideEffects({
  user,
  loanOfficer,
  agent: existingAssociatedAgent,
  redirect,
  afterAuthAction,
}: {
  user: UserAPIResponse;
  loanOfficer: LoanOfficerAPIResponse | null;
  agent: CompleteAgentData | null;
  redirect?: { view: View; params?: { slug: string } };
  afterAuthAction?: AfterAuthAction;
}) {
  const completeConnectedAgentInState = (yield select(
    selectCompleteConnectedAgent
  )) as ReturnType<typeof selectCompleteConnectedAgent>;

  /* If the user has no existing associated agent, associate the user with the agent currently in state */
  if (!existingAssociatedAgent && completeConnectedAgentInState) {
    try {
      yield* callSaga(
        [consumerApiClient, consumerApiClient.associateUserAndAgent],
        {
          userId: user.id,
          agentId: completeConnectedAgentInState.id,
        }
      );
      /* Handling this error separately so that the rest of the login flow can continue */
    } catch (error) {
      reportToSentry('Unable to associate user with agent', { error });
    }
  }

  /* If the user has an existing associated agent, set this agent on state */
  if (existingAssociatedAgent) {
    yield put(
      cacheAgentData({
        agentType: 'connectedAgent',
        data: {
          agent: existingAssociatedAgent,
          incomplete_agent: null,
          intro_completed: false,
          intro_required: false,
        },
      })
    );
  }

  if (loanOfficer) {
    yield put(fetchLoanOfficerInfoSuccess({ loInfo: loanOfficer }));
  }
  /**
   * On client side, if user logs in we need to refetch mortgage terms to show
   * user saved default terms
   */
  const homePriceForMortgageCalc = yield select(
    getHomePriceForMortgageCalculator
  );
  const activePDPSlug = yield select(getActivePDPSlug);
  if (activePDPSlug) {
    yield put(
      fetchMortgageAndTerms(
        activePDPSlug,
        { homePrice: homePriceForMortgageCalc },
        true
      )
    );
    const mortgageCalculationDetails = yield select(
      getMortgageCalculationDetails
    );
    const { downPaymentPct, interestRate, homePrice, mortgageId } =
      mortgageCalculationDetails;
    yield put(
      fetchMortgageSummary({
        activePropertySlug: activePDPSlug,
        userChosenHomePrice: homePrice,
        userChosenInterestRate: interestRate,
        userChosenLoanTerm: mortgageId,
        userChosenDownpaymentPct: downPaymentPct,
      })
    );
  }
  /**
   * We need to report signin complete via a action,
   * do not change this to do it using meta on loginSuccess action,
   * that causes a bug where email, user_id won't be set for the event
   */
  yield put(reportEvent('signin_complete'));
  setSentryUser(user);
  if (afterAuthAction) {
    yield put(afterAuthAction);
  }

  /* Check if user attempted to access a page that required login */
  const attemptedView = yield select(getAttemptedView);
  if (attemptedView !== '') {
    /* Redirect to the page the user tried to access before logging in */
    const attemptedParams = yield select(getAttemptedParams);
    const attemptedQuery = yield select(getAttemptedQuery);
    yield put(
      routeChange({
        view: attemptedView,
        params: attemptedParams,
        query: attemptedQuery,
      })
    );
  } else if (redirect) {
    const toView = redirect?.view;
    const slug = redirect?.params?.slug;
    if (slug) {
      yield put(routeChange({ view: toView, params: { slug: slug } }));
    } else {
      yield put(routeChange({ view: toView }));
    }
  } else {
    yield put(authModalHide());
  }

  yield put(clearAttemptedRoute());
}

export function* handleLogin(action) {
  const {
    credentials: { email, password },
    routeAfterLoginSuccess,
    afterAuthAction,
  } = action.payload;
  try {
    const redirect = yield select(getRedirectPostLogin, routeAfterLoginSuccess);
    const { user, token, loanOfficer, agent } = yield* callSaga(
      [authClient, authClient.login],
      { email, password, grant_type: GRANT_TYPES.USER }
    );

    yield put(loginSuccess({ user, token }));
    /* Unexpected request failures inside handleLoginSideEffects shouldn't prevent the login from succeeding */
    try {
      yield call(handleLoginSideEffects, {
        user,
        loanOfficer,
        agent,
        redirect,
        afterAuthAction,
      });
    } catch (error) {
      reportToSentry(error);
    }
  } catch (error: any) {
    yield put(loginError(error.messageRaw || 'Unable to login'));
  }
}

export function* handleLoginOAuthOIDC(action: LoginOAuthOIDCAction) {
  const { idToken, afterAuthAction } = action.payload;

  const { loanOfficer, agent, user, token } = yield* callSaga(
    [authClient, authClient.loginOAuthOIDC],
    {
      id_token: idToken,
    }
  );

  yield put(loginSuccess({ user, token }));
  yield call(handleLoginSideEffects, {
    user,
    loanOfficer,
    agent,
    redirect: undefined,
    afterAuthAction,
  });
}

export function* handleSubmitForgotPassword(
  action: SubmitForgotPasswordAction
) {
  const { email, type, hideSuccessToast } = action.payload;
  try {
    const result = yield call([authClient, authClient.forgotPassword], {
      email,
      type,
    });

    if (isEmpty(result) && !hideSuccessToast) {
      yield put(
        submitForgotPasswordSuccess('Success, Please check your email')
      );
    }
  } catch (error: any) {
    yield put(
      submitForgotPasswordError(
        error?.messageRaw ?? 'Error: We could not verify your email'
      )
    );
  }
}

export function* handleSubmitPasswordReset(action) {
  const { newPassword, routeAfterReset } = action.payload;
  try {
    const temporaryToken = yield select(getForgotPasswordTemporaryToken);
    if (!temporaryToken) {
      throw new Error('Unable to reset your password. Please try again.');
    }
    const userEmailId = yield select(getUserEmailAddress);
    const response = yield call(
      [authClient, authClient.resetPassword],
      temporaryToken,
      newPassword,
      userEmailId
    );
    if (isEmpty(response)) {
      yield put(submitPasswordResetSuccess('Password has been updated'));
      yield delay(500);
      yield put(routeChange({ view: routeAfterReset }));
    }
  } catch (error: any) {
    yield put(
      verifyResetPasswordFailure(
        error?.messageRaw ?? 'Unable to reset your password. Please try again.'
      )
    );
  }
}

export function* handleRouterErrorSaga(action) {
  const { metadata } = action.payload;
  yield put(setAttemptedRoute(metadata.routeObj));
}

export function* handleFetchNewAccessToken(action) {
  try {
    const { token } = yield call([
      authClient,
      authClient.refreshAccessTokenAndCookie,
    ]);
    yield put(fetchNewAccessTokenSuccess(token));
  } catch (error: any) {
    const isLoggedIn = yield select(getIsLoggedIn);
    /* The /refresh-cookie endpoint should only ever return a 401 if the user is authenticated so
     * this check should never fail. Regardless, including for safety */
    if (isLoggedIn) {
      yield put(fetchNewAccessTokenFailure());
      yield call(handleUnauthorizedLogout);
    } else {
      /* If for some reason, the API fails to auto-refresh the anon refresh token, do an anon login */
      const { token: anonLoginToken } = yield call([
        authClient,
        authClient.anonymousLogin,
      ]);
      yield put(fetchNewAccessTokenSuccess(anonLoginToken));
    }
  }
}

export function* handleFetchAdminPortalAccessToken() {
  const { token } = yield call([
    authClient,
    authClient.refreshAdminPortalAccessTokenAndCookie,
  ]);
  yield put(fetchAdminAccessTokenSuccess(token));
}

/* Execute common logout functionality */
export function* handleLogout() {
  yield put(resetAuthDependentState());
  setSentryUser(null);

  const { token } = yield call([authClient, authClient.logout]);
  yield put(logoutSuccess(token));
}

/* Executed on logout due to user interaction (i.e. clicking logout button) */
export function* handleUserLogout() {
  yield call(handleLogout);
  /* Redirect if on a page that requires you to be logged in */
  const currView = yield select(getCurrentView);
  if (
    ALL_SEARCH_VIEWS.indexOf(currView) === -1 &&
    currView !== View.PROPERTY_DETAILS
  ) {
    yield put(routeChange({ view: View.SEARCH }));
  }
}

/* Executed after cobranded session timeout.  Only used for cobrands that have implemented `SessionKeepAlive` component */
export function* handleUserLogoutThenRedirect(
  action: LogOutThenRedirectAction
) {
  yield call(handleLogout);
  window.location.href = action.payload.url;
}

/* Executed on logged-in user receiving a 401 from /refresh-cookie */
export function* handleUnauthorizedLogout() {
  yield call(handleLogout);

  /* If on PDP, provide convenience of simply showing the auth modal since we have logic to
   * reload PDP data after a login when on that page.  Otherwise, redirect to login screen
   * to ensure app data reflects newly authorized status after login */

  /* If on either the PDP page or viewing the PDP modal on the search page */
  const activePDPSlug = yield select(getActivePDPSlug);
  if (activePDPSlug) {
    yield put(authModalShow());
  } else {
    yield put(routeChange({ view: View.LOGIN }));
  }
}

/**
 * Saga to pass into redux-saga-router for use in determining when the user is logged in
 */
export function* routerGetIsLoggedInFn() {
  return yield select(getIsLoggedIn);
}

export function* updateUserProfileSaga(action: UpdateUserProfileAction) {
  const { userInfo, disableToast, toastMessage } = action.payload;
  try {
    const result = yield call(
      [authClient, authClient.updateUserInfo],
      userInfo
    );
    yield put(updateUserInfoOnState(result));

    if (!disableToast) {
      yield put(updateUserProfileSuccess(toastMessage || 'Profile updated successfully.'));
    }
  } catch (error: any) {
    yield put(
      updateUserProfileError(
        error?.messageRaw ?? 'Unable to save changes. Please try again.'
      )
    );
  }
}

export function* updateUserEmailSaga(action: UpdateUserEmailAction) {
  const { userInfo } = action.payload;
  try {
    const result = yield call(
      [authClient, authClient.updateUserInfo],
      userInfo
    );
    yield put(updateUserInfoOnState(result));
    yield put(updateUserEmailSuccess());
  } catch (error: any) {
    yield put(
      updateUserEmailError(
        error?.messageRaw ?? 'Unable to save changes. Please try again.'
      )
    );
  }
}

export function* updateUserTermsAccepted() {
  const result = yield call([authClient, authClient.updateUserTermsAccepted]);
  yield put(acceptTermsAndConditionsSuccess(result.terms_accepted as string));
}

export function* fetchUserProfileSaga() {
  try {
    const userProfile = yield call([authClient, authClient.getUserInfo]);
    yield put(updateUserInfoOnState(userProfile));
  } catch (error: any) {
    /**
     * Edge case: fetch user details call could fail if server is down
     */
    yield put(fetchUserProfileError('Unable to retrieve user information.'));
  }
}

/* Only used for testing. This will generate a new cookie (refresh token) and access token
 * with the expiration durations that you pass in */
export function* regenCookieSaga(action) {
  const { expires, grantType, refreshExpires } = action.payload;
  const { token } = yield call(
    [authClient, authClient.regenAccessTokenAndCookie],
    { expires, grantType, refreshExpires }
  );
  yield put(fetchNewAccessTokenSuccess(token));
}

export function* updateUserEmailOptInSaga(
  action: SubmitUserEmailUpdateFromOptInAction
) {
  const { userInfo } = action.payload;
  try {
    const result = yield call(
      [authClient, authClient.updateUserInfo],
      userInfo
    );
    yield put(updateUserInfoOnState(result));
    yield put(submitUserEmailUpdateFromOptInSuccess());
    yield put(closeEmailOptInModal());
  } catch (error: any) {
    if (error.statusCode === 409) {
      yield put(
        submitUserEmailUpdateFromOptInError(
          'This email has already been taken. Please try again.'
        )
      );
    } else {
      yield put(
        submitUserEmailUpdateFromOptInError(
          error?.messageRaw ?? 'Unable to save changes. Please try again.'
        )
      );
    }
  }
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [CREATE_USER]: handleCreateUser,
    [CONFIRM_NEW_USER]: handleConfirmUser,
    [SELF_CREATE_LO_DIRECT_CLIENT]: handleCreateCHDClient,
    [CREATE_LO_DIRECT_CLIENT_FROM_INVITE]: handleCreateCHDClient,
    [LOGIN]: handleLogin,
    [LOGIN_OAUTH_OIDC]: handleLoginOAuthOIDC,
    [LOGOUT]: handleUserLogout,
    [LOGOUT_THEN_REDIRECT]: handleUserLogoutThenRedirect,
    [LOGOUT_WITHOUT_REDIRECT]: handleLogout,
    [RESEND_CONFIRM_USER_EMAIL]: handleResendConfirmUserEmail,
    [ROUTER_ERROR]: handleRouterErrorSaga,
    [SUBMIT_FORGOT_PASSWORD]: handleSubmitForgotPassword,
    [SUBMIT_PASSWORD_RESET_THEN_REDIRECT]: handleSubmitPasswordReset,
    [FETCH_USER_PROFILE]: fetchUserProfileSaga,
    [UPDATE_USER_EMAIL]: updateUserEmailSaga,
    [UPDATE_USER_PROFILE]: updateUserProfileSaga,
    [FETCH_NEW_ACCESS_TOKEN]: handleFetchNewAccessToken,
    [fetchAdminPortalAccessToken.type]: handleFetchAdminPortalAccessToken,
    [REGEN_COOKIE]: regenCookieSaga,
    [LOG_TOU_ACCEPTANCE]: updateUserTermsAccepted,
    [ACCEPT_TERMS_AND_CONDITIONS]: updateUserTermsAccepted,
    [SUBMIT_USER_EMAIL_UPDATE_FROM_OPT_IN]: updateUserEmailOptInSaga,
    [URQL_INITIATED_UNAUTHORIZED_LOGOUT]: handleUnauthorizedLogout,
    [ENSURE_LOGGED_IN_THEN_ACTION]: ensureLoggedInThenActionSaga,
  });
};
