import classNames from 'classnames';
import * as Tabs from '@radix-ui/react-tabs';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import defaultTheme from '@client/css-modules/FlatDivideTabList.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type OptionType = {
  type: string;
  label: string;
  index?: number;
  'data-event-name'?: string;
};

type FlatDivideTabListProps = {
  theme: Theme;
  selectedTab: string;
  options: Array<OptionType>;
  ['data-parent-event-name']?: string;
};

const FlatDivideTabList: React.FC<FlatDivideTabListProps> = ({
  theme,
  options,
  selectedTab,
  ['data-parent-event-name']: dataParentEventName,
  ...rest
}) => {
  const { activeOptionUnderlineColor } = useCobrandStyles();
  return (
    <Tabs.List className={theme.FlatDivideTabList} {...rest}>
      {options.map((opt, index) => (
        <Tabs.Trigger
          value={opt.type}
          data-parent-event-name={dataParentEventName}
          data-event-name={
            opt['data-event-name'] || `click_${opt.type.toLowerCase()}_tab`
          }
          className={classNames(theme.FlatDivideTab, {
            [theme.FlatDivideTabSelected]: selectedTab === opt.type,
          })}
          key={index}
        >
          <div className={theme.FlatDivideTabLabel}>{opt.label}</div>
          <div
            className={theme.FlatDivideTabSelectedIndicator}
            style={
              selectedTab === opt.type
                ? { background: activeOptionUnderlineColor }
                : {}
            }
          />
        </Tabs.Trigger>
      ))}
    </Tabs.List>
  );
};

const ThemedFlatDivideTabList = themr(
  'FlatDivideTabList',
  defaultTheme
)(FlatDivideTabList);
export default ThemedFlatDivideTabList;
