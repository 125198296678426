import { themr } from '@friendsofreactjs/react-css-themr';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { getIsEligibleForFinancing } from '@client/components/cobrand/chase/chase-constants';
import HorizontalSeparator from '@client/components/generic/HorizontalSeparator';
import PillButton from '@client/components/generic/PillButton';
import MLSAttribution from '@client/components/MLSAttribution';
import PropertyCardValueInline from '@client/components/PropertyCardValue/PropertyCardValueInline';
import LenderPropertyCTAContainerChase from '@client/containers/cobrand/chase/lender-property-cta-chase.container';
import WatchListActionButtonContainer from '@client/containers/watchlist-action-button.container';
import defaultTheme from '@client/css-modules/PDPRightRailCardChase.css';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import { View } from '@client/routes/constants';
import {
  reportPropertyDetailsUnwatchClick,
  reportPropertyDetailsUnwatchConfirmClick,
  reportPropertyDetailsWatchClick,
} from '@client/store/actions/analytics.actions';
import { NWMLS_ID, STATUSES } from '@client/store/constants';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  getPropertyDetailsInitStatus,
  getPropertyDetailsNormalized,
  getPropertyIsInWatchList,
} from '@client/store/selectors/property-details.selectors';
import { getActivePDPSlug } from '@client/store/selectors/router.selectors';
import { updateContextualSearchId } from '@client/store/slices/analytics-data.slice';
import { PDPRightRailCardProps } from '@client/store/types/cobranded-components/pdp-right-rail-card';
import {
  getMLSIdFromPropertyDetails,
  getPropertyValueAndValueLabel,
} from '@client/utils/property.utils';
import ShareButtonWithModal from '../ShareButtonWithModal';

const PDPRightRailCardChase = ({
  theme,
  isShowingShareAndSaveSection,
  isShowingClickHintCTA,
  onClick,
}: PDPRightRailCardProps) => {
  const dispatch = useDispatch();

  const isAddedToWatchList: boolean = useSelector(getPropertyIsInWatchList);
  const propertyDetails = useSelector(getPropertyDetailsNormalized);
  const PDPInitStatus = useSelector(getPropertyDetailsInitStatus);
  const isActivePDPSlug = !!useSelector(getActivePDPSlug);
  const isTempChasePdpAddShareFunctionalityEnabled = useSelector(
    getIsFeatureEnabled('temp_chase_pdp_add_share_functionality')
  );

  if (!propertyDetails || !propertyDetails.slug) {
    return null;
  }

  const handlePropertyDetailsWatchClick = (slug) => {
    dispatch(reportPropertyDetailsWatchClick(slug));
  };

  const handlePropertyDetailsUnwatchClick = (slug) => {
    dispatch(reportPropertyDetailsUnwatchClick(slug));
  };

  const handlePropertyDetailsUnwatchConfirmClick = (slug) => {
    dispatch(reportPropertyDetailsUnwatchConfirmClick(slug));
  };

  const handleRouteChange = (view: (typeof View)[keyof typeof View]) => {
    dispatch(routeChange({ view: view }));
  };

  const { fullAddress, propertyType, slug } = propertyDetails || {};
  const valueAndValueLabel = !propertyDetails
    ? { value: 0, valueLabel: '' }
    : getPropertyValueAndValueLabel(propertyDetails);
  const { value, valueLabel } = valueAndValueLabel;
  const isEligibleForFinancing = getIsEligibleForFinancing(
    propertyType,
    propertyDetails?.status
  );

  const watchlistAddress = {
    street: propertyDetails.streetAddress,
    city: propertyDetails.city,
    state: propertyDetails.state,
    zip: propertyDetails.zipcode,
    unit: propertyDetails.unit,
    address_id: propertyDetails.hcAddressId,
    slug: propertyDetails.slug,
  };

  return (
    <div
      className={classNames(theme.PDPRightRailCard, {
        [theme.PDPRightRailCardWithLargePriceFont]: !isShowingClickHintCTA,
      })}
      data-hc-name="summary-options-panel"
      onClick={onClick}
    >
      <div className={theme.ContentWrapper}>
        <div
          className={theme.ListingStatusSection}
          data-hc-name="listing-status"
        >
          <PropertyCardValueInline
            value={value}
            valueLabel={valueLabel}
            isShowingClickHintCTA={isShowingClickHintCTA}
            theme={theme}
            bottomLabel={''}
          />
          {PDPInitStatus === STATUSES.SUCCESS &&
            (isEligibleForFinancing ? (
              <LenderPropertyCTAContainerChase
                propertyDetails={propertyDetails}
                effectivePropertyPrice={value}
                shouldShowExceededBuyingPowerNotice
                theme={theme}
              />
            ) : (
              <>
                <div className={theme.InEligibleForFinanceContainer}>
                  <div className={theme.ContentWrapper}>
                    <div className={theme.CircleIcon} />
                    <div className={theme.SubHeading}>
                      Not eligible for financing
                      <div className={theme.SubText}>
                        Unfortunately you will not be able to transact on this
                        home at this time.
                      </div>
                    </div>
                  </div>
                </div>
                <HorizontalSeparator theme={theme} />
              </>
            ))}
        </div>
        {isShowingShareAndSaveSection &&
          (isTempChasePdpAddShareFunctionalityEnabled ? (
            <div className={theme.ShareAndSavePropertySection}>
              <div
                className={theme.ShareButtonContainer}
                data-hc-name="share-button"
              >
                <AccessibleElementUniqueId>
                  {({ uid }) => (
                    <>
                      <ShareButtonWithModal
                        id={uid}
                        theme={theme}
                        slug={slug}
                      />
                      <label htmlFor={uid} className={theme.ButtonLabel}>
                        Share
                      </label>
                    </>
                  )}
                </AccessibleElementUniqueId>
              </div>
              <WatchListActionButtonContainer
                address={watchlistAddress}
                fullAddress={fullAddress}
                isAddedToWatchList={isAddedToWatchList}
                addressSlug={slug}
                theme={theme}
                isShowingButtonLabel
                handleReportWatchClick={handlePropertyDetailsWatchClick}
                handleReportUnwatchClick={handlePropertyDetailsUnwatchClick}
                handleReportUnwatchConfirmClick={
                  handlePropertyDetailsUnwatchConfirmClick
                }
                shouldHandleCheckingForWatchListStatus={true}
              />
            </div>
          ) : (
            <div className={theme.SavePropertySection}>
              <WatchListActionButtonContainer
                address={watchlistAddress}
                fullAddress={fullAddress}
                isAddedToWatchList={isAddedToWatchList}
                addressSlug={slug}
                theme={theme}
                handleReportWatchClick={handlePropertyDetailsWatchClick}
                handleReportUnwatchClick={handlePropertyDetailsUnwatchClick}
                handleReportUnwatchConfirmClick={
                  handlePropertyDetailsUnwatchConfirmClick
                }
                shouldHandleCheckingForWatchListStatus={true}
              />
              <div className={theme.SaveHomesText}>
                Save homes that you like and want to follow.
              </div>
            </div>
          ))}
        {PDPInitStatus === STATUSES.SUCCESS && !isEligibleForFinancing && (
          <PillButton
            ariaLabel={'start a new search'}
            className={theme.NewSearchButton}
            data-event-name="click_lender_cta_new_search"
            onClick={() => {
              handleRouteChange(View.SEARCH);
              dispatch(updateContextualSearchId());
            }}
          >
            Start a new search
          </PillButton>
        )}
      </div>
      {getMLSIdFromPropertyDetails(propertyDetails) !== NWMLS_ID && (
        <MLSAttribution
          className={classNames(theme.MLSAttribution, {
            [theme.MLSAttributionOnMobilePDP]: isActivePDPSlug,
          })}
          propertyDetails={propertyDetails}
        />
      )}
    </div>
  );
};

const ThemedPDPRightRailCardChase = themr(
  'PDPRightRailCardChase',
  defaultTheme
)(PDPRightRailCardChase);
export default ThemedPDPRightRailCardChase;
