import classNames from 'classnames';

import LearnMoreAside from '@client/components/LearnMoreAside';
import Tooltip from '@client/components/generic/Tooltip';
import AvmBreakdownToolTipText from '@client/components/AvmBreakdownToolTipText';
import { AvmBreakdownAvmDefinitionProps } from '@client/store/types/cobranded-components/avm-breakdown-avm-definition';
import theme from '@client/css-modules/AvmBreakdownAvmDefinition.css';

const LearnMore = () => {
  return <span style={{ textDecoration: 'underline' }}>Learn More</span>;
};

const AvmBreakdownAvmDefinition: React.FC<AvmBreakdownAvmDefinitionProps> = ({
  initialShiftAmount,
  maxWidth,
  showDataDeepDives,
  tooltipPosition,
}) => (
  <div
    className={classNames(theme.HcAvmExplanation, {
      [theme.NoAvmFactors]: !showDataDeepDives,
    })}
    data-hc-name={'avm-explanation'}
  >
    What influenced our estimate?
    {
      <LearnMoreAside theme={theme}>
        {() => (
          <Tooltip
            dataHcName="avm-breakdown-definition-tooltip"
            trigger={<LearnMore />}
            content={<AvmBreakdownToolTipText />}
            position={tooltipPosition}
            initialShiftAmount={initialShiftAmount}
            maxWidth={maxWidth}
          />
        )}
      </LearnMoreAside>
    }
  </div>
);

export default AvmBreakdownAvmDefinition;
