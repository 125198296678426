import React, { Component } from 'react';
import renderOnMountUntyped from '@client/hocs/render-on-mount-untyped';
import defaultTheme from '@client/css-modules/ScrollToTop.css';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import smoothscroll from 'smoothscroll-polyfill';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import TrackScrollPosition from '@client/hocs/track-scroll-position';
import ChevronIconStandardGray from '@client/inline-svgs/chevron-standard-gray';
import classNames from 'classnames';

type Props = {
  theme: Theme;
  scrollableParent?: Element | Window | null;
  /* will override the CSS that hides the button when towards top of page,
   * meaning the component with never use the .ScrollToTopHidden class */
  overrideHidden?: boolean;
  className?: string;
};

class ScrollToTop extends Component<Props> {
  constructor(props) {
    super(props);
    smoothscroll.polyfill();
  }

  setFocusToTopTimeout: ReturnType<typeof setTimeout> | null = null;

  componentWillUnmount() {
    if (this.setFocusToTopTimeout !== null) {
      window.clearTimeout(this.setFocusToTopTimeout);
    }
  }

  getScrollableParent = (): Element | Window => {
    const { scrollableParent } = this.props;
    return scrollableParent ? scrollableParent : window;
  };

  handleClick = (): void => {
    this.getScrollableParent().scroll({
      top: 0,
      behavior: 'smooth',
    });

    /* refocusing to top most element for accessibility requirement */
    this.setFocusToTopTimeout = setTimeout(() => {
      const topClickableEle: NodeListOf<HTMLElement> =
        document.querySelectorAll('button');
      topClickableEle[0] && topClickableEle[0].focus();
      /* setting timeout to wait for it to smoothly scroll first */
    }, 1000);
  };

  render() {
    const { theme, className, overrideHidden } = this.props;

    return (
      <TrackScrollPosition rateLimitStrategy="throttle" rateLimitAmount={300}>
        {({ y }) => (
          <button
            type="button"
            aria-label="back to top"
            className={
              y > 200 || overrideHidden
                ? className
                  ? classNames(theme.ScrollToTop, className)
                  : theme.ScrollToTop
                : className
                ? classNames(theme.ScrollToTopHidden, className)
                : theme.ScrollToTopHidden
            }
            onClick={this.handleClick}
            onKeyDown={onEnterOrSpaceKey(this.handleClick)}
          >
            <ChevronIconStandardGray />
          </button>
        )}
      </TrackScrollPosition>
    );
  }
}

const ThemedScrollToTop = themr('ScrollToTop', defaultTheme)(ScrollToTop);
export default renderOnMountUntyped(ThemedScrollToTop);
