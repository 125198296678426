import React from 'react';

import {
  punctuateStreetAddress,
  punctuateUnit,
} from '@client/utils/string.utils';

type AddressUnstyledProps = {
  address?: {
    addrState?: string;
    city?: string;
    street?: string;
    unit?: string;
    zip?: string;
  } | null;
  hasLineBreak?: boolean;
  hideCityStateZip?: boolean;
};

const AddressUnstyled: React.FunctionComponent<AddressUnstyledProps> = ({
  address,
  hasLineBreak,
  hideCityStateZip,
}) => {
  const { street, unit, city, addrState, zip } = address || {};
  return (
    <span>
      {street ? punctuateStreetAddress(street) : '-'}
      {unit && `, ${punctuateUnit(unit)}`}
      {!hideCityStateZip && ', '}
      {hasLineBreak && !hideCityStateZip && <br />}
      {hideCityStateZip ? '' : `${city}, ${addrState} ${zip}`}
    </span>
  );
};

export default AddressUnstyled;
