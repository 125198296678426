// NOTE: This is done as a string with replace tokens so we can extract
// the result type to generate types
const QUERY_TEMPLATE = /* GraphQL */ `query SpatialSearchPropertyCount(
  $spatialId: PropertiesIdentifierInput!,
  #extraFiltersDef
) {
  propertySpatialSearch(
    id: $spatialId,
    limit: 0
    countLimit: { type: AUTO },
    #extraFiltersValues
  ) {
    ... on SpatialSearchCountResult {
      count
    }
    ... on SpatialSearchOverCountResult {
      moreThan
    }
  }
}`;

export default ({spatialId, filtersMapping}) => ({
  query: QUERY_TEMPLATE.replace(
    '#extraFiltersDef', filtersMapping.map(item => `$${item.key}: ${item.gqlType}`).join(',')
  ).replace(
    '#extraFiltersValues', filtersMapping.map(item => `${item.key}: $${item.key}`).join(',')
  ),
  variables: {
    spatialId,
    ...filtersMapping.reduce((mem, curr) => {
      mem[curr.key] = curr.value;
      return mem;
    }, {})
  }
});
