export interface UtmState {
  utm_address?: string;
  utm_adgroup?: string;
  utm_campaign?: string;
  utm_city?: string;
  utm_email?: string;
  utm_firstname?: string;
  utm_lastname?: string;
  utm_medium?: string;
  utm_region?: string;
  utm_source?: string;
  utm_state?: string;
  utm_term?: string;
  utm_zip?: string;
  utm_uuid?: string;
}

export const INITIAL_UTM_STATE: UtmState = {};

export default function utmReducer(
  state = INITIAL_UTM_STATE,
  /* there is no action to type yet */
  action
): UtmState {
  switch (action.type) {
    default:
      return state;
  }
}
