import { connect } from 'react-redux';

import TermsOfUsePage from '@client/pages/TermsOfUsePage';
import { getLegalContentTermsOfUse } from '@client/store/selectors/legal-content.selectors';
import { fetchLegalContent } from '@client/store/actions/legal-content.actions';
import HC_CONSTANTS from '@client/app.config';

const mapStateToProps = (state) => {
  return {
    content: getLegalContentTermsOfUse(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleFetchContent: () =>
    dispatch(
      fetchLegalContent('termsOfUse', HC_CONSTANTS.TERMS_OF_USE_PROXY_URL)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfUsePage);
