import React from 'react';
import { useSelector } from 'react-redux';
import PropertyDataTable from '@client/components/PropertyDataTable';
import {
  getPropertyGeneralDetails,
  getPropertyMlsAbbrevName,
} from '@client/store/selectors/property-details.selectors';
import theme from '@client/css-modules/AdditionalHomeDetails.css';

/* A component that displays the general details of a property, via
 * wrapping PropertyDataTable with the appropriate content */
const AdditionalHomeDetails: React.FC = () => {
  const propertyGeneralDetails = useSelector(getPropertyGeneralDetails);
  const mlsNameAbbreviated = useSelector(getPropertyMlsAbbrevName);

  return (
    <section className={theme.AdditionalHomeDetails}>
      <PropertyDataTable
        content={propertyGeneralDetails}
        mlsNameAbbreviated={mlsNameAbbreviated}
        theme={theme}
      />
    </section>
  );
};

export default AdditionalHomeDetails;
