import React from 'react';
import { GeoJSONSource } from 'mapbox-gl';
import Layer from '@hc/hcmaps-mapboxgl/lib/components/Layer';
import LayerSource from '@hc/hcmaps-mapboxgl/lib/components/LayerSource';
import { MapAndAPIContext } from '@hc/hcmaps-mapboxgl/lib/context/map-and-api-context';

import { LatitudeLongitudeObject } from '@client/store/types/maps';
import { MapboxLayerId } from '@client/store/map-constants';

type TriggerProp = string | number | boolean | null;

type Props = {
  /* Unique identifier for the layer */
  layerId: string;
  /* Specifies to place this layer beneath the layer id given */
  beneathLayerId?: MapboxLayerId;
  /* Where to place the pulse symbols */
  positions: LatitudeLongitudeObject[];
  positionsUpdateTrigger?: TriggerProp;
  imageUrl: string;
};

type State = {
  renderLayer: boolean;
};

/**
 * Adds a symbol map layer rendering and animating a "pulse" at the given positions
 */
class MapMarkerPulseLayer extends React.PureComponent<Props, State> {
  state = {
    renderLayer: false,
  };

  sourceId = `${this.props.layerId}-source`;
  symbolImageId = `${this.props.layerId}-image`;
  animationFrame: number = 0;
  allowLayerRender: boolean = false;

  static contextType = MapAndAPIContext;
  declare context: React.ContextType<typeof MapAndAPIContext>;

  componentDidMount() {
    const { map } = this.context;
    const { imageUrl } = this.props;

    this.allowLayerRender = true;

    if (!map) {
      throw new Error(
        'Attempting to load MapMarkerPulseLayer image before map is available'
      );
    }
    /* Load the icon image */
    map.loadImage(imageUrl, (error, image) => {
      if (error) {
        throw error;
      }
      /* Map might be unmounted by the time the image loads */
      if (map && map.getStyle() && !map.hasImage(this.symbolImageId) && image) {
        map.addImage(this.symbolImageId, image);
      }
      /* Check to prevent `setState` from being called if component is unmounted
       * before image finishes loading */
      if (this.allowLayerRender) {
        this.setState({ renderLayer: true });
      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { map } = this.context;
    const { positionsUpdateTrigger } = this.props;
    /* Kick off the pulse animation */
    if (!prevState.renderLayer && this.state.renderLayer) {
      this.animateSymbol(0);
    }
    if (
      map &&
      prevProps.positionsUpdateTrigger !== positionsUpdateTrigger &&
      map.getSource(this.sourceId)
    ) {
      /* We know this is a GeoJSON source */
      (map.getSource(this.sourceId) as GeoJSONSource).setData(
        this.getPulseGeoJSONFeatureData()
      );
    }
  }

  componentWillUnmount() {
    const { map } = this.context;

    this.allowLayerRender = false;
    window.cancelAnimationFrame(this.animationFrame);
    if (map && map.getStyle() && map.hasImage(this.symbolImageId)) {
      map.removeImage(this.symbolImageId);
    }
  }

  getPulseGeoJSONFeatureData = () => {
    const { positions } = this.props;

    return {
      type: 'FeatureCollection' as 'FeatureCollection',
      features: positions.map((position) => ({
        type: 'Feature' as 'Feature',
        properties: {},
        geometry: {
          type: 'Point' as 'Point',
          coordinates: [position.longitude, position.latitude],
        },
      })),
    };
  };

  /**
   * Update the icon's opacity and size around 60x/second
   */
  animateSymbol = (timestamp) => {
    const { map } = this.context;
    const { layerId } = this.props;
    /* Adjust higher for a slower animation, lower for faster */
    const animationSpeed = 1500;
    const percentComplete = (timestamp % animationSpeed) / animationSpeed;
    const maxSize = 0.6;
    const maxOpacity = 0.8;

    if (map) {
      map.setPaintProperty(
        layerId,
        'icon-opacity',
        maxOpacity - percentComplete * maxOpacity
      );
      map.setLayoutProperty(layerId, 'icon-size', percentComplete * maxSize);
    }

    /* Request the next frame of the animation */
    this.animationFrame = window.requestAnimationFrame(this.animateSymbol);
  };

  render() {
    const { layerId, beneathLayerId } = this.props;
    const { renderLayer } = this.state;

    return (
      renderLayer && (
        <Layer
          layerId={layerId}
          type="symbol"
          beneathLayerId={beneathLayerId}
          paint={{
            'icon-opacity': 0,
          }}
          layout={{
            'icon-size': 0,
            'icon-image': this.symbolImageId,
            'icon-ignore-placement': true,
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'symbol-avoid-edges': false,
          }}
          minZoom={9}
          Source={
            <LayerSource
              sourceId={this.sourceId}
              type="geojson"
              data={this.getPulseGeoJSONFeatureData()}
              maxZoom={22}
            />
          }
        />
      )
    );
  }
}

export default MapMarkerPulseLayer;
