import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import defaultTheme from '@client/css-modules/StreetViewError.css';
import streetViewErrorImg from '@client/assets/images/street-view-error.png';

type Props = {
  theme: Theme;
};

const StreetViewError = ({ theme }: Props): JSX.Element => (
  <div
    className={theme.StreetViewError}
    role="img"
    aria-labelledby="streetviewtext"
  >
    <div className={theme.OverlayText} id="streetviewtext">
      Uh-oh! Google street view hasn't seen this property
    </div>
    <img
      src={streetViewErrorImg}
      alt="Google Street View vehicle"
      className={theme.StreetViewErrorImg}
    />
  </div>
);

const ThemedStreetViewError = themr(
  'ThemedStreetViewError',
  defaultTheme
)(StreetViewError);
export default ThemedStreetViewError;
