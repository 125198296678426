import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import HeartIconActive from '@client/inline-svgs/heart-active';
import HeartIcon from '@client/inline-svgs/heart';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return HeartIcon;
    default:
      return HeartIconActive;
  }
};

const HeartIconActiveCobranded: React.FC<{ className: string }> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default HeartIconActiveCobranded;
