import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import PillButton from '@client/components/generic/PillButton';
import defaultTheme from '@client/css-modules/LenderCTAButtonPDP.css';
import {
  useLenderCtaPdpConfig,
  useLenderCtaUrlModifier,
} from '@client/hooks/lender-cta-config.hooks';
import { useNativeAppLenderCTAClick } from '@client/hooks/native-app-integration.hooks';
import { useOutboundCtaUrl } from '@client/hooks/outbound-cta-url.hooks';
import { useLoDirectLenderCtaClick } from '@client/hooks/your-team.hooks';
import { getIsInsideNativeApp } from '@client/store/selectors/cobranding.selectors';
import { getActiveHomeownerOrPDPSlug } from '@client/store/selectors/router.selectors';
import { AnalyticsDefaultLenderCTAForPDP } from '@client/store/types/analytics';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';

type Props = {
  theme?: Theme;
  className?: string;
  dataEventName?: string;
  dataParentEventName?: string;
  forwardedRef?: React.MutableRefObject<HTMLDivElement | null>;
};

const LenderCTAButtonPDP: React.FC<Props> = ({
  theme = {},
  className,
  dataEventName,
  dataParentEventName,
  forwardedRef,
}) => {
  const lenderCtaPdpConfig = useLenderCtaPdpConfig();
  const loDirectLenderCtaClick = useLoDirectLenderCtaClick();
  const nativeAppLenderCTAClick = useNativeAppLenderCTAClick();
  const isInsideNativeApp = useSelector(getIsInsideNativeApp);
  const contextualAddressSlug = useSelector(getActiveHomeownerOrPDPSlug);
  const { ctaText, ctaUrl, buttonColor, buttonFontColor, nativeAppNavKey } =
    lenderCtaPdpConfig;
  const modifiedCtaUrl = useLenderCtaUrlModifier(ctaUrl);
  const outboundCtaUrl = useOutboundCtaUrl(modifiedCtaUrl);

  if (!outboundCtaUrl || !ctaText) {
    return null;
  }

  const onClick = () => {
    if (loDirectLenderCtaClick) {
      loDirectLenderCtaClick();
    } else if (isInsideNativeApp && nativeAppNavKey) {
      nativeAppLenderCTAClick(nativeAppNavKey, {
        addressSlug: contextualAddressSlug,
      });
    } else {
      window.open(outboundCtaUrl, '_blank');
    }
  };
  const clickEventDataJson: AnalyticsDefaultLenderCTAForPDP = {
    area: 'pdp',
    cta_url: outboundCtaUrl,
    button_copy: ctaText,
  };

  return (
    <>
      {outboundCtaUrl && ctaText && (
        <div className={theme.LenderCTAButtonPDP} ref={forwardedRef}>
          <PillButton
            data-event-name={dataEventName}
            data-parent-event-name={dataParentEventName}
            data-event-data-json={JSON.stringify(clickEventDataJson)}
            ariaLabel={ctaText}
            className={
              className
                ? classNames(theme.LenderCTAButton, className)
                : theme.LenderCTAButton
            }
            onClick={onClick}
            onKeyDown={onEnterOrSpaceKey(onClick)}
            customBackgroundColor={buttonColor || undefined}
            customTextColor={buttonFontColor || undefined}
          >
            {ctaText}
          </PillButton>
        </div>
      )}
    </>
  );
};

export default themr(
  'ThemrLenderCTAButtonPDP',
  defaultTheme
)(LenderCTAButtonPDP);
