import { Component } from 'react';

let uidCounter = 0;
const UID_PREFIX = 'accessible-uid-';
const generateUid = () => `${UID_PREFIX}${uidCounter++}`;

type Props = {
  children: ({ uid }: { uid: string }) => JSX.Element;
};

type State = {
  hasMounted: boolean;
};

/**
 * HOC that returns a render prop, passing a unique id as the prop `uid` for use in associating
 * elements such as labels and inputs for accessibility compliance
 */
class AccessibleElementUniqueId extends Component<Props, State> {
  uid = generateUid();

  state: State = {
    hasMounted: false,
  };

  componentDidMount() {
    this.setState({ hasMounted: true });
  }

  render() {
    const { children } = this.props;
    const { hasMounted } = this.state;

    return children({
      uid:
        process.env.NODE_ENV === 'test' &&
        !window.__ENABLE_ACCESSIBLE_ELEMENT_UNIQUE_ID
          ? `${UID_PREFIX}${typeof window['globalGenericCounter'] === 'number' ? window['globalGenericCounter']++ : 'test-value'}`
          : hasMounted
          ? this.uid
          : `${UID_PREFIX}premount`,
    });
  }
}

export default AccessibleElementUniqueId;
