import { put, select, take } from 'redux-saga/effects';
import { routeChange } from '@src/redux-saga-router-plus/actions';

import { STATUSES, Status } from '@client/store/constants';
import { View } from '@client/routes/constants';
import { ClaimedHomes } from '@client/store/types/homeowner';
import {
  FETCH_CLAIMED_HOMES_SUCCESS,
  fetchHomeownerUserDataIfNecessary,
} from '@client/store/actions/homeowner.actions';
import {
  getClaimedHomes,
  getClaimedHomesStatus,
} from '@client/store/selectors/homeowner.selectors';
import { getIsLoggedIn } from '@client/store/selectors/auth.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';

export default function* routeHomeownerManageHomes() {
  yield put(fetchHomeownerUserDataIfNecessary());
  const claimedHomesStatus = (yield select(getClaimedHomesStatus)) as Status;
  if (
    claimedHomesStatus === STATUSES.INIT ||
    claimedHomesStatus === STATUSES.LOADING
  ) {
    // Wait for claimed homes response
    yield take(FETCH_CLAIMED_HOMES_SUCCESS);
  }
  const claimedHomes = (yield select(getClaimedHomes)) as ClaimedHomes;
  const claimedHomesKeys = Object.keys(claimedHomes);
  const claimedHomesCount = claimedHomesKeys.length;
  const isLoggedIn = (yield select(getIsLoggedIn)) as boolean;
  const isHomeownerEnabled = (yield select(
    getIsFeatureEnabled('homeowner')
  )) as boolean;

  /*
   * If homeowner feature is disabled, redirect to search
   */
  if (!isHomeownerEnabled) {
    yield put(routeChange({ view: View.SEARCH }));
  }

  /*
   * should redirect to /homeowner splash if no claimed homes on client side
   */
  if (claimedHomesCount === 0 || !isLoggedIn) {
    yield put(routeChange({ view: View.HOMEOWNER }));
  }

  /*
   * should redirect to /homeowner/slug if only one claimed home on client side
   */
  if (claimedHomesCount === 1) {
    yield put(
      routeChange({
        view: View.HOMEOWNER_PROPERTY_DETAILS,
        params: { slug: claimedHomes[claimedHomesKeys[0]].slug },
      })
    );
  }
}
