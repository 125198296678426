import React from 'react';

import AdditionalHomeDetails from '@client/components/AdditionalHomeDetails';
import ConditionalFeature from '@client/components/ConditionalFeature';
import HorizontalSeparator from '@client/components/generic/HorizontalSeparator';
import GrantProgramPDPBanner from '@client/components/GrantProgram/GrantProgramPDPBanner';
import MLSAttributionPDPNWMLSException from '@client/components/MLSAttributionPDPNWMLSException';
import OpenHouseInfo from '@client/components/OpenHouseInfo';
import PDPRightRailCardChase from '@client/components/PDPRightRailCard/PDPRightRailCardChase';
import AvmBreakdownContainer from '@client/containers/avm-breakdown.container';
import BreakoutSectionsContainer from '@client/containers/breakout-sections.container';
import BrokerageAttributionContainer from '@client/containers/brokerage-attribution.container';
import CarouselRowContainer from '@client/containers/carousel-row.container';
import LoanQualificationLetterContainer from '@client/containers/cobrand/chase/loan-qualification-letter-chase.container';
import Footer from '@client/containers/footer.container';
import ListingInfoContainer from '@client/containers/listing-info.container';
import MapPropertyPageContainer from '@client/containers/map-property-page.container';
import NearbyListingsContainer from '@client/containers/nearby-listings.container';
import PropertyIntroContainer from '@client/containers/property-intro.container';
import theme from '@client/css-modules/PropertyPageDesktopContentsChase.css';
import { PropertyPageDesktopContentsProps } from '@client/store/types/cobranded-components/property-page';

/**
 * Contains the contents displayed within the PDP on desktop.
 */
const PropertyPageDesktopContentsChase: React.FC<
  PropertyPageDesktopContentsProps
> = ({ isCanaryUIFeatureEnabled }) => {
  return (
    <div className={theme.PropertyPageDesktopContents}>
      <CarouselRowContainer />
      <div className={theme.Columns}>
        <div className={theme.LeftSide}>
          <MLSAttributionPDPNWMLSException />
          <BrokerageAttributionContainer
            isMinimalStyling={false}
            theme={theme}
          />
          <PropertyIntroContainer addressAsHeader theme={theme} />
          <LoanQualificationLetterContainer theme={theme} />
          <OpenHouseInfo />
          <ListingInfoContainer />
          <AdditionalHomeDetails />
          <ConditionalFeature renderIfFeaturesEnabled={['grant_program']}>
            <GrantProgramPDPBanner />
          </ConditionalFeature>
          <MapPropertyPageContainer />
          <HorizontalSeparator theme={theme} />
          <AvmBreakdownContainer />
          <BreakoutSectionsContainer />
          <NearbyListingsContainer />
        </div>
        <div className={theme.RightSide} data-hc-name="right-side-column">
          <PDPRightRailCardChase
            theme={theme}
            isShowingShareAndSaveSection
            isCanaryUIFeatureEnabled={isCanaryUIFeatureEnabled}
          />
        </div>
      </div>
      <HorizontalSeparator theme={theme} />
      <Footer theme={theme} shouldUseSectionElement />
    </div>
  );
};

export default PropertyPageDesktopContentsChase;
