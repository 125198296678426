export const CLICK_ADD_AGENT = 'click_add_agent';

export const CLICK_FIND_AGENT_BANNER_PDP = 'click_find_agent_banner_pdp';
export const CLICK_FIND_AGENT_BANNER_HO = 'click_find_agent_banner_ho';

export const CLICK_FIND_AGENT_CARD_PDP = 'click_find_agent_card_pdp';
export const CLICK_FIND_AGENT_CARD_HO = 'click_find_agent_card_ho';

export const CLICK_ADD_AGENT_BANNER = 'click_add_agent_banner';
export const CLICK_ADD_AGENT_BANNER_NO_AGENT = 'click_dont_have_agent_banner';

export const CLICK_ADD_AGENT_CARD = 'click_add_agent_card';
export const CLICK_ADD_AGENT_CARD_NO_AGENT = 'click_dont_have_agent_card';
