/**
 * For fetching property photos throughout the app
 */
export default ({ slug, size }) => ({
  query: /* GraphQL */ `query PropertyDetailsPhotos($slug: String, $size: ImageSize) {
    propertyLookup (id: { slug: $slug}) {
      bestPhotos (orderBy: MLS) {
        id
        storageUrl
        prediction
        confidence
        representation (size: $size) {
          httpUrl
          height
          width
        }
      }
      mls {
        regulations {
          logoOverlay
          photosLogoOverlay
        }
      }
    }
  }`,
  variables: {
    slug,
    size,
  },
});
