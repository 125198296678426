import { STATUSES } from '@client/store/constants';

import {
  FETCH_PROPERTY_PHOTOS,
  FETCH_PROPERTY_PHOTOS_SUCCESS,
  FETCH_STREET_VIEW_URL,
  FETCH_STREET_VIEW_URL_SUCCESS,
  PhotoCacheItem,
  PropertyPhotosSizes,
  PropertyPhotosState,
} from '@client/store/types/property-photos';

import {
  FETCH_FULL_PROPERTY_DETAILS_SUCCESS,
  FETCH_INIT_PROPERTY_DETAILS_SUCCESS,
} from '@client/store/actions/property-details.actions';
import { PropertyPhotosAction } from '@client/store/actions/property-photos.actions';
import { PDP_CAROUSEL_PHOTO_SIZE } from '@client/store/types/property';

export const INITIAL_PHOTO_CACHE_ITEM_STATE: PhotoCacheItem = {
  status: STATUSES.INIT,
  bestPhotos: null,
  mlsLogoOverlay: null,
  mlsPhotosLogoOverlay: false,
};

export const INITIAL_PHOTO_SIZES_STATE = {
  SMALL: {},
  MEDIUM: {},
  LARGE: {},
};

export const PROPERTY_PHOTOS_INITIAL_STATE: PropertyPhotosState = {
  propertyPhotoDataSizeCache: INITIAL_PHOTO_SIZES_STATE,
  propertyPhotoStreetViewCache: {},
};

const getPropertyPhotoCacheItemOrInitForSlug = (
  size: PropertyPhotosSizes,
  slug: string,
  state: PropertyPhotosState
): PhotoCacheItem => {
  const item = state.propertyPhotoDataSizeCache[size][slug];
  return item || INITIAL_PHOTO_CACHE_ITEM_STATE;
};

export default function propertyPhotosReducer(
  state = PROPERTY_PHOTOS_INITIAL_STATE,
  action: PropertyPhotosAction
): PropertyPhotosState {
  switch (action.type) {
    case FETCH_PROPERTY_PHOTOS:
      return {
        ...state,
        propertyPhotoDataSizeCache: {
          ...state.propertyPhotoDataSizeCache,
          [action.payload.size]: {
            ...state.propertyPhotoDataSizeCache[action.payload.size],
            [action.payload.slug]: {
              ...getPropertyPhotoCacheItemOrInitForSlug(
                action.payload.size,
                action.payload.slug,
                state
              ),
              status: STATUSES.LOADING,
            },
          },
        },
      };
    case FETCH_PROPERTY_PHOTOS_SUCCESS:
      return {
        ...state,
        propertyPhotoDataSizeCache: {
          ...state.propertyPhotoDataSizeCache,
          [action.payload.size]: {
            ...state.propertyPhotoDataSizeCache[action.payload.size],
            [action.payload.slug]: {
              status: STATUSES.SUCCESS,
              bestPhotos: action.payload.bestPhotos,
              mlsLogoOverlay:
                action.payload?.mls?.regulations?.logoOverlay || null,
              mlsPhotosLogoOverlay:
                action.payload?.mls?.regulations?.photosLogoOverlay || false,
            },
          },
        },
      };
    /* Add photos for the current PDP property to cache */
    case FETCH_INIT_PROPERTY_DETAILS_SUCCESS:
      return {
        ...state,
        propertyPhotoDataSizeCache: {
          ...state.propertyPhotoDataSizeCache,
          [PDP_CAROUSEL_PHOTO_SIZE]: {
            ...state.propertyPhotoDataSizeCache[PDP_CAROUSEL_PHOTO_SIZE],
            [action.payload.slug]: {
              status: STATUSES.SUCCESS,
              bestPhotos: action.payload.data?.bestPhotos,
              mlsLogoOverlay:
                action.payload?.data?.mls?.regulations?.logoOverlay || null,
              mlsPhotosLogoOverlay:
                action.payload?.data?.mls?.regulations?.photosLogoOverlay ||
                false,
            },
          },
        },
      };
    /* Add photos for the current PDP property's nearby listings to cache */
    case FETCH_FULL_PROPERTY_DETAILS_SUCCESS:
      return {
        ...state,
        propertyPhotoDataSizeCache: {
          ...state.propertyPhotoDataSizeCache,
          ['MEDIUM']: {
            ...state.propertyPhotoDataSizeCache['MEDIUM'],
            ...action.payload.data.nearbyListings?.reduce((mem, curr) => {
              if (curr.address?.slug) {
                mem[curr.address.slug] = {
                  status: STATUSES.SUCCESS,
                  bestPhotos: curr.bestPhotos,
                  mlsLogoOverlay: curr.mls?.regulations?.logoOverlay || null,
                  mlsPhotosLogoOverlay:
                    curr.mls?.regulations?.photosLogoOverlay || false,
                };
              }
              return mem;
            }, {}),
          },
        },
      };
    case FETCH_STREET_VIEW_URL:
      return {
        ...state,
        propertyPhotoStreetViewCache: {
          ...state.propertyPhotoStreetViewCache,
          [action.payload.slug]: {
            status: STATUSES.LOADING,
            url: null,
          },
        },
      };
    case FETCH_STREET_VIEW_URL_SUCCESS:
      return {
        ...state,
        propertyPhotoStreetViewCache: {
          ...state.propertyPhotoStreetViewCache,
          [action.payload.slug]:
            action.payload.url === null
              ? {
                  status: STATUSES.FAILED,
                  url: null,
                }
              : {
                  status: STATUSES.SUCCESS,
                  url: action.payload.url,
                },
        },
      };
    default:
      return state;
  }
}
