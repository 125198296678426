import {
  Photo,
  PropertyDetailsPhotosQuery,
} from '@client/store/sagas/queries/types';
import { Status } from '@client/store/types/statuses';

export const FETCH_PROPERTY_PHOTOS = 'FETCH_PROPERTY_PHOTOS';
export const FETCH_PROPERTY_PHOTOS_SUCCESS = 'FETCH_PROPERTY_PHOTOS_SUCCESS';
export const FETCH_STREET_VIEW_URL = 'FETCH_STREET_VIEW_URL';
export const FETCH_STREET_VIEW_URL_SUCCESS = 'FETCH_STREET_VIEW_URL_SUCCESS';

export type PropertyPhotosSizes = 'SMALL' | 'MEDIUM' | 'LARGE';

export type PhotoCacheItem = {
  status: Status;
  bestPhotos: Photo[] | null;
  mlsLogoOverlay: string | null;
  mlsPhotosLogoOverlay: boolean;
};

export type StreetViewCacheItem =
  | {
      status: 'SUCCESS';
      url: string;
    }
  | {
      status: 'LOADING' | 'FAILED';
      url: null;
    };

export type PropertyPhotoDataCache = {
  [key: string]: PhotoCacheItem | undefined;
};
export type PropertyPhotoCacheSizeCache = {
  [key in PropertyPhotosSizes]: PropertyPhotoDataCache;
};

export type PropertyPhotoStreetViewCache = {
  [key: string]: StreetViewCacheItem;
};

export type PropertyPhotosState = {
  propertyPhotoDataSizeCache: PropertyPhotoCacheSizeCache;
  propertyPhotoStreetViewCache: PropertyPhotoStreetViewCache;
};

export type FetchPropertyPhotosAction = {
  type: typeof FETCH_PROPERTY_PHOTOS;
  payload: {
    size: PropertyPhotosSizes;
    slug: string;
  };
};

export type FetchPropertyPhotosSuccessAction = {
  type: typeof FETCH_PROPERTY_PHOTOS_SUCCESS;
  payload: {
    size: PropertyPhotosSizes;
    slug: string;
    bestPhotos: NonNullable<
      PropertyDetailsPhotosQuery['propertyLookup']
    >['bestPhotos'];
    mls: NonNullable<PropertyDetailsPhotosQuery['propertyLookup']>['mls'];
  };
};

export type FetchStreetViewAvailabilityAction = {
  type: typeof FETCH_STREET_VIEW_URL;
  payload: {
    slug: string;
    fullAddress: string;
    imageHeight: number;
    imageWidth: number;
  };
};

export type FetchStreetViewAvailabilitySuccessAction = {
  type: typeof FETCH_STREET_VIEW_URL_SUCCESS;
  payload: {
    slug: string;
    url: string | null;
  };
};
