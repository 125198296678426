import React, { Component } from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import defaultTheme from '@client/css-modules/AuthButtonComeHome.css';
import AccountIcon from '@client/inline-svgs/account-icon';
import MenuNotificationCount from '@client/components/MenuNotificationCount';
import { findDOMNode } from 'react-dom';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import CobrandedDataComponent, {
  CobrandedDataComponentArgs,
} from '@client/components/CobrandedDataComponent';
import CobrandedStyles from '@client/components/CobrandedStyles';
import AccountDetailsMenu from '@client/components/AccountDetailsMenu';
import { View } from '@client/routes/constants';
import { ModalKey } from '@client/store/constants';

type Props = {
  isLoggedIn: boolean;
  handleRouteChange: (routeKey: View) => void;
  handleLogout: () => void;
  handleShowAuthModal: () => void;
  handleAccountAvatarClick: () => void;
  unreadAlertsCount: number;
  theme: Theme;
  handleOpenModal: (modalKey: ModalKey) => void;
};

type State = {
  currentRouteKey: string;
  accountDetailsMenuOpen: boolean;
  shiftAmount: number;
};

class AuthButton extends Component<Props, State> {
  state: State = {
    currentRouteKey: 'initial',
    accountDetailsMenuOpen: false,
    shiftAmount: 0,
  };

  authButtonRef: HTMLElement | undefined = undefined;

  redirectToLoginPage = (): void => {
    this.props.handleShowAuthModal();
  };

  toggleAccountDetailsMenu = (): void => {
    this.props.handleAccountAvatarClick();
    this.setState(
      {
        accountDetailsMenuOpen: !this.state.accountDetailsMenuOpen,
        shiftAmount: 0,
      },
      () => this.authButtonRef && this.authButtonRef.focus()
    );
  };

  localHandleRouteChange = (routeKey: View): void => {
    this.setState({
      currentRouteKey: routeKey,
      accountDetailsMenuOpen: !this.state.accountDetailsMenuOpen,
      shiftAmount: 0,
    });
    this.props.handleRouteChange(routeKey);
  };

  localHandleOpenModal = (modalKey: ModalKey): void => {
    this.setState({
      accountDetailsMenuOpen: !this.state.accountDetailsMenuOpen,
      shiftAmount: 0,
    });
    this.props.handleOpenModal(modalKey);
  };

  localHandleLogout = (): void => {
    this.setState({
      currentRouteKey: 'logout',
      accountDetailsMenuOpen: !this.state.accountDetailsMenuOpen,
      shiftAmount: 0,
    });
    this.props.handleLogout();
  };

  // Shift the tooltip to be completely within the viewport if it's partially offscreen
  // By the time that we shift the tooltip it's already been fit to be narrow enough
  // to fit within the viewport
  getShift = (tooltipElement: HTMLElement | null, width: number): number => {
    const marginFromEdge = 5;

    if (tooltipElement === null) {
      return 0;
    }
    const { left } = tooltipElement.getBoundingClientRect();
    const right = left + width;

    if (left < 0) {
      return Math.abs(left) + marginFromEdge;
    } else if (right > window.innerWidth - marginFromEdge) {
      return -(right - window.innerWidth + marginFromEdge);
    } else {
      return 0;
    }
  };

  /**
   * After tooltip is rendered, check and reposition if not entirely within viewport
   */
  checkPositioningAndDisplay = (ele) => {
    if (ele) {
      const marginFromEdge = 5;
      const tooltipElement = findDOMNode(ele);
      if (!(tooltipElement instanceof HTMLElement)) {
        return;
      }
      const { width } = tooltipElement.getBoundingClientRect();

      // If the tooltip is wider than the screen, size down to the screen
      let newWidth =
        width > window.innerWidth - marginFromEdge
          ? window.innerWidth - marginFromEdge * 2
          : width;

      // Set width, now narrow enough to fit within the viewport
      tooltipElement.style.maxWidth = `${newWidth}px`;

      this.setState({
        shiftAmount: this.getShift(tooltipElement, newWidth),
      });
    }
  };

  setAuthButtonRef = (el) => {
    this.authButtonRef = el;
  };

  render() {
    const { isLoggedIn, theme, unreadAlertsCount } = this.props;
    const { accountDetailsMenuOpen, shiftAmount } = this.state;
    const initialOffset = -50;

    return (
      <CobrandedStyles>
        {({
          authButtonBackgroundColor,
          pillButtonPrimaryButtonBorderRadius,
        }) => (
          <nav className={theme.AuthDropdownContainer}>
            <button
              style={{
                background: authButtonBackgroundColor,
                ...(!isLoggedIn ? { width: '100%' } : {}),
                borderRadius: isLoggedIn
                  ? '28px'
                  : pillButtonPrimaryButtonBorderRadius,
              }}
              ref={this.setAuthButtonRef}
              type="button"
              aria-haspopup={isLoggedIn}
              aria-expanded={isLoggedIn && accountDetailsMenuOpen}
              aria-label={isLoggedIn ? 'Log out' : 'Join or log in'}
              onClick={
                isLoggedIn
                  ? this.toggleAccountDetailsMenu
                  : this.redirectToLoginPage
              }
              onKeyDown={onEnterOrSpaceKey(
                isLoggedIn
                  ? this.toggleAccountDetailsMenu
                  : this.redirectToLoginPage
              )}
              className={theme.AuthButtonContainer}
            >
              <div
                className={classNames(theme.AuthButtonLabel, {
                  [theme.LoggedInAuthButtonLabel]: isLoggedIn,
                })}
              >
                Join or Log in
              </div>
              <AccountIcon
                className={classNames(theme.LoggedOutAuthIcon, {
                  [theme.LoggedInAuthIcon]: isLoggedIn,
                })}
              />
              <CobrandedDataComponent>
                {({
                  customizationData: {
                    is_showing_unread_alerts_count_on_account_icon,
                  },
                }: CobrandedDataComponentArgs) => (
                  <>
                    {is_showing_unread_alerts_count_on_account_icon && (
                      <MenuNotificationCount
                        key={'notification-count'}
                        theme={theme}
                        count={unreadAlertsCount}
                      />
                    )}
                  </>
                )}
              </CobrandedDataComponent>
            </button>
            {accountDetailsMenuOpen && isLoggedIn && (
              <>
                <div
                  ref={this.checkPositioningAndDisplay}
                  className={theme.AuthDropdown}
                  style={{
                    left: `${initialOffset + shiftAmount}px`,
                    right: `${initialOffset - shiftAmount}px`,
                  }}
                >
                  <AccountDetailsMenu
                    handleCloseMenu={this.toggleAccountDetailsMenu}
                    handleRouteChange={this.localHandleRouteChange}
                    handleLogout={this.localHandleLogout}
                    unreadAlertsCount={unreadAlertsCount}
                    handleOpenModal={this.localHandleOpenModal}
                  />
                  <div
                    className={theme.Arrow}
                    style={{ left: `calc(53% - ${shiftAmount}px)` }}
                  />
                </div>
                <div
                  className={theme.BackdropScreen}
                  onClick={this.toggleAccountDetailsMenu}
                />
              </>
            )}
          </nav>
        )}
      </CobrandedStyles>
    );
  }
}

const ThemedAuthBtn = themr('AuthButton', defaultTheme)(AuthButton);
export default ThemedAuthBtn;
