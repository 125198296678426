import { useRequestATourState } from '@client/hooks/request-a-tour.hooks';
import CircleButton from '@client/components/generic/CircleButton';
import PersonOnCircle from '@client/inline-svgs/person-on-circle';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import defaultTheme from '@client/css-modules/RequestATourButton.css';

type Props = {
  theme: Theme;
};

const RequestATourButton = ({ theme }: Props) => {
  const { openRequestATourModal } = useRequestATourState();

  return (
    <div className={theme.RequestATourButton}>
      <CircleButton
        data-event-name="click_request_a_tour_PDP_button"
        data-parent-event-name="click_request_a_tour"
        ariaLabel="Request tour"
        theme={theme}
        onClick={openRequestATourModal}
      >
        <PersonOnCircle className={theme.PersonOnCircle} />
      </CircleButton>
      <div className={theme.ButtonLabel}>Request tour</div>
    </div>
  );
};

const ThemedRequestATourButton = themr(
  'RequestATourButton',
  defaultTheme
)(RequestATourButton);
export default ThemedRequestATourButton;
