import { Theme, themr } from '@friendsofreactjs/react-css-themr';

import defaultTheme from '@client/css-modules/MLSCopyrightText.css';

type MLSCopyrightTextProps = {
  theme: Theme;
  logoUrl?: string | null;
  copyrightText?: string | null;
  disclaimerText?: string | null;
  refreshedDateTime: string;
};
/**
 * Copyright text required on the PDP by MLS contracts
 */
const MLSCopyrightText: React.FC<MLSCopyrightTextProps> = ({
  theme,
  logoUrl,
  copyrightText,
  disclaimerText,
  refreshedDateTime,
}) => (
  <div className={theme.MLSCopyrightText} data-hc-name={'mls-copyright'}>
    {logoUrl && <img src={logoUrl} alt="MLS logo" className={theme.MLSLogo} />}
    {disclaimerText && <p>{disclaimerText}</p>}
    {copyrightText && <p>{copyrightText}</p>}
    {refreshedDateTime && (
      /**
       * timestamp is in UTC from server but date() interprets it to browser's timezone.
       * This caused error: Hydration failed because the initial UI does not match
       * what was rendered on the server. It's inevitably different between the server
       * and client so we need to silence the hydration mismatch warning by adding
       * suppressHydrationWarning={true} to the element.
       */
      <p suppressHydrationWarning>Updated on {refreshedDateTime}</p>
    )}
  </div>
);

const ThemedMLSCopyrightText = themr(
  'MLSCopyrightText',
  defaultTheme
)(MLSCopyrightText);
export default ThemedMLSCopyrightText;
