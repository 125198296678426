import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import Layer from '@hc/hcmaps-mapboxgl/lib/components/Layer';
import LayerSource from '@hc/hcmaps-mapboxgl/lib/components/LayerSource';
import { Api } from '@hc/hcmaps-mapboxgl/lib/components/Maps';
import geojsonExtent from '@mapbox/geojson-extent';
import * as Tabs from '@radix-ui/react-tabs';
import classNames from 'classnames';
import { LngLatBounds, MapboxGeoJSONFeature, MapMouseEvent } from 'mapbox-gl';
import { Component } from 'react';

import HC_CONSTANTS from '@client/app.config';
import CobrandedComponent, {
  CobrandedComponentArgs,
} from '@client/components/CobrandedComponent';
import FlatDivideTabList from '@client/components/generic/FlatDivideTabList';
import Maps from '@client/components/Maps';
import MissingLotLinesPlaceholderCobranded from '@client/components/MissingLotLinesPlaceholder/MissingLotLinesPlaceholderCobranded';
import StreetViewError from '@client/components/StreetViewError';
import StreetViewContainer from '@client/containers/street-view.container';
import defaultTheme from '@client/css-modules/MapPropertyPage.css';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { MAP_VIEW_TYPES } from '@client/store/constants';
import { LayerMetric, LAYER_GROUP_KEYS } from '@client/store/map-constants';
import { AnalyticsEventAddress } from '@client/store/types/analytics';
import {
  LatitudeLongitudeObject,
  MapMarkerGeoJSONFeature,
  PositionArray,
} from '@client/store/types/maps';
import {
  computeBoundsArrayFromLatLngPoints,
  offsetLatLngPointByMeters,
  padBoundsByPercentage,
} from '@client/utils/maps.utils';
import { flattenDeepWhile } from '@client/utils/object.utils';
import { MultiPolygon, Polygon } from 'geojson';

const MAP_VIEW_OPTIONS = [
  {
    index: 0,
    label: 'Lot',
    type: MAP_VIEW_TYPES.LOT,
    'data-event-name': 'click_lot_view',
  },
  {
    index: 1,
    label: 'Map View',
    type: MAP_VIEW_TYPES.MAP,
    'data-event-name': 'click_map_view',
  },
  {
    index: 2,
    label: 'Street View',
    type: MAP_VIEW_TYPES.STREET,
    'data-event-name': 'click_google_street_view',
  },
];
const FIT_PROPERTY_OFFSET = [0, -60];
const LOT_LAYER_SOURCE_ID = 'property-lot-boundary-source';
const DEFAULT_ACTIVE_LAYER_GROUP = LAYER_GROUP_KEYS.PRICE;

const MapViewTabPanel = ({ children, theme, value, ...rest }) => (
  <Tabs.Content value={value} className={theme.MapViewTabPanel} {...rest}>
    <div className={theme.MapSection}>{children}</div>
  </Tabs.Content>
);

/* tslint:disable-next-line */
const Popup =
  typeof window === 'undefined' ? (
    <div />
  ) : (
    require('@hc/hcmaps-mapboxgl/lib/components/Popup').default
  );

type TriggerProp = string | number | boolean | null;

type Props = {
  avmFSD: number | null;
  markerFeatures: MapMarkerGeoJSONFeature[];
  disableDragging: boolean;
  shouldShowHeatmapsWhenTogglingMapView: boolean;
  fitMapTrigger: TriggerProp;
  fitPopupPadding: {
    x: number;
    y: number;
  };
  isShowingMapZoomControls: boolean;
  /* Required since heatmaps are being used */
  mapGeoRequestAccessToken: string;
  /* The name of a school district boundary that should be highlighted on init of schools layer */
  autoHighlightSchoolDistrictName: string | null;
  /* Whether parcel data has been loaded based upon the full-property details request completing */
  isParcelDataLoaded: boolean;
  /* A geoJSON feature representing the parcel boundary */
  parcelGeoJSON: MultiPolygon | Polygon;
  propertyLocation: LatitudeLongitudeObject | null;
  handleGetMapLayerLegendBreaks: (
    bounds: LngLatBounds,
    zoom: number,
    activeLayerMetric: LayerMetric | null
  ) => void;
  handleMapDrag: (
    isLayerActive: boolean,
    address: AnalyticsEventAddress
  ) => void;
  handleMapZoom: (
    isLayerActive: boolean,
    zoomedIn: boolean,
    address: AnalyticsEventAddress
  ) => void;
  handleMoveEnd: (api) => void;
  handleHasToggledHeatmaps: (hasEnabledHeatmaps: boolean) => void;
  noPopupForAddressSlug: string;
  lotSizeDescription: string | null;
  theme: Theme;
  propertyAddress: AnalyticsEventAddress;
};

type State = {
  selectedMapViewTab: string;
  selectedMapViewTabIndex: number;
  streetViewFailed: boolean;
  lotPopupPosition: PositionArray | null;
};

class MapPropertyPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.isLayerActive = props.shouldShowHeatmapsWhenTogglingMapView;
  }

  state: State = {
    selectedMapViewTab: MAP_VIEW_OPTIONS[0].type,
    streetViewFailed: false,
    selectedMapViewTabIndex: MAP_VIEW_OPTIONS[0].index,
    lotPopupPosition: null,
  };

  /* Denote when map is changing location due to mounting vs. due to user interaction */
  mapIsChangingLocationProgrammatically: boolean = true;
  currentZoom: number = 0;
  isLayerActive: boolean = false;

  onStreetViewFail = () => {
    this.setState({ streetViewFailed: true });
  };

  getSetMapLocation = (): { lat: number; lng: number } | null => {
    const { propertyLocation } = this.props;
    return propertyLocation
      ? offsetLatLngPointByMeters(
          { lat: propertyLocation.latitude, lng: propertyLocation.longitude },
          FIT_PROPERTY_OFFSET
        )
      : null;
  };

  handleMapViewTabsChange = (value: string) => {
    MAP_VIEW_OPTIONS.find((opt) => {
      if (opt.type === value) {
        this.setState({
          selectedMapViewTabIndex: opt.index,
          selectedMapViewTab: opt.type,
        });
      }
    });
  };

  handleMoveEnd = (
    api: Api,
    e: MapMouseEvent | null,
    { activeLayerMetric }: { activeLayerMetric: LayerMetric | null }
  ) => {
    const map = api.getMap();
    if (map) {
      const zoom = map.getZoom();
      const bounds = map.getBounds();
      this.props.handleGetMapLayerLegendBreaks(bounds, zoom, activeLayerMetric);
    }
    if (this.mapIsChangingLocationProgrammatically) {
      this.mapIsChangingLocationProgrammatically = false;
    } else {
      this.props.handleMapDrag(this.isLayerActive, this.props.propertyAddress);
    }
  };

  handleZoomStart = (api: Api) => {
    const map = api.getMap();
    if (map) {
      this.currentZoom = map.getZoom();
    }
  };

  handleZoomEnd = (api: Api) => {
    const map = api.getMap();
    if (map && !this.mapIsChangingLocationProgrammatically) {
      this.props.handleMapZoom(
        this.isLayerActive,
        map.getZoom() > this.currentZoom,
        this.props.propertyAddress
      );
    }
  };

  handleAutoActiveSchoolDistrictActivate = (
    api: Api,
    schoolDistrictFeature: MapboxGeoJSONFeature
  ) => {
    const map = api.getMap();
    /* Include the subject property in the bounds */
    const setMapLocation = this.getSetMapLocation();
    if (
      !setMapLocation ||
      !schoolDistrictFeature ||
      !schoolDistrictFeature.properties
    ) {
      return;
    }

    const boundsToFit = computeBoundsArrayFromLatLngPoints([
      [
        schoolDistrictFeature.properties.lat,
        schoolDistrictFeature.properties.lon,
      ],
      [setMapLocation.lat, setMapLocation.lng],
    ]);
    /* Pad to show more of the school district and make room for the school popup */
    const paddedBoundsToFit = padBoundsByPercentage(
      {
        southWest: { lat: boundsToFit[0][0], lng: boundsToFit[0][1] },
        northEast: { lat: boundsToFit[1][0], lng: boundsToFit[1][1] },
      },
      2.0
    );
    const formattedBoundsToFit: [[number, number], [number, number]] = [
      [paddedBoundsToFit.southWest.lat, paddedBoundsToFit.southWest.lng],
      [paddedBoundsToFit.northEast.lat, paddedBoundsToFit.northEast.lng],
    ];

    if (map && !map.isMoving()) {
      api.setMapPositionToBounds(formattedBoundsToFit);
    } else if (map) {
      map.once('moveend', () => {
        api.setMapPositionToBounds(formattedBoundsToFit);
      });
    }
  };

  handleLotMapLoaded = (api: Api) => {
    /* In a prod build, the `moveend` event does NOT fire on map init, so using this method, bound
     * to the `load` event, to trigger it on init */
    if (process.env.NODE_ENV === 'production') {
      this.handleMoveEnd(api, null, { activeLayerMetric: null });
    }

    const map = api.getMap();
    const { parcelGeoJSON, lotSizeDescription } = this.props;

    if (!map) {
      throw new Error(
        'Attempting to handle property map mount without map available'
      );
    } else {
      const WSENBounds = geojsonExtent(parcelGeoJSON);
      map.scrollZoom.disable();
      map.fitBounds(
        [
          [WSENBounds[0], WSENBounds[1]],
          [WSENBounds[2], WSENBounds[3]],
        ],
        {
          padding: 100,
          maxZoom: 19,
        }
      );
    }

    /* Calculate popup position from the parcel coordinates in the geoJSON object */
    if (parcelGeoJSON && lotSizeDescription) {
      const flatCoordinateList = flattenDeepWhile(
        parcelGeoJSON.coordinates,
        (value) =>
          !(typeof value[0] === 'number' && typeof value[1] === 'number')
      );
      let highestLatitude = flatCoordinateList[0][1];
      let westMostLongitude = flatCoordinateList[0][0];
      let eastMostLongitude = flatCoordinateList[0][0];

      for (let i = 1; i < flatCoordinateList.length; i++) {
        const currentCoord = flatCoordinateList[i];

        if (currentCoord[1] > highestLatitude) {
          highestLatitude = currentCoord[1];
        }
        if (currentCoord[0] < westMostLongitude) {
          westMostLongitude = currentCoord[0];
        }
        if (currentCoord[0] > eastMostLongitude) {
          eastMostLongitude = currentCoord[0];
        }
      }

      this.setState({
        lotPopupPosition: [
          highestLatitude,
          westMostLongitude +
            Math.abs(
              Math.abs(westMostLongitude) - Math.abs(eastMostLongitude)
            ) /
              2,
        ],
      });
    }
  };

  handlePropertyMapLoaded = (api: Api) => {
    /* In a prod build, the `moveend` event does NOT fire on map init, so using this method, bound
     * to the `load` event, to trigger it on init */
    if (process.env.NODE_ENV === 'production') {
      this.handleMoveEnd(api, null, { activeLayerMetric: null });
    }

    if (api) {
      const map = api.getMap();

      if (map) {
        map.scrollZoom.disable();
      }
    }
  };

  handleActiveLayerChange = (metric: LayerMetric | null) => {
    this.isLayerActive = !!metric;
    this.props.handleHasToggledHeatmaps(!!metric);
  };

  render() {
    const {
      selectedMapViewTabIndex,
      streetViewFailed,
      selectedMapViewTab,
      lotPopupPosition,
    } = this.state;

    const {
      avmFSD,
      markerFeatures,
      disableDragging,
      fitMapTrigger,
      fitPopupPadding,
      isShowingMapZoomControls,
      mapGeoRequestAccessToken,
      autoHighlightSchoolDistrictName,
      isParcelDataLoaded,
      parcelGeoJSON,
      propertyLocation,
      lotSizeDescription,
      shouldShowHeatmapsWhenTogglingMapView,
      theme,
    } = this.props;

    const setMapLocation = this.getSetMapLocation();

    return (
      <CobrandedComponent>
        {({
          styles: { pdpLotMapLotIndicatorColor, pdpLotMapTooltipColor },
          utils: { getShouldDisplayAVMForFSD },
        }: CobrandedComponentArgs) => {
          const isShowingAVMForFSD = getShouldDisplayAVMForFSD(avmFSD);
          return (
            <section className={theme.MapPropertyPage}>
              <Tabs.Root
                className={theme.MapViewTabs}
                value={selectedMapViewTab}
                onValueChange={this.handleMapViewTabsChange}
              >
                <FlatDivideTabList
                  theme={theme}
                  options={MAP_VIEW_OPTIONS}
                  selectedTab={selectedMapViewTab}
                  data-parent-event-name={PARENT_EVENTS.CLICK_PDP_VIEW}
                />
                <div className={theme.MapViewTabPanels}>
                  <MapViewTabPanel
                    value={MAP_VIEW_OPTIONS[0].type}
                    theme={theme}
                    className={theme.LotSizeMap}
                  >
                    {selectedMapViewTabIndex === 0 && (
                      <>
                        {isParcelDataLoaded ? (
                          parcelGeoJSON ? (
                            <Maps
                              theme={theme}
                              tileURL={
                                HC_CONSTANTS.MAP_STYLE_URL_SATELLITE_SIMPLE
                              }
                              setMapLocation={
                                propertyLocation
                                  ? [
                                      propertyLocation.latitude,
                                      propertyLocation.longitude,
                                    ]
                                  : null
                              }
                              handleMapBackgroundLoaded={
                                this.handleLotMapLoaded
                              }
                              zoomWhenPositioningToPoint={18}
                              allowScrollZoom
                              markerFeatures={markerFeatures}
                              disableDragging={disableDragging}
                              fitMapTrigger={fitMapTrigger}
                              showZoomControls={isShowingMapZoomControls}
                              geoRequestAccessToken={mapGeoRequestAccessToken}
                              customSources={[
                                <LayerSource
                                  key="property-lot-layer-source"
                                  sourceId={LOT_LAYER_SOURCE_ID}
                                  type="geojson"
                                  data={{
                                    type: 'Feature',
                                    geometry: parcelGeoJSON,
                                    properties: {},
                                  }}
                                  maxZoom={22}
                                />,
                              ]}
                              customLayers={[
                                <Layer
                                  key="property-lot-boundary-fill"
                                  layerId="property-lot-boundary-fill"
                                  type="fill"
                                  paint={{
                                    'fill-color': pdpLotMapLotIndicatorColor,
                                    'fill-opacity': 0.5,
                                  }}
                                  minZoom={10}
                                  sourceId={LOT_LAYER_SOURCE_ID}
                                />,
                                <Layer
                                  key="property-lot-boundary-line"
                                  layerId="property-lot-boundary-line"
                                  type="line"
                                  paint={{
                                    'line-color': pdpLotMapLotIndicatorColor,
                                    'line-width': 2,
                                  }}
                                  minZoom={10}
                                  sourceId={LOT_LAYER_SOURCE_ID}
                                />,
                              ]}
                              customPopup={
                                lotPopupPosition && (
                                  <Popup
                                    Content={
                                      <div
                                        className={theme.LotPopupContent}
                                        style={{
                                          backgroundColor:
                                            pdpLotMapTooltipColor,
                                        }}
                                      >
                                        {lotSizeDescription}
                                        <div
                                          className={theme.LotPopupContentTip}
                                          style={{
                                            backgroundColor:
                                              pdpLotMapTooltipColor,
                                          }}
                                        ></div>
                                      </div>
                                    }
                                    fitPopupInMapViewport={false}
                                    position={lotPopupPosition}
                                    mapboxOptions={{
                                      offset: {
                                        bottom: [0, -20],
                                      },
                                      closeButton: false,
                                      closeOnClick: false,
                                    }}
                                    handleClosePopup={() => false}
                                  />
                                )
                              }
                            />
                          ) : (
                            <MissingLotLinesPlaceholderCobranded
                              theme={theme}
                            />
                          )
                        ) : null}
                      </>
                    )}
                  </MapViewTabPanel>
                  <MapViewTabPanel
                    value={MAP_VIEW_OPTIONS[1].type}
                    theme={theme}
                  >
                    {selectedMapViewTabIndex === 1 && (
                      <Maps
                        theme={theme}
                        setMapLocation={
                          setMapLocation
                            ? [setMapLocation.lat, setMapLocation.lng]
                            : null
                        }
                        zoomWhenPositioningToPoint={15}
                        allowScrollZoom
                        markerFeatures={markerFeatures}
                        disableDragging={disableDragging}
                        fitMapTrigger={fitMapTrigger}
                        fitPopupPadding={fitPopupPadding}
                        handleMoveEnd={this.handleMoveEnd}
                        handleZoomStart={this.handleZoomStart}
                        handleZoomEnd={this.handleZoomEnd}
                        handleMapBackgroundLoaded={this.handlePropertyMapLoaded}
                        defaultActiveLayerGroup={
                          shouldShowHeatmapsWhenTogglingMapView
                            ? DEFAULT_ACTIVE_LAYER_GROUP
                            : null
                        }
                        autoHighlightSchoolDistrictName={
                          autoHighlightSchoolDistrictName
                        }
                        handleAutoActiveSchoolDistrictActivate={
                          this.handleAutoActiveSchoolDistrictActivate
                        }
                        handleActiveLayerChange={this.handleActiveLayerChange}
                        hasMapLayersControl
                        useFloatingLayerGroupsControl
                        allowShowingBottomControl
                        showZoomControls={isShowingMapZoomControls}
                        geoRequestAccessToken={mapGeoRequestAccessToken}
                      />
                    )}
                  </MapViewTabPanel>
                  <MapViewTabPanel
                    value={MAP_VIEW_OPTIONS[2].type}
                    theme={theme}
                  >
                    {selectedMapViewTabIndex === 2 && (
                      <>
                        {streetViewFailed ? (
                          <StreetViewError theme={theme} />
                        ) : (
                          <StreetViewContainer
                            onFailCallback={this.onStreetViewFail}
                          />
                        )}
                      </>
                    )}
                  </MapViewTabPanel>
                </div>
              </Tabs.Root>
              <hr
                className={classNames(theme.SectionDivider, {
                  [theme.SectionDividerWithReducedMargin]: !isShowingAVMForFSD,
                })}
              />
            </section>
          );
        }}
      </CobrandedComponent>
    );
  }
}

const ThemedMapPropertyPage = themr(
  'MapPropertyPage',
  defaultTheme
)(MapPropertyPage);
export default ThemedMapPropertyPage;
