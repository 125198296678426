import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PillButton from '@client/components/generic/PillButton';
import defaultTheme from '@client/css-modules/ContactLOButton.css';
import { useOutboundCtaUrl } from '@client/hooks/outbound-cta-url.hooks';
import { useLoDirectLenderCtaClick } from '@client/hooks/your-team.hooks';
import MessageIcon from '@client/inline-svgs/message-icon';
import { logOutThenRedirect } from '@client/store/actions/auth.actions';
import { getIsLoggedIn } from '@client/store/selectors/auth.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';

type ContactLOButtonProps = {
  theme: Theme;
  style?: React.CSSProperties;
  buttonText: string;
  buttonCTAUrl: string | null;
  handleClickWithExternalCtaLink?: () => void;
  handleClickWithoutExternalCtaLink?: () => void;
  /* For event reporting */
  ['data-event-name']?: string;
  ['data-parent-event-name']?: string;
};

const ContactLOButton: FC<ContactLOButtonProps> = ({
  theme,
  style,
  buttonText,
  buttonCTAUrl,
  handleClickWithExternalCtaLink,
  handleClickWithoutExternalCtaLink,
  ['data-event-name']: dataEventName,
  ['data-parent-event-name']: dataParentEventName,
}) => {
  const shouldLogoutOnPartnerCtaClick = useSelector(
    getIsFeatureEnabled('should_logout_on_partner_cta_click')
  );
  const isLoggedIn = useSelector(getIsLoggedIn);
  const dispatch = useDispatch();
  const loDirectLenderCtaClick = useLoDirectLenderCtaClick();

  const outboundCtaUrl = useOutboundCtaUrl(buttonCTAUrl);
  const redirectToExternalUrl = (ctaLink: string) => {
    if (isLoggedIn && shouldLogoutOnPartnerCtaClick) {
      dispatch(logOutThenRedirect(ctaLink));
    } else {
      window.open(ctaLink, '_blank');
    }
  };

  const handleContactBtnClick = (ctaLink: string | null) => {
    if (loDirectLenderCtaClick) {
      loDirectLenderCtaClick();
    } else if (ctaLink) {
      if (handleClickWithExternalCtaLink) {
        handleClickWithExternalCtaLink();
      }
      redirectToExternalUrl(ctaLink);
    } else if (handleClickWithoutExternalCtaLink) {
      handleClickWithoutExternalCtaLink();
    }
  };

  return (
    <PillButton
      data-event-name={dataEventName}
      data-parent-event-name={dataParentEventName}
      dataHcName="contact-lo-button"
      theme={theme}
      className={theme.ContactButton}
      style={style}
      ariaLabel={`Contact ${buttonText}`}
      onKeyDown={onEnterOrSpaceKey(() => handleContactBtnClick(outboundCtaUrl))}
      onClick={() => handleContactBtnClick(outboundCtaUrl)}
    >
      <MessageIcon className={theme.MessageIcon} />
      <div className={theme.ButtonText}>Contact {buttonText}</div>
    </PillButton>
  );
};

const ThemedContactLOButton = themr(
  'ContactLOButton',
  defaultTheme
)(ContactLOButton);
export default ThemedContactLOButton;
