import { FILTER_KEYS } from '@client/store/filter-constants';

/**
 * Partial keys used to create event names dynamically
 * i.e. generated name: `${EVENTS.CLICK_FILTER}_list_price`
 */
export const PARTIAL_EVENTS = {
  CLICK_PROPERTY_DETAILS: 'click_property_details',
  CLICK_PROPERTY_DETAILS_ACTIVITIES: 'click_property_details_activities',
  CLICK_BOTTOM_NAV: 'click_bottom_nav',
};

export const EVENTS = {
  CLICK_BUYER_PDP_CONTACT:
    'click_buyer_pdp_contact' as 'click_buyer_pdp_contact', // user clicks the contact button within the LO card on the property detail page
  CLICK_BUYING_POWER_CANCEL:
    'click_buying_power_cancel' as 'click_buying_power_cancel', // CHASE ONLY - user clicks to cancel buying power.
  CLICK_BUYING_POWER_OK: 'click_buying_power_ok' as 'click_buying_power_ok', // CHASE ONLY - user clicks to accept buying power.
  CLICK_COMEHOME_LOGO: 'click_comehome_logo' as 'click_comehome_logo', // user clicks the Comehome logo in the subnav
  CLICK_FIND_AGENT_HEADER:
    'click_find_agent_header' as 'click_find_agent_agent', // Click the find an agent header menu.
  CLICK_HEATMAP_CRIME: 'click_heatmap_crime' as 'click_heatmap_crime', // Click on the crime heatmap overlay on the search results page.
  CLICK_HEATMAP_FORECAST: 'click_heatmap_forecast' as 'click_heatmap_forecast', // Click on the forecast heatmap on the search results page.
  CLICK_HEATMAP_HOME_DRAG:
    'click_heatmap_home_drag' as 'click_heatmap_home_drag', // User drags the map with the heatmap turned on within the search results page.
  CLICK_HEATMAP_HOME_ZOOM:
    'click_heatmap_home_zoom' as 'click_heatmap_home_zoom', // User zooms the map with the heatmap turned on within the search results page.
  CLICK_HEATMAP_ITEM: 'click_heatmap_item' as 'click_heatmap_item',
  CLICK_HEATMAP_PRICE: 'click_heatmap_price' as 'click_heatmap_price', // Click on the price heatmap overlay on the search results page.
  CLICK_HEATMAP_SCHOOL: 'click_heatmap_school' as 'click_heatmap_school', // Click on the school heatmap overlay on the search results page.
  CLICK_LENDER_CTA: 'click_lender_cta' as 'click_lender_cta', // click the CTA on lender experiences (for example "get pre-approved on lendco")
  CLICK_LENDER_CTA_SETUP_BUYING_POWER:
    'click_lender_cta_setup_buying_power' as 'click_lender_cta_setup_buying_power', // CHASE ONLY - click to set up buying power
  CLICK_LENDER_CTA_OWNER: 'click_lender_cta_owner' as 'click_lender_cta_owner', // Click loan advisor cta for homeowner
  CLICK_LO_CONTACT_CALL: 'click_lo_contact_call' as 'click_lo_contact_call', // User clicks the phone icon within the LO card on mobile.
  CLICK_LO_CONTACT_EMAIL: 'click_lo_contact_email' as 'click_lo_contact_email', // User clicks the email address within the LO card on mobile.
  CLICK_MOBILE_FILTER_ADD_FILTER:
    'click_mobile_filter_add_filter' as 'click_mobile_filter_add_filter', // user clicks the add filter button on the mobile view.
  CLICK_MOBILE_FILTER_APPLY:
    'click_mobile_filter_apply' as 'click_mobile_filter_apply', // user clicks to apply the selected filters on mobile.
  CLICK_MOBILE_FILTER_BACK:
    'click_mobile_filter_back' as 'click_mobile_filter_back', // user clicks the back button in the mobile filters screen.
  CLICK_MOBILE_FILTER_CANCEL:
    'click_mobile_filter_cancel' as 'click_mobile_filter_cancel', // user clicks the cancel button within the mobile filters view.
  CLICK_MOBILE_FILTER_ITEM:
    'click_mobile_filter_item' as 'click_mobile_filter_item',
  CLICK_MOBILE_FILTER_SHOW_RESULTS:
    'click_mobile_filter_show_results' as 'click_mobile_filter_show_results', // user clicks the show results button on filters in mobile.
  CLICK_NAV_LOGOUT: 'click_nav_logout' as 'click_nav_logout', // user clicks to logout within the navigation.
  CLICK_NEARBY_PROPERTIES_WATCH:
    'click_nearby_properties_watch' as 'click_nearby_properties_watch', // user clicks to save a property from the nearby properties module.
  CLICK_OUR_BROKERS_PAGE: 'click_our_brokers_page' as 'click_our_brokers_page', // page load of the brokerage page
  CLICK_OUR_BROKERS_SEARCH:
    'click_our_brokers_search' as 'click_our_brokers_search', // User clicks on the search field
  CLICK_OUR_BROKERS_GET_STARTED:
    'click_our_brokers_get_started' as 'click_our_brokers_get_started', // user clicks the get started button
  CLICK_OUR_BROKERS_FIRST_NAME:
    'click_our_brokers_first_name' as 'click_our_brokers_first_name', // user enters his or her first name
  CLICK_OUR_BROKERS_LAST_NAME:
    'click_our_brokers_last_name' as 'click_our_brokers_last_name', // user enters his or her last name
  CLICK_OUR_BROKERS_EMAIL:
    'click_our_brokers_email' as 'click_our_brokers_email', // user enters his or her email address
  CLICK_OUR_BROKERS_MESSAGE:
    'click_our_brokers_message' as 'click_our_brokers_message', // user enters his or her message
  CLICK_OUR_BROKERS_SEND_CTA:
    'click_our_brokers_send_cta' as 'click_our_brokers_send_cta', // user clicks send button
  CLICK_OWNER_EQUITY: 'click_owner_equity' as 'click_owner_equity', // clicks the owner equity module.
  CLICK_OWNER_LO_MODAL_FIRST_NAME:
    'click_owner_lo_modal_first_name' as 'click_owner_lo_modal_first_name', // User enters and completes first name field within the LO modal on homeowner.
  CLICK_OWNER_LO_MODAL_LAST_NAME:
    'click_owner_lo_modal_last_name' as 'click_owner_lo_modal_last_name', // User enters and completes last name field within the LO modal on homeowner.
  CLICK_OWNER_LO_MODAL_EMAIL_ADDRESS:
    'click_owner_lo_modal_email_address' as 'click_owner_lo_modal_email_address', // user enters an email address within the LO contact form on homeowner.
  CLICK_OWNER_LO_MODAL_MESSAGE:
    'click_owner_lo_modal_message' as 'click_owner_lo_modal_message', // user enters a message within the LO contact form on homeowner
  CLICK_OWNER_LO_MODAL_PHONE_NUMBER:
    'click_owner_lo_modal_phone_number' as 'click_owner_lo_modal_phone_number', // user enters a phone number within the LO contact form on homeowner.
  CLICK_PDP_OFF_MARKET_SEE_LOAN_PRODUCTS:
    'click_pdp_off_market_see_loan_products' as 'click_pdp_off_market_see_loan_products', // CHASE ONLY - click to see loan products on an off market home
  CLICK_PROPERTY_CARD: 'click_owner_show_details' as 'click_owner_show_details', // user clicks to view a property card
  CLICK_PROPERTY_DETAILS_GENERAL_DETAILS:
    'click_property_details_general_details' as 'click_property_details_general_details',
  CLICK_PROPERTY_INFO: 'click_property_info' as 'click_property_info', // click to view more info on the property
  CLICK_SAVED_SEARCH_REMOVE_PROPERTY:
    'click_saved_search_remove_property' as 'click_saved_search_remove_property', // Removes a property on the saved search page
  CLICK_SAVED_SEARCHES: 'click_saved_searches' as 'click_saved_searches', // click to view the saved search tap
  CLICK_SEARCH_FILTERS: 'click_search_filters' as 'click_search_filters', // click to view search filters
  CLICK_SEARCH_MAP_AUTOCOMPLETE_SEARCH_BUTTON:
    'click_search_map_autocomplete_search_button' as 'click_search_map_autocomplete_search_button', // user autocompletes a search
  CLICK_SEARCH_MAP_MARKER:
    'click_search_map_marker' as 'click_search_map_marker', // user clicks a map pin on the search results page.
  CLICK_SEARCH_SORT_MENU_OPEN:
    'click_sort_search_menu_open' as 'click_sort_search_menu_open', // user opens the sorting menu
  CLICK_SEARCH_STILL_EXPLORE_LOAN_OPTIONS:
    'click_search_still_explore_loan_options' as 'click_search_still_explore_loan_options', // CHASE ONLY - user clicks to explore loan options on the search page
  CLICK_SEE_LETTER: 'click_see_letter' as 'click_see_letter', // CHASE ONLY - user clicks to see the qualification letter
  CLICK_SETTINGS_SAVED_SEARCH:
    'click_settings_saved_search' as 'click_settings_saved_search', // user clicks to view a saved search
  CLICK_SHARE_COPY_LINK: 'click_share_copy_link' as 'click_share_copy_link', // user clicks to copy a link
  CLICK_PDP_SHARE_MESSAGE_SEND_CTA_SUCCESS:
    'click_pdp_share_message_send_cta_success' as 'click_pdp_share_message_send_cta_success', // successfully shared property from property details page using share functionality
  CLICK_PDP_SHARE_MESSAGE_SEND_CTA_ERROR:
    'click_pdp_share_message_send_cta_error' as 'click_pdp_share_message_send_cta_error', // Share property from property details page failed due an error
  CLICK_SHOW_CANT_FIND_ADDRESS_MODAL:
    'click_show_cant_find_address_modal' as 'click_show_cant_find_address_modal', // use views the can't find an address modal
  CLICK_TALK_TO_AGENT_TOP_AGENTS_RANKED:
    'click_talk_to_agent_top_agents_ranked', // TAR ONLY - Click main CTA on top agents ranked
  CLICK_NOTIFICATIONS_LENDER_LINK:
    'click_notifications_lender_link' as 'click_notifications_lender_link', // user navigates to lender homesite from notifications page
  CLICK_WATCHLIST_TAB: 'click_watchlist_tab' as 'click_watchlist_tab', // user clicks the saved property tab on the saved properties page
  CLICK_WELCOME_SEARCH_FIELD:
    'click_welcome_search_field' as 'click_welcome_search_field', // CHASE ONLY - User clicks the welcome search field
  NO_ADDRESS_RESULTS_FOR_QUERY:
    'no_address_results_for_query' as 'no_address_results_for_query', // cannot find an address when an address is passed to us
  NO_PROPERTIES_IN_WATCHLIST:
    'no_properties_in_watchlist' as 'no_properties_in_watchlist', // No properties saved in the watch list
  PAGE_VIEW: 'pageview' as 'pageview',
  SEARCH_LIST_HAS_SEARCH_PROPERTY_RESULTS:
    'search_list_has_search_property_results' as 'search_list_has_search_property_results', // properties load on the search results page
  SEARCH_LIST_NO_SEARCH_PROPERTY_RESULTS:
    'search_list_no_search_property_results' as 'search_list_no_search_property_results', // no results on the search list page
  SIGNIN_ERROR: 'signin_error' as 'signin_error', // there is an error on sign in
  GENERIC_TERMS_MODAL_CONFIRMED:
    'generic_terms_modal_confirmed' as 'generic_terms_modal_confirmed', // When the above terms and conditions modal is agreed to
};

export type AnalyticsEvent = (typeof EVENTS)[keyof typeof EVENTS];

/**
 * Parent event categories to be sent with events for aid in grouping events during analysis.
 * Every event belongs to exactly one parent event.
 */
export const PARENT_EVENTS = {
  CLICK_ACCOUNT: 'click_account' as 'click_account',
  CLICK_AD_CTA: 'click_ad_cta' as 'click_ad_cta', // user clicks the button on the Ad card
  CLICK_ALERTS: 'click_alerts' as 'click_alerts',
  CLICK_APP_DOWNLOAD: 'click_app_download' as 'click_app_download',
  CLICK_BANNER_SEARCH: 'click_banner_search' as 'click_banner_search',
  CLICK_BOTTOM_NAV: 'click_bottom_nav' as 'click_bottom_nav',
  CLICK_BUYER_FINANCE_CTA:
    'click_buyer_finance_cta' as 'click_buyer_finance_cta',
  CLICK_BUYER_LOAN_OPTIONS:
    'click_buyer_loan_options' as 'click_buyer_loan_options',
  CLICK_COMPARABLES: 'click_comparables' as 'click_comparables',
  CLICK_CTA: 'click_cta' as 'click_cta',
  CLICK_EMAIL_LINK: 'click_email_link' as 'click_email_link',
  CLICK_EMAIL_MODAL: 'click_email_modal' as 'click_email_modal',
  CLICK_EMAIL_SAVED_SEARCH:
    'click_email_saved_search' as 'click_email_saved_search',
  CLICK_ERROR: 'click_error' as 'click_error',
  CLICK_EXPLORE_OTHER_PROPERTIES:
    'click_explore_other_properties' as 'click_explore_other_properties',
  CLICK_FINANCE_CTA: 'click_finance_cta',
  CLICK_FIND_AGENT: 'click_find_agent' as 'click_find_agent',
  CLICK_HEADER_BANNER: 'click_header_banner' as 'click_header_banner',
  CLICK_HEATMAP: 'click_heatmap' as 'click_heatmap',
  CLICK_KNOWN_AGENT: 'click_known_agent' as 'click_known_agent',
  CLICK_LENDER_NAV: 'click_lender_nav' as 'click_lender_nav',
  CLICK_LENDER_CTA: 'click_lender_cta' as 'click_lender_cta',
  CLICK_LENDER_REFERRAL: 'click_lender_referral' as 'click_lender_referral',
  CLICK_LO_CONTACT: 'click_lo_contact' as 'click_lo_contact',
  CLICK_LODIRECT: 'click_lodirect' as 'click_lodirect',
  CLICK_NAV: 'click_nav' as 'click_nav',
  CLICK_MAP: 'click_map' as 'click_map',
  CLICK_MORE_FILTERS: 'click_more_filters' as 'click_more_filters',
  CLICK_OTHER_LISTED: 'click_other_listed' as 'click_other_listed',
  CLICK_OUR_BROKERS: 'click_our_brokers' as 'click_our_brokers',
  CLICK_OWNER_LOAN_OPTIONS:
    'click_owner_loan_options' as 'click_owner_loan_options',
  CLICK_OWNER_AVM: 'click_owner_avm' as 'click_owner_avm',
  CLICK_OWNER_CLAIM: 'click_owner_claim' as 'click_owner_claim',
  CLICK_OWNER_DASHBOARD: 'click_owner_dashboard' as 'click_owner_dashboard',
  CLICK_OWNER_EDIT: 'click_owner_edit' as 'click_owner_edit',
  CLICK_OWNER_EQUITY: 'click_owner_equity' as 'click_owner_equity',
  CLICK_OWNER_FINANCE_CTA:
    'click_owner_finance_cta' as 'click_owner_finance_cta',
  CLICK_OWNER_IMPROVEMENT:
    'click_owner_improvement' as 'click_owner_improvement',
  CLICK_OWNER_MANAGE: 'click_owner_manage' as 'click_owner_manage',
  CLICK_OWNER_PROFINDER: 'click_owner_profinder' as 'click_owner_profinder',
  CLICK_OWNER_SALES: 'click_owner_sales' as 'click_owner_sales',
  CLICK_OWNER_SELL: 'click_owner_sell' as 'click_owner_sell',
  CLICK_OWNER_SNAPSHOT: 'click_owner_snapshot' as 'click_owner_snapshot',
  CLICK_OWNER_REFI: 'click_owner_refi' as 'click_owner_refi',
  CLICK_OWNER_VERIFY: 'click_owner_verify' as 'click_owner_verify',
  CLICK_PARTNER_AGENT: 'click_partner_agent' as 'click_partner_agent',
  CLICK_PDP_MODULES: 'click_pdp_modules' as 'click_pdp_modules',
  CLICK_PDP_VIEW: 'click_pdp_view' as 'click_pdp_view',
  CLICK_PROPERTY_CARD: 'click_property_card' as 'click_property_card',
  CLICK_PROPERTY_DETAILS_OWNER:
    'click_property_details_owner' as 'click_property_details_owner',
  CLICK_PROPERTY_DETAILS: 'click_property_details' as 'click_property_details',
  CLICK_PROPERTY_WATCHING:
    'click_property_watching' as 'click_property_watching',
  CLICK_QUICK_FILTER: 'click_quick_filter' as 'click_quick_filter',
  CLICK_SAVE_SEARCH: 'click_save_search' as 'click_save_search',
  CLICK_SEARCH: 'click_search' as 'click_search',
  CLICK_SEARCH_FILTERS: 'click_search_filters' as 'click_search_filters',
  CLICK_SETTINGS: 'click_settings' as 'click_settings',
  CLICK_SIGN_IN: 'click_sign_in' as 'click_sign_in',
  CLICK_SIGNUP: 'click_signup' as 'click_signup',
  CLICK_SHARE: 'click_share' as 'click_share',
  CLICK_SORT: 'click_sort' as 'click_sort',
  CLICK_SORT_BATHS: 'click_sort_baths' as 'click_sort_baths',
  CLICK_SORT_BEDS: 'click_sort_beds' as 'click_sort_beds',
  CLICK_SORT_CHVALUE: 'click_sort_chvalue' as 'click_sort_chvalue',
  CLICK_SORT_DAYSMARKET: 'click_sort_daysmarket' as 'click_sort_daysmarket',
  CLICK_SORT_LISTPRICE: 'click_sort_listprice' as 'click_sort_listprice',
  CLICK_SORT_SQFT: 'click_sort_sqft' as 'click_sort_sqft',
  CLICK_STORE: 'click_store' as 'click_store',
  CLICK_REMOVE_PROPERTY: 'click_remove_property' as 'click_remove_property',
  CLICK_TALK_TO_AGENT_TOP_AGENTS_RANKED:
    'click_talk_to_agent_top_agents_ranked',
  CLICK_TOOLTIP: 'click_tooltip' as 'click_tooltip',
  CLICK_URL_CTA: 'click_url_cta' as 'click_url_cta',
  CLICK_VIEW_OPTION: 'click_view_option' as 'click_view_option',
  CLICK_WATCHLIST: 'click_watchlist' as 'click_watchlist',
  CLICK_YOUR_TEAM: 'click_your_team' as 'click_your_team',
  EVENT_STREAM: 'event_stream' as 'event_stream',
  PMI: 'pmi' as 'pmi',
  PROPENSITY_TO_SELL: 'propensity_to_sell' as 'propensity_to_sell',
  TOAST_ERROR: 'toast_error' as 'toast_error',
  VIEW_KNOWN_AGENT: 'view_known_agent' as 'view_known_agent',
};

export type ParentEvent = (typeof PARENT_EVENTS)[keyof typeof PARENT_EVENTS];

// Used for Google Analytics events
export const GA_EVENTS = {
  PAGEVIEW: 'pageview',
  MODALVIEW: 'modalview',
  EVENT: 'event',
  OUTBOUND_LINK: 'outboundLink',
  EXCEPTION: 'exception',
};

// Used for the filters in the Map Search, separated out because the keys are taken from
// the filter-constants file
export const FILTER_EVENTS = {
  [FILTER_KEYS.PROPERTY_TYPE]: 'click_search_filters_property_type',
  [FILTER_KEYS.MLS_STATE]: 'click_search_filters_listing_status',
  [FILTER_KEYS.AVM_PRICE_MIN_MAX]: 'click_search_filters_avm',
  [FILTER_KEYS.BEDS_MIN_MAX]: 'click_search_filters_beds',
  [FILTER_KEYS.BATHS_MIN_MAX]: 'click_search_filters_baths',
  [FILTER_KEYS.SQFT_MIN_MAX]: 'click_search_filters_sq_ft',
  [FILTER_KEYS.LOT_AREA_MIN_MAX]: 'click_search_filters_lot_size',
  [FILTER_KEYS.YEAR_BUILT_MIN_MAX]: 'click_search_filters_year_built',
  [FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX]: 'click_search_filters_days_on_market',
  [FILTER_KEYS.BEST_PRIMARY_SCHOOL_PERCENTILE_MIN_MAX]:
    'click_search_filters_elementary_school',
  [FILTER_KEYS.BEST_MIDDLE_SCHOOL_PERCENTILE_MIN_MAX]:
    'click_search_filters_middle_school',
  [FILTER_KEYS.BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX]:
    'click_search_filters_high_school',
  [FILTER_KEYS.LIST_PRICE_MIN_MAX]: 'click_search_filters_list_price',
  [FILTER_KEYS.GEO_PRECISION_MIN_MAX]: 'click_search_filters_geo_precision',
  [FILTER_KEYS.HPI_MIN_MAX]: 'click_search_filters_forecast',
  [FILTER_KEYS.PRICE_PER_SQFT50_MIN_MAX]:
    'click_search_filters_price_per_sq_ft',
  [FILTER_KEYS.CRIME_COUNTY_PERCENTILE_MIN_MAX]: 'click_search_filters_crime',
  [FILTER_KEYS.MARKET_GRADE_MIN_MAX]: 'click_search_filters_market_grade',
  [FILTER_KEYS.YEAR_RISK_OF_DECLINE_MIN_MAX]:
    'click_search_filters_risk_decline',
  [FILTER_KEYS.LIST_TO_AVM_PRICE_MIN_MAX]: 'click_search_filters_underpriced',
  [FILTER_KEYS.RENTAL_ESTIMATE_MIN_MAX]: 'click_search_filters_rent_avm',
  [FILTER_KEYS.RENTAL_YIELD_MIN_MAX]: 'click_search_filters_rental_yield',
  FILTER_DISMISS: 'filter_dismiss',
  FILTER_RESULTS: 'filter_results',
  FILTER_PROPERTY: 'filter_property',
  CLICK_BUYING_POWER: 'filter_buying_power',
};
