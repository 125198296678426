import { NoSavedSearchesProps } from '@client/store/types/saved-searches';
import theme from '@client/css-modules/NoSavedSearchesChase.css';
import PillButton from '@client/components/generic/PillButton';
import SavedSearchesPageDefaultIconCobranded from '@client/components/SavedSearchesPageDefaultIcon/SavedSearchesPageDefaultIconCobranded';
import { useSelector } from 'react-redux';
import { getWatchlistPageConfig } from '@client/store/selectors/cobranding.selectors';

const NoSavedSearchesChase: React.FC<NoSavedSearchesProps> = ({
  handleAccessFilters,
}) => {
  const watchlistPageConfig = useSelector(getWatchlistPageConfig);
  return (
    <>
      <div className={theme.NoSavedSearchesTabletAndDesktop}>
        <div className={theme.NoResultsBackgroundImage}>
          <div className={theme.NoResultsCard}>
            <div className={theme.NoResultsIcon}>
              <SavedSearchesPageDefaultIconCobranded
                className={theme.Icon}
                aria-hidden
              />
            </div>
            <div className={theme.NoResultsText}>
              {watchlistPageConfig?.savedSearchesDescription ||
                "No saved searches yet. Curate groupings of filters to surface homes that fit your customized search criteria."
              }
            </div>
            <PillButton
              ariaLabel="Access filters"
              className={theme.AccessFiltersButton}
              onClick={handleAccessFilters}
            >
              Access filters
            </PillButton>
          </div>
        </div>
      </div>
      <div className={theme.NoSavedSearchesMobile}>
        <div className={theme.NoResultsNotice}>
          <div className={theme.NoResultsIcon}>
            <SavedSearchesPageDefaultIconCobranded className={theme.Icon} />
          </div>
          <div className={theme.NoResultsText}>
            {watchlistPageConfig?.savedSearchesDescription ||
              (<>No homes saved yet.
                <br />
                Start searching for homes to add.
              </>
              )
            }
          </div>
        </div>
        <PillButton
          ariaLabel="Access filters"
          className={theme.AccessFiltersButton}
          onClick={handleAccessFilters}
        >
          Access filters
        </PillButton>
      </div>
    </>
  );
};

export default NoSavedSearchesChase;
