import { connect } from 'react-redux';
import React, { Component } from 'react';

import CobrandComponents, {
  ComponentDefMapping,
} from '@client/components/AvmIcons';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { CobrandId, EmptyDiv } from '@client/store/types/cobranding';

type Props = {
  cobrandId: CobrandId;
};

const mapStateToProps = (state) => ({
  cobrandId: getCobrandId(state),
});

type CobrandComponentKeys = keyof ComponentDefMapping;
type NeverIfNull<T> = T extends EmptyDiv ? never : T;
type PropsOf<T> = NeverIfNull<T> extends React.ComponentType<infer P> ? P : any;
type ComponentTypeOf<T extends CobrandComponentKeys> = ComponentDefMapping[T];

/**
 * HOC that returns a cobranded component given the component's key as defined in `cobrand-components.ts`.
 * This allows a single container to easily reference the correct component for each cobrand.
 * Example usage in container:
 *
 * export default connect(mapStateToProps, {})(cobrandedComponentForKey('Header'));
 */
export default function <T extends CobrandComponentKeys>(
  CobrandedComponentKey: T
): ComponentTypeOf<T> {
  class CobrandedComponentWrapper extends Component<
    Props & PropsOf<ComponentTypeOf<T>>
  > {
    render() {
      const { cobrandId, ...rest } = this.props;

      const CobrandedComponent: any = CobrandComponents[CobrandedComponentKey];

      /* This should never happen when using this HOC in TypeScripted components */
      if (!CobrandedComponent) {
        throw new Error(
          `Component not found for key ${CobrandedComponentKey} in cobrand ${cobrandId}`
        );
      }
      return <CobrandedComponent {...rest} />;
    }
  }
  const c: any = connect(mapStateToProps, {});
  return c(CobrandedComponentWrapper);
}
