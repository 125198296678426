import React, { FC } from 'react';
import { Theme } from '@friendsofreactjs/react-css-themr';

type CopyRightProps = {
  theme?: Theme;
  company?: string;
};

const Copyright: FC<CopyRightProps> = ({ theme = {}, company }) => {
  const copyRightCompany = company ?? 'HouseCanary';
  const getCurrentYear = () => new Date().getFullYear();
  return (
    <span className={theme.Copyright} data-hc-name={'copyright-date'}>
      © {getCurrentYear()} {copyRightCompany}
    </span>
  );
};

export default Copyright;
