import { useEffect, useState } from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';

import PhotoPlaceholderContainer from '@client/containers/photo-placeholder.container';
import ThumbnailCarousel from '@client/components/ThumbnailCarousel';
import defaultTheme from '@client/css-modules/PropertyPhoto.css';
import { LatitudeLongitudeObject } from '@client/store/types/maps';
import {
  PropertyPhotosSizes,
  FetchPropertyPhotosAction,
} from '@client/store/types/property-photos';
import PulseLoader from '@client/components/PulseLoader';

type Props = {
  photos: string[];
  photoSize: PropertyPhotosSizes;
  streetViewLocation: LatitudeLongitudeObject | null;
  photosStatusIsInit: boolean;
  photosStatusIsLoading: boolean;
  addressSlug: string | null;
  fullAddress?: string | null;
  handleFetchPropertyPhotos: ({
    size,
    slug,
  }: FetchPropertyPhotosAction['payload']) => void;
  theme: Theme;
  shouldUseThumbnailCarousel?: boolean;
  className?: string;
  mapTileSize?: [number, number];
  isHidingMapTilePin?: boolean;
  mlsLogoUrl?: string | null;
};

/**
 * A component that fetches and displays either a single photo for an address slug or a
 * Street View or map placeholder for that address slug should photos for it not exist
 */
function PropertyPhoto({
  addressSlug,
  photos,
  photosStatusIsLoading,
  photosStatusIsInit,
  theme,
  streetViewLocation,
  shouldUseThumbnailCarousel,
  className,
  mapTileSize,
  fullAddress,
  isHidingMapTilePin,
  mlsLogoUrl,
  photoSize,
  handleFetchPropertyPhotos,
}: Props) {
  const [isMounted, setIsMounted] = useState(false);

  const showLoadingIndicator = photos.length
    ? false
    : !isMounted ||
      (isMounted && (photosStatusIsInit || photosStatusIsLoading));
  const classes = className
    ? classNames(className, theme.PropertyPhoto)
    : theme.PropertyPhoto;

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (addressSlug && photosStatusIsInit) {
      handleFetchPropertyPhotos({ size: photoSize, slug: addressSlug });
    }
  }, [addressSlug]);

  return (
    <div className={classes}>
      <PulseLoader isLoading={showLoadingIndicator}>
        {photos && photos.length ? (
          shouldUseThumbnailCarousel ? (
            <ThumbnailCarousel
              alwaysShowControls
              className={theme.Photo}
              theme={theme}
              urls={photos}
              alt={fullAddress ? `Photo of ${fullAddress}` : 'Property photo'}
              title="Go to property"
              overlay={mlsLogoUrl}
              fullAddress={fullAddress}
            />
          ) : (
            <div
              className={theme.Photo}
              style={{ backgroundImage: `url(${photos[0]})` }}
            >
              {mlsLogoUrl && (
                <div
                  className={theme.MlsLogoOverlay}
                  data-hc-name="mls-logo"
                  style={{ backgroundImage: `url(${mlsLogoUrl})` }}
                />
              )}
            </div>
          )
        ) : (
          <PhotoPlaceholderContainer
            isHidingMapTilePin={isHidingMapTilePin}
            mapTileSize={mapTileSize}
            addressSlug={addressSlug}
            fullAddress={fullAddress || null}
            streetViewLocation={streetViewLocation}
          />
        )}
      </PulseLoader>
    </div>
  );
}

const ThemedPropertyPhoto = themr('PropertyPhoto', defaultTheme)(PropertyPhoto);
export default ThemedPropertyPhoto;
