import geojsonExtent from '@mapbox/geojson-extent';

import { Action } from '@client/store/actions';
import {
  CLEAR_CURRENT_SAVED_SEARCH,
  CREATE_SAVED_SEARCH,
  CREATE_SAVED_SEARCH_ERROR,
  CREATE_SAVED_SEARCH_SUCCESS,
  DELETE_SAVED_SEARCH_SUCCESS,
  FETCH_SAVED_SEARCHES,
  FETCH_SAVED_SEARCHES_ERROR,
  FETCH_SAVED_SEARCHES_SUCCESS,
  SELECT_SAVED_SEARCH,
  UPDATE_SAVED_SEARCH,
  UPDATE_SAVED_SEARCH_SUCCESS,
} from '@client/store/actions/saved-search.actions';
import { Status, STATUSES } from '@client/store/constants';
import { LatLngObject } from '@client/store/types/maps';
import {
  SavedSearchAPIResponse,
  SavedSearchItem,
} from '@client/store/types/saved-searches';
import {
  consumerAPIFiltersToStateFilters,
  savedSearchItemModel,
} from '@client/utils/filters.utils';

export const SAVED_SEARCH_INITIAL_STATE: SavedSearchState = {
  status: STATUSES.INIT,
  savingStatus: STATUSES.INIT,
  searches: [],
  hasSavedCurrentSearch: false,
  errorMessage: null,
  selected: null,
};

export type SavedSearchState = {
  status: Status;
  savingStatus: Status;
  searches: SavedSearchItem[];
  hasSavedCurrentSearch: boolean;
  errorMessage: null | string;
  selected: null | number;
};

/**
 * Return state after saved searches are fetched from API successfully
 */
export const getSavedSearchesFetchSuccessState = (
  data: SavedSearchAPIResponse[]
) => ({
  status: STATUSES.SUCCESS,
  searches: data
    .map((item) => savedSearchItemModel(item))
    // Newest first
    .sort(
      (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
    ),
});

/**
 * Return state after a saved search is selected from the UI
 */
export const getSavedSearchSelectState = (searchId: number) => ({
  selected: searchId,
  hasSavedCurrentSearch: true,
});

/**
 * Transform a saved-search item into data ready to be applied to the search page
 */
export const marshallSavedSearchResponse = (
  search: SavedSearchItem,
  isDisplayMultiFamilySearchFiltersEnabled: boolean
) => {
  const { sw_lat, sw_lng, ne_lat, ne_lng, geom, ...restFilters } =
    search.filter;
  /* These are both non-null values here since they're gotten via the geom if they returned null from the API */
  let southWest = { lat: sw_lat, lng: sw_lng } as LatLngObject;
  let northEast = { lat: ne_lat, lng: ne_lng } as LatLngObject;

  if (geom) {
    const WSENBounds = geojsonExtent(geom);
    southWest = { lat: WSENBounds[1], lng: WSENBounds[0] };
    northEast = { lat: WSENBounds[3], lng: WSENBounds[2] };
  }
  const constrainedToPlace = restFilters.place_id
    ? {
        placeId: restFilters.place_id,
        mlsCoverage: null,
        city: null,
        zipcode: null,
        state: null,
      }
    : null;
  const placeGeoJSON = geom || null;
  const placeGeoJSONDescription = search.name;
  const filters = consumerAPIFiltersToStateFilters(
    restFilters,
    isDisplayMultiFamilySearchFiltersEnabled
  );

  return {
    filters,
    southWest,
    northEast,
    constrainedToPlace,
    placeGeoJSON,
    placeGeoJSONDescription,
  };
};

export default function savedSearchReducer(
  state = SAVED_SEARCH_INITIAL_STATE,
  action: Action
): SavedSearchState {
  switch (action.type) {
    case FETCH_SAVED_SEARCHES:
      return {
        ...state,
        status: STATUSES.LOADING,
      };
    case CREATE_SAVED_SEARCH:
      return {
        ...state,
        savingStatus: STATUSES.UPDATING,
        hasSavedCurrentSearch: true,
        errorMessage: SAVED_SEARCH_INITIAL_STATE.errorMessage,
      };
    case CREATE_SAVED_SEARCH_SUCCESS:
      const newSearch = savedSearchItemModel(action.payload.response);
      return {
        ...state,
        selected: newSearch.searchId,
        savingStatus: STATUSES.SUCCESS,
        searches: [newSearch, ...state.searches],
      };
    /* Happens once per app session */
    case FETCH_SAVED_SEARCHES_SUCCESS:
      return {
        ...state,
        ...getSavedSearchesFetchSuccessState(action.payload.data),
      };
    case FETCH_SAVED_SEARCHES_ERROR:
      return {
        ...state,
        status: STATUSES.ERROR,
        searches: SAVED_SEARCH_INITIAL_STATE.searches,
      };
    case DELETE_SAVED_SEARCH_SUCCESS:
      return {
        ...state,
        searches: state.searches.filter(
          (item: SavedSearchItem) => item.searchId !== action.payload.searchId
        ),
      };
    case SELECT_SAVED_SEARCH:
      return {
        ...state,
        ...getSavedSearchSelectState(action.payload.searchId),
      };
    case UPDATE_SAVED_SEARCH:
      return {
        ...state,
        /* Setting this right away so make the UI respond immediately to click */
        hasSavedCurrentSearch: true,
      };
    case UPDATE_SAVED_SEARCH_SUCCESS:
      // replace the out dated search:
      const updatedSearch = savedSearchItemModel(action.payload.search);
      return {
        ...state,
        searches: state.searches.map((search: SavedSearchItem) => {
          if (search.searchId === updatedSearch.searchId) {
            return updatedSearch;
          } else {
            return search;
          }
        }),
      };
    /* Clears checkbox, allowing for new saved search to be created */
    case CLEAR_CURRENT_SAVED_SEARCH:
      return {
        ...state,
        hasSavedCurrentSearch: SAVED_SEARCH_INITIAL_STATE.hasSavedCurrentSearch,
        selected: SAVED_SEARCH_INITIAL_STATE.selected,
        errorMessage: SAVED_SEARCH_INITIAL_STATE.errorMessage,
      };
    case CREATE_SAVED_SEARCH_ERROR:
      return {
        ...state,
        /* Setting to init since we're triggering a 5-second error message via the `errorMessage` property,
         * after which we need to be ready for the next save attempt */
        savingStatus: STATUSES.INIT,
        errorMessage: action.payload.message,
        hasSavedCurrentSearch: SAVED_SEARCH_INITIAL_STATE.hasSavedCurrentSearch,
      };
    default:
      return state;
  }
}
