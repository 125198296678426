import React, { ReactElement } from 'react';

import {
  dollarsFormatter,
  numberFormatter,
} from '@client/utils/formatter.utils';
import { pluralize } from '@client/utils/string.utils';
import { RegressionData } from '@client/store/types/regression-data';
import { AvmFactor, MedianValues } from '@client/store/types/avm-break-down';

/**
 * DEEP_DIVES_VIRTUAL_LOCATION_MAP_ZOOM is used to calculate estimated bounds to check if heatmap
 * data for average price exists (To determine if location map is displayed in deep dives).
 * Edge Case: Estimated bounds could be catching a tiny piece of data on the edge of the map,
 * causing it to look like there is no heat map data, inorder to overcome this, DEEP_DIVES_VIRTUAL_LOCATION_MAP_ZOOM
 * is not same as DEEP_DIVES_LOCATION_MAP_ZOOM (greater by 4 to narrow down the zoom right around
 * subject property to overcome the edge case scenario).
 */
export const DEEP_DIVES_VIRTUAL_LOCATION_MAP_ZOOM = 19;

export const VIRTUAL_MAP_DIMENSIONS = {
  height: 390,
  width: 500,
};

export const DEEP_DIVES_LOCATION_MAP_ZOOM = 15;

export const DEEP_DIVES_LOCATION_MAP_MIN_ZOOM = 9;

export const buildMedianValues = (
  medianValues: MedianValues
): ReactElement[] => {
  const {
    medianBlockPrice,
    medianAge,
    medianLivingArea,
    medianBathrooms,
    poolPercent,
    basementPercent,
    medianBedrooms,
    medianLotSize,
  } = medianValues;
  /* These need to be handled as an array that we push to, so that we avoid
   * passing null values to the Slider components props.children: */
  let values: ReactElement[] = [];

  const hasMedianValues =
    medianAge ||
    medianBlockPrice ||
    medianLivingArea ||
    medianLotSize ||
    medianBathrooms ||
    medianBedrooms ||
    poolPercent >= 0.5 ||
    basementPercent >= 0.5;

  const boldenText = (str: string): ReactElement => <strong>{str}</strong>;
  const compileValues = (
    key: string | null,
    textEl: string | ReactElement
  ): void => {
    values.push(
      key ? <span key={key}>{textEl}</span> : (textEl as ReactElement)
    );
  };

  if (hasMedianValues) {
    compileValues('median-intro', 'Typical homes in this area ');

    if (medianAge) {
      const calculatedMedianAge = new Date().getFullYear() - medianAge;
      compileValues(
        'median-age',
        boldenText(`were built in ${calculatedMedianAge}`)
      );
    }

    if (medianBlockPrice) {
      compileValues(
        null,
        <span key="median-block-price">
          costs {boldenText(dollarsFormatter(medianBlockPrice))}
        </span>
      );
    }

    if (medianLivingArea) {
      compileValues(
        null,
        <span key="median-living-area">
          are on average{' '}
          {boldenText(numberFormatter(medianLivingArea) + ' sq. ft.')}
        </span>
      );
    }

    if (medianLotSize) {
      compileValues(
        null,
        <span key="median-lot-size">
          sit on a {boldenText(numberFormatter(medianLotSize) + 'sq. ft. ')} lot
        </span>
      );
    }

    if (medianBedrooms) {
      compileValues(
        null,
        <span key="median-bedrooms">
          have{' '}
          {boldenText(`${medianBedrooms} ${pluralize('bed', medianBedrooms)}`)}
        </span>
      );
    }

    if (medianBathrooms) {
      compileValues(
        null,
        <span key="median-bathrooms">
          have{' '}
          {boldenText(
            `${medianBathrooms} ${pluralize('bathroom', medianBathrooms)}`
          )}
        </span>
      );
    }

    if (basementPercent >= 0.5) {
      compileValues('median-basement', boldenText('have a basement'));
    }

    if (poolPercent >= 0.5) {
      compileValues('median-pool', boldenText('have a pool'));
    }
  }

  return values.map((Comp, idx) => {
    const isLastIdx = idx === values.length - 1;
    const key = `${Comp.key}-wrapper`;

    if (!isLastIdx && idx > 1) {
      return <span key={key}>, {Comp}</span>;
    } else if (isLastIdx && values.length > 3) {
      return <span key={key}>, and {Comp}.</span>;
    } else if (isLastIdx && values.length === 3) {
      return <span key={key}> and {Comp}.</span>;
    } else {
      return Comp;
    }
  });
};

export const getHasAvmFactors = (
  avmValue: number | null,
  avmFactors: AvmFactor[] | null
): boolean => {
  return !!(avmValue && avmFactors && avmFactors.length > 0);
};

export const getShowDataDeepDives = (
  hasAvmFactors: boolean,
  isLoadingRegressionsData: boolean,
  regressionsData: RegressionData[],
  showLocationInDataDeepDives: boolean
): boolean => {
  return (
    showLocationInDataDeepDives ||
    (hasAvmFactors && !isLoadingRegressionsData && regressionsData.length >= 1)
  );
};
