import { ALL_SEARCH_VIEWS } from '@client/routes/constants';
import { getRoutesConfig } from '@client/routes/index';
import { searchPersistMapLocation } from '@client/store/actions/search.actions';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { updateGenericViewId } from '@client/store/slices/analytics-data.slice';
import { isGenericView } from '@client/utils/analytics.utils';
import {
  getPreviousParams,
  getPreviousView,
} from '@src/redux-saga-router-plus/selectors';
import { get, isEqual } from 'lodash';
import { call, put, select } from 'redux-saga/effects';

function scrollToTopOfPage() {
  window && window.scrollTo(0, 0);
}

export function* setCurrentViewId(previousView, currentView) {
  if (previousView !== currentView && isGenericView(currentView)) {
    yield put(updateGenericViewId());
  }
}

export function* applyRouteChangeSideEffects({ view, params }) {
  const previousView = yield select(getPreviousView);
  const previousParams = yield select(getPreviousParams);

  const homepageSelector = yield call(getIsFeatureEnabled, 'homepage');
  const isHomepageEnabled = yield select(homepageSelector);
  const routeConfig = getRoutesConfig({
    isHomepageEnabled,
  });
  const newTitle = get(routeConfig[view], 'title');

  /* Persist the current search map location if navigating from a search route to another route */
  if (
    ALL_SEARCH_VIEWS.includes(previousView) &&
    !ALL_SEARCH_VIEWS.includes(view)
  ) {
    yield put(searchPersistMapLocation());
  }

  /* Set the page title if either the view or view params are changing */
  if (
    previousView &&
    (view !== previousView ||
      !isEqual(params, previousParams) ||
      newTitle !== get(routeConfig[previousView], 'title'))
  ) {
    yield call(scrollToTopOfPage);
  }

  yield call(setCurrentViewId, previousView, view);

  /* this accounts for situations when multiple components using body-scroll-lock are nested, in which case the scroll lock isn't cleared correctly when the components unmount during a route transition. */
  if (
    previousView &&
    view !== previousView &&
    document.body.style.overflow === 'hidden'
  ) {
    document.body.style.overflow = '';
  }
}
