import { Alert } from '@client/utils/reach-alert';
import React from 'react';

import defaultTheme from '@client/css-modules/SearchMapNotificationNoMLSChase.css';
import WarningIcon from '@client/inline-svgs/warning-icon';
import PureAlert from '@client/components/generic/PureAlert';
import mapMLSCoverageFillPattern from '@client/assets/images/mls-layer-pattern.png';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';

type Props = { theme: Theme };

const SearchMapNotificationNoMLSChase = ({ theme }: Props) => {
  return (
    <div className={theme.MapNotificationInner}>
      <WarningIcon />
      <Alert type="assertive">
        <div className={theme.MLSCoverageNotification}>
          <PureAlert type="polite">
            <h2 aria-label="We have limited data availability for the current map area.">
              Limited data coverage
            </h2>
            <p aria-hidden="true">
              Data availability in the patterned area is limited.
            </p>
          </PureAlert>
          <img
            aria-hidden="true"
            crossOrigin="anonymous"
            src={mapMLSCoverageFillPattern}
            alt="Limited MLS coverage fill pattern"
          />
        </div>
      </Alert>
    </div>
  );
};

export default themr(
  'SearchMapNotificationNoMLSChaseThemed',
  defaultTheme
)(SearchMapNotificationNoMLSChase);
