import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { getIsMobile } from '@client/store/selectors/match-media.selectors';
import {
  disableBodyScroll,
  enableBodyScroll,
} from '@client/utils/body-scroll-lock';

type OwnProps = {
  shouldAlwaysPreventBodyScrolling?: boolean;
};

const mapStateToProps = (state, ownProps: OwnProps) => ({
  shouldPreventBodyScrolling:
    getIsMobile(state) || ownProps.shouldAlwaysPreventBodyScrolling,
});

type Props = {
  [propName: string]: any;
};

/**
 * HOC used to disable touch scrolling on all other page element but the passed element
 * Utilizes body-scroll-lock on component mount, disabling the scroll lock on unmount
 * https://www.npmjs.com/package/body-scroll-lock
 *
 * Notes:
 * - This must be passed the *scrollable element* within the modal, not necessarily the modal itself
 * - If seeking to have some child of the scrollable modal also be scrollable (nested modals),
 *   this HOC must be applied to the inner child as well
 */
export default (
  ScrollableComponentWithinModal: React.ComponentClass<any> | 'div' | 'table'
) => {
  class ModalComponentWithScrollingFix extends Component<Props> {
    scrollableComponentEle: Element | Text | null = null;

    componentDidUpdate(prevProps) {
      const { shouldPreventBodyScrolling } = this.props;

      /* Remove scroll restriction when switching from mobile to desktop */
      if (
        prevProps.shouldPreventBodyScrolling &&
        !shouldPreventBodyScrolling &&
        this.scrollableComponentEle
      ) {
        this.deactivateScrollingFix();
      }
    }

    componentWillUnmount() {
      this.deactivateScrollingFix();
    }

    activateScrollingFix = () => {
      if (this.scrollableComponentEle) {
        disableBodyScroll(this.scrollableComponentEle as Element);
      }
    };

    deactivateScrollingFix = () => {
      enableBodyScroll(this.scrollableComponentEle as Element);
      this.scrollableComponentEle = null;
    };

    recordScrollableComponentRef = (ele: React.ReactInstance | null) => {
      const { forwardedRef } = this.props;
      if (ele) {
        this.scrollableComponentEle = findDOMNode(ele);
        this.activateScrollingFix();
      }
      if (forwardedRef) {
        forwardedRef(ele);
      }
    };

    render() {
      const {
        shouldPreventBodyScrolling,
        shouldAlwaysPreventBodyScrolling,
        forwardedRef,
        ...props
      } = this.props;
      return shouldPreventBodyScrolling ? (
        <ScrollableComponentWithinModal
          {...props}
          ref={this.recordScrollableComponentRef}
        />
      ) : (
        <ScrollableComponentWithinModal {...props} ref={forwardedRef} />
      );
    }
  }

  return connect(mapStateToProps, () => ({}))(ModalComponentWithScrollingFix);
};
