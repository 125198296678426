import { isEqual, memoize } from 'lodash';
import { createSelector } from 'reselect';

import {
  FilterKey,
  FILTER_KEYS,
  HIDDEN_SAVED_SEARCH_DESCRIPTION_FILTERS,
  INITIAL_FILTER_VALUES,
} from '@client/store/filter-constants';
import { ReduxState } from '@client/store/types/redux-state';
import {
  consumerAPIFiltersToStateFilters,
  getAllPropertyTypeValues,
  getFilterSummary,
  getFilterTitle,
} from '@client/utils/filters.utils';
import { getIsDisplayMultiFamilySearchFiltersEnabled } from './enabled-features.selectors';

export const getSavedSearchState = (state: ReduxState) => state.savedSearch;

export const getSavedSearches = createSelector(
  getSavedSearchState,
  (savedSearchState) => savedSearchState.searches
);

export const getTopNSavedSearches = memoize(function (n: number) {
  return createSelector(getSavedSearches, (savedSearches) =>
    savedSearches.slice(0, n)
  );
});

export const getCreatedSavedSearchErrorMessage = createSelector(
  getSavedSearchState,
  (savedSearchState) => savedSearchState.errorMessage
);

export const getHasSavedCurrentSearch = createSelector(
  getSavedSearchState,
  (searchState) => {
    return searchState.hasSavedCurrentSearch;
  }
);

export const getSavedSearchesStatus = createSelector(
  getSavedSearchState,
  (savedSearchState) => savedSearchState.status
);

export const getSelectedSavedSearch = createSelector(
  getSavedSearchState,
  (savedSearchState) => savedSearchState.selected
);

export const getSelectedSavedSearchDisplayName = createSelector(
  getSavedSearches,
  getSelectedSavedSearch,
  (searches, selectedId) => {
    if (selectedId && searches.length) {
      const selectedSearch = searches.find(
        (search) => search.searchId === selectedId
      );
      return selectedSearch ? selectedSearch.displayName : null;
    } else {
      return null;
    }
  }
);

export const getSavedSearchSavingStatus = createSelector(
  getSavedSearchState,
  (savedSearchState) => savedSearchState.savingStatus
);

/* Return an object mapping filter summaries to each saved-search id */
export const getSavedSearchFilterSummaryMap = createSelector(
  getSavedSearches,
  getIsDisplayMultiFamilySearchFiltersEnabled,
  (savedSearches, isDisplayMultiFamilySearchFiltersEnabled) => {
    const allPropertyTypeValues = getAllPropertyTypeValues(
      isDisplayMultiFamilySearchFiltersEnabled
    );
    const initialFilterValues = {
      ...INITIAL_FILTER_VALUES,
      [FILTER_KEYS.PROPERTY_TYPE]: allPropertyTypeValues,
    };

    return savedSearches.reduce((output, search) => {
      const { sw_lat, sw_lng, ne_lat, ne_lng, geom, ...restFilters } =
        search.filter;
      const filters = consumerAPIFiltersToStateFilters(
        restFilters,
        isDisplayMultiFamilySearchFiltersEnabled
      );
      const activeFilterKeyArr = Object.keys(filters).filter(
        (filterKey) =>
          !isEqual(filters[filterKey], initialFilterValues[filterKey]) &&
          HIDDEN_SAVED_SEARCH_DESCRIPTION_FILTERS.indexOf(
            filterKey as FilterKey
          ) === -1
      );
      const filterSummaryArr = (activeFilterKeyArr as FilterKey[]).map(
        (filterKey) => {
          const value = filters[filterKey];
          let description = `${getFilterSummary(
            filterKey,
            value
          )} ${getFilterTitle(filterKey)}`;
          // The summary for Days on Market includes whether it is a "Day" or a plural "Days", but the title
          // repeats "Days" (as in "Days on Market"), so we're removing the erroneous text here. We cannot change
          // the title as there are multiple points in our app where we pull that for display.
          if (filterKey === FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX) {
            description = description.replace(
              /(Day|Days) Days/,
              (match, dayOrDays: string) => dayOrDays
            );
          }
          return description;
        }
      );

      output[search.searchId] = filterSummaryArr.join(', ');
      return output;
    }, {});
  }
);
