import { createSelector } from 'reselect';

import { ReduxState } from '@client/store/types/redux-state';
import {
  ParamsType,
  QueryType,
  RouterStateType,
  RouteTypeWithPotentiallyNullView,
  ViewType,
} from './types';

export const getRouterState = (state: ReduxState): RouterStateType =>
  state.router;

export const getCurrentRoute = createSelector(
  getRouterState,
  (routerState): RouteTypeWithPotentiallyNullView => routerState.current
);

export const getCurrentView = createSelector(
  getCurrentRoute,
  (routeDef): ViewType | null => routeDef.view
);

export const getCurrentParams = createSelector(
  getCurrentRoute,
  (routeDef): ParamsType => routeDef.params
);

export const getCurrentQuery = createSelector(
  getCurrentRoute,
  (routeDef): QueryType => routeDef.query
);

export const getPreviousRoute = createSelector(
  getRouterState,
  (routerState): RouteTypeWithPotentiallyNullView => routerState.previous
);

export const getPreviousView = createSelector(
  getPreviousRoute,
  (routeDef): ViewType | null => routeDef.view
);

export const getPreviousParams = createSelector(
  getPreviousRoute,
  (routeDef): ParamsType => routeDef.params
);

export const getPreviousQuery = createSelector(
  getPreviousRoute,
  (routeDef): QueryType => routeDef.query
);
