import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { closeModal } from '@client/store/actions/modals.actions';
import { getActiveModalKey } from '@client/store/selectors/modals.selectors';
import { getPropertyFullAddress } from '@client/store/selectors/property-details.selectors';
import { getAllPropertyPhotosForAddressSlug } from '@client/store/selectors/property-photos.selectors';

import CarouselDialog from '@client/components/CarouselDialog';

import { PropertyPhotosSizes } from '@client/store/types/property-photos';

type OwnProps = {
  initialSlideUrl: null | string;
  photoSize: PropertyPhotosSizes;
  addressSlug: string;
};

const mapStateToProps = (state, ownProps: OwnProps) => {
  const urls = getAllPropertyPhotosForAddressSlug(ownProps.addressSlug)(state, {
    size: ownProps.photoSize,
  });
  return {
    isActive: getActiveModalKey(state) === 'photo-list',
    urls,
    initialSlide: ownProps.initialSlideUrl
      ? urls.indexOf(ownProps.initialSlideUrl)
      : 0,
    dataHcName: 'carousel-photo',
    scrollTopOnThumbnailClick: true,
    showArrows: true,
    fullAddress: getPropertyFullAddress(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onClose: () => dispatch(closeModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CarouselDialog);
