import loadScript from 'little-loader';
import { isEqual } from 'lodash';

import HC_CONSTANTS from '@client/app.config';
import { View } from '@client/routes/constants';
import { AnalyticsEvent, EVENTS } from '@client/store/analytics-constants';
import {
  CHASE_EVENT_MAPPING,
  ChaseEventMapping,
} from '@client/store/chase-analytics-mapping';
import { CobrandId } from '@client/store/types/cobranding';
import { localStorageUtil } from '@client/utils/local-storage.utils';

type EventData = { event: AnalyticsEvent; props?: {}; view: View; query: {} };

const { COBRAND_ANALYTICS_REPORTING_SCRIPT_URLS } = HC_CONSTANTS;

/* Cause loading of external JS lib and actual event reporting to be skipped and analytics params to be logged to the console */
/* eslint-disable-next-line custom/explain-localstorage */
const shouldDryRun = !!localStorageUtil.getItem('cobrandAnalyticsDryRun');

/* Cause event reporting to function as normal, with parameters logged to the console */
/* eslint-disable-next-line custom/explain-localstorage */
const shouldLiveDebug = !!localStorageUtil.getItem('cobrandAnalyticsLiveDebug');

if (shouldDryRun) {
  /* Stub so that we don't get undefined errors.  These are normally provided by the external JS script. */
  (window as any).RPT_AddVariables = () => void 0;
  (window as any).RPT_RecordEvent = () => void 0;
}

const optionallyLog = (...args: string[]) => {
  if (shouldLiveDebug || shouldDryRun) {
    console.log(args.join(' / '));
  }
};

const queryCheck = (
  defValue: string,
  actualValue: string,
  notInQuery: boolean
): boolean => {
  return notInQuery ? defValue !== actualValue : defValue === actualValue;
};
export const COBRANDED_ANALYTICS_EVENTS = {
  chase: CHASE_EVENT_MAPPING,
};

/* Promise to ensure that events are fired using the external script only after it loads */
let reportingLoaded: Promise<unknown> | null = null;

export const COBRANDED_ANALYTICS_HANDLERS: {
  [key in CobrandId]?: {
    handleLoadScript: () => void;
    handleReportEvent: ({ event, props, view, query }: EventData) => void;
  };
} = {
  chase: {
    handleLoadScript: (): Promise<unknown> => {
      if (reportingLoaded === null) {
        reportingLoaded = new Promise((resolve, reject) => {
          if (shouldDryRun) {
            console.log(
              'Chase analytics reporting is in DRY RUN mode.  Events will be logged here, but not actually reported.'
            );
            resolve(void 0);
          } else if (!COBRAND_ANALYTICS_REPORTING_SCRIPT_URLS.chase) {
            /* This is expected in certain environments where we don't want to report events to Chase */
            console.error(
              'No Chase event reporting script URL is defined for this environment.  Enable cobrandAnalyticsDryRun to see events.'
            );
            /* Resolve so that the page doesn't hang in dev environments without reporting functionality */
            resolve(void 0);
          } else {
            loadScript(COBRAND_ANALYTICS_REPORTING_SCRIPT_URLS.chase, (err) => {
              if (err) {
                reject(err);
              } else {
                resolve(void 0);
              }
            });
          }
        });
      }
      return reportingLoaded;
    },
    handleReportEvent: ({ event, props, view, query }: EventData): void => {
      /* First find the event def that we want, then send the `data` associated with that event def */
      const eventDefMapping: ChaseEventMapping =
        COBRANDED_ANALYTICS_EVENTS.chase;
      const eventsToSend = eventDefMapping.filter((def) => {
        /* For page view events, ensure that the view and query params match our target values */
        if (event === EVENTS.PAGE_VIEW) {
          const queryDefItemsArePresentInQuery =
            !def.query ||
            Object.keys(def.query).filter((queryDefKey) =>
              queryCheck(
                query[queryDefKey],
                def.query![queryDefKey],
                !!def.notInQuery
              )
            ).length === Object.keys(def.query).length;
          return (
            def.event === event &&
            def.view === view &&
            queryDefItemsArePresentInQuery
          );
          /* For standard events, just filter on the matching event name and props */
        } else {
          return (
            def.event === event && (!def.props || isEqual(def.props, props))
          );
        }
      });
      if (
        eventsToSend.length &&
        (window as any).RPT_AddVariables &&
        (window as any).RPT_RecordEvent
      ) {
        /* If we get here, we have events to send and we've either loaded the external reporting script OR
         * we have the localStorage flag enabled to log the events to the console */
        eventsToSend.forEach((eventDef) => {
          /* This allows generating a custom event name, driven by the event props */
          const value =
            typeof eventDef.data.value === 'function'
              ? eventDef.data.value(props)
              : eventDef.data.value;
          optionallyLog('▷ RPT_AddVariables', eventDef.data.key, value);
          (window as any).RPT_AddVariables(eventDef.data.key, value);

          if (eventDef.data.key === 'wa_lnk') {
            optionallyLog('▷ RPT_AddVariables', 'wa_tp', '1');
            (window as any).RPT_AddVariables('wa_tp', '1');
          }

          optionallyLog('○ RPT_RecordEvent');
          (window as any).RPT_RecordEvent();
        });
      }
    },
  },
};
