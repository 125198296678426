import {
  SET_ATTEMPTED_ROUTE,
  CLEAR_ATTEMPTED_ROUTE,
} from '@client/store/actions/attempted-route.actions';
import { Action } from '@client/store/actions';
import { View } from '@client/routes/constants';
import { RouteType } from '@src/redux-saga-router-plus/types';

export type AttemptedRouteState = RouteType;
export const INITIAL_ATTEMPTED_ROUTE: RouteType = {
  view: '' as View,
  params: {},
  query: {},
  path: '',
};

export function attemptedRouteReducer(
  state = INITIAL_ATTEMPTED_ROUTE,
  action: Action
): RouteType {
  switch (action.type) {
    case SET_ATTEMPTED_ROUTE:
      return {
        ...state,
        view: action.payload.view,
        path: action.payload.path,
        params: action.payload.params,
        query: action.payload.query,
      };

    case CLEAR_ATTEMPTED_ROUTE:
      return {
        ...state,
        ...INITIAL_ATTEMPTED_ROUTE,
      };

    default:
      return state;
  }
}
