import { reportPropertyPageMortgageCalculator } from '@client/store/actions/property-details.actions';
import { ModalKey } from '@client/store/constants';
import { LOCKED_PDP_MORTGAGE_CALCULATOR_PLACEHOLDER } from '@client/store/locked-components-constants';
import {
  getHomePriceForMortgageCalculator,
  getMortgageSummary,
} from '@client/store/selectors/property-details.selectors';
import {
  SectionId,
  SpecialUserType,
} from '@client/store/types/locked-components';
import { useDispatch, useSelector } from 'react-redux';
import CobrandedDataComponent, {
  CobrandedDataComponentArgs,
} from '../CobrandedDataComponent';
import PreviewSection from './PreviewSection';

interface Props {
  openSectionModal: (modalKey: ModalKey) => void;
}

const MortgageCalculator = ({ openSectionModal }: Props) => {
  const dispatch = useDispatch();
  const shouldDisplayMortgageCalculator = !!useSelector(
    getHomePriceForMortgageCalculator
  );
  const mortgageSummary = useSelector(getMortgageSummary);

  return (
    <CobrandedDataComponent>
      {({
        customizationData: { should_display_mortgage_calculator_for_cobrand },
      }: CobrandedDataComponentArgs) => (
        <>
          {shouldDisplayMortgageCalculator &&
            should_display_mortgage_calculator_for_cobrand && (
              <PreviewSection
                data-section-key={'mortgage_calculator'}
                dataEventName=""
                onClick={() => {
                  dispatch(
                    reportPropertyPageMortgageCalculator(
                      'click_property_details_mortgage_calculator',
                      'click_pdp_modules'
                    )
                  );
                  openSectionModal('mortgage_calculator');
                }}
                title={'Mortgage Calculator'}
                lockedComponent={{
                  sectionId: SectionId.PdpMortgageCalculator,
                  lockedFor: [SpecialUserType.Restricted],
                }}
                placeholder={LOCKED_PDP_MORTGAGE_CALCULATOR_PLACEHOLDER}
              >
                <div>{mortgageSummary}</div>
              </PreviewSection>
            )}
        </>
      )}
    </CobrandedDataComponent>
  );
};

export default MortgageCalculator;
