import PillButton from '@client/components/generic/PillButton';
import { Props } from '@client/components/GrantProgram/GrantProgramCTACobranded';
import { StyleProps } from '@client/components/GrantProgram/GrantProgramFullContent';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { chaseLenderCTAClick } from '@client/store/actions/cobranding.actions';
import { getIsInsideNativeApp } from '@client/store/selectors/cobranding.selectors';
import { selectReportEventProps } from '@client/store/slices/grant-program.slice';
import { escape } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

const StyledCTAContainer = styled.div<StyleProps>`
  margin-left: auto;
  ${(props) => `margin-top: ${props.$isNarrowLayout ? '30px' : '0'};`}
  & button {
    ${(props) => (props.$useFullWidthCTA ? 'max-width: 100%;' : 'width: auto;')}
    min-width: 160px;
    height: 40px;
    font-size: 14px;
  }
`;

const GrantProgramCTAChase = ({
  isNarrowLayout,
  isPdpPage,
  buttonText,
  buttonUrl,
  useFullWidthCTA,
  dataEventName,
  dataEventParentName,
  nativeAppNavKey,
}: Props) => {
  const isInsideNativeApp = useSelector(getIsInsideNativeApp);
  const reportEventProps = useSelector(selectReportEventProps);
  const dispatch = useDispatch();

  const pillButtonProps = isPdpPage
    ? {
        'data-event-name': dataEventName,
        'data-parent-event-name': dataEventParentName,
      }
    : {};
  const onClick = () => {
    if (!isPdpPage) {
      dispatch(
        reportEvent(dataEventName, dataEventParentName, reportEventProps)
      );
    }
    if (isInsideNativeApp && nativeAppNavKey) {
      dispatch(chaseLenderCTAClick(buttonUrl, nativeAppNavKey));
    } else {
      window.location.href = escape(buttonUrl);
    }
  };

  return (
    <StyledCTAContainer
      $isNarrowLayout={isNarrowLayout}
      $useFullWidthCTA={useFullWidthCTA}
    >
      <PillButton
        {...pillButtonProps}
        dataHcName="grant-program-content-cta"
        ariaLabel={buttonText}
        onClick={onClick}
      >
        {buttonText}
      </PillButton>
    </StyledCTAContainer>
  );
};

export default GrantProgramCTAChase;
