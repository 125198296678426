export type ChaseJSBridgeEventDestination =
  | 'get_buying_power'
  | 'LOAN_OPTIONS'
  | 'view_offer_letter'
  | 'update_buying_power';

export type ChasePropertyTypeJSBridgeEventParams = {
  event_type: 'property';
  destination: ChaseJSBridgeEventDestination;
  street_address: string;
  unit?: string | null;
  city: string;
  state: string;
  zipcode: string;
  county: string | null;
  list_price: number | null;
  property_type:
    | 'condo'
    | 'manufactured'
    | 'other'
    | 'commercial'
    | 'coop'
    | 'single_family_detached'
    | 'single_family_attached'
    | 'vacant_lot'
    | 'multi_family'
    | 'timeshare'
    | null;
  year_built: number | null;
  ch_property_id: string | null;
};

export type ChaseAreaTypeJSBridgeEventParams = {
  event_type: 'area';
  destination: 'LOAN_OPTIONS';
  city: string;
  state: string;
  zipcode?: string;
};

export type ChaseCantFindAddressJSBridgeEventParams = {
  /* This is passed via chase-integration.saga as the `navKey` in the JSBridge params (native app integration) and the URL params (web app integration)  */
  destination: 'requestChaseMyHomeManualAddress' | string;
};

export type ChaseCTAEventParams =
  | ChasePropertyTypeJSBridgeEventParams
  | ChaseAreaTypeJSBridgeEventParams
  | ChaseCantFindAddressJSBridgeEventParams;

export const getIsPropertyTypeParams = (
  params: ChaseCTAEventParams
): params is ChasePropertyTypeJSBridgeEventParams => {
  return !!(params as ChasePropertyTypeJSBridgeEventParams).ch_property_id;
};

export const getIsPropertyTypeWithNoPropertyIdParams = (
  params: ChaseCTAEventParams
): params is ChasePropertyTypeJSBridgeEventParams => {
  return !!(
    !(params as ChasePropertyTypeJSBridgeEventParams).ch_property_id &&
    (params as ChasePropertyTypeJSBridgeEventParams).event_type === 'property'
  );
};

export const getIsAreaTypeParams = (
  params: ChaseCTAEventParams
): params is ChaseAreaTypeJSBridgeEventParams => {
  return !!((params as ChaseAreaTypeJSBridgeEventParams).event_type === 'area');
};
