import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import CobrandedStyles from '@client/components/CobrandedStyles';
import defaultTheme from '@client/css-modules/FindAgentBanner.css';
import PillButton from '@client/components/generic/PillButton';
import { CLICK_FIND_AGENT_BANNER_PDP } from '@client/components/FindAgent/FindAgent_events';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import {
  useYourTeamModalState,
  useYourTeamAgentData,
} from '@client/hooks/your-team.hooks';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';

type Props = {
  theme: Theme;
  isNarrowLayout: boolean;
};

const FindAgentBanner: React.FC<Props> = ({ theme, isNarrowLayout }) => {
  const dispatch = useDispatch();
  const isYourTeamEnabled = useSelector(getIsFeatureEnabled('your_team'));
  const { introducedAgentData } = useYourTeamAgentData();
  const { openYourTeamModal } = useYourTeamModalState();

  if (!isYourTeamEnabled) {
    throw new Error(
      'FindAgentBanner rendered unexpectedly with your_team disabled'
    );
  }

  const buttonText = introducedAgentData ? 'Contact agent' : 'Looking to buy?';
  const bodyText = introducedAgentData
    ? 'Contact your agent for a personalized home buying experience.'
    : 'We’ll connect you with top agents in your area.';

  const onOpenFindAgentClick = () => {
    dispatch(
      reportEvent(CLICK_FIND_AGENT_BANNER_PDP, PARENT_EVENTS.CLICK_FIND_AGENT)
    );
    openYourTeamModal('agent');
  };

  return (
    <>
      <CobrandedStyles>
        {({ partnerAgentBannerGradientColor, partnerAgentBackgroundColor }) => (
          <div
            className={classNames(theme.FindAgentBanner, {
              [theme.NarrowLayout]: isNarrowLayout,
            })}
            style={{
              background: partnerAgentBannerGradientColor,
              backgroundSize: '65%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right 0% top 20%',
              backgroundColor: partnerAgentBackgroundColor,
            }}
          >
            <div className={theme.TextColumn}>
              <div data-hc-name="find-agent-title" className={theme.TitleCopy}>
                {bodyText}
              </div>
            </div>
            <div className={classNames(theme.CtaColumn)}>
              <PillButton
                dataHcName="find-agent-button"
                ariaLabel={buttonText}
                className={theme.PillButton}
                onClick={onOpenFindAgentClick}
              >
                {buttonText}
              </PillButton>
            </div>
          </div>
        )}
      </CobrandedStyles>
    </>
  );
};

export default themr('ThemedFindAgentBanner', defaultTheme)(FindAgentBanner);
