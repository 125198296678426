/* Ported from react-input-range
 * https://github.com/davidchin/react-input-range */

import React from 'react';
import Label from './InputRangeLabel';

type Props = {
  ariaLabelledby?: string;
  ariaControls?: string;
  classNames?: { [className: string]: string };
  formatLabel?: (val: number) => string | number;
  formatAria?: (val: number) => number;
  maxValue: number;
  minValue: number;
  onSliderDrag: (e, f) => void;
  onSliderKeyDown: (e, f) => void;
  percentage: number;
  type: string;
  value: number;
};

export default class Slider extends React.Component<Props> {
  node: HTMLElement | null;
  constructor(props) {
    super(props);
    this.node = null;
  }

  componentWillUnmount() {
    this.removeDocumentMouseMoveListener();
    this.removeDocumentMouseUpListener();
    this.removeDocumentTouchEndListener();
    this.removeDocumentTouchMoveListener();
  }

  /**
   * @return {Object}
   */
  getStyle = () => {
    const perc = (this.props.percentage || 0) * 100;
    const style = {
      position: 'absolute' as any,
      left: `${perc}%`,
    };

    return style;
  };

  /**
   * Listen to mousemove event
   * @return {void}
   */
  addDocumentMouseMoveListener = () => {
    this.removeDocumentMouseMoveListener();
    this.node &&
      this.node.ownerDocument.addEventListener(
        'mousemove',
        this.handleMouseMove
      );
  };

  /**
   * Listen to mouseup event
   * @return {void}
   */
  addDocumentMouseUpListener = () => {
    this.removeDocumentMouseUpListener();
    this.node &&
      this.node.ownerDocument.addEventListener('mouseup', this.handleMouseUp);
  };

  /**
   * Listen to touchmove event
   * @return {void}
   */
  addDocumentTouchMoveListener = () => {
    this.removeDocumentTouchMoveListener();
    this.node &&
      this.node.ownerDocument.addEventListener(
        'touchmove',
        this.handleTouchMove
      );
  };

  /**
   * Listen to touchend event
   * @return {void}
   */
  addDocumentTouchEndListener = () => {
    this.removeDocumentTouchEndListener();
    this.node &&
      this.node.ownerDocument.addEventListener('touchend', this.handleTouchEnd);
  };

  /**
   * @return {void}
   */
  removeDocumentMouseMoveListener = () => {
    this.node &&
      this.node.ownerDocument.removeEventListener(
        'mousemove',
        this.handleMouseMove
      );
  };

  /**
   * @return {void}
   */
  removeDocumentMouseUpListener = () => {
    this.node &&
      this.node.ownerDocument.removeEventListener(
        'mouseup',
        this.handleMouseUp
      );
  };

  /**
   * @return {void}
   */
  removeDocumentTouchMoveListener = () => {
    this.node &&
      this.node.ownerDocument.removeEventListener(
        'touchmove',
        this.handleTouchMove
      );
  };

  /**
   * @return {void}
   */
  removeDocumentTouchEndListener = () => {
    this.node &&
      this.node.ownerDocument.removeEventListener(
        'touchend',
        this.handleTouchEnd
      );
  };

  /**
   * @return {void}
   */
  handleMouseDown = () => {
    this.addDocumentMouseMoveListener();
    this.addDocumentMouseUpListener();
  };

  /**
   * @return {void}
   */
  handleMouseUp = () => {
    this.removeDocumentMouseMoveListener();
    this.removeDocumentMouseUpListener();
  };

  /**
   * @param {SyntheticEvent} event
   * @return {void}
   */

  handleMouseMove = (event) => {
    this.props.onSliderDrag(event, this.props.type);
  };

  /**
   * @return {void}
   */

  handleTouchStart = () => {
    this.addDocumentTouchEndListener();
    this.addDocumentTouchMoveListener();
  };

  /**
   * @param {SyntheticEvent} event
   * @return {void}
   */
  handleTouchMove = (event) => {
    this.props.onSliderDrag(event, this.props.type);
  };

  /**
   * @return {void}
   */
  handleTouchEnd = () => {
    this.removeDocumentTouchMoveListener();
    this.removeDocumentTouchEndListener();
  };

  /**
   * @param {SyntheticEvent} event
   * @return {void}
   */
  handleKeyDown = (event) => {
    this.props.onSliderKeyDown(event, this.props.type);
  };

  /**
   * @override
   * @return {JSX.Element}
   */
  render() {
    const style = this.getStyle();

    return (
      <span
        className={this.props.classNames?.sliderContainer}
        ref={(node) => {
          this.node = node;
        }}
        style={style}
      >
        <Label
          classNames={this.props.classNames ? this.props.classNames : {}}
          formatLabel={this.props.formatLabel}
          type="value"
        >
          {this.props.value}
        </Label>

        <div
          aria-labelledby={this.props.ariaLabelledby}
          aria-controls={this.props.ariaControls}
          aria-valuemax={
            !!this.props.formatAria
              ? this.props.formatAria(this.props.maxValue)
              : this.props.maxValue
          }
          aria-valuemin={
            !!this.props.formatAria
              ? this.props.formatAria(this.props.minValue)
              : this.props.minValue
          }
          aria-valuenow={
            !!this.props.formatAria
              ? this.props.formatAria(this.props.value)
              : this.props.value
          }
          aria-valuetext={
            !!this.props.formatLabel
              ? `${this.props.formatLabel(this.props.value)}`
              : `${this.props.value}`
          }
          className={this.props.classNames?.slider}
          draggable="false"
          onKeyDown={this.handleKeyDown}
          onMouseDown={this.handleMouseDown}
          onTouchStart={this.handleTouchStart}
          role="slider"
          tabIndex={0}
        />
      </span>
    );
  }
}
