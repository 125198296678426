import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import { compareAsc, subMinutes } from 'date-fns';
import { createSelector } from 'reselect';

import { getCobrandUtils } from '@client/cobrand-settings/cobrand-utils';
import { View } from '@client/routes/constants';
import {
  getIsLoggedIn,
  getIsUserEmailConfirmed,
  getUserCreatedDate,
  getUserEmailsBounced,
} from '@client/store/selectors/auth.selectors';
import { getShouldDisplayEmailIssueBanners } from '@client/store/selectors/cobranding.selectors';
import { getIsApplicationMounted } from '@client/store/selectors/server-rendering-info.selectors';
import { ReduxState } from '@client/store/types/redux-state';
import { reportToSentry } from '@client/utils/error.utils';
import { localStorageUtil } from '@client/utils/local-storage.utils';
import { getIsFeatureEnabled } from './enabled-features.selectors';

/* For use when we want to hide the 'download app' SMS banner at the top of the screen for a view */
const HIDDEN_BANNER_FOR_VIEWS = [
  View.WELCOME_SEARCH,
  View.WELCOME_TRANSITION,
  View.PARTNER_API_CREDENTIAL,
] as View[];

export function getAppBannerState(state: ReduxState) {
  return state.appBanner;
}

/* Recreating instead of importing to avoid circular dependency */
export const getCobrandUtilMethods = (state: ReduxState) =>
  getCobrandUtils(state.cobranding.id);

export const getUpdateEmailBannerState = createSelector(
  getAppBannerState,
  (appBannerState) => {
    return appBannerState.updateEmailBanner;
  }
);

export const getConfirmEmailBannerState = createSelector(
  getAppBannerState,
  (appBannerState) => {
    return appBannerState.confirmEmailBanner;
  }
);

export const getIsUpdateEmailBannerOpen = createSelector(
  getIsLoggedIn,
  getUserEmailsBounced,
  getUpdateEmailBannerState,
  getCurrentView,
  getShouldDisplayEmailIssueBanners,
  (
    isLoggedIn,
    userEmailsBounced,
    updateEmailBannerState,
    currentView,
    shouldDisplayEmailIssueBanners
  ) => {
    /* Try..catch to better diagnose this Sentry issue
     * https://sentry.io/organizations/housecanarycom/issues/2098072505/?project=1258985
     */
    let isAllowedToShowBanner: boolean = true;
    try {
      const { isAllowedToShow } = updateEmailBannerState;
      isAllowedToShowBanner = isAllowedToShow;
    } catch (e: any) {
      reportToSentry('Caught error in getting updateEmailBannerState', {
        updateEmailBannerState,
        error: e,
      });
    }
    return (
      isLoggedIn &&
      !!userEmailsBounced &&
      isAllowedToShowBanner &&
      shouldDisplayEmailIssueBanners &&
      !!currentView &&
      HIDDEN_BANNER_FOR_VIEWS.indexOf(currentView) === -1
    );
  }
);

export const getIsConfirmEmailBannerOpen = createSelector(
  getIsLoggedIn,
  getIsUserEmailConfirmed,
  getUserCreatedDate,
  getConfirmEmailBannerState,
  getCurrentView,
  getShouldDisplayEmailIssueBanners,
  (state) => getIsFeatureEnabled('disable_user_confirmation_flow')(state),
  (
    isLoggedIn,
    isUserEmailConfirmed,
    userCreatedDate,
    confirmEmailBannerState,
    currentView,
    shouldDisplayEmailIssueBanners,
    isUserConfirmationFlowDisabled
  ) => {
    const minutesUserAgeAtWhichBannerDisplays =
      /* Allow setting in localStorage for testing purposes */
      /* eslint-disable-next-line custom/explain-localstorage */
      localStorageUtil.getItem(
        'minutesUserAgeAtWhichConfirmReminderDisplays'
      ) || '43200'; /* 30 days */
    return !!(
      isLoggedIn &&
      !isUserEmailConfirmed &&
      confirmEmailBannerState?.isAllowedToShow &&
      userCreatedDate &&
      /* If it's been more than 30 days since the user was created */
      compareAsc(
        subMinutes(
          new Date(),
          parseInt(minutesUserAgeAtWhichBannerDisplays, 10)
        ),
        new Date(userCreatedDate as string)
      ) === 1 &&
      shouldDisplayEmailIssueBanners &&
      currentView &&
      HIDDEN_BANNER_FOR_VIEWS.indexOf(currentView) === -1 &&
      !isUserConfirmationFlowDisabled
    );
  }
);

export const getIsAppBannerOpen = createSelector(
  getIsApplicationMounted,
  getIsUpdateEmailBannerOpen,
  getIsConfirmEmailBannerOpen,
  (isAppMounted, isUpdateEmailBannerOpen, isConfirmEmailBannerOpen) => {
    return (
      isAppMounted && (isUpdateEmailBannerOpen || isConfirmEmailBannerOpen)
    );
  }
);

export const getHeaderAppModalState = createSelector(
  getAppBannerState,
  (appBannerState) => {
    return appBannerState.headerAppModal;
  }
);

export const getHeaderAppModalFormState = createSelector(
  getHeaderAppModalState,
  (headerAppModal) => headerAppModal.formState
);
