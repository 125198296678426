import { Component } from 'react';
import theme from '@client/css-modules/Breadcrumbs.css';
import RouterLink from '@client/components/RouterLink';
import { View, VIEW_PATHS } from '@client/routes/constants';

type Props = {
  cityState: string | null;
  streetAddress: string | null;
  zipcode: string | null;
  placeSlug: string | null;
};

type State = {
  isMounted: boolean;
};

export default class Breadcrumbs extends Component<Props, State> {
  state: State = {
    isMounted: false,
  };

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  getLink = (
    view: View,
    label: string,
    params?: {}
  ): React.ComponentType | JSX.Element => {
    const { isMounted } = this.state;

    if (isMounted) {
      return (
        <RouterLink
          className={theme.RouterLink}
          view={view}
          ariaLabel={label}
          params={params}
        >
          {label}
        </RouterLink>
      );
    } else {
      const paramKeys = params && Object.keys(params);
      if (paramKeys && paramKeys.length > 1) {
        throw new Error(
          'getLink currently only supports links with a single parameter'
        );
      }
      const paramKey = paramKeys && paramKeys[0];
      const manualRoutePath =
        params && paramKey
          ? VIEW_PATHS[view].replace(`:${paramKey}`, params[paramKey])
          : VIEW_PATHS[view];
      return (
        <a
          href={manualRoutePath}
          onClick={(e) => e.preventDefault()}
          className={theme.RouterLink}
        >
          {label}
        </a>
      );
    }
  };

  render() {
    const { cityState, streetAddress, zipcode, placeSlug } = this.props;

    return (
      placeSlug &&
      cityState && (
        <div role="navigation" aria-label="sub navigation">
          <ul className={theme.Breadcrumbs} data-hc-name="breadcrumbs">
            <li key="home" className={theme.link}>
              <>{this.getLink(View.SEARCH, 'Home')}</>
            </li>
            <li key="city-state" className={theme.link}>
              <>
                {this.getLink(View.CITIES, cityState, { placeSlug: placeSlug })}
              </>
            </li>
            {zipcode && (
              <li key="zip-code" className={theme.link}>
                <>{this.getLink(View.ZIPCODE, zipcode, { zipcode: zipcode })}</>
              </li>
            )}
            <li key="street-address" className={theme.link} aria-current={true}>
              {streetAddress}
            </li>
          </ul>
        </div>
      )
    );
  }
}
