import Header from '@client/components/Header/Header';
import HeaderChase from '@client/components/Header/HeaderChase';
import HeaderComehome from '@client/components/Header/HeaderComehome';
import { useLenderCtaPdpConfig } from '@client/hooks/lender-cta-config.hooks';
import { useNativeAppLenderCTAClick } from '@client/hooks/native-app-integration.hooks';
import { View } from '@client/routes/constants';
import { EVENTS, PARENT_EVENTS } from '@client/store/analytics-constants';
import { ModalKey } from '@client/store/constants';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { HeaderProps } from '@client/store/types/cobranded-components/header';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import { useSelector } from 'react-redux';

type MetaData = {
  dataEventName?: string;
  dataParentEventName?: string;
  dataHcName?: string;
};
type NavItem = {
  title: string;
  key: string;
  route: View;
  /* Lendco custom header links */
  link?: string;
} & MetaData;
type ModalItem = {
  title: string;
  modalKey: ModalKey;
} & MetaData;
export type ExternalUrlItem = {
  title: string;
  url: string;
  nativeAppNavKey?: string;
  nativeAppLenderCTAClick?: (
    navKey: string,
    params: { [key: string]: string | number | null }
  ) => void;
} & MetaData;
export type AccountMenuItem = ModalItem | NavItem | ExternalUrlItem;

export function isExternalUrlItem(
  item: AccountMenuItem
): item is ExternalUrlItem {
  return !!((item as ExternalUrlItem)?.title && (item as ExternalUrlItem)?.url);
}
export function isModalItem(item: AccountMenuItem): item is ModalItem {
  return !!((item as ModalItem)?.title && (item as ModalItem)?.modalKey);
}
export function isNavItem(item: AccountMenuItem): item is NavItem {
  return !!(
    (item as NavItem)?.title &&
    (item as NavItem)?.route &&
    (item as NavItem)?.key
  );
}

export const getMenuItemsLoggedIn = ({
  isYourTeamEnabled,
  isReferralServicesEnabled,
  isRecentUserActivityEnabled,
  grantProgramHeaderNavItem,
  isShowingHomeownerNavLink,
  disableUserProfileEditing,
  hideProfileAuthElements,
  navLinkLabels,
  customNavCtas,
}: HeaderProps): AccountMenuItem[] => [
  ...(disableUserProfileEditing || hideProfileAuthElements
    ? []
    : [
        {
          title: 'Your Profile',
          key: 'profile',
          route: View.PROFILE,
        },
      ]),
  ...(hideProfileAuthElements
    ? []
    : [
        {
          title: 'Settings',
          key: 'setting',
          route: View.SETTINGS,
        },
      ]),
  ...(isShowingHomeownerNavLink
    ? [
        {
          title: navLinkLabels.navLinkHomeownerLabel || 'My Home',
          key: 'myHome',
          route: View.HOMEOWNER,
        },
      ]
    : []),
  ...(isYourTeamEnabled && isReferralServicesEnabled
    ? [
        {
          title: 'Find an agent',
          key: 'findAnAgent',
          route: View.CONCIERGE_TEAM,
          dataEventName: EVENTS.CLICK_FIND_AGENT_HEADER,
          dataParentEventName: PARENT_EVENTS.CLICK_NAV,
        },
      ]
    : []),
  {
    title: navLinkLabels.navLinkWatchlistLabel || 'Saved',
    key: 'watchlist',
    route: View.WATCHLIST,
  },
  ...(isRecentUserActivityEnabled
    ? [
        {
          title: 'Activity',
          modalKey: 'recent-user-activity' as ModalKey,
        },
      ]
    : []),
  ...(grantProgramHeaderNavItem ? [grantProgramHeaderNavItem] : []),
  ...(hideProfileAuthElements
    ? []
    : [
        {
          title: 'Logout',
          key: 'logout',
          route: View.LOGOUT,
        },
      ]),
];

export const getMenuItemsLoggedOut = ({
  isYourTeamEnabled,
  isReferralServicesEnabled,
  grantProgramHeaderNavItem,
  hideProfileAuthElements,
}: HeaderProps): AccountMenuItem[] => [
  ...(isYourTeamEnabled && isReferralServicesEnabled
    ? [
        {
          title: 'Find an agent',
          key: 'findAnAgent',
          route: View.CONCIERGE_TEAM,
          dataEventName: EVENTS.CLICK_FIND_AGENT_HEADER,
          dataParentEventName: PARENT_EVENTS.CLICK_NAV,
        },
      ]
    : []),
  ...(grantProgramHeaderNavItem ? [grantProgramHeaderNavItem] : []),
  ...(hideProfileAuthElements
    ? []
    : [
        {
          title: 'Sign up',
          key: 'signup',
          route: View.SIGN_UP,
        },
        {
          title: 'Log in',
          key: 'login',
          route: View.LOGIN,
        },
      ]),
];

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return HeaderChase;
    case COBRAND_IDS.WWW:
      return HeaderComehome;
    default:
      return Header;
  }
};

/*
 * Please define all non-hooks based props in `header.container` until the entire container is moved into this component
 */
const HeaderCobranded: React.FC<
  Omit<HeaderProps, 'theme' | 'lenderCtaPdpConfig' | 'nativeAppLenderCTAClick'>
> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const lenderCtaPdpConfig = useLenderCtaPdpConfig();
  const nativeAppLenderCTAClick = useNativeAppLenderCTAClick();

  const Component = getComponentForCobrand(cobrandId);
  return (
    <Component
      {...props}
      lenderCtaPdpConfig={lenderCtaPdpConfig}
      nativeAppLenderCTAClick={nativeAppLenderCTAClick}
    />
  );
};

export default HeaderCobranded;
