import { call } from 'redux-saga/effects';

import {
  fetchNewAccessToken,
  FETCH_NEW_ACCESS_TOKEN_SUCCESS,
  FETCH_NEW_ACCESS_TOKEN_FAILURE,
} from '@client/store/actions/auth.actions';
import { getAccessToken } from '@client/store/selectors/auth.selectors';
import { HttpClient, handleResponse } from '@client/services/http-client';

const httpClient = new HttpClient({
  fetchNewAccessTokenActionObject: fetchNewAccessToken(),
  fetchNewAccessTokenSuccessActionString: FETCH_NEW_ACCESS_TOKEN_SUCCESS,
  fetchNewAccessTokenFailureActionString: FETCH_NEW_ACCESS_TOKEN_FAILURE,
  getAccessTokenSelector: getAccessToken,
});

class MiscellaneousApiClient {
  *getLegalContent(nodeServerEndpoint: string) {
    const options = {
      url: nodeServerEndpoint,
      requestOptions: { method: 'GET' },
    };
    const rawResponse = yield call(
      [httpClient, httpClient.handleFetch],
      options
    );
    return yield call(handleResponse, rawResponse);
  }
}
export const miscellaneousApiClient = new MiscellaneousApiClient();
