import { View } from '@client/routes/constants';
import { routerGetIsLoggedInFn } from '@client/store/sagas/auth.saga';
import LoadingSection from '@client/components/generic/LoadingSection';
import { applyRouteChangeSideEffects } from '@client/store/sagas/router.saga';

export const routerOptions = {
  ComponentLoading: LoadingSection,
  loginRequiredRedirect: { view: View.LOGIN },
  logoutRequiredRedirect: { view: View.SEARCH },
  isLoggedInFn: routerGetIsLoggedInFn,
  afterRouteSaga: applyRouteChangeSideEffects,
};

export default routerOptions;
