import { call, put, select } from 'redux-saga/effects';

import { consumerApiClient } from '@client/services/consumer-api-client';
import { updateHomeownerPropertyData } from '@client/store/actions/homeowner.actions';
import { watchEvery } from '@client/utils/saga.utils';
import {
  putVerificationData,
  fetchVerificationData,
  cacheVerificationData,
} from '@client/store/slices/homeowner-verification.slice';
import { toastShow } from '@client/store/actions/toast.actions';
import { setActiveNotification } from '@client/store/actions/modals.actions';
import { displayEmailOptInModalAfterVerification } from '@client/store/sagas/modals.saga';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { STATUSES } from '@client/store/constants';
import { getActiveSlugForHomeownerAndSubpages } from '@client/store/selectors/router.selectors';
import { getHOAnalyticsEventAddress } from '@client/store/selectors/homeowner.selectors';

function* fetchVerificationDataSaga() {
  const slugInParams = yield select(getActiveSlugForHomeownerAndSubpages);

  if (slugInParams) {
    yield put(
      cacheVerificationData({
        slug: slugInParams,
        questionsLoadingStatus: STATUSES.LOADING,
      })
    );
  }
  try {
    const response = yield call([
      consumerApiClient,
      consumerApiClient.getVerificationObject,
    ]);
    yield put(
      cacheVerificationData({
        slug: response?.home?.slug,
        failedCount: response?.home?.failed_count,
        isLocked: response?.is_locked,
        isVerified: response?.home?.verified,
        questions: response?.questions,
        questionsLoadingStatus: STATUSES.SUCCESS,
      })
    );
  } catch (error: any) {
    yield put(toastShow(`${error?.responseJSON?.status}. Please try again.`));
    throw error;
  }
}

function* putVerificationDataSaga(action) {
  try {
    const response = yield call(
      [consumerApiClient, consumerApiClient.submitVerificationAnswers],
      action.payload
    );
    const isVerificationSuccess = response?.success;
    const isVerified = isVerificationSuccess || response?.home?.verified;

    yield put(updateHomeownerPropertyData(response?.home));
    yield put(
      cacheVerificationData({
        isVerified,
        slug: response?.home?.slug,
        isLocked: response?.is_locked,
        failedCount: response?.home?.failed_count,
        ...(isVerified && { questions: [] }),
        ...(isVerified && { questionsLoadingStatus: STATUSES.SUCCESS }),
      })
    );

    if (isVerificationSuccess) {
      /* Note: this event is used in our TE integration, don't change or remove */
      yield put(
        reportEvent(
          'click_owner_verify_success',
          PARENT_EVENTS.CLICK_OWNER_VERIFY,
          yield select(getHOAnalyticsEventAddress)
        )
      );
      yield put(setActiveNotification('home-verified-notification'));
      yield call(displayEmailOptInModalAfterVerification);
    }
  } catch (error: any) {
    yield put(toastShow(`${error?.responseJSON?.status}. Please try again.`));
    throw error;
  }
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [fetchVerificationData.type]: fetchVerificationDataSaga,
    [putVerificationData.type]: putVerificationDataSaga,
  });
};
