import React, { PureComponent } from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import defaultTheme from '@client/css-modules/LoadingSection.css';
import LoadingSpinner from '@client/inline-svgs/loading-spinner';

const DEFAULT_SPINNER_DELAY = 500;

type Props = {
  /* Whether to display a centered loading spinner in place of the child content */
  isLoading: boolean;
  className?: string;
  /* Milliseconds to wait before displaying spinner */
  spinnerDelay?: number;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  theme: Theme;
  children?: React.ReactNode;
};

type State = {
  isShowingSpinner: boolean;
};

/**
 * Displays a loading spinner in place of passed children content.  Meant to be used
 * to wrap sections of the page that require a loading state.
 */
class LoadingSection extends PureComponent<Props, State> {
  state: State = {
    isShowingSpinner: false,
  };

  spinnerDisplayTimeout: number = 0;

  componentDidMount() {
    const { spinnerDelay } = this.props;
    const effectiveSpinnerDelay =
      spinnerDelay === undefined ? DEFAULT_SPINNER_DELAY : spinnerDelay;

    this.spinnerDisplayTimeout = window.setTimeout(() => {
      this.setState({ isShowingSpinner: true });
    }, effectiveSpinnerDelay);
  }

  componentWillUnmount() {
    if (this.spinnerDisplayTimeout) {
      window.clearTimeout(this.spinnerDisplayTimeout);
    }
  }

  render() {
    const { className, isLoading, theme, children, onClick, onKeyDown, style } =
      this.props;
    const { isShowingSpinner } = this.state;

    return (
      <div
        className={classNames(theme.LoadingSection, {
          [className || '']: !!className,
        })}
        data-testid="loading-section"
        onClick={onClick}
        onKeyDown={onKeyDown}
        style={style}
      >
        {isLoading && isShowingSpinner && (
          <div className={theme.SpinnerWrapper}>
            <LoadingSpinner className={theme.Spinner} />
          </div>
        )}
        {!isLoading && children}
      </div>
    );
  }
}

export default themr('ThemedLoadingSection', defaultTheme)(LoadingSection);
