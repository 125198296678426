import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';

import defaultTheme from '@client/css-modules/ClickableLogo.css';
import { stopEvent, key } from '@client/utils/component.utils';
import { getComeHomeLogoRedirect } from '@client/store/selectors/cobranding-utils.selectors';
import { SVGIconComponentType } from '@client/inline-svgs/types';
import { View } from '@client/routes/constants';
import { getIsShowingComehomeByHouseCanaryHeaderLogo } from '@client/store/selectors/mls-global-display-rules.selectors';
import { searchPropertyListUpdateScrollPosition } from '@client/store/actions/search.actions';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { getActiveHomeownerOrPDPSlug } from '@client/store/selectors/router.selectors';
import { getIsWellsFargoCobrand } from '@client/store/selectors/cobranding.selectors';

type Props = {
  theme: Theme;
  className?: string;
  dataHcName: string;
  Logo:
  | React.FunctionComponent<React.HTMLAttributes<HTMLImageElement>>
  | SVGIconComponentType;
  LogoByHouseCanary?:
  | React.FunctionComponent<React.HTMLAttributes<HTMLImageElement>>
  | SVGIconComponentType;
  ariaLabel?: string;
  alwaysUseRouteChange?: boolean;
  style?: React.CSSProperties;
  reportClickComeHomeLogoOverride?: () => void;
  shouldShowComehomeByHouseCanaryForWells?: boolean;
  manualDestination?: {
    url: string;
    target: string;
  };
};

/**
 * Renders the ComeHome logo and attaches click handlers to either take the user to the home page
 * within the app OR redirect to comehome.com if on a cobranded site.  Also decides whether to use
 * the "ComeHome" or "ComeHome by HouseCanary" logo based on MLS requirements.
 */
const ClickableLogo: React.FC<Props> = ({
  theme,
  dataHcName,
  Logo,
  LogoByHouseCanary,
  ariaLabel,
  alwaysUseRouteChange,
  className,
  style,
  reportClickComeHomeLogoOverride,
  shouldShowComehomeByHouseCanaryForWells,
  manualDestination,
}) => {
  const dispatch = useDispatch();
  const isHomepageEnabled = useSelector(getIsFeatureEnabled('homepage'));
  const handleRouteChange = () => {
    dispatch(
      routeChange({ view: isHomepageEnabled ? View.HOMEPAGE : View.SEARCH })
    );
  };
  const handleResetSearchListScroll = () => {
    dispatch(searchPropertyListUpdateScrollPosition(0));
  };
  const ch_property_id = useSelector(getActiveHomeownerOrPDPSlug);
  const handleReportClickClickableLogo = () => {
    if (reportClickComeHomeLogoOverride) {
      reportClickComeHomeLogoOverride();
    } else {
      dispatch(reportEvent('click_comehome_logo', '', { ch_property_id }));
    }
  };
  const comeHomeRedirect = useSelector(getComeHomeLogoRedirect);
  const isWellsFargoCobrand = useSelector(getIsWellsFargoCobrand);
  /* this is to address compliance issue raised for Wells only */
  const isWellsException = !!(
    shouldShowComehomeByHouseCanaryForWells && isWellsFargoCobrand
  );
  const isShowingLogoByHouseCanary: boolean =
    useSelector(getIsShowingComehomeByHouseCanaryHeaderLogo) ||
    isWellsException;
  const isAlreadyOnHomePage = useSelector(getCurrentView) === View.SEARCH;

  const goToHome = () => {
    handleReportClickClickableLogo();

    if (manualDestination) {
      window.open(manualDestination.url, manualDestination.target);
    } else if (alwaysUseRouteChange || !comeHomeRedirect) {
      handleRouteChange();

      /* When clicking link at the bottom of the property list on the search page */
      if (isAlreadyOnHomePage) {
        handleResetSearchListScroll();
      }
    } else {
      const { url, target } = comeHomeRedirect;
      window.open(url, target);
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (key.isReturn(e.key) || key.isSpace(e.key)) {
      stopEvent(e);
      handleReportClickClickableLogo();
      goToHome();
    }
  };

  const EffectiveLogo =
    isShowingLogoByHouseCanary && LogoByHouseCanary ? LogoByHouseCanary : Logo;

  return (
    <button
      className={classNames(theme.ClickableLogoWrapper)}
      data-hc-name={dataHcName}
      onKeyDown={(e) => handleOnKeyDown(e)}
      onClick={() => goToHome()}
      type="button"
    >
      <EffectiveLogo
        className={classNames(theme.ClickableLogo, {
          [className || '']: className,
          [theme.ClickableLogoByHouseCanary]:
            isShowingLogoByHouseCanary && LogoByHouseCanary,
        })}
        style={style}
        aria-label={
          ariaLabel ? ariaLabel : 'comehome by HouseCanary'
        }
      />
    </button>
  );
};

const ThemedClickableLogo = themr('HeaderLogo', defaultTheme)(ClickableLogo);
export default ThemedClickableLogo;
