import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { Status, STATUSES } from '@client/store/constants';
import { ReduxState } from '@client/store/types/redux-state';

type Credential = { access_key: string; secret_key: string };
export type PartnerAPICredentialState = {
  credential: null | Credential;
  status: Status;
};
const initialState: PartnerAPICredentialState = {
  credential: null,
  status: STATUSES.INIT,
};

export const PartnerAPICredentialSlice = createSlice({
  name: 'partnerAPICredential',
  initialState,
  reducers: {
    fetchPartnerAPICredential: (
      state,
      action: PayloadAction<{ token: string }>
    ) => ({
      ...state,
      status: STATUSES.LOADING,
    }),
    fetchPartnerAPICredentialFailure: (state) => ({
      ...state,
      status: STATUSES.FAILED,
    }),
    fetchPartnerAPICredentialSuccess: (
      _,
      action: PayloadAction<{ credential: Credential }>
    ) => ({
      credential: action.payload.credential,
      status: STATUSES.SUCCESS,
    }),
  },
});

function selectPartnerAPICredentialState(state: ReduxState) {
  return state.partnerAPICredential;
}
export const selectPartnerAPICredential = createSelector(
  selectPartnerAPICredentialState,
  (partnerAPICredentialState) => partnerAPICredentialState.credential
);
export const selectPartnerAPICredentialStatus = createSelector(
  selectPartnerAPICredentialState,
  (partnerAPICredentialState) => partnerAPICredentialState.status
);

export const {
  fetchPartnerAPICredential,
  fetchPartnerAPICredentialFailure,
  fetchPartnerAPICredentialSuccess,
} = PartnerAPICredentialSlice.actions;
export default PartnerAPICredentialSlice.reducer;
