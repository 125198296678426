import { connect } from 'react-redux';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { FeatureId } from '@client/store/types/feature-flags';
import { ReduxState } from '@client/store/types/redux-state';

type OwnProps = {
  renderIfFeaturesEnabled?: FeatureId[];
  renderIfFeaturesNotEnabled?: FeatureId[];
  children: React.ReactNode;
};

type Props = {
  shouldRenderChildren: boolean;
  children?: React.ReactNode;
};

function areAllFeaturesEnabled(
  featureIds: FeatureId[],
  state: ReduxState
): boolean {
  return featureIds.every((featureId) => getIsFeatureEnabled(featureId)(state));
}

const mapStateToProps = (state, ownProps: OwnProps) => {
  const { renderIfFeaturesEnabled, renderIfFeaturesNotEnabled } = ownProps;
  if (renderIfFeaturesEnabled) {
    return {
      shouldRenderChildren: areAllFeaturesEnabled(
        renderIfFeaturesEnabled,
        state
      ),
    };
  } else if (renderIfFeaturesNotEnabled) {
    return {
      shouldRenderChildren: !areAllFeaturesEnabled(
        renderIfFeaturesNotEnabled,
        state
      ),
    };
  } else {
    throw new Error('Invalid props passed to ConditionalFeature component');
  }
};

/**
 * Component that conditionally renders a feature depending on the feature's enabled flag in state
 *
 * Example usage:
 *
 *  <ConditionalFeature renderIfFeaturesEnabled={[FEATURE_IDS.FOO,FEATURE_IDS.BAR]}>
 *    <div>Foo Bar Feature</div>
 *  </CobrandedComponent>
 *
 * Note that ALL features must be enabled/disabled for an Array to pass as truthy.
 */
const ConditionalFeature: React.FC<Props> = ({
  shouldRenderChildren,
  children,
}) => {
  return shouldRenderChildren ? <>{children}</> : null;
};

export default connect(mapStateToProps, {})(ConditionalFeature);
