import classNames from 'classnames';
import React, { Component } from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import defaultTheme from '@client/css-modules/HorizontalSelectorButtons.css';
import CobrandedStyles from '@client/components/CobrandedStyles';

type ValueType = number | string;
type Props = {
  /* Current selected value */
  value: ValueType | null;
  /** Selector items to render */
  options: { label: string; value: any }[];
  /* Divider to appear between options */
  OptionDivider?: React.ComponentType;
  /** Function to call when any selector item is clicked */
  onSelect: (value: ValueType) => void;
  theme: Theme;
  /* For screen readers, a label denoting what the group is controlling */
  ariaLabelledBy?: string;
  /* For screen readers, an id denoting what the control is controlling */
  ariaControls?: string;
  /* For screen readers, an id that can be used to associate the control with something else */
  id?: string;
  ['data-event-name']?: string;
  ['data-parent-event-name']?: string;
};

/**
 * A simple control with buttons laid out in a horizontal fashion.  If needing tabs/radio button
 * functionality, please instead use HorizontalSelectorTabs.  This component doesn't have
 * accessibility features to function as radio buttons
 */
class HorizontalSelectorButtons extends Component<Props> {
  render() {
    const {
      options,
      onSelect,
      value,
      theme,
      OptionDivider,
      ariaLabelledBy,
      ariaControls,
      id,
    } = this.props;

    return (
      <CobrandedStyles>
        {({ activeOptionUnderlineColor }) => {
          return (
            <div
              id={id}
              className={classNames(theme.HorizontalSelectorButtons, {
                [theme.HorizontalSelectorWithDefaultDivider]: !OptionDivider,
              })}
              aria-labelledby={ariaLabelledBy}
            >
              {options.map(({ value: optionValue, label }, i) => (
                <button
                  data-event-name={this.props['data-event-name']}
                  data-parent-event-name={this.props['data-parent-event-name']}
                  key={optionValue}
                  className={theme.Option}
                  onClick={() => onSelect(optionValue)}
                  aria-controls={ariaControls}
                  aria-label={label}
                >
                  <div className={theme.OptionLabelBlock}>
                    <div className={theme.OptionLabelText}>{label}</div>
                    <div
                      className={theme.SelectedIndicator}
                      style={
                        value && value === optionValue
                          ? { background: activeOptionUnderlineColor }
                          : {}
                      }
                    ></div>
                  </div>
                  {OptionDivider && i !== options.length - 1 && (
                    <div className={theme.OptionDividerWrapper}>
                      <>{OptionDivider}</>
                    </div>
                  )}
                </button>
              ))}
            </div>
          );
        }}
      </CobrandedStyles>
    );
  }
}

export default themr(
  'HorizontalSelectorButtonsThemed',
  defaultTheme
)(HorizontalSelectorButtons);
