import React from 'react';
import { Map } from 'mapbox-gl';
import { MapAndAPIContext } from '@hc/hcmaps-mapboxgl/lib/context/map-and-api-context';
import { Api } from '@hc/hcmaps-mapboxgl/lib/components/Maps';

/** Class that handles creating MapboxGL zoom controls and adding them to the map
 * Ported from https://github.com/mapbox/mapbox-gl-js/blob/master/src/ui/control/navigation_control.js */
class MapboxNavigationControl {
  constructor(options: { onZoomIn: VoidFunction; onZoomOut: VoidFunction }) {
    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    this._zoomInButton = this._createButton(
      'mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-in',
      this.handleZoomIn
    );
    this._zoomOutButton = this._createButton(
      'mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-out',
      this.handleZoomOut
    );
    this._zoomInCallback = options.onZoomIn;
    this._zoomOutCallback = options.onZoomOut;
  }

  _map: Map | null = null;
  _container: HTMLDivElement;
  _zoomInButton: HTMLButtonElement;
  _zoomOutButton: HTMLButtonElement;
  _zoomInCallback: () => void;
  _zoomOutCallback: () => void;

  /* Called by Mapbox when the control is added */
  onAdd = (map: Map) => {
    this._map = map;
    return this._container;
  };

  /* Called by Mapbox when the control is removed */
  onRemove = () => {
    if (this._container.parentNode) {
      this._container.parentNode.removeChild(this._container);
    }
    this._map = null;
  };

  handleZoomIn = () => {
    if (this._map) {
      this._map.zoomIn();
    }
    if (this._zoomInCallback) {
      this._zoomInCallback();
    }
  };

  handleZoomOut = () => {
    if (this._map) {
      this._map.zoomOut();
    }
    if (this._zoomOutCallback) {
      this._zoomOutCallback();
    }
  };

  _createButton(className: string, onClick: () => void): HTMLButtonElement {
    const button = window.document.createElement('button');
    const buttonAriaLabel = `${className
      .split(' ')[1]
      .replace('mapboxgl-ctrl-', '')
      .replace('-', ' ')}`;
    button.className = className;
    button.type = 'button';
    button.setAttribute('aria-label', buttonAriaLabel);
    button.setAttribute('data-hc-name', buttonAriaLabel);
    button.addEventListener('click', onClick);
    this._container.appendChild(button);
    return button;
  }
}

type Props = {
  location?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  onZoomIn?: (api: Api) => void;
  onZoomOut?: (api: Api) => void;
};

/**
 * Represents a navigation control to be added to the map
 */
class NavControl extends React.PureComponent<Props> {
  static contextType = MapAndAPIContext;
  declare context: React.ContextType<typeof MapAndAPIContext>;

  navigationControl: MapboxNavigationControl | null = null;

  handleZoomIn = () => {
    const { api } = this.context;
    const { onZoomIn } = this.props;

    if (api && onZoomIn) {
      onZoomIn(api);
    }
  };
  handleZoomOut = () => {
    const { api } = this.context;
    const { onZoomOut } = this.props;

    if (api && onZoomOut) {
      onZoomOut(api);
    }
  };

  componentDidMount() {
    const { map } = this.context;
    const { location } = this.props;

    const effectiveMapboxOptions = {
      onZoomIn: this.handleZoomIn,
      onZoomOut: this.handleZoomOut,
    };

    if (map) {
      this.navigationControl = new MapboxNavigationControl(
        effectiveMapboxOptions
      );
      map.addControl(this.navigationControl, location || 'top-right');
    }
  }

  componentWillUnmount() {
    const { map } = this.context;

    if (map && map.getStyle() && this.navigationControl) {
      map.removeControl(this.navigationControl);
    }
  }

  render() {
    return null;
  }
}

export default NavControl;
