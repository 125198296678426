import cobrandedComponentForKey from '@client/hocs/cobranded-component-for-key';
import { AvmFactorType } from '@client/store/types/avm-break-down';

type Props = {
  className: string;
  type: AvmFactorType;
};

function AvmFactorTypeIcon(props: Props) {
  const IconForAvmFactor = cobrandedComponentForKey(props.type);

  return <IconForAvmFactor {...props} />;
}

export default AvmFactorTypeIcon;
