import { useSelector } from 'react-redux';

import {
  getLoanOfficerInfo,
  getLoanOfficerLastMessageDate,
  getLoCardUrl,
} from '@client/store/selectors/loan-officer.selectors';
import {
  getGenericLOInfo,
  getLoCardConfig,
} from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { LOInfoProviderArgs } from '@client/store/types/loan-officer';

export function useLoInfoProps(): LOInfoProviderArgs {
  const genericLoData = useSelector(getGenericLOInfo);
  const lastMessageDate = useSelector(
    getLoanOfficerLastMessageDate
  ) as ReturnType<typeof getLoanOfficerLastMessageDate>;
  const loConfig = useSelector(getLoCardConfig);
  const loData = useSelector(getLoanOfficerInfo);
  const showLOCard = useSelector(getIsFeatureEnabled('loan_officer'));
  const loCtaUrl = useSelector(getLoCardUrl);
  const getLOCardIntroText = (
    enableIntroText?: boolean | null,
    introText?: string | null,
    introAltText?: string | null
  ): string | null => {
    // if enable is false but intro text is available, use the intro text
    const shouldEnableIntroText = !!enableIntroText || !!introText;
    const availableIntroText = (loData ? introText : introAltText) || null;
    return shouldEnableIntroText ? availableIntroText : null;
  };

  return {
    genericLoData,
    lastMessageDate,
    loConfig,
    loData,
    showLOCard,
    loCtaUrl,
    getLOCardIntroText,
  };
}
