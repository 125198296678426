import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import GreenCheckIcon from '@client/inline-svgs/green-check';
import ChaseSavedSearchNotificationCheckMarkIcon from '@client/inline-svgs/cobrand/chase/chase-saved-search-notification-check-mark-icon';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return ChaseSavedSearchNotificationCheckMarkIcon;
    default:
      return GreenCheckIcon;
  }
};

const GreenCheckMarkIconCobranded: React.FC<{ className?: string }> = (
  props
) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default GreenCheckMarkIconCobranded;
