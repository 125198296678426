import { call, select } from 'redux-saga/effects';
import { consumerApiClient } from '@client/services/consumer-api-client';
import { ReferralFormValues } from '@client/store/types/property-contact-form';
import { getLoanOfficerInfo } from '@client/store/selectors/loan-officer.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';

export function* submitReferral(
  fields: ReferralFormValues,
  loanOfficer: ReturnType<typeof getLoanOfficerInfo>,
  urlPath: string
) {
  const {
    firstName: first_name,
    lastName: last_name,
    email: email,
    message: comments,
    phone: phone_number,
    entrySlug: entry_slug,
    preApproved: pre_approved,
    propertyDetailSlugs: property_detail_slugs,
    hasAgent: has_agent,
    buyingOrSelling,
    agentId: agent_id,
    incompleteAgentId: incomplete_agent_id,
    notes,
    tourRequest: tour_request,
  } = fields;

  const {
    address,
    unit,
    city,
    minPrice: min_price,
    avm,
    state: state_abbreviation,
    zip,
    beds,
    baths,
    sqFt,
    mlsLogoOverlay: mls_logo_overlay,
    photo,
    maxPrice: max_price,
  } = fields.selectedProperty || {};

  const isPipedriveEnabled = (yield select(
    getIsFeatureEnabled('enable_pipedrive')
  )) as boolean;
  const potentiallyRequiredFields = isPipedriveEnabled
    ? {
        state_abbreviation,
        address,
        city,
        zip,
      }
    : {
        state_abbreviation: state_abbreviation ?? 'none',
        address: address ?? 'none',
        city: city ?? 'none',
        zip: zip ?? 'none',
      };

  const selected_property = {
    ...potentiallyRequiredFields,
    unit,
    min_price,
    avm,
    beds,
    baths,
    sqFt,
    mls_logo_overlay,
    photo,
    max_price,
  };

  const data = {
    first_name,
    last_name,
    email,
    phone_number,
    comments,
    entry_slug,
    pre_approved,
    property_detail_slugs,
    selected_property,
    has_agent,
    loan_officer: loanOfficer
      ? {
          name: loanOfficer.fullName,
          phone: loanOfficer.phone,
          email: loanOfficer.email,
        }
      : null,
    buyingOrSelling,
    agent_id,
    incomplete_agent_id,
    notes,
    tour_request,
  };

  /* remove undefined values */
  Object.keys(data).forEach(
    (key) => data[key] === undefined && delete data[key]
  );

  return yield call(
    [consumerApiClient, consumerApiClient.submitFindAgentForm],
    urlPath,
    data
  );
}
