import React from 'react';

import UpdateEmailBannerContainer from '@client/containers/update-email-banner.container';
import ConfirmEmailBanner from '@client/components/ConfirmEmailBanner';

type AppBannerProps = {
  isShowingUpdateEmailBanner: boolean;
  isShowingConfirmEmailBanner: boolean;
};

const AppBanner: React.FunctionComponent<AppBannerProps> = (props) => {
  const { isShowingUpdateEmailBanner, isShowingConfirmEmailBanner } = props;

  return isShowingUpdateEmailBanner ? (
    <UpdateEmailBannerContainer />
  ) : isShowingConfirmEmailBanner ? (
    <ConfirmEmailBanner />
  ) : /* This condition shouldn't be possible due to <AppBanner> only rendering in <Router>
   * when one of the 3 above booleans is true */
  null;
};

export default AppBanner;
