import AccessiblePageTitleContainer from '@client/containers/accessible-page-title.container';
import { useEffect } from 'react';

import theme from '@client/css-modules/LegalDoc.css';
import { AnchorsAttributes, modifyAnchors } from '@client/utils/dom.utils';

type Props = {
  content: string | null;
  handleFetchContent: () => void;
};

const PrivacyPolicyPage = ({ content, handleFetchContent }: Props) => {
  useEffect(() => {
    if (!content) {
      handleFetchContent();
    } else {
      const anchors = document.getElementsByTagName('a');
      const anchorAttributes: AnchorsAttributes = {
        href: 'mailto:privacy@housecanary.com ',
        innerText: 'privacy@housecanary.com ',
      };
      const targetInnerText = '[email protected]';
      modifyAnchors(anchors, anchorAttributes, targetInnerText);
    }
  }, [content]);

  return (
    <section data-hc-name="legal-content" className={theme.LegalDoc}>
      <AccessiblePageTitleContainer />
      <h1 className={theme.Title}>COMEHOME PRIVACY POLICY</h1>
      <hr />
      {/* The HTML content for this page is pulled from HouseCanary.com and therefore is trusted */}
      {content && (
        <div
          className={theme.Content}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </section>
  );
};

export default PrivacyPolicyPage;
