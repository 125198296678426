import { Alert } from '@client/utils/reach-alert';
import React from 'react';
import theme from '@client/css-modules/MapLayersScreenreaderAlert.css';

type Props = {
  layerLabel: string | null;
  featureDescriptions: {
    featureType: string;
    name: string;
    value: number;
  }[];
  featureSummary: string | null;
};

/**
 * An alert to be read to the screenreader user when a map layer is rendered or changes
 */
const MapLayersScreenreaderAlert: React.FC<Props> = ({
  layerLabel,
  featureDescriptions,
  featureSummary,
}) => {
  return (
    <Alert type="polite" className={theme.MapLayersScreenreaderAlert}>
      {layerLabel && (
        <div>
          <div role="heading" aria-level={2}>
            A map overlay showing {layerLabel}.{featureSummary}
          </div>
          {featureDescriptions.length > 0 && (
            <table>
              <caption>
                A table of features shown on the map by census block
              </caption>
              <thead>
                <tr>
                  <th scope="col">Feature Type</th>
                  <th scope="col">Name</th>
                  <th scope="col">Value</th>
                </tr>
              </thead>
              <tbody>
                {featureDescriptions.map((desc) => (
                  <tr key={desc.name}>
                    <td>{desc.featureType}</td>
                    <td>{desc.name}</td>
                    <td>{desc.value == null ? 'no value' : desc.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </Alert>
  );
};

export default MapLayersScreenreaderAlert;
