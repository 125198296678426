import colors from '@client/css-modules/_colors.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { LockIcon } from '@client/inline-svgs/index-for-testing-only';
import { authModalShow } from '@client/store/actions/auth.actions';
import { AUTH_MODAL_PAGES } from '@client/store/constants';
import { getSSOModalConfig } from '@client/store/selectors/cobranding.selectors';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

const StyledLockedContentContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled.button<{ $color?: string }>`
  color: ${(props) => props.$color ?? colors['ch-blue']};
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  font-size: 14px;
  font-weight: 800;
  width: 100%;
`;

const StyledLockIcon = styled(LockIcon)`
  vertical-align: middle;

  & svg {
    height: 20px;
    width: 20px;

    & path {
      stroke-width: 2px;
    }
  }
`;

const StyledTextSpan = styled.span`
  font-weight: 700;
`;

export type LockedContentTheme = {
  LockedContentContainer?: string;
  LockedContentButton?: string;
  LockIcon?: string;
  LockMessage?: string;
};

type Props = {
  theme?: LockedContentTheme;
  lockMessage?: string;
  showLockIcon?: boolean;
};

export const LockedContent = ({ theme, lockMessage, showLockIcon }: Props) => {
  const { linkColor } = useCobrandStyles();
  const dispatch = useDispatch();
  const openAuthModal = () => {
    dispatch(authModalShow({ startingPage: AUTH_MODAL_PAGES.LOGIN }));
  };

  const ssoModalConfig = useSelector(getSSOModalConfig);
  if (!ssoModalConfig?.specialUserTypeSsoCtaUrl) return null;

  return (
    <StyledLockedContentContainer className={theme?.LockedContentContainer}>
      <StyledButton
        className={theme?.LockedContentButton}
        $color={linkColor}
        onClick={openAuthModal}
      >
        {showLockIcon && <StyledLockIcon className={theme?.LockIcon} />}
        {lockMessage && (
          <StyledTextSpan className={theme?.LockMessage}>
            {`${ssoModalConfig?.specialUserTypeSsoCtaText} ${lockMessage}`}
          </StyledTextSpan>
        )}
      </StyledButton>
    </StyledLockedContentContainer>
  );
};
