import { LatitudeLongitudeObject } from '@client/store/types/maps';
import { LocalActivitiesQuery } from '@client/store/sagas/queries/types';
import { ActivityId } from '@client/store/constants';

export const FETCH_LOCAL_ACTIVITIES = 'FETCH_LOCAL_ACTIVITIES';
export const FETCH_LOCAL_ACTIVITIES_SUCCESS = 'FETCH_LOCAL_ACTIVITIES_SUCCESS';
export const FETCH_LOCAL_ACTIVITIES_ERROR = 'FETCH_LOCAL_ACTIVITIES_ERROR';
export const SET_LOCAL_ACTIVITIES_FILTER = 'SET_LOCAL_ACTIVITIES_FILTER';
export const RESET_LOCAL_ACTIVITIES_FILTER = 'RESET_LOCAL_ACTIVITIES_FILTER';

export type FetchLocalActivitiesAction = {
  type: typeof FETCH_LOCAL_ACTIVITIES;
  payload: {
    propertyLocation: LatitudeLongitudeObject;
  };
};

export const fetchLocalActivities = (
  propertyLocation: FetchLocalActivitiesAction['payload']['propertyLocation']
): FetchLocalActivitiesAction => ({
  type: FETCH_LOCAL_ACTIVITIES,
  payload: {
    propertyLocation,
  },
});

type FetchLocalActivitiesSuccessAction = {
  type: typeof FETCH_LOCAL_ACTIVITIES_SUCCESS;
  payload: {
    data: LocalActivitiesQuery;
  };
};

export const fetchLocalActivitiesSuccess = (
  data: LocalActivitiesQuery
): FetchLocalActivitiesSuccessAction => ({
  type: FETCH_LOCAL_ACTIVITIES_SUCCESS,
  payload: {
    data,
  },
});

type FetchLocalActivitiesErrorAction = {
  type: typeof FETCH_LOCAL_ACTIVITIES_ERROR;
};

export const fetchLocalActivitiesError =
  (): FetchLocalActivitiesErrorAction => ({
    type: FETCH_LOCAL_ACTIVITIES_ERROR,
  });

type SetLocalActivitiesFilterAction = {
  type: typeof SET_LOCAL_ACTIVITIES_FILTER;
  payload: {
    type: ActivityId;
    title: string;
  };
};

export const setLocalActivitiesFilter = (
  type: ActivityId,
  title: string
): SetLocalActivitiesFilterAction => ({
  type: SET_LOCAL_ACTIVITIES_FILTER,
  payload: {
    type,
    title,
  },
});

type ResetLocalActivitiesFilter = {
  type: typeof RESET_LOCAL_ACTIVITIES_FILTER;
};

export const resetLocalActivitiesFilter = (): ResetLocalActivitiesFilter => ({
  type: RESET_LOCAL_ACTIVITIES_FILTER,
});

export type LocalActivitiesAction =
  | FetchLocalActivitiesAction
  | FetchLocalActivitiesSuccessAction
  | FetchLocalActivitiesErrorAction
  | SetLocalActivitiesFilterAction
  | ResetLocalActivitiesFilter;
