import React from 'react';
import SearchPageMapClient, {
  SearchMapPageProps,
} from '@client/components/SearchPageMap';
import SearchPageMapServer from '@client/components/SearchPageMapServer';

export default class SearchPageMapWrapper extends React.Component<SearchMapPageProps> {
  state = {
    isMounted: false,
  };

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  render() {
    const { isMounted } = this.state;

    return isMounted ? (
      <SearchPageMapClient {...this.props} />
    ) : (
      <SearchPageMapServer {...this.props} />
    );
  }
}
