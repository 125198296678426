import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import NoAvmPlaceholderCobranded from '@client/components/NoAvmPlaceholder/NoAvmPlaceholderCobranded';
import defaultTheme from '@client/css-modules/PropertyCardValue.css';
import { LockedComponent } from '@client/hocs/locked-component';
import { LOCKED_RENTAL_ESTIMATE_VALUE_PLACEHOLDER } from '@client/store/locked-components-constants';
import { getSearchResultsPageConfig } from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  SectionId,
  SpecialUserType,
} from '@client/store/types/locked-components';
import { dollarsWithPlaceholder } from '@client/utils/string.utils';

export type PropertyCardValueProps = {
  bottomLabel: string | JSX.Element;
  monthlyPaymentEstimate?: number | null;
  theme: Theme;
  valueLabel: string | JSX.Element;
  value: number | null;
  rentalEstimate?: number | null;
};

/**
 * The price and the price label inline horizontally with monthly mortgage payment
 */
const PropertyCardValue: React.FunctionComponent<PropertyCardValueProps> = ({
  bottomLabel,
  monthlyPaymentEstimate,
  theme,
  value,
  valueLabel,
  rentalEstimate,
}) => {
  const isEstimatedMortgagePaymentsFeatureActivated = useSelector(
    getIsFeatureEnabled('estimated_mortgage_payments')
  );

  const searchResultsPageConfig = useSelector(getSearchResultsPageConfig);

  return (
    <div
      className={classNames(theme.PropertyCardValue, {
        [theme.PropertyCardValueNoEstimatedPayment]:
          !isEstimatedMortgagePaymentsFeatureActivated,
      })}
    >
      <div
        className={classNames(theme.PriceRow, {
          [theme.PriceRowNoEstimatedPayment]:
            !isEstimatedMortgagePaymentsFeatureActivated,
        })}
        data-hc-name="property-status"
      >
        <div className={theme.ValueLabel} data-hc-name="property-status-text">
          {valueLabel}
        </div>
        <div className={theme.Value} data-hc-name="property-price">
          {value ? (
            dollarsWithPlaceholder(value)
          ) : (
            <NoAvmPlaceholderCobranded theme={theme} />
          )}
        </div>
        {searchResultsPageConfig?.showRentalEstimate && (
          <div className={theme.RentalEstimate}>
            <div className={theme.RentalEstimateLabel}>Rental Estimate: </div>

            <LockedComponent
              sectionId={SectionId.PropertyCardRentalEstimate}
              lockedFor={[SpecialUserType.Restricted]}
              theme={theme}
              className={theme.LockedComponentContainer}
            >
              {({ isLocked }) => (
                <div
                  className={classNames(theme.RentalEstimateValue, {
                    [theme.disabled]: isLocked,
                  })}
                >
                  {isLocked
                    ? `${LOCKED_RENTAL_ESTIMATE_VALUE_PLACEHOLDER}`
                    : `${dollarsWithPlaceholder(rentalEstimate)}/month`}
                </div>
              )}
            </LockedComponent>
          </div>
        )}
      </div>
      {isEstimatedMortgagePaymentsFeatureActivated && (
        <>
          <div className={theme.HorizontalSeparator} />
          <div
            className={theme.EstimatedMonthlyPayment}
            data-hc-name="property-monthly-payment"
          >
            Estimated monthly payment{' '}
            {dollarsWithPlaceholder(monthlyPaymentEstimate)}
          </div>
        </>
      )}
      {bottomLabel && <div className={theme.BottomLabel}>{bottomLabel}</div>}
    </div>
  );
};

export default themr(
  'PropertyCardValueThemed',
  defaultTheme
)(PropertyCardValue);
