import React, { Component } from 'react';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';

import {
  FilterKey,
  FilterMinMaxNumberValue,
  FilterMinMaxStringValue,
  CONTROL_CUSTOMIZATIONS,
} from '@client/store/filter-constants';
import RangeSlider from '@client/components/generic/RangeSlider';
import { getFilterControlCustomization } from '@client/utils/filters.utils';
import defaultTheme from '@client/css-modules/MobileFiltersSlider.css';

export type MobileFiltersSliderProps = {
  theme: Theme;
  filterKey: FilterKey;
  values: (number | string)[];
  getValueForControlFormatter: (val: number | string | null) => number;
  labelFormatter: (value: number | { min: number; max: number }) => string;
  setValueForStateFormatter: (val: number) => number;
  onChange: (value: [number | null, number | null]) => void;
  handleReportValueSelection: (value: null) => void;
  currentValues: FilterMinMaxNumberValue | FilterMinMaxStringValue;
  ariaLabelledBy: string;
};

type State = {
  min: number;
  max: number;
};

class MobileFiltersSlider extends Component<MobileFiltersSliderProps, State> {
  values: number[] = [];
  constructor(props: MobileFiltersSliderProps) {
    super(props);
    this.values = props.values.map((v) => props.getValueForControlFormatter(v));
    const { getValueForControlFormatter, currentValues } = props;
    const formattedCurrentValues = [
      getValueForControlFormatter(currentValues[0]),
      getValueForControlFormatter(currentValues[1]),
    ];
    this.state = {
      min: formattedCurrentValues[0] || this.values[0],
      max: formattedCurrentValues[1] || this.values[this.values.length - 1],
    };
  }

  handleValueChange = (value): void => {
    const { onChange, setValueForStateFormatter } = this.props;
    /**
     * For 30% JS returns 0.30000000004, how numbers are handled in JS
     * Using toFixed to restrict to 2 significant digits, toFixed returns a string
     * hence converting it into a number
     */
    const min = Number(value.min.toFixed(2));
    const max = Number(value.max.toFixed(2));
    const returnMin =
      min === this.values[0] ? null : setValueForStateFormatter(min);
    const returnMax =
      max === this.values[this.values.length - 1]
        ? null
        : setValueForStateFormatter(max);

    onChange([returnMin, returnMax]);
    /* Updating control value via local state for performance */
    this.setState({
      min: min,
      max: max,
    });
  };

  render() {
    const {
      theme,
      labelFormatter,
      filterKey,
      ariaLabelledBy,
      handleReportValueSelection,
    } = this.props;
    const { min, max } = this.state;
    const minValue = this.values[0];
    const maxValue = this.values[this.values.length - 1];
    const customizations = getFilterControlCustomization(filterKey);
    const shouldReverseColors =
      !!customizations?.[CONTROL_CUSTOMIZATIONS.SHOULD_REVERSE_SLIDER_COLORS];

    return (
      <div className={theme.MobileFiltersSlider}>
        <RangeSlider
          ariaLabelledBy={ariaLabelledBy}
          className={shouldReverseColors ? theme.hotToCool : theme.coolToHot}
          labelFormatter={labelFormatter}
          minValue={minValue}
          maxValue={maxValue}
          value={{
            min: min,
            max: max,
          }}
          theme={theme}
          step={this.values[1] - this.values[0]}
          onChangeComplete={this.handleValueChange}
          handleReportValueSelection={handleReportValueSelection}
          rightSideLabel={''}
        />
      </div>
    );
  }
}

export default themr('MobileFiltersSlider', defaultTheme)(MobileFiltersSlider);
