import React from 'react';
import classNames from 'classnames';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import defaultTheme from '@client/css-modules/ValueComparison.css';
import Arrow from '@client/inline-svgs/arrow';
import { dollarsFormatter } from '@client/utils/formatter.utils';

type ArrowProps = {
  className?: string;
};

type ValuesObject = {
  leftText?: string;
  leftValue?: string;
  rightText?: string;
  rightValue?: string;
  Arrow?: any;
  arrowClasses?: string;
};

type Props = {
  hpiLastYear: number | null;
  hpiNow: number | null;
  hpiNextYear: number | null;
  theme: Theme;
};

class ValueComparison extends React.Component<Props> {
  getComparisonText = () => {
    const { hpiLastYear, hpiNow, hpiNextYear, theme } = this.props;

    const values: ValuesObject = {};

    if (hpiLastYear && hpiNow) {
      values.leftValue = dollarsFormatter(hpiLastYear);
      values.rightValue = dollarsFormatter(hpiNow);
      values.leftText = 'Last year:';
      values.rightText = 'Today:';

      if (hpiLastYear !== hpiNow) {
        values.arrowClasses =
          hpiNow > hpiLastYear
            ? classNames(theme.Arrow, theme.Up)
            : classNames(theme.Arrow, theme.Down);
        values.Arrow = (props: ArrowProps) => (
          <Arrow className={props.className} />
        );
      }
    } else if (hpiNextYear && hpiNow) {
      values.leftValue = dollarsFormatter(hpiNow);
      values.rightValue = dollarsFormatter(hpiNextYear);
      values.leftText = 'Today:';
      values.rightText = 'Next year:';

      if (hpiNextYear !== hpiNow) {
        values.arrowClasses =
          hpiNow < hpiNextYear
            ? classNames(theme.Arrow, theme.Up)
            : classNames(theme.Arrow, theme.Down);
        values.Arrow = (props: ArrowProps) => (
          <Arrow className={props.className} />
        );
      }
    }

    return values;
  };

  render() {
    const { theme } = this.props;

    const { leftValue, rightValue, leftText, rightText, Arrow, arrowClasses } =
      this.getComparisonText();

    return (
      <div className={theme.ValueRow}>
        <div className={theme.ValueColumn}>
          <div className={theme.ValueText}>{leftText}</div>
          <div className={theme.Value}>{leftValue}</div>
        </div>
        <div className={theme.ValueColumn}>
          <div className={theme.ValueText}>{rightText}</div>
          <div className={theme.Value}>
            {rightValue}
            {Arrow && <Arrow className={arrowClasses} />}
          </div>
        </div>
      </div>
    );
  }
}

export default themr('ValueComparisonThemed', defaultTheme)(ValueComparison);
