import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { PropertyCardLenderCTASectionProps } from '@client/store/types/cobranded-components/property-card-lender-cta-section';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import PropertyCardCTASectionChase from './PropertyCardCTASectionChase';
import PropertyCardCTASection from './PropertyCardCTASection';

export const getPropertyCardCTAComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return PropertyCardCTASectionChase;
    case COBRAND_IDS.WWW:
      return null;
    case COBRAND_IDS.TOPAGENTSRANKED:
      return null;
    default:
      return PropertyCardCTASection;
  }
};

const PropertyCardLenderCTASectionCobranded: React.FC<
  PropertyCardLenderCTASectionProps
> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getPropertyCardCTAComponentForCobrand(cobrandId);
  return Component ? <Component {...props} /> : null;
};

export default PropertyCardLenderCTASectionCobranded;
