import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import Layer from '@hc/hcmaps-mapboxgl/lib/components/Layer';
import LayerSource from '@hc/hcmaps-mapboxgl/lib/components/LayerSource';
import { Api } from '@hc/hcmaps-mapboxgl/lib/components/Maps';
import Popup from '@hc/hcmaps-mapboxgl/lib/components/Popup';
import SymbolsLayer from '@hc/hcmaps-mapboxgl/lib/components/SymbolsLayer';
import { MapAndAPIContext } from '@hc/hcmaps-mapboxgl/lib/context/map-and-api-context';
import * as Tabs from '@radix-ui/react-tabs';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { throttle, uniqBy, values } from 'lodash';
import {
  Expression,
  LngLatBounds,
  MapLayerMouseEvent,
  MapboxGeoJSONFeature,
  Popup as MapboxPopup,
} from 'mapbox-gl';
import React, { Component } from 'react';

import HC_CONSTANTS from '@client/app.config';
import CobrandedStyles from '@client/components/CobrandedStyles';
import BinaryHorizontalToggle from '@client/components/generic/BinaryHorizontalToggle';
import HorizontalSelectorButtons from '@client/components/generic/HorizontalSelectorButtons';
import HorizontalSelectorTabs from '@client/components/generic/HorizontalSelectorTabs';
import Tooltip from '@client/components/generic/Tooltip';
import BlocksLayer from '@client/components/MapBlocksLayer';
import MapLayersScreenreaderAlert from '@client/components/MapLayersScreenreaderAlert';
import MapLegend from '@client/components/MapLegend';
import SchoolMarkerPopup from '@client/components/SchoolMapMarkerPopup';
import SearchMapNotification from '@client/components/SearchMapNotification';
import SymbolsLayerAccessibilityControl from '@client/components/SymbolsLayerAccessibilityControl';
import defaultTheme from '@client/css-modules/MapLayersControl.css';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import { LockedComponent } from '@client/hocs/locked-component';
import CloseIcon from '@client/inline-svgs/close';
import {
  ParentEventType,
  eventType,
} from '@client/store/actions/analytics.actions';
import {
  BLOCK_FEATURES_FILTER,
  FIT_POPUP_PADDING_WITH_DESKTOP_CONTROLS,
  HALFTONE_IMAGES,
  LAYERS_TO_USE_GRADIENTS,
  LAYER_CONTROL_GROUPS,
  LAYER_GROUP_KEYS,
  LAYER_GROUP_LAYER_METRICS,
  LAYER_GROUP_LEGEND_CONFIG,
  LAYER_LABELS,
  LAYER_PRESET_TYPES,
  LAYER_PRESET_TYPES_FOR_METRIC,
  LAYER_SOURCE_ZOOM_MAPPING,
  LayerGroup,
  LayerMetric,
  MAPBOX_LAYER_IDS,
  MARKER_POPUP_Y_OFFSET,
  MapboxLayerId,
  NO_DATA_FOR_LAYER_MESSAGE,
  SCHOOLS_LAYER_FILTER_DEF,
  SCHOOLS_LEGEND_INTERVALS,
  SCHOOL_MARKER_IMAGE_IDS,
  SCHOOL_MARKER_IMAGE_URL_BY_ID,
  TOO_FAR_ZOOMED_OUT_ERROR_MESSAGE_FOR_LAYER_GROUP,
} from '@client/store/map-constants';
import { SpecialUserType } from '@client/store/types/locked-components';
import {
  LayerGroupLabel,
  LegendBreaksData,
  LegendColorTable,
  LegendInterval,
} from '@client/store/types/maps';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { key } from '@client/utils/component.utils';
import {
  getBlocksLayerFillDefinition,
  getLegendBreakGradients,
  getLegendBreaksUUIDForLayer,
  loadHalftoneImages,
} from '@client/utils/map-controls.utils';
import {
  computeBoundsArrayFromLatLngPoints,
  getColorTableFromIntervals,
  getDistance,
  getIsPointInsideBounds,
  getSchoolDistrictFeatureColor,
  padBoundsByPercentage,
} from '@client/utils/maps.utils';
import { createPortal } from 'react-dom';

const { GEOTILE_DATA_URL } = HC_CONSTANTS;
const SCHOOLS_SYMBOLS_SOURCE = 'schools-symbol-source';
const SCHOOLS_SOURCE_LAYER_ID = 'schools_raw';
const SCHOOLS_LAYER_SCHOOL_KIND = 'school';
const SET_DEFAULT_ACTIVE_GROUP_DELAY = 1000;
const FLY_TO_AUTO_ACTIVE_SCHOOL_DURATION = 1000;
const SCHOOLS_MIN_ZOOM = 9;
const { bottomGroupsControlHeight } = defaultTheme;

const SWITCH_ANIMATION_VARIANTS = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      easing: 'easeOut',
      delay: 0.36,
      duration: 0.15,
    },
  },
};

const QUICK_FADE_ANIMATION_VARIANTS = {
  animate: {
    opacity: 1,
    transition: {
      default: {
        duration: 300,
        delay: 100,
        easing: 'easeOut',
      },
    },
  },
};

const SYMBOLS_LAYER_A11Y_MARKER_DATA_ATTR_NAME =
  'symbols-layer-accessibility-id';
const SYMBOLS_LAYER_A11Y_MARKER_DATA_KEY = 'uid';

type TriggerProp = string | number | boolean | null;

type SchoolFeatureProperties = {
  all_district: boolean;
  coex: boolean;
  indent: number;
  kind: string;
  lat: number;
  level: string;
  lon: number;
  name: string;
  rank: number;
  rank_unrounded: number;
  uid: string;
};

type Props = {
  /* Callback method executed when 'Show Properties' toggle is changed */
  handleToggleMarkers?: (api: Api, isShowingMarkers: boolean) => void;
  /* Callback executed when active layer is changed by the user */
  onActiveLayerChange: (metric: LayerMetric | null) => void;
  /* Identifier of a group to enable on mount */
  defaultActiveLayerGroup?: LayerGroup;
  /* Object containing legend breaks for all layer metrics */
  legendBreaks: LegendBreaksData;
  layerGroupLabelsWithDataAttribution: { [K in LayerGroup]: LayerGroupLabel };
  /* If provided, layers in this component are inserted beneath this layer on the map */
  beneathLayerId?: MapboxLayerId;
  /* The name of a school district boundary that should be highlighted on init of schools layer */
  autoHighlightSchoolDistrictName?: string | null;
  /* The padding from the edge of the viewport to add when panning the map to fit the popup */
  fitPopupPadding?: {
    x: number;
    y: number;
  };
  /* Text to show in place of the bottom map layer items control */
  MapNotification?: string | false | JSX.Element;
  /* Callback executed after school district is auto-highlighted when enabling the schools layer */
  handleAutoActiveSchoolDistrictActivate?: (
    api: Api,
    schoolFeature: MapboxGeoJSONFeature
  ) => void;
  handleCloseBottomGroupsControl?: VoidFunction;
  /* Whether to display the layer groups as a 'floating' control in the top-left map area */
  useFloatingLayerGroupsControl?: boolean;
  /* Whether to display the layer groups in an animated list in the bottom map area (used for mobile) */
  isShowingBottomLayerGroupsControl?: boolean;
  /* Whether to allow display of the active map layer control at the map bottom (hiding on mobile in certain cases) */
  allowShowingBottomControl?: boolean;
  handleGetMapLayerLegendBreaks: (
    mapBounds: LngLatBounds,
    zoom: number,
    metric: LayerMetric
  ) => void;
  /* Associate the controls with the Map component's id for screen readers and accessibility */
  mapAriaId: string;
  /* Whether markers are being hidden via the 'Show Properties' control */
  isShowingMarkers?: boolean;
  isSchoolDataEnabled: boolean;
  isMapNotificationAboveYourTeamButton: boolean;
  closeBottomLayerControlTrigger?: TriggerProp;
  theme: Theme;
  showPropertiesDefaultOn?: boolean;
  reportEvent: (event: eventType, parentEvent: ParentEventType | null) => void;
};

type featureDescription = {
  featureType: string;
  name: string;
  value: any;
};

type State = {
  activeGroupId: LayerGroup | null;
  activeLayerId: LayerMetric | null;
  activeSchoolFeatureProps: SchoolFeatureProperties | null;
  allSchoolIconImagesLoaded: boolean;
  allHalftoneImagesLoaded: boolean;
  currentZoom: number | null;
  featureDescriptions: featureDescription[];
  /* keeping its own show property state in the MapLayersControl because the parent SRP component does not persist users' decision on show property toggle for MapLayersControl */
  isShowingMarkersInMapLayers: boolean;
  hasMonochromeOverlay: boolean;
  repaintTrigger: string | null;
  /* API returned properties for a school feature:
   * { name, rank_unrounded, uid, lat, lon } */
  shouldFitPopupInViewport: boolean;
  isSchoolsLayerAccessibilityEnabled: boolean;
};

type FloatingLayerGroupsControlProps = {
  theme: Theme;
  activeGroupId: LayerGroup | null;
  handleLayersGroupClick: (groupId: LayerGroup) => void;
  effectiveLayerControlGroups: Array<LayerGroup>;
  layerGroupLabelsWithDataAttribution: { [K in LayerGroup]: LayerGroupLabel };
  mapAriaId: string;
  activeOptionUnderlineColor?: string;
};

class FloatingLayerGroupsControl extends Component<FloatingLayerGroupsControlProps> {
  render() {
    const {
      theme,
      activeGroupId,
      handleLayersGroupClick,
      effectiveLayerControlGroups,
      layerGroupLabelsWithDataAttribution,
      mapAriaId,
      activeOptionUnderlineColor,
    } = this.props;

    return (
      <Tabs.Root
        id="LayerGroupsControl"
        key="LayerGroupsControl"
        className={theme.LayerGroupsControl}
        data-hc-name={'layer-buttons'}
        /* Need to set value to '' when deselecting a group to allow reselection of the same group (setting to undefined doesn't allow reselection) */
        value={activeGroupId || ''}
        activationMode="manual"
        onValueChange={(value) => handleLayersGroupClick(value as LayerGroup)}
      >
        <Tabs.List className={theme.LayerGroupsControlInner}>
          {effectiveLayerControlGroups.map((groupId) => (
            <LockedComponent
              className={theme.LockedComponentContainer}
              sectionId={groupId}
              lockedFor={[SpecialUserType.Restricted]}
              theme={theme}
              key={`layerGroupsControl${groupId}LockedComponent`}
            >
              {({ isLocked }) => (
                <Tabs.Trigger
                  id={`LayerGroupControlItem_${groupId}`}
                  disabled={isLocked}
                  value={groupId}
                  data-hc-name={`${layerGroupLabelsWithDataAttribution[
                    groupId
                  ].label.toLowerCase()}-button`}
                  data-event-name={`click_heatmap_${layerGroupLabelsWithDataAttribution[
                    groupId
                  ].label.toLowerCase()}`}
                  tabIndex={0}
                  aria-controls={mapAriaId}
                  aria-selected={groupId === activeGroupId}
                  aria-label={`${layerGroupLabelsWithDataAttribution[groupId].label} map layer`}
                  key={groupId}
                  className={classNames(theme.LayerGroupsControlButton, {
                    [theme.locked]: isLocked,
                  })}
                  onKeyDown={onEnterOrSpaceKey(() =>
                    handleLayersGroupClick(groupId)
                  )}
                >
                  <div className={theme.LayerGroupsControlButtonLabel}>
                    {layerGroupLabelsWithDataAttribution[groupId].label}
                  </div>
                  <div
                    className={theme.SelectedIndicator}
                    style={
                      groupId === activeGroupId
                        ? { background: activeOptionUnderlineColor }
                        : {}
                    }
                  />
                </Tabs.Trigger>
              )}
            </LockedComponent>
          ))}
        </Tabs.List>
      </Tabs.Root>
    );
  }
}

class MapLayersControl extends Component<Props, State> {
  schoolDistrictShownViaClick: boolean = true;
  setDefaultActiveGroupTimeout: number | null = null;
  /* Used to cache the legend color table data so that we can still display it when legend data is
   * not available for UI reasons */
  cachedLegendColorTable: LegendColorTable | null = null;
  throttledGenerateTextualDescriptions: any = null;
  resetShouldFitPopupInViewportTimeout: null | number = null;
  schoolMarkerA11yFocusTimeout: null | number = null;
  previouslyFocusedSchoolMarkerId: string | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      activeGroupId: null,
      activeLayerId: null,
      currentZoom: null,
      repaintTrigger: null,
      /* API returned properties for a school feature:
       * { name, rank_unrounded, uid, lat, lon } */
      activeSchoolFeatureProps: null,
      allSchoolIconImagesLoaded: false,
      allHalftoneImagesLoaded: false,
      shouldFitPopupInViewport: true,
      hasMonochromeOverlay: false,
      featureDescriptions: [],
      isShowingMarkersInMapLayers: !!props.showPropertiesDefaultOn,
      isSchoolsLayerAccessibilityEnabled: false,
    };
  }

  static contextType = MapAndAPIContext;
  declare context: React.ContextType<typeof MapAndAPIContext>;

  componentDidMount() {
    const { defaultActiveLayerGroup } = this.props;
    const { map } = this.context;

    this.throttledGenerateTextualDescriptions = throttle(
      this.handleGenerateTextualDescriptions,
      300,
      {
        trailing: true,
      }
    );

    if (map) {
      map.on('click', this.handleMapClick);
      map.on('zoomend', this.handleZoomEnd);
      map.on('data', this.throttledGenerateTextualDescriptions);
      map.on('moveend', this.throttledGenerateTextualDescriptions);
    }

    /* Set default active group if provided */
    if (defaultActiveLayerGroup) {
      /* Delaying here for the PDP case.  Probably would be wise to keep this delay
       * for other pages as well, as generally other JS work should take priority
       * on mount */
      this.setDefaultActiveGroupTimeout = window.setTimeout(() => {
        this.setActiveGroup({
          groupId: defaultActiveLayerGroup,
          layerId: null,
          toggledProgrammatically: true,
        });
      }, SET_DEFAULT_ACTIVE_GROUP_DELAY);
    }

    this.bindWindowEvents();
  }

  componentWillUnmount() {
    const { map } = this.context;

    if (map) {
      map.off('click', this.handleMapClick);
      map.off('zoomend', this.handleZoomEnd);
    }

    if (this.setDefaultActiveGroupTimeout) {
      window.clearTimeout(this.setDefaultActiveGroupTimeout);
    }
    if (this.resetShouldFitPopupInViewportTimeout) {
      window.clearTimeout(this.resetShouldFitPopupInViewportTimeout);
    }
    if (this.schoolMarkerA11yFocusTimeout) {
      window.clearTimeout(this.schoolMarkerA11yFocusTimeout);
    }

    if (this.throttledGenerateTextualDescriptions && map) {
      map.off('data', this.throttledGenerateTextualDescriptions);
      map.off('moveend', this.throttledGenerateTextualDescriptions);
      this.throttledGenerateTextualDescriptions.cancel();
      this.throttledGenerateTextualDescriptions = null;
    }

    if (map && map.getStyle()) {
      this.removeSchoolMarkerImages();
    }

    this.unbindWindowEvents();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { activeGroupId, activeLayerId, hasMonochromeOverlay } = this.state;
    const {
      legendBreaks,
      isShowingBottomLayerGroupsControl,
      closeBottomLayerControlTrigger,
    } = this.props;

    if (activeGroupId && activeLayerId) {
      const prevLegendBreaksUUID = `${getLegendBreaksUUIDForLayer(
        prevProps.legendBreaks,
        activeLayerId
      )}-${prevState.hasMonochromeOverlay}`;
      const legendBreaksUUID = `${getLegendBreaksUUIDForLayer(
        legendBreaks,
        activeLayerId
      )}-${hasMonochromeOverlay}`;

      /* Tell BlocksLayer to repaint the active layer if the legend breaks change (occurs after a pan/zoom) */
      if (prevLegendBreaksUUID !== legendBreaksUUID) {
        this.setState({ repaintTrigger: legendBreaksUUID });
      }
    }
    if (
      !prevProps.isShowingBottomLayerGroupsControl &&
      isShowingBottomLayerGroupsControl
    ) {
      this.setActiveGroup({
        groupId: null,
        layerId: null,
        toggledProgrammatically: false,
      });
    }
    if (
      closeBottomLayerControlTrigger !==
      prevProps.closeBottomLayerControlTrigger
    ) {
      this.setActiveGroup({
        groupId: null,
        layerId: null,
        toggledProgrammatically: false,
      });
    }
  }

  bindWindowEvents = (): void => {
    window.addEventListener('keydown', this.handleOnKeyDown);
  };

  unbindWindowEvents = (): void => {
    window.removeEventListener('keydown', this.handleOnKeyDown);
  };

  handleOnKeyDown = (e: KeyboardEvent): void => {
    const { isSchoolsLayerAccessibilityEnabled } = this.state;

    if (key.isTab(e.key) && !isSchoolsLayerAccessibilityEnabled) {
      this.setState({ isSchoolsLayerAccessibilityEnabled: true });
    }
  };

  effectiveLayerControlGroups = LAYER_CONTROL_GROUPS.filter((groupId) => {
    return (
      groupId !== LAYER_GROUP_KEYS.SCHOOLS || this.props.isSchoolDataEnabled
    );
  });

  handleGenerateTextualDescriptions = () => {
    const { map } = this.context;
    const { activeLayerId, activeGroupId, currentZoom } = this.state;
    let mapLayerId;
    let zooms = activeLayerId ? LAYER_SOURCE_ZOOM_MAPPING[activeLayerId] : null;
    if (zooms == null) {
      return;
    }
    for (let k of Object.keys(zooms)) {
      let v = zooms[k];
      if (currentZoom && currentZoom >= v[0]) {
        mapLayerId = `${k}_layer`;
        break;
      }
    }

    /* Ensure that the map and map's style are ready, that the layer has been added to the map,
     * and that all tiles are loaded (else we're wasting effort on checking for mls coverage at this time)  */
    if (
      !map ||
      !map.getStyle() ||
      !map.getLayer(mapLayerId) ||
      !map.areTilesLoaded()
    ) {
      return;
    }

    if (activeGroupId === LAYER_GROUP_KEYS.SCHOOLS) {
      this.setState({ featureDescriptions: [] });
      return;
    }

    let descriptions = {};
    map
      .queryRenderedFeatures(undefined, { layers: [mapLayerId] })
      .forEach((feature) => {
        if (
          feature &&
          feature.properties &&
          feature.properties.uid &&
          activeLayerId
        ) {
          descriptions[feature.properties.uid] =
            feature.properties[activeLayerId];
        }
      });

    const featureType = `Census Block${
      currentZoom && currentZoom < 13 ? ' Group' : ''
    }`;
    const featureDescriptions = Object.keys(descriptions).map((k) => {
      return {
        featureType,
        name: k,
        value: descriptions[k],
      };
    });

    this.setState({ featureDescriptions });
  };

  /**
   * Hide the active school district layer when clicking the map.
   */
  handleMapClick = () => {
    this.schoolDistrictShownViaClick = false;
    this.setState({ activeSchoolFeatureProps: null });
  };

  handleZoomEnd = () => {
    const { map } = this.context;

    if (map) {
      this.setState({ currentZoom: map.getZoom() });
    }
  };

  /**
   * Show the school district and popup with school info for the clicked-on school icon
   */
  handleSchoolIconClick = (e: MapLayerMouseEvent) => {
    this.schoolDistrictShownViaClick = true;
    this.setState({
      activeSchoolFeatureProps: e.features
        ? (e.features[0].properties as SchoolFeatureProperties | null)
        : null,
    });
  };

  /**
   * Show the school district and popup with school info for the clicked-on school icon
   */
  handleSchoolIconEnterOrSpaceKey = (
    e: KeyboardEvent,
    feature: MapboxGeoJSONFeature
  ) => {
    const featureProperties = feature.properties as SchoolFeatureProperties;

    this.schoolDistrictShownViaClick = true;
    this.setState({ activeSchoolFeatureProps: featureProperties });
    this.previouslyFocusedSchoolMarkerId =
      featureProperties[SYMBOLS_LAYER_A11Y_MARKER_DATA_KEY];
  };

  /**
   * Show school district when hovering school marker
   */
  handleSchoolIconMouseEnter = (e: MapLayerMouseEvent) => {
    const setNewActiveSchoolFeatureProps = () => {
      this.setState({
        activeSchoolFeatureProps: e.features
          ? (e.features[0].properties as SchoolFeatureProperties | null)
          : null,
      });
    };
    if (
      this.schoolDistrictShownViaClick &&
      this.state.activeSchoolFeatureProps !== null
    ) {
      /*
        changeFilterTrigger and repaintTrigger on <Layer /> doesn't seem to work for some reason when activeSchoolFeatureProps.uid changes
        Setting key: activeSchoolFeatureProps to null first to force render the new filter with new color
      */
      this.setState({ activeSchoolFeatureProps: null }, () => {
        setNewActiveSchoolFeatureProps();
      });
    } else {
      setNewActiveSchoolFeatureProps();
    }
    this.schoolDistrictShownViaClick = false;
  };

  /**
   * Hide school district when un-hovering school marker
   */
  handleSchoolIconMouseLeave = () => {
    if (!this.schoolDistrictShownViaClick) {
      this.setState({ activeSchoolFeatureProps: null });
    }
  };

  /**
   * Handles opening the school popup when pressing Enter on a school pin in a11y mode
   */
  handleSchoolPopupEscKeyPress = (): void => {
    this.setState({ activeSchoolFeatureProps: null }, () => {
      /* Return focus to school pin.  Need to query the DOM since the original `ref` reference to the marker
       * will have changed by now */
      const a11yMarker = document.querySelector(
        `[${SYMBOLS_LAYER_A11Y_MARKER_DATA_ATTR_NAME}='${this.previouslyFocusedSchoolMarkerId}']`
      );

      if (a11yMarker) {
        (a11yMarker as HTMLDivElement).focus();
      }
      this.previouslyFocusedSchoolMarkerId = null;
    });
  };

  getMapLayerClickType = (layerId: string) => {
    switch (layerId) {
      case LAYER_GROUP_LAYER_METRICS[LAYER_GROUP_KEYS.SCHOOLS][0]:
        return 'click_heatmap_school_elementary';
      case LAYER_GROUP_LAYER_METRICS[LAYER_GROUP_KEYS.SCHOOLS][1]:
        return 'click_heatmap_school_middle';
      case LAYER_GROUP_LAYER_METRICS[LAYER_GROUP_KEYS.SCHOOLS][2]:
        return 'click_heatmap_school_high';
      case LAYER_GROUP_LAYER_METRICS[LAYER_GROUP_KEYS.CRIME][0]:
        return 'click_heatmap_crime_all';
      case LAYER_GROUP_LAYER_METRICS[LAYER_GROUP_KEYS.CRIME][1]:
        return 'click_heatmap_crime_violent';
      case LAYER_GROUP_LAYER_METRICS[LAYER_GROUP_KEYS.CRIME][2]:
        return 'click_heatmap_crime_property';
      case LAYER_GROUP_LAYER_METRICS[LAYER_GROUP_KEYS.PRICE][0]:
        return 'click_heatmap_price_sqft';
      case LAYER_GROUP_LAYER_METRICS[LAYER_GROUP_KEYS.PRICE][1]:
        return 'click_heatmap_price_avg';
      case LAYER_GROUP_LAYER_METRICS[LAYER_GROUP_KEYS.FORECAST][0]:
        return 'click_heatmap_forecast';
      default:
        return '';
    }
  };
  /**
   * Set the active layer group. Only a single group may be active at once
   */
  setActiveGroup = ({
    groupId,
    layerId,
    toggledProgrammatically,
  }: {
    groupId: LayerGroup | null;
    layerId: LayerMetric | null;
    toggledProgrammatically: boolean;
  }) => {
    const { map, api } = this.context;
    const {
      autoHighlightSchoolDistrictName,
      isShowingMarkers,
      handleToggleMarkers,
    } = this.props;
    const { activeGroupId, isShowingMarkersInMapLayers } = this.state;

    /* If active group is changing */
    if (groupId !== activeGroupId) {
      this.setState({ activeGroupId: groupId, featureDescriptions: [] });
      this.setActiveLayer(
        groupId ? layerId || LAYER_GROUP_LAYER_METRICS[groupId][0] : null,
        groupId || null,
        toggledProgrammatically
      );

      /* When displaying schools layer, first load marker images after which layer will be rendered */
      if (groupId === LAYER_GROUP_KEYS.SCHOOLS && map) {
        this.loadSchoolMarkerImages();
        /* As soon as schools data finishes loading, display the school district closest to the subject
         * property, if provided */
        if (autoHighlightSchoolDistrictName) {
          map.on('idle', this.setAutoActiveSchoolDistrict);
        }
      } else {
        this.removeSchoolMarkerImages();
        loadHalftoneImages(map, () => {
          this.setState({ allHalftoneImagesLoaded: true });
        });
      }
      /* When deactivating heatmaps with markers toggled off, show markers */
      if (!groupId && !isShowingMarkers && handleToggleMarkers && api) {
        handleToggleMarkers(api, true);
      }

      /* persist show property status decided by the user */
      if (
        groupId &&
        isShowingMarkers !== isShowingMarkersInMapLayers &&
        handleToggleMarkers &&
        api
      ) {
        handleToggleMarkers(api, isShowingMarkersInMapLayers);
      }
    }
  };

  /**
   * Iterate features from schools layer, looking for the school id to show the boundary for
   * on layer activate
   */
  setAutoActiveSchoolDistrict = () => {
    const { map, api } = this.context;
    const {
      autoHighlightSchoolDistrictName,
      handleAutoActiveSchoolDistrictActivate,
    } = this.props;
    if (!map) {
      throw new Error(
        'Attempting to query source features before the map is available'
      );
    }
    const uniqueSourceFeatures = uniqBy(
      map.querySourceFeatures(SCHOOLS_SYMBOLS_SOURCE, {
        sourceLayer: SCHOOLS_SOURCE_LAYER_ID,
      }),
      (feature) => feature?.properties?.uid
    );
    map.off('idle', this.setAutoActiveSchoolDistrict);

    const schoolDistrictFeature = uniqueSourceFeatures.find(
      (feature) => feature?.properties?.name === autoHighlightSchoolDistrictName
    );

    if (schoolDistrictFeature && api) {
      this.setState({
        activeSchoolFeatureProps:
          schoolDistrictFeature.properties as SchoolFeatureProperties,
        shouldFitPopupInViewport: false,
      });

      if (handleAutoActiveSchoolDistrictActivate) {
        /* Execute outside functionality (fitting the school and the subject property in the map bounds) */
        handleAutoActiveSchoolDistrictActivate(api, schoolDistrictFeature);
      }

      /* This kinda sucks, but we don't want to fit the popup in the viewport during
       * this `flyTo` event, but we DO want to fit it during subsequent clicks on
       * school pins */
      this.resetShouldFitPopupInViewportTimeout = window.setTimeout(() => {
        this.setState({ shouldFitPopupInViewport: true });
      }, FLY_TO_AUTO_ACTIVE_SCHOOL_DURATION);
    }
  };

  /**
   * If no school markers are inside the map bounds, fit the closest one outside the bounds inside the map
   */
  ensureAtLeastOneSchoolMarkerInsideMap = (): void => {
    const { map, api } = this.context;
    const { activeLayerId } = this.state;

    /* This should only ever be executed when a layer is active */
    if (!activeLayerId) {
      return void 0;
    }
    if (!map) {
      throw new Error(
        'Attempting to query school source features before the map is available'
      );
    }

    const uniqueSourceFeatures = uniqBy(
      map.querySourceFeatures(SCHOOLS_SYMBOLS_SOURCE, {
        sourceLayer: SCHOOLS_SOURCE_LAYER_ID,
      }),
      (feature) => feature?.properties?.uid
    );
    const schoolFeatures = uniqueSourceFeatures.filter(
      (feature) =>
        SCHOOLS_LAYER_FILTER_DEF[activeLayerId] ===
          feature?.properties?.level &&
        feature?.properties?.kind === SCHOOLS_LAYER_SCHOOL_KIND
    );
    const mapCenterLatLng = map.getCenter();
    const mapBounds = {
      southWest: map.getBounds().getSouthWest(),
      northEast: map.getBounds().getNorthEast(),
    };

    /* Sometimes the map becomes 'idle' after adding the schools source to the map but before the
     * source is finished loading.  In this case, exit early and keep the 'idle' event binding in place.
     * The 'idle' event will trigger a moment later in this case once the schools are actually loaded. */
    if (schoolFeatures.length === 0) {
      return;
    } else {
      map.off('idle', this.ensureAtLeastOneSchoolMarkerInsideMap);
    }

    const isAnyMarkerInsideBounds = schoolFeatures.some(
      (feature) =>
        feature.properties &&
        getIsPointInsideBounds(mapBounds, [
          feature.properties.lon,
          feature.properties.lat,
        ])
    );
    /* Only take action if no school marker is visible */
    if (!isAnyMarkerInsideBounds) {
      const schoolFeaturesSortedByDistance = schoolFeatures
        .map((feature) => ({
          feature,
          distanceFromCenter:
            feature.properties &&
            getDistance(mapCenterLatLng, {
              lat: feature.properties.lat,
              lng: feature.properties.lon,
            }),
        }))
        .sort(
          (a, b) =>
            (a.distanceFromCenter || +Infinity) -
            (b.distanceFromCenter || +Infinity)
        );
      const closestMarkerFeature =
        schoolFeaturesSortedByDistance[0] &&
        schoolFeaturesSortedByDistance[0].feature;
      /* Fit marker in map */
      const boundsToFit =
        closestMarkerFeature.properties &&
        computeBoundsArrayFromLatLngPoints([
          [
            closestMarkerFeature.properties.lat,
            closestMarkerFeature.properties.lon,
          ],
          [mapBounds.southWest.lat, mapBounds.southWest.lng],
          [mapBounds.northEast.lat, mapBounds.northEast.lng],
        ]);

      const paddedBoundsToFit =
        boundsToFit &&
        padBoundsByPercentage(
          {
            southWest: { lat: boundsToFit[0][0], lng: boundsToFit[0][1] },
            northEast: { lat: boundsToFit[1][0], lng: boundsToFit[1][1] },
          },
          2.0
        );
      const formattedBoundsToFit =
        paddedBoundsToFit &&
        ([
          [paddedBoundsToFit.southWest.lat, paddedBoundsToFit.southWest.lng],
          [paddedBoundsToFit.northEast.lat, paddedBoundsToFit.northEast.lng],
        ] as [[number, number], [number, number]]);

      if (!map.isMoving() && formattedBoundsToFit && api) {
        api.setMapPositionToBounds(formattedBoundsToFit);
      }
    }
  };

  /**
   * Set the active layer within a group. Only a single layer may be active at once
   */
  setActiveLayer = (
    layerId: LayerMetric | null,
    groupId: LayerGroup | null,
    toggledProgrammatically: boolean
  ) => {
    const { map } = this.context;
    const { autoHighlightSchoolDistrictName } = this.props;

    if (!map) {
      throw new Error(
        'Attempting to set active layer before the map is available'
      );
    }

    this.setState({
      activeLayerId: layerId,
      activeSchoolFeatureProps: null,
      currentZoom: map.getZoom(),
    });

    /* Only execute this functionality if we're NOT auto-highlighting the closest school district */
    if (
      groupId === LAYER_GROUP_KEYS.SCHOOLS &&
      !autoHighlightSchoolDistrictName
    ) {
      map.on('idle', this.ensureAtLeastOneSchoolMarkerInsideMap);
    }

    if (!toggledProgrammatically) {
      this.props.onActiveLayerChange(layerId);
    }
  };

  /**
   * Generate a configuration accepted by the Mapbox GL API to assign image ids
   * to school symbols depending on the school's rank
   * @example
   *  [
   *    ['any', ['>=', ['get', 'rank_unrounded'], 0], ['<', ['get', 'rank_unrounded'], 20]],
   *    'school-icon-0',
   *    ['any', ['>=', ['get', 'rank_unrounded'], 20], ['<', ['get', 'rank_unrounded'], 40]],
   *    'school-icon-1'
   *  ]
   */
  getSchoolsLayerIconImageCaseDefinition = (): (Expression | string)[] => {
    let stepDef: (Expression | string)[] = [];
    const existingInternals = SCHOOLS_LEGEND_INTERVALS;
    const additionalInterval: [number, number, null, null, string] = [
      101,
      +Infinity,
      null,
      null,
      SCHOOL_MARKER_IMAGE_IDS.SCHOOL_MARKER_IMAGE_UNRANKED,
    ];

    [...existingInternals]
      .concat([additionalInterval])
      .forEach(([lowEnd, highEnd, color, rangeDisplay, mapboxImageID]) => {
        stepDef.push([
          'all',
          ['>', ['number', ['get', 'rank_unrounded'], Infinity], lowEnd],
          ['<=', ['number', ['get', 'rank_unrounded'], Infinity], highEnd],
        ]);
        if (mapboxImageID) {
          stepDef.push(mapboxImageID);
        }
      });
    return stepDef;
  };

  /**
   * Reformat the legend breaks passed via props to prepare for display in the UI
   */
  getLegendColorTableForLayer = (
    layerId: LayerMetric,
    groupId: LayerGroup
  ): LegendColorTable | null => {
    const { isCurrencyBased, isPercentBased, valueSuffix } =
      LAYER_GROUP_LEGEND_CONFIG[groupId];
    const intervals = this.getIntervalsForLayer(layerId);

    if (!intervals) {
      return null;
    }

    const colorTable = getColorTableFromIntervals({
      intervals,
      isCurrencyBased,
      isPercentBased,
      valueSuffix,
    });

    /* Cache this data so that we can display a legend even though no data exists for an area
     * in some cases (an error message is shown in this case) */
    if (colorTable) {
      let i = 0;
      for (let row of colorTable) {
        row.patternImageUrl = HALFTONE_IMAGES[i].url;
        i++;
      }
      this.cachedLegendColorTable = colorTable;
    }
    return colorTable;
  };

  /**
   * Get either hardcoded or API generated legend breaks for the active layer
   */
  getIntervalsForLayer = (
    layerId: LayerMetric
  ): LegendInterval[] | undefined => {
    const { map } = this.context;
    const { legendBreaks } = this.props;

    if (!map) {
      throw new Error(
        'Attempting to get intervals for layer before the map is available'
      );
    }

    return LAYER_GROUP_LAYER_METRICS[LAYER_GROUP_KEYS.SCHOOLS].indexOf(
      layerId
    ) > -1 && map.getZoom() > SCHOOLS_MIN_ZOOM
      ? SCHOOLS_LEGEND_INTERVALS
      : legendBreaks[layerId];
  };

  /**
   * Execute a callback when the 'Show Properties' toggle is changed
   */
  handleShowMarkersToggleChange = () => {
    const { api } = this.context;
    const { handleToggleMarkers, isShowingMarkers } = this.props;

    if (!api) {
      throw new Error(
        'Attempting to toggle markers before the api is available'
      );
    }

    if (handleToggleMarkers) {
      handleToggleMarkers(api, !isShowingMarkers);
    }
    this.setState((state) => ({
      isShowingMarkersInMapLayers: !state.isShowingMarkersInMapLayers,
    }));
  };

  handleMonochromeToggleChange = (isEnabled: boolean) => {
    this.setState({ hasMonochromeOverlay: isEnabled });
  };

  /**
   * Load all images needed for school symbols, then activate the schools symbol layer
   */
  loadSchoolMarkerImages = () => {
    const { map } = this.context;
    let imagesLoadedCount = 0;
    const imageIds = Object.keys(SCHOOL_MARKER_IMAGE_URL_BY_ID);

    if (!map) {
      throw new Error(
        'Attempting to load school marker images into the map before the map is available'
      );
    }

    /* Load the icon image */
    imageIds.forEach((imageId) => {
      map.loadImage(SCHOOL_MARKER_IMAGE_URL_BY_ID[imageId], (error, image) => {
        if (error) {
          throw error;
        }
        /* Map might be unmounted by the time the image loads */
        if (map && image) {
          /* Add the icon image to the map */
          map.addImage(imageId, image);
          imagesLoadedCount++;

          if (imagesLoadedCount === imageIds.length) {
            this.setState({ allSchoolIconImagesLoaded: true });
          }
        }
      });
    });
  };

  /* Remove school marker icon images */
  removeSchoolMarkerImages = () => {
    const { map } = this.context;

    Object.keys(SCHOOL_MARKER_IMAGE_URL_BY_ID).forEach((imageId) => {
      if (map && map.hasImage(imageId)) {
        map.removeImage(imageId);
      }
    });
  };

  getLayerGroupTooltipContent = ({
    activeGroupId,
    legendColorTable,
    hasMonochromeOverlay,
    theme,
  }: {
    activeGroupId: LayerGroup;
    legendColorTable: any;
    hasMonochromeOverlay: boolean;
    theme: Theme;
  }) => {
    const { layerGroupLabelsWithDataAttribution } = this.props;

    return (
      <ul className={theme.LegendList}>
        {layerGroupLabelsWithDataAttribution[activeGroupId].tooltipText && (
          <div className={theme.TooltipText}>
            {layerGroupLabelsWithDataAttribution[activeGroupId].tooltipText}
          </div>
        )}
        {legendColorTable.map((interval, i) => (
          <li key={interval.color} className={theme.TooltipDetailsContainer}>
            <div
              className={
                theme.TooltipColorCodes +
                (hasMonochromeOverlay
                  ? ` ${theme.TooltipColorCodesHalftone}`
                  : '')
              }
              style={
                hasMonochromeOverlay
                  ? { background: `url('${interval.patternImageUrl}')` }
                  : { backgroundColor: interval.color }
              }
            />
            <div className={theme.TooltipLabel}>
              <span>
                {interval.label[0]} to {interval.label[1]}
              </span>
              <span className={theme.TooltipIntervalExplanation}>
                {i === legendColorTable.length - 1 &&
                  layerGroupLabelsWithDataAttribution[activeGroupId]
                    .tooltipLegendHighestExplanation &&
                  legendColorTable.length >= 2 &&
                  `(${layerGroupLabelsWithDataAttribution[activeGroupId].tooltipLegendHighestExplanation})`}
                {i === 0 &&
                  layerGroupLabelsWithDataAttribution[activeGroupId]
                    .tooltipLegendLowestExplanation &&
                  legendColorTable.length >= 2 &&
                  `(${layerGroupLabelsWithDataAttribution[activeGroupId].tooltipLegendLowestExplanation})`}
              </span>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  handleDeactivateLayer = (groupId: LayerGroup) => {
    this.setActiveGroup({
      groupId: null,
      layerId: null,
      toggledProgrammatically: false,
    });
    const focusEle = document.getElementById(
      `LayerGroupControlItem_${groupId}`
    );
    if (focusEle) {
      focusEle.focus();
    }
  };

  /**
   * Returns a React component for a notification to be displayed within the map
   */
  getMapNotification = ({
    key,
    Notification,
    theme,
  }: {
    key: string;
    Notification: JSX.Element | string;
    theme: Theme;
  }) => {
    const { activeGroupId, activeLayerId } = this.state;
    const { allowShowingBottomControl, isMapNotificationAboveYourTeamButton } =
      this.props;

    return (
      <motion.div
        key={key}
        initial={{ y: 130, opacity: 0 }}
        animate={{
          y: 0,
          opacity: 1,
          transition: {
            delay: 0.4,
          },
        }}
        className={classNames(theme.MapNotification, {
          [theme.MapNotificationPositionedHigher]:
            allowShowingBottomControl && activeGroupId && activeLayerId,
          [theme.MapNotificationYourTeam]: isMapNotificationAboveYourTeamButton,
        })}
      >
        {Notification}
      </motion.div>
    );
  };

  fetchLegendBreaks = (groupId: LayerGroup) => {
    const { map } = this.context;
    const { handleGetMapLayerLegendBreaks } = this.props;

    if (map) {
      handleGetMapLayerLegendBreaks(
        map.getBounds(),
        map.getZoom(),
        LAYER_GROUP_LAYER_METRICS[groupId][0]
      );
    }
  };

  handleLayersGroupClick = (groupId: LayerGroup) => {
    this.setActiveGroup({
      groupId,
      layerId: null,
      toggledProgrammatically: false,
    });
    this.fetchLegendBreaks(groupId);
  };

  handleMobileLayerListSelect = (layerId: LayerMetric, groupId: LayerGroup) => {
    const { handleCloseBottomGroupsControl } = this.props;

    if (handleCloseBottomGroupsControl) {
      handleCloseBottomGroupsControl();
    }
    this.setActiveGroup({ groupId, layerId, toggledProgrammatically: false });
  };

  /* Focus the first filter item after component mounts for a11y compliance*/
  focusFirstLayerLabelWithinGroup = (ele: HTMLDivElement | null) => {
    if (ele !== null) {
      const filterItemNodeList = ele.querySelectorAll(`[tabindex="0"]`);
      if (filterItemNodeList && filterItemNodeList[0]) {
        (filterItemNodeList[0] as HTMLElement).focus();
      }
    }
  };

  addLayerGroupControlAsFirstChild(element: JSX.Element) {
    const mapElement = document.getElementById(this.props.mapAriaId) as Element;
    const foundElement = document.getElementById('LayerGroupsControl');
    // Check if the element is already in the mapElement
    if (foundElement && foundElement !== mapElement.firstChild) {
      mapElement.insertBefore(foundElement, mapElement.firstChild);
    }
    return createPortal(element, mapElement);
  }

  render() {
    const {
      beneathLayerId,
      fitPopupPadding,
      MapNotification,
      useFloatingLayerGroupsControl,
      isShowingBottomLayerGroupsControl,
      allowShowingBottomControl,
      handleCloseBottomGroupsControl,
      isShowingMarkers,
      handleToggleMarkers,
      legendBreaks,
      theme,
      mapAriaId,
      layerGroupLabelsWithDataAttribution,
      reportEvent,
    } = this.props;
    const {
      activeGroupId,
      activeLayerId,
      repaintTrigger,
      activeSchoolFeatureProps,
      allSchoolIconImagesLoaded,
      allHalftoneImagesLoaded,
      shouldFitPopupInViewport,
      currentZoom,
      hasMonochromeOverlay,
      featureDescriptions,
      isSchoolsLayerAccessibilityEnabled,
    } = this.state;
    const legendColorTable =
      activeGroupId &&
      activeLayerId &&
      this.getLegendColorTableForLayer(activeLayerId, activeGroupId);
    const schoolPopupPosition =
      activeSchoolFeatureProps &&
      ([activeSchoolFeatureProps.lat, activeSchoolFeatureProps.lon] as [
        number,
        number,
      ]);
    const minZoomForLayerId =
      activeLayerId &&
      LAYER_SOURCE_ZOOM_MAPPING[activeLayerId] &&
      values(LAYER_SOURCE_ZOOM_MAPPING[activeLayerId])
        .map((zoomLevelsByEndpoint) => zoomLevelsByEndpoint[0])
        .sort((a, b) => a - b)[0];
    const showToggleMonochromeMode = activeGroupId !== LAYER_GROUP_KEYS.SCHOOLS;
    const activeLayerLegendBreaks =
      activeLayerId && legendBreaks[activeLayerId];
    const featureSummary = activeLayerId
      ? activeGroupId === LAYER_GROUP_KEYS.SCHOOLS && activeSchoolFeatureProps
        ? `${
            activeSchoolFeatureProps.name
          } is selected.  Its rank is ${Math.floor(
            activeSchoolFeatureProps.rank_unrounded
          )} out of 100.`
        : activeGroupId &&
            legendBreaks &&
            activeLayerLegendBreaks &&
            activeLayerLegendBreaks.length
          ? `${LAYER_LABELS[activeLayerId]} ${
              layerGroupLabelsWithDataAttribution[activeGroupId].longLabel ||
              layerGroupLabelsWithDataAttribution[activeGroupId].label
            } in the map area ranges from ${layerGroupLabelsWithDataAttribution[
              activeGroupId
            ].valueFormatter(
              activeLayerLegendBreaks[0][0]
            )} to ${layerGroupLabelsWithDataAttribution[
              activeGroupId
            ].valueFormatter(
              activeLayerLegendBreaks[activeLayerLegendBreaks.length - 1][1]
            )}.`
          : activeGroupId === LAYER_GROUP_KEYS.SCHOOLS
            ? 'Please tab to each school to hear more information'
            : 'We don’t have heatmap data for this area.'
      : null;

    return (
      <CobrandedStyles>
        {({ activeOptionUnderlineColor }) => (
          <AccessibleElementUniqueId>
            {({ uid }) => (
              <div className={theme.MapLayersControl}>
                {
                  /* A 'floating' group list shown in the top-left map area, typically used for larger, desktop views */
                  (
                    typeof useFloatingLayerGroupsControl === 'undefined'
                      ? true
                      : useFloatingLayerGroupsControl
                  ) ? (
                    <>
                      {this.addLayerGroupControlAsFirstChild(
                        <FloatingLayerGroupsControl
                          theme={theme}
                          handleLayersGroupClick={this.handleLayersGroupClick}
                          effectiveLayerControlGroups={
                            this.effectiveLayerControlGroups
                          }
                          activeGroupId={activeGroupId}
                          mapAriaId={mapAriaId}
                          layerGroupLabelsWithDataAttribution={
                            layerGroupLabelsWithDataAttribution
                          }
                          activeOptionUnderlineColor={
                            activeOptionUnderlineColor
                          }
                        />
                      )}
                    </>
                  ) : (
                    /* A vertical, animated group list shown, when activated, in the bottom map area typically on mobile views */
                    <AnimatePresence>
                      {isShowingBottomLayerGroupsControl && (
                        <motion.div
                          key="bottom-groups-control"
                          initial={{ y: bottomGroupsControlHeight }}
                          exit={{ y: bottomGroupsControlHeight }}
                          animate={{
                            y: 0,
                            transition: {
                              default: {
                                duration: 0.15,
                                easing: 'easeIn',
                              },
                            },
                          }}
                          className={theme.BottomGroupsControl}
                        >
                          <button
                            type="button"
                            aria-label="Close heatmaps "
                            className={theme.BottomGroupsControlCloseIcon}
                            onClick={handleCloseBottomGroupsControl}
                            onKeyDown={
                              handleCloseBottomGroupsControl
                                ? onEnterOrSpaceKey(
                                    handleCloseBottomGroupsControl
                                  )
                                : undefined
                            }
                            data-event-name="close_heatmap_control"
                          >
                            <CloseIcon />
                          </button>
                          {this.effectiveLayerControlGroups.map((groupId) => (
                            <LockedComponent
                              sectionId={groupId}
                              lockedFor={[SpecialUserType.Restricted]}
                              theme={theme}
                              className={theme.LockedComponentContainer}
                            >
                              {({ isLocked }) => (
                                <div
                                  key={groupId}
                                  className={theme.BottomGroupsControlRow}
                                >
                                  <div
                                    className={classNames(
                                      theme.BottomGroupsControlRowInner,
                                      {
                                        [theme.BottomGroupsControlRowInnerLocked]:
                                          isLocked,
                                      }
                                    )}
                                  >
                                    <AccessibleElementUniqueId>
                                      {({ uid: layerControlRowUid }) => (
                                        <>
                                          <label
                                            className={theme.ControlGroupLabel}
                                            id={layerControlRowUid}
                                            onClick={() =>
                                              !isLocked &&
                                              this.handleMobileLayerListSelect(
                                                LAYER_GROUP_LAYER_METRICS[
                                                  groupId
                                                ][0],
                                                groupId
                                              )
                                            }
                                          >
                                            {
                                              layerGroupLabelsWithDataAttribution[
                                                groupId
                                              ].label
                                            }
                                          </label>
                                          <div
                                            className={theme.LayerControlRow}
                                          >
                                            <HorizontalSelectorButtons
                                              data-event-name={`click_heatmap_${layerGroupLabelsWithDataAttribution[
                                                groupId
                                              ].label.toLowerCase()}`}
                                              theme={theme}
                                              options={(
                                                LAYER_GROUP_LAYER_METRICS[
                                                  groupId
                                                ] || []
                                              ).map((layerId) => ({
                                                label: LAYER_LABELS[layerId],
                                                value: layerId,
                                              }))}
                                              value={activeLayerId}
                                              onSelect={(layerId) =>
                                                this.handleMobileLayerListSelect(
                                                  layerId as LayerMetric,
                                                  groupId
                                                )
                                              }
                                              ariaLabelledBy={
                                                layerControlRowUid
                                              }
                                              id={uid}
                                              ariaControls={mapAriaId}
                                            />
                                          </div>
                                        </>
                                      )}
                                    </AccessibleElementUniqueId>
                                  </div>
                                </div>
                              )}
                            </LockedComponent>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  )
                }
                {
                  /* Generic passed-in notifications, i.e. 'Please zoom in to view properties' */
                  allowShowingBottomControl &&
                    MapNotification &&
                    this.getMapNotification({
                      key: 'MapNotificationGeneric',
                      Notification: MapNotification,
                      theme: theme,
                    })
                }
                {
                  /* Error messaging telling the user to zoom in to view the active heatmap layer */
                  allowShowingBottomControl &&
                    !MapNotification &&
                    activeGroupId &&
                    activeLayerId &&
                    !legendColorTable &&
                    currentZoom &&
                    minZoomForLayerId &&
                    currentZoom < minZoomForLayerId &&
                    this.getMapNotification({
                      key: 'MapNotificationLayerZoomedOut',
                      Notification: (
                        <SearchMapNotification>
                          {
                            TOO_FAR_ZOOMED_OUT_ERROR_MESSAGE_FOR_LAYER_GROUP[
                              activeGroupId
                            ]
                          }
                        </SearchMapNotification>
                      ),
                      theme: theme,
                    })
                }
                {
                  /* Error messaging telling the user that no heatmap data exists in the current area */
                  allowShowingBottomControl &&
                    !MapNotification &&
                    activeGroupId &&
                    activeLayerId &&
                    !legendColorTable &&
                    currentZoom &&
                    minZoomForLayerId &&
                    currentZoom >= minZoomForLayerId &&
                    this.getMapNotification({
                      key: 'MapNotificationLayerNoData',
                      Notification: (
                        <SearchMapNotification>
                          {NO_DATA_FOR_LAYER_MESSAGE}
                        </SearchMapNotification>
                      ),
                      theme: theme,
                    })
                }
                {allowShowingBottomControl &&
                  activeGroupId &&
                  activeLayerId && (
                    <motion.div
                      key={'LayerItemsControlInner'}
                      initial={{ y: 20, opacity: 0 }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        transition: {
                          default: {
                            duration: 0.3,
                          },
                        },
                      }}
                      className={classNames(theme.LayerItemsControl, {
                        [theme.LayerItemsControlWithMarkersToggle]:
                          handleToggleMarkers,
                      })}
                      ref={this.focusFirstLayerLabelWithinGroup}
                    >
                      <div className={theme.LayerItemsControlInner}>
                        <div className={theme.LayerItemsControlMainColumn}>
                          <div className={theme.LayerItemsControlRow}>
                            <div className={theme.BottomGroupsControlRowInner}>
                              <AccessibleElementUniqueId>
                                {({ uid: layerControlRowUid }) => (
                                  <>
                                    <label
                                      className={theme.ControlGroupLabel}
                                      id={layerControlRowUid}
                                    >
                                      {
                                        layerGroupLabelsWithDataAttribution[
                                          activeGroupId
                                        ].label
                                      }
                                    </label>
                                    <div className={theme.LayerControlRow}>
                                      <HorizontalSelectorTabs
                                        getDataEventName={
                                          this.getMapLayerClickType
                                        }
                                        theme={theme}
                                        options={LAYER_GROUP_LAYER_METRICS[
                                          activeGroupId
                                        ].map((layerId) => ({
                                          label: LAYER_LABELS[layerId],
                                          value: layerId,
                                        }))}
                                        value={activeLayerId}
                                        onSelect={(layerId) => {
                                          if (layerId) {
                                            this.setActiveLayer(
                                              layerId as LayerMetric,
                                              activeGroupId,
                                              false
                                            );
                                          }
                                        }}
                                        ariaLabelledBy={layerControlRowUid}
                                        id={uid}
                                        ariaControls={mapAriaId}
                                      />
                                    </div>
                                  </>
                                )}
                              </AccessibleElementUniqueId>
                            </div>
                            {handleToggleMarkers && (
                              <AccessibleElementUniqueId>
                                {({ uid: switchUid }) => (
                                  <motion.div
                                    {...SWITCH_ANIMATION_VARIANTS}
                                    className={
                                      theme.LayerItemsControlMarkerToggleColumn
                                    }
                                    key="LayerItemsControlMarkerToggleColumn"
                                  >
                                    <label
                                      htmlFor={switchUid}
                                      id={`show-properties-${switchUid}`}
                                    >
                                      Show Properties
                                    </label>
                                    <div
                                      className={theme.HorizontalToggleWrapper}
                                    >
                                      <BinaryHorizontalToggle
                                        data-event-name={`click_show_properties_${
                                          isShowingMarkers ? 'off' : 'on'
                                        }`}
                                        dataHcName="toggle-map-markers"
                                        ariaLabel={'Map Markers'}
                                        ariaLabelledBy={`show-properties-${switchUid}`}
                                        handleSelect={
                                          this.handleShowMarkersToggleChange
                                        }
                                        selectedValue={!!isShowingMarkers}
                                        id={switchUid}
                                      />
                                    </div>
                                  </motion.div>
                                )}
                              </AccessibleElementUniqueId>
                            )}
                            {showToggleMonochromeMode && (
                              <AccessibleElementUniqueId>
                                {({ uid: switchUid }) => {
                                  const MONOCHROME_LABEL_ID = `label-${switchUid}`;
                                  return (
                                    <motion.div
                                      className={classNames(
                                        theme.LayerItemsControlMarkerToggleColumn,
                                        theme.LayerItemsControlMarkerToggleColumnMonochrome
                                      )}
                                      key="hasMonochromeOverlayControlMarkerToggleColumn"
                                      {...SWITCH_ANIMATION_VARIANTS}
                                    >
                                      <div
                                        className={theme.MonochromeLabelWrapper}
                                      >
                                        <label
                                          htmlFor={switchUid}
                                          id={MONOCHROME_LABEL_ID}
                                        >
                                          Monochrome
                                        </label>
                                        <Tooltip
                                          dataHcName="monochrome-heatmap-layers-info"
                                          theme={theme}
                                          hasTransparentScreen
                                          position="top"
                                          maxWidth={300}
                                          triggerAriaDescribedBy={
                                            MONOCHROME_LABEL_ID
                                          }
                                          content={
                                            'Monochrome heatmap layers can be used as an alternative to the standard color-based heatmaps to aid users with difficulties distinguishing colors.'
                                          }
                                          afterDisplay={() =>
                                            reportEvent(
                                              'show_monochrome_toggle_info',
                                              ''
                                            )
                                          }
                                          afterHide={() =>
                                            reportEvent(
                                              'hide_monochrome_toggle_info',
                                              ''
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className={
                                          theme.HorizontalToggleWrapper
                                        }
                                      >
                                        <BinaryHorizontalToggle
                                          data-event-name="click_monochrome_map_layer_toggle"
                                          dataHcName="toggle-map-layer-monochrome"
                                          ariaLabel={
                                            'Monochrome Heatmap Layers'
                                          }
                                          ariaLabelledBy={`monochrome-${switchUid}`}
                                          handleSelect={
                                            this.handleMonochromeToggleChange
                                          }
                                          selectedValue={hasMonochromeOverlay}
                                          id={switchUid}
                                        />
                                      </div>
                                    </motion.div>
                                  );
                                }}
                              </AccessibleElementUniqueId>
                            )}
                          </div>

                          <motion.div
                            key="legendFade"
                            {...QUICK_FADE_ANIMATION_VARIANTS}
                            className={theme.LayerItemsControlRow}
                          >
                            <div className={theme.LegendWrapper}>
                              <motion.div
                                key="label"
                                initial={{ opacity: 0, x: -20 }}
                                animate={{
                                  opacity: 1,
                                  x: 0,
                                  transition: {
                                    default: {
                                      delay: 0.36,
                                      duration: 0.14,
                                      easing: 'easeOut',
                                    },
                                    x: {
                                      delay: 0.36,
                                      duration: 0.2,
                                    },
                                  },
                                }}
                                exit={{
                                  opacity: 0,
                                  x: -20,
                                  transition: {
                                    x: {
                                      delay: 0.36,
                                      duration: 0.2,
                                    },
                                  },
                                }}
                                className={theme.LayerItemsControlLabel}
                                aria-describedby="activemaplayerdescription"
                              >
                                {layerGroupLabelsWithDataAttribution[
                                  activeGroupId
                                ].longLabel ||
                                  layerGroupLabelsWithDataAttribution[
                                    activeGroupId
                                  ].label}
                                {legendColorTable && (
                                  <Tooltip
                                    dataHcName="heatmap-legend-info"
                                    shouldHideCloseButton
                                    theme={theme}
                                    hasTransparentScreen
                                    maxWidth={300}
                                    position="top"
                                    triggerAriaLabel={`Show ${activeGroupId} info tooltip`}
                                    content={this.getLayerGroupTooltipContent({
                                      activeGroupId,
                                      legendColorTable,
                                      hasMonochromeOverlay:
                                        activeGroupId ===
                                        LAYER_GROUP_KEYS.SCHOOLS
                                          ? false
                                          : hasMonochromeOverlay,
                                      theme,
                                    })}
                                  />
                                )}
                              </motion.div>
                              <MapLegend
                                theme={theme}
                                colorTable={this.cachedLegendColorTable}
                                colors={
                                  (
                                    LAYERS_TO_USE_GRADIENTS as LayerMetric[]
                                  ).indexOf(activeLayerId) > -1
                                    ? getLegendBreakGradients(
                                        legendBreaks,
                                        activeLayerId,
                                        hasMonochromeOverlay
                                      ) || undefined
                                    : legendColorTable
                                      ? legendColorTable.map(
                                          (item) => item.color
                                        )
                                      : undefined
                                }
                                halftone={hasMonochromeOverlay}
                              />
                            </div>
                            <div className={theme.NoDataLegend}>
                              No Data <i className={theme.GrayColorTile} />
                            </div>
                          </motion.div>
                          <div
                            className={classNames(
                              theme.LayerItemsControlRow,
                              theme.DataAttribution
                            )}
                          >
                            {
                              layerGroupLabelsWithDataAttribution[activeGroupId]
                                .dataAttribution
                            }
                          </div>
                        </div>
                        <motion.div
                          {...QUICK_FADE_ANIMATION_VARIANTS}
                          key="closeIcon"
                        >
                          <button
                            type="button"
                            aria-label={`Close ${layerGroupLabelsWithDataAttribution[activeGroupId].label} maps`}
                            className={theme.LayerItemsControlCloseButton}
                            onClick={() => {
                              this.handleDeactivateLayer(activeGroupId);
                            }}
                            onKeyDown={onEnterOrSpaceKey((e) => {
                              this.handleDeactivateLayer(activeGroupId);
                            })}
                            data-event-name={`close_${layerGroupLabelsWithDataAttribution[
                              activeGroupId
                            ].label.toLowerCase()}_heatmap`}
                          >
                            <CloseIcon />
                          </button>
                        </motion.div>
                      </div>
                    </motion.div>
                  )}
                {
                  /* Blocks layers for all heatmaps */
                  activeLayerId &&
                    LAYER_PRESET_TYPES_FOR_METRIC[activeLayerId] ===
                      LAYER_PRESET_TYPES.BLOCKS &&
                    (!hasMonochromeOverlay || allHalftoneImagesLoaded) && (
                      <BlocksLayer
                        /* HACK(mikep): BlocksLayer can't handle changing of the keys specified in the paint object,
                        so force a rerender based on hasMonochromeOverlay which changes the fill properties */
                        key={`mo-${hasMonochromeOverlay}`}
                        sourceZoomMapping={
                          LAYER_SOURCE_ZOOM_MAPPING[activeLayerId]
                        }
                        urlBase={GEOTILE_DATA_URL}
                        repaintTrigger={repaintTrigger}
                        metric={activeLayerId}
                        filter={BLOCK_FEATURES_FILTER}
                        paint={{
                          'fill-opacity': 0.4,
                          ...getBlocksLayerFillDefinition(
                            legendBreaks,
                            activeLayerId,
                            hasMonochromeOverlay
                          ),
                        }}
                        beneathLayerId={beneathLayerId}
                      />
                    )
                }
                {
                  /* Schools symbol layer */
                  activeLayerId &&
                    LAYER_PRESET_TYPES_FOR_METRIC[activeLayerId] ===
                      LAYER_PRESET_TYPES.SCHOOLS &&
                    allSchoolIconImagesLoaded && (
                      <>
                        <SymbolsLayer
                          layerId={MAPBOX_LAYER_IDS.SCHOOL_SYMBOLS}
                          sourceLayerId={SCHOOLS_SOURCE_LAYER_ID}
                          minZoom={SCHOOLS_MIN_ZOOM}
                          layout={{
                            'icon-image': [
                              'case',
                              ...this.getSchoolsLayerIconImageCaseDefinition(),
                              /* Fallback image id */
                              SCHOOL_MARKER_IMAGE_IDS.SCHOOL_MARKER_IMAGE_0,
                            ] as Expression,
                            'icon-ignore-placement': true,
                            'icon-anchor': 'bottom',
                            'icon-size': 0.55,
                          }}
                          getLatLng={(feature) =>
                            feature.properties
                              ? {
                                  lat: feature.properties.lat,
                                  lng: feature.properties.lon,
                                }
                              : null
                          }
                          uniqBy={(feature) =>
                            feature.properties
                              ? `${feature.properties.uid}-${feature.properties.level}`
                              : null
                          }
                          featureFilter={(feature) =>
                            !!feature.properties &&
                            SCHOOLS_LAYER_FILTER_DEF[activeLayerId] ===
                              feature.properties.level &&
                            feature.properties.kind ===
                              SCHOOLS_LAYER_SCHOOL_KIND
                          }
                          changeFilterTrigger={activeLayerId}
                          showPopupOnSymbolHover
                          Source={
                            <LayerSource
                              sourceId={SCHOOLS_SYMBOLS_SOURCE}
                              type="vector"
                              url={`${GEOTILE_DATA_URL}/${SCHOOLS_SOURCE_LAYER_ID}/{z}/{x}/{y}.mvt`}
                              minZoom={9}
                            />
                          }
                          /* Toggle the display of another layer showing the school's district upon clicking the icon */
                          onSymbolClick={this.handleSchoolIconClick}
                          onSymbolMouseEnter={this.handleSchoolIconMouseEnter}
                          onSymbolMouseLeave={this.handleSchoolIconMouseLeave}
                        />
                        {isSchoolsLayerAccessibilityEnabled && (
                          <SymbolsLayerAccessibilityControl
                            layerIds={[MAPBOX_LAYER_IDS.SCHOOL_SYMBOLS]}
                            indicatorSize={{
                              width: 42,
                              height: 60,
                            }}
                            indicatorTranslateRelativeToAnchor={{
                              x: '-50%',
                              y: '-91%',
                            }}
                            uniqBy={(feature) =>
                              feature.properties
                                ? `${feature.properties.uid}-${feature.properties.level}`
                                : 0
                            }
                            getLatLng={(feature) =>
                              feature.properties && {
                                lat: feature.properties.lat,
                                lng: feature.properties.lon,
                              }
                            }
                            featureFilter={(feature) =>
                              !!feature.properties &&
                              SCHOOLS_LAYER_FILTER_DEF[activeLayerId] ===
                                feature.properties.level &&
                              feature.properties.kind ===
                                SCHOOLS_LAYER_SCHOOL_KIND
                            }
                            getAccessibilityLabel={(feature) =>
                              feature.properties?.name
                                ? typeof feature.properties?.rank_unrounded ===
                                  'number'
                                  ? `${
                                      feature.properties.name
                                    }. This school has a ranking of ${Math.round(
                                      feature.properties?.rank_unrounded
                                    )} percentile`
                                  : `${feature.properties.name}. This school\'s ranking is unknown`
                                : ''
                            }
                            getDataAttr={(feature) => [
                              SYMBOLS_LAYER_A11Y_MARKER_DATA_ATTR_NAME,
                              feature.properties
                                ? feature.properties[
                                    SYMBOLS_LAYER_A11Y_MARKER_DATA_KEY
                                  ]
                                : '',
                            ]}
                            onEnterOrSpaceKeypress={
                              this.handleSchoolIconEnterOrSpaceKey
                            }
                          />
                        )}
                      </>
                    )
                }
                {
                  /* A fill layer with a filter, only coloring the intended school district */
                  activeSchoolFeatureProps && (
                    <Layer
                      layerId={MAPBOX_LAYER_IDS.SCHOOL_DISTRICT_BOUNDARY}
                      type="fill"
                      sourceId={SCHOOLS_SYMBOLS_SOURCE}
                      sourceLayerId={SCHOOLS_SOURCE_LAYER_ID}
                      filter={[
                        '==',
                        ['get', 'uid'],
                        activeSchoolFeatureProps.uid,
                      ]}
                      changeFilterTrigger={activeSchoolFeatureProps.uid}
                      paint={{
                        'fill-opacity': 0,
                        'fill-opacity-transition': { duration: 300 },
                        'fill-color': getSchoolDistrictFeatureColor(
                          activeSchoolFeatureProps.rank_unrounded
                        ),
                      }}
                      transitionPaint={{
                        'fill-opacity': 0.5,
                      }}
                      beneathLayerId={MAPBOX_LAYER_IDS.SCHOOL_SYMBOLS}
                    />
                  )
                }
                {schoolPopupPosition && (
                  <Popup
                    key={
                      activeSchoolFeatureProps
                        ? activeSchoolFeatureProps.uid
                        : Math.random()
                    }
                    Content={
                      <SchoolMarkerPopup
                        theme={theme}
                        featureProperties={activeSchoolFeatureProps}
                      />
                    }
                    fitPopupInMapViewport={shouldFitPopupInViewport}
                    position={schoolPopupPosition}
                    mapboxOptions={{
                      className: theme.SchoolsPopupMapboxContainer,
                      closeButton: false,
                      offset: {
                        /* Account for the icon's height */
                        bottom: [0, MARKER_POPUP_Y_OFFSET],
                      },
                    }}
                    fitPopupPadding={
                      fitPopupPadding || FIT_POPUP_PADDING_WITH_DESKTOP_CONTROLS
                    }
                    afterMount={(popup: MapboxPopup) => {
                      if (isSchoolsLayerAccessibilityEnabled) {
                        /* Focus the popup and set the ESC key to close the popup */
                        const popupEle = (
                          popup as any
                        ).getElement() as HTMLDivElement;
                        popupEle.setAttribute('tabIndex', '-1');
                        /* This should be garbage-collected when the element is removed from the DOM */
                        popupEle.addEventListener('keydown', (e) => {
                          if (key.isEscape(e.key)) {
                            this.handleSchoolPopupEscKeyPress();
                          }
                        });
                        this.schoolMarkerA11yFocusTimeout = window.setTimeout(
                          () => {
                            popupEle.focus();
                          },
                          100
                        );
                      }
                    }}
                  />
                )}
                {activeGroupId && activeLayerId && featureSummary && (
                  <MapLayersScreenreaderAlert
                    layerLabel={
                      layerGroupLabelsWithDataAttribution[activeGroupId]
                        ? `${LAYER_LABELS[activeLayerId]} ${layerGroupLabelsWithDataAttribution[activeGroupId].label}`
                        : null
                    }
                    featureDescriptions={featureDescriptions}
                    featureSummary={featureSummary}
                  />
                )}
              </div>
            )}
          </AccessibleElementUniqueId>
        )}
      </CobrandedStyles>
    );
  }
}

const ThemedMapLayersControl = themr(
  'MapLayersControl',
  defaultTheme
)(MapLayersControl);
export default ThemedMapLayersControl;
