import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import ButtonMenuButton from './ButtonMenuButton';
import ButtonMenuButtonChase from './ButtonMenuButtonChase';
import { ButtonMenuButtonProps } from '@client/store/types/cobranded-components/button-menu-button';
import { COBRAND_IDS } from '@client/store/types/cobranding';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return ButtonMenuButtonChase;
    default:
      return ButtonMenuButton;
  }
};

const ButtonMenuButtonCobranded: React.FC<
  Omit<ButtonMenuButtonProps, 'theme'>
> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default ButtonMenuButtonCobranded;
