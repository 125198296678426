import { connect } from 'react-redux';
import AccessiblePageTitle from '@client/components/generic/AccessiblePageTitle';
import { getPageTitleForView } from '@client/store/selectors/router.selectors';
import { getPreviousView } from '@src/redux-saga-router-plus/selectors';
import { getIsApplicationMounted } from '@client/store/selectors/server-rendering-info.selectors';

const mapStateToProps = (state) => {
  return {
    isAppMounted: getIsApplicationMounted(state),
    pageTitle: getPageTitleForView(state),
    isInitialView: !getPreviousView(state),
  };
};

export default connect(mapStateToProps, {})(AccessiblePageTitle);
