import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import { MLS_IDS_TO_DISPLAY_LISTING_INFO_LOGO } from '@client/store/constants';
import defaultTheme from '@client/css-modules/ListingInfo.css';
import { formatPhoneNumForLink } from '@client/utils/string.utils';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type Props = {
  agentInfo: {
    agentName: string | null;
    email: string | null;
    phone: string | null;
    officeName: string | null;
    licenseNumber: string | null;
    buyerBrokerageCompensationDisplay: string | null;
  };
  shouldDisplayAgentInfo: boolean;
  shouldDisplayListingNotes: boolean;
  listingNotes: string | null;
  handlePropertyDetailsContactAgentClick: (slug: string | null) => void;
  propertyIdInMLS: string | null;
  mlsNameAbbreviated: string | null;
  mlsLogoUrl: string | null;
  mlsIdOfMls: number | null;
  propertySlug: string | null;
  theme: Theme;
};

/**
 * MLS information around the property listing
 */
const ListingInfo: React.FunctionComponent<Props> = ({
  theme,
  agentInfo,
  shouldDisplayAgentInfo,
  listingNotes,
  handlePropertyDetailsContactAgentClick,
  shouldDisplayListingNotes,
  propertyIdInMLS,
  mlsIdOfMls,
  mlsLogoUrl,
  mlsNameAbbreviated,
  propertySlug,
}) => {
  const {
    agentName,
    phone,
    email,
    licenseNumber,
    officeName,
    buyerBrokerageCompensationDisplay,
  } = agentInfo;
  const { propertyListingPhoneColor } = useCobrandStyles();

  return (
    <>
      {shouldDisplayAgentInfo || (shouldDisplayListingNotes && listingNotes) ? (
        <section
          className={theme.ListingInfo}
          data-hc-name={'listing-info-section'}
        >
          <h2 className={theme.Heading}>Listing Agent &amp; Comments</h2>
          {shouldDisplayAgentInfo && (
            <div
              className={theme.ContactInfo}
              onClick={() =>
                handlePropertyDetailsContactAgentClick(propertySlug)
              }
            >
              {(agentName || officeName) && (
                <div className={theme.AgentNameSection}>
                  {agentName && (
                    <span
                      className={theme.AgentName}
                      data-hc-name={'listing-agent-name'}
                    >
                      {agentName}
                    </span>
                  )}
                  {agentName && officeName && ' • '}
                  {officeName}
                </div>
              )}
              {phone && (
                <>
                  <a
                    className={theme.phone}
                    style={{ color: propertyListingPhoneColor }}
                    data-hc-name={'listing-agent-phone'}
                    href={`tel:${formatPhoneNumForLink(phone)}`}
                  >
                    {phone}
                  </a>
                  {email && ' • '}
                </>
              )}
              {email && (
                <a
                  className={theme.email}
                  style={{ color: propertyListingPhoneColor }}
                  data-hc-name={'listing-agent-email'}
                  href={`mailto:${email}`}
                >
                  {email}
                </a>
              )}
              {licenseNumber && (
                <span
                  className={theme.LicenseNumber}
                  data-hc-name={'listing-agent-license-number'}
                >
                  {` License #${licenseNumber}`}
                </span>
              )}
            </div>
          )}
          {shouldDisplayListingNotes && listingNotes && (
            <div className={theme.ListingNotes} data-hc-name={'listing-notes'}>
              {listingNotes}
            </div>
          )}
          {(mlsNameAbbreviated || buyerBrokerageCompensationDisplay) && <hr />}
          <>
            {buyerBrokerageCompensationDisplay && (
              <div className={theme.CompensationDisplay}>
                {`Listing Broker Offer of Compensation is ${buyerBrokerageCompensationDisplay}.*`}
              </div>
            )}
            {mlsNameAbbreviated && (
              <div
                className={theme.MLSAttribution}
                data-hc-name={'listing-data-source'}
              >
                {propertyIdInMLS && (
                  <span>{`MLS# ${propertyIdInMLS}`}&nbsp;•&nbsp;</span>
                )}
                Data provided by {mlsNameAbbreviated}
                {mlsIdOfMls &&
                  MLS_IDS_TO_DISPLAY_LISTING_INFO_LOGO.includes(mlsIdOfMls) &&
                  mlsLogoUrl && (
                    <img
                      className={theme.MLSLogoSmall}
                      src={mlsLogoUrl}
                      alt={`${mlsNameAbbreviated} logo`}
                    />
                  )}
              </div>
            )}
            {buyerBrokerageCompensationDisplay && (
              <div className={theme.CompensationDisplayDisclaimer}>
                *This offer of compensation is only eligible for participants of
                the MLS where the listing was filed.
              </div>
            )}
          </>
        </section>
      ) : null}
    </>
  );
};

const ThemedListingInfo = themr('ListingInfo', defaultTheme)(ListingInfo);
export default ThemedListingInfo;
