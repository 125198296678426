import { stopEvent, key } from '@client/utils/component.utils';

type CallbackFunc = (e: React.KeyboardEvent<HTMLElement>) => void;
type ReturnFunc = (e: React.KeyboardEvent<HTMLElement>) => void;

/**
 * Convenience methods for defining callbacks to be called only when certain keys are pressed.
 * Example usage:
 *
 * <div onKeyDown={onEnterKey(handleClick)} />
 */
export const onEnterOrSpaceKey = (
  callback: CallbackFunc,
  allowNativeDOMEvent?: boolean
): ReturnFunc => {
  return (e: React.KeyboardEvent<HTMLElement>) => {
    if (key.isReturn(e.key) || key.isSpace(e.key)) {
      if (!allowNativeDOMEvent) {
        stopEvent(e);
      }
      callback(e);
    }
  };
};

export const onEnterKey = (
  callback: CallbackFunc,
  allowNativeDOMEvent?: boolean
): ReturnFunc => {
  return (e: React.KeyboardEvent<HTMLElement>) => {
    if (key.isReturn(e.key)) {
      if (!allowNativeDOMEvent) {
        stopEvent(e);
      }
      callback(e);
    }
  };
};

export const onSpaceKey = (
  callback: CallbackFunc,
  allowNativeDOMEvent?: boolean
): ReturnFunc => {
  return (e: React.KeyboardEvent<HTMLElement>) => {
    if (key.isSpace(e.key)) {
      if (!allowNativeDOMEvent) {
        stopEvent(e);
      }
      callback(e);
    }
  };
};
