import {
  CLOSE_UPDATE_EMAIL_BANNER,
  CLOSE_CONFIRM_EMAIL_BANNER,
} from '@client/store/actions/app-banner.actions';
import { Action } from '@client/store/actions';
import { STATUSES, Status } from '@client/store/constants';
import { CONFIRM_NEW_USER_SUCCESS } from '@client/store/actions/auth.actions';

export type AppBannerState = {
  updateEmailBanner: {
    isAllowedToShow: boolean;
  };
  confirmEmailBanner: {
    isAllowedToShow: boolean;
  };
  headerAppModal: {
    formState: {
      status: Status;
      errors: null | string;
    };
  };
};

export const INITIAL_APP_BANNER_STATE: AppBannerState = {
  updateEmailBanner: {
    isAllowedToShow: true,
  },
  confirmEmailBanner: {
    isAllowedToShow: true,
  },
  headerAppModal: {
    formState: {
      status: STATUSES.INIT,
      errors: null,
    },
  },
};

export default function appBannerReducer(
  state = INITIAL_APP_BANNER_STATE,
  action: Action
): AppBannerState {
  switch (action.type) {
    case CLOSE_UPDATE_EMAIL_BANNER:
      return {
        ...state,
        updateEmailBanner: {
          ...state.updateEmailBanner,
          isAllowedToShow: false,
        },
      };
    case CLOSE_CONFIRM_EMAIL_BANNER:
    case CONFIRM_NEW_USER_SUCCESS:
      return {
        ...state,
        confirmEmailBanner: {
          ...state.updateEmailBanner,
          isAllowedToShow: false,
        },
      };
    default:
      return state;
  }
}
