import {
  HistoryType,
  RouteDefsAllType,
  RouterOptionsType,
  SagaMiddlewareType,
} from './types';

import initRouter from './init-router';
import LinkFactory from './react/LinkFactory';
import { OS_MAC, OS_WIN } from './constants';

let Link;

const router = (
  history: HistoryType,
  ROUTES: RouteDefsAllType,
  sagaMiddleware: SagaMiddlewareType,
  options: RouterOptionsType
): void => {
  const os = navigator.platform.indexOf('Mac') > -1 ? OS_MAC : OS_WIN;
  Link = LinkFactory(ROUTES, os);
  initRouter(history, ROUTES, sagaMiddleware, options);
};

export { Link };
export default router;
