import { ModalKey } from '@client/store/constants';
import { LOCKED_PDP_SALE_HISTORY_PLACEHOLDER } from '@client/store/locked-components-constants';
import { getSalesHistory } from '@client/store/selectors/property-details.selectors';
import {
  SectionId,
  SpecialUserType,
} from '@client/store/types/locked-components';
import { formatDate } from '@client/utils/date.utils';
import { dollarsFormatter } from '@client/utils/formatter.utils';
import { Theme } from '@friendsofreactjs/react-css-themr';
import { useSelector } from 'react-redux';
import PreviewSection from './PreviewSection';

interface Props {
  theme: Theme;
  openSectionModal: (modalKey: ModalKey) => void;
}

const SaleHistory = ({ theme, openSectionModal }: Props) => {
  const saleHistory = useSelector(getSalesHistory);
  /** Account for sale history to be null or an empty array */
  const mostRecentSale =
    saleHistory && saleHistory.length ? saleHistory[0] : null;

  return (
    <>
      {!(!Array.isArray(saleHistory) || !saleHistory.length) && (
        <PreviewSection
          data-section-key={'sale_history'}
          dataEventName={'click_property_details_sale_history'}
          onClick={() => openSectionModal('sale_history')}
          title="Sale History"
          lockedComponent={{
            sectionId: SectionId.PdpSaleHistory,
            lockedFor: [SpecialUserType.Restricted],
          }}
          placeholder={LOCKED_PDP_SALE_HISTORY_PLACEHOLDER}
        >
          {mostRecentSale && (
            <>
              {mostRecentSale.transferPrice ? (
                <div>
                  Most Recent: {dollarsFormatter(mostRecentSale.transferPrice)}
                </div>
              ) : (
                <div>Most recent: </div>
              )}
              <span className={theme.SaleHistoryStatus}>
                {mostRecentSale.displayType}
              </span>
              <span>
                {' '}
                •{' '}
                {mostRecentSale.transferDate
                  ? // Adding T00:00:00 to allow the date to be interpreted in the browser's default timezone
                    formatDate(`${mostRecentSale.transferDate}T00:00:00`)
                  : ''}
              </span>
            </>
          )}
        </PreviewSection>
      )}
    </>
  );
};

export default SaleHistory;
