import { themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PillButton from '@client/components/generic/PillButton';
import MarketingModuleHouseImg from '@client/components/HomeownerAdImg/MarketingModuleHouseImg';
import defaultTheme from '@client/css-modules/LenderCTACard.css';
import { useOutboundCtaUrl } from '@client/hooks/outbound-cta-url.hooks';
import { chaseLenderCTAClick } from '@client/store/actions/cobranding.actions';
import { EVENTS, PARENT_EVENTS } from '@client/store/analytics-constants';
import { getLenderCtasConfig } from '@client/store/selectors/cobranding.selectors';
import { getLoanOfficerId } from '@client/store/selectors/loan-officer.selectors';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import { AnalyticsLenderCTADefault } from '@client/store/types/analytics';
import {
  CARD_TYPES,
  LenderCTAProps,
} from '@client/store/types/cobranded-components/lender-cta-card';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { replaceTemplateKeyWithLoId } from '@client/utils/loan-officer.utils';

const LenderCTACardChase: React.FC<LenderCTAProps> = ({
  theme = {},
  cardType,
  area,
  ordinal,
}) => {
  const dispatch = useDispatch();
  const isSmallScreen = useSelector(getIsSmallSize);
  const lenderCtasConfig = useSelector(getLenderCtasConfig);
  const loId = useSelector(getLoanOfficerId);
  const loUrlTemplate = lenderCtasConfig?.[area]?.loUrlTemplate;
  const ctaUrl =
    /* Chase doesn't currently feature LOs in their instance, but leaving this here from the generic component
     * in case they choose to in the future */
    !!loUrlTemplate && loId
      ? replaceTemplateKeyWithLoId(loUrlTemplate, loId)
      : lenderCtasConfig?.[area]?.ctaUrl;

  const outboundCtaUrl = useOutboundCtaUrl(ctaUrl || null);

  if (!lenderCtasConfig) {
    return null;
  }

  const {
    [area]: {
      headline,
      ctaText,
      headlineFontColor,
      headlineBackgroundColor,
      buttonColor,
      buttonFontColor,
      nativeAppNavKey,
    },
  } = lenderCtasConfig;

  /* Don't render the card if the respective Lender CTA fields aren't populated in Parcon */
  if (!nativeAppNavKey || !ctaUrl || !outboundCtaUrl) {
    return null;
  }

  const clickEventDataJson: AnalyticsLenderCTADefault = {
    area,
    cta_url: ctaUrl,
    button_copy: ctaText,
    headline_copy: headline,
    cta_ordinal: ordinal,
  };

  const onClick = () => {
    outboundCtaUrl &&
      dispatch(chaseLenderCTAClick(outboundCtaUrl, nativeAppNavKey));
  };

  return (
    <div
      className={classNames(theme.LenderCTACard, {
        [theme.FullCard]:
          cardType === CARD_TYPES.FULL ||
          (cardType === CARD_TYPES.BOTH && !isSmallScreen),
        [theme.SmallCard]:
          cardType === CARD_TYPES.SMALL ||
          (cardType === CARD_TYPES.BOTH && isSmallScreen),
      })}
      style={
        headlineBackgroundColor
          ? {
              backgroundColor: headlineBackgroundColor,
            }
          : {}
      }
    >
      <div className={theme.HeadlineWrapper}>
        {headline && (
          <div
            className={theme.HeadlineCopy}
            style={{ color: headlineFontColor }}
          >
            {headline}
          </div>
        )}
        {outboundCtaUrl && ctaText && (
          <div className={theme.LenderCTA}>
            <PillButton
              data-event-name={EVENTS.CLICK_LENDER_CTA}
              data-parent-event-name={PARENT_EVENTS.CLICK_LENDER_CTA}
              data-event-data-json={JSON.stringify(clickEventDataJson)}
              ariaLabel={ctaText}
              className={theme.LenderCTAButton}
              onClick={onClick}
              onKeyDown={onEnterOrSpaceKey(onClick)}
              customBackgroundColor={buttonColor}
              customTextColor={buttonFontColor}
            >
              {ctaText}
            </PillButton>
          </div>
        )}
      </div>
      <div className={theme.AdImgContainer}>
        <MarketingModuleHouseImg className={theme.AdImg} />
      </div>
    </div>
  );
};

export default themr(
  'ThemedLenderCTACardChase',
  defaultTheme
)(LenderCTACardChase);
