import { put, select, call } from 'redux-saga/effects';
import { routeChange } from '@src/redux-saga-router-plus/actions';

import { View } from '@client/routes/constants';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { consumerApiClient } from '@client/services/consumer-api-client';

export default function* routeCustomerAdminPortal() {
  const shouldDisplayCustomerAdminPortal = (yield select(
    getIsFeatureEnabled('customer_admin_portal')
  )) as boolean;

  /**
   * If customer_admin_portal feature is not enabled, redirect to search page.
   */
  if (shouldDisplayCustomerAdminPortal) {
    try {
      yield call([
        consumerApiClient,
        consumerApiClient.getIsAdminAccessTokenValid,
      ]);
    } catch (e: any) {
      yield put(routeChange({ view: View.SEARCH }));
    }
  } else {
    yield put(routeChange({ view: View.SEARCH }));
  }
}
