export const CLICK_FIND_NEW_AGENT_FORM_SUBMIT =
  'CLICK_FIND_NEW_AGENT_FORM_SUBMIT';
export const CLICK_AGENT_LEAD_REFERRAL_NETWORK =
  'CLICK_AGENT_LEAD_REFERRAL_NETWORK';

export type FindNewAgentFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
  buyingOrSelling: 'Buying';
  formName: 'Contact New Agent';
  hasAgent: false;
  entrySlug?: string;
  propertyDetailSlugs?: string[];
};
export type SubmitFindNewAgentForm = {
  type: typeof CLICK_FIND_NEW_AGENT_FORM_SUBMIT;
  payload: FindNewAgentFormValues;
};
export const submitFindNewAgentForm = (
  formValues: FindNewAgentFormValues
): SubmitFindNewAgentForm => ({
  type: CLICK_FIND_NEW_AGENT_FORM_SUBMIT,
  payload: formValues,
});

export type SubmitAgentLeadReferralNetworkPayload = {
  preferred_contact_type: 'phone' | 'email';
  phone?: string;
  email?: string;
  tour_type?: 'video' | 'in-person' | null;
  time_of_day?: 'morning' | 'afternoon' | null;
  message?: string;
  slug?: string;
};
export type SubmitAgentLeadReferralNetwork = {
  type: typeof CLICK_AGENT_LEAD_REFERRAL_NETWORK;
  payload: SubmitAgentLeadReferralNetworkPayload;
};
export const submitAgentLeadReferralNetwork = (
  payload: SubmitAgentLeadReferralNetworkPayload
): SubmitAgentLeadReferralNetwork => ({
  type: CLICK_AGENT_LEAD_REFERRAL_NETWORK,
  payload,
});

export type FindAgentAction =
  | SubmitFindNewAgentForm
  | SubmitAgentLeadReferralNetwork;
