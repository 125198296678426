import { useSelector } from 'react-redux';
import {
  getIsShowingHeader,
  getIsShowingFooter,
  getAppContainerIsFlexed,
  getAppContainerHasMinHeight,
  getIsShowingBlankPage,
  getAppHasGreyBackground,
} from '@client/store/selectors/global-ui.selectors';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import { getSearchIsShowingMobileFilters } from '@client/store/selectors/search.selectors';
import { getIsAppBannerOpen } from '@client/store/selectors/app-banner.selectors';

export function usePageState() {
  const isShowingBlankPage = useSelector(getIsShowingBlankPage);
  const isAppContainerFlexed = useSelector(getAppContainerIsFlexed);
  const headerIsShown = useSelector(getIsShowingHeader);
  const appContainerHasMinHeight = useSelector(getAppContainerHasMinHeight);
  const hasGreyBackground = useSelector(getAppHasGreyBackground);
  const footerIsShown = useSelector(getIsShowingFooter);

  const disableAdBlockDetection = useSelector(getIsSmallSize);
  const isAppBannerOpen = useSelector(getIsAppBannerOpen);
  const isAppContainerBlurred = useSelector(getSearchIsShowingMobileFilters);

  return {
    isShowingBlankPage,
    isAppContainerFlexed,
    headerIsShown,
    appContainerHasMinHeight,
    hasGreyBackground,
    footerIsShown,
    disableAdBlockDetection,
    isAppBannerOpen,
    isAppContainerBlurred,
  };
}
