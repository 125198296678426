import { useSelector } from 'react-redux';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  getCobrandDisplayName,
  getCobrandId,
} from '@client/store/selectors/cobranding.selectors';
import { COBRAND_IDS } from '@client/store/types/cobranding';

export function useUnsubscribeEmailBrandingProps() {
  const isFeatureEnabled = useSelector(
    getIsFeatureEnabled('unsubscribe_email_branding')
  );
  const cobrandId = useSelector(getCobrandId);
  const cobrandDisplayName = useSelector(getCobrandDisplayName);

  return {
    cobrandDisplayName,
    isFeatureEnabled,
    isLender: cobrandId !== COBRAND_IDS.WWW,
  };
}
