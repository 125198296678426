import { format } from 'date-fns';

import {
  FETCH_LOAN_OFFICER_INFO_SUCCESS,
  FETCH_LOAN_OFFICER_INFO_ERROR,
  CONTACT_LOAN_OFFICER_SUCCESS,
  CONTACT_LOAN_OFFICER_ERROR,
} from '@client/store/actions/loan-officer.actions';
import { LoanOfficerAPIResponse } from '@client/store/types/consumer-api';
import { Action } from '@client/store/actions';
import { STATUSES } from '@client/store/constants';
import { Status } from '@client/store/types/statuses';

export type LoanOfficerState = {
  contactLoanOfficerError: string | null;
  status: Status;
  data: LoanOfficerAPIResponse | null;
  refiSavingsPerMonth: number;
  lastMessageDate: string | null;
};

export const INITIAL_LOAN_OFFICER_STATE: LoanOfficerState = {
  contactLoanOfficerError: null,
  status: STATUSES.INIT,
  data: null,
  refiSavingsPerMonth: 234,
  lastMessageDate: null,
};

export default function loanOfficerReducer(
  state = INITIAL_LOAN_OFFICER_STATE,
  action: Action
): LoanOfficerState {
  switch (action.type) {
    case FETCH_LOAN_OFFICER_INFO_SUCCESS:
      return {
        ...state,
        status: STATUSES.SUCCESS,
        data: action.payload.loInfo
          ? {
              ...action.payload.loInfo,
            }
          : null,
      };
    case FETCH_LOAN_OFFICER_INFO_ERROR:
      return {
        ...INITIAL_LOAN_OFFICER_STATE,
        status: STATUSES.ERROR,
      };
    case CONTACT_LOAN_OFFICER_SUCCESS:
      return {
        ...state,
        lastMessageDate: format(new Date(), 'M/DD/YY'),
      };
    case CONTACT_LOAN_OFFICER_ERROR:
      return {
        ...state,
        contactLoanOfficerError: action.payload.error,
      };
    default:
      return state;
  }
}
