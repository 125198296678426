import { Status } from '@client/store/constants';
import {
  BusinessReview,
  ClaimedHomeAddress,
  ClaimedHomeData,
  ClaimedHomes,
  HomeownerPropertyData,
  HomeownerResponseArray,
  HomeownerResponse,
  ImprovementDataPoint,
  TimeRange,
  LoanTerms,
  PropensityToSellResponseValue,
  HomeImprovementData,
} from '@client/store/types/homeowner';
import { Block, Condition } from '@client/store/sagas/queries/types';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { ReferralPropertyData } from '@client/store/types/property-contact-form';

export const FETCH_HOMEOWNER_PROPERTY_PHOTOS =
  'FETCH_HOMEOWNER_PROPERTY_PHOTOS';
export const FETCH_HOMEOWNER_PROPERTY_PHOTOS_SUCCESS =
  'FETCH_HOMEOWNER_PROPERTY_PHOTOS_SUCCESS';
export const FETCH_HOMEOWNER_USER_DATA = 'FETCH_HOMEOWNER_USER_DATA';
export const FETCH_HOMEOWNER_USER_DATA_IF_NECESSARY =
  'FETCH_HOMEOWNER_USER_DATA_IF_NECESSARY';
export const FETCH_HOMEOWNER_USER_DATA_SUCCESS =
  'FETCH_HOMEOWNER_USER_DATA_SUCCESS';
export const FETCH_CLAIMED_HOMES_IF_NECESSARY =
  'FETCH_CLAIMED_HOMES_IF_NECESSARY';
export const FETCH_CLAIMED_HOMES = 'FETCH_CLAIMED_HOMES';
export const FETCH_CLAIMED_HOMES_SUCCESS = 'FETCH_CLAIMED_HOMES_SUCCESS';
export const FETCH_CLAIMED_HOMES_ERROR = 'FETCH_CLAIMED_HOMES_ERROR';
export const UPDATE_CLAIMED_HOME_DETAILS = 'UPDATE_CLAIMED_HOME_DETAILS';
export const UPDATE_CLAIMED_HOME_DETAILS_VIA_FORM =
  'UPDATE_CLAIMED_HOME_DETAILS_VIA_FORM';
export const UPDATE_CLAIMED_HOME_SUCCESS = 'UPDATE_CLAIMED_HOME_SUCCESS';
export const UPDATE_CLAIMED_HOME_ERROR = 'UPDATE_CLAIMED_HOME_ERROR';
export const CHANGE_CLAIMED_HOME_FORM_VALUE = 'CHANGE_CLAIMED_HOME_FORM_VALUE';
export const FETCH_LOAN_TERMS = 'FETCH_LOAN_TERMS';
export const UPDATE_LOAN_TERMS = 'UPDATE_LOAN_TERMS';
export const UPDATE_LOAN_TERMS_ERROR = 'UPDATE_LOAN_TERMS_ERROR';
export const SET_EDIT_FORM_TO_INIT = 'SET_EDIT_FORM_TO_INIT';
export const CLAIM_PROPERTY_SUCCESS = 'CLAIM_PROPERTY_SUCCESS';
export const CLAIM_ACTIVE_PROPERTY_ERROR = 'CLAIM_ACTIVE_PROPERTY_ERROR';
export const CLAIM_ACTIVE_PROPERTY_THEN_VERIFY =
  'CLAIM_ACTIVE_PROPERTY_THEN_VERIFY';
export const UPDATE_HOMEOWNER_PROPERTY_DATA = 'UPDATE_HOMEOWNER_PROPERTY_DATA';
export const CLAIM_PROPERTY_ERROR = 'CLAIM_PROPERTY_ERROR';
export const FETCH_HOME_ADVISOR_ESTIMATED_COST =
  'FETCH_HOME_ADVISOR_ESTIMATED_COST';
export const FETCH_HOME_ADVISOR_ESTIMATED_COST_SUCCESS =
  'FETCH_HOME_ADVISOR_ESTIMATED_COST_SUCCESS';
export const FETCH_HOMEOWNER_MANAGE_HOMES_DATA =
  'FETCH_HOMEOWNER_MANAGE_HOMES_DATA';
export const FETCH_HOMEOWNER_MANAGE_HOMES_DATA_ERROR =
  'FETCH_HOMEOWNER_MANAGE_HOMES_DATA_ERROR';
export const FETCH_HOMEOWNER_HOME_IMPROVEMENTS =
  'FETCH_HOMEOWNER_HOME_IMPROVEMENTS';
export const FETCH_HOMEOWNER_HOME_IMPROVEMENTS_SUCCESS =
  'FETCH_HOMEOWNER_HOME_IMPROVEMENTS_SUCCESS';
export const MANAGE_SESSION_STORAGE_USER_INPUT_HOME_IMPROVEMENT_CALCULATION =
  'MANAGE_SESSION_STORAGE_USER_INPUT_HOME_IMPROVEMENT_CALCULATION';
export const FETCH_PROPERTY_VALUE_FORECAST = 'FETCH_PROPERTY_VALUE_FORECAST';
export const FETCH_PROPERTY_VALUE_FORECAST_SUCCESS =
  'FETCH_PROPERTY_VALUE_FORECAST_SUCCESS';
export const FETCH_LOAN_TERMS_IF_NECESSARY = 'FETCH_LOAN_TERMS_IF_NECESSARY';
export const REMOVE_PROPERTY_FROM_CLAIMED_HOMES =
  'REMOVE_PROPERTY_FROM_CLAIMED_HOMES';
export const DELETE_PROPERTY_FROM_CLAIMED_HOMES_STATE =
  'DELETE_PROPERTY_FROM_CLAIMED_HOMES_STATE';
export const REMOVE_PROPERTY_FROM_CLAIMED_HOMES_ERROR =
  'REMOVE_PROPERTY_FROM_CLAIMED_HOMES_ERROR';
export const CLAIM_PROPERTY = 'CLAIM_PROPERTY';
export const OPEN_EQUITY_MODAL = 'OPEN_EQUITY_MODAL';
export const CLOSE_EQUITY_MODAL = 'CLOSE_EQUITY_MODAL';
export const OPEN_IMPROVE_MODAL = 'OPEN_IMPROVE_MODAL';
export const CLOSE_IMPROVE_MODAL = 'CLOSE_IMPROVE_MODAL';
export const RESET_CLAIMED_HOME_STATUS = 'RESET_CLAIMED_HOME_STATUS';
export const RESET_HOMEOWNER_MANAGE_HOMES_STATE =
  'RESET_HOMEOWNER_MANAGE_HOMES_STATE';
export const OPEN_EQUITY_PAGE_IF_WE_HAVE_THE_DATA =
  'OPEN_EQUITY_PAGE_IF_WE_HAVE_THE_DATA';
export const SHOW_EQUITY_UPDATE_ERROR = 'SHOW_EQUITY_UPDATE_ERROR';
export const CLAIM_HOME_IF_LOGGED_IN_THEN_REDIRECT =
  'CLAIM_HOME_IF_LOGGED_IN_THEN_REDIRECT';
export const SET_AVM_CHART_TIME_RANGE = 'SET_AVM_CHART_TIME_RANGE';
export const SET_AVM_AT_TARGET_TIME_ON_CHART =
  'SET_AVM_AT_TARGET_TIME_ON_CHART';
export const SET_DATE_AT_TARGET_TIME_ON_CHART =
  'SET_DATE_AT_TARGET_TIME_ON_CHART';
export const SET_SELECTED_HOME_CONDITION = 'SET_SELECTED_HOME_CONDITION';
export const ADD_SELECTED_HOME_PROJECT = 'ADD_SELECTED_HOME_PROJECT';
export const DELETE_SELECTED_HOME_PROJECT = 'DELETE_SELECTED_HOME_PROJECT';
export const FETCH_HOME_INFO_FOR_PROFINDER = 'FETCH_HOME_INFO_FOR_PROFINDER';
export const FETCH_HOME_INFO_FOR_PROFINDER_SUCCESS =
  'FETCH_HOME_INFO_FOR_PROFINDER_SUCCESS';
export const SUBMIT_PROFINDER_REFERRAL_REQUEST =
  'SUBMIT_PROFINDER_REFERRAL_REQUEST';
export const SUBMIT_PROFINDER_REFERRAL_REQUEST_SUCCESS =
  'SUBMIT_PROFINDER_REFERRAL_REQUEST_SUCCESS';
export const SUBMIT_PROFINDER_REFERRAL_REQUEST_ERROR =
  'SUBMIT_PROFINDER_REFERRAL_REQUEST_ERROR';
export const REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE =
  'REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE';
export const REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE_SUCCESS =
  'REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE_SUCCESS';
export const REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE_ERROR =
  'REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE_ERROR';
export const FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS =
  'FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS';
export const FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS_SUCCESS =
  'FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS_SUCCESS';
export const FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS_ERROR =
  'FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS_ERROR';
export const CLOSE_PROFINDER_REVIEWS_MODAL = 'CLOSE_PROFINDER_REVIEWS_MODAL';
export const OPEN_PROFINDER_REVIEWS_MODAL = 'OPEN_PROFINDER_REVIEWS_MODAL';
export const SET_HOMEADVISOR_REVIEWS_STATUS_TO_SUCCESS =
  'SET_HOMEADVISOR_REVIEWS_STATUS_TO_SUCCESS';
export const UPDATE_PROFINDER_COST_ESTIMATES =
  'UPDATE_PROFINDER_COST_ESTIMATES';
export const SET_PROFINDER_STATUS_TO_SUCCESS =
  'SET_PROFINDER_STATUS_TO_SUCCESS';
export const SET_PROFINDER_STATUS_TO_ERROR = 'SET_PROFINDER_STATUS_TO_ERROR';
export const FETCH_HOMEOWNER_MANAGE_SINGLE_HOME =
  'FETCH_HOMEOWNER_MANAGE_SINGLE_HOME';
export const FETCH_HOMEOWNER_MANAGE_SINGLE_HOME_SUCCESS =
  'FETCH_HOMEOWNER_MANAGE_SINGLE_HOME_SUCCESS';
export const SET_HOMEOWNER_MANAGE_HOME_STATUS_TO_LOADING =
  'SET_HOMEOWNER_MANAGE_HOME_STATUS_TO_LOADING';
export const SET_HOMEOWNER_PMI_MODAL_WAS_SHOWN =
  'SET_HOMEOWNER_PMI_MODAL_WAS_SHOWN';
export const SUBMIT_PROPENSITY_TO_SELL_RESPONSE =
  'SUBMIT_PROPENSITY_TO_SELL_RESPONSE';
export const FETCH_HOMEOWNER_PROPERTY_IF_NECESSARY =
  'FETCH_HOMEOWNER_PROPERTY_IF_NECESSARY';
export const SEND_HOMEOWNER_HOME_IMPROVEMENTS_ANALYTICS =
  'SEND_HOMEOWNER_HOME_IMPROVEMENTS_ANALYTICS';
export const RESET_HOMEOWNER_PROP_DETAILS = 'RESET_HOMEOWNER_PROP_DETAILS';
export const FETCH_HOMEOWNER_PROP_DETAILS_SUCCESS =
  'FETCH_HOMEOWNER_PROP_DETAILS_SUCCESS';
export const FETCH_HOMEOWNER_PROP_DETAILS_ERROR =
  'FETCH_HOMEOWNER_PROP_DETAILS_ERROR';
/* Fetches claimed homes and loan terms, knowledge of which should be available throughout the homeowner experience */
export type FetchHomeownerUserDataAction = {
  type: typeof FETCH_HOMEOWNER_USER_DATA;
};

export const fetchHomeownerUserData = (): FetchHomeownerUserDataAction => ({
  type: FETCH_HOMEOWNER_USER_DATA,
});

export type FetchHomeownerUserDataSuccessAction = {
  type: typeof FETCH_HOMEOWNER_USER_DATA_SUCCESS;
};

export const fetchHomeownerUserDataSuccess =
  (): FetchHomeownerUserDataSuccessAction => ({
    type: FETCH_HOMEOWNER_USER_DATA_SUCCESS,
  });

export type FetchHomeownerUserDataIfNecessaryAction = {
  type: typeof FETCH_HOMEOWNER_USER_DATA_IF_NECESSARY;
};

export const fetchHomeownerUserDataIfNecessary =
  (): FetchHomeownerUserDataIfNecessaryAction => ({
    type: FETCH_HOMEOWNER_USER_DATA_IF_NECESSARY,
  });

/* Fetches the property details necessary to populate the homeowner dashboard and other homeowner pages with data */
export type FetchHomeownerPropertyIfNecessaryAction = {
  type: typeof FETCH_HOMEOWNER_PROPERTY_IF_NECESSARY;
  payload: {
    slug: string;
  };
};

export const fetchHomeownerPropertyIfNecessary = (
  slug: string
): FetchHomeownerPropertyIfNecessaryAction => ({
  type: FETCH_HOMEOWNER_PROPERTY_IF_NECESSARY,
  payload: {
    slug,
  },
});

type FetchHomeownerManageHomesDataAction = {
  type: typeof FETCH_HOMEOWNER_MANAGE_HOMES_DATA;
  payload: {
    data: ClaimedHomes;
  };
};

export const fetchHomeownerManageHomesData = (
  data: ClaimedHomes
): FetchHomeownerManageHomesDataAction => ({
  type: FETCH_HOMEOWNER_MANAGE_HOMES_DATA,
  payload: {
    data,
  },
});

type FetchHomeownerManageSingleHomeAction = {
  type: typeof FETCH_HOMEOWNER_MANAGE_SINGLE_HOME;
  payload: {
    data: {
      slug: string;
      zipHpiStart: string;
      zipHpiEnd: string;
    };
  };
};

type ManageSingleHomePayload =
  FetchHomeownerManageSingleHomeAction['payload']['data'];

export const fetchHomeownerManageSingleHome = (
  data: ManageSingleHomePayload
): FetchHomeownerManageSingleHomeAction => ({
  type: FETCH_HOMEOWNER_MANAGE_SINGLE_HOME,
  payload: {
    data,
  },
});

type FetchHomeownerManageSingleHomeSuccessAction = {
  type: typeof FETCH_HOMEOWNER_MANAGE_SINGLE_HOME_SUCCESS;
  payload: {
    data: {
      [slug: string]: ClaimedHomeData;
    };
  };
};

type ManageSingleHomeSuccessPayload =
  FetchHomeownerManageSingleHomeSuccessAction['payload']['data'];

export const fetchHomeownerManageSingleHomeSuccess = (
  data: ManageSingleHomeSuccessPayload
): FetchHomeownerManageSingleHomeSuccessAction => ({
  type: FETCH_HOMEOWNER_MANAGE_SINGLE_HOME_SUCCESS,
  payload: {
    data,
  },
});

type SetHomeownerManageHomeStateToLoadingAction = {
  type: typeof SET_HOMEOWNER_MANAGE_HOME_STATUS_TO_LOADING;
};

export const setHomeownerManageHomeStateToLoading =
  (): SetHomeownerManageHomeStateToLoadingAction => ({
    type: SET_HOMEOWNER_MANAGE_HOME_STATUS_TO_LOADING,
  });

type FetchHomeownerManageHomesDataErrorAction = {
  type: typeof FETCH_HOMEOWNER_MANAGE_HOMES_DATA_ERROR;
  payload: {
    data: {
      key: string;
      error: string;
    };
  };
};

export const fetchHomeownerManageHomesDataError = (
  data: FetchHomeownerManageHomesDataErrorAction['payload']['data']
): FetchHomeownerManageHomesDataErrorAction => ({
  type: FETCH_HOMEOWNER_MANAGE_HOMES_DATA_ERROR,
  payload: {
    data,
  },
});

type ResetHomeownerPropDetailsAction = {
  type: typeof RESET_HOMEOWNER_PROP_DETAILS;
  payload: {
    slug: string;
  };
};

export const resetHomeownerPropDetails = (
  slug: ResetHomeownerPropDetailsAction['payload']['slug']
): ResetHomeownerPropDetailsAction => ({
  type: RESET_HOMEOWNER_PROP_DETAILS,
  payload: {
    slug,
  },
});

type FetchHomeownerPropDetailsSuccessAction = {
  type: typeof FETCH_HOMEOWNER_PROP_DETAILS_SUCCESS;
  payload: {
    slug: string;
    data: HomeownerPropertyData & {
      placeId: number;
    };
  };
};

export const fetchHomeownerPropDetailsSuccess = ({
  slug,
  data,
}: FetchHomeownerPropDetailsSuccessAction['payload']): FetchHomeownerPropDetailsSuccessAction => ({
  type: FETCH_HOMEOWNER_PROP_DETAILS_SUCCESS,
  payload: {
    slug,
    data,
  },
});

type FetchHomeownerPropDetailsErrorAction = {
  type: typeof FETCH_HOMEOWNER_PROP_DETAILS_ERROR;
  payload: {
    slug: string;
  };
};

export const fetchHomeownerPropDetailsError = ({
  slug,
}: FetchHomeownerPropDetailsErrorAction['payload']): FetchHomeownerPropDetailsErrorAction => ({
  type: FETCH_HOMEOWNER_PROP_DETAILS_ERROR,
  payload: {
    slug,
  },
});

type FetchHomeownerPropertyPhotosAction = {
  type: typeof FETCH_HOMEOWNER_PROPERTY_PHOTOS;
  payload: {
    slug: string;
    latitude: number;
    longitude: number;
    propertyStatus: Status;
    shouldFallbackToMapPhoto: boolean;
  };
};

export const fetchHomeownerPropertyPhotos = ({
  slug,
  latitude,
  longitude,
  propertyStatus,
  shouldFallbackToMapPhoto,
}: FetchHomeownerPropertyPhotosAction['payload']): FetchHomeownerPropertyPhotosAction => ({
  type: FETCH_HOMEOWNER_PROPERTY_PHOTOS,
  payload: {
    slug,
    latitude,
    longitude,
    propertyStatus,
    shouldFallbackToMapPhoto,
  },
});

type FetchHomeownerPropertyPhotosSuccessAction = {
  type: typeof FETCH_HOMEOWNER_PROPERTY_PHOTOS_SUCCESS;
  payload: {
    slug: string;
    photos: string[];
  };
};

export const fetchHomeownerPropertyPhotosSuccess = ({
  slug,
  photos,
}: FetchHomeownerPropertyPhotosSuccessAction['payload']): FetchHomeownerPropertyPhotosSuccessAction => ({
  type: FETCH_HOMEOWNER_PROPERTY_PHOTOS_SUCCESS,
  payload: {
    slug,
    photos,
  },
});

type FetchClaimedHomesIfNecessaryAction = {
  type: typeof FETCH_CLAIMED_HOMES_IF_NECESSARY;
};

export const fetchClaimedHomesIfNecessary =
  (): FetchClaimedHomesIfNecessaryAction => ({
    type: FETCH_CLAIMED_HOMES_IF_NECESSARY,
  });

type FetchClaimedHomesAction = {
  type: typeof FETCH_CLAIMED_HOMES;
};

export const fetchClaimedHomes = (): FetchClaimedHomesAction => ({
  type: FETCH_CLAIMED_HOMES,
});

type FetchClaimedHomesSuccessAction = {
  type: typeof FETCH_CLAIMED_HOMES_SUCCESS;
  payload: {
    claimedHomes: HomeownerResponseArray;
  };
};

export const fetchClaimedHomesSuccess = (
  claimedHomes: any
): FetchClaimedHomesSuccessAction => ({
  type: FETCH_CLAIMED_HOMES_SUCCESS,
  payload: { claimedHomes },
});

type FetchClaimedHomesErrorAction = {
  type: typeof FETCH_CLAIMED_HOMES_ERROR;
};

export const fetchClaimedHomesError = (): FetchClaimedHomesErrorAction => ({
  type: FETCH_CLAIMED_HOMES_ERROR,
});

export type SendClaimedHomeDetailsToDbThroughEditHomeFormAction = {
  type: typeof UPDATE_CLAIMED_HOME_DETAILS_VIA_FORM;
  payload: {
    homeDetails: undefined;
  };
  meta: {
    analytics: {
      event: 'click_owner_edit_save';
      parentEvent: typeof PARENT_EVENTS.CLICK_OWNER_EDIT;
      props: {
        ch_property_id: string | null;
      };
    };
  };
};

export const sendClaimedHomeDetailsToDbThroughEditHomeForm = (
  slug: string | null
): SendClaimedHomeDetailsToDbThroughEditHomeFormAction => ({
  type: UPDATE_CLAIMED_HOME_DETAILS_VIA_FORM,
  payload: {
    homeDetails: undefined,
  },
  meta: {
    analytics: {
      event: 'click_owner_edit_save',
      parentEvent: PARENT_EVENTS.CLICK_OWNER_EDIT,
      props: {
        ch_property_id: slug,
      },
    },
  },
});

export type SendClaimedHomeDetailsToDbAction = {
  type: typeof UPDATE_CLAIMED_HOME_DETAILS;
  payload: {
    homeDetails: {
      [key: string]: number | string | boolean | null;
    };
  };
};

export const sendClaimedHomeDetailsToDb = (
  homeDetails: SendClaimedHomeDetailsToDbAction['payload']['homeDetails']
): SendClaimedHomeDetailsToDbAction => ({
  type: UPDATE_CLAIMED_HOME_DETAILS,
  payload: { homeDetails },
});

type UpdateClaimedHomeFormSuccessAction = {
  type: typeof UPDATE_CLAIMED_HOME_SUCCESS;
  payload: {
    homeData: HomeownerResponse;
  };
};

export const updateClaimedHomesSuccess = (
  homeData: HomeownerResponse
): UpdateClaimedHomeFormSuccessAction => ({
  type: UPDATE_CLAIMED_HOME_SUCCESS,
  payload: { homeData },
});

type UpdateClaimedHomeFormErrorAction = {
  type: typeof UPDATE_CLAIMED_HOME_ERROR;
  payload: {
    error: string;
  };
  meta: {
    toast: {
      message: string;
    };
    analytics?: {
      event: string;
    };
  };
};

export const updateClaimedHomeError = (
  error: string,
  analyticsEvent?: string
): UpdateClaimedHomeFormErrorAction => ({
  type: UPDATE_CLAIMED_HOME_ERROR,
  payload: { error },
  meta: {
    toast: {
      message: "We're sorry, an error occurred. Please try again.",
    },
    ...(analyticsEvent
      ? {
          analytics: {
            event: analyticsEvent,
          },
        }
      : {}),
  },
});

type UpdateClaimedHomeFormAction = {
  type: typeof CHANGE_CLAIMED_HOME_FORM_VALUE;
  payload: {
    key: string;
    value: number | string | boolean | null;
  };
};

export const updateClaimedHomeForm = (
  key: string,
  value: string | number | null
): UpdateClaimedHomeFormAction => ({
  type: CHANGE_CLAIMED_HOME_FORM_VALUE,
  payload: {
    key,
    value,
  },
});

type FetchLoanTermsAction = {
  type: typeof FETCH_LOAN_TERMS;
};

export const fetchLoanTerms = (): FetchLoanTermsAction => ({
  type: FETCH_LOAN_TERMS,
});

type FetchLoanTermsIfNecessaryAction = {
  type: typeof FETCH_LOAN_TERMS_IF_NECESSARY;
};

export const fetchLoanTermsIfNecessary =
  (): FetchLoanTermsIfNecessaryAction => ({
    type: FETCH_LOAN_TERMS_IF_NECESSARY,
  });

type UpdateLoanTermsAction = {
  type: typeof UPDATE_LOAN_TERMS;
  payload: {
    loanTerms: LoanTerms;
  };
};

export const updateLoanTerms = (
  loanTerms: UpdateLoanTermsAction['payload']['loanTerms']
): UpdateLoanTermsAction => ({
  type: UPDATE_LOAN_TERMS,
  payload: { loanTerms },
});

type UpdateLoanTermsErrorAction = {
  type: typeof UPDATE_LOAN_TERMS_ERROR;
  payload: {
    error: string;
  };
  analyticsEvent?: {
    meta: {
      analytics: {
        event: string;
      };
    };
  };
};

export const updateLoanTermsError = (
  error: string,
  analyticsEvent?: string
): UpdateLoanTermsErrorAction => ({
  type: UPDATE_LOAN_TERMS_ERROR,
  payload: { error },
  ...(analyticsEvent
    ? {
        meta: {
          analytics: {
            event: analyticsEvent,
          },
        },
      }
    : {}),
});

export type FetchHomeownerHomeImprovementsAction = {
  type: typeof FETCH_HOMEOWNER_HOME_IMPROVEMENTS;
  payload: {
    slug: string;
    data: HomeImprovementData;
  };
};

export const fetchHomeownerHomeImprovements = (
  slug: string,
  data: FetchHomeownerHomeImprovementsAction['payload']['data']
): FetchHomeownerHomeImprovementsAction => ({
  type: FETCH_HOMEOWNER_HOME_IMPROVEMENTS,
  payload: {
    slug,
    data,
  },
});

export type HomeImprovementSuccessPayloadData = {
  improvements?:
    | null
    | {
        beds: ImprovementDataPoint;
        baths: ImprovementDataPoint;
        addition: ImprovementDataPoint;
        pool: ImprovementDataPoint;
      }
    | {};
  condition?: Condition;
};

type FetchHomeownerHomeImprovementsSuccess = {
  type: typeof FETCH_HOMEOWNER_HOME_IMPROVEMENTS_SUCCESS;
  payload: {
    data: HomeImprovementSuccessPayloadData;
    slug: string;
  };
};

export const fetchHomeownerHomeImprovementsSuccess = (
  slug: string,
  data: HomeImprovementSuccessPayloadData,
  estimateId: string | null
): FetchHomeownerHomeImprovementsSuccess => ({
  type: FETCH_HOMEOWNER_HOME_IMPROVEMENTS_SUCCESS,
  payload: { data, slug },
});

type SendHomeownerHomeImprovementsAnalytics = {
  type: typeof SEND_HOMEOWNER_HOME_IMPROVEMENTS_ANALYTICS;
  payload: {
    data: HomeImprovementSuccessPayloadData;
    slug: string;
  };
  meta: {
    analytics: {
      event: string;
      parentEvent: string;
      props: {
        ch_property_id: string;
        hicData: HomeImprovementSuccessPayloadData;
        estimateId: string | null;
      };
    };
  };
};

export const sendGenericHomeownerHomeImprovementsAnalytics = (
  slug: string,
  data: HomeImprovementSuccessPayloadData,
  estimateId: string | null
): SendHomeownerHomeImprovementsAnalytics => ({
  type: SEND_HOMEOWNER_HOME_IMPROVEMENTS_ANALYTICS,
  payload: { data, slug },
  meta: {
    analytics: {
      event: 'click_owner_improvement_state_update',
      parentEvent: 'click_owner_improvement',
      props: {
        ch_property_id: slug,
        hicData: data,
        estimateId,
      },
    },
  },
});

type ManageSessionStorageUserInputHomeImprovementsCalculationAction = {
  type: typeof MANAGE_SESSION_STORAGE_USER_INPUT_HOME_IMPROVEMENT_CALCULATION;
  payload: {
    activePropertySlug: string;
    sessionStorageKey: string;
    value: any;
    isSet: boolean;
  };
};

export const manageSessionStorageUserInputHomeImprovementsCalculation = (
  activePropertySlug: string,
  sessionStorageKey: string,
  value: any,
  isSet: boolean
): ManageSessionStorageUserInputHomeImprovementsCalculationAction => ({
  type: MANAGE_SESSION_STORAGE_USER_INPUT_HOME_IMPROVEMENT_CALCULATION,
  payload: {
    activePropertySlug,
    sessionStorageKey,
    value,
    isSet,
  },
});

type FetchPropertyValueForecastAction = {
  type: typeof FETCH_PROPERTY_VALUE_FORECAST;
  payload: {
    slug: string;
  };
};

export const fetchPropertyValueForecast = (
  slug: string
): FetchPropertyValueForecastAction => ({
  type: FETCH_PROPERTY_VALUE_FORECAST,
  payload: { slug },
});

type ValueForecastData = {
  block: Block;
};

type FetchPropertyValueForecastSuccessAction = {
  type: typeof FETCH_PROPERTY_VALUE_FORECAST_SUCCESS;
  payload: {
    slug: string;
    propertyValueForecastData: ValueForecastData;
  };
};

export const fetchPropertyValueForecastSuccess = (
  slug: string,
  propertyValueForecastData: ValueForecastData
): FetchPropertyValueForecastSuccessAction => ({
  type: FETCH_PROPERTY_VALUE_FORECAST_SUCCESS,
  payload: { slug, propertyValueForecastData },
});

type SetEditFormToInitAction = {
  type: typeof SET_EDIT_FORM_TO_INIT;
};

export const setEditFormToInit = (): SetEditFormToInitAction => ({
  type: SET_EDIT_FORM_TO_INIT,
});

type ClaimPropertyAction = {
  type: typeof CLAIM_PROPERTY;
  payload: { slug: string };
};

export const claimProperty = (slug: string): ClaimPropertyAction => ({
  type: CLAIM_PROPERTY,
  payload: { slug },
});

type ClaimPropertySuccessAction = {
  type: typeof CLAIM_PROPERTY_SUCCESS;
  payload: {
    propertyData: HomeownerResponse;
  };
};

export const claimPropertySuccess = (
  propertyData: HomeownerResponse
): ClaimPropertySuccessAction => ({
  type: CLAIM_PROPERTY_SUCCESS,
  payload: { propertyData },
});

type ClaimActivePropertyErrorAction = {
  type: typeof CLAIM_ACTIVE_PROPERTY_ERROR;
  payload: {
    error: string;
  };
  meta?: {
    analytics: {
      event: string;
    };
  };
};

export const claimActivePropertyError = (
  error: string,
  analyticsEvent?: string
): ClaimActivePropertyErrorAction => ({
  type: CLAIM_ACTIVE_PROPERTY_ERROR,
  payload: { error },
  ...(analyticsEvent
    ? {
        meta: {
          analytics: {
            event: analyticsEvent,
          },
        },
      }
    : {}),
});

type UpdateHomeownerPropertyDataAction = {
  type: typeof UPDATE_HOMEOWNER_PROPERTY_DATA;
  payload: {
    propertyData: HomeownerResponse;
  };
};

export const updateHomeownerPropertyData = (
  propertyData: HomeownerResponse
): UpdateHomeownerPropertyDataAction => ({
  type: UPDATE_HOMEOWNER_PROPERTY_DATA,
  payload: { propertyData },
});

type ClaimPropertyErrorAction = {
  type: typeof CLAIM_PROPERTY_ERROR;
  meta: {
    toast: {
      message: string;
    };
    analytics?: {
      event: string;
    };
  };
};

export const claimPropertyError = (
  message: string,
  analyticsEvent?: string
): ClaimPropertyErrorAction => ({
  type: CLAIM_PROPERTY_ERROR,
  meta: {
    toast: {
      message,
    },
    ...(analyticsEvent
      ? {
          analytics: {
            event: analyticsEvent,
          },
        }
      : {}),
  },
});

export type RemovePropertyFromClaimedHomesAction = {
  type: typeof REMOVE_PROPERTY_FROM_CLAIMED_HOMES;
  payload: {
    slug: string;
  };
  meta: {
    analytics: {
      event: 'click_owner_manage_dashboard_remove';
      parentEvent: typeof PARENT_EVENTS.CLICK_OWNER_MANAGE;
    };
  };
};

export const removePropertyFromClaimedHomes = (
  slug: string
): RemovePropertyFromClaimedHomesAction => ({
  type: REMOVE_PROPERTY_FROM_CLAIMED_HOMES,
  payload: {
    slug,
  },
  meta: {
    analytics: {
      event: 'click_owner_manage_dashboard_remove',
      parentEvent: PARENT_EVENTS.CLICK_OWNER_MANAGE,
    },
  },
});

type RemovePropertyFromClaimedHomesErrorAction = {
  type: typeof REMOVE_PROPERTY_FROM_CLAIMED_HOMES_ERROR;
  meta: {
    analytics: {
      event: 'toast_error_removal';
    };
    toast: {
      message: string;
    };
  };
};

export const removePropertyFromClaimedHomesError = (
  message: string
): RemovePropertyFromClaimedHomesErrorAction => ({
  type: REMOVE_PROPERTY_FROM_CLAIMED_HOMES_ERROR,
  meta: {
    analytics: {
      event: 'toast_error_removal',
    },
    toast: {
      message,
    },
  },
});

type DeletePropertyFromClaimedHomesStateAction = {
  type: typeof DELETE_PROPERTY_FROM_CLAIMED_HOMES_STATE;
  payload: { slug: string };
  meta: {
    toast: {
      message: string;
    };
  };
};

// TODO: confirm flow for deleting a property from account
export const deletePropertyFromClaimedHomesState = (
  slug: string
): DeletePropertyFromClaimedHomesStateAction => ({
  type: DELETE_PROPERTY_FROM_CLAIMED_HOMES_STATE,
  payload: { slug },
  meta: {
    toast: {
      message: 'This property has been removed from your account',
    },
  },
});

type ResetClaimedHomeFormStatusAction = {
  type: typeof RESET_CLAIMED_HOME_STATUS;
};

export const resetClaimedHomeFormStatus =
  (): ResetClaimedHomeFormStatusAction => ({
    type: RESET_CLAIMED_HOME_STATUS,
  });

type ResetHomeownerManageHomeStateAction = {
  type: typeof RESET_HOMEOWNER_MANAGE_HOMES_STATE;
};

export const resetHomeownerManageHomesState =
  (): ResetHomeownerManageHomeStateAction => ({
    type: RESET_HOMEOWNER_MANAGE_HOMES_STATE,
  });

type ShowEquityUpdateErrorAction = {
  type: typeof SHOW_EQUITY_UPDATE_ERROR;
  meta: {
    toast: {
      message: string;
    };
    analytics: {
      event: 'toast_error_details_for_equity';
    };
  };
};

export const showEquityUpdateError = (): ShowEquityUpdateErrorAction => ({
  type: SHOW_EQUITY_UPDATE_ERROR,
  meta: {
    toast: {
      message:
        'In order to view your estimated equity, please enter Purchase rate, Interest rate, Loan terms, and Loan amount.',
    },
    analytics: {
      event: 'toast_error_details_for_equity',
    },
  },
});

type ClaimHomeIfLoggedInThenRedirectAction = {
  type: typeof CLAIM_HOME_IF_LOGGED_IN_THEN_REDIRECT;
  payload: {
    slug: string;
  };
};

export const claimHomeIfLoggedInThenRedirect = (
  slug: string
): ClaimHomeIfLoggedInThenRedirectAction => ({
  type: CLAIM_HOME_IF_LOGGED_IN_THEN_REDIRECT,
  payload: {
    slug,
  },
});

type SetAvmChartTimeRangeAction = {
  type: typeof SET_AVM_CHART_TIME_RANGE;
  timeRange: {
    time: TimeRange;
  };
};

export const setAvmChartTimeRange = (
  time: TimeRange
): SetAvmChartTimeRangeAction => ({
  type: SET_AVM_CHART_TIME_RANGE,
  timeRange: {
    time,
  },
});

type SetAvmAtTargetTimeOnChartAction = {
  type: typeof SET_AVM_AT_TARGET_TIME_ON_CHART;
  payload: {
    value: number;
  };
};

export const setAvmAtTargetTimeOnChart = (
  value: number
): SetAvmAtTargetTimeOnChartAction => ({
  type: SET_AVM_AT_TARGET_TIME_ON_CHART,
  payload: {
    value,
  },
});

type SetDateAtTargetTimeOnChartAction = {
  type: typeof SET_DATE_AT_TARGET_TIME_ON_CHART;
  payload: {
    date: string | Date | null;
  };
};

export const setDateAtTargetTimeOnChart = (
  date?: string | Date
): SetDateAtTargetTimeOnChartAction => ({
  type: SET_DATE_AT_TARGET_TIME_ON_CHART,
  payload: {
    date: !!date ? date : null,
  },
});

type AddSelectedHomeProjectAction = {
  type: typeof ADD_SELECTED_HOME_PROJECT;
  payload: {
    selectedItemName: string;
    value: number;
    slug: string;
  };
};

export const addSelectedHomeProject = (
  selectedItemName: string,
  value: number,
  slug: string
): AddSelectedHomeProjectAction => ({
  type: ADD_SELECTED_HOME_PROJECT,
  payload: {
    selectedItemName,
    value,
    slug,
  },
});

type DeleteSelectedHomeProjectAction = {
  type: typeof DELETE_SELECTED_HOME_PROJECT;
  payload: {
    selectedItemName: string;
    slug: string;
  };
};

export const deleteSelectedHomeProject = (
  selectedItemName: string,
  slug: string
): DeleteSelectedHomeProjectAction => ({
  type: DELETE_SELECTED_HOME_PROJECT,
  payload: {
    selectedItemName,
    slug,
  },
});

type FetchHomeAdvisorEstimatedCostAction = {
  type: typeof FETCH_HOME_ADVISOR_ESTIMATED_COST;
  payload: {
    taskKey: string;
    slug: string;
  };
};

export const fetchHomeAdvisorEstimatedCost = (
  slug: string,
  taskKey: string
): FetchHomeAdvisorEstimatedCostAction => ({
  type: FETCH_HOME_ADVISOR_ESTIMATED_COST,
  payload: {
    taskKey,
    slug,
  },
});

type FetchHomeAdvisorEstimatedCostSuccessAction = {
  type: typeof FETCH_HOME_ADVISOR_ESTIMATED_COST_SUCCESS;
  payload: {
    slug: string;
    data: {
      min: number;
      avg: number;
      max: number;
    };
  };
};

type EstimatedCostData =
  FetchHomeAdvisorEstimatedCostSuccessAction['payload']['data'];

export const fetchHomeAdvisorEstimatedCostSuccess = (
  slug: string,
  data: EstimatedCostData
): FetchHomeAdvisorEstimatedCostSuccessAction => ({
  type: FETCH_HOME_ADVISOR_ESTIMATED_COST_SUCCESS,
  payload: {
    slug,
    data,
  },
});

type FetchHomeInfoForProFinderAction = {
  type: typeof FETCH_HOME_INFO_FOR_PROFINDER;
  payload: {
    slug: string;
  };
};

export const fetchHomeInfoForProFinder = (
  slug: string
): FetchHomeInfoForProFinderAction => ({
  type: FETCH_HOME_INFO_FOR_PROFINDER,
  payload: {
    slug,
  },
});

type FetchHomeInfoForProFinderSuccessAction = {
  type: typeof FETCH_HOME_INFO_FOR_PROFINDER_SUCCESS;
  payload: {
    slug: string;
    data: {
      propertyLookup: {
        address: ClaimedHomeAddress;
      };
    };
  };
};

type ProFinderHomeAddress =
  FetchHomeInfoForProFinderSuccessAction['payload']['data'];

export const fetchHomeInfoForProFinderSuccess = (
  slug: string,
  data: ProFinderHomeAddress
): FetchHomeInfoForProFinderSuccessAction => ({
  type: FETCH_HOME_INFO_FOR_PROFINDER_SUCCESS,
  payload: {
    slug,
    data,
  },
});

type SubmitProFinderReferralRequestAction = {
  type: typeof SUBMIT_PROFINDER_REFERRAL_REQUEST;
  payload: {
    formData: {
      address_line1: string;
      address_line2: string;
      city: string;
      email: string;
      first_name: string;
      last_name: string;
      phone_number: string;
      state_abbreviation: string;
      task_id: number;
      test_only: boolean;
      zip: string;
      financing: string;
    };
  };
  meta: {
    analytics: {
      parentEvent: string;
      event: string;
      props: {
        formData: ProFinderReferralData;
        estimateId: string | null;
      };
    };
  };
};

type ProFinderReferralData =
  SubmitProFinderReferralRequestAction['payload']['formData'];

export const submitProFinderReferralRequest = (
  formData: ProFinderReferralData,
  estimateId: string | null
): SubmitProFinderReferralRequestAction => ({
  type: SUBMIT_PROFINDER_REFERRAL_REQUEST,
  payload: {
    formData,
  },
  meta: {
    analytics: {
      parentEvent: 'click_owner_profinder',
      event: 'click_owner_profinder_submit',
      props: {
        formData,
        estimateId,
      },
    },
  },
});

type SubmitProFinderReferralRequestSuccessAction = {
  type: typeof SUBMIT_PROFINDER_REFERRAL_REQUEST_SUCCESS;
  payload: {
    projectId: string;
    businesses: {
      name: string;
      rating: number;
      reviews: number;
      business_id: number;
    }[];
  };
};

type SubmitProFinderReferralRequestSuccessArgs = {
  project_id: string;
  businesses: {
    name: string;
    rating: number;
    reviews: number;
    business_id: number;
  }[];
};

export const submitProFinderReferralRequestSuccess = (
  referralData: SubmitProFinderReferralRequestSuccessArgs
): SubmitProFinderReferralRequestSuccessAction => ({
  type: SUBMIT_PROFINDER_REFERRAL_REQUEST_SUCCESS,
  payload: {
    projectId: referralData.project_id,
    businesses: referralData.businesses,
  },
});

type SubmitProFinderReferralRequestErrorAction = {
  type: typeof SUBMIT_PROFINDER_REFERRAL_REQUEST_ERROR;
  payload: {
    error: string;
  };
};

export const submitProFinderReferralRequestError = (
  error: string
): SubmitProFinderReferralRequestErrorAction => ({
  type: SUBMIT_PROFINDER_REFERRAL_REQUEST_ERROR,
  payload: {
    error,
  },
});

type RequestHomeAdvisorContractorQuoteAction = {
  type: typeof REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE;
  payload: {
    projectId: string;
    businessId: number;
  };
};

export const requestHomeAdvisorContractorQuote = (
  projectId: string,
  businessId: number
): RequestHomeAdvisorContractorQuoteAction => ({
  type: REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE,
  payload: {
    projectId,
    businessId,
  },
});

type RequestHomeAdvisorContractorQuoteSuccessAction = {
  type: typeof REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE_SUCCESS;
  payload: {
    businessId: number;
    quoteContact: string;
  };
};

export const requestHomeAdvisorContractorQuoteSuccess = (
  businessId: number,
  quoteContact: string
): RequestHomeAdvisorContractorQuoteSuccessAction => ({
  type: REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE_SUCCESS,
  payload: {
    businessId,
    quoteContact,
  },
});

type RequestHomeAdvisorContractorQuoteErrorAction = {
  type: typeof REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE_ERROR;
  payload: {
    businessId: number;
  };
  meta: {
    toast: {
      message: string;
    };
  };
};

export const requestHomeAdvisorContractorQuoteError = (
  businessId: number
): RequestHomeAdvisorContractorQuoteErrorAction => ({
  type: REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE_ERROR,
  payload: {
    businessId,
  },
  meta: {
    toast: {
      message:
        'There was an error while sending the request. Please try again.',
    },
  },
});

type FetchHomeAdvisorReviewsForBusinessAction = {
  type: typeof FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS;
  payload: {
    businessId: number;
  };
};

export const fetchHomeAdvisorReviewsForBusiness = (
  businessId: number
): FetchHomeAdvisorReviewsForBusinessAction => ({
  type: FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS,
  payload: {
    businessId,
  },
});

type FetchHomeAdvisorReviewsSuccessAction = {
  type: typeof FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS_SUCCESS;
  payload: {
    businessId: number;
    reviews: BusinessReview;
  };
};

export const fetchHomeAdvisorReviewsSuccess = ({
  businessId,
  reviews,
}: FetchHomeAdvisorReviewsSuccessAction['payload']): FetchHomeAdvisorReviewsSuccessAction => ({
  type: FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS_SUCCESS,
  payload: {
    businessId,
    reviews,
  },
});

type FetchHomeAdvisorReviewsErrorAction = {
  type: typeof FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS_ERROR;
  payload: {
    businessId: number;
  };
};

export const fetchHomeAdvisorReviewsError = ({
  businessId,
}: FetchHomeAdvisorReviewsErrorAction['payload']): FetchHomeAdvisorReviewsErrorAction => ({
  type: FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS_ERROR,
  payload: {
    businessId,
  },
});

type SetHomeAdvisorReviewsStatusToSuccessAction = {
  type: typeof SET_HOMEADVISOR_REVIEWS_STATUS_TO_SUCCESS;
};

export const setHomeAdvisorReviewsStatusToSuccess =
  (): SetHomeAdvisorReviewsStatusToSuccessAction => ({
    type: SET_HOMEADVISOR_REVIEWS_STATUS_TO_SUCCESS,
  });

type UpdateProFinderCostEstimatesAction = {
  type: typeof UPDATE_PROFINDER_COST_ESTIMATES;
  payload: {
    id: string;
    min: number;
    max: number;
  };
};

export const updateProFinderCostEstimates = (
  id: string,
  min: number,
  max: number
): UpdateProFinderCostEstimatesAction => ({
  type: UPDATE_PROFINDER_COST_ESTIMATES,
  payload: {
    id,
    min,
    max,
  },
});

type SetProFinderStatusToSuccessAction = {
  type: typeof SET_PROFINDER_STATUS_TO_SUCCESS;
};

export const setProFinderStatusToSuccess =
  (): SetProFinderStatusToSuccessAction => ({
    type: SET_PROFINDER_STATUS_TO_SUCCESS,
  });

export type SetHomeownerPmiModalWasShownAction = {
  type: typeof SET_HOMEOWNER_PMI_MODAL_WAS_SHOWN;
};

export const setHomeownerPmiModalWasShown =
  (): SetHomeownerPmiModalWasShownAction => ({
    type: SET_HOMEOWNER_PMI_MODAL_WAS_SHOWN,
  });

export type SubmitPropensityToSellResponseAction = {
  type: typeof SUBMIT_PROPENSITY_TO_SELL_RESPONSE;
  payload: {
    addressSlug: string;
    interestedInSelling: PropensityToSellResponseValue;
    canContact: boolean;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    propertyData: ReferralPropertyData;
  };
};

export const submitPropensityToSellResponse = ({
  addressSlug,
  interestedInSelling,
  canContact,
  firstName,
  lastName,
  email,
  phone,
  propertyData,
}: SubmitPropensityToSellResponseAction['payload']): SubmitPropensityToSellResponseAction => ({
  type: SUBMIT_PROPENSITY_TO_SELL_RESPONSE,
  payload: {
    addressSlug,
    interestedInSelling,
    canContact,
    firstName,
    lastName,
    email,
    phone,
    propertyData,
  },
});

export type HomeownerAction =
  | FetchHomeownerUserDataAction
  | FetchHomeownerManageHomesDataAction
  | FetchHomeownerManageHomesDataErrorAction
  | FetchHomeownerUserDataSuccessAction
  | FetchHomeownerPropertyPhotosAction
  | FetchHomeownerPropertyPhotosSuccessAction
  | FetchClaimedHomesIfNecessaryAction
  | FetchClaimedHomesAction
  | FetchClaimedHomesSuccessAction
  | FetchClaimedHomesErrorAction
  | SendClaimedHomeDetailsToDbThroughEditHomeFormAction
  | SendClaimedHomeDetailsToDbAction
  | UpdateClaimedHomeFormSuccessAction
  | UpdateClaimedHomeFormErrorAction
  | UpdateClaimedHomeFormAction
  | FetchLoanTermsAction
  | FetchLoanTermsIfNecessaryAction
  | UpdateLoanTermsAction
  | UpdateLoanTermsErrorAction
  | FetchHomeownerHomeImprovementsAction
  | FetchHomeownerHomeImprovementsSuccess
  | ManageSessionStorageUserInputHomeImprovementsCalculationAction
  | FetchPropertyValueForecastAction
  | FetchPropertyValueForecastSuccessAction
  | SetEditFormToInitAction
  | ClaimPropertyAction
  | ClaimPropertySuccessAction
  | ClaimActivePropertyErrorAction
  | UpdateHomeownerPropertyDataAction
  | ClaimPropertyErrorAction
  | RemovePropertyFromClaimedHomesAction
  | RemovePropertyFromClaimedHomesErrorAction
  | DeletePropertyFromClaimedHomesStateAction
  | ResetClaimedHomeFormStatusAction
  | ResetHomeownerManageHomeStateAction
  | ShowEquityUpdateErrorAction
  | ClaimHomeIfLoggedInThenRedirectAction
  | SetAvmChartTimeRangeAction
  | SetAvmAtTargetTimeOnChartAction
  | SetDateAtTargetTimeOnChartAction
  | AddSelectedHomeProjectAction
  | DeleteSelectedHomeProjectAction
  | FetchHomeAdvisorEstimatedCostAction
  | FetchHomeAdvisorEstimatedCostSuccessAction
  | FetchHomeInfoForProFinderAction
  | FetchHomeInfoForProFinderSuccessAction
  | SubmitProFinderReferralRequestAction
  | SubmitProFinderReferralRequestSuccessAction
  | SubmitProFinderReferralRequestErrorAction
  | RequestHomeAdvisorContractorQuoteAction
  | RequestHomeAdvisorContractorQuoteSuccessAction
  | RequestHomeAdvisorContractorQuoteErrorAction
  | FetchHomeAdvisorReviewsForBusinessAction
  | FetchHomeAdvisorReviewsSuccessAction
  | FetchHomeAdvisorReviewsErrorAction
  | SetHomeAdvisorReviewsStatusToSuccessAction
  | UpdateProFinderCostEstimatesAction
  | SetProFinderStatusToSuccessAction
  | SetHomeownerManageHomeStateToLoadingAction
  | FetchHomeownerManageSingleHomeSuccessAction
  | SetHomeownerPmiModalWasShownAction
  | FetchHomeownerPropertyIfNecessaryAction
  | ResetHomeownerPropDetailsAction
  | FetchHomeownerPropDetailsSuccessAction
  | FetchHomeownerPropDetailsErrorAction
  | SendHomeownerHomeImprovementsAnalytics;
