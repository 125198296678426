import { CONFIG_MAP } from '../config';

const {
  consumerAPI,
  consumerAppHostname,
  graphQlURL,
  beaconURL,
  comehomeBrandedHostname,
  coveredInsuranceIframeUrl,
  vectilesURL,
  sentryURL,
  cobrandAnalyticsReportingScriptUrls,
  cobrandOauthSignInRedirectUrl,
  cobrandSamlSignInRedirectUrl,
  allowFeatureFlagAndCustomizationDataJSONOverride,
  stripePublishableKey,
  stripePricingTableId,
  stripeBillingLink,
} = CONFIG_MAP;

export default {
  APP_HOSTNAME: consumerAppHostname,
  APPLICATION_NAME: 'Consumer',
  ANALYTICS_APP_NAME: 'consumer_web',
  CONFIRM_URL: `${consumerAppHostname}/confirm-user?token={}`,
  COBRAND_ANALYTICS_REPORTING_SCRIPT_URLS: cobrandAnalyticsReportingScriptUrls,
  COBRAND_OAUTH_SIGN_IN_REDIRECT_URL: `${cobrandOauthSignInRedirectUrl}`,
  COBRAND_SAML_SIGN_IN_REDIRECT_URL: `${cobrandSamlSignInRedirectUrl}`,
  COMEHOME_BRANDED_HOSTNAME: `${comehomeBrandedHostname}`,
  COVERED_INSURANCE_IFRAME_URL: `${coveredInsuranceIframeUrl}`,
  BEACON_URL: beaconURL,
  GEOTILE_DATA_URL: `${vectilesURL}/tiles`,
  GEOLOCATION_ENDPOINT: `${consumerAppHostname}/geolocation`,
  AUTH_TOKEN_REFRESH_ENDPOINT: `${consumerAppHostname}/auth-token-refresh`,
  PARTNER_ENDPOINT: `${consumerAPI}/v1/partner`,
  PRIVACY_POLICY_PROXY_URL: `${consumerAppHostname}/privacy-policy-content`,
  TERMS_OF_USE_PROXY_URL: `${consumerAppHostname}/terms-of-use-content`,
  PRIVACY_POLICY_CONTENT_URL:
    'https://www.housecanary.com/legal/comehome-privacy-policy',
  CONSUMER_API_URL: `${consumerAPI}/v2`,
  SENTRY_URL: sentryURL,
  GOOGLE_API_KEY: 'AIzaSyDDSEoExBbt0UuCkgQv84UvMsCIE00Ph-s',
  GOOGLE_STREETVIEW_URL: 'https://www.google.com/maps/embed/v1/streetview',
  GOOGLE_STREETVIEW_IMAGE_ENDPOINT: `${consumerAPI}/goog/sv`,
  GOOGLE_GEOCODE_URL: 'https://maps.googleapis.com/maps/api/geocode/json',
  GRAPH_QL_URL: `${graphQlURL}/graphql`,
  MAP_STYLE_URL_PRIMARY:
    'mapbox://styles/matt-hc/cjnute79e53qp2sp99szmei5u?optimize=true',
  MAP_STYLE_URL_SATELLITE_SIMPLE:
    'mapbox://styles/matt-hc/cj6fc91dr1sma2smhcq9qckg8?optimize=true',
  MAPBOX_ACCESS_TOKEN:
    'pk.eyJ1IjoibWF0dC1oYyIsImEiOiJ4ZHRkby1jIn0.6AE5Ob825Mlcaf_lIU66eA',
  MAPBOX_STATIC_TILES: {
    DOMAIN: 'https://api.mapbox.com',
    TOKEN: 'pk.eyJ1IjoibWF0dC1oYyIsImEiOiJ4ZHRkby1jIn0.6AE5Ob825Mlcaf_lIU66eA',
    USERNAME: 'matt-hc',
    MAP_ID: 'cj82261bl9du22sq8i3rkc905',
    FULL_FEATURED_MAP_ID: 'cjnute79e53qp2sp99szmei5u',
    CH_JP_BLUE_PIN_URL:
      'https://hc-pro-images.s3-us-west-2.amazonaws.com/app/ch-jp-map-pin-for-sale.png',
    HC_BLUE_PIN_URL:
      'https://hc-pro-images.s3-us-west-2.amazonaws.com/app/ch-map-pin-for-sale.png',
    LENDCO_GREEN_PIN_URL:
      'https://hc-pro-images.s3-us-west-2.amazonaws.com/app/ch-lendco-map-pin-for-sale.png',
    OFF_MARKET_PIN_URL:
      'https://hc-pro-images.s3-us-west-2.amazonaws.com/app/ch-map-pin-off-market.png',
  },
  /** Share URLS */
  FACEBOOK_SHARE_URL: 'https://www.facebook.com/sharer/sharer.php',
  TWITTER_SHARE_URL: 'https://twitter.com/intent/tweet',
  PINTEREST_SHARE_URL: 'http://pinterest.com/pin/create/button/',
  ITUNES_APP_URL:
    'https://itunes.apple.com/us/app/comehome-by-housecanary/id1289334446?ls=1&mt=8',
  ANDROID_APP_URL:
    'https://play.google.com/store/apps/details?id=com.housecanary.housecanary',
  SOCIAL_IMAGES_URLS: {
    OG: 'https://www.housecanary.com/assets/images/general-social-share-og.jpg',
    TWITTER:
      'https://www.housecanary.com/assets/images/general-social-share-twitter.jpg',
  },
  PROPERTY_GRAPH_URL: graphQlURL,
  DRE_NOTICE: 'California DRE #02113022',
  HC_BROKERAGE_INFO_URL: 'https://www.housecanary.com/brokerage',
  ALLOW_FEATURE_FLAG_AND_CUSTOMIZATION_DATA_JSON_OVERRIDE:
    allowFeatureFlagAndCustomizationDataJSONOverride,
  STRIPE_PUBLISHABLE_KEY: stripePublishableKey,
  STRIPE_PRICING_TABLE_ID: stripePricingTableId,
  STRIPE_BILLING_LINK: stripeBillingLink,
  OUTBOUND_CTA_URL: `${consumerAPI}/v2/outbound`,
};
