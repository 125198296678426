/**
 * The code for this component is taken from https://github.com/adcentury/react-mobile-picker
 * and adjusted to fit the needs of our app
 */
import classNames from 'classnames';
import React from 'react';
import { isEqual } from 'lodash';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { key } from '@client/utils/component.utils';

import Tooltip from '@client/components/generic/Tooltip';
import defaultTheme from '@client/css-modules/ScrollPicker.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { FilterPickerValue } from '@client/store/types/filters';

/* We need this flag to persist for the entire app session */
let hasSeenTooltipConfirmationOnce = false;
const DEFAULT_ITEM_HEIGHT = 36;

const isTouchEvent = (event: MouseEvent | TouchEvent): event is TouchEvent =>
  !!(event as TouchEvent).targetTouches;

type TooltipConfirmationProps = {
  confirmationText?: string;
  onConfirm: () => void;
  onCancel: () => void;
  theme: Theme;
};
const TooltipConfirmation: React.FC<TooltipConfirmationProps> = ({
  confirmationText,
  onConfirm,
  onCancel,
  theme,
}) => {
  const { activeBuyingPowerColor } = useCobrandStyles();
  return (
    <div className={theme.TooltipConfirmation}>
      <div className={theme.TooltipConfirmationContent}>{confirmationText}</div>
      <div className={theme.TooltipConfirmationButtons}>
        <button
          className={theme.TooltipConfirmationButtonCancel}
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          className={theme.TooltipConfirmationButtonOk}
          style={{ color: activeBuyingPowerColor }}
          onClick={onConfirm}
        >
          Ok
        </button>
      </div>
    </div>
  );
};

type PickerColumnProps = {
  options: FilterPickerValue[];
  name: string;
  value: FilterPickerValue;
  itemHeight: number;
  columnHeight?: number;
  labelFormatter: (option, name) => string | JSX.Element;
  onChange: (name: string, value: FilterPickerValue) => void;
  onClick?: (name: string, value: FilterPickerValue) => void;
  confirmIfGreaterThanValue?: number;
  handleShowConfirmationTooltip: () => void;
  theme: Theme;
};

type PickerColumnState = {
  startTouchY: number;
  startScrollerTranslate: number;
  minTranslate: number;
  maxTranslate: number;
  isFocusType: boolean;
};

class PickerColumn extends React.Component<
  PickerColumnProps,
  PickerColumnState
> {
  constructor(props: PickerColumnProps) {
    super(props);
    this.state = {
      isFocusType: false,
      startTouchY: 0,
      startScrollerTranslate: 0,
      ...this.computeTranslate(props),
    };
  }

  animationFrame: number | null = null;
  isClicked: boolean = false;
  isMoving: boolean = false;
  handleTouchEndTimeout: number | null = null;
  scrollerTranslate: number = 0;
  prevActiveIndex: number = this.props.value
    ? this.props.options.indexOf(this.props.value)
    : 0;
  pickerColumnEle: HTMLElement | null = null;
  pickerParentEle: HTMLElement | null = null;

  static defaultProps = {
    labelFormatter: (v) => v,
  };

  componentDidMount() {
    if (this.pickerParentEle) {
      /* These need to be bound manually since React automatically makes them passive. We do want
       * to be able to preventDefault, so we need them not to be passive */
      this.pickerParentEle.addEventListener('touchmove', this.handleTouchMove);
      this.pickerParentEle.addEventListener(
        'touchstart',
        this.handleTouchStart
      );
    }
  }

  componentDidUpdate(prevProps: PickerColumnProps) {
    const { options } = this.props;
    if (this.isMoving) {
      return;
    }
    /* When a min scroll picker causes the total available options to change in the max scroll
     * picker or vice-versa, we want to keep the scroll picker whose options are changing on the
     * current option with no transition time */
    if (!isEqual(options, prevProps.options)) {
      this.setState(this.computeTranslate(this.props));
      this.setInitialScrollerTranslate();
    }
  }

  componentWillUnmount() {
    if (this.pickerParentEle) {
      this.pickerParentEle.removeEventListener(
        'touchmove',
        this.handleTouchMove
      );
      this.pickerParentEle.removeEventListener(
        'touchstart',
        this.handleTouchStart
      );
    }
    this.pickerParentEle = null;
    document.removeEventListener('pointermove', this.handleTouchMove);
    document.removeEventListener('pointerup', this.handleTouchEnd);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('pointercancel', this.handleTouchCancel);
    if (this.handleTouchEndTimeout) {
      window.clearTimeout(this.handleTouchEndTimeout);
    }
    if (this.animationFrame) {
      window.cancelAnimationFrame(this.animationFrame);
    }
  }

  computeTranslate = (
    props: PickerColumnProps
  ): { minTranslate: number; maxTranslate: number } => {
    const { options, itemHeight, columnHeight } = props;
    if (columnHeight) {
      return {
        minTranslate:
          columnHeight / 2 - itemHeight * options.length + itemHeight / 2,
        maxTranslate: columnHeight / 2 - itemHeight / 2,
      };
    } else {
      return {
        minTranslate: 0,
        maxTranslate: 0,
      };
    }
  };

  onValueSelected = (newValue: FilterPickerValue): void => {
    this.props.onChange(this.props.name, newValue);
  };

  handleTouchStart = (event: MouseEvent | TouchEvent): void => {
    /*
     * NOTE: If focus gets triggered, we know this is a Voiceover event,
     * and do not want to use the touch event handling
     */
    if (this.state.isFocusType) {
      return;
    }

    event.preventDefault();

    this.isClicked = true;
    const startTouchY = isTouchEvent(event)
      ? event.targetTouches[0].pageY
      : event.pageY;
    this.setState({
      startTouchY,
      startScrollerTranslate: this.scrollerTranslate,
    });
    document.addEventListener('pointermove', this.handleTouchMove);
    document.addEventListener('pointerup', this.handleTouchEnd);
    document.addEventListener('touchend', this.handleTouchEnd);
    document.addEventListener('pointercancel', this.handleTouchCancel);
  };

  handleTouchMove = (event: MouseEvent | TouchEvent): void => {
    /*
     * NOTE: If focus gets triggered, we know this is a Voiceover event,
     * and do not want to use the touch event handling
     */
    if (this.state.isFocusType) {
      return;
    }

    event.preventDefault();

    const { startTouchY, startScrollerTranslate, minTranslate, maxTranslate } =
      this.state;

    if (this.isClicked) {
      const touchY = isTouchEvent(event)
        ? event.targetTouches[0].pageY
        : event.pageY;
      if (!this.isMoving) {
        this.isMoving = true;
        return;
      }
      let nextScrollerTranslate = startScrollerTranslate + touchY - startTouchY;
      if (nextScrollerTranslate < minTranslate) {
        nextScrollerTranslate =
          minTranslate - Math.pow(minTranslate - nextScrollerTranslate, 0.8);
      } else if (nextScrollerTranslate > maxTranslate) {
        nextScrollerTranslate =
          maxTranslate + Math.pow(nextScrollerTranslate - maxTranslate, 0.8);
      }
      this.setScrollerTranslate(nextScrollerTranslate);
    }
  };

  handleTouchEnd = (): void => {
    /*
     * NOTE: If focus gets triggered, we know this is a Voiceover event,
     * and do not want to use the touch event handling
     */
    if (this.state.isFocusType) {
      return;
    }

    const { confirmIfGreaterThanValue, handleShowConfirmationTooltip } =
      this.props;
    if (!this.isMoving) {
      return;
    }
    this.isMoving = false;
    this.isClicked = false;
    this.setState({
      startTouchY: 0,
      startScrollerTranslate: 0,
    });
    this.handleTouchEndTimeout = window.setTimeout(() => {
      const { options, itemHeight } = this.props;
      const { minTranslate, maxTranslate } = this.state;
      let activeIndex;
      if (this.scrollerTranslate > maxTranslate) {
        activeIndex = 0;
      } else if (this.scrollerTranslate < minTranslate) {
        activeIndex = options.length - 1;
      } else {
        activeIndex = -Math.round(
          (this.scrollerTranslate - maxTranslate) / itemHeight
        );
      }
      this.onValueSelected(options[activeIndex]);
      if (
        !hasSeenTooltipConfirmationOnce &&
        confirmIfGreaterThanValue &&
        typeof activeIndex === 'number' &&
        typeof this.prevActiveIndex === 'number' &&
        typeof options[activeIndex] === 'number' &&
        typeof confirmIfGreaterThanValue === 'number' &&
        (options[activeIndex] as number) >
          (confirmIfGreaterThanValue as number) &&
        /* Only show confirmation if user is increasing the value */
        activeIndex > this.prevActiveIndex
      ) {
        handleShowConfirmationTooltip();
      }
      this.setInitialScrollerTranslate(activeIndex, 100);
      this.prevActiveIndex = activeIndex;
    }, 0);
    document.removeEventListener('pointermove', this.handleTouchMove);
    document.removeEventListener('pointerup', this.handleTouchEnd);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('pointercancel', this.handleTouchCancel);
  };

  handleTouchCancel = (): undefined | void => {
    /*
     * NOTE: If focus gets triggered, we know this is a Voiceover event,
     * and do not want to use the touch event handling
     */
    if (this.state.isFocusType) {
      return;
    }

    if (!this.isMoving || !this.isClicked) {
      return;
    } else {
      this.isMoving = false;
      this.isClicked = false;
      this.setState({
        startTouchY: 0,
        startScrollerTranslate: 0,
      });
      this.setScrollerTranslate(this.state.startScrollerTranslate);
    }
  };

  handleItemClick = (option: FilterPickerValue): void => {
    const { name, onClick, value } = this.props;
    if (option !== value) {
      this.onValueSelected(option);
    } else {
      onClick && onClick(name, value);
    }
  };

  handleItemKeyDown = (e) => {
    if (!this.state.isFocusType) {
      this.setState({ isFocusType: true });
    }

    if (key.isReturn(e.key) || key.isSpace(e.key)) {
      const selectedValue = e.target.dataset.value;
      this.handleItemClick(selectedValue);
    }
  };

  renderItems(): JSX.Element[] {
    const { options, itemHeight, value, theme, labelFormatter, name } =
      this.props;

    return options.map((option: FilterPickerValue, index) => {
      const style = {
        height: itemHeight + 'px',
        lineHeight: itemHeight + 'px',
      };
      const label = labelFormatter(option, name);
      const isSelectedValue = option === value;
      return (
        <li
          id={this.createOptionId(option)}
          aria-selected={isSelectedValue}
          role="option"
          data-value={`${option}`}
          key={name + '-' + option}
          className={classNames(theme.pickerItem, {
            [theme.pickerItemSelected]: isSelectedValue,
          })}
          style={style}
          tabIndex={isSelectedValue ? 0 : -1}
          onKeyDown={this.handleItemKeyDown}
          onPointerDown={() => this.handleItemClick(option)}
        >
          {label}
        </li>
      );
    });
  }

  createOptionId = (option: FilterPickerValue) =>
    `filter-option-${this.props.name}-${option.toString()}`;

  pickerColumnRef = (ele: HTMLUListElement | null) => {
    if (ele) {
      this.pickerColumnEle = ele;
      this.setInitialScrollerTranslate();
    }
  };
  pickerParentRef = (ele: HTMLDivElement | null) => {
    if (ele) {
      this.pickerParentEle = ele;
    }
  };

  setInitialScrollerTranslate = (
    activeIndex?: number,
    transitionDuration?: number
  ): void => {
    const { options, value, itemHeight, columnHeight } = this.props;
    const transitionDur = transitionDuration || 0;
    let selectedIndex =
      typeof activeIndex === 'undefined' ? options.indexOf(value) : activeIndex;

    if (selectedIndex < 0) {
      console.warn(
        'Warning: "' +
          this.props.name +
          '" doesn\'t contain an option of "' +
          value +
          '".'
      );
      this.onValueSelected(options[0]);
      selectedIndex = 0;
    }

    if (columnHeight) {
      this.setScrollerTranslate(
        columnHeight / 2 - itemHeight / 2 - selectedIndex * itemHeight,
        transitionDur
      );
    }
  };

  setScrollerTranslate = (
    translate: number,
    transitionDuration = 100
  ): void => {
    const translateString = `translate3d(0, ${translate}px, 0)`;

    this.scrollerTranslate = translate;
    if (this.pickerColumnEle) {
      this.animationFrame = window.requestAnimationFrame(() => {
        this.pickerColumnEle!.style.transitionDuration = `${transitionDuration}ms`;
        this.pickerColumnEle!.style.transform = translateString;
      });
    }
  };

  handleColumnKeyDown = (e) => {
    if (key.isArrowDown(e.key) || key.isArrowUp(e.key)) {
      const { options, value } = this.props;
      const currIdx = options.indexOf(value);
      const idxShiftAmount = key.isArrowUp(e.key) ? -1 : 1;
      const newIdx = currIdx + idxShiftAmount;
      const effectiveNewIdx =
        newIdx < 0
          ? 0
          : newIdx > options.length - 1
          ? options.length - 1
          : newIdx;

      this.onValueSelected(options[effectiveNewIdx]);
      this.setInitialScrollerTranslate(effectiveNewIdx, 100);
    }
  };

  render() {
    const { theme, value, name } = this.props;

    return (
      <div className={theme.pickerColumn} ref={this.pickerParentRef}>
        <ul
          role="listbox"
          tabIndex={-1}
          aria-label={name}
          aria-activedescendant={this.createOptionId(value)}
          className={theme.pickerScroller}
          onKeyDown={this.handleColumnKeyDown}
          ref={this.pickerColumnRef}
        >
          {this.renderItems()}
        </ul>
      </div>
    );
  }
}

type PickerProps = {
  labelFormatter: (
    option: FilterPickerValue,
    name: string
  ) => string | JSX.Element;
  optionGroups: {
    min?: FilterPickerValue[];
    max?: FilterPickerValue[];
  };
  valueGroups: {
    min?: FilterPickerValue;
    max?: FilterPickerValue;
  };
  onChange: (name: string, value: FilterPickerValue) => void;
  onClick?: (name: string, value: FilterPickerValue) => void;
  itemHeight?: number;
  height?: number;
  confirmIfGreaterThanValue?: number;
  confirmForOptionGroup?: string;
  confirmationText?: string;
  onConfirmationConfirm?: () => void;
  onConfirmationCancel?: () => void;
  ariaLabelledBy: string;
  theme: Theme;
  dataHcName: string;
};

type PickerState = {
  isShowingConfirmTooltip: boolean;
};

class Picker extends React.Component<PickerProps, PickerState> {
  static defaultProps = {
    onClick: () => {},
    itemHeight: DEFAULT_ITEM_HEIGHT,
    height: 160,
  };

  state: PickerState = {
    isShowingConfirmTooltip: false,
  };

  handleShowConfirmationTooltip = (): void => {
    this.setState({ isShowingConfirmTooltip: true });
  };

  handleConfirmationConfirm = (): void => {
    const { onConfirmationConfirm } = this.props;

    this.setState({ isShowingConfirmTooltip: false });
    if (onConfirmationConfirm) {
      onConfirmationConfirm();
    }
    /* Prevents the confirmation from being shown again during the app session */
    hasSeenTooltipConfirmationOnce = true;
  };

  handleConfirmationCancel = (): void => {
    const { onConfirmationCancel } = this.props;

    if (onConfirmationCancel) {
      onConfirmationCancel();
    }
  };

  renderInner(): JSX.Element {
    const {
      optionGroups,
      valueGroups,
      itemHeight,
      height,
      onChange,
      onClick,
      theme,
      labelFormatter,
      ariaLabelledBy,
      confirmIfGreaterThanValue,
      confirmForOptionGroup,
    } = this.props;
    const effectiveItemHeight = itemHeight || DEFAULT_ITEM_HEIGHT;
    const highlightStyle = {
      height: itemHeight,
      marginTop: -(effectiveItemHeight / 2),
    };
    const columnNodes: JSX.Element[] = [];

    for (let name in optionGroups) {
      columnNodes.push(
        <PickerColumn
          labelFormatter={labelFormatter}
          theme={theme}
          key={name}
          name={name}
          options={optionGroups[name]}
          value={valueGroups[name]}
          itemHeight={effectiveItemHeight}
          columnHeight={height}
          onChange={onChange}
          onClick={onClick}
          confirmIfGreaterThanValue={
            confirmForOptionGroup === name
              ? confirmIfGreaterThanValue
              : undefined
          }
          handleShowConfirmationTooltip={this.handleShowConfirmationTooltip}
        />
      );
    }
    return (
      <div className={theme.pickerInner} aria-labelledby={ariaLabelledBy}>
        {columnNodes}
        <div className={theme.pickerHighlight} style={highlightStyle} />
      </div>
    );
  }

  render() {
    const { theme, height, confirmationText, dataHcName } = this.props;
    const { isShowingConfirmTooltip } = this.state;
    const style = {
      height: height,
    };

    return (
      <div
        className={theme.pickerContainer}
        style={style}
        data-hc-name={dataHcName}
      >
        {this.renderInner()}
        <div className={theme.TooltipContainer}>
          <div className={theme.TooltipContainerSection} />
          <div className={theme.TooltipContainerSection}>
            {isShowingConfirmTooltip && (
              <Tooltip
                dataHcName={`${dataHcName}-tooltip`}
                theme={theme}
                trigger={<div />}
                offset={[0, -70]}
                content={
                  <TooltipConfirmation
                    theme={theme}
                    confirmationText={confirmationText}
                    onConfirm={this.handleConfirmationConfirm}
                    onCancel={this.handleConfirmationCancel}
                  />
                }
                showOnInit
                shouldHideCloseButton
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default themr('Picker', defaultTheme)(Picker);
