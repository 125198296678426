import { View } from '@client/routes/constants';
import { put, select } from 'redux-saga/effects';
import { getUtmState } from '@client/store/selectors/tracking.selectors';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import { watchEvery } from '@client/utils/saga.utils';
import { reportEvent } from '@client/store/actions/analytics.actions';

function* reportPageViewFromEmailSource(action) {
  const utmParams = yield select(getUtmState);
  const { isAppInit } = action;
  const {
    utm_source: partner,
    utm_campaign: notification_type,
    utm_medium: medium,
  } = utmParams;
  const currentView = yield select(getCurrentView);
  const shouldSendAnalytics = medium === 'email' && isAppInit;
  if (shouldSendAnalytics) {
    switch (currentView) {
      case View.TERMS_OF_USE:
        yield put(
          reportEvent('click_email_terms_of_use', '', {
            partner,
            notification_type,
          })
        );
        break;
      case View.PRIVACY_POLICY:
        yield put(
          reportEvent('click_email_privacy', '', { partner, notification_type })
        );
        break;
      case View.UNSUBSCRIBE:
        yield put(
          reportEvent('click_email_unsubscribe', '', {
            partner,
            notification_type,
          })
        );
        break;
      case View.FEEDBACK:
        yield put(
          reportEvent('click_email_contact', '', { partner, notification_type })
        );
        break;
      default:
        yield put(
          reportEvent('click_email_comehome_link', '', {
            partner,
            notification_type,
          })
        );
        break;
    }
  }
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    REPORT_PAGEVIEW: reportPageViewFromEmailSource,
  });
};
