import React from 'react';
import {
  punctuateStreetAddress,
  punctuateUnit,
} from '@client/utils/string.utils';
import { PropertyPageAddressProps } from '@client/store/types/cobranded-components/property-page-address';

const PropertyPageAddress: React.FunctionComponent<
  PropertyPageAddressProps
> = ({ address }) => {
  const { streetAddress, unit, city, state, zipcode } = address;

  return (
    <>
      {punctuateStreetAddress(streetAddress)}
      {unit && `, ${punctuateUnit(unit)}`}, {city}, {state} {zipcode}
    </>
  );
};

export default PropertyPageAddress;
