import { STATES_ABRREV_ARRAY } from '@client/services/states.value';
import { stripOutNonNumber } from '@client/utils/string.utils';
import {
  EMAIL_TEST,
  PHONE_TEST,
  CITY_TEST,
  ZIPCODE_TEST,
  DIGIT,
} from '@client/utils/regex.utils';

/**
 * Checks if a string contains a valid US phone number
 * Will return true if it contains only numbers, spaces, dashes, or parenthesis
 * and is either a 10 digit phone number or 11 digits, but with 1 as the first number
 */
export const isValidPhoneNumber = (string: string): boolean => {
  if (!string) {
    return false;
  }
  const numLength = string.replace(DIGIT, '').length;
  const firstNumberIsOne = string.charAt(0) === '1';

  if (firstNumberIsOne) {
    return !PHONE_TEST.test(string) && numLength === 11;
  } else {
    return !PHONE_TEST.test(string) && numLength === 10;
  }
};

/**
 * Validates a single email string
 * @param  {string} email string to validate
 * @return {boolean} true if valid, false if not and empty string
 */
export const isValidEmailAddress = (email: string): boolean => {
  return !email ? false : EMAIL_TEST.test(email);
};

export const isPhoneNumberStandard = (
  phone?: string | number | null
): boolean => {
  if (!phone) {
    return false;
  }

  let strippedLength = stripOutNonNumber(`${phone}`).length;
  return strippedLength === 10 || strippedLength === 11;
};

export const isValidState = (val: string): boolean =>
  STATES_ABRREV_ARRAY.includes(val);

export const isValidZipcode = (val: string): boolean => {
  return val ? ZIPCODE_TEST.test(val) : true;
};

/**
 * City name must not contain special characters.
 * Regex test gives true if city name has special characters in it
 */
export const isValidCity = (val: string): boolean => {
  return val ? !CITY_TEST.test(val) : false;
};
