import React, { useEffect } from 'react';
import theme from '@client/css-modules/AdminPage.css';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import ThemedClickableLogo from '@client/components/ClickableLogo';
import { View, VIEW_PATHS } from '@client/routes/constants';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentRoute } from '@src/redux-saga-router-plus/selectors';
import RouterLink from '../RouterLink';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import ComeHomeHeaderLogoCobranded from '@client/components/ComeHomeHeaderLogo/ComeHomeHeaderLogoCobranded';
import ComeHomeHeaderLogoByHouseCanaryCobranded from '@client/components/ComeHomeHeaderLogo/ComeHomeHeaderLogoByHouseCanaryCobranded';

const dataHcName = 'AdminPageNavigation';

const SidebarNav = ({ theme }: { theme: Theme }) => {
  const dispatch = useDispatch();
  const currentRoute = useSelector(getCurrentRoute);
  const currentPath = currentRoute.path;
  const isHomepageEnabled = useSelector(getIsFeatureEnabled('homepage'));
  const handleRouteChange = () => {
    dispatch(
      routeChange({ view: isHomepageEnabled ? View.HOMEPAGE : View.SEARCH })
    );
  };

  useEffect(() => {
    /** Redirect from /admin to /admin/analytics until login exists on /admin */
    if (currentPath === VIEW_PATHS[View.ADMIN]) {
      dispatch(routeChange({ view: View.ADMIN_ANALYTICS }));
    }
  }, [currentPath, dispatch]);

  return (
    <nav className={theme.Sidebar} data-hc-name={dataHcName}>
      <section className={theme.Logo}>
        <ThemedClickableLogo
          theme={theme}
          dataHcName={`${dataHcName}-logo`}
          Logo={ComeHomeHeaderLogoCobranded}
          LogoByHouseCanary={ComeHomeHeaderLogoByHouseCanaryCobranded}
        />
      </section>
      <section className={theme.Nav} data-hc-name={`${dataHcName}-table-list`}>
        <h3>Administrator</h3>
        <RouterLink
          className={
            currentPath === VIEW_PATHS[View.ADMIN_ANALYTICS]
              ? theme.ActiveButton
              : ''
          }
          view={View.ADMIN_ANALYTICS}
          data-hc-name={`${dataHcName}-analytics-link`}
        >
          Analytics
        </RouterLink>
        {/* Commenting out for now as requested here
        https://housecanary.atlassian.net/browse/LST-510  */}
        {/* <RouterLink
          className={
            currentPath === ADMIN_VIEW_PATHS[VIEWS.ADMIN_ROI]
              ? theme.ActiveButton
              : ''
          }
          view={VIEWS.ADMIN_ROI}
          data-hc-name={`${dataHcName}-roi-link`}
        >
          ROI
        </RouterLink> */}
        <RouterLink
          className={
            currentPath === VIEW_PATHS[View.ADMIN_API] ? theme.ActiveButton : ''
          }
          view={View.ADMIN_API}
          data-hc-name={`${dataHcName}-api-link`}
        >
          API
        </RouterLink>
        <RouterLink
          className={
            currentPath === VIEW_PATHS[View.ADMIN_SUPPORT]
              ? theme.ActiveButton
              : ''
          }
          view={View.ADMIN_SUPPORT}
          data-hc-name={`${dataHcName}-support-link`}
        >
          Support
        </RouterLink>
      </section>
      <section className={theme.Actions}>
        <button
          className={theme.PrimaryButton}
          onClick={handleRouteChange}
          data-hc-name={`${dataHcName}-homepage-link`}
        >
          Back to ComeHome
        </button>
      </section>
    </nav>
  );
};

const ThemedSidebarNav = themr('Header', theme)(SidebarNav);

export default ThemedSidebarNav;
