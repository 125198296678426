import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import ScrollToTop from '@client/components/ScrollToTop';
import OpenHouseInfo from '@client/components/OpenHouseInfo';
import LoadingSection from '@client/components/generic/LoadingSection';
import AdditionalHomeDetails from '@client/components/AdditionalHomeDetails';
import MLSAttributionPDPNWMLSException from '@client/components/MLSAttributionPDPNWMLSException';
import PDPRightRailCardCobranded from '@client/components/PDPRightRailCard/PDPRightRailCardCobranded';
import PDPLoanOfficerAdMobileCobranded from '@client/components/PDPLoanOfficerAdMobile/PDPLoanOfficerAdMobileCobranded';
import MobileStickyFooter from '@client/components/MobileStickyFooter/MobileStickyFooter';
import ScrollTrackedComponent from '@client/hocs/track-scroll-position';
import Footer from '@client/containers/footer.container';
import ListingInfoContainer from '@client/containers/listing-info.container';
import CarouselRowContainer from '@client/containers/carousel-row.container';
import PropertyIntroContainer from '@client/containers/property-intro.container';
import NearbyListingsContainer from '@client/containers/nearby-listings.container';
import MapPropertyPageContainer from '@client/containers/map-property-page.container';
import FindAgentBanner from '@client/components/FindAgent/FindAgentBanner';
import BreakoutSectionsContainer from '@client/containers/breakout-sections.container';
import HomeownerUpsellAdContainer from '@client/containers/homeowner-upsell-ad.container';
import AvmBreakdownVerticalContainer from '@client/containers/avm-breakdown-vertical.container';
import { PropertyPageMobileContentsProps } from '@client/store/types/cobranded-components/property-page';
import theme from '@client/css-modules/PropertyPageMobileContents.css';
import ConditionalFeature from '@client/components/ConditionalFeature';
import GrantProgramPDPBanner from '@client/components/GrantProgram/GrantProgramPDPBanner';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { getPropertyDetailPageConfig } from '@client/store/selectors/cobranding.selectors';
import CustomPropertyBanner from '../CustomPropertyBanner';

/**
 * Contains the contents displayed within the PDP on mobile. Used in both `PropertyPage` and `PropertyPageModal`,
 * and is also the left-hand column of `PropertyPageTabletContents`.
 */
const PropertyPageMobileContents: React.FC<PropertyPageMobileContentsProps> = ({
  isPDPDataLoaded,
  isActiveListing,
  shouldShowHomeownerAd,
  isTabletView,
  hidePDPRightRailCard,
  isCanaryUIFeatureEnabled,
  isReferralServicesEnabled,
  isHidingAvmBreakdown,
}) => {
  const isYourTeamEnabled = useSelector(getIsFeatureEnabled('your_team'));
  const isCtaCleanupEnabled = useSelector(getIsFeatureEnabled('cta_cleanup'));
  const isMobileStickyFooterEnabled = useSelector(
    getIsFeatureEnabled('pdp_sticky_footer_mobile')
  );
  const lenderCTAForwardedRef = useRef<HTMLDivElement>(null);
  const [rightRailCTAPosition, setRightRailCTAPosition] = useState(0);
  const propertyPageData = useSelector(getPropertyDetailPageConfig)
  const showCustomBanner = propertyPageData.bannerHeadline && propertyPageData.bannerButtonLabel && propertyPageData.bannerDestinationUrl

  return (
    <div className={theme.PropertyPageMobileContents}>
      {!isTabletView && (
        <CarouselRowContainer theme={theme} useFullWidthImage />
      )}
      <MLSAttributionPDPNWMLSException />
      <PropertyIntroContainer
        theme={theme}
        isShowingCondensedPropertyInfo
        isShowingDaysOnMarket
      />
      {!hidePDPRightRailCard && (
        <ScrollTrackedComponent
          onPositionChange={() => {
            const ctaTop =
              lenderCTAForwardedRef.current?.getBoundingClientRect().top;
            if (ctaTop) {
              setRightRailCTAPosition(ctaTop);
            }
          }}
          rateLimitStrategy="throttle"
          rateLimitAmount={300}
        >
          {() => (
            <PDPRightRailCardCobranded
              theme={theme}
              isShowingShareAndSaveSection
              isShowingMonthlyPaymentDetails
              isCanaryUIFeatureEnabled={isCanaryUIFeatureEnabled}
              isStandaloneModule
              lenderCTAForwardedRef={lenderCTAForwardedRef}
            />
          )}
        </ScrollTrackedComponent>
      )}
      <div className={theme.InitDataSection}>
        <LoadingSection
          isLoading={!isPDPDataLoaded}
          className={theme.InitDataLoadingSection}
        >
          {isPDPDataLoaded && (
            <>
              {!isTabletView && (
                <ConditionalFeature renderIfFeaturesEnabled={['grant_program']}>
                  <GrantProgramPDPBanner />
                </ConditionalFeature>
              )}
              <OpenHouseInfo theme={theme} />
              <ListingInfoContainer theme={theme} />
              <AdditionalHomeDetails />
              {isTabletView && (
                <ConditionalFeature renderIfFeaturesEnabled={['grant_program']}>
                  <GrantProgramPDPBanner />
                </ConditionalFeature>
              )}
              {
                /* The Homeowner ad is placed differently on the page for onsale/offsale properties */
                !isActiveListing && shouldShowHomeownerAd && (
                  <HomeownerUpsellAdContainer theme={theme} />
                )
              }
              {!isTabletView && !isYourTeamEnabled && !isCtaCleanupEnabled && (
                <PDPLoanOfficerAdMobileCobranded />
              )}
              {isReferralServicesEnabled && isYourTeamEnabled && (
                <div className={theme.FindAgentWrapper}>
                  <FindAgentBanner isNarrowLayout />
                </div>
              )}
              {showCustomBanner &&
                (<div
                  className={theme.FindAgentWrapper}
                  data-hc-name="find-agent-section"
                >
                  <CustomPropertyBanner isNarrowLayout propertyPageData={propertyPageData} />
                </div>
              )}
              <MapPropertyPageContainer theme={theme} />
              {!isHidingAvmBreakdown && (
                <AvmBreakdownVerticalContainer theme={theme} />
              )}
              <BreakoutSectionsContainer theme={theme} />
              {
                /* The Homeowner ad is placed differently on the page for onsale/offsale properties */
                isActiveListing && shouldShowHomeownerAd && (
                  <HomeownerUpsellAdContainer theme={theme} />
                )
              }
              <NearbyListingsContainer theme={theme} />
              {!isMobileStickyFooterEnabled && <ScrollToTop />}
              <hr className={theme.SectionDivider} />
            </>
          )}
        </LoadingSection>
      </div>
      <div className={theme.FooterSection}>
        <Footer theme={theme} shouldUseSectionElement />
      </div>
      {isMobileStickyFooterEnabled && !hidePDPRightRailCard && (
        <MobileStickyFooter rightRailCTAPosition={rightRailCTAPosition} />
      )}
    </div>
  );
};

export default PropertyPageMobileContents;
