import CarouselRowContainer from '@client/containers/carousel-row.container';
import theme from '@client/css-modules/PropertyPageTabletContents.css';
import { PropertyPageTabletContentsProps } from '@client/store/types/cobranded-components/property-page';
import PropertyPageMobileContentsCobranded from '@client/components/PropertyPageMobileContents/PropertyPageMobileContentsCobranded';
import PDPRightRailCardCobranded from '@client/components/PDPRightRailCard/PDPRightRailCardCobranded';

export default function PropertyPageTabletContents({
  isActiveListing,
  shouldShowHomeownerAd,
  isCanaryUIFeatureEnabled,
  isReferralServicesEnabled,
  isHidingAvmBreakdown,
}: PropertyPageTabletContentsProps) {
  return (
    <div className={theme.PropertyPageTabletContents}>
      <CarouselRowContainer theme={theme} />
      <div className={theme.Columns}>
        <div className={theme.LeftSide}>
          <PropertyPageMobileContentsCobranded
            isTabletView
            hidePDPRightRailCard
            isActiveListing={isActiveListing}
            shouldShowHomeownerAd={shouldShowHomeownerAd}
            isPDPDataLoaded={true}
            isReferralServicesEnabled={isReferralServicesEnabled}
            isHidingAvmBreakdown={isHidingAvmBreakdown}
            isCanaryUIFeatureEnabled={isCanaryUIFeatureEnabled}
          />
        </div>
        <div className={theme.RightSide} data-hc-name="right-side-column">
          <PDPRightRailCardCobranded
            isShowingMonthlyPaymentDetails
            isShowingShareAndSaveSection
            isCanaryUIFeatureEnabled={isCanaryUIFeatureEnabled}
            isStandaloneModule
            theme={theme}
          />
        </div>
      </div>
    </div>
  );
}
