import {
  FetchFullPropertyDetailsSuccessAction,
  FetchInitPropertyDetailsSuccessAction,
} from '@client/store/actions/property-details.actions';
import {
  FetchPropertyPhotosAction,
  FetchPropertyPhotosSuccessAction,
  FetchStreetViewAvailabilityAction,
  FetchStreetViewAvailabilitySuccessAction,
  FETCH_PROPERTY_PHOTOS,
  FETCH_PROPERTY_PHOTOS_SUCCESS,
  FETCH_STREET_VIEW_URL,
  FETCH_STREET_VIEW_URL_SUCCESS,
} from '@client/store/types/property-photos';

export const fetchPropertyPhotos = ({
  size,
  slug,
}: FetchPropertyPhotosAction['payload']): FetchPropertyPhotosAction => ({
  type: FETCH_PROPERTY_PHOTOS,
  payload: { size, slug },
});

export const fetchPropertyPhotosSuccess = ({
  size,
  slug,
  bestPhotos,
  mls,
}: FetchPropertyPhotosSuccessAction['payload']): FetchPropertyPhotosSuccessAction => ({
  type: FETCH_PROPERTY_PHOTOS_SUCCESS,
  payload: {
    size,
    slug,
    bestPhotos,
    mls,
  },
});

export const fetchStreetViewUrl = ({
  slug,
  fullAddress,
  imageHeight,
  imageWidth,
}: FetchStreetViewAvailabilityAction['payload']): FetchStreetViewAvailabilityAction => ({
  type: FETCH_STREET_VIEW_URL,
  payload: {
    slug,
    fullAddress,
    imageHeight,
    imageWidth,
  },
});

export const fetchStreetViewUrlSuccess = ({
  slug,
  url,
}: FetchStreetViewAvailabilitySuccessAction['payload']): FetchStreetViewAvailabilitySuccessAction => ({
  type: FETCH_STREET_VIEW_URL_SUCCESS,
  payload: {
    slug,
    url,
  },
});

export type PropertyPhotosAction =
  | FetchStreetViewAvailabilityAction
  | FetchStreetViewAvailabilitySuccessAction
  | FetchPropertyPhotosAction
  | FetchPropertyPhotosSuccessAction
  | FetchInitPropertyDetailsSuccessAction
  | FetchFullPropertyDetailsSuccessAction;
