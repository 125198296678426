import PDPRightRailCardChase from '@client/components/PDPRightRailCard/PDPRightRailCardChase';
import PDPRightRailCardTopAgentsRanked from '@client/components/PDPRightRailCard/PDPRightRailCardTopAgentsRanked';
import PDPRightRailCard from '@client/components/PDPRightRailCard/PDPRightRailCard';
import { PDPRightRailCardProps } from '@client/store/types/cobranded-components/pdp-right-rail-card';

import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { COBRAND_IDS } from '@client/store/types/cobranding';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.TOPAGENTSRANKED:
      return PDPRightRailCardTopAgentsRanked;
    case COBRAND_IDS.CHASE:
      return PDPRightRailCardChase;
    default:
      return PDPRightRailCard;
  }
};

const PDPRightRailCardCobranded: React.FC<PDPRightRailCardProps> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default PDPRightRailCardCobranded;
