import { Component } from 'react';

import theme from '@client/css-modules/StreetView.css';
import renderOnMount from '@client/hocs/render-on-mount';
import { LatitudeLongitudeObject } from '@client/store/types/maps';
import { reportToSentry } from '@client/utils/error.utils';
import { getBearing } from '@client/utils/maps.utils';

let googleScriptAdded = false;
let googleLoadedCallback;
let googlePromise = new Promise((resolve, reject) => {
  googleLoadedCallback = () => resolve(null);
});

global.googleMapsApiInit = () => {
  googleLoadedCallback();
};

declare let google: {
  maps: any;
};

function initGoogleAPI() {
  if (googleScriptAdded) {
    return;
  }
  let head = document.getElementsByTagName('head')[0];
  let script = document.createElement('script');
  script.type = 'text/javascript';
  script.src =
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyCSeB-EnjyYdtbAhxsXEZZDZuaeHODnKSw&callback=googleMapsApiInit';
  head.appendChild(script);
  googleScriptAdded = true;
}

type StreetViewProps = {
  /** Lat, lng of property */
  propertyLocation: LatitudeLongitudeObject | null;
  /** Method executed when Street View is not available for the given location.
   * This can be used to adjust the parent layout */
  onFailCallback: () => void;
};

export default renderOnMount(
  class StreetView extends Component<StreetViewProps> {
    panorama: any = null;
    mounted: boolean = false;
    rootElement: HTMLDivElement | null = null;
    setToAddAttributesToElementsOnMap: number = 0;

    componentWillUnmount() {
      this.mounted = false;
      if (this.setToAddAttributesToElementsOnMap !== null) {
        window.clearTimeout(this.setToAddAttributesToElementsOnMap);
      }
    }

    addAttributesToArrowsLeftRightOnMap = () => {
      /* Add attributes to the arrows on the map due to accessibility requirement */
      const viewMapArrowPaths: HTMLCollection = document
        .querySelectorAll('.gmnoprint')[0]
        ?.querySelectorAll('svg')[0]?.children;
      if (viewMapArrowPaths) {
        /* Add attributes to the arrows on Google street view */
        for (let i = 0; i < viewMapArrowPaths.length; i++) {
          /* the default arrow's color is white and '#CCFFFF' on hover*/
          if (
            (viewMapArrowPaths[i]?.tagName === 'path' &&
              viewMapArrowPaths[i]?.attributes[0]?.value === 'white') ||
            viewMapArrowPaths[i]?.attributes[0]?.value === '#CCFFFF'
          ) {
            if (i === 2) {
              viewMapArrowPaths[i].setAttribute('role', 'button');
              viewMapArrowPaths[i].setAttribute('tabindex', '0');
              viewMapArrowPaths[i].setAttribute(
                'aria-label',
                'Move the street view image left'
              );
            } else if (i === 5) {
              viewMapArrowPaths[i].setAttribute('role', 'button');
              viewMapArrowPaths[i].setAttribute('tabindex', '0');
              viewMapArrowPaths[i].setAttribute(
                'aria-label',
                'Move the street view image right'
              );
            }
          }
        }
      }
    };

    recordRootElement = (ele: HTMLDivElement | null) => {
      if (ele) {
        this.rootElement = ele;
        this.setToAddAttributesToElementsOnMap = window.setTimeout(() => {
          /* Add aria-label to the compass on the map due to accessibility requirement */
          const viewCompassNorth: HTMLElement | null =
            document.querySelector('.gm-compass-needle');
          const viewCompassClockwise: HTMLElement | null =
            document.querySelector('.gm-compass-turn-opposite');
          const viewCompassCounterClockwise: HTMLElement | null =
            document.querySelector('.gm-compass-turn');

          /* Add attributes to the arrows on Google street view */
          this.addAttributesToArrowsLeftRightOnMap();

          if (viewCompassNorth) {
            viewCompassNorth.setAttribute(
              'aria-label',
              'Rotate street view to face north'
            );
          }
          if (viewCompassClockwise) {
            viewCompassClockwise.setAttribute(
              'aria-label',
              'Rotate street view clockwise'
            );
          }
          if (viewCompassCounterClockwise) {
            viewCompassCounterClockwise.setAttribute(
              'aria-label',
              'Rotate street view counterclockwise'
            );
          }

          /* timeout 2000ms is added to wait for the map to load. */
          /* TODO: Should place above logic into where it could detect when the map is loaded */
        }, 2000);
      }
    };

    processStreetViewData = (data, status): void => {
      if (!this.props.propertyLocation) {
        return;
      }

      if (status === google.maps.StreetViewStatus.OK) {
        const streetViewLocation = {
          lat: data.location.latLng.lat(),
          lng: data.location.latLng.lng(),
        };
        const propertyLocation = {
          lat: this.props.propertyLocation.latitude,
          lng: this.props.propertyLocation.longitude,
        };

        const heading = getBearing(streetViewLocation, propertyLocation);

        this.panorama.setPano(data.location.pano);
        this.panorama.setPov({
          heading,
          pitch: 0,
        });
        this.panorama.setVisible(true);
        /**
         * When a user clicks on the arrows on the street view,
         * the map will be reloaded and all the aria attributes on the map will get remove.
         * We need to add them back to pass the accessibility requirement
         */
        this.panorama.addListener('position_changed', () => {
          this.setToAddAttributesToElementsOnMap = window.setTimeout(() => {
            /* Add aria attributes to the map due to accessibility requirement */
            this.addAttributesToArrowsLeftRightOnMap();
            /**
             * timeout 100ms is added to wait for the map to load.
             * Doesn't need to wait long since this will activate
             * when the street view is reloaded from a user clicking on the arrows
             *
             * TODO: Should place above logic into where it could detect when the map is loaded
             */
          }, 100);
        });
      } else {
        this.props.onFailCallback();
      }
    };

    /* Prevent `google not found` error (likely due to ad blockers blocking a script) from breaking page */
    componentDidCatch(error, errorInfo) {
      reportToSentry(error, {
        errorInfo,
        originalError: error,
      });
    }

    componentDidMount() {
      initGoogleAPI();
      const { propertyLocation } = this.props;

      if (!propertyLocation) {
        throw new Error('StreetView mounted without propertyLocation');
      }

      const geoLocation = {
        lat: propertyLocation.latitude,
        lng: propertyLocation.longitude,
      };

      this.mounted = true;
      googlePromise.then(() => {
        if (!this.mounted) {
          return;
        }
        /* Global var `google` set on window via script in HTML */
        /* One way to check if google is available on window  */
        if (typeof google === 'object' && typeof google.maps === 'object') {
          const defaultControlOptions = {
            zoomControlOptions: {
              position: google.maps.ControlPosition.LEFT_BOTTOM,
            },
            panControlOptions: {
              position: google.maps.ControlPosition.LEFT_BOTTOM,
            },
            motionTracking: false,
          };
          const streetViewService = new google.maps.StreetViewService();

          /* Init the Street View panorama on our DOM element */
          this.panorama = new google.maps.StreetViewPanorama(
            this.rootElement,
            defaultControlOptions
          );

          /* Retrieve Street View data and run a callback method */
          streetViewService.getPanorama(
            {
              location: geoLocation,
              radius: 50,
              source: google.maps.StreetViewSource.OUTDOOR,
            },
            this.processStreetViewData
          );
        }
      });
    }

    render() {
      return (
        <>
          <div
            className={theme.StreetViewContainer}
            ref={this.recordRootElement}
            aria-label="Google Street View"
          />
          {/* Re-color 'view on google maps link' to a brighter color due to accessibility requirement */}
          <style
            dangerouslySetInnerHTML={{
              __html: `
            .gm-iv-long-address-description {
              color: #FFFFFF;
            }

            .gm-iv-address-link a  {
              color: #FFFFFF;
              text-decoration: underline;
            }

            .gm-style-cc a:focus {
              border: 2px solid #DEDEDE;
            }

            .gm-style-cc div button:focus {
              border: 2px solid #DEDEDE !important;
            }

            .gm-fullscreen-control:focus {
              border: 2px solid #DEDEDE !important;
            }

            .gm-control-active:focus {
              border: 2px solid #DEDEDE !important;
            }

            .gm-style div button[aria-label="Keyboard shortcuts"] {
              display: none !important;
            }
            .gm-style-cc div button[aria-label="Keyboard shortcuts"] {
              display: inline-block !important;
            }
          `,
            }}
          />
        </>
      );
    }
  }
);
