import React from 'react';
import { Layer as LayerType } from 'mapbox-gl';
import Layer from '@hc/hcmaps-mapboxgl/lib/components/Layer';
import LayerSource from '@hc/hcmaps-mapboxgl/lib/components/LayerSource';

type TriggerProp = string | number | boolean | null;

type Props = {
  /* The metric to be pulled from the vector tile response for painting the features */
  metric: string;
  paint: LayerType['paint'];
  /* The opacity of the colors used in painting the features */
  fillOpacity?: number;
  /* Defines which sources should be used a which zoom levels.  These sourceKeywords are used
   * both in ids for the layers and the sources and in the URLs for the sources, following
   * our HC conventions */
  sourceZoomMapping: { [key: string]: number[] | undefined };
  /* The environment-specific base URL for the tile requests */
  urlBase: string;
  /* Mapbox filter definition */
  filter?: LayerType['filter'];
  /* A property that triggers a repainting when it changes. If not supplied,
   * a change to `metric` will trigger a repaint */
  repaintTrigger?: TriggerProp;
  /* A property that triggers a layout change when it changes. If not supplied,
   * a change to `metric` will trigger a layout change */
  changeLayoutTrigger?: TriggerProp;
  /* A property that triggers a filter change when it changes. If not supplied,
   * a change to `metric` will trigger a filter change */
  changeFilterTrigger?: TriggerProp;
  /* If provided, layers in this component are inserted beneath this layer on the map */
  beneathLayerId?: string;
};

/**
 * Renders one or more Gaia block layers optionally comprised of different tile sets
 * at different zoom levels.  Makes decisions about layer configuration using our
 * in-house HC standards for convenience.
 */
class BlocksLayer extends React.PureComponent<Props> {
  render() {
    const {
      urlBase,
      sourceZoomMapping,
      metric,
      filter,
      paint,
      repaintTrigger,
      changeLayoutTrigger,
      changeFilterTrigger,
      beneathLayerId,
    } = this.props;

    return Object.keys(sourceZoomMapping).map((sourceKeyword) => (
      <Layer
        key={sourceKeyword}
        layerId={`${sourceKeyword}_layer`}
        sourceLayerId={sourceKeyword}
        type="fill"
        minZoom={
          sourceZoomMapping[sourceKeyword]
            ? (sourceZoomMapping[sourceKeyword] as number[])[0]
            : undefined
        }
        maxZoom={
          sourceZoomMapping[sourceKeyword]
            ? (sourceZoomMapping[sourceKeyword] as number[])[1]
            : undefined
        }
        repaintTrigger={repaintTrigger ? `${repaintTrigger}-${metric}` : metric}
        changeLayoutTrigger={
          changeLayoutTrigger ? `${changeLayoutTrigger}-${metric}` : metric
        }
        changeFilterTrigger={
          changeFilterTrigger ? `${changeFilterTrigger}-${metric}` : metric
        }
        paint={paint}
        filter={filter}
        beneathLayerId={beneathLayerId}
        Source={
          <LayerSource
            sourceId={`${sourceKeyword}_source`}
            type="vector"
            url={`${urlBase}/${sourceKeyword}/{z}/{x}/{y}.mvt`}
            minZoom={
              sourceZoomMapping[sourceKeyword]
                ? (sourceZoomMapping[sourceKeyword] as number[])[0]
                : undefined
            }
            maxZoom={
              sourceZoomMapping[sourceKeyword]
                ? (sourceZoomMapping[sourceKeyword] as number[])[1]
                : undefined
            }
          />
        }
      />
    ));
  }
}

export default BlocksLayer;
