import {
  ErrorMetadataType,
  ParamsType,
  QueryType,
  RouteDefOptionType,
  RouteType,
  ViewType,
} from './types';

import queryString from 'query-string';

// Options applied to view/params/query before determining the next route
export const applyRouteChangeOptionsBefore = (
  view: ViewType | undefined,
  params: ParamsType = {},
  query: QueryType = {},
  currentRoute: RouteType
): {
  nextView: ViewType;
  nextParams: ParamsType;
  nextQuery: QueryType;
} => {
  const nextView = typeof view === 'string' ? view : currentRoute.view;
  return {
    nextView,
    nextParams: params,
    nextQuery: query,
  };
};

// Options applied to view/params/query after determining the next route and applying the querySelector
export const applyRouteChangeOptionsAfter = (
  view: ViewType,
  params: ParamsType,
  query: QueryType,
  currentRoute: RouteType
): {
  finalView: ViewType;
  finalParams: ParamsType;
  finalQuery: QueryType;
} => {
  const finalView = view || currentRoute.view;
  const finalParams = params;
  const finalQuery = { ...query };

  return {
    finalView,
    finalParams,
    finalQuery,
  };
};

export const applyRouteChangeOptionsAll = (
  view: ViewType,
  params: ParamsType,
  query: QueryType,
  currentRoute: RouteType
): {
  nextView: ViewType;
  nextParams: ParamsType;
  nextQuery: QueryType;
} => {
  const { nextView, nextParams, nextQuery } = applyRouteChangeOptionsBefore(
    view,
    params,
    query,
    currentRoute
  );
  const { finalView, finalParams, finalQuery } = applyRouteChangeOptionsAfter(
    nextView,
    nextParams,
    nextQuery,
    currentRoute
  );
  // Return as nextView... because they may be modified by the querySelector on the new route when called from this method
  return {
    nextView: finalView,
    nextParams: finalParams,
    nextQuery: finalQuery,
  };
};

export const buildFullPath = (
  routeDef: RouteDefOptionType,
  params: ParamsType,
  query: QueryType
): string => {
  const { path } = routeDef;
  const paramNames = Object.keys(params);

  // Replace the url parameters with values
  let fullPath = path;
  paramNames.forEach((paramName) => {
    fullPath = fullPath.replace(
      `:${paramName}?`,
      encodeURIComponent(params[paramName])
    );
    fullPath = fullPath.replace(
      `:${paramName}`,
      encodeURIComponent(params[paramName])
    );
  });

  // Remove unused optional parameters from url
  fullPath = fullPath.replace(/(:[^/]+\?)/g, '');
  // Remove trailing slashes
  fullPath = fullPath !== '/' ? fullPath.replace(/\/+$/, '') : fullPath;

  fullPath += buildQueryStr(routeDef.query, query);
  return fullPath;
};

export const buildQueryStr = (
  queryDef: Array<string> = [],
  queryVals: QueryType = {}
): string => {
  let filtered = {};
  let hasValidValues = false;
  queryDef.forEach((name) => {
    if (queryVals.hasOwnProperty(name) && queryVals[name] !== undefined) {
      filtered[name] = queryVals[name];
      hasValidValues = true;
    }
  });
  return hasValidValues ? `?${queryString.stringify(filtered)}` : '';
};

export const buildQueryObj = (): QueryType => {
  let query = {};
  if (window.location.search) {
    query = { ...queryString.parse(window.location.search) };
  }
  return query;
};

export const buildErrorMetadata = (routeObj: RouteType): ErrorMetadataType => ({
  location: window.location.href,
  routeObj,
});

export const transformRoutesDefinitionToComponentMapping = (ROUTES: {
  [key: string]: RouteDefOptionType;
}) =>
  Object.keys(ROUTES).reduce((views, viewId) => {
    views[viewId] = ROUTES[viewId].component;
    return views;
  }, {});

export const combineParamsQuery = (
  newObj: Object = {},
  currentObj: Object = {}
): Object => {
  return { ...currentObj, ...newObj };
};

// Used by ruta3: https://github.com/bevacqua/ruta3/blob/master/pathToRegExp.js
export const pathToRegExp = (
  path: string,
  keys: Array<string | undefined>
): Object => {
  path = path
    .concat('/?')
    .replace(/\/\(/g, '(?:/')
    .replace(/(\/)?(\.)?:(\w+)(?:(\(.*?\)))?(\?)?|\*/g, tweak)
    .replace(/([\/.])/g, '\\$1')
    .replace(/\*/g, '(.*)');

  return new RegExp('^' + path + '$', 'i');

  function tweak(match, slash, format, key, capture, optional) {
    if (match === '*') {
      keys.push(void 0);
      return match;
    }

    keys.push(key);

    slash = slash || '';

    return (
      '' +
      (optional ? '' : slash) +
      '(?:' +
      (optional ? slash : '') +
      (format || '') +
      (capture
        ? capture.replace(/\*/g, '{0,}').replace(/\./g, '[\\s\\S]')
        : '([^/]+?)') +
      ')' +
      (optional || '')
    );
  }
};
