import React from 'react';

import TransformedImage, {
  autoCrop,
} from '@client/components/TransformedImage';

/**
 * Due an apparent iOS bug or performance optimization, we need to render images instead of canvas elements
 * https://sentry.io/housecanarycom/consumer-web-qa/?query=Error+when+attempting+image+manipulation
 */
type Props = {
  shouldRenderImgFallback: boolean;
  onLoad?: ({ target }: { target: EventTarget } | Event) => void;
  alt?: string;
  src: string;
  className?: string;
};

export default class TransformedImageIOSFix extends React.Component<Props> {
  imageEle: HTMLImageElement | null = null;

  handleImageLoading = (img) => {
    const { onLoad } = this.props;

    if (img && onLoad) {
      this.imageEle = img;
      img.addEventListener('load', onLoad);
      /* Account for image cached in browser */
      if (img.complete) {
        onLoad({ target: img });
      }
    }
  };

  componentWillUnmount() {
    const { onLoad } = this.props;

    if (this.imageEle && onLoad) {
      this.imageEle.removeEventListener('load', onLoad);
    }
  }

  render() {
    const { shouldRenderImgFallback, onLoad, alt, ...rest } = this.props;

    return shouldRenderImgFallback ? (
      <img alt={alt} ref={this.handleImageLoading} {...rest} />
    ) : (
      <TransformedImage
        alt={alt}
        transforms={[autoCrop()]}
        onLoad={onLoad}
        {...rest}
      />
    );
  }
}
