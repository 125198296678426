import PropertyBrief from '@client/components/PropertyBrief';
import PropertyPageAddress from '@client/components/PropertyPageAddress';
import defaultTheme from '@client/css-modules/PropertyIntro.css';
import { EVENTS, PARENT_EVENTS } from '@client/store/analytics-constants';
import { AlertPropertyDetails } from '@client/store/types/alerts';
import {
  NormalizedProperty,
  PropertyBasicInfo,
} from '@client/store/types/property';
import { onEnterKey } from '@client/utils/accessibility.utils';
import { getIsActiveListing } from '@client/utils/property.utils';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { differenceInDays } from 'date-fns';
import React from 'react';
import CobrandedStyles from './CobrandedStyles';

const isNormalizedPropertyWithSimilarity = (
  propertyDetails: NormalizedProperty | AlertPropertyDetails
): propertyDetails is NormalizedProperty => {
  return !!(propertyDetails as NormalizedProperty).similarity;
};

type Props = {
  ariaLabel?: string;
  /* A rel to pass through to the <a> element, used for SEO */
  rel?: string;
  propertyBasicInfo: PropertyBasicInfo | null;
  isAddedToWatchList: boolean;
  showExpandedPropInfoOnLoad?: boolean;
  /* Whether we're showing the condensed version of the property info, such as when on a
   * property card or in the mobile PDP */
  isShowingCondensedPropertyInfo?: boolean;
  isCondensedAddressLink?:boolean;
  propertyDetails: NormalizedProperty | AlertPropertyDetails | null;
  /* Whether to show "day on market" for property */
  isShowingDaysOnMarket?: boolean;
  /* Whether to show similarity score for property.  Only applies when used for comps */
  isShowingSimilarity?: boolean;
  /* Render the PropertyPageAddress component as a h1 component. */
  addressAsHeader?: boolean;
  /* For instances where the property info link should handle its own redirect.
   * For example, if a user is using keystrokes for accessibility, the link
   * needs to function on its own instead of relying on the parents click handler */
  onStreetAddressLinkKeyDown?: () => void;
  theme: Theme;
  /* This component is shared across many UI areas. We don't want this event firing when the SRP card is clicked, but do want it elsewhere */
  isFiringGeneralDetailsAnalyticsEvent?: boolean;
};

function PropertyIntro({
  ariaLabel,
  isShowingDaysOnMarket,
  isShowingSimilarity,
  onStreetAddressLinkKeyDown,
  propertyBasicInfo,
  propertyDetails,
  rel,
  theme,
  isShowingCondensedPropertyInfo,
  isCondensedAddressLink,
  isFiringGeneralDetailsAnalyticsEvent,
  addressAsHeader,
}: Props) {
  /**
   * Return the days the property has been on the market
   */
  function getDaysOnMarket(
    propertyDetails: AlertPropertyDetails | NormalizedProperty
  ) {
    const { dateOfListing } = propertyDetails;
    return !!dateOfListing ? differenceInDays(Date.now(), dateOfListing) : null;
  }

  /* When hiding the mobile property card, the removal of the property card slug in the URL triggers the
   * animation that removes the property card, hence this component is rendered without data for a brief period */
  if (
    !propertyDetails ||
    (!isShowingCondensedPropertyInfo && !propertyBasicInfo)
  ) {
    return null;
  }
  const isActiveListing = getIsActiveListing(propertyDetails.status);
  const preventDefault = (e: React.SyntheticEvent): void => {
    e.preventDefault();
  };
  const daysOnMarket = getDaysOnMarket(propertyDetails);

  return (
    <div className={theme.PropertyIntro}>
      {/* Shown only on large screens */}
      {!isShowingCondensedPropertyInfo && propertyBasicInfo && (
        <section
          className={theme.DesktopPropertyInfo}
          data-hc-name="property-intro-section"
          aria-label="property introduction"
        >
          {propertyBasicInfo.address && (
            <>
              {addressAsHeader ? (
                <h1 className={theme.Address}>
                  <PropertyPageAddress address={propertyBasicInfo.address} />
                </h1>
              ) : (
                <div className={theme.Address}>
                  <PropertyPageAddress address={propertyBasicInfo.address} />
                </div>
              )}
            </>
          )}
          <div className={theme.PropertyInfo}>
            <PropertyBrief
              theme={theme}
              baths={propertyBasicInfo.baths}
              beds={propertyBasicInfo.beds}
              sqFeet={propertyBasicInfo.sqFeet}
              daysOnMarket={propertyBasicInfo.daysOnMarket}
              propertyType={propertyBasicInfo.propertyType}
            />
          </div>
        </section>
      )}

      {/* Shown on small screen PDPs and within all property cards */}
      {isShowingCondensedPropertyInfo && (
        <section className={theme.SmallPropertyInfo}>
          <div
            className={theme.PropertyCardAddress}
            data-hc-name="property-address"
          >
            <div className={theme.StreetAddress}>
              {isCondensedAddressLink ? (<CobrandedStyles>
                {({ linkColor }) => (
                  <h2>
                    <a
                      style={{ color: linkColor }}
                      href={`/property-details/${propertyDetails.slug}`}
                      onClick={(e: React.MouseEvent) => preventDefault(e)}
                      rel={rel}
                      aria-label={
                        ariaLabel ||
                        `View property details for ${propertyDetails.fullStreetAddress}, ${propertyDetails.city}, ${propertyDetails.state}`
                      }
                      data-event-name={
                        isFiringGeneralDetailsAnalyticsEvent
                          ? EVENTS.CLICK_PROPERTY_DETAILS_GENERAL_DETAILS
                          : undefined
                      }
                      data-parent-event-name={
                        isFiringGeneralDetailsAnalyticsEvent
                          ? PARENT_EVENTS.CLICK_PDP_MODULES
                          : undefined
                      }
                      onKeyDown={onEnterKey(() => onStreetAddressLinkKeyDown?.())}
                    >
                      {propertyDetails.fullStreetAddress}
                    </a>
                  </h2>
                )}
              </CobrandedStyles>) :(<>{propertyDetails.fullStreetAddress}</>)}
            </div>
            <div className={theme.CityStateZipcode}>
              {propertyDetails.city}, {propertyDetails.state}{' '}
              {propertyDetails.zipcode}
            </div>
          </div>
          <PropertyBrief
            beds={propertyDetails.beds}
            baths={propertyDetails.baths}
            sqFeet={propertyDetails.sqFt}
            daysOnMarket={
              isActiveListing && isShowingDaysOnMarket ? daysOnMarket : null
            }
            theme={theme}
            similarity={
              isShowingSimilarity &&
                isNormalizedPropertyWithSimilarity(propertyDetails) &&
                propertyDetails.similarity
                ? propertyDetails.similarity
                : null
            }
          />
        </section>
      )}
    </div>
  );
}

const ThemedPropertyIntro = themr('PropertyIntro', defaultTheme)(PropertyIntro);
export default ThemedPropertyIntro;
