import { connect } from 'react-redux';

import CarouselRow from '@client/components/CarouselRow';
import {
  reportCarouselRightArrowClick,
  reportEvent,
} from '@client/store/actions/analytics.actions';
import { authModalShow } from '@client/store/actions/auth.actions';
import { openModal } from '@client/store/actions/modals.actions';
import { AUTH_MODAL_PAGES, STATUSES } from '@client/store/constants';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import {
  getPropertyAddressSlug,
  getPropertyDetailsNormalized,
  getPropertyFullAddress,
  getPropertyIsInWatchList,
  getPropertyIsShowingPhotosLoginUpsell,
  getPropertyMLSLogoOverlayPhoto,
} from '@client/store/selectors/property-details.selectors';
import {
  getPropertyCarouselRowPhotos,
  getPropertyDetailsPhotosStatus,
  getPropertyFirstPhoto,
} from '@client/store/selectors/property-photos.selectors';
import { getIsInitialRoute } from '@client/store/selectors/router.selectors';
import { getIsApplicationMounted } from '@client/store/selectors/server-rendering-info.selectors';
import { AnalyticsEventAddress } from '@client/store/types/analytics';
import { PDP_CAROUSEL_PHOTO_SIZE as PHOTO_SIZE } from '@client/store/types/property';

const mapStateToProps = (state) => {
  const isInitialRoute = getIsInitialRoute(state);
  const isSmallScreen = getIsSmallSize(state);
  const firstPhoto = getPropertyFirstPhoto(state, { size: PHOTO_SIZE });

  return {
    addressSlug: getPropertyAddressSlug(state),
    fullAddress: getPropertyFullAddress(state),
    propertyDetails: getPropertyDetailsNormalized(state),
    photos: getPropertyCarouselRowPhotos(state, { size: PHOTO_SIZE }),
    photoSize: PHOTO_SIZE,
    photosStatusIsInit:
      getPropertyDetailsPhotosStatus(state, { size: PHOTO_SIZE }) ===
      STATUSES.INIT,
    photosStatusIsLoading:
      getPropertyDetailsPhotosStatus(state, { size: PHOTO_SIZE }) ===
      STATUSES.LOADING,
    shouldShowDesktopPhotosModal: !isSmallScreen,
    isAddedToWatchList: getPropertyIsInWatchList(state),
    mapTileSize: (isSmallScreen ? [400, 220] : [1100, 420]) as [number, number],
    isShowingLoginUpsell: getPropertyIsShowingPhotosLoginUpsell(state),
    isAppMounted: getIsApplicationMounted(state),
    /* On a small screen, if there's a previous route, that means we're coming here after clicking a property card,
     * meaning that images don't need to be preloaded (the browser already has at least 1 cached).  Otherwise,
     * preload images */
    imagePreloadCount: isSmallScreen ? (isInitialRoute ? 1 : 0) : 3,
    overlay: !!firstPhoto ? getPropertyMLSLogoOverlayPhoto(state) : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleShowPhotosListModal: (): void => {
    dispatch(openModal('photo-list'));
  },
  handleReportClickNext: (address): void => {
    dispatch(reportCarouselRightArrowClick(address));
  },
  handleReportClickPrev: (address: AnalyticsEventAddress): void => {
    dispatch(
      reportEvent('click_carousel_left_arrow', '', {
        address,
        ch_property_id: address.slug,
      })
    );
  },
  handleReportLoginUpsell: (): void => {
    dispatch(reportEvent('click_pdp_photo_carousel_login_upsell'));
  },
  handleReportClickPropertyDetailsMainPhoto: (
    address: AnalyticsEventAddress
  ): void => {
    dispatch(
      reportEvent('click_pdp_main_photo', '', {
        address,
        ch_property_id: address.slug,
      })
    );
  },
  handleShowAuthModal: (): void => {
    dispatch(
      authModalShow({
        subHeadingText: {
          [AUTH_MODAL_PAGES.SIGN_UP]:
            'Join and start seeing more photos on ComeHome!',
          [AUTH_MODAL_PAGES.LOGIN]: 'Login and see more photos on ComeHome!',
        },
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CarouselRow);
