import { call, put } from 'redux-saga/effects';

import { graphQLApiClient } from '@client/services/graphql-api-client';
import {
  FETCH_TEST_PROPERTIES,
  fetchTestPropertiesSuccess,
} from '@client/store/actions/test-properties.actions';
import { watchEvery } from '@client/utils/saga.utils';

function* getTestPropertiesSaga() {
  const data = yield call([
    graphQLApiClient,
    graphQLApiClient.getTestProperties,
  ]);
  yield put(fetchTestPropertiesSuccess(data));
}

function throwTestErrorSaga() {
  throw new Error('Test Error 💥');
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [FETCH_TEST_PROPERTIES]: getTestPropertiesSaga,
    THROW_TEST_ERROR: throwTestErrorSaga,
  });
};
