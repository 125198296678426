import { localStorageUtil } from '@client/utils/local-storage.utils';

/**
 * Only in development mode, allow a "blacklist" of action keys to be created and
 * maintained to reduce the clutter in Redux Dev Tools
 * @returns redux enhancer object to be added
 */
export const setupReduxActionsBlacklist = () => {
  /* eslint-disable-next-line custom/explain-localstorage */
  const blacklist = localStorageUtil.getItem('reduxActionsBlacklist');

  return global.__REDUX_DEVTOOLS_EXTENSION__({
    trace: true,
    traceLimit: 25,
    actionsBlacklist: blacklist && blacklist.split(','),
  });
};
