import PropertyPageMobileContents from '@client/components/PropertyPageMobileContents/PropertyPageMobileContents';
import PropertyPageMobileContentsChase from '@client/components/PropertyPageMobileContents/PropertyPageMobileContentsChase';
import PropertyPageMobileContentsComeHome from '@client/components/PropertyPageMobileContents/PropertyPageMobileContentsComehome';
import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { PropertyPageMobileContentsProps } from '@client/store/types/cobranded-components/property-page';
import { COBRAND_IDS } from '@client/store/types/cobranding';

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
      return PropertyPageMobileContentsChase;
    case COBRAND_IDS.WWW:
      return PropertyPageMobileContentsComeHome;
    default:
      return PropertyPageMobileContents;
  }
};

const PropertyPageMobileContentsCobranded: React.FC<
  Omit<PropertyPageMobileContentsProps, 'theme'>
> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default PropertyPageMobileContentsCobranded;
