import { themr } from '@friendsofreactjs/react-css-themr';

import { dollarsFormatterWithSign } from '@client/utils/string.utils';
import { AVMFactorDeltaValueProps } from '@client/store/types/cobranded-components/avm-factor-delta-value';
import theme from '@client/css-modules/AvmFactorDeltaValue.css';

const AVMFactorDeltaValue: React.FunctionComponent<
  AVMFactorDeltaValueProps
> = ({ value, theme }) => {
  return (
    <div className={theme.AvmFactorValueDelta}>
      {`${dollarsFormatterWithSign(value)} to value`}
    </div>
  );
};

export default themr('ThemedAVMFactorDeltaValue', theme)(AVMFactorDeltaValue);
