import {
  abbrNumberFormatter,
  percentFormatter,
} from '@client/utils/string.utils';

/**
 * Persist the sign of value and add a dollar sign to the output of addrNumberFormatter
 * @param  {number} value
 * @return {string} abbreviated dollar amount
 */
export const abbrDollarsFormatter = (value, placeholder, decimalPlaces) => {
  if (!value && value !== 0) {
    return typeof placeholder !== 'undefined' ? placeholder : '--';
  }

  // store the sign of the number
  const sign = value < 0 ? '-1' : '1';

  /**
   * always pass absolute value to then formatter for the comparisons
   * in it to work right
   */
  if (value < 0) {
    value *= -1;
  }

  return `$${abbrNumberFormatter(value, placeholder, decimalPlaces, sign)}`;
};

export const getForecastRangeValue = (value) => {
  const roundedToOneDecimal = parseFloat(value.toFixed(1));
  const percentage = percentFormatter(roundedToOneDecimal, undefined, false);
  return roundedToOneDecimal > 0 ? `+${percentage}` : percentage;
};
