import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import TextButton from '@client/components/generic/TextButton';
import BellIcon from '@client/inline-svgs/bell-icon';
import { openEmailOptInModal } from '@client/store/actions/email-opt-in.actions';

import theme from '@client/css-modules/EmailOptInSignUpCta.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type Props = {
  isInMobileView?: boolean;
  handleClickEmailOptInSignUpCta?: () => void;
};

const EmailOptInSignUpCta = ({
  isInMobileView,
  handleClickEmailOptInSignUpCta,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const handleClickCta = () => {
    if (isInMobileView && handleClickEmailOptInSignUpCta) {
      handleClickEmailOptInSignUpCta();
    }
    dispatch(openEmailOptInModal());
  };

  const { linkColor } = useCobrandStyles();

  return (
    <div
      className={classNames(theme.EmailOptInSignUpCta, {
        [theme.isInMobileView]: isInMobileView,
      })}
    >
      <div className={theme.TextCol}>
        Get notified on saved homes and newest listings.{' '}
        <TextButton
          style={{ color: linkColor }}
          theme={theme}
          onClick={handleClickCta}
        >
          Add my email
        </TextButton>
      </div>

      <div className={theme.ImageCol} aria-hidden>
        <BellIcon className={theme.BellIcon} />
      </div>
    </div>
  );
};

export default EmailOptInSignUpCta;
