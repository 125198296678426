import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { ReduxState } from '@client/store/types/redux-state';
import { RequestATourFormState } from '@client/store/types/request-a-tour';

export type RequestATourState = {
  isModalOpen: boolean;
};
const initialState: RequestATourState = {
  isModalOpen: false,
};

export const RequestATourSlice = createSlice({
  name: 'requestATour',
  initialState,
  reducers: {
    setIsModalOpen: (
      state,
      action: PayloadAction<{ isModalOpen: boolean }>
    ) => ({
      ...state,
      isModalOpen: action.payload.isModalOpen,
    }),
    submitForm: (state, action: PayloadAction<RequestATourFormState>) => ({
      ...state
    })
  },
});

function selectRequestATourState(state: ReduxState): RequestATourState {
  return state.requestATour;
}

export const selectIsRequestATourModalOpen = createSelector(
  selectRequestATourState,
  (requestATourState) => requestATourState.isModalOpen
);

export const {
  setIsModalOpen,
  submitForm,
} = RequestATourSlice.actions;

export default RequestATourSlice.reducer;
