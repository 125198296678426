import React from 'react';
import theme from '@client/css-modules/SearchMapNotification.css';
import WarningIcon from '@client/inline-svgs/warning-icon';
import { Alert } from '@client/utils/reach-alert';

const SearchMapNotification: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <div className={theme.MapNotificationInner}>
      <WarningIcon />
      <Alert type="assertive">{children}</Alert>
    </div>
  );
};

export default SearchMapNotification;
