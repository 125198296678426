export const CLOSE_UPDATE_EMAIL_BANNER = 'CLOSE_UPDATE_EMAIL_BANNER';
export const CLOSE_CONFIRM_EMAIL_BANNER = 'CLOSE_CONFIRM_EMAIL_BANNER';

type CloseConfirmEmailBanner = {
  type: typeof CLOSE_CONFIRM_EMAIL_BANNER;
};

export const closeConfirmEmailBanner = (): CloseConfirmEmailBanner => ({
  type: CLOSE_CONFIRM_EMAIL_BANNER,
});

type CloseUpdateEmailBanner = {
  type: typeof CLOSE_UPDATE_EMAIL_BANNER;
};

export const closeUpdateEmailBanner = (): CloseUpdateEmailBanner => ({
  type: CLOSE_UPDATE_EMAIL_BANNER,
});

export type AppBannerAction = CloseConfirmEmailBanner | CloseUpdateEmailBanner;
