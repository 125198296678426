import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { goBackOrDispatch } from '@client/store/actions/modals.actions';
import {
  getIsShowingSearchPageList,
  getSearchIsShowingMobileFilters,
  getSearchViewport,
} from '@client/store/selectors/search.selectors';
import { reportHitDlPageOnInvalidDevice } from '@client/store/actions/analytics.actions';
import {
  searchUpdateUrlParams,
  searchFetchPropertyList,
  searchReportInitialFilterState,
} from '@client/store/actions/search.actions';
import {
  ensureLoggedInThenCreateSavedSearch,
  clearSavedSearchState,
} from '@client/store/actions/saved-search.actions';
import SearchPage from '@client/pages/SearchPage';
import { BoundsObject } from '@client/store/types/maps';
import { getIsInsideNativeApp } from '@client/store/selectors/cobranding.selectors';
import { getIsFourHundredZoom } from '@client/store/selectors/match-media.selectors';

const mapStateToProps = (state) => {
  return {
    isShowingSearchList: getIsShowingSearchPageList(state),
    isShowingMobileFiltersForQueryParam: getSearchIsShowingMobileFilters(state),
    initialPropertyListLocationParams: getSearchViewport(state),
    shouldGoBackWhenClosingMobileFilters: getIsInsideNativeApp(state),
    isFourHundredPercentZoom: getIsFourHundredZoom(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSavedSearchState: () => dispatch(clearSavedSearchState()),
  reportDLErr: () => dispatch(reportHitDlPageOnInvalidDevice()),
  handleSaveSearch: (location: 'map' | 'list' | 'filters') => {
    dispatch(ensureLoggedInThenCreateSavedSearch(location));
  },
  handleCloseMobileFilters: (shouldGoBackWhenClosingMobileFilters: boolean) => {
    /* When inside a native app, we want the experience to resemble a native app: opening the filters
     * modal is going "deeper" into the app, so when we exit the filters modal (moving "shallower")
     * then go "back" in the app, we want to go even shallower, not go back to the filters modal */
    if (shouldGoBackWhenClosingMobileFilters) {
      dispatch(
        goBackOrDispatch(
          searchUpdateUrlParams({
            isShowingMobileFilters: false,
          })
        )
      );
      /* When we're in a standalone web app, going "back" after closing the filters modal should simply
       * return to the previous state, in this case reopening the just-closed filters modal */
    } else {
      dispatch(searchUpdateUrlParams({ isShowingMobileFilters: false }));
    }
  },
  reportInitialFilterState: () => dispatch(searchReportInitialFilterState()),
  searchFetchPropertyList: (params: BoundsObject) => {
    dispatch(searchFetchPropertyList({ bounds: params }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
