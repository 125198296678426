import { call, put } from 'redux-saga/effects';

import { miscellaneousApiClient } from '@client/services/miscellaneous-api-client';
import {
  fetchLegalContentSuccess,
  FETCH_LEGAL_CONTENT,
} from '@client/store/actions/legal-content.actions';
import { watchEvery } from '@client/utils/saga.utils';

/* Fetch legal content via pulling the HTML from different HouseCanary.com pages,
 * then parsing out the sought content by looking for a <comehomecontent/> HTML tag
 */
function* legalContentSaga(action) {
  try {
    const response = yield call(
      [miscellaneousApiClient, miscellaneousApiClient.getLegalContent],
      action.payload.url
    );
    yield put(fetchLegalContentSuccess(action.payload.key, response));
  } catch (e: any) {
    throw new Error('Legal content on HouseCanary.com not found');
  }
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [FETCH_LEGAL_CONTENT]: legalContentSaga,
  });
};
