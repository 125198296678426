/**
 * Creates a single time display string from given start and end time strings.
 * @param {string} startStr - start time string (e.g. 12:00AM)
 * @param {string} endStr - end time string (e.g. 12:00PM)
 */
export const format12HrTimesAsDisplayString = (
  startStr: string | null,
  endStr: string | null
): string => {
  if (!startStr) {
    return '';
  }
  const start = format12HrTimeForDisplay(startStr);
  if (start === '' || !endStr) {
    return start;
  }
  const end = format12HrTimeForDisplay(endStr);
  if (end === '') {
    return start;
  }
  return `${start} - ${end}`;
};

function format12HrTimeForDisplay(timeStr: string): string {
  const period = timeStr.slice(-2).toLowerCase();
  const parts = timeStr.slice(0, -2).split(':');

  if ((period !== 'am' && period !== 'pm') || parts.length !== 2) {
    return '';
  }
  /**
   * As per design, Min time format: 01:00PM, Max time format: 12:45PM
   * time slot looks like 01:00pm - 01:45pm
   */
  if (parts[0].length === 1) {
    return `${'0' + parts[0]}:${parts[1]}${period}`;
  } else {
    return `${parts[0]}:${parts[1]}${period}`;
  }
}
