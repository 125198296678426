import { themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import { Component } from 'react';

import ClickableLogo from '@client/components/ClickableLogo';
import MyHomeHeaderChase from '@client/components/cobrand/chase/MyHomeHeaderChase';
import CobrandedStyles from '@client/components/CobrandedStyles';
import DesktopNavItem from '@client/components/DesktopNavItem';
import HeaderLogoByHouseCanaryDarker from '@client/components/HeaderLogoByHouseCanaryDarker';
import HeaderMobileBottomSection from '@client/components/HeaderMobileBottomSection';
import HeaderMobileSearchHomesNav from '@client/components/HeaderMobileSearchHomesNav';
import MenuNotificationCount from '@client/components/MenuNotificationCount';
import AutoCompleteSearchContainer from '@client/containers/autocomplete-search.container';
import defaultTheme from '@client/css-modules/HeaderChase.css';
import ComeHomeLogoWithoutHouse from '@client/inline-svgs/come-home-logo-without-house';
import { ALL_SEARCH_VIEWS, View } from '@client/routes/constants';
import { EVENTS, PARENT_EVENTS } from '@client/store/analytics-constants';
import { HeaderProps } from '@client/store/types/cobranded-components/header';
import { getOutboundUrl } from '@client/utils/url-formatting.utils';
import { AnimatePresence, motion } from 'framer-motion';

type State = {
  isMobileMenuOpen: boolean;
};

/**
 * Due to SSR requirements, the desktop vs. mobile experiences are shown/hidden via CSS.
 * Each is grouped by a top-level container in the render method.
 */
export class Header extends Component<HeaderProps, State> {
  state: State = {
    isMobileMenuOpen: false,
  };

  componentDidMount() {
    const { isLoggedIn } = this.props;
    /* Only attempt alerts fetch if logged in, otherwise we display a message on the page */
    if (isLoggedIn) {
      this.fetchAlertsData();
    }
  }

  componentDidUpdate(prevProps: HeaderProps) {
    const { isLoggedIn } = this.props;

    /* When logging in/signing up FROM the alerts page, fetch alerts after authenticating */
    if (isLoggedIn && !prevProps.isLoggedIn) {
      this.fetchAlertsData();
    }
  }

  fetchAlertsData(): void {
    const { getUnreadAlertsCount, userId } = this.props;

    if (userId) {
      getUnreadAlertsCount();
    }
  }

  openMobileMenu = (): void => {
    this.setState({ isMobileMenuOpen: true });
  };

  closeMobileMenu = (): void => {
    this.setState({ isMobileMenuOpen: false });
  };

  localShowAuthModal = (): void => {
    this.setState({ isMobileMenuOpen: false });
    this.props.handleShowAuthModal();
  };

  onAddressSearchResultClick = (): void => {
    if (this.props.shouldCloseMenuOnAddressSearchResultClick) {
      this.closeMobileMenu();
    }
  };

  handleSignUp = () => {
    this.closeMobileMenu();
    this.props.handleShowAuthModal();
  };

  handleLogout = () => {
    this.closeMobileMenu();
    this.props.handleLogout();
  };

  handleClickScreen = () => {
    this.closeMobileMenu();
  };

  handleClearEmptyInput = () => {
    this.closeMobileMenu();
  };

  handleClearPopulatedInput = () => {
    const {
      handleClearConstrainedToPlace,
      shouldClearConstrainedPlaceOnClearingSearch,
    } = this.props;
    /* Clear the place boundary on the map and send subsequent property requests without a
     * place constraint */
    if (shouldClearConstrainedPlaceOnClearingSearch) {
      handleClearConstrainedToPlace();
    }
  };

  handleClickSearchButton = () => {
    const { currentView, handleClickAutoCompleteSearchButton } = this.props;
    if (
      currentView &&
      ALL_SEARCH_VIEWS.indexOf(currentView) > -1 &&
      handleClickAutoCompleteSearchButton
    ) {
      handleClickAutoCompleteSearchButton();
    }
  };

  handleGrantProgramHeaderNavClick = () => {
    const { grantProgramHeaderNavItem, accessToken, isOutboundCtaEnabled } =
      this.props;
    let url = grantProgramHeaderNavItem?.url;
    if (isOutboundCtaEnabled && grantProgramHeaderNavItem?.url && accessToken) {
      url = getOutboundUrl({
        originalCtaUrl: grantProgramHeaderNavItem.url,
        accessToken,
      });
    }
    window.open(url, '_blank');
  };

  render() {
    const {
      theme,
      isLoggedIn,
      isShowingPersistentSearchFieldBelowHeader,
      shouldDisplayCurrentLocationSearchOption,
      placeGeoJSONDescription,
      isDesktopHeaderFullWidth,
      isHidingPersistentSearchForPageOnTablet,
      handleRouteChange,
      currentView,
      unreadAlertsCount,
      isShowingBackNavButton,
      isHidingTopSection,
      shouldAlwaysUseRouteChangeOnComeHomeLogo,
      isYourTeamEnabled,
      isReferralServicesEnabled,
      userContactInfo,
      grantProgramHeaderNavItem,
      navLinkLabels,
    } = this.props;
    const { isMobileMenuOpen } = this.state;
    const isMobileMenuActive =
      isMobileMenuOpen || isShowingPersistentSearchFieldBelowHeader;

    return (
      <CobrandedStyles>
        {({ activeNavItemColor, headerBackgroundColor }) => (
          <>
            {/* Only displayed on small screens via CSS */}
            <div className={theme.MobileContainer}>
              {/* Hidden in native app:  */}
              {!isHidingTopSection && (
                <MyHomeHeaderChase
                  isDesktopHeaderFullWidth={isDesktopHeaderFullWidth}
                  headerBackgroundColor={headerBackgroundColor}
                />
              )}
              <div className={theme.MobileMenuContainer}>
                <AnimatePresence>
                  {isMobileMenuActive && (
                    <motion.nav
                      initial={{ height: 0, opacity: 0 }}
                      exit={{
                        height: 0,
                        opacity: 0,
                        transition: {
                          opacity: {
                            duration: 0.1,
                          },
                          height: {
                            duration: 0.2,
                          },
                        },
                      }}
                      animate={{
                        opacity: 1,
                        height: 'initial',
                        transition: {
                          ease: 'easeInOut',
                          duration: isMobileMenuActive ? 0.1 : 0.2,
                        },
                      }}
                      key="menu"
                      aria-label="Navigation Menu"
                      className={classNames(theme.MobileSearchItemAnimation, {
                        [theme.MobileSearchItemAnimationHiddenOnTablet]:
                          isHidingPersistentSearchForPageOnTablet,
                      })}
                    >
                      <ul className={theme.MobileMenuList}>
                        <li
                          key={'SearchContainer'}
                          className={theme.SearchItem}
                        >
                          {/* Only focus autocomplete input on mount when not logged in,
                            otherwise saved searches would automatically be shown, blocking other menu items */}
                          <AutoCompleteSearchContainer
                            /* Only focus the input on mount if it's not persistently shown and if the user is logged-out
                             * since otherwise the saved searches will be displayed which might be jarring */
                            focusInputOnMount={
                              !isShowingPersistentSearchFieldBelowHeader &&
                              !isLoggedIn
                            }
                            theme={theme}
                            onResultClick={this.onAddressSearchResultClick}
                            prefilledUserInput={placeGeoJSONDescription}
                            handleClearPopulatedInput={
                              this.handleClearPopulatedInput
                            }
                            handleClearEmptyInput={this.handleClearEmptyInput}
                            handleClickSearchButton={
                              this.handleClickSearchButton
                            }
                            shouldDisplayCurrentLocationOption={
                              shouldDisplayCurrentLocationSearchOption
                            }
                            hasVisibleLabel
                          />
                        </li>
                      </ul>
                    </motion.nav>
                  )}
                </AnimatePresence>
                {isMobileMenuActive &&
                  !isShowingPersistentSearchFieldBelowHeader && (
                    <div
                      className={theme.MobileMenuScreen}
                      onClick={this.handleClickScreen}
                    />
                  )}
              </div>
              <HeaderMobileBottomSection
                isShowingBackNavButton={isShowingBackNavButton}
                currentView={currentView}
                isMobileMenuOpen={isMobileMenuOpen}
                theme={theme}
              />
              <HeaderMobileSearchHomesNav theme={theme} />
            </div>

            {/* Only displayed on large screens via CSS */}
            <div className={theme.DesktopContainer}>
              {!isHidingTopSection && (
                <MyHomeHeaderChase
                  isDesktopHeaderFullWidth={isDesktopHeaderFullWidth}
                  headerBackgroundColor={headerBackgroundColor}
                />
              )}
              <div
                className={classNames(theme.DesktopInnerContainer, {
                  [theme.DesktopInnerContainerFullWidth]:
                    isDesktopHeaderFullWidth,
                })}
              >
                <div className={theme.DesktopNavContainer}>
                  <DesktopNavItem
                    dataHcName={'buy-home-button'}
                    theme={theme}
                    view={View.SEARCH}
                    currentView={currentView}
                    activeColor={activeNavItemColor}
                    onClick={() => handleRouteChange(View.SEARCH)}
                    hasActiveColorWhenHovered
                    underlineWhenSelected
                  >
                    { navLinkLabels.navLinkSearchLabel || "Find a home"}
                  </DesktopNavItem>
                  <DesktopNavItem
                    dataHcName={'saved-button'}
                    theme={theme}
                    view={[View.WATCHLIST, View.SAVED_SEARCHES]}
                    currentView={currentView}
                    activeColor={activeNavItemColor}
                    onClick={() => handleRouteChange(View.WATCHLIST)}
                    hasActiveColorWhenHovered
                    underlineWhenSelected
                  >
                     {navLinkLabels.navLinkWatchlistLabel || "Saved" }
                  </DesktopNavItem>
                  <DesktopNavItem
                    theme={theme}
                    dataHcName={'alerts-button'}
                    view={View.ALERTS}
                    currentView={currentView}
                    activeColor={activeNavItemColor}
                    onClick={() => handleRouteChange(View.ALERTS)}
                    hasActiveColorWhenHovered
                    underlineWhenSelected
                  >
                     {navLinkLabels.navLinkAlertsLabel || "Alerts" }
                    <MenuNotificationCount
                      theme={theme}
                      count={unreadAlertsCount}
                    />
                  </DesktopNavItem>
                  {isYourTeamEnabled && isReferralServicesEnabled && (
                    <DesktopNavItem
                      theme={theme}
                      dataHcName={'find-an-agent-button'}
                      dataEventName={EVENTS.CLICK_FIND_AGENT_HEADER}
                      dataParentEventName={PARENT_EVENTS.CLICK_NAV}
                      dataEventDataJson={userContactInfo}
                      view={View.CONCIERGE_TEAM}
                      currentView={currentView}
                      onClick={() => handleRouteChange(View.CONCIERGE_TEAM)}
                      activeColor={activeNavItemColor}
                      hasActiveColorWhenHovered
                    >
                      Find an agent
                    </DesktopNavItem>
                  )}
                  {grantProgramHeaderNavItem && (
                    <DesktopNavItem
                      view={null}
                      theme={theme}
                      dataHcName={grantProgramHeaderNavItem.dataHcName}
                      dataEventName={grantProgramHeaderNavItem.dataEventName!}
                      dataParentEventName={
                        grantProgramHeaderNavItem.dataParentEventName!
                      }
                      currentView={currentView}
                      onClick={this.handleGrantProgramHeaderNavClick}
                      activeColor={activeNavItemColor}
                      hasActiveColorWhenHovered
                    >
                      {grantProgramHeaderNavItem.title}
                    </DesktopNavItem>
                  )}
                </div>
                <div className={theme.DesktopLogoContainer}>
                  <ClickableLogo
                    dataHcName={'comehome-logo'}
                    Logo={(props) => (<ComeHomeLogoWithoutHouse {...props} role="img" />)}
                    LogoByHouseCanary={HeaderLogoByHouseCanaryDarker}
                    theme={theme}
                    alwaysUseRouteChange={
                      shouldAlwaysUseRouteChangeOnComeHomeLogo
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </CobrandedStyles>
    );
  }
}

const ThemedHeader = themr('Header', defaultTheme)(Header);
export default ThemedHeader;
