import { useSelector } from 'react-redux';

import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import ComeHomeLogoPoweredByImg from '@client/components/ComeHomeLogoPoweredByImg';

type Props = {
  className?: string;
};

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    default:
      return ComeHomeLogoPoweredByImg;
  }
};

const FooterLogo: React.FC<Props> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return <Component {...props} />;
};

export default FooterLogo;
