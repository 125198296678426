import { AlertData } from '@client/store/types/alerts';

export const FETCH_ALERTS = 'FETCH_ALERTS';
export const FETCH_ALERTS_SUCCESS = 'FETCH_ALERTS_SUCCESS';
export const FETCH_ALERTS_ERROR = 'FETCH_ALERTS_ERROR';
export const ALERTS_MARK_ALL_READ = 'ALERTS_MARK_ALL_READ';
export const ALERTS_MARK_SINGLE_READ = 'ALERTS_MARK_SINGLE_READ';
export const ALERTS_GO_TO_PROPERTY = 'ALERTS_GO_TO_PROPERTY';
export const ALERTS_FETCH_NEXT_PAGE = 'ALERTS_FETCH_NEXT_PAGE';
export const FETCH_ALERTS_UNREAD_COUNT = 'FETCH_ALERTS_UNREAD_COUNT';
export const FETCH_ALERTS_UNREAD_COUNT_SUCCESS =
  'FETCH_ALERTS_UNREAD_COUNT_SUCCESS';
export const RESET_UNREAD_ALERTS_SUCCESS = 'RESET_UNREAD_ALERTS_SUCCESS';
export const FETCH_ALERT_MLS_DETAILS = 'FETCH_ALERT_MLS_DETAILS';
export const FETCH_ALERT_MLS_DETAILS_SUCCESS =
  'FETCH_ALERT_MLS_DETAILS_SUCCESS';
export const ENSURE_LOGIN_THEN_REDIRECT_TO_NOTIFICATIONS_PAGE =
  'ENSURE_LOGIN_THEN_REDIRECT_TO_NOTIFICATIONS_PAGE';

export type FetchAlertMlsDetails = {
  type: typeof FETCH_ALERT_MLS_DETAILS;
  payload: {
    slug: string;
  };
};

export const fetchAlertMlsDetails = (slug: string): FetchAlertMlsDetails => ({
  type: FETCH_ALERT_MLS_DETAILS,
  payload: {
    slug,
  },
});

type MlsPhotoDetails = {
  regulations: {
    logoOverlay: string | null;
    photosLogoOverlay: boolean | null;
  } | null;
};

type FetchAlertMlsDetailsSuccess = {
  type: typeof FETCH_ALERT_MLS_DETAILS_SUCCESS;
  payload: {
    slug: string;
    mlsPhotoOverlayDetails: MlsPhotoDetails;
  };
};

export const fetchAlertMlsDetailsSuccess = ({
  slug,
  mlsPhotoOverlayDetails,
}: {
  slug: string;
  mlsPhotoOverlayDetails: MlsPhotoDetails;
}): FetchAlertMlsDetailsSuccess => ({
  type: FETCH_ALERT_MLS_DETAILS_SUCCESS,
  payload: { slug, mlsPhotoOverlayDetails },
});

type FetchAlerts = {
  type: typeof FETCH_ALERTS;
};

export const fetchAlerts = (): FetchAlerts => ({
  type: FETCH_ALERTS,
});

type FetchAlertsSuccess = {
  type: typeof FETCH_ALERTS_SUCCESS;
  payload: {
    alerts: AlertData[];
    markAsReadUrl: string;
    nextPageUrl: string | undefined;
  };
};

export const fetchAlertsSuccess = ({
  alerts,
  markAsReadUrl,
  nextPageUrl,
}: {
  alerts: AlertData[];
  markAsReadUrl: string;
  nextPageUrl: string;
}): FetchAlertsSuccess => ({
  type: FETCH_ALERTS_SUCCESS,
  payload: {
    alerts,
    markAsReadUrl,
    nextPageUrl,
  },
});

type FetchAlertsUnreadCount = {
  type: typeof FETCH_ALERTS_UNREAD_COUNT;
};

export const fetchAlertsUnreadCount = (): FetchAlertsUnreadCount => ({
  type: FETCH_ALERTS_UNREAD_COUNT,
});

type FetchAlertsUnreadCountSuccess = {
  type: typeof FETCH_ALERTS_UNREAD_COUNT_SUCCESS;
  payload: {
    unreadAlertsCount: number;
  };
};

export const fetchAlertsUnreadCountSuccess = (
  unreadAlertsCount: number
): FetchAlertsUnreadCountSuccess => ({
  type: FETCH_ALERTS_UNREAD_COUNT_SUCCESS,
  payload: {
    unreadAlertsCount,
  },
});

type FetchAlertsError = {
  type: typeof FETCH_ALERTS_ERROR;
};

export const fetchAlertsError = (): FetchAlertsError => ({
  type: FETCH_ALERTS_ERROR,
});

type AlertsMarkAllRead = {
  type: typeof ALERTS_MARK_ALL_READ;
};

export const alertsMarkAllRead = (): AlertsMarkAllRead => ({
  type: ALERTS_MARK_ALL_READ,
});

type AlertsFetchNextPage = {
  type: typeof ALERTS_FETCH_NEXT_PAGE;
};

export const alertsFetchNextPage = (): AlertsFetchNextPage => ({
  type: ALERTS_FETCH_NEXT_PAGE,
});

export type GoToAlertProperty = {
  type: typeof ALERTS_GO_TO_PROPERTY;
  payload: {
    slug: string;
    addressId: number;
  };
};

export const goToAlertProperty = ({
  slug,
  addressId,
}: {
  slug: string;
  addressId: number;
}): GoToAlertProperty => ({
  type: ALERTS_GO_TO_PROPERTY,
  payload: {
    slug,
    addressId,
  },
});

type ResetUnreadAlertsSuccess = {
  type: typeof RESET_UNREAD_ALERTS_SUCCESS;
};

export const resetUnreadAlertsSuccess = (): ResetUnreadAlertsSuccess => ({
  type: RESET_UNREAD_ALERTS_SUCCESS,
});

type EnsureLoggedInThenRedirectToNotificationsPage = {
  type: typeof ENSURE_LOGIN_THEN_REDIRECT_TO_NOTIFICATIONS_PAGE;
};

export const ensureLoggedInThenRedirectToNotificationsPage =
  (): EnsureLoggedInThenRedirectToNotificationsPage => ({
    type: ENSURE_LOGIN_THEN_REDIRECT_TO_NOTIFICATIONS_PAGE,
  });

export type AlertsAction =
  | FetchAlerts
  | FetchAlertsSuccess
  | FetchAlertMlsDetails
  | FetchAlertMlsDetailsSuccess
  | FetchAlertsUnreadCount
  | FetchAlertsUnreadCountSuccess
  | FetchAlertsError
  | AlertsMarkAllRead
  | AlertsFetchNextPage
  | GoToAlertProperty
  | ResetUnreadAlertsSuccess
  | EnsureLoggedInThenRedirectToNotificationsPage;
