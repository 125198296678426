import { Request } from 'express';
import { Variant } from 'ab-test-jsx';
import { FeatureId } from '../client/store/types/feature-flags';

export const AB_TESTS = {
  SAVE_BUTTON_HOVER_STATE:
    'save-button-hover-state' as 'save-button-hover-state',
  FIND_AGENT_CTA: 'find-agent-cta' as 'find-agent-cta',
  TEST_NEARBY_SALES: 'test-nearby-sales' as 'test-nearby-sales',
};

type AbTestsKeys = (typeof AB_TESTS)[keyof typeof AB_TESTS];

export type ABTests =
  | {
      [key in AbTestsKeys]: Variant | undefined;
    }
  | {};

export const VARIANTS = {
  A: 'A' as Variant,
  B: 'B' as Variant,
};

export const AB_TEST_FEATURE_FLAGS_ID: { [key in AbTestsKeys]: FeatureId } = {
  /* TODO: update AB test feature flags */
  [AB_TESTS.SAVE_BUTTON_HOVER_STATE]: 'save_button_hover_state',
  [AB_TESTS.FIND_AGENT_CTA]: 'test_ab_test_1',
  [AB_TESTS.TEST_NEARBY_SALES]: 'test_ab_test_2',
};

export const AB_TEST_QUERY_PREFIX = 'ABTEST_';

/* Convert variants object to a format expected by <ABTestsProvider> (replacing null with undefined
 * for tests that are disabled via feature flag) */
export const getFormattedABTestsData = (abTestsObj: ABTests) => {
  let formattedDataObj = {};
  for (let key in abTestsObj) {
    formattedDataObj[key] = abTestsObj[key] || undefined;
  }
  return formattedDataObj as ABTests;
};

export interface CanaryRequest extends Request {
  cookies: { [key: string]: string | undefined };
  enabledFeatures: FeatureId[];
  abTests: ABTests;
}
