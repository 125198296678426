/**
 * Keep top-level keys in alpha order please
 */
/**
 * Keep top-level keys in alpha order please
 */

export default ({
  slug,
  bedsAdditionalSqFt,
  bathAdditionalSqFt,
  squareFootageAdditionalSqFt,
}) => ({
  query: `query HomeownerHomeImprovementsQuery(
    $slug: String,
    $bedsAdditionalSqFt: Int,
    $bathAdditionalSqFt: Int
  ) {
    propertyLookup (id: { slug: $slug}) {
      improvements {
        beds (additionalSqFt: $bedsAdditionalSqFt) {
          additionalSqFt
          cost
          value
        }
        bath (additionalSqFt: $bathAdditionalSqFt) {
          additionalSqFt
          cost
          value
        }
        addition (additionalSqFt: $squareFootageAdditionalSqFt) {
          additionalSqFt
          cost
          value
        }
        pool {
          additionalSqFt
          cost
          value
        }
      }
    }
  }`,
  variables: {
    slug,
    bedsAdditionalSqFt,
    bathAdditionalSqFt,
    squareFootageAdditionalSqFt,
  },
});
