import { Component } from 'react';
import { connect } from 'react-redux';
import {
  columnLayoutChange,
  screenResize,
  screenResizeZoomChange,
} from '@client/store/actions/match-media.actions';
import theme from '@client/css-modules/_column_breakpoints.css';

const {
  oneColumnLayoutMaxWidth,
  twoColumnLayoutMinWidth,
  twoColumnLayoutMaxWidth,
  threeColumnLayoutMinWidth,
} = theme;

/** Component that listens for viewport size change events, dispatching an action when
 * an event is received, which allows us to set browser size on state. This component
 * renders nothing, but must be connected to state and make use of React lifecycle
 * methods to function.
 */

type Props = {
  resize: ({
    mediaQuery,
    screenSize,
    deviceIOS,
    deviceAndroid,
  }: {
    mediaQuery: string;
    screenSize: string;
    deviceIOS: boolean;
    deviceAndroid: boolean;
  }) => void;
  columnLayoutChange: ({
    columnLayoutMediaQuery,
    columnLayout,
  }: {
    columnLayoutMediaQuery: string;
    columnLayout: string;
  }) => void;
  screenSizeZoomChange: ({
    screenResizeZoomMediaQuery,
    screenZoomSize,
  }: {
    screenResizeZoomMediaQuery: string;
    screenZoomSize: string | null | undefined;
  }) => void;
};

export type ScreenSize = 'xxsmall' | 'xsmall' | 'small' | 'tablet' | 'xlarge';

const COLUMN_LAYOUT_SIZES = [
  'onecolumn' as 'onecolumn',
  'twocolumn' as 'twocolumn',
  'threecolumn' as 'threecolumn',
];
export type ColumnLayoutScreenSize = (typeof COLUMN_LAYOUT_SIZES)[number];

const isColumnSize = (
  size: ColumnLayoutScreenSize | ScreenZoomScreenSize | ScreenSize
): size is ColumnLayoutScreenSize => {
  return COLUMN_LAYOUT_SIZES.includes(size as ColumnLayoutScreenSize);
};

const SCREEN_ZOOM_SIZES = [
  'fourhundredzoom' as 'fourhundredzoom',
];

export type ScreenZoomScreenSize = (typeof SCREEN_ZOOM_SIZES)[number];

const isScreenZoomSize = (
  size: ScreenZoomScreenSize | ColumnLayoutScreenSize | ScreenSize
): size is ScreenZoomScreenSize => {
  return SCREEN_ZOOM_SIZES.includes(size as ScreenZoomScreenSize);
};

class MatchMedia extends Component<Props> {
  unsubscribeXXSmall: (() => void) | null = null;
  unsubscribeXSmall: (() => void) | null = null;
  unsubscribeSmall: (() => void) | null = null;
  unsubscribeTablet: (() => void) | null = null;
  unsubscribeXLarge: (() => void) | null = null;
  unsubscribeOneColumnLayout: (() => void) | null = null;
  unsubscribeTwoColumnLayout: (() => void) | null = null;
  unsubscribeThreeColumnLayout: (() => void) | null = null;
  unsubscribeFourHundredZoom: (() => void) | null = null;

  subscribe = () => {
    /* Note that the ranges defined here CANNOT overlap, else weird behavior will ensue */
    this.unsubscribeXXSmall = this.addMq(
      '(min-width: 1px) and (max-width: 349px)',
      'xxsmall'
    );
    this.unsubscribeXSmall = this.addMq(
      '(min-width: 350px) and (max-width: 599px)',
      'xsmall'
    );
    this.unsubscribeSmall = this.addMq(
      '(min-width: 600px) and (max-width: 767px)',
      'small'
    );
    this.unsubscribeTablet = this.addMq(
      '(min-width: 768px) and (max-width: 1024px)',
      'tablet'
    );
    this.unsubscribeXLarge = this.addMq(
      'all and (min-width: 1025px)',
      'xlarge'
    );
    this.unsubscribeOneColumnLayout = this.addMq(
      `(min-width: 1px) and (max-width: ${oneColumnLayoutMaxWidth})`,
      'onecolumn'
    );
    this.unsubscribeTwoColumnLayout = this.addMq(
      `(min-width: ${twoColumnLayoutMinWidth}) and (max-width: ${twoColumnLayoutMaxWidth})`,
      'twocolumn'
    );
    this.unsubscribeThreeColumnLayout = this.addMq(
      `(min-width: ${threeColumnLayoutMinWidth})`,
      'threecolumn'
    );
    this.unsubscribeFourHundredZoom = this.addMq(
      '(width <= 320px) and (height < 480px)',
      'fourhundredzoom'
    );

  };

  unsubscribe = () => {
    if (this.unsubscribeXXSmall) this.unsubscribeXXSmall();
    if (this.unsubscribeXSmall) this.unsubscribeXSmall();
    if (this.unsubscribeSmall) this.unsubscribeSmall();
    if (this.unsubscribeTablet) this.unsubscribeTablet();
    if (this.unsubscribeXLarge) this.unsubscribeXLarge();
    if (this.unsubscribeOneColumnLayout) this.unsubscribeOneColumnLayout();
    if (this.unsubscribeTwoColumnLayout) this.unsubscribeTwoColumnLayout();
    if (this.unsubscribeThreeColumnLayout) this.unsubscribeThreeColumnLayout();
    if (this.unsubscribeFourHundredZoom) this.unsubscribeFourHundredZoom();
  };

  addMq = (
    mq: string,
    size: ScreenSize | ColumnLayoutScreenSize | ScreenZoomScreenSize
  ) => {
    const query = window.matchMedia(mq);
    let handler;
    if (isColumnSize(size)) {
      handler = this.handleColumnLayoutChange.bind(null, size);
    } else if (isScreenZoomSize(size)) {
      handler = this.handleScreenZoomChange.bind(null, size);
    } else {
      handler = this.handleScreenSizes.bind(null, size);
    }
    /* query.addEventListener not current supported in Safari */
    if (query.addEventListener) {
      query.addEventListener('change', handler);
    } else {
      /* tslint:disable-next-line:deprecation */
      query.addListener(handler);
    }
    handler(query);
    return () => {
      /* query.removeEventListener not current supported in Safari */
      if (query.removeEventListener) {
        query.removeEventListener('change', handler);
      } else {
        /* tslint:disable-next-line:deprecation */
        query.removeListener(handler);
      }
    };
  };

  handleScreenSizes = (size: ScreenSize, mql: any) => {
    if (mql.matches) {
      this.props.resize({
        mediaQuery: mql.media,
        screenSize: size,
        deviceIOS: !!navigator.userAgent.match(/(iPad|iPhone|iPod)/i),
        deviceAndroid: !!navigator.userAgent.match(/android/i),
      });
    }
  };

  handleColumnLayoutChange = (size: ColumnLayoutScreenSize, mql: any) => {
    if (mql.matches) {
      this.props.columnLayoutChange({
        columnLayoutMediaQuery: mql.media,
        columnLayout: size,
      });
    }
  };

  handleScreenZoomChange = (size: ScreenZoomScreenSize, mql: any) => {
    if (mql.matches) {
      this.props.screenSizeZoomChange({
        screenResizeZoomMediaQuery: mql.media,
        screenZoomSize: size,
      });
    } else {
      this.props.screenSizeZoomChange({
        screenResizeZoomMediaQuery: mql.media,
        screenZoomSize: undefined
      })
    }
  };

  componentDidMount() {
    this.subscribe();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render = () => null;
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  resize: (props) => {
    dispatch(screenResize(props));
  },
  columnLayoutChange: (props) => {
    dispatch(columnLayoutChange(props));
  },
  screenSizeZoomChange: (props) => {
    dispatch(screenResizeZoomChange(props));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchMedia);
