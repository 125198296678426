import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { pull } from 'lodash';
import { Component } from 'react';

import Checkbox from '@client/components/generic/Checkbox';
import defaultTheme from '@client/css-modules/MultiSelect.css';
import { FE_ONLY_MLS_STATES } from '@client/store/filter-constants';
import { SpecialUserType } from '@client/store/types/locked-components';

type Props<T> = {
  dataHcName?: string;
  /* Will cause the box to be checked if the value is not in the value list */
  inverseBehavior?: boolean;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
  theme: Theme;
  options: Array<{ label: string; value: T }>;
  onChange: (newValues: T[], valueAdded: T | null) => void;
  handleReportValueSelection: (value: T) => void;
  filterKey?: string;
  values: T[];
  label: string;
};
/**
 * This control renders selectable checkboxes for each option passed
 */
class MultiSelect<T> extends Component<Props<T>, {}> {
  updateValues = (value: T): void => {
    const { onChange, values, handleReportValueSelection } = this.props;
    let wasAdded = true;
    if (onChange) {
      const isSelecting = !values.includes(value);
      let newValues = values.slice();

      if (isSelecting) {
        newValues.push(value);
        handleReportValueSelection(value);
      } else {
        pull(newValues, value);
        wasAdded = false;
      }
      onChange(newValues, wasAdded ? value : null);
    }
  };

  render() {
    const {
      theme,
      filterKey,
      options,
      values,
      label,
      inverseBehavior,
      dataHcName,
      ariaLabelledBy,
      ariaDescribedBy,
    } = this.props;
    const shouldUseOwnLabel = !!(!ariaLabelledBy && label);

    return (
      <div
        className={theme.MultiSelect}
        data-hc-name={dataHcName ? dataHcName : 'multi-select'}
      >
        {shouldUseOwnLabel && (
          <label className={theme.MultiSelectGroupLabel}>{label}</label>
        )}
        <div
          className={theme.MultiSelectGroup}
          role="group"
          aria-labelledby={ariaLabelledBy ? ariaLabelledBy : label}
        >
          {options.map((option) => (
            <Checkbox
              ariaDescribedBy={ariaDescribedBy}
              key={`${option.value}`}
              lockedComponent={{
                sectionId: `${filterKey}-${option.value}`,
                lockedFor: [SpecialUserType.Restricted],
              }}
              theme={theme}
              label={
                option.value !== FE_ONLY_MLS_STATES.ALL ? option.label : 'All'
              }
              name={
                option.value !== FE_ONLY_MLS_STATES.ALL ? option.label : 'All'
              }
              onClick={(e) => this.updateValues(option.value)}
              isChecked={
                inverseBehavior
                  ? values.indexOf(option.value) === -1
                  : values.indexOf(option.value) > -1
              }
            />
          ))}
        </div>
      </div>
    );
  }
}

const ThemedMultiSelect = themr('MultiSelect', defaultTheme)(MultiSelect);
export default ThemedMultiSelect;
