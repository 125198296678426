import React from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import AlertUserIcon from '@client/inline-svgs/alert-user';
import { View } from '@client/routes/constants';
import defaultTheme from '@client/css-modules/AdBlockDetection.css';

type AdBlockDetectionProps = {
  currentView: View | null;
  theme: Theme;
};

type AdBlockDetectionState = {
  adBlockDetected: boolean;
};

// This is an Id of the container that is used as bait for ad blocker
const adBannerParentId = 'adBannerParent';

class AdBlockDetection extends React.Component<
  AdBlockDetectionProps,
  AdBlockDetectionState
> {
  static defaultProps = {
    currentView: null,
  };

  state = {
    adBlockDetected: false,
  };

  componentDidMount() {
    this.detectAdBlocker();
  }

  componentDidUpdate(nextProps: AdBlockDetectionProps) {
    // On page redirects check if there is an adblocker
    if (this.props.currentView !== nextProps.currentView) {
      this.detectAdBlocker();
    }
  }

  detectAdBlocker() {
    const noAdBlockDetected = () => {
      this.setState({
        adBlockDetected: false,
      });
    };

    const adBlockDetected = () => {
      this.setState({
        adBlockDetected: true,
      });
    };

    const adBannerParent = document.getElementById(adBannerParentId);
    /*
      Check for the height of the element to know if user has an ad blocker.
      If height of the element is greater than zero, there is no ad blocker.
    */
    if (adBannerParent && adBannerParent.offsetHeight > 0) {
      noAdBlockDetected();
    } else {
      adBlockDetected();
    }
  }

  render() {
    const { adBlockDetected } = this.state;
    const { theme } = this.props;
    return (
      <>
        {/* This is a bait for ad blocker */}
        <div id={adBannerParentId} style={{ position: 'absolute' }}>
          {/*
            Styles must be defined here for ad block detection to work.
            Class name must be adBanner, ad blockers block elements with
            such class names from being rendered on the page.
          */}
          <div
            className="adBanner"
            style={{
              backgroundColor: 'transparent',
              height: '1px',
              width: '1px',
              content: ' ',
            }}
          />
        </div>
        {adBlockDetected && (
          <div className={theme.AdBlockMessageContainer}>
            <div
              className={theme.WarningIcon}
              aria-label="Warning Icon for ad blocker detection banner"
            >
              <AlertUserIcon />
            </div>
            <div className={theme.MessageStyling}>
              Do you have your ad blocker on? Turn it off for the best ComeHome
              experience.
            </div>
          </div>
        )}
      </>
    );
  }
}

export default themr('AdBlockDetection', defaultTheme)(AdBlockDetection);
