import { createSelector } from 'reselect';

import { ReduxState } from '@client/store/types/redux-state';
import { ACTIVITY_IDS } from '@client/store/constants';

export function getActivitiesState(state: ReduxState) {
  return state.localActivities;
}

export const getLocalActivitiesStatus = createSelector(
  getActivitiesState,
  (activitiesState) => activitiesState.status
);

export const getAllLocalActivities = createSelector(
  getActivitiesState,
  (activitiesState) => activitiesState.results
);

export const getLocalActivitiesActiveFilter = createSelector(
  getActivitiesState,
  (activitiesState) => activitiesState.activeFilter
);

export const getLocalActivitiesActiveFilterTitle = createSelector(
  getActivitiesState,
  (activitiesState) => activitiesState.activeFilterTitle
);

export const getLocalActivitiesResultCount = createSelector(
  getActivitiesState,
  (activitiesState) => activitiesState.counts[ACTIVITY_IDS.ALL]
);

export const getLocalActivitiesCounts = createSelector(
  getActivitiesState,
  (activitiesState) => activitiesState.counts
);

export const getLocalActivitiesSearchRadius = createSelector(
  getActivitiesState,
  (activitiesState) => activitiesState.searchRadius
);

export const getBusinessSummary = createSelector(
  getActivitiesState,
  (activitiesState) => activitiesState.businessSummary
);
