import React from 'react';

import DismissibleBanner from '@client/components/DismissibleBanner';
import theme from '@client/css-modules/UpdateEmailBanner.css';
import EmailIconCobranded from '@client/components/EmailIcon/EmailIconCobranded';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type Props = {
  isSmallScreen: boolean;
  handleUpdateEmailModalOpen: () => void;
  handleCloseBanner: () => void;
};

const UpdateUserEmailBanner: React.FunctionComponent<Props> = ({
  isSmallScreen,
  handleCloseBanner,
  handleUpdateEmailModalOpen,
}) => {
  const { dismissableBannerLinkColor } = useCobrandStyles();

  return (
    <div className={theme.UpdateEmailBanner}>
      <DismissibleBanner
        active={true}
        onClose={handleCloseBanner}
        theme={theme}
        ariaLabelledby="update-email-banner"
      >
        <div
          className={theme.UpdateEmailBannerContent}
          data-testid="update-email-banner"
          data-hc-name="update-email-banner"
        >
          {isSmallScreen ? (
            <div className={theme.BannerText} id="update-email-banner">
              We are having difficulty with your email address.
              <div>
                <span
                  className={theme.UpdateNow}
                  onClick={handleUpdateEmailModalOpen}
                  style={{ color: dismissableBannerLinkColor }}
                >
                  update now
                </span>
                &nbsp;or in your profile settings.
              </div>
            </div>
          ) : (
            <>
              <EmailIconCobranded className={theme.EmailIcon} />
              <div className={theme.BannerText}>
                Whoops! It seems we are having difficulty with your email
                address. You can&nbsp;
                <span
                  className={theme.UpdateNow}
                  onClick={handleUpdateEmailModalOpen}
                  style={{ color: dismissableBannerLinkColor }}
                >
                  update now
                </span>
                &nbsp;or in your profile settings.
              </div>
            </>
          )}
        </div>
      </DismissibleBanner>
    </div>
  );
};

export default UpdateUserEmailBanner;
