import { View } from '@client/routes/constants';
import {
  Credentials,
  login,
  logOut,
  logOutThenRedirect,
} from '@client/store/actions/auth.actions';
import {
  getCurrentUser,
  getIsLoggedIn,
  getLoginErrorMessage,
  getResetPasswordErrorMessage,
  getSignUpErrorMessage,
  getValidationErrors,
} from '@client/store/selectors/auth.selectors';
import { AfterAuthAction } from '@client/store/types/auth';
import { useDispatch, useSelector } from 'react-redux';

type LoginUserFunc = {
  credentials: Credentials;
  routeAfterLoginSuccess?: View;
  afterAuthAction?: AfterAuthAction;
};

export function useAuth() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const user = useSelector(getCurrentUser);
  const loginError = useSelector(getLoginErrorMessage);
  const signupError = useSelector(getSignUpErrorMessage);
  const validationError = useSelector(getValidationErrors);
  const resetPasswordError = useSelector(getResetPasswordErrorMessage);

  const logInUser = ({
    credentials,
    routeAfterLoginSuccess,
    afterAuthAction,
  }: LoginUserFunc) => {
    dispatch(login(credentials, routeAfterLoginSuccess, afterAuthAction));
  };

  const logOutUser = (redirect?: string) => {
    if (redirect) {
      dispatch(logOutThenRedirect(redirect));
    } else {
      dispatch(logOut());
    }
  };

  return {
    isLoggedIn,
    loginError,
    logInUser,
    logOutUser,
    user,
    signupError,
    resetPasswordError,
    validationError,
  };
}
