import React from 'react';
import { useSelector } from 'react-redux';

import theme from '@client/css-modules/UnsubscribePage.css';
import { View } from '@client/routes/constants';
import RouterLink from '@client/components/RouterLink';
import { useUnsubscribeEmailBrandingProps } from '@client/hooks/unsubscribe-email-branding.hooks';
import { getIsLoggedIn } from '@client/store/selectors/auth.selectors';
import GreenCheckMarkIconCobranded from '@client/components/GreenCheckMarkIcon/GreenCheckMarkIconCobranded';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type Props = {
  query: {
    email?: string;
  };
};

const UnsubscribePage: React.FC<Props> = ({ query }) => {
  const userEmail = query.email && window.atob(query.email);
  const { cobrandDisplayName, isFeatureEnabled, isLender } =
    useUnsubscribeEmailBrandingProps();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const { linkColor } = useCobrandStyles();

  return isFeatureEnabled ? (
    <div className={theme.UnsubscribePage}>
      <div className={theme.TitleContainer}>
        <div className={theme.IconWrapper}>
          <GreenCheckMarkIconCobranded className={theme.GreenCheckIcon} />
        </div>
        <div className={theme.Title}>Unsubscribe successful</div>
      </div>
      <div className={theme.Message}>
        {userEmail && `Thanks, ${userEmail}. `}
        {isLender
          ? `You have been unsubscribed from all ComeHome alerts sent on behalf of ${cobrandDisplayName}.`
          : 'You have been unsubscribed from all ComeHome alerts.'}
      </div>
      {!isLoggedIn && (
        <div className={theme.LinksContainer}>
          <div className={theme.MessageAndLink}>
            <RouterLink
              className={theme.Link}
              style={{ color: linkColor }}
              view={View.LOGIN}
              ariaLabel="Log in"
            >
              Log in
            </RouterLink>
            &nbsp;or&nbsp;
            <RouterLink
              className={theme.Link}
              style={{ color: linkColor }}
              view={View.SIGN_UP}
              ariaLabel="create an account"
            >
              create an account
            </RouterLink>
            &nbsp;to customize the notifications you receive.
          </div>
        </div>
      )}
      {isLoggedIn && (
        <div className={theme.LinksContainer}>
          <div className={theme.MessageAndLink}>
            <RouterLink
              className={theme.Link}
              style={{ color: linkColor }}
              view={View.NOTIFICATIONS}
              ariaLabel="Manage alerts"
            >
              Manage alerts
            </RouterLink>
            &nbsp;that ComeHome sends to you
            {isLender && ` on behalf of ${cobrandDisplayName}`}.
          </div>
        </div>
      )}
    </div>
  ) : (
    /**
     * Keeping the old unsubscribed page
     * since we are shipping the new unsubscribe email branding with a feature flag
     */
    <div className={theme.UnsubscribePage}>
      {userEmail
        ? `Thanks, ${userEmail}, you have been unsubscribed.`
        : 'You have been unsubscribed'}
    </div>
  );
};

export default UnsubscribePage;
