import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import { useSelector } from 'react-redux';

import { getIsWellsFargoCobrand } from '@client/store/selectors/cobranding.selectors';
import YourTeamTriggerButton from '@client/components/YourTeam/YourTeamTriggerButton';
import YourTeamTriggerButtonWells from '@client/components/YourTeam/YourTeamTriggerButtonWells';
import { YourTeamModalType } from '@client/store/types/your-team';
import { useYourTeamModalState } from '@client/hooks/your-team.hooks';
import { getSearchIsShowingMobileFilters } from '@client/store/selectors/search.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { showYourTeamModalForRoute } from '@client/store/slices/your-team.slice';
import defaultTheme from '@client/css-modules/YourTeam/YourTeamModal.css';

const getComponentForCobrand = (isWellsFargoCobrand: boolean) => {
  if (isWellsFargoCobrand) {
    return YourTeamTriggerButtonWells;
  } else {
    return YourTeamTriggerButton;
  }
};

const YourTeamTriggerButtonCobranded: React.FC<{
  theme: Theme;
  openYourTeamModal: (modalType: YourTeamModalType) => void;
  dataEventName?: string;
  dataParentEventName?: string;
}> = (props) => {
  const isWellsFargoCobrand = useSelector(getIsWellsFargoCobrand);
  const Component = getComponentForCobrand(isWellsFargoCobrand);
  return <Component {...props} />;
};

const YourTeamTriggerButtonWrapper: React.FC<{
  theme: Theme;
  dataEventName?: string;
  dataParentEventName?: string;
}> = ({ theme, dataEventName, dataParentEventName }) => {
  const { isYourTeamModalOpen, openYourTeamModal } = useYourTeamModalState();
  const isMobileSearchFiltersOn = useSelector(getSearchIsShowingMobileFilters);
  const isYourTeamEnabled = useSelector(getIsFeatureEnabled('your_team'));
  const isAllowedPerRoute = useSelector(showYourTeamModalForRoute);

  const shouldShowButton =
    isYourTeamEnabled &&
    !isYourTeamModalOpen &&
    isAllowedPerRoute &&
    !isMobileSearchFiltersOn;

  return (
    <>
      {shouldShowButton && (
        <YourTeamTriggerButtonCobranded
          theme={theme}
          openYourTeamModal={openYourTeamModal}
          dataEventName={dataEventName}
          dataParentEventName={dataParentEventName}
        />
      )}
    </>
  );
};

const ThemedYourTeamTriggerButtonWrapper = themr(
  'YourTeamTriggerButtonWrapper',
  defaultTheme
)(YourTeamTriggerButtonWrapper);
export default ThemedYourTeamTriggerButtonWrapper;
