import { Action } from '@client/store/actions';

import { ABTests } from '../../../ab-test';

export type ABTestsState = {
  testVariants: ABTests;
};

export const INITIAL_AB_TESTS_STATE = {
  testVariants: {},
};

/* NOTE: The default for the AB Tests is set to VARIANTS.A, meaning any feature with a null value will fall back to the default */
export default (state = INITIAL_AB_TESTS_STATE, action: Action): ABTestsState =>
  state;
