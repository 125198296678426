import { FeatureId } from '@client/store/types/feature-flags';
import { Action } from '@client/store/actions';

export type EnabledFeaturesState = FeatureId[];
const INITIAL_STATE: EnabledFeaturesState = [];

export default function enabledFeaturesState(
  state = INITIAL_STATE,
  action: Action
): EnabledFeaturesState {
  switch (action.type) {
    default:
      return state;
  }
}
