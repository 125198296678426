import { call, put, select } from 'redux-saga/effects';
import geojsonExtent from '@mapbox/geojson-extent';
import { PayloadAction } from '@reduxjs/toolkit';

import { consumerApiClient } from '@client/services/consumer-api-client';
import { watchEvery } from '@client/utils/saga.utils';
import { getSavedSearchFieldsForPlace } from '@client/store/sagas/saved-search.saga';
import {
  fetchRecentProperties,
  fetchRecentPropertiesSuccess,
  fetchRecentSearchesSuccess,
  fetchRecentSearches,
  selectRecentSearch,
  postRecentSearchSave,
  goToRecentSearch,
} from '@client/store/slices/recent-user-activity.slice';
import {
  searchApplySavedFilters,
  searchClearResultMapMarkers,
  SEARCH_CLEAR_ALL_FILTERS,
  SEARCH_FETCH_PLACE_DETAILS_SUCCESS,
  SEARCH_UPDATE_FILTER,
} from '@client/store/actions/search.actions';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { getUserId } from '@client/store/selectors/auth.selectors';
import { GeneratorReturnType } from '@client/types/utils';
import {
  FetchFullPropertyDetailsSuccessAction,
  FETCH_FULL_PROPERTY_DETAILS_SUCCESS,
} from '@client/store/actions/property-details.actions';
import {
  createSavedSearchError,
  createSavedSearchSuccess,
} from '@client/store/actions/saved-search.actions';

/**
 * Post a recent search to the API
 */
function* postRecentSearchSaga() {
  const isRecentUserActivityEnabled = (yield select(
    getIsFeatureEnabled('recent_user_activity')
  )) as boolean;
  const userId = (yield select(getUserId)) as ReturnType<typeof getUserId>;
  const searchData = (yield call(
    getSavedSearchFieldsForPlace
  )) as GeneratorReturnType<ReturnType<typeof getSavedSearchFieldsForPlace>>;
  /* Since this saga runs on every filter update or place search, we need to make sure that we're only
   * attempting to post a recent search when we should be */
  if (!isRecentUserActivityEnabled || !userId || !searchData?.place_id) {
    return;
  }
  yield call(
    [consumerApiClient, consumerApiClient.postRecentSearch],
    userId,
    searchData
  );
}

/**
 * Fetch recent searches from the API
 */
function* getRecentSearchesSaga() {
  const userId = (yield select(getUserId)) as ReturnType<typeof getUserId>;
  /* This saga should never be run for logged-out users */
  if (!userId) {
    throw new Error('Attempting to get recent searches without a user id');
  }
  const apiResponse = yield call(
    [consumerApiClient, consumerApiClient.getRecentSearches],
    userId
  );
  yield put(fetchRecentSearchesSuccess(apiResponse));
}

/**
 * Post a recent property view to the API
 */
function* postRecentPropertySaga(
  action: FetchFullPropertyDetailsSuccessAction
) {
  const isRecentUserActivityEnabled = (yield select(
    getIsFeatureEnabled('recent_user_activity')
  )) as boolean;
  const userId = (yield select(getUserId)) as ReturnType<typeof getUserId>;
  const propertyData = action.payload.data;
  /* Since this saga runs after the PDP is loaded, we need to make sure that we're only
   * attempting to post a recent property when we should be */
  if (
    !isRecentUserActivityEnabled ||
    !userId ||
    !propertyData.address.hcAddressId
  ) {
    return;
  }
  yield call(
    [consumerApiClient, consumerApiClient.postRecentProperties],
    userId,
    { address_ids: [propertyData.address.hcAddressId] }
  );
}

/**
 * Fetch recent searches from the API
 */
function* getRecentPropertiesSaga() {
  const userId = (yield select(getUserId)) as ReturnType<typeof getUserId>;
  /* This saga should never be run for logged-out users */
  if (!userId) {
    throw new Error('Attempting to get recent properties without a user id');
  }
  const apiResponse = yield call(
    [consumerApiClient, consumerApiClient.getRecentProperties],
    userId
  );
  yield put(fetchRecentPropertiesSuccess(apiResponse));
}

function* createSavedSearchSaga(action: PayloadAction<{ searchId: number }>) {
  const searchData = yield select(selectRecentSearch(action.payload.searchId));
  if (searchData) {
    let data = Object.keys(searchData)
      /* when both mls_state and mls_state_group is defined, API outputs error */
      .filter((key) => key !== 'mls_state_group')
      .reduce((acc, key) => {
        acc[key] = searchData[key];
        return acc;
      }, {});
    try {
      const newSearch = yield call(
        [consumerApiClient, consumerApiClient.createSavedSearch],
        data
      );
      yield put(createSavedSearchSuccess(newSearch));
    } catch (e: any) {
      yield put(
        createSavedSearchError(
          "We're sorry, but an error has occurred. Please try again later."
        )
      );
      throw e;
    }
  }
}

export function* goToRecentSearchSaga(
  action: PayloadAction<{ searchId: number }>
) {
  const searchFilter = yield select(
    selectRecentSearch(action.payload.searchId)
  );
  if (searchFilter) {
    const WSENBounds = geojsonExtent(searchFilter.geom);
    /* Clear markers to prepare for fetching new markers for new set of filters */
    yield put(searchClearResultMapMarkers());
    /* Note: this causes all filter values to be set and map position to be set.
     * Search list properties will be loaded via listening saga */
    yield put(
      searchApplySavedFilters({
        filters: searchFilter,
        southWest: { lat: WSENBounds[1], lng: WSENBounds[0] },
        northEast: { lat: WSENBounds[3], lng: WSENBounds[2] },
        constrainedToPlace: {
          placeId: searchFilter.place_id,
          mlsCoverage: null,
          city: null,
          zipcode: null,
          state: null,
        },
        placeGeoJSON: searchFilter.geom,
        placeGeoJSONDescription: searchFilter.name,
      })
    );
  }
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [fetchRecentSearches.type]: getRecentSearchesSaga,
    [SEARCH_FETCH_PLACE_DETAILS_SUCCESS]: postRecentSearchSaga,
    [SEARCH_UPDATE_FILTER]: postRecentSearchSaga,
    [SEARCH_CLEAR_ALL_FILTERS]: postRecentSearchSaga,
    [fetchRecentProperties.type]: getRecentPropertiesSaga,
    [FETCH_FULL_PROPERTY_DETAILS_SUCCESS]: postRecentPropertySaga,
    [postRecentSearchSave.type]: createSavedSearchSaga,
    [goToRecentSearch.type]: goToRecentSearchSaga,
  });
};
