import { FETCH_LEGAL_CONTENT_SUCCESS } from '@client/store/actions/legal-content.actions';
import { Action } from '@client/store/actions';

export type LegalContentState = {
  privacyPolicy: null | string;
  privacyPolicyCA: null | string;
  termsOfUse: null | string;
};

export const INITIAL_LEGAL_CONTENT_STATE: LegalContentState = {
  privacyPolicy: null,
  privacyPolicyCA: null,
  termsOfUse: null,
};

export default function legalContentReducer(
  state = INITIAL_LEGAL_CONTENT_STATE,
  action: Action
): LegalContentState {
  switch (action.type) {
    case FETCH_LEGAL_CONTENT_SUCCESS:
      return {
        ...state,
        [action.payload.key]: action.payload.content,
      };
    default:
      return state;
  }
}
