import React, { Component } from 'react';
import { isEmpty, throttle } from 'lodash';
import { MapMouseEvent } from 'mapbox-gl';
import { motion } from 'framer-motion';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import { Api } from '@hc/hcmaps-mapboxgl/lib/components/Maps';

import { GeoLocation } from '@client/store/sagas/queries/types';
import {
  LegendBreaksData,
  LegendColorTable,
  MapMarkerGeoJSONFeature,
} from '@client/store/types/maps';
import {
  getBlocksLayerFillDefinition,
  getLegendBreakGradients,
  getLegendBreaksUUIDForLayer,
  loadHalftoneImages,
} from '@client/utils/map-controls.utils';
import {
  BLOCK_FEATURES_FILTER,
  HALFTONE_IMAGES,
  LAYERS_TO_USE_GRADIENTS,
  LAYER_GROUP_KEYS,
  LAYER_GROUP_LABELS,
  LAYER_LABELS,
  LAYER_METRICS,
  LAYER_SOURCE_ZOOM_MAPPING,
  MAPBOX_LAYER_IDS,
} from '@client/store/map-constants';
import MapLayersScreenreaderAlert from '@client/components/MapLayersScreenreaderAlert';
import Maps from '@client/components/Maps';
import defaultTheme from '@client/css-modules/MapAvmDeepDiveLocation.css';
import BlocksLayer from '@client/components/MapBlocksLayer';
import MapLegend from '@client/components/MapLegend';
import Tooltip from '@client/components/generic/Tooltip';
import HC_CONSTANTS from '@client/app.config';
import {
  offsetLatLngPointByMeters,
  getColorTableFromIntervals,
} from '@client/utils/maps.utils';
import {
  DEEP_DIVES_LOCATION_MAP_ZOOM,
  DEEP_DIVES_LOCATION_MAP_MIN_ZOOM,
} from '@client/utils/avm-data-deep-dives.utils';
import SmallModal from '@client/components/generic/SmallModal';
import AvmDeepDiveCurrentPropertyTooltip from '@client/components/AvmDeepDiveCurrentPropertyTooltip';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import BinaryHorizontalToggle from '@client/components/generic/BinaryHorizontalToggle';


const FIT_PROPERTY_OFFSET = [0, -60];
const DEFAULT_ACTIVE_LAYER_GROUP = LAYER_GROUP_KEYS.PRICE;

const { GEOTILE_DATA_URL } = HC_CONSTANTS;
const LAYER_ID = LAYER_METRICS.AVG_PRICE;


type LocationRegressionDataType = {
  description?: string;
  defaultDescription: string;
  label: string;
  type: string;
  value: number | null;
};

type Props = {
  data: LocationRegressionDataType;
  disableDragging: boolean;
  fitMapTrigger?: boolean;
  handleGetMapLayerLegendBreaks: (bounds, zoom) => void;
  legendBreaks: LegendBreaksData;
  /* Required since heatmaps are being used */
  mapGeoRequestAccessToken: string;
  markerFeatures: MapMarkerGeoJSONFeature[];
  propertyLocation: Omit<GeoLocation, 'precision'> | null;
  showDataSummaryForSmallScreens: boolean;
  isShowingMapZoomControls: boolean;
  ariaLabel?: string;
  theme: Theme;
};

type State = {
  isMarkerDialogActive: boolean;
  repaintTrigger: string | null;
  markerFeaturesChangedTrigger: string | null;
  hasMonochromeOverlay: boolean;
  allHalftoneImagesLoaded: boolean;
  featureDescriptions: {
    featureType: string;
    name: string;
    value: number;
  }[];
  ariaMessage?: JSX.Element | string;
  timeoutId?: NodeJS.Timeout | null;
};

class MapAvmDeepDiveLocation extends Component<Props, State> {
  ensureSubjectMarkerRenderedTimeout: any = null;

  state: State = {
    isMarkerDialogActive: false,
    repaintTrigger: null,
    markerFeaturesChangedTrigger: null,
    hasMonochromeOverlay: false,
    allHalftoneImagesLoaded: false,
    featureDescriptions: [],
    ariaMessage: '',
    timeoutId: null,
  };

  /* Denote when map is changing location due to mounting vs. due to user interaction */
  mapIsChangingLocationProgrammatically = true;
  currentZoom = 0;
  mapApi: Api | null = null;
  isLayerActive = !!DEFAULT_ACTIVE_LAYER_GROUP;
  throttledGenerateTextualDescriptions: any = null;

  componentDidMount() {}

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { legendBreaks } = this.props;
    const prevLegendBreaksUUID = `${getLegendBreaksUUIDForLayer(
      prevProps.legendBreaks,
      LAYER_ID
    )}-${prevState.hasMonochromeOverlay}`;
    const legendBreaksUUID = `${getLegendBreaksUUIDForLayer(
      legendBreaks,
      LAYER_ID
    )}-${this.state.hasMonochromeOverlay}`;

    /* Tell BlocksLayer to repaint the active layer if the legend breaks change (occurs after a pan/zoom) */
    if (prevLegendBreaksUUID !== legendBreaksUUID) {
      this.setState({ repaintTrigger: legendBreaksUUID });
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.ensureSubjectMarkerRenderedTimeout);

    if (this.throttledGenerateTextualDescriptions && this.mapApi) {
      const map = this.mapApi.getMap();

      if (map) {
        map.off('data', this.throttledGenerateTextualDescriptions);
        map.off('moveend', this.throttledGenerateTextualDescriptions);
        this.throttledGenerateTextualDescriptions.cancel();
        this.throttledGenerateTextualDescriptions = null;
      }
    }
  }

  handleGenerateTextualDescriptions = (): void => {
    const map = this.mapApi && this.mapApi.getMap();

    if (!map) {
      return;
    }

    const currentZoom = map.getZoom();
    let mapLayerId;
    let zooms = LAYER_SOURCE_ZOOM_MAPPING[LAYER_ID];
    if (zooms == null) {
      return;
    }
    for (let k of Object.keys(zooms)) {
      let v = zooms[k];
      if (currentZoom >= v[0]) {
        mapLayerId = `${k}_layer`;
        break;
      }
    }

    /* Ensure that the map and map's style are ready, that the layer has been added to the map,
     * and that all tiles are loaded (else we're wasting effort on checking for mls coverage at this time)  */
    if (
      !map ||
      !map.getStyle() ||
      !map.getLayer(mapLayerId) ||
      !map.areTilesLoaded()
    ) {
      return;
    }

    let descriptions = {};
    map
      .queryRenderedFeatures(undefined, { layers: [mapLayerId] })
      .forEach((feature) => {
        if (feature && feature.properties && feature.properties.uid) {
          descriptions[feature.properties.uid] = feature.properties[LAYER_ID];
        }
      });

    const featureType = `Census Block${currentZoom < 13 ? ' Group' : ''}`;
    const featureDescriptions = Object.keys(descriptions).map((k) => {
      return {
        featureType,
        name: k,
        value: descriptions[k],
      };
    });

    this.setState({ featureDescriptions });
  };

  handleClose = () => {
    this.setState({ isMarkerDialogActive: false });
  };

  handleMarkerFeatureClick = (e: MapMouseEvent, api: Api) => {
    if (
      this.props.showDataSummaryForSmallScreens &&
      !this.state.isMarkerDialogActive
    ) {
      this.setState({ isMarkerDialogActive: true });
    }
  };

  handleMoveEnd = (api: Api) => {
    const map = api.getMap();
    if (map) {
      const zoom = map.getZoom();
      const bounds = map.getBounds();
      this.props.handleGetMapLayerLegendBreaks(bounds, zoom);
    }
    if (this.mapIsChangingLocationProgrammatically) {
      this.mapIsChangingLocationProgrammatically = false;
    }
  };

  handleZoomStart = (api: Api) => {
    const map = api.getMap();
    if (map) {
      this.currentZoom = map.getZoom();
    }
  };

  handleMapBackgroundLoaded = (api: Api) => {
    let map = api.getMap();
    this.mapApi = api;

    /* In a prod build, the `moveend` event does NOT fire on map init, so using this method, bound
     * to the `load` event, to trigger it on init */
    if (process.env.NODE_ENV === 'production') {
      this.handleMoveEnd(api);
    }

    if (map) {
      loadHalftoneImages(map, () => {
        this.setState({ allHalftoneImagesLoaded: true });
      });
      /* Nasty hack to ensure that the subject marker renders on the map.  Need deeper investigation into why
       * calling `map.querySourceFeatures` within MapHTMLMarkersLayer.jsx doesn't return the marker when it should
       * after mounting */
      this.ensureSubjectMarkerRenderedTimeout = window.setTimeout(() => {
        this.setState({ markerFeaturesChangedTrigger: 'ensurerendered' });
      }, 1500);

      this.throttledGenerateTextualDescriptions = throttle(
        this.handleGenerateTextualDescriptions,
        300,
        {
          trailing: true,
        }
      );

      map.on('data', this.throttledGenerateTextualDescriptions);
      map.on('moveend', this.throttledGenerateTextualDescriptions);
    }
  };

  getLayerTooltipContent = (
    legendColorTable: LegendColorTable,
    hasMonochromeOverlay: boolean
  ) => {
    const { theme } = this.props;
    return (
      <ul className={theme.LegendList}>
        {legendColorTable.map(
          (interval, i) =>
            interval.color && (
              <li
                key={interval.color}
                className={theme.TooltipDetailsContainer}
              >
                <div
                  className={
                    theme.TooltipColorCodes +
                    (hasMonochromeOverlay
                      ? ` ${theme.TooltipColorCodesHalftone}`
                      : '')
                  }
                  style={
                    hasMonochromeOverlay
                      ? { background: `url("${interval.patternImageUrl}")` }
                      : { backgroundColor: interval.color }
                  }
                />
                <div className={theme.TooltipLabel}>
                  <span>
                    {interval.label[0]} to {interval.label[1]}
                  </span>
                </div>
              </li>
            )
        )}
      </ul>
    );
  };

  /**
   * Reformat the legend breaks passed via props to prepare for display in the UI
   */
  getLegendColorTableForLayer = (): LegendColorTable | null => {
    const intervals = this.props.legendBreaks[LAYER_ID];

    if (!intervals) {
      return null;
    }
    // Average price is currency
    let colorTable = getColorTableFromIntervals({
      intervals,
      isCurrencyBased: true,
      isPercentBased: false,
      valueSuffix: '',
    });
    let i = 0;
    for (let row of colorTable) {
      row.patternImageUrl = HALFTONE_IMAGES[i].url;
      i++;
    }
    return colorTable;
  };

  handleMonochromeToggleChange = (isEnabled: boolean) => {
    this.setState({ hasMonochromeOverlay: isEnabled });
  };


  render() {
    const {
      data,
      markerFeatures,
      disableDragging,
      fitMapTrigger,
      legendBreaks,
      isShowingMapZoomControls,
      showDataSummaryForSmallScreens,
      mapGeoRequestAccessToken,
      propertyLocation,
      ariaLabel,
      theme,
    } = this.props;

    const {
      repaintTrigger,
      markerFeaturesChangedTrigger,
      hasMonochromeOverlay,
      allHalftoneImagesLoaded,
      featureDescriptions,
    } = this.state;
    /**
     * we fetch legendBreaks in handleMoveEnd function, make sure we have legendBreaks
     * before calling getLegendColorTableForLayer
     */
    const legendColorTable =
      !isEmpty(legendBreaks) && this.getLegendColorTableForLayer();
    const activeLayerLegendBreaks = legendBreaks[LAYER_ID];
    const featureSummary =
      legendBreaks && activeLayerLegendBreaks && activeLayerLegendBreaks.length
        ? `${LAYER_LABELS[LAYER_ID]} ${
            LAYER_GROUP_LABELS[DEFAULT_ACTIVE_LAYER_GROUP].longLabel ||
            LAYER_GROUP_LABELS[DEFAULT_ACTIVE_LAYER_GROUP].label
          } in the map area ranges from ${LAYER_GROUP_LABELS[
            DEFAULT_ACTIVE_LAYER_GROUP
          ].valueFormatter(
            activeLayerLegendBreaks[0][0]
          )} to ${LAYER_GROUP_LABELS[DEFAULT_ACTIVE_LAYER_GROUP].valueFormatter(
            activeLayerLegendBreaks[activeLayerLegendBreaks.length - 1][1]
          )}.`
        : 'No data is available in the map area.';
    const setMapLocation =
      propertyLocation &&
      propertyLocation.latitude &&
      propertyLocation.longitude
        ? offsetLatLngPointByMeters(
            { lat: propertyLocation.latitude, lng: propertyLocation.longitude },
            FIT_PROPERTY_OFFSET
          )
        : null;

    return (
      <React.Fragment>
        <section
          className={theme.MapAvmDeepDiveLocation}
          data-hc-name="avm-data-deep-dive-section"
          role="figure"
          aria-label={ariaLabel}
        >
          {showDataSummaryForSmallScreens ? (
            data.value ? (
              <div className={theme.DeepDiveComponentSummary}>
                {data.description}
              </div>
            ) : (
              <div className={theme.DeepDiveComponentSummary}>
                {data.defaultDescription}
              </div>
            )
          ) : null}
          {legendColorTable && (
            <div key="legendFade" className={theme.LayerItemsControlRow}>
              <div className={theme.LegendWrapper}>
                <div key="label" className={theme.LayerItemsControlLabel}>
                  Median Block Price
                  <Tooltip
                    dataHcName="median-block-price-tooltip"
                    theme={theme}
                    hasTransparentScreen
                    maxWidth={300}
                    content={this.getLayerTooltipContent(
                      legendColorTable,
                      hasMonochromeOverlay
                    )}
                    triggerAriaLabel="Open median block price range"
                  />
                </div>
                <MapLegend
                  theme={theme}
                  colorTable={legendColorTable}
                  colors={
                    LAYERS_TO_USE_GRADIENTS.indexOf(LAYER_ID) > -1
                      ? getLegendBreakGradients(
                          legendBreaks,
                          LAYER_ID,
                          hasMonochromeOverlay
                        ) || undefined
                      : legendColorTable
                      ? legendColorTable.map((item) => item.color)
                      : undefined
                  }
                  halftone={hasMonochromeOverlay}
                  small
                />
              </div>
              <AccessibleElementUniqueId>
                {({ uid: switchUid }) => {
                  const MONOCHROME_LABEL_ID = `label-${switchUid}`;
                  return (
                    <motion.div
                      className={theme.LayerItemsControlMarkerToggleColumn}
                      key="LayerItemsControlMarkerToggleColumn"
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: {
                          easing: 'easeOut',
                          delay: 0.36,
                          duration: 0.15,
                        },
                      }}
                    >
                      <div className={theme.MonochromeLabelWrapper}>
                        <label id={MONOCHROME_LABEL_ID} htmlFor={switchUid}>
                          Monochrome
                        </label>
                        <Tooltip
                          dataHcName="monochrome-tooltip"
                          theme={theme}
                          hasTransparentScreen
                          position="top"
                          maxWidth={300}
                          initialShiftAmount={-120}
                          marginFromEdge={{ x: 10, y: 20 }}
                          triggerAriaDescribedBy={MONOCHROME_LABEL_ID}
                          content={
                            'Monochrome heatmap layers can be used as an alternative to the standard color-based heatmaps to aid users with difficulties distinguishing colors.'
                          }
                        />
                      </div>
                      <div className={theme.HorizontalToggleWrapper}>
                        <BinaryHorizontalToggle
                          dataHcName="toggle-map-layer-monochrome"
                          ariaLabel={'Monochrome Heatmap Layers'}
                          handleSelect={this.handleMonochromeToggleChange}
                          selectedValue={hasMonochromeOverlay}
                          id={switchUid}
                        />
                      </div>
                      <div className={theme.NoDataLegend}>
                        No Data <i className={theme.GrayColorTile} />
                      </div>
                    </motion.div>
                  );
                }}
              </AccessibleElementUniqueId>
            </div>
          )}
          <div className={theme.MapSection}>
            <Maps
              theme={theme}
              setMapLocation={
                setMapLocation ? [setMapLocation.lat, setMapLocation.lng] : null
              }
              zoomWhenPositioningToPoint={DEEP_DIVES_LOCATION_MAP_ZOOM}
              allowScrollZoom={false}
              markerFeatures={markerFeatures}
              markerFeaturesChangedTrigger={markerFeaturesChangedTrigger}
              disableDragging={disableDragging}
              fitMapTrigger={fitMapTrigger}
              handleMoveEnd={this.handleMoveEnd}
              handleZoomStart={this.handleZoomStart}
              handleMapBackgroundLoaded={this.handleMapBackgroundLoaded}
              defaultActiveLayerGroup={DEFAULT_ACTIVE_LAYER_GROUP}
              useFloatingLayerGroupsControl
              allowShowingBottomControl
              showZoomControls={isShowingMapZoomControls}
              geoRequestAccessToken={mapGeoRequestAccessToken}
              handleMarkerFeatureClick={this.handleMarkerFeatureClick}
              minZoom={DEEP_DIVES_LOCATION_MAP_MIN_ZOOM}
              MarkerPopup={
                !showDataSummaryForSmallScreens ? (
                  <div className={theme.MapMarkerPopup}>
                    <AvmDeepDiveCurrentPropertyTooltip />
                  </div>
                ) : undefined
              }
              customLayers={
                allHalftoneImagesLoaded
                  ? [
                      <BlocksLayer
                        /* HACK(mikep): BlocksLayer can't handle changing of the keys specified in the paint object,
                     so force a rerender based on hasMonochromeOverlay which changes the fill properties */
                        key={`avmdeepdivelocation-mo-${hasMonochromeOverlay}`}
                        sourceZoomMapping={LAYER_SOURCE_ZOOM_MAPPING[LAYER_ID]}
                        urlBase={GEOTILE_DATA_URL}
                        metric={LAYER_ID}
                        filter={BLOCK_FEATURES_FILTER}
                        repaintTrigger={repaintTrigger}
                        paint={{
                          'fill-opacity': 0.4,
                          ...getBlocksLayerFillDefinition(
                            legendBreaks,
                            LAYER_ID,
                            hasMonochromeOverlay
                          ),
                        }}
                        beneathLayerId={MAPBOX_LAYER_IDS.MARKERS}
                      />,
                    ]
                  : []
              }
            />
            <MapLayersScreenreaderAlert
              layerLabel={'Median Block Price'}
              featureDescriptions={featureDescriptions}
              featureSummary={featureSummary}
            />
          </div>
        </section>
        <SmallModal
          isActive={this.state.isMarkerDialogActive}
          handleClose={this.handleClose}
        >
          <AvmDeepDiveCurrentPropertyTooltip />
        </SmallModal>
      </React.Fragment>
    );
  }
}

export default themr(
  'ThemedMapAvmDeepDiveLocation',
  defaultTheme
)(MapAvmDeepDiveLocation);
