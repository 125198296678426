import { apply, call, select, delay } from 'redux-saga/effects';

import { HistoryType, RouteDefsAllType, RouteChangeType } from './types';
import { ActionRouterErrorType } from './actions';
import { DEFAULT_ERROR_MESSAGES, LOG_MSG_PREFIX } from './constants';
import { getPreviousRoute, getCurrentRoute } from './selectors';
import {
  buildFullPath,
  applyRouteChangeOptionsBefore,
  applyRouteChangeOptionsAfter,
} from './utils';

export function* goToRoute(
  history: HistoryType,
  ROUTES: RouteDefsAllType,
  action?: {
    payload: RouteChangeType;
  }
) {
  try {
    const {
      view,
      params = {},
      query = {},
      options = {},
    } = action?.payload || {};
    let fullPath;

    // Add small delay to allow analytics events from the current page to be sent with the current "source_id"
    yield delay(10);

    if (!fullPath) {
      const currentRoute = yield select(getCurrentRoute);
      let { nextView, nextParams, nextQuery } = yield call(
        applyRouteChangeOptionsBefore,
        view,
        params,
        query,
        currentRoute
      );
      const { finalView, finalParams, finalQuery } = yield call(
        applyRouteChangeOptionsAfter,
        nextView,
        nextParams,
        nextQuery,
        currentRoute
      );

      fullPath = buildFullPath(ROUTES[finalView], finalParams, finalQuery);
    }
    if (options.replace) {
      yield apply(history, history.replace, [fullPath]);
    } else if (options.historyState) {
      yield apply(history, history.push, [fullPath, options.historyState]);
    } else {
      yield apply(history, history.push, [fullPath]);
    }
  } catch (e) {
    console.error(LOG_MSG_PREFIX, e);
  }
}

export function* goToPreviousRoute(
  history: HistoryType,
  ROUTES: RouteDefsAllType
) {
  const previousRoute = yield select(getPreviousRoute);
  const { view, params = {}, query = {} } = previousRoute;
  let routeDef;
  if (previousRoute.view) {
    routeDef = ROUTES[view];
    const fullPath = buildFullPath(routeDef, params, query);
    yield apply(history, history.push, [fullPath]);
  } else {
    yield apply(history, history.goBack, []);
  }
}

export function* logRouterError(action: ActionRouterErrorType) {
  const { errorCode } = action.payload;
  const message =
    action.payload.message || DEFAULT_ERROR_MESSAGES[errorCode.toString()];
  yield apply(console, console.error, [
    LOG_MSG_PREFIX,
    errorCode.toString(),
    message,
    '| Path:',
    window.location.pathname,
  ]);
}
