import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LOContactInfo from '@client/components/LOContactInfo';
import GenericLOInfo from '@client/components/GenericLOInfo';
import { openModal } from '@client/store/actions/modals.actions';
import { reportEvent } from '@client/store/actions/analytics.actions';
import defaultTheme from '@client/css-modules/PDPLoanOfficerAdMobile.css';
import { useAutoSaveProperty } from '@client/hooks/auto-save-property.hooks';
import ContactLOButton from '@client/components/ContactLOButton';
import { getCustomizationData } from '@client/store/selectors/cobranding.selectors';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { useLoInfoProps } from '@client/hooks/lo-info-provider.hooks';
import { getPropertyAddressSlug } from '@client/store/selectors/property-details.selectors';
import { EVENTS, PARENT_EVENTS } from '@client/store/analytics-constants';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';

type Props = { theme: Theme };

const PDPLoanOfficerAdMobile = ({ theme }: Props) => {
  const dispatch = useDispatch();
  const autoSaveProperty = useAutoSaveProperty();
  const ch_property_id = useSelector(getPropertyAddressSlug);
  const openContactModal = () => {
    autoSaveProperty();
    dispatch(openModal('contact-loan-officer'));
  };
  const handleClickWithExternalCtaLink: () => void = () => {
    dispatch(
      reportEvent('click_url_cta_pdp', PARENT_EVENTS.CLICK_URL_CTA, {
        ch_property_id,
      })
    );
  };
  const { external_url_for_cta_btn_on_lo_card_in_pdp } =
    useSelector(getCustomizationData);
  const { loanOfficerBg, pillButtonPrimaryBackgroundColor } =
    useCobrandStyles();
  const { lastMessageDate, loConfig, loData, showLOCard, getLOCardIntroText } =
    useLoInfoProps();

  if (!showLOCard) {
    return null;
  } else {
    // loConfig is undefined for ComeHome, chase cobrands
    const hbPdp = loConfig?.hbPdp;
    const contactButtonText = loData?.firstName ?? loData?.fullName ?? 'Us';
    const headline = hbPdp?.headline ?? 'Finance your next home purchase.';
    const introText = getLOCardIntroText(
      hbPdp?.enableIntroText,
      hbPdp?.introText,
      hbPdp?.introAltText
    );
    const buttonFill = hbPdp?.buttonColor ?? pillButtonPrimaryBackgroundColor;
    const bgColor = hbPdp?.bgColor ?? loanOfficerBg;

    return (
      <div className={theme.PropertyPageMobileLoAd}>
        <div className={theme.AgentWrapper}>
          {loData ? <LOContactInfo /> : <GenericLOInfo />}
        </div>
        <div style={{ background: bgColor }} className={theme.CopyArea}>
          <div className={theme.HelpYouText}>{introText}</div>
          <div className={theme.AccessText}>{headline}</div>
          <div>
            {
              <ContactLOButton
                data-event-name={EVENTS.CLICK_BUYER_PDP_CONTACT}
                data-parent-event-name={PARENT_EVENTS.CLICK_LO_CONTACT}
                theme={theme}
                style={{
                  background: buttonFill,
                }}
                buttonText={contactButtonText}
                buttonCTAUrl={external_url_for_cta_btn_on_lo_card_in_pdp}
                handleClickWithExternalCtaLink={handleClickWithExternalCtaLink}
                handleClickWithoutExternalCtaLink={openContactModal}
              />
            }
            {lastMessageDate && (
              <div className={theme.LastMessageDate}>
                Last message sent on {lastMessageDate}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

const ThemedPDPLoanOfficerAdMobile = themr(
  'PDPLoanOfficerAdMobile',
  defaultTheme
)(PDPLoanOfficerAdMobile);
export default ThemedPDPLoanOfficerAdMobile;
