import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import React, { Component } from 'react';

import PillButton from '@client/components/generic/PillButton';
import defaultTheme from '@client/css-modules/LenderPropertyCTAChase.css';
import { dollarsFormatter } from '@client/utils/formatter.utils';
import { getIsActiveListing } from '@client/utils/property.utils';

import { PropertyType } from '@client/store/sagas/queries/types';
import {
  ChaseCTAEventParams,
  ChaseJSBridgeEventDestination,
  ChasePropertyTypeJSBridgeEventParams,
} from '@client/store/types/chase-integration-data';
import { NormalizedProperty } from '@client/store/types/property';

type Props = {
  userBuyingPower: number | null;
  propertyDetails: NormalizedProperty;
  effectivePropertyPrice: number | null;
  shouldShowExceededBuyingPowerNotice?: boolean;
  getJSBridgePropertyTypeForPropertyType: GetJSBridgePropertyTypeType;
  handleClick: (args: ChaseCTAEventParams) => void;
  handleReportOffMarketSeeLoanProductsClick: () => void;
  handleReportLenderCtaSetupBuyingPowerClick: (slug: string) => void;
  handleReportLenderCtaSeeLoanOptionsClick: (slug: string) => void;
  theme: Theme;
  isBuyingPowerFeatureActivated: boolean;
};

type TextAndDestination = {
  text: string;
  destination: ChaseJSBridgeEventDestination;
};

type GetJSBridgePropertyTypeType = (
  propertyType: PropertyType | null
) => ChasePropertyTypeJSBridgeEventParams['property_type'];

/**
 * A CTA to either set a user's buying power or see loan info
 * all within a parent native application
 */
class LenderPropertyCTAChase extends Component<Props> {
  onClick = (
    e: React.MouseEvent,
    getJSBridgePropertyTypeForPropertyType: GetJSBridgePropertyTypeType
  ) => {
    e.stopPropagation();
    const {
      propertyDetails,
      effectivePropertyPrice,
      handleClick,
      handleReportOffMarketSeeLoanProductsClick,
      handleReportLenderCtaSetupBuyingPowerClick,
      handleReportLenderCtaSeeLoanOptionsClick,
    } = this.props;
    const {
      slug,
      streetAddress,
      unit,
      city,
      state,
      zipcode,
      zipcodePlus4,
      county,
      status,
      propertyType,
      yearBuilt,
    } = propertyDetails;
    const destination = this.getCTATextAndDestinationForPrice().destination;
    const isActiveListing = getIsActiveListing(status);

    if (!isActiveListing && destination === 'LOAN_OPTIONS') {
      handleReportOffMarketSeeLoanProductsClick();
    } else if (destination === 'get_buying_power') {
      handleReportLenderCtaSetupBuyingPowerClick(propertyDetails.slug);
    } else if (destination === 'LOAN_OPTIONS') {
      handleReportLenderCtaSeeLoanOptionsClick(propertyDetails.slug);
    }

    handleClick({
      event_type: 'property',
      destination,
      street_address: streetAddress,
      unit: unit || null,
      city,
      state,
      zipcode: zipcodePlus4 ? `${zipcode}-${zipcodePlus4}` : zipcode,
      county: county || null,
      list_price: isActiveListing ? effectivePropertyPrice : null,
      property_type: getJSBridgePropertyTypeForPropertyType(propertyType),
      year_built: yearBuilt || null,
      ch_property_id: slug,
    });
  };

  /**
   * Return the button text and parent app destination for the button
   */
  getCTATextAndDestinationForPrice = (): TextAndDestination => {
    const {
      userBuyingPower,
      effectivePropertyPrice,
      isBuyingPowerFeatureActivated,
    } = this.props;

    if (!userBuyingPower && isBuyingPowerFeatureActivated) {
      return {
        text: 'Set up buying power',
        destination: 'get_buying_power',
      };
    } else if (
      !isBuyingPowerFeatureActivated ||
      !userBuyingPower ||
      !effectivePropertyPrice ||
      userBuyingPower > effectivePropertyPrice
    ) {
      return {
        text: 'See loan options',
        destination: 'LOAN_OPTIONS',
      };
    } else {
      /* if userBuyingPower <= effectivePropertyPrice */
      return {
        text: 'Update buying power',
        destination: 'update_buying_power',
      };
    }
  };

  render() {
    const {
      theme,
      shouldShowExceededBuyingPowerNotice,
      userBuyingPower,
      effectivePropertyPrice,
      propertyDetails,
      getJSBridgePropertyTypeForPropertyType,
    } = this.props;

    const buttonText = this.getCTATextAndDestinationForPrice().text;
    const { streetAddress, unit } = propertyDetails;

    return (
      <div className={theme.LenderPropertyCTAChase}>
        <PillButton
          ariaLabel={`${buttonText} for ${streetAddress} ${unit ? unit : ''}`}
          className={theme.LenderPropertyCTAButton}
          onClick={(e) => {
            this.onClick(e, getJSBridgePropertyTypeForPropertyType);
          }}
        >
          {buttonText}
        </PillButton>
        {shouldShowExceededBuyingPowerNotice &&
          userBuyingPower &&
          effectivePropertyPrice &&
          userBuyingPower < effectivePropertyPrice && (
            <div className={theme.OverBuyingPowerNotice}>
              {`This home is out of your buying power of ${dollarsFormatter(
                userBuyingPower
              )}`}
            </div>
          )}
      </div>
    );
  }
}

export default themr(
  'LenderPropertyCTAThemed',
  defaultTheme
)(LenderPropertyCTAChase);
