export const TOAST_SHOW = 'TOAST_SHOW';
export const TOAST_HIDE = 'TOAST_HIDE';

type ToastShowAction = {
  type: typeof TOAST_SHOW;
  payload: {
    message: string;
    messageId: number | null;
  };
};

/** This action is dispatched from toast.middleware.js, which is invoked by including
 * a `meta: { toast }` object on any dispatched action  */
export const toastShow = (
  message,
  messageId = new Date().getTime()
): ToastShowAction => {
  return {
    type: TOAST_SHOW,
    payload: {
      message,
      messageId,
    },
  };
};

type ToastHideAction = {
  type: typeof TOAST_HIDE;
};

export const toastHide = (): ToastHideAction => ({
  type: TOAST_HIDE,
});

export type ToastAction = ToastShowAction | ToastHideAction;
