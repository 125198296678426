export default (slug) => ({
  query: /* GraphQL */ `query AvmFactors($slug: String) {
    propertyLookup (id: { slug: $slug}) {
      avmFactors {
        __typename
        ... on BathroomsAvmFactor {
          medianBathrooms: areaMedian
        }
        ... on AgeAvmFactor {
          medianAge: areaMedian
        }
        ... on PoolAvmFactor {
          poolPercent: areaPercent
        }
        ... on BasementAvmFactor {
          basementPercent: areaPercent
        }
        ... on BedroomsAvmFactor {
          medianBedrooms: areaMedian
        }
        ... on LocationAvmFactor {
          medianBlockPrice: areaMedian
        }
        ... on LotSizeAvmFactor {
          medianLotSize: areaMedian
        }
        ... on LivingAreaAvmFactor {
          medianLivingArea: areaMedian
        }
        displayName
        comparisonDescription
        accessibleComparisonDescription
        value
      }
      avm (qualityMethod: CONSUMER) {
        priceMean
      }
    }
  }`,

  variables: {
    slug,
  },
});
