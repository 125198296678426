import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { closeModal, openModal } from '@client/store/actions/modals.actions';
import { getIsModalActive } from '@client/store/selectors/modals.selectors';
import { Theme } from '@friendsofreactjs/react-css-themr';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

const StyledButton = styled.button<{ $color: string }>`
  color: ${(props) => props.$color};
  font-size: 12px;
  border: none;
  outline: none;
  background: none;
  text-decoration: none;
  cursor: pointer;
  padding: 0px;
  text-decoration: underline;
`;

type Props = { theme?: Theme; dataEventName: string };
const modalKey = 'recent-user-activity';

export default function RecentUserActivityCTA({ theme, dataEventName }: Props) {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(getIsModalActive(modalKey));
  const { linkColor } = useCobrandStyles();

  return (
    <StyledButton
      className={theme?.RecentUserActivityCTA}
      $color={linkColor ?? '#0269F7'}
      data-hc-name="view-search-activity-button"
      data-parent-event-name="click_recent_activity"
      data-event-name={dataEventName}
      onClick={function () {
        if (isModalOpen) {
          dispatch(closeModal());
        } else {
          dispatch(openModal(modalKey));
        }
      }}
    >
      View Search Activity
    </StyledButton>
  );
}
