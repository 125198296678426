import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getHOAnalyticsEventAddress } from '@client/store/selectors/homeowner.selectors';
import { AnalyticsEventAddress } from '@client/store/types/analytics';
import { getDataAttrEventNameForDOMEventTarget } from '@client/utils/dom.utils';
import {
  eventType,
  ParentEventType,
  reportEvent,
} from '@client/store/actions/analytics.actions';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import { getIDSelectorByView } from '@client/utils/analytics.utils';

/**
 * Report any click event where a `data-event-name` is defined on a DOM node or parent DOM node,
 * adding context about the current property.
 *
 * NOTE: This is only for use within pages that fetch and use `homeowner` Redux state data, since
 * that's where we're pulling the address data from.  In the future we could potentially add URQL
 * fetching of the active property's address to this component to make it not depend on Redux data
 */
const withHomeownerPropertyEventReporting =
  <P extends object>(WrappedComponent: React.ComponentType<P>): React.FC<P> =>
  (props: P) => {
    const dispatch = useDispatch();
    const propertyAddress: AnalyticsEventAddress | null = useSelector(
      getHOAnalyticsEventAddress
    );
    const source = useSelector(getCurrentView);
    const sourceId = useSelector(getIDSelectorByView(source));

    const handleDataAttrEventReporting = (
      e: React.MouseEvent | React.KeyboardEvent
    ) => {
      const topLevelItems =
        source && sourceId ? { source, source_id: sourceId } : undefined;
      const { eventName, parentEventName, eventData } =
        getDataAttrEventNameForDOMEventTarget(e);
      if (eventName && propertyAddress) {
        dispatch(
          reportEvent(
            eventName as eventType,
            parentEventName as ParentEventType,
            {
              ...propertyAddress,
              ...eventData,
            },
            topLevelItems
          )
        );
      }
    };

    return (
      <div
        onClick={handleDataAttrEventReporting}
        onKeyDown={handleDataAttrEventReporting}
        /* Inherit `minHeight` from the parent element (the `<main>` element in all current uses) if defined there */
        style={{ minHeight: 'inherit ' }}
      >
        <WrappedComponent {...props} />
      </div>
    );
  };

export default withHomeownerPropertyEventReporting;
