import SearchPageList from '@client/components/SearchPageList';
import {
  reportEvent,
  reportPropertyCardClick,
  reportSearchListHasPropertyResults,
  reportSearchListNoPropertyResults,
} from '@client/store/actions/analytics.actions';
import {
  searchAddPropertyListPropertyToPropertyDataCache,
  searchFetchMoreListProperties,
  searchFetchPropertyList,
  searchListApplySort,
  searchPropertyListUpdateScrollPosition,
  searchUpdateUrlParams,
} from '@client/store/actions/search.actions';
import { fetchWatchListIfNotInState } from '@client/store/actions/watchlist.actions';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { STATUSES } from '@client/store/constants';
import { getIsLoggedIn } from '@client/store/selectors/auth.selectors';
import { getSaveSearchButtonConfig } from '@client/store/selectors/cobranding.selectors';
import {
  getIsFeatureEnabled,
  getSearchListMaxResultCount,
} from '@client/store/selectors/enabled-features.selectors';
import {
  getIsFourHundredZoom,
  getIsSmallSize,
} from '@client/store/selectors/match-media.selectors';
import { getHasSavedCurrentSearch } from '@client/store/selectors/saved-search.selectors';
import {
  getFinalSearchActiveFilterCount,
  getSearchFilterSetIncludesOffMarketProperties,
  getSearchIncompleteMLSCoverageExistsInMapArea,
  getSearchIsLoadingMoreListProperties,
  getSearchIsShowingMLSCoverageLayer,
  getSearchIsShowingMobileFilters,
  getSearchListLastUpdatedTimestamp,
  getSearchListPreviousScrollPosition,
  getSearchListProperties,
  getSearchListPropertiesWithAdCard,
  getSearchListSortField,
  getSearchListSortOrder,
  getSearchMarkerFeaturesMinZoom,
  getSearchMLSCoverageStatus,
  getSearchMoreListPropertiesAvailable,
  getSearchPropertyListIsErrorStatus,
  getSearchPropertyListIsInitStatus,
  getSearchPropertyListIsSuccessStatus,
  getSearchPropertyTotalCount,
  getSearchShouldShowLoadingIndicatorWhenLoading,
  getSearchShowPropertyListDisclaimerFairHousingNY,
  getSearchViewport,
  getSearchZoom,
} from '@client/store/selectors/search.selectors';
import { getWatchListItems } from '@client/store/selectors/watchlist.selectors';
import { getScaffoldingCMGMarketingCardDataForSRP } from '@client/store/slices/scaffolding-data.slice';
import { NormalizedProperty } from '@client/store/types/property';
import {
  SearchListSortField,
  SearchListSortOrder,
} from '@client/store/types/search';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

const mapStateToProps = (state) => {
  const propertyResults = getSearchListProperties(state);
  const propertyResultsWithAdCardList =
    getSearchListPropertiesWithAdCard(state);
  const currentMapZoom = getSearchZoom(state);

  return {
    /* Grant toggle exists outside of filters but is within the same UI */
    activeFilterCount: getFinalSearchActiveFilterCount(state),
    areMorePropertiesAvailableToLoad:
      getSearchMoreListPropertiesAvailable(state),
    boundsForLoanOfficerFetch: getSearchViewport(state),
    hasSavedCurrentSearch: getHasSavedCurrentSearch(state),
    hasSearchMLSCoverageLoaded:
      !getSearchIsShowingMLSCoverageLayer(state) ||
      getSearchMLSCoverageStatus(state) === STATUSES.SUCCESS,
    photoSize: getIsSmallSize(state)
      ? ('LARGE' as 'LARGE')
      : ('MEDIUM' as 'MEDIUM'),
    isLoadingMore: getSearchIsLoadingMoreListProperties(state),
    isFourHundredPercentZoom: getIsFourHundredZoom(state),
    isMLSCoverageIncompleteForMapArea:
      getSearchIncompleteMLSCoverageExistsInMapArea(state),
    isLoggedIn: getIsLoggedIn(state),
    isPropertyListInitStatus: getSearchPropertyListIsInitStatus(state),
    isPropertyListSuccessStatus: getSearchPropertyListIsSuccessStatus(state),
    isPropertyListErrorStatus: getSearchPropertyListIsErrorStatus(state),
    isShowingMobileFilters: getSearchIsShowingMobileFilters(state),
    isSortControlDisabled:
      getSearchFilterSetIncludesOffMarketProperties(state) ||
      propertyResults.length === 0,
    isTooLowZoom:
      !!currentMapZoom &&
      currentMapZoom < getSearchMarkerFeaturesMinZoom(state),
    isCanaryUIFeatureEnabled: getIsFeatureEnabled('canary_ui')(state),
    isMobileView: getIsSmallSize(state),
    lastUpdatedTimestamp: getSearchListLastUpdatedTimestamp(state),
    maxResultCount: getSearchListMaxResultCount(state),
    previousScrollPosition: getSearchListPreviousScrollPosition(state),
    propertyResults,
    propertyResultsWithAdCard: propertyResultsWithAdCardList,
    isScaffoldingCMGMarketingCardDataForSRP:
      !!getScaffoldingCMGMarketingCardDataForSRP(state),
    shouldFetchWatchListData: getIsLoggedIn(state),
    shouldHideNoResultsBackground: getSearchIsShowingMobileFilters(state),
    shouldShowDisclaimerFairHousingNY:
      getSearchShowPropertyListDisclaimerFairHousingNY(state),
    shouldShowLoadingIndicatorWhenLoading:
      getSearchShouldShowLoadingIndicatorWhenLoading(state),
    sortField: getSearchListSortField(state),
    sortOrder: getSearchListSortOrder(state),
    totalPropertyCount: getSearchPropertyTotalCount(state),
    /* Used to update state property card list when property is added to watchlist */
    watchListItemsCount: getWatchListItems(state).length,
    saveSearchButtonConfig: getSaveSearchButtonConfig(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeSearchList: (): void => {
    dispatch(
      searchUpdateUrlParams({
        isShowingSearchPageList: false,
      })
    );
  },
  handleOpenPDP: (item: NormalizedProperty): void => {
    dispatch(
      searchAddPropertyListPropertyToPropertyDataCache({
        addressSlug: item.slug,
        normalizedPropertyData: item,
      })
    );
  },
  getWatchListData: () => dispatch(fetchWatchListIfNotInState()),
  fetchProperties: () =>
    dispatch(
      searchFetchPropertyList({
        showLoadingIndicator: true,
      })
    ),
  handleChangeSort: (
    sortField: SearchListSortField,
    sortOrder: SearchListSortOrder
  ): void => {
    dispatch(reportEvent('click_sort_search_change'));
    dispatch(searchListApplySort({ sortField, sortOrder }));
  },
  handleOpenSortMenu: (): void => {
    dispatch(reportEvent('click_sort_search_menu_open'));
  },
  reportPropertyCardClick: () => dispatch(reportPropertyCardClick()),
  handleSearchListUnwatchClick: (slug) =>
    dispatch(
      reportEvent(
        'click_search_list_unwatch',
        PARENT_EVENTS.CLICK_PROPERTY_WATCHING,
        { ch_property_id: slug }
      )
    ),
  handleSearchListUnwatchConfirmClick: (slug) =>
    dispatch(
      reportEvent(
        'click_search_list_watch',
        PARENT_EVENTS.CLICK_PROPERTY_WATCHING,
        { ch_property_id: slug }
      )
    ),
  handleLoadMoreProperties: () => dispatch(searchFetchMoreListProperties()),
  handleOpenMobileFilters: () => {
    dispatch(reportEvent('click_search_filters'));
    dispatch(
      searchUpdateUrlParams({
        isShowingMobileFilters: true,
      })
    );
  },
  handleUpdateListScrollPosition: (scrollPosition: number): void => {
    dispatch(searchPropertyListUpdateScrollPosition(scrollPosition));
  },
  handleReportNoPropertyResults: () =>
    dispatch(reportSearchListNoPropertyResults()),
  handleReportHasPropertyResults: () =>
    dispatch(reportSearchListHasPropertyResults()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPageList);
