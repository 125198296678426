import { Action } from '@client/store/actions';
import { toastShow } from '@client/store/actions/toast.actions';

/**
 * Allows the dispatching of toast actions by adding a `toast` meta key to any action:
 *
 * const downloadFile = (data) => ({
 *   type: DOWNLOAD_FILE,
 *   payload: {
 *     data
 *   },
 *   meta: {
 *     toast: {
 *       message: 'Your download will begin shortly...'
 *     }
 *   }
 * });
 */
const toastMiddleware = (store) => (next) => (action: Action) => {
  if (action && action['meta']) {
    const { toast } = action['meta'];
    if (toast && toast.message) {
      store.dispatch(toastShow(toast.message));
    }
  }
  return next(action);
};

export default toastMiddleware;
