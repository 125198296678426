import { values } from 'lodash';
import React, { Component } from 'react';

import { reportToSentry } from '@client/utils/error.utils';
import { createComponentFromDOMNode } from '@client/utils/component.utils';
import TransformedImage, {
  autoCrop,
} from '@client/components/TransformedImage';
import theme from '@client/css-modules/ImagePreloader.css';

export type PreloadedImages = { [url: string]: false | React.ReactElement };

type Props = {
  /* List of image urls to preload */
  urls: string[];
  /* Callback fired when all images are loaded. Object mapping image URLs to DOM
   * elements provided as argument */
  onLoad?: (preloadedImages: PreloadedImages) => void;
  renderCanvasElement?: boolean;
  alt?: string;
};

/**
 * Given an array of URLs, preload the images offscreen and execute a callback when complete
 */
export default class ImagePreloader extends Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    props.urls.forEach((url) => {
      this.imageStatuses[url] = false;
      this.preloadedImages[url] = false;
    });
  }
  static defaultProps = {
    renderCanvasElement: true,
  };

  state = {};
  imageStatuses: { [url: string]: boolean } = {};
  /* Will contain canvas or fallback img React components as they load, mapped via URL.
   * URL key's value will be `undefined` if the img URL fails to load */
  preloadedImages: PreloadedImages = {};

  /**
   * On image load success or failure
   * @param  {node} image node or, `null` if image load failure
   * @param  {string} image src
   */
  onImageLoad = (img: EventTarget | null, url: string) => {
    this.imageStatuses[url] = true;
    const imageComponent = !!img && createComponentFromDOMNode(img as Element);
    if (!imageComponent) {
      reportToSentry(
        'Error when attempting image manipulation. Image with will be hidden',
        {},
        'info'
      );
    }
    this.preloadedImages[url] = imageComponent;

    /* If all images loaded */
    if (values(this.imageStatuses).indexOf(false) === -1 && this.props.onLoad) {
      this.props.onLoad(this.preloadedImages);
    }
  };

  render() {
    const { urls, renderCanvasElement, alt } = this.props;

    return (
      <div aria-hidden="true">
        {urls.map((url, idx) =>
          renderCanvasElement ? (
            <TransformedImage
              key={url + idx}
              alt={alt}
              className={theme.OffscreenImg}
              src={url}
              transforms={[autoCrop()]}
              onLoad={(e) => this.onImageLoad(e.target, url)}
              onError={() => this.onImageLoad(null, url)}
            />
          ) : (
            <link rel="preload" href={url} key={url} as="image" />
          )
        )}
      </div>
    );
  }
}
