import { Action } from '@client/store/actions';
import { APPLICATION_DID_MOUNT } from '@client/store/actions/server-rendering-info.actions';

export type ServerRenderingInfoState = {
  shouldServerSideRender: boolean;
  applicationDidMount: boolean;
};

export const INITIAL_STATE = {
  shouldServerSideRender: false,
  applicationDidMount: false,
};

export default function serverRenderingInfoReducer(
  state = INITIAL_STATE,
  action: Action
): ServerRenderingInfoState {
  switch (action.type) {
    case APPLICATION_DID_MOUNT:
      return {
        ...state,
        applicationDidMount: true,
      };
    default:
      return state;
  }
}
