import { uniq } from 'lodash';
import { createSelector } from 'reselect';

import { STATUSES } from '@client/store/constants';
import { ReduxState } from '@client/store/types/redux-state';
import { getPhotoUrlsFromPhotos } from '@client/utils/photo.utils';

import { getActivePDPSlug } from '@client/store/selectors/router.selectors';

import { PropertyPhotosSizes } from '@client/store/types/property-photos';

const MAX_CAROUSEL_PHOTOS = 40;

export function getPropertyPhotosState(
  state: ReduxState,
  props: { size: PropertyPhotosSizes }
) {
  return state.propertyPhotos
    ? state.propertyPhotos.propertyPhotoDataSizeCache[props.size]
    : {};
}

export function getPropertyStreetViewCache(state: ReduxState) {
  return state.propertyPhotos
    ? state.propertyPhotos.propertyPhotoStreetViewCache
    : {};
}

export const getAllPropertyPhotosForAddressSlug = (
  addressSlug: string | null
) =>
  createSelector(getPropertyPhotosState, (propertyPhotoDataCache) => {
    if (!addressSlug) return [];

    const photoData = propertyPhotoDataCache[addressSlug];
    return photoData
      ? getPhotoUrlsFromPhotos(photoData.bestPhotos || null)
      : [];
  });

export const getFirstPropertyPhotoForAddressSlug = (
  addressSlug: string | null
) =>
  createSelector(getPropertyPhotosState, (propertyPhotoDataCache) => {
    if (!addressSlug) return '';

    const photoData = propertyPhotoDataCache[addressSlug];
    const bestPhoto = photoData ? photoData.bestPhotos : [];
    return bestPhoto ? (bestPhoto[0]?.representation?.httpUrl as string) : '';
  });

export const getPropertyDetailsPhotosStatus = createSelector(
  getActivePDPSlug,
  getPropertyPhotosState,
  (addressSlug, propertyPhotoDataCache) => {
    if (!addressSlug) return STATUSES.INIT;

    const photoCacheEntry = propertyPhotoDataCache[addressSlug];
    return photoCacheEntry ? photoCacheEntry.status : STATUSES.INIT;
  }
);

export const getPropertyPhotosStatusForAddressSlug = (
  addressSlug: string | null
) =>
  createSelector(getPropertyPhotosState, (propertyPhotoDataCache) => {
    if (!addressSlug) {
      return STATUSES.INIT;
    }

    const photoCacheEntry = propertyPhotoDataCache[addressSlug];
    return photoCacheEntry ? photoCacheEntry.status : STATUSES.INIT;
  });

export const getActivePropertyStreetViewState = createSelector(
  getActivePDPSlug,
  getPropertyStreetViewCache,
  (addressSlug, streetViewCache) => {
    return addressSlug ? streetViewCache[addressSlug] || null : null;
  }
);

export const getStreetViewStateForAddressSlug = (
  addressSlug: string | undefined | null
) =>
  createSelector(getPropertyStreetViewCache, (streetViewCache) => {
    return addressSlug ? streetViewCache[addressSlug] || null : null;
  });

/* Check the photo data cache for property photos */
export const getAllPropertyPhotos = createSelector(
  getActivePDPSlug,
  getPropertyPhotosState,
  (addressSlug, propertyPhotoDataCache) => {
    if (!addressSlug) return [];

    const photoCacheItem = propertyPhotoDataCache[addressSlug];
    return photoCacheItem
      ? getPhotoUrlsFromPhotos(photoCacheItem.bestPhotos)
      : [];
  }
);

export const getPropertyFirstPhoto = createSelector(
  getAllPropertyPhotos,
  (allPhotos) => (allPhotos.length ? allPhotos[0] : null)
);

export const getPropertyCarouselRowPhotos = createSelector(
  getAllPropertyPhotos,
  (allPhotos) =>
    allPhotos.length ? uniq(allPhotos.slice(0, MAX_CAROUSEL_PHOTOS)) : []
);
