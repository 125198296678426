import { RouterStateType } from './types';
import { ActionType, ROUTE_UPDATE } from './actions';

const ROUTE_STATE = {
  view: null,
  path: null,
  params: {},
  query: {},
};

export const INITIAL_STATE = {
  current: { ...ROUTE_STATE },
  previous: { ...ROUTE_STATE },
};

export default (
  state: RouterStateType = INITIAL_STATE,
  action: ActionType
): RouterStateType => {
  const type = action.type;
  switch (type) {
    case ROUTE_UPDATE:
      if (action.payload) {
        return {
          ...state,
          previous: { ...state.current },
          current: {
            ...ROUTE_STATE,
            ...action.payload,
          },
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};
