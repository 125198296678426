import React from 'react';
import classNames from 'classnames';
import { themr } from '@friendsofreactjs/react-css-themr';

import { ButtonMenuButtonProps } from '@client/store/types/cobranded-components/button-menu-button';
import defaultTheme from '@client/css-modules/ButtonMenuButtonChase.css';

/**
 * The button rendered in <ButtonMenu />
 */
const ButtonMenuButtonChase = ({
  theme,
  className,
  isActive,
  children,
  ariaControls,
  ariaLabel,
  onClick,
  id,
}: ButtonMenuButtonProps) => {
  return (
    <button
      className={classNames(theme.ButtonMenuButton, {
        [theme.Active]: !!isActive,
        [className || '']: className,
      })}
      aria-label={ariaLabel}
      id={id}
      aria-haspopup="true"
      aria-expanded={isActive}
      aria-controls={ariaControls}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default themr(
  'ButtonMenuButtonChaseThemed',
  defaultTheme
)(ButtonMenuButtonChase);
