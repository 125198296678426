import { ROUTE_CHANGE } from '@src/redux-saga-router-plus/actions';
import { Action } from '@client/store/actions';

export type RouterAdditionalInfoState = {
  /* This is crucial for deciding whether to go "back" in the browser in order to close an open modal */
  isInitialRoute: boolean;
};

const INITIAL_STATE = {
  isInitialRoute: true,
};

/* The reducer exists for cases when we need to augment the data available in the imported
 * redux-saga-router-plus reducer */
export default function routerAdditionalInfoReducer(
  state: RouterAdditionalInfoState = INITIAL_STATE,
  action: Action
): RouterAdditionalInfoState {
  switch (action.type) {
    case ROUTE_CHANGE as 'ROUTE_CHANGE':
      return {
        ...state,
        isInitialRoute:
          state.isInitialRoute && action.payload?.options?.replace
            ? true
            : false,
      };
    default:
      return state;
  }
}
