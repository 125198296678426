import { useDispatch } from 'react-redux';
import React from 'react';

import { closeConfirmEmailBanner } from '@client/store/actions/app-banner.actions';
import { resendConfirmUserEmail } from '@client/store/actions/auth.actions';
import DismissibleBanner from '@client/components/DismissibleBanner';
import theme from '@client/css-modules/ConfirmEmailBanner.css';
import EmailIconCobranded from '@client/components/EmailIcon/EmailIconCobranded';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

const ConfirmEmailBanner: React.FunctionComponent<{}> = ({}) => {
  const dispatch = useDispatch();
  const handleCloseBanner = () => {
    dispatch(closeConfirmEmailBanner());
  };
  const handleResendEmail = () => {
    dispatch(resendConfirmUserEmail(null));
    dispatch(closeConfirmEmailBanner());
  };
  const { dismissableBannerLinkColor } = useCobrandStyles();

  return (
    <div className={theme.ConfirmEmailBanner}>
      <DismissibleBanner
        active={true}
        onClose={handleCloseBanner}
        theme={theme}
        ariaLabelledby="confirm-email-banner"
      >
        <div
          className={theme.ConfirmEmailBannerContent}
          data-testid="confirm-email-banner"
          data-hc-name="confirm-email-banner"
          id="confirm-email-banner"
        >
          <EmailIconCobranded className={theme.EmailIcon} aria-hidden />
          Your email address is not confirmed. Please click the link in the
          email we sent.&nbsp;
          <button
            className={theme.ResendButton}
            onClick={handleResendEmail}
            style={{
              color: dismissableBannerLinkColor,
            }}
          >
            <div className={theme.DesktopButtonText}>
              Click here to resend it.
            </div>
            <div className={theme.MobileButtonText}>Resend it.</div>
          </button>
        </div>
      </DismissibleBanner>
    </div>
  );
};

export default ConfirmEmailBanner;
