import {
  addYears,
  addMonths,
  addDays,
  subYears,
  subWeeks,
  subMonths,
} from 'date-fns';

import {
  formatDate,
  getFirstOfMonthStringForDateObj,
} from '@client/utils/date.utils';
import { ModalKey } from '@client/store/constants';

/* Constants used across homeowner components */

// Helper functions to create dynamic dates formatted in the way the API needs them for our demo data:
export const monthsAgo = (amount) =>
  getFirstOfMonthStringForDateObj(subMonths(new Date(), amount));
export const weeksAgo = (amount) =>
  getFirstOfMonthStringForDateObj(subWeeks(new Date(), amount));
export const monthsFromNow = (amount) =>
  getFirstOfMonthStringForDateObj(addMonths(new Date(), amount));

let nextYear = addYears(new Date(), 1);
if (nextYear.getDate() === 1) {
  // if the day is the 1st, the API doesn't return this month back
  addDays(nextYear, 1);
}

const API_DATE_FORMAT = 'YYYY-MM-DD';
// Our data is based on the first of the month, so if you don't set the date
// to the first, you won't get this months data from last year:
export const ONE_YEAR_AGO = getFirstOfMonthStringForDateObj(
  subYears(new Date(), 1)
);
export const NOW = formatDate(Date.now(), API_DATE_FORMAT);
export const THIS_MONTH = getFirstOfMonthStringForDateObj(new Date());
export const HOMEOWNER_SEARCH_ADDRESS_INPUT_PLACEHOLDER = 'Enter address';
export const ONE_YEAR_FROM_NOW = formatDate(nextYear, API_DATE_FORMAT);
export const ONE_YEAR_AND_A_MONTH_FROM_NOW = formatDate(
  addMonths(addYears(new Date(), 10), 1),
  API_DATE_FORMAT
);
export const ONE_YEAR_FROM_THIS_MONTH = getFirstOfMonthStringForDateObj(
  addYears(new Date(), 1)
);
export const SIX_MONTHS_FROM_NOW = monthsFromNow(6);
export const SIX_MONTHS_AGO = formatDate(
  subMonths(new Date(), 6),
  API_DATE_FORMAT
);
export const TEN_YEARS_AND_A_MONTH_AGO = formatDate(
  subMonths(subYears(new Date(), 10), 1),
  API_DATE_FORMAT
);

export const INTEREST_RATE_PRECISION = 3;

export const CLAIMED_HOME_KEYS = {
  ADDRESS_ID: 'address_id' as 'address_id',
  BATHS_FULL: 'baths_full' as 'baths_full',
  BATHS_PARTIAL: 'baths_partial' as 'baths_partial',
  BEDS: 'beds' as 'beds',
  CREATED_AT: 'created' as 'created',
  FAILED_COUNT: 'failed_count' as 'failed_count',
  TABLE_ID: 'id' as 'id',
  LIVING_AREA: 'living_area' as 'living_area',
  LOCKOUT_DATE: 'lockout_date' as 'lockout_date',
  LOT_SIZE: 'lot_size' as 'lot_size',
  OPTED_IN_TO_SELL: 'opted_in_to_sell' as 'opted_in_to_sell',
  PMI_MODAL_WAS_SHOWN: 'pmi_modal_was_shown' as 'pmi_modal_was_shown',
  INTERESTED_IN_SELLING: 'interested_in_selling' as 'interested_in_selling',
  PRINCIPAL: 'principal' as 'principal',
  RATE: 'rate' as 'rate',
  ROOM_COUNT: 'room_count' as 'room_count',
  SALE_DATE: 'sale_date' as 'sale_date',
  SALE_PRICE: 'sale_price' as 'sale_price',
  SLUG: 'slug' as 'slug',
  TERMS: 'terms' as 'terms',
  UPDATED: 'updated' as 'updated',
  VERIFIED: 'verified' as 'verified',
  BASEMENT: 'basement' as 'basement',
  POOL: 'pool' as 'pool',
  GARAGE_TYPE: 'garage_type' as 'garage_type',
  CONDITION: 'condition' as 'condition',
};

export const EQUITY_FORM_KEYS = {
  PURCHASE_PRICE: 'purchase_price' as 'purchase_price',
  LOAN_TERMS: 'loan_terms' as 'loan_terms',
  ORIGINATION_DATE: 'origination_date' as 'origination_date',
  INTEREST_RATE: 'interest_rate' as 'interest_rate',
  ORIGINAL_LOAN_AMOUNT: 'original_loan_amount' as 'original_loan_amount',
  CURRENT_BALANCE: 'current_balance' as 'current_balance',
  MONTHLY_PAYMENT: 'monthly_payment_pi' as 'monthly_payment_pi',
  MORTGAGE_INSURANCE_PAYMENT: 'monthly_payment_ti' as 'monthly_payment_ti',
  SECOND_ORIGINAL_LOAN_AMOUNT:
    'second_mortgage_original_loan_amount' as 'second_mortgage_original_loan_amount',
  SECOND_ORIGINATION_DATE:
    'second_mortgage_origination_date' as 'second_mortgage_origination_date',
  SECOND_INTEREST_RATE:
    'second_mortgage_interest_rate' as 'second_mortgage_interest_rate',
  SECOND_LAST_PAYMENT_DATE:
    'second_mortgage_monthly_payment_due_date' as 'second_mortgage_monthly_payment_due_date',
  SECOND_LOAN_TERMS:
    'second_mortgage_loan_terms' as 'second_mortgage_loan_terms',
  SECOND_LOAN_CURRENT_BALANCE:
    'second_mortgage_current_balance' as 'second_mortgage_current_balance',
  SECOND_LOAN_MONTHLY_PAYMENT:
    'second_mortgage_monthly_payment_pi' as 'second_mortgage_monthly_payment_pi',
  SECOND_LOAN_INSURANCE_PAYMENT:
    'second_mortgage_monthly_payment_ti' as 'second_mortgage_monthly_payment_ti',
};

export const EMPTY_INPUT_FIELD_VALUE = '';

// Use in mortgage/schedule api to get refinancing data
export const REFINANCING_KEYS = {
  INTEREST_RATE: 'interestRate' as 'interestRate',
  LOAN_AMOUNT: 'loanAmount' as 'loanAmount',
  TERMS: 'terms' as 'terms',
  ADDITIONAL_MONTHLY_PAYMENT:
    'additionalMonthlyPayment' as 'additionalMonthlyPayment',
  MORTGAGE_ID: 'mortgageId' as 'mortgageId',
  LOAN_DATE: 'loanDate' as 'loanDate',
  ONE_TIME_PAYMENT: 'oneTimePayment' as 'oneTimePayment',
  IS_IN_INITIAL_MORTGAGE_SCHEDULE_DATA:
    'isInitialMortgageScheduleData' as 'isInitialMortgageScheduleData',
  PROPERTY_ADDRESS: 'propertyAddress' as 'propertyAddress',
  TRIGGERED_BY_INTERACTION:
    'triggeredByInteraction' as 'triggeredByInteraction',
};

export const CALCULATOR_TYPES = {
  REFINANCE: 'refi-calculator' as 'refi-calculator',
};

export const REFINANCING_API_KEYS = {
  ADDITIONAL_MONTHLY_PAYMENT: 'additional_monthly_payment',
  ONE_TIME_PAYMENT: 'one_time_payment',
  INTEREST_RATE: 'interest_rate',
  LOAN_AMOUNT: 'loan_amount',
  MORTGAGE_ID: 'mortgage_id',
  LOAN_DATE: 'loan_date',
  PROPERTY_ADDRESS: 'property_address',
  TRIGGERED_BY_INTERACTION: 'triggered_by_interaction',
  CALCULATOR_TYPE: 'calculator_type',
};

/* For Homeowner home improvements modal */
export const HOME_IMPROVEMENTS_TYPES = {
  SCENARIOS: 'Project',
};

/* these keys need to match with graphql object keys */
export const HOME_IMPROVEMENTS_SCENARIOS_KEY = {
  BEDS: 'beds',
  BATH: 'bath',
  SQUARE_FOOTAGE: 'addition',
  POOL: 'pool',
};

export const LOGIN_REQUIRED_MODALS: { [key in ModalKey]?: boolean } = {
  'homeowner-home-improve': true,
};

export const HOME_IMPROVEMENTS_SCENARIOS_INPUT_FIELD_NAME_KEY = {
  BEDS: 'bedsAdditionalSqFt',
  BATH: 'bathAdditionalSqFt',
  SQUARE_FOOTAGE: 'squareFootageAdditionalSqFt',
};

export const HOME_IMPROVEMENTS_SCENARIOS = [
  {
    eleId: HOME_IMPROVEMENTS_SCENARIOS_KEY.BEDS,
    title: 'Add a bedroom',
    subTitle: '',
    inputFieldName: HOME_IMPROVEMENTS_SCENARIOS_INPUT_FIELD_NAME_KEY.BEDS,
    isShowingSubtitleInTitleColumn: true,
    isShowingCheckbox: true,
    isShowingInputField: true,
    isShowingIcon: true,
    isShowingHorizontalSeparator: true,
    isCondition: false,
  },
  {
    eleId: HOME_IMPROVEMENTS_SCENARIOS_KEY.BATH,
    title: 'Add a bathroom',
    subTitle: '',
    inputFieldName: HOME_IMPROVEMENTS_SCENARIOS_INPUT_FIELD_NAME_KEY.BATH,
    isShowingSubtitleInTitleColumn: true,
    isShowingCheckbox: true,
    isShowingInputField: true,
    isShowingIcon: true,
    isShowingHorizontalSeparator: true,
    isCondition: false,
  },
  {
    eleId: HOME_IMPROVEMENTS_SCENARIOS_KEY.SQUARE_FOOTAGE,
    title: 'Add square footage',
    subTitle: '',
    inputFieldName:
      HOME_IMPROVEMENTS_SCENARIOS_INPUT_FIELD_NAME_KEY.SQUARE_FOOTAGE,
    isShowingSubtitleInTitleColumn: true,
    isShowingCheckbox: true,
    isShowingInputField: true,
    isShowingIcon: true,
    isShowingHorizontalSeparator: true,
    isCondition: false,
  },
  {
    eleId: HOME_IMPROVEMENTS_SCENARIOS_KEY.POOL,
    title: 'Add a pool',
    subTitle: '',
    isShowingSubtitleInTitleColumn: true,
    isShowingCheckbox: true,
    isShowingInputField: false,
    isShowingIcon: true,
    isShowingHorizontalSeparator: true,
    isCondition: false,
  },
];

export const SESSION_STORAGE_KEYS = {
  /* for homeowner home improvement modal */
  HOME_IMPROVEMENTS_SELECTED_HOME_CONDITION_OPTION:
    'home_improvements_selected_home_condition_option',
  HOME_IMPROVEMENTS_SCENARIOS_BEDS_ADDITIONAL_SQFT:
    'home_improvements_scenarios_beds_additional_sqft',
  HOME_IMPROVEMENTS_SCENARIOS_BATH_ADDITIONAL_SQFT:
    'home_improvements_scenarios_bath_additional_sqft',
  HOME_IMPROVEMENTS_SCENARIOS_SQUARE_FOOTAGE_ADDITIONAL_SQFT:
    'home_improvements_scenarios_square_footage_additional_sqft',
  /* The logic for the pool is boolean (either adding the pool or not adding the pool) there is no additional sqft for adding the pool */
  HOME_IMPROVEMENTS_SCENARIOS_POOL: 'home_improvements_scenarios_pool',
};

export const HOME_ADVISOR_COST_GUIDE: Record<
  string,
  {
    addedSqFtCostGuide: Array<{
      addedSqFt: number | null;
      minEstimatedCost: number | null;
      maxEstimatedCost: number | null;
    }>;
  }
> = {
  [HOME_IMPROVEMENTS_SCENARIOS_KEY.BEDS]: {
    addedSqFtCostGuide: [
      {
        addedSqFt: null,
        minEstimatedCost: 80,
        maxEstimatedCost: 200,
      },
    ],
  },
  [HOME_IMPROVEMENTS_SCENARIOS_KEY.BATH]: {
    addedSqFtCostGuide: [
      {
        addedSqFt: null,
        minEstimatedCost: 50000,
        maxEstimatedCost: 90000,
      },
    ],
  },
  [HOME_IMPROVEMENTS_SCENARIOS_KEY.SQUARE_FOOTAGE]: {
    addedSqFtCostGuide: [
      {
        addedSqFt: 120,
        minEstimatedCost: 9600,
        maxEstimatedCost: 24000,
      },
      {
        addedSqFt: 144,
        minEstimatedCost: 11520,
        maxEstimatedCost: 28800,
      },
      {
        addedSqFt: 400,
        minEstimatedCost: 32000,
        maxEstimatedCost: 80000,
      },
      {
        addedSqFt: 500,
        minEstimatedCost: 40000,
        maxEstimatedCost: 100000,
      },
      {
        addedSqFt: 600,
        minEstimatedCost: 48000,
        maxEstimatedCost: 120000,
      },
      {
        addedSqFt: 1000,
        minEstimatedCost: 80000,
        maxEstimatedCost: 200000,
      },
    ],
  },
};

export const HOME_ADVISOR_ADDED_SQ_FT_GROUP: Array<{
  useAddedSqFtAt: number | null;
  minAddedSqFt: number | null;
  maxAddedSqFt: number | null;
}> = [
  {
    useAddedSqFtAt: 120,
    minAddedSqFt: 1,
    maxAddedSqFt: 130,
  },
  {
    useAddedSqFtAt: 144,
    minAddedSqFt: 131,
    maxAddedSqFt: 250,
  },
  {
    useAddedSqFtAt: 400,
    minAddedSqFt: 251,
    maxAddedSqFt: 449,
  },
  {
    useAddedSqFtAt: 500,
    minAddedSqFt: 450,
    maxAddedSqFt: 549,
  },
  {
    useAddedSqFtAt: 600,
    minAddedSqFt: 550,
    maxAddedSqFt: 749,
  },
  {
    useAddedSqFtAt: 1000,
    minAddedSqFt: 750,
    maxAddedSqFt: null,
  },
];

export const AVM_CHART_TIME_RANGES = {
  SIX_MONTH: 'sixMonths' as 'sixMonths',
  ONE_YEAR: 'oneYear' as 'oneYear',
  THREE_YEAR: 'threeYears' as 'threeYears',
  TEN_YEAR: 'tenYears' as 'tenYears',
};

export const HOMEOWNER_HEADLINE_KEY = 'hl' as 'hl';

export const PROFINDER_TYPE_IDS = {
  BEDROOM: 'bedroom' as 'bedroom',
  BATHROOM: 'bathroom' as 'bathroom',
  ADDITION: 'addition' as 'addition',
  POOL: 'pool' as 'pool',
};

export type ProfinderTypeIds =
  typeof PROFINDER_TYPE_IDS[keyof typeof PROFINDER_TYPE_IDS];
