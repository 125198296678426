import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
import { createSelector } from 'reselect';

import { getCobrandUtils } from '@client/cobrand-settings/cobrand-utils';
import { HOMEOWNER_ROUTES, HOME_BUYER_ROUTES } from '@client/routes/constants';
import { ReduxState } from '@client/store/types/redux-state';

/* These selectors combine some values from `cobranding` Redux state with methods from `cobrand-utils.ts`
 * for convenience and to avoid code repetition.  If needing values directly from `cobranding` state,
 * such as `domainTheme` fields, use `cobranding.selectors` instead, as importing this can cause circular
 * dependencies.
 *
 * Ideally we'll remove this selectors file once all cobrand config functionality is in Parcon */

/* Recreating instead of importing to avoid circular dependency */
const getCobrandingState = (state: ReduxState) => state.cobranding;

/* Recreating instead of importing to avoid circular dependency */
const getCobrandId = createSelector(
  getCobrandingState,
  (cobrandingState) => cobrandingState.id
);

export const getCobrandUtilMethods = createSelector(getCobrandId, (cobrandId) =>
  getCobrandUtils(cobrandId)
);

/**
 * Returns the url and target for redirecting when clicking the ComeHome logo or null if
 * a `routeChange` should be used instead
 */
export const getComeHomeLogoRedirect = createSelector(
  getCurrentView,
  getCobrandId,
  (currentView, cobrandId) => {
    let campaign;
    if (currentView && HOME_BUYER_ROUTES.includes(currentView)) {
      campaign = 'homebuyer';
    } else if (currentView && HOMEOWNER_ROUTES.includes(currentView)) {
      campaign = 'homeowner';
    }
    return getCobrandUtils(cobrandId).getComeHomeLogoRedirect({
      campaign,
      cobrandId,
      medium: currentView,
    });
  }
);
