import React from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import HeaderBackArrowContainer from '@client/containers/header-back-arrow.container';
import ClickableLogo from '@client/components/ClickableLogo';
import BrokerageAttributionContainer from '@client/containers/brokerage-attribution.container';
import { View } from '@client/routes/constants';
import { useSelector } from 'react-redux';
import { getIsInsideNativeApp } from '@client/store/selectors/cobranding.selectors';
import defaultTheme from '@client/css-modules/HeaderMobileBottomSection.css';
import BrokerageAttributionComeHomeLogo from '@client/components/BrokerageAttributionComeHomeLogo';
import ComeHomeHeaderLogoByHouseCanaryCobranded from '@client/components/ComeHomeHeaderLogo/ComeHomeHeaderLogoByHouseCanaryCobranded';

type Props = {
  isShowingBackNavButton?: boolean;
  currentView?: View | null;
  isMobileMenuOpen?: boolean;
  theme: Theme;
};

const HeaderMobileBottomSection: React.FC<Props> = ({
  isShowingBackNavButton,
  currentView,
  isMobileMenuOpen,
  theme,
}) => {
  const isInsideNativeApp = useSelector(getIsInsideNativeApp);

  return (
    <div className={theme.HeaderMobileBottomSection}>
      {isShowingBackNavButton && (
        <div className={theme.BackButtonWrapper}>
          <HeaderBackArrowContainer />
        </div>
      )}
      <div className={theme.ComeHomeLogoSection}>
        <ClickableLogo
          shouldShowComehomeByHouseCanaryForWells
          dataHcName={'comehome-logo'}
          Logo={BrokerageAttributionComeHomeLogo}
          LogoByHouseCanary={ComeHomeHeaderLogoByHouseCanaryCobranded}
          theme={theme}
          alwaysUseRouteChange={isInsideNativeApp}
        />
      </div>
      {!isShowingBackNavButton && (
        <BrokerageAttributionContainer isMinimalStyling={true} theme={theme} />
      )}
    </div>
  );
};

export default themr(
  'ThemedHeaderMobileBottomSection',
  defaultTheme
)(HeaderMobileBottomSection);
