import { createSelector } from 'reselect';
import { get } from 'lodash';
import {
  getCurrentView,
  getCurrentParams,
  getCurrentQuery,
} from '@src/redux-saga-router-plus/selectors';
import { differenceInDays } from 'date-fns';

import { getIsModalActive } from '@client/store/selectors/modals.selectors';
import { buildSubjectMarker } from '@client/utils/maps.utils';
import { MARKER_IMAGE_IDS } from '@client/store/map-constants';
import {
  PROPERTY_STATUSES,
  STATUSES,
  RegressionType,
  PDP_PUBLIC_OWNER_TOGGLE,
} from '@client/store/constants';
import {
  View,
  VIEW_PATH_PARAM_KEYS,
  HOMEOWNER_ROUTES,
} from '@client/routes/constants';
import { getIsActiveListing } from '@client/utils/property.utils';
import { getPropertyIsActiveStatusType } from '@client/store/selectors/property-details.selectors';
import { ReduxState } from '@client/store/types/redux-state';
import {
  ONE_YEAR_AGO,
  THIS_MONTH,
  ONE_YEAR_FROM_THIS_MONTH,
  SIX_MONTHS_FROM_NOW,
  AVM_CHART_TIME_RANGES,
} from '@client/store/homeowner-constants';
import {
  HomeownerState,
  ChartObjType,
  DataPointType,
  HpiAvmDataPoint,
  TransferDataPoint,
  TransferXY,
  TransferMonths,
} from '@client/store/types/homeowner';
import { PropertyValuesWithRegressionTypes } from '@client/store/types/regression-data';
import { AvmFactor } from '@client/store/types/avm-break-down';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  getActiveSlugForHomeownerAndSubpages,
  getActiveHomeownerProperty,
} from '@client/store/selectors/router.selectors';
import { ReferralPropertyData } from '@client/store/types/property-contact-form';

const DEFAULT_STATUS = PROPERTY_STATUSES.UNKNOWN;

/**
 * Compute data for each series featured in the Homeowner AVM chart
 * @param  {array} rawData a single set of data points
 * @return {object} series data for use in chart plus important display data
 */
const computeHomeownerAvmChartData = (
  avmData: HpiAvmDataPoint[],
  transferData?: TransferDataPoint[]
) => {
  const MONTHS_IN_TEN_YEARS = 121;
  const MONTHS_IN_THREE_YEARS = 37;
  const MONTHS_IN_ONE_YEAR = 13;
  const MONTHS_IN_SIX_MONTHS = 6;
  const CURRENT_DATE = new Date();

  const CHART_DATA_LENGTH = avmData?.length || 0;

  const chartObject: ChartObjType = {
    transfers: {
      [AVM_CHART_TIME_RANGES.SIX_MONTH]: [],
      [AVM_CHART_TIME_RANGES.ONE_YEAR]: [],
      [AVM_CHART_TIME_RANGES.THREE_YEAR]: [],
      [AVM_CHART_TIME_RANGES.TEN_YEAR]: [],
    },
    [AVM_CHART_TIME_RANGES.SIX_MONTH]: [],
    [AVM_CHART_TIME_RANGES.ONE_YEAR]: [],
    [AVM_CHART_TIME_RANGES.THREE_YEAR]: [],
    [AVM_CHART_TIME_RANGES.TEN_YEAR]: [],
  };

  const transferMonths: TransferMonths = {};
  if (!!transferData && transferData?.length !== 0) {
    for (let j = 0; j < transferData.length; j++) {
      const monthsSinceTransfer = monthDiff(
        new Date(transferData[j].transferDate),
        CURRENT_DATE
      );
      transferMonths[monthsSinceTransfer + 2] = transferData[j];
    }
  }

  if (CHART_DATA_LENGTH !== 0) {
    for (let i = 0; i < CHART_DATA_LENGTH; i++) {
      const date = avmData[i].month;
      const dataPoint: DataPointType = {
        x: date,
        y: avmData[i].adjustedValue,
      };

      let transferPoint: TransferXY = { x: undefined, y: undefined };
      const objectPos = avmData.length - i;

      if (
        transferMonths[objectPos] &&
        transferMonths[objectPos].transferPrice &&
        transferMonths[objectPos].eventType !== 'OTHER'
      ) {
        dataPoint.transfer = transferMonths[objectPos].transferPrice;
        transferPoint.x = date;
        transferPoint.y = transferMonths[objectPos].transferPrice;
      }

      if (CHART_DATA_LENGTH - i <= MONTHS_IN_TEN_YEARS) {
        if (transferPoint.y) dataPoint.sale = transferPoint.y;
        chartObject[AVM_CHART_TIME_RANGES.TEN_YEAR].push(dataPoint);
        chartObject.transfers[AVM_CHART_TIME_RANGES.TEN_YEAR].push(
          transferPoint
        );
      }
      if (CHART_DATA_LENGTH - i <= MONTHS_IN_THREE_YEARS) {
        if (transferPoint.y) dataPoint.sale = transferPoint.y;
        chartObject.threeYears.push(dataPoint);
        chartObject.transfers.threeYears.push(transferPoint);
      }
      if (CHART_DATA_LENGTH - i <= MONTHS_IN_ONE_YEAR) {
        if (transferPoint.y) dataPoint.sale = transferPoint.y;
        chartObject.oneYear.push(dataPoint);
        chartObject.transfers.oneYear.push(transferPoint);
      }
      if (CHART_DATA_LENGTH - i <= MONTHS_IN_SIX_MONTHS) {
        if (transferPoint.y) dataPoint.sale = transferPoint.y;
        chartObject.sixMonths.push(dataPoint);
        chartObject.transfers.sixMonths.push(transferPoint);
      }
    }
  }
  return chartObject;

  function monthDiff(d1: Date, d2: Date) {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() + 1;
    months += d2.getMonth();
    return months <= 0 ? 0 : (months as number);
  }
};

export function getHomeownerState(state: ReduxState) {
  return state.homeowner;
}

export const getHomeownerUserDataStatus = createSelector(
  getHomeownerState,
  (homeownerState) => homeownerState.homeownerUserDataStatus
);

export const getHomeownerAvmChartState = createSelector(
  getHomeownerState,
  (homeownerState) => homeownerState.avmChart
);

export const getAvmChartTimeRange = createSelector(
  getHomeownerAvmChartState,
  (avmChartState) =>
    get(avmChartState, 'timeRange', AVM_CHART_TIME_RANGES.TEN_YEAR)
);

export const getAvmAtTargetTimeOnChart = createSelector(
  getHomeownerAvmChartState,
  (avmChartState) => avmChartState.avmAtTargetTimeOnChart
);

export const getDateAtTargetTimeOnChart = createSelector(
  getHomeownerAvmChartState,
  (avmChartState) => avmChartState.dateAtTargetTimeOnChart
);

export const getLoanTermOptions = createSelector(
  getHomeownerState,
  (homeownerState) => homeownerState.loanTermOptions
);

export const getClaimedHomesStatus = createSelector(
  getHomeownerState,
  (homeownerState) => homeownerState.claimedHomesStatus
);

export const getClaimedHomes = createSelector(
  getHomeownerState,
  (homeownerState) => homeownerState.claimedHomes
);

export const getClaimedHomeEditFormStatus = createSelector(
  getHomeownerState,
  (homeownerState) => homeownerState.updateClaimHomeStatus
);

export const getHomeownerPropertyDataCache = createSelector(
  getHomeownerState,
  (homeownerState) => homeownerState.homeownerPropertyDataCache
);

export const getActiveHomeownerPropertyData = createSelector(
  getActiveHomeownerProperty,
  (activeHomeownerProperty) => activeHomeownerProperty?.data || null
);

export const getBuyerOrOwnerPropertySlug = createSelector(
  getCurrentView,
  getCurrentParams,
  (view, params) => {
    if (view === View.PROPERTY_DETAILS) {
      return (
        params[VIEW_PATH_PARAM_KEYS[View.PROPERTY_DETAILS]!.slice(1)] || null
      );
      /* Covers homeowner, home improvement calculator, and pro-finder: */
    } else if (get(params, 'slug', false)) {
      return get(params, 'slug', null);
    } else {
      return null;
    }
  }
);

export const getIsBuyerOrOwnerPropertyInClaimedHomes = createSelector(
  getBuyerOrOwnerPropertySlug,
  getClaimedHomes,
  (slug: string | null, claimedHomes: HomeownerState['claimedHomes']) =>
    slug ? !!claimedHomes[slug] : false
);

export const getIsActivePropertyInUsersClaimedHomes = createSelector(
  getActiveSlugForHomeownerAndSubpages,
  getClaimedHomes,
  (slug, claimedHomes) => !!(slug && claimedHomes[slug])
);

export const getActivePropertyClaimedHomeInfo = createSelector(
  getActiveSlugForHomeownerAndSubpages,
  getClaimedHomes,
  (slug, claimedHomes) => (slug ? claimedHomes[slug] : null)
);

export const getActivePropertyHasUserSeenPMIModal = createSelector(
  getActivePropertyClaimedHomeInfo,
  (activeProperty) => activeProperty?.pmi_modal_was_shown ?? false
);

export const getActivePropertyLtvDetails = createSelector(
  getActiveHomeownerPropertyData,
  (activePropertyData) => activePropertyData?.ltvDetails
);

export const getActivePropertyLtvMean = createSelector(
  getActivePropertyLtvDetails,
  (ltvDetails) => ltvDetails?.ltvMean
);
export const getActivePropertyOriginationLtv = createSelector(
  getActivePropertyLtvDetails,
  (ltvDetails) => ltvDetails?.origination?.ltv
);

export const getIsActivePropertyQualifiedForPMIRemoval = createSelector(
  getActivePropertyLtvMean,
  getActivePropertyOriginationLtv,
  (ltvToday, ltvAtOrig) =>
    typeof ltvAtOrig !== 'undefined' &&
    typeof ltvToday !== 'undefined' &&
    ltvAtOrig >= 0.8 &&
    0.8 >= ltvToday
);

export const getActivePropertyIsLikelyToSell = createSelector(
  getActiveHomeownerPropertyData,
  (activePropertyData) => {
    const propensityToSellThreeMonth =
      activePropertyData?.propensityToSell?.threeMonth;

    return typeof propensityToSellThreeMonth === 'number'
      ? propensityToSellThreeMonth > 0.5
      : false;
  }
);

export const getActivePropertyHasUserSeenPropensityModal = createSelector(
  getActivePropertyClaimedHomeInfo,
  (activeProperty) => !!activeProperty?.interested_in_selling_last_updated
);

export const getActivePropertyTableId = createSelector(
  getActivePropertyClaimedHomeInfo,
  (claimedHome) => get(claimedHome, 'id')
);

export const getPropertyAddress = createSelector(
  getActiveHomeownerPropertyData,
  (activeHomeownerPropertyData) => activeHomeownerPropertyData?.address || null
);

export const getActivePropertyZipCode = createSelector(
  getPropertyAddress,
  (activeHomeownerPropertyAddress) =>
    get(activeHomeownerPropertyAddress, 'zipcode')
);

export const getHomeownerPropertyDataForChartTooltip = createSelector(
  getActiveHomeownerPropertyData,
  (data) => {
    const mlsState = data?.mlsState || null;
    const listDate = data?.listDate || null;
    return {
      beds: data?.livingSpace?.bedrooms?.count,
      baths: data?.livingSpace?.bathrooms?.summaryCount,
      city: data?.address?.city,
      daysOnMarket:
        getIsActiveListing(mlsState) && listDate
          ? differenceInDays(new Date(), new Date(listDate))
          : null,
      sqFeet: data?.livingSpace?.livingArea,
      streetAddress: data?.address?.streetAddress,
      state: data?.address?.state,
      unit: data?.address?.unit,
      zipcode: data?.address?.zipcode,
    };
  }
);

export const getHcAddressId = createSelector(getPropertyAddress, (address) =>
  get(address, 'hcAddressId')
);

export const getHomeownerClaimedHomeEdits = createSelector(
  getHomeownerState,
  (homeownerState) => {
    const editState = homeownerState.editClaimedHomeForm;
    const { afterMortgageUpdateAction, ...actualEdits } = editState;
    return actualEdits;
  }
);

export const getIsHomeDetailsFormEdited = createSelector(
  getHomeownerClaimedHomeEdits,
  (formState) => {
    const hasEdit = Object.keys(formState).find(
      (key) => formState[key].overridden === true
    );
    return !!hasEdit;
  }
);

export const getAvmPriceMean = createSelector(
  getActiveHomeownerPropertyData,
  (activeHomeownerPropertyData) => {
    return activeHomeownerPropertyData?.avm?.priceMean;
  }
);

export const getAvmPriceUpper = createSelector(
  getActiveHomeownerPropertyData,
  (activeHomeownerPropertyData) => {
    return activeHomeownerPropertyData?.avm?.priceUpper;
  }
);

export const getHomeownerInitStatus = createSelector(
  getActiveHomeownerProperty,
  (activeHomeownerProperty) =>
    activeHomeownerProperty?.initHomeownerPageStatus || STATUSES.INIT
);

export const getHomeownerManageHomesInitStatus = createSelector(
  getHomeownerState,
  (homeownerState) =>
    get(homeownerState, 'initManageHomesDataStatus', STATUSES.INIT)
);

export const getIsDoneGettingDataForAllClaimedHomes = createSelector(
  getClaimedHomes,
  (claimedHomes) => {
    const homesArr = Object.keys(claimedHomes);
    const homesWithData = homesArr.filter((home) => !!claimedHomes[home]?.data);
    return homesWithData.length >= homesArr.length;
  }
);

export const getHomeownerPropertyCardDetailsStatus = createSelector(
  getActiveHomeownerProperty,
  (activeHomeownerProperty) =>
    get(activeHomeownerProperty, 'propertyCardDetailsStatus')
);

export const getHomeownerPropertyPhoto = createSelector(
  getActiveHomeownerPropertyData,
  (activeHomeownerPropertyData) => activeHomeownerPropertyData?.photos?.[0]
);

export const getHomeownerHpiData = createSelector(
  getActiveHomeownerPropertyData,
  (activeHomeownerPropertyData) => {
    return activeHomeownerPropertyData?.zip?.hpi || [];
  }
);

function getHpiValueForMonth(
  hpiData: ReturnType<typeof getHomeownerHpiData>,
  dateConstant: string
) {
  const neededMonth = hpiData.find(
    (val) => val.month.substring(0, 7) === dateConstant.substring(0, 7)
  );
  return neededMonth?.adjustedValue ?? null;
}

export const getHomeownerHpiDateOneYearAgo = createSelector(
  getHomeownerHpiData,
  (hpiData) => {
    const neededMonth = hpiData.find(
      (val) => val.month.substring(0, 7) === ONE_YEAR_AGO.substring(0, 7)
    );
    return neededMonth?.month;
  }
);

export const getHomeownerHpiDateThisMonth = createSelector(
  getHomeownerHpiData,
  (hpiData) => {
    const neededMonth = hpiData.find(
      (val) => val.month.substring(0, 7) === THIS_MONTH.substring(0, 7)
    );
    return neededMonth?.month;
  }
);

export const getHomeownerHpiMonthOneYearLater = createSelector(
  getHomeownerHpiData,
  (hpiData) => {
    const neededMonth = hpiData.find(
      (val) =>
        val.month.substring(0, 7) === ONE_YEAR_FROM_THIS_MONTH.substring(0, 7)
    );
    return neededMonth?.month;
  }
);

export const getHomeownerHpiForOneYearAgo = createSelector(
  getHomeownerHpiData,
  (hpiData) => {
    if (hpiData) {
      return getHpiValueForMonth(hpiData, ONE_YEAR_AGO);
    } else {
      return null;
    }
  }
);

export const getHomeownerHpiForThisMonth = createSelector(
  getHomeownerHpiData,
  (hpiData) => {
    if (hpiData) {
      return getHpiValueForMonth(hpiData, THIS_MONTH);
    } else {
      return null;
    }
  }
);

export const getHomeownerHpiForNextYear = createSelector(
  getHomeownerHpiData,
  (hpiData) => {
    if (hpiData) {
      return getHpiValueForMonth(hpiData, ONE_YEAR_FROM_THIS_MONTH);
    } else {
      return null;
    }
  }
);

export const getHomeownerHpiForSixMonthsFromNow = createSelector(
  getHomeownerHpiData,
  (hpiData) => {
    if (hpiData) {
      return getHpiValueForMonth(hpiData, SIX_MONTHS_FROM_NOW);
    } else {
      return null;
    }
  }
);

export const getHpiHistorical = createSelector(
  getHomeownerHpiData,
  (homeownerHpiData) => {
    const hpiHistoricalData: HpiAvmDataPoint[] = [];
    for (let i = 0; i < homeownerHpiData.length; i++) {
      hpiHistoricalData.push(homeownerHpiData[i]);
      if (homeownerHpiData[i].month === THIS_MONTH) {
        return hpiHistoricalData;
      }
    }
    return hpiHistoricalData;
  }
);

export const getHomeownerPropertyTransfers = createSelector(
  getActiveHomeownerPropertyData,
  (activeHomeownerPropertyData) => activeHomeownerPropertyData?.transfers
);

export const getHomeownerAvmComputedChartData = createSelector(
  getHpiHistorical,
  getHomeownerPropertyTransfers,
  (avmChartData, propertyTransfers) =>
    computeHomeownerAvmChartData(
      avmChartData,
      propertyTransfers as TransferDataPoint[]
    )
);

export const getHomeownerAvmChartDataExists = createSelector(
  getHomeownerAvmComputedChartData,
  (homeownerAvmChartData) => {
    if (homeownerAvmChartData) {
      const { tenYears } = homeownerAvmChartData;
      if (tenYears && tenYears.length > 0) return true;
    }
    return false;
  }
);

export const getFirstAvmOnChart = createSelector(
  getHomeownerAvmComputedChartData,
  getAvmChartTimeRange,
  (homeownerAvmChartData, avmChartTimeRange) =>
    get(homeownerAvmChartData, [avmChartTimeRange, '0', 'y'])
);

export const getFirstDateOnChart = createSelector(
  getHomeownerAvmComputedChartData,
  getAvmChartTimeRange,
  (homeownerAvmChartData, avmChartTimeRange) =>
    get(homeownerAvmChartData, [avmChartTimeRange, '0', 'x'])
);

export const getLastDateOnChart = createSelector(
  getHomeownerAvmComputedChartData,
  getAvmChartTimeRange,
  (homeownerAvmChartData, avmChartTimeRange) => {
    let lastIndex;
    const selectedHomeownerAvmChartData = get(
      homeownerAvmChartData,
      avmChartTimeRange
    );
    if (selectedHomeownerAvmChartData) {
      lastIndex = selectedHomeownerAvmChartData.length - 1;
    }
    return get(homeownerAvmChartData, [avmChartTimeRange, lastIndex, 'x']);
  }
);

export const getMarketStatsPast = createSelector(
  getActiveHomeownerPropertyData,
  (activeHomeownerPropertyData) => activeHomeownerPropertyData?.marketStats?.[1]
);

export const getMarketStatsCurrent = createSelector(
  getActiveHomeownerPropertyData,
  (activeHomeownerPropertyData) => activeHomeownerPropertyData?.marketStats?.[0]
);

export const getHomeownerShapedPropertyAddress = createSelector(
  getPropertyAddress,
  (address) => {
    return {
      address: address && {
        ...address,
        street: address.streetAddress,
        state: address.state,
        // For compatibility with comp lib component
        addrState: address.state,
        zip: address.zipcode,
      },
    };
  }
);

export const getAvmCardData = createSelector(
  getHomeownerShapedPropertyAddress,
  getAvmPriceMean,
  getHomeownerPropertyPhoto,
  getHpiHistorical,
  (shapedAddress, avm, photo, hpiHistorical) => {
    let lastAdjustedValue: number | null = null;
    if (hpiHistorical && hpiHistorical.length > 0) {
      lastAdjustedValue = hpiHistorical[hpiHistorical.length - 2].adjustedValue;
    }
    return {
      address: shapedAddress.address,
      avm: avm,
      photo: photo,
      lastValue: lastAdjustedValue,
    };
  }
);

export const getHomeownerAvmFactors = createSelector(
  getActiveHomeownerPropertyData,
  (activePropertyData) => get(activePropertyData, 'avmFactors')
);

const locationAvmFactor: AvmFactor = {
  defaultDescription:
    'Use the map below to explore pricing trends in the area.',
  label: 'Location',
  type: 'LocationAvmFactor',
  value: 0,
  description: '',
};

export const getHomeownerAvmFactorsWithLocation = createSelector(
  getHomeownerAvmFactors,
  (state, showLocationInDataDeepDives) => showLocationInDataDeepDives,
  (avmFactors, showLocationInDataDeepDives) => {
    let avmFactorsWithLocation = avmFactors ? [...avmFactors] : [];

    if (showLocationInDataDeepDives) {
      avmFactorsWithLocation.unshift(locationAvmFactor);
    }

    return avmFactorsWithLocation;
  }
);

export const getHomeownerRegressionsData = createSelector(
  getActiveHomeownerPropertyData,
  (state, showLocationInDataDeepDives) => showLocationInDataDeepDives,
  (activePropertyData, showLocationInDataDeepDives) => {
    let regressionsData =
      activePropertyData?.avmDeepDiveData?.regressionsData || [];
    /**
     * RegressionsData from backend is empty array or null but we have median block price heatmaps data,
     * adding in location to show in data deep dives in this case.
     */
    if (regressionsData.length === 0 && showLocationInDataDeepDives) {
      regressionsData.unshift({
        ...locationAvmFactor,
        displayName: 'Location',
        description: undefined,
        type: RegressionType.LOCATION,
        regression: {
          line: [],
        },
      });
    }
    /**
     * RegressionsData from backend has Location but we do not have median block price heatmaps data,
     * hence not showing location in data deep dives.
     */
    if (!showLocationInDataDeepDives) {
      regressionsData =
        regressionsData.filter(
          (item) => item.type.toUpperCase() !== RegressionType.LOCATION
        ) || [];
    }
    return regressionsData;
  }
);

/**
 * tractStats count is total number of properties used in the regression check
 */
export const getHomeownerTractStatsCount = createSelector(
  getActiveHomeownerPropertyData,
  (activePropertyData) => activePropertyData?.avmDeepDiveData?.tractStatsCount
);

export const getHomeownerValuesWithRegressionTypes = createSelector(
  getActiveHomeownerPropertyData,
  (activePropertyData) => {
    const { livingSpace, site, structure } = activePropertyData || {};

    const currYear = new Date().getFullYear();
    const yearBuilt = structure?.yearBuilt || null;
    const ageOfProperty = yearBuilt ? currYear - yearBuilt : null;

    return {
      [RegressionType.LIVINGAREA]: livingSpace?.livingArea || null,
      [RegressionType.LOTSIZE]: site?.area || null,
      [RegressionType.BEDROOMS]: livingSpace?.bedrooms?.count || null,
      [RegressionType.BATHROOMS]: livingSpace?.bathrooms?.summaryCount || null,
      [RegressionType.AGE]: ageOfProperty,
    } as PropertyValuesWithRegressionTypes;
  }
);

export const getHomeownerPropertyCardData = createSelector(
  getMarketStatsPast,
  getMarketStatsCurrent,
  getHomeownerShapedPropertyAddress,
  (marketStatsPast, marketStatsCurrent, shapedAddress) => {
    return {
      address: shapedAddress.address,
      marketStatsPast,
      marketStatsCurrent,
    };
  }
);

export const getHomeownerPropertyLocation = createSelector(
  getActiveHomeownerPropertyData,
  (activeHomeownerPropertyData) =>
    activeHomeownerPropertyData?.geoLocation || null
);

export const getHomeownerPropertyAddressSlug = createSelector(
  getActiveHomeownerPropertyData,
  (activeHomeownerPropertyData) => activeHomeownerPropertyData?.address?.slug
);

export const formattedDate = (dateStr) => {
  type DateFormatOptions = { [key: string]: string };
  const longDateFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const shortDateFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  };

  if (dateStr) {
    const date = new Date(dateStr);
    return {
      longDateFormat: date.toLocaleDateString(
        'en-US',
        longDateFormatOptions as DateFormatOptions
      ),
      shortDateFormat: date.toLocaleDateString(
        'en-US',
        shortDateFormatOptions as DateFormatOptions
      ),
    };
  } else {
    return {};
  }
};

export const getPropertyListingStatus = (property, summary?) => {
  return summary
    ? get(summary, ['mlsState'])
    : get(property, ['mlsState']) ||
        get(property, ['latestListing', 'status'], DEFAULT_STATUS);
};

export const getPropertyBuildingId = createSelector(
  getActiveHomeownerPropertyData,
  (activeHomeownerPropertyData) =>
    activeHomeownerPropertyData?.hcBuildingId || null
);

export const getHomeownerMapMarkerFeature = createSelector(
  getHomeownerPropertyAddressSlug,
  getHomeownerPropertyLocation,
  getAvmPriceMean,
  getPropertyBuildingId,
  (slug, location, value, buildingId) => {
    const marker =
      slug &&
      buildSubjectMarker({
        label: null,
        labelColor: null,
        addressSlug: slug,
        latitude: location ? location.latitude : null,
        longitude: location ? location.longitude : null,
        imageId: MARKER_IMAGE_IDS.SUBJECT_OFF_MARKET,
        buildingId,
      });
    return marker
      ? {
          type: 'Feature' as 'Feature',
          properties: marker,
          geometry: {
            type: 'Point' as 'Point',
            coordinates: [marker.lng, marker.lat] as [number, number],
          },
        }
      : null;
  }
);

export const getHomeownerPropertyLatLong = createSelector(
  getHomeownerPropertyLocation,
  (location) => {
    return {
      latitude: location?.latitude || null,
      longitude: location?.longitude || null,
    };
  }
);

export const getHomeImprovementsCalculationDetails = createSelector(
  getActiveHomeownerProperty,
  (activeProperty) => activeProperty?.homeImprovements || null
);

export const getMaxHomeConditionValue = createSelector(
  getActiveHomeownerPropertyData,
  (activePropertyData) => {
    const conditions = get(activePropertyData, ['condition'], null);
    if (conditions) {
      const homeConditionKeys = Object.keys(conditions);
      return conditions[homeConditionKeys[homeConditionKeys.length - 2]];
    }

    return conditions;
  }
);

export const getHomeImprovementsCalculationDetailsStatus = createSelector(
  getActiveHomeownerProperty,
  (activeProperty) => activeProperty?.initHomeImprovementsStatus || null
);

export const getPropertyValueForecastBasedOnBlockData = createSelector(
  getActiveHomeownerPropertyData,
  (activePropertyData) =>
    activePropertyData?.block ? activePropertyData.block.valueTsForecast : null
);

/* fetches 3 month past value + current value + 12 month forecast value */
export const getPropertyValueForecastBasedOnZipData = createSelector(
  getHomeownerHpiData,
  (homeownerHpiData) => {
    const currentMonthIdx = (homeownerHpiData || []).findIndex(
      (data) => data.month === THIS_MONTH
    );
    return homeownerHpiData.slice(currentMonthIdx - 3, currentMonthIdx + 13);
  }
);

export const getIsActivePropertyOnMarket = createSelector(
  getActiveHomeownerPropertyData,
  (activePropertyData) => {
    const status = getPropertyListingStatus(activePropertyData);
    return getIsActiveListing(status);
  }
);

export const getHomeownerDeepDiveStatus = createSelector(
  getActiveHomeownerProperty,
  (activeProperty) => activeProperty?.avmDeepDiveStatus || null
);

export const getOptedInToSellSetting = createSelector(
  getActivePropertyClaimedHomeInfo,
  (details) => {
    return get(details, 'opted_in_to_sell');
  }
);

export const getAfterMortgageUpdateAction = createSelector(
  getHomeownerState,
  (homeownerState) =>
    get(homeownerState, ['editClaimedHomeForm', 'afterMortgageUpdateAction'])
);

export const getShouldDisplayHomeownerHomeBuyerToggle = createSelector(
  getIsBuyerOrOwnerPropertyInClaimedHomes,
  getCurrentView,
  getIsActivePropertyOnMarket,
  getPropertyIsActiveStatusType,
  getCurrentQuery,
  (state) => getIsFeatureEnabled('homeowner')(state),
  (
    isInClaimedHomes,
    view,
    isHomeownerActiveListing,
    isHomeBuyerActiveListing,
    query,
    isHomeownerEnabled
  ) => {
    const isToggleEnabledViaQueryParam = !!query[PDP_PUBLIC_OWNER_TOGGLE];
    const isOffMarket =
      view === View.HOMEOWNER_PROPERTY_DETAILS
        ? !isHomeownerActiveListing
        : !isHomeBuyerActiveListing;
    return (
      isHomeownerEnabled &&
      (isOffMarket || isInClaimedHomes || isToggleEnabledViaQueryParam)
    );
  }
);

export const getIsShowingHomeownerSearchModal = getIsModalActive(
  'homeowner-search-modal'
);
export const getIsProFinderReviewsModalActive = getIsModalActive(
  'cho-pro-finder-reviews'
);

/* FOR HOME IMPROVEMENT PAGE */
export const getHomeImprovementCalculatorData = createSelector(
  getHomeownerState,
  (homeownerState) => homeownerState.homeImprovementCalculator
);

export const getSelectedHomeProjects = createSelector(
  getHomeImprovementCalculatorData,
  getActiveSlugForHomeownerAndSubpages,
  (homeImprovementCalculator, slug) => {
    return slug && homeImprovementCalculator.selectedHomeProjects[slug]
      ? homeImprovementCalculator.selectedHomeProjects[slug]
      : {};
  }
);

export const getHomeAdvisorEstimatedCost = createSelector(
  getActiveHomeownerProperty,
  (activeProperty) => activeProperty?.homeAdvisorEstimatedCost || null
);

export const getProFinderState = createSelector(
  getHomeownerState,
  (homeownerState) => homeownerState.proFinder
);

export const getProFinderCostEstimates = createSelector(
  getProFinderState,
  (proFinderState) => proFinderState.costEstimates
);

export const getProFinderStatus = createSelector(
  getProFinderState,
  (proFinderState) => proFinderState.proFinderStatus
);

export const getProFinderReferralStatus = createSelector(
  getProFinderState,
  (proFinderState) => proFinderState.proFinderReferralStatus
);

export const getProFinderReferralState = createSelector(
  getProFinderState,
  (proFinderState) => proFinderState.referrals
);

export const getProFinderReferralProjectId = createSelector(
  getProFinderReferralState,
  (referralState) => referralState.projectId
);

export const getProFinderReferralBusinesses = createSelector(
  getProFinderReferralState,
  (referralState) => referralState.businesses
);

export const getProFinderQuoteContactNumber = createSelector(
  getProFinderState,
  (proFinderState) => proFinderState.quoteContact
);

export const getProFinderReviewsModalStatus = createSelector(
  getProFinderState,
  (proFinderState) => proFinderState.proFinderReviewsModalStatus
);

export const getProFinderReviews = createSelector(
  getProFinderState,
  (proFinderState) => proFinderState.reviews
);

export const getProFinderActiveReviewId = createSelector(
  getProFinderState,
  (proFinderState) => proFinderState.activeReviewId
);

/* For auto filling the form in the find-agent-seller page */
export const getSelectedPropertyForReferSellerApi = (
  addressSlug: string | null
) =>
  createSelector(
    getHomeownerPropertyDataCache,
    (dataCache): ReferralPropertyData | null => {
      const property = addressSlug ? dataCache[addressSlug] : null;
      if (!property) {
        return null;
      }

      const data = property.data;
      const bestPhoto = data?.bestPhotos && data.bestPhotos[0];
      return {
        address: data?.address?.streetAddress,
        city: data?.address?.city,
        zip: data?.address?.zipcode,
        minPrice: 0,
        homeType: data?.propertyType || 'other',
        state: data?.address?.state,
        unit: data?.address?.unit ?? null,
        beds: data?.livingSpace?.bedrooms?.count ?? null,
        baths: data?.livingSpace?.bathrooms?.summaryCount ?? null,
        sqFt: data?.livingSpace?.livingArea ?? null,
        mlsLogoOverlay: data?.mls?.regulations?.logoOverlay ?? null,
        photo: bestPhoto?.representation?.httpUrl,
      };
    }
  );

export const getIsHomeownerRoute = createSelector(
  getCurrentView,
  (currentView) => !!(currentView && HOMEOWNER_ROUTES.includes(currentView))
);

export const getHOAnalyticsEventAddress = createSelector(
  getPropertyAddress,
  (property) => {
    return property
      ? {
          slug: property.slug,
          unit: property.unit || null,
          street: property.streetAddress,
          city: property.city,
          state: property.state,
          zip: property.zipcode,
          address_id: property.hcAddressId,
        }
      : null;
  }
);
