import { connect } from 'react-redux';

import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import { getIsInsideNativeApp } from '@client/store/selectors/cobranding.selectors';
import SlideInModal from '@client/components/generic/SlideInModal';

type OwnProps = {
  isFullScreen?: boolean;
  hideCloseIcon?: boolean;
  dataHcName?: string;
};

const mapStateToProps = (state, ownProps: OwnProps) => ({
  isFullScreen:
    typeof ownProps.isFullScreen !== 'undefined'
      ? ownProps.isFullScreen
      : getIsSmallSize(state),
  hideCloseIcon:
    (getIsInsideNativeApp(state) && getIsSmallSize(state)) ||
    ownProps.hideCloseIcon,
});

export default connect(mapStateToProps, {})(SlideInModal);
