import { createSelector } from 'reselect';

import { ReduxState } from '@client/store/types/redux-state';
import { AlertsState } from '@client/store/reducers/alerts.reducer';
import { STATUSES } from '@client/store/constants';

export function getAlertsState(state: ReduxState): AlertsState {
  return state.alerts;
}

export const getAllAlerts = createSelector(
  getAlertsState,
  (alertsState) => alertsState.items
);

export const getAlertsStatus = createSelector(
  getAlertsState,
  (alertsState) => alertsState.status
);

export const getIsAlertsInitOrLoading = createSelector(
  getAlertsStatus,
  (alertsStatus) =>
    alertsStatus === STATUSES.INIT || alertsStatus === STATUSES.LOADING
);

export const getUnreadAlertsCount = createSelector(getAlertsState, (alerts) => {
  return alerts.unreadAlertsCount;
});

export const getAlertsMarkAsReadUrl = createSelector(
  getAlertsState,
  (alertsState) => {
    return alertsState.markAsReadUrl;
  }
);

export const getAlertsNextPageUrl = createSelector(
  getAlertsState,
  (alertsState) => {
    return alertsState.nextPageUrl;
  }
);

export const getHasResetUnreadAlerts = createSelector(
  getAlertsState,
  (alertsState) => {
    return alertsState.hasResetUnreadAlerts;
  }
);
