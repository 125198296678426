import { RefObject } from 'react';
import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import LegacyLenderCTAButton from '@client/components/LegacyLenderCTAButton/LegacyLenderCTAButton';
import LegacyLenderCTAButtonTopAgentsRanked from '@client/components/LegacyLenderCTAButton/LegacyLenderCTAButtonTopAgentsRanked';
import { Theme } from '@friendsofreactjs/react-css-themr';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import { AnalyticsEventAddress } from '@client/store/types/analytics';

type Props = {
  address?: AnalyticsEventAddress;
  className?: string;
  theme?: Theme;
  returnButtonRef?: RefObject<HTMLButtonElement>;
  reportAnalytics?: () => void;
  style?: { [cssKey: string]: string | number };
  ctaTitle?: string;
  dataHcName?: string;
  ['data-event-name']?: string;
  ['data-parent-event-name']?: string;
};

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.TOPAGENTSRANKED:
      return LegacyLenderCTAButtonTopAgentsRanked;
    case COBRAND_IDS.WWW:
      return null;
    default:
      return LegacyLenderCTAButton;
  }
};

const LegacyLenderCTAButtonCobranded: React.FC<Props> = (props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return Component ? <Component {...props} /> : null;
};

export default LegacyLenderCTAButtonCobranded;
