import { RefObject } from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import defaultTheme from '@client/css-modules/LenderCTAButton.css';
import { useDispatch, useSelector } from 'react-redux';
/* Actions */
import { routeChange } from '@src/redux-saga-router-plus/actions';
import { logOutThenRedirect } from '@client/store/actions/auth.actions';
/* Selectors */
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  getUserBuyingPower,
  getIsLoggedIn,
  getUserPartnerUserId,
} from '@client/store/selectors/auth.selectors';
import { getPropertyDetailsNormalized } from '@client/store/selectors/property-details.selectors';
import {
  getPreQualified,
  getActivePDPSlug,
} from '@client/store/selectors/router.selectors';
import {
  getCustomizationData,
  getCobrandId,
} from '@client/store/selectors/cobranding.selectors';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';
/* Utils */
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { getPropertyValueAndValueLabel } from '@client/utils/property.utils';
import {
  addPartnerPropertyAndUserIdToUrl,
  getTargetView,
} from '@client/utils/routing.utils';
/* HOCs & Hooks */
import LOInfoProvider from '@client/hocs/lo-info-provider';
import { useAutoSaveProperty } from '@client/hooks/auto-save-property.hooks';
/* Constants and Types */
import { View, HOME_BUYER_ROUTES } from '@client/routes/constants';
import { LOInfoProviderArgs } from '@client/store/types/loan-officer';
import { COBRAND_IDS } from '@client/store/types/cobranding';
/* Components */
import PillButton from '@client/components/generic/PillButton';
import { useCobrandData } from '@client/hooks/cobrand-data.hooks';

type Props = {
  className?: string;
  theme: Theme;
  returnButtonRef?: RefObject<HTMLButtonElement>;
  reportAnalytics?: () => void;
  style?: { [cssKey: string]: string | number };
  ctaTitle?: string;
  onClick?: () => void;
  /* For event reporting */
  ['data-event-name']?: string;
  ['data-parent-event-name']?: string;
  dataHcName?: string;
};

const LegacyLenderCTAButton = ({
  theme,
  returnButtonRef,
  reportAnalytics,
  className,
  style,
  ctaTitle,
  onClick,
  ['data-event-name']: dataEventName,
  ['data-parent-event-name']: dataParentEventName,
  dataHcName,
}: Props) => {
  const dispatch = useDispatch();
  const autoSaveProperty = useAutoSaveProperty();
  const customizationData = useSelector(getCustomizationData);
  const propertyId = useSelector(getActivePDPSlug);
  const userId = useSelector(getUserPartnerUserId);
  const isBuyingPowerFeatureActivated = useSelector(
    getIsFeatureEnabled('buying_power')
  );
  const userBuyingPower = useSelector(getUserBuyingPower);
  const isPreQualified = useSelector(getPreQualified);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const shouldLogoutOnPartnerCtaClick = useSelector(
    getIsFeatureEnabled('should_logout_on_partner_cta_click')
  );
  const propertyDetails = useSelector(getPropertyDetailsNormalized);
  const isTARCobrand =
    useSelector(getCobrandId) === COBRAND_IDS.TOPAGENTSRANKED;

  const valueAndValueLabel = !propertyDetails
    ? { value: 0, valueLabel: '' }
    : getPropertyValueAndValueLabel(propertyDetails);
  const { value } = valueAndValueLabel;
  const { lender_cta_pdp_button_color } = useCobrandData();
  const currentView = useSelector(getCurrentView);

  /**
   * Return the button text and parent app destination for the button
   */
  const getLenderCTAButtonText = (homeValue) => {
    if (isPreQualified) {
      return {
        text: 'Purchase with Lendco',
      };
    } else if (isBuyingPowerFeatureActivated && !userBuyingPower) {
      return {
        text: 'Set up buying power',
      };
    } else if (
      isBuyingPowerFeatureActivated &&
      userBuyingPower &&
      homeValue &&
      homeValue > userBuyingPower
    ) {
      return {
        text: 'Update buying power',
      };
    } else {
      return {
        text: 'See loan options',
      };
    }
  };

  const handleRouteChange = (targetView: View) => {
    dispatch(routeChange({ view: targetView }));
  };

  const getIsInternalUrl = (ctaLinkFromParcon?: string) => {
    const targetView = getTargetView(ctaLinkFromParcon);
    if (targetView && HOME_BUYER_ROUTES.includes(targetView)) {
      if (
        (ctaLinkFromParcon &&
          ctaLinkFromParcon.indexOf('.comehome.com') >= 0) ||
        (ctaLinkFromParcon &&
          ctaLinkFromParcon.indexOf('.housecanary.net') >= 0)
      ) {
        return true;
      }
    }
    return false;
  };

  const redirectToExternalUrl = (ctaLink: string) => {
    if (isLoggedIn && shouldLogoutOnPartnerCtaClick) {
      dispatch(logOutThenRedirect(ctaLink));
    } else {
      window.open(ctaLink, '_blank');
    }
  };

  const lenderCTAButtonOnClick = (ctaLinkFromParcon?: string) => {
    if (ctaLinkFromParcon !== null) {
      const targetView = getTargetView(ctaLinkFromParcon);
      const isInternalUrl = getIsInternalUrl(ctaLinkFromParcon);

      autoSaveProperty();
      reportAnalytics?.();

      if (targetView && isInternalUrl) {
        handleRouteChange(targetView);
      } else if (ctaLinkFromParcon) {
        const fullLinkStr = addPartnerPropertyAndUserIdToUrl(
          ctaLinkFromParcon,
          customizationData,
          propertyId,
          userId
        );
        redirectToExternalUrl(fullLinkStr);
      }
    }
  };

  return (
    <LOInfoProvider>
      {({ loConfig, loCtaUrl, loData }: LOInfoProviderArgs) => {
        const buttonText =
          loConfig?.hbPdp?.cta ?? getLenderCTAButtonText(value).text;

        let shouldShowButton = true;
        if (isTARCobrand) {
          shouldShowButton = false;
        }

        return shouldShowButton ? (
          <div
            className={
              className
                ? classNames(className, theme.LenderCTA)
                : theme.LenderCTA
            }
            data-testid="lender-cta"
          >
            <PillButton
              dataHcName={dataHcName}
              data-event-name={dataEventName}
              data-parent-event-name={dataParentEventName}
              forwardedRef={returnButtonRef}
              ariaLabel={buttonText}
              className={theme.LenderCTAButton}
              onClick={() => {
                /* Note: this supports the LegacyLenderCTAButtonTopAgentsRanked component's usage of this component */
                if (onClick) {
                  onClick();
                } else {
                  lenderCTAButtonOnClick(loCtaUrl);
                }
              }}
              onKeyDown={onEnterOrSpaceKey(() => {
                /* Note: this supports the LegacyLenderCTAButtonTopAgentsRanked component's usage of this component */
                if (onClick) {
                  onClick();
                } else {
                  lenderCTAButtonOnClick(loCtaUrl);
                }
              })}
              customBackgroundColor={
                currentView === View.PROPERTY_DETAILS &&
                lender_cta_pdp_button_color
                  ? lender_cta_pdp_button_color
                  : undefined
              }
              style={style}
            >
              {ctaTitle ?? buttonText}
            </PillButton>
          </div>
        ) : null;
      }}
    </LOInfoProvider>
  );
};

export default themr(
  'ThemedLegacyLenderCTAButton',
  defaultTheme
)(LegacyLenderCTAButton);
