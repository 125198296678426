import { themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import PillButton from '@client/components/generic/PillButton';
import MarketingModuleHouseImg from '@client/components/HomeownerAdImg/MarketingModuleHouseImg';
import defaultTheme from '@client/css-modules/LenderCTACard.css';
import {
  useLenderCtaNotPdpUrl,
  useLenderCtaUrlModifier,
} from '@client/hooks/lender-cta-config.hooks';
import { useNativeAppLenderCTAClick } from '@client/hooks/native-app-integration.hooks';
import { useOutboundCtaUrl } from '@client/hooks/outbound-cta-url.hooks';
import { useLoDirectLenderCtaClick } from '@client/hooks/your-team.hooks';
import { EVENTS, PARENT_EVENTS } from '@client/store/analytics-constants';
import {
  getIsInsideNativeApp,
  getLenderCtasConfig,
} from '@client/store/selectors/cobranding.selectors';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import { getActiveHomeownerOrPDPSlug } from '@client/store/selectors/router.selectors';
import { AnalyticsLenderCTADefault } from '@client/store/types/analytics';
import {
  CARD_TYPES,
  LenderCTAProps,
} from '@client/store/types/cobranded-components/lender-cta-card';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';

const LenderCTACard = ({
  theme = {},
  cardType,
  area,
  ordinal,
}: LenderCTAProps) => {
  const isSmallScreen = useSelector(getIsSmallSize);
  const lenderCtasConfig = useSelector(getLenderCtasConfig);
  const LODirectLenderCTAClick = useLoDirectLenderCtaClick();
  const nativeAppLenderCTAClick = useNativeAppLenderCTAClick();
  const isInsideNativeApp = useSelector(getIsInsideNativeApp);
  const contextualAddressSlug = useSelector(getActiveHomeownerOrPDPSlug);
  const modifiedCtaUrl = useLenderCtaUrlModifier(useLenderCtaNotPdpUrl(area));
  const outboundCtaUrl = useOutboundCtaUrl(modifiedCtaUrl);

  if (!lenderCtasConfig || !modifiedCtaUrl || !outboundCtaUrl) {
    return null;
  }

  const {
    [area]: {
      headline,
      ctaText,
      headlineFontColor,
      headlineBackgroundColor,
      buttonColor,
      buttonFontColor,
    },
  } = lenderCtasConfig;
  const nativeAppNavKey = lenderCtasConfig?.[area]?.nativeAppNavKey;

  const onClick = () => {
    if (LODirectLenderCTAClick) {
      LODirectLenderCTAClick();
    } else if (isInsideNativeApp && nativeAppNavKey) {
      nativeAppLenderCTAClick(nativeAppNavKey, {
        addressSlug: contextualAddressSlug,
      });
    } else {
      outboundCtaUrl && window.open(outboundCtaUrl, '_blank');
    }
  };
  const clickEventDataJson: AnalyticsLenderCTADefault = {
    area,
    cta_url: modifiedCtaUrl,
    button_copy: ctaText,
    headline_copy: headline,
    cta_ordinal: ordinal,
  };

  return (
    <div
      className={classNames(theme.LenderCTACard, {
        [theme.FullCard]:
          cardType === CARD_TYPES.FULL ||
          (cardType === CARD_TYPES.BOTH && !isSmallScreen),
        [theme.SmallCard]:
          cardType === CARD_TYPES.SMALL ||
          (cardType === CARD_TYPES.BOTH && isSmallScreen),
      })}
      style={
        headlineBackgroundColor
          ? {
              backgroundColor: headlineBackgroundColor,
            }
          : {}
      }
    >
      <div className={theme.HeadlineWrapper}>
        {headline && (
          <div
            className={theme.HeadlineCopy}
            style={{ color: headlineFontColor }}
          >
            {headline}
          </div>
        )}
        {outboundCtaUrl && ctaText && (
          <div className={theme.LenderCTA}>
            <PillButton
              data-event-name={EVENTS.CLICK_LENDER_CTA}
              data-parent-event-name={PARENT_EVENTS.CLICK_LENDER_CTA}
              data-event-data-json={JSON.stringify(clickEventDataJson)}
              ariaLabel={ctaText}
              className={theme.LenderCTAButton}
              onClick={onClick}
              onKeyDown={onEnterOrSpaceKey(onClick)}
              customBackgroundColor={buttonColor}
              customTextColor={buttonFontColor}
            >
              {ctaText}
            </PillButton>
          </div>
        )}
      </div>
      <div className={theme.AdImgContainer}>
        <MarketingModuleHouseImg className={theme.AdImg} />
      </div>
    </div>
  );
};

export default themr('ThemedLenderCTACard', defaultTheme)(LenderCTACard);
