import { PARENT_EVENTS } from '@client/store/analytics-constants';
import {
  SEARCH_LIST_SORT_FIELD_TYPES,
  SEARCH_LIST_SORT_ORDER_TYPES,
} from '@client/store/constants';
import { FilterKey } from '@client/store/filter-constants';
import {
  BoundsArray,
  GaiaSchoolsById,
  LatLngObject,
  MapMarker,
  MLSCoverageLayerFeatureProperties,
  MultiUnitDataObject,
  PositionArray,
  PropertyMapMarker,
} from '@client/store/types/maps';
import {
  ConstrainedToPlace,
  FilteredAddressReference,
  FilteredPlaceReference,
} from '@client/store/types/place-search';
import {
  InitPropertyLookupWithAddressRequired,
  NormalizedProperty,
} from '@client/store/types/property';
import {
  ModifiedSpatialSearchResponse,
  SearchListSortField,
  SearchListSortOrder,
  SpatialSearchDetailWithAddressRequired,
} from '@client/store/types/search';
import { WatchListItem } from '@client/store/types/watchlist';
import { Geometry } from 'geojson';

export const SEARCH_FETCH_PLACES = 'SEARCH_FETCH_PLACES';
export const SEARCH_FETCH_PLACES_SUCCESS = 'SEARCH_FETCH_PLACES_SUCCESS';
export const SEARCH_CLEAR_PLACES = 'SEARCH_CLEAR_PLACES';
export const SEARCH_FETCH_PLACE_DETAILS = 'SEARCH_FETCH_PLACE_DETAILS';
export const SEARCH_FETCH_PLACE_DETAILS_SUCCESS =
  'SEARCH_FETCH_PLACE_DETAILS_SUCCESS';
export const FETCH_USER_LOCATION = 'FETCH_USER_LOCATION';
export const FETCH_USER_LOCATION_SUCCESS = 'FETCH_USER_LOCATION_SUCCESS';

export const SEARCH_FETCH_MAP_PROPERTIES_FOR_BOUNDS =
  'SEARCH_FETCH_MAP_PROPERTIES_FOR_BOUNDS';
export const SEARCH_SET_MAP_MARKERS_FOR_TILE =
  'SEARCH_SET_MAP_MARKERS_FOR_TILE';
export const SEARCH_UPDATE_MAP_TILE_CACHE = 'SEARCH_UPDATE_MAP_TILE_CACHE';
export const SEARCH_CLEAR_MAP_NON_CLUSTER_MARKERS =
  'SEARCH_CLEAR_MAP_NON_CLUSTER_MARKERS';
export const SEARCH_FILTER_MAP_MARKERS_BY_ZOOM =
  'SEARCH_FILTER_MAP_MARKERS_BY_ZOOM';

export const SEARCH_FETCH_PROPERTY_LIST = 'SEARCH_FETCH_PROPERTY_LIST';
export const SEARCH_FETCH_MORE_SIDEBAR_PROPERTIES =
  'SEARCH_FETCH_MORE_SIDEBAR_PROPERTIES';
export const SEARCH_FETCH_PROPERTY_LIST_SUCCESS =
  'SEARCH_FETCH_PROPERTY_LIST_SUCCESS';

export const SEARCH_FETCH_SELECTED_MAP_PROPERTY =
  'SEARCH_FETCH_SELECTED_MAP_PROPERTY';
export const SEARCH_FETCH_SELECTED_MAP_PROPERTY_SUCCESS =
  'SEARCH_FETCH_SELECTED_MAP_PROPERTY_SUCCESS';

export const SEARCH_CANCEL_PENDING_QUERIES = 'SEARCH_CANCEL_PENDING_QUERIES';
export const SEARCH_CLEAR_RESULT_MAP_MARKERS =
  'SEARCH_CLEAR_RESULT_MAP_MARKERS';
export const SEARCH_PROPERTY_CARD_HOVER = 'SEARCH_PROPERTY_CARD_HOVER';
export const SEARCH_SHOW_TEMPORARY_MARKER = 'SEARCH_SHOW_TEMPORARY_MARKER';
export const SEARCH_REMOVE_TEMPORARY_MARKER = 'SEARCH_REMOVE_TEMPORARY_MARKER';
export const SEARCH_UPDATE_FILTER = 'SEARCH_UPDATE_FILTER';
export const SEARCH_REPORT_INITIAL_FILTER_STATE =
  'SEARCH_REPORT_INITIAL_FILTER_STATE';
export const SEARCH_TOGGLE_DESKTOP_FILTERS = 'SEARCH_TOGGLE_DESKTOP_FILTERS';
export const SEARCH_TOGGLE_MARKERS = 'SEARCH_TOGGLE_MARKERS';
export const SEARCH_CLEAR_ALL_FILTERS = 'SEARCH_CLEAR_ALL_FILTERS';
export const SEARCH_UPDATE_URL_PARAMS = 'SEARCH_UPDATE_URL_PARAMS';
export const SEARCH_APPLY_SAVED_FILTERS = 'SEARCH_APPLY_SAVED_FILTERS';
export const SEARCH_SET_MAP_VIEWPORT = 'SEARCH_SET_MAP_VIEWPORT';
export const SEARCH_HIGHLIGHT_SCHOOL_MARKERS =
  'SEARCH_HIGHLIGHT_SCHOOL_MARKERS';
export const SEARCH_SET_PULSE_LOCATIONS = 'SEARCH_SET_PULSE_LOCATIONS';
export const SEARCH_CLEAR_CONSTRAINED_TO_PLACE =
  'SEARCH_CLEAR_CONSTRAINED_TO_PLACE';
export const SEARCH_UPDATE_MAP_MULTI_UNIT_PROPERTY_CACHE =
  'SEARCH_UPDATE_MAP_MULTI_UNIT_PROPERTY_CACHE';
export const SEARCH_INVALIDATE_MAP_SIZE = 'SEARCH_INVALIDATE_MAP_SIZE';

export const SEARCH_LIST_HANDLE_OPEN_OR_CLOSE =
  'SEARCH_LIST_HANDLE_OPEN_OR_CLOSE';

export const SEARCH_LIST_APPLY_SORT = 'SEARCH_LIST_APPLY_SORT';
export const SEARCH_LIST_CLEAR_SORT = 'SEARCH_LIST_CLEAR_SORT';
export const SEARCH_FETCH_MORE_LIST_PROPERTIES =
  'SEARCH_FETCH_MORE_LIST_PROPERTIES';
export const SEARCH_SET_PDP_MODAL_MARKER_DATA_AND_SHOW_MODAL =
  'SEARCH_SET_PDP_MODAL_MARKER_DATA_AND_SHOW_MODAL';
export const SEARCH_ROUTE_CHANGE_TO_HIDE_PDP_MODAL =
  'SEARCH_ROUTE_CHANGE_TO_HIDE_PDP_MODAL';
export const SEARCH_SET_MULTI_UNIT_MARKER_DATA =
  'SEARCH_SET_MULTI_UNIT_MARKER_DATA';
export const SEARCH_ADD_PROPERTY_LIST_PROPERTY_TO_PROPERTY_DATA_CACHE =
  'SEARCH_ADD_PROPERTY_LIST_PROPERTY_TO_PROPERTY_DATA_CACHE';
export const SEARCH_SET_ACTIVE_MULTI_UNIT_PROPERTIES =
  'SEARCH_SET_ACTIVE_MULTI_UNIT_PROPERTIES';
export const SEARCH_TOGGLE_MAP_BOTTOM_LAYER_GROUPS_LIST =
  'SEARCH_TOGGLE_MAP_BOTTOM_LAYER_GROUPS_LIST';
export const SEARCH_REPORT_SHOWING_MAP_BOTTOM_LAYER_CONTROL =
  'SEARCH_REPORT_SHOWING_MAP_BOTTOM_LAYER_CONTROL';
export const SEARCH_GET_USER_LOCATION_VIA_SELECTION =
  'SEARCH_GET_USER_LOCATION_VIA_SELECTION';
export const SEARCH_GET_USER_LOCATION_SUCCESS =
  'SEARCH_GET_USER_LOCATION_SUCCESS';
export const SEARCH_GET_USER_LOCATION_WHEN_NOT_AVAILABLE =
  'SEARCH_GET_USER_LOCATION_WHEN_NOT_AVAILABLE';

export const SEARCH_SET_SELECTED_FILTER_INITIAL_VALUE =
  'SEARCH_SET_SELECTED_FILTER_INITIAL_VALUE';
export const SEARCH_CLEAR_SELECTED_FILTER_INITIAL_VALUE =
  'SEARCH_CLEAR_SELECTED_FILTER_INITIAL_VALUE';
export const SEARCH_CLEAR_SET_MAP_LOCATION = 'SEARCH_CLEAR_SET_MAP_LOCATION';

export const SEARCH_GET_LAST_SEARCH_SUCCESS = 'SEARCH_GET_LAST_SEARCH_SUCCESS';
export const SEARCH_PROPERTY_LIST_UPDATE_SCROLL_POSITION =
  'SEARCH_PROPERTY_LIST_UPDATE_SCROLL_POSITION';
export const SEARCH_SET_MLS_LAYER_FEATURES = 'SEARCH_SET_MLS_LAYER_FEATURES';
export const SEARCH_MAP_MOVED_AFTER_INIT = 'SEARCH_MAP_MOVED_AFTER_INIT';
export const SEARCH_DISMISS_MLS_COVERAGE_MODAL =
  'SEARCH_DISMISS_MLS_COVERAGE_MODAL';
export const SEARCH_SET_MAP_LOCATION_TO_LAST_VIEWED_PDP =
  'SEARCH_SET_MAP_LOCATION_TO_LAST_VIEWED_PDP';
export const SEARCH_HIDE_MLS_REGISTRATION_CONFIRM =
  'SEARCH_HIDE_MLS_REGISTRATION_CONFIRM';
export const SEARCH_SHOW_MLS_REGISTRATION_CONFIRM =
  'SEARCH_SHOW_MLS_REGISTRATION_CONFIRM';
export const SEARCH_UPDATE_TEXT_BOX = 'SEARCH_UPDATE_TEXT_BOX';
export const SEARCH_REPORT_UPDATE_TEXT_BOX = 'SEARCH_REPORT_UPDATE_TEXT_BOX';
export const SEARCH_FIELD_CLICK = 'SEARCH_FIELD_CLICK';
export const SEARCH_PERSIST_MAP_LOCATION = 'SEARCH_PERSIST_MAP_LOCATION';

/* TODO remove when we're ready to switch to using `totalCount` in the property list response */
export const SEARCH_SET_PROPERTY_COUNT = 'SEARCH_SET_PROPERTY_COUNT';

type BoundsObject = {
  southWest: LatLngObject;
  northEast: LatLngObject;
};

type TileCoord = {
  x: number;
  y: number;
  z: number;
};

export type SearchFetchPlacesAction = {
  type: typeof SEARCH_FETCH_PLACES;
  payload: {
    term: string;
    buildingId?: number;
  };
};

export const searchFetchPlaces = (
  term: string,
  buildingId?: number
): SearchFetchPlacesAction => ({
  type: SEARCH_FETCH_PLACES,
  payload: {
    term,
    buildingId,
  },
});

export type SearchFetchPlacesSuccessAction = {
  type: typeof SEARCH_FETCH_PLACES_SUCCESS;
  payload: {
    places: FilteredPlaceReference[] | null;
    addresses: FilteredAddressReference[] | null;
    sessionToken: string | null;
  };
};

export const searchFetchPlacesSuccess = (
  places: FilteredPlaceReference[] | null,
  addresses: FilteredAddressReference[] | null,
  sessionToken: string | null
): SearchFetchPlacesSuccessAction => ({
  type: SEARCH_FETCH_PLACES_SUCCESS,
  payload: {
    places,
    addresses,
    sessionToken,
  },
});

export type SearchClearPlacesAction = {
  type: typeof SEARCH_CLEAR_PLACES;
};

export const searchClearPlaces = (): SearchClearPlacesAction => ({
  type: SEARCH_CLEAR_PLACES,
});

export type SearchFetchPlaceDetailsAction = {
  type: typeof SEARCH_FETCH_PLACE_DETAILS;
  payload: {
    id: string;
    type: string;
    text: string | null;
  };
};

export const searchFetchPlaceDetails = ({
  id,
  type,
  text,
}: {
  id: string;
  type: string;
  text: string | null;
}): SearchFetchPlaceDetailsAction => ({
  type: SEARCH_FETCH_PLACE_DETAILS,
  payload: {
    id,
    type,
    text,
  },
});

export type SearchFetchPlaceDetailsSuccessAction = {
  type: typeof SEARCH_FETCH_PLACE_DETAILS_SUCCESS;
  payload: {
    placeBounds: BoundsArray;
    constrainedToPlace: ConstrainedToPlace;
    placeGeoJSON: Geometry | null;
    placeGeoJSONDescription: string;
    shouldResetPropertyList: boolean;
  };
};

export const searchFetchPlaceDetailsSuccess = ({
  placeBounds,
  constrainedToPlace,
  placeGeoJSONDescription,
  placeGeoJSON,
  shouldResetPropertyList,
}: SearchFetchPlaceDetailsSuccessAction['payload']): SearchFetchPlaceDetailsSuccessAction => {
  return {
    type: SEARCH_FETCH_PLACE_DETAILS_SUCCESS,
    payload: {
      placeBounds,
      constrainedToPlace,
      placeGeoJSONDescription,
      placeGeoJSON,
      shouldResetPropertyList,
    },
  };
};

export type SearchFetchUserLocationAction = {
  type: typeof FETCH_USER_LOCATION;
};

export const fetchUserLocation = (): SearchFetchUserLocationAction => ({
  type: FETCH_USER_LOCATION,
});

export type SearchFetchUserLocationSuccessAction = {
  type: typeof FETCH_USER_LOCATION_SUCCESS;
  payload: {
    latitude: number;
    longitude: number;
  };
};

export const fetchUserLocationSuccess = ({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}): SearchFetchUserLocationSuccessAction => ({
  type: FETCH_USER_LOCATION_SUCCESS,
  payload: {
    latitude,
    longitude,
  },
});

export type SearchFetchMapPropertiesForBoundsAction = {
  type: typeof SEARCH_FETCH_MAP_PROPERTIES_FOR_BOUNDS;
  payload: BoundsObject & {
    zoom: number;
  };
};

export const searchFetchMapPropertiesForBounds = ({
  southWest,
  northEast,
  zoom,
}: BoundsObject & {
  zoom: number;
}): SearchFetchMapPropertiesForBoundsAction => ({
  type: SEARCH_FETCH_MAP_PROPERTIES_FOR_BOUNDS,
  payload: {
    southWest,
    northEast,
    zoom,
  },
});

export type SearchSetMapMarkersForTileAction = {
  type: typeof SEARCH_SET_MAP_MARKERS_FOR_TILE;
  payload: {
    markers: MapMarker[];
    tile: TileCoord;
    removeMarkersFromTileKeys: string[];
  };
};

export const searchSetMapMarkersForTile = ({
  markers,
  tile,
  removeMarkersFromTileKeys = [],
}: {
  markers: MapMarker[];
  tile: TileCoord;
  removeMarkersFromTileKeys?: string[];
}): SearchSetMapMarkersForTileAction => ({
  type: SEARCH_SET_MAP_MARKERS_FOR_TILE,
  payload: {
    markers,
    tile,
    removeMarkersFromTileKeys,
  },
});

export type SearchUpdateMapTileCacheAction = {
  type: typeof SEARCH_UPDATE_MAP_TILE_CACHE;
  payload: {
    data: ModifiedSpatialSearchResponse;
    tile: TileCoord;
  };
};

export const searchUpdateMapTileCache = ({
  data,
  tile,
}: {
  data: ModifiedSpatialSearchResponse;
  tile: TileCoord;
}): SearchUpdateMapTileCacheAction => ({
  type: SEARCH_UPDATE_MAP_TILE_CACHE,
  payload: {
    data,
    tile,
  },
});

export type SearchClearMapNonClusterMarkersAction = {
  type: typeof SEARCH_CLEAR_MAP_NON_CLUSTER_MARKERS;
};

/* Clear only property markers, not cluster markers */
export const searchClearMapNonClusterMarkers =
  (): SearchClearMapNonClusterMarkersAction => ({
    type: SEARCH_CLEAR_MAP_NON_CLUSTER_MARKERS,
  });

export type SearchFilterMapMarkersByZoomAction = {
  type: typeof SEARCH_FILTER_MAP_MARKERS_BY_ZOOM;
  payload: {
    zoom: number;
  };
};

/* After new markers are added following a map zoom event, clear out markers that no longer belong */
export const searchFilterMapMarkersByZoom = (
  zoom: number
): SearchFilterMapMarkersByZoomAction => ({
  type: SEARCH_FILTER_MAP_MARKERS_BY_ZOOM,
  payload: {
    zoom,
  },
});

export type SearchToggleMarkersAction = {
  type: typeof SEARCH_TOGGLE_MARKERS;
  payload: {
    shouldShow: boolean;
  };
};

export const searchToggleMarkers = (
  shouldShow: boolean
): SearchToggleMarkersAction => ({
  type: SEARCH_TOGGLE_MARKERS,
  payload: {
    shouldShow,
  },
});

export type SearchUpdateUrlParamsAction = {
  type: typeof SEARCH_UPDATE_URL_PARAMS;
  payload: {
    shouldReplace?: boolean;
    shouldUpdateInitialHistoryLocationKey?: boolean;
    selectedAddressSlug?: string | null;
    isShowingSearchPageList?: boolean;
    isShowingMobileFilters?: boolean;
  };
};

/* Causes search-page route params to be updated via a saga.
 * This should NOT be used to navigate from a non search page route to the search page,
 * since it will be a no-op */
export const searchUpdateUrlParams = (
  payload: SearchUpdateUrlParamsAction['payload']
): SearchUpdateUrlParamsAction => {
  const {
    shouldReplace,
    shouldUpdateInitialHistoryLocationKey,
    selectedAddressSlug,
    isShowingSearchPageList,
    isShowingMobileFilters,
  } = payload;
  return {
    type: SEARCH_UPDATE_URL_PARAMS,
    payload: {
      shouldReplace,
      shouldUpdateInitialHistoryLocationKey,
      selectedAddressSlug,
      isShowingSearchPageList,
      isShowingMobileFilters,
    },
  };
};

export type SearchListHandleOpenOrCloseAction = {
  type: typeof SEARCH_LIST_HANDLE_OPEN_OR_CLOSE;
};

export const searchListHandleOpenOrClose =
  (): SearchListHandleOpenOrCloseAction => ({
    type: SEARCH_LIST_HANDLE_OPEN_OR_CLOSE,
  });

export type SearchFetchMoreSidebarPropertiesAction = {
  type: typeof SEARCH_FETCH_MORE_SIDEBAR_PROPERTIES;
};

export const searchFetchMoreSidebarProperties =
  (): SearchFetchMoreSidebarPropertiesAction => ({
    type: SEARCH_FETCH_MORE_SIDEBAR_PROPERTIES,
  });

export type SearchFetchPropertyListSuccessAction = {
  type: typeof SEARCH_FETCH_PROPERTY_LIST_SUCCESS;
  payload: {
    properties: SpatialSearchDetailWithAddressRequired[];
    totalCount: number | null;
    cursor: string | null;
    atEnd: boolean | null;
    append: boolean;
    watchlistProperties: WatchListItem[];
    isErrorResponse: boolean;
    lastUpdatedTimestamp: string;
  };
};

export const searchFetchPropertyListSuccess = ({
  properties,
  totalCount,
  cursor,
  atEnd,
  append,
  watchlistProperties,
  isErrorResponse,
  lastUpdatedTimestamp,
}: SearchFetchPropertyListSuccessAction['payload']): SearchFetchPropertyListSuccessAction => ({
  type: SEARCH_FETCH_PROPERTY_LIST_SUCCESS,
  payload: {
    properties,
    totalCount,
    cursor,
    atEnd,
    append,
    isErrorResponse,
    watchlistProperties,
    lastUpdatedTimestamp,
  },
});

export type SearchCancelPendingQueriesAction = {
  type: typeof SEARCH_CANCEL_PENDING_QUERIES;
};

export const searchCancelPendingQueries =
  (): SearchCancelPendingQueriesAction => ({
    type: SEARCH_CANCEL_PENDING_QUERIES,
  });

export type SearchFetchSelectedMapPropertyAction = {
  type: typeof SEARCH_FETCH_SELECTED_MAP_PROPERTY;
  payload: {
    slug: string;
  };
};

export const searchFetchSelectedMapProperty = (
  slug: string
): SearchFetchSelectedMapPropertyAction => ({
  type: SEARCH_FETCH_SELECTED_MAP_PROPERTY,
  payload: {
    slug,
  },
});

export type SearchFetchSelectedMapPropertySuccessAction = {
  type: typeof SEARCH_FETCH_SELECTED_MAP_PROPERTY_SUCCESS;
  payload: {
    data: InitPropertyLookupWithAddressRequired;
  };
};

export const searchFetchSelectedMapPropertySuccess = (
  data: SearchFetchSelectedMapPropertySuccessAction['payload']['data']
): SearchFetchSelectedMapPropertySuccessAction => ({
  type: SEARCH_FETCH_SELECTED_MAP_PROPERTY_SUCCESS,
  payload: {
    data,
  },
});

export type SearchClearResultMapMarkersAction = {
  type: typeof SEARCH_CLEAR_RESULT_MAP_MARKERS;
};

export const searchClearResultMapMarkers =
  (): SearchClearResultMapMarkersAction => ({
    type: SEARCH_CLEAR_RESULT_MAP_MARKERS,
  });

type SearchPropertyCardHoverAction = {
  type: typeof SEARCH_PROPERTY_CARD_HOVER;
  payload: {
    property: NormalizedProperty;
    isHovered: boolean;
    isMarkerOnMap: boolean;
  };
};

export const searchPropertyCardHover = ({
  property,
  isHovered,
  isMarkerOnMap,
}: SearchPropertyCardHoverAction['payload']): SearchPropertyCardHoverAction => ({
  type: SEARCH_PROPERTY_CARD_HOVER,
  payload: {
    property,
    isHovered,
    isMarkerOnMap,
  },
});

export type SearchRemoveTemporaryMarkerAction = {
  type: typeof SEARCH_REMOVE_TEMPORARY_MARKER;
};

export const searchRemoveTemporaryMarker =
  (): SearchRemoveTemporaryMarkerAction => ({
    type: SEARCH_REMOVE_TEMPORARY_MARKER,
  });

export type SearchShowTemporaryMarkerAction = {
  type: typeof SEARCH_SHOW_TEMPORARY_MARKER;
  payload: {
    data: NormalizedProperty;
  };
};

export const searchShowTemporaryMarker = (
  data: NormalizedProperty
): SearchShowTemporaryMarkerAction => ({
  type: SEARCH_SHOW_TEMPORARY_MARKER,
  payload: {
    data,
  },
});

export type SearchReportInitialFilterState = {
  type: typeof SEARCH_REPORT_INITIAL_FILTER_STATE;
};

export const searchReportInitialFilterState =
  (): SearchReportInitialFilterState => ({
    type: SEARCH_REPORT_INITIAL_FILTER_STATE,
  });

export type SearchUpdateFilterAction = {
  type: typeof SEARCH_UPDATE_FILTER;
  payload: { key: string; value: any };
};

export const searchUpdateFilter = (
  key: string,
  value: any
): SearchUpdateFilterAction => ({
  type: SEARCH_UPDATE_FILTER,
  payload: {
    key,
    value,
  },
});

export type SearchToggleDesktopFiltersAction = {
  type: typeof SEARCH_TOGGLE_DESKTOP_FILTERS;
  meta: {
    analytics: {
      parentEvent: string;
      event: string;
    };
  };
};

export const searchToggleDesktopFilters =
  (): SearchToggleDesktopFiltersAction => ({
    type: SEARCH_TOGGLE_DESKTOP_FILTERS,
    meta: {
      analytics: {
        parentEvent: PARENT_EVENTS.CLICK_SEARCH_FILTERS,
        event: 'click_search_filters_more_filters',
      },
    },
  });

export type SearchClearAllFiltersAction = {
  type: typeof SEARCH_CLEAR_ALL_FILTERS;
  payload: {
    isDisplayMultiFamilySearchFiltersEnabled: boolean;
  };
};

export const searchClearAllFilters = ({
  isDisplayMultiFamilySearchFiltersEnabled,
}: SearchClearAllFiltersAction['payload']): SearchClearAllFiltersAction => ({
  type: SEARCH_CLEAR_ALL_FILTERS,
  payload: {
    isDisplayMultiFamilySearchFiltersEnabled,
  },
});

export type SearchApplySavedFiltersAction = {
  type: typeof SEARCH_APPLY_SAVED_FILTERS;
  payload: BoundsObject & {
    filters: { [key: string]: any };
    placeGeoJSON: Geometry | null;
    placeGeoJSONDescription: string | null;
    constrainedToPlace: ConstrainedToPlace;
  };
};

export const searchApplySavedFilters = ({
  filters,
  southWest,
  northEast,
  constrainedToPlace,
  placeGeoJSON,
  placeGeoJSONDescription,
}: SearchApplySavedFiltersAction['payload']): SearchApplySavedFiltersAction => ({
  type: SEARCH_APPLY_SAVED_FILTERS,
  payload: {
    filters,
    southWest,
    northEast,
    constrainedToPlace,
    placeGeoJSON,
    placeGeoJSONDescription,
  },
});

export type SearchSetMapViewportAction = {
  type: typeof SEARCH_SET_MAP_VIEWPORT;
  payload: BoundsObject & {
    zoom: number;
    shouldResetPropertyListState: boolean;
  };
};

export const searchSetMapViewport = ({
  southWest,
  northEast,
  zoom,
  shouldResetPropertyListState,
}: SearchSetMapViewportAction['payload']): SearchSetMapViewportAction => ({
  type: SEARCH_SET_MAP_VIEWPORT,
  payload: {
    southWest,
    northEast,
    zoom,
    shouldResetPropertyListState,
  },
});

export type SearchMapMovedAfterInitAction = {
  type: typeof SEARCH_MAP_MOVED_AFTER_INIT;
  payload: BoundsObject;
};

export const searchMapMovedAfterInit = ({
  southWest,
  northEast,
}: BoundsObject): SearchMapMovedAfterInitAction => ({
  type: SEARCH_MAP_MOVED_AFTER_INIT,
  payload: {
    southWest,
    northEast,
  },
});

export type SearchHighlightSchoolMarkersAction = {
  type: typeof SEARCH_HIGHLIGHT_SCHOOL_MARKERS;
  payload: {
    addressSlug: string | null;
    gaiaSchoolsById: GaiaSchoolsById;
  };
};

export const searchHighlightSchoolMarkers = ({
  addressSlug,
  gaiaSchoolsById,
}: SearchHighlightSchoolMarkersAction['payload']): SearchHighlightSchoolMarkersAction => ({
  type: SEARCH_HIGHLIGHT_SCHOOL_MARKERS,
  payload: {
    addressSlug,
    gaiaSchoolsById,
  },
});

export type SearchSetPulseLocationsAction = {
  type: typeof SEARCH_SET_PULSE_LOCATIONS;
  payload: {
    locations:
      | {
          latitude: number;
          longitude: number;
        }[]
      | null;
  };
};

/* Set an array of pulse location {latitude,longitude} objects on state */
export const searchSetPulseLocations = (
  locations:
    | {
        latitude: number;
        longitude: number;
      }[]
    | null
): SearchSetPulseLocationsAction => ({
  type: SEARCH_SET_PULSE_LOCATIONS,
  payload: {
    locations,
  },
});

export type SearchClearConstrainedToPlaceAction = {
  type: typeof SEARCH_CLEAR_CONSTRAINED_TO_PLACE;
};

export const searchClearConstrainedToPlace =
  (): SearchClearConstrainedToPlaceAction => ({
    type: SEARCH_CLEAR_CONSTRAINED_TO_PLACE,
  });

export type SearchUpdateMapMultiUnitPropertyCacheAction = {
  type: typeof SEARCH_UPDATE_MAP_MULTI_UNIT_PROPERTY_CACHE;
  payload: {
    multiUnitMarkersByLatLng: {
      [key: string]: MultiUnitDataObject[];
    };
  };
};

export const searchUpdateMapMultiUnitPropertyCache = (
  multiUnitMarkersByLatLng: SearchUpdateMapMultiUnitPropertyCacheAction['payload']['multiUnitMarkersByLatLng']
): SearchUpdateMapMultiUnitPropertyCacheAction => ({
  type: SEARCH_UPDATE_MAP_MULTI_UNIT_PROPERTY_CACHE,
  payload: {
    multiUnitMarkersByLatLng,
  },
});

export type SearchAddPropertyListPropertyToPropertyDataCacheAction = {
  type: typeof SEARCH_ADD_PROPERTY_LIST_PROPERTY_TO_PROPERTY_DATA_CACHE;
  payload: {
    addressSlug: string;
    normalizedPropertyData: NormalizedProperty;
  };
};

export const searchAddPropertyListPropertyToPropertyDataCache = ({
  addressSlug,
  normalizedPropertyData,
}: SearchAddPropertyListPropertyToPropertyDataCacheAction['payload']): SearchAddPropertyListPropertyToPropertyDataCacheAction => ({
  type: SEARCH_ADD_PROPERTY_LIST_PROPERTY_TO_PROPERTY_DATA_CACHE,
  payload: {
    addressSlug,
    normalizedPropertyData,
  },
});

export type SearchPropertyListUpdateScrollPositionAction = {
  type: typeof SEARCH_PROPERTY_LIST_UPDATE_SCROLL_POSITION;
  payload: {
    position: number;
  };
};

export const searchPropertyListUpdateScrollPosition = (
  position: number
): SearchPropertyListUpdateScrollPositionAction => {
  return {
    type: SEARCH_PROPERTY_LIST_UPDATE_SCROLL_POSITION,
    payload: {
      position,
    },
  };
};

export type SearchFetchPropertyListAction = {
  type: typeof SEARCH_FETCH_PROPERTY_LIST;
  payload: {
    bounds?: BoundsObject;
    showLoadingIndicator?: boolean;
  };
};

export const searchFetchPropertyList = ({
  bounds,
  showLoadingIndicator,
}: SearchFetchPropertyListAction['payload']): SearchFetchPropertyListAction => ({
  type: SEARCH_FETCH_PROPERTY_LIST,
  payload: {
    bounds,
    showLoadingIndicator,
  },
});

export type SearchListApplySortAction = {
  type: typeof SEARCH_LIST_APPLY_SORT;
  payload: {
    sortField: SearchListSortField;
    sortOrder: SearchListSortOrder;
  };
  meta: {
    analytics: {
      event: any; // TODO: better type
    };
  };
};

export const searchListApplySort = ({
  sortField,
  sortOrder,
}: SearchListApplySortAction['payload']): SearchListApplySortAction => ({
  type: SEARCH_LIST_APPLY_SORT,
  payload: {
    sortField,
    sortOrder,
  },
  meta: {
    analytics: {
      event:
        sortField === SEARCH_LIST_SORT_FIELD_TYPES.LIST_DATE
          ? sortOrder === SEARCH_LIST_SORT_ORDER_TYPES.DESCENDING
            ? 'click_sort_listdate_high_low'
            : 'click_sort_listdate_low_high'
          : sortOrder === SEARCH_LIST_SORT_ORDER_TYPES.DESCENDING
          ? 'click_sort_avm_high_low'
          : 'click_sort_avm_low_high',
    },
  },
});

export type SearchFetchMoreListPropertiesAction = {
  type: typeof SEARCH_FETCH_MORE_LIST_PROPERTIES;
};

export const searchFetchMoreListProperties =
  (): SearchFetchMoreListPropertiesAction => ({
    type: SEARCH_FETCH_MORE_LIST_PROPERTIES,
  });

export type SearchSetPDPModalMarkerDataAndShowModalAction = {
  type: typeof SEARCH_SET_PDP_MODAL_MARKER_DATA_AND_SHOW_MODAL;
  payload: {
    markerFeatureProps: PropertyMapMarker;
  };
};

export const searchSetPDPModalMarkerDataAndShowModal = (
  markerFeatureProps: PropertyMapMarker
): SearchSetPDPModalMarkerDataAndShowModalAction => ({
  type: SEARCH_SET_PDP_MODAL_MARKER_DATA_AND_SHOW_MODAL,
  payload: {
    markerFeatureProps,
  },
});

export type SearchRouteChangeToHidePDPModalAction = {
  type: typeof SEARCH_ROUTE_CHANGE_TO_HIDE_PDP_MODAL;
};

export const searchRouteChangeToHidePDPModal =
  (): SearchRouteChangeToHidePDPModalAction => ({
    type: SEARCH_ROUTE_CHANGE_TO_HIDE_PDP_MODAL,
  });

export type SearchSetSelectedFilterInitialValueAction = {
  type: typeof SEARCH_SET_SELECTED_FILTER_INITIAL_VALUE;
  payload: { key: FilterKey; values: any };
};

export const searchSetSelectedFilterInitialValue = (
  key: FilterKey,
  values: any
): SearchSetSelectedFilterInitialValueAction => ({
  type: SEARCH_SET_SELECTED_FILTER_INITIAL_VALUE,
  payload: {
    key,
    values,
  },
});

export type SearchClearSelectedFilterInitialValueAction = {
  type: typeof SEARCH_CLEAR_SELECTED_FILTER_INITIAL_VALUE;
};

export const searchClearSelectedFilterInitialValue =
  (): SearchClearSelectedFilterInitialValueAction => ({
    type: SEARCH_CLEAR_SELECTED_FILTER_INITIAL_VALUE,
  });

export type SearchSetMultiUnitMarkerDataAction = {
  type: typeof SEARCH_SET_MULTI_UNIT_MARKER_DATA;
  payload: {
    properties: NonNullable<PropertyMapMarker | MultiUnitDataObject>[] | null;
  };
};

export const searchSetMultiUnitMarkerData = (
  properties: NonNullable<PropertyMapMarker | MultiUnitDataObject>[] | null
): SearchSetMultiUnitMarkerDataAction => ({
  type: SEARCH_SET_MULTI_UNIT_MARKER_DATA,
  payload: {
    properties,
  },
});

export type SearchSetActiveMultiUnitPropertiesAction = {
  type: typeof SEARCH_SET_ACTIVE_MULTI_UNIT_PROPERTIES;
  payload: {
    properties: MultiUnitDataObject[] | null;
  };
};

export const searchSetActiveMultiUnitProperties = (
  properties: MultiUnitDataObject[] | null
): SearchSetActiveMultiUnitPropertiesAction => ({
  type: SEARCH_SET_ACTIVE_MULTI_UNIT_PROPERTIES,
  payload: {
    properties,
  },
});

export type SearchToggleMapBottomLayerGroupsListAction = {
  type: typeof SEARCH_TOGGLE_MAP_BOTTOM_LAYER_GROUPS_LIST;
  payload: {
    shouldShow: boolean;
  };
};

export const searchToggleMapBottomLayerGroupsList = (
  shouldShow: boolean
): SearchToggleMapBottomLayerGroupsListAction => ({
  type: SEARCH_TOGGLE_MAP_BOTTOM_LAYER_GROUPS_LIST,
  payload: {
    shouldShow,
  },
});

export type SearchReportShowingMapBottomLayerControlAction = {
  type: typeof SEARCH_REPORT_SHOWING_MAP_BOTTOM_LAYER_CONTROL;
  payload: {
    isShowing: boolean;
  };
};

export const searchReportShowingMapBottomLayerControl = (
  isShowing: boolean
): SearchReportShowingMapBottomLayerControlAction => ({
  type: SEARCH_REPORT_SHOWING_MAP_BOTTOM_LAYER_CONTROL,
  payload: {
    isShowing,
  },
});

export type SearchGetUserLocationViaSelectionAction = {
  type: typeof SEARCH_GET_USER_LOCATION_VIA_SELECTION;
};

export const searchGetUserLocationViaSelection =
  (): SearchGetUserLocationViaSelectionAction => ({
    type: SEARCH_GET_USER_LOCATION_VIA_SELECTION,
  });

export type SearchGetUserLocationWhenNotAvailableAction = {
  type: typeof SEARCH_GET_USER_LOCATION_WHEN_NOT_AVAILABLE;
};

export const searchGetUserLocationWhenNotAvailable =
  (): SearchGetUserLocationWhenNotAvailableAction => ({
    type: SEARCH_GET_USER_LOCATION_WHEN_NOT_AVAILABLE,
  });

export type SearchGetUserLocationSuccessAction = {
  type: typeof SEARCH_GET_USER_LOCATION_SUCCESS;
  payload: {
    setMapLocation: PositionArray;
  };
};

export const searchGetUserLocationSuccess = (
  setMapLocation: PositionArray
): SearchGetUserLocationSuccessAction => ({
  type: SEARCH_GET_USER_LOCATION_SUCCESS,
  payload: {
    setMapLocation,
  },
});

export type SearchInvalidateMapSizeAction = {
  type: typeof SEARCH_INVALIDATE_MAP_SIZE;
};

export const searchInvalidateMapSize = (): SearchInvalidateMapSizeAction => ({
  type: SEARCH_INVALIDATE_MAP_SIZE,
});

export type SearchClearSetMapLocationAction = {
  type: typeof SEARCH_CLEAR_SET_MAP_LOCATION;
};

export const searchClearSetMapLocation =
  (): SearchClearSetMapLocationAction => ({
    type: SEARCH_CLEAR_SET_MAP_LOCATION,
  });

export type SearchGetLastSearchSuccessAction = {
  type: typeof SEARCH_GET_LAST_SEARCH_SUCCESS;
  payload: BoundsObject & {
    filters: { [key: string]: any };
    placeGeoJSON: Geometry | null;
    placeGeoJSONDescription: string | null;
    isShowingSearchList?: boolean;
    sortField: SearchListSortField;
    sortOrder: SearchListSortOrder;
    constrainedToPlace: ConstrainedToPlace;
  };
};

export const searchGetLastSearchSuccess = ({
  filters,
  southWest,
  northEast,
  placeGeoJSON,
  placeGeoJSONDescription,
  isShowingSearchList,
  constrainedToPlace,
  sortField,
  sortOrder,
}: SearchGetLastSearchSuccessAction['payload']): SearchGetLastSearchSuccessAction => ({
  type: SEARCH_GET_LAST_SEARCH_SUCCESS,
  payload: {
    filters,
    southWest,
    northEast,
    placeGeoJSON,
    placeGeoJSONDescription,
    isShowingSearchList,
    constrainedToPlace,
    sortField,
    sortOrder,
  },
});

export type SearchSetMLSCoverageLevelsAction = {
  type: typeof SEARCH_SET_MLS_LAYER_FEATURES;
  payload: {
    features: (null | MLSCoverageLayerFeatureProperties)[];
  };
};

export const searchSetMLSLayerFeatures = (
  features: (null | MLSCoverageLayerFeatureProperties)[]
): SearchSetMLSCoverageLevelsAction => ({
  type: SEARCH_SET_MLS_LAYER_FEATURES,
  payload: {
    features,
  },
});

export type SearchDismissMlsCoverageModalAction = {
  type: typeof SEARCH_DISMISS_MLS_COVERAGE_MODAL;
};

export const searchDismissMlsCoverageModal =
  (): SearchDismissMlsCoverageModalAction => ({
    type: SEARCH_DISMISS_MLS_COVERAGE_MODAL,
  });

export type SearchSetMapLocationToLastViewedPDP = {
  type: typeof SEARCH_SET_MAP_LOCATION_TO_LAST_VIEWED_PDP;
  payload: {
    setMapLocation: PositionArray;
    bounds: BoundsArray;
  };
};

export const searchSetMapLocationToLastViewedPDP = (
  setMapLocation: PositionArray,
  bounds: BoundsArray
): SearchSetMapLocationToLastViewedPDP => ({
  type: SEARCH_SET_MAP_LOCATION_TO_LAST_VIEWED_PDP,
  payload: {
    setMapLocation,
    bounds,
  },
});

export type SearchDismissMLSRegistrationConfirmAction = {
  type: typeof SEARCH_HIDE_MLS_REGISTRATION_CONFIRM;
};

export type SearchShowMLSRegistrationConfirmAction = {
  type: typeof SEARCH_SHOW_MLS_REGISTRATION_CONFIRM;
};

export const SearchDismissMLSRegistrationConfirm =
  (): SearchDismissMLSRegistrationConfirmAction => ({
    type: SEARCH_HIDE_MLS_REGISTRATION_CONFIRM,
  });

export const SearchShowMLSRegistrationConfirm =
  (): SearchShowMLSRegistrationConfirmAction => ({
    type: SEARCH_SHOW_MLS_REGISTRATION_CONFIRM,
  });

type SearchSetPropertyCountAction = {
  type: typeof SEARCH_SET_PROPERTY_COUNT;
  payload: {
    count: number | null;
  };
};

export const searchSetPropertyCount = (
  count: number
): SearchSetPropertyCountAction => ({
  type: SEARCH_SET_PROPERTY_COUNT,
  payload: {
    count,
  },
});

type SearchUpdateTextBox = {
  type: typeof SEARCH_UPDATE_TEXT_BOX;
  payload: {
    searchText: string;
  };
};

export const searchUpdateTextBox = (
  searchText: string
): SearchUpdateTextBox => ({
  type: SEARCH_UPDATE_TEXT_BOX,
  payload: {
    searchText,
  },
});

type SearchReportUpdateTextBox = {
  type: typeof SEARCH_REPORT_UPDATE_TEXT_BOX;
  payload: {
    eventName: 'search_state_update';
  };
};

export const searchReportUpdateTextBox = (): SearchReportUpdateTextBox => ({
  type: SEARCH_REPORT_UPDATE_TEXT_BOX,
  payload: {
    eventName: 'search_state_update',
  },
});

type SearchFieldClick = {
  type: typeof SEARCH_FIELD_CLICK;
  payload: {
    eventName: 'click_search_field';
    parentEvent: typeof PARENT_EVENTS.CLICK_SEARCH;
  };
};

export const searchFieldClick = (): SearchFieldClick => ({
  type: SEARCH_FIELD_CLICK,
  payload: {
    eventName: 'click_search_field',
    parentEvent: PARENT_EVENTS.CLICK_SEARCH,
  },
});

type SearchPersistMapLocationAction = {
  type: typeof SEARCH_PERSIST_MAP_LOCATION;
};

export const searchPersistMapLocation = (): SearchPersistMapLocationAction => ({
  type: SEARCH_PERSIST_MAP_LOCATION,
});

export type SearchAction =
  | SearchFetchPlacesAction
  | SearchClearAllFiltersAction
  | SearchFetchPlacesSuccessAction
  | SearchClearPlacesAction
  | SearchFetchPlaceDetailsAction
  | SearchFetchPlaceDetailsSuccessAction
  | SearchFetchUserLocationAction
  | SearchFetchUserLocationSuccessAction
  | SearchFetchMapPropertiesForBoundsAction
  | SearchSetMapMarkersForTileAction
  | SearchUpdateMapTileCacheAction
  | SearchClearMapNonClusterMarkersAction
  | SearchFilterMapMarkersByZoomAction
  | SearchToggleMarkersAction
  | SearchUpdateUrlParamsAction
  | SearchFetchPropertyListAction
  | SearchFetchMoreSidebarPropertiesAction
  | SearchFetchPropertyListSuccessAction
  | SearchCancelPendingQueriesAction
  | SearchFetchSelectedMapPropertyAction
  | SearchFetchSelectedMapPropertySuccessAction
  | SearchClearResultMapMarkersAction
  | SearchPropertyCardHoverAction
  | SearchRemoveTemporaryMarkerAction
  | SearchShowTemporaryMarkerAction
  | SearchUpdateFilterAction
  | SearchToggleDesktopFiltersAction
  | SearchApplySavedFiltersAction
  | SearchSetMapViewportAction
  | SearchHighlightSchoolMarkersAction
  | SearchSetPulseLocationsAction
  | SearchClearConstrainedToPlaceAction
  | SearchUpdateMapMultiUnitPropertyCacheAction
  | SearchListHandleOpenOrCloseAction
  | SearchListApplySortAction
  | SearchSetPDPModalMarkerDataAndShowModalAction
  | SearchRouteChangeToHidePDPModalAction
  | SearchSetSelectedFilterInitialValueAction
  | SearchClearSelectedFilterInitialValueAction
  | SearchSetMultiUnitMarkerDataAction
  | SearchSetActiveMultiUnitPropertiesAction
  | SearchToggleMapBottomLayerGroupsListAction
  | SearchReportShowingMapBottomLayerControlAction
  | SearchGetUserLocationViaSelectionAction
  | SearchGetUserLocationWhenNotAvailableAction
  | SearchGetUserLocationSuccessAction
  | SearchInvalidateMapSizeAction
  | SearchClearSetMapLocationAction
  | SearchAddPropertyListPropertyToPropertyDataCacheAction
  | SearchGetLastSearchSuccessAction
  | SearchPropertyListUpdateScrollPositionAction
  | SearchFetchMoreListPropertiesAction
  | SearchSetMLSCoverageLevelsAction
  | SearchDismissMlsCoverageModalAction
  | SearchSetMapLocationToLastViewedPDP
  | SearchDismissMLSRegistrationConfirmAction
  | SearchShowMLSRegistrationConfirmAction
  | SearchSetPropertyCountAction
  | SearchUpdateTextBox
  | SearchReportUpdateTextBox
  | SearchFieldClick
  | SearchPersistMapLocationAction;
