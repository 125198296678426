import { connect } from 'react-redux';

import renderOnMountUntyped from '@client/hocs/render-on-mount-untyped';
import { closeUpdateEmailBanner } from '@client/store/actions/app-banner.actions';
import { updateEmailModalOpen } from '@client/store/actions/auth.actions';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import UpdateEmailBanner from '@client/components/UpdateEmailBanner';

const mapStateToProps = (state) => {
  return {
    isSmallScreen: getIsSmallSize(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleCloseBanner: () => dispatch(closeUpdateEmailBanner()),
    handleUpdateEmailModalOpen: () => dispatch(updateEmailModalOpen()),
  };
};

export default renderOnMountUntyped(
  connect(mapStateToProps, mapDispatchToProps)(UpdateEmailBanner)
);
