import { EVENTS } from '@client/store/analytics-constants';
import {
  ModalKey,
  ModalKeyToRenderInPortal,
  NotificationKey,
} from '@client/store/constants';

export const SET_MODAL_PORTAL_KEYS = 'SET_MODAL_PORTAL_KEYS';
export const SHOW_CANT_FIND_ADDRESS_MODAL = 'SHOW_CANT_FIND_ADDRESS_MODAL';
export const HIDE_CANT_FIND_ADDRESS_MODAL = 'HIDE_CANT_FIND_ADDRESS_MODAL';
export const SHOW_CANT_FIND_ADDRESS_LINK = 'SHOW_CANT_FIND_ADDRESS_LINK';
export const GO_BACK_OR_DISPATCH = 'GO_BACK_OR_DISPATCH';
export const SHOW_BROKERAGE_ATTRIBUTION_MODAL =
  'SHOW_BROKERAGE_ATTRIBUTION_MODAL';
export const HIDE_BROKERAGE_ATTRIBUTION_MODAL =
  'HIDE_BROKERAGE_ATTRIBUTION_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SET_ACTIVE_NOTIFICATION = 'SET_ACTIVE_NOTIFICATION';
export const CLEAR_ACTIVE_NOTIFICATION = 'CLEAR_ACTIVE_NOTIFICATION';
export const SET_IS_SHOWING_ALTERNATE_YOUR_TEAM_TRIGGER_BUTTON =
  'SET_IS_SHOWING_ALTERNATE_YOUR_TEAM_TRIGGER_BUTTON';

export type SetActiveNotificationAction = {
  type: typeof SET_ACTIVE_NOTIFICATION;
  payload: {
    activeNotification: NotificationKey;
  };
};

export const setActiveNotification = (activeNotification: NotificationKey) => ({
  type: SET_ACTIVE_NOTIFICATION,
  payload: {
    activeNotification,
  },
});

export type ClearActiveNotificationAction = {
  type: typeof CLEAR_ACTIVE_NOTIFICATION;
};

export const clearActiveNotification = () => ({
  type: CLEAR_ACTIVE_NOTIFICATION,
});

export type OpenModalWithModalKeyAction = {
  type: typeof OPEN_MODAL;
  payload: {
    modalKey: ModalKey;
  };
};

export const openModal = (modalKey: ModalKey): OpenModalWithModalKeyAction => ({
  type: OPEN_MODAL,
  payload: {
    modalKey,
  },
});

export type CloseModalOpenedWithModalKeyAction = {
  type: typeof CLOSE_MODAL;
};

export const closeModal = (): CloseModalOpenedWithModalKeyAction => ({
  type: CLOSE_MODAL,
});

type SetModalPortalKeysAction = {
  type: typeof SET_MODAL_PORTAL_KEYS;
  payload: {
    keys: ModalKeyToRenderInPortal[];
  };
};

export const setModalPortalKeys = (
  keys: ModalKeyToRenderInPortal[]
): SetModalPortalKeysAction => ({
  type: SET_MODAL_PORTAL_KEYS,
  payload: {
    keys,
  },
});

type OpenBrokerageAttributionModalAction = {
  type: typeof SHOW_BROKERAGE_ATTRIBUTION_MODAL;
};

export const openBrokerageAttributionModal =
  (): OpenBrokerageAttributionModalAction => ({
    type: SHOW_BROKERAGE_ATTRIBUTION_MODAL,
  });

type CloseBrokeragerAttributionModalAction = {
  type: typeof HIDE_BROKERAGE_ATTRIBUTION_MODAL;
};

export const closeBrokeragerAttributionModal =
  (): CloseBrokeragerAttributionModalAction => ({
    type: HIDE_BROKERAGE_ATTRIBUTION_MODAL,
  });

export type ShowCantFindAddressModalAction = {
  type: typeof SHOW_CANT_FIND_ADDRESS_MODAL;
  meta: {
    analytics: {
      event: string;
    };
  };
};

export const showCantFindAddressModal = (): ShowCantFindAddressModalAction => ({
  type: SHOW_CANT_FIND_ADDRESS_MODAL,
  meta: {
    analytics: {
      event: EVENTS.CLICK_SHOW_CANT_FIND_ADDRESS_MODAL,
    },
  },
});

export type HideCantFindAddressModalAction = {
  type: typeof HIDE_CANT_FIND_ADDRESS_MODAL;
};

export const hideCantFindAddressModal = (): HideCantFindAddressModalAction => ({
  type: HIDE_CANT_FIND_ADDRESS_MODAL,
});

export type SetIsShowingAlternateYourTeamTriggerButtonAction = {
  type: typeof SET_IS_SHOWING_ALTERNATE_YOUR_TEAM_TRIGGER_BUTTON;
  payload: boolean;
};

export const setIsShowingAlternateYourTeamTriggerButton = (
  isShowing: boolean
): SetIsShowingAlternateYourTeamTriggerButtonAction => ({
  type: SET_IS_SHOWING_ALTERNATE_YOUR_TEAM_TRIGGER_BUTTON,
  payload: isShowing,
});

export type GoBackOrDispatchAction = {
  type: typeof GO_BACK_OR_DISPATCH;
  payload: {
    actionIfCantGoBack: { type: string; payload?: any };
  };
};

/* Go back in the browser to close a modal or get out of a certain state OR if we're on the first page
 * in the app's nav history, dispatch the provided action to get out of the state a different way
 * (usually this action should be a `routeChange` action to remove the query param that controls the modal/state) */
export const goBackOrDispatch = (actionIfCantGoBack: {
  type: string;
  payload?: any;
}): GoBackOrDispatchAction => ({
  type: GO_BACK_OR_DISPATCH,
  payload: {
    actionIfCantGoBack,
  },
});

export type ModalAction =
  | GoBackOrDispatchAction
  | SetModalPortalKeysAction
  | OpenBrokerageAttributionModalAction
  | CloseBrokeragerAttributionModalAction
  | ShowCantFindAddressModalAction
  | HideCantFindAddressModalAction
  | SetIsShowingAlternateYourTeamTriggerButtonAction
  | CloseModalOpenedWithModalKeyAction
  | OpenModalWithModalKeyAction
  | SetActiveNotificationAction
  | ClearActiveNotificationAction;
