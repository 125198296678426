import {
  analyticsMiddleware,
  kinesisTracker,
} from '@src/analytics-redux-middleware';

import { getABTestVariants } from '@client/store/selectors/ab-tests.selectors';
import { getAnalyticsEventInfo } from '../selectors/tracking.selectors';
import {
  getCurrentView,
  getCurrentQuery,
} from '@src/redux-saga-router-plus/selectors';

import { isMainAppReduxState } from '@client/utils/analytics.utils';
import { googleAnalytics4Tracker } from '@client/services/google-analytics-4-tracker';

import {
  LOGIN_SUCCESS,
  CREATE_USER_SUCCESS,
  RESET_AUTH_DEPENDENT_STATE,
} from '@client/store/actions/auth.actions';
import { AnalyticsEvent, ParentEvent } from '@client/store/analytics-constants';
import HC_CONSTANTS from '@client/app.config';
import { COBRANDED_ANALYTICS_HANDLERS } from '@client/utils/cobrand-analytics.utils';
import { reportToSentry } from '@client/utils/error.utils';
import { ReduxState } from '@client/store/types/redux-state';
import { ActionType, StoreType } from '@src/analytics-redux-middleware/types';
import {
  SearchWidgetReduxState,
  SearchWidgetStoreType,
} from '@src/widgets/search-widget/store/types';

const getEventProps = (
  state: ReduxState | SearchWidgetReduxState,
  action: ActionType
): {
  experiments: any;
  search_id?: string;
  [key: string]: any;
} => {
  const abTestVariants = isMainAppReduxState(state)
    ? getABTestVariants(state)
    : {};
  return {
    /* Send the set variant for all defined A/B test experiments */
    experiments: {
      ...abTestVariants,
    },
    /* pass contextual IDs, if non-null, for backend event attribution */
    ...(isMainAppReduxState(state)
      ? state.analyticsData.contextualSearchId && {
          search_id: state.analyticsData.contextualSearchId,
        }
      : { search_id: undefined }),
    ...(isMainAppReduxState(state)
      ? state.analyticsData.currentPDPViewId && {
          current_pdp_view_id: state.analyticsData.currentPDPViewId,
        }
      : { current_pdp_view_id: undefined }),
    /* Add props defined in action payload */
    ...action.meta?.analytics?.props,
  };
};

/**
 * Create a tracker so 'analytics' events are sent to kinesis
 * @return {object} analytics middleware tracker object
 */
const trackerKinesis = (initialState: ReduxState) => {
  /* Kinesis Tracker */
  const trackerObjKinesis = kinesisTracker(
    'consumer_web',
    HC_CONSTANTS.BEACON_URL,
    getAnalyticsEventInfo
  );
  const cobrandId = initialState.cobranding.id;
  const cobrandAnalyticsHandler = COBRANDED_ANALYTICS_HANDLERS[cobrandId];

  return {
    key: 'analytics',
    fn: (
      action: ActionType,
      store: StoreType | SearchWidgetStoreType
    ): void => {
      const state = store.getState();
      const currentView = isMainAppReduxState(state)
        ? getCurrentView(state)
        : null;
      const currentQuery = isMainAppReduxState(state)
        ? getCurrentQuery(state)
        : null;

      if (action.meta && action.meta.analytics) {
        const eventName: AnalyticsEvent = action.meta.analytics.event;
        const eventType: ParentEvent | undefined =
          action.meta.analytics.parentEvent;

        const props = getEventProps(state, action);
        // check if `props` contains a `ch_property_id` or `slug` value (expected to be a property slug)
        let chPropertyId: string | null = null;
        if (!!props) {
          chPropertyId = props.ch_property_id ?? props.slug ?? null;
        }

        const { topLevelItems } = action.meta.analytics;

        const kinesis = {
          ...action.meta.analytics,
          event: eventName,
          eventType: eventType,
          props,
          topLevelItems: {
            ch_property_id: chPropertyId,
            ...topLevelItems,
          },
        };

        const combinedAction = {
          ...action,
          meta: {
            ...action.meta,
            kinesis,
            ga: {
              ...action.meta.analytics,
              event: eventName,
              eventType: eventType,
              props,
            },
          },
        };
        trackerObjKinesis && trackerObjKinesis.fn(combinedAction, store);
      }
      /* Conditionally report the event in a cobrand-specific manner */
      if (
        cobrandId &&
        action.meta &&
        action.meta.analytics &&
        cobrandAnalyticsHandler
      ) {
        cobrandAnalyticsHandler
          .handleLoadScript()
          .then(() => {
            try {
              cobrandAnalyticsHandler.handleReportEvent({
                event: action?.meta?.analytics?.event,
                props: action?.meta?.analytics?.props,
                view: currentView,
                query: currentQuery,
              });
            } catch (err) {
              reportToSentry(err, {
                originalError: err,
              });
            }
          })
          .catch((err) => {
            reportToSentry('Failed to load the event reporting script', {
              originalError: err,
            });
          });
      }
    },
  };
};

export default (initialState) =>
  analyticsMiddleware([trackerKinesis(initialState)]);

export const raw = (initialState: ReduxState) => {
  const parconGATrackerId =
    initialState.cobranding.domainScheme.googleAnalyticsId;
  let trackers: { key: string; fn: (action, state) => void }[] = [];

  if (!!parconGATrackerId) {
    /* These actions are responsible for setting/removing tracking and user data on state */
    const setUserInfoActions = [
      LOGIN_SUCCESS,
      CREATE_USER_SUCCESS,
      RESET_AUTH_DEPENDENT_STATE,
    ];
    trackers.push(
      googleAnalytics4Tracker(
        initialState,
        getAnalyticsEventInfo as (
          state: ReduxState | SearchWidgetReduxState
        ) => {},
        setUserInfoActions,
        getEventProps
      )
    );
  }

  return (store) => (next) => (action) => {
    let result = next(action);
    trackers.forEach((tracker) => {
      try {
        /* Note that due to next(action) being run before this, store.getState() always returns the
         * new state after the reducers are run for the action */
        tracker.fn(action, store);
      } catch (e: any) {
        console.error(`error in tracker ${tracker.key}`, e);
      }
    });
    return result;
  };
};
