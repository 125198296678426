import { createSelector } from 'reselect';

import { ReduxState } from '@client/store/types/redux-state';
import { UtmState } from '@client/store/reducers/utm.reducer';
import { TrackingState } from '@client/store/reducers/tracking.reducer';
import {
  getReferrer,
  getCurrentUser,
} from '@client/store/selectors/auth.selectors';
import {
  getInviteId,
  getInviteEmail,
} from '@client/store/selectors/invite.selectors';
import { View } from '@client/routes/constants';
import { RouterStateType } from '@src/redux-saga-router-plus/types';
import { isMainAppReduxState } from '@client/utils/analytics.utils';
import { getVisitId } from '@client/utils/visit.util';

export function getUtmState(state: ReduxState): UtmState {
  return state.utm;
}

function getTrackingState(state: ReduxState): TrackingState {
  return state.tracking;
}

const getTrackingId = createSelector(
  getTrackingState,
  (tracking) => tracking.trackingId
);

const getRouterState = (state: ReduxState): RouterStateType => state.router;

const getCurrentRoute = createSelector(
  getRouterState,
  (routerState) => routerState.current
);

const getCurrentView = createSelector(
  getCurrentRoute,
  (routeDef) => routeDef.view
);

const getAnalyticsDataState = (state: ReduxState) => state.analyticsData;

export const getAnalyticsEventInfo = createSelector(
  getInviteId,
  getInviteEmail,
  getTrackingId,
  getReferrer,
  getCurrentUser,
  getUtmState,
  getCurrentView,
  getAnalyticsDataState,
  isMainAppReduxState,
  getVisitId,
  (
    inviteId,
    inviteEmail,
    trackingId,
    referrer,
    user,
    utmParams,
    currentView,
    analyticsData,
    isMainApp
  ) => {
    const { utm_medium: utm_source, utm_uuid: utm_source_id } = utmParams;

    const analytics_source = currentView;

    let analytics_source_id: string | undefined | null = utm_source_id;
    if (isMainApp && !analytics_source_id) {
      switch (currentView) {
        case View.SEARCH:
          analytics_source_id = analyticsData.contextualSearchId;
          break;
        case View.PROPERTY_DETAILS:
          analytics_source_id = analyticsData.currentPDPViewId;
          break;
        case View.HOME_IMPROVEMENT:
          analytics_source_id = analyticsData.currentHICEstimateId;
          break;
        default:
          analytics_source_id = analyticsData.genericViewId;
      }
    }
    let userAnalyticsInfo = {
      tracking_id: trackingId, // identifies the user or invite
      user_id: user?.id, // identifies a registered user
      email: user?.email || inviteEmail,
      partner_user_id: user?.partner_user_id,
      http_referer: referrer,
      source: utm_source || analytics_source,
      source_id: analytics_source_id,
      ...utmParams,
    };

    if (inviteId) {
      userAnalyticsInfo['invite_id'] = inviteId; // identifies an invite who is not registered user
    }

    return userAnalyticsInfo;
  }
);
