import classNames from 'classnames';
import pluralize from 'pluralize';
import React, { Component } from 'react';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';

import defaultTheme from '@client/css-modules/NumberAdjuster.css';

type Props = {
  labelFormatter: (value: number) => string;
  onChange: (newValue: number) => void;
  handleReportValueSelection: (
    operator: 'plus' | 'minus',
    value: string | number | null
  ) => void;
  value: number;
  minValue: number;
  maxValue: number | string;
  offScreenLabel: boolean;
  label: string;
  ariaLabelledBy?: string;
  theme: Theme;
};

type State = {
  value: number;
};

const OPERATORS = {
  '+': (a, b) => a + b,
  '-': (a, b) => a - b,
};

/**
 * Form control to adjust a number by clicking + and - buttons
 */
class NumberAdjuster extends Component<Props, State> {
  static defaultProps = {
    labelFormatter: (value) => value,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      value: this.props.value,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  handleIncrementValue = (operator: '+' | '-') => {
    const newValue = OPERATORS[operator](this.state.value, 1);
    this.setState({ value: newValue });
    this.props.onChange(newValue);
    this.props.handleReportValueSelection(
      operator === '+' ? 'plus' : 'minus',
      newValue
    );
  };

  render() {
    const {
      theme,
      labelFormatter,
      minValue,
      maxValue,
      label,
      offScreenLabel,
      ariaLabelledBy,
    } = this.props;
    const { value } = this.state;
    const shouldUseOwnLabel = !!(!ariaLabelledBy && label);

    return (
      <div className={theme.NumberAdjuster}>
        {shouldUseOwnLabel && (
          <label
            className={classNames(theme.NumberAdjusterLabel, {
              [theme.OffScreenLabel]: !!offScreenLabel,
            })}
          >
            {label}
          </label>
        )}
        <div
          className={theme.NumberAdjusterControls}
          aria-labelledby={ariaLabelledBy ? ariaLabelledBy : label}
          role="group"
        >
          <button
            disabled={value === minValue}
            aria-label={`Subtract ${label}`}
            className={classNames(theme.Button, {
              [theme.ButtonDisabled]: value === minValue,
            })}
            onClick={() => value !== minValue && this.handleIncrementValue('-')}
          >
            -
          </button>
          <span className={theme.ValueLabelAriaText} aria-live="assertive">
            {`${value} ${pluralize(label, value)} or more`}
          </span>
          <div className={theme.ValueLabel}>{labelFormatter(value)}</div>
          <button
            disabled={value === maxValue}
            aria-label={`Add ${label}`}
            className={classNames(theme.Button, {
              [theme.ButtonDisabled]: value === maxValue,
            })}
            onClick={() => value !== maxValue && this.handleIncrementValue('+')}
          >
            +
          </button>
        </div>
      </div>
    );
  }
}

const ThemedNumberAdjuster = themr(
  'NumberAdjuster',
  defaultTheme
)(NumberAdjuster);
export default ThemedNumberAdjuster;
