import { useSelector } from 'react-redux';
import { getCobrandId } from '@client/store/selectors/cobranding.selectors';
import { COBRAND_IDS } from '@client/store/types/cobranding';
import PDPLoanOfficerAdLendco from '@client/components/PDPLoanOfficerAd/PDPLoanOfficerAdLendco';
import { Theme } from '@friendsofreactjs/react-css-themr';

type Props = { theme?: Theme; className?: string };

const getComponentForCobrand = (cobrandId: string) => {
  switch (cobrandId) {
    case COBRAND_IDS.CHASE:
    case COBRAND_IDS.WWW:
      return null;
    default:
      return PDPLoanOfficerAdLendco;
  }
};

const PDPLoanOfficerAd = (props: Props) => {
  const cobrandId = useSelector(getCobrandId);
  const Component = getComponentForCobrand(cobrandId);
  return Component ? <Component {...props} /> : null;
};

export default PDPLoanOfficerAd;
