import { call } from 'redux-saga/effects';

import {
  USER_UNSUBSCRIBE_QUERY_STRING,
  USER_UNSUBSCRIBE_QUERY_TOKEN,
} from '@client/routes/constants';
import { consumerApiClient } from '@client/services/consumer-api-client';

export default function* routeUserUnsubscribe({ query }) {
  /* Only send the request if it will contain a valid user id and token */
  if (
    query[USER_UNSUBSCRIBE_QUERY_STRING] &&
    query[USER_UNSUBSCRIBE_QUERY_TOKEN]
  ) {
    yield call(
      [
        consumerApiClient,
        consumerApiClient.unsubscribeUserFromEmailNotifications,
      ],
      query[USER_UNSUBSCRIBE_QUERY_STRING],
      query[USER_UNSUBSCRIBE_QUERY_TOKEN]
    );
  } else {
    throw new Error(
      `User unsubscribe page hit without a ${USER_UNSUBSCRIBE_QUERY_STRING} query param`
    );
  }
}
