import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { format, parse } from 'date-fns';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import defaultTheme from '@client/css-modules/OpenHouseInfo.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { OpenHouse } from '@client/store/sagas/queries/types';
import { getIsTabletOrSmallerSize } from '@client/store/selectors/match-media.selectors';
import { getPropertyOpenHouseList } from '@client/store/selectors/property-details.selectors';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { format12HrTimesAsDisplayString } from '@client/utils/time.utils';

const INITIAL_OPEN_HOUSE_DATES_TO_DISPLAY = 3;

type OpenHousesData = {
  [key: string]: OpenHouse[];
};

type OpenHouseInfoProps = {
  theme: Theme;
};

/**
 * Upcoming open houses for the property
 */
const OpenHouseInfo: React.FC<OpenHouseInfoProps> = ({ theme }) => {
  const openHouseData: OpenHousesData = useSelector(getPropertyOpenHouseList);
  const isTabletOrSmallerSize = useSelector(getIsTabletOrSmallerSize);
  const openHouseDateKeys = Object.keys(openHouseData);
  const { linkColor } = useCobrandStyles();
  const [isShowingAll, setIsShowingAll] = useState(false);

  const handleSeeMoreToggle = () => {
    setIsShowingAll(!isShowingAll);
  };
  const openHouseDateKeysForDisplay = isShowingAll
    ? openHouseDateKeys
    : openHouseDateKeys.slice(0, INITIAL_OPEN_HOUSE_DATES_TO_DISPLAY);

  return openHouseDateKeysForDisplay &&
    openHouseDateKeysForDisplay.length > 0 ? (
    <section className={theme.OpenHouseInfo}>
      <h2 className={theme.Heading}>Upcoming Open Houses</h2>
      <div className={theme.OpenHouseInfoTable}>
        {openHouseDateKeysForDisplay.map((openHouseDateString) => {
          /**
           * Due to parse() parsing the Date string from the API as UTC, we need to deconstruct and reconstruct
           * the date to get it relative to the local timezone
           */
          const utcDate = parse(openHouseDateString);
          const dateStr = new Date(
            utcDate.getUTCFullYear(),
            utcDate.getUTCMonth(),
            utcDate.getUTCDate()
          );
          /** Accounting for less screen area on mobile devices using different formatting */
          const displayDate = isTabletOrSmallerSize
            ? format(dateStr, 'ddd M/D')
            : format(dateStr, 'dddd M/D');
          return (
            <div
              className={theme.OpenHouseInfoRow}
              key={`OpenHouseInfoRow-${displayDate}`}
              data-hc-name="open-house-row"
            >
              <div className={theme.OpenHouseItemDate}>{displayDate}</div>
              <div className={theme.OpenHouseTimesContainer}>
                {Array.isArray(openHouseData[openHouseDateString]) &&
                  openHouseData[openHouseDateString].map((openHouse, index) => {
                    const { date, end, start } = openHouse;
                    const uId = `${start}${end}${date}${index}`;
                    return (
                      <div key={uId} className={theme.OpenHouseTime}>
                        {format12HrTimesAsDisplayString(start, end)}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
      {openHouseDateKeys.length > INITIAL_OPEN_HOUSE_DATES_TO_DISPLAY && (
        <button
          className={theme.SeeMoreLink}
          onClick={handleSeeMoreToggle}
          style={{ color: linkColor }}
          aria-label={`See ${isShowingAll ? 'Less' : 'More'} Open House Dates`}
          onKeyDown={onEnterOrSpaceKey(handleSeeMoreToggle)}
        >
          See {isShowingAll ? 'less' : 'more'}
        </button>
      )}
    </section>
  ) : null;
};

const ThemedOpenHouseInfo = themr('OpenHouseInfo', defaultTheme)(OpenHouseInfo);
export default ThemedOpenHouseInfo;
