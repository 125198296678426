import { get } from 'lodash';
import React, { PureComponent } from 'react';

interface IHTMLImageElement {
  prototype: HTMLImageElement;
  new (): HTMLImageElement;
}

declare global {
  interface Window {
    Image: IHTMLImageElement;
  }
}

export class TransformContext {
  draw2d: CanvasRenderingContext2D;
  top: number;
  left: number;
  width: number;
  height: number;

  constructor(
    draw2d: any,
    top: number,
    left: number,
    width: number,
    height: number
  ) {
    this.draw2d = draw2d;
    this.top = top;
    this.left = left;
    this.width = width;
    this.height = height;
  }
}

export type Transform = (TransformContext) => void;

export type TransformedImageProps = {
  role?: string;
  className?: string;
  key?: string;
  /** The URL of the image to display */
  src?: string;
  style?: React.CSSProperties;
  width?: number;
  height?: number;
  transforms: Transform[];
  onClick?: () => void;
  onError?: any;
  onLoad?: ({ target }: { target: EventTarget }) => void;
  alt?: string;
  ariaLabel?: string;
};

type State = {
  fallback: boolean;
};

export function autoCrop(
  threshold: number = 0.05,
  maxBorderWidth: number = 0.25
) {
  /**
   * Compares two colors and returns true if the euclidean distance between the
   * colors is <= threshold.  The distance is computed in (0, 1) normalized space.
   */
  function cmpColor(a, b) {
    if (a === b) {
      return true;
    }

    const aa = ((a >> 24) & 0xff) / 255;
    const ar = ((a >> 16) & 0xff) / 255;
    const ag = ((a >> 8) & 0xff) / 255;
    const ab = (a & 0xff) / 255;

    const ba = ((b >> 24) & 0xff) / 255;
    const br = ((b >> 16) & 0xff) / 255;
    const bg = ((b >> 8) & 0xff) / 255;
    const bb = (b & 0xff) / 255;

    const sq = (a) => a * a;
    const distance = Math.sqrt(
      sq(aa - ba) + sq(ar - br) + sq(ag - bg) + sq(ab - bb)
    );
    return distance <= threshold;
  }

  function detectNorth(d, w, h) {
    let crop = 0;
    let refC = d[0];
    const maxNCrop = h * maxBorderWidth;
    for (let y = 0; y < maxNCrop; y++) {
      const offset = w * y;
      for (let x = 0; x < w; x++) {
        let c = d[offset + x];
        if (!cmpColor(c, refC)) {
          return crop;
        }
        refC = c;
      }
      crop++;
    }
    return crop;
  }

  function detectSouth(d, w, h) {
    let crop = 0;
    let refC = d[h * w - 1];
    const maxSCrop = h - h * maxBorderWidth;
    for (let y = h - 1; y >= maxSCrop; y--) {
      const offset = w * y;
      for (let x = 0; x < w; x++) {
        let c = d[offset + x];
        if (!cmpColor(c, refC)) {
          return crop;
        }
        refC = c;
      }
      crop++;
    }
    return crop;
  }

  function detectEast(d, w, h) {
    let crop = 0;
    let refC = d[0];
    const maxECrop = w * maxBorderWidth;
    for (let x = 0; x < maxECrop; x++) {
      let offset = x;
      for (let y = 0; y < h; y++) {
        let c = d[offset];
        if (!cmpColor(c, refC)) {
          return crop;
        }
        offset += w;
        refC = c;
      }
      crop++;
    }
    return crop;
  }

  function detectWest(d, w, h) {
    let crop = 0;
    let refC = d[h * w - 1];
    const maxWCrop = w - w * maxBorderWidth;
    for (let x = w - 1; x >= maxWCrop; x--) {
      let offset = x;
      for (let y = 0; y < h; y++) {
        let c = d[offset];
        if (!cmpColor(c, refC)) {
          return crop;
        }
        offset += w;
        refC = c;
      }
      crop++;
    }
    return crop;
  }

  function applyTransform(transformCtx: TransformContext) {
    const imageData = transformCtx.draw2d.getImageData(
      transformCtx.top,
      transformCtx.left,
      transformCtx.width,
      transformCtx.height
    );

    const d = new Uint32Array(imageData.data.buffer);
    const w = transformCtx.width | 0;
    const h = transformCtx.height | 0;

    const northCrop = detectNorth(d, w, h);
    const southCrop = detectSouth(d, w, h);
    const eastCrop = detectEast(d, w, h);
    const westCrop = detectWest(d, w, h);
    transformCtx.top += northCrop;
    transformCtx.left += eastCrop;
    transformCtx.width -= eastCrop + westCrop;
    transformCtx.height -= northCrop + southCrop;
  }

  return applyTransform;
}

/**
 * TransformedImage is a replacement for the `img` tag, which can
 * apply a series of transforms to the image prior to display.
 *
 * Note that for transforms to work, the image MUST be served from
 * a CORS enabled server OR the origin of the web page.  If CORS
 * checks fail, the untransformed image will be displayed.
 */
export default class TransformedImage extends PureComponent<
  TransformedImageProps,
  State
> {
  tempCanvas: HTMLCanvasElement | null = null;
  canvas: HTMLCanvasElement | null = null;
  img: HTMLImageElement | null = null;

  state: State = {
    fallback: false,
  };

  componentDidMount = () => {
    this.tempCanvas = document.createElement('canvas');
    this.triggerImageLoad();
  };

  componentWillUnmount = () => {
    this.canvas = null;
    this.tempCanvas = null;

    if (this.img) {
      this.img.onerror = null;
      this.img.onload = null;
      this.img = null;
    }
  };

  componentDidUpdate(prevProps: TransformedImageProps) {
    if (prevProps.src !== this.props.src) {
      this.triggerImageLoad();
    }
    return null;
  }

  render = () => {
    const { ariaLabel, alt, src, transforms, onLoad, onError, ...params } =
      this.props;
    if (this.state.fallback || process.env.NODE_ENV === 'test') {
      return (
        <img
          alt={alt}
          src={src}
          onLoad={onLoad}
          onError={onError}
          data-testid="transformed-image-img"
          {...params}
        />
      );
    }

    return (
      <canvas
        role={get(params, 'role') ? params.role : 'img'}
        aria-label={ariaLabel ? ariaLabel : alt}
        ref={(e) => {
          this.canvas = e;
        }}
        {...params}
      />
    );
  };

  triggerImageLoad = () => {
    const src = this.props.src;
    let canvas = this.canvas;
    this.setState({ fallback: false });

    /* Don't attempt to draw image into a canvas when deving locally, as CORS doesn't allow this */
    if (process.env.LOCALHOST === 'true') {
      this.setState({ fallback: true });
      return;
    }

    if (!canvas) {
      if (process.env.NODE_ENV !== 'test') {
        console.error(
          'Canvas was unexpectedly null.  Triggering image fallback.'
        );
      }
      this.setState({ fallback: true });
      return;
    }

    if (!src) {
      const context = canvas.getContext('2d');
      context && context.clearRect(0, 0, canvas.width, canvas.height);
      return;
    }

    const img = new window.Image();
    this.img = img;
    img.onload = () => this.imageLoaded(img, src);

    // Maybe the origin server doesn't support CORS.  If so, try falling
    // back to a plain old image tag that doesn't require CORS support.
    img.onerror = () => this.setState({ fallback: true });
    img.crossOrigin = 'anonymous';
    img.src = src;
  };

  imageLoaded = (img: HTMLImageElement, src: string) => {
    if (this.props.src !== src) {
      // Props was updated while this image was loading, so ignore the event.
      return;
    }

    if (!this.canvas || !this.tempCanvas) {
      // Was unmounted
      return;
    }

    const canvas = this.canvas;
    const tempCanvas = this.tempCanvas;

    const w = img.width;
    const h = img.height;
    this.tempCanvas.width = w;
    this.tempCanvas.height = h;
    const tempCanvasContext = tempCanvas.getContext('2d');

    // https://sentry.io/housecanarycom/consumer-web-qa/issues/696076645
    if (!tempCanvasContext) {
      if (this.props.onError) {
        this.props.onError({
          target: tempCanvas,
          message: 'Context not available on temp canvas element',
        });
      }
      return;
    }

    // Set browser specific properties on an untyped object
    const untypedCtx: any = tempCanvasContext;
    untypedCtx.mozImageSmoothingEnabled = false;
    untypedCtx.webkitImageSmoothingEnabled = false;
    untypedCtx.msImageSmoothingEnabled = false;

    tempCanvasContext.imageSmoothingEnabled = false;
    tempCanvasContext.drawImage(img, 0, 0);
    const transformCtx = new TransformContext(tempCanvasContext, 0, 0, w, h);

    /* IE throws a script-blocking "SecurityError" in some cases when attempting
     * to do the image manipulation */
    this.props.transforms.forEach((transform) => {
      try {
        transform(transformCtx);
      } catch (e) {
        console.warn(`Error when attempting image manipulation: ${e}`);
        if (this.props.onError) {
          this.props.onError(e);
        }
      }
    });

    // scale the target canvas
    if (this.props.width && this.props.height) {
      // Do nothing, will have already been rendered with these props
    } else if (this.props.width) {
      canvas.height =
        transformCtx.height * (this.props.width / transformCtx.width);
    } else if (this.props.height) {
      canvas.width =
        transformCtx.width * (this.props.height / transformCtx.height);
    } else {
      canvas.width = transformCtx.width;
      canvas.height = transformCtx.height;
    }

    const canvasContext = canvas.getContext('2d');

    // https://sentry.io/housecanarycom/consumer-web-qa/issues/696127691
    if (!canvasContext) {
      if (this.props.onError) {
        this.props.onError({
          target: canvas,
          message: 'Context not available on canvas element',
        });
      }
      return;
    }

    canvasContext.drawImage(
      tempCanvas,
      transformCtx.left,
      transformCtx.top,
      transformCtx.width,
      transformCtx.height,
      0,
      0,
      canvas.width,
      canvas.height
    );

    if (this.props.onLoad) {
      this.props.onLoad({
        target: canvas,
      });
    }
  };
}
