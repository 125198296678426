import {
  LockedContent,
  LockedContentTheme,
} from '@client/components/LockedContent/LockedContent';
import {
  LockedComponentArgs,
  useLockedComponent,
} from '@client/hooks/use-locked-component.hooks';
import {
  LockedComponentType,
  SpecialUserType,
} from '@client/store/types/locked-components';
import React from 'react';
import styled from 'styled-components';

export interface LockedComponentProps {
  theme?: LockedContentTheme;
  className?: string;
  children: (args: LockedComponentArgs) => JSX.Element | null;
  sectionId?: LockedComponentType<any>;
  lockedFor: SpecialUserType[];
  lockMessage?: string;
  showLockedSection?: boolean;
  showLockIcon?: boolean;
}

const StyledLockedComponentContainer = styled.div`
  position: relative;
`;

export const LockedComponent: React.FC<LockedComponentProps> = ({
  children,
  theme,
  className,
  sectionId,
  lockedFor,
  lockMessage,
  showLockedSection = true,
  showLockIcon = true,
}) => {
  const lockedComponentArgs = useLockedComponent({ sectionId, lockedFor });

  if (showLockedSection && lockedComponentArgs.isLocked && sectionId) {
    return (
      <StyledLockedComponentContainer className={className}>
        <LockedContent
          theme={theme}
          lockMessage={lockMessage}
          showLockIcon={showLockIcon}
        />
        {children(lockedComponentArgs)}
      </StyledLockedComponentContainer>
    );
  }
  return <>{children(lockedComponentArgs)}</>;
};
