import { SyntheticEvent } from 'react';
import { AUTH_MODAL_PAGES } from '@client/store/constants';
import {
  LOUserApiResponse,
  UserAPIResponse,
} from '@client/store/types/consumer-api';

// creating a manual input event
type ManualInputEvent = {
  type: string;
  target: {
    [key: string]: any;
    name?: string;
    value?: any;
  };
};

export type InputEvent = SyntheticEvent | ManualInputEvent;

export type AuthModalPage =
  (typeof AUTH_MODAL_PAGES)[keyof typeof AUTH_MODAL_PAGES];

export type AuthModalSubHeadingText = {
  [K in AuthModalPage]: string;
};

export type AuthModalHeadingText = {
  [K in AuthModalPage]: string;
};

export type EmailSettingFrequency = 'daily' | 'weekly' | 'monthly';

export type User = UserAPIResponse | LOUserApiResponse;

export const isStandardUser = (
  user: UserAPIResponse | LOUserApiResponse | null
): user is UserAPIResponse =>
  !!(user && (user as LOUserApiResponse).nmls_id === undefined);

export const isLOUser = (
  user: UserAPIResponse | LOUserApiResponse | null
): user is LOUserApiResponse =>
  !!(user && (user as LOUserApiResponse).special_user_type === 'lo');

export type LODirectUserSignupFields = {
  email: string;
  first_name: string | null;
  last_name: string | null;
  password: string;
  invite_token?: string;
};
export interface LODirectUserSignupPayload extends LODirectUserSignupFields {
  application: string;
  partner_user_id?: string;
  tracking?: {
    origin_subdomain: string;
    ref: string;
  };
}

export type ValidationErrors = {
  email?: string;
  password?: string;
};

export type UserInfoProfilePage = {
  city: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  state: string | null;
  zipcode: string | null;
};

export type UserInfoProfilePageSnakeCase = Omit<
  UserInfoProfilePage,
  'firstName' | 'lastName' | 'zipcode'
> & {
  first_name: string | null;
  last_name: string | null;
  zip_code: string | null;
};

export type UserContactInfo = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export type AfterAuthAction = {
  type: string;
  payload?: any;
} | null;

export const isErrorObj = (
  error: string | null | ErrorObj
): error is ErrorObj => {
  return (
    !!error &&
    typeof error !== 'string' &&
    typeof (error as ErrorObj).messageRaw === 'string'
  );
};
export type ErrorObj = { messageRaw: string };

export type NodeCreateUserRequestBody = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string | null;
  password: string;
  application: string;
  loan_officer_id: string | null;
  agent_id: string | null;
};

export type NodeSelfCreateLODirectClientRequestBody = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string | null;
  password: string;
  application: string;
  loan_officer_id: string | null;
  agent_id: string | null;
  /* The below fields aren't sent by the browser/client if the user is signing up from
   * /chd-client-invite-sign-up after clicking an email invite link following a LO Direct client upload.
   * The fields already exist on the invite record in that case and will be transfered to the user after signup */
  buying_price_point?: number | null;
  is_buyer?: boolean | null;
  loan_amount?: number | null;
  slug?: string | null;
};
