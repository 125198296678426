import { debounce, throttle } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

type Props = {
  /* Setting throttle amount to 0 causes lodash throttling not to be used, but it's still throttled by
   * `requestAnimationFrame`, which is essentially throttling to 60x/second or 16ms */
  scrollableParent?: Element | null;
  /* Optional callback to fire on position change.  Can be used in lieu of the render prop. */
  onPositionChange?: ({ x, y }: { x: number; y: number }) => void;
  /* Whether to use lodash 'throttle' or 'debounce' methods for rate limiting the children re-render
   * or callback */
  rateLimitStrategy: 'throttle' | 'debounce';
  /* The duration to pass to the lodash rate limiting method */
  rateLimitAmount: number;
  /* The options to pass to the lodash rate limiting method */
  rateLimitOptions?: { leading: boolean; trailing: boolean };
  children?: ({ x, y }: { x: number; y: number }) => JSX.Element | null;
};

/**
 * Provides passed-in component with scroll positioning information for the specified
 * passed `scrollableParent`
 */
const ScrollTrackedComponent: React.FC<Props> = ({
  scrollableParent,
  children,
  rateLimitStrategy,
  rateLimitAmount,
  rateLimitOptions,
  onPositionChange,
}) => {
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });
  const handlePositionChange = ({ x, y }: { x: number; y: number }) => {
    setPosition({ x, y });
    if (onPositionChange) {
      onPositionChange({ x, y });
    }
  };

  /* Create instance variable to store this number that shouldn't cause re-renders when it changes */
  let animationFrame = useRef(0);
  const handleScroll = useCallback(
    (e) => {
      /* Used to subvert "expression is always true" TS error */
      const theWindow: any = window;

      if (scrollableParent) {
        animationFrame.current = window.requestAnimationFrame(() => {
          handlePositionChange({
            x: scrollableParent.scrollTop,
            y: scrollableParent.scrollTop,
          });
        });
      } else if (typeof theWindow !== 'undefined') {
        animationFrame.current = window.requestAnimationFrame(() => {
          const currentX =
            window.scrollX ||
            window.pageXOffset ||
            document.documentElement.scrollLeft ||
            0;
          const currentY =
            window.scrollY ||
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            0;
          handlePositionChange({
            x: currentX,
            y: currentY,
          });
        });
      }
    },
    [scrollableParent]
  );
  let rateLimitFn = rateLimitStrategy === 'debounce' ? debounce : throttle;
  let throttledHandleScroll = useRef(
    rateLimitAmount !== 0
      ? rateLimitFn(
          handleScroll,
          rateLimitAmount,
          rateLimitOptions || {
            trailing: true,
            leading: false,
          }
        )
      : handleScroll
  );

  /* Mount and unmount */
  useEffect(() => {
    /* Used to subvert "expression is always true" TS error */
    const theWindow: any = window;

    if (scrollableParent === null) {
      /* Do nothing, waiting for element to be passed-in */
    } else if (scrollableParent) {
      scrollableParent.addEventListener(
        'scroll',
        throttledHandleScroll.current
      );
    } else if (typeof theWindow !== 'undefined') {
      window.addEventListener('scroll', throttledHandleScroll.current);
    }

    return () => {
      /* Used to subvert "expression is always true" TS error */
      const theWindow: any = window;

      if (scrollableParent) {
        scrollableParent.removeEventListener(
          'scroll',
          throttledHandleScroll.current
        );
        window.cancelAnimationFrame(animationFrame.current);
      } else if (typeof theWindow !== 'undefined') {
        window.removeEventListener('scroll', throttledHandleScroll.current);
        window.cancelAnimationFrame(animationFrame.current);
      }
    };
  }, [handleScroll, scrollableParent]);

  return children ? children({ x: position.x, y: position.y }) : null;
};

export default ScrollTrackedComponent;
