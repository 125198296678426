import { themr } from '@friendsofreactjs/react-css-themr';
import defaultTheme from '@client/css-modules/TextAreaWithFormStyle.css';
import TextArea from '@client/components/generic/TextArea';

type PropsOf<T> = T extends React.ComponentType<infer P> ? P : any;
type Props = PropsOf<typeof TextArea>;

/**
 * Wrapper for TextArea that provides standard styling for textareas inside of forms in our app
 *
 * This component is not themeable since it's intended to provide the exact appearance of a text
 * input and label used in forms throughout the app. If you need different styling, consult with
 * design or use <TextInput> by itself.
 */
const TextAreaWithFormStyle: React.FC<Props> = (props) => (
  <TextArea {...props} />
);

export default themr(
  'ThemedTextAreaWithFormStyle',
  defaultTheme
)(TextAreaWithFormStyle);
