import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { routeChange } from '@src/redux-saga-router-plus/actions';

import BrokerageAttribution from '@client/components/BrokerageAttribution';
import { openBrokerageAttributionModal } from '@client/store/actions/modals.actions';
import {
  getIsSmallSize,
  getIsTabletOrSmallerSize,
} from '@client/store/selectors/match-media.selectors';
import { View } from '@client/routes/constants';
import { getActiveHomeownerOrPDPSlug } from '@client/store/selectors/router.selectors';

type OwnProps = {
  isVerticalAlignment?: boolean;
  hideBrokerageSection?: boolean;
};

const mapStateToProps = (state, ownProps: OwnProps) => ({
  /* Instead of using media queries in the CSS we are passing this from the container
   * based on the size of the viewport. This is because Homebuyer uses a hybrid of
   * mobile and desktop views for Tablet sizing, so we can't just use media queries across
   * the whole site */
  isVerticalAlignment: ownProps.isVerticalAlignment || getIsSmallSize(state),
  hideBrokerageSection: Boolean(
    ownProps.hideBrokerageSection && getIsTabletOrSmallerSize(state)
  ),
  slug: getActiveHomeownerOrPDPSlug(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openAttributionModal: () => dispatch(openBrokerageAttributionModal()),
  redirectToBrokerageInfo: () =>
    dispatch(routeChange({ view: View.BROKERAGE_INFO })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrokerageAttribution);
