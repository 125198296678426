export const DEFAULT_ERROR_MESSAGES /* : Object */ = {
  '404': 'Route Not Found',
  '401': 'Route Not Authorized (loginRequired)',
  '403': 'Route Forbidden (logoutRequired)',
  '500': 'Unhandled Exception',
};

export const ATTRIBUTE_IGNORE_INTERCEPT /* : string */ =
  'data-ignore-intercept';
export const LOG_MSG_PREFIX /* : string */ = '[Router]';
export const OS_MAC /* : string */ = 'MAC';
export const OS_WIN /* : string */ = 'WIN';
