import HC_CONSTANTS from '@client/app.config';
import {
  ACCESS_TOKEN_URL_KEY,
  ORIGINAL_CTA_URL_KEY,
  SEARCH_MAP_LOCATION_URL_PARAM_KEY,
  SEARCH_MAP_URL_PARAM_DELIMINATOR,
} from '@client/store/constants';
import { BoundsObject } from '@client/store/types/maps';
import accounting from 'accounting';

/**
 * Intended for reuse between the main app and the widgets - given a bounds object, provides the
 * formatted query params needed to navigate to the SRP for that location
 */
export const getSearchPageLocationUrlQueryParam = ({
  southWest,
  northEast,
}: BoundsObject): {
  [key: string]: string;
} => {
  const formattedLocation = [
    southWest.lat,
    southWest.lng,
    northEast.lat,
    northEast.lng,
  ]
    .map((value) => accounting.toFixed(value, 5))
    .join(SEARCH_MAP_URL_PARAM_DELIMINATOR);
  return {
    [SEARCH_MAP_LOCATION_URL_PARAM_KEY]: formattedLocation,
  };
};

/* generates current hostname i.e. https://www.comehome.com/ NOTE that url returns with a trailing "/" */
export const getHostname = (cobrandId: string) => {
  let hostname =
    process.env.NODE_ENV === 'test'
      ? 'test-hostname'
      : HC_CONSTANTS.COMEHOME_BRANDED_HOSTNAME;

  if (cobrandId) {
    hostname = hostname.replace('https://www', `https://${cobrandId}`);
  }

  return hostname;
};

export const getOutboundUrl = ({
  originalCtaUrl,
  accessToken,
}: {
  originalCtaUrl: string;
  accessToken: string;
}): string =>
  `${HC_CONSTANTS.OUTBOUND_CTA_URL}` +
  `?${ACCESS_TOKEN_URL_KEY}=${accessToken}` +
  `&${ORIGINAL_CTA_URL_KEY}=${encodeURIComponent(originalCtaUrl)}`;
