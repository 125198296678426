import { createSelector } from 'reselect';
import { STATUSES } from '@client/store/constants';
import { LAYER_METRICS } from '@client/store/map-constants';
import { ReduxState } from '@client/store/types/redux-state';

export function getMapsLegendBreaksState(state: ReduxState) {
  return state.mapLegendBreaks;
}

export const getMapLegendBreaksStatus = createSelector(
  getMapsLegendBreaksState,
  (mapsLegendBreaksState) => mapsLegendBreaksState.status
);

export const getMapLegendBreaks = createSelector(
  getMapsLegendBreaksState,
  (mapsLegendBreaksState) => mapsLegendBreaksState.data
);

export const getShowLocationInDataDeepDives = createSelector(
  getMapLegendBreaksStatus,
  getMapLegendBreaks,
  (status, legendBreaks) => {
    if (status === STATUSES.SUCCESS) {
      return !!legendBreaks[LAYER_METRICS.AVG_PRICE];
    } else {
      return false;
    }
  }
);
