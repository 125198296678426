import { connect } from 'react-redux';
import React, { Component } from 'react';
import { throttle } from 'lodash';
import Layer from '@hc/hcmaps-mapboxgl/lib/components/Layer';
import LayerSource from '@hc/hcmaps-mapboxgl/lib/components/LayerSource';
import { MapAndAPIContext } from '@hc/hcmaps-mapboxgl/lib/context/map-and-api-context';

import {
  COMPLETE_MLS_COVERAGE_LEVEL,
  MapboxLayerId,
} from '@client/store/map-constants';
import { MLSCoverageLayerFeatureProperties } from '@client/store/types/maps';
import { getShouldUsePatternForMLSCoverageLayer } from '@client/store/selectors/cobranding.selectors';
import HC_CONSTANTS from '@client/app.config';
import fillPattern from '@client/assets/images/mls-layer-pattern.png';

const { GEOTILE_DATA_URL } = HC_CONSTANTS;

const mapStateToProps = (state) => ({
  shouldUsePatternForMLSCoverageLayer:
    getShouldUsePatternForMLSCoverageLayer(state),
});

type Props = {
  beneathLayerId: MapboxLayerId;
  onMLSCoverageChange?: (
    coverageLevels: (null | MLSCoverageLayerFeatureProperties)[]
  ) => void;
  shouldUsePatternForMLSCoverageLayer: boolean;
};

type State = {
  sourceHasMounted: boolean;
  isPatternImageLoaded: boolean;
};

const patternImageId = 'fillPatternImage';
const sourceId = 'mls-coverage-layer-source';
const fillLayerId = 'mls-coverage-fill-layer';
const lineLayerId = 'mls-coverage-line-layer';
const sourceLayerId = 'consumer_mls_coverage';

class MapMLSCoverageLayer extends Component<Props> {
  state: State = {
    sourceHasMounted: false,
    isPatternImageLoaded: false,
  };

  static contextType = MapAndAPIContext;
  declare context: React.ContextType<typeof MapAndAPIContext>;

  throttledCheckSourceDataHandler: any = null;

  componentDidMount() {
    const { map } = this.context;
    const { onMLSCoverageChange, shouldUsePatternForMLSCoverageLayer } =
      this.props;

    if (onMLSCoverageChange && map) {
      this.throttledCheckSourceDataHandler = throttle(
        this.handleCheckSourceData,
        300,
        {
          trailing: true,
        }
      );
      map.on('data', this.throttledCheckSourceDataHandler);
      map.on('moveend', this.throttledCheckSourceDataHandler);
    }

    if (shouldUsePatternForMLSCoverageLayer && map) {
      /* Load the pattern image */
      map.loadImage(fillPattern, (error, image) => {
        if (error) {
          /* Since greater functionality might depend on this, don't block it if the image fails to load */
          if (onMLSCoverageChange) {
            onMLSCoverageChange([
              { mls_coverage: COMPLETE_MLS_COVERAGE_LEVEL },
            ]);
          }
          throw error;
        }
        /* Map might be unmounted by the time the image loads */
        if (map && map.getStyle() && image) {
          /* Add the image to the map */
          map.addImage(patternImageId, image);
          this.setState({ isPatternImageLoaded: true });
        }
      });
    }

    this.setState({ sourceHasMounted: true });
  }

  componentWillUnmount() {
    const { map } = this.context;
    const { onMLSCoverageChange, shouldUsePatternForMLSCoverageLayer } =
      this.props;
    if (onMLSCoverageChange) {
      onMLSCoverageChange([{ mls_coverage: COMPLETE_MLS_COVERAGE_LEVEL }]);
    }

    if (map) {
      if (onMLSCoverageChange) {
        map.off('data', this.throttledCheckSourceDataHandler);
        map.off('moveend', this.throttledCheckSourceDataHandler);
        this.throttledCheckSourceDataHandler.cancel();
        this.throttledCheckSourceDataHandler = null;
      }

      if (map.getStyle() && shouldUsePatternForMLSCoverageLayer) {
        if (map.hasImage(patternImageId)) {
          map.removeImage(patternImageId);
        }
      }
    }
  }

  handleCheckSourceData = () => {
    const { map } = this.context;
    const { onMLSCoverageChange } = this.props;

    /* Ensure that the map and map's style are ready, that the layer has been added to the map,
     * and that all tiles are loaded (else we're wasting effort on checking for mls coverage at this time)  */
    if (
      !map ||
      !map.getStyle() ||
      !map.getLayer(fillLayerId) ||
      !map.areTilesLoaded()
    ) {
      return;
    }

    const coverageLevels = map
      .queryRenderedFeatures(undefined, { layers: [fillLayerId] })
      .map(
        (feature) =>
          feature && (feature.properties as MLSCoverageLayerFeatureProperties)
      );

    if (onMLSCoverageChange) {
      onMLSCoverageChange(coverageLevels);
    }
  };

  render() {
    const { beneathLayerId, shouldUsePatternForMLSCoverageLayer } = this.props;
    const { sourceHasMounted, isPatternImageLoaded } = this.state;

    return (
      <>
        <LayerSource
          sourceId={sourceId}
          type="vector"
          url={`${GEOTILE_DATA_URL}/consumer_mls_coverage/{z}/{x}/{y}.mvt`}
          minZoom={4}
          maxZoom={22}
        />
        {sourceHasMounted &&
          shouldUsePatternForMLSCoverageLayer &&
          isPatternImageLoaded && (
            <Layer
              layerId={fillLayerId}
              sourceLayerId={sourceLayerId}
              type="fill"
              minZoom={4}
              paint={{
                'fill-pattern': patternImageId,
                'fill-opacity': [
                  'step',
                  /* -Infinity will be assigned to any `null` value, causing the fallback step def to be used */
                  ['number', ['get', 'mls_coverage'], +Infinity],
                  0.1,
                  30,
                  0,
                ],
              }}
              beneathLayerId={beneathLayerId}
              sourceId={sourceId}
            />
          )}
        {sourceHasMounted && !shouldUsePatternForMLSCoverageLayer && (
          <>
            <Layer
              layerId={fillLayerId}
              sourceLayerId={sourceLayerId}
              type="fill"
              minZoom={4}
              paint={{
                'fill-color': '#000',
                'fill-opacity': [
                  'step',
                  /* -Infinity will be assigned to any `null` value, causing the fallback step def to be used */
                  ['number', ['get', 'mls_coverage'], +Infinity],
                  0.05,
                  30,
                  0,
                ],
              }}
              sourceId={sourceId}
              beneathLayerId={beneathLayerId}
            />
            <Layer
              layerId={lineLayerId}
              sourceLayerId={sourceLayerId}
              type="line"
              minZoom={4}
              paint={{
                'line-color': '#000',
                'line-opacity': [
                  'step',
                  /* -Infinity will be assigned to any `null` value, causing the fallback step def to be used */
                  ['number', ['get', 'mls_coverage'], +Infinity],
                  0.2,
                  30,
                  0,
                ],
              }}
              sourceId={sourceId}
              beneathLayerId={beneathLayerId}
            />
          </>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, {})(MapMLSCoverageLayer);
