import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import CircleButton from '@client/components/generic/CircleButton';
import defaultTheme from '@client/css-modules/ShareButton.css';
import ShareButtonIconCobranded from '@client/components/ShareButtonIcon/ShareButtonIconCobranded';

export type ShareButtonProps = {
  onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;
  theme: Theme;
};

const ShareButton = ({ onClick, theme }: ShareButtonProps) => {
  return (
    <div className={theme.ShareButton}>
      <CircleButton
        ariaLabel="share"
        data-event-name="click_pdp_share"
        data-parent-event-name="click_share"
        onClick={onClick}
        theme={theme}
      >
        <ShareButtonIconCobranded className={theme.ShareIcon} />
      </CircleButton>
    </div>
  );
};

export default themr('ThemedShareButton', defaultTheme)(ShareButton);
