import { NoSavedSearchesProps } from '@client/store/types/saved-searches';
import NoSavedSearchesMobileIcon from '@client/inline-svgs/cobrand/lendco/lendco-no-saved-searches-mobile-icon';
import theme from '@client/css-modules/NoSavedSearches.css';
import PillButton from '@client/components/generic/PillButton';
import SavedSearchesPageDefaultIconCobranded from '@client/components/SavedSearchesPageDefaultIcon/SavedSearchesPageDefaultIconCobranded';
import { useSelector } from 'react-redux';
import { getIsSmallSize } from '@client/store/selectors/match-media.selectors';
import { getWatchlistPageConfig } from '@client/store/selectors/cobranding.selectors';

const NoSavedSearches: React.FC<NoSavedSearchesProps> = ({
  handleAccessFilters,
  pillButtonId,
}) => {
  const isSmallSize = useSelector(getIsSmallSize);
  const watchlistPageConfig = useSelector(getWatchlistPageConfig);

  return (
    <>
      {
        isSmallSize ? (
          <div className={theme.NoSavedSearchesMobile}>
            <div className={theme.NoResultsNotice}>
              <div className={theme.NoResultsIcon} >
                <NoSavedSearchesMobileIcon
                  className={theme.Icon}
                  aria-hidden="true"
                />
              </div>
              <div className={theme.NoResultsText}>
                {
                  watchlistPageConfig?.savedSearchesDescription ||
                  "You have no saved searches yet. Curate groupings of filters to surface homes that fit your customized search criteria."
                }
              </div>
            </div>
            <PillButton
              ariaLabel="Access filters"
              className={theme.AccessFiltersButton}
              onClick={handleAccessFilters}
              id={pillButtonId}
            >
              Access filters
            </PillButton>
          </div>
        ) : (
          <div className={theme.NoSavedSearchesTabletAndDesktop}>
            <div className={theme.NoResultsBackgroundImage}>
              <div className={theme.NoResultsCard}>
                <div className={theme.NoResultsIcon}>
                  <SavedSearchesPageDefaultIconCobranded
                    className={theme.Icon}
                    aria-hidden="true"
                  />
                </div>
                <div className={theme.NoResultsText}>
                  {
                    watchlistPageConfig?.savedSearchesDescription ||
                    "You have no saved searches yet. Curate groupings of filters to surface homes that fit your customized search criteria."
                  }
                </div>
                <PillButton
                  ariaLabel="Access filters"
                  className={theme.AccessFiltersButton}
                  onClick={handleAccessFilters}
                  id={pillButtonId}
                >
                  Access filters
                </PillButton>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default NoSavedSearches;
