import router from '@src/redux-saga-router-plus/reducer';
import { combineReducers } from 'redux';

import { RESET_AUTH_DEPENDENT_STATE } from '@client/store/actions/auth.actions';
import { FEEDBACK_PAGE_LOADED } from '@client/store/actions/feedback.actions';
import { FETCH_INIT_PROPERTY_DETAILS } from '@client/store/actions/property-details.actions';
import { CLEAR_SAVED_SEARCH_STATE } from '@client/store/actions/saved-search.actions';
import { clearOnActions } from '@client/store/clear-reducer-on-actions';
import abTestReducer from '@client/store/reducers/ab-tests.reducer';
import alertsReducer from '@client/store/reducers/alerts.reducer';
import appBannerReducer from '@client/store/reducers/app-banner.reducer';
import { attemptedRouteReducer } from '@client/store/reducers/attempted-route.reducer';
import {
  authReducer,
  confirmUserReducer,
  forgotPasswordReducer,
  userProfileReducer,
} from '@client/store/reducers/auth.reducer';
import brokerageInfoReducer from '@client/store/reducers/brokerage-info.reducer';
import cobrandingReducer from '@client/store/reducers/cobranding.reducer';
import { emailOptInReducer } from '@client/store/reducers/email-opt-in.reducer';
import enabledFeaturesReducer from '@client/store/reducers/enabled-features.reducer';
import feedbackReducer from '@client/store/reducers/feedback.reducer';
import homeownerReducer from '@client/store/reducers/homeowner.reducer';
import imageCTAsReducer from '@client/store/reducers/image-ctas.reducer';
import inviteReducer from '@client/store/reducers/invite.reducer';
import legalContentReducer from '@client/store/reducers/legal-content.reducer';
import loanOfficerReducer from '@client/store/reducers/loan-officer.reducer';
import localActivitiesReducer from '@client/store/reducers/local-activities.reducer';
import mapLegendBreaksReducer from '@client/store/reducers/map-legend-breaks.reducer';
import matchMediaReducer from '@client/store/reducers/match-media.reducer';
import mlsGlobalDisplayRulesReducer from '@client/store/reducers/mls-global-display-rules.reducer';
import modalsReducer from '@client/store/reducers/modals.reducer';
import propertyDetailsReducer from '@client/store/reducers/property-details.reducer';
import propertyPhotosReducer from '@client/store/reducers/property-photos.reducer';
import routerAdditionalInfoReducer from '@client/store/reducers/router-additional-info.reducer';
import savedSearchReducer from '@client/store/reducers/saved-search.reducer';
import searchReducer from '@client/store/reducers/search.reducer';
import serverRenderingInfoReducer from '@client/store/reducers/server-rendering-info.reducer';
import sharePropertyReducer from '@client/store/reducers/share-property.reducer';
import testPropertiesReducer from '@client/store/reducers/test-properties.reducer';
import toastReducer from '@client/store/reducers/toast.reducer';
import trackingReducer from '@client/store/reducers/tracking.reducer';
import utmReducer from '@client/store/reducers/utm.reducer';
import watchlistReducer from '@client/store/reducers/watchlist.reducer';
import AdminPortalSlice from '@client/store/slices/admin-portal.slice';
import analyticsDataReducer from '@client/store/slices/analytics-data.slice';
import censusTractSlice from '@client/store/slices/grant-program.slice';
import homeownerEquityReducer from '@client/store/slices/homeowner-equity.slice';
import homeownerMortgageReducer from '@client/store/slices/homeowner-mortgage.slice';
import homeownerVerificationReducer from '@client/store/slices/homeowner-verification.slice';
import LoDirectSlice from '@client/store/slices/lo-direct.slice';
import PartnerAPICredentialSlice from '@client/store/slices/partner-api-credential.slice';
import RecentUserActivitySlice from '@client/store/slices/recent-user-activity.slice';
import RequestATourSlice from '@client/store/slices/request-a-tour.slice';
import scaffoldingDataReducer from '@client/store/slices/scaffolding-data.slice';
import YourTeamSlice from '@client/store/slices/your-team.slice';

/** Please keep in alpha order */
export default combineReducers({
  abTests: abTestReducer,

  adminPortal: clearOnActions(AdminPortalSlice, [RESET_AUTH_DEPENDENT_STATE]),
  alerts: clearOnActions(alertsReducer, [RESET_AUTH_DEPENDENT_STATE]),
  analyticsData: analyticsDataReducer,
  appBanner: appBannerReducer,
  attemptedRoute: attemptedRouteReducer,
  auth: authReducer,
  brokerageInfo: brokerageInfoReducer,
  grantProgram: censusTractSlice,
  cobranding: cobrandingReducer,
  confirmUser: confirmUserReducer,
  emailOptIn: emailOptInReducer,
  enabledFeatures: enabledFeaturesReducer,
  feedback: clearOnActions(feedbackReducer, [FEEDBACK_PAGE_LOADED]),
  forgotPassword: forgotPasswordReducer,
  homeowner: clearOnActions(homeownerReducer, [RESET_AUTH_DEPENDENT_STATE]),
  homeownerEquity: clearOnActions(homeownerEquityReducer, [
    RESET_AUTH_DEPENDENT_STATE,
  ]),
  homeownerMortgage: clearOnActions(homeownerMortgageReducer, [
    RESET_AUTH_DEPENDENT_STATE,
  ]),
  homeownerVerification: clearOnActions(homeownerVerificationReducer, [
    RESET_AUTH_DEPENDENT_STATE,
  ]),
  invite: inviteReducer,
  legalContent: legalContentReducer,
  loanOfficer: clearOnActions(loanOfficerReducer, [RESET_AUTH_DEPENDENT_STATE]),
  localActivities: clearOnActions(localActivitiesReducer, [
    FETCH_INIT_PROPERTY_DETAILS,
  ]),
  loDirect: clearOnActions(LoDirectSlice, [RESET_AUTH_DEPENDENT_STATE]),
  mapLegendBreaks: clearOnActions(mapLegendBreaksReducer, [
    FETCH_INIT_PROPERTY_DETAILS,
  ]),
  matchMedia: matchMediaReducer,
  mlsGlobalDisplayRules: mlsGlobalDisplayRulesReducer,
  modals: modalsReducer,
  propertyDetails: propertyDetailsReducer,
  propertyPhotos: propertyPhotosReducer,
  recentUserActivity: clearOnActions(RecentUserActivitySlice, [
    RESET_AUTH_DEPENDENT_STATE,
  ]),
  requestATour: clearOnActions(RequestATourSlice, [RESET_AUTH_DEPENDENT_STATE]),
  router,
  routerAdditionalInfo: routerAdditionalInfoReducer,
  shareProperty: sharePropertyReducer,
  savedSearch: clearOnActions(savedSearchReducer, [
    RESET_AUTH_DEPENDENT_STATE,
    CLEAR_SAVED_SEARCH_STATE,
  ]),
  scaffoldingData: scaffoldingDataReducer,
  search: searchReducer,
  serverRenderingInfo: serverRenderingInfoReducer,
  testProperties: testPropertiesReducer,
  toast: toastReducer,
  tracking: trackingReducer,
  userProfile: clearOnActions(userProfileReducer, [RESET_AUTH_DEPENDENT_STATE]),
  utm: utmReducer,
  watchlist: clearOnActions(watchlistReducer, [RESET_AUTH_DEPENDENT_STATE]),
  yourTeam: clearOnActions(YourTeamSlice, [RESET_AUTH_DEPENDENT_STATE]),
  imageCTAs: imageCTAsReducer,
  partnerAPICredential: PartnerAPICredentialSlice,
});
