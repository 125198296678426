import { routeChange } from '@src/redux-saga-router-plus/actions';
import { get } from 'lodash';
import { all, call, fork, put, select, take } from 'redux-saga/effects';

import { View } from '@client/routes/constants';
import { consumerApiClient } from '@client/services/consumer-api-client';
import { graphQLApiClient } from '@client/services/graphql-api-client';
import {
  CLAIM_HOME_IF_LOGGED_IN_THEN_REDIRECT,
  CLAIM_PROPERTY,
  claimProperty,
  claimPropertyError,
  claimPropertySuccess,
  deletePropertyFromClaimedHomesState,
  FETCH_CLAIMED_HOMES,
  FETCH_CLAIMED_HOMES_IF_NECESSARY,
  FETCH_CLAIMED_HOMES_SUCCESS,
  FETCH_HOME_ADVISOR_ESTIMATED_COST,
  FETCH_HOME_INFO_FOR_PROFINDER,
  FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS,
  FETCH_HOMEOWNER_HOME_IMPROVEMENTS,
  FETCH_HOMEOWNER_MANAGE_HOMES_DATA,
  FETCH_HOMEOWNER_MANAGE_SINGLE_HOME,
  FETCH_HOMEOWNER_PROPERTY_IF_NECESSARY,
  FETCH_HOMEOWNER_USER_DATA,
  FETCH_HOMEOWNER_USER_DATA_IF_NECESSARY,
  FETCH_LOAN_TERMS,
  FETCH_LOAN_TERMS_IF_NECESSARY,
  FETCH_PROPERTY_VALUE_FORECAST,
  fetchClaimedHomes,
  fetchClaimedHomesError,
  fetchClaimedHomesIfNecessary,
  fetchClaimedHomesSuccess,
  fetchHomeAdvisorEstimatedCostSuccess,
  fetchHomeAdvisorReviewsError,
  fetchHomeAdvisorReviewsSuccess,
  fetchHomeInfoForProFinderSuccess,
  FetchHomeownerHomeImprovementsAction,
  fetchHomeownerHomeImprovementsSuccess,
  fetchHomeownerManageHomesDataError,
  fetchHomeownerManageSingleHome,
  fetchHomeownerPropDetailsError,
  fetchHomeownerPropDetailsSuccess,
  FetchHomeownerPropertyIfNecessaryAction,
  fetchHomeownerUserData,
  FetchHomeownerUserDataAction,
  fetchHomeownerUserDataIfNecessary,
  fetchHomeownerUserDataSuccess,
  fetchLoanTerms,
  fetchLoanTermsIfNecessary,
  fetchPropertyValueForecast,
  fetchPropertyValueForecastSuccess,
  MANAGE_SESSION_STORAGE_USER_INPUT_HOME_IMPROVEMENT_CALCULATION,
  REMOVE_PROPERTY_FROM_CLAIMED_HOMES,
  RemovePropertyFromClaimedHomesAction,
  removePropertyFromClaimedHomesError,
  REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE,
  requestHomeAdvisorContractorQuoteError,
  requestHomeAdvisorContractorQuoteSuccess,
  resetClaimedHomeFormStatus,
  resetHomeownerPropDetails,
  SendClaimedHomeDetailsToDbAction,
  SendClaimedHomeDetailsToDbThroughEditHomeFormAction,
  SET_HOMEOWNER_PMI_MODAL_WAS_SHOWN,
  setHomeAdvisorReviewsStatusToSuccess,
  setHomeownerManageHomeStateToLoading,
  SetHomeownerPmiModalWasShownAction,
  setProFinderStatusToSuccess,
  SUBMIT_PROFINDER_REFERRAL_REQUEST,
  SUBMIT_PROPENSITY_TO_SELL_RESPONSE,
  submitProFinderReferralRequestError,
  submitProFinderReferralRequestSuccess,
  SubmitPropensityToSellResponseAction,
  UPDATE_CLAIMED_HOME_DETAILS,
  UPDATE_CLAIMED_HOME_DETAILS_VIA_FORM,
  updateClaimedHomeError,
  updateClaimedHomesSuccess,
  updateLoanTerms,
  updateLoanTermsError,
} from '@client/store/actions/homeowner.actions';
import { setActiveNotification } from '@client/store/actions/modals.actions';
import { STATUSES } from '@client/store/constants';
import {
  CLAIMED_HOME_KEYS,
  ONE_YEAR_AND_A_MONTH_FROM_NOW,
  TEN_YEARS_AND_A_MONTH_AGO,
} from '@client/store/homeowner-constants';
import { submitReferral } from '@client/store/sagas/referral-services.saga';
import {
  getCurrentUser,
  getIsLoggedIn,
} from '@client/store/selectors/auth.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  getAfterMortgageUpdateAction,
  getClaimedHomes,
  getClaimedHomesStatus,
  getHomeownerClaimedHomeEdits,
  getHomeownerManageHomesInitStatus,
  getHomeownerUserDataStatus,
  getIsDoneGettingDataForAllClaimedHomes,
  getLoanTermOptions,
  getProFinderReviews,
  getPropertyAddress,
} from '@client/store/selectors/homeowner.selectors';
import { getLoanOfficerInfo } from '@client/store/selectors/loan-officer.selectors';
import { getHomeownerPropertyDataCache } from '@client/store/selectors/router.selectors';
import { selectHICEstimateId } from '@client/store/slices/analytics-data.slice';
import {
  ClaimedHomeFormStateKeys,
  ClaimedHomes,
} from '@client/store/types/homeowner';
import { ReferralFormValues } from '@client/store/types/property-contact-form';
import { formatDate } from '@client/utils/date.utils';
import { getPhotoUrlsFromPhotos } from '@client/utils/photo.utils';
import { watchEvery } from '@client/utils/saga.utils';

const SAVE_ERROR_MSG =
  'Unable to save changes. Please reload the page and try again.';

function* getHomeownerUserDataIfNecessary(action) {
  const status = (yield select(getHomeownerUserDataStatus)) as ReturnType<
    typeof getHomeownerUserDataStatus
  >;
  const isLoggedIn = (yield select(getIsLoggedIn)) as ReturnType<
    typeof getIsLoggedIn
  >;
  const isAlreadyLoadedOrLoading =
    status === STATUSES.SUCCESS || status === STATUSES.LOADING;
  const homeownerSelector = yield call(getIsFeatureEnabled, 'homeowner');
  const isHomeownerEnabled = yield select(homeownerSelector);
  if (isLoggedIn && isHomeownerEnabled && !isAlreadyLoadedOrLoading) {
    yield put(fetchHomeownerUserData());
  }
}

function* getHomeInfoForProFinder(action) {
  const slug = action.payload.slug;
  yield put(fetchClaimedHomesIfNecessary());

  const propertyAddress = yield select(getPropertyAddress);
  const slugFromState = get(propertyAddress, 'slug');
  const hcAddressId = get(propertyAddress, 'hcAddressId');
  const fullAddress = get(propertyAddress, 'fullAddress');
  const streetAddress = get(propertyAddress, 'streetAddress');
  const city = get(propertyAddress, 'city');
  const propertyStateCode = get(propertyAddress, 'state');
  const zipcode = get(propertyAddress, 'zipcode');

  if (
    slugFromState &&
    hcAddressId &&
    fullAddress &&
    streetAddress &&
    city &&
    propertyStateCode &&
    zipcode
  ) {
    yield put(setProFinderStatusToSuccess());
  } else {
    const homeAddressData = yield call(
      [graphQLApiClient, graphQLApiClient.getCHOProFinderData],
      { slug }
    );
    yield put(fetchHomeInfoForProFinderSuccess(slug, homeAddressData));
  }
  /* Check if logged in and if home is claimed, redirect otherwise */
  const [isLoggedIn, claimedHomes] = yield all([
    select(getIsLoggedIn),
    select(getClaimedHomes),
  ]);

  const isActivePropertyInUsersClaimedHomes = !!get(claimedHomes, slug);

  if (!isLoggedIn) {
    yield put(
      routeChange({
        view: View.HOMEOWNER,
      })
    );
  } else if (!isActivePropertyInUsersClaimedHomes) {
    yield put(
      routeChange({
        view: View.HOMEOWNER_PROPERTY_DETAILS,
        params: {
          slug: slug,
        },
      })
    );
  }
}

function* fetchHomeAdvisorReferrals(action) {
  const formData = action.payload.formData;
  try {
    const response = yield call(
      [consumerApiClient, consumerApiClient.getHomeAdvisorReferrals],
      formData
    );
    yield put(submitProFinderReferralRequestSuccess(response));
  } catch (err: any) {
    yield put(submitProFinderReferralRequestError(err?.responseJSON?.message));
  }
}

function* requestHomeAdvisorContractorQuoteSaga(action) {
  const { projectId, businessId } = action.payload;
  try {
    const response = yield call(
      [consumerApiClient, consumerApiClient.requestHomeAdvisorQuote],
      { projectId, businessId }
    );
    yield put(
      requestHomeAdvisorContractorQuoteSuccess(
        businessId,
        get(response, 'phone_number')
      )
    );
  } catch (err) {
    yield put(requestHomeAdvisorContractorQuoteError(businessId));
  }
}

function* fetchHomeAdvisorReviewsForBusiness(action) {
  const { businessId } = action.payload;
  const currentReviews = yield select(getProFinderReviews);
  const alreadyHasReviewsForThisBusiness = !!currentReviews[businessId];

  if (!alreadyHasReviewsForThisBusiness) {
    try {
      const response = yield call(
        [
          consumerApiClient,
          consumerApiClient.requestHomeAdvisorBusinessReviews,
        ],
        businessId
      );
      yield put(
        fetchHomeAdvisorReviewsSuccess({ businessId, reviews: response })
      );
    } catch (err) {
      yield put(fetchHomeAdvisorReviewsError(businessId));
    }
  } else {
    yield put(setHomeAdvisorReviewsStatusToSuccess());
  }
}

/* This is the initial above the fold data request for Homeowner. */
function* getHomeownerUserData(action: FetchHomeownerUserDataAction) {
  // Users could navigate from one homeowner page to another homeowner page, in
  // which case they would already have their claimed homes and loan terms set.
  // We only want to make those calls if the user doesn't already have that data set:
  yield put(fetchClaimedHomesIfNecessary());
  yield put(fetchLoanTermsIfNecessary());
  yield put(fetchHomeownerUserDataSuccess());
}

function* fetchClaimedHomesData() {
  // Fetch claimed homes data and set on state
  const claimedHomes = yield call([
    consumerApiClient,
    consumerApiClient.getClaimedHomesData,
  ]);
  if (claimedHomes) {
    yield put(fetchClaimedHomesSuccess(claimedHomes));
  } else {
    yield put(fetchClaimedHomesError());
  }
  return claimedHomes;
}

function* fetchClaimedHomesIfNotAlreadyFetched() {
  const claimedHomesFetchStatus = yield select(getClaimedHomesStatus);
  const isLoggedIn = yield select(getIsLoggedIn);
  const isHomeownerEnabled = (yield select(
    getIsFeatureEnabled('homeowner')
  )) as boolean;

  if (
    claimedHomesFetchStatus === STATUSES.INIT &&
    isLoggedIn &&
    isHomeownerEnabled
  ) {
    yield put(fetchClaimedHomes());
  }
}

function* setHomeownerPmiModalWasShownSaga(
  action: SetHomeownerPmiModalWasShownAction
) {
  const homeDetails: ClaimedHomeFormStateKeys = (yield select(
    getHomeownerClaimedHomeEdits
  )) as ClaimedHomeFormStateKeys;
  const payload = {
    ...homeDetails,
    [CLAIMED_HOME_KEYS.PMI_MODAL_WAS_SHOWN]: {
      overridden: true,
      value: true,
    },
  };

  const result = yield call(
    [consumerApiClient, consumerApiClient.updateClaimedHomesData],
    payload
  );
  yield put(updateClaimedHomesSuccess(result));
}

function enforceClaimedHomesAPIFormat(homeDetails) {
  for (let key in homeDetails) {
    const NUMERIC_VALUE = [
      CLAIMED_HOME_KEYS.BATHS_FULL,
      CLAIMED_HOME_KEYS.BATHS_PARTIAL,
      CLAIMED_HOME_KEYS.BEDS,
      CLAIMED_HOME_KEYS.ROOM_COUNT,
    ] as string[];
    const DROPDOWN_VALUE = [
      CLAIMED_HOME_KEYS.BASEMENT,
      CLAIMED_HOME_KEYS.POOL,
      CLAIMED_HOME_KEYS.CONDITION,
      CLAIMED_HOME_KEYS.GARAGE_TYPE,
    ] as string[];

    /* HomeownerOptionSelector designed to take string value only. all the value parsing here and below in this saga could be removed once we refactor heavily abstracted components */
    if (DROPDOWN_VALUE.includes(key) && homeDetails[key].overridden) {
      if (homeDetails[key].value === 'true') {
        homeDetails[key].value = true;
      } else if (homeDetails[key].value === 'false') {
        homeDetails[key].value = false;
      } else if (homeDetails[key].value === '0') {
        homeDetails[key].value = null;
      }
    } else if (NUMERIC_VALUE.includes(key) && homeDetails[key].overridden) {
      if (!homeDetails[key].value) {
        homeDetails[key].value = 0;
      } else {
        homeDetails[key].value = parseInt(homeDetails[key].value, 10);
      }
    } else if (
      key === CLAIMED_HOME_KEYS.LIVING_AREA &&
      !homeDetails[key].value &&
      homeDetails[key].overridden
    ) {
      homeDetails[key].value = 0;
    } else {
      if (
        key !== CLAIMED_HOME_KEYS.TERMS &&
        key !== CLAIMED_HOME_KEYS.SALE_DATE &&
        typeof homeDetails[key].value === 'string'
      ) {
        const dataPoint = homeDetails[key];
        const numbersMatches =
          dataPoint && get(dataPoint, 'value', '0').match(/[0-9]|\./g);
        if (numbersMatches) {
          const updatedVal = Number(numbersMatches.join(''));
          homeDetails[key].value = updatedVal;
        }
      } else if (key === CLAIMED_HOME_KEYS.SALE_DATE) {
        if (homeDetails[key].value === '') {
          homeDetails[key].value = null;
        } else if (homeDetails[key].value) {
          let date = new Date(homeDetails[key].value);
          homeDetails[key].value = date;
        }
      } else if (key === CLAIMED_HOME_KEYS.TERMS) {
        if (homeDetails[key].value === '0') {
          homeDetails[key].value = null;
        }
      } else {
        /* Protects against sending incorrect types, empty strings to backend */
        if (homeDetails[key].value === '') {
          if (
            key === CLAIMED_HOME_KEYS.PRINCIPAL ||
            key === CLAIMED_HOME_KEYS.RATE
          ) {
            homeDetails[key].value = 0;
          } else {
            homeDetails[key].value = null;
          }
        } else if (typeof homeDetails[key].value === 'string') {
          homeDetails[key].value = parseInt(homeDetails[key].value, 10);
        }
      }
    }
  }
}

function* updateClaimedHomeSaga(
  action:
    | SendClaimedHomeDetailsToDbThroughEditHomeFormAction
    | SendClaimedHomeDetailsToDbAction
) {
  let homeDetails = yield select(getHomeownerClaimedHomeEdits);
  let homeDetailsFromPayload = action.payload?.homeDetails;

  /* We can update claimed home data by 2 different ways.
  1. UPDATE_CLAIMED_HOME_DETAILS by passing the homeDetails through the action payload.
    Use on the open to sell home toggle button
  2. Through the edit form. homeDetailsFromPayload will be undefined this way because we use the data from editClaimedHomeForm in the state.
    We call CHANGE_CLAIMED_HOME_FORM_VALUE to set the data first then call UPDATE_CLAIMED_HOME_DETAILS.
    Use on the edit detail form.
   */
  if (homeDetailsFromPayload) {
    homeDetails = Object.assign(homeDetails, homeDetailsFromPayload);
    if (Object.keys(homeDetails).every((key) => homeDetails[key].overridden)) {
      for (let key in homeDetails) {
        if (key === CLAIMED_HOME_KEYS.OPTED_IN_TO_SELL) {
          try {
            const result = yield call(
              [consumerApiClient, consumerApiClient.updateClaimedHomesData],
              homeDetails
            );
            yield put(updateClaimedHomesSuccess(result));
          } catch (error: any) {
            yield put(
              updateClaimedHomeError(
                get(error, 'messageRaw', SAVE_ERROR_MSG),
                'toast_error_save_reload'
              )
            );
          }
        }
      }
    }
    return;
  }

  homeDetails = Object.assign(homeDetails, {});
  if (Object.keys(homeDetails).some((key) => homeDetails[key].overridden)) {
    yield call(enforceClaimedHomesAPIFormat, homeDetails);
    try {
      const result = yield call(
        [consumerApiClient, consumerApiClient.updateClaimedHomesData],
        homeDetails
      );
      /**
       * Check if anything has changed in the mortgage data and if enough data
       * exists to recalculate the mortgage info, do so:
       */
      const keysToCheckForMortgageUpdates = [
        CLAIMED_HOME_KEYS.PRINCIPAL,
        CLAIMED_HOME_KEYS.RATE,
        CLAIMED_HOME_KEYS.SALE_DATE,
        CLAIMED_HOME_KEYS.SALE_PRICE,
        CLAIMED_HOME_KEYS.TERMS,
      ];

      const hasUpdatedMortgageInfo = keysToCheckForMortgageUpdates.some(
        (key) => homeDetails[key].overridden
      );
      if (hasUpdatedMortgageInfo) {
        const afterMortgageUpdateAction = yield select(
          getAfterMortgageUpdateAction
        );
        if (afterMortgageUpdateAction) {
          yield put(afterMortgageUpdateAction);
        }
      }

      yield put(updateClaimedHomesSuccess(result));
      yield put(setActiveNotification('home-edit-success-notification'));
    } catch (error: any) {
      yield put(
        updateClaimedHomeError(
          get(error, 'messageRaw', SAVE_ERROR_MSG),
          'toast_error_save_reload'
        )
      );
    }
  } else {
    yield put(resetClaimedHomeFormStatus());
  }
}

function* getLoanTerms() {
  try {
    const result = yield call([
      consumerApiClient,
      consumerApiClient.getLoanTerms,
    ]);
    yield put(updateLoanTerms(result));
  } catch (error: any) {
    yield put(
      updateLoanTermsError(
        get(error, 'messageRaw', SAVE_ERROR_MSG),
        'toast_error_save_reload'
      )
    );
  }
}

/* Get home improve value */
function* getHomeownerHomeImprovementsSaga(
  action: FetchHomeownerHomeImprovementsAction
) {
  const { slug, data } = action.payload;
  const {
    bedsAdditionalSqFt,
    bathAdditionalSqFt,
    squareFootageAdditionalSqFt,
  } = data;
  const improveValueResponse = yield call(
    [graphQLApiClient, graphQLApiClient.getHomeownerHomeImprovements],
    slug,
    bedsAdditionalSqFt,
    bathAdditionalSqFt,
    squareFootageAdditionalSqFt
  );
  const improvementsData = get(improveValueResponse, ['propertyLookup']);

  const estimateId = yield select(selectHICEstimateId);

  /* TODO: testing => this is for displaying the pool on the home improvement calculator. Remove this test if we know the property that will be increase value by adding a pool */
  // improvementsData.improvements.pool.value = 10000;
  yield put(
    fetchHomeownerHomeImprovementsSuccess(slug, improvementsData, estimateId)
  );
}

export type ObjToStore = {
  slug: string;
  value: number | string;
};

function manageSessionStorageUserInputHomeImprovementsCalculationSaga(action) {
  const { activePropertySlug, sessionStorageKey, value, isSet } =
    action.payload;

  const objToStore = {
    slug: activePropertySlug,
    value: value,
  };
  if (!window.sessionStorage) {
    return;
  }
  const storedInputHomeImprovementsCalculatorString =
    window.sessionStorage.getItem(sessionStorageKey);
  let storedInputHomeImprovementsCalculatorArr: ObjToStore[] = [];

  if (storedInputHomeImprovementsCalculatorString) {
    storedInputHomeImprovementsCalculatorArr = JSON.parse(
      storedInputHomeImprovementsCalculatorString
    );
    const targetIndex = storedInputHomeImprovementsCalculatorArr.findIndex(
      (ele) => ele.slug === activePropertySlug
    );

    if (targetIndex > -1) {
      storedInputHomeImprovementsCalculatorArr[targetIndex].value = value;
    } else {
      storedInputHomeImprovementsCalculatorArr.push(objToStore);
    }
  } else {
    storedInputHomeImprovementsCalculatorArr.push(objToStore);
  }

  if (window.sessionStorage) {
    if (isSet) {
      window.sessionStorage.setItem(
        sessionStorageKey,
        JSON.stringify(storedInputHomeImprovementsCalculatorArr)
      );
    } else {
      window.sessionStorage.removeItem(sessionStorageKey);
    }
  }
}

function* getPropertyValueForecastSaga(action) {
  const { slug } = action.payload;
  const result = yield call(
    [graphQLApiClient, graphQLApiClient.getPropertyValueForecastData],
    slug
  );
  const data = get(result, ['propertyLookup']);
  yield put(fetchPropertyValueForecastSuccess(slug, data));
}

function* getLoanTermsIfNecessary() {
  const terms = yield select(getLoanTermOptions);
  if (!terms.length) {
    yield put(fetchLoanTerms());
  }
}

/* Sometimes you need to claim a property that isn't the active property */
export function* claimPropertySaga(action) {
  const { slug } = action.payload;
  const claimedHomes = (yield select(getClaimedHomes)) as ReturnType<
    typeof getClaimedHomes
  >;
  if (!claimedHomes[slug]) {
    const data = { slug };
    try {
      const result = yield call(
        [consumerApiClient, consumerApiClient.associateClaimedHomeWithUser],
        data
      );
      yield put(claimPropertySuccess(result));
    } catch (error: any) {
      const isLoggedIn = yield select(getIsLoggedIn);
      const CLAIM_PROP_ERR_MSG =
        'Unable to save changes. You may need to login or signup to claim this property.';
      if (isLoggedIn) {
        yield put(
          claimPropertyError(
            get(error, 'messageRaw', CLAIM_PROP_ERR_MSG),
            'toast_error_save_login'
          )
        );
      }
    }
  }
}

function* getManageHomeCardData(action) {
  const { slug, zipHpiStart, zipHpiEnd } = action.payload.data;
  /* Forking for performance, this allows us to call each at once instead of waiting for each one to return first */
  yield fork(
    [graphQLApiClient, graphQLApiClient.getHomeownerManageHomeCardData],
    { slug, zipHpiStart, zipHpiEnd }
  );
}

function* getClaimedHomePropertyData(action) {
  const claimedHomesFetchStatus = yield select(getClaimedHomesStatus);
  if (claimedHomesFetchStatus === STATUSES.INIT) {
    take(FETCH_CLAIMED_HOMES_SUCCESS);
  }
  const claimedHomes: ClaimedHomes = action.payload.data;
  const hasDataForAllClaimedHomes = yield select(
    getIsDoneGettingDataForAllClaimedHomes
  );
  const homeDataStatus = yield select(getHomeownerManageHomesInitStatus);
  const isLoading = homeDataStatus === STATUSES.LOADING;

  if (!hasDataForAllClaimedHomes && !isLoading) {
    yield put(setHomeownerManageHomeStateToLoading());
    const date = new Date();
    const currentDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const firstOfMonth = currentDate.getTime();
    const firstOfLastMonth = currentDate.setMonth(currentDate.getMonth() - 1);

    const zipHpiStart = formatDate(firstOfLastMonth, 'YYYY-MM-DD');
    const zipHpiEnd = formatDate(firstOfMonth, 'YYYY-MM-DD');

    for (let key of Object.keys(claimedHomes)) {
      let slug = key;
      if (!claimedHomes[slug]?.data) {
        try {
          yield put(
            fetchHomeownerManageSingleHome({ slug, zipHpiStart, zipHpiEnd })
          );
        } catch (error: any) {
          yield put(fetchHomeownerManageHomesDataError({ key, error }));
          /* Rethrow for Sentry reporting */
          throw error;
        }
      }
    }
  }
}

function* removePropertyFromClaimedHomesSaga(
  action: RemovePropertyFromClaimedHomesAction
) {
  try {
    const { slug } = action.payload;
    const claimedHomes = yield select(getClaimedHomes);
    const propertyToRemove = claimedHomes[slug];
    const tableId = propertyToRemove.id;
    yield call(
      [consumerApiClient, consumerApiClient.disassociateClaimedHomeFromUser],
      tableId
    );
    yield put(deletePropertyFromClaimedHomesState(slug));
  } catch (error: any) {
    const REMOVAL_ERR_MSG =
      "We're sorry, an error occurred while removing this property. Please reload the page and try again.";
    yield put(
      removePropertyFromClaimedHomesError(
        get(error, 'messageRaw', REMOVAL_ERR_MSG)
      )
    );
    /* Rethrow for Sentry reporting */
    throw error;
  }
}

function* claimHomeIfLoggedInThenRedirectSaga(action) {
  const slug = get(action, ['payload', 'slug'], null);
  const isLoggedIn = yield select(getIsLoggedIn);

  if (isLoggedIn) {
    yield put(claimProperty(slug));
  }

  yield put(
    routeChange({
      view: View.HOMEOWNER_PROPERTY_DETAILS,
      params: { slug: slug },
    })
  );
}

function* getHomeAdvisorEstimatedCostSaga(action) {
  const { slug, taskKey } = action.payload;
  const response = yield call(
    [consumerApiClient, consumerApiClient.getHomeAdvisorEstimatedCost],
    taskKey
  );
  yield put(fetchHomeAdvisorEstimatedCostSuccess(slug, response));
}

function* fetchHomeownerPropertyIfNecessarySaga(
  action: FetchHomeownerPropertyIfNecessaryAction
) {
  const propertyDataCache = (yield select(
    getHomeownerPropertyDataCache
  )) as ReturnType<typeof getHomeownerPropertyDataCache>;

  if (
    !propertyDataCache[action.payload.slug] ||
    propertyDataCache[action.payload.slug]?.initHomeownerPageStatus ===
      STATUSES.INIT
  ) {
    const slug = action.payload.slug;
    yield put(resetHomeownerPropDetails(slug));
    yield put(fetchHomeownerUserDataIfNecessary());

    yield put(fetchPropertyValueForecast(slug));
    const zipCode = slug.split('-').pop();

    /* We have to use TEN_YEARS_AND_A_MONTH_AGO and not just TEN_YEARS_AGO
      because when you query hpiHistorical AVM data it will bump everything
      forward 1 month automatically (┛ಠ_ಠ)┛彡┻━┻ */
    try {
      const apiOptions = {
        slug,
        zipHpiStart: TEN_YEARS_AND_A_MONTH_AGO,
        zipHpiEnd: ONE_YEAR_AND_A_MONTH_FROM_NOW,
      };

      const propertyData = yield call(
        [graphQLApiClient, graphQLApiClient.getHomeownerPropDetailsData],
        apiOptions
      );
      const placeSearchData = yield call(
        [graphQLApiClient, graphQLApiClient.getHomeownerPlaceSearchData],
        { zipCode }
      );
      const placeId = get(
        placeSearchData,
        ['placeSearch', 'places', 0, 'placeId'],
        null
      );

      const propertyLookup = {
        ...propertyData,
        /* TODO: we should instead let `bestPhotos` be set on state and use `getPhotoUrlsFromPhotos` in a selector */
        photos: getPhotoUrlsFromPhotos(propertyData.bestPhotos),
      };
      yield put(
        fetchHomeownerPropDetailsSuccess({
          slug,
          data: { ...propertyLookup, placeId: placeId },
        })
      );
    } catch (error: any) {
      yield put(fetchHomeownerPropDetailsError({ slug }));
      /* Rethrow for Sentry reporting */
      throw error;
    }
  }
}

function* submitPropensityToSellResponseSaga(
  action: SubmitPropensityToSellResponseAction
) {
  const isReferralServicesEnabled = yield select(
    getIsFeatureEnabled('referral_services')
  );
  const result = yield call(
    [consumerApiClient, consumerApiClient.updateClaimedHomesData],
    {
      [CLAIMED_HOME_KEYS.INTERESTED_IN_SELLING]: {
        overridden: true,
        value: action.payload.interestedInSelling,
      },
    }
  );

  if (
    isReferralServicesEnabled &&
    action.payload.interestedInSelling === 'YES' &&
    action.payload.canContact
  ) {
    const loanOfficer = (yield select(getLoanOfficerInfo)) as ReturnType<
      typeof getLoanOfficerInfo
    >;
    const user = (yield select(getCurrentUser)) as ReturnType<
      typeof getCurrentUser
    >;
    if (!user) {
      throw new Error(
        'Attempting to submit propensity modal without a valid user'
      );
    }
    const referralData: ReferralFormValues = {
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
      email: action.payload.email,
      phone: action.payload.phone,
      entrySlug: action.payload.addressSlug,
      propertyDetailSlugs: [action.payload.addressSlug],
      selectedProperty: action.payload.propertyData,
      buyingOrSelling: 'Selling',
    };
    yield call(submitReferral, referralData, loanOfficer, '/refer/seller');
  }
  yield put(updateClaimedHomesSuccess(result));
}

export default (sagaMiddleware) => {
  watchEvery(sagaMiddleware, {
    [REMOVE_PROPERTY_FROM_CLAIMED_HOMES]: removePropertyFromClaimedHomesSaga,
    [FETCH_LOAN_TERMS_IF_NECESSARY]: getLoanTermsIfNecessary,
    [FETCH_LOAN_TERMS]: getLoanTerms,
    [UPDATE_CLAIMED_HOME_DETAILS]: updateClaimedHomeSaga,
    [UPDATE_CLAIMED_HOME_DETAILS_VIA_FORM]: updateClaimedHomeSaga,
    [FETCH_CLAIMED_HOMES_IF_NECESSARY]: fetchClaimedHomesIfNotAlreadyFetched,
    [FETCH_CLAIMED_HOMES]: fetchClaimedHomesData,
    [FETCH_HOMEOWNER_USER_DATA]: getHomeownerUserData,
    [FETCH_HOMEOWNER_USER_DATA_IF_NECESSARY]: getHomeownerUserDataIfNecessary,
    [FETCH_HOMEOWNER_PROPERTY_IF_NECESSARY]:
      fetchHomeownerPropertyIfNecessarySaga,
    [FETCH_HOMEOWNER_HOME_IMPROVEMENTS]: getHomeownerHomeImprovementsSaga,
    [MANAGE_SESSION_STORAGE_USER_INPUT_HOME_IMPROVEMENT_CALCULATION]:
      manageSessionStorageUserInputHomeImprovementsCalculationSaga,
    [FETCH_PROPERTY_VALUE_FORECAST]: getPropertyValueForecastSaga,
    [CLAIM_PROPERTY]: claimPropertySaga,
    [FETCH_HOMEOWNER_MANAGE_HOMES_DATA]: getClaimedHomePropertyData,
    [CLAIM_HOME_IF_LOGGED_IN_THEN_REDIRECT]:
      claimHomeIfLoggedInThenRedirectSaga,
    [FETCH_HOME_ADVISOR_ESTIMATED_COST]: getHomeAdvisorEstimatedCostSaga,
    [FETCH_HOME_INFO_FOR_PROFINDER]: getHomeInfoForProFinder,
    [SUBMIT_PROFINDER_REFERRAL_REQUEST]: fetchHomeAdvisorReferrals,
    [REQUEST_HOMEADVISOR_CONTRACTOR_QUOTE]:
      requestHomeAdvisorContractorQuoteSaga,
    [FETCH_HOMEADVISOR_REVIEWS_FOR_BUSINESS]:
      fetchHomeAdvisorReviewsForBusiness,
    [FETCH_HOMEOWNER_MANAGE_SINGLE_HOME]: getManageHomeCardData,
    [SET_HOMEOWNER_PMI_MODAL_WAS_SHOWN]: setHomeownerPmiModalWasShownSaga,
    [SUBMIT_PROPENSITY_TO_SELL_RESPONSE]: submitPropensityToSellResponseSaga,
  });
};
