import React from 'react';
import classNames from 'classnames';

import theme from '@client/css-modules/NewIcon.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';

type Props = {
  className?: string;
};

const NewIcon: React.FunctionComponent<Props> = ({ className }) => {
  const { newIconBg, newIconTextColor } = useCobrandStyles();
  return (
    <div
      className={
        className ? classNames(theme.NewIcon, className) : theme.NewIcon
      }
      style={{
        background: newIconBg,
        color: newIconTextColor,
      }}
    >
      New
    </div>
  );
};

export default NewIcon;
