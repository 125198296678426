import React from 'react';
import { Alert, AlertProps } from '@client/utils/reach-alert';

/**
 * Component wrapper of Reach-UI's <Alert /> component, when this is not
 * rendered with shouldComponentUpdate it can cause the screen reader re-read the Alert with
 * every re-render, which is obnoxious.
 */
export default class PureAlert extends React.Component<AlertProps> {
  shouldComponentUpdate(nextProps: AlertProps) {
    return nextProps.children !== this.props.children;
  }
  render() {
    return <Alert {...this.props} />;
  }
}
