import { connect } from 'react-redux';
import TransformedImageIOSFix from '@client/components/TransformedImageIOSFix';
import { getIsIOSDevice } from '@client/store/selectors/match-media.selectors';

const mapStateToProps = (state) => {
  return {
    shouldRenderImgFallback: getIsIOSDevice(state),
  };
};

export default connect(mapStateToProps, () => ({}))(TransformedImageIOSFix);
