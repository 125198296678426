import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { ADMIN_ROUTES, LO_DIRECT_ROUTES, View } from '@client/routes/constants';
import { STATUSES } from '@client/store/constants';
import { ReferralFormValues } from '@client/store/types/property-contact-form';
import { ReduxState } from '@client/store/types/redux-state';
import {
  CompleteAgentData,
  ConnectedAgent,
  ContactLoanOfficerActionArgs,
  FetchAgentDataPayload,
  FetchAgentType,
  LODirectLeadMessage,
  PreviousAgent,
  YourTeamModalType,
  YourTeamState,
} from '@client/store/types/your-team';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';

const agentInitState = {
  fetchStatus: STATUSES.INIT,
  data: null,
};

export const INITIAL_YOUR_TEAM_STATE: YourTeamState = {
  connectedAgent: agentInitState,
  genericAgent: agentInitState,
  lenderAgent: agentInitState,
  previousAgent: agentInitState,
  /* controls YT modal open/close AND tab lo/agent state */
  modalType: null,
  contactAgentError: null,
  contactLoanOfficerError: null,
  contactAgentMessageStatus: STATUSES.INIT,
  contactLoanOfficerMessageStatus: STATUSES.INIT,
  isMessageToLoDirectSubmitted: false,
};

export const YourTeamSlice = createSlice({
  name: 'yourTeam',
  initialState: INITIAL_YOUR_TEAM_STATE,
  reducers: {
    fetchAgentData: (state, action: PayloadAction<FetchAgentDataPayload>) => ({
      ...state,
      [action.payload.agentType]: {
        data: state[action.payload.agentType]?.data ?? null,
        fetchStatus: STATUSES.LOADING,
      },
    }),
    postContactAgent: (
      state,
      action: PayloadAction<{ selectedCta: string; id: string }>
    ) => ({
      ...state,
      contactAgentMessageStatus: STATUSES.SUCCESS,
    }),
    resetContactAgentStatus: (state) => ({
      ...state,
      contactAgentMessageStatus: STATUSES.INIT,
    }),
    postLenderAgentSelection: (
      state,
      action: PayloadAction<{
        formValues: ReferralFormValues;
        agentId: string;
        agentData: CompleteAgentData;
      }>
    ) => ({
      ...state,
      connectedAgent: {
        data: {
          agent: action.payload.agentData,
          incomplete_agent: null,
          intro_completed: false,
          intro_required: false,
        },
        fetchStatus: STATUSES.SUCCESS,
      },
    }),
    postPreviousAgentSelection: (
      state,
      action: PayloadAction<{
        formValues: ReferralFormValues;
        agentData: PreviousAgent;
      }>
    ) => ({
      ...state,
      connectedAgent: {
        data: {
          agent: null,
          incomplete_agent: {
            id: '',
            phone: action.payload.agentData.phone,
            full_name: action.payload.agentData.name,
            email: action.payload.agentData.email,
            brokerage_name: action.payload.agentData.brokerageName,
            license_number: action.payload.agentData.licenseNumber,
            license_state: action.payload.agentData.state,
          },
          intro_completed: false,
          intro_required: false,
        },
        fetchStatus: STATUSES.SUCCESS,
      },
    }),
    postFindAgentForm: (
      state,
      action: PayloadAction<{ formValues: ReferralFormValues }>
    ) => {},
    fetchAgentDataError: (state, action: PayloadAction<FetchAgentType>) => ({
      ...state,
      [action.payload]: {
        data: state[action.payload]?.data ?? null,
        fetchStatus: STATUSES.ERROR,
      },
    }),
    cacheAgentData: (
      state,
      action: PayloadAction<
        | {
            agentType: 'connectedAgent';
            data: ConnectedAgent;
          }
        | {
            agentType: 'genericAgent';
            data: ConnectedAgent;
          }
        | {
            agentType: 'lenderAgent';
            data: CompleteAgentData;
          }
        | {
            agentType: 'previousAgent';
            data: PreviousAgent;
          }
      >
    ) => ({
      ...state,
      [action.payload.agentType]: {
        fetchStatus: STATUSES.SUCCESS,
        data: action.payload.data,
      },
    }),
    setYourTeamModalType: (
      state,
      action: PayloadAction<{ modalType: YourTeamModalType }>
    ) => ({
      ...state,
      modalType: action.payload.modalType,
    }),
    deleteAgentSelection: (state) => ({
      ...state,
      connectedAgent: agentInitState,
    }),
    postContactLoanOfficer: (
      state,
      action: PayloadAction<ContactLoanOfficerActionArgs>
    ) => ({
      ...state,
      contactLoanOfficerMessageStatus: STATUSES.LOADING,
    }),
    contactLoanOfficerSuccess: (state) => ({
      ...state,
      contactLoanOfficerError: null,
      contactLoanOfficerMessageStatus: STATUSES.SUCCESS,
    }),
    contactLoanOfficerError: (
      state,
      action: PayloadAction<{ error: string }>
    ) => ({
      ...state,
      contactLoanOfficerError: action.payload.error,
      contactLoanOfficerMessageStatus: STATUSES.ERROR,
    }),
    resetContactLoanOfficerMessageState: (state) => ({
      ...state,
      contactLoanOfficerError: null,
      contactLoanOfficerMessageStatus: STATUSES.INIT,
    }),
    submitLODirectLeadMessage: (
      state,
      action: PayloadAction<LODirectLeadMessage>
    ) => ({
      ...state,
      isMessageToLoDirectSubmitted: true,
    }),
    clearisMessageToLoDirectSubmitted: (state) => ({
      ...state,
      isMessageToLoDirectSubmitted: false,
    }),
  },
});

function selectYourTeamState(state: ReduxState): YourTeamState {
  return state.yourTeam;
}
export const selectConnectedAgent = createSelector(
  selectYourTeamState,
  (yourTeamState) => yourTeamState.connectedAgent
);
export const selectGenericAgent = createSelector(
  selectYourTeamState,
  (yourTeamState) => yourTeamState.genericAgent
);
export const selectCompleteConnectedAgent = createSelector(
  selectYourTeamState,
  (yourTeamState) => yourTeamState.connectedAgent?.data?.agent || null
);
export const selectLenderAgent = createSelector(
  selectYourTeamState,
  (yourTeamState) => yourTeamState.lenderAgent
);
export const selectPreviousAgent = createSelector(
  selectYourTeamState,
  (yourTeamState) => yourTeamState.previousAgent
);
export const selectYourTeamModalType = createSelector(
  selectYourTeamState,
  (yourTeamState) => yourTeamState.modalType
);

export const selectContactAgentMessageStatus = createSelector(
  selectYourTeamState,
  (yourTeamState) => yourTeamState.contactAgentMessageStatus
);

export const selectLoanOfficerMessageStatus = createSelector(
  selectYourTeamState,
  (yourTeamState) => yourTeamState.contactLoanOfficerMessageStatus
);
export const selectIsMessageToLoDirectSubmitted = createSelector(
  selectYourTeamState,
  (yourTeamState) => yourTeamState.isMessageToLoDirectSubmitted
);

export const showYourTeamModalForRoute = createSelector(
  getCurrentView,
  (currentView) => {
    const HIDDEN_FOR_VIEW = [
      ...ADMIN_ROUTES,
      ...LO_DIRECT_ROUTES,
      View.PARTNER_API_CREDENTIAL,
      View.PAGE_404,
      View.WIDGET_DEMO_BANNER,
      View.WIDGET_DEMO_HERO,
      View.NATIVE_APP_TEST,
      View.CHD_CLIENT_SIGN_UP,
      View.HOME_INSURANCE,
      View.UNAUTHENTICATED_ACCESS,
    ];
    return currentView && !HIDDEN_FOR_VIEW.includes(currentView);
  }
);

export const {
  fetchAgentData,
  fetchAgentDataError,
  cacheAgentData,
  postContactAgent,
  postLenderAgentSelection,
  postPreviousAgentSelection,
  postFindAgentForm,
  setYourTeamModalType,
  deleteAgentSelection,
  postContactLoanOfficer,
  contactLoanOfficerSuccess,
  contactLoanOfficerError,
  resetContactLoanOfficerMessageState,
  submitLODirectLeadMessage,
  clearisMessageToLoDirectSubmitted,
  resetContactAgentStatus,
} = YourTeamSlice.actions;

export default YourTeamSlice.reducer;
