import { RouteType, RouteChangeType } from './types';

import { DEFAULT_ERROR_MESSAGES } from './constants';

export const ROUTER_ERROR = 'ROUTER_ERROR';
export const ROUTE_UPDATE = 'ROUTE_UPDATE';
export const ROUTE_CHANGE = 'ROUTE_CHANGE';
export const ROUTE_BACK = 'ROUTE_BACK';

export type ActionMetaType = {
  router: RouteType;
  [key: string]: Object;
};

export type ActionRouterErrorType = {
  type: typeof ROUTER_ERROR;
  payload: {
    errorCode: number;
    message: string | null;
    e: Object | null;
    metadata?: Object;
  };
};

export type ActionRouteUpdateType = {
  type: typeof ROUTE_UPDATE;
  payload: RouteType;
};

export type ActionRouteChangeType = {
  type: typeof ROUTE_CHANGE;
  payload: RouteChangeType;
};

export type ActionRouteBackType = {
  type: typeof ROUTE_BACK;
};

export const routerError = (
  errorCode: number | null,
  message: string | null,
  e: Object | null,
  metadata?: Object
): ActionRouterErrorType => {
  return {
    type: ROUTER_ERROR,
    payload: {
      errorCode: errorCode || 500,
      message:
        message ||
        DEFAULT_ERROR_MESSAGES[(errorCode && errorCode.toString()) || 500],
      e,
      metadata,
    },
  };
};

// Updates the current route in the store and renders the associated component
export const routeUpdate = (route: RouteType): ActionRouteUpdateType => ({
  type: ROUTE_UPDATE,
  payload: { ...route },
});

// Navigate to a new route/url
export const routeChange = (route: RouteChangeType): ActionRouteChangeType => ({
  type: ROUTE_CHANGE,
  payload: { ...route },
});

// Navigate to previous route/url
export const routeBack = (): ActionRouteBackType => ({
  type: ROUTE_BACK,
});

export type ActionType =
  | ActionRouterErrorType
  | ActionRouteUpdateType
  | ActionRouteChangeType
  | ActionRouteBackType;
