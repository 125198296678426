import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { Component } from 'react';

import PropertyCard from '@client/components/PropertyCard';
import Slider from '@client/components/Slider';
import defaultTheme from '@client/css-modules/NearbyListings.css';

import { AnalyticsEventAddress } from '@client/store/types/analytics';
import { NormalizedProperty } from '@client/store/types/property';

const { cardWidth } = defaultTheme;

type Props = {
  hasListings: boolean;
  nearbyListings: NormalizedProperty[];
  handlePropertyClick: (
    slug: string,
    subjectPropertyAddress: AnalyticsEventAddress
  ) => void;
  isCurrentPDPActiveListing: boolean;
  isShowingDaysOnMarket: boolean;
  theme: Theme;
  subjectPropertyAddress: AnalyticsEventAddress | null;
  reportNearbyListingsCarouselDotClick: (
    subjectPropertyAddress: AnalyticsEventAddress
  ) => void;
  handleNearbyListingsWatchClick: () => void;
  handleNearbyListingsUnwatchClick: () => void;
  handleNearbyListingsUnwatchConfirmClick: () => void;
};

class NearbyListings extends Component<Props> {
  localHandlePropertyClick = (slug: string) => {
    const { subjectPropertyAddress, handlePropertyClick } = this.props;
    handlePropertyClick(slug, subjectPropertyAddress!);
  };

  localReportPositionIndicatorClick = () => {
    const { subjectPropertyAddress, reportNearbyListingsCarouselDotClick } =
      this.props;
    reportNearbyListingsCarouselDotClick(subjectPropertyAddress!);
  };

  render() {
    const {
      hasListings,
      nearbyListings,
      isCurrentPDPActiveListing,
      theme,
      handleNearbyListingsWatchClick,
      handleNearbyListingsUnwatchClick,
      handleNearbyListingsUnwatchConfirmClick,
      isShowingDaysOnMarket,
    } = this.props;

    const renderedListings =
      !!nearbyListings.length &&
      nearbyListings.map((listing) => (
        <PropertyCard
          theme={theme}
          key={listing.slug}
          photoSize={'MEDIUM' as 'MEDIUM'}
          propertyDetails={listing}
          goToProperty={this.localHandlePropertyClick}
          style={{ width: cardWidth, maxWidth: cardWidth }}
          isAddedToWatchList={false}
          isShowingWatchListActionButton={false}
          isShowingDaysOnMarket={isShowingDaysOnMarket}
          handleReportWatchClick={handleNearbyListingsWatchClick}
          handleReportUnwatchClick={handleNearbyListingsUnwatchClick}
          handleReportUnwatchConfirmClick={
            handleNearbyListingsUnwatchConfirmClick
          }
        />
      ));

    return (
      isCurrentPDPActiveListing &&
      hasListings && (
        <section
          className={theme.NearbyListings}
          data-hc-name="nearby-listings-section"
        >
          <div
            className={theme.Heading}
            role="heading"
            aria-level={3}
            data-hc-name="nearby-listings-heading"
          >
            Other Homes For Sale
          </div>
          <div className={theme.SliderContainer}>
            <Slider
              sliderTrackDataHcName={'nearby-listings-properties'}
              theme={theme}
              infinityMode={false}
              includePositionIndicator={nearbyListings.length > 1}
              hideRightArrow
              hideLeftArrow
              reportPositionIndicatorClick={
                this.localReportPositionIndicatorClick
              }
              uIdField="propertyDetails.slug"
            >
              {renderedListings}
            </Slider>
          </div>
        </section>
      )
    );
  }
}

const ThemedNearbyListings = themr(
  'NearbyListings',
  defaultTheme
)(NearbyListings);
export default ThemedNearbyListings;
