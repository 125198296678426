import React from 'react';
import scrollIntoView from 'scroll-into-view';

type Props = {
  children: JSX.Element;
  timing?: number;
  topPosition?: number;
};

/**
 * Wrapper Component used to scroll into view wrapped components when
 * rendered
 */
export default class ScrollSectionIntoView extends React.Component<Props> {
  static defaultProps = {
    timing: 300,
    topPosition: 0.5,
  };

  scrollToSection?: JSX.Element;

  setScrollRef = (el) => {
    this.scrollToSection = el;
  };

  componentDidMount() {
    const itemToScroll = this.scrollToSection;
    scrollIntoView(itemToScroll, {
      time: this.props.timing,
      align: {
        top: this.props.topPosition,
      },
    });
  }

  render() {
    const { children } = this.props;

    return <div ref={this.setScrollRef}>{children}</div>;
  }
}
