import { Api } from '@hc/hcmaps-mapboxgl/lib/components/Maps';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { debounce, isEqual, keyBy, throttle } from 'lodash';
import { LngLat, LngLatBounds, Map, MapMouseEvent, Point } from 'mapbox-gl';
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';

import AdCardForCobrand from '@client/components/AdCardForCobrand';
import ConnectWithATopLocalAgent from '@client/components/cobrand/topagentsranked/ConnectWithATopLocalAgent';
import CobrandedStyles from '@client/components/CobrandedStyles';
import ConditionalFeature from '@client/components/ConditionalFeature';
import DisclaimerFairHousing from '@client/components/DisclaimerFairHousing';
import DropdownSort from '@client/components/generic/DropdownSort';
import FlatButton from '@client/components/generic/FlatButton';
import LoadingSection from '@client/components/generic/LoadingSection';
import PureAlert from '@client/components/generic/PureAlert';
import ImageCTAOrLenderCTA from '@client/components/ImageCTAOrLenderCTA';
import LazilyRenderedList from '@client/components/LazilyRenderedList';
import LenderCTACardCobranded from '@client/components/LenderCTACard/LenderCTACardCobranded';
import LoadMoreResultsButton from '@client/components/LoadMoreResultsButton';
import Maps from '@client/components/Maps';
import NoResultsNotice from '@client/components/NoResultsNotice';
import PropertyCard from '@client/components/PropertyCard';
import RecentUserActivityCTA from '@client/components/RecentUserActivity/RecentUserActivityCTA';
import ResizableContainer from '@client/components/ResizableContainer';
import SaveSearchFloatingButton from '@client/components/SaveSearchFloatingButton';
import ScaffoldingMarketingCardSRP from '@client/components/ScaffoldingUI/ScaffoldingMarketingCard/ScaffoldingMarketingCardSRP';
import SearchLoanOfficerAdCobranded from '@client/components/SearchLoanOfficerAd/SearchLoanOfficerAdCobranded';
import SearchMapNotification from '@client/components/SearchMapNotification';
import SearchMapNotificationNoMLSCobranded from '@client/components/SearchMapNotificationNoMLS/SearchMapNotificationNoMLSCobranded';
import SRPFinanceCTA from '@client/components/SRPFinanceCTA';
import AutoCompleteSearchContainer from '@client/containers/autocomplete-search.container';
import BrokerageAttributionContainer from '@client/containers/brokerage-attribution.container';
import Footer from '@client/containers/footer.container';
import MultiUnitSelectModalContainer from '@client/containers/multi-unit-select-modal.container';
import SearchMapPropertyCardContainer from '@client/containers/search-map-property-card.container';
import SearchTopBarFiltersContainer from '@client/containers/search-top-bar-filters.container';
import propertyCardTheme from '@client/css-modules/PropertyCard.css';
import theme from '@client/css-modules/SearchPageMap.css';
import ExclamationIcon from '@client/inline-svgs/exclamation';
import HeartFilled from '@client/inline-svgs/heart-filled';
import HeartOutline from '@client/inline-svgs/heart-outline';
import HeatMapsIcon from '@client/inline-svgs/heat-maps-icon';
import LocationIcon from '@client/inline-svgs/location';
import MobileFilterIcon from '@client/inline-svgs/mobile-filter';
import SortIcon from '@client/inline-svgs/sort-icon';
import Spinner from '@client/inline-svgs/spinner';
import WatchListEmptyIcon from '@client/inline-svgs/watchlist-empty';
import { View } from '@client/routes/constants';
import {
  SEARCH_ACTIVE_VIEW_URL_PARAM_KEY,
  SEARCH_LIST_SORT_OPTIONS,
  STATUSES,
} from '@client/store/constants';
import { LayerMetric, MAPBOX_LAYER_IDS } from '@client/store/map-constants';
import { CARD_TYPES } from '@client/store/types/cobranded-components/lender-cta-card';
import {
  APIClusterMapMarker,
  GaiaSchoolsById,
  LatitudeLongitudeObject,
  LatLngObject,
  MapMarkerGeoJSONFeature,
  MLSCoverageLayerFeatureProperties,
  MultiUnitDataObject,
  MultiUnitMapMarker,
  PropertyMapMarker,
  SetMapLocation,
  SubjectMapMarker,
} from '@client/store/types/maps';
import {
  NormalizedProperty,
  PropertyListProperty,
  PropertyListWithAdCard,
} from '@client/store/types/property';
import {
  SearchListSortField,
  SearchListSortOrder,
} from '@client/store/types/search';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { generateGenericUid } from '@client/utils/component.utils';
import {
  getBoundsFromCenterPointAndZoom,
  getIsPointInsideGeoJSON,
  getMapboxGLBoundsForBounds,
  getMultiUnitClusterKey,
  isPropertyMarker,
} from '@client/utils/maps.utils';
import { getPropertyCountDisplay } from '@client/utils/property.utils';
import {
  getIsAdCardForCobrand,
  getIsFinanceAd,
  getIsLenderCTACard,
  getIsLoanOfficerAd,
  getIsPropertyCardItem,
  getIsTopAgentsRankedAd,
} from '@client/utils/search-ad.utils';
import { parseCSSAmount } from '@client/utils/string.utils';
import { Geometry } from 'geojson';

const { cardHeight, cardWidth } = propertyCardTheme;
const { sidebarHorizontalPadding } = theme;
const cardWidthInt = parseCSSAmount(cardWidth);
const cardHeightInt = parseCSSAmount(cardHeight);
const sidebarHorizontalPaddingInt = parseCSSAmount(sidebarHorizontalPadding);
const PROPERTY_CARD_DIMENSIONS = {
  width: cardWidth,
  height: cardHeight,
};
const TOO_LOW_ZOOM_WARNING_TEXT = 'Please zoom in to view properties';
const MAP_CENTER_PIN_ANIMATION_DURATION = 600;
const MAP_FIT_POPUP_ANIMATION_DURATION = 1500;
const MAP_FIT_BOUNDS_OPTIONS = { duration: 1500 };
const AUTOCOMPLETE_SUBMIT_BUTTON_ID = 'search-autocomplete-submit-button';

type TriggerProp = string | number | boolean | null;

export type HandleMapPositionChangeArgs = {
  bounds: LngLatBounds;
  zoom: number;
  allowUpdatingUrlParams: boolean;
  shouldReloadSidebarProperties: boolean;
  shouldResetPropertyListState: boolean;
  /* If this is undefined, we won't change the current respective Redux state value */
  showSidebarLoadingIndicator?: boolean;
};

/* Please keep in alpha order */
export type SearchMapPageProps = {
  activeFilterCount: number;
  activeMultiUnitProperties: MultiUnitDataObject[] | null;
  allMarkerAreClusters: boolean;
  allowShowingMobileButtons: boolean;
  areDefaultFiltersChanged: boolean;
  canLoadMoreSidebarProperties: boolean;
  disableSidebarDragging: boolean;
  fitPopupPadding: { x: number; y: number };
  getWatchListData: () => void;
  handleCancelPendingPropertyQueries: () => void;
  handleClearConstrainedToPlace: () => void;
  handleClearCurrentSavedSearch: () => void;
  handleGetMapLayerLegendBreaks: (
    bounds: LngLatBounds,
    zoom: number,
    metric?: LayerMetric
  ) => void;
  /* Handle changing the route to hide the PDP modal */
  handleHidePDPModal: () => void;
  handleFetchMapProperties: ({
    bounds,
    zoom,
  }: {
    bounds: LngLatBounds;
    zoom: number;
  }) => void;
  handleFetchSelectedPropertySidebarData: (addressSlug: string) => void;
  handleLoadMoreSidebarProperties: () => void;
  handleMapDrag: (isLayerActive: boolean) => void;
  handleMapMoveAfterInitialized: (bounds: LngLatBounds) => void;
  handleMapPositionChange: ({
    bounds,
    zoom,
    allowUpdatingUrlParams,
    shouldReloadSidebarProperties,
    shouldResetPropertyListState,
    showSidebarLoadingIndicator,
  }: HandleMapPositionChangeArgs) => void;
  handleMapZoom: (isLayerEnabled: boolean, isZoomIn: boolean) => void;
  handleNavigateToPDP: (normalizedPropertyData: NormalizedProperty) => void;
  handleOpenSearchList: () => void;
  handlePropertyCardHover: ({
    property,
    isHovered,
    isMarkerOnMap,
  }: {
    property: PropertyListProperty;
    isHovered: boolean;
    isMarkerOnMap: boolean;
  }) => void;
  handleReportMapPropertyClick: () => void;
  handleReportMarkerClick: (addressSlug: string) => void;
  handleReportPropertyWatchClick: (slug: string) => void;
  handleReportPropertyUnwatchClick: (slug: string) => void;
  handleReportPropertyUnwatchConfirmClick: (slug: string) => void;
  handleReportSearchInputFocus: () => void;
  handleReportShowingMapBottomLayerControl: (isShowing: boolean) => void;
  handleReportSidebarPropertyClick: () => void;
  handleSetActiveMultiUnitProperties: (
    properties: MultiUnitDataObject[] | null
  ) => void;
  handleSetMultiUnitPropertyData: (
    properties: MultiUnitDataObject[] | null
  ) => void;
  handleToggleMapBottomLayerGroupsList: (shouldShow: boolean) => void;
  handleToggleMarkers: (
    shouldShow: boolean,
    bounds: LngLatBounds,
    zoom: number
  ) => void;
  handleChangeSort: (
    field: SearchListSortField,
    order: SearchListSortOrder
  ) => void;
  handleRouteChange: (view: View, query?: {}) => void;
  /* Handle changing the route to show the PDP modal */
  handleShowPDPModal: (featureProps: PropertyMapMarker) => void;
  handleSetMLSLayerFeatures: (
    features: (null | MLSCoverageLayerFeatureProperties)[]
  ) => void;
  handleToggleMobileFilters: () => void;
  hasSavedCurrentSearch: boolean;
  hasSeparateListPage: boolean;
  highlightSchoolMarkers: ({
    addressSlug,
    gaiaSchoolsById,
  }: {
    addressSlug: string | null;
    gaiaSchoolsById: GaiaSchoolsById;
  }) => void;
  invalidateMapSizeTrigger: number;
  isConstrainedToPlaceWithLimitedCoverage: boolean;
  isLoadingMoreSidebarProperties: boolean;
  isPropertyListInitOrLoadingStatus: boolean;
  isPropertyListErrorStatus: boolean;
  isLoggedIn: boolean;
  /* Whether the PDP modal should be shown now, triggered on route change */
  isPDPModalActive: boolean;
  isSavingCurrentSearch: boolean;
  isScaffoldingCMGMarketingCardDataForSRP?: boolean;
  /* This controls the showing/hiding of the heatmap groups selector */
  isShowingMapBottomLayerGroupsList: boolean;
  isSortControlDisabled: boolean;
  isShowingMapZoomControls: boolean;
  isShowingMLSCoverageLayer: boolean;
  isMLSCoverageIncompleteForMapArea: boolean;
  isShowingMobileFilters: boolean;
  isShowingNoMLSModal: boolean;
  isShowingResultCount: boolean;
  isShowingSearchInput: boolean;
  isShowingSearchList: boolean;
  isShowingSidebarForScreenSize: boolean;
  isShowingMarkers: boolean;
  isShowingTooLowZoomWarningOverMap: boolean;
  isUsingLegacySaveSearchButton: boolean;
  isCanaryUIFeatureEnabled?: boolean;
  isCtaCleanupEnabled: boolean;
  isYourTeamEnabled: boolean;
  /* Required since heatmaps are being used */
  mapGeoRequestAccessToken: string;
  mapPulseLocations: LatitudeLongitudeObject[] | null;
  markerFeatures: MapMarkerGeoJSONFeature[];
  markerFeaturesChangedTrigger: string;
  /* The zoom level below which to hide the marker features layer */
  markerFeaturesMinZoom: number;
  MarkerPopup?: React.ReactElement;
  maxResultCount: number;
  multiUnitMarkersByLatLng: {
    [key: string]: MultiUnitDataObject[];
  };
  placeBoundaryUpdateTrigger: string | null;
  placeBoundaryGeoJSON: Geometry | null;
  placeGeoJSONDescription?: string;
  propertyListLastUpdatedTimestamp: string;
  setMapLocation: SetMapLocation;
  /* Used to prevent map zoom/move events from taking affect when we're in the process of leaving
   * the search page route */
  shouldAllowHandlingMapPositionChange: boolean;
  shouldCenterMapMarkerOnClick: boolean;
  shouldClearConstrainedPlaceOnClearingSearch: boolean;
  shouldDisplayCurrentLocationSearchOption: boolean;
  shouldGetGeolocationFromDevice: boolean;
  shouldHideSidebarForConstrainedPlaceWithNoResults: boolean;
  shouldMaintainUrlWhenUpdatingPlaceBoundary: boolean;
  shouldShowListLoadingIndicatorWhenLoading: boolean;
  shouldShowMobileDisclaimerFairHousingNY: boolean;
  /* Whether we should change the route to show the PDP modal on property select */
  shouldShowPDPModalOnPropertySelect: boolean;
  shouldShowSidebarDisclaimerFairHousingNY: boolean;
  shouldRenderMap: boolean;
  sidebarProperties: PropertyListProperty[];
  sidebarPropertiesWithAdCard: PropertyListWithAdCard[];
  sidebarPropertiesCursor: string | null;
  /* The field by which to sort the sidebar properties */
  sortField: SearchListSortField | null;
  /* Whether to sort in ascending or descending order */
  sortOrder: SearchListSortOrder | null;
  temporaryMarker: SubjectMapMarker | null;
  totalPropertiesAvailable: number | null;
  useConstrainedToPlaceNoResultsSidebarMessage: boolean;
  /* Whether to use the top-left "floating" heatmap groups control or the middle-bottom control */
  useFloatingMapLayerGroupsControl: boolean;
  zoomWhenPositioningToPoint?: number;
  handleClickAutoCompleteSearchButton: () => void;
  reportHeatmapClick: () => void;
  handleSelectCurrentLocation: () => void;
  handleSearchUpdateTextBox: (searchText: string, reportEvent: boolean) => void;
  isSmallScreen: boolean;
  handleSaveSearch: (location: 'map') => void;
  isFourHundredPercentZoom?: boolean;
};

type State = {
  /* placement of mobile control buttons */
  hasAlternateMobileControlPosition: boolean;
  invalidateSizeTrigger: TriggerProp;
  isListPriceFilterPopoverOpen: boolean;
  isBedsFilterPopoverOpen: boolean;
  isPropertyTypeFilterPopoverOpen: boolean;
  isTooLowZoom: boolean;
  isSchoolsLayerEnabled: boolean;
  isShowingMarkers: boolean;
  /* Sidebar property to scroll if present in sidebar */
  sidebarPropertySlugToScroll: string | null;
  indexOfLastPropertyInViewport: number | null;
  isShowingSidebarForConstrainedPlace: boolean;
  scrollToTopTrigger: TriggerProp;
};

function getSortDisplayText(
  sortField: SearchListSortField,
  sortOrder: SearchListSortOrder
): string | null {
  const selectedSortDef = SEARCH_LIST_SORT_OPTIONS.find(
    (def) => def.field === sortField && def.order === sortOrder
  );
  return selectedSortDef ? selectedSortDef.labelAbbr : null;
}

/*
 * Contains map, property card container, search, and filters
 */
export default class SearchPageMap extends Component<
  SearchMapPageProps,
  State
> {
  constructor(props: SearchMapPageProps) {
    super(props);
    /* Fetch watchlist to show which properties are currently in watchlist */
    if (props.isLoggedIn) {
      props.getWatchListData();
    }
    /* This handles the case of a user slowly zooming the map inward via pinching on a mobile device. Our primary
     * handler for map position change is bound to map `moveend`, so without this we wouldn't load properties
     * when slowly pinch-zooming */
    this.throttledHandlePositionChangeOnMapZoom = throttle(
      this.handlePositionChangeOnMapZoom,
      300,
      /* Don't need trailing execution since `handleMoveEnd` will cover this */
      { leading: false, trailing: false }
    );
    this.debouncedCancelPendingPromisesOnMapMove = debounce(
      props.handleCancelPendingPropertyQueries,
      100,
      { leading: true, trailing: false }
    );
    this.debouncedHandlePropertyCardHover = debounce(
      this.localHandlePropertyCardHover,
      200,
      { leading: true, trailing: true }
    );
  }

  mapApi: Api | null = null;
  mapboxMap: Map | null = null;
  passedMinSupportedZoom: boolean = false;
  /* We don't always want the URL lat,lng to change and the sidebar properties to update
   * when the map changes position */
  allowHandleMapPositionChange: boolean = true;
  /* Disable property card hover actions during scrolling of the sidebar. Re-enable them
   * after scrolling stops */
  allowPropertyCardHoverAction: boolean = false;
  lastHoveredItem: PropertyListProperty | null = null;
  sidebarResizeTriggerTimeout: undefined | number = undefined;
  allowPropertyCardHoverActionTimeout: undefined | number = undefined;
  allowHandleMapMoveTimeout: undefined | number = undefined;
  invalidateMapSizeAfterMountTimeout: undefined | number = undefined;
  resizableContainerNode: HTMLElement | null = null;
  /* Denote when map is changing location due to `props.setMapLocation` changing vs.
   * map changing location due to user interaction */
  mapIsChangingLocationProgrammatically: boolean = true;
  /* We don't want to reload properties after certain map `moveend` events */
  shouldReloadPropertiesOnNextMapMoveEnd: boolean = false;
  shouldUpdateUrlParamsOnNextMapMoveEnd: boolean = false;
  throttledHandlePositionChangeOnMapZoom:
    | null
    | ((() => void) & { cancel: () => void }) = null;
  debouncedCancelPendingPromisesOnMapMove:
    | null
    | ((() => void) & { cancel: () => void }) = null;
  debouncedHandlePropertyCardHover:
    | null
    | (((property: PropertyListProperty, isHovered: boolean) => void) & {
        cancel: () => void;
      }) = null;
  shouldFetchNewMapPropertiesDuringZoom: boolean = true;
  /* Used for analytics */
  currentZoom: number = 0;
  /* Used for analytics */
  isLayerActive: boolean = false;
  hasInitialMapMoveCompleted: boolean = false;

  state: State = {
    isListPriceFilterPopoverOpen: false,
    isBedsFilterPopoverOpen: false,
    isPropertyTypeFilterPopoverOpen: false,
    isTooLowZoom: false,
    /* Sidebar property to scroll if present in sidebar */
    sidebarPropertySlugToScroll: null,
    isShowingMarkers: true,
    invalidateSizeTrigger: null,
    indexOfLastPropertyInViewport: null,
    /* placement of mobile control buttons */
    hasAlternateMobileControlPosition: false,
    isSchoolsLayerEnabled: false,
    isShowingSidebarForConstrainedPlace: true,
    scrollToTopTrigger: null,
  };

  componentDidUpdate(prevProps: SearchMapPageProps) {
    /* When zooming in 400%, determine if we redirect to the list view */
    this.handleRedirect();
    /* When logging in/signing up FROM the search page, fetch watchlist after authenticating */
    if (!prevProps.isLoggedIn && this.props.isLoggedIn) {
      this.props.getWatchListData();
    }
    /* Trigger reloading of the map tile properties and the sidebar properties after search input has been cleared */
    if (
      !this.props.placeGeoJSONDescription &&
      prevProps.placeGeoJSONDescription &&
      this.mapApi
    ) {
      this.handleMoveEnd(this.mapApi, null, {
        activeLayerMetric: null,
        showSidebarLoadingIndicator: true,
        isMapInitializing: false,
      });
    }
    if (
      this.props.invalidateMapSizeTrigger !== prevProps.invalidateMapSizeTrigger
    ) {
      /* Some mobile browsers change the window height when the keyboard is open and need
       * the map to readjust its size after it's closed */
      this.setState({
        invalidateSizeTrigger: this.props.invalidateMapSizeTrigger,
      });
    }
    /* Prevent URL params from updating after map move when we're navigating to a city route (currently
     * only possible via clicking a footer city link) */
    if (
      this.props.placeBoundaryUpdateTrigger &&
      !prevProps.placeBoundaryUpdateTrigger &&
      this.props.shouldMaintainUrlWhenUpdatingPlaceBoundary
    ) {
      this.shouldUpdateUrlParamsOnNextMapMoveEnd = false;
    }

    /* If the feature flag is enabled, whenever the sidebar finishes loading a new set of properties,
     * hide the sidebar if constrained to a place AND no properties are found */
    if (
      this.props.shouldHideSidebarForConstrainedPlaceWithNoResults &&
      this.props.isShowingSidebarForScreenSize &&
      prevProps.isPropertyListInitOrLoadingStatus &&
      !this.props.isPropertyListInitOrLoadingStatus
    ) {
      const isShowingSidebarForConstrainedPlace =
        !this.props.isConstrainedToPlaceWithLimitedCoverage ||
        (this.props.isConstrainedToPlaceWithLimitedCoverage &&
          this.props.sidebarProperties.length > 0);

      this.setState({
        isShowingSidebarForConstrainedPlace,
        invalidateSizeTrigger: `${this.state.invalidateSizeTrigger}sidebar${
          isShowingSidebarForConstrainedPlace ? 'shown' : 'hidden'
        }`,
      });
    }
  }

  componentDidMount() {
    /* If user is zoomed in 400%, determine if we should redirect to the list view */
    this.handleRedirect();
  }

  debounceRefocusElement = debounce((elementId: string) => {
    (document.getElementById(elementId) as HTMLElement)?.focus()
  }, 300)

  getSnapshotBeforeUpdate(prevProps: SearchMapPageProps) {
    if (
      !isEqual(this.props.setMapLocation, prevProps.setMapLocation) &&
      this.props.setMapLocation
    ) {
      this.mapIsChangingLocationProgrammatically = true;
    }
    return null;
  }

  componentWillUnmount() {
    window.clearTimeout(this.sidebarResizeTriggerTimeout);
    window.clearTimeout(this.allowHandleMapMoveTimeout);
    window.clearTimeout(this.invalidateMapSizeAfterMountTimeout);
    if (this.throttledHandlePositionChangeOnMapZoom) {
      this.throttledHandlePositionChangeOnMapZoom.cancel();
    }
  }

  dropdownLabelUid = `dropdown-label-${generateGenericUid()}`;

  handleRedirect() {
    const pageView = new URLSearchParams(window.location.search).get('view');
    const { isFourHundredPercentZoom, handleRouteChange } = this.props;
    if (isFourHundredPercentZoom && pageView === 'map') {
      handleRouteChange(View.SEARCH, {
        [SEARCH_ACTIVE_VIEW_URL_PARAM_KEY]: 'list',
      });
    }
  }

  handleClickZoomIn = (api: Api) => {
    this.handleZoomButtonClick(api, 1);
  };
  handleClickZoomOut = (api: Api) => {
    this.handleZoomButtonClick(api, -1);
  };

  /**
   * Given that we know the end zoom level after clicking a zoom control, fetch properties
   * before zoom starts rather than on move end
   */
  handleZoomButtonClick = (api: Api, zoomDifferential: 1 | -1): void => {
    const map = api.getMap();

    if (map) {
      this.handleZoomByIncrement(api, map.getCenter(), zoomDifferential);
    }
  };

  /**
   * Given that we know the end zoom level that we want to reach, fetch properties before
   * zoom starts rather than on move end
   */
  handleZoomByIncrement = (
    api: Api,
    center: LatLngObject,
    zoomDifferential: 1 | -1
  ): void => {
    const map = api.getMap();
    const { lat, lng } = center;

    if (!map) {
      throw new Error(
        'Attempting to zoomByIncrement when the map is unavailable'
      );
    }
    const zoom = map.getZoom() + zoomDifferential;
    const { handleMapPositionChange, isShowingSidebarForScreenSize } =
      this.props;
    const { width, height } = map.getCanvas().getBoundingClientRect();
    const bounds = getBoundsFromCenterPointAndZoom([lat, lng], zoom, {
      width,
      height,
    });

    handleMapPositionChange({
      bounds: getMapboxGLBoundsForBounds(bounds),
      zoom,
      allowUpdatingUrlParams: true,
      shouldReloadSidebarProperties: isShowingSidebarForScreenSize,
      showSidebarLoadingIndicator: false,
      shouldResetPropertyListState: false,
    });
    /* Since we're reloading properties here we don't want to do so again on `moveend` */
    this.shouldReloadPropertiesOnNextMapMoveEnd = false;
    this.shouldUpdateUrlParamsOnNextMapMoveEnd = false;
    this.shouldFetchNewMapPropertiesDuringZoom = false;
  };

  handleZoom = (api: Api): void => {
    const map = api.getMap();
    this.mapboxMap = map;
    if (this.throttledHandlePositionChangeOnMapZoom) {
      this.throttledHandlePositionChangeOnMapZoom();
    }
  };

  handleZoomStart = (api: Api): void => {
    const map = api.getMap();
    if (map) {
      /* Used for analytics */
      this.currentZoom = map.getZoom();
    }
  };

  handleZoomEnd = (api: Api): void => {
    const map = api.getMap();
    const zoom = map && map.getZoom();

    /* Since this event is triggered when fitting multiple markers in the map on
     * map init, ensure it's only handled when zoom done by user */
    if (!this.mapIsChangingLocationProgrammatically) {
      this.props.handleClearCurrentSavedSearch();
    }
    if (!this.mapIsChangingLocationProgrammatically && zoom) {
      this.props.handleMapZoom(this.isLayerActive, zoom > this.currentZoom);
    }
  };

  /* Fetch new map properties during a zoom, throttled above */
  handlePositionChangeOnMapZoom = (): void => {
    const { handleFetchMapProperties, shouldAllowHandlingMapPositionChange } =
      this.props;
    if (
      this.mapboxMap &&
      this.shouldFetchNewMapPropertiesDuringZoom &&
      shouldAllowHandlingMapPositionChange
    ) {
      handleFetchMapProperties({
        bounds: this.mapboxMap.getBounds(),
        zoom: this.mapboxMap.getZoom(),
      });
    }
  };

  handleAPIClusterMarkerClick = (
    e: React.MouseEvent,
    api: Api,
    featureProps: APIClusterMapMarker
  ) => {
    const { activeMultiUnitProperties } = this.props;

    if (featureProps) {
      this.handleZoomByIncrement(
        api,
        { lat: featureProps.lat, lng: featureProps.lng },
        1
      );
      if (activeMultiUnitProperties) {
        this.handleCloseMultiSelectModal();
      }
    }
  };

  handleMapClick = (api: Api, e: MapMouseEvent): void => {
    const {
      isPDPModalActive,
      handleHidePDPModal,
      isShowingMapBottomLayerGroupsList,
      useConstrainedToPlaceNoResultsSidebarMessage,
      placeBoundaryGeoJSON,
      activeMultiUnitProperties,
    } = this.props;

    if (isPDPModalActive) {
      handleHidePDPModal();
    }
    if (activeMultiUnitProperties) {
      this.handleCloseMultiSelectModal();
    }
    if (isShowingMapBottomLayerGroupsList) {
      this.handleCloseMapBottomGroupsList();
    }
    /* Hide place boundary after clicking the map either when clicking outside of the boundary
     * geoJSON or when the geoJSON isn't a typical polygon shape */
    if (useConstrainedToPlaceNoResultsSidebarMessage) {
      const point = [e.lngLat.lng, e.lngLat.lat] as [number, number];
      if (
        (placeBoundaryGeoJSON &&
          ['MultiPolygon', 'Polygon'].indexOf(placeBoundaryGeoJSON.type) ===
            -1) ||
        !placeBoundaryGeoJSON ||
        !getIsPointInsideGeoJSON(placeBoundaryGeoJSON, point)
      ) {
        this.handleClearPopulatedInput();
      }
    }
  };

  handleMoveStart = (): void => {
    /* Mapbox fires the `movestart` event continuously when zooming via the touchpad.
     * We only want this functionality executed once on movestart */
    if (
      this.hasInitialMapMoveCompleted &&
      this.debouncedCancelPendingPromisesOnMapMove
    ) {
      this.debouncedCancelPendingPromisesOnMapMove();
    }
  };

  /* Handle map move end, triggering the loading of map properties and sidebar properties (when sidebar
   * is shown). Note: this does NOT fire on map init, so we're triggering this method manually in
   * `handleMapBackgroundLoaded` to load properties on init */
  handleMoveEnd = (
    api: Api,
    e: MapMouseEvent | null,
    {
      activeLayerMetric,
      showSidebarLoadingIndicator,
      isMapInitializing,
    }: {
      activeLayerMetric: LayerMetric | null;
      showSidebarLoadingIndicator?: boolean;
      isMapInitializing?: boolean;
    }
  ): void => {
    const {
      sidebarProperties,
      handleClearCurrentSavedSearch,
      handleMapPositionChange,
      handleGetMapLayerLegendBreaks,
      handleFetchMapProperties,
      shouldAllowHandlingMapPositionChange,
      isShowingSearchList,
      isShowingSidebarForScreenSize,
      handleMapMoveAfterInitialized,
      hasSeparateListPage,
      markerFeaturesMinZoom,
    } = this.props;
    const map = api.getMap();

    if (!map) {
      return;
    }
    const zoom = map.getZoom();

    /* A programmatic resize occurs when we want Mapbox to redraw the map baselayer to fill the entire
     * map container after the container size changes.  This happens in response to `invalidateSizeTrigger`
     * changing.  This should not cause any downstream effects */
    if (e && (e as any).isProgrammaticResize) {
      return;
    }

    /* Display/remove zoom notification */
    if (zoom < markerFeaturesMinZoom) {
      this.setState({ isTooLowZoom: true });
    } else if (this.state.isTooLowZoom) {
      this.setState({ isTooLowZoom: false });
    }

    if (
      this.allowHandleMapPositionChange &&
      shouldAllowHandlingMapPositionChange &&
      map
    ) {
      const bounds = map.getBounds();
      /* If map position is changed as a result of user panning or zooming the map */
      if (!this.mapIsChangingLocationProgrammatically) {
        handleClearCurrentSavedSearch();
      }
      /* Reload properties in all situations except for:
        - On first map move (occurs on component mount), when we already have properties
          returned server-side
        - Whenever the user clicks the zoom in or out buttons (in this case, we execute
          this functionality immediately after button click) */
      let shouldReloadSidebarProperties =
        (this.shouldReloadPropertiesOnNextMapMoveEnd ||
          sidebarProperties.length === 0) &&
        isShowingSidebarForScreenSize;
      /* Fetch new map tile properties */
      handleFetchMapProperties({ bounds, zoom });
      /* Update URL params and fetch new sidebar properties */
      handleMapPositionChange({
        bounds,
        zoom,
        allowUpdatingUrlParams: this.shouldUpdateUrlParamsOnNextMapMoveEnd,
        shouldReloadSidebarProperties,
        showSidebarLoadingIndicator: showSidebarLoadingIndicator,
        /* This is only true if we're viewing only the map on a small screen */
        shouldResetPropertyListState:
          hasSeparateListPage && !isShowingSearchList,
      });
      if (activeLayerMetric) {
        handleGetMapLayerLegendBreaks(bounds, zoom, activeLayerMetric);
      }
      if (!isMapInitializing) {
        handleMapMoveAfterInitialized(bounds);
      }
    }
    if (this.mapIsChangingLocationProgrammatically) {
      this.mapIsChangingLocationProgrammatically = false;
    } else {
      this.props.handleMapDrag(this.isLayerActive);
    }
    if (!this.shouldReloadPropertiesOnNextMapMoveEnd) {
      this.shouldReloadPropertiesOnNextMapMoveEnd = true;
    }
    if (!this.shouldFetchNewMapPropertiesDuringZoom) {
      this.shouldFetchNewMapPropertiesDuringZoom = true;
    }
    if (!this.shouldUpdateUrlParamsOnNextMapMoveEnd) {
      this.shouldUpdateUrlParamsOnNextMapMoveEnd = true;
    }
    this.hasInitialMapMoveCompleted = true;
  };

  handleMapBackgroundLoaded = (api: Api): void => {
    /* The `moveend` event does NOT fire on map init, so using this method, bound to the `load` event,
     * to trigger similar map and sidebar property loading functionality on init */
    this.handleMoveEnd(api, null, {
      activeLayerMetric: null,
      showSidebarLoadingIndicator: true,
      isMapInitializing: true,
    });
    /* Needed for calling `handleMoveEnd` after clearing address search input */
    this.mapApi = api;
  };

  handleSidebarResize = (width: number): void => {
    this.temporarilyDisableHandleMapPositionChange();
    this.setState({ invalidateSizeTrigger: width });
  };

  handlePropertyCardEntranceAnimationComplete = (): void => {
    this.setState({ invalidateSizeTrigger: Math.random() });
  };

  /* Temporarily prevent a map position change from updating the URL params and reloading
   * sidebar properties. Useful when we want an action that moves the map to not affect
   * the positioning of the sidebar properties. */
  temporarilyDisableHandleMapPositionChange = (delay: number = 300): void => {
    if (this.allowHandleMapPositionChange) {
      this.allowHandleMapPositionChange = false;
    } else {
      window.clearTimeout(this.allowHandleMapMoveTimeout);
    }

    this.allowHandleMapMoveTimeout = window.setTimeout(() => {
      this.allowHandleMapPositionChange = true;
    }, delay);
  };

  /**
   * Returns number of sidebar cards per row on desktop, returns 0 when on a mobile device
   * where sidebar is hidden
   */
  getSidebarCardsPerRow = (): number => {
    return this.resizableContainerNode
      ? Math.floor(
          this.resizableContainerNode.getBoundingClientRect().width /
            cardWidthInt
        )
      : 0;
  };

  handleSidebarScroll = (): void => {
    this.allowPropertyCardHoverAction = false;

    window.clearTimeout(this.allowPropertyCardHoverActionTimeout);
    this.allowPropertyCardHoverActionTimeout = window.setTimeout(() => {
      this.allowPropertyCardHoverAction = true;
    }, 10);

    // onMouseLeave events are not fired when scrolling out of the mouseover area.
    // this ensures that the property highlighting gets removed if a user scrolls
    // while hovering on the property card:
    if (this.lastHoveredItem) {
      this.props.handlePropertyCardHover({
        property: this.lastHoveredItem,
        isHovered: false,
        isMarkerOnMap: false,
      });
    }
  };

  localHandlePropertyCardHover = (
    property: PropertyListProperty,
    isHovered: boolean
  ): void => {
    const map = this.mapApi && this.mapApi.getMap();
    const { markerFeatures } = this.props;
    /* Cache the last item hovered so that the scroll handler can remove the hover state when scrolling */
    this.lastHoveredItem = property;

    if (this.allowPropertyCardHoverAction && map) {
      const isMarkerOnMap = markerFeatures.some((feature) => {
        const marker = feature.properties;
        return isPropertyMarker(marker) && marker.addressSlug === property.slug;
      });
      this.props.handlePropertyCardHover({
        property,
        isHovered,
        isMarkerOnMap,
      });
    }
  };

  handleMarkerFeatureClick = (
    e: MapMouseEvent,
    api: Api,
    featureProps: PropertyMapMarker
  ): void => {
    const {
      handleReportMarkerClick,
      isShowingSidebarForScreenSize,
      shouldShowPDPModalOnPropertySelect,
      handleShowPDPModal,
      handleFetchSelectedPropertySidebarData,
      activeMultiUnitProperties,
      shouldCenterMapMarkerOnClick,
    } = this.props;

    if (!featureProps) {
      return;
    }

    handleReportMarkerClick(featureProps.addressSlug);
    /* Scroll to the property in the sidebar on desktop */
    if (isShowingSidebarForScreenSize) {
      this.setState({ sidebarPropertySlugToScroll: featureProps.addressSlug });
      handleFetchSelectedPropertySidebarData(featureProps.addressSlug);
    }
    /* Open PDP modal on mobile */
    if (shouldShowPDPModalOnPropertySelect) {
      handleShowPDPModal(featureProps);
    }
    if (activeMultiUnitProperties) {
      this.handleCloseMultiSelectModal();
    }
    if (shouldCenterMapMarkerOnClick) {
      /* Prevent the map moving as a result of clicking a map marker from hiding the PDP modal */
      this.temporarilyDisableHandleMapPositionChange(
        MAP_CENTER_PIN_ANIMATION_DURATION
      );
      this.handleCenterPropertyLatLng(e, api, {
        latitude: featureProps.lat,
        longitude: featureProps.lng,
      });
    } else {
      /* Prevent the map moving as a result of opening a popup from changing URL params or sidebar properties */
      this.temporarilyDisableHandleMapPositionChange(
        MAP_FIT_POPUP_ANIMATION_DURATION
      );
    }
  };

  handleMarkerFeatureHover = (
    api: Api,
    feature: {
      properties: MultiUnitMapMarker | APIClusterMapMarker | PropertyMapMarker;
    }
  ): void => {
    const map = api.getMap();
    const { isSchoolsLayerEnabled } = this.state;
    const featureProps = feature.properties;

    if (!isSchoolsLayerEnabled) {
      return;
    }
    if (!featureProps) {
      this.props.highlightSchoolMarkers({
        addressSlug: null,
        gaiaSchoolsById: null,
      });
    } else if (
      'isProperty' in featureProps ||
      'isMultiUnitCluster' in featureProps
    ) {
      let addressSlug: string | null = null;

      if ('isProperty' in featureProps) {
        addressSlug = featureProps.addressSlug;
      } else if ('isMultiUnitCluster' in featureProps) {
        addressSlug = featureProps.childAddressSlugs[0];
      }

      if (addressSlug && map) {
        const gaiaSchoolsById = keyBy(
          map
            .querySourceFeatures(
              `${MAPBOX_LAYER_IDS.SCHOOL_SYMBOLS}-geojson-source`
            )
            .map((feature) => ({
              id: featureProps.uId,
              location: {
                latitude: featureProps.lat,
                longitude: featureProps.lng,
              },
            })),
          'id'
        );
        this.props.highlightSchoolMarkers({
          addressSlug,
          gaiaSchoolsById,
        });
      }
    }
  };

  handleMultiUnitMarkerClick = (
    e: MapMouseEvent,
    api: Api,
    featureProps: MultiUnitMapMarker
  ): void => {
    const {
      multiUnitMarkersByLatLng,
      handleSetActiveMultiUnitProperties,
      handleSetMultiUnitPropertyData,
      handleHidePDPModal,
      isPDPModalActive,
      shouldCenterMapMarkerOnClick,
    } = this.props;
    const { lat, lng } = featureProps;
    const activeMultiUnitProperties = multiUnitMarkersByLatLng
      ? multiUnitMarkersByLatLng[
          getMultiUnitClusterKey({ latitude: lat, longitude: lng })
        ]
      : null;

    if (isPDPModalActive) {
      handleHidePDPModal();
    }
    handleSetMultiUnitPropertyData(activeMultiUnitProperties);
    handleSetActiveMultiUnitProperties(activeMultiUnitProperties);

    if (shouldCenterMapMarkerOnClick) {
      this.handleCenterPropertyLatLng(e, api, {
        latitude: lat,
        longitude: lng,
      });
    }
  };

  handleOpenMultiUnitPropertyInPDPModal = (
    featureProps: PropertyMapMarker
  ): void => {
    const { handleShowPDPModal } = this.props;
    this.handleCloseMultiSelectModal();
    handleShowPDPModal(featureProps);
  };

  handleGoToMultiUnitPropertyPDPPage = (
    featureProps: PropertyMapMarker
  ): void => {
    const { handleNavigateToPDP } = this.props;
    this.handleCloseMultiSelectModal();

    if (featureProps !== null && 'addressSlug' in featureProps) {
      handleNavigateToPDP(featureProps.normalizedPropertyData);
    }
  };

  handleActiveLayerChange = (
    metric: LayerMetric | null,
    center: LngLat,
    zoom: number
  ): void => {
    this.props.handleReportShowingMapBottomLayerControl(!!metric);
    this.setState({
      hasAlternateMobileControlPosition: !!metric,
      isSchoolsLayerEnabled: !!metric && metric.indexOf('schools') > -1,
    });
    this.isLayerActive = !!metric;
  };

  handleAutoCompleteResultClick = (): void => {
    this.setState({ isTooLowZoom: false });
    this.debounceRefocusElement(AUTOCOMPLETE_SUBMIT_BUTTON_ID)
  };

  handleSidebarPropertyClick = (slug: string): void => {
    const property = this.props.sidebarPropertiesWithAdCard.find(
      (property): property is PropertyListProperty => {
        return getIsPropertyCardItem(property) && property.slug === slug;
      }
    );

    if (property) {
      this.props.handleReportSidebarPropertyClick();
      this.props.handleNavigateToPDP(property);
    } else {
      throw new Error(
        'Property not found for sidebar property click with slug ' + slug
      );
    }
  };

  handleMapPropertyClick = (
    normalizedPropertyData: NormalizedProperty
  ): void => {
    this.props.handleReportMapPropertyClick();
    this.props.handleNavigateToPDP(normalizedPropertyData);
  };

  handleMarkerFeaturePopupRemove = (): void => {
    this.setState({ sidebarPropertySlugToScroll: null });
  };

  resizableContainerRef = (ele: any): void => {
    /* We know that the ResizableContainer component's rendered element can't be of type Text */
    const node = ele && (findDOMNode(ele) as Element | null);
    if (node) {
      this.resizableContainerNode = node;
    }
  };

  handleSaveSearch = () => {
    this.props.handleSaveSearch('map');
  };

  handleToggleMarkers = (api: Api, shouldShow: boolean): void => {
    const map = api.getMap();
    if (map) {
      const { handleToggleMarkers } = this.props;
      handleToggleMarkers(shouldShow, map.getBounds(), map.getZoom());
    }
  };

  preloadPropertyCardPhotos = (slugsInViewport: string[]): void => {
    const lastSlugInViewport = slugsInViewport.slice(-1)[0];
    const propertySlugs = this.props.sidebarProperties.map(
      (property) => property.slug
    );
    const indexOfLastPropertyInViewport =
      propertySlugs.indexOf(lastSlugInViewport);

    if (indexOfLastPropertyInViewport > -1) {
      this.setState({
        indexOfLastPropertyInViewport: indexOfLastPropertyInViewport,
      });
    }
  };

  handleClearPopulatedInput = (): void => {
    const {
      handleClearConstrainedToPlace,
      shouldClearConstrainedPlaceOnClearingSearch,
    } = this.props;
    /* Clear the place boundary on the map and send subsequent property requests without a
     * place constraint */
    if (shouldClearConstrainedPlaceOnClearingSearch) {
      handleClearConstrainedToPlace();
    }
  };

  handleCloseMultiSelectModal = (): void => {
    this.props.handleSetActiveMultiUnitProperties(null);
  };

  handleOpenMapBottomGroupsControl = (): void => {
    const {
      handleToggleMapBottomLayerGroupsList,
      handleGetMapLayerLegendBreaks,
      reportHeatmapClick,
    } = this.props;
    const map = this.mapApi && this.mapApi.getMap();

    reportHeatmapClick();
    handleToggleMapBottomLayerGroupsList(true);

    if (map) {
      handleGetMapLayerLegendBreaks(map.getBounds(), map.getZoom());
    }
  };

  handleCloseMapBottomGroupsList = (): void => {
    this.props.handleToggleMapBottomLayerGroupsList(false);
  };

  handleCenterPropertyLatLng = (
    e: MapMouseEvent,
    api: Api,
    latLng: LatitudeLongitudeObject
  ): void => {
    const map = api.getMap();
    /* Mapbox MapMouseEvent does indeed have a clientY property.  Asserting as such */
    const pixelsFromScreenBottom =
      window.innerHeight - (e as MapMouseEvent & { clientY: number }).clientY;

    /* If property is in the area to be covered by the PDP modal, center the pin in
     * the upper portion of the map */
    if (
      map &&
      pixelsFromScreenBottom <
        cardHeightInt + 25 /* space between card and screen bottom */
    ) {
      map.flyTo({
        center: { lat: latLng.latitude, lng: latLng.longitude },
        zoom: map.getZoom(),
        offset: new Point(0, -100),
        duration: MAP_CENTER_PIN_ANIMATION_DURATION - 100,
      });
    }
  };

  handleChangeSort = (
    field: SearchListSortField,
    order: SearchListSortOrder
  ): void => {
    const { handleChangeSort } = this.props;
    this.setState({
      scrollToTopTrigger: `${Date.now()}`,
    });
    handleChangeSort(field, order);
  };

  handlePropertyCardMouseEnter = (
    e: React.MouseEvent,
    addressSlug: string
  ): void => {
    const { sidebarProperties } = this.props;
    const property = sidebarProperties.find((o) => o.slug === addressSlug);

    if (property && this.debouncedHandlePropertyCardHover) {
      this.debouncedHandlePropertyCardHover(property, true);
    }
  };

  handlePropertyCardMouseLeave = (
    e: React.MouseEvent,
    addressSlug: string
  ): void => {
    const { sidebarProperties } = this.props;
    const property = sidebarProperties.find((o) => o.slug === addressSlug);

    if (property && this.debouncedHandlePropertyCardHover) {
      this.debouncedHandlePropertyCardHover(property, false);
    }
  };

  render() {
    const {
      activeFilterCount,
      activeMultiUnitProperties,
      allMarkerAreClusters,
      allowShowingMobileButtons,
      areDefaultFiltersChanged,
      canLoadMoreSidebarProperties,
      disableSidebarDragging,
      fitPopupPadding,
      handleClickAutoCompleteSearchButton,
      handleLoadMoreSidebarProperties,
      handleReportPropertyUnwatchClick,
      handleReportPropertyWatchClick,
      handleReportPropertyUnwatchConfirmClick,
      handleReportSearchInputFocus,
      handleSelectCurrentLocation,
      handleSetMLSLayerFeatures,
      handleToggleMobileFilters,
      handleSearchUpdateTextBox,
      hasSavedCurrentSearch,
      isFourHundredPercentZoom,
      isLoadingMoreSidebarProperties,
      isMLSCoverageIncompleteForMapArea,
      isSmallScreen,
      isPDPModalActive,
      isPropertyListErrorStatus,
      isPropertyListInitOrLoadingStatus,
      isScaffoldingCMGMarketingCardDataForSRP,
      isSavingCurrentSearch,
      isShowingMapBottomLayerGroupsList,
      isShowingMapZoomControls,
      isShowingMarkers,
      isShowingMLSCoverageLayer,
      isShowingMobileFilters,
      isShowingNoMLSModal,
      isShowingResultCount,
      isShowingSearchInput,
      isShowingSearchList,
      isShowingSidebarForScreenSize,
      isShowingTooLowZoomWarningOverMap,
      isSortControlDisabled,
      isUsingLegacySaveSearchButton,
      isCtaCleanupEnabled,
      isYourTeamEnabled,
      mapGeoRequestAccessToken,
      mapPulseLocations,
      markerFeatures,
      markerFeaturesChangedTrigger,
      MarkerPopup,
      maxResultCount,
      multiUnitMarkersByLatLng,
      placeBoundaryGeoJSON,
      placeBoundaryUpdateTrigger,
      placeGeoJSONDescription,
      propertyListLastUpdatedTimestamp,
      setMapLocation,
      shouldDisplayCurrentLocationSearchOption,
      shouldGetGeolocationFromDevice,
      shouldRenderMap,
      shouldShowListLoadingIndicatorWhenLoading,
      shouldShowMobileDisclaimerFairHousingNY,
      shouldShowPDPModalOnPropertySelect,
      shouldShowSidebarDisclaimerFairHousingNY,
      sidebarProperties,
      sidebarPropertiesWithAdCard,
      sortField,
      sortOrder,
      temporaryMarker,
      totalPropertiesAvailable,
      useConstrainedToPlaceNoResultsSidebarMessage,
      useFloatingMapLayerGroupsControl,
      zoomWhenPositioningToPoint,
      isCanaryUIFeatureEnabled,
      isLoggedIn,
    } = this.props;
    const {
      hasAlternateMobileControlPosition,
      invalidateSizeTrigger,
      isTooLowZoom,
      sidebarPropertySlugToScroll,
      isShowingSidebarForConstrainedPlace,
    } = this.state;

    const DisclaimerFairHousingNYComponent = (
      <DisclaimerFairHousing
        theme={theme}
        url={'https://www.dos.ny.gov/licensing/docs/FairHousingNotice_new.pdf'}
        ariaLabel={'Fair Housing Policy for New York State'}
        state={'New York'}
      />
    );
    /* The max width that we'll allow for resizing sidebar, no matter what viewport size */
    const sidebarStaticMaxWidth =
      cardWidthInt * 4 + sidebarHorizontalPaddingInt;
    /* The space that the filters section has, given our max allowed sidebar width */
    const filtersSpace = window.innerWidth - sidebarStaticMaxWidth;
    /* Reduce max allowed resize width if necessary */
    const sidebarStepReduction =
      filtersSpace < 200 ? 2 : filtersSpace < 500 ? 1 : 0;
    /* The actual max width that we'll allow for resizing sidebar so that filters will fit */
    const sidebarMaxWidth =
      sidebarStaticMaxWidth - cardWidthInt * sidebarStepReduction;
    const isMapLayerActive = hasAlternateMobileControlPosition;
    const sidebarPropertyCardsPerRow = this.getSidebarCardsPerRow();

    return (
      <section
        data-hc-name={'main-section'}
        className={theme.SearchPageMap}
        style={
          isShowingSearchList
            ? { visibility: 'hidden' }
            : isFourHundredPercentZoom
            ? { position: 'static' }
            : {}
        }
        aria-hidden={isShowingSearchList ? true : false}
      >
        <div className={theme.TopBar} data-hc-name={'toolbar'}>
          <div
            className={theme.TopBarRowAbove}
            style={{
              justifyContent: isUsingLegacySaveSearchButton
                ? 'space-between'
                : 'flex-start',
            }}
          >
            {isShowingSearchInput && (
              <AutoCompleteSearchContainer
                submitButtonId={AUTOCOMPLETE_SUBMIT_BUTTON_ID}
                dataHcName={'search-field'}
                onResultClick={this.handleAutoCompleteResultClick}
                theme={theme}
                prefilledUserInput={placeGeoJSONDescription}
                handleClearPopulatedInput={this.handleClearPopulatedInput}
                handleInputFocus={handleReportSearchInputFocus}
                handleClickSearchButton={handleClickAutoCompleteSearchButton}
                shouldDisplayCurrentLocationOption={
                  shouldDisplayCurrentLocationSearchOption
                }
                clearAndCloseWithOneClick
                marginFromPageBottom={80}
                onInputChangeCallback={handleSearchUpdateTextBox}
              />
            )}
            <SearchTopBarFiltersContainer
              handleSaveSearch={this.handleSaveSearch}
            />
          </div>
        </div>
        <div className={theme.MainContent}>
          <h1>Search Homes</h1>
          {!isShowingSidebarForScreenSize &&
            shouldShowMobileDisclaimerFairHousingNY && (
              <div className={theme.MobileDisclaimerFairHousingWrapper}>
                {DisclaimerFairHousingNYComponent}
              </div>
            )}
          <div className={theme.MapSection} data-hc-name={'map-section'}>
            {shouldRenderMap && (
              <Maps
                showPropertiesDefaultOn
                allowScrollZoom
                attributionLocation={
                  isCtaCleanupEnabled && isSmallScreen
                    ? 'bottom-left'
                    : 'bottom-right'
                }
                fitMarkersOnMarkerChange={false}
                shouldGetGeolocationFromDevice={shouldGetGeolocationFromDevice}
                handleActiveLayerChange={this.handleActiveLayerChange}
                handleMapClick={this.handleMapClick}
                handleMarkerFeatureClick={this.handleMarkerFeatureClick}
                handleMarkerFeaturePopupClose={
                  this.handleMarkerFeaturePopupRemove
                }
                /* Disabling this for now due to it not working well in combination with the MLSCoverage
                 * map layer (which inappropriately responds to the map data change enacted by pulsing
                 * the school pins). Will investigate further and re-implement if necessary */
                handleMarkerFeatureHover={undefined}
                handleMultiUnitMarkerClick={this.handleMultiUnitMarkerClick}
                handleZoom={this.handleZoom}
                handleZoomStart={this.handleZoomStart}
                handleMoveStart={this.handleMoveStart}
                handleMoveEnd={this.handleMoveEnd}
                handleMapBackgroundLoaded={this.handleMapBackgroundLoaded}
                handlePropertyClick={this.handleMapPropertyClick}
                handleZoomEnd={this.handleZoomEnd}
                handleToggleMarkers={this.handleToggleMarkers}
                handleClickZoomIn={this.handleClickZoomIn}
                handleClickZoomOut={this.handleClickZoomOut}
                handleAPIClusterMarkerClick={this.handleAPIClusterMarkerClick}
                multiUnitMarkersByLatLng={multiUnitMarkersByLatLng}
                hasMapLayersControl
                fitPopupPadding={fitPopupPadding}
                zoomWhenPositioningToPoint={zoomWhenPositioningToPoint}
                invalidateSizeTrigger={invalidateSizeTrigger}
                markerFeatures={markerFeatures}
                markerFeaturesChangedTrigger={markerFeaturesChangedTrigger}
                {...(temporaryMarker
                  ? { temporaryMarkers: [temporaryMarker] }
                  : {})}
                pulseLocations={mapPulseLocations}
                setMapLocation={setMapLocation}
                placeBoundaryGeoJSON={placeBoundaryGeoJSON}
                placeBoundaryUpdateTrigger={placeBoundaryUpdateTrigger}
                fitBoundsOptions={MAP_FIT_BOUNDS_OPTIONS}
                showZoomControls={isShowingMapZoomControls}
                geoRequestAccessToken={mapGeoRequestAccessToken}
                MarkerPopup={MarkerPopup}
                useFloatingLayerGroupsControl={useFloatingMapLayerGroupsControl}
                isShowingBottomLayerGroupsControl={
                  isShowingMapBottomLayerGroupsList
                }
                isShowingMarkers={isShowingMarkers}
                allowShowingBottomControl={
                  !isShowingMobileFilters &&
                  !isPDPModalActive &&
                  !activeMultiUnitProperties
                }
                handleCloseBottomGroupsControl={
                  this.handleCloseMapBottomGroupsList
                }
                MapNotification={
                  isTooLowZoom &&
                  isShowingTooLowZoomWarningOverMap &&
                  !isShowingMapBottomLayerGroupsList &&
                  !isMapLayerActive ? (
                    <SearchMapNotification>
                      Please zoom in to view properties
                    </SearchMapNotification>
                  ) : (
                    /* Show MLS map notification if an area with < 30 mls coverage exists in the map
                     * and if the No MLS modal is not already showing */
                    isMLSCoverageIncompleteForMapArea &&
                    !isShowingNoMLSModal && (
                      <SearchMapNotificationNoMLSCobranded />
                    )
                  )
                }
                isShowingMLSCoverageLayer={isShowingMLSCoverageLayer}
                onMLSCoverageChange={handleSetMLSLayerFeatures}
                theme={theme}
              />
            )}
            {/* location services icon shown only on Desktop and Tablet */}
            <button
              type="button"
              aria-label="Find Current Location"
              className={theme.LocationIcon}
              onClick={handleSelectCurrentLocation}
              onKeyDown={onEnterOrSpaceKey(handleSelectCurrentLocation)}
            >
              <LocationIcon />
            </button>
            {
              <MultiUnitSelectModalContainer
                isActive={!!activeMultiUnitProperties}
                properties={activeMultiUnitProperties}
                onSelectProperty={
                  shouldShowPDPModalOnPropertySelect
                    ? this.handleOpenMultiUnitPropertyInPDPModal
                    : this.handleGoToMultiUnitPropertyPDPPage
                }
                showWatchListButton
                handleReportUnwatchClick={handleReportPropertyUnwatchClick}
                handleReportUnwatchConfirmClick={
                  handleReportPropertyUnwatchConfirmClick
                }
                handleCloseModal={this.handleCloseMultiSelectModal}
              />
            }
          </div>
          {isShowingSidebarForScreenSize &&
            isShowingSidebarForConstrainedPlace && (
              <ResizableContainer
                data-hc-name={'property-card-section'}
                theme={theme}
                allowResize={!disableSidebarDragging}
                stepWidth={cardWidthInt}
                totalPadding={sidebarHorizontalPaddingInt}
                maxWidth={sidebarMaxWidth}
                snapToIncrementAfterResize
                onResize={this.handleSidebarResize}
                ref={this.resizableContainerRef}
              >
                {sidebarPropertyCardsPerRow > 0 && (
                  <div className={theme.BrokerageWrapper}>
                    <BrokerageAttributionContainer
                      isMinimalStyling={false}
                      isVerticalAlignment={sidebarPropertyCardsPerRow < 2}
                      hideBrokerageSection
                    />
                  </div>
                )}
                <div
                  className={theme.SidebarSectionTopBar}
                  data-hc-name={'topbar'}
                >
                  <DropdownSort
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onChangeSort={this.handleChangeSort}
                    isDisabled={isSortControlDisabled}
                    theme={theme}
                    dropdownLabelId={this.dropdownLabelUid}
                    Button={
                      <button
                        data-hc-name={'sort-link'}
                        className={classNames(theme.SortButtonBorderless, {
                          [theme.SortButtonDisabled]: isSortControlDisabled,
                        })}
                      >
                        <div>
                          <SortIcon className={theme.SortButtonIcon} />
                          {sortField && sortOrder ? (
                            <span id={this.dropdownLabelUid}>
                              Sort by:{' '}
                              {getSortDisplayText(sortField, sortOrder)}
                            </span>
                          ) : (
                            <span id={this.dropdownLabelUid}>Sort</span>
                          )}
                        </div>
                      </button>
                    }
                  />
                  {isShowingResultCount &&
                    typeof totalPropertiesAvailable === 'number' && (
                      <div
                        className={theme.PropertyCount}
                        data-hc-name={'property-count'}
                      >
                        <PureAlert type="polite">
                          {getPropertyCountDisplay(
                            totalPropertiesAvailable,
                            maxResultCount
                          )}
                        </PureAlert>
                      </div>
                    )}
                  {isLoggedIn && (
                    <ConditionalFeature
                      renderIfFeaturesEnabled={['recent_user_activity']}
                    >
                      <RecentUserActivityCTA dataEventName="click_recent_activity_srp_view_search_activity" />
                    </ConditionalFeature>
                  )}
                </div>
                {allMarkerAreClusters && (
                  <div className={theme.SidebarNotification}>
                    <div className={theme.SidebarNotificationInner}>
                      <ExclamationIcon /> Please zoom in to view individual
                      properties on the map
                    </div>
                  </div>
                )}
                {shouldShowSidebarDisclaimerFairHousingNY && (
                  <div className={theme.SidebarDisclaimerFairHousingWrapper}>
                    {DisclaimerFairHousingNYComponent}
                  </div>
                )}
                <LoadingSection
                  className={theme.SidebarSectionInner}
                  isLoading={
                    isPropertyListInitOrLoadingStatus &&
                    shouldShowListLoadingIndicatorWhenLoading
                  }
                >
                  {isTooLowZoom ? (
                    <React.Fragment>
                      <NoResultsNotice
                        theme={theme}
                        emptyMessage={TOO_LOW_ZOOM_WARNING_TEXT}
                        icon={<WatchListEmptyIcon />}
                      />
                      <Footer shouldUseSectionElement />
                    </React.Fragment>
                  ) : sidebarProperties.length > 0 ? (
                    <React.Fragment>
                      <div className={theme.MinHeightWrapper}>
                        <LazilyRenderedList
                          dataHcName={'property-list'}
                          dataHcLastUpdated={propertyListLastUpdatedTimestamp}
                          className={theme.SidebarContents}
                          /* Preload cards 500px above and below the fold */
                          preloadBuffer={500}
                          scrollableAncestorClassName={
                            theme.SidebarSectionInner
                          }
                          /* Set intended dimensions of list items so that dimensions of each item
                           * container remain constant before and after child render */
                          itemDimensionsStyle={PROPERTY_CARD_DIMENSIONS}
                          llKeyField="data-ll-key"
                          updateItemsToRenderTrigger={invalidateSizeTrigger}
                          showBottomLoadingIndicator={
                            isLoadingMoreSidebarProperties
                          }
                          isMoreToLoad={false}
                          onScroll={this.handleSidebarScroll}
                          keyToScroll={sidebarPropertySlugToScroll || undefined}
                          shouldScrollToTopOnChildChange={
                            !isLoadingMoreSidebarProperties
                          }
                          shouldKeepItemsRendered
                          handleReportItemsInViewport={
                            this.preloadPropertyCardPhotos
                          }
                        >
                          {sidebarPropertiesWithAdCard.map((item, index) => {
                            if (getIsPropertyCardItem(item)) {
                              return (
                                <PropertyCard
                                  theme={theme}
                                  data-ll-key={item.slug}
                                  key={item.slug}
                                  photoSize={isSmallScreen ? 'LARGE' : 'MEDIUM'}
                                  isShowingWatchListActionButton
                                  isShowingDaysOnMarket
                                  propertyDetails={item}
                                  status={STATUSES.SUCCESS}
                                  shouldHandleCheckingForWatchListStatus={false}
                                  isAddedToWatchList={!!item.isAddedToWatchList}
                                  goToProperty={this.handleSidebarPropertyClick}
                                  onMouseEnter={
                                    this.handlePropertyCardMouseEnter
                                  }
                                  onMouseLeave={
                                    this.handlePropertyCardMouseLeave
                                  }
                                  handleReportWatchClick={
                                    handleReportPropertyWatchClick
                                  }
                                  handleReportUnwatchClick={
                                    handleReportPropertyUnwatchClick
                                  }
                                  handleReportUnwatchConfirmClick={
                                    handleReportPropertyUnwatchConfirmClick
                                  }
                                />
                              );
                            } else if (
                              isCanaryUIFeatureEnabled &&
                              isScaffoldingCMGMarketingCardDataForSRP
                            ) {
                              return (
                                <ScaffoldingMarketingCardSRP
                                  key="ScaffoldingMarketingCardSRP"
                                  data-ll-key="ScaffoldingMarketingCardSRP"
                                />
                              );
                            } else if (getIsLenderCTACard(item)) {
                              return (
                                <ImageCTAOrLenderCTA
                                  key={`ImageCTAOrLenderCTA-${index}`}
                                  data-ll-key={`ImageCTAOrLenderCTA-${index}`}
                                  area="srp"
                                  ordinal={index}
                                  theme={theme}
                                  LenderCTA={
                                    <LenderCTACardCobranded
                                      area="srp"
                                      ordinal={index}
                                      cardType={CARD_TYPES.BOTH}
                                      key={`DefaultAdCard-${index}`}
                                      data-ll-key={`DefaultAdCard-${index}`}
                                    />
                                  }
                                />
                              );
                            } else if (getIsTopAgentsRankedAd(item)) {
                              return (
                                <ConnectWithATopLocalAgent
                                  key="ConnectWithATopLocalAgent"
                                  data-ll-key="ConnectWithATopLocalAgent"
                                  theme={theme}
                                />
                              );
                            } else if (getIsLoanOfficerAd(item)) {
                              return (
                                <SearchLoanOfficerAdCobranded
                                  key="SearchLoanOfficerAd"
                                  data-ll-key="SearchLoanOfficerAd"
                                />
                              );
                            } else if (getIsFinanceAd(item)) {
                              return (
                                <SRPFinanceCTA
                                  key="SRPFinanceCTA"
                                  data-ll-key="SRPFinanceCTA"
                                />
                              );
                            } else if (getIsAdCardForCobrand(item)) {
                              return (
                                <AdCardForCobrand
                                  key="AdCardForCobrand"
                                  data-ll-key="AdCardForCobrand"
                                />
                              );
                            } else {
                              return null;
                            }
                          })}
                        </LazilyRenderedList>
                        {canLoadMoreSidebarProperties && (
                          <React.Fragment>
                            <LoadMoreResultsButton
                              handleLoadMoreClick={
                                handleLoadMoreSidebarProperties
                              }
                            />
                            <div className={theme.LoadMoreResultsBorder} />
                          </React.Fragment>
                        )}
                      </div>
                      <Footer shouldUseSectionElement />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className={theme.MinHeightWrapper}>
                        <NoResultsNotice
                          theme={theme}
                          emptyMessage={
                            isPropertyListErrorStatus
                              ? 'Please zoom in'
                              : 'No results'
                          }
                          instructionMessage={
                            isPropertyListErrorStatus ? (
                              'Or search for a new city, ZIP code or address to view properties'
                            ) : useConstrainedToPlaceNoResultsSidebarMessage ? (
                              <div>
                                You're currently searching
                                {areDefaultFiltersChanged ? ' filtered ' : ' '}
                                homes in {placeGeoJSONDescription}
                                <span
                                  className={theme.ClearSearchConstraintLink}
                                  onClick={this.handleClearPopulatedInput}
                                >
                                  Redo search here
                                </span>
                              </div>
                            ) : (
                              'Search a new region or try zooming out the map to view a wider area.'
                            )
                          }
                          icon={<WatchListEmptyIcon />}
                        />
                      </div>
                      <Footer shouldUseSectionElement />
                    </React.Fragment>
                  )}
                </LoadingSection>
              </ResizableContainer>
            )}
        </div>
        {allowShowingMobileButtons && !isUsingLegacySaveSearchButton && (
          <SaveSearchFloatingButton
            isSavingCurrentSearch={isSavingCurrentSearch}
            hasSavedCurrentSearch={hasSavedCurrentSearch}
            handleSaveSearch={this.handleSaveSearch}
          />
        )}
        {allowShowingMobileButtons &&
          !isShowingMobileFilters &&
          !isPDPModalActive &&
          !activeMultiUnitProperties &&
          !isShowingMapBottomLayerGroupsList && (
            <div
              className={classNames(theme.MobileButtonsPositioner, {
                [theme.positionedHigher]: hasAlternateMobileControlPosition,
                [theme.MobileButtonsPositionerYourTeam]: isYourTeamEnabled,
              })}
            >
              <motion.div
                initial={{ x: 51, y: 0 }}
                animate={{
                  x: 0,
                  y: 0,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                    ease: 'backInOut',
                  },
                }}
                className={theme.MobileButtons}
              >
                <CobrandedStyles>
                  {({ activeFilterCountColor }) => (
                    <>
                      {isUsingLegacySaveSearchButton && (
                        <FlatButton
                          className={classNames(
                            theme.MobileButton,
                            theme.SaveSearch,
                            { [theme.isInactive]: hasSavedCurrentSearch }
                          )}
                          theme={theme}
                          aria-label="Save"
                          onClick={() => {
                            if (!hasSavedCurrentSearch) {
                              this.handleSaveSearch();
                            }
                          }}
                          icon={
                            isSavingCurrentSearch ? (
                              <Spinner
                                className={classNames(
                                  theme.MobileButtonIcon,
                                  theme.Spinner
                                )}
                              />
                            ) : hasSavedCurrentSearch ? (
                              <HeartFilled className={theme.MobileButtonIcon} />
                            ) : (
                              <HeartOutline
                                className={theme.MobileButtonIcon}
                              />
                            )
                          }
                          label={
                            isSavingCurrentSearch
                              ? 'Saving'
                              : hasSavedCurrentSearch
                              ? 'Saved'
                              : 'Save'
                          }
                        />
                      )}
                      <FlatButton
                        onClick={() => handleToggleMobileFilters()}
                        theme={theme}
                        className={theme.MobileButton}
                        aria-label="Filters"
                        label={
                          <>
                            {activeFilterCount > 0 && (
                              <div
                                className={theme.MobileActiveFiltersCount}
                                style={{
                                  backgroundColor: activeFilterCountColor,
                                }}
                              >
                                {activeFilterCount}
                              </div>
                            )}
                            <div>Filters</div>
                          </>
                        }
                        icon={
                          !activeFilterCount ? (
                            <MobileFilterIcon
                              className={theme.MobileButtonIcon}
                            />
                          ) : undefined
                        }
                      />
                    </>
                  )}
                </CobrandedStyles>
                <FlatButton
                  onClick={() => this.handleOpenMapBottomGroupsControl()}
                  theme={theme}
                  className={theme.MobileButton}
                  label="Heatmaps"
                  aria-label="Heatmaps"
                  icon={<HeatMapsIcon className={theme.MobileButtonIcon} />}
                />
              </motion.div>
            </div>
          )}
        {shouldShowPDPModalOnPropertySelect && (
          <SearchMapPropertyCardContainer
            onEntranceAnimationComplete={
              this.handlePropertyCardEntranceAnimationComplete
            }
          />
        )}
      </section>
    );
  }
}
