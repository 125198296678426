import React from 'react';
import theme from '@client/css-modules/AccessiblePageTitle.css';
import { Alert } from '@client/utils/reach-alert';

type Props = {
  isAppMounted: boolean;
  pageTitle: string;
  isInitialView: boolean;
};

const setDocumentTitleForPage = (title: string) => {
  if (document) {
    if (document.title !== title) {
      document.title = title;
    }
  }
};

export default class AccessiblePageTitle extends React.Component<Props> {
  componentDidUpdate() {
    setDocumentTitleForPage(this.props.pageTitle);
  }

  componentDidMount() {
    setDocumentTitleForPage(this.props.pageTitle);
  }

  shouldComponentUpdate(prevProps: Props) {
    return prevProps.pageTitle !== this.props.pageTitle;
  }

  render() {
    const { isInitialView, pageTitle, isAppMounted } = this.props;

    /* Since screen readers will read the document title on the initial page load,
     * we don't need to render an alert on the initial load or on the server */
    return !isInitialView && isAppMounted ? (
      <Alert
        type="assertive"
        tabIndex={-1}
        className={theme.AccessiblePageTitle}
      >
        {`Navigated to ${pageTitle.replace(" | ComeHome", "")}`}
      </Alert>
    ) : null;
  }
}
