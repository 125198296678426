import { connect } from 'react-redux';

import MultiUnitSelectModal from '@client/components/MultiUnitSelectModal';
import { getWatchListItems } from '@client/store/selectors/watchlist.selectors';

const mapStateToProps = (state) => {
  return {
    watchListItems: getWatchListItems(state),
  };
};

export default connect(mapStateToProps, {})(MultiUnitSelectModal);
