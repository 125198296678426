import { useLoInfoProps } from '@client/hooks/lo-info-provider.hooks';
import { LOInfoProviderArgs } from '@client/store/types/loan-officer';

type LOInfoProviderProps = {
  children: (args: LOInfoProviderArgs) => JSX.Element | null;
};

/**
 * LOInfoComponent encapsulates, provides all the LO related data
 * to corresponding wrapped components
 */
const LOInfoProvider: React.FC<LOInfoProviderProps> = ({ children }) => {
  return children(useLoInfoProps());
};

export default LOInfoProvider;
