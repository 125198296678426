import React, { Component } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { STATUSES } from '@client/store/constants';
import PropertyCard from '@client/components/PropertyCard';
import { parseCSSAmount } from '@client/utils/string.utils';
import LoadingSection from '@client/components/generic/LoadingSection';
import theme from '@client/css-modules/SearchMapPropertyCard.css';

import { NormalizedProperty } from '@client/store/types/property';
import { PropertyPhotosSizes } from '@client/store/types/property-photos';
import { stopEvent } from '@client/utils/component.utils';

const { propertyCardHeight } = theme;

type Props = {
  isActive: boolean;
  photoSize: PropertyPhotosSizes;
  activePropertyHasData: boolean;
  isAddedToWatchList: boolean;
  addressSlug: string | null;
  normalizedPropertyData: NormalizedProperty | null;
  setLocalStoragePropertySeen: (addressSlug: string) => void;
  fetchPropertyCardData: (addressSlug: string) => void;
  onEntranceAnimationComplete?: () => void;
  handleGoToPDP: (slug: string) => void;
  handlePropertyDetailsWatchClick: (slug: string) => void;
  handlePropertyDetailsUnwatchClick: (slug: string) => void;
  handlePropertyDetailsUnwatchConfirmClick: (slug: string) => void;
  handleHidePDPModal: () => void;
};

type State = {};

/**
 * A wrapper around <PropertyCard> to allow it to animate-in and fetch property details if shown on
 * page load
 */
export default class SearchMapPropertyCard extends Component<Props, State> {
  onEntranceAnimationCompleteTimeout: number | null = null;

  componentDidMount() {
    const {
      activePropertyHasData,
      isActive,
      fetchPropertyCardData,
      addressSlug,
      setLocalStoragePropertySeen,
    } = this.props;

    if (!activePropertyHasData && isActive && addressSlug) {
      fetchPropertyCardData(addressSlug);
    }
    if (isActive && addressSlug) {
      setLocalStoragePropertySeen(addressSlug);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      fetchPropertyCardData,
      addressSlug,
      isActive,
      activePropertyHasData,
      onEntranceAnimationComplete,
    } = this.props;

    /* If the propertyDataCache was cleared, fetch data for the selected property */
    if (
      !prevProps.isActive &&
      isActive &&
      !activePropertyHasData &&
      addressSlug
    ) {
      fetchPropertyCardData(addressSlug);
    }

    if (!prevProps.isActive && isActive && onEntranceAnimationComplete) {
      this.onEntranceAnimationCompleteTimeout = window.setTimeout(
        onEntranceAnimationComplete,
        500
      );
    }
  }

  componentWillUnmount() {
    if (this.onEntranceAnimationCompleteTimeout) {
      window.clearTimeout(this.onEntranceAnimationCompleteTimeout);
    }
  }

  handlePropertyCardClose = (e) => {
    stopEvent(e)
    this.props.handleHidePDPModal();
  }

  render() {
    const {
      activePropertyHasData,
      isActive,
      photoSize,
      addressSlug,
      normalizedPropertyData,
      isAddedToWatchList,
      handleGoToPDP,
      handlePropertyDetailsWatchClick,
      handlePropertyDetailsUnwatchClick,
      handlePropertyDetailsUnwatchConfirmClick,
    } = this.props;

    return (
      <AnimatePresence>
        {isActive && (
            <motion.div
              className={theme.SearchMapPropertyCard}
              key="pdp-modal-wrapper"
              initial={{
                y: parseCSSAmount(propertyCardHeight) + 20,
              }}
              animate={{
                y: 0,
                transition: {
                  duration: 0.15,
                  easing: 'easeIn',
                  damping: 0,
                },
              }}
              exit={{
                y: parseCSSAmount(propertyCardHeight) + 20,
                transition: {
                  damping: 0,
                  duration: 0.1,
                },
              }}
            >
              <LoadingSection
                isLoading={!activePropertyHasData || !normalizedPropertyData}
                className={theme.LoadingSection}
              >
                  <PropertyCard
                    theme={theme}
                    data-ll-key={addressSlug}
                    key={addressSlug!}
                    photoSize={photoSize}
                    isShowingWatchListActionButton
                    isShowingDaysOnMarket
                    propertyDetails={normalizedPropertyData!}
                    status={STATUSES.SUCCESS}
                    shouldHandleCheckingForWatchListStatus={false}
                    isAddedToWatchList={isAddedToWatchList}
                    goToProperty={handleGoToPDP}
                    handleReportWatchClick={handlePropertyDetailsWatchClick}
                    handleReportUnwatchClick={handlePropertyDetailsUnwatchClick}
                    handleReportUnwatchConfirmClick={
                      handlePropertyDetailsUnwatchConfirmClick
                    }
                    hasMobileMapCloseButton={isActive}
                    handlePropertyCardClose={this.handlePropertyCardClose}
                  />
              </LoadingSection>
            </motion.div>
        )}
      </AnimatePresence>
    );
  }
}
