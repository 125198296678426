import accounting from 'accounting';
import pluralizeWord from 'pluralize';
import {
  dollarsFormatter,
  numberFormatter,
} from '@client/utils/formatter.utils';
import { EMPTY_INPUT_FIELD_VALUE } from '@client/store/homeowner-constants';
import { EXTERNAL_LEGAL_CONTENT_REGEX } from '@client/store/constants';
import { get } from 'lodash';

export const DEFAULT_PLACEHOLDER = '--';
export const DEFAULT_BED_DATA_LABEL = 'Bed';
export const DEFAULT_BATH_DATA_LABEL = 'Bath';

export const isValidLength = (
  string: string,
  minLength: number = 1,
  maxLength?: number
) => {
  const isValidMinLength = string.length >= minLength;
  if (maxLength) {
    return isValidMinLength && string.length <= maxLength;
  } else {
    return isValidMinLength;
  }
};

export const dollarsFormatterWithSign = (
  value: string | number | null
): string | null => {
  if (value) {
    const floatVal = typeof value === 'string' ? parseFloat(value) : value;
    const percentage = dollarsFormatter(Math.abs(floatVal));
    return floatVal > 0 ? `+ ${percentage}` : `- ${percentage}`;
  }

  return null;
};

/**
 * Returns the formatted value to use for
 * the property details: bed and bath data.
 * Accounting for studios having 0 bedrooms or bathrooms
 * If we receive 0, undefined or null then we show placeholder (if any) or '--' by default
 */
export const placeholderFormatter = (
  value?: number | string | null,
  placeholder = DEFAULT_PLACEHOLDER
): string | number => value || placeholder;

/**
 * Get the property unit on the tooltip and
 * the property brief header on the AVM deep dive in PDP
 * e.g.
 * getLabelPropertyData(0, 'Bed'), return 'Bed'
 * getLabelPropertyData(1, 'Bed'), return 'Bed'
 * getLabelPropertyData(2, 'Bed'), return 'Beds'
 * getLabelPropertyData(undefined, 'Bed'), return 'Bed'
 */
export const getLabelPropertyData = (
  data: undefined | null | number,
  singularLabel: string
): string => {
  /**
   * if data is 0 or undefined, pluralize returns plural
   * so we need to correct it to return singular.
   */
  if (data) {
    if (data === 0) {
      return singularLabel;
    }
    return pluralize(singularLabel, data);
  } else {
    return singularLabel;
  }
};

export const rateFormatter = (
  i?: string | number,
  decimalPlaces?: number
): string => {
  if (i && i !== '%' && i !== EMPTY_INPUT_FIELD_VALUE) {
    if (typeof i === 'string') {
      i = i.replace(/[^0-9.]/g, '');
      let strArr = i.split('.');
      let precision = 0;

      if (strArr && get(strArr, '1')) {
        precision = get(strArr, '1').length;
      }

      i =
        decimalPlaces && precision > decimalPlaces
          ? parseFloat(i).toFixed(decimalPlaces).toString()
          : parseFloat(i).toString();
    }
    return i + '%';
  } else {
    return EMPTY_INPUT_FIELD_VALUE;
  }
};

/**
 * Convert the first character of a string to uppercase
 * @param  {string} string to capitalize
 * @return {string} string with first character converted to uppercase
 */
export const capitalize = (string: string): string =>
  `${string.charAt(0).toUpperCase()}${string.substring(1)}`;

/**
 * Convert a given singular tense string to plural if the passed count is > 1
 * @param  {string} string to pluralize
 * @return {number} number used to determine whether to pluralize
 */
export const pluralize = (string: string, count: number): string =>
  pluralizeWord(string, count);

/**
 * Convert a line of text into title case. For example,
 * 'A place in time' becomes 'A Place In Time'
 * @param {string} string to change
 * @return {string} string that has each word capitalized
 */
export const titleCase = (string: string): string => {
  return string
    .toLowerCase()
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');
};

/*
 * Given a number, return an abbreviation of that number using K for thousands and M for millions
 * @param  {number} value
 * @param  {integer} sign - 1 for positive numbers, -1 for negative numbers
 * @return {string} abbreviated number
 */
export const abbrNumberFormatter = (
  value?: number | null,
  placeholder: string | null = DEFAULT_PLACEHOLDER,
  decimalPlaces: number = 1,
  sign: number = 1
): string => {
  if (!value && value !== 0) {
    return placeholder ?? DEFAULT_PLACEHOLDER;
  }
  if (value >= 9900000) {
    return `${sign * parseFloat(accounting.toFixed(value / 1000000, 0))}M`;
  } else if (value >= 999000) {
    return `${
      sign * parseFloat(accounting.toFixed(value / 1000000, decimalPlaces))
    }M`;
  } else if (value >= 20000) {
    return `${sign * parseFloat(accounting.toFixed(value / 1000, 0))}k`;
  } else if (value >= 1000) {
    return `${
      sign * parseFloat(accounting.toFixed(value / 1000, decimalPlaces))
    }k`;
  } else {
    return `${sign * parseFloat(accounting.toFixed(value, 0))}`;
  }
};

/**
 * Just add a dollar sign to the output of addrNumberFormatter
 * @param  {number} value
 * @return {string} abbreviated dollar amount
 */
export const abbrDollarsFormatter = (
  value: number,
  placeholder: string | null = DEFAULT_PLACEHOLDER,
  decimalPlaces?: number
): string | null => {
  if (!value && value !== 0) {
    return placeholder;
  }
  return `$${abbrNumberFormatter(value, placeholder, decimalPlaces)}`;
};

/**
 * Adds percentage to a number, defaulting to placeholder
 * @param {number} value
 * @param {string} placeholder
 * @return {string} value || placeholder + '%'
 */
export const percentFormatter = (
  value?: number | null,
  placeholder: string = DEFAULT_PLACEHOLDER,
  round: boolean = true
): string => {
  if (value) {
    if (round && typeof value === 'number') {
      value = Math.floor(value);
    }
    return `${value}%`;
  } else {
    return placeholder;
  }
};

/**
 * Strips any non-numeric values from a string, returning it as a formatted number
 * @param {string} inputValue
 * @return {number} the number formatted
 */
export const removeNonDigits = (input?: string | number | null) => {
  const filteredInput = `${input}`.replace(/[^\d.]/g, '');

  if (typeof input === 'number') {
    return input;
  } else if (!filteredInput) {
    return null;
  }

  /* using Set to filter out duplicate dots, for e.g., ..2 -> 0.2  */
  return Number(Array.from(new Set(filteredInput.split('.'))).join('.'));
};

/**
 * Strips any non-numeric values from a string, swap to Number (removing things like 0 from the beginning), then return as String.
 * @param {string} inputValue
 * @return {string} a string of the number formatted
 */
export const removeLettersAndFormatAsString = (inputValue: string): string => {
  // Formats so that if there's a 0 at the start, or decimals etc, it comes out properly
  return `${removeNonDigits(inputValue)}`;
};

/**
 * Escapes quotes in strings
 * @param {string} string to escape
 * @return {string} escaped string
 */
export const escapeQuotes = (string: string): string => {
  return string.replace(/\\([\s\S])|(")/g, '\\$1$2');
};

export const unformatNumber = (value: string): number =>
  accounting.unformat(value);

/**
 * Dollars formatter that falls back to a placeholder for null or $0 values
 * @param {number} value
 * @param {string} placeholder
 * @return {string} '$' + value || placeholder
 */
export const dollarsWithPlaceholder = (
  value?: number | string | null,
  placeholder: string = DEFAULT_PLACEHOLDER
): string => (value ? dollarsFormatter(value) : placeholder);

/**
 * Number formatter that falls back to a placeholder for null or 0 values
 * @param {number} value
 * @param {string} placeholder
 * @return {string} value || placeholder
 */
export const numbersWithPlaceholder = (
  value?: number | string | null,
  placeholder: string = DEFAULT_PLACEHOLDER
) => (value ? numberFormatter(value) : placeholder);

/**
 * Given a camel-case string, return the string in snake-case format  (i.e. 'itTakesTwo' => 'it_takes_two')
 * @param {string} camel-case value
 * @return {string} snake-case value
 */
export const camelCaseToSnakeCase = (value?: string): string | undefined =>
  value
    ? value.replace(/([A-Z0-9])/g, (char) => `_${char.toLowerCase()}`)
    : value;

/**
 * Given a camel-case string, return the string with spaces format  (i.e. 'itTakesTwo' => 'it takes two')
 * @param {string} camel-case value
 * @return {string} string with spaces
 */
export const camelCaseToStringWithSpaces = (
  value?: string
): string | undefined =>
  value
    ? value
        .replace(/([A-Z0-9])/g, (char) => ` ${char.toLowerCase()}`)
        .replace(/^ /, '')
    : value;

/**
 * Given an imported CSS string value, return a numerical amount
 * @param  {string}
 * @return {number}
 */
export const parseCSSAmount = (valueString?: string): number => {
  if (!valueString) {
    throw new Error(
      `parseCSSAmount encountered a falsy valueString argument: ${valueString}`
    );
  } else if (
    valueString.indexOf('rem') === -1 &&
    valueString.indexOf('px') === -1
  ) {
    throw new Error(
      `parseCSSAmount valueString argument must be in either rems or px units.  You passed ${valueString}`
    );
  }
  let value = parseFloat(valueString.replace(/[^0-9.]/, ''));
  if (valueString.indexOf('rem') > -1) {
    let baseFontSize = 16;
    // Check needed for SSR
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const bodyFontSizeValueMatches = window
        .getComputedStyle(document.body)
        .getPropertyValue('font-size')
        .match(/\d+/);
      // Check needed for testing
      if (bodyFontSizeValueMatches && bodyFontSizeValueMatches[0]) {
        baseFontSize = parseFloat(bodyFontSizeValueMatches[0]);
      }
    }
    value = value * baseFontSize;
  }
  return value;
};

/**
 * Given a header value returned by Consumer API, get the alerts "mark as read"
 * URL by returning the last of the included link values
 * @param { string } header value, i.e. '<https://api.dev.ch.housecanary.net/foo>; rel="mark-as-read"'
 */
export const getConsumerAPILinkHeaderValues = (
  string?: string | {}
): { [key: string]: string } => {
  if (typeof string === 'string') {
    return string
      .split(',')
      .map((segment) => {
        if (segment) {
          const key = segment.match(/rel="(.+)"/) || [];
          const value = segment.match(/<(.+)>/) || [];

          return {
            key: key[1],
            value: value[1],
          };
        } else {
          return null;
        }
      })
      .filter((segment) => segment)
      .reduce((mem, segment) => {
        if (segment) {
          mem[segment.key] = segment.value;
        }
        return mem;
      }, {});
  } else {
    throw new Error(
      `Value passed to getConsumerAPILinkHeaderValues not of type string. You passed ${typeof string}`
    );
  }
};

/**
 * Checks if an address string ends in an abbreviated form of the type of street,
 * for example 'Dr', 'St', 'Ln', etc. and adds a period at the end if so.
 * @param {String} address - The street address
 * @returns {String} - the address with the proper punctuation
 */
export const punctuateStreetAddress = (
  address: string | null
): string | null => {
  const abbreviations = [
    'st',
    'ln',
    'dr',
    'ave',
    'rd',
    'ct',
    'cir',
    'blvd',
    'fwy',
    'hwy',
    'jct',
    'lndg',
    'pkwy',
    'pt',
    'rte',
    'hls',
  ];
  if (address) {
    const endsInAbbr = abbreviations.some((abbr) =>
      address.toLowerCase().endsWith(abbr)
    );
    return endsInAbbr ? `${address}.` : address;
  }

  return address;
};

/**
 * Checks if an address string ends in an abbreviated form of the type of street,
 * for example 'Dr', 'St', 'Ln', etc. and adds a period at the end if so.
 * @param {String} address - The street address
 * @returns {String} - the address with the proper punctuation
 */
export const punctuateUnit = (unit) => {
  const abbreviations = [
    'apt',
    'fl',
    'flr',
    'lowr',
    'uppr',
    'ste',
    'bldg',
    'dept',
  ];
  abbreviations.forEach((abbr) => {
    /* SECURITY: non-literal regexps involving `abbr`
     * are ok here since they come from the hardcoded
     * `abbreviations` list above */
    const regex = new RegExp(abbr + '(\\s|$)'); // eslint-disable-line security/detect-non-literal-regexp
    if (unit.toLowerCase().match(regex)) {
      const newUnit =
        abbr.charAt(0).toUpperCase() + abbr.slice(1, abbr.length) + '.';
      const newRe = new RegExp(abbr, 'i'); // eslint-disable-line security/detect-non-literal-regexp
      unit = unit.replace(newRe, newUnit);
    }
  });
  return unit;
};

/**
 * Create place slug from a place description
 * @param  {string} place description
 * @return {string} place slug
 * @example
 *   placeSlugForDescription("San Francisco, CA") --> "San-Francisco-CA"
 */
export const placeSlugForDescription = (
  description: string | null
): string | null =>
  description
    ? description.replace(/[\s,]/g, '-').replace(/--/g, '-')
    : description;

/**
 * Given the HTML from a legal page at HouseCanary.com, parse out the ComeHome content
 * @param  {string} html page content
 * @return {string} html snippet
 */
export const getComehomeContentFromHTML = (htmlString: string): string => {
  const matches = htmlString.match(EXTERNAL_LEGAL_CONTENT_REGEX);

  if (!matches) {
    throw new Error('Cannot parse <comehomecontent> from HTML: ' + htmlString);
  }
  return matches && matches[1];
};

export const stripOutNonNumber = (input: string): string =>
  input.replace(/\D/g, '');

export const formatZipInputFormDisplay = (input) => {
  if (!input) {
    input = '';
  } else if (typeof input !== 'string') {
    input = `${input}`;
  }
  input = stripOutNonNumber(input);
  return input.substring(0, 5);
};

/**
 * cannot assume that phone nums from MLS are formatted correctly
 * this ensures formatted phone number link is compatible with browser and native app
 * https://developer.apple.com/library/archive/featuredarticles/iPhoneURLScheme_Reference/PhoneLinks/PhoneLinks.html
 */
export const formatPhoneNumForLink = (phoneNum: string): string => {
  let phoneNumLink = stripOutNonNumber(phoneNum);

  if (phoneNumLink.charAt(0) === '1') {
    phoneNumLink = phoneNumLink.substring(0, 11);
    return (
      phoneNumLink.charAt(0) +
      '-' +
      phoneNumLink.substring(1, 4) +
      '-' +
      phoneNumLink.substring(4, 7) +
      '-' +
      phoneNumLink.substring(7, 11)
    );
  } else {
    phoneNumLink = phoneNumLink.substring(0, 10);
    return (
      phoneNumLink.substring(0, 3) +
      '-' +
      phoneNumLink.substring(3, 6) +
      '-' +
      phoneNumLink.substring(6, 10)
    );
  }
};

export const formatPhoneInputForDisplay = (input) => {
  if (!input) {
    input = '';
  } else if (typeof input !== 'string') {
    input = `${input}`;
  }
  input = stripOutNonNumber(input);

  const size = input.length;
  const hasCountryCode = input.charAt(0) === '1';
  if (hasCountryCode) {
    input = input.substring(0, 11);
    if (size === 1) {
      return input;
    } else if (size < 5) {
      return input.charAt(0) + ' (' + input.substring(1, input.length);
    } else if (size < 8) {
      return (
        input.charAt(0) +
        ' (' +
        input.substring(1, 4) +
        ') ' +
        input.substring(4, 7)
      );
    } else {
      return (
        input.charAt(0) +
        ' (' +
        input.substring(1, 4) +
        ') ' +
        input.substring(4, 7) +
        ' - ' +
        input.substring(7, 11)
      );
    }
  } else {
    input = input.substring(0, 10);
    if (size === 0) {
      return input;
    } else if (size < 4) {
      return '(' + input;
    } else if (size < 7) {
      return '(' + input.substring(0, 3) + ') ' + input.substring(3, 6);
    } else {
      return (
        '(' +
        input.substring(0, 3) +
        ') ' +
        input.substring(3, 6) +
        ' - ' +
        input.substring(6, 10)
      );
    }
  }
};

export const formatPhoneNumber = (phone: string | number): string => {
  const originalString = `${phone}`;
  let stripped = stripOutNonNumber(originalString);

  if (stripped.length !== 10 && stripped.length !== 11) {
    return originalString;
  }

  stripped = stripped.substring(0, 11);

  if (stripped.length === 11) {
    stripped =
      stripped.slice(0, 1) +
      ' (' +
      stripped.slice(1, 4) +
      ') ' +
      stripped.slice(4, 7) +
      '-' +
      stripped.slice(7, stripped.length);
  } else {
    stripped =
      '(' +
      stripped.slice(0, 3) +
      ') ' +
      stripped.slice(3, 6) +
      '-' +
      stripped.slice(6, stripped.length);
  }
  return stripped;
};

/**
 * Converts a hex color value to rgba string
 * @param {string} hex - color in format: #rrggbb, rrggbb, #rgb, rgb
 * @param {number} opacity (0 - 100)
 * @returns {string | null} - equivalent value rgba() string
 * @example
 *   convertHexColorToRgba('#08706B', 80)  --> 'rgba(8, 112, 107, 0.8)'
 *   convertHexColorToRgba('#000000', 100) --> 'rgba(0, 0, 0, 1)'
 */
export const convertHexColorToRgba = (
  hex: string | null,
  opacity: number = 100
): string => {
  if (hex === null) return '';

  if (hex.match(/^#?[0-9a-f]{3}$/i)) {
    hex = hex.replace('#', '');
    const r = hex.substring(0, 1);
    const g = hex.substring(1, 2);
    const b = hex.substring(2, 3);
    hex = `#${r}${r}${g}${g}${b}${b}`;
  } else if (!hex.match(/^#?[0-9a-f]{6}$/i)) {
    return '';
  }

  if (opacity < 0) {
    opacity = 0;
  } else if (opacity > 100) {
    opacity = 100;
  }

  hex = hex.replace('#', '');
  const rr = parseInt(hex.substring(0, 2), 16);
  const gg = parseInt(hex.substring(2, 4), 16);
  const bb = parseInt(hex.substring(4, 6), 16);

  return `rgba(${rr}, ${gg}, ${bb}, ${opacity / 100})`;
};

/**
 * Lighten / Darken a hex color value
 * @param  {string} hex - color in #rrggbb format
 * @param  {number} amt - amount to lighten / darken. ex: -255 (darkest). 255 (lightest)
 * @return {string | null} lightened / darkened hex color value
 * @example
 *   lightenDarkenColor('#08706B', 80)   --> '#58c0bb'
 *   lightenDarkenColor('#000000', 255)  --> '#ffffff'
 *   lightenDarkenColor('#ffffff', -255) --> '#000000'
 */
export const lightenDarkenHexColor = (hex: string, amt: number = 0): string => {
  if (!hex.match(/^#[0-9a-f]{6}$/i)) {
    return '';
  }
  if (amt === 0) {
    return hex;
  }

  const num = parseInt(hex.replace('#', ''), 16);

  let r = (num >> 16) + amt;
  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  let b = ((num >> 8) & 0x00ff) + amt;
  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  let g = (num & 0x0000ff) + amt;
  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }

  const [red, green, blue] = [r, g, b].map((color) =>
    color <= 15 ? `0${color.toString(16)}` : color.toString(16)
  );
  return `#${red}${blue}${green}`;
};

export function getUrlParams(search) {
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  const params = {};
  hashes.map((hash) => {
    const [key, val] = hash.split('=');
    params[key] = decodeURIComponent(val);
  });
  return params;
}

export function getStringWithDelimiter(arr: any[], delimiterType: string) {
  const filteredArr = arr.filter((el) => el);
  if (filteredArr.length > 1) {
    return filteredArr.join(` ${delimiterType} `);
  }
  return filteredArr.join('');
}
