import { authExchange } from '@urql/exchange-auth';
import Cookies from 'js-cookie';

import HC_CONSTANTS from '@client/app.config';
import { getCobrandIdFromHostname } from '@client/cobrand-settings/cobrand-utils';

export const urqlAuthExchange = (
  sessionToken: string,
  doUnauthorizedLogout: () => void
) =>
  authExchange(async (utils) => {
    let urqlSessionToken = sessionToken;
    return {
      /* This is called prior to each outgoing request.  Its purpose is to modify the request, supplying
       * the necessary auth headers */
      addAuthToOperation: (operation) => {
        return utils.appendHeaders(operation, {
          'hc-api-auth': `Bearer ${urqlSessionToken}`,
        });
      },
      /* This is called after each request completes.  If it returns true, the `getAuth` method defined
       * above is called to initiate the token refresh flow. */
      didAuthError: (error) => {
        return error?.response?.status === 401;
      },
      /* This method is called whenever didAuthError returns true */
      refreshAuth: async () => {
        let fetchNewAccessTokenResponse;

        if (typeof (window as any) !== 'undefined') {
          fetchNewAccessTokenResponse = await window.fetch(
            HC_CONSTANTS.AUTH_TOKEN_REFRESH_ENDPOINT,
            {
              headers: {
                'device-id': Cookies.get('hcid') as string,
                'X-platform': navigator.userAgent,
                'X-SiteID': getCobrandIdFromHostname(window.location.hostname),
              },
              method: 'POST',
              cache: 'reload' as 'reload',
              credentials: 'include' as 'include',
            }
          );
          /* Expected case when a token is being refreshed - supply all subsequent requests with the
           * new token */
          if (fetchNewAccessTokenResponse.status === 200) {
            const { token } = await fetchNewAccessTokenResponse.json();
            urqlSessionToken = token.access;
            /* The refresh token call should only ever fail for authenticated users. In this case,
             * log the user out */
          } else {
            doUnauthorizedLogout();
          }
        }
      },
    };
  });
