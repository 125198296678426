import { connect } from 'react-redux';

import PropertyPhoto from '@client/components/PropertyPhoto';
import {
  getAllPropertyPhotosForAddressSlug,
  getPropertyPhotosStatusForAddressSlug,
} from '@client/store/selectors/property-photos.selectors';
import { STATUSES } from '@client/store/constants';
import { fetchPropertyPhotos } from '@client/store/actions/property-photos.actions';

import {
  PropertyPhotosSizes,
  FetchPropertyPhotosAction,
} from '@client/store/types/property-photos';

type OwnProps = {
  className?: string;
  addressSlug?: string;
  photoSize: PropertyPhotosSizes;
  /* Photos that were fetched as part of the property data outside of the photo cache. Since we already
   * have them, there's no need to fetch them again */
  overridePhotos?: string[];
  mlsLogoUrl?: string | null;
};

const mapStateToProps = (state, ownProps: OwnProps) => {
  /**
   * Note that we need to pass addressSlug into all of these selectors since this component
   * needs to fetch photos for properties that are NOT the active PDP property
   */
  const addressSlug = ownProps.addressSlug || null;
  const photosStatus = ownProps.overridePhotos
    ? STATUSES.SUCCESS
    : getPropertyPhotosStatusForAddressSlug(addressSlug)(state, {
        size: ownProps.photoSize,
      });
  const photos =
    ownProps.overridePhotos ||
    getAllPropertyPhotosForAddressSlug(addressSlug)(state, {
      size: ownProps.photoSize,
    });

  /* When used in `MultiUnitSelectModal`, the photosStatus will be undefined for a brief
   * moment after this component mounts and before state is initialized for each property */
  const photosStatusIsInit = photosStatus === STATUSES.INIT;
  const photosStatusIsLoading = photosStatus === STATUSES.LOADING;

  return {
    addressSlug,
    photos,
    photosStatusIsInit,
    photosStatusIsLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleFetchPropertyPhotos: ({
    size,
    slug,
  }: FetchPropertyPhotosAction['payload']) =>
    dispatch(fetchPropertyPhotos({ size, slug })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyPhoto);
