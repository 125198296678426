import logoSrc from '@client/assets/images/comehome-by-housecanary-house-on-right.png';
import React from 'react';
/**
 * An alternate logo for display when required by a specific MLS
 */
const ComehomeHeaderLogoByHousecanaryWithHouse: React.FunctionComponent<
  React.HTMLAttributes<HTMLImageElement>
> = (props) => {
  return <img src={logoSrc} {...props} />;
};

export default ComehomeHeaderLogoByHousecanaryWithHouse;
