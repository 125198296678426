import { getPropertyDetailPageConfig } from '@client/store/selectors/cobranding.selectors';
import { useSelector } from 'react-redux';

export type SecondaryCtaPdpConfig = {
  ctaText: string | null;
  ctaUrl: string | null;
  ctaButtonBgColor: string | null;
  ctaTextColor: string | null;
  nativeAppNavKey: string | null;
};

export const useSecondaryCtaPdpConfig = (): SecondaryCtaPdpConfig => {
  const pdpConfig = useSelector(getPropertyDetailPageConfig);

  return {
    ctaText: pdpConfig?.secondaryCtaText || null,
    ctaUrl: pdpConfig?.secondaryCtaUrl || null,
    ctaButtonBgColor: pdpConfig?.secondaryCtaButtonBgColor || null,
    ctaTextColor: pdpConfig?.secondaryCtaButtonTextColor || null,
    nativeAppNavKey: pdpConfig?.secondaryCtaNativeAppNavKey || null,
  };
};
