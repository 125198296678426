// NOTE: This is done as a string with replace tokens so we can extract
// the result type to generate types
const QUERY_TEMPLATE = /* GraphQL */ `query SpatialSearchMapProperties(
  $spatialId: PropertiesIdentifierInput!,
  $limit: Int!,
  #extraFiltersDef
) {
  propertySpatialSearch(
    id: $spatialId,
    limit: $limit,
    countLimit: { type: AUTO },
    #extraFiltersValues
  ) {
    ... on SpatialSearchCountResult {
      count,
      labelLocation(format:DICT)
    }
    ... on SpatialSearchOverCountResult {
      moreThan,
      labelLocation(format:DICT)
    }
    ... on SpatialSearchDetailResults {
      labelLocation
      hits {
        property {
          address {
            slug
            fullAddress
            hcAddressId
            streetAddress
            unit
            city
            state
            zipcode
          }
          latestListing {
            listingOfficeName
          }
          mls {
            regulations {
              logoOverlay
              photosLogoOverlay
            }
          }
          paymentEstimate {
            total
          }
        }
        summary {
          avm {
            priceMean
          }
          beds
          baths {
            summaryCount
          }
          sqft
          mlsState
          hcMlsId
          listPrice
          listDate
          hcBuildingId
          geoLocation {
            latitude
            longitude
          }
          propertyType
          yearBuilt
          tractId
        }
      }
    }
  }
}`;

export default ({ spatialId, filtersMapping, limit }) => ({
  query: QUERY_TEMPLATE.replace(
    '#extraFiltersDef',
    filtersMapping.map((item) => `$${item.key}: ${item.gqlType}`).join(',')
  ).replace(
    '#extraFiltersValues',
    filtersMapping.map((item) => `${item.key}: $${item.key}`).join(',')
  ),
  variables: {
    spatialId,
    limit,
    ...filtersMapping.reduce((mem, curr) => {
      mem[curr.key] = curr.value;
      return mem;
    }, {}),
  },
});
